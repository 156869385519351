import Dexie, { Table } from 'dexie';
import { AwardTarget, CompletedTask, UnitState } from 'types';

export type Entry<T> = {
  id: string;
  createdOn: number;
  expiresOn: number;
  value: T;
};

export const DB_NAME = 'go4guides';

export function expiryDate(days: number): number {
  return new Date().valueOf() + 1000 * 60 * 60 * 24 * days;
}

export function cacheExistsAndNotExpired<T>(cachedData?: Entry<T>): boolean {
  if (!cachedData) return false;
  return cachedData.expiresOn >= Date.now().valueOf();
}

class IDB extends Dexie {
  awardTargets!: Table<Entry<AwardTarget>>;
  awardProgress!: Table<Entry<CompletedTask[]>>;
  units!: Table<Entry<UnitState>>;

  constructor() {
    super(DB_NAME);
    this.version(1).stores({
      awardTargets: 'id, createdOn, expiresOn',
      awardProgress: 'id, createdOn, expiresOn',
      units: 'id, createdOn, expiresOn'
    });
  }
}

const db = new IDB();
export default db;
