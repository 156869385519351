// import { Suspense, lazy } from 'react';
import EmptySkill from './EmptySkill';

import KnowMyself from './KnowMyself';
import ExpressMyself from './ExpressMyself';
import BeWell from './BeWell';
import HaveAdventures from './HaveAdventures';
import TakeAction from './TakeAction';
import SkillsForMyFuture from './SkillsForMyFuture';

// const KnowMyself = lazy(() => import('./KnowMyself'));
// const ExpressMyself = lazy(() => import('./ExpressMyself'));
// const BeWell = lazy(() => import('./BeWell'));
// const HaveAdventures = lazy(() => import('./HaveAdventures'));
// const TakeAction = lazy(() => import('./TakeAction'));
// const SkillsForMyFuture = lazy(() => import('./SkillsForMyFuture'));

function SVG(props: any) {
  const { children, ...otherProps } = props;
  return (
    <svg
      viewBox="0 0 61.687 70.219"
      enableBackground="new 0 0 61.687 70.219"
      {...otherProps}
    >
      {children}
    </svg>
  );
}

function Skill(props: any) {
  const { guidesTheme, name, stage } = props;

  const Fallback = () => <EmptySkill guidesTheme={guidesTheme} />;

  if (name) {
    const themeProps = { skill: name, stage };
    switch (guidesTheme) {
      case 'Know Myself':
        return <KnowMyself {...themeProps} fallback={<Fallback />} />;
      case 'Express Myself':
        return <ExpressMyself {...themeProps} fallback={<Fallback />} />;
      case 'Be Well':
        return <BeWell {...themeProps} fallback={<Fallback />} />;
      case 'Have Adventures':
        return <HaveAdventures {...themeProps} fallback={<Fallback />} />;
      case 'Take Action':
        return <TakeAction {...themeProps} fallback={<Fallback />} />;
      case 'Skills For My Future':
        return <SkillsForMyFuture {...themeProps} fallback={<Fallback />} />;
      default:
        break;
    }
  }

  return <Fallback />;
}

function SkillsBuilder(props: any) {
  const { guidesTheme, skill, stage, ...otherProps } = props;
  return (
    <SVG {...otherProps}>
      {/* <Suspense fallback={<Skill guidesTheme={guidesTheme} />}> */}
      <Skill guidesTheme={guidesTheme} name={skill} stage={stage} />
      {/* </Suspense> */}
    </SVG>
  );
}

export default SkillsBuilder;
