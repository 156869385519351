import { HTMLProps } from 'react';
import { Checkbox } from 'components/form';
import './activity.scss';

type ActivityProps = HTMLProps<HTMLDivElement> & {
  name: string;
  duration?: number;
  completed: boolean;
  selected: boolean;
  disabled?: boolean;
  activeColor: string;
  onToggle: () => void;
};

function Activity(props: ActivityProps) {
  const {
    className,
    name,
    duration,
    completed,
    selected,
    disabled,
    activeColor: color,
    onToggle,
    ...otherProps
  } = props;

  let newClassName = 'gta-item';
  if (selected) newClassName += ' gta-item--selected';
  if (completed && !selected) newClassName += ' gta-item--completed';
  if (className) newClassName += ` ${className}`;

  let contentClassName = '';
  if (completed)
    contentClassName += ` bg-${color}${selected ? '' : `-70 text-dark`}`;
  if (!duration) contentClassName += ' py-2';

  return (
    <div
      {...otherProps}
      className={newClassName}
      style={{ opacity: disabled ? 0.5 : '' }}
    >
      <div
        style={{ whiteSpace: 'nowrap', overflowX: 'hidden' }}
        className={contentClassName}
      >
        <div style={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}>
          {name}
        </div>
        {duration !== undefined && <small>{`${duration} minutes`}</small>}
      </div>

      <div className="d-grid" style={{ placeContent: 'center' }}>
        {!disabled && <Checkbox checked={selected} onChange={onToggle} />}
      </div>
    </div>
  );
}

export default Activity;
