import Bolt from 'components/icons/ui/Bolt';
import { Span } from 'components/typography';
import { HTMLProps } from 'react';

interface OverallProgressProps extends HTMLProps<HTMLDivElement> {
  title: string;
  color?: string;
  progress: number;
  notification?: string;
  glass?: boolean;
}

function OverallProgress(props: OverallProgressProps) {
  const {
    className,
    title,
    color = 'light',
    progress,
    notification,
    glass = false
  } = props;

  const newClassName = `bg-${color}${glass ? '--glass' : ''} d-flex p-2 mb-1${
    className ? ` ${className}` : ''
  }`;

  return (
    <div
      className={newClassName}
      style={{
        alignItems: 'center',
        gap: 8,
        borderRadius: 10,
        width: '100%'
      }}
    >
      <Span color="light" align="left" className="flex-1">
        {title}
      </Span>

      {notification !== undefined && (
        <div
          tabIndex={-1}
          className="tooltip-wrapper"
          style={{ position: 'relative' }}
        >
          <Bolt fill="yellow" />
          <span className="tooltip">{notification}</span>
        </div>
      )}

      <div
        className={`bg-light`}
        style={{
          width: 75,
          height: 10,
          borderRadius: 10,
          overflow: 'hidden'
        }}
      >
        <div
          className="bg-success"
          style={{
            width: `${progress}%`,
            height: '100%',
            transition: 'width 0.2s linear'
          }}
        />
      </div>
    </div>
  );
}

export default OverallProgress;
