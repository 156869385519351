import Base, { AwardProps } from './Base';

function ExpressMyself(props: AwardProps) {
  return (
    <Base {...props}>
      <g id="Express_Myself_Award">
        <g>
          <defs>
            <rect id="SVGID_9_" x="0" y="0" width="53.513" height="66.893" />
          </defs>
          <clipPath id="SVGID_10_">
            <use xlinkHref="#SVGID_9_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M26.757,66.225L26.757,66.225c-14.481,0-26.222-11.74-26.222-26.223V5.745
			c0-2.803,2.271-5.075,5.075-5.075h42.293c2.803,0,5.074,2.272,5.074,5.075v34.258C52.979,54.485,41.24,66.225,26.757,66.225"
          />
          <rect
            x="2.453"
            y="2.361"
            clipPath="url(#SVGID_10_)"
            fill="#EB008B"
            width="48.465"
            height="19.121"
          />

          <line
            clipPath="url(#SVGID_10_)"
            fill="none"
            stroke="#EE95A7"
            strokeWidth="3.822"
            strokeMiterlimit="10"
            x1="3.093"
            y1="22.011"
            x2="50.643"
            y2="22.011"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M21.723,8.66c0.016,0.124,0.021,0.227,0.021,0.315
			c0,1.047-0.748,1.8-1.79,1.8c-1.047,0-1.862-0.789-1.862-1.806c0-1.011,0.811-1.805,1.848-1.805c0.768,0,1.443,0.361,1.686,1.037
			l-0.819,0.237c-0.201-0.392-0.532-0.495-0.866-0.495c-0.563,0-0.991,0.444-0.991,1.026c0,0.589,0.439,1.032,1.017,1.032
			c0.454,0,0.784-0.237,0.912-0.619H19.82V8.66H21.723z"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M24.814,10.697h-0.826v-0.248c-0.195,0.207-0.469,0.325-0.794,0.325
			c-0.604,0-1.006-0.428-1.006-1.078V8.108h0.82v1.413c0,0.304,0.181,0.51,0.443,0.51c0.325,0,0.536-0.221,0.536-0.722V8.108h0.826
			V10.697z"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M26.346,7.344c0,0.263-0.201,0.459-0.479,0.459s-0.474-0.196-0.474-0.459
			c0-0.253,0.195-0.47,0.474-0.47S26.346,7.091,26.346,7.344 M25.454,8.107h0.825v2.589h-0.825V8.107z"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M29.643,10.698h-0.824v-0.207c-0.202,0.181-0.47,0.284-0.805,0.284
			c-0.686,0-1.265-0.552-1.265-1.372c0-0.815,0.579-1.372,1.265-1.372c0.335,0,0.604,0.103,0.805,0.283V6.982h0.824V10.698z
			 M28.818,9.403c0-0.397-0.3-0.671-0.635-0.671c-0.36,0-0.624,0.274-0.624,0.671c0,0.402,0.264,0.67,0.624,0.67
			C28.518,10.073,28.818,9.805,28.818,9.403"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M32.779,9.614h-1.896c0.064,0.309,0.277,0.5,0.555,0.5
			c0.191,0,0.414-0.046,0.553-0.299l0.732,0.149c-0.221,0.547-0.691,0.81-1.285,0.81c-0.746,0-1.365-0.552-1.365-1.372
			c0-0.815,0.619-1.372,1.371-1.372c0.734,0,1.32,0.526,1.336,1.372V9.614z M30.896,9.108h1.068
			c-0.076-0.279-0.283-0.407-0.521-0.407C31.213,8.701,30.975,8.845,30.896,9.108"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M33.838,9.856c0.035,0.222,0.236,0.304,0.459,0.304
			c0.217,0,0.336-0.093,0.336-0.206c0-0.088-0.066-0.155-0.258-0.191l-0.527-0.103c-0.479-0.088-0.754-0.346-0.754-0.753
			c0-0.532,0.459-0.877,1.115-0.877c0.646,0,1.074,0.294,1.176,0.742l-0.752,0.15c-0.025-0.16-0.182-0.304-0.434-0.304
			c-0.223,0-0.295,0.108-0.295,0.201c0,0.072,0.031,0.144,0.197,0.18l0.607,0.124c0.49,0.103,0.717,0.398,0.717,0.779
			c0,0.573-0.494,0.872-1.176,0.872c-0.607,0-1.129-0.222-1.217-0.753L33.838,9.856z"
          />
          <polygon
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            points="10.754,16.348 8.062,16.348 8.062,12.892 10.739,12.892
			10.739,13.624 8.913,13.624 8.913,14.274 10.589,14.274 10.589,14.965 8.913,14.965 8.913,15.615 10.754,15.615 		"
          />
          <polygon
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            points="13.989,16.348 13.04,16.348 12.544,15.6 12.044,16.348 11.1,16.348
			11.952,15.094 11.1,13.754 12.044,13.754 12.544,14.558 13.04,13.754 13.989,13.754 13.132,15.094 		"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M17.296,15.053c0,0.82-0.578,1.372-1.265,1.372
			c-0.335,0-0.603-0.103-0.804-0.284v1.084h-0.825v-3.467h0.825v0.206c0.201-0.18,0.469-0.283,0.804-0.283
			C16.718,13.682,17.296,14.238,17.296,15.053 M16.486,15.053c0-0.397-0.263-0.671-0.624-0.671c-0.335,0-0.634,0.274-0.634,0.671
			c0,0.402,0.299,0.67,0.634,0.67C16.223,15.724,16.486,15.456,16.486,15.053"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M19.628,13.743l-0.035,0.825h-0.151c-0.577,0-0.891,0.3-0.891,0.97v0.81
			h-0.826v-2.589h0.826v0.495c0.185-0.31,0.468-0.532,0.891-0.532C19.515,13.722,19.566,13.728,19.628,13.743"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M22.502,15.265h-1.897c0.065,0.309,0.277,0.5,0.556,0.5
			c0.191,0,0.413-0.046,0.553-0.299l0.732,0.149c-0.223,0.547-0.691,0.81-1.285,0.81c-0.747,0-1.366-0.552-1.366-1.372
			c0-0.815,0.619-1.372,1.372-1.372c0.733,0,1.32,0.526,1.336,1.372V15.265z M20.62,14.759h1.068
			c-0.078-0.279-0.284-0.407-0.521-0.407C20.934,14.352,20.697,14.496,20.62,14.759"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M23.56,15.507c0.036,0.222,0.237,0.304,0.459,0.304
			c0.217,0,0.336-0.093,0.336-0.206c0-0.088-0.067-0.155-0.259-0.191l-0.526-0.103c-0.479-0.088-0.753-0.346-0.753-0.753
			c0-0.532,0.459-0.877,1.114-0.877c0.646,0,1.074,0.294,1.177,0.742l-0.753,0.15c-0.025-0.16-0.181-0.304-0.434-0.304
			c-0.222,0-0.294,0.108-0.294,0.201c0,0.072,0.031,0.144,0.195,0.18l0.609,0.124c0.49,0.104,0.717,0.398,0.717,0.779
			c0,0.573-0.494,0.872-1.176,0.872c-0.609,0-1.13-0.222-1.218-0.753L23.56,15.507z"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M26.17,15.507c0.036,0.222,0.237,0.304,0.459,0.304
			c0.217,0,0.336-0.093,0.336-0.206c0-0.088-0.066-0.155-0.259-0.191l-0.526-0.103c-0.479-0.088-0.753-0.346-0.753-0.753
			c0-0.532,0.459-0.877,1.114-0.877c0.646,0,1.074,0.294,1.177,0.742l-0.753,0.15c-0.025-0.16-0.181-0.304-0.434-0.304
			c-0.222,0-0.294,0.108-0.294,0.201c0,0.072,0.031,0.144,0.196,0.18l0.608,0.124c0.49,0.104,0.717,0.398,0.717,0.779
			c0,0.573-0.495,0.872-1.176,0.872c-0.608,0-1.13-0.222-1.218-0.753L26.17,15.507z"
          />
          <polygon
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            points="33.383,16.348 32.557,16.348 32.557,14.506 31.588,16.348
			31.195,16.348 30.221,14.496 30.221,16.348 29.395,16.348 29.395,12.892 30.23,12.892 31.391,15.084 32.551,12.892 33.383,12.892
					"
          />
          <polygon
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            points="35.266,17.225 34.375,17.225 34.791,16.26 33.719,13.754
			34.607,13.754 35.219,15.265 35.875,13.754 36.762,13.754 		"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M37.65,15.507c0.035,0.222,0.236,0.304,0.459,0.304
			c0.217,0,0.336-0.093,0.336-0.206c0-0.088-0.068-0.155-0.26-0.191l-0.525-0.104c-0.479-0.088-0.752-0.346-0.752-0.753
			c0-0.532,0.459-0.877,1.113-0.877c0.645,0,1.072,0.294,1.176,0.742l-0.752,0.15c-0.025-0.16-0.182-0.304-0.434-0.304
			c-0.223,0-0.295,0.108-0.295,0.201c0,0.072,0.031,0.144,0.197,0.18l0.607,0.124c0.49,0.104,0.719,0.398,0.719,0.779
			c0,0.573-0.496,0.872-1.178,0.872c-0.607,0-1.129-0.222-1.217-0.753L37.65,15.507z"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M42.217,15.265h-1.898c0.066,0.309,0.277,0.5,0.557,0.5
			c0.189,0,0.412-0.046,0.553-0.299l0.732,0.149c-0.223,0.547-0.693,0.81-1.285,0.81c-0.748,0-1.367-0.552-1.367-1.372
			c0-0.815,0.619-1.372,1.373-1.372c0.732,0,1.32,0.526,1.336,1.372V15.265z M40.334,14.759h1.068
			c-0.078-0.279-0.285-0.407-0.521-0.407C40.648,14.352,40.41,14.496,40.334,14.759"
          />
          <rect
            x="42.645"
            y="12.633"
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            width="0.824"
            height="3.714"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M45.75,13.758v0.655h-0.713v1.934h-0.818v-1.934h-0.373v-0.655h0.373v-0.201
			c0-0.609,0.418-0.996,1.041-0.996c0.176,0,0.346,0.036,0.512,0.108l-0.176,0.64c-0.078-0.026-0.16-0.041-0.223-0.041
			c-0.195,0-0.336,0.118-0.336,0.325v0.165H45.75z"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#EE95A7"
            d="M48.336,3.823c0.748,0,1.357,0.609,1.357,1.357v34.958
			c0,12.646-10.289,22.936-22.936,22.936c-12.646,0-22.934-10.289-22.934-22.936V5.18c0-0.748,0.607-1.357,1.355-1.357H48.336
			 M48.336,0H5.179C2.32,0,0,2.319,0,5.18v34.958c0,14.777,11.98,26.758,26.757,26.758c14.778,0,26.756-11.98,26.756-26.758V5.18
			C53.514,2.319,51.195,0,48.336,0"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#D1D1CE"
            d="M39.869,34.235l-6.229-6.227L16.68,44.969l0.487,0.486l-1.685,1.686
			L31.01,62.67c10.621-2,18.682-11.342,18.682-22.533v-0.105l-7.809-7.811L39.869,34.235z"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#4F86C6"
            d="M40.852,31.69H21.926c-0.704,0-1.274,0.568-1.274,1.273v12.449
			c0,0.705,0.57,1.275,1.274,1.275h12.011l4.748,4.506c0.162,0.152,0.43,0.039,0.43-0.186v-4.32h1.736
			c0.703,0,1.273-0.57,1.273-1.275V32.963C42.125,32.258,41.555,31.69,40.852,31.69"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#EB008B"
            d="M32.67,27.58H13.745c-0.703,0-1.273,0.57-1.273,1.274v12.451
			c0,0.703,0.57,1.273,1.273,1.273h1.735v4.32c0,0.225,0.269,0.34,0.431,0.188l4.748-4.508H32.67c0.703,0,1.273-0.57,1.273-1.273
			V28.854C33.943,28.15,33.373,27.58,32.67,27.58"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#D30E7A"
            d="M33.945,41.305v-3.074l-7.083-7.082l-3.58,3.58l-0.576-0.574l-3.019,3.217
			l5.207,5.207h7.775C33.373,42.579,33.945,42.008,33.945,41.305"
          />

          <rect
            x="22.533"
            y="31.226"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.7097 27.414)"
            clipPath="url(#SVGID_10_)"
            fill="#D30E7A"
            width="3.408"
            height="7.717"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M19.687,34.676c0-1.738,0.395-3.148,3.02-3.526v1.426
			c-0.968,0.115-1.329,0.689-1.345,1.543h1.345v3.248h-3.02V34.676z"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M23.837,34.676c0-1.738,0.394-3.148,3.018-3.526v1.426
			c-0.967,0.115-1.329,0.689-1.345,1.543h1.345v3.248h-3.018V34.676z"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#4F86C6"
            d="M26.757,61.28c-2.288,0-4.141-1.854-4.141-4.141
			c0-2.289,1.853-4.143,4.141-4.143c2.288,0,4.141,1.854,4.141,4.143C30.898,59.426,29.045,61.28,26.757,61.28"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M26.757,52.997c2.288,0,4.141,1.855,4.141,4.143s-1.854,4.141-4.141,4.141
			c-2.288,0-4.141-1.854-4.141-4.141S24.469,52.997,26.757,52.997 M26.757,52.641c-2.48,0-4.498,2.018-4.498,4.498
			s2.018,4.498,4.498,4.498c2.481,0,4.499-2.018,4.499-4.498S29.238,52.641,26.757,52.641"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M28.92,55.452c-0.207,0-0.402,0.094-0.538,0.217l-0.011-0.01
			c0.063-0.104,0.146-0.301,0.146-0.621c0-0.768-0.768-1.346-1.761-1.346s-1.771,0.578-1.771,1.346c0,0.32,0.104,0.518,0.156,0.621
			l-0.012,0.01c-0.134-0.123-0.331-0.217-0.537-0.217c-0.633,0-1.264,0.609-1.264,1.75c0,1.117,0.621,1.75,1.294,1.75
			c0.621,0,1.058-0.344,1.449-0.736c-0.063-0.094-0.155-0.188-0.258-0.188c-0.104,0-0.218,0.074-0.414,0.229
			c-0.259,0.197-0.487,0.313-0.788,0.313c-0.527,0-0.754-0.592-0.754-1.367c0-0.83,0.217-1.377,0.775-1.377
			c0.248,0,0.413,0.07,0.58,0.227c0.248,0.229,0.33,0.289,0.444,0.289c0.084,0,0.135-0.051,0.156-0.082
			c-0.188-0.186-0.456-0.58-0.456-1.086c0-0.58,0.486-0.984,1.397-0.984c0.91,0,1.398,0.404,1.398,0.984
			c0,0.506-0.271,0.9-0.456,1.086c0.02,0.031,0.072,0.082,0.155,0.082c0.113,0,0.196-0.061,0.444-0.289
			c0.155-0.156,0.332-0.227,0.58-0.227c0.561,0,0.777,0.547,0.777,1.377c0,0.775-0.229,1.367-0.756,1.367
			c-0.299,0-0.538-0.115-0.787-0.313c-0.197-0.154-0.311-0.229-0.414-0.229s-0.196,0.094-0.259,0.188
			c0.383,0.393,0.828,0.736,1.448,0.736c0.674,0,1.295-0.633,1.295-1.75C30.184,56.061,29.553,55.452,28.92,55.452"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M27.451,55.43h-0.466c-0.031,0-0.051-0.021-0.062-0.041l-0.146-0.445
			c-0.01-0.029-0.021-0.029-0.031,0l-0.145,0.445c-0.01,0.031-0.031,0.041-0.052,0.041h-0.466c-0.021,0-0.03,0.01-0.011,0.02
			l0.384,0.281c0.021,0.02,0.021,0.051,0.01,0.072l-0.146,0.434c-0.009,0.021-0.009,0.043,0.021,0.021l0.383-0.27
			c0.031-0.02,0.052-0.02,0.083,0l0.373,0.27c0.02,0.021,0.031,0.01,0.02-0.01l-0.144-0.445c-0.011-0.031,0-0.063,0.021-0.072
			l0.382-0.281C27.482,55.44,27.471,55.43,27.451,55.43"
          />
          <path
            clipPath="url(#SVGID_10_)"
            fill="#FFFFFF"
            d="M27.099,58.495c-0.196-0.496-0.353-0.838-0.353-1.252
			c0-0.178,0.053-0.332,0.084-0.414c0.041-0.082,0.052-0.137,0.01-0.137c-0.063,0-0.196,0.146-0.279,0.271
			c-0.063,0.102-0.135,0.301-0.135,0.455c0,0.289,0.093,0.6,0.154,0.787c0.115,0.32,0.219,0.693,0.219,1.045
			c0,0.561-0.249,0.941-0.693,1.283c0.04,0.031,0.145,0.082,0.27,0.082c0.434,0,0.951-0.484,0.951-1.148
			C27.327,59.075,27.213,58.805,27.099,58.495"
          />
        </g>
      </g>
    </Base>
  );
}

export default ExpressMyself;
