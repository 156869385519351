import Base, { AwardProps } from './Base';

function TakeAction(props: AwardProps) {
  return (
    <Base {...props}>
      <g id="Take_Action_Award">
        <g>
          <defs>
            <rect id="SVGID_3_" width="53.515" height="66.893" />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_3_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M26.758,66.223L26.758,66.223c-14.482,0-26.223-11.74-26.223-26.221V5.744
			c0-2.804,2.272-5.075,5.075-5.075h42.294c2.803,0,5.075,2.271,5.075,5.075v34.258C52.979,54.483,41.239,66.223,26.758,66.223"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#D1D1CE"
            d="M20.046,33.122l8.796,8.797l2.45,2.32l12.698,2.08l-0.082-10.793l8.459,8.459
			c-2.053,12.434-12.848,21.92-25.865,21.92c-2.027,0-3.999-0.236-5.895-0.674l-9.012-9.637l1.724-2.359l-1.624-1.623l7.18-9.662
			L20.046,33.122"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M11.546,57.284v0.045h0.05C11.579,57.315,11.563,57.297,11.546,57.284"
          />
          <rect
            x="2.452"
            y="2.36"
            clipPath="url(#SVGID_4_)"
            fill="#F68E1E"
            width="48.465"
            height="19.121"
          />

          <line
            clipPath="url(#SVGID_4_)"
            fill="none"
            stroke="#FAB582"
            strokeWidth="3.822"
            strokeMiterlimit="10"
            x1="3.094"
            y1="22.011"
            x2="50.643"
            y2="22.011"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M21.746,8.692c0.016,0.124,0.021,0.228,0.021,0.315
			c0,1.047-0.748,1.8-1.79,1.8c-1.048,0-1.862-0.789-1.862-1.805c0-1.013,0.81-1.807,1.847-1.807c0.769,0,1.444,0.361,1.687,1.037
			l-0.82,0.237c-0.201-0.392-0.532-0.495-0.866-0.495c-0.563,0-0.991,0.444-0.991,1.027c0,0.588,0.439,1.031,1.018,1.031
			c0.454,0,0.784-0.237,0.912-0.619h-1.057V8.692H21.746z"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M24.838,10.73h-0.826v-0.248c-0.195,0.207-0.47,0.325-0.795,0.325
			c-0.603,0-1.006-0.429-1.006-1.078V8.142h0.82v1.413c0,0.304,0.182,0.51,0.444,0.51c0.325,0,0.536-0.221,0.536-0.722V8.142h0.826
			V10.73z"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M26.369,7.377c0,0.264-0.201,0.459-0.479,0.459
			c-0.279,0-0.476-0.195-0.476-0.459c0-0.253,0.196-0.47,0.476-0.47C26.168,6.907,26.369,7.124,26.369,7.377 M25.478,8.141h0.825
			v2.589h-0.825V8.141z"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M29.667,10.73h-0.826v-0.207c-0.201,0.182-0.469,0.284-0.804,0.284
			c-0.686,0-1.264-0.552-1.264-1.372c0-0.814,0.578-1.372,1.264-1.372c0.335,0,0.603,0.104,0.804,0.283V7.016h0.826V10.73z
			 M28.841,9.436c0-0.396-0.299-0.671-0.634-0.671c-0.361,0-0.624,0.274-0.624,0.671c0,0.402,0.263,0.67,0.624,0.67
			C28.542,10.105,28.841,9.838,28.841,9.436"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M32.804,9.647h-1.897c0.065,0.309,0.277,0.5,0.556,0.5
			c0.192,0,0.413-0.047,0.553-0.299l0.732,0.148c-0.223,0.548-0.691,0.811-1.285,0.811c-0.747,0-1.366-0.552-1.366-1.372
			c0-0.815,0.619-1.372,1.372-1.372c0.733,0,1.32,0.526,1.336,1.372V9.647z M30.921,9.142h1.067
			c-0.076-0.279-0.283-0.407-0.521-0.407C31.235,8.734,30.998,8.879,30.921,9.142"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M33.861,9.89c0.036,0.222,0.237,0.304,0.459,0.304
			c0.217,0,0.336-0.093,0.336-0.206c0-0.088-0.067-0.155-0.258-0.19l-0.527-0.104c-0.479-0.088-0.753-0.346-0.753-0.753
			c0-0.532,0.459-0.877,1.115-0.877c0.645,0,1.073,0.294,1.176,0.742l-0.753,0.149c-0.026-0.159-0.181-0.304-0.434-0.304
			c-0.223,0-0.295,0.108-0.295,0.201c0,0.072,0.031,0.144,0.196,0.181l0.609,0.123c0.49,0.104,0.717,0.398,0.717,0.779
			c0,0.573-0.495,0.872-1.176,0.872c-0.609,0-1.13-0.223-1.218-0.753L33.861,9.89z"
          />
          <polygon
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            points="13.783,13.668 13.783,16.382 12.938,16.382 12.938,13.668
			11.817,13.668 11.817,12.925 14.902,12.925 14.902,13.668 		"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M17.617,16.382h-0.825v-0.207c-0.201,0.181-0.47,0.283-0.806,0.283
			c-0.686,0-1.264-0.552-1.264-1.372c0-0.814,0.578-1.371,1.264-1.371c0.336,0,0.604,0.103,0.806,0.282v-0.206h0.825V16.382z
			 M16.792,15.086c0-0.396-0.299-0.671-0.635-0.671c-0.361,0-0.624,0.274-0.624,0.671c0,0.402,0.263,0.672,0.624,0.672
			C16.493,15.758,16.792,15.488,16.792,15.086"
          />
          <polygon
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            points="20.031,16.381 19.19,15.447 19.042,15.447 19.042,16.381
			18.217,16.381 18.217,12.667 19.042,12.667 19.042,14.674 19.154,14.674 19.975,13.792 20.971,13.792 19.836,15.024 21.09,16.381
					"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M23.741,15.298h-1.898c0.066,0.31,0.278,0.5,0.557,0.5
			c0.19,0,0.413-0.047,0.553-0.299l0.732,0.149c-0.223,0.547-0.692,0.81-1.285,0.81c-0.747,0-1.366-0.552-1.366-1.372
			c0-0.814,0.619-1.372,1.372-1.372c0.733,0,1.319,0.526,1.336,1.372V15.298z M21.858,14.792h1.067
			c-0.078-0.278-0.283-0.406-0.521-0.406C22.173,14.386,21.936,14.529,21.858,14.792"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M27.92,16.381l-0.243-0.63h-1.454l-0.242,0.63h-0.913l1.443-3.457h0.872
			l1.445,3.457H27.92z M26.95,13.885l-0.439,1.135h0.882L26.95,13.885z"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M30.882,15.282l0.758,0.165c-0.129,0.614-0.675,1.011-1.321,1.011
			c-0.772,0-1.413-0.552-1.413-1.372c0-0.814,0.641-1.372,1.413-1.372c0.63,0,1.178,0.388,1.321,0.996l-0.772,0.186
			c-0.073-0.315-0.285-0.459-0.549-0.459c-0.371,0-0.619,0.273-0.619,0.649c0,0.382,0.248,0.65,0.619,0.65
			C30.582,15.736,30.794,15.598,30.882,15.282"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M33.987,16.268c-0.201,0.108-0.413,0.191-0.706,0.191
			c-0.646,0-1.032-0.352-1.032-1.032v-0.98H31.78v-0.654h0.469v-0.764h0.825v0.764h0.764v0.654h-0.764v0.897
			c0,0.269,0.129,0.377,0.346,0.377c0.113,0,0.269-0.041,0.376-0.103L33.987,16.268z"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M35.236,13.028c0,0.263-0.201,0.459-0.479,0.459
			c-0.279,0-0.476-0.196-0.476-0.459c0-0.253,0.196-0.47,0.476-0.47C35.035,12.559,35.236,12.775,35.236,13.028 M34.345,13.791
			h0.825v2.59h-0.825V13.791z"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M38.492,15.086c0,0.82-0.65,1.367-1.419,1.367
			c-0.784,0-1.434-0.547-1.434-1.367c0-0.814,0.649-1.361,1.434-1.361C37.842,13.725,38.492,14.271,38.492,15.086 M37.697,15.086
			c0-0.402-0.304-0.671-0.624-0.671c-0.336,0-0.64,0.269-0.64,0.671c0,0.407,0.304,0.672,0.64,0.672
			C37.394,15.758,37.697,15.493,37.697,15.086"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M41.546,14.792v1.589h-0.819v-1.413c0-0.305-0.182-0.511-0.444-0.511
			c-0.325,0-0.537,0.223-0.537,0.723v1.201H38.92v-2.59h0.825v0.248c0.197-0.207,0.47-0.325,0.796-0.325
			C41.145,13.714,41.546,14.143,41.546,14.792"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FAB582"
            d="M48.335,3.821c0.748,0,1.357,0.609,1.357,1.357v34.957
			c0,12.646-10.289,22.936-22.935,22.936c-12.646,0-22.935-10.289-22.935-22.936V5.179c0-0.748,0.608-1.357,1.356-1.357H48.335
			 M48.335,0H5.18C2.319,0,0,2.317,0,5.179v34.957c0,14.777,11.98,26.758,26.758,26.758c14.776,0,26.757-11.98,26.757-26.758V5.179
			C53.515,2.317,51.195,0,48.335,0"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#DA9D6D"
            d="M18.752,52.272c-0.963,0.516-2.063,0.813-3.232,0.813
			c-1.328,0-2.564-0.387-3.615-1.039v2.5h6.848V52.272z"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#E4B085"
            d="M18.752,52.272v-0.104h0.014c0-0.063,0.03-0.123,0.087-0.156
			c1.366-0.789,2.412-2.039,2.944-3.52c0.12-0.332,0.294-0.664,0.518-0.986l3.392-4.904c0.482-0.691,0.314-1.645-0.375-2.127
			c-0.689-0.484-1.645-0.316-2.128,0.375l-2.156,3.182c0.001,0.008,0.005,0.014,0.005,0.021c0.002,0.105-0.083,0.193-0.19,0.197
			c-2.355,0.045-4.272,2-4.272,4.355c0,0.107-0.086,0.193-0.193,0.193c-0.106,0-0.192-0.086-0.192-0.193
			c0-2.498,1.979-4.574,4.45-4.729v-9.674c0-0.732-0.596-1.328-1.329-1.328c-0.732,0-1.328,0.596-1.328,1.328v6.357v1.262
			c0,0.107-0.086,0.195-0.193,0.195s-0.193-0.088-0.193-0.195v-1.262v-6.357v-1.42c0-0.732-0.596-1.33-1.328-1.33
			c-0.733,0-1.33,0.598-1.33,1.33v1.871v4.775v2.393c0,0.107-0.086,0.195-0.192,0.195c-0.107,0-0.193-0.088-0.193-0.195V39.43
			v-4.775c0-0.732-0.597-1.328-1.33-1.328c-0.731,0-1.327,0.596-1.327,1.328v1.984v5.576v0.697c0,0.105-0.088,0.193-0.193,0.193
			c-0.107,0-0.193-0.088-0.193-0.193v-0.697v-5.576c0-0.732-0.596-1.328-1.33-1.328c-0.731,0-1.328,0.596-1.328,1.328v2.791v4.588
			v2.215c0,2.229,1.106,4.297,2.958,5.537c0.057,0.037,0.083,0.098,0.083,0.16v0.115c1.051,0.652,2.287,1.039,3.615,1.039
			C16.688,53.084,17.789,52.788,18.752,52.272"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#DA9D6D"
            d="M16.203,48.606c0,0.107,0.086,0.193,0.192,0.193
			c0.107,0,0.193-0.086,0.193-0.193c0-2.357,1.917-4.311,4.272-4.355c0.107-0.002,0.192-0.092,0.19-0.197
			c0-0.008-0.004-0.014-0.005-0.021c-0.012-0.096-0.091-0.168-0.189-0.168h-0.003c-0.067,0-0.134,0.01-0.201,0.014
			C18.182,44.032,16.203,46.108,16.203,48.606"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#DA9D6D"
            d="M41.552,52.043c-1.05,0.654-2.286,1.039-3.613,1.039
			c-1.171,0-2.27-0.293-3.232-0.811v2.273h6.846V52.043z"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#E4B085"
            d="M41.552,52.043V51.93h0.001c0-0.063,0.027-0.123,0.082-0.16
			c1.854-1.24,2.959-3.311,2.959-5.537v-2.215V39.43v-2.789c0-0.734-0.596-1.33-1.328-1.33c-0.733,0-1.329,0.596-1.329,1.33v5.574
			v0.697c0,0.107-0.087,0.193-0.193,0.193c-0.107,0-0.193-0.086-0.193-0.193v-0.697v-5.574v-1.986c0-0.732-0.596-1.328-1.329-1.328
			c-0.732,0-1.328,0.596-1.328,1.328v4.775v2.395c0,0.105-0.086,0.191-0.193,0.191s-0.193-0.086-0.193-0.191V39.43v-4.775v-1.871
			c0-0.732-0.597-1.33-1.329-1.33s-1.329,0.598-1.329,1.33v1.42v6.357v1.264c0,0.105-0.087,0.191-0.193,0.191
			s-0.193-0.086-0.193-0.191v-1.264v-6.357c0-0.732-0.596-1.328-1.329-1.328c-0.732,0-1.329,0.596-1.329,1.328v9.674
			c2.473,0.154,4.451,2.23,4.451,4.73c0,0.105-0.086,0.191-0.192,0.191c-0.107,0-0.193-0.086-0.193-0.191
			c0-2.359-1.917-4.314-4.273-4.359c-0.106-0.002-0.191-0.09-0.189-0.195c0-0.008,0.004-0.014,0.005-0.021l-2.158-3.184
			c-0.48-0.689-1.438-0.857-2.126-0.373c-0.689,0.482-0.858,1.438-0.375,2.127l3.392,4.902c0.224,0.324,0.398,0.656,0.518,0.988
			c0.533,1.48,1.579,2.73,2.945,3.52c0.057,0.033,0.086,0.094,0.086,0.154h0.015v0.105c0.963,0.518,2.062,0.811,3.232,0.811
			C39.266,53.083,40.502,52.698,41.552,52.043"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#DA9D6D"
            d="M32.596,44.249c2.355,0.045,4.272,2,4.272,4.357
			c0,0.107,0.086,0.195,0.193,0.195c0.106,0,0.192-0.088,0.192-0.195c0-2.498-1.979-4.574-4.45-4.729
			c-0.067-0.006-0.134-0.014-0.201-0.014H32.6c-0.099,0-0.178,0.074-0.189,0.168c-0.001,0.008-0.005,0.014-0.005,0.021
			C32.403,44.159,32.488,44.247,32.596,44.249"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#EE3A44"
            d="M18.752,54.545h-6.848h-0.358h-0.978v1.82
			c0.316,0.314,0.644,0.621,0.978,0.918c0.017,0.014,0.034,0.029,0.05,0.045c2.43,2.145,5.316,3.783,8.492,4.75v-7.533H19.11H18.752
			z"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#EE3A44"
            d="M41.552,54.545h-6.846h-0.358h-0.979v7.549
			c3.633-1.096,6.889-3.068,9.52-5.674v-1.875h-0.978H41.552z"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#4F86C6"
            d="M26.758,61.278c-2.288,0-4.142-1.854-4.142-4.141
			c0-2.289,1.854-4.141,4.142-4.141c2.287,0,4.141,1.852,4.141,4.141C30.898,59.424,29.045,61.278,26.758,61.278"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M26.758,52.997c2.287,0,4.141,1.854,4.141,4.141s-1.854,4.141-4.141,4.141
			c-2.288,0-4.142-1.854-4.142-4.141S24.47,52.997,26.758,52.997 M26.758,52.639c-2.48,0-4.498,2.018-4.498,4.498
			s2.018,4.498,4.498,4.498c2.479,0,4.498-2.018,4.498-4.498S29.237,52.639,26.758,52.639"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M28.92,55.45c-0.207,0-0.402,0.094-0.537,0.219l-0.012-0.012
			c0.063-0.104,0.146-0.301,0.146-0.621c0-0.766-0.768-1.346-1.76-1.346c-0.993,0-1.771,0.58-1.771,1.346
			c0,0.32,0.104,0.518,0.155,0.621l-0.011,0.012c-0.135-0.125-0.331-0.219-0.537-0.219c-0.634,0-1.265,0.609-1.265,1.75
			c0,1.117,0.621,1.75,1.294,1.75c0.621,0,1.058-0.344,1.449-0.736c-0.062-0.094-0.155-0.188-0.258-0.188
			c-0.104,0-0.218,0.074-0.414,0.23c-0.259,0.195-0.487,0.311-0.788,0.311c-0.527,0-0.754-0.592-0.754-1.367
			c0-0.83,0.217-1.377,0.776-1.377c0.248,0,0.413,0.072,0.58,0.229c0.247,0.227,0.329,0.289,0.443,0.289
			c0.084,0,0.136-0.053,0.156-0.082c-0.187-0.188-0.456-0.58-0.456-1.088c0-0.58,0.487-0.984,1.398-0.984
			c0.909,0,1.397,0.404,1.397,0.984c0,0.508-0.27,0.9-0.456,1.088c0.021,0.029,0.072,0.082,0.155,0.082
			c0.114,0,0.197-0.063,0.445-0.289c0.154-0.156,0.332-0.229,0.579-0.229c0.56,0,0.776,0.547,0.776,1.377
			c0,0.775-0.228,1.367-0.755,1.367c-0.3,0-0.538-0.115-0.787-0.311c-0.197-0.156-0.31-0.23-0.414-0.23
			c-0.104,0-0.195,0.094-0.259,0.188c0.383,0.393,0.828,0.736,1.449,0.736c0.673,0,1.294-0.633,1.294-1.75
			C30.184,56.059,29.553,55.45,28.92,55.45"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M27.451,55.43h-0.467c-0.03,0-0.051-0.023-0.062-0.043l-0.146-0.443
			c-0.01-0.031-0.021-0.031-0.03,0l-0.146,0.443c-0.01,0.031-0.031,0.043-0.052,0.043h-0.466c-0.021,0-0.03,0.008-0.01,0.02
			l0.384,0.279c0.02,0.02,0.02,0.053,0.009,0.072l-0.145,0.434c-0.01,0.023-0.01,0.043,0.021,0.023l0.383-0.27
			c0.031-0.021,0.052-0.021,0.083,0l0.373,0.27c0.021,0.02,0.031,0.01,0.021-0.012l-0.145-0.445c-0.011-0.029,0-0.063,0.021-0.072
			l0.381-0.279C27.481,55.438,27.471,55.43,27.451,55.43"
          />
          <path
            clipPath="url(#SVGID_4_)"
            fill="#FFFFFF"
            d="M27.099,58.493c-0.196-0.496-0.352-0.838-0.352-1.252
			c0-0.176,0.052-0.332,0.083-0.414c0.042-0.082,0.052-0.135,0.01-0.135c-0.063,0-0.196,0.145-0.279,0.27
			c-0.063,0.104-0.135,0.301-0.135,0.455c0,0.289,0.093,0.602,0.155,0.787c0.114,0.32,0.218,0.693,0.218,1.045
			c0,0.561-0.249,0.943-0.693,1.283c0.04,0.033,0.145,0.084,0.27,0.084c0.434,0,0.952-0.486,0.952-1.15
			C27.327,59.073,27.213,58.803,27.099,58.493"
          />

          <line
            clipPath="url(#SVGID_4_)"
            fill="none"
            stroke="#F68E1E"
            strokeWidth="0.899"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="19.73"
            y1="29.306"
            x2="22.006"
            y2="30.544"
          />

          <line
            clipPath="url(#SVGID_4_)"
            fill="none"
            stroke="#F68E1E"
            strokeWidth="0.899"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="24.132"
            y1="25.907"
            x2="24.865"
            y2="28.392"
          />

          <line
            clipPath="url(#SVGID_4_)"
            fill="none"
            stroke="#F68E1E"
            strokeWidth="0.899"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="33.784"
            y1="29.306"
            x2="31.509"
            y2="30.544"
          />

          <line
            clipPath="url(#SVGID_4_)"
            fill="none"
            stroke="#F68E1E"
            strokeWidth="0.899"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="29.383"
            y1="25.907"
            x2="28.649"
            y2="28.392"
          />
        </g>
      </g>
    </Base>
  );
}

export default TakeAction;
