// import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/button';
import { Heading, Paragraph } from 'components/typography';

import ErrorPage from './ErrorPage';
import { Helmet } from 'react-helmet-async';

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>

      <ErrorPage errorCode={404}>
        <div>
          <Heading size={1}>Oops!</Heading>
          <Heading size={2} color="warning">
            Where are we?
          </Heading>

          <Paragraph>
            The page you are looking for, has been moved, removed, renamed or
            may never have existed.
          </Paragraph>

          <br />
          <Button color="warning" onClick={() => navigate('/')}>
            Go to Home page
          </Button>
        </div>
      </ErrorPage>
    </>
  );
}

export default PageNotFound;
