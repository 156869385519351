import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from 'react';
// import { useLiveQuery } from 'dexie-react-hooks';

import { CRUD, GuidesThemesAreas as GTA } from '../constants';
import guidesThemesData from 'data/guide-themes.json';
import notImplemented from 'utilities/notImplemented';
import { getCollection, setCollection } from 'utilities/firebase';
import idb, {
  cacheExistsAndNotExpired,
  Entry,
  expiryDate
} from 'utilities/idb';
import AuthContext from './Auth';
import { randomString } from 'utilities/string';
import { toast } from 'react-toastify';
import {
  Awards,
  AwardTarget,
  CompletedTask,
  DatabaseState,
  DispatchAction,
  GuidesTheme,
  UnitState
} from 'types';

enum Actions {
  SET_LOADING,
  SET_USER_PROFILE,
  SET_UNITS,
  SET_MY_UNIT,
  SET_AWARD_PROGRESS,
  SET_AWARD_TARGETS,
  SET_GUIDES_THEMES,
  SET_ERROR,
  SET_STATE
}

const baseApiUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:5001/go-for-guides/us-central1/api'
    : 'https://us-central1-go-for-guides.cloudfunctions.net/api';

const initialState: DatabaseState = {
  loading: false,
  awardProgress: [],
  awardTargets: null,
  guidesThemes: guidesThemesData as unknown as GuidesTheme[],
  units: null,
  myUnit: null,
  error: null,

  updateAwardProgress: notImplemented('updateAwardProgress'),
  getAwards: notImplemented('getAwards')
};

export const Context = createContext(initialState);

function reducer(state = initialState, action: DispatchAction) {
  switch (action.type) {
    case Actions.SET_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case Actions.SET_UNITS:
      return {
        ...state,
        units: action.payload,
        loading: false,
        error: null
      };
    case Actions.SET_MY_UNIT:
      return {
        ...state,
        myUnit: action.payload,
        loading: false,
        error: null
      };
    case Actions.SET_AWARD_PROGRESS:
      return {
        ...state,
        awardProgress: action.payload,
        loading: false,
        error: null
      };
    case Actions.SET_AWARD_TARGETS:
      return {
        ...state,
        awardTargets: action.payload,
        loading: false,
        error: null
      };
    case Actions.SET_GUIDES_THEMES:
      return {
        ...state,
        guidesThemes: action.payload,
        loading: false,
        error: null
      };
    case Actions.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
}

export function Provider(props: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, token } = useContext(AuthContext);
  const { children } = props;

  const updateAwardProgress = (
    selectedItems: CompletedTask[],
    action: CRUD
  ) => {
    if (!user) return;
    let newAwardProgress: CompletedTask[] = [...state.awardProgress];

    switch (action) {
      case CRUD.CREATE:
        newAwardProgress.push(...selectedItems);
        break;
      case CRUD.DELETE:
        newAwardProgress = newAwardProgress.filter(
          (item) =>
            !selectedItems.find(
              (selected) =>
                selected.task === item.task &&
                selected.theme === item.theme &&
                selected.program === item.program
            )
        );
        break;
      default:
        break;
    }

    setCollection(`awardProgress/${user.uid}`, newAwardProgress, () => {
      const toastAction = action === CRUD.DELETE ? 'deleted' : 'completed';
      toast.success(
        `You have ${toastAction}, ${
          selectedItems.length
        } ${selectedItems[0].program
          .replace(' Activities', '')
          .toLowerCase()} activitie(s)`
      );
    });

    dispatch({ type: Actions.SET_AWARD_PROGRESS, payload: newAwardProgress });

    idb.awardProgress.where({ id: user.uid }).delete();
    if (newAwardProgress.length)
      idb.awardProgress.add({
        id: user.uid,
        value: newAwardProgress,
        createdOn: new Date().valueOf(),
        expiresOn: expiryDate(30)
      });
  };

  const getAwardProgress = useCallback(async () => {
    try {
      if (!user) return;

      const setProgress = (progress: CompletedTask[]) =>
        dispatch({ type: Actions.SET_AWARD_PROGRESS, payload: progress });

      const setProgressThenCache = (progress: CompletedTask[]) => {
        const newProgress = progress || [];

        setProgress(newProgress);
        idb.awardProgress.where({ id: user.uid }).delete();
        if (newProgress.length)
          idb.awardProgress.add({
            id: user.uid,
            value: newProgress,
            createdOn: new Date().valueOf(),
            expiresOn: expiryDate(30)
          });
      };

      const cachedData: Entry<CompletedTask[]> | undefined = (
        await idb.awardProgress.toArray()
      ).find((item: Entry<CompletedTask[]>) => item.id === user.uid);

      if (cachedData?.value && cacheExistsAndNotExpired(cachedData))
        setProgress(cachedData.value);

      getCollection<CompletedTask[]>(
        `awardProgress/${user.uid}`,
        setProgressThenCache
      );
    } catch (error) {
      dispatch({
        type: Actions.SET_ERROR,
        payload: error
      });
    }
  }, [user]);

  const getAwards = useCallback(
    (
      getOverallProgress: (
        guidesTheme: GuidesTheme,
        activityType: GTA
      ) => number
    ) => {
      const { awardProgress, guidesThemes } = state as DatabaseState;
      const awards: Awards = { bronze: [], silver: [], gold: [] };
      const completedThemes = guidesThemes
        .reduce((acc, theme) => {
          const progress = awardProgress.filter(
            (item) => item.theme === theme.name
          );

          const lastCompletedDate = progress
            .sort((a, b) => {
              if (a.completedAt === undefined || b.completedAt === undefined)
                return 0;

              return new Date(b.completedAt) > new Date(a.completedAt) ? 1 : -1;
            })
            .reduce(
              (acc, item, i) =>
                acc === '' && item.completedAt !== undefined
                  ? item.completedAt
                  : acc,
              ''
            );

          const interestBadgesProgress = getOverallProgress(
            theme,
            GTA.InterestBadges
          );
          const skillsBuildersProgress = getOverallProgress(
            theme,
            GTA.SkillsBuilders
          );
          const umaProgress = getOverallProgress(
            theme,
            GTA.UnitMeetingActivities
          );

          const overallProgress =
            (interestBadgesProgress + skillsBuildersProgress + umaProgress) / 3;

          if (overallProgress === 100)
            acc.push({ name: theme.name, completedAt: lastCompletedDate });
          return acc;
        }, [] as Record<string, string>[])
        .sort((a, b) =>
          new Date(b.completedAt) > new Date(a.completedAt) ? -1 : 1
        );

      completedThemes.forEach((theme) => {
        if (awards.bronze.length < 2) awards.bronze.push(theme.name);
        else if (awards.silver.length < 2) awards.silver.push(theme.name);
        else if (awards.gold.length < 2) awards.gold.push(theme.name);
      });
      // console.log(awards, completedThemes);
      return awards;
    },
    [state.awardProgress, state.awardTargets, state.guidesThemes]
  );

  const getMyUnit = async (units: Record<string, UnitState>) => {
    const newMyUnit = user?.unitId && units ? units[user.unitId] : null;
    if (newMyUnit !== null && user !== null) {
      // https://go4api2.herokuapp.com
      // http://localhost:5000
      const url = `${baseApiUrl}/v1/users/getByUnit/${user.unitId}`;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };

      newMyUnit.members = await fetch(url, { headers })
        .then((response) => response.json())
        .then((data) => data || [])
        .catch((error) => {
          console.error(error);
          return [];
        });
    }

    dispatch({
      type: Actions.SET_MY_UNIT,
      payload: newMyUnit
    });
  };

  const getUnits = useCallback(async () => {
    try {
      const setUnit = (units: Record<string, UnitState>) =>
        dispatch({
          type: Actions.SET_UNITS,
          payload: units
        });

      const setUnitThenCache = (units: Record<string, UnitState>) => {
        setUnit(units);
        const bulkData = Object.keys(units).map(
          (key) =>
            ({
              id: key,
              value: units[key],
              createdOn: new Date().valueOf(),
              expiresOn: expiryDate(30)
            } as Entry<UnitState>)
        );

        idb.units.clear();
        idb.units.bulkAdd(bulkData);
      };

      const cachedData: Entry<UnitState>[] = await idb.units.toArray();
      const isValid: boolean =
        cachedData.length > 0 &&
        !cachedData
          .map((item) => cacheExistsAndNotExpired(item))
          .includes(false);

      if (isValid) {
        const units = cachedData.reduce(
          (acc, item) => ({ ...acc, [item.id]: item.value }),
          {}
        );

        setUnit(units);
        return;
      }

      getCollection<Record<string, UnitState>>('/units/', setUnitThenCache);
    } catch (error) {
      dispatch({
        type: Actions.SET_ERROR,
        payload: error
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAwardTargets = useCallback(async () => {
    try {
      const setAwardTargets = (payload: AwardTarget) =>
        dispatch({ type: Actions.SET_AWARD_TARGETS, payload });

      const setAwardTargetsThenCache = (targets: AwardTarget) => {
        setAwardTargets(targets);
        idb.awardTargets.clear();
        idb.awardTargets.add({
          id: randomString(),
          createdOn: new Date().valueOf(),
          expiresOn: expiryDate(30),
          value: targets
        });
      };

      dispatch({ type: Actions.SET_LOADING });
      const cachedData: Entry<AwardTarget> | undefined = (
        await idb.awardTargets.toArray()
      ).find((_entry: Entry<AwardTarget>, i: number) => i === 0);

      const newTargets = cacheExistsAndNotExpired<AwardTarget>(cachedData)
        ? cachedData?.value || null
        : null;

      if (newTargets) {
        setAwardTargets(newTargets);
        return;
      }

      getCollection<AwardTarget>('/awardTargets/', setAwardTargetsThenCache);
    } catch (error) {
      dispatch({
        type: Actions.SET_ERROR,
        payload: error
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMyUnit(state.units);
  }, [user, state.units]);

  useEffect(() => {
    getAwardProgress();
  }, [getAwardProgress]);

  useEffect(() => {
    getUnits();
  }, [getUnits]);

  useEffect(() => {
    getAwardTargets();
  }, [getAwardTargets]);

  return (
    <Context.Provider
      value={{
        ...state,
        updateAwardProgress,
        getAwards
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
