import { HTMLProps, PropsWithChildren } from 'react';
import { TextAlign, TextCase } from '../../constants';

export type BaseTypographyProps = {
  as?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'span'
    | 'small'
    | 'strong'
    | 'em'
    | 'i';
  align?: TextAlign;
  color?: string;
  case?: TextCase;
};

type TypographyProps = HTMLProps<any> &
  PropsWithChildren<any & BaseTypographyProps>;

function Typography(props: TypographyProps): JSX.Element {
  const {
    children,
    className,
    as,
    align,
    color,
    case: textCase,
    ...otherProps
  } = props;

  const Tag: string = as || 'span';
  let newClassName = '';

  if (color) newClassName += ` text-${color}`;
  if (align) newClassName += ` text-${align}`;
  if (textCase) newClassName += ` text-${textCase}`;
  if (className) newClassName += ` ${className}`;

  return (
    <Tag {...otherProps} className={newClassName}>
      {children}
    </Tag>
  );
}

export const Small = (props: TypographyProps) => (
  <Typography {...props} as="small" />
);

export default Typography;
