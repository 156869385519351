import React from 'react';
import BaseIcon, { UIIconProps } from './BaseIcon';

function Person(props: UIIconProps) {
  const { filled, ...otherProps } = props;
  return (
    <BaseIcon {...otherProps}>
      {filled ? (
        <>
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        </>
      ) : null}

      {!filled ? (
        <>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
        </>
      ) : null}
    </BaseIcon>
  );
}

export default Person;
