// #startregion LiveSmart Groups
const LiveSmartStage5 = () => (
  <g id="Stage_5_10_">
    <polygon
      fill="#E9CAD8"
      points="57.579,19.714 57.579,50.566 30.866,65.99 4.154,50.566 4.154,19.714 30.866,4.287 			"
    />
    <polygon fill="#F5ACCC" points="57.578,50.566 30.868,65.99 30.868,35.14 			" />
    <polygon
      fill="#F5ACCC"
      points="57.578,19.715 57.578,50.566 30.868,35.14 			"
    />
    <polygon
      fill="#F5ACCC"
      points="30.868,4.283 57.578,19.708 30.868,35.135 			"
    />
    <polygon
      fill="#F5ACCC"
      points="30.865,65.992 4.154,50.566 30.865,35.143 			"
    />
    <polygon fill="#F5ACCC" points="30.868,35.14 4.157,50.566 4.157,19.715 			" />
    <g>
      <path
        fill="#AB6A92"
        d="M30.866,4.6l26.44,15.269v30.538l-26.44,15.27L4.427,50.406V19.868L30.866,4.6 M30.866,0.023
					c-0.572,0-1.144,0.149-1.657,0.444L1.679,16.366c-1.026,0.593-1.658,1.687-1.658,2.873v31.798c0,1.186,0.631,2.277,1.658,2.873
					l27.53,15.896c0.513,0.297,1.085,0.445,1.657,0.445c0.574,0,1.146-0.148,1.659-0.445L60.054,53.91
					c1.028-0.596,1.659-1.688,1.659-2.873V19.239c0-1.186-0.631-2.28-1.659-2.873L32.525,0.468
					C32.012,0.172,31.44,0.023,30.866,0.023"
      />
      <polygon
        fill="#7B2860"
        points="17.742,33.682 14.536,33.682 14.536,29.342 15.604,29.342 15.604,32.748 17.742,32.748 				"
      />
      <path
        fill="#7B2860"
        d="M19.445,29.472c0,0.33-0.251,0.575-0.603,0.575c-0.349,0-0.594-0.245-0.594-0.575
					c0-0.318,0.246-0.59,0.594-0.59C19.194,28.881,19.445,29.154,19.445,29.472 M18.325,30.431h1.036v3.251h-1.036V30.431z"
      />
      <polygon
        fill="#7B2860"
        points="22.172,33.682 21.118,33.682 19.77,30.424 20.865,30.424 21.647,32.432 22.425,30.424
					23.52,30.424 				"
      />
      <path
        fill="#7B2860"
        d="M26.946,32.322h-2.384c0.085,0.388,0.351,0.627,0.7,0.627c0.239,0,0.519-0.058,0.693-0.374l0.918,0.187
					c-0.278,0.685-0.868,1.015-1.612,1.015c-0.939,0-1.717-0.693-1.717-1.722c0-1.021,0.778-1.722,1.722-1.722
					c0.92,0,1.659,0.66,1.678,1.722V32.322z M24.583,31.686h1.34c-0.097-0.349-0.356-0.509-0.655-0.509
					C24.978,31.177,24.679,31.356,24.583,31.686"
      />
      <path
        fill="#7B2860"
        d="M29.648,32.625c0.044,0.28,0.296,0.382,0.576,0.382c0.272,0,0.421-0.115,0.421-0.259
					c0-0.11-0.084-0.192-0.324-0.239l-0.662-0.129c-0.602-0.11-0.945-0.435-0.945-0.944c0-0.668,0.577-1.103,1.399-1.103
					c0.81,0,1.347,0.368,1.477,0.932l-0.945,0.187c-0.033-0.2-0.228-0.379-0.545-0.379c-0.278,0-0.368,0.134-0.368,0.25
					c0,0.091,0.039,0.182,0.246,0.228l0.764,0.154c0.616,0.13,0.901,0.499,0.901,0.98c0,0.718-0.622,1.095-1.477,1.095
					c-0.764,0-1.418-0.28-1.528-0.946L29.648,32.625z"
      />
      <path
        fill="#7B2860"
        d="M37.562,31.648v2.034h-1.029v-1.813c0-0.369-0.239-0.603-0.545-0.603c-0.381,0-0.634,0.278-0.634,0.823
					v1.594h-1.036v-1.813c0-0.369-0.239-0.603-0.551-0.603c-0.375,0-0.62,0.278-0.62,0.823v1.594H32.11V30.43h1.037v0.3
					c0.219-0.246,0.537-0.396,0.957-0.396c0.454,0,0.843,0.194,1.061,0.55c0.261-0.337,0.649-0.55,1.155-0.55
					C37.013,30.333,37.562,30.857,37.562,31.648"
      />
      <path
        fill="#7B2860"
        d="M41.623,33.682h-1.036v-0.259c-0.251,0.226-0.59,0.354-1.01,0.354c-0.862,0-1.587-0.69-1.587-1.722
					c0-1.024,0.725-1.722,1.587-1.722c0.42,0,0.759,0.129,1.01,0.356v-0.259h1.036V33.682z M40.587,32.055
					c0-0.498-0.375-0.839-0.796-0.839c-0.454,0-0.784,0.341-0.784,0.839c0,0.506,0.33,0.842,0.784,0.842
					C40.212,32.897,40.587,32.561,40.587,32.055"
      />
      <path
        fill="#7B2860"
        d="M44.7,30.412l-0.046,1.035h-0.188c-0.726,0-1.12,0.377-1.12,1.219v1.016H42.31V30.43h1.037v0.624
					c0.234-0.39,0.589-0.668,1.12-0.668C44.558,30.385,44.621,30.391,44.7,30.412"
      />
      <path
        fill="#7B2860"
        d="M47.55,33.541c-0.252,0.135-0.518,0.236-0.887,0.236c-0.809,0-1.296-0.44-1.296-1.293v-1.23h-0.59
					v-0.823h0.59v-0.959h1.036v0.959h0.958v0.823h-0.958v1.126c0,0.338,0.162,0.473,0.435,0.473c0.142,0,0.336-0.052,0.474-0.129
					L47.55,33.541z"
      />
      <path
        fill="#AB6A92"
        d="M20.062,37.748l-1.469,0.298c-0.109-0.569-0.581-0.851-1.062-0.851c-0.498,0-0.816,0.272-0.816,0.616
					c0,0.245,0.109,0.427,0.49,0.51l1.261,0.28c1.051,0.237,1.668,0.806,1.668,1.77c0,1.296-1.152,1.968-2.503,1.968
					c-1.371,0-2.494-0.652-2.657-1.842l1.542-0.3c0.154,0.628,0.589,0.897,1.196,0.897c0.563,0,0.898-0.254,0.898-0.624
					c0-0.273-0.126-0.443-0.561-0.538l-1.271-0.271c-0.871-0.198-1.631-0.652-1.631-1.759c0-1.18,0.978-1.915,2.403-1.915
					C18.873,35.987,19.834,36.643,20.062,37.748"
      />
      <path
        fill="#AB6A92"
        d="M24.133,42.003c-0.354,0.189-0.725,0.336-1.243,0.336c-1.133,0-1.814-0.616-1.814-1.814v-1.726h-0.825
					v-1.149h0.825v-1.343h1.45v1.343h1.344v1.149h-1.344v1.58c0,0.472,0.229,0.66,0.608,0.66c0.201,0,0.474-0.07,0.663-0.179
					L24.133,42.003z"
      />
      <path
        fill="#AB6A92"
        d="M29.202,42.203h-1.451v-0.366c-0.354,0.32-0.824,0.502-1.414,0.502c-1.207,0-2.223-0.972-2.223-2.414
					c0-1.434,1.017-2.413,2.223-2.413c0.59,0,1.06,0.182,1.414,0.501v-0.363h1.451V42.203z M27.751,39.925
					c0-0.698-0.526-1.178-1.115-1.178c-0.635,0-1.099,0.479-1.099,1.178c0,0.708,0.463,1.181,1.099,1.181
					C27.225,41.105,27.751,40.633,27.751,39.925"
      />
      <path
        fill="#AB6A92"
        d="M34.762,41.747c0,1.409-1.07,2.18-2.549,2.18c-1.043,0-1.84-0.364-2.286-1.099l1.207-0.651
					c0.345,0.406,0.598,0.561,1.07,0.561c0.662,0,1.133-0.391,1.133-1.106v-0.126c-0.326,0.28-0.78,0.435-1.369,0.435
					c-1.188,0-2.196-0.944-2.196-2.232c0-1.263,1.008-2.195,2.196-2.195c0.589,0,1.042,0.154,1.369,0.435v-0.3h1.425V41.747z
					 M33.337,39.707c0-0.607-0.48-1.034-1.061-1.034c-0.634,0-1.087,0.427-1.087,1.034c0,0.609,0.454,1.044,1.087,1.044
					C32.857,40.751,33.337,40.316,33.337,39.707"
      />
      <path
        fill="#AB6A92"
        d="M40.104,40.297h-3.339c0.118,0.544,0.491,0.88,0.979,0.88c0.335,0,0.725-0.082,0.97-0.525l1.289,0.262
					c-0.392,0.963-1.215,1.426-2.259,1.426c-1.315,0-2.403-0.972-2.403-2.414c0-1.434,1.088-2.413,2.412-2.413
					c1.288,0,2.323,0.928,2.351,2.413V40.297z M36.792,39.407h1.877c-0.136-0.489-0.498-0.718-0.916-0.718
					C37.345,38.689,36.928,38.945,36.792,39.407"
      />
      <path
        fill="#AB6A92"
        d="M44.854,38.255c1.342,0,2.141,0.771,2.141,1.962c0,1.25-0.908,2.122-2.304,2.122
					c-1.251,0-2.313-0.719-2.35-2.086l1.416-0.28c0.008,0.786,0.462,1.095,0.961,1.095c0.516,0,0.87-0.336,0.87-0.834
					c0-0.454-0.345-0.754-0.87-0.754h-2.086l0.108-3.354h3.99v1.296h-2.685l-0.036,0.834H44.854z"
      />
      <path
        fill="#7B2860"
        d="M36.835,20.736c0,0.227-0.183,0.41-0.41,0.41h-0.848c-0.226,0-0.409-0.183-0.409-0.41v-0.638h-0.655
					l-0.628,1.003c-0.116,0.186-0.321,0.298-0.541,0.298h-4.861c-0.218,0-0.422-0.112-0.539-0.298l-0.63-1.003h-0.748v0.638
					c0,0.227-0.183,0.41-0.41,0.41h-0.847c-0.226,0-0.41-0.183-0.41-0.41v-0.638h-0.89l0.392,4.45
					c0.033,0.378,0.351,0.668,0.732,0.67l11.459,0.007c0.38,0,0.697-0.291,0.73-0.67l0.4-4.457h-0.889V20.736z"
      />
      <path
        fill="#7B2860"
        d="M23.345,17.62l0.216,1.861c0.011,0.093,0.088,0.161,0.181,0.161h1.157v-0.846
					c0-0.225,0.184-0.408,0.41-0.408h0.847c0.226,0,0.41,0.183,0.41,0.408v0.846h0.898c0.063,0,0.121,0.032,0.155,0.084l0.708,1.133
					c0.033,0.052,0.09,0.085,0.156,0.085h4.86c0.063,0,0.121-0.033,0.155-0.085l0.708-1.133c0.034-0.052,0.093-0.084,0.154-0.084
					h0.807v-0.846c0-0.225,0.184-0.408,0.409-0.408h0.848c0.227,0,0.41,0.183,0.41,0.408v0.846h1.158
					c0.091,0,0.168-0.068,0.18-0.161l0.218-1.861H23.345z M32.727,20.255h-3.719c-0.103,0-0.186-0.084-0.186-0.187
					c0-0.104,0.083-0.188,0.186-0.188h3.719c0.104,0,0.186,0.084,0.186,0.188C32.913,20.171,32.831,20.255,32.727,20.255
					 M32.727,19.358h-3.719c-0.103,0-0.186-0.084-0.186-0.188c0-0.103,0.083-0.187,0.186-0.187h3.719
					c0.104,0,0.186,0.084,0.186,0.187C32.913,19.274,32.831,19.358,32.727,19.358"
      />
      <rect x="25.354" y="18.843" fill="#7B2860" width="0.756" height="1.848" />
      <rect x="35.623" y="18.843" fill="#7B2860" width="0.757" height="1.848" />
      <path
        fill="#7B2860"
        d="M27.61,15.557h-3.084c-0.297,0-0.565,0.179-0.68,0.453l-0.476,1.154h14.993l-0.478-1.154
					c-0.114-0.274-0.383-0.453-0.679-0.453h-3.084v-0.455v-0.743c0-0.429-0.349-0.778-0.776-0.778h-0.639
					c-0.05-0.125-0.172-0.213-0.315-0.213h-3.051c-0.146,0-0.268,0.088-0.318,0.213h-0.638c-0.428,0-0.776,0.349-0.776,0.778v0.743
					V15.557z M28.253,14.359c0-0.074,0.061-0.134,0.134-0.134H29v0.022c0,0.19,0.153,0.343,0.343,0.343h3.051
					c0.188,0,0.341-0.153,0.341-0.343v-0.022h0.613c0.073,0,0.135,0.06,0.135,0.134v0.743v0.455h-5.229v-0.455V14.359z"
      />
      <path
        fill="#4F86C6"
        d="M30.866,59.559c-2.637,0-4.772-2.135-4.772-4.771c0-2.64,2.136-4.775,4.772-4.775
					c2.639,0,4.776,2.136,4.776,4.775C35.642,57.424,33.505,59.559,30.866,59.559"
      />
      <path
        fill="#FFFFFF"
        d="M30.866,50.013c2.639,0,4.776,2.136,4.776,4.775c0,2.636-2.137,4.771-4.776,4.771
					c-2.638,0-4.772-2.135-4.772-4.771C26.093,52.148,28.228,50.013,30.866,50.013 M30.866,49.601c-2.857,0-5.184,2.328-5.184,5.188
					c0,2.858,2.327,5.184,5.184,5.184c2.861,0,5.187-2.325,5.187-5.184C36.053,51.929,33.727,49.601,30.866,49.601"
      />
      <path
        fill="#FFFFFF"
        d="M33.36,52.842c-0.238,0-0.463,0.108-0.619,0.251l-0.014-0.011c0.073-0.122,0.169-0.348,0.169-0.719
					c0-0.883-0.885-1.549-2.029-1.549c-1.146,0-2.042,0.666-2.042,1.549c0,0.371,0.119,0.597,0.179,0.719l-0.013,0.011
					c-0.154-0.143-0.381-0.251-0.619-0.251c-0.729,0-1.457,0.702-1.457,2.017c0,1.288,0.717,2.018,1.493,2.018
					c0.716,0,1.218-0.396,1.669-0.848c-0.071-0.106-0.18-0.218-0.298-0.218c-0.118,0-0.252,0.086-0.477,0.266
					c-0.298,0.228-0.562,0.36-0.908,0.36c-0.609,0-0.869-0.683-0.869-1.578c0-0.954,0.25-1.588,0.895-1.588
					c0.285,0,0.476,0.084,0.667,0.262c0.286,0.265,0.381,0.334,0.512,0.334c0.096,0,0.157-0.058,0.18-0.094
					c-0.214-0.215-0.524-0.67-0.524-1.252c0-0.67,0.561-1.137,1.611-1.137c1.048,0,1.61,0.467,1.61,1.137
					c0,0.582-0.311,1.037-0.525,1.252c0.023,0.036,0.083,0.094,0.18,0.094c0.131,0,0.227-0.069,0.512-0.334
					c0.179-0.178,0.382-0.262,0.667-0.262c0.646,0,0.896,0.634,0.896,1.588c0,0.896-0.262,1.578-0.87,1.578
					c-0.346,0-0.621-0.133-0.908-0.36c-0.227-0.18-0.358-0.266-0.477-0.266c-0.12,0-0.228,0.111-0.298,0.218
					c0.441,0.452,0.954,0.848,1.67,0.848c0.774,0,1.491-0.729,1.491-2.018C34.816,53.544,34.09,52.842,33.36,52.842"
      />
      <path
        fill="#FFFFFF"
        d="M31.667,52.816h-0.538c-0.034,0-0.06-0.024-0.07-0.046l-0.169-0.512c-0.011-0.038-0.021-0.038-0.034,0
					l-0.167,0.512c-0.013,0.036-0.037,0.046-0.061,0.046h-0.536c-0.024,0-0.036,0.012-0.013,0.025l0.441,0.322
					c0.024,0.022,0.024,0.061,0.011,0.082l-0.166,0.502c-0.012,0.024-0.012,0.049,0.024,0.024l0.441-0.309
					c0.037-0.027,0.058-0.027,0.095,0l0.43,0.309c0.023,0.024,0.035,0.011,0.023-0.012l-0.166-0.515
					c-0.011-0.035,0-0.071,0.024-0.082l0.44-0.322C31.703,52.828,31.69,52.816,31.667,52.816"
      />
      <path
        fill="#FFFFFF"
        d="M31.261,56.351c-0.228-0.572-0.406-0.969-0.406-1.444c0-0.204,0.059-0.384,0.096-0.477
					c0.047-0.096,0.06-0.157,0.011-0.157c-0.072,0-0.228,0.168-0.322,0.312c-0.073,0.118-0.156,0.347-0.156,0.522
					c0,0.336,0.107,0.693,0.18,0.908c0.131,0.372,0.25,0.802,0.25,1.208c0,0.644-0.286,1.084-0.798,1.478
					c0.048,0.036,0.166,0.097,0.31,0.097c0.5,0,1.098-0.561,1.098-1.326C31.523,57.017,31.392,56.708,31.261,56.351"
      />
    </g>
  </g>
);

const LiveSmartStage4 = () => (
  <g id="Stage_4_10_">
    <polygon
      fill="#E9CAD8"
      points="57.579,19.713 57.579,50.564 30.868,65.99 4.154,50.564 4.154,19.713 30.868,4.287 			"
    />
    <polygon
      fill="#F5ACCC"
      points="57.578,50.564 30.868,65.99 30.868,35.141 			"
    />
    <polygon
      fill="#F5ACCC"
      points="57.578,19.715 57.578,50.564 30.868,35.141 			"
    />
    <polygon
      fill="#F5ACCC"
      points="30.868,4.283 57.578,19.708 30.868,35.132 			"
    />
    <polygon fill="#F5ACCC" points="30.865,65.99 4.156,50.566 30.865,35.141 			" />
    <g>
      <path
        fill="#AB6A92"
        d="M30.868,4.6l26.437,15.268v30.54L30.868,65.674L4.428,50.407v-30.54L30.868,4.6 M30.868,0.023
					c-0.574,0-1.147,0.148-1.66,0.444L1.68,16.366c-1.026,0.593-1.66,1.688-1.66,2.873v31.795c0,1.187,0.634,2.281,1.66,2.873
					l27.527,15.899c0.513,0.295,1.086,0.443,1.66,0.443c0.571,0,1.144-0.148,1.658-0.443l27.528-15.899
					c1.027-0.592,1.659-1.687,1.659-2.873V19.239c0-1.185-0.632-2.28-1.659-2.873L32.525,0.468
					C32.012,0.172,31.439,0.023,30.868,0.023"
      />
      <polygon
        fill="#7B2860"
        points="17.742,33.682 14.536,33.682 14.536,29.342 15.604,29.342 15.604,32.749 17.742,32.749 				"
      />
      <path
        fill="#7B2860"
        d="M19.446,29.471c0,0.33-0.253,0.575-0.603,0.575c-0.349,0-0.595-0.245-0.595-0.575
					c0-0.318,0.246-0.59,0.595-0.59C19.193,28.881,19.446,29.153,19.446,29.471 M18.325,30.43h1.036v3.251h-1.036V30.43z"
      />
      <polygon
        fill="#7B2860"
        points="22.173,33.682 21.118,33.682 19.77,30.423 20.865,30.423 21.648,32.431 22.426,30.423
					23.52,30.423 				"
      />
      <path
        fill="#7B2860"
        d="M26.946,32.322h-2.382c0.084,0.388,0.349,0.627,0.699,0.627c0.24,0,0.519-0.059,0.693-0.376l0.919,0.189
					c-0.278,0.685-0.868,1.016-1.612,1.016c-0.939,0-1.716-0.694-1.716-1.723c0-1.022,0.777-1.723,1.723-1.723
					c0.919,0,1.658,0.66,1.677,1.723V32.322z M24.583,31.686h1.34c-0.098-0.349-0.356-0.51-0.654-0.51
					C24.977,31.176,24.679,31.354,24.583,31.686"
      />
      <path
        fill="#7B2860"
        d="M29.647,32.625c0.045,0.279,0.296,0.383,0.576,0.383c0.272,0,0.421-0.116,0.421-0.259
					c0-0.11-0.083-0.194-0.322-0.241l-0.662-0.128c-0.604-0.11-0.945-0.434-0.945-0.945c0-0.667,0.576-1.102,1.398-1.102
					c0.811,0,1.347,0.369,1.477,0.933l-0.946,0.187c-0.033-0.2-0.225-0.381-0.544-0.381c-0.278,0-0.369,0.135-0.369,0.252
					c0,0.09,0.039,0.182,0.247,0.227l0.763,0.156c0.617,0.13,0.901,0.498,0.901,0.979c0,0.718-0.621,1.095-1.476,1.095
					c-0.766,0-1.418-0.279-1.528-0.947L29.647,32.625z"
      />
      <path
        fill="#7B2860"
        d="M37.562,31.647v2.034h-1.029v-1.813c0-0.37-0.239-0.603-0.544-0.603c-0.382,0-0.635,0.278-0.635,0.823
					v1.593h-1.035v-1.813c0-0.37-0.24-0.603-0.552-0.603c-0.374,0-0.621,0.278-0.621,0.823v1.593h-1.037v-3.253h1.037v0.3
					c0.22-0.246,0.537-0.396,0.957-0.396c0.454,0,0.843,0.194,1.064,0.55c0.258-0.337,0.646-0.55,1.151-0.55
					C37.012,30.333,37.562,30.856,37.562,31.647"
      />
      <path
        fill="#7B2860"
        d="M41.623,33.682h-1.036v-0.26c-0.251,0.227-0.588,0.356-1.009,0.356c-0.861,0-1.588-0.692-1.588-1.723
					c0-1.024,0.727-1.723,1.588-1.723c0.421,0,0.758,0.129,1.009,0.356V30.43h1.036V33.682z M40.587,32.055
					c0-0.498-0.375-0.841-0.796-0.841c-0.454,0-0.785,0.343-0.785,0.841c0,0.505,0.331,0.842,0.785,0.842
					C40.212,32.897,40.587,32.56,40.587,32.055"
      />
      <path
        fill="#7B2860"
        d="M44.699,30.411l-0.045,1.036h-0.187c-0.727,0-1.121,0.375-1.121,1.218v1.017h-1.036v-3.253h1.036v0.623
					c0.233-0.389,0.59-0.667,1.121-0.667C44.557,30.385,44.621,30.391,44.699,30.411"
      />
      <path
        fill="#7B2860"
        d="M47.55,33.538c-0.251,0.137-0.518,0.24-0.887,0.24c-0.81,0-1.295-0.441-1.295-1.295v-1.231h-0.589V30.43
					h0.589v-0.959h1.036v0.959h0.957v0.822h-0.957v1.127c0,0.337,0.162,0.473,0.433,0.473c0.145,0,0.338-0.051,0.473-0.13
					L47.55,33.538z"
      />
      <path
        fill="#AB6A92"
        d="M19.834,37.746l-1.469,0.301c-0.109-0.573-0.58-0.854-1.062-0.854c-0.498,0-0.816,0.272-0.816,0.619
					c0,0.242,0.108,0.424,0.49,0.507l1.261,0.28c1.051,0.236,1.669,0.809,1.669,1.77c0,1.299-1.152,1.967-2.504,1.967
					c-1.37,0-2.494-0.651-2.658-1.841l1.542-0.297c0.154,0.625,0.589,0.897,1.197,0.897c0.563,0,0.898-0.257,0.898-0.628
					c0-0.27-0.127-0.442-0.562-0.533l-1.271-0.272C15.68,39.461,14.92,39.01,14.92,37.9c0-1.178,0.978-1.915,2.403-1.915
					C18.646,35.985,19.607,36.641,19.834,37.746"
      />
      <path
        fill="#AB6A92"
        d="M23.906,42c-0.355,0.193-0.725,0.336-1.243,0.336c-1.134,0-1.814-0.616-1.814-1.813v-1.722h-0.826
					v-1.153h0.826v-1.343h1.451v1.343h1.342v1.153h-1.342v1.576c0,0.474,0.227,0.663,0.608,0.663c0.2,0,0.471-0.074,0.662-0.181
					L23.906,42z"
      />
      <path
        fill="#AB6A92"
        d="M28.976,42.201h-1.452v-0.363c-0.353,0.316-0.825,0.498-1.414,0.498c-1.208,0-2.221-0.968-2.221-2.413
					c0-1.434,1.014-2.41,2.221-2.41c0.589,0,1.061,0.179,1.414,0.498v-0.363h1.452V42.201z M27.523,39.923
					c0-0.696-0.525-1.178-1.115-1.178c-0.634,0-1.097,0.481-1.097,1.178c0,0.707,0.462,1.181,1.097,1.181
					C26.999,41.104,27.523,40.63,27.523,39.923"
      />
      <path
        fill="#AB6A92"
        d="M34.533,41.748c0,1.405-1.068,2.176-2.549,2.176c-1.042,0-1.84-0.362-2.285-1.098l1.206-0.651
					c0.345,0.406,0.599,0.561,1.071,0.561c0.663,0,1.133-0.388,1.133-1.105v-0.128c-0.326,0.281-0.78,0.436-1.369,0.436
					c-1.188,0-2.194-0.941-2.194-2.231c0-1.261,1.007-2.193,2.194-2.193c0.589,0,1.043,0.153,1.369,0.432v-0.297h1.424V41.748z
					 M33.109,39.706c0-0.608-0.48-1.035-1.061-1.035c-0.635,0-1.087,0.427-1.087,1.035s0.453,1.042,1.087,1.042
					C32.629,40.748,33.109,40.314,33.109,39.706"
      />
      <path
        fill="#AB6A92"
        d="M39.874,40.298h-3.337c0.119,0.544,0.49,0.877,0.979,0.877c0.337,0,0.727-0.079,0.972-0.525l1.286,0.265
					c-0.389,0.96-1.215,1.422-2.258,1.422c-1.314,0-2.403-0.968-2.403-2.41c0-1.434,1.089-2.413,2.412-2.413
					c1.288,0,2.321,0.924,2.349,2.413V40.298z M36.565,39.408h1.876c-0.137-0.492-0.498-0.718-0.916-0.718
					C37.117,38.69,36.7,38.943,36.565,39.408"
      />
      <path
        fill="#AB6A92"
        d="M46.568,41.085v1.116h-1.406v-1.116h-3.157v-1.344l2.712-3.618h1.852v3.765h0.733v1.197H46.568z
					 M45.162,37.564l-1.643,2.323h1.643V37.564z"
      />
      <path
        fill="#7B2860"
        d="M36.835,20.736c0,0.227-0.184,0.41-0.41,0.41h-0.847c-0.226,0-0.41-0.183-0.41-0.41v-0.638h-0.655
					L33.886,21.1c-0.116,0.186-0.321,0.299-0.542,0.299h-4.859c-0.221,0-0.424-0.113-0.541-0.299l-0.63-1.002h-0.745v0.638
					c0,0.227-0.185,0.41-0.41,0.41h-0.847c-0.227,0-0.41-0.183-0.41-0.41v-0.638h-0.891l0.392,4.45
					c0.034,0.378,0.351,0.668,0.731,0.67l11.46,0.007c0.38,0,0.697-0.291,0.731-0.67l0.398-4.456h-0.888V20.736z"
      />
      <path
        fill="#7B2860"
        d="M23.343,17.619l0.219,1.862c0.011,0.092,0.087,0.161,0.18,0.161h1.157v-0.846
					c0-0.226,0.184-0.408,0.41-0.408h0.847c0.227,0,0.41,0.183,0.41,0.408v0.846h0.899c0.063,0,0.121,0.032,0.155,0.083l0.708,1.132
					c0.033,0.053,0.092,0.085,0.156,0.085h4.859c0.063,0,0.121-0.033,0.153-0.085l0.712-1.132c0.033-0.052,0.092-0.083,0.154-0.083
					h0.805v-0.846c0-0.226,0.185-0.408,0.409-0.408h0.848c0.226,0,0.41,0.183,0.41,0.408v0.846h1.156
					c0.094,0,0.171-0.068,0.183-0.161l0.217-1.862H23.343z M32.726,20.254h-3.717c-0.103,0-0.188-0.084-0.188-0.188
					c0-0.103,0.085-0.187,0.188-0.187h3.717c0.103,0,0.188,0.084,0.188,0.187C32.914,20.17,32.829,20.254,32.726,20.254
					 M32.726,19.357h-3.717c-0.103,0-0.188-0.084-0.188-0.187c0-0.104,0.085-0.187,0.188-0.187h3.717
					c0.103,0,0.188,0.083,0.188,0.187C32.914,19.273,32.829,19.357,32.726,19.357"
      />
      <rect x="25.356" y="18.843" fill="#7B2860" width="0.755" height="1.848" />
      <rect x="35.624" y="18.843" fill="#7B2860" width="0.756" height="1.848" />
      <path
        fill="#7B2860"
        d="M27.611,15.557h-3.084c-0.297,0-0.565,0.179-0.68,0.453l-0.476,1.154h14.993l-0.478-1.154
					c-0.114-0.273-0.382-0.453-0.68-0.453h-3.084v-0.456v-0.743c0-0.429-0.346-0.778-0.775-0.778H32.71
					c-0.051-0.125-0.173-0.213-0.318-0.213h-3.05c-0.145,0-0.266,0.088-0.318,0.213h-0.636c-0.429,0-0.777,0.349-0.777,0.778v0.743
					V15.557z M28.253,14.359c0-0.074,0.061-0.133,0.135-0.133h0.611v0.021c0,0.19,0.153,0.343,0.343,0.343h3.05
					c0.19,0,0.343-0.153,0.343-0.343v-0.021h0.613c0.073,0,0.134,0.059,0.134,0.133v0.743v0.456h-5.229v-0.456V14.359z"
      />
      <path
        fill="#4F86C6"
        d="M30.868,59.56c-2.639,0-4.774-2.136-4.774-4.774c0-2.636,2.136-4.774,4.774-4.774
					c2.636,0,4.773,2.139,4.773,4.774C35.641,57.424,33.504,59.56,30.868,59.56"
      />
      <path
        fill="#FFFFFF"
        d="M30.868,50.011c2.636,0,4.773,2.139,4.773,4.774c0,2.639-2.137,4.774-4.773,4.774
					c-2.639,0-4.774-2.136-4.774-4.774C26.093,52.149,28.229,50.011,30.868,50.011 M30.868,49.601c-2.86,0-5.186,2.326-5.186,5.185
					c0,2.859,2.326,5.187,5.186,5.187c2.858,0,5.184-2.327,5.184-5.187C36.052,51.927,33.726,49.601,30.868,49.601"
      />
      <path
        fill="#FFFFFF"
        d="M33.36,52.84c-0.238,0-0.464,0.107-0.619,0.25l-0.015-0.011c0.074-0.121,0.17-0.347,0.17-0.716
					c0-0.883-0.885-1.552-2.029-1.552s-2.042,0.669-2.042,1.552c0,0.369,0.12,0.595,0.179,0.716l-0.013,0.011
					c-0.154-0.143-0.38-0.25-0.618-0.25c-0.729,0-1.458,0.705-1.458,2.017c0,1.291,0.716,2.017,1.494,2.017
					c0.715,0,1.218-0.393,1.67-0.848c-0.073-0.106-0.181-0.214-0.299-0.214c-0.12,0-0.252,0.086-0.478,0.261
					c-0.299,0.229-0.562,0.361-0.908,0.361c-0.608,0-0.87-0.683-0.87-1.577c0-0.954,0.25-1.584,0.896-1.584
					c0.287,0,0.476,0.081,0.667,0.261c0.287,0.262,0.381,0.333,0.514,0.333c0.095,0,0.155-0.061,0.179-0.094
					c-0.215-0.218-0.524-0.669-0.524-1.255c0-0.668,0.559-1.134,1.61-1.134c1.05,0,1.61,0.466,1.61,1.134
					c0,0.586-0.31,1.037-0.524,1.255c0.022,0.033,0.083,0.094,0.177,0.094c0.133,0,0.228-0.071,0.514-0.333
					c0.179-0.18,0.381-0.261,0.668-0.261c0.645,0,0.894,0.63,0.894,1.584c0,0.895-0.262,1.577-0.869,1.577
					c-0.346,0-0.621-0.132-0.908-0.361c-0.228-0.175-0.357-0.261-0.476-0.261c-0.121,0-0.228,0.107-0.3,0.214
					c0.443,0.455,0.955,0.848,1.671,0.848c0.775,0,1.492-0.726,1.492-2.017C34.817,53.545,34.089,52.84,33.36,52.84"
      />
      <path
        fill="#FFFFFF"
        d="M31.667,52.817h-0.538c-0.036,0-0.058-0.024-0.07-0.049l-0.167-0.513c-0.012-0.035-0.023-0.035-0.036,0
					l-0.167,0.513c-0.012,0.036-0.035,0.049-0.059,0.049h-0.537c-0.025,0-0.036,0.012-0.012,0.022l0.441,0.322
					c0.023,0.024,0.023,0.06,0.012,0.086l-0.167,0.5c-0.01,0.022-0.01,0.047,0.023,0.022l0.444-0.309
					c0.035-0.024,0.059-0.024,0.094,0l0.43,0.309c0.022,0.024,0.035,0.013,0.022-0.012l-0.166-0.511
					c-0.012-0.037,0-0.073,0.025-0.086l0.44-0.322C31.702,52.829,31.69,52.817,31.667,52.817"
      />
      <path
        fill="#FFFFFF"
        d="M31.261,56.349c-0.226-0.573-0.406-0.966-0.406-1.441c0-0.205,0.059-0.383,0.095-0.479
					c0.048-0.097,0.061-0.157,0.011-0.157c-0.07,0-0.227,0.169-0.32,0.312c-0.074,0.12-0.156,0.346-0.156,0.524
					c0,0.337,0.107,0.693,0.179,0.909c0.133,0.368,0.25,0.798,0.25,1.205c0,0.646-0.285,1.084-0.799,1.479
					c0.049,0.038,0.167,0.096,0.312,0.096c0.499,0,1.098-0.562,1.098-1.324C31.524,57.018,31.392,56.706,31.261,56.349"
      />
    </g>
  </g>
);

const LiveSmartStage3 = () => (
  <g id="Stage_3_10_">
    <polygon
      fill="#E9CAD8"
      points="57.579,19.703 57.579,50.539 30.868,65.954 4.157,50.539 4.157,19.703 30.868,4.285 			"
    />
    <polygon
      fill="#F5ACCC"
      points="57.578,50.539 30.869,65.954 30.869,35.122 			"
    />
    <polygon
      fill="#F5ACCC"
      points="57.578,19.705 57.578,50.539 30.869,35.122 			"
    />
    <polygon
      fill="#F5ACCC"
      points="30.869,4.281 57.578,19.698 30.869,35.115 			"
    />
    <g>
      <path
        fill="#AB6A92"
        d="M30.869,4.598l26.438,15.26V50.38L30.869,65.641L4.43,50.38V19.857L30.869,4.598 M30.869,0.023
					c-0.574,0-1.146,0.148-1.66,0.444L1.682,16.358c-1.027,0.592-1.659,1.686-1.659,2.871v31.78c0,1.185,0.631,2.279,1.659,2.87
					l27.526,15.892c0.515,0.295,1.086,0.443,1.66,0.443c0.573,0,1.145-0.148,1.658-0.443l27.528-15.892
					c1.025-0.591,1.658-1.686,1.658-2.87v-31.78c0-1.185-0.633-2.279-1.658-2.871L32.526,0.468
					C32.013,0.172,31.441,0.023,30.869,0.023"
      />
      <polygon
        fill="#7B2860"
        points="17.744,33.664 14.539,33.664 14.539,29.327 15.606,29.327 15.606,32.731 17.744,32.731 				"
      />
      <path
        fill="#7B2860"
        d="M19.448,29.456c0,0.33-0.253,0.575-0.603,0.575c-0.35,0-0.596-0.245-0.596-0.575
					c0-0.317,0.246-0.59,0.596-0.59C19.195,28.866,19.448,29.139,19.448,29.456 M18.327,30.414h1.036v3.25h-1.036V30.414z"
      />
      <polygon
        fill="#7B2860"
        points="22.175,33.664 21.119,33.664 19.771,30.408 20.867,30.408 21.65,32.414 22.427,30.408
					23.521,30.408 				"
      />
      <path
        fill="#7B2860"
        d="M26.947,32.305h-2.383c0.084,0.388,0.351,0.627,0.7,0.627c0.239,0,0.518-0.06,0.691-0.375l0.92,0.188
					c-0.278,0.686-0.868,1.016-1.612,1.016c-0.94,0-1.717-0.694-1.717-1.723c0-1.021,0.777-1.721,1.723-1.721
					c0.919,0,1.658,0.66,1.677,1.721V32.305z M24.584,31.669h1.34c-0.098-0.349-0.356-0.51-0.653-0.51
					C24.979,31.16,24.681,31.339,24.584,31.669"
      />
      <path
        fill="#7B2860"
        d="M29.649,32.608c0.045,0.279,0.298,0.382,0.576,0.382c0.272,0,0.421-0.115,0.421-0.259
					c0-0.11-0.084-0.193-0.323-0.241l-0.661-0.128c-0.602-0.11-0.945-0.434-0.945-0.944c0-0.667,0.576-1.101,1.399-1.101
					c0.81,0,1.346,0.368,1.476,0.932l-0.945,0.187c-0.032-0.201-0.227-0.381-0.544-0.381c-0.278,0-0.369,0.135-0.369,0.252
					c0,0.091,0.038,0.181,0.246,0.227l0.764,0.154c0.616,0.13,0.901,0.499,0.901,0.978c0,0.718-0.622,1.095-1.476,1.095
					c-0.765,0-1.418-0.278-1.528-0.947L29.649,32.608z"
      />
      <path
        fill="#7B2860"
        d="M37.563,31.631v2.033h-1.029v-1.813c0-0.37-0.239-0.602-0.545-0.602c-0.382,0-0.634,0.278-0.634,0.822
					v1.593h-1.036v-1.813c0-0.37-0.238-0.602-0.55-0.602c-0.376,0-0.621,0.278-0.621,0.822v1.593h-1.036v-3.251h1.036v0.3
					c0.219-0.247,0.537-0.396,0.956-0.396c0.454,0,0.844,0.193,1.063,0.549c0.26-0.337,0.648-0.549,1.154-0.549
					C37.014,30.317,37.563,30.84,37.563,31.631"
      />
      <path
        fill="#7B2860"
        d="M41.624,33.664h-1.036v-0.261c-0.252,0.228-0.589,0.357-1.01,0.357c-0.862,0-1.585-0.692-1.585-1.723
					c0-1.022,0.723-1.721,1.585-1.721c0.421,0,0.758,0.128,1.01,0.355v-0.259h1.036V33.664z M40.588,32.038
					c0-0.497-0.375-0.84-0.796-0.84c-0.454,0-0.784,0.343-0.784,0.84c0,0.505,0.33,0.842,0.784,0.842
					C40.213,32.88,40.588,32.543,40.588,32.038"
      />
      <path
        fill="#7B2860"
        d="M44.701,30.395l-0.046,1.036h-0.187c-0.728,0-1.121,0.375-1.121,1.217v1.017h-1.036v-3.251h1.036v0.623
					c0.233-0.389,0.59-0.667,1.121-0.667C44.56,30.369,44.624,30.375,44.701,30.395"
      />
      <path
        fill="#7B2860"
        d="M47.551,33.522c-0.251,0.135-0.519,0.238-0.887,0.238c-0.809,0-1.295-0.44-1.295-1.293v-1.231h-0.59
					v-0.822h0.59v-0.958h1.036v0.958h0.957v0.822h-0.957v1.126c0,0.337,0.162,0.472,0.434,0.472c0.143,0,0.336-0.051,0.473-0.129
					L47.551,33.522z"
      />
      <path
        fill="#AB6A92"
        d="M20.059,37.728l-1.469,0.299c-0.11-0.571-0.582-0.852-1.062-0.852c-0.499,0-0.815,0.272-0.815,0.617
					c0,0.244,0.107,0.426,0.488,0.508l1.261,0.28c1.052,0.237,1.669,0.808,1.669,1.769c0,1.297-1.152,1.967-2.504,1.967
					c-1.367,0-2.492-0.652-2.658-1.84l1.542-0.3c0.154,0.626,0.59,0.897,1.198,0.897c0.561,0,0.898-0.255,0.898-0.625
					c0-0.272-0.128-0.443-0.563-0.536l-1.27-0.271c-0.871-0.2-1.633-0.652-1.633-1.76c0-1.178,0.98-1.913,2.403-1.913
					C18.871,35.968,19.832,36.622,20.059,37.728"
      />
      <path
        fill="#AB6A92"
        d="M24.129,41.979c-0.354,0.19-0.725,0.336-1.242,0.336c-1.133,0-1.813-0.617-1.813-1.813V38.78h-0.826
					v-1.151h0.826v-1.343h1.449v1.343h1.343v1.151h-1.343v1.577c0,0.471,0.227,0.661,0.609,0.661c0.198,0,0.471-0.073,0.662-0.181
					L24.129,41.979z"
      />
      <path
        fill="#AB6A92"
        d="M29.199,42.181h-1.451v-0.364c-0.354,0.316-0.825,0.499-1.415,0.499c-1.207,0-2.223-0.97-2.223-2.412
					c0-1.434,1.016-2.411,2.223-2.411c0.589,0,1.061,0.181,1.415,0.5v-0.363h1.451V42.181z M27.748,39.903
					c0-0.697-0.527-1.177-1.116-1.177c-0.635,0-1.098,0.479-1.098,1.177c0,0.707,0.462,1.18,1.098,1.18
					C27.221,41.083,27.748,40.61,27.748,39.903"
      />
      <path
        fill="#AB6A92"
        d="M34.757,41.725c0,1.407-1.069,2.177-2.547,2.177c-1.044,0-1.842-0.362-2.286-1.095l1.205-0.653
					c0.345,0.406,0.6,0.562,1.07,0.562c0.663,0,1.135-0.39,1.135-1.106v-0.127c-0.326,0.281-0.78,0.436-1.369,0.436
					c-1.188,0-2.196-0.943-2.196-2.231c0-1.261,1.007-2.193,2.196-2.193c0.589,0,1.042,0.154,1.369,0.435v-0.3h1.423V41.725z
					 M33.334,39.686c0-0.607-0.482-1.034-1.062-1.034c-0.634,0-1.088,0.427-1.088,1.034c0,0.608,0.454,1.042,1.088,1.042
					C32.852,40.728,33.334,40.294,33.334,39.686"
      />
      <path
        fill="#AB6A92"
        d="M40.099,40.276h-3.337c0.117,0.544,0.489,0.878,0.978,0.878c0.337,0,0.727-0.081,0.971-0.525
					l1.288,0.263c-0.389,0.962-1.215,1.424-2.259,1.424c-1.314,0-2.403-0.97-2.403-2.411c0-1.434,1.089-2.412,2.413-2.412
					c1.288,0,2.321,0.926,2.35,2.412V40.276z M36.787,39.388h1.878c-0.136-0.49-0.499-0.719-0.916-0.719
					C37.341,38.669,36.923,38.925,36.787,39.388"
      />
      <path
        fill="#AB6A92"
        d="M46.972,40.357c0,1.151-0.934,1.958-2.269,1.958c-1.277,0-2.211-0.743-2.348-1.923l1.414-0.262
					c0.081,0.597,0.408,0.942,0.943,0.942c0.508,0,0.871-0.29,0.871-0.734c0-0.398-0.3-0.635-0.825-0.635h-0.817v-1.189h0.754
					c0.518,0,0.815-0.225,0.815-0.614c0-0.417-0.316-0.697-0.808-0.697c-0.487,0-0.853,0.299-0.906,0.979l-1.368-0.264
					c0.136-1.205,1.033-1.949,2.267-1.949c1.297,0,2.177,0.797,2.177,1.914c0,0.553-0.236,0.979-0.654,1.223
					C46.699,39.352,46.972,39.785,46.972,40.357"
      />
      <path
        fill="#7B2860"
        d="M36.836,20.725c0,0.227-0.183,0.41-0.41,0.41h-0.847c-0.227,0-0.411-0.183-0.411-0.41v-0.638h-0.654
					l-0.629,1.002c-0.115,0.185-0.319,0.298-0.54,0.298h-4.861c-0.219,0-0.422-0.113-0.539-0.298l-0.63-1.002h-0.747v0.638
					c0,0.227-0.183,0.41-0.41,0.41h-0.847c-0.226,0-0.41-0.183-0.41-0.41v-0.638h-0.89l0.392,4.447
					c0.033,0.378,0.351,0.668,0.731,0.669l11.459,0.007c0.379,0,0.697-0.29,0.731-0.669l0.397-4.454h-0.887V20.725z"
      />
      <path
        fill="#7B2860"
        d="M23.346,17.61l0.216,1.86c0.011,0.093,0.088,0.161,0.182,0.161h1.158v-0.846
					c0-0.225,0.183-0.408,0.409-0.408h0.849c0.224,0,0.408,0.183,0.408,0.408v0.846h0.898c0.063,0,0.122,0.032,0.156,0.083
					l0.708,1.132c0.035,0.052,0.092,0.085,0.154,0.085h4.861c0.063,0,0.121-0.034,0.155-0.085l0.71-1.132
					c0.032-0.052,0.09-0.083,0.154-0.083h0.804v-0.846c0-0.225,0.185-0.408,0.41-0.408h0.848c0.226,0,0.41,0.183,0.41,0.408v0.846
					h1.158c0.092,0,0.169-0.068,0.18-0.161l0.218-1.86H23.346z M32.727,20.244H29.01c-0.104,0-0.188-0.084-0.188-0.187
					c0-0.104,0.084-0.188,0.188-0.188h3.717c0.104,0,0.188,0.084,0.188,0.188C32.916,20.16,32.831,20.244,32.727,20.244
					 M32.727,19.348H29.01c-0.104,0-0.188-0.084-0.188-0.188c0-0.104,0.084-0.187,0.188-0.187h3.717
					c0.104,0,0.188,0.083,0.188,0.187C32.916,19.263,32.831,19.348,32.727,19.348"
      />
      <rect x="25.357" y="18.833" fill="#7B2860" width="0.755" height="1.846" />
      <rect x="35.624" y="18.833" fill="#7B2860" width="0.756" height="1.846" />
      <path
        fill="#7B2860"
        d="M27.612,15.549h-3.084c-0.297,0-0.565,0.178-0.68,0.452l-0.476,1.154h14.993l-0.478-1.154
					c-0.114-0.274-0.383-0.452-0.68-0.452h-3.084v-0.456v-0.742c0-0.429-0.347-0.777-0.775-0.777h-0.638
					c-0.052-0.125-0.174-0.213-0.317-0.213h-3.05c-0.145,0-0.268,0.088-0.318,0.213h-0.637c-0.429,0-0.777,0.348-0.777,0.777v0.742
					V15.549z M28.254,14.352c0-0.074,0.061-0.134,0.135-0.134h0.612v0.022c0,0.19,0.152,0.342,0.343,0.342h3.05
					c0.189,0,0.341-0.152,0.341-0.342v-0.022h0.614c0.073,0,0.133,0.06,0.133,0.134v0.742v0.456h-5.228v-0.456V14.352z"
      />
      <path
        fill="#4F86C6"
        d="M30.869,59.528c-2.638,0-4.774-2.134-4.774-4.771s2.136-4.772,4.774-4.772
					c2.637,0,4.773,2.136,4.773,4.772S33.506,59.528,30.869,59.528"
      />
      <path
        fill="#FFFFFF"
        d="M30.869,49.985c2.637,0,4.773,2.136,4.773,4.772s-2.136,4.771-4.773,4.771
					c-2.638,0-4.774-2.134-4.774-4.771S28.23,49.985,30.869,49.985 M30.869,49.575c-2.859,0-5.186,2.324-5.186,5.183
					c0,2.856,2.326,5.183,5.186,5.183c2.859,0,5.185-2.326,5.185-5.183C36.054,51.899,33.728,49.575,30.869,49.575"
      />
      <path
        fill="#FFFFFF"
        d="M33.361,52.813c-0.238,0-0.464,0.108-0.62,0.25l-0.013-0.011c0.072-0.12,0.168-0.346,0.168-0.717
					c0-0.882-0.884-1.55-2.028-1.55c-1.144,0-2.041,0.668-2.041,1.55c0,0.371,0.12,0.597,0.179,0.717l-0.011,0.011
					c-0.156-0.142-0.382-0.25-0.621-0.25c-0.729,0-1.457,0.703-1.457,2.017c0,1.288,0.717,2.016,1.493,2.016
					c0.715,0,1.217-0.395,1.669-0.848c-0.072-0.106-0.18-0.215-0.298-0.215s-0.252,0.085-0.478,0.264
					c-0.298,0.228-0.561,0.359-0.908,0.359c-0.608,0-0.869-0.683-0.869-1.576c0-0.955,0.25-1.586,0.896-1.586
					c0.286,0,0.476,0.083,0.667,0.261c0.286,0.263,0.381,0.333,0.513,0.333c0.095,0,0.155-0.059,0.179-0.094
					c-0.215-0.214-0.524-0.668-0.524-1.252c0-0.668,0.56-1.135,1.61-1.135c1.05,0,1.609,0.467,1.609,1.135
					c0,0.584-0.309,1.038-0.522,1.252c0.022,0.035,0.083,0.094,0.177,0.094c0.132,0,0.227-0.07,0.513-0.333
					c0.18-0.178,0.383-0.261,0.669-0.261c0.646,0,0.895,0.631,0.895,1.586c0,0.894-0.264,1.576-0.87,1.576
					c-0.347,0-0.622-0.132-0.907-0.359c-0.227-0.179-0.358-0.264-0.476-0.264c-0.121,0-0.229,0.108-0.3,0.215
					c0.441,0.453,0.954,0.848,1.671,0.848c0.774,0,1.49-0.728,1.49-2.016C34.817,53.516,34.091,52.813,33.361,52.813"
      />
      <path
        fill="#FFFFFF"
        d="M31.668,52.789H31.13c-0.036,0-0.058-0.024-0.07-0.048l-0.168-0.511c-0.012-0.037-0.023-0.037-0.036,0
					l-0.167,0.511c-0.014,0.036-0.036,0.048-0.061,0.048h-0.538c-0.023,0-0.034,0.012-0.012,0.023l0.442,0.322
					c0.023,0.024,0.023,0.062,0.012,0.084l-0.167,0.5c-0.011,0.023-0.011,0.048,0.025,0.023l0.441-0.308
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.308c0.023,0.024,0.036,0.012,0.023-0.012l-0.167-0.512
					c-0.013-0.035,0-0.071,0.024-0.084l0.44-0.322C31.704,52.801,31.691,52.789,31.668,52.789"
      />
      <path
        fill="#FFFFFF"
        d="M31.263,56.32c-0.227-0.573-0.406-0.968-0.406-1.442c0-0.203,0.06-0.384,0.095-0.478
					c0.048-0.097,0.06-0.157,0.011-0.157c-0.07,0-0.226,0.168-0.321,0.311c-0.071,0.121-0.155,0.348-0.155,0.524
					c0,0.336,0.107,0.692,0.179,0.907c0.132,0.371,0.251,0.8,0.251,1.206c0,0.646-0.287,1.084-0.8,1.477
					c0.047,0.037,0.168,0.097,0.311,0.097c0.5,0,1.096-0.562,1.096-1.324C31.523,56.987,31.393,56.677,31.263,56.32"
      />
    </g>
  </g>
);
// #endregion

// #startregion Lead Groups
const LeadStage5 = () => (
  <g id="Stage_5_11_">
    <polygon
      fill="#E9CAD8"
      points="57.579,19.714 57.579,50.565 30.866,65.99 4.155,50.565 4.155,19.714 30.866,4.288 			"
    />
    <polygon
      fill="#F5ACCC"
      points="57.578,50.565 30.868,65.99 30.868,35.141 			"
    />
    <polygon
      fill="#F5ACCC"
      points="57.578,19.715 57.578,50.565 30.868,35.141 			"
    />
    <polygon
      fill="#F5ACCC"
      points="30.868,4.283 57.578,19.708 30.868,35.135 			"
    />
    <polygon
      fill="#F5ACCC"
      points="30.865,65.992 4.155,50.566 30.865,35.142 			"
    />
    <polygon fill="#F5ACCC" points="30.868,35.141 4.158,50.565 4.158,19.715 			" />
    <g>
      <path
        fill="#AB6A92"
        d="M30.868,4.601l26.437,15.268v30.538L30.868,65.676L4.428,50.407V19.869L30.868,4.601 M30.868,0.023
					c-0.574,0-1.146,0.149-1.659,0.446L1.68,16.366c-1.027,0.593-1.659,1.688-1.659,2.873v31.796c0,1.188,0.632,2.28,1.659,2.873
					l27.529,15.898c0.513,0.296,1.085,0.444,1.659,0.444c0.572,0,1.145-0.148,1.657-0.444l27.53-15.898
					c1.026-0.593,1.658-1.686,1.658-2.873V19.239c0-1.185-0.632-2.28-1.658-2.873L32.525,0.469
					C32.012,0.172,31.44,0.023,30.868,0.023"
      />
      <polygon
        fill="#7B2860"
        points="26.609,33.682 23.404,33.682 23.404,29.342 24.471,29.342 24.471,32.749 26.609,32.749 				"
      />
      <path
        fill="#7B2860"
        d="M30.329,32.322h-2.385c0.085,0.388,0.351,0.627,0.7,0.627c0.239,0,0.519-0.058,0.692-0.375l0.919,0.188
					c-0.278,0.685-0.868,1.017-1.612,1.017c-0.938,0-1.715-0.695-1.715-1.724c0-1.023,0.777-1.723,1.722-1.723
					c0.919,0,1.657,0.66,1.678,1.723V32.322z M27.964,31.687h1.34c-0.096-0.349-0.356-0.512-0.653-0.512
					C28.358,31.175,28.06,31.356,27.964,31.687"
      />
      <path
        fill="#7B2860"
        d="M34.22,33.682h-1.036v-0.26c-0.251,0.229-0.59,0.356-1.009,0.356c-0.862,0-1.588-0.692-1.588-1.723
					c0-1.022,0.726-1.723,1.588-1.723c0.419,0,0.758,0.129,1.009,0.356V30.43h1.036V33.682z M33.184,32.056
					c0-0.498-0.375-0.842-0.796-0.842c-0.455,0-0.785,0.344-0.785,0.842c0,0.505,0.33,0.843,0.785,0.843
					C32.808,32.898,33.184,32.561,33.184,32.056"
      />
      <path
        fill="#7B2860"
        d="M38.327,33.682H37.29v-0.26c-0.251,0.229-0.591,0.356-1.01,0.356c-0.862,0-1.588-0.692-1.588-1.723
					c0-1.022,0.726-1.723,1.588-1.723c0.419,0,0.759,0.129,1.01,0.356v-1.672h1.037V33.682z M37.29,32.056
					c0-0.498-0.376-0.842-0.795-0.842c-0.454,0-0.784,0.344-0.784,0.842c0,0.505,0.33,0.843,0.784,0.843
					C36.914,32.898,37.29,32.561,37.29,32.056"
      />
      <path
        fill="#AB6A92"
        d="M20.061,37.747l-1.469,0.301c-0.108-0.573-0.581-0.854-1.062-0.854c-0.498,0-0.816,0.272-0.816,0.617
					c0,0.244,0.109,0.426,0.49,0.509l1.261,0.28c1.051,0.235,1.668,0.808,1.668,1.77c0,1.297-1.151,1.969-2.503,1.969
					c-1.37,0-2.494-0.653-2.658-1.843l1.542-0.299c0.154,0.625,0.589,0.897,1.197,0.897c0.563,0,0.898-0.254,0.898-0.625
					c0-0.272-0.126-0.445-0.561-0.536l-1.271-0.272c-0.871-0.199-1.632-0.651-1.632-1.76c0-1.179,0.979-1.914,2.404-1.914
					C18.873,35.987,19.834,36.642,20.061,37.747"
      />
      <path
        fill="#AB6A92"
        d="M24.133,42.002c-0.354,0.191-0.725,0.337-1.242,0.337c-1.134,0-1.814-0.618-1.814-1.814V38.8h-0.825
					v-1.152h0.825v-1.342h1.45v1.342h1.344V38.8h-1.344v1.578c0,0.473,0.228,0.663,0.608,0.663c0.201,0,0.473-0.073,0.665-0.182
					L24.133,42.002z"
      />
      <path
        fill="#AB6A92"
        d="M29.202,42.201H27.75v-0.363c-0.354,0.317-0.824,0.499-1.414,0.499c-1.206,0-2.223-0.97-2.223-2.412
					c0-1.433,1.017-2.413,2.223-2.413c0.59,0,1.061,0.181,1.414,0.5v-0.363h1.452V42.201z M27.75,39.925
					c0-0.698-0.525-1.18-1.114-1.18c-0.635,0-1.099,0.481-1.099,1.18c0,0.706,0.463,1.18,1.099,1.18
					C27.225,41.104,27.75,40.631,27.75,39.925"
      />
      <path
        fill="#AB6A92"
        d="M34.762,41.747c0,1.408-1.069,2.178-2.549,2.178c-1.042,0-1.84-0.363-2.287-1.097l1.208-0.655
					c0.345,0.409,0.6,0.564,1.069,0.564c0.664,0,1.134-0.391,1.134-1.106v-0.129c-0.326,0.282-0.78,0.437-1.368,0.437
					c-1.189,0-2.197-0.943-2.197-2.231c0-1.262,1.008-2.195,2.197-2.195c0.588,0,1.042,0.155,1.368,0.436v-0.3h1.425V41.747z
					 M33.337,39.707c0-0.608-0.481-1.035-1.061-1.035c-0.634,0-1.087,0.427-1.087,1.035c0,0.607,0.454,1.044,1.087,1.044
					C32.856,40.751,33.337,40.314,33.337,39.707"
      />
      <path
        fill="#AB6A92"
        d="M40.104,40.297h-3.339c0.118,0.544,0.491,0.879,0.979,0.879c0.336,0,0.726-0.081,0.97-0.524l1.29,0.262
					c-0.391,0.963-1.216,1.424-2.26,1.424c-1.314,0-2.402-0.97-2.402-2.411c0-1.434,1.088-2.414,2.413-2.414
					c1.287,0,2.322,0.926,2.35,2.414V40.297z M36.792,39.408h1.878c-0.136-0.491-0.499-0.718-0.916-0.718
					C37.345,38.69,36.928,38.944,36.792,39.408"
      />
      <path
        fill="#AB6A92"
        d="M44.854,38.256c1.342,0,2.141,0.771,2.141,1.961c0,1.25-0.908,2.12-2.304,2.12
					c-1.252,0-2.312-0.717-2.35-2.085l1.415-0.283c0.009,0.79,0.463,1.099,0.962,1.099c0.517,0,0.869-0.336,0.869-0.833
					c0-0.455-0.344-0.755-0.869-0.755h-2.087l0.11-3.355h3.989v1.298h-2.685l-0.036,0.834H44.854z"
      />
      <path
        fill="#7B2860"
        d="M35.162,13.445l-2.589,1.495l1.83,3.172c0.014,0.022,0.022,0.045,0.031,0.069
					c0.067,0.076,0.079,0.186,0.017,0.271l-2.009,2.825l5.43-3.137c0.146-0.085,0.147-0.294,0.004-0.381l-2.301-1.381
					c-0.021-0.013-0.034-0.037-0.034-0.063l-0.047-2.683C35.49,13.464,35.309,13.361,35.162,13.445"
      />
      <polygon
        fill="#7B2860"
        points="33.509,19.02 31.273,20.311 31.862,21.332 				"
      />
      <path
        fill="#7B2860"
        d="M33.937,18.181l-2.525-4.375c-0.082-0.141-0.263-0.188-0.402-0.107l-4.02,2.319l2.71,4.695l4.209-2.43
					C33.944,18.261,33.956,18.217,33.937,18.181"
      />
      <path
        fill="#7B2860"
        d="M26.631,16.272c-0.147,0.176-0.322,0.328-0.524,0.445c-0.204,0.118-0.421,0.189-0.638,0.229l5.383,9.324
					c0.081,0.14,0.259,0.188,0.4,0.107l0.646-0.373c0.14-0.082,0.188-0.262,0.107-0.402L26.631,16.272z"
      />
      <path
        fill="#7B2860"
        d="M26.585,15.426c0.104-0.387,0.05-0.791-0.15-1.138c-0.201-0.347-0.524-0.594-0.911-0.699
					c-0.128-0.035-0.26-0.051-0.391-0.051c-0.26,0-0.516,0.069-0.747,0.201c-0.716,0.414-0.961,1.332-0.547,2.049
					c0.413,0.716,1.332,0.961,2.047,0.549C26.233,16.136,26.481,15.814,26.585,15.426 M24.58,15.36
					c0.061,0.106,0.025,0.239-0.081,0.3c-0.035,0.021-0.073,0.029-0.11,0.029c-0.076,0-0.148-0.039-0.19-0.109
					c-0.298-0.518-0.121-1.182,0.396-1.48c0.106-0.06,0.239-0.025,0.3,0.08c0.062,0.105,0.025,0.241-0.08,0.3
					C24.507,14.659,24.403,15.052,24.58,15.36"
      />
      <path
        fill="#4F86C6"
        d="M30.868,59.56c-2.639,0-4.774-2.137-4.774-4.773c0-2.638,2.136-4.773,4.774-4.773
					c2.637,0,4.773,2.136,4.773,4.773C35.641,57.423,33.505,59.56,30.868,59.56"
      />
      <path
        fill="#FFFFFF"
        d="M30.868,50.013c2.637,0,4.773,2.136,4.773,4.773s-2.136,4.773-4.773,4.773
					c-2.639,0-4.774-2.136-4.774-4.773S28.229,50.013,30.868,50.013 M30.868,49.601c-2.86,0-5.186,2.325-5.186,5.186
					c0,2.859,2.326,5.186,5.186,5.186s5.185-2.326,5.185-5.186C36.053,51.926,33.728,49.601,30.868,49.601"
      />
      <path
        fill="#FFFFFF"
        d="M33.359,52.841c-0.237,0-0.463,0.108-0.619,0.251l-0.014-0.012c0.073-0.119,0.169-0.347,0.169-0.717
					c0-0.883-0.884-1.552-2.028-1.552c-1.146,0-2.042,0.669-2.042,1.552c0,0.37,0.12,0.598,0.178,0.717l-0.011,0.012
					c-0.154-0.143-0.381-0.251-0.619-0.251c-0.729,0-1.457,0.704-1.457,2.017c0,1.29,0.717,2.018,1.493,2.018
					c0.715,0,1.217-0.395,1.67-0.848c-0.072-0.106-0.181-0.216-0.298-0.216c-0.119,0-0.252,0.086-0.476,0.264
					c-0.301,0.228-0.563,0.358-0.909,0.358c-0.608,0-0.87-0.681-0.87-1.576c0-0.955,0.25-1.588,0.896-1.588
					c0.285,0,0.477,0.085,0.667,0.264c0.286,0.263,0.382,0.334,0.512,0.334c0.096,0,0.157-0.061,0.181-0.095
					c-0.215-0.216-0.524-0.669-0.524-1.254c0-0.668,0.56-1.133,1.611-1.133c1.049,0,1.61,0.465,1.61,1.133
					c0,0.585-0.311,1.038-0.525,1.254c0.024,0.034,0.084,0.095,0.181,0.095c0.13,0,0.226-0.071,0.512-0.334
					c0.179-0.179,0.382-0.264,0.667-0.264c0.645,0,0.896,0.633,0.896,1.588c0,0.896-0.263,1.576-0.87,1.576
					c-0.346,0-0.62-0.131-0.908-0.358c-0.228-0.178-0.357-0.264-0.478-0.264c-0.119,0-0.226,0.109-0.298,0.216
					c0.442,0.453,0.955,0.848,1.671,0.848c0.774,0,1.492-0.728,1.492-2.018C34.817,53.545,34.09,52.841,33.359,52.841"
      />
      <path
        fill="#FFFFFF"
        d="M31.667,52.817h-0.538c-0.034,0-0.058-0.024-0.071-0.048l-0.167-0.514c-0.013-0.036-0.024-0.036-0.037,0
					l-0.166,0.514c-0.013,0.035-0.037,0.048-0.061,0.048h-0.537c-0.025,0-0.035,0.011-0.012,0.022l0.442,0.323
					c0.023,0.023,0.023,0.06,0.01,0.083l-0.166,0.502c-0.012,0.023-0.012,0.048,0.024,0.023l0.442-0.309
					c0.036-0.024,0.06-0.024,0.095,0l0.43,0.309c0.023,0.024,0.037,0.012,0.023-0.012l-0.167-0.514c-0.01-0.035,0-0.071,0.025-0.083
					l0.439-0.323C31.703,52.828,31.69,52.817,31.667,52.817"
      />
      <path
        fill="#FFFFFF"
        d="M31.262,56.35c-0.228-0.574-0.407-0.967-0.407-1.444c0-0.202,0.061-0.382,0.097-0.477
					c0.047-0.096,0.058-0.157,0.01-0.157c-0.071,0-0.228,0.168-0.322,0.313c-0.072,0.118-0.155,0.344-0.155,0.523
					c0,0.334,0.107,0.692,0.179,0.907c0.131,0.371,0.251,0.8,0.251,1.207c0,0.643-0.287,1.083-0.801,1.478
					c0.049,0.036,0.167,0.096,0.312,0.096c0.5,0,1.098-0.561,1.098-1.324C31.524,57.018,31.392,56.707,31.262,56.35"
      />
    </g>
  </g>
);

const LeadStage4 = () => (
  <g id="Stage_4_11_">
    <polygon
      fill="#E9CAD8"
      points="57.578,19.714 57.578,50.565 30.868,65.992 4.155,50.565 4.155,19.714 30.868,4.288 			"
    />
    <polygon
      fill="#F5ACCC"
      points="57.578,50.565 30.868,65.992 30.868,35.141 			"
    />
    <polygon
      fill="#F5ACCC"
      points="57.578,19.715 57.578,50.565 30.868,35.141 			"
    />
    <polygon
      fill="#F5ACCC"
      points="30.868,4.284 57.578,19.708 30.868,35.136 			"
    />
    <polygon
      fill="#F5ACCC"
      points="30.866,65.993 4.155,50.566 30.866,35.142 			"
    />
    <g>
      <path
        fill="#AB6A92"
        d="M30.868,4.601l26.439,15.268v30.539L30.868,65.676L4.428,50.408V19.869L30.868,4.601 M30.868,0.023
					c-0.574,0-1.147,0.149-1.66,0.445L1.68,16.366c-1.026,0.593-1.66,1.688-1.66,2.873v31.797c0,1.187,0.633,2.28,1.66,2.873
					l27.528,15.896c0.512,0.298,1.086,0.446,1.66,0.446c0.571,0,1.144-0.148,1.658-0.446l27.529-15.896
					c1.025-0.593,1.658-1.687,1.658-2.873V19.239c0-1.185-0.633-2.28-1.658-2.873L32.525,0.469
					C32.012,0.172,31.439,0.023,30.868,0.023"
      />
      <polygon
        fill="#7B2860"
        points="26.609,33.682 23.403,33.682 23.403,29.343 24.473,29.343 24.473,32.75 26.609,32.75 				"
      />
      <path
        fill="#7B2860"
        d="M30.327,32.323h-2.383c0.084,0.388,0.35,0.627,0.699,0.627c0.238,0,0.518-0.057,0.692-0.375l0.92,0.187
					c-0.278,0.687-0.868,1.018-1.612,1.018c-0.939,0-1.717-0.695-1.717-1.723c0-1.024,0.778-1.723,1.724-1.723
					c0.919,0,1.657,0.66,1.677,1.723V32.323z M27.963,31.687h1.34c-0.097-0.351-0.356-0.512-0.653-0.512
					C28.358,31.175,28.061,31.357,27.963,31.687"
      />
      <path
        fill="#7B2860"
        d="M34.219,33.682h-1.034v-0.26c-0.254,0.228-0.59,0.356-1.012,0.356c-0.861,0-1.585-0.692-1.585-1.722
					c0-1.024,0.725-1.723,1.585-1.723c0.422,0,0.758,0.129,1.012,0.356v-0.258h1.034V33.682z M33.185,32.056
					c0-0.499-0.377-0.843-0.797-0.843c-0.454,0-0.785,0.344-0.785,0.843c0,0.505,0.331,0.842,0.785,0.842
					C32.807,32.898,33.185,32.561,33.185,32.056"
      />
      <path
        fill="#7B2860"
        d="M38.326,33.682h-1.035v-0.26c-0.252,0.228-0.591,0.356-1.012,0.356c-0.86,0-1.584-0.692-1.584-1.722
					c0-1.024,0.724-1.723,1.584-1.723c0.421,0,0.76,0.129,1.012,0.356v-1.672h1.035V33.682z M37.291,32.056
					c0-0.499-0.376-0.843-0.797-0.843c-0.454,0-0.784,0.344-0.784,0.843c0,0.505,0.33,0.842,0.784,0.842
					C36.915,32.898,37.291,32.561,37.291,32.056"
      />
      <path
        fill="#AB6A92"
        d="M19.834,37.748l-1.469,0.299c-0.109-0.571-0.581-0.852-1.062-0.852c-0.498,0-0.816,0.271-0.816,0.617
					c0,0.244,0.109,0.425,0.49,0.508l1.262,0.28c1.051,0.235,1.669,0.808,1.669,1.77c0,1.298-1.153,1.97-2.504,1.97
					c-1.37,0-2.494-0.654-2.658-1.843l1.542-0.299c0.154,0.625,0.59,0.896,1.197,0.896c0.562,0,0.898-0.254,0.898-0.626
					c0-0.271-0.127-0.443-0.563-0.534l-1.27-0.272c-0.872-0.199-1.632-0.652-1.632-1.761c0-1.178,0.979-1.914,2.403-1.914
					C18.646,35.987,19.607,36.642,19.834,37.748"
      />
      <path
        fill="#AB6A92"
        d="M23.906,42.002c-0.354,0.191-0.725,0.337-1.242,0.337c-1.134,0-1.814-0.617-1.814-1.814V38.8h-0.825
					v-1.152h0.825v-1.342H22.3v1.342h1.342V38.8H22.3v1.579c0,0.472,0.228,0.662,0.608,0.662c0.199,0,0.472-0.073,0.663-0.181
					L23.906,42.002z"
      />
      <path
        fill="#AB6A92"
        d="M28.974,42.202h-1.451v-0.363c-0.353,0.317-0.825,0.5-1.414,0.5c-1.207,0-2.222-0.971-2.222-2.413
					c0-1.434,1.015-2.413,2.222-2.413c0.589,0,1.061,0.18,1.414,0.5v-0.363h1.451V42.202z M27.523,39.926
					c0-0.698-0.525-1.18-1.115-1.18c-0.634,0-1.097,0.481-1.097,1.18c0,0.705,0.462,1.18,1.097,1.18
					C26.998,41.105,27.523,40.631,27.523,39.926"
      />
      <path
        fill="#AB6A92"
        d="M34.532,41.749c0,1.405-1.067,2.177-2.548,2.177c-1.042,0-1.84-0.362-2.284-1.097l1.206-0.654
					c0.344,0.407,0.598,0.562,1.07,0.562c0.662,0,1.134-0.389,1.134-1.105v-0.128c-0.327,0.282-0.78,0.437-1.37,0.437
					c-1.188,0-2.194-0.942-2.194-2.232c0-1.262,1.005-2.194,2.194-2.194c0.59,0,1.043,0.153,1.37,0.435v-0.3h1.422V41.749z
					 M33.11,39.707c0-0.608-0.48-1.034-1.061-1.034c-0.635,0-1.088,0.426-1.088,1.034c0,0.607,0.453,1.043,1.088,1.043
					C32.63,40.75,33.11,40.314,33.11,39.707"
      />
      <path
        fill="#AB6A92"
        d="M39.875,40.297h-3.337c0.118,0.544,0.489,0.88,0.979,0.88c0.337,0,0.728-0.082,0.972-0.525l1.286,0.263
					c-0.389,0.962-1.215,1.425-2.258,1.425c-1.314,0-2.403-0.971-2.403-2.412c0-1.435,1.089-2.414,2.413-2.414
					c1.287,0,2.321,0.925,2.349,2.414V40.297z M36.566,39.408h1.875c-0.136-0.491-0.498-0.718-0.915-0.718
					C37.118,38.69,36.7,38.945,36.566,39.408"
      />
      <path
        fill="#AB6A92"
        d="M46.568,41.087v1.115h-1.406v-1.115h-3.158v-1.345l2.713-3.618h1.852v3.766h0.734v1.197H46.568z
					 M45.162,37.566l-1.642,2.323h1.642V37.566z"
      />
      <path
        fill="#7B2860"
        d="M35.162,13.445l-2.59,1.496l1.832,3.171c0.014,0.022,0.021,0.045,0.029,0.068
					c0.067,0.077,0.081,0.187,0.019,0.271l-2.01,2.823l5.431-3.135c0.146-0.085,0.146-0.294,0.003-0.381l-2.3-1.381
					c-0.022-0.013-0.036-0.038-0.036-0.062l-0.047-2.684C35.49,13.464,35.309,13.361,35.162,13.445"
      />
      <polygon
        fill="#7B2860"
        points="33.508,19.021 31.273,20.312 31.862,21.332 				"
      />
      <path
        fill="#7B2860"
        d="M33.935,18.181l-2.524-4.375c-0.082-0.14-0.261-0.188-0.402-0.107l-4.02,2.32l2.711,4.694l4.209-2.429
					C33.944,18.261,33.957,18.217,33.935,18.181"
      />
      <path
        fill="#7B2860"
        d="M26.63,16.272c-0.145,0.177-0.32,0.328-0.524,0.444c-0.203,0.119-0.419,0.19-0.638,0.229l5.382,9.325
					c0.082,0.14,0.26,0.188,0.401,0.107l0.647-0.373c0.141-0.081,0.189-0.262,0.108-0.401L26.63,16.272z"
      />
      <path
        fill="#7B2860"
        d="M26.584,15.427c0.104-0.387,0.051-0.792-0.15-1.138c-0.2-0.347-0.522-0.594-0.909-0.699
					c-0.13-0.034-0.261-0.051-0.391-0.051c-0.26,0-0.516,0.069-0.747,0.201c-0.716,0.414-0.962,1.332-0.55,2.049
					c0.415,0.717,1.333,0.962,2.048,0.549C26.232,16.137,26.482,15.815,26.584,15.427 M24.58,15.361
					c0.061,0.105,0.024,0.239-0.08,0.299c-0.035,0.021-0.073,0.031-0.11,0.031c-0.077,0-0.149-0.04-0.191-0.11
					c-0.298-0.517-0.121-1.182,0.396-1.48c0.105-0.06,0.239-0.025,0.299,0.08c0.062,0.105,0.025,0.24-0.079,0.3
					C24.508,14.659,24.401,15.053,24.58,15.361"
      />
      <path
        fill="#4F86C6"
        d="M30.868,59.561c-2.639,0-4.774-2.138-4.774-4.773c0-2.638,2.135-4.775,4.774-4.775
					c2.638,0,4.774,2.138,4.774,4.775C35.642,57.423,33.506,59.561,30.868,59.561"
      />
      <path
        fill="#FFFFFF"
        d="M30.868,50.012c2.638,0,4.774,2.138,4.774,4.775c0,2.636-2.136,4.773-4.774,4.773
					c-2.639,0-4.774-2.138-4.774-4.773C26.094,52.149,28.229,50.012,30.868,50.012 M30.868,49.601c-2.86,0-5.187,2.325-5.187,5.187
					c0,2.858,2.327,5.185,5.187,5.185c2.858,0,5.185-2.326,5.185-5.185C36.053,51.926,33.726,49.601,30.868,49.601"
      />
      <path
        fill="#FFFFFF"
        d="M33.361,52.842c-0.239,0-0.465,0.107-0.62,0.251l-0.014-0.012c0.073-0.119,0.169-0.346,0.169-0.717
					c0-0.883-0.885-1.553-2.029-1.553c-1.145,0-2.042,0.67-2.042,1.553c0,0.371,0.12,0.598,0.18,0.717l-0.014,0.012
					c-0.153-0.144-0.38-0.251-0.619-0.251c-0.729,0-1.457,0.704-1.457,2.017c0,1.29,0.716,2.017,1.494,2.017
					c0.714,0,1.216-0.395,1.67-0.847c-0.072-0.107-0.18-0.216-0.298-0.216c-0.121,0-0.252,0.085-0.478,0.264
					c-0.298,0.228-0.562,0.357-0.908,0.357c-0.608,0-0.87-0.682-0.87-1.575c0-0.955,0.251-1.588,0.896-1.588
					c0.286,0,0.476,0.085,0.668,0.264c0.285,0.263,0.381,0.334,0.512,0.334c0.096,0,0.155-0.061,0.18-0.095
					c-0.215-0.216-0.525-0.669-0.525-1.253c0-0.67,0.56-1.135,1.611-1.135c1.049,0,1.611,0.465,1.611,1.135
					c0,0.584-0.311,1.037-0.525,1.253c0.023,0.034,0.083,0.095,0.177,0.095c0.133,0,0.228-0.071,0.514-0.334
					c0.18-0.179,0.383-0.264,0.668-0.264c0.646,0,0.896,0.633,0.896,1.588c0,0.894-0.263,1.575-0.87,1.575
					c-0.346,0-0.621-0.13-0.908-0.357c-0.226-0.179-0.356-0.264-0.476-0.264c-0.12,0-0.227,0.108-0.298,0.216
					c0.44,0.452,0.954,0.847,1.671,0.847c0.774,0,1.491-0.727,1.491-2.017C34.817,53.546,34.089,52.842,33.361,52.842"
      />
      <path
        fill="#FFFFFF"
        d="M31.667,52.818h-0.538c-0.035,0-0.058-0.024-0.07-0.049l-0.168-0.512c-0.013-0.036-0.023-0.036-0.035,0
					l-0.167,0.512c-0.013,0.036-0.035,0.049-0.059,0.049h-0.538c-0.024,0-0.036,0.011-0.012,0.022l0.442,0.323
					c0.023,0.023,0.023,0.06,0.011,0.083l-0.167,0.501c-0.011,0.024-0.011,0.049,0.024,0.024l0.442-0.31
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.31c0.024,0.024,0.035,0.012,0.024-0.012l-0.166-0.514c-0.013-0.035,0-0.07,0.022-0.083
					l0.441-0.323C31.702,52.829,31.69,52.818,31.667,52.818"
      />
      <path
        fill="#FFFFFF"
        d="M31.261,56.351c-0.226-0.574-0.405-0.968-0.405-1.443c0-0.204,0.059-0.383,0.095-0.478
					c0.048-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.167-0.321,0.311c-0.073,0.119-0.156,0.347-0.156,0.523
					c0,0.336,0.108,0.693,0.178,0.908c0.133,0.372,0.251,0.801,0.251,1.207c0,0.644-0.287,1.084-0.799,1.477
					c0.048,0.037,0.166,0.097,0.31,0.097c0.5,0,1.099-0.56,1.099-1.324C31.524,57.018,31.393,56.708,31.261,56.351"
      />
    </g>
  </g>
);

const LeadStage3 = () => (
  <g id="Stage_3_11_">
    <polygon
      fill="#E9CAD8"
      points="57.58,19.712 57.58,50.564 30.868,65.99 4.157,50.564 4.157,19.712 30.868,4.285 			"
    />
    <polygon fill="#F5ACCC" points="57.578,50.564 30.87,65.99 30.87,35.14 			" />
    <polygon fill="#F5ACCC" points="57.578,19.713 57.578,50.564 30.87,35.14 			" />
    <polygon fill="#F5ACCC" points="30.87,4.281 57.578,19.707 30.87,35.134 			" />
    <g>
      <path
        fill="#AB6A92"
        d="M30.869,4.599l26.437,15.269v30.539l-26.437,15.27L4.431,50.406V19.867L30.869,4.599 M30.869,0.021
					c-0.573,0-1.146,0.149-1.659,0.446L1.682,16.365c-1.027,0.592-1.659,1.688-1.659,2.873v31.798c0,1.187,0.632,2.28,1.659,2.873
					l27.527,15.898c0.514,0.297,1.086,0.444,1.659,0.444c0.573,0,1.145-0.147,1.658-0.444l27.526-15.898
					c1.027-0.593,1.66-1.687,1.66-2.873V19.237c0-1.185-0.633-2.28-1.66-2.873L32.526,0.467C32.013,0.17,31.441,0.021,30.869,0.021"
      />
      <polygon
        fill="#7B2860"
        points="26.611,33.681 23.406,33.681 23.406,29.341 24.474,29.341 24.474,32.747 26.611,32.747 				"
      />
      <path
        fill="#7B2860"
        d="M30.329,32.321h-2.383c0.084,0.388,0.35,0.627,0.699,0.627c0.239,0,0.518-0.057,0.693-0.375l0.92,0.188
					c-0.279,0.686-0.869,1.018-1.613,1.018c-0.939,0-1.715-0.695-1.715-1.724c0-1.024,0.776-1.723,1.722-1.723
					c0.919,0,1.658,0.661,1.677,1.723V32.321z M27.965,31.686h1.34c-0.097-0.35-0.355-0.512-0.653-0.512
					C28.36,31.173,28.062,31.355,27.965,31.686"
      />
      <path
        fill="#7B2860"
        d="M34.221,33.681h-1.036V33.42c-0.25,0.229-0.588,0.357-1.009,0.357c-0.862,0-1.587-0.693-1.587-1.723
					c0-1.022,0.725-1.723,1.587-1.723c0.421,0,0.759,0.129,1.009,0.356v-0.259h1.036V33.681z M33.185,32.054
					c0-0.498-0.375-0.842-0.795-0.842c-0.454,0-0.784,0.344-0.784,0.842c0,0.506,0.33,0.843,0.784,0.843
					C32.809,32.897,33.185,32.561,33.185,32.054"
      />
      <path
        fill="#7B2860"
        d="M38.327,33.681h-1.036V33.42c-0.251,0.229-0.589,0.357-1.009,0.357c-0.863,0-1.587-0.693-1.587-1.723
					c0-1.022,0.724-1.723,1.587-1.723c0.42,0,0.758,0.129,1.009,0.356v-1.671h1.036V33.681z M37.291,32.054
					c0-0.498-0.376-0.842-0.796-0.842c-0.454,0-0.784,0.344-0.784,0.842c0,0.506,0.33,0.843,0.784,0.843
					C36.915,32.897,37.291,32.561,37.291,32.054"
      />
      <path
        fill="#AB6A92"
        d="M20.058,37.746l-1.469,0.3c-0.109-0.571-0.582-0.853-1.061-0.853c-0.499,0-0.816,0.272-0.816,0.618
					c0,0.243,0.107,0.424,0.489,0.507l1.261,0.281c1.052,0.236,1.67,0.807,1.67,1.769c0,1.298-1.153,1.97-2.505,1.97
					c-1.368,0-2.493-0.653-2.657-1.844l1.542-0.297c0.154,0.624,0.59,0.897,1.197,0.897c0.561,0,0.897-0.256,0.897-0.627
					c0-0.271-0.126-0.444-0.562-0.535l-1.27-0.272c-0.871-0.199-1.633-0.652-1.633-1.76c0-1.18,0.98-1.913,2.404-1.913
					C18.871,35.987,19.831,36.641,20.058,37.746"
      />
      <path
        fill="#AB6A92"
        d="M24.129,42.001c-0.354,0.191-0.725,0.337-1.241,0.337c-1.135,0-1.815-0.618-1.815-1.815V38.8h-0.824
					v-1.154h0.824v-1.341h1.451v1.341h1.342V38.8h-1.342v1.576c0,0.473,0.228,0.665,0.609,0.665c0.198,0,0.471-0.075,0.662-0.183
					L24.129,42.001z"
      />
      <path
        fill="#AB6A92"
        d="M29.198,42.2h-1.45v-0.364c-0.354,0.318-0.826,0.501-1.415,0.501c-1.208,0-2.223-0.97-2.223-2.413
					c0-1.433,1.015-2.412,2.223-2.412c0.589,0,1.061,0.18,1.415,0.498v-0.362h1.45V42.2z M27.748,39.924
					c0-0.698-0.526-1.18-1.116-1.18c-0.635,0-1.096,0.481-1.096,1.18c0,0.706,0.461,1.181,1.096,1.181
					C27.222,41.104,27.748,40.63,27.748,39.924"
      />
      <path
        fill="#AB6A92"
        d="M34.759,41.746c0,1.407-1.07,2.179-2.549,2.179c-1.043,0-1.841-0.363-2.286-1.099l1.205-0.653
					c0.345,0.408,0.599,0.563,1.07,0.563c0.663,0,1.134-0.39,1.134-1.106v-0.127c-0.326,0.281-0.78,0.436-1.37,0.436
					c-1.188,0-2.194-0.943-2.194-2.231c0-1.262,1.007-2.194,2.194-2.194c0.589,0,1.043,0.153,1.37,0.434v-0.3h1.425V41.746z
					 M33.334,39.706c0-0.608-0.481-1.035-1.062-1.035c-0.635,0-1.089,0.427-1.089,1.035s0.454,1.044,1.089,1.044
					C32.853,40.75,33.334,40.314,33.334,39.706"
      />
      <path
        fill="#AB6A92"
        d="M40.098,40.296h-3.336c0.118,0.543,0.489,0.88,0.979,0.88c0.335,0,0.726-0.081,0.969-0.526l1.288,0.263
					c-0.391,0.962-1.215,1.425-2.257,1.425c-1.317,0-2.404-0.97-2.404-2.412c0-1.434,1.087-2.413,2.413-2.413
					c1.287,0,2.322,0.924,2.348,2.413V40.296z M36.789,39.406h1.876c-0.137-0.49-0.499-0.718-0.915-0.718
					C37.342,38.688,36.924,38.944,36.789,39.406"
      />
      <path
        fill="#AB6A92"
        d="M46.972,40.376c0,1.155-0.935,1.961-2.269,1.961c-1.278,0-2.213-0.745-2.347-1.925l1.413-0.26
					c0.081,0.596,0.408,0.942,0.943,0.942c0.509,0,0.871-0.291,0.871-0.736c0-0.398-0.3-0.635-0.824-0.635h-0.818v-1.188h0.754
					c0.517,0,0.816-0.228,0.816-0.618c0-0.416-0.318-0.698-0.809-0.698c-0.487,0-0.853,0.302-0.906,0.979l-1.369-0.262
					c0.137-1.208,1.034-1.95,2.268-1.95c1.297,0,2.178,0.796,2.178,1.913c0,0.555-0.238,0.98-0.654,1.224
					C46.699,39.371,46.972,39.805,46.972,40.376"
      />
      <path
        fill="#7B2860"
        d="M35.163,13.443l-2.59,1.496l1.831,3.171c0.014,0.022,0.023,0.045,0.032,0.069
					c0.064,0.076,0.077,0.186,0.018,0.271l-2.011,2.825l5.43-3.137c0.145-0.086,0.148-0.295,0.004-0.382l-2.3-1.381
					c-0.022-0.013-0.034-0.037-0.034-0.063l-0.048-2.683C35.491,13.462,35.308,13.359,35.163,13.443"
      />
      <polygon
        fill="#7B2860"
        points="33.51,19.019 31.275,20.309 31.863,21.331 				"
      />
      <path
        fill="#7B2860"
        d="M33.938,18.179l-2.525-4.375c-0.082-0.141-0.261-0.188-0.401-0.107l-4.019,2.319l2.709,4.695l4.208-2.43
					C33.945,18.259,33.957,18.215,33.938,18.179"
      />
      <path
        fill="#7B2860"
        d="M26.632,16.271c-0.146,0.176-0.32,0.327-0.524,0.445c-0.203,0.118-0.419,0.189-0.639,0.228l5.384,9.325
					c0.081,0.14,0.26,0.188,0.401,0.107l0.646-0.373c0.14-0.081,0.188-0.261,0.107-0.402L26.632,16.271z"
      />
      <path
        fill="#7B2860"
        d="M26.586,15.424c0.104-0.387,0.051-0.792-0.15-1.138c-0.201-0.347-0.523-0.595-0.91-0.699
					c-0.129-0.035-0.261-0.051-0.39-0.051c-0.261,0-0.517,0.069-0.748,0.201c-0.716,0.414-0.961,1.331-0.547,2.048
					c0.413,0.718,1.331,0.962,2.047,0.549C26.235,16.135,26.483,15.813,26.586,15.424 M24.581,15.359
					c0.061,0.106,0.025,0.239-0.08,0.299c-0.035,0.021-0.073,0.03-0.11,0.03c-0.075,0-0.148-0.04-0.189-0.109
					c-0.299-0.518-0.121-1.183,0.396-1.481c0.105-0.059,0.239-0.024,0.3,0.08c0.062,0.106,0.024,0.241-0.079,0.3
					C24.509,14.657,24.403,15.05,24.581,15.359"
      />
      <path
        fill="#4F86C6"
        d="M30.869,59.561c-2.638,0-4.773-2.139-4.773-4.775s2.135-4.773,4.773-4.773s4.773,2.137,4.773,4.773
					S33.507,59.561,30.869,59.561"
      />
      <path
        fill="#FFFFFF"
        d="M30.869,50.012c2.638,0,4.773,2.137,4.773,4.773c0,2.639-2.135,4.775-4.773,4.775
					s-4.773-2.137-4.773-4.775C26.095,52.148,28.23,50.012,30.869,50.012 M30.869,49.6c-2.86,0-5.186,2.326-5.186,5.186
					c0,2.86,2.326,5.185,5.186,5.185c2.859,0,5.185-2.324,5.185-5.185C36.054,51.926,33.728,49.6,30.869,49.6"
      />
      <path
        fill="#FFFFFF"
        d="M33.361,52.84c-0.237,0-0.464,0.106-0.62,0.25l-0.013-0.01c0.072-0.12,0.168-0.348,0.168-0.717
					c0-0.885-0.883-1.553-2.028-1.553s-2.041,0.668-2.041,1.553c0,0.369,0.12,0.597,0.18,0.717l-0.012,0.01
					c-0.155-0.144-0.382-0.25-0.62-0.25c-0.729,0-1.456,0.705-1.456,2.019c0,1.288,0.716,2.017,1.492,2.017
					c0.715,0,1.217-0.396,1.669-0.848c-0.071-0.106-0.179-0.216-0.298-0.216c-0.119,0-0.251,0.085-0.478,0.264
					c-0.297,0.227-0.561,0.356-0.907,0.356c-0.608,0-0.87-0.68-0.87-1.573c0-0.955,0.25-1.588,0.896-1.588
					c0.286,0,0.476,0.083,0.668,0.263c0.286,0.261,0.381,0.333,0.511,0.333c0.096,0,0.156-0.061,0.18-0.095
					c-0.214-0.215-0.524-0.668-0.524-1.253c0-0.669,0.56-1.135,1.611-1.135c1.05,0,1.61,0.466,1.61,1.135
					c0,0.585-0.31,1.038-0.524,1.253c0.023,0.034,0.083,0.095,0.178,0.095c0.132,0,0.227-0.072,0.513-0.333
					c0.179-0.18,0.384-0.263,0.668-0.263c0.646,0,0.895,0.633,0.895,1.588c0,0.894-0.262,1.573-0.869,1.573
					c-0.347,0-0.621-0.13-0.907-0.356c-0.227-0.179-0.358-0.264-0.478-0.264c-0.12,0-0.227,0.109-0.298,0.216
					c0.44,0.452,0.954,0.848,1.67,0.848c0.774,0,1.49-0.729,1.49-2.017C34.817,53.545,34.091,52.84,33.361,52.84"
      />
      <path
        fill="#FFFFFF"
        d="M31.668,52.817h-0.537c-0.036,0-0.059-0.026-0.071-0.049l-0.168-0.513c-0.013-0.036-0.023-0.036-0.035,0
					l-0.167,0.513c-0.013,0.035-0.036,0.049-0.06,0.049h-0.539c-0.022,0-0.035,0.01-0.012,0.021l0.443,0.323
					c0.023,0.023,0.023,0.061,0.011,0.084l-0.167,0.502c-0.011,0.021-0.011,0.046,0.025,0.021l0.441-0.307
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.307c0.023,0.024,0.034,0.012,0.023-0.01l-0.166-0.514
					c-0.013-0.035,0-0.071,0.023-0.084l0.44-0.323C31.704,52.827,31.69,52.817,31.668,52.817"
      />
      <path
        fill="#FFFFFF"
        d="M31.262,56.349c-0.226-0.573-0.406-0.967-0.406-1.442c0-0.204,0.06-0.383,0.096-0.478
					c0.048-0.098,0.06-0.158,0.011-0.158c-0.071,0-0.226,0.17-0.321,0.313c-0.072,0.118-0.156,0.345-0.156,0.522
					c0,0.335,0.108,0.693,0.179,0.908c0.132,0.371,0.251,0.801,0.251,1.207c0,0.644-0.288,1.084-0.799,1.477
					c0.047,0.038,0.167,0.097,0.31,0.097c0.5,0,1.097-0.559,1.097-1.324C31.524,57.017,31.392,56.707,31.262,56.349"
      />
    </g>
  </g>
);
// #endregion

const LiveSmart = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <LiveSmartStage3 {...otherProps} />;
    case 4:
      return <LiveSmartStage4 {...otherProps} />;
    case 5:
      return <LiveSmartStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

const Lead = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <LeadStage3 {...otherProps} />;
    case 4:
      return <LeadStage4 {...otherProps} />;
    case 5:
      return <LeadStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

function SkillsForMyFuture(props: any) {
  const { skill, stage, fallback = null, ...otherProps } = props;

  switch (skill) {
    case 'Live smart':
      return <LiveSmart stage={stage} {...otherProps} fallback={fallback} />;
    case 'Lead':
      return <Lead stage={stage} {...otherProps} fallback={fallback} />;
    default:
      return fallback;
  }
}

export default SkillsForMyFuture;
