import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

// POLYFILLS
import 'url-search-params-polyfill';
// END

import RootProvider from 'context/RootProvider';
import ErrorBoundary from 'components/error-boundary';
import App from 'app';

import './index.scss';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://6fe7b20c4d2842a5985f1016a51e93c8@o1158733.ingest.sentry.io/6244293',
  integrations: [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <RootProvider>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </RootProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
