// import { lazy } from 'react';

import KnowMyself from './KnowMyself';
import ExpressMyself from './ExpressMyself';
import BeWell from './BeWell';
import HaveAdventures from './HaveAdventures';
import TakeAction from './TakeAction';
import SkillsForMyFuture from './SkillsForMyFuture';

// const KnowMyself = lazy(() => import('./themes/KnowMyself'));
// const ExpressMyself = lazy(() => import('./themes/ExpressMyself'));
// const BeWell = lazy(() => import('./themes/BeWell'));
// const HaveAdventures = lazy(() => import('./themes/HaveAdventures'));
// const TakeAction = lazy(() => import('./themes/TakeAction'));
// const SkillsForMyFuture = lazy(() => import('./themes/SkillsForMyFuture'));

function Award(props: any) {
  const { name, ...otherProps } = props;

  switch (name) {
    case 'Know Myself':
      return <KnowMyself {...otherProps} />;
    case 'Express Myself':
      return <ExpressMyself {...otherProps} />;
    case 'Be Well':
      return <BeWell {...otherProps} />;
    case 'Have Adventures':
      return <HaveAdventures {...otherProps} />;
    case 'Take Action':
      return <TakeAction {...otherProps} />;
    case 'Skills For My Future':
      return <SkillsForMyFuture {...otherProps} />;
    default:
      return null;
  }
}

export default Award;
