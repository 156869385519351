// import React from 'react'
import Base from './Base';

function TakeAction(props: any) {
  const { invert, primaryColor, secondaryColor, ...otherProps } = props;
  const secondaryColour = secondaryColor || '#FFFFFF';
  const primaryColour = primaryColor || '#F78E1E';

  return (
    <Base {...otherProps}>
      <g>
        <defs>
          <path
            id="TAKEACTION_SVGID_3_"
            d="M2.031,34c0,17.589,14.26,31.851,31.853,31.851c17.591,0,31.851-14.262,31.851-31.851
    c0-17.592-14.26-31.853-31.851-31.853C16.291,2.148,2.031,16.409,2.031,34"
          />
        </defs>

        <clipPath id="TAKEACTION_SVGID_4_">
          <use xlinkHref="#TAKEACTION_SVGID_3_" overflow="visible" />
        </clipPath>
      </g>

      <g id="TakeAction_theme">
        <path
          clipPath="url(#TAKEACTION_SVGID_4_)"
          fill={invert ? primaryColour : secondaryColour}
          d="M64.461,33.999c0,16.888-13.689,30.578-30.577,30.578
			S3.305,50.887,3.305,33.999c0-16.887,13.691-30.578,30.579-30.578S64.461,17.111,64.461,33.999"
        />

        <path
          clipPath="url(#TAKEACTION_SVGID_4_)"
          fill={invert ? secondaryColour : primaryColour}
          d="M33.779,31.65c0.288-0.224,0.48-0.339,0.62-0.479
			c-0.05-0.832-0.1-1.49-0.162-2.047c-0.031,0.192-0.061,0.385-0.09,0.577C34.054,30.285,33.929,30.865,33.779,31.65 M57.17,37.953
			c0.475,0.248,0.535,0.629,0.141,0.969c-1.363,1.164-2.516,2.479-3.084,4.225c-0.047,0.141-0.146,0.281-0.254,0.387
			c-0.863,0.865-1.709,1.748-2.598,2.586h0.002c0,0-1.082,1.309-1.266,2.428c-0.068,0.406,0.109,3.588,0.361,7.479h-6.096
			c0-0.357-0.008-0.678-0.025-0.951c-0.473-7.666-0.299-8.713-1.152-10.557c-0.004-0.008-0.01-0.016-0.014-0.021
			c-0.014-0.021-0.02-0.049-0.029-0.072c-0.018-0.035-0.035-0.07-0.053-0.107l0.008-0.002c-0.041-0.119-0.074-0.246-0.098-0.371
			c-0.197-1.01-0.396-2.02-0.576-3.033c-0.164-0.924-0.455-1.789-1-2.572c0.428,6.697,1.094,15.707,1.242,17.688h-9.065
			c0.232-2.332,1.395-14.279,1.164-18.049c-0.004-0.072-0.008-0.139-0.013-0.209c-0.014,0.064-0.026,0.133-0.039,0.199
			c-0.026,0.131-0.059,0.266-0.103,0.391l0.007,0.002c-0.02,0.039-0.037,0.076-0.055,0.115c-0.012,0.023-0.017,0.053-0.031,0.074
			c-0.004,0.008-0.01,0.016-0.015,0.023c-0.902,1.947-0.718,3.053-1.217,11.152c-0.082,1.328,0.016,3.707,0.184,6.301H26.49
			c0.426-6.15,0.838-12.592,0.739-13.197c-0.194-1.184-1.339-2.563-1.339-2.563l0.004-0.002c-0.938-0.885-1.832-1.818-2.744-2.73
			c-0.113-0.113-0.219-0.26-0.268-0.41c-0.602-1.844-1.817-3.232-3.258-4.462c-0.418-0.357-0.352-0.76,0.147-1.021
			c1.123-0.59,2.835,0.068,3.413,1.209c0.256,0.504,0.736,0.908,1.159,1.31c0.296,0.281,0.559,0.066,0.783-0.186
			c0.683-0.765,0.847-1.69,0.727-2.652c-0.155-1.24-0.396-2.469-0.597-3.704c-0.186-1.155-0.373-2.31-0.545-3.467
			c-0.039-0.262-0.032-0.535-0.018-0.801c0.024-0.514,0.281-0.846,0.805-0.936c0.553-0.097,0.847,0.171,0.978,0.689
			c0.435,1.728,0.869,3.455,1.318,5.18c0.115,0.445,0.276,0.878,0.417,1.316c0.069,0.005,0.139,0.009,0.206,0.014
			c0.08-0.226,0.203-0.447,0.23-0.679c0.177-1.53,0.336-3.062,0.493-4.595c0.093-0.898,0.168-1.798,0.259-2.697
			c0.018-0.182,0.06-0.366,0.117-0.54c0.154-0.473,0.462-0.807,0.984-0.765c0.559,0.046,0.82,0.456,0.857,0.97
			c0.042,0.574,0.038,1.153,0.015,1.729c-0.062,1.499-0.155,2.998-0.216,4.497c-0.034,0.81-0.139,1.633,0.35,2.426
			c0.405-1.58,0.791-3.164,1.225-4.734c0.085-0.308,0.186-0.613,0.292-0.915c-0.118-0.613-0.234-1.227-0.344-1.842
			c-0.18-1.015-0.498-1.963-1.099-2.823c-0.787-1.13-1.549-2.28-2.266-3.455c-0.354-0.577-0.192-1.235,0.235-1.472
			c0.434-0.241,0.865,0.001,1.317,0.588c0.882,1.146,1.803,2.262,2.723,3.378c0.146,0.176,0.363,0.292,0.701,0.556
			c-0.153-0.813-0.285-1.414-0.381-2.02c-0.331-2.099-0.661-4.198-0.967-6.301c-0.071-0.489,0.148-0.89,0.641-1.054
			c0.522-0.176,0.841,0.194,1.013,0.568c0.316,0.69,0.576,1.413,0.781,2.144c0.449,1.629,0.848,3.272,1.268,4.909
			c0.508-0.821,0.396-1.675,0.361-2.514c-0.063-1.555-0.158-3.108-0.223-4.663c-0.025-0.597-0.029-1.198,0.014-1.792
			c0.039-0.533,0.313-0.959,0.891-1.006c0.541-0.044,0.859,0.303,1.02,0.794c0.061,0.18,0.104,0.37,0.121,0.559
			c0.096,0.932,0.172,1.866,0.27,2.796c0.162,1.589,0.328,3.178,0.512,4.763c0.027,0.241,0.156,0.471,0.238,0.705
			c0.07-0.005,0.143-0.01,0.213-0.014c0.145-0.455,0.313-0.904,0.434-1.365c0.463-1.788,0.916-3.579,1.365-5.37
			c0.135-0.537,0.439-0.814,1.014-0.715c0.543,0.094,0.809,0.439,0.834,0.971c0.016,0.276,0.023,0.558-0.018,0.83
			c-0.178,1.2-0.373,2.397-0.566,3.595c-0.207,1.279-0.457,2.554-0.617,3.839c-0.125,0.998,0.045,1.956,0.752,2.75
			c0.234,0.261,0.506,0.484,0.813,0.193c0.439-0.417,0.938-0.836,1.203-1.36c0.6-1.181,2.373-1.863,3.539-1.252
			c0.518,0.271,0.586,0.688,0.15,1.059c-1.492,1.276-2.754,2.715-3.377,4.626c-0.051,0.156-0.16,0.307-0.275,0.426
			c-0.947,0.945-1.873,1.913-2.846,2.829l0.002,0.003c0,0-1.186,1.43-1.387,2.658c-0.047,0.289,0.02,1.866,0.146,4.106
			c0.668,0.85,1.352,1.686,2.039,2.52c0.133,0.162,0.332,0.268,0.641,0.508c-0.141-0.742-0.26-1.291-0.348-1.844
			c-0.303-1.916-0.605-3.833-0.885-5.754c-0.064-0.446,0.137-0.813,0.586-0.963c0.477-0.159,0.768,0.179,0.926,0.521
			c0.289,0.629,0.525,1.289,0.711,1.957c0.41,1.487,0.775,2.987,1.158,4.481c0.465-0.75,0.363-1.529,0.332-2.294
			c-0.059-1.419-0.146-2.838-0.203-4.258c-0.023-0.545-0.027-1.093,0.014-1.636c0.035-0.487,0.283-0.876,0.811-0.919
			c0.494-0.039,0.785,0.277,0.932,0.724c0.055,0.166,0.094,0.339,0.111,0.512c0.086,0.852,0.156,1.703,0.244,2.553
			c0.15,1.451,0.301,2.902,0.469,4.349c0.023,0.221,0.143,0.43,0.217,0.645c0.064-0.004,0.131-0.008,0.195-0.014
			c0.133-0.414,0.285-0.824,0.395-1.246c0.424-1.632,0.838-3.267,1.248-4.903c0.123-0.49,0.402-0.742,0.926-0.651
			c0.494,0.085,0.738,0.399,0.762,0.886c0.014,0.252,0.02,0.51-0.018,0.758c-0.162,1.096-0.338,2.189-0.516,3.283
			c-0.189,1.167-0.416,2.332-0.564,3.505c-0.113,0.912,0.043,1.787,0.688,2.51c0.213,0.24,0.461,0.443,0.742,0.178
			c0.4-0.381,0.855-0.764,1.098-1.242C54.486,38.02,56.107,37.396,57.17,37.953 M19.007,41.273c0.117-0.367,0.252-0.729,0.349-1.1
			c0.374-1.439,0.736-2.881,1.1-4.324c0.109-0.432,0.354-0.654,0.816-0.574c0.436,0.076,0.65,0.352,0.672,0.781
			c0.012,0.223,0.017,0.449-0.016,0.668c-0.144,0.967-0.3,1.93-0.456,2.895c-0.166,1.029-0.366,2.057-0.495,3.09
			c-0.102,0.805,0.035,1.576,0.605,2.215c0.188,0.211,0.406,0.391,0.653,0.156c0.353-0.336,0.755-0.674,0.969-1.096
			c0.482-0.949,1.91-1.5,2.848-1.008c0.417,0.219,0.473,0.555,0.123,0.854c-1.202,1.027-2.218,2.186-2.72,3.725
			c-0.04,0.125-0.128,0.248-0.222,0.342c-0.763,0.762-1.509,1.541-2.291,2.279l0.003,0.002c0,0-0.956,1.15-1.117,2.139
			c-0.041,0.25,0.021,1.695,0.14,3.709H14.63c-0.273-4.924-0.228-5.816-0.896-7.262c-0.004-0.008-0.009-0.014-0.013-0.02
			c-0.012-0.018-0.016-0.041-0.025-0.063c-0.016-0.031-0.03-0.063-0.046-0.096h0.006c-0.037-0.107-0.064-0.219-0.086-0.328
			c-0.174-0.891-0.35-1.779-0.507-2.674c-0.146-0.818-0.402-1.58-0.886-2.273c-0.634-0.91-1.248-1.836-1.824-2.781
			c-0.284-0.465-0.154-0.994,0.189-1.186c0.349-0.193,0.697,0.002,1.061,0.473c0.71,0.924,1.452,1.822,2.191,2.721
			c0.118,0.143,0.293,0.236,0.566,0.447c-0.125-0.654-0.229-1.139-0.308-1.627c-0.267-1.689-0.531-3.379-0.778-5.072
			c-0.059-0.395,0.12-0.717,0.516-0.85c0.42-0.141,0.678,0.158,0.816,0.459c0.255,0.555,0.464,1.137,0.627,1.725
			c0.361,1.313,0.684,2.635,1.021,3.953c0.408-0.662,0.32-1.348,0.292-2.023c-0.051-1.252-0.129-2.504-0.18-3.756
			c-0.021-0.479-0.023-0.963,0.012-1.441c0.031-0.43,0.25-0.773,0.716-0.811c0.437-0.035,0.693,0.244,0.822,0.639
			c0.047,0.146,0.082,0.299,0.099,0.451c0.074,0.75,0.138,1.5,0.215,2.252c0.132,1.277,0.265,2.557,0.412,3.834
			c0.021,0.193,0.126,0.379,0.19,0.568C18.891,41.279,18.949,41.275,19.007,41.273 M65.736,33.999
			c0-17.59-14.262-31.851-31.854-31.851c-17.591,0-31.852,14.261-31.852,31.851c0,17.591,14.261,31.853,31.852,31.853
			C51.475,65.852,65.736,51.59,65.736,33.999"
        />
      </g>
    </Base>
  );
}

export default TakeAction;
