import { HTMLProps } from 'react';

import Dots from 'components/dots';
import Button from 'components/button';
import './guides-theme.scss';

type UMAThemeActivityProps = HTMLProps<HTMLDivElement> & {
  // title?: string;
  // color: string;
  onClick?: () => void;
};

export function UMAThemeActivity(props: UMAThemeActivityProps) {
  const { children, className, onClick, ...otherProps } = props;
  let tabIndex, role, clickHandler;

  if (onClick) {
    tabIndex = -1;
    role = 'button';
    clickHandler = onClick;
  }

  let newClassName = 'd-flex br-rounded px-2 py-1 mb-1';
  if (className) newClassName += ` ${className}`;

  return (
    <div
      {...otherProps}
      className={newClassName}
      tabIndex={tabIndex}
      role={role}
      onClick={clickHandler}
      style={{
        backgroundColor: 'rgb(255 255 255 / 0.5)',
        alignItems: 'center'
      }}
    >
      {children}
      <Dots
        count={1}
        selectedIndexes={[0]}
        activeColor="success"
        inactiveColor="dark"
        className="ml-1"
      />
    </div>
  );
}

function ThemeActivity(props: any) {
  const { children, targetCount, progressCount, onButtonClick, ...otherProps } =
    props;

  const newClassName = 'br-rounded p-2 mb-1';
  const selectedIndexes =
    progressCount > 0 ? [...new Array(progressCount)].map((_, i) => i) : [];

  return (
    // className="d-flex flex-column" style={{ justifyContent: 'center' }}
    <div>
      <div
        {...otherProps}
        className={newClassName}
        style={{ backgroundColor: 'rgb(255 255 255 / 0.5)' }}
      >
        {children}

        <Dots
          count={targetCount}
          selectedIndexes={selectedIndexes}
          activeColor={'success'}
          inactiveColor="dark"
          className="mt-1"
        />
      </div>

      <Button
        color="dark"
        block
        // className="button--small"
        onClick={onButtonClick}
      >
        Edit
      </Button>
    </div>
  );
}

export default ThemeActivity;
