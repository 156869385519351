// #startregion Explore Groups
const ExploreStage5 = () => (
  <g id="Stage_5_1_">
    <polygon
      fill="#E0EED3"
      points="57.554,19.689 57.554,50.53 30.844,65.951 4.134,50.53 4.134,19.689 30.844,4.269 			"
    />
    <polygon
      id="_x31__of_5"
      fill="#9BCD78"
      points="30.845,4.265 57.553,19.685 30.845,35.105 			"
    />
    <polygon
      id="_x32__of_5"
      fill="#9BCD78"
      points="57.553,19.691 57.553,50.531 30.845,35.111 			"
    />
    <polygon
      id="_x33__of_5"
      fill="#9BCD78"
      points="57.553,50.531 30.845,65.951 30.845,35.111 			"
    />
    <polygon
      id="_x34__of_5"
      fill="#9BCD78"
      points="30.842,65.952 4.134,50.532 30.842,35.112 			"
    />
    <polygon
      id="_x35__of_5"
      fill="#9BCD78"
      points="30.845,35.111 4.137,50.531 4.137,19.691 			"
    />
    <g>
      <defs>
        <rect id="SVGID_1_" y="0.007" width="61.688" height="70.204" />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_2_)"
        fill="#72BE44"
        d="M30.844,4.581L57.28,19.845v30.527L30.844,65.636L4.406,50.372V19.845
					L30.844,4.581 M30.844,0.007c-0.573,0-1.146,0.147-1.658,0.443L1.658,16.344c-1.026,0.593-1.657,1.687-1.657,2.872v31.786
					c0,1.185,0.631,2.278,1.657,2.871l27.527,15.894c0.513,0.296,1.085,0.444,1.658,0.444s1.145-0.148,1.658-0.444l27.526-15.894
					c1.027-0.593,1.658-1.687,1.658-2.871V19.216c0-1.186-0.631-2.279-1.658-2.872L32.502,0.45
					C31.988,0.154,31.417,0.007,30.844,0.007"
      />
      <polygon
        clipPath="url(#SVGID_2_)"
        fill="#008A49"
        points="22.613,33.653 19.233,33.653 19.233,29.315 22.594,29.315
					22.594,30.234 20.301,30.234 20.301,31.05 22.406,31.05 22.406,31.918 20.301,31.918 20.301,32.734 22.613,32.734 				"
      />
      <polygon
        clipPath="url(#SVGID_2_)"
        fill="#008A49"
        points="26.609,33.653 25.418,33.653 24.797,32.714 24.168,33.653
					22.983,33.653 24.052,32.08 22.983,30.396 24.168,30.396 24.797,31.406 25.418,30.396 26.609,30.396 25.534,32.08 				"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#008A49"
        d="M30.695,32.027c0,1.03-0.726,1.723-1.587,1.723
					c-0.421,0-0.757-0.129-1.01-0.356v1.36h-1.036v-4.352h1.036v0.26c0.253-0.228,0.589-0.356,1.01-0.356
					C29.97,30.306,30.695,31.005,30.695,32.027 M29.679,32.027c0-0.498-0.33-0.841-0.783-0.841c-0.421,0-0.797,0.343-0.797,0.841
					c0,0.506,0.376,0.843,0.797,0.843C29.349,32.87,29.679,32.533,29.679,32.027"
      />
      <rect
        x="31.169"
        y="28.991"
        clipPath="url(#SVGID_2_)"
        fill="#008A49"
        width="1.036"
        height="4.661"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#008A49"
        d="M36.259,32.027c0,1.03-0.815,1.718-1.78,1.718c-0.984,0-1.8-0.688-1.8-1.718
					c0-1.022,0.815-1.709,1.8-1.709C35.443,30.318,36.259,31.005,36.259,32.027 M35.262,32.027c0-0.505-0.382-0.841-0.783-0.841
					c-0.421,0-0.804,0.336-0.804,0.841c0,0.513,0.383,0.843,0.804,0.843C34.88,32.87,35.262,32.54,35.262,32.027"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#008A49"
        d="M39.122,30.383l-0.046,1.036h-0.188c-0.726,0-1.119,0.376-1.119,1.219v1.016
					h-1.037v-3.251h1.037v0.622c0.232-0.39,0.588-0.667,1.119-0.667C38.979,30.357,39.044,30.364,39.122,30.383"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#008A49"
        d="M42.665,32.294h-2.384c0.085,0.388,0.351,0.628,0.7,0.628
					c0.239,0,0.518-0.058,0.691-0.376l0.921,0.188c-0.279,0.687-0.868,1.017-1.612,1.017c-0.939,0-1.716-0.693-1.716-1.723
					c0-1.022,0.776-1.722,1.723-1.722c0.919,0,1.657,0.66,1.677,1.722V32.294z M40.301,31.659h1.341
					c-0.098-0.351-0.356-0.512-0.653-0.512C40.696,31.147,40.398,31.328,40.301,31.659"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#4FA246"
        d="M20.039,37.718l-1.47,0.3c-0.108-0.571-0.58-0.853-1.061-0.853
					c-0.499,0-0.816,0.272-0.816,0.616c0,0.245,0.109,0.426,0.49,0.508l1.26,0.281c1.052,0.236,1.669,0.807,1.669,1.769
					c0,1.297-1.151,1.968-2.503,1.968c-1.369,0-2.494-0.652-2.657-1.841l1.541-0.3c0.155,0.626,0.591,0.898,1.197,0.898
					c0.563,0,0.898-0.254,0.898-0.626c0-0.272-0.127-0.444-0.563-0.535l-1.27-0.272c-0.87-0.199-1.632-0.652-1.632-1.759
					c0-1.179,0.979-1.914,2.402-1.914C18.851,35.958,19.813,36.611,20.039,37.718"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#4FA246"
        d="M24.11,41.971c-0.354,0.19-0.726,0.336-1.242,0.336
					c-1.134,0-1.814-0.617-1.814-1.814V38.77h-0.825v-1.151h0.825v-1.342h1.451v1.342h1.342v1.151h-1.342v1.578
					c0,0.471,0.228,0.662,0.608,0.662c0.199,0,0.471-0.072,0.662-0.182L24.11,41.971z"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#4FA246"
        d="M29.179,42.171h-1.451v-0.364c-0.353,0.319-0.825,0.499-1.414,0.499
					c-1.206,0-2.222-0.97-2.222-2.412c0-1.433,1.016-2.41,2.222-2.41c0.589,0,1.062,0.18,1.414,0.497v-0.362h1.451V42.171z
					 M27.728,39.894c0-0.697-0.524-1.178-1.114-1.178c-0.636,0-1.098,0.48-1.098,1.178c0,0.707,0.462,1.18,1.098,1.18
					C27.203,41.073,27.728,40.601,27.728,39.894"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#4FA246"
        d="M34.738,41.717c0,1.405-1.07,2.177-2.549,2.177
					c-1.043,0-1.841-0.363-2.285-1.097l1.206-0.653c0.345,0.408,0.599,0.562,1.069,0.562c0.663,0,1.135-0.39,1.135-1.105v-0.127
					c-0.327,0.28-0.78,0.436-1.369,0.436c-1.189,0-2.195-0.943-2.195-2.231c0-1.261,1.006-2.194,2.195-2.194
					c0.589,0,1.042,0.154,1.369,0.435v-0.3h1.424V41.717z M33.314,39.677c0-0.607-0.481-1.033-1.062-1.033
					c-0.635,0-1.088,0.426-1.088,1.033s0.453,1.044,1.088,1.044C32.833,40.721,33.314,40.284,33.314,39.677"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#4FA246"
        d="M40.078,40.267h-3.337c0.118,0.544,0.489,0.879,0.979,0.879
					c0.336,0,0.727-0.081,0.972-0.526l1.286,0.264c-0.39,0.962-1.214,1.424-2.258,1.424c-1.313,0-2.403-0.971-2.403-2.412
					c0-1.434,1.09-2.412,2.412-2.412c1.288,0,2.322,0.925,2.35,2.412V40.267z M36.768,39.377h1.877
					c-0.136-0.488-0.499-0.717-0.916-0.717C37.321,38.66,36.905,38.914,36.768,39.377"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#4FA246"
        d="M44.829,38.226c1.343,0,2.141,0.771,2.141,1.959
					c0,1.251-0.907,2.122-2.304,2.122c-1.251,0-2.313-0.717-2.349-2.086l1.415-0.281c0.008,0.789,0.462,1.098,0.96,1.098
					c0.517,0,0.871-0.336,0.871-0.834c0-0.454-0.344-0.754-0.871-0.754h-2.085l0.109-3.354h3.989v1.297h-2.685l-0.036,0.834H44.829z
					"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#E0EED3"
        d="M26.316,25.108h-0.622c-1.647,0-2.982-1.336-2.982-2.982v-0.622
					c0-1.648,1.335-2.984,2.982-2.984h0.622c1.648,0,2.983,1.336,2.983,2.984v0.622C29.3,23.772,27.965,25.108,26.316,25.108"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#E0EED3"
        d="M35.977,25.108h-0.623c-1.647,0-2.982-1.336-2.982-2.982v-0.622
					c0-1.648,1.335-2.984,2.982-2.984h0.623c1.647,0,2.982,1.336,2.982,2.984v0.622C38.959,23.772,37.624,25.108,35.977,25.108"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#E0EED3"
        d="M30.816,23.282h-0.189c-0.5,0-0.906-0.405-0.906-0.906v-0.188
					c0-0.501,0.406-0.907,0.906-0.907h0.189c0.5,0,0.905,0.406,0.905,0.907v0.188C31.722,22.877,31.316,23.282,30.816,23.282"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#008A49"
        d="M39.119,20.792c-0.072-0.198-0.162-0.388-0.264-0.565l-0.74-1.586
					c-0.414-1.136-1.372-1.915-2.473-2.152l-0.28-0.649c-0.332-0.911-1.341-1.383-2.253-1.051s-1.382,1.34-1.05,2.253l0.195,0.686
					c-0.151,0.196-0.282,0.41-0.388,0.635c-0.273-0.239-0.631-0.385-1.022-0.385c-0.393,0-0.75,0.146-1.024,0.385
					c-0.105-0.225-0.235-0.438-0.388-0.635l0.196-0.686c0.332-0.913-0.139-1.921-1.05-2.253c-0.913-0.332-1.921,0.14-2.253,1.051
					l-0.288,0.651c-1.098,0.238-2.053,1.017-2.465,2.15l-0.74,1.586c-0.102,0.178-0.191,0.367-0.265,0.565
					c-0.678,1.865,0.283,3.929,2.148,4.607s3.929-0.283,4.606-2.149c0.042-0.112,0.077-0.227,0.106-0.341
					c0.248,0.534,0.787,0.905,1.415,0.905c0.627,0,1.167-0.371,1.413-0.905c0.03,0.114,0.064,0.229,0.106,0.341
					c0.679,1.866,2.741,2.828,4.607,2.149C38.836,24.721,39.799,22.657,39.119,20.792 M28.61,22.991
					c-0.535,1.471-2.163,2.23-3.633,1.694c-1.473-0.535-2.23-2.162-1.695-3.635c0.535-1.471,2.163-2.229,3.634-1.693
					C28.388,19.894,29.146,21.521,28.61,22.991 M30.845,23.069c-0.451,0-0.815-0.365-0.815-0.815s0.364-0.815,0.815-0.815
					c0.449,0,0.814,0.365,0.814,0.815S31.294,23.069,30.845,23.069 M36.711,24.686c-1.471,0.536-3.098-0.224-3.634-1.694
					c-0.535-1.471,0.224-3.098,1.695-3.634c1.471-0.535,3.098,0.223,3.634,1.693C38.941,22.523,38.183,24.15,36.711,24.686"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#008A49"
        d="M25.946,20.11c0.124,0,0.224-0.101,0.224-0.224
					c0-0.124-0.1-0.224-0.224-0.224c-1.299,0-2.357,1.057-2.357,2.356c0,0.123,0.101,0.225,0.225,0.225s0.224-0.102,0.224-0.225
					C24.037,20.967,24.895,20.11,25.946,20.11"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#008A49"
        d="M33.677,22.243c0.124,0,0.224-0.101,0.224-0.224
					c0-1.053,0.855-1.909,1.909-1.909c0.123,0,0.224-0.101,0.224-0.224c0-0.124-0.101-0.225-0.224-0.225
					c-1.3,0-2.357,1.059-2.357,2.357C33.452,22.143,33.553,22.243,33.677,22.243"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#4F86C6"
        d="M30.844,59.522c-2.637,0-4.773-2.135-4.773-4.772
					c0-2.636,2.137-4.771,4.773-4.771c2.638,0,4.774,2.136,4.774,4.771C35.618,57.388,33.481,59.522,30.844,59.522"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M30.844,49.979c2.638,0,4.774,2.136,4.774,4.771
					c0,2.638-2.137,4.772-4.774,4.772s-4.773-2.135-4.773-4.772C26.07,52.114,28.206,49.979,30.844,49.979 M30.844,49.566
					c-2.859,0-5.186,2.326-5.186,5.184c0,2.858,2.326,5.185,5.186,5.185c2.86,0,5.186-2.326,5.186-5.185
					C36.029,51.893,33.704,49.566,30.844,49.566"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M33.337,52.806c-0.238,0-0.464,0.107-0.62,0.251l-0.013-0.012
					c0.073-0.12,0.169-0.346,0.169-0.716c0-0.883-0.884-1.551-2.029-1.551c-1.145,0-2.041,0.668-2.041,1.551
					c0,0.37,0.121,0.596,0.18,0.716l-0.012,0.012c-0.155-0.144-0.383-0.251-0.62-0.251c-0.729,0-1.456,0.703-1.456,2.018
					c0,1.288,0.716,2.016,1.492,2.016c0.716,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.181-0.215-0.299-0.215
					s-0.252,0.085-0.477,0.263c-0.299,0.228-0.563,0.359-0.908,0.359c-0.608,0-0.87-0.683-0.87-1.575
					c0-0.955,0.251-1.587,0.896-1.587c0.286,0,0.476,0.083,0.668,0.262c0.286,0.263,0.381,0.333,0.512,0.333
					c0.096,0,0.156-0.06,0.18-0.094c-0.215-0.216-0.524-0.668-0.524-1.253c0-0.668,0.56-1.135,1.61-1.135
					c1.05,0,1.611,0.467,1.611,1.135c0,0.585-0.311,1.037-0.524,1.253c0.022,0.034,0.083,0.094,0.179,0.094
					c0.131,0,0.226-0.07,0.512-0.333c0.18-0.179,0.382-0.262,0.668-0.262c0.646,0,0.896,0.632,0.896,1.587
					c0,0.893-0.264,1.575-0.871,1.575c-0.346,0-0.62-0.132-0.907-0.359c-0.227-0.178-0.358-0.263-0.477-0.263
					c-0.12,0-0.228,0.107-0.3,0.215c0.442,0.453,0.955,0.848,1.672,0.848c0.774,0,1.49-0.728,1.49-2.016
					C34.793,53.509,34.065,52.806,33.337,52.806"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M31.644,52.782h-0.537c-0.035,0-0.06-0.024-0.071-0.048l-0.168-0.513
					c-0.012-0.035-0.022-0.035-0.035,0l-0.167,0.513c-0.012,0.036-0.036,0.048-0.06,0.048h-0.537c-0.024,0-0.035,0.011-0.012,0.023
					l0.441,0.322c0.023,0.023,0.023,0.061,0.012,0.083l-0.168,0.501c-0.011,0.024-0.011,0.049,0.025,0.024l0.441-0.31
					c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.023,0.024,0.036,0.012,0.023-0.012l-0.166-0.514
					c-0.012-0.035,0-0.071,0.024-0.083l0.439-0.322C31.68,52.793,31.667,52.782,31.644,52.782"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M31.238,56.313c-0.228-0.571-0.406-0.966-0.406-1.442
					c0-0.203,0.061-0.382,0.096-0.478c0.047-0.096,0.061-0.155,0.012-0.155c-0.071,0-0.227,0.167-0.322,0.311
					c-0.072,0.119-0.155,0.346-0.155,0.524c0,0.335,0.108,0.692,0.179,0.906c0.133,0.37,0.252,0.801,0.252,1.206
					c0,0.646-0.287,1.085-0.801,1.479c0.048,0.037,0.167,0.096,0.312,0.096c0.5,0,1.097-0.561,1.097-1.324
					C31.5,56.98,31.369,56.671,31.238,56.313"
      />
    </g>
  </g>
);

const ExploreStage4 = () => (
  <g id="Stage_4_2_">
    <polygon
      fill="#E0EED3"
      points="57.553,19.689 57.553,50.53 30.844,65.951 4.134,50.53 4.134,19.689 30.844,4.269 			"
    />
    <polygon
      id="_x31__of_4_1_"
      fill="#9BCD78"
      points="30.844,4.265 57.552,19.685 30.844,35.104 			"
    />
    <polygon
      id="_x32__of_4_1_"
      fill="#9BCD78"
      points="57.553,19.691 57.553,50.531 30.845,35.111 			"
    />
    <polygon
      id="_x33__of_4_1_"
      fill="#9BCD78"
      points="57.553,50.531 30.845,65.951 30.845,35.111 			"
    />
    <polygon
      id="_x34__of_4_1_"
      fill="#9BCD78"
      points="30.842,65.952 4.134,50.532 30.842,35.112 			"
    />
    <g>
      <defs>
        <rect id="SVGID_3_" y="0.007" width="61.687" height="70.204" />
      </defs>
      <clipPath id="SVGID_4_">
        <use xlinkHref="#SVGID_3_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_4_)"
        fill="#72BE44"
        d="M30.844,4.581L57.28,19.845v30.527L30.844,65.636L4.406,50.372V19.845
					L30.844,4.581 M30.844,0.007c-0.573,0-1.146,0.147-1.659,0.443L1.658,16.344C0.633,16.937,0,18.03,0,19.216v31.785
					c0,1.186,0.633,2.279,1.658,2.872l27.526,15.894c0.513,0.296,1.086,0.443,1.659,0.443c0.572,0,1.145-0.147,1.658-0.443
					l27.526-15.894c1.027-0.593,1.658-1.687,1.658-2.872V19.216c0-1.186-0.631-2.279-1.658-2.872L32.502,0.45
					C31.988,0.154,31.416,0.007,30.844,0.007"
      />
      <polygon
        clipPath="url(#SVGID_4_)"
        fill="#008A49"
        points="22.612,33.653 19.232,33.653 19.232,29.315 22.594,29.315
					22.594,30.234 20.302,30.234 20.302,31.05 22.405,31.05 22.405,31.918 20.302,31.918 20.302,32.734 22.612,32.734 				"
      />
      <polygon
        clipPath="url(#SVGID_4_)"
        fill="#008A49"
        points="26.609,33.653 25.417,33.653 24.796,32.714 24.168,33.653
					22.982,33.653 24.051,32.08 22.982,30.396 24.168,30.396 24.796,31.406 25.417,30.396 26.609,30.396 25.534,32.08 				"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#008A49"
        d="M30.695,32.027c0,1.03-0.726,1.723-1.587,1.723
					c-0.421,0-0.757-0.129-1.01-0.356v1.36h-1.036v-4.352h1.036v0.26c0.253-0.228,0.589-0.356,1.01-0.356
					C29.97,30.306,30.695,31.005,30.695,32.027 M29.679,32.027c0-0.498-0.33-0.841-0.783-0.841c-0.421,0-0.797,0.343-0.797,0.841
					c0,0.506,0.376,0.843,0.797,0.843C29.349,32.87,29.679,32.533,29.679,32.027"
      />
      <rect
        x="31.169"
        y="28.991"
        clipPath="url(#SVGID_4_)"
        fill="#008A49"
        width="1.036"
        height="4.661"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#008A49"
        d="M36.259,32.027c0,1.03-0.815,1.718-1.781,1.718
					c-0.983,0-1.799-0.688-1.799-1.718c0-1.022,0.815-1.709,1.799-1.709C35.443,30.318,36.259,31.005,36.259,32.027 M35.262,32.027
					c0-0.505-0.382-0.841-0.784-0.841c-0.421,0-0.803,0.336-0.803,0.841c0,0.513,0.382,0.843,0.803,0.843
					C34.88,32.87,35.262,32.54,35.262,32.027"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#008A49"
        d="M39.122,30.383l-0.046,1.036h-0.188c-0.726,0-1.119,0.376-1.119,1.218v1.017
					h-1.037v-3.251h1.037v0.622c0.232-0.39,0.588-0.667,1.119-0.667C38.979,30.357,39.044,30.364,39.122,30.383"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#008A49"
        d="M42.665,32.294h-2.384c0.085,0.388,0.35,0.628,0.7,0.628
					c0.238,0,0.518-0.058,0.692-0.376l0.919,0.188c-0.278,0.687-0.868,1.017-1.611,1.017c-0.939,0-1.717-0.693-1.717-1.723
					c0-1.022,0.777-1.722,1.723-1.722c0.92,0,1.657,0.66,1.678,1.722V32.294z M40.301,31.659h1.34
					c-0.097-0.351-0.355-0.512-0.653-0.512C40.696,31.147,40.398,31.328,40.301,31.659"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#4FA246"
        d="M19.812,37.718l-1.469,0.3c-0.108-0.571-0.581-0.853-1.062-0.853
					c-0.498,0-0.816,0.272-0.816,0.616c0,0.245,0.109,0.426,0.49,0.508l1.261,0.281c1.051,0.235,1.669,0.807,1.669,1.769
					c0,1.297-1.152,1.967-2.504,1.967c-1.369,0-2.493-0.651-2.657-1.84l1.542-0.3c0.154,0.626,0.59,0.898,1.197,0.898
					c0.562,0,0.898-0.254,0.898-0.626c0-0.272-0.127-0.444-0.563-0.535l-1.27-0.272c-0.871-0.2-1.633-0.652-1.633-1.759
					c0-1.179,0.979-1.914,2.403-1.914C18.624,35.958,19.585,36.611,19.812,37.718"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#4FA246"
        d="M23.883,41.971c-0.354,0.19-0.726,0.336-1.242,0.336
					c-1.134,0-1.814-0.617-1.814-1.814V38.77h-0.824v-1.151h0.824v-1.342h1.451v1.342h1.342v1.151h-1.342v1.578
					c0,0.471,0.228,0.662,0.608,0.662c0.199,0,0.471-0.072,0.662-0.182L23.883,41.971z"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#4FA246"
        d="M28.951,42.171H27.5v-0.364c-0.353,0.319-0.824,0.499-1.414,0.499
					c-1.207,0-2.222-0.97-2.222-2.412c0-1.433,1.015-2.411,2.222-2.411c0.59,0,1.062,0.181,1.414,0.498v-0.362h1.451V42.171z
					 M27.5,39.894c0-0.697-0.525-1.178-1.114-1.178c-0.636,0-1.098,0.48-1.098,1.178c0,0.707,0.462,1.18,1.098,1.18
					C26.975,41.073,27.5,40.601,27.5,39.894"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#4FA246"
        d="M34.51,41.717c0,1.405-1.069,2.177-2.548,2.177
					c-1.043,0-1.841-0.363-2.285-1.098l1.206-0.652c0.345,0.408,0.598,0.562,1.069,0.562c0.663,0,1.135-0.39,1.135-1.105v-0.127
					c-0.327,0.28-0.78,0.436-1.37,0.436c-1.188,0-2.194-0.943-2.194-2.231c0-1.261,1.006-2.194,2.194-2.194
					c0.59,0,1.043,0.154,1.37,0.435v-0.3h1.423V41.717z M33.087,39.677c0-0.607-0.481-1.033-1.062-1.033
					c-0.635,0-1.088,0.426-1.088,1.033s0.453,1.044,1.088,1.044C32.605,40.721,33.087,40.284,33.087,39.677"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#4FA246"
        d="M39.851,40.267h-3.337c0.118,0.544,0.49,0.879,0.979,0.879
					c0.336,0,0.727-0.081,0.972-0.526l1.286,0.264c-0.39,0.962-1.215,1.424-2.258,1.424c-1.314,0-2.402-0.971-2.402-2.412
					c0-1.434,1.088-2.412,2.412-2.412c1.287,0,2.321,0.925,2.349,2.412V40.267z M36.541,39.377h1.877
					c-0.137-0.488-0.499-0.717-0.916-0.717C37.094,38.66,36.677,38.914,36.541,39.377"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#4FA246"
        d="M46.543,41.055v1.116h-1.406v-1.116H41.98v-1.342l2.711-3.617h1.852v3.762
					h0.734v1.197H46.543z M45.137,37.536l-1.641,2.321h1.641V37.536z"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#E0EED3"
        d="M26.316,25.108h-0.622c-1.647,0-2.984-1.336-2.984-2.982v-0.622
					c0-1.648,1.337-2.984,2.984-2.984h0.622c1.648,0,2.982,1.336,2.982,2.984v0.622C29.299,23.772,27.965,25.108,26.316,25.108"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#E0EED3"
        d="M35.976,25.108h-0.622c-1.647,0-2.983-1.336-2.983-2.982v-0.622
					c0-1.648,1.336-2.984,2.983-2.984h0.622c1.647,0,2.983,1.336,2.983,2.984v0.622C38.959,23.772,37.623,25.108,35.976,25.108"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#E0EED3"
        d="M30.815,23.282h-0.189c-0.5,0-0.905-0.405-0.905-0.906v-0.188
					c0-0.501,0.405-0.907,0.905-0.907h0.189c0.5,0,0.905,0.406,0.905,0.907v0.188C31.721,22.877,31.315,23.282,30.815,23.282"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#008A49"
        d="M39.119,20.792c-0.072-0.198-0.162-0.388-0.264-0.565l-0.74-1.586
					c-0.414-1.136-1.372-1.915-2.473-2.152l-0.28-0.649c-0.332-0.911-1.341-1.383-2.253-1.051c-0.913,0.332-1.383,1.34-1.051,2.253
					l0.196,0.686c-0.151,0.196-0.283,0.41-0.388,0.635c-0.274-0.239-0.631-0.385-1.023-0.385s-0.75,0.146-1.023,0.385
					c-0.105-0.225-0.236-0.438-0.389-0.635l0.197-0.686c0.332-0.913-0.139-1.921-1.051-2.253s-1.92,0.14-2.252,1.051l-0.288,0.651
					c-1.098,0.238-2.053,1.017-2.465,2.15l-0.741,1.586c-0.102,0.178-0.191,0.367-0.264,0.565c-0.68,1.865,0.283,3.929,2.148,4.607
					s3.928-0.283,4.606-2.149c0.041-0.112,0.077-0.227,0.105-0.341c0.248,0.534,0.787,0.905,1.415,0.905
					c0.627,0,1.166-0.371,1.414-0.905c0.03,0.114,0.064,0.229,0.105,0.341c0.68,1.866,2.742,2.828,4.607,2.149
					S39.798,22.657,39.119,20.792 M28.61,22.991c-0.536,1.471-2.163,2.23-3.634,1.694c-1.472-0.535-2.23-2.162-1.694-3.635
					c0.535-1.471,2.162-2.229,3.634-1.693C28.387,19.894,29.145,21.521,28.61,22.991 M30.844,23.069
					c-0.451,0-0.815-0.365-0.815-0.815c0-0.451,0.364-0.815,0.815-0.815c0.45,0,0.815,0.364,0.815,0.815
					C31.659,22.704,31.294,23.069,30.844,23.069 M36.711,24.686c-1.471,0.536-3.098-0.224-3.634-1.694
					c-0.535-1.471,0.224-3.098,1.695-3.634c1.471-0.535,3.098,0.223,3.634,1.693C38.941,22.523,38.182,24.15,36.711,24.686"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#008A49"
        d="M25.946,20.11c0.123,0,0.224-0.101,0.224-0.224
					c0-0.124-0.101-0.225-0.224-0.225c-1.3,0-2.357,1.058-2.357,2.357c0,0.123,0.102,0.225,0.224,0.225
					c0.125,0,0.225-0.102,0.225-0.225C24.037,20.967,24.895,20.11,25.946,20.11"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#008A49"
        d="M33.676,22.243c0.123,0,0.225-0.101,0.225-0.224
					c0-1.053,0.855-1.909,1.908-1.909c0.123,0,0.225-0.101,0.225-0.224c0-0.124-0.102-0.225-0.225-0.225
					c-1.3,0-2.356,1.059-2.356,2.357C33.452,22.143,33.552,22.243,33.676,22.243"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#4F86C6"
        d="M30.844,59.522c-2.639,0-4.773-2.135-4.773-4.772
					c0-2.636,2.135-4.771,4.773-4.771c2.638,0,4.772,2.136,4.772,4.771C35.616,57.388,33.481,59.522,30.844,59.522"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#FFFFFF"
        d="M30.844,49.979c2.638,0,4.772,2.136,4.772,4.771
					c0,2.638-2.135,4.772-4.772,4.772c-2.639,0-4.773-2.135-4.773-4.772C26.07,52.114,28.205,49.979,30.844,49.979 M30.844,49.566
					c-2.859,0-5.186,2.326-5.186,5.184c0,2.858,2.326,5.185,5.186,5.185c2.858,0,5.185-2.326,5.185-5.185
					C36.028,51.893,33.702,49.566,30.844,49.566"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#FFFFFF"
        d="M33.337,52.806c-0.239,0-0.464,0.107-0.62,0.251l-0.013-0.012
					c0.073-0.12,0.169-0.346,0.169-0.716c0-0.883-0.885-1.551-2.029-1.551s-2.041,0.668-2.041,1.551c0,0.37,0.119,0.596,0.18,0.716
					l-0.014,0.012c-0.153-0.144-0.381-0.251-0.619-0.251c-0.729,0-1.457,0.703-1.457,2.018c0,1.288,0.717,2.016,1.493,2.016
					c0.716,0,1.218-0.395,1.67-0.848c-0.071-0.107-0.18-0.215-0.298-0.215c-0.12,0-0.252,0.085-0.478,0.263
					c-0.298,0.228-0.562,0.359-0.907,0.359c-0.609,0-0.87-0.683-0.87-1.575c0-0.955,0.251-1.587,0.896-1.587
					c0.285,0,0.476,0.083,0.668,0.262c0.285,0.263,0.381,0.333,0.512,0.333c0.096,0,0.156-0.06,0.18-0.094
					c-0.215-0.216-0.524-0.668-0.524-1.253c0-0.668,0.56-1.135,1.61-1.135c1.049,0,1.611,0.467,1.611,1.135
					c0,0.585-0.311,1.037-0.525,1.253c0.023,0.034,0.083,0.094,0.179,0.094c0.132,0,0.227-0.07,0.513-0.333
					c0.179-0.179,0.382-0.262,0.668-0.262c0.645,0,0.895,0.632,0.895,1.587c0,0.893-0.262,1.575-0.869,1.575
					c-0.347,0-0.621-0.132-0.908-0.359c-0.227-0.178-0.357-0.263-0.477-0.263s-0.227,0.107-0.299,0.215
					c0.441,0.453,0.955,0.848,1.671,0.848c0.775,0,1.491-0.728,1.491-2.016C34.793,53.509,34.065,52.806,33.337,52.806"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#FFFFFF"
        d="M31.643,52.782h-0.537c-0.035,0-0.059-0.024-0.07-0.048l-0.168-0.513
					c-0.012-0.036-0.023-0.036-0.035,0l-0.168,0.513c-0.012,0.035-0.035,0.048-0.059,0.048h-0.537c-0.024,0-0.036,0.011-0.013,0.023
					l0.442,0.322c0.023,0.023,0.023,0.061,0.011,0.083l-0.167,0.501c-0.011,0.024-0.011,0.049,0.024,0.024l0.441-0.31
					c0.036-0.024,0.06-0.024,0.096,0l0.43,0.31c0.022,0.024,0.035,0.012,0.022-0.012l-0.166-0.514c-0.012-0.035,0-0.071,0.024-0.083
					l0.44-0.322C31.678,52.793,31.666,52.782,31.643,52.782"
      />
      <path
        clipPath="url(#SVGID_4_)"
        fill="#FFFFFF"
        d="M31.237,56.313c-0.226-0.571-0.406-0.966-0.406-1.442
					c0-0.203,0.061-0.382,0.097-0.478c0.048-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.227,0.168-0.321,0.312
					c-0.073,0.119-0.156,0.346-0.156,0.524c0,0.335,0.108,0.692,0.18,0.906c0.132,0.37,0.251,0.801,0.251,1.206
					c0,0.646-0.287,1.085-0.8,1.479c0.048,0.037,0.166,0.096,0.311,0.096c0.5,0,1.098-0.561,1.098-1.324
					C31.5,56.98,31.368,56.671,31.237,56.313"
      />
    </g>
  </g>
);

const ExploreStage3 = () => (
  <g id="Stage_3_1_">
    <polygon
      fill="#E0EED3"
      points="57.552,19.69 57.552,50.531 30.843,65.951 4.132,50.531 4.132,19.69 30.843,4.269 			"
    />
    <polygon
      id="_x31__of_3"
      fill="#9BCD78"
      points="30.843,4.265 57.552,19.685 30.843,35.105 			"
    />
    <polygon
      id="_x32__of_3"
      fill="#9BCD78"
      points="57.552,19.692 57.552,50.531 30.845,35.111 			"
    />
    <polygon
      id="_x33__of_3"
      fill="#9BCD78"
      points="57.552,50.531 30.845,65.951 30.845,35.111 			"
    />
    <g>
      <defs>
        <rect id="SVGID_5_" x="0" y="0.007" width="61.687" height="70.204" />
      </defs>
      <clipPath id="SVGID_6_">
        <use xlinkHref="#SVGID_5_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_6_)"
        fill="#72BE44"
        d="M30.843,4.582L57.28,19.845v30.528L30.843,65.637L4.406,50.373V19.845
					L30.843,4.582 M30.843,0.007c-0.572,0-1.145,0.147-1.658,0.443L1.658,16.344c-1.027,0.593-1.659,1.687-1.659,2.872v31.786
					c0,1.186,0.632,2.279,1.659,2.871l27.527,15.895c0.514,0.295,1.086,0.443,1.658,0.443s1.145-0.148,1.658-0.443l27.527-15.895
					c1.025-0.592,1.658-1.686,1.658-2.871V19.216c0-1.186-0.633-2.279-1.658-2.872L32.501,0.451
					C31.987,0.155,31.415,0.007,30.843,0.007"
      />
      <polygon
        clipPath="url(#SVGID_6_)"
        fill="#008A49"
        points="22.612,33.654 19.232,33.654 19.232,29.316 22.593,29.316
					22.593,30.235 20.301,30.235 20.301,31.05 22.405,31.05 22.405,31.918 20.301,31.918 20.301,32.735 22.612,32.735 				"
      />
      <polygon
        clipPath="url(#SVGID_6_)"
        fill="#008A49"
        points="26.608,33.654 25.417,33.654 24.796,32.714 24.167,33.654
					22.983,33.654 24.05,32.081 22.983,30.397 24.167,30.397 24.796,31.407 25.417,30.397 26.608,30.397 25.534,32.081 				"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#008A49"
        d="M30.694,32.028c0,1.031-0.725,1.722-1.586,1.722
					c-0.422,0-0.758-0.129-1.01-0.355v1.359h-1.037v-4.351h1.037v0.26c0.252-0.228,0.588-0.356,1.01-0.356
					C29.97,30.306,30.694,31.005,30.694,32.028 M29.679,32.028c0-0.498-0.33-0.841-0.783-0.841c-0.422,0-0.797,0.343-0.797,0.841
					c0,0.506,0.375,0.843,0.797,0.843C29.349,32.871,29.679,32.534,29.679,32.028"
      />
      <rect
        x="31.169"
        y="28.992"
        clipPath="url(#SVGID_6_)"
        fill="#008A49"
        width="1.035"
        height="4.661"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#008A49"
        d="M36.259,32.028c0,1.031-0.816,1.718-1.781,1.718
					c-0.984,0-1.799-0.688-1.799-1.718c0-1.022,0.814-1.709,1.799-1.709C35.442,30.319,36.259,31.005,36.259,32.028 M35.261,32.028
					c0-0.505-0.381-0.841-0.783-0.841c-0.422,0-0.803,0.336-0.803,0.841c0,0.513,0.381,0.843,0.803,0.843
					C34.88,32.871,35.261,32.541,35.261,32.028"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#008A49"
        d="M39.122,30.383l-0.047,1.036h-0.188c-0.725,0-1.119,0.376-1.119,1.219v1.016
					h-1.037v-3.251h1.037v0.622c0.232-0.389,0.59-0.667,1.119-0.667C38.979,30.358,39.044,30.365,39.122,30.383"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#008A49"
        d="M42.665,32.294H40.28c0.086,0.388,0.35,0.628,0.701,0.628
					c0.238,0,0.518-0.058,0.691-0.376l0.92,0.188c-0.279,0.685-0.869,1.015-1.611,1.015c-0.939,0-1.717-0.693-1.717-1.721
					c0-1.023,0.777-1.723,1.723-1.723c0.92,0,1.656,0.66,1.678,1.723V32.294z M40.3,31.66h1.34
					c-0.096-0.351-0.355-0.512-0.652-0.512C40.696,31.148,40.397,31.329,40.3,31.66"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#4FA246"
        d="M20.034,37.719l-1.47,0.299c-0.108-0.57-0.58-0.852-1.061-0.852
					c-0.499,0-0.816,0.271-0.816,0.615c0,0.246,0.108,0.426,0.489,0.508l1.261,0.281c1.053,0.236,1.67,0.807,1.67,1.77
					c0,1.297-1.152,1.967-2.505,1.967c-1.367,0-2.492-0.652-2.656-1.84l1.542-0.301c0.153,0.627,0.59,0.898,1.196,0.898
					c0.563,0,0.898-0.254,0.898-0.625c0-0.273-0.127-0.445-0.562-0.535l-1.271-0.273c-0.87-0.199-1.632-0.652-1.632-1.758
					c0-1.18,0.979-1.914,2.402-1.914C18.845,35.959,19.806,36.611,20.034,37.719"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#4FA246"
        d="M24.104,41.971c-0.354,0.191-0.725,0.336-1.242,0.336
					c-1.135,0-1.814-0.617-1.814-1.814V38.77h-0.825v-1.15h0.825v-1.342h1.451v1.342h1.342v1.15h-1.342v1.578
					c0,0.471,0.227,0.662,0.607,0.662c0.199,0,0.473-0.072,0.662-0.182L24.104,41.971z"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#4FA246"
        d="M29.173,42.172h-1.451v-0.365c-0.352,0.32-0.824,0.5-1.414,0.5
					c-1.207,0-2.221-0.971-2.221-2.412c0-1.434,1.014-2.41,2.221-2.41c0.59,0,1.063,0.18,1.414,0.498v-0.363h1.451V42.172z
					 M27.722,39.895c0-0.697-0.525-1.178-1.115-1.178c-0.635,0-1.096,0.48-1.096,1.178c0,0.707,0.461,1.18,1.096,1.18
					C27.196,41.074,27.722,40.602,27.722,39.895"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#4FA246"
        d="M34.731,41.717c0,1.406-1.07,2.178-2.547,2.178
					c-1.043,0-1.842-0.363-2.287-1.098l1.207-0.652c0.344,0.408,0.598,0.561,1.07,0.561c0.662,0,1.133-0.389,1.133-1.105v-0.127
					c-0.326,0.281-0.779,0.436-1.369,0.436c-1.188,0-2.195-0.943-2.195-2.23c0-1.262,1.008-2.195,2.195-2.195
					c0.59,0,1.043,0.154,1.369,0.436v-0.301h1.424V41.717z M33.308,39.678c0-0.607-0.48-1.033-1.061-1.033
					c-0.635,0-1.088,0.426-1.088,1.033s0.453,1.043,1.088,1.043C32.827,40.721,33.308,40.285,33.308,39.678"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#4FA246"
        d="M40.073,40.268h-3.338c0.117,0.543,0.49,0.879,0.979,0.879
					c0.336,0,0.727-0.082,0.971-0.527l1.287,0.264c-0.389,0.963-1.215,1.424-2.258,1.424c-1.314,0-2.402-0.971-2.402-2.412
					c0-1.434,1.088-2.412,2.412-2.412c1.287,0,2.322,0.926,2.35,2.412V40.268z M36.763,39.377h1.877
					c-0.137-0.488-0.5-0.717-0.916-0.717C37.315,38.66,36.897,38.914,36.763,39.377"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#4FA246"
        d="M46.944,40.348c0,1.152-0.934,1.959-2.266,1.959
					c-1.279,0-2.213-0.742-2.35-1.922l1.414-0.264c0.082,0.6,0.408,0.943,0.943,0.943c0.508,0,0.871-0.291,0.871-0.734
					c0-0.398-0.299-0.635-0.824-0.635h-0.816v-1.188h0.752c0.518,0,0.816-0.227,0.816-0.617c0-0.416-0.316-0.697-0.807-0.697
					s-0.854,0.299-0.908,0.979l-1.369-0.264c0.137-1.205,1.035-1.949,2.268-1.949c1.297,0,2.178,0.799,2.178,1.914
					c0,0.553-0.236,0.979-0.654,1.225C46.673,39.342,46.944,39.777,46.944,40.348"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#E0EED3"
        d="M26.315,25.109h-0.621c-1.648,0-2.984-1.336-2.984-2.982v-0.622
					c0-1.648,1.336-2.984,2.984-2.984h0.621c1.648,0,2.982,1.336,2.982,2.984v0.622C29.298,23.773,27.964,25.109,26.315,25.109"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#E0EED3"
        d="M35.976,25.109h-0.623c-1.646,0-2.982-1.336-2.982-2.982v-0.622
					c0-1.648,1.336-2.984,2.982-2.984h0.623c1.646,0,2.982,1.336,2.982,2.984v0.622C38.958,23.773,37.622,25.109,35.976,25.109"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#E0EED3"
        d="M30.815,23.283h-0.189c-0.5,0-0.906-0.405-0.906-0.906v-0.188
					c0-0.501,0.406-0.907,0.906-0.907h0.189c0.5,0,0.906,0.406,0.906,0.907v0.188C31.722,22.877,31.315,23.283,30.815,23.283"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#008A49"
        d="M39.118,20.792c-0.072-0.198-0.162-0.388-0.264-0.565l-0.74-1.586
					c-0.414-1.136-1.373-1.915-2.473-2.152L35.36,15.84c-0.332-0.912-1.34-1.384-2.252-1.052s-1.383,1.34-1.051,2.253l0.197,0.686
					c-0.152,0.197-0.283,0.41-0.389,0.635c-0.273-0.239-0.631-0.385-1.023-0.385s-0.75,0.146-1.023,0.385
					c-0.105-0.225-0.234-0.438-0.389-0.635l0.197-0.686c0.332-0.913-0.139-1.921-1.051-2.253s-1.92,0.14-2.252,1.052l-0.287,0.65
					c-1.098,0.238-2.053,1.018-2.465,2.15l-0.742,1.586c-0.102,0.178-0.191,0.367-0.264,0.565c-0.68,1.865,0.283,3.929,2.148,4.607
					s3.928-0.283,4.607-2.149c0.041-0.112,0.076-0.227,0.105-0.341c0.248,0.534,0.787,0.905,1.414,0.905s1.166-0.371,1.414-0.905
					c0.031,0.114,0.064,0.229,0.107,0.341c0.678,1.866,2.74,2.828,4.605,2.149S39.798,22.658,39.118,20.792 M28.61,22.992
					c-0.535,1.471-2.164,2.23-3.635,1.694c-1.471-0.535-2.23-2.162-1.693-3.634c0.535-1.472,2.162-2.229,3.633-1.694
					C28.386,19.894,29.144,21.521,28.61,22.992 M30.843,23.07c-0.451,0-0.814-0.365-0.814-0.815s0.363-0.815,0.814-0.815
					s0.816,0.365,0.816,0.815S31.294,23.07,30.843,23.07 M36.71,24.686c-1.471,0.536-3.098-0.224-3.633-1.694
					s0.223-3.098,1.695-3.634c1.471-0.535,3.098,0.223,3.633,1.694S38.181,24.151,36.71,24.686"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#008A49"
        d="M25.946,20.111c0.123,0,0.223-0.101,0.223-0.224
					c0-0.124-0.1-0.225-0.223-0.225c-1.301,0-2.357,1.058-2.357,2.357c0,0.123,0.1,0.225,0.223,0.225
					c0.125,0,0.225-0.102,0.225-0.225C24.036,20.967,24.892,20.111,25.946,20.111"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#008A49"
        d="M33.675,22.244c0.125,0,0.225-0.1,0.225-0.224
					c0-1.053,0.855-1.909,1.908-1.909c0.123,0,0.225-0.101,0.225-0.224c0-0.124-0.102-0.225-0.225-0.225
					c-1.299,0-2.355,1.059-2.355,2.357C33.452,22.144,33.552,22.244,33.675,22.244"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#4F86C6"
        d="M30.843,59.523c-2.639,0-4.773-2.135-4.773-4.771s2.135-4.773,4.773-4.773
					s4.773,2.137,4.773,4.773S33.481,59.523,30.843,59.523"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#FFFFFF"
        d="M30.843,49.979c2.639,0,4.773,2.137,4.773,4.773s-2.135,4.771-4.773,4.771
					s-4.773-2.135-4.773-4.771S28.204,49.979,30.843,49.979 M30.843,49.566c-2.859,0-5.186,2.326-5.186,5.186
					c0,2.857,2.326,5.184,5.186,5.184s5.186-2.326,5.186-5.184C36.028,51.893,33.702,49.566,30.843,49.566"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#FFFFFF"
        d="M33.337,52.807c-0.24,0-0.465,0.107-0.621,0.25l-0.012-0.012
					c0.072-0.119,0.168-0.346,0.168-0.715c0-0.883-0.883-1.551-2.029-1.551c-1.145,0-2.041,0.668-2.041,1.551
					c0,0.369,0.119,0.596,0.18,0.715l-0.012,0.012c-0.154-0.143-0.383-0.25-0.621-0.25c-0.729,0-1.455,0.703-1.455,2.018
					c0,1.287,0.717,2.016,1.492,2.016c0.715,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.18-0.215-0.299-0.215
					s-0.252,0.084-0.477,0.262c-0.299,0.229-0.563,0.359-0.908,0.359c-0.609,0-0.869-0.682-0.869-1.574
					c0-0.955,0.25-1.588,0.895-1.588c0.285,0,0.477,0.084,0.668,0.262c0.285,0.264,0.381,0.334,0.512,0.334
					c0.096,0,0.156-0.061,0.18-0.094c-0.215-0.217-0.523-0.668-0.523-1.254c0-0.668,0.559-1.135,1.609-1.135
					c1.049,0,1.611,0.467,1.611,1.135c0,0.586-0.311,1.037-0.525,1.254c0.023,0.033,0.084,0.094,0.18,0.094
					c0.131,0,0.227-0.07,0.512-0.334c0.18-0.178,0.383-0.262,0.668-0.262c0.645,0,0.895,0.633,0.895,1.588
					c0,0.893-0.262,1.574-0.869,1.574c-0.346,0-0.621-0.131-0.906-0.359c-0.229-0.178-0.359-0.262-0.479-0.262
					s-0.227,0.107-0.299,0.215c0.441,0.453,0.955,0.848,1.672,0.848c0.775,0,1.49-0.729,1.49-2.016
					C34.792,53.51,34.065,52.807,33.337,52.807"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#FFFFFF"
        d="M31.642,52.783h-0.537c-0.035,0-0.059-0.025-0.07-0.049l-0.168-0.512
					c-0.012-0.035-0.023-0.035-0.035,0l-0.166,0.512c-0.014,0.037-0.037,0.049-0.061,0.049h-0.537c-0.023,0-0.035,0.01-0.014,0.023
					l0.443,0.322c0.023,0.023,0.023,0.061,0.012,0.082l-0.168,0.502c-0.01,0.023-0.01,0.049,0.025,0.023l0.441-0.309
					c0.035-0.025,0.059-0.025,0.096,0l0.43,0.309c0.021,0.025,0.035,0.012,0.021-0.01l-0.164-0.516c-0.014-0.035,0-0.07,0.023-0.082
					l0.439-0.322C31.679,52.793,31.665,52.783,31.642,52.783"
      />
      <path
        clipPath="url(#SVGID_6_)"
        fill="#FFFFFF"
        d="M31.237,56.314c-0.227-0.572-0.406-0.967-0.406-1.443
					c0-0.203,0.061-0.383,0.096-0.479c0.049-0.096,0.061-0.154,0.012-0.154c-0.072,0-0.227,0.166-0.322,0.311
					c-0.072,0.119-0.156,0.346-0.156,0.523c0,0.336,0.109,0.693,0.18,0.908c0.133,0.369,0.252,0.799,0.252,1.203
					c0,0.646-0.287,1.086-0.801,1.48c0.047,0.037,0.168,0.096,0.311,0.096c0.5,0,1.098-0.561,1.098-1.324
					C31.499,56.982,31.368,56.672,31.237,56.314"
      />
    </g>
  </g>
);
// #endregion

// #startregion Camp Groups
const CampStage5 = () => (
  <g id="Stage_5_2_">
    <polygon
      fill="#E0EED3"
      points="57.553,19.689 57.553,50.531 30.843,65.951 4.133,50.531 4.133,19.689 30.843,4.269 			"
    />
    <polygon
      id="_x31__of_5_1_"
      fill="#9BCD78"
      points="30.844,4.265 57.552,19.686 30.844,35.105 			"
    />
    <polygon
      id="_x32__of_5_1_"
      fill="#9BCD78"
      points="57.553,19.691 57.553,50.531 30.845,35.111 			"
    />
    <polygon
      id="_x33__of_5_1_"
      fill="#9BCD78"
      points="57.553,50.531 30.845,65.951 30.845,35.111 			"
    />
    <polygon
      id="_x34__of_5_1_"
      fill="#9BCD78"
      points="30.842,65.952 4.133,50.532 30.842,35.112 			"
    />
    <polygon
      id="_x35__of_5_1_"
      fill="#9BCD78"
      points="30.844,35.111 4.136,50.531 4.136,19.691 			"
    />
    <g>
      <defs>
        <rect id="SVGID_7_" x="0" y="0.007" width="61.687" height="70.204" />
      </defs>
      <clipPath id="SVGID_8_">
        <use xlinkHref="#SVGID_7_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_8_)"
        fill="#72BE44"
        d="M30.843,4.582L57.28,19.845v30.528L30.843,65.636L4.406,50.373V19.845
					L30.843,4.582 M30.843,0.007c-0.573,0-1.145,0.147-1.658,0.444L1.658,16.344C0.631,16.937,0,18.03,0,19.216v31.786
					c0,1.185,0.631,2.278,1.658,2.872l27.526,15.892c0.514,0.297,1.085,0.445,1.658,0.445s1.146-0.148,1.658-0.445l27.527-15.892
					c1.026-0.594,1.657-1.688,1.657-2.872V19.216c0-1.186-0.631-2.279-1.657-2.872L32.501,0.451
					C31.988,0.154,31.416,0.007,30.843,0.007"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#008A49"
        d="M25.935,32.028c-0.201,1.088-1.074,1.722-2.188,1.722
					c-1.288,0-2.306-0.99-2.306-2.266c0-1.27,1.018-2.267,2.306-2.267c1.068,0,1.975,0.635,2.182,1.664l-1.048,0.246
					c-0.137-0.628-0.577-0.933-1.134-0.933c-0.687,0-1.236,0.551-1.236,1.289s0.55,1.295,1.236,1.295
					c0.551,0,0.991-0.312,1.14-0.958L25.935,32.028z"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#008A49"
        d="M29.892,33.653h-1.035v-0.259c-0.252,0.227-0.591,0.355-1.011,0.355
					c-0.861,0-1.586-0.691-1.586-1.722c0-1.023,0.725-1.722,1.586-1.722c0.42,0,0.759,0.129,1.011,0.355v-0.259h1.035V33.653z
					 M28.856,32.028c0-0.498-0.377-0.842-0.797-0.842c-0.453,0-0.783,0.344-0.783,0.842c0,0.505,0.33,0.842,0.783,0.842
					C28.479,32.87,28.856,32.533,28.856,32.028"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#008A49"
        d="M36.031,31.62v2.033h-1.03V31.84c0-0.368-0.238-0.602-0.544-0.602
					c-0.381,0-0.634,0.278-0.634,0.822v1.593h-1.037V31.84c0-0.368-0.238-0.602-0.55-0.602c-0.375,0-0.621,0.278-0.621,0.822v1.593
					h-1.036v-3.251h1.036V30.7c0.22-0.246,0.537-0.395,0.958-0.395c0.453,0,0.842,0.194,1.063,0.551
					c0.259-0.337,0.646-0.551,1.151-0.551C35.481,30.306,36.031,30.83,36.031,31.62"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#008A49"
        d="M40.307,32.028c0,1.03-0.726,1.722-1.587,1.722
					c-0.421,0-0.758-0.129-1.01-0.355v1.359h-1.036v-4.351h1.036v0.259c0.252-0.227,0.589-0.355,1.01-0.355
					C39.581,30.307,40.307,31.005,40.307,32.028 M39.29,32.028c0-0.498-0.332-0.842-0.784-0.842c-0.42,0-0.796,0.344-0.796,0.842
					c0,0.505,0.376,0.842,0.796,0.842C38.958,32.87,39.29,32.533,39.29,32.028"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#4FA246"
        d="M20.038,37.718l-1.469,0.299c-0.109-0.57-0.58-0.852-1.062-0.852
					c-0.499,0-0.815,0.272-0.815,0.617c0,0.244,0.108,0.426,0.489,0.508l1.261,0.28c1.052,0.235,1.669,0.808,1.669,1.769
					c0,1.297-1.152,1.968-2.503,1.968c-1.369,0-2.494-0.652-2.657-1.841l1.541-0.299c0.155,0.625,0.59,0.897,1.196,0.897
					c0.563,0,0.898-0.255,0.898-0.627c0-0.271-0.127-0.443-0.562-0.533l-1.271-0.273c-0.87-0.199-1.632-0.652-1.632-1.759
					c0-1.179,0.979-1.914,2.403-1.914C18.85,35.958,19.812,36.612,20.038,37.718"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#4FA246"
        d="M24.109,41.971c-0.354,0.191-0.726,0.336-1.242,0.336
					c-1.134,0-1.813-0.617-1.813-1.813V38.77h-0.825v-1.151h0.825v-1.343h1.45v1.343h1.343v1.151h-1.343v1.578
					c0,0.472,0.228,0.662,0.608,0.662c0.2,0,0.471-0.073,0.662-0.181L24.109,41.971z"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#4FA246"
        d="M29.179,42.17h-1.451v-0.362c-0.354,0.317-0.825,0.499-1.414,0.499
					c-1.206,0-2.223-0.971-2.223-2.412c0-1.434,1.017-2.412,2.223-2.412c0.589,0,1.061,0.181,1.414,0.499v-0.363h1.451V42.17z
					 M27.728,39.895c0-0.698-0.525-1.18-1.115-1.18c-0.635,0-1.098,0.481-1.098,1.18c0,0.707,0.463,1.179,1.098,1.179
					C27.202,41.073,27.728,40.602,27.728,39.895"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#4FA246"
        d="M34.737,41.717c0,1.406-1.07,2.177-2.548,2.177
					c-1.044,0-1.841-0.362-2.286-1.097l1.206-0.654c0.345,0.409,0.599,0.563,1.07,0.563c0.663,0,1.134-0.391,1.134-1.107v-0.127
					c-0.327,0.282-0.78,0.437-1.369,0.437c-1.189,0-2.195-0.943-2.195-2.231c0-1.261,1.006-2.194,2.195-2.194
					c0.589,0,1.042,0.154,1.369,0.435v-0.299h1.424V41.717z M33.313,39.677c0-0.608-0.481-1.034-1.062-1.034
					c-0.635,0-1.088,0.426-1.088,1.034c0,0.607,0.453,1.043,1.088,1.043C32.832,40.72,33.313,40.284,33.313,39.677"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#4FA246"
        d="M40.077,40.267H36.74c0.118,0.544,0.49,0.879,0.979,0.879
					c0.336,0,0.726-0.081,0.971-0.526l1.287,0.264c-0.391,0.962-1.215,1.424-2.258,1.424c-1.314,0-2.403-0.97-2.403-2.412
					c0-1.433,1.089-2.412,2.412-2.412c1.288,0,2.321,0.925,2.349,2.412V40.267z M36.768,39.378h1.877
					c-0.137-0.49-0.499-0.718-0.916-0.718C37.32,38.66,36.904,38.914,36.768,39.378"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#4FA246"
        d="M44.829,38.226c1.342,0,2.14,0.771,2.14,1.959
					c0,1.251-0.907,2.122-2.303,2.122c-1.251,0-2.313-0.717-2.35-2.086l1.415-0.281c0.008,0.789,0.462,1.098,0.96,1.098
					c0.518,0,0.871-0.335,0.871-0.834c0-0.454-0.344-0.753-0.871-0.753h-2.085l0.109-3.355h3.99v1.297h-2.686l-0.036,0.834H44.829z"
      />
      <polygon
        clipPath="url(#SVGID_8_)"
        fill="#E0EED3"
        points="36.291,22.742 30.517,22.742 28.354,18.622 36.291,18.622 				"
      />
      <polygon
        clipPath="url(#SVGID_8_)"
        fill="#E0EED3"
        points="22.872,24.896 26.078,19.479 29.282,24.896 				"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#008A49"
        d="M40.277,24.703h-0.591l-4.321-7.832l0.674-1.222
					c0.06-0.108,0.021-0.245-0.087-0.304c-0.109-0.061-0.246-0.021-0.305,0.087l-0.538,0.976l-0.538-0.976
					c-0.061-0.107-0.196-0.147-0.305-0.087c-0.107,0.059-0.147,0.195-0.088,0.304l0.66,1.196h-7.854l0.66-1.196
					c0.06-0.108,0.021-0.245-0.088-0.306c-0.108-0.059-0.244-0.02-0.305,0.089l-0.538,0.976l-0.538-0.976
					c-0.06-0.107-0.194-0.147-0.304-0.089c-0.108,0.061-0.147,0.197-0.088,0.306l0.674,1.222l4.569,8.28h9.249
					c0.124,0,0.224-0.101,0.224-0.224C40.501,24.803,40.401,24.703,40.277,24.703 M34.521,19.877l1.112,2.019h-3.716l-1.115-2.019
					H34.521z"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#008A49"
        d="M26.204,17.336l-4.066,7.367h-0.729c-0.123,0-0.224,0.101-0.224,0.225
					s0.101,0.224,0.224,0.224h9.106L26.204,17.336z M28.364,24.703h-4.296l2.148-3.805L28.364,24.703z"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#4F86C6"
        d="M30.843,59.522c-2.637,0-4.772-2.136-4.772-4.771
					c0-2.637,2.136-4.772,4.772-4.772c2.639,0,4.774,2.136,4.774,4.772C35.617,57.387,33.481,59.522,30.843,59.522"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#FFFFFF"
        d="M30.843,49.978c2.639,0,4.774,2.137,4.774,4.773
					c0,2.636-2.136,4.772-4.774,4.772c-2.638,0-4.772-2.137-4.772-4.772C26.07,52.114,28.205,49.978,30.843,49.978 M30.843,49.567
					c-2.858,0-5.185,2.324-5.185,5.184c0,2.858,2.326,5.183,5.185,5.183c2.86,0,5.187-2.324,5.187-5.183
					C36.029,51.892,33.703,49.567,30.843,49.567"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#FFFFFF"
        d="M33.337,52.806c-0.239,0-0.465,0.108-0.62,0.251l-0.013-0.012
					c0.072-0.119,0.168-0.346,0.168-0.716c0-0.883-0.884-1.552-2.028-1.552c-1.146,0-2.041,0.669-2.041,1.552
					c0,0.37,0.12,0.597,0.179,0.716l-0.012,0.012c-0.155-0.143-0.382-0.251-0.62-0.251c-0.729,0-1.456,0.704-1.456,2.018
					c0,1.288,0.716,2.016,1.492,2.016c0.716,0,1.217-0.396,1.67-0.847c-0.072-0.108-0.18-0.217-0.298-0.217
					c-0.119,0-0.252,0.086-0.478,0.265c-0.299,0.227-0.562,0.357-0.908,0.357c-0.608,0-0.869-0.682-0.869-1.574
					c0-0.955,0.251-1.587,0.895-1.587c0.286,0,0.477,0.082,0.668,0.262c0.286,0.263,0.381,0.334,0.512,0.334
					c0.097,0,0.156-0.061,0.181-0.095c-0.215-0.216-0.525-0.669-0.525-1.254c0-0.668,0.561-1.133,1.611-1.133
					c1.049,0,1.61,0.465,1.61,1.133c0,0.585-0.311,1.038-0.524,1.254c0.022,0.034,0.083,0.095,0.179,0.095
					c0.131,0,0.227-0.071,0.512-0.334c0.18-0.18,0.383-0.262,0.668-0.262c0.646,0,0.896,0.632,0.896,1.587
					c0,0.893-0.263,1.574-0.871,1.574c-0.346,0-0.62-0.131-0.907-0.357c-0.227-0.179-0.357-0.265-0.477-0.265
					c-0.12,0-0.228,0.108-0.299,0.217c0.441,0.451,0.955,0.847,1.671,0.847c0.775,0,1.491-0.728,1.491-2.016
					C34.793,53.51,34.064,52.806,33.337,52.806"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#FFFFFF"
        d="M31.643,52.782h-0.537c-0.035,0-0.059-0.023-0.071-0.048l-0.168-0.513
					c-0.012-0.035-0.022-0.035-0.035,0l-0.166,0.513c-0.013,0.036-0.036,0.048-0.061,0.048h-0.537c-0.023,0-0.035,0.011-0.012,0.023
					l0.441,0.322c0.023,0.023,0.023,0.061,0.012,0.083l-0.167,0.501c-0.011,0.024-0.011,0.049,0.024,0.024l0.441-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.431,0.309c0.022,0.024,0.035,0.012,0.022-0.012l-0.166-0.514
					c-0.012-0.034,0-0.07,0.024-0.083l0.439-0.322C31.679,52.793,31.667,52.782,31.643,52.782"
      />
      <path
        clipPath="url(#SVGID_8_)"
        fill="#FFFFFF"
        d="M31.237,56.313c-0.227-0.573-0.406-0.966-0.406-1.443
					c0-0.203,0.061-0.382,0.096-0.477c0.048-0.097,0.061-0.156,0.012-0.156c-0.071,0-0.227,0.167-0.322,0.311
					c-0.072,0.119-0.155,0.347-0.155,0.524c0,0.335,0.108,0.693,0.18,0.907c0.132,0.371,0.251,0.8,0.251,1.205
					c0,0.645-0.287,1.085-0.801,1.479c0.049,0.037,0.168,0.096,0.312,0.096c0.5,0,1.097-0.561,1.097-1.323
					C31.499,56.981,31.368,56.671,31.237,56.313"
      />
    </g>
  </g>
);

const CampStage4 = () => (
  <g id="Stage_4_1_">
    <polygon
      fill="#E0EED3"
      points="57.553,19.69 57.553,50.531 30.843,65.951 4.133,50.531 4.133,19.69 30.843,4.269 			"
    />
    <polygon
      id="_x31__of_4"
      fill="#9BCD78"
      points="30.844,4.265 57.552,19.685 30.844,35.105 			"
    />
    <polygon
      id="_x32__of_4"
      fill="#9BCD78"
      points="57.552,19.692 57.552,50.531 30.844,35.111 			"
    />
    <polygon
      id="_x33__of_4"
      fill="#9BCD78"
      points="57.552,50.531 30.844,65.951 30.844,35.111 			"
    />
    <polygon
      id="_x34__of_4"
      fill="#9BCD78"
      points="30.842,65.953 4.134,50.533 30.842,35.113 			"
    />
    <g>
      <path
        fill="#72BE44"
        d="M30.843,4.582L57.28,19.845v30.528L30.843,65.637L4.406,50.373V19.845L30.843,4.582 M30.843,0.007
					c-0.573,0-1.146,0.147-1.659,0.444L1.658,16.344c-1.026,0.593-1.659,1.687-1.659,2.872v31.786c0,1.186,0.633,2.279,1.659,2.871
					l27.526,15.893c0.513,0.297,1.086,0.445,1.659,0.445c0.572,0,1.146-0.148,1.658-0.445l27.527-15.893
					c1.026-0.592,1.658-1.686,1.658-2.871V19.216c0-1.186-0.632-2.279-1.658-2.872L32.501,0.452
					C31.988,0.155,31.415,0.007,30.843,0.007"
      />
      <path
        fill="#008A49"
        d="M25.935,32.029c-0.201,1.088-1.075,1.721-2.189,1.721c-1.288,0-2.305-0.99-2.305-2.265
					c0-1.27,1.017-2.267,2.305-2.267c1.069,0,1.975,0.635,2.182,1.664l-1.048,0.246c-0.136-0.628-0.577-0.933-1.134-0.933
					c-0.686,0-1.236,0.551-1.236,1.289c0,0.737,0.55,1.295,1.236,1.295c0.551,0,0.991-0.312,1.14-0.958L25.935,32.029z"
      />
      <path
        fill="#008A49"
        d="M29.892,33.654h-1.036v-0.26c-0.252,0.227-0.59,0.355-1.01,0.355c-0.862,0-1.587-0.691-1.587-1.721
					c0-1.023,0.725-1.723,1.587-1.723c0.42,0,0.758,0.13,1.01,0.356v-0.259h1.036V33.654z M28.856,32.029
					c0-0.498-0.376-0.842-0.796-0.842c-0.454,0-0.784,0.344-0.784,0.842c0,0.505,0.33,0.842,0.784,0.842
					C28.48,32.871,28.856,32.534,28.856,32.029"
      />
      <path
        fill="#008A49"
        d="M36.031,31.621v2.034h-1.029V31.84c0-0.369-0.24-0.602-0.544-0.602c-0.382,0-0.636,0.278-0.636,0.822
					v1.593h-1.035V31.84c0-0.369-0.239-0.602-0.55-0.602c-0.376,0-0.622,0.278-0.622,0.822v1.593h-1.036v-3.251h1.036v0.298
					c0.22-0.246,0.538-0.395,0.958-0.395c0.455,0,0.842,0.194,1.063,0.551c0.259-0.337,0.647-0.551,1.152-0.551
					C35.48,30.306,36.031,30.831,36.031,31.621"
      />
      <path
        fill="#008A49"
        d="M40.307,32.029c0,1.03-0.727,1.721-1.587,1.721c-0.421,0-0.757-0.129-1.011-0.355v1.359h-1.035v-4.35
					h1.035v0.259c0.254-0.227,0.59-0.356,1.011-0.356C39.58,30.306,40.307,31.005,40.307,32.029 M39.289,32.029
					c0-0.498-0.33-0.842-0.783-0.842c-0.42,0-0.797,0.344-0.797,0.842c0,0.505,0.377,0.842,0.797,0.842
					C38.959,32.871,39.289,32.534,39.289,32.029"
      />
      <path
        fill="#4FA246"
        d="M19.812,37.719l-1.469,0.299c-0.109-0.57-0.581-0.852-1.062-0.852c-0.498,0-0.816,0.271-0.816,0.617
					c0,0.242,0.109,0.426,0.49,0.508l1.261,0.279c1.051,0.236,1.669,0.809,1.669,1.77c0,1.297-1.152,1.967-2.504,1.967
					c-1.369,0-2.493-0.652-2.657-1.84l1.542-0.299c0.154,0.625,0.59,0.896,1.197,0.896c0.562,0,0.898-0.254,0.898-0.627
					c0-0.27-0.127-0.443-0.562-0.533l-1.27-0.273c-0.871-0.199-1.632-0.652-1.632-1.758c0-1.18,0.979-1.914,2.403-1.914
					C18.623,35.959,19.584,36.613,19.812,37.719"
      />
      <path
        fill="#4FA246"
        d="M23.882,41.971c-0.354,0.191-0.725,0.336-1.242,0.336c-1.134,0-1.814-0.617-1.814-1.813V38.77h-0.825
					v-1.15h0.825v-1.344h1.451v1.344h1.342v1.15h-1.342v1.578c0,0.471,0.227,0.662,0.608,0.662c0.199,0,0.471-0.072,0.662-0.18
					L23.882,41.971z"
      />
      <path
        fill="#4FA246"
        d="M28.951,42.17H27.5v-0.361c-0.353,0.316-0.825,0.498-1.414,0.498c-1.207,0-2.222-0.971-2.222-2.412
					c0-1.434,1.015-2.412,2.222-2.412c0.589,0,1.061,0.182,1.414,0.5v-0.363h1.451V42.17z M27.5,39.895
					c0-0.697-0.526-1.18-1.115-1.18c-0.635,0-1.097,0.482-1.097,1.18c0,0.707,0.462,1.18,1.097,1.18
					C26.974,41.074,27.5,40.602,27.5,39.895"
      />
      <path
        fill="#4FA246"
        d="M34.51,41.717c0,1.406-1.07,2.178-2.549,2.178c-1.043,0-1.841-0.363-2.285-1.098l1.206-0.654
					c0.345,0.41,0.598,0.564,1.07,0.564c0.663,0,1.134-0.391,1.134-1.107v-0.127c-0.327,0.281-0.78,0.436-1.37,0.436
					c-1.188,0-2.194-0.943-2.194-2.23c0-1.262,1.006-2.195,2.194-2.195c0.59,0,1.043,0.154,1.37,0.436v-0.301h1.424V41.717z
					 M33.086,39.678c0-0.609-0.481-1.035-1.061-1.035c-0.635,0-1.088,0.426-1.088,1.035c0,0.605,0.453,1.043,1.088,1.043
					C32.605,40.721,33.086,40.283,33.086,39.678"
      />
      <path
        fill="#4FA246"
        d="M39.851,40.268h-3.337c0.117,0.543,0.489,0.879,0.979,0.879c0.336,0,0.726-0.082,0.971-0.527
					l1.287,0.264c-0.391,0.963-1.215,1.424-2.258,1.424c-1.315,0-2.403-0.971-2.403-2.412c0-1.434,1.088-2.412,2.412-2.412
					c1.288,0,2.322,0.926,2.35,2.412V40.268z M36.54,39.377h1.877c-0.136-0.488-0.499-0.717-0.916-0.717
					C37.093,38.66,36.676,38.914,36.54,39.377"
      />
      <path
        fill="#4FA246"
        d="M46.542,41.057v1.115h-1.405v-1.115H41.98v-1.344l2.711-3.617h1.851v3.762h0.734v1.199H46.542z
					 M45.137,37.537l-1.642,2.32h1.642V37.537z"
      />
      <polygon
        fill="#E0EED3"
        points="36.291,22.743 30.516,22.743 28.354,18.622 36.291,18.622 				"
      />
      <polygon
        fill="#E0EED3"
        points="22.872,24.896 26.078,19.479 29.283,24.896 				"
      />
      <path
        fill="#008A49"
        d="M40.276,24.704h-0.59l-4.321-7.832l0.674-1.222c0.06-0.108,0.021-0.245-0.088-0.305
					s-0.244-0.021-0.305,0.087l-0.537,0.977l-0.537-0.977c-0.061-0.106-0.197-0.146-0.306-0.087s-0.147,0.196-0.087,0.305
					l0.659,1.195h-7.854l0.659-1.195c0.06-0.108,0.021-0.245-0.087-0.306c-0.108-0.059-0.245-0.02-0.304,0.088l-0.538,0.977
					l-0.539-0.977c-0.059-0.106-0.195-0.146-0.303-0.088c-0.108,0.061-0.148,0.197-0.088,0.306l0.673,1.222l4.569,8.28h9.249
					c0.125,0,0.225-0.101,0.225-0.225S40.401,24.704,40.276,24.704 M34.521,19.877l1.113,2.019h-3.716l-1.114-2.019H34.521z"
      />
      <path
        fill="#008A49"
        d="M26.203,17.335l-4.066,7.368h-0.729c-0.123,0-0.223,0.101-0.223,0.225s0.1,0.224,0.223,0.224h9.108
					L26.203,17.335z M28.364,24.704h-4.295l2.147-3.805L28.364,24.704z"
      />
      <path
        fill="#4F86C6"
        d="M30.843,59.523c-2.638,0-4.773-2.137-4.773-4.773c0-2.635,2.135-4.771,4.773-4.771
					c2.637,0,4.773,2.137,4.773,4.771C35.616,57.387,33.48,59.523,30.843,59.523"
      />
      <path
        fill="#FFFFFF"
        d="M30.843,49.979c2.637,0,4.773,2.137,4.773,4.773c0,2.635-2.136,4.771-4.773,4.771
					c-2.638,0-4.773-2.137-4.773-4.771C26.07,52.115,28.205,49.979,30.843,49.979 M30.843,49.566c-2.859,0-5.185,2.326-5.185,5.186
					c0,2.857,2.326,5.182,5.185,5.182s5.185-2.324,5.185-5.182C36.028,51.893,33.702,49.566,30.843,49.566"
      />
      <path
        fill="#FFFFFF"
        d="M33.337,52.807c-0.239,0-0.464,0.107-0.62,0.25l-0.013-0.012c0.073-0.119,0.169-0.346,0.169-0.715
					c0-0.885-0.885-1.553-2.029-1.553c-1.145,0-2.041,0.668-2.041,1.553c0,0.369,0.119,0.596,0.179,0.715l-0.013,0.012
					c-0.154-0.143-0.381-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.016c0,1.289,0.716,2.018,1.493,2.018
					c0.715,0,1.217-0.396,1.67-0.848c-0.072-0.107-0.18-0.217-0.298-0.217c-0.12,0-0.252,0.086-0.478,0.266
					c-0.298,0.227-0.561,0.357-0.907,0.357c-0.609,0-0.87-0.682-0.87-1.576c0-0.955,0.251-1.586,0.895-1.586
					c0.286,0,0.476,0.082,0.668,0.262c0.286,0.264,0.381,0.334,0.512,0.334c0.096,0,0.156-0.061,0.18-0.096
					c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.668,0.56-1.133,1.611-1.133c1.049,0,1.611,0.465,1.611,1.133
					c0,0.584-0.311,1.037-0.525,1.252c0.023,0.035,0.083,0.096,0.178,0.096c0.132,0,0.227-0.07,0.513-0.334
					c0.179-0.18,0.381-0.262,0.668-0.262c0.645,0,0.895,0.631,0.895,1.586c0,0.895-0.262,1.576-0.87,1.576
					c-0.346,0-0.621-0.131-0.908-0.357c-0.226-0.18-0.357-0.266-0.476-0.266c-0.12,0-0.227,0.109-0.299,0.217
					c0.441,0.451,0.955,0.848,1.671,0.848c0.774,0,1.491-0.729,1.491-2.018C34.793,53.51,34.064,52.807,33.337,52.807"
      />
      <path
        fill="#FFFFFF"
        d="M31.643,52.783h-0.537c-0.036,0-0.059-0.025-0.071-0.049l-0.168-0.514c-0.012-0.033-0.023-0.033-0.035,0
					l-0.168,0.514c-0.012,0.037-0.035,0.049-0.059,0.049h-0.537c-0.024,0-0.036,0.01-0.012,0.021l0.442,0.324
					c0.023,0.023,0.023,0.061,0.011,0.082l-0.167,0.502c-0.011,0.023-0.011,0.047,0.024,0.023l0.442-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.023,0.035,0.012,0.023-0.012l-0.166-0.514
					c-0.012-0.035,0-0.07,0.024-0.082l0.44-0.324C31.678,52.793,31.666,52.783,31.643,52.783"
      />
      <path
        fill="#FFFFFF"
        d="M31.237,56.313c-0.226-0.572-0.406-0.965-0.406-1.441c0-0.203,0.06-0.383,0.096-0.479
					c0.048-0.096,0.06-0.154,0.011-0.154c-0.071,0-0.226,0.166-0.321,0.311c-0.073,0.119-0.156,0.346-0.156,0.523
					c0,0.336,0.108,0.693,0.179,0.906c0.132,0.371,0.251,0.801,0.251,1.205c0,0.646-0.287,1.086-0.799,1.48
					c0.047,0.037,0.166,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.324C31.5,56.982,31.368,56.672,31.237,56.313"
      />
    </g>
  </g>
);

const CampStage3 = () => (
  <g id="Stage_3_2_">
    <polygon
      fill="#E0EED3"
      points="57.552,19.693 57.552,50.535 30.841,65.956 4.131,50.535 4.131,19.693 30.841,4.272 			"
    />
    <polygon
      id="_x31__of_3_1_"
      fill="#9BCD78"
      points="30.843,4.269 57.551,19.688 30.843,35.109 			"
    />
    <polygon
      id="_x32__of_3_1_"
      fill="#9BCD78"
      points="57.551,19.695 57.551,50.535 30.843,35.115 			"
    />
    <polygon
      id="_x33__of_3_1_"
      fill="#9BCD78"
      points="57.551,50.535 30.843,65.955 30.843,35.115 			"
    />
    <g>
      <defs>
        <rect
          id="SVGID_9_"
          x="-0.002"
          y="0.011"
          width="61.688"
          height="70.204"
        />
      </defs>
      <clipPath id="SVGID_10_">
        <use xlinkHref="#SVGID_9_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_10_)"
        fill="#72BE44"
        d="M30.842,4.586l26.438,15.263v30.528L30.842,65.64L4.405,50.377V19.849
					L30.842,4.586 M30.842,0.011c-0.573,0-1.145,0.147-1.659,0.443L1.657,16.348C0.63,16.94-0.002,18.034-0.002,19.22v31.786
					c0,1.185,0.632,2.278,1.659,2.871L29.183,69.77c0.514,0.297,1.086,0.445,1.659,0.445c0.573,0,1.145-0.148,1.658-0.445
					l27.528-15.893c1.025-0.593,1.657-1.687,1.657-2.871V19.22c0-1.186-0.632-2.279-1.657-2.872L32.5,0.454
					C31.987,0.158,31.415,0.011,30.842,0.011"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#008A49"
        d="M25.934,32.032c-0.201,1.088-1.075,1.722-2.189,1.722
					c-1.288,0-2.305-0.99-2.305-2.266c0-1.27,1.017-2.267,2.305-2.267c1.069,0,1.975,0.634,2.182,1.664l-1.048,0.246
					c-0.136-0.628-0.577-0.932-1.134-0.932c-0.686,0-1.236,0.55-1.236,1.288c0,0.737,0.55,1.295,1.236,1.295
					c0.551,0,0.991-0.312,1.14-0.958L25.934,32.032z"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#008A49"
        d="M29.891,33.657h-1.036v-0.26c-0.252,0.228-0.589,0.356-1.01,0.356
					c-0.862,0-1.586-0.692-1.586-1.722c0-1.023,0.724-1.723,1.586-1.723c0.421,0,0.758,0.129,1.01,0.356v-0.259h1.036V33.657z
					 M28.854,32.032c0-0.498-0.376-0.842-0.796-0.842c-0.454,0-0.784,0.344-0.784,0.842c0,0.505,0.33,0.842,0.784,0.842
					C28.479,32.874,28.854,32.537,28.854,32.032"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#008A49"
        d="M36.03,31.624v2.033H35v-1.813c0-0.369-0.239-0.602-0.544-0.602
					c-0.382,0-0.634,0.277-0.634,0.821v1.594h-1.036v-1.813c0-0.369-0.239-0.602-0.55-0.602c-0.376,0-0.622,0.277-0.622,0.821v1.594
					h-1.036v-3.251h1.036v0.299c0.22-0.246,0.538-0.396,0.958-0.396c0.453,0,0.842,0.195,1.062,0.552
					c0.259-0.337,0.648-0.552,1.153-0.552C35.48,30.309,36.03,30.834,36.03,31.624"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#008A49"
        d="M40.305,32.032c0,1.029-0.726,1.722-1.586,1.722
					c-0.422,0-0.758-0.129-1.011-0.356v1.36h-1.036v-4.351h1.036v0.259c0.253-0.228,0.589-0.356,1.011-0.356
					C39.579,30.31,40.305,31.009,40.305,32.032 M39.288,32.032c0-0.498-0.33-0.842-0.782-0.842c-0.422,0-0.798,0.344-0.798,0.842
					c0,0.505,0.376,0.842,0.798,0.842C38.958,32.874,39.288,32.537,39.288,32.032"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#4FA246"
        d="M20.032,37.722l-1.469,0.299c-0.109-0.57-0.581-0.852-1.061-0.852
					c-0.499,0-0.816,0.271-0.816,0.617c0,0.243,0.108,0.426,0.489,0.508l1.261,0.281c1.052,0.235,1.669,0.807,1.669,1.768
					c0,1.297-1.152,1.968-2.504,1.968c-1.368,0-2.493-0.652-2.657-1.841l1.542-0.299c0.154,0.626,0.59,0.896,1.197,0.896
					c0.562,0,0.898-0.254,0.898-0.625c0-0.271-0.127-0.443-0.562-0.534l-1.27-0.273c-0.87-0.199-1.632-0.652-1.632-1.759
					c0-1.18,0.979-1.914,2.403-1.914C18.844,35.962,19.805,36.615,20.032,37.722"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#4FA246"
        d="M24.103,41.975c-0.354,0.191-0.725,0.336-1.242,0.336
					c-1.134,0-1.814-0.617-1.814-1.813v-1.724h-0.825v-1.15h0.825V36.28h1.451v1.343h1.342v1.15h-1.342v1.578
					c0,0.471,0.227,0.662,0.608,0.662c0.199,0,0.471-0.073,0.662-0.181L24.103,41.975z"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#4FA246"
        d="M29.172,42.175h-1.451v-0.364c-0.353,0.318-0.825,0.5-1.414,0.5
					c-1.207,0-2.222-0.971-2.222-2.412c0-1.434,1.015-2.412,2.222-2.412c0.589,0,1.061,0.181,1.414,0.499v-0.363h1.451V42.175z
					 M27.721,39.898c0-0.698-0.526-1.179-1.115-1.179c-0.635,0-1.097,0.48-1.097,1.179c0,0.707,0.462,1.179,1.097,1.179
					C27.195,41.077,27.721,40.605,27.721,39.898"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#4FA246"
        d="M34.73,41.721c0,1.406-1.069,2.177-2.547,2.177
					c-1.043,0-1.841-0.363-2.286-1.097l1.206-0.653c0.345,0.408,0.599,0.562,1.07,0.562c0.663,0,1.134-0.39,1.134-1.105v-0.127
					c-0.326,0.281-0.78,0.435-1.369,0.435c-1.188,0-2.195-0.942-2.195-2.23c0-1.261,1.007-2.194,2.195-2.194
					c0.589,0,1.043,0.154,1.369,0.435v-0.3h1.424V41.721z M33.307,39.681c0-0.608-0.48-1.034-1.061-1.034
					c-0.635,0-1.088,0.426-1.088,1.034c0,0.607,0.453,1.043,1.088,1.043C32.826,40.724,33.307,40.288,33.307,39.681"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#4FA246"
        d="M40.071,40.271h-3.337c0.117,0.544,0.49,0.879,0.979,0.879
					c0.336,0,0.727-0.081,0.971-0.526l1.287,0.264c-0.39,0.962-1.215,1.424-2.258,1.424c-1.314,0-2.402-0.971-2.402-2.412
					c0-1.434,1.088-2.412,2.412-2.412c1.287,0,2.321,0.925,2.349,2.412V40.271z M36.762,39.381h1.877
					c-0.137-0.489-0.5-0.717-0.916-0.717C37.314,38.664,36.897,38.918,36.762,39.381"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#4FA246"
        d="M46.943,40.352c0,1.151-0.934,1.959-2.267,1.959
					c-1.278,0-2.213-0.743-2.349-1.923l1.415-0.263c0.081,0.599,0.407,0.943,0.942,0.943c0.508,0,0.871-0.291,0.871-0.735
					c0-0.398-0.3-0.635-0.824-0.635h-0.817v-1.188h0.753c0.518,0,0.816-0.227,0.816-0.616c0-0.418-0.317-0.698-0.808-0.698
					c-0.489,0-0.853,0.299-0.907,0.979L42.4,37.912c0.137-1.206,1.035-1.95,2.268-1.95c1.297,0,2.177,0.799,2.177,1.914
					c0,0.554-0.235,0.979-0.653,1.225C46.672,39.346,46.943,39.779,46.943,40.352"
      />
      <polygon
        clipPath="url(#SVGID_10_)"
        fill="#E0EED3"
        points="36.289,22.747 30.515,22.747 28.352,18.626 36.289,18.626 				"
      />
      <polygon
        clipPath="url(#SVGID_10_)"
        fill="#E0EED3"
        points="22.87,24.899 26.076,19.482 29.281,24.899 				"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#008A49"
        d="M40.275,24.707h-0.591l-4.321-7.832l0.674-1.222
					c0.061-0.108,0.021-0.245-0.086-0.306c-0.109-0.059-0.246-0.02-0.306,0.089l-0.538,0.976l-0.538-0.976
					c-0.06-0.108-0.196-0.147-0.304-0.089c-0.108,0.061-0.148,0.197-0.088,0.306l0.66,1.196h-7.854l0.659-1.196
					c0.06-0.108,0.021-0.245-0.087-0.306c-0.109-0.059-0.245-0.02-0.305,0.089l-0.538,0.975l-0.538-0.975
					c-0.059-0.108-0.195-0.147-0.304-0.089c-0.108,0.061-0.147,0.197-0.088,0.306l0.674,1.222l4.569,8.28h9.249
					c0.124,0,0.225-0.101,0.225-0.224C40.5,24.808,40.399,24.707,40.275,24.707 M34.52,19.881l1.113,2.019h-3.716l-1.114-2.019
					H34.52z"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#008A49"
        d="M26.202,17.34l-4.065,7.367h-0.73c-0.123,0-0.223,0.101-0.223,0.225
					s0.1,0.224,0.223,0.224h9.108L26.202,17.34z M28.363,24.707h-4.296l2.148-3.805L28.363,24.707z"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#4F86C6"
        d="M30.842,59.527c-2.638,0-4.773-2.136-4.773-4.772
					c0-2.636,2.135-4.771,4.773-4.771c2.638,0,4.773,2.136,4.773,4.771C35.615,57.392,33.479,59.527,30.842,59.527"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#FFFFFF"
        d="M30.842,49.982c2.638,0,4.773,2.137,4.773,4.773
					c0,2.636-2.136,4.771-4.773,4.771c-2.638,0-4.773-2.136-4.773-4.771C26.069,52.119,28.204,49.982,30.842,49.982 M30.842,49.571
					c-2.859,0-5.185,2.325-5.185,5.185c0,2.857,2.326,5.183,5.185,5.183s5.186-2.325,5.186-5.183
					C36.027,51.896,33.701,49.571,30.842,49.571"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#FFFFFF"
        d="M33.336,52.811c-0.24,0-0.466,0.108-0.621,0.251l-0.013-0.012
					c0.072-0.119,0.168-0.347,0.168-0.716c0-0.884-0.883-1.552-2.028-1.552s-2.041,0.668-2.041,1.552
					c0,0.369,0.119,0.597,0.179,0.716l-0.012,0.012c-0.155-0.143-0.382-0.251-0.62-0.251c-0.729,0-1.456,0.704-1.456,2.017
					c0,1.288,0.716,2.017,1.492,2.017c0.716,0,1.217-0.396,1.67-0.848c-0.072-0.107-0.18-0.216-0.299-0.216
					c-0.118,0-0.251,0.085-0.477,0.265c-0.298,0.227-0.561,0.357-0.907,0.357c-0.609,0-0.87-0.682-0.87-1.575
					c0-0.954,0.25-1.587,0.896-1.587c0.286,0,0.476,0.083,0.668,0.263c0.286,0.263,0.381,0.334,0.512,0.334
					c0.096,0,0.156-0.061,0.179-0.096c-0.214-0.215-0.524-0.668-0.524-1.253c0-0.668,0.56-1.133,1.611-1.133
					c1.049,0,1.61,0.465,1.61,1.133c0,0.585-0.31,1.038-0.524,1.253c0.023,0.035,0.083,0.096,0.178,0.096
					c0.132,0,0.227-0.071,0.513-0.334c0.179-0.18,0.382-0.263,0.668-0.263c0.645,0,0.895,0.633,0.895,1.587
					c0,0.894-0.262,1.575-0.869,1.575c-0.346,0-0.621-0.131-0.907-0.357c-0.227-0.18-0.358-0.265-0.477-0.265
					c-0.12,0-0.227,0.108-0.299,0.216c0.441,0.452,0.955,0.848,1.671,0.848c0.774,0,1.49-0.729,1.49-2.017
					C34.791,53.515,34.063,52.811,33.336,52.811"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#FFFFFF"
        d="M31.641,52.787h-0.537c-0.036,0-0.059-0.024-0.071-0.048l-0.168-0.514
					c-0.012-0.034-0.023-0.034-0.035,0l-0.167,0.514c-0.013,0.036-0.036,0.048-0.06,0.048h-0.538c-0.023,0-0.035,0.011-0.012,0.022
					l0.443,0.323c0.023,0.023,0.023,0.061,0.011,0.084l-0.167,0.5c-0.011,0.024-0.011,0.048,0.025,0.024l0.441-0.31
					c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.023,0.023,0.035,0.012,0.023-0.013l-0.166-0.512
					c-0.013-0.036,0-0.072,0.023-0.084l0.44-0.323C31.677,52.798,31.664,52.787,31.641,52.787"
      />
      <path
        clipPath="url(#SVGID_10_)"
        fill="#FFFFFF"
        d="M31.235,56.317c-0.226-0.572-0.406-0.966-0.406-1.442
					c0-0.203,0.061-0.382,0.096-0.478c0.048-0.096,0.06-0.155,0.011-0.155c-0.071,0-0.226,0.167-0.321,0.311
					c-0.072,0.119-0.156,0.346-0.156,0.524c0,0.335,0.108,0.692,0.179,0.906c0.132,0.371,0.251,0.801,0.251,1.206
					c0,0.645-0.287,1.085-0.799,1.479c0.047,0.037,0.167,0.096,0.31,0.096c0.5,0,1.097-0.56,1.097-1.324
					C31.497,56.985,31.366,56.676,31.235,56.317"
      />
    </g>
  </g>
);
// #endregion

const Explore = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <ExploreStage3 {...otherProps} />;
    case 4:
      return <ExploreStage4 {...otherProps} />;
    case 5:
      return <ExploreStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

const Camp = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <CampStage3 {...otherProps} />;
    case 4:
      return <CampStage4 {...otherProps} />;
    case 5:
      return <CampStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

function HaveAdventures(props: any) {
  const { skill, stage, fallback = null, ...otherProps } = props;

  switch (skill) {
    case 'Explore':
      return <Explore stage={stage} {...otherProps} fallback={fallback} />;
    case 'Camp':
      return <Camp stage={stage} {...otherProps} fallback={fallback} />;
    default:
      return fallback;
  }
}

export default HaveAdventures;
