import { HTMLProps } from 'react';
import { CompletedTask } from 'types';
import { randomString } from 'utilities/string';
import Activity from './Activity';

type ActivityListProps = HTMLProps<HTMLDivElement> & {
  items: CompletedTask[];
  selectedItems: CompletedTask[];
  itemColor: string;
  onItemToggle: (item: CompletedTask) => void;
};

export enum ActivityAction {
  Complete,
  Delete
}

function ActivityList(props: ActivityListProps) {
  const {
    className,
    items,
    selectedItems,
    itemColor,
    onItemToggle,
    ...otherProps
  } = props;

  let newClasName = 'gta-list';
  if (className) newClasName += ` ${className}`;

  return (
    <div {...otherProps} className={newClasName}>
      {items.map((activity) => {
        const isSelected =
          selectedItems.findIndex((item) => item.task === activity.task) > -1;
        const isCompleted = !!activity.createdAt;
        const isDisabled =
          (selectedItems.length > 0 &&
            isCompleted !== !!selectedItems[0].createdAt) ||
          (activity.createdAt !== undefined &&
            new Date(activity.createdAt).getTime() <
              new Date().getTime() - 1000 * 60 * 60 * 24);

        return (
          <Activity
            key={randomString()}
            name={activity.task}
            duration={activity.duration}
            completed={isCompleted}
            onToggle={() => !isDisabled && onItemToggle(activity)}
            selected={isSelected}
            activeColor={itemColor}
            disabled={isDisabled}
          />
        );
      })}
    </div>
  );
}

export default ActivityList;
