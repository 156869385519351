import React, { HTMLProps, PropsWithChildren, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import DatabaseContext from 'context/Database';
import HomeIcon from 'components/icons/ui/Home';
import TrophyIcon from 'components/icons/ui/Trophy';
// import GroupIcon from 'components/icons/ui/Group';
import ContactsIcon from 'components/icons/ui/Contacts';
// import PersonIcon from 'components/icons/ui/Person';
import AccountIcon from 'components/icons/ui/Account';
import { IconButton, LinkButton } from 'components/button';

import './toolbar.scss';
import GuidesThemeIcon from 'components/icons/GuidesTheme';
import { randomString, toKebabCase } from 'utilities/string';

export type ToolbarProps<T> = HTMLProps<HTMLElement> & PropsWithChildren<T>;

function Toolbar(props: ToolbarProps<any>) {
  const { children, className, ...otherProps } = props;

  const classes = ['toolbar'];
  if (className) classes.push(className);

  return (
    <footer {...otherProps} className={classes.join(' ')}>
      {children}
    </footer>
  );
}

export function NavigationToolbar(props: ToolbarProps<any>) {
  const { ...otherProps } = props;
  const location = useLocation();
  const color = 'light';

  return (
    <Toolbar {...otherProps}>
      <LinkButton to="/" color={color} className="button--icon-button">
        <HomeIcon size={32} filled={location.pathname === '/'} />
      </LinkButton>

      {/* $as={Link} to="/progress" */}
      <IconButton color={color} disabled>
        <TrophyIcon size={32} filled={location.pathname === '/progress'} />
      </IconButton>

      {/* $as={Link} to="/patrol" */}
      <IconButton color={color} disabled>
        <ContactsIcon size={32} filled={location.pathname === '/patrol'} />
      </IconButton>

      <LinkButton to="/profile" color={color} className="button--icon-button">
        <AccountIcon size={32} filled={location.pathname === '/profile'} />
      </LinkButton>
    </Toolbar>
  );
}

export function GuidesThemesToolbar(props: ToolbarProps<any>) {
  const { guidesThemes } = useContext(DatabaseContext);
  const { ...otherProps } = props;
  const location = useLocation();
  const color = 'light';

  return (
    <Toolbar {...otherProps} className="toolbar--guides-themes">
      {guidesThemes.map((theme) => {
        const slug = toKebabCase(theme.name);
        const path = `/guides-themes/${slug}`;
        const active = location.pathname === path;
        let linkClassName = 'button--icon-button p-0';
        if (active) linkClassName += ' button--active';

        return (
          <LinkButton
            key={randomString()}
            to={path}
            color={active ? `${slug}-20` : color}
            className={linkClassName}
          >
            <GuidesThemeIcon
              name={theme.name}
              invert
              primaryColor={`var(--${active ? slug : 'dark'})`}
              secondaryColor={`var(--${active ? `${slug}-20` : color})`}
              // style={{ opacity: active ? 1 : 0.5 }}
            />

            {/* {active ? <small>{theme.name}</small> : null} */}
          </LinkButton>
        );
      })}
    </Toolbar>
  );
}

export default Toolbar;
