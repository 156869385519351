import React from 'react';

function UMA(props: any) {
  const { color = 'var(--dark)', width, height, ...otherProps } = props;
  return (
    <svg
      {...otherProps}
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 82.635 58.095"
      enableBackground="new 0 0 82.635 58.095"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill={color}
          d="M29.103,35.754c-1.458-1.464-2.644-3.193-3.504-5.102c-2.093,0.905-4.397,1.411-6.818,1.411
		c-3.868,0-7.43-1.296-10.31-3.454c-0.114,0.075-0.227,0.149-0.339,0.228c-0.136,0.094-0.271,0.19-0.404,0.287
		c-0.113,0.082-0.226,0.166-0.337,0.251c-0.131,0.101-0.26,0.202-0.388,0.306c-0.11,0.088-0.218,0.178-0.325,0.268
		c-0.126,0.106-0.25,0.213-0.372,0.322c-0.105,0.093-0.209,0.188-0.312,0.284c-0.12,0.11-0.238,0.224-0.355,0.339
		c-0.101,0.099-0.2,0.198-0.299,0.3c-0.114,0.117-0.227,0.234-0.338,0.354c-0.096,0.104-0.19,0.209-0.284,0.315
		c-0.108,0.122-0.215,0.245-0.319,0.369c-0.092,0.109-0.182,0.221-0.272,0.332c-0.101,0.126-0.201,0.252-0.299,0.381
		c-0.087,0.115-0.172,0.23-0.257,0.348c-0.094,0.13-0.188,0.261-0.279,0.394c-0.083,0.12-0.162,0.241-0.242,0.363
		c-0.087,0.135-0.174,0.268-0.258,0.403c-0.078,0.126-0.152,0.254-0.227,0.381c-0.08,0.138-0.16,0.273-0.237,0.412
		c-0.072,0.131-0.141,0.264-0.21,0.397c-0.072,0.139-0.146,0.277-0.215,0.42c-0.067,0.137-0.13,0.275-0.193,0.415
		c-0.065,0.141-0.131,0.281-0.193,0.425c-0.061,0.142-0.117,0.287-0.175,0.431c-0.057,0.144-0.116,0.285-0.17,0.431
		c-0.055,0.148-0.105,0.3-0.156,0.449c-0.05,0.145-0.101,0.287-0.147,0.434c-0.049,0.154-0.092,0.312-0.137,0.468
		c-0.041,0.144-0.085,0.288-0.123,0.433c-0.042,0.163-0.079,0.328-0.117,0.492c-0.033,0.144-0.069,0.284-0.099,0.428
		c-0.036,0.174-0.065,0.348-0.096,0.522c-0.024,0.138-0.052,0.275-0.074,0.415c-0.029,0.186-0.05,0.374-0.073,0.561
		c-0.016,0.132-0.036,0.261-0.05,0.393c-0.022,0.209-0.035,0.421-0.05,0.633c-0.007,0.112-0.019,0.222-0.025,0.334
		C0.008,43.649,0,43.977,0,44.305v3.18c0,1.013,0.821,1.834,1.834,1.834h18.612C21.45,43.737,24.642,38.908,29.103,35.754"
        />
        <path
          fill={color}
          d="M10.822,27.293L10.822,27.293c2.303,1.474,5.029,2.34,7.959,2.34c2.127,0,4.146-0.459,5.975-1.27
		c-0.185-0.642-0.327-1.3-0.437-1.969c-0.141-0.857-0.231-1.731-0.24-2.625c0-0.06-0.009-0.117-0.009-0.177
		c0-6.225,3.325-11.671,8.28-14.704C30.058,3.664,24.842,0,18.781,0C10.611,0,3.965,6.647,3.965,14.816
		c0,4.275,1.831,8.122,4.737,10.828C9.357,26.256,10.065,26.808,10.822,27.293"
        />
        <path
          fill={color}
          d="M74.084,28.668c-2.865,2.123-6.398,3.395-10.231,3.395c-2.421,0-4.724-0.506-6.817-1.411
		c-0.867,1.922-2.062,3.663-3.535,5.135c4.385,3.15,7.537,7.958,8.617,13.532h18.684c0.759,0,1.41-0.462,1.689-1.12
		c0.094-0.22,0.145-0.46,0.145-0.714v-2.649C82.635,38.183,79.261,32.16,74.084,28.668"
        />
        <path
          fill={color}
          d="M58.564,23.593c0,0.057-0.009,0.112-0.009,0.17c-0.008,0.882-0.096,1.743-0.233,2.59
		c0,0.001-0.001,0.003-0.001,0.004c-0.111,0.683-0.254,1.353-0.443,2.007c1.83,0.811,3.85,1.27,5.977,1.27
		c2.913,0,5.624-0.857,7.917-2.315c0.764-0.485,1.478-1.038,2.138-1.651c2.919-2.708,4.76-6.563,4.76-10.85
		c0-8.169-6.646-14.815-14.814-14.815c-6.063,0-11.278,3.662-13.57,8.888C55.24,11.921,58.564,17.369,58.564,23.593"
        />
        <path
          fill={color}
          d="M59.606,49.318c-1.099-4.897-4.024-9.154-8.058-11.875c-1.784,1.322-3.827,2.305-6.045,2.86
		c-0.001,0-0.002,0-0.002,0c-0.833,0.209-1.684,0.37-2.559,0.452c-0.535,0.051-1.076,0.083-1.625,0.083
		c-0.043,0-0.084-0.006-0.125-0.006c-0.457-0.004-0.909-0.031-1.357-0.069c-0.072-0.006-0.145-0.004-0.216-0.011
		c-0.896-0.088-1.765-0.262-2.616-0.482c-2.2-0.569-4.227-1.56-5.997-2.886c-0.114,0.075-0.227,0.15-0.339,0.228
		c-0.136,0.095-0.27,0.19-0.404,0.287c-0.113,0.083-0.225,0.166-0.336,0.251c-0.131,0.101-0.261,0.202-0.389,0.306
		c-0.109,0.088-0.218,0.178-0.325,0.268c-0.125,0.106-0.249,0.213-0.372,0.322c-0.105,0.093-0.208,0.188-0.311,0.284
		c-0.12,0.11-0.239,0.224-0.356,0.339c-0.101,0.099-0.2,0.199-0.298,0.3c-0.114,0.117-0.227,0.235-0.339,0.354
		c-0.096,0.104-0.19,0.209-0.284,0.315c-0.107,0.122-0.214,0.245-0.319,0.369c-0.092,0.11-0.182,0.221-0.271,0.332
		c-0.101,0.126-0.202,0.253-0.299,0.381c-0.088,0.115-0.173,0.232-0.257,0.348c-0.095,0.13-0.189,0.262-0.28,0.394
		c-0.082,0.12-0.162,0.242-0.241,0.364c-0.088,0.134-0.175,0.267-0.259,0.402c-0.077,0.126-0.152,0.254-0.227,0.381
		c-0.08,0.138-0.16,0.273-0.236,0.412c-0.073,0.131-0.141,0.265-0.21,0.397c-0.073,0.139-0.146,0.277-0.215,0.42
		c-0.067,0.137-0.13,0.275-0.194,0.415c-0.065,0.141-0.131,0.282-0.192,0.425c-0.061,0.142-0.118,0.287-0.176,0.431
		c-0.057,0.144-0.115,0.286-0.169,0.431c-0.055,0.148-0.106,0.3-0.157,0.449c-0.05,0.145-0.101,0.287-0.147,0.434
		c-0.049,0.155-0.092,0.312-0.137,0.468c-0.041,0.145-0.085,0.288-0.123,0.433c-0.042,0.163-0.078,0.328-0.117,0.492
		c-0.033,0.144-0.069,0.284-0.098,0.429c-0.006,0.025-0.012,0.051-0.017,0.075h0.003c-0.03,0.148-0.056,0.298-0.082,0.446
		c-0.025,0.138-0.053,0.275-0.074,0.415c-0.029,0.187-0.051,0.374-0.074,0.562c-0.016,0.131-0.036,0.26-0.05,0.392
		c-0.021,0.204-0.033,0.41-0.048,0.616c0,0.006-0.001,0.012-0.001,0.017c-0.008,0.112-0.02,0.222-0.026,0.334
		c-0.016,0.325-0.025,0.652-0.025,0.98v3.18c0,1.013,0.821,1.835,1.834,1.835h15.466h1.357h17.071c1.014,0,1.834-0.822,1.834-1.835
		V53.61c0-0.627-0.029-1.248-0.088-1.861C59.932,50.923,59.785,50.116,59.606,49.318"
        />
        <path
          fill={color}
          d="M38.843,32.018c0.325,0.122,0.656,0.22,0.992,0.293c0.447,0.097,0.902,0.149,1.357,0.158
		c0.881,0.016,1.764-0.136,2.598-0.451c0.896-0.339,1.737-0.862,2.456-1.582c0.447-0.448,1.152-0.457,1.63-0.06
		c0.028,0.023,0.063,0.033,0.089,0.06c0.475,0.475,0.475,1.244,0,1.719c-0.208,0.208-0.426,0.401-0.648,0.586
		c-0.619,0.514-1.291,0.93-1.996,1.26c-0.416,0.196-0.839,0.368-1.275,0.499c-0.888,0.269-1.808,0.404-2.728,0.404
		c-0.043,0-0.084-0.005-0.125-0.006c-0.455-0.006-0.908-0.049-1.357-0.121C39.416,34.71,39,34.623,38.591,34.5
		c-0.001-0.001-0.003-0.001-0.004-0.002c-0.492-0.147-0.971-0.343-1.438-0.572c-0.645-0.319-1.261-0.713-1.833-1.188
		c-0.222-0.184-0.439-0.376-0.647-0.584c-0.474-0.475-0.474-1.244,0-1.719c0.006-0.006,0.014-0.007,0.02-0.014
		c0.475-0.457,1.23-0.454,1.699,0.014C37.107,31.155,37.948,31.679,38.843,32.018 M26.501,23.593c0,0.335,0.028,0.662,0.05,0.992
		c0.059,0.884,0.196,1.743,0.403,2.577c0.028,0.113,0.056,0.226,0.087,0.337c0.081,0.296,0.168,0.587,0.267,0.875
		c0.133,0.39,0.276,0.773,0.44,1.146c0.819,1.868,2.017,3.528,3.49,4.899c0.655,0.61,1.363,1.163,2.12,1.648
		c0,0,0.001,0,0.001,0.001c0.861,0.551,1.783,1.011,2.753,1.376c0.92,0.347,1.882,0.604,2.878,0.761
		c0.281,0.045,0.559,0.099,0.845,0.127c0.447,0.044,0.9,0.064,1.357,0.068c0.041,0.001,0.082,0.006,0.125,0.006
		c0.754,0,1.491-0.074,2.217-0.184c0.97-0.146,1.907-0.387,2.807-0.711c0.001-0.001,0.002-0.001,0.003-0.001
		c1.02-0.37,1.988-0.845,2.891-1.418c0.764-0.485,1.478-1.039,2.137-1.651c1.484-1.377,2.691-3.044,3.515-4.922
		c0.164-0.373,0.308-0.757,0.44-1.146c0.105-0.311,0.199-0.627,0.285-0.946v-0.001c0.023-0.089,0.047-0.177,0.068-0.265
		c0.208-0.838,0.346-1.702,0.403-2.589c0.021-0.326,0.05-0.649,0.05-0.98c0-5.15-2.645-9.69-6.643-12.347
		c-0.353-0.233-0.713-0.456-1.086-0.66c-0.36-0.196-0.733-0.373-1.111-0.541c-1.83-0.81-3.849-1.269-5.976-1.269
		c-0.043,0-0.084,0.006-0.125,0.006c-0.457,0.004-0.91,0.025-1.357,0.069c-1.585,0.158-3.094,0.574-4.493,1.193
		c-0.378,0.168-0.752,0.345-1.113,0.541c-0.372,0.204-0.733,0.427-1.085,0.66C29.145,13.902,26.501,18.442,26.501,23.593"
        />
      </g>
    </svg>
  );
}

export default UMA;
