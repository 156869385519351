import { useContext, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';

import AuthContext from 'context/Auth';
import { friendlyMessage } from 'utilities/firebase';
import { useLocalStorage } from 'hooks/useStorage';
import Button, { IconButton } from 'components/button';
import { Checkbox, Input, InputGroup, PasswordBox } from 'components/form';
import { Span } from 'components/typography';
import PersonIcon from 'components/icons/ui/Person';
import LockIcon from 'components/icons/ui/Lock';
import VisibilityIcon from 'components/icons/ui/Visibility';
import VisibilityOffIcon from 'components/icons/ui/VisibilityOff';
import Logo from 'components/logo';
import { PageContent } from 'components/layout';

import './auth.scss';

function SignIn() {
  const {
    loading,
    isValid: isAuthorised,
    error,
    signInWithUsernameAndPassword
  } = useContext(AuthContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [rememberMeUsername, setRememberMeUsername, removeRememberMeUsername] =
    useLocalStorage('rememberMe.username', null);

  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo = urlParams.get('r');

  const onFormSubmit = (event: any) => {
    event.preventDefault();
    if (rememberMe) setRememberMeUsername(username);
    else removeRememberMeUsername();
    signInWithUsernameAndPassword(username, password);
  };

  useEffect(() => {
    if (!rememberMeUsername) return;
    setUsername(rememberMeUsername);
    setRememberMe(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!error) return;
    toast.error(friendlyMessage(error));
  }, [error]);

  useEffect(() => {
    if (isAuthorised) toast.success('You are now signed in');
  }, [isAuthorised]);

  if (isAuthorised) return <Navigate to={redirectTo || '/'} replace />;

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>

      <PageContent className="content--sign-in">
        <Logo />

        <form onSubmit={onFormSubmit}>
          <InputGroup>
            <Span color="light" className="input-group--addon bg-dark">
              <PersonIcon size={32} />
            </Span>

            <Input
              placeholder="Username"
              className="bg-dark--glass"
              value={username}
              onChange={(e: any) => setUsername(e.target.value)}
              required
              disabled={loading}
            />
          </InputGroup>

          <InputGroup>
            <Span color="light" className="input-group--addon bg-dark">
              <LockIcon size={32} />
            </Span>

            <PasswordBox
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              className="bg-dark--glass"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              required
              disabled={loading}
            />

            <IconButton
              className="input-group--addon bg-dark--glass"
              onClick={() => setShowPassword(!showPassword)}
              disabled={loading}
            >
              {!showPassword ? <VisibilityIcon size={32} /> : null}
              {showPassword ? <VisibilityOffIcon size={32} /> : null}
            </IconButton>
          </InputGroup>

          <Checkbox
            label="Remember me"
            className="d-block text-left px-2 mb-2"
            checked={rememberMe}
            onChange={(e: any) => setRememberMe(e.target.checked)}
            disabled={loading}
          />
          {/* className="mb-1" */}
          <Button type="submit" color="primary" block disabled={loading}>
            {loading ? 'Loading...' : 'Sign In'}
          </Button>

          {/* <Link to="#" className="text-dark">
            Forgot Password?
          </Link> */}

          {/* <div className="my-2">
            {"Don't have an account? "}
            <Link to="/sign-up" className="text-dark">
              Sign Up
            </Link>
          </div> */}
        </form>
      </PageContent>
    </>
  );
}

export default SignIn;
