import { HTMLProps } from 'react';
import { Link, ExternalLink } from 'components/typography';
import './button.scss';

type LinkButtonProps = HTMLProps<HTMLAnchorElement> & {
  to?: any;
  external?: boolean;
  color: string;
  block?: boolean;
};

function LinkButton(props: LinkButtonProps) {
  const { children, className, to, external, color, block, ...otherProps } =
    props;

  const Tag = external ? ExternalLink : Link;
  const classes = ['button'];
  if (className) classes.push(className);
  if (block) classes.push('button--block');
  if (color) classes.push(`button--${color}`);

  return (
    <Tag {...otherProps} to={to} className={classes.join(' ')}>
      {children}
    </Tag>
  );
}

export default LinkButton;
