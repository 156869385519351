import React from 'react';

const Whittling = () => {
  return (
    <g id="Whittling">
      <g>
        <defs>
          <rect id="WHITTLING_SVGID_53_" width="74.905" height="72.354" />
        </defs>
        <clipPath id="WHITTLING_SVGID_54_">
          <use xlinkHref="#WHITTLING_SVGID_53_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#WHITTLING_SVGID_54_)"
          fill="#72BF44"
          d="M55.506,61.069H6.18c-3.06,0-5.564-2.504-5.564-5.563V6.18
				c0-3.059,2.504-5.563,5.564-5.563h49.326c3.06,0,5.563,2.504,5.563,5.563v49.327C61.069,58.565,58.565,61.069,55.506,61.069"
        />
      </g>
      <g>
        <defs>
          <path
            id="WHITTLING_SVGID_55_"
            d="M5.676,0C2.554,0,0,2.555,0,5.677V56.01c0,3.122,2.554,5.676,5.676,5.676h50.333
					c3.123,0,5.678-2.554,5.678-5.676V5.677C61.687,2.555,59.133,0,56.009,0H5.676z"
          />
        </defs>
        <clipPath id="WHITTLING_SVGID_56_">
          <use xlinkHref="#WHITTLING_SVGID_55_" overflow="visible" />
        </clipPath>
        <polygon
          clipPath="url(#WHITTLING_SVGID_56_)"
          fill="#008B4A"
          points="11.053,49.306 47.231,72.354 76.121,39.503 41.947,19.635 			"
        />
      </g>
      <polygon
        fill="#FFFFFF"
        points="22.12,11.137 21.068,11.137 20.402,8.676 19.73,11.137 18.683,11.137 17.547,7.153 18.552,7.153
			19.247,9.662 19.932,7.153 20.865,7.153 21.549,9.662 22.244,7.153 23.256,7.153 		"
      />
      <g>
        <defs>
          <rect id="WHITTLING_SVGID_57_" width="74.905" height="72.354" />
        </defs>
        <clipPath id="WHITTLING_SVGID_58_">
          <use xlinkHref="#WHITTLING_SVGID_57_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M26.629,9.306v1.831h-0.945V9.509c0-0.352-0.209-0.589-0.518-0.589
				c-0.368,0-0.613,0.255-0.613,0.832v1.385h-0.951V6.855h0.951v1.582c0.232-0.238,0.542-0.374,0.922-0.374
				C26.165,8.063,26.629,8.557,26.629,9.306"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M28.287,7.271c0,0.304-0.232,0.529-0.553,0.529
				c-0.321,0-0.547-0.226-0.547-0.529c0-0.291,0.226-0.541,0.547-0.541C28.055,6.73,28.287,6.98,28.287,7.271 M27.258,8.151h0.951
				v2.985h-0.951V8.151z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M31.079,11.006c-0.232,0.125-0.476,0.222-0.815,0.222
				c-0.744,0-1.189-0.405-1.189-1.19v-1.13h-0.541V8.152h0.541v-0.88h0.951v0.88h0.88v0.755h-0.88v1.035
				c0,0.309,0.149,0.433,0.399,0.433c0.131,0,0.309-0.047,0.434-0.118L31.079,11.006z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M33.666,11.006c-0.232,0.125-0.476,0.222-0.815,0.222
				c-0.743,0-1.189-0.405-1.189-1.19v-1.13h-0.541V8.152h0.541v-0.88h0.951v0.88h0.881v0.755h-0.881v1.035
				c0,0.309,0.149,0.433,0.398,0.433c0.131,0,0.311-0.047,0.436-0.118L33.666,11.006z"
        />
        <rect
          x="34.013"
          y="6.855"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          width="0.952"
          height="4.281"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M36.649,7.271c0,0.304-0.232,0.529-0.553,0.529
				c-0.322,0-0.547-0.226-0.547-0.529c0-0.291,0.225-0.541,0.547-0.541C36.417,6.73,36.649,6.98,36.649,7.271 M35.621,8.151h0.951
				v2.985h-0.951V8.151z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M40.255,9.306v1.831H39.31V9.509c0-0.352-0.209-0.589-0.512-0.589
				c-0.375,0-0.618,0.255-0.618,0.832v1.385h-0.951V8.151h0.951v0.286c0.226-0.238,0.54-0.374,0.915-0.374
				C39.79,8.063,40.255,8.557,40.255,9.306"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M43.936,10.84c0,0.922-0.701,1.427-1.67,1.427
				c-0.685,0-1.208-0.237-1.499-0.719l0.79-0.429c0.226,0.268,0.393,0.37,0.702,0.37c0.435,0,0.744-0.258,0.744-0.727v-0.084
				c-0.215,0.186-0.512,0.286-0.898,0.286c-0.779,0-1.439-0.618-1.439-1.463c0-0.826,0.66-1.439,1.439-1.439
				c0.387,0,0.684,0.102,0.898,0.286V8.152h0.933V10.84z M43.003,9.502c0-0.398-0.315-0.678-0.696-0.678
				c-0.416,0-0.714,0.279-0.714,0.678s0.298,0.683,0.714,0.683C42.688,10.185,43.003,9.9,43.003,9.502"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#9CCE78"
          d="M56.01,4.406c0.688,0,1.27,0.582,1.27,1.271V56.01
				c0,0.689-0.581,1.271-1.27,1.271H5.677c-0.689,0-1.271-0.582-1.271-1.271V5.677c0-0.688,0.582-1.271,1.271-1.271H56.01 M56.01,0
				H5.677C2.554,0,0,2.554,0,5.677V56.01c0,3.122,2.554,5.677,5.677,5.677H56.01c3.122,0,5.677-2.555,5.677-5.677V5.677
				C61.687,2.554,59.132,0,56.01,0"
        />
        <line
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#F5DB8B"
          x1="50.706"
          y1="49.65"
          x2="50.706"
          y2="49.651"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#AC6E26"
          d="M43.718,21.692L17.167,49.659l-6.796-6.451l26.552-27.967
				c1.779-1.876,4.746-1.954,6.623-0.172C45.421,16.851,45.499,19.816,43.718,21.692"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#623B23"
          d="M16.994,43.036c-1.877-1.78-4.842-1.704-6.623,0.173
				c-1.783,1.876-1.705,4.843,0.172,6.623c1.876,1.781,4.842,1.704,6.623-0.173C18.947,47.783,18.871,44.817,16.994,43.036"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#AC6E26"
          d="M16.349,43.716c-1.501-1.425-3.874-1.363-5.299,0.139
				c-1.425,1.5-1.363,3.872,0.138,5.298c1.501,1.425,3.874,1.363,5.299-0.137C17.912,47.513,17.85,45.141,16.349,43.716"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#623B23"
          d="M15.729,44.368c-1.141-1.083-2.945-1.036-4.027,0.104
				c-1.083,1.142-1.036,2.944,0.104,4.027c1.142,1.082,2.944,1.036,4.027-0.104C16.917,47.254,16.871,45.451,15.729,44.368"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#623B23"
          d="M24.274,38.453l-4.598,4.843c-0.16,0.167-0.153,0.435,0.016,0.594
				c0.167,0.161,0.434,0.153,0.594-0.016l4.598-4.842c0.159-0.169,0.152-0.435-0.016-0.595C24.7,38.277,24.435,38.284,24.274,38.453
				"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#623B23"
          d="M20.583,34.949l-4.598,4.843c-0.16,0.167-0.153,0.435,0.016,0.594
				c0.168,0.16,0.435,0.153,0.594-0.016l4.598-4.842c0.159-0.169,0.152-0.435-0.016-0.595S20.743,34.78,20.583,34.949"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#623B23"
          d="M36.805,17.863l-4.598,4.844c-0.16,0.167-0.153,0.433,0.016,0.592
				c0.168,0.161,0.434,0.154,0.594-0.015l4.599-4.843c0.158-0.169,0.151-0.435-0.017-0.594
				C37.229,17.688,36.964,17.695,36.805,17.863"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#623B23"
          d="M28.056,27.634l-4.597,4.844c-0.161,0.167-0.154,0.434,0.015,0.593
				c0.168,0.161,0.435,0.154,0.594-0.016l4.598-4.842c0.16-0.169,0.153-0.435-0.016-0.595
				C28.482,27.459,28.216,27.466,28.056,27.634"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#623B23"
          d="M32.934,25.637l-10.82,11.396c-0.16,0.169-0.153,0.435,0.015,0.595
				c0.169,0.16,0.435,0.153,0.595-0.016l10.82-11.396c0.158-0.169,0.152-0.436-0.016-0.596
				C33.358,25.461,33.094,25.469,32.934,25.637"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#623B23"
          d="M40.226,17.955l-2.199,2.316c-0.16,0.168-0.152,0.435,0.016,0.594
				c0.168,0.16,0.434,0.153,0.594-0.015l2.199-2.316c0.16-0.169,0.152-0.435-0.016-0.595C40.65,17.78,40.386,17.787,40.226,17.955"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#623B23"
          d="M38.971,22.321l-2.198,2.316c-0.16,0.168-0.154,0.435,0.016,0.594
				c0.166,0.16,0.434,0.153,0.592-0.015l2.201-2.316c0.16-0.169,0.152-0.435-0.017-0.595C39.396,22.146,39.13,22.152,38.971,22.321"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#AC6E26"
          d="M14.895,45.247c-0.656-0.622-1.692-0.595-2.314,0.06
				c-0.622,0.656-0.595,1.692,0.06,2.315c0.656,0.622,1.692,0.594,2.314-0.061C15.578,46.906,15.551,45.869,14.895,45.247"
        />

        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="none"
          stroke="#623B23"
          strokeWidth="1.092"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
				M37.247,27.176c0.849,0.546,3.443,2.01,7.293-0.464"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#72BF44"
          d="M45.801,30.314c-2.075,0.054-3.962-0.798-5.286-2.193
				c1.251-1.462,3.092-2.409,5.167-2.462c2.073-0.055,3.961,0.797,5.285,2.192C49.717,29.313,47.876,30.261,45.801,30.314"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#C8CCCC"
          d="M31.428,36.045L17.292,25.501c0,0-2.265-3.048-4.954-7.886
				c0,0,6.039-0.43,12.228,5.354l10.321,9.645L31.428,36.045z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFDD00"
          d="M52.052,50.746c-1.496,1.715-4.098,1.892-5.812,0.396l-8.084-7.762
				l-7.792-6.803c-0.673-0.588-0.743-1.609-0.155-2.282l1.199-1.375l1.198-1.373c0.589-0.674,1.611-0.743,2.285-0.154l7.791,6.801
				l8.976,6.741C53.372,46.431,53.548,49.032,52.052,50.746"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M34.703,34.811c-0.005,0.688-0.565,1.24-1.253,1.236
				s-1.241-0.564-1.237-1.253c0.004-0.688,0.565-1.242,1.253-1.237S34.708,34.122,34.703,34.811"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#AC6E26"
          d="M25.915,25.511c0,0,1.045-0.056,1.664-0.457l1.429,1.337
				c0,0-0.631,0.588-1.511,0.604L25.915,25.511z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#5087C7"
          d="M30.854,55.36c-2.638,0-4.773-2.136-4.773-4.772s2.135-4.772,4.773-4.772
				c2.638,0,4.774,2.136,4.774,4.772S33.491,55.36,30.854,55.36"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M30.854,45.815c2.638,0,4.774,2.136,4.774,4.772s-2.137,4.772-4.774,4.772
				c-2.638,0-4.773-2.136-4.773-4.772S28.215,45.815,30.854,45.815 M30.854,45.403c-2.859,0-5.185,2.325-5.185,5.185
				c0,2.858,2.326,5.184,5.185,5.184c2.86,0,5.187-2.325,5.187-5.184C36.04,47.729,33.714,45.403,30.854,45.403"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M33.347,48.643c-0.238,0-0.464,0.107-0.619,0.252l-0.014-0.013
				c0.073-0.12,0.169-0.346,0.169-0.716c0-0.883-0.884-1.552-2.029-1.552s-2.041,0.669-2.041,1.552c0,0.37,0.119,0.596,0.179,0.716
				l-0.013,0.013c-0.154-0.145-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.703-1.457,2.018c0,1.288,0.717,2.016,1.493,2.016
				c0.715,0,1.217-0.395,1.67-0.847c-0.072-0.108-0.18-0.216-0.298-0.216c-0.119,0-0.252,0.085-0.477,0.264
				c-0.299,0.228-0.562,0.358-0.908,0.358c-0.609,0-0.87-0.683-0.87-1.575c0-0.955,0.251-1.587,0.895-1.587
				c0.286,0,0.476,0.083,0.668,0.262c0.286,0.263,0.381,0.334,0.512,0.334c0.096,0,0.156-0.06,0.18-0.095
				c-0.215-0.215-0.525-0.668-0.525-1.253c0-0.668,0.56-1.134,1.611-1.134c1.049,0,1.611,0.466,1.611,1.134
				c0,0.585-0.311,1.038-0.525,1.253c0.023,0.035,0.083,0.095,0.179,0.095c0.131,0,0.227-0.071,0.512-0.334
				c0.179-0.179,0.382-0.262,0.668-0.262c0.646,0,0.896,0.632,0.896,1.587c0,0.893-0.262,1.575-0.87,1.575
				c-0.347,0-0.621-0.131-0.907-0.358c-0.228-0.179-0.358-0.264-0.477-0.264c-0.12,0-0.227,0.107-0.299,0.216
				c0.441,0.452,0.956,0.847,1.67,0.847c0.775,0,1.492-0.728,1.492-2.016C34.804,49.346,34.075,48.643,33.347,48.643"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M31.653,48.619h-0.537c-0.035,0-0.059-0.023-0.071-0.048l-0.168-0.513
				c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.513c-0.012,0.036-0.035,0.048-0.059,0.048h-0.537c-0.024,0-0.036,0.011-0.012,0.024
				l0.442,0.321c0.023,0.023,0.023,0.061,0.011,0.083l-0.167,0.501c-0.011,0.024-0.011,0.05,0.024,0.024l0.442-0.309
				c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.036,0.012,0.023-0.011L31.2,49.048c-0.012-0.034,0-0.07,0.024-0.083
				l0.44-0.321C31.689,48.63,31.677,48.619,31.653,48.619"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M31.247,52.15c-0.226-0.573-0.406-0.966-0.406-1.443
				c0-0.203,0.06-0.382,0.096-0.477c0.048-0.096,0.06-0.156,0.012-0.156c-0.072,0-0.227,0.167-0.322,0.312
				c-0.073,0.118-0.156,0.346-0.156,0.523c0,0.335,0.108,0.692,0.179,0.907c0.132,0.37,0.251,0.799,0.251,1.206
				c0,0.646-0.287,1.085-0.799,1.479c0.047,0.036,0.167,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.324
				C31.51,52.818,31.379,52.509,31.247,52.15"
        />
        <rect
          x="-4.982"
          y="77.688"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          width="5.987"
          height="5.986"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-3.109,79.725v1.273c0,0.167-0.011,0.43-0.221,0.571
				c-0.082,0.054-0.195,0.085-0.308,0.085c-0.135,0-0.297-0.046-0.432-0.176l0.223-0.269c0.036,0.054,0.096,0.102,0.184,0.102
				c0.079,0,0.124-0.034,0.144-0.064c0.045-0.065,0.043-0.19,0.043-0.306v-1.217H-3.109z"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          points="-1.728,81.611 -2.005,81.611 -2.661,79.725 -2.271,79.725
				-1.849,81.009 -1.389,79.707 -1.185,79.707 -0.749,81.009 -0.296,79.725 0.094,79.725 -0.605,81.611 -0.882,81.611
				-1.295,80.416 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-2.487-25.894c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.024,0.094,0.059,0.125,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.055,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.019-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.487z M-2.578-25.225c0.059,0,0.107-0.019,0.144-0.055
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H-2.578z"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          points="-1.484,-25.895 -1.273,-24.85 -1.268,-24.85 -1.055,-25.895
				-0.613,-25.895 -0.613,-24.415 -0.887,-24.415 -0.887,-25.597 -0.891,-25.597 -1.154,-24.415 -1.387,-24.415 -1.649,-25.597
				-1.653,-25.597 -1.653,-24.415 -1.927,-24.415 -1.927,-25.895 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-0.159-24.833c0,0.035,0.003,0.065,0.008,0.095
				c0.005,0.028,0.016,0.051,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.008-0.024-0.024-0.047-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053C0.042-25.008,0-25.024-0.049-25.04
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.107,0.336-0.107
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.014,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124
				c0.023,0.051,0.034,0.11,0.034,0.182v0.041H0.192c0-0.07-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.015c-0.022,0.008-0.04,0.022-0.054,0.037c-0.013,0.017-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087C0.491-25.035,0.505-25,0.515-24.964
				c0.007,0.038,0.012,0.079,0.012,0.125c0,0.155-0.046,0.27-0.136,0.341c-0.09,0.07-0.216,0.106-0.378,0.106
				c-0.169,0-0.29-0.037-0.362-0.109c-0.072-0.073-0.108-0.18-0.108-0.315v-0.061h0.298V-24.833z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M1.432-24.647c0.021,0.045,0.064,0.067,0.13,0.067
				c0.035,0,0.064-0.007,0.085-0.019c0.022-0.013,0.038-0.03,0.05-0.051c0.012-0.021,0.019-0.047,0.023-0.076
				c0.003-0.029,0.005-0.06,0.005-0.091c0-0.034-0.002-0.065-0.007-0.094c-0.005-0.03-0.015-0.058-0.028-0.08
				c-0.014-0.023-0.033-0.042-0.058-0.057c-0.025-0.014-0.059-0.021-0.1-0.021H1.433v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.02
				c0.021-0.014,0.039-0.03,0.052-0.052c0.013-0.021,0.022-0.046,0.029-0.073C1.696-25.436,1.7-25.465,1.7-25.494
				c0-0.071-0.012-0.12-0.035-0.149c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.005-0.077,0.017
				c-0.021,0.013-0.037,0.028-0.047,0.049c-0.011,0.022-0.019,0.049-0.022,0.077c-0.003,0.029-0.005,0.061-0.005,0.095H1.132
				c0-0.146,0.036-0.254,0.109-0.325c0.073-0.071,0.18-0.108,0.322-0.108c0.133,0,0.237,0.031,0.311,0.092
				c0.074,0.062,0.111,0.156,0.111,0.286c0,0.089-0.02,0.161-0.059,0.218c-0.039,0.055-0.094,0.091-0.164,0.105v0.004
				c0.094,0.017,0.162,0.054,0.201,0.115c0.04,0.062,0.06,0.139,0.06,0.232c0,0.049-0.007,0.1-0.019,0.151
				c-0.013,0.053-0.037,0.102-0.072,0.145c-0.037,0.043-0.086,0.076-0.149,0.104c-0.062,0.026-0.146,0.039-0.248,0.039
				c-0.142,0-0.248-0.039-0.319-0.116c-0.07-0.077-0.105-0.188-0.105-0.33v-0.006h0.286C1.398-24.758,1.41-24.692,1.432-24.647"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M2.679-25.642c-0.019-0.031-0.055-0.046-0.104-0.046
				c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.019-0.043,0.045-0.056,0.078c-0.013,0.032-0.022,0.072-0.026,0.118
				s-0.006,0.095-0.006,0.148v0.073h0.004c0.025-0.039,0.061-0.069,0.107-0.092c0.047-0.023,0.103-0.035,0.169-0.035
				c0.065,0,0.12,0.012,0.166,0.035c0.045,0.022,0.082,0.054,0.111,0.095c0.028,0.041,0.049,0.089,0.062,0.145
				c0.013,0.057,0.019,0.117,0.019,0.185c0,0.17-0.037,0.298-0.112,0.386c-0.076,0.088-0.196,0.132-0.362,0.132
				c-0.103,0-0.184-0.018-0.245-0.054c-0.06-0.036-0.107-0.086-0.139-0.148c-0.033-0.063-0.054-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.205,0.009-0.302c0.006-0.098,0.025-0.185,0.056-0.26
				c0.031-0.074,0.08-0.135,0.146-0.181c0.067-0.046,0.161-0.069,0.282-0.069c0.052,0,0.103,0.008,0.152,0.02
				c0.05,0.013,0.093,0.033,0.13,0.062c0.037,0.029,0.067,0.068,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.178H2.708
				C2.708-25.566,2.7-25.61,2.679-25.642 M2.413-24.754c0.004,0.035,0.012,0.065,0.024,0.092c0.011,0.025,0.028,0.046,0.05,0.06
				c0.021,0.015,0.049,0.022,0.084,0.022c0.035,0,0.064-0.008,0.086-0.022c0.021-0.014,0.037-0.034,0.048-0.061
				s0.019-0.058,0.021-0.094c0.004-0.033,0.006-0.072,0.006-0.113c0-0.101-0.012-0.171-0.037-0.214
				c-0.025-0.043-0.066-0.064-0.124-0.064c-0.06,0-0.103,0.021-0.127,0.064c-0.025,0.043-0.037,0.113-0.037,0.214
				C2.407-24.829,2.409-24.79,2.413-24.754"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M3.15-25.067c0.042-0.066,0.104-0.107,0.187-0.124v-0.004
				c-0.04-0.011-0.074-0.025-0.101-0.047c-0.026-0.021-0.048-0.044-0.065-0.069c-0.016-0.027-0.028-0.054-0.035-0.083
				c-0.007-0.029-0.01-0.057-0.01-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.315,0.094c0.077,0.063,0.116,0.166,0.116,0.311c0,0.026-0.004,0.054-0.012,0.083
				c-0.007,0.029-0.019,0.056-0.036,0.083c-0.016,0.025-0.038,0.049-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.047v0.004
				c0.083,0.017,0.145,0.058,0.187,0.124c0.041,0.066,0.062,0.146,0.062,0.238c0,0.043-0.005,0.091-0.015,0.141
				c-0.011,0.052-0.033,0.099-0.066,0.143c-0.033,0.045-0.08,0.081-0.142,0.111c-0.061,0.028-0.143,0.043-0.245,0.043
				c-0.102,0-0.184-0.015-0.246-0.043c-0.062-0.03-0.109-0.066-0.142-0.111c-0.033-0.044-0.055-0.091-0.065-0.143
				c-0.011-0.05-0.016-0.098-0.016-0.141C3.088-24.922,3.109-25.001,3.15-25.067 M3.394-24.734c0.005,0.03,0.014,0.058,0.027,0.081
				c0.013,0.021,0.031,0.041,0.053,0.054s0.05,0.019,0.083,0.019c0.033,0,0.06-0.006,0.083-0.019
				c0.021-0.013,0.039-0.032,0.052-0.054c0.013-0.023,0.023-0.051,0.027-0.081c0.005-0.031,0.008-0.064,0.008-0.1
				s-0.003-0.068-0.008-0.099c-0.004-0.031-0.014-0.057-0.027-0.08s-0.031-0.041-0.052-0.054c-0.023-0.014-0.05-0.02-0.083-0.02
				c-0.033,0-0.061,0.006-0.083,0.02c-0.022,0.013-0.04,0.03-0.053,0.054s-0.022,0.049-0.027,0.08
				c-0.005,0.03-0.007,0.063-0.007,0.099S3.389-24.766,3.394-24.734 M3.451-25.331c0.026,0.033,0.061,0.051,0.106,0.051
				c0.044,0,0.079-0.018,0.105-0.051c0.027-0.032,0.04-0.085,0.04-0.154c0-0.136-0.048-0.201-0.145-0.201
				c-0.097,0-0.145,0.065-0.145,0.201C3.412-25.416,3.425-25.363,3.451-25.331"
        />
        <rect
          x="6.558"
          y="-26.393"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#008B4A"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M7.472-25.894c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.055,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.019-0.123,0.027-0.193,0.027h-0.16v0.59H6.95v-1.479H7.472z M7.38-25.225c0.059,0,0.107-0.019,0.144-0.055
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058H7.248v0.45H7.38z"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          points="8.475,-25.895 8.686,-24.85 8.691,-24.85 8.904,-25.895
				9.345,-25.895 9.345,-24.415 9.072,-24.415 9.072,-25.597 9.068,-25.597 8.805,-24.415 8.572,-24.415 8.309,-25.597
				8.305,-25.597 8.305,-24.415 8.031,-24.415 8.031,-25.895 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M9.799-24.833c0,0.034,0.003,0.065,0.009,0.095
				c0.004,0.028,0.015,0.051,0.03,0.071c0.015,0.019,0.036,0.034,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
				c0.045,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.009-0.024-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.008,0.183,0.022c0.056,0.014,0.104,0.038,0.145,0.07s0.072,0.073,0.095,0.124
				c0.023,0.051,0.034,0.11,0.034,0.182v0.041h-0.285c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.015c-0.023,0.008-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.061,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.066,0.045,0.103
				c0.008,0.037,0.012,0.078,0.012,0.125c0,0.155-0.046,0.27-0.136,0.341c-0.09,0.07-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.037-0.362-0.109c-0.072-0.073-0.108-0.18-0.108-0.315v-0.061h0.298V-24.833z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M11.39-24.647c0.021,0.045,0.065,0.066,0.13,0.066
				c0.035,0,0.064-0.006,0.086-0.018c0.021-0.013,0.037-0.03,0.049-0.051c0.012-0.021,0.019-0.047,0.023-0.076
				c0.003-0.029,0.005-0.06,0.005-0.091c0-0.034-0.002-0.065-0.007-0.094c-0.005-0.03-0.015-0.058-0.028-0.08
				c-0.013-0.023-0.033-0.042-0.058-0.057c-0.025-0.014-0.058-0.021-0.1-0.021h-0.099v-0.195h0.097c0.032,0,0.059-0.006,0.08-0.02
				c0.022-0.014,0.039-0.03,0.052-0.052c0.014-0.021,0.023-0.046,0.029-0.073c0.006-0.028,0.009-0.058,0.009-0.087
				c0-0.071-0.012-0.12-0.035-0.149c-0.024-0.029-0.059-0.043-0.108-0.043c-0.031,0-0.057,0.005-0.077,0.017
				c-0.02,0.013-0.036,0.028-0.047,0.049c-0.011,0.022-0.018,0.049-0.022,0.077c-0.003,0.029-0.005,0.061-0.005,0.095h-0.273
				c0-0.146,0.036-0.254,0.109-0.325c0.073-0.071,0.181-0.108,0.322-0.108c0.134,0,0.237,0.031,0.312,0.092
				c0.074,0.062,0.11,0.156,0.11,0.286c0,0.089-0.019,0.161-0.059,0.218c-0.039,0.055-0.094,0.091-0.164,0.105v0.004
				c0.095,0.016,0.162,0.054,0.202,0.115c0.039,0.062,0.059,0.139,0.059,0.231c0,0.05-0.007,0.101-0.019,0.152
				c-0.012,0.053-0.037,0.101-0.072,0.145c-0.037,0.043-0.085,0.076-0.149,0.104c-0.062,0.026-0.145,0.039-0.247,0.039
				c-0.142,0-0.249-0.039-0.32-0.116c-0.07-0.077-0.105-0.188-0.105-0.33v-0.006h0.286C11.357-24.758,11.369-24.692,11.39-24.647"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M12.539-25.883h0.309v0.932h0.142v0.231h-0.142v0.305h-0.273v-0.305h-0.522
				v-0.244L12.539-25.883z M12.57-25.509l-0.287,0.558h0.291v-0.558H12.57z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M13.108-25.067c0.042-0.066,0.104-0.107,0.187-0.124v-0.004
				c-0.04-0.011-0.073-0.025-0.101-0.047c-0.026-0.021-0.048-0.044-0.065-0.069c-0.016-0.027-0.028-0.054-0.035-0.083
				c-0.007-0.029-0.01-0.058-0.01-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.311c0,0.025-0.004,0.054-0.011,0.083
				c-0.008,0.029-0.02,0.056-0.037,0.083c-0.016,0.025-0.038,0.049-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.047v0.004
				c0.083,0.017,0.145,0.058,0.187,0.124c0.041,0.066,0.062,0.146,0.062,0.238c0,0.043-0.005,0.091-0.015,0.141
				c-0.01,0.052-0.032,0.099-0.066,0.143c-0.032,0.045-0.08,0.081-0.141,0.111c-0.062,0.028-0.144,0.043-0.246,0.043
				c-0.102,0-0.184-0.015-0.246-0.043c-0.062-0.03-0.108-0.066-0.142-0.111c-0.033-0.044-0.054-0.091-0.065-0.143
				c-0.01-0.05-0.015-0.098-0.015-0.141C13.047-24.922,13.068-25.001,13.108-25.067 M13.354-24.734
				c0.004,0.03,0.013,0.058,0.026,0.081c0.013,0.021,0.031,0.041,0.053,0.054c0.022,0.013,0.05,0.019,0.083,0.019
				c0.033,0,0.06-0.006,0.083-0.019c0.022-0.013,0.039-0.032,0.053-0.054c0.012-0.023,0.022-0.051,0.027-0.081
				c0.004-0.031,0.007-0.064,0.007-0.1s-0.003-0.068-0.007-0.099c-0.005-0.031-0.015-0.058-0.027-0.08
				c-0.014-0.023-0.031-0.041-0.053-0.054c-0.023-0.014-0.05-0.02-0.083-0.02c-0.033,0-0.061,0.006-0.083,0.02
				c-0.022,0.013-0.04,0.03-0.053,0.054c-0.013,0.022-0.022,0.049-0.026,0.08c-0.006,0.03-0.008,0.063-0.008,0.099
				S13.347-24.766,13.354-24.734 M13.409-25.331c0.026,0.033,0.062,0.051,0.106,0.051c0.044,0,0.079-0.018,0.105-0.051
				c0.027-0.032,0.04-0.085,0.04-0.154c0-0.136-0.048-0.201-0.145-0.201c-0.097,0-0.145,0.065-0.145,0.201
				C13.371-25.416,13.383-25.363,13.409-25.331"
        />
        <rect
          x="16.97"
          y="-26.393"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFDD00"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M17.884-25.895c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.019-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.884z M17.793-25.225c0.059,0,0.107-0.019,0.144-0.055
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058H17.66v0.45H17.793z"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          points="18.887,-25.895 19.098,-24.85 19.104,-24.85 19.316,-25.895
				19.757,-25.895 19.757,-24.415 19.484,-24.415 19.484,-25.597 19.48,-25.597 19.217,-24.415 18.984,-24.415 18.721,-25.597
				18.717,-25.597 18.717,-24.415 18.443,-24.415 18.443,-25.895 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M20.211-24.833c0,0.034,0.003,0.065,0.009,0.095
				c0.004,0.028,0.015,0.051,0.03,0.071c0.015,0.019,0.036,0.034,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
				c0.045,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.009-0.024-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.008,0.183,0.022c0.056,0.014,0.104,0.038,0.145,0.07s0.072,0.073,0.095,0.124s0.034,0.11,0.034,0.182v0.041
				h-0.285c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.015
				c-0.022,0.008-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				s0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.066,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.037-0.362-0.109
				c-0.072-0.073-0.108-0.18-0.108-0.315v-0.061h0.298V-24.833z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M21.818-25.451h-0.292v-0.194h0.026c0.1,0,0.178-0.019,0.235-0.056
				c0.058-0.038,0.094-0.098,0.109-0.181h0.22v1.467h-0.298V-25.451z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M22.483-25.393c0.006-0.094,0.025-0.178,0.056-0.25
				c0.03-0.073,0.076-0.13,0.137-0.174c0.062-0.043,0.148-0.066,0.256-0.066c0.091,0,0.165,0.014,0.224,0.039
				c0.058,0.026,0.105,0.067,0.14,0.123c0.035,0.057,0.06,0.131,0.075,0.221c0.014,0.091,0.022,0.201,0.022,0.331
				c0,0.103-0.004,0.2-0.011,0.294c-0.007,0.095-0.025,0.178-0.055,0.25c-0.031,0.072-0.077,0.13-0.139,0.171
				c-0.061,0.042-0.146,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.059-0.022-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.06-0.13-0.075-0.221c-0.014-0.09-0.022-0.2-0.022-0.33C22.472-25.2,22.477-25.299,22.483-25.393 M22.778-24.808
				c0.005,0.06,0.013,0.105,0.026,0.139c0.012,0.034,0.029,0.058,0.05,0.069c0.021,0.013,0.046,0.02,0.078,0.02
				c0.034,0,0.061-0.009,0.081-0.023c0.021-0.017,0.037-0.043,0.049-0.082c0.011-0.038,0.02-0.089,0.025-0.152
				c0.004-0.063,0.007-0.142,0.007-0.235v-0.16c0-0.092-0.003-0.168-0.007-0.228c-0.005-0.058-0.014-0.104-0.026-0.138
				c-0.013-0.034-0.029-0.058-0.05-0.07s-0.047-0.018-0.079-0.018c-0.033,0-0.059,0.008-0.08,0.022
				c-0.021,0.017-0.038,0.045-0.049,0.081c-0.012,0.038-0.02,0.09-0.025,0.153s-0.007,0.141-0.007,0.235v0.161
				C22.771-24.941,22.773-24.866,22.778-24.808"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M23.811-24.628c0.019,0.032,0.054,0.048,0.104,0.048
				c0.041,0,0.072-0.01,0.096-0.028c0.023-0.02,0.042-0.044,0.054-0.078c0.014-0.033,0.022-0.073,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.148v-0.07h-0.005c-0.024,0.037-0.06,0.066-0.106,0.089c-0.046,0.024-0.103,0.035-0.169,0.035
				c-0.065,0-0.12-0.011-0.165-0.035c-0.047-0.022-0.084-0.054-0.112-0.095c-0.028-0.04-0.049-0.089-0.062-0.145
				c-0.013-0.057-0.019-0.118-0.019-0.184c0-0.17,0.037-0.3,0.112-0.391c0.076-0.089,0.196-0.135,0.362-0.135
				c0.102,0,0.184,0.02,0.244,0.059c0.061,0.037,0.108,0.088,0.14,0.15c0.033,0.063,0.054,0.135,0.064,0.219
				c0.009,0.082,0.014,0.17,0.014,0.26c0,0.104-0.003,0.206-0.009,0.303c-0.006,0.098-0.025,0.184-0.056,0.258
				c-0.031,0.075-0.08,0.133-0.147,0.177c-0.067,0.044-0.161,0.066-0.281,0.066c-0.053,0-0.103-0.005-0.153-0.016
				c-0.049-0.011-0.092-0.03-0.129-0.059c-0.037-0.027-0.067-0.065-0.09-0.114c-0.023-0.048-0.035-0.106-0.037-0.178h0.287
				C23.781-24.701,23.79-24.658,23.811-24.628 M23.795-25.184c0.025,0.043,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.127-0.064
				s0.037-0.114,0.037-0.213c0-0.042-0.002-0.081-0.006-0.117c-0.004-0.036-0.012-0.066-0.024-0.091
				c-0.011-0.026-0.028-0.046-0.05-0.062c-0.021-0.014-0.049-0.021-0.084-0.021s-0.064,0.008-0.086,0.021
				c-0.021,0.016-0.037,0.035-0.048,0.062c-0.011,0.027-0.019,0.057-0.022,0.093c-0.003,0.035-0.005,0.073-0.005,0.115
				C23.758-25.298,23.77-25.227,23.795-25.184"
        />
        <rect
          x="56.502"
          y="-26.393"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#5087C7"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M57.416-25.895c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.025,0.094,0.06,0.125,0.101c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.057-0.055,0.103-0.096,0.139c-0.04,0.036-0.09,0.063-0.147,0.081
				c-0.058,0.019-0.123,0.027-0.192,0.027h-0.16v0.59h-0.299v-1.479H57.416z M57.325-25.225c0.059,0,0.107-0.019,0.145-0.055
				c0.036-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.049-0.17c-0.031-0.039-0.084-0.058-0.158-0.058h-0.125v0.45H57.325z
				"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          points="58.419,-25.895 58.63,-24.85 58.636,-24.85 58.849,-25.895
				59.29,-25.895 59.29,-24.415 59.017,-24.415 59.017,-25.597 59.013,-25.597 58.749,-24.415 58.517,-24.415 58.253,-25.597
				58.249,-25.597 58.249,-24.415 57.976,-24.415 57.976,-25.895 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M59.743-24.833c0,0.034,0.004,0.065,0.01,0.095
				c0.004,0.028,0.015,0.051,0.029,0.071c0.016,0.019,0.037,0.034,0.063,0.045c0.025,0.012,0.059,0.017,0.096,0.017
				c0.045,0,0.088-0.015,0.123-0.045c0.036-0.029,0.057-0.075,0.057-0.137c0-0.034-0.006-0.063-0.015-0.086
				c-0.009-0.024-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.032-0.017-0.075-0.032-0.125-0.048
				c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.032-0.035-0.057-0.076-0.071-0.122
				c-0.016-0.046-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.008,0.183,0.022c0.056,0.014,0.104,0.038,0.146,0.07s0.072,0.073,0.095,0.124s0.034,0.11,0.034,0.182v0.041
				h-0.285c0-0.07-0.014-0.125-0.038-0.162c-0.024-0.039-0.065-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.015
				c-0.022,0.008-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.061,0.059,0.123,0.086l0.23,0.099c0.057,0.025,0.104,0.051,0.139,0.078
				c0.037,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.037,0.066,0.045,0.102c0.009,0.038,0.013,0.079,0.013,0.125
				c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.217,0.106-0.379,0.106c-0.168,0-0.289-0.037-0.361-0.109
				c-0.072-0.073-0.107-0.18-0.107-0.315v-0.061h0.297V-24.833z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M61.011-24.415c0-0.065,0.006-0.125,0.02-0.182
				c0.013-0.058,0.033-0.11,0.061-0.162c0.026-0.051,0.06-0.102,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.147l0.121-0.112
				c0.033-0.029,0.061-0.056,0.079-0.081c0.021-0.025,0.036-0.049,0.047-0.075c0.011-0.024,0.019-0.051,0.022-0.081
				c0.002-0.027,0.005-0.061,0.005-0.099c0-0.121-0.048-0.182-0.146-0.182c-0.037,0-0.066,0.007-0.088,0.021
				s-0.038,0.037-0.05,0.063c-0.013,0.025-0.021,0.056-0.022,0.091c-0.004,0.034-0.006,0.07-0.006,0.109h-0.285v-0.056
				c0-0.137,0.035-0.242,0.109-0.314c0.073-0.074,0.188-0.11,0.346-0.11c0.149,0,0.26,0.034,0.332,0.104
				c0.071,0.069,0.107,0.167,0.107,0.296c0,0.048-0.004,0.091-0.012,0.132c-0.01,0.041-0.021,0.081-0.039,0.116
				c-0.019,0.038-0.043,0.074-0.071,0.11c-0.028,0.035-0.063,0.071-0.106,0.108l-0.164,0.153c-0.045,0.04-0.08,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.048,0.1h0.554v0.231H61.011z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M62.917-25.625c-0.061,0.086-0.115,0.178-0.162,0.275
				c-0.049,0.099-0.09,0.199-0.126,0.303s-0.065,0.21-0.089,0.316c-0.024,0.107-0.043,0.212-0.055,0.315h-0.313
				c0.011-0.088,0.027-0.182,0.05-0.278c0.022-0.098,0.052-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.134-0.305
				c0.053-0.104,0.114-0.203,0.187-0.301h-0.618v-0.257h0.904V-25.625z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M63.34-25.451h-0.292v-0.194h0.025c0.101,0,0.179-0.019,0.235-0.056
				c0.058-0.038,0.095-0.098,0.109-0.181h0.22v1.467H63.34V-25.451z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M64.048-25.067c0.042-0.066,0.104-0.107,0.188-0.124v-0.004
				c-0.041-0.011-0.074-0.025-0.102-0.047c-0.026-0.021-0.048-0.044-0.064-0.069c-0.017-0.027-0.029-0.055-0.035-0.083
				c-0.008-0.029-0.011-0.058-0.011-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.181-0.094,0.315-0.094
				s0.24,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.311c0,0.025-0.004,0.054-0.012,0.083
				c-0.008,0.028-0.02,0.056-0.036,0.083c-0.017,0.025-0.038,0.049-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.047v0.004
				c0.083,0.017,0.146,0.058,0.187,0.124s0.063,0.146,0.063,0.238c0,0.043-0.006,0.091-0.016,0.141
				c-0.01,0.052-0.031,0.099-0.065,0.143c-0.032,0.045-0.08,0.081-0.142,0.111c-0.062,0.027-0.144,0.043-0.246,0.043
				c-0.102,0-0.184-0.016-0.246-0.043c-0.062-0.03-0.107-0.066-0.141-0.111c-0.033-0.044-0.055-0.091-0.066-0.143
				c-0.01-0.05-0.014-0.098-0.014-0.141C63.987-24.922,64.008-25.001,64.048-25.067 M64.292-24.734
				c0.005,0.03,0.014,0.058,0.027,0.08c0.012,0.022,0.03,0.041,0.053,0.055c0.021,0.013,0.05,0.019,0.082,0.019
				c0.033,0,0.061-0.006,0.084-0.019c0.021-0.014,0.039-0.032,0.053-0.055c0.012-0.022,0.021-0.05,0.027-0.08
				c0.004-0.031,0.006-0.064,0.006-0.1s-0.002-0.068-0.006-0.099c-0.006-0.031-0.016-0.058-0.027-0.08
				c-0.014-0.023-0.031-0.041-0.053-0.054c-0.023-0.014-0.051-0.02-0.084-0.02c-0.032,0-0.061,0.006-0.082,0.02
				c-0.022,0.013-0.041,0.03-0.053,0.054c-0.014,0.022-0.022,0.049-0.027,0.08c-0.006,0.03-0.008,0.063-0.008,0.099
				S64.286-24.766,64.292-24.734 M64.349-25.331c0.025,0.033,0.063,0.05,0.105,0.05c0.045,0,0.08-0.017,0.105-0.05
				c0.027-0.032,0.04-0.085,0.04-0.154c0-0.136-0.048-0.201-0.146-0.201c-0.097,0-0.145,0.065-0.145,0.201
				C64.31-25.416,64.323-25.363,64.349-25.331"
        />
        <rect
          x="46.593"
          y="-26.393"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#C8CCCC"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M47.509-25.895c0.075,0,0.14,0.013,0.191,0.038s0.094,0.06,0.125,0.101
				c0.032,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.021,0.108,0.021,0.163c0,0.076-0.012,0.143-0.034,0.199
				s-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.147,0.081c-0.059,0.019-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479
				H47.509z M47.417-25.225c0.059,0,0.107-0.019,0.143-0.055c0.039-0.036,0.057-0.092,0.057-0.168c0-0.074-0.016-0.131-0.048-0.17
				s-0.085-0.058-0.16-0.058h-0.124v0.45H47.417z"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          points="48.511,-25.895 48.722,-24.85 48.728,-24.85 48.94,-25.895
				49.382,-25.895 49.382,-24.416 49.108,-24.416 49.108,-25.597 49.104,-25.597 48.841,-24.416 48.608,-24.416 48.347,-25.597
				48.343,-25.597 48.343,-24.416 48.067,-24.416 48.067,-25.895 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M49.836-24.833c0,0.034,0.003,0.065,0.008,0.095
				c0.005,0.028,0.017,0.051,0.031,0.071c0.015,0.019,0.036,0.034,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
				c0.046,0,0.087-0.015,0.123-0.045c0.037-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.063-0.015-0.086
				c-0.008-0.024-0.023-0.047-0.043-0.066c-0.021-0.019-0.049-0.036-0.082-0.053s-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.125-0.046-0.172-0.073c-0.049-0.026-0.09-0.057-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
				c-0.016-0.046-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.337-0.108
				c0.066,0,0.127,0.008,0.184,0.022c0.056,0.014,0.104,0.038,0.144,0.07c0.042,0.032,0.073,0.073,0.097,0.124
				c0.022,0.051,0.033,0.11,0.033,0.182v0.041h-0.285c0-0.07-0.014-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.125-0.057
				c-0.032,0-0.061,0.004-0.082,0.015c-0.022,0.008-0.041,0.022-0.055,0.037c-0.013,0.016-0.021,0.035-0.025,0.055
				c-0.006,0.021-0.008,0.042-0.008,0.065c0,0.045,0.01,0.083,0.029,0.115c0.02,0.03,0.061,0.059,0.124,0.086l0.229,0.099
				c0.058,0.025,0.104,0.051,0.14,0.078s0.063,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102
				c0.007,0.038,0.013,0.079,0.013,0.125c0,0.156-0.047,0.271-0.137,0.342c-0.09,0.07-0.216,0.106-0.378,0.106
				c-0.169,0-0.29-0.037-0.362-0.109c-0.072-0.073-0.107-0.18-0.107-0.315v-0.061h0.298V-24.833z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M51.581-25.883h0.309v0.932h0.141v0.231H51.89v0.305h-0.273v-0.305h-0.522
				v-0.244L51.581-25.883z M51.612-25.509l-0.287,0.558h0.291v-0.558H51.612z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M52.097-24.415c0-0.065,0.007-0.125,0.02-0.182
				c0.014-0.058,0.033-0.11,0.061-0.162c0.027-0.051,0.061-0.102,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.147l0.121-0.112
				c0.033-0.029,0.061-0.056,0.08-0.081c0.021-0.025,0.035-0.049,0.047-0.075c0.011-0.024,0.019-0.051,0.021-0.081
				c0.003-0.027,0.005-0.061,0.005-0.099c0-0.121-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.089,0.022
				s-0.037,0.037-0.049,0.063c-0.012,0.025-0.02,0.056-0.023,0.091c-0.003,0.034-0.004,0.07-0.004,0.109h-0.287v-0.056
				c0-0.137,0.037-0.242,0.109-0.315c0.074-0.073,0.188-0.109,0.347-0.109c0.149,0,0.26,0.034,0.332,0.104
				c0.071,0.069,0.108,0.167,0.108,0.296c0,0.048-0.004,0.091-0.014,0.132c-0.008,0.041-0.021,0.081-0.039,0.116
				c-0.018,0.038-0.041,0.074-0.07,0.11c-0.028,0.035-0.064,0.071-0.106,0.108l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H52.097z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M54.004-25.625c-0.062,0.086-0.116,0.178-0.163,0.275
				c-0.047,0.099-0.089,0.199-0.126,0.303c-0.035,0.104-0.065,0.21-0.089,0.316c-0.023,0.107-0.041,0.212-0.053,0.315h-0.314
				c0.012-0.088,0.027-0.182,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
				c0.053-0.104,0.115-0.203,0.187-0.301H53.1v-0.257h0.904V-25.625z"
        />
        <rect
          x="66.37"
          y="-26.393"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          width="8.813"
          height="8.813"
        />

        <rect
          x="66.37"
          y="-26.393"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.813"
          height="8.813"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          points="66.962,-25.895 67.124,-24.8 67.13,-24.8 67.304,-25.895 67.589,-25.895
				67.763,-24.8 67.767,-24.8 67.931,-25.895 68.218,-25.895 67.947,-24.415 67.614,-24.415 67.448,-25.488 67.444,-25.488
				67.278,-24.415 66.944,-24.415 66.675,-25.895 			"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          points="68.636,-25.895 68.636,-25.328 68.984,-25.328 68.984,-25.895 69.282,-25.895
				69.282,-24.415 68.984,-24.415 68.984,-25.071 68.636,-25.071 68.636,-24.415 68.337,-24.415 68.337,-25.895 			"
        />
        <rect
          x="69.504"
          y="-25.895"
          clipPath="url(#WHITTLING_SVGID_58_)"
          width="0.298"
          height="1.479"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          points="70.891,-25.649 70.567,-25.649 70.567,-24.415 70.269,-24.415 70.269,-25.649
				69.946,-25.649 69.946,-25.895 70.891,-25.895 			"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          points="71.847,-25.649 71.317,-25.649 71.317,-25.302 71.813,-25.302 71.813,-25.057
				71.317,-25.057 71.317,-24.658 71.867,-24.658 71.867,-24.415 71.019,-24.415 71.019,-25.895 71.847,-25.895 			"
        />
        <rect
          x="26.838"
          y="-26.393"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#AC6E26"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M27.752-25.895c0.076,0,0.14,0.013,0.192,0.037
				c0.052,0.026,0.094,0.061,0.125,0.102c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59H27.23v-1.479H27.752z M27.661-25.226c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.449H27.661z"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          points="28.756,-25.895 28.967,-24.85 28.972,-24.85 29.185,-25.895
				29.625,-25.895 29.625,-24.416 29.353,-24.416 29.353,-25.597 29.349,-25.597 29.085,-24.416 28.853,-24.416 28.59,-25.597
				28.585,-25.597 28.585,-24.416 28.312,-24.416 28.312,-25.895 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M30.08-24.833c0,0.033,0.003,0.065,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.034,0.061,0.045c0.026,0.012,0.059,0.016,0.097,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.009-0.025-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.101-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.008,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071s0.072,0.073,0.095,0.124s0.034,0.11,0.034,0.182v0.041
				h-0.285c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.023,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.026,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.064,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.105-0.378,0.105c-0.168,0-0.289-0.036-0.362-0.109
				c-0.072-0.072-0.108-0.179-0.108-0.314v-0.061h0.298V-24.833z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M31.687-25.451h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.055
				c0.058-0.038,0.094-0.098,0.109-0.181h0.22v1.467h-0.298V-25.451z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M32.665-24.648c0.021,0.045,0.065,0.068,0.131,0.068
				c0.035,0,0.063-0.007,0.086-0.02c0.021-0.013,0.037-0.028,0.049-0.051c0.012-0.021,0.02-0.047,0.023-0.075
				c0.002-0.028,0.004-0.06,0.004-0.091c0-0.034-0.002-0.064-0.006-0.095c-0.006-0.029-0.015-0.056-0.029-0.079
				c-0.012-0.024-0.032-0.043-0.057-0.057c-0.025-0.014-0.059-0.021-0.1-0.021h-0.1v-0.195h0.097c0.032,0,0.059-0.006,0.08-0.019
				c0.022-0.013,0.038-0.03,0.052-0.052s0.023-0.046,0.029-0.074c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.07-0.013-0.119-0.035-0.149c-0.024-0.028-0.06-0.043-0.108-0.043c-0.03,0-0.057,0.006-0.076,0.018
				c-0.021,0.012-0.036,0.028-0.047,0.05c-0.012,0.021-0.019,0.047-0.022,0.076c-0.003,0.028-0.005,0.061-0.005,0.095h-0.272
				c0-0.146,0.035-0.255,0.108-0.326c0.072-0.071,0.182-0.106,0.322-0.106c0.134,0,0.236,0.029,0.312,0.092
				c0.074,0.06,0.11,0.155,0.11,0.286c0,0.088-0.02,0.16-0.059,0.216s-0.095,0.091-0.164,0.106v0.004
				c0.094,0.016,0.162,0.054,0.201,0.114c0.039,0.063,0.059,0.14,0.059,0.232c0,0.049-0.006,0.101-0.019,0.153
				c-0.012,0.053-0.036,0.1-0.071,0.143c-0.037,0.043-0.086,0.078-0.149,0.104c-0.062,0.026-0.146,0.039-0.247,0.039
				c-0.143,0-0.249-0.038-0.319-0.115s-0.105-0.188-0.105-0.329v-0.007h0.286C32.632-24.758,32.645-24.693,32.665-24.648"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M33.679-24.628c0.02,0.032,0.055,0.047,0.104,0.047
				c0.041,0,0.072-0.009,0.097-0.027c0.022-0.02,0.041-0.045,0.053-0.078c0.015-0.033,0.022-0.073,0.027-0.118
				c0.004-0.045,0.006-0.095,0.006-0.148v-0.07h-0.004c-0.024,0.037-0.061,0.065-0.106,0.089c-0.046,0.022-0.103,0.035-0.169,0.035
				c-0.065,0-0.12-0.013-0.165-0.035c-0.048-0.023-0.084-0.054-0.112-0.095c-0.027-0.041-0.049-0.09-0.063-0.146
				c-0.012-0.056-0.019-0.117-0.019-0.183c0-0.17,0.037-0.3,0.112-0.391c0.076-0.09,0.195-0.135,0.361-0.135
				c0.103,0,0.185,0.02,0.244,0.059c0.062,0.037,0.108,0.088,0.141,0.15c0.033,0.063,0.054,0.135,0.064,0.218
				c0.008,0.083,0.014,0.171,0.014,0.261c0,0.104-0.004,0.206-0.01,0.303c-0.006,0.098-0.024,0.184-0.056,0.258
				c-0.03,0.075-0.08,0.133-0.147,0.177c-0.066,0.043-0.16,0.065-0.28,0.065c-0.054,0-0.103-0.004-0.153-0.015
				c-0.049-0.011-0.092-0.03-0.129-0.059s-0.066-0.065-0.09-0.114c-0.023-0.048-0.035-0.106-0.037-0.178h0.286
				C33.648-24.701,33.658-24.658,33.679-24.628 M33.663-25.184c0.025,0.042,0.066,0.064,0.124,0.064c0.06,0,0.103-0.022,0.127-0.064
				c0.025-0.043,0.037-0.115,0.037-0.213c0-0.042-0.002-0.081-0.006-0.117s-0.013-0.066-0.024-0.092
				c-0.011-0.025-0.027-0.045-0.05-0.061c-0.021-0.014-0.049-0.021-0.084-0.021c-0.034,0-0.063,0.008-0.086,0.021
				c-0.021,0.016-0.037,0.035-0.048,0.062c-0.012,0.026-0.02,0.057-0.021,0.092c-0.004,0.036-0.006,0.074-0.006,0.116
				C33.626-25.299,33.638-25.227,33.663-25.184"
        />
        <rect
          x="36.729"
          y="-26.393"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#623B23"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M37.644-25.895c0.075,0,0.139,0.013,0.191,0.037
				c0.052,0.026,0.094,0.061,0.125,0.102c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.011,0.143-0.034,0.199s-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.147,0.081
				c-0.059,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H37.644z M37.552-25.226c0.059,0,0.107-0.018,0.145-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.16-0.058h-0.124v0.449H37.552z"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          points="38.646,-25.895 38.856,-24.85 38.862,-24.85 39.075,-25.895
				39.517,-25.895 39.517,-24.416 39.243,-24.416 39.243,-25.597 39.239,-25.597 38.976,-24.416 38.743,-24.416 38.481,-25.597
				38.478,-25.597 38.478,-24.416 38.202,-24.416 38.202,-25.895 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M39.971-24.833c0,0.033,0.003,0.065,0.008,0.094s0.017,0.052,0.031,0.072
				c0.015,0.019,0.036,0.034,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.014,0.123-0.044
				c0.037-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.063-0.015-0.086c-0.008-0.025-0.023-0.047-0.043-0.066
				c-0.021-0.019-0.049-0.036-0.082-0.053s-0.076-0.032-0.125-0.048c-0.066-0.022-0.125-0.046-0.172-0.073
				c-0.05-0.026-0.09-0.057-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122c-0.016-0.046-0.023-0.101-0.023-0.161
				c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.008,0.185,0.021
				c0.056,0.015,0.104,0.039,0.144,0.071c0.042,0.032,0.073,0.073,0.097,0.124c0.022,0.051,0.033,0.11,0.033,0.182v0.041h-0.285
				c0-0.071-0.014-0.125-0.039-0.162c-0.023-0.039-0.065-0.057-0.123-0.057c-0.033,0-0.061,0.004-0.082,0.014
				c-0.023,0.009-0.041,0.023-0.055,0.038c-0.013,0.016-0.021,0.035-0.025,0.055c-0.006,0.021-0.008,0.042-0.008,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.02,0.03,0.061,0.059,0.124,0.086l0.229,0.099c0.058,0.025,0.104,0.051,0.14,0.078
				c0.036,0.026,0.063,0.056,0.086,0.087c0.021,0.03,0.036,0.064,0.045,0.102c0.007,0.038,0.011,0.079,0.011,0.125
				c0,0.156-0.045,0.271-0.135,0.342c-0.09,0.07-0.216,0.105-0.378,0.105c-0.169,0-0.29-0.036-0.362-0.109
				c-0.072-0.072-0.107-0.179-0.107-0.314v-0.061h0.298V-24.833z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M41.716-25.883h0.309v0.931h0.141v0.232h-0.141v0.305h-0.273v-0.305h-0.522
				v-0.244L41.716-25.883z M41.747-25.509l-0.287,0.557h0.291v-0.557H41.747z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M42.809-25.643c-0.019-0.03-0.056-0.045-0.104-0.045
				c-0.039,0-0.069,0.01-0.095,0.028c-0.024,0.018-0.043,0.045-0.056,0.077c-0.014,0.033-0.021,0.073-0.025,0.118
				c-0.005,0.046-0.007,0.096-0.007,0.149v0.073h0.004c0.025-0.039,0.062-0.069,0.106-0.093c0.048-0.022,0.104-0.034,0.17-0.034
				c0.064,0,0.119,0.012,0.166,0.034c0.045,0.023,0.082,0.055,0.111,0.096c0.027,0.041,0.049,0.089,0.062,0.145
				c0.013,0.057,0.019,0.117,0.019,0.184c0,0.17-0.037,0.299-0.111,0.387c-0.076,0.088-0.196,0.131-0.362,0.131
				c-0.103,0-0.185-0.017-0.245-0.053s-0.107-0.086-0.139-0.148c-0.033-0.063-0.055-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.205,0.009-0.302c0.006-0.099,0.024-0.185,0.056-0.26
				c0.031-0.074,0.08-0.135,0.146-0.182c0.066-0.045,0.161-0.068,0.281-0.068c0.053,0,0.104,0.007,0.152,0.02
				c0.051,0.013,0.094,0.033,0.131,0.062c0.037,0.029,0.066,0.067,0.09,0.115c0.022,0.048,0.035,0.107,0.035,0.178h-0.285
				C42.839-25.566,42.829-25.61,42.809-25.643 M42.542-24.755c0.004,0.036,0.013,0.066,0.024,0.093
				c0.011,0.025,0.028,0.046,0.05,0.06s0.049,0.022,0.084,0.022s0.064-0.009,0.086-0.022s0.037-0.034,0.049-0.062
				c0.011-0.025,0.019-0.057,0.021-0.093c0.004-0.034,0.006-0.072,0.006-0.114c0-0.1-0.012-0.171-0.036-0.214
				c-0.025-0.043-0.066-0.063-0.125-0.063c-0.06,0-0.103,0.021-0.127,0.063c-0.025,0.043-0.037,0.114-0.037,0.214
				C42.536-24.829,42.538-24.791,42.542-24.755"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M43.569-24.628c0.02,0.032,0.055,0.047,0.104,0.047
				c0.04,0,0.072-0.009,0.097-0.027c0.022-0.02,0.041-0.045,0.053-0.078c0.014-0.033,0.022-0.073,0.027-0.118
				c0.004-0.045,0.006-0.095,0.006-0.149v-0.069h-0.004c-0.025,0.037-0.061,0.065-0.106,0.089c-0.048,0.022-0.103,0.035-0.169,0.035
				s-0.12-0.013-0.166-0.035c-0.047-0.023-0.083-0.054-0.111-0.095c-0.027-0.041-0.049-0.09-0.063-0.146s-0.02-0.117-0.02-0.183
				c0-0.17,0.038-0.3,0.113-0.391c0.074-0.09,0.195-0.135,0.361-0.135c0.103,0,0.184,0.02,0.244,0.059
				c0.062,0.036,0.108,0.088,0.141,0.15c0.031,0.063,0.054,0.135,0.064,0.218c0.008,0.083,0.014,0.171,0.014,0.261
				c0,0.104-0.004,0.206-0.01,0.303c-0.006,0.098-0.024,0.184-0.057,0.258c-0.029,0.075-0.08,0.133-0.146,0.177
				c-0.067,0.043-0.162,0.065-0.28,0.065c-0.054,0-0.104-0.004-0.153-0.015s-0.092-0.03-0.129-0.059s-0.068-0.065-0.091-0.114
				c-0.022-0.048-0.034-0.106-0.036-0.178h0.286C43.539-24.701,43.549-24.658,43.569-24.628 M43.554-25.184
				c0.025,0.042,0.066,0.064,0.124,0.064c0.06,0,0.103-0.022,0.126-0.064c0.025-0.043,0.038-0.115,0.038-0.213
				c0-0.042-0.002-0.081-0.006-0.117s-0.013-0.066-0.024-0.092s-0.027-0.045-0.05-0.061c-0.021-0.014-0.049-0.021-0.084-0.021
				c-0.036,0-0.063,0.008-0.086,0.021c-0.021,0.016-0.037,0.035-0.049,0.062c-0.011,0.025-0.019,0.057-0.021,0.092
				c-0.004,0.036-0.006,0.074-0.006,0.116C43.517-25.299,43.528-25.227,43.554-25.184"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M12.708,75.936c0.592,0,0.973,0.392,0.973,0.991
				c0,0.594-0.381,0.986-0.973,0.986c-0.588,0-0.969-0.393-0.969-0.986C11.739,76.327,12.12,75.936,12.708,75.936 M12.708,77.525
				c0.352,0,0.457-0.302,0.457-0.599c0-0.302-0.105-0.603-0.457-0.603c-0.349,0-0.454,0.301-0.454,0.603
				C12.253,77.224,12.358,77.525,12.708,77.525"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M14.666,75.986h0.378v0.345h-0.378v0.929c0,0.175,0.044,0.219,0.218,0.219
				c0.054,0,0.105-0.004,0.16-0.016v0.403c-0.087,0.014-0.2,0.018-0.302,0.018c-0.315,0-0.591-0.072-0.591-0.445v-1.107h-0.312
				v-0.345h0.312v-0.563h0.515V75.986z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M15.714,77.05c0.014,0.326,0.174,0.476,0.46,0.476
				c0.208,0,0.374-0.127,0.407-0.243h0.454c-0.146,0.442-0.454,0.631-0.879,0.631c-0.591,0-0.957-0.406-0.957-0.986
				c0-0.563,0.388-0.992,0.957-0.992c0.639,0,0.947,0.538,0.911,1.115H15.714z M16.552,76.723c-0.047-0.261-0.16-0.398-0.41-0.398
				c-0.327,0-0.421,0.254-0.428,0.398H16.552z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M17.77,77.253c0.004,0.225,0.193,0.315,0.396,0.315
				c0.149,0,0.337-0.058,0.337-0.239c0-0.156-0.214-0.21-0.584-0.29c-0.298-0.065-0.595-0.17-0.595-0.501
				c0-0.479,0.414-0.603,0.816-0.603c0.41,0,0.788,0.139,0.828,0.599h-0.49c-0.015-0.199-0.167-0.255-0.352-0.255
				c-0.116,0-0.287,0.022-0.287,0.176c0,0.185,0.29,0.21,0.584,0.278c0.302,0.069,0.596,0.179,0.596,0.526
				c0,0.494-0.429,0.653-0.857,0.653c-0.436,0-0.86-0.163-0.882-0.66H17.77z"
        />
        <rect
          x="8.913"
          y="80.465"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M12.018,79.027h0.89c0.798,0,1.244,0.399,1.244,1.234
				c0,0.868-0.381,1.357-1.244,1.357h-0.89V79.027z M12.362,81.328h0.573c0.236,0,0.871-0.064,0.871-1.02
				c0-0.616-0.228-0.99-0.864-0.99h-0.58V81.328z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M15.33,79.699c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.418,80.131,14.735,79.699,15.33,79.699 M15.33,81.391
				c0.323,0,0.584-0.255,0.584-0.707c0-0.458-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.255-0.584,0.713
				C14.746,81.136,15.007,81.391,15.33,81.391"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M17.61,79.742h0.291v0.298h0.006c0.131-0.232,0.341-0.342,0.61-0.342
				c0.494,0,0.646,0.283,0.646,0.687v1.234h-0.308v-1.271c0-0.228-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.06
				H17.61V79.742z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M20.436,79.699c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.525,80.131,19.841,79.699,20.436,79.699 M20.436,81.391
				c0.323,0,0.584-0.255,0.584-0.707c0-0.458-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.255-0.584,0.713
				C19.852,81.136,20.113,81.391,20.436,81.391"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M22.137,79.742h0.374v0.272h-0.374v1.165c0,0.142,0.039,0.167,0.232,0.167
				h0.142v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319v-0.272h0.319V79.18h0.309V79.742z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M25.522,81.612c-0.055,0.031-0.123,0.05-0.222,0.05
				c-0.16,0-0.261-0.087-0.261-0.29c-0.17,0.2-0.399,0.29-0.661,0.29c-0.34,0-0.62-0.152-0.62-0.526
				c0-0.424,0.316-0.515,0.635-0.577c0.341-0.064,0.632-0.043,0.632-0.274c0-0.27-0.222-0.313-0.418-0.313
				c-0.261,0-0.453,0.081-0.468,0.356H23.83c0.018-0.465,0.378-0.628,0.795-0.628c0.338,0,0.704,0.077,0.704,0.515v0.966
				c0,0.146,0,0.21,0.098,0.21c0.026,0,0.055-0.003,0.095-0.018V81.612z M25.021,80.65c-0.12,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.035-0.378,0.108-0.378,0.337c0,0.204,0.175,0.275,0.364,0.275c0.406,0,0.573-0.254,0.573-0.424V80.65z"
        />
        <rect
          x="25.826"
          y="79.027"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          width="0.308"
          height="2.592"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M27.041,79.742h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.167,0.232,0.167
				h0.142v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319v-0.272h0.319V79.18h0.309V79.742z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M29.355,81.024c-0.083,0.413-0.381,0.638-0.802,0.638
				c-0.599,0-0.882-0.414-0.9-0.987c0-0.562,0.37-0.976,0.882-0.976c0.663,0,0.867,0.62,0.849,1.074h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.366H29.355z M29.057,80.501
				c-0.014-0.294-0.236-0.53-0.54-0.53c-0.323,0-0.516,0.243-0.538,0.53H29.057z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M29.691,79.742h0.291v0.396h0.006c0.149-0.301,0.356-0.45,0.69-0.44v0.327
				c-0.497,0-0.678,0.283-0.678,0.758v0.836h-0.309V79.742z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M33.58,81.612c-0.055,0.031-0.122,0.05-0.222,0.05
				c-0.159,0-0.262-0.087-0.262-0.29c-0.17,0.2-0.398,0.29-0.659,0.29c-0.342,0-0.621-0.152-0.621-0.526
				c0-0.424,0.316-0.515,0.636-0.577c0.34-0.064,0.631-0.043,0.631-0.274c0-0.27-0.222-0.313-0.416-0.313
				c-0.263,0-0.454,0.081-0.47,0.356h-0.308c0.018-0.465,0.378-0.628,0.795-0.628c0.337,0,0.703,0.077,0.703,0.515v0.966
				c0,0.146,0,0.21,0.098,0.21c0.025,0,0.055-0.003,0.095-0.018V81.612z M33.079,80.65c-0.119,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.035-0.377,0.108-0.377,0.337c0,0.204,0.174,0.275,0.363,0.275c0.405,0,0.572-0.254,0.572-0.424V80.65z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M33.854,79.742h0.291v0.396h0.007c0.149-0.301,0.356-0.45,0.69-0.44v0.327
				c-0.498,0-0.68,0.283-0.68,0.758v0.836h-0.309V79.742z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M35.502,79.742h0.374v0.272h-0.374v1.165c0,0.142,0.039,0.167,0.231,0.167
				h0.143v0.272H35.64c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.318v-0.272h0.318V79.18h0.31V79.742z"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          points="38.074,81.619 37.748,81.619 37.367,80.127 37.358,80.127
				36.981,81.619 36.647,81.619 36.045,79.743 36.386,79.743 36.812,81.277 36.819,81.277 37.196,79.743 37.534,79.743
				37.925,81.277 37.933,81.277 38.353,79.743 38.673,79.743 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M39.774,79.699c0.596,0,0.911,0.432,0.911,0.984
				c0,0.547-0.315,0.979-0.911,0.979c-0.595,0-0.91-0.432-0.91-0.979C38.864,80.131,39.18,79.699,39.774,79.699 M39.774,81.391
				c0.323,0,0.584-0.255,0.584-0.707c0-0.458-0.261-0.713-0.584-0.713c-0.322,0-0.584,0.255-0.584,0.713
				C39.19,81.136,39.452,81.391,39.774,81.391"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M41.036,79.742h0.291v0.396h0.006c0.149-0.301,0.356-0.45,0.69-0.44v0.327
				c-0.497,0-0.679,0.283-0.679,0.758v0.836h-0.309V79.742z"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          points="42.273,79.027 42.583,79.027 42.583,80.563 43.438,79.743
				43.853,79.743 43.108,80.425 43.906,81.619 43.515,81.619 42.871,80.639 42.583,80.908 42.583,81.619 42.273,81.619 			"
        />
        <rect
          x="8.913"
          y="84.221"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M14.086,84.439c0,0.653-0.374,0.998-1.02,0.998
				c-0.668,0-1.063-0.31-1.063-0.998v-1.655h0.345v1.655c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.655
				h0.345V84.439z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M14.774,84.784c0.011,0.275,0.25,0.362,0.505,0.362
				c0.192,0,0.454-0.044,0.454-0.279c0-0.239-0.306-0.279-0.614-0.349c-0.305-0.068-0.614-0.171-0.614-0.529
				c0-0.378,0.374-0.533,0.701-0.533c0.414,0,0.744,0.13,0.769,0.588h-0.308c-0.022-0.24-0.233-0.316-0.435-0.316
				c-0.186,0-0.4,0.052-0.4,0.239c0,0.223,0.326,0.258,0.614,0.327c0.308,0.069,0.612,0.171,0.612,0.534
				c0,0.446-0.417,0.591-0.798,0.591c-0.421,0-0.777-0.171-0.794-0.635H14.774z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M17.999,84.78c-0.083,0.413-0.38,0.639-0.802,0.639
				c-0.598,0-0.882-0.414-0.9-0.987c0-0.562,0.371-0.976,0.882-0.976c0.664,0,0.868,0.62,0.849,1.073h-1.404
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.366H17.999z M17.702,84.258
				c-0.015-0.294-0.236-0.53-0.541-0.53c-0.323,0-0.515,0.243-0.537,0.53H17.702z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M19.783,83.499h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V83.499z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M20.824,83.162h-0.308v-0.379h0.308V83.162z M20.515,83.5h0.308v1.876
				h-0.308V83.5z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M22.921,85.215c0,0.61-0.28,0.923-0.871,0.923
				c-0.352,0-0.759-0.143-0.777-0.552h0.309c0.014,0.226,0.283,0.305,0.486,0.305c0.403,0,0.562-0.29,0.562-0.711v-0.123h-0.007
				c-0.102,0.228-0.348,0.341-0.584,0.341c-0.559,0-0.838-0.439-0.838-0.951c0-0.443,0.217-0.991,0.867-0.991
				c0.236,0,0.446,0.106,0.559,0.313h0.003v-0.269h0.291V85.215z M22.616,84.399c0-0.331-0.146-0.672-0.533-0.672
				c-0.396,0-0.556,0.323-0.556,0.683c0,0.337,0.124,0.714,0.53,0.714C22.464,85.124,22.616,84.751,22.616,84.399"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M23.384,82.784h0.309v0.991H23.7c0.102-0.226,0.366-0.319,0.591-0.319
				c0.494,0,0.646,0.282,0.646,0.687v1.232h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
				h-0.309V82.784z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M25.829,83.499h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V83.499z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M28.361,83.456c0.595,0,0.911,0.432,0.911,0.983
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.433-0.911-0.979C27.45,83.888,27.766,83.456,28.361,83.456 M28.361,85.146
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C27.777,84.893,28.038,85.146,28.361,85.146"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          points="30.479,85.375 30.148,85.375 29.452,83.499 29.797,83.499
				30.323,85.063 30.33,85.063 30.842,83.499 31.164,83.499 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M33.048,84.78c-0.084,0.413-0.381,0.639-0.802,0.639
				c-0.6,0-0.882-0.414-0.9-0.987c0-0.563,0.37-0.976,0.882-0.976c0.663,0,0.867,0.619,0.85,1.073h-1.406
				c-0.011,0.326,0.175,0.617,0.584,0.617c0.255,0,0.433-0.123,0.487-0.366H33.048z M32.749,84.258
				c-0.014-0.294-0.235-0.53-0.54-0.53c-0.322,0-0.516,0.243-0.538,0.53H32.749z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M33.384,83.499h0.291v0.396h0.006c0.148-0.302,0.355-0.45,0.689-0.44v0.327
				c-0.496,0-0.678,0.283-0.678,0.758v0.835h-0.309V83.499z"
        />
        <rect
          x="34.621"
          y="82.784"
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          width="0.308"
          height="2.592"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M36.217,83.456c0.595,0,0.911,0.432,0.911,0.983
				c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.433-0.911-0.979C35.306,83.888,35.622,83.456,36.217,83.456 M36.217,85.146
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712s-0.584,0.254-0.584,0.712
				C35.633,84.893,35.894,85.146,36.217,85.146"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M38.763,84.102c-0.047-0.235-0.209-0.374-0.463-0.374
				c-0.447,0-0.586,0.353-0.586,0.733c0,0.345,0.156,0.686,0.549,0.686c0.297,0,0.475-0.174,0.515-0.457h0.315
				c-0.068,0.457-0.355,0.729-0.826,0.729c-0.573,0-0.879-0.399-0.879-0.958c0-0.563,0.291-1.005,0.886-1.005
				c0.424,0,0.766,0.2,0.809,0.646H38.763z"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          points="39.455,82.784 39.765,82.784 39.765,84.319 40.62,83.499
				41.034,83.499 40.29,84.182 41.088,85.375 40.696,85.375 40.053,84.396 39.765,84.664 39.765,85.375 39.455,85.375 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M41.646,83.162h-0.309v-0.379h0.309V83.162z M41.337,83.5h0.309v1.876
				h-0.309V83.5z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M42.124,83.499h0.291v0.297h0.006c0.133-0.231,0.342-0.341,0.61-0.341
				c0.493,0,0.646,0.283,0.646,0.687v1.233h-0.308v-1.27c0-0.229-0.146-0.378-0.381-0.378c-0.374,0-0.556,0.251-0.556,0.589v1.059
				h-0.309V83.499z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#EC008C"
          d="M45.759,85.215c0,0.61-0.279,0.923-0.87,0.923
				c-0.353,0-0.759-0.143-0.777-0.552h0.31c0.014,0.226,0.283,0.305,0.485,0.305c0.403,0,0.563-0.29,0.563-0.711v-0.123h-0.008
				c-0.103,0.228-0.349,0.341-0.584,0.341c-0.56,0-0.838-0.439-0.838-0.951c0-0.443,0.217-0.991,0.866-0.991
				c0.236,0,0.446,0.106,0.56,0.313h0.004v-0.269h0.289V85.215z M45.454,84.399c0-0.331-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.323-0.556,0.683c0,0.337,0.124,0.714,0.53,0.714C45.303,85.124,45.454,84.751,45.454,84.399"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-12.319-25.895c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.088,0.069,0.143c0.013,0.053,0.02,0.107,0.02,0.163
				c0,0.075-0.011,0.142-0.034,0.198c-0.023,0.058-0.054,0.104-0.095,0.139c-0.041,0.037-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.319z M-12.41-25.225c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.048-0.17c-0.032-0.039-0.085-0.059-0.159-0.059h-0.125v0.45H-12.41z
				"
        />
        <polygon
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          points="-11.316,-25.895 -11.105,-24.85 -11.101,-24.85 -10.887,-25.895
				-10.445,-25.895 -10.445,-24.414 -10.719,-24.414 -10.719,-25.597 -10.724,-25.597 -10.986,-24.414 -11.219,-24.414
				-11.481,-25.597 -11.485,-25.597 -11.485,-24.414 -11.759,-24.414 -11.759,-25.895 			"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-9.992-24.833c0,0.034,0.003,0.066,0.008,0.094
				c0.005,0.028,0.016,0.053,0.031,0.072s0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.028,0.056-0.075,0.056-0.137c0-0.034-0.006-0.062-0.015-0.086
				c-0.008-0.024-0.023-0.047-0.044-0.065c-0.021-0.02-0.048-0.036-0.082-0.054c-0.033-0.016-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.124-0.047-0.172-0.073c-0.048-0.025-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.075-0.072-0.122
				c-0.015-0.045-0.023-0.099-0.023-0.16c0-0.146,0.041-0.256,0.122-0.326c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041H-9.64c0-0.07-0.012-0.125-0.037-0.161c-0.024-0.039-0.066-0.058-0.124-0.058
				c-0.033,0-0.061,0.004-0.083,0.015c-0.022,0.009-0.04,0.021-0.054,0.037c-0.013,0.017-0.021,0.035-0.026,0.056
				c-0.005,0.021-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.029,0.114c0.019,0.032,0.06,0.06,0.124,0.086l0.23,0.101
				c0.057,0.023,0.103,0.051,0.139,0.077c0.036,0.027,0.064,0.057,0.086,0.087s0.036,0.065,0.045,0.103
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.269-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.169,0-0.29-0.036-0.362-0.109c-0.072-0.074-0.108-0.179-0.108-0.315v-0.061h0.298V-24.833z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-8.401-24.647c0.021,0.045,0.065,0.067,0.13,0.067
				c0.035,0,0.064-0.007,0.086-0.019c0.021-0.013,0.037-0.03,0.049-0.051c0.012-0.021,0.019-0.047,0.023-0.076
				c0.003-0.028,0.005-0.06,0.005-0.091c0-0.033-0.002-0.064-0.007-0.095c-0.005-0.028-0.015-0.056-0.028-0.079
				c-0.014-0.023-0.033-0.043-0.058-0.057s-0.059-0.021-0.1-0.021H-8.4v-0.195h0.097c0.032,0,0.059-0.006,0.08-0.019
				c0.021-0.014,0.039-0.03,0.052-0.053c0.013-0.021,0.023-0.046,0.029-0.074c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.069-0.012-0.118-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.057,0.006-0.077,0.017
				c-0.021,0.012-0.036,0.029-0.047,0.05c-0.011,0.022-0.018,0.048-0.022,0.076c-0.003,0.029-0.005,0.062-0.005,0.095H-8.7
				c0-0.146,0.036-0.255,0.109-0.325c0.073-0.071,0.181-0.107,0.322-0.107c0.133,0,0.237,0.03,0.311,0.092
				c0.074,0.061,0.111,0.155,0.111,0.285c0,0.089-0.02,0.161-0.059,0.217C-7.945-25.231-8-25.196-8.07-25.181v0.004
				c0.095,0.015,0.162,0.053,0.201,0.114c0.04,0.062,0.06,0.139,0.06,0.231c0,0.05-0.007,0.102-0.019,0.153
				c-0.012,0.052-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.077-0.149,0.104c-0.062,0.026-0.146,0.039-0.248,0.039
				c-0.141,0-0.248-0.038-0.319-0.116c-0.07-0.077-0.105-0.188-0.105-0.329v-0.007h0.286C-8.434-24.758-8.422-24.692-8.401-24.647"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-7.153-25.642c-0.02-0.03-0.056-0.046-0.105-0.046
				c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.019-0.042,0.045-0.056,0.078c-0.013,0.033-0.022,0.072-0.025,0.118
				c-0.005,0.045-0.007,0.095-0.007,0.148v0.072h0.004c0.026-0.038,0.061-0.067,0.108-0.092c0.046-0.022,0.102-0.034,0.168-0.034
				c0.065,0,0.12,0.012,0.166,0.034c0.045,0.024,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.089,0.062,0.146
				c0.013,0.056,0.02,0.117,0.02,0.183c0,0.17-0.038,0.298-0.113,0.387c-0.075,0.089-0.196,0.132-0.362,0.132
				c-0.102,0-0.184-0.019-0.245-0.054c-0.06-0.037-0.107-0.086-0.139-0.148c-0.033-0.063-0.054-0.137-0.064-0.219
				c-0.009-0.082-0.014-0.169-0.014-0.26c0-0.103,0.003-0.204,0.009-0.301c0.006-0.098,0.025-0.184,0.057-0.259
				c0.03-0.075,0.079-0.136,0.145-0.183c0.067-0.045,0.161-0.067,0.282-0.067c0.052,0,0.104,0.007,0.152,0.019
				c0.05,0.013,0.093,0.033,0.13,0.063s0.068,0.066,0.09,0.115c0.023,0.048,0.035,0.106,0.036,0.177h-0.286
				C-7.124-25.566-7.133-25.611-7.153-25.642 M-7.42-24.754c0.005,0.035,0.012,0.066,0.024,0.092
				c0.012,0.026,0.028,0.046,0.05,0.061c0.021,0.014,0.049,0.021,0.084,0.021c0.036,0,0.064-0.008,0.086-0.021
				c0.021-0.015,0.037-0.034,0.048-0.062c0.011-0.026,0.019-0.057,0.022-0.093c0.003-0.034,0.005-0.073,0.005-0.115
				c0-0.099-0.012-0.171-0.037-0.213c-0.025-0.043-0.066-0.064-0.124-0.064c-0.06,0-0.102,0.021-0.127,0.064
				c-0.024,0.042-0.037,0.114-0.037,0.213C-7.426-24.829-7.424-24.79-7.42-24.754"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-6.683-25.066c0.042-0.067,0.104-0.108,0.187-0.125v-0.004
				c-0.04-0.011-0.074-0.025-0.101-0.047c-0.026-0.02-0.048-0.043-0.065-0.069c-0.016-0.026-0.028-0.055-0.035-0.083
				c-0.007-0.029-0.01-0.057-0.01-0.083c0-0.145,0.038-0.247,0.115-0.31c0.076-0.063,0.181-0.095,0.316-0.095
				c0.134,0,0.239,0.031,0.315,0.095c0.077,0.063,0.116,0.165,0.116,0.31c0,0.026-0.004,0.054-0.011,0.083
				c-0.008,0.028-0.02,0.057-0.037,0.083c-0.016,0.026-0.038,0.05-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.047v0.004
				c0.083,0.017,0.145,0.058,0.187,0.125c0.041,0.065,0.062,0.145,0.062,0.237c0,0.043-0.005,0.09-0.015,0.141
				c-0.011,0.052-0.032,0.1-0.066,0.144c-0.033,0.044-0.08,0.08-0.141,0.11c-0.062,0.028-0.144,0.043-0.246,0.043
				s-0.184-0.015-0.246-0.043c-0.062-0.03-0.108-0.066-0.142-0.11c-0.033-0.044-0.054-0.092-0.065-0.144
				c-0.011-0.051-0.016-0.098-0.016-0.141C-6.745-24.922-6.723-25.001-6.683-25.066 M-6.438-24.734
				c0.005,0.03,0.014,0.058,0.027,0.08c0.013,0.023,0.031,0.041,0.053,0.055c0.022,0.013,0.05,0.02,0.083,0.02
				c0.033,0,0.06-0.007,0.083-0.02c0.022-0.014,0.039-0.031,0.052-0.055c0.013-0.022,0.023-0.05,0.028-0.08
				c0.004-0.031,0.007-0.064,0.007-0.099c0-0.036-0.003-0.069-0.007-0.1c-0.005-0.03-0.015-0.058-0.028-0.079
				c-0.013-0.024-0.03-0.041-0.052-0.054c-0.023-0.015-0.05-0.021-0.083-0.021c-0.033,0-0.061,0.006-0.083,0.021
				c-0.022,0.013-0.04,0.029-0.053,0.054c-0.013,0.021-0.022,0.049-0.027,0.079s-0.007,0.063-0.007,0.1
				C-6.445-24.799-6.443-24.766-6.438-24.734 M-6.381-25.331c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05
				c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.2-0.145-0.2c-0.097,0-0.145,0.065-0.145,0.2
				C-6.42-25.416-6.408-25.364-6.381-25.331"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-11.913-23.648c-0.064,0.092-0.122,0.189-0.173,0.294
				c-0.05,0.104-0.095,0.212-0.133,0.321s-0.07,0.222-0.095,0.335c-0.024,0.114-0.044,0.227-0.057,0.335h-0.332
				c0.011-0.093,0.029-0.191,0.053-0.295c0.023-0.104,0.055-0.209,0.093-0.315c0.039-0.107,0.086-0.215,0.142-0.324
				c0.056-0.109,0.122-0.215,0.198-0.319h-0.656v-0.272h0.96V-23.648z"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-11.813-23.401c0.007-0.1,0.026-0.188,0.059-0.266
				c0.032-0.076,0.081-0.138,0.146-0.186c0.066-0.046,0.156-0.068,0.272-0.068c0.096,0,0.174,0.014,0.237,0.041
				c0.063,0.026,0.112,0.07,0.149,0.131c0.038,0.06,0.065,0.138,0.08,0.233c0.015,0.098,0.023,0.215,0.023,0.353
				c0,0.108-0.004,0.212-0.011,0.312c-0.007,0.101-0.027,0.189-0.06,0.267c-0.032,0.076-0.08,0.138-0.146,0.182
				c-0.065,0.044-0.156,0.065-0.272,0.065c-0.094,0-0.172-0.012-0.236-0.037c-0.063-0.025-0.112-0.068-0.15-0.128
				c-0.038-0.061-0.065-0.138-0.08-0.235c-0.015-0.095-0.023-0.213-0.023-0.351C-11.825-23.197-11.821-23.301-11.813-23.401
				 M-11.5-22.779c0.006,0.063,0.014,0.111,0.028,0.147c0.013,0.036,0.031,0.061,0.052,0.073c0.023,0.014,0.051,0.021,0.084,0.021
				c0.035,0,0.064-0.008,0.086-0.025c0.022-0.017,0.04-0.046,0.052-0.086c0.012-0.04,0.021-0.095,0.026-0.162
				c0.006-0.066,0.008-0.15,0.008-0.251v-0.17c0-0.098-0.002-0.179-0.008-0.24c-0.005-0.063-0.015-0.112-0.027-0.147
				c-0.013-0.036-0.031-0.061-0.053-0.074c-0.022-0.013-0.05-0.02-0.084-0.02c-0.035,0-0.064,0.009-0.086,0.024
				c-0.022,0.018-0.039,0.046-0.052,0.086c-0.012,0.041-0.02,0.095-0.026,0.162c-0.005,0.068-0.008,0.152-0.008,0.251v0.17
				C-11.508-22.921-11.505-22.842-11.5-22.779"
        />
        <path
          clipPath="url(#WHITTLING_SVGID_58_)"
          fill="#FFFFFF"
          d="M-10.735-23.686c0.009-0.053,0.025-0.097,0.049-0.132
				c0.025-0.035,0.058-0.062,0.101-0.078c0.044-0.017,0.1-0.026,0.167-0.026c0.069,0,0.125,0.01,0.168,0.026
				c0.042,0.017,0.075,0.043,0.1,0.078c0.024,0.035,0.04,0.079,0.049,0.132c0.009,0.053,0.013,0.115,0.013,0.185
				c0,0.071-0.004,0.133-0.013,0.186c-0.009,0.053-0.025,0.097-0.049,0.132c-0.025,0.036-0.058,0.061-0.1,0.079
				c-0.043,0.016-0.099,0.024-0.168,0.024c-0.067,0-0.123-0.009-0.167-0.024c-0.043-0.019-0.076-0.043-0.101-0.079
				c-0.024-0.035-0.04-0.079-0.049-0.132c-0.009-0.053-0.013-0.114-0.013-0.186C-10.748-23.57-10.744-23.633-10.735-23.686
				 M-10.5-23.313c0.011,0.044,0.038,0.066,0.082,0.066c0.044,0,0.071-0.022,0.082-0.066c0.009-0.043,0.015-0.109,0.015-0.197
				c0-0.079-0.006-0.139-0.015-0.181c-0.011-0.042-0.038-0.063-0.082-0.063c-0.044,0-0.071,0.021-0.082,0.063
				c-0.01,0.042-0.015,0.102-0.015,0.181C-10.515-23.422-10.51-23.355-10.5-23.313 M-9.41-23.959l-0.894,1.658H-10.5l0.895-1.658
				H-9.41z M-9.801-22.937c0.009-0.053,0.025-0.097,0.049-0.133c0.024-0.034,0.058-0.062,0.102-0.077
				c0.043-0.018,0.098-0.026,0.165-0.026c0.07,0,0.125,0.009,0.168,0.026c0.042,0.016,0.076,0.043,0.1,0.077
				c0.025,0.036,0.041,0.08,0.05,0.133c0.008,0.053,0.013,0.114,0.013,0.185c0,0.071-0.005,0.135-0.013,0.187
				c-0.009,0.052-0.025,0.095-0.05,0.13c-0.024,0.034-0.058,0.059-0.1,0.075c-0.043,0.015-0.098,0.022-0.168,0.022
				c-0.067,0-0.122-0.008-0.165-0.022c-0.044-0.017-0.078-0.041-0.102-0.075c-0.024-0.035-0.04-0.078-0.049-0.13
				c-0.01-0.052-0.013-0.115-0.013-0.187C-9.813-22.822-9.811-22.884-9.801-22.937 M-9.566-22.563
				c0.01,0.044,0.037,0.064,0.081,0.064s0.072-0.021,0.082-0.064c0.011-0.043,0.016-0.109,0.016-0.197
				c0-0.079-0.005-0.14-0.016-0.181c-0.01-0.043-0.038-0.063-0.082-0.063s-0.071,0.021-0.081,0.063
				c-0.01,0.041-0.015,0.102-0.015,0.181C-9.581-22.673-9.576-22.606-9.566-22.563"
        />
      </g>
    </g>
  );
};

const Navigator = () => {
  return (
    <g id="Navigator">
      <g>
        <defs>
          <rect
            id="NAVIGATOR_SVGID_59_"
            y="0.001"
            width="71.171"
            height="61.687"
          />
        </defs>
        <clipPath id="NAVIGATOR_SVGID_60_">
          <use xlinkHref="#NAVIGATOR_SVGID_59_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#NAVIGATOR_SVGID_60_)"
          fill="#72BF44"
          d="M55.507,61.071H6.18c-3.059,0-5.563-2.504-5.563-5.563V6.182
				c0-3.06,2.504-5.563,5.563-5.563h49.327c3.06,0,5.563,2.504,5.563,5.563v49.327C61.07,58.567,58.566,61.071,55.507,61.071"
        />
      </g>
      <g>
        <defs>
          <path
            id="NAVIGATOR_SVGID_61_"
            d="M5.677,0.002C2.588,0.002,0.054,2.501,0,5.578v50.435l5.677,5.675H56.01
					c3.122,0,5.678-2.554,5.678-5.676V5.678c0-3.123-2.556-5.676-5.678-5.676H5.677z"
          />
        </defs>
        <clipPath id="NAVIGATOR_SVGID_62_">
          <use xlinkHref="#NAVIGATOR_SVGID_61_" overflow="visible" />
        </clipPath>
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_62_)"
          fill="#008B4A"
          points="9.323,44.29 55.939,60.901 71.171,47.911 49.334,40.621
				27.63,34.362 			"
        />
      </g>
      <polygon
        fill="#005891"
        points="22.66,40.621 9.323,44.29 9.323,18.012 22.66,14.342 		"
      />
      <polygon
        fill="#5087C7"
        points="22.66,40.621 35.996,44.29 35.996,18.012 22.66,14.342 		"
      />
      <polygon
        fill="#005891"
        points="49.334,40.621 35.996,44.29 35.996,18.012 49.334,14.342 		"
      />
      <g>
        <defs>
          <rect
            id="NAVIGATOR_SVGID_63_"
            y="0.001"
            width="71.171"
            height="61.687"
          />
        </defs>
        <clipPath id="NAVIGATOR_SVGID_64_">
          <use xlinkHref="#NAVIGATOR_SVGID_63_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M36.296,32.07l-0.052-0.776c0.416-0.028,0.834-0.08,1.244-0.153l0.137,0.769
				C37.187,31.985,36.74,32.041,36.296,32.07 M12.839,31.835l-0.583-0.517c0.294-0.333,0.614-0.651,0.949-0.949l0.517,0.582
				C13.41,31.228,13.113,31.525,12.839,31.835 M32.755,31.688c-0.408-0.105-0.822-0.233-1.271-0.39l0.258-0.735
				c0.428,0.149,0.822,0.271,1.209,0.372L32.755,31.688z M38.929,31.605l-0.216-0.748c0.402-0.115,0.802-0.255,1.186-0.411
				l0.295,0.721C39.783,31.334,39.359,31.481,38.929,31.605 M30.254,30.83c-0.402-0.165-0.803-0.338-1.203-0.515l0.314-0.712
				c0.393,0.173,0.787,0.343,1.184,0.505L30.254,30.83z M41.404,30.596l-0.371-0.686c0.369-0.199,0.729-0.42,1.067-0.656
				l0.445,0.638C42.183,30.145,41.799,30.382,41.404,30.596 M14.705,30.174l-0.447-0.637c0.362-0.256,0.746-0.493,1.138-0.708
				l0.374,0.683C15.403,29.713,15.045,29.936,14.705,30.174 M27.865,29.79c-0.394-0.173-0.788-0.345-1.184-0.506l0.295-0.722
				c0.402,0.166,0.803,0.339,1.202,0.516L27.865,29.79z M16.901,28.968l-0.299-0.719c0.409-0.169,0.833-0.32,1.26-0.447l0.222,0.746
				C17.683,28.668,17.285,28.809,16.901,28.968 M25.488,28.827c-0.43-0.15-0.826-0.273-1.21-0.374l0.2-0.753
				c0.403,0.106,0.818,0.234,1.267,0.393L25.488,28.827z M19.305,28.257l-0.14-0.767c0.438-0.079,0.885-0.138,1.327-0.171
				l0.058,0.777C20.135,28.128,19.716,28.181,19.305,28.257 M23.05,28.191c-0.408-0.063-0.827-0.102-1.246-0.117l0.029-0.778
				c0.449,0.017,0.898,0.06,1.336,0.127L23.05,28.191z"
        />

        <line
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.779"
          strokeMiterlimit="10"
          x1="43.029"
          y1="26.317"
          x2="45.928"
          y2="29.216"
        />

        <line
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.779"
          strokeMiterlimit="10"
          x1="45.928"
          y1="26.317"
          x2="43.029"
          y2="29.216"
        />

        <line
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.779"
          strokeMiterlimit="10"
          x1="25.296"
          y1="18.272"
          x2="28.194"
          y2="21.17"
        />

        <line
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.779"
          strokeMiterlimit="10"
          x1="28.194"
          y1="18.272"
          x2="25.296"
          y2="21.17"
        />

        <line
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.779"
          strokeMiterlimit="10"
          x1="12.753"
          y1="37.217"
          x2="15.651"
          y2="40.115"
        />

        <line
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.779"
          strokeMiterlimit="10"
          x1="15.651"
          y1="37.217"
          x2="12.753"
          y2="40.115"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFDD00"
          d="M43.975,19.285c-5.684,0-10.292,4.607-10.292,10.292
				c0,7.597,10.292,17.768,10.292,17.768s10.291-10.171,10.291-17.768C54.266,23.893,49.658,19.285,43.975,19.285 M43.971,34.889
				c-2.924,0-5.293-2.368-5.293-5.293c0-2.923,2.369-5.293,5.293-5.293c2.923,0,5.293,2.37,5.293,5.293
				C49.264,32.521,46.894,34.889,43.971,34.889"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#F5DB8B"
          d="M43.975,19.285h-0.012v5.02c0.002,0,0.006-0.001,0.008-0.001
				c2.924,0,5.293,2.369,5.293,5.293c0,2.923-2.369,5.293-5.293,5.293h-0.008v12.444c0.002,0.003,0.012,0.012,0.012,0.012
				s10.291-10.172,10.291-17.77C54.266,23.894,49.658,19.285,43.975,19.285"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          points="20.228,11.139 19.418,11.139 17.402,8.831 17.402,11.139
				16.421,11.139 16.421,7.155 17.236,7.155 19.251,9.533 19.251,7.155 20.228,7.155 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M24.094,11.138h-0.951V10.9c-0.232,0.209-0.541,0.328-0.927,0.328
				c-0.791,0-1.457-0.638-1.457-1.582c0-0.939,0.666-1.581,1.457-1.581c0.386,0,0.695,0.118,0.927,0.327V8.154h0.951V11.138z
				 M23.143,9.646c0-0.458-0.345-0.773-0.731-0.773c-0.416,0-0.719,0.315-0.719,0.773c0,0.464,0.303,0.772,0.719,0.772
				C22.798,10.419,23.143,10.11,23.143,9.646"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          points="26.616,11.139 25.647,11.139 24.41,8.148 25.415,8.148
				26.135,9.991 26.848,8.148 27.853,8.148 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M29.221,7.273c0,0.304-0.232,0.529-0.553,0.529
				c-0.321,0-0.547-0.226-0.547-0.529c0-0.292,0.226-0.541,0.547-0.541C28.989,6.732,29.221,6.981,29.221,7.273 M28.193,8.154h0.951
				v2.983h-0.951V8.154z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M32.863,10.842c0,0.922-0.7,1.427-1.67,1.427
				c-0.684,0-1.207-0.238-1.498-0.719l0.79-0.429c0.226,0.268,0.393,0.369,0.702,0.369c0.434,0,0.744-0.256,0.744-0.726v-0.084
				c-0.215,0.185-0.512,0.286-0.898,0.286c-0.779,0-1.439-0.618-1.439-1.464c0-0.825,0.66-1.438,1.439-1.438
				c0.386,0,0.683,0.1,0.898,0.285V8.154h0.933V10.842z M31.931,9.503c0-0.397-0.315-0.678-0.696-0.678
				c-0.416,0-0.714,0.28-0.714,0.678c0,0.398,0.298,0.684,0.714,0.684C31.615,10.187,31.931,9.901,31.931,9.503"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M36.665,11.138h-0.95V10.9c-0.232,0.209-0.541,0.328-0.929,0.328
				c-0.79,0-1.457-0.638-1.457-1.582c0-0.939,0.667-1.581,1.457-1.581c0.388,0,0.696,0.118,0.929,0.326V8.154h0.95V11.138z
				 M35.715,9.646c0-0.458-0.346-0.773-0.732-0.773c-0.416,0-0.719,0.315-0.719,0.773c0,0.464,0.303,0.772,0.719,0.772
				C35.369,10.419,35.715,10.11,35.715,9.646"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M39.537,11.008c-0.232,0.125-0.477,0.221-0.814,0.221
				c-0.744,0-1.189-0.404-1.189-1.189v-1.13h-0.542V8.154h0.542V7.273h0.951v0.881h0.88v0.755h-0.88v1.034
				c0,0.31,0.148,0.434,0.398,0.434c0.131,0,0.309-0.048,0.434-0.119L39.537,11.008z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M42.933,9.646c0,0.944-0.75,1.575-1.636,1.575
				c-0.904,0-1.652-0.631-1.652-1.575c0-0.939,0.748-1.569,1.652-1.569C42.183,8.077,42.933,8.707,42.933,9.646 M42.017,9.646
				c0-0.465-0.351-0.773-0.72-0.773c-0.387,0-0.736,0.309-0.736,0.773c0,0.469,0.35,0.772,0.736,0.772
				C41.666,10.419,42.017,10.115,42.017,9.646"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M45.58,8.136l-0.042,0.951h-0.172c-0.666,0-1.028,0.346-1.028,1.118v0.934
				h-0.951V8.154h0.951v0.571c0.213-0.357,0.54-0.613,1.028-0.613C45.449,8.112,45.508,8.118,45.58,8.136"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#9CCE78"
          d="M56.01,4.407c0.689,0,1.271,0.582,1.271,1.271v50.333
				c0,0.688-0.581,1.271-1.271,1.271H5.676c-0.689,0-1.27-0.582-1.27-1.271V5.679c0-0.689,0.581-1.271,1.27-1.271H56.01
				 M56.01,0.001H5.676c-3.122,0-5.677,2.555-5.677,5.678v50.333c0,3.122,2.555,5.677,5.677,5.677h50.333
				c3.123,0,5.679-2.555,5.679-5.677V5.679C61.688,2.556,59.132,0.001,56.01,0.001"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#5087C7"
          d="M30.854,55.361c-2.638,0-4.774-2.135-4.774-4.771
				c0-2.638,2.136-4.773,4.774-4.773s4.774,2.136,4.774,4.773C35.628,53.227,33.492,55.361,30.854,55.361"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M30.854,45.817c2.638,0,4.774,2.136,4.774,4.771
				c0,2.638-2.136,4.773-4.774,4.773s-4.774-2.136-4.774-4.773C26.08,47.953,28.216,45.817,30.854,45.817 M30.854,45.405
				c-2.86,0-5.185,2.325-5.185,5.184c0,2.859,2.325,5.185,5.185,5.185c2.859,0,5.185-2.325,5.185-5.185
				C36.039,47.73,33.713,45.405,30.854,45.405"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M33.348,48.645c-0.239,0-0.464,0.107-0.62,0.251l-0.013-0.012
				c0.072-0.12,0.169-0.347,0.169-0.716c0-0.884-0.886-1.553-2.029-1.553c-1.145,0-2.042,0.669-2.042,1.553
				c0,0.369,0.12,0.596,0.18,0.716l-0.013,0.012c-0.155-0.144-0.381-0.251-0.619-0.251c-0.729,0-1.457,0.703-1.457,2.018
				c0,1.287,0.716,2.016,1.492,2.016c0.716,0,1.218-0.396,1.67-0.848c-0.071-0.107-0.179-0.216-0.298-0.216
				s-0.251,0.085-0.477,0.265c-0.298,0.227-0.561,0.357-0.908,0.357c-0.608,0-0.869-0.682-0.869-1.574
				c0-0.955,0.25-1.588,0.895-1.588c0.286,0,0.476,0.083,0.668,0.263c0.286,0.263,0.381,0.334,0.512,0.334
				c0.096,0,0.156-0.061,0.179-0.096c-0.214-0.215-0.525-0.668-0.525-1.253c0-0.668,0.561-1.134,1.612-1.134
				c1.05,0,1.611,0.466,1.611,1.134c0,0.585-0.312,1.038-0.526,1.253c0.024,0.035,0.084,0.096,0.18,0.096
				c0.131,0,0.227-0.071,0.513-0.334c0.179-0.18,0.382-0.263,0.668-0.263c0.646,0,0.896,0.633,0.896,1.588
				c0,0.893-0.263,1.574-0.871,1.574c-0.346,0-0.621-0.131-0.908-0.357c-0.226-0.18-0.356-0.265-0.477-0.265
				c-0.119,0-0.226,0.108-0.298,0.216c0.44,0.452,0.955,0.848,1.671,0.848c0.775,0,1.491-0.729,1.491-2.016
				C34.804,49.348,34.076,48.645,33.348,48.645"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M31.653,48.621h-0.537c-0.036,0-0.059-0.024-0.071-0.048l-0.168-0.514
				c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.048-0.06,0.048h-0.537c-0.024,0-0.035,0.011-0.012,0.023
				l0.442,0.322c0.024,0.023,0.024,0.06,0.012,0.083l-0.167,0.501c-0.011,0.024-0.011,0.049,0.024,0.024l0.442-0.31
				c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.023,0.024,0.035,0.012,0.023-0.012l-0.166-0.514c-0.013-0.035,0-0.071,0.024-0.083
				l0.439-0.322C31.688,48.632,31.677,48.621,31.653,48.621"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M31.248,52.152c-0.226-0.573-0.406-0.966-0.406-1.443
				c0-0.204,0.06-0.382,0.096-0.477c0.047-0.097,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.167-0.321,0.311
				c-0.073,0.118-0.156,0.347-0.156,0.524c0,0.335,0.108,0.692,0.179,0.907c0.132,0.37,0.251,0.799,0.251,1.206
				c0,0.645-0.287,1.085-0.799,1.478c0.047,0.037,0.166,0.096,0.31,0.096c0.5,0,1.098-0.56,1.098-1.323
				C31.511,52.82,31.379,52.51,31.248,52.152"
        />
        <rect
          x="-3.59"
          y="76.707"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          width="5.986"
          height="5.986"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-1.718,78.744v1.273c0,0.166-0.011,0.43-0.221,0.571
				c-0.082,0.054-0.195,0.085-0.308,0.085c-0.135,0-0.297-0.046-0.432-0.176l0.223-0.269c0.037,0.054,0.097,0.102,0.184,0.102
				c0.079,0,0.124-0.034,0.144-0.065c0.046-0.064,0.043-0.189,0.043-0.305v-1.217H-1.718z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          points="-0.337,80.631 -0.614,80.631 -1.271,78.744 -0.881,78.744
				-0.459,80.028 0.002,78.727 0.206,78.727 0.642,80.028 1.094,78.744 1.485,78.744 0.786,80.631 0.509,80.631 0.096,79.435 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-2.471-27.406c0.076,0,0.14,0.013,0.192,0.037
				c0.052,0.026,0.094,0.061,0.125,0.102c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.471z M-2.562-26.736c0.059,0,0.107-0.019,0.144-0.055
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H-2.562z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          points="-1.468,-27.406 -1.257,-26.361 -1.252,-26.361 -1.039,-27.406
				-0.597,-27.406 -0.597,-25.928 -0.871,-25.928 -0.871,-27.108 -0.875,-27.108 -1.138,-25.928 -1.371,-25.928 -1.633,-27.108
				-1.637,-27.108 -1.637,-25.928 -1.911,-25.928 -1.911,-27.406 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-0.144-26.346c0,0.034,0.003,0.066,0.008,0.095
				c0.005,0.027,0.016,0.052,0.031,0.071s0.036,0.035,0.061,0.046c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.008-0.025-0.024-0.048-0.044-0.066c-0.021-0.02-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.047-0.172-0.073c-0.049-0.026-0.089-0.058-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				C-0.414-26.885-0.421-26.939-0.421-27c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.008,0.184,0.021c0.056,0.015,0.104,0.039,0.144,0.071c0.042,0.032,0.073,0.073,0.096,0.124
				s0.034,0.11,0.034,0.182v0.041H0.208c0-0.071-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.014c-0.022,0.009-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.026,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.064,0.045,0.102
				c0.007,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.105-0.378,0.105
				c-0.169,0-0.29-0.036-0.362-0.109c-0.072-0.072-0.108-0.179-0.108-0.314v-0.061h0.298V-26.346z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M1.447-26.16c0.021,0.045,0.064,0.068,0.13,0.068
				c0.035,0,0.064-0.007,0.085-0.02c0.022-0.013,0.038-0.028,0.05-0.051c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.028,0.005-0.06,0.005-0.091c0-0.034-0.002-0.064-0.007-0.095c-0.005-0.029-0.015-0.056-0.028-0.079
				c-0.014-0.024-0.033-0.043-0.058-0.057c-0.025-0.014-0.059-0.021-0.1-0.021H1.448v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.019
				c0.021-0.013,0.039-0.03,0.052-0.051c0.013-0.022,0.022-0.047,0.029-0.075c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.07-0.012-0.119-0.035-0.149c-0.024-0.028-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.006-0.077,0.018
				c-0.021,0.012-0.037,0.028-0.047,0.05c-0.011,0.021-0.019,0.047-0.022,0.076c-0.003,0.028-0.005,0.061-0.005,0.095H1.148
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.071,0.18-0.106,0.322-0.106c0.133,0,0.237,0.029,0.311,0.092
				c0.074,0.06,0.111,0.155,0.111,0.286c0,0.088-0.02,0.16-0.059,0.216s-0.094,0.091-0.164,0.106v0.004
				c0.094,0.016,0.162,0.054,0.201,0.114c0.04,0.063,0.06,0.14,0.06,0.232c0,0.049-0.007,0.101-0.019,0.152
				c-0.013,0.054-0.037,0.101-0.072,0.144c-0.037,0.043-0.086,0.078-0.149,0.104c-0.062,0.026-0.146,0.039-0.248,0.039
				c-0.142,0-0.248-0.038-0.319-0.115c-0.07-0.077-0.105-0.188-0.105-0.329v-0.007h0.286C1.414-26.27,1.425-26.205,1.447-26.16"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M2.694-27.154c-0.019-0.03-0.055-0.045-0.104-0.045
				c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.018-0.043,0.045-0.056,0.077c-0.013,0.033-0.022,0.073-0.025,0.118
				c-0.005,0.046-0.007,0.096-0.007,0.149v0.073h0.004c0.025-0.039,0.061-0.069,0.107-0.093c0.047-0.022,0.103-0.034,0.169-0.034
				c0.065,0,0.12,0.012,0.166,0.034c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.089,0.062,0.145
				c0.013,0.057,0.019,0.117,0.019,0.184c0,0.17-0.037,0.299-0.112,0.387c-0.076,0.088-0.196,0.131-0.362,0.131
				c-0.103,0-0.184-0.017-0.245-0.053c-0.06-0.036-0.107-0.086-0.139-0.148c-0.033-0.063-0.054-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.205,0.009-0.302c0.006-0.099,0.025-0.185,0.056-0.26
				c0.031-0.074,0.08-0.135,0.146-0.182c0.067-0.045,0.161-0.068,0.282-0.068c0.052,0,0.103,0.007,0.152,0.02
				c0.05,0.013,0.093,0.033,0.13,0.062c0.037,0.029,0.067,0.067,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.178H2.725
				C2.725-27.078,2.715-27.122,2.694-27.154 M2.428-26.267C2.433-26.23,2.44-26.2,2.452-26.174c0.011,0.025,0.028,0.046,0.05,0.06
				c0.021,0.014,0.049,0.022,0.084,0.022s0.064-0.009,0.086-0.022c0.021-0.014,0.037-0.034,0.048-0.062
				c0.011-0.025,0.019-0.057,0.021-0.093c0.004-0.034,0.006-0.072,0.006-0.114c0-0.1-0.012-0.171-0.037-0.214
				S2.645-26.66,2.586-26.66c-0.06,0-0.103,0.021-0.127,0.063c-0.025,0.043-0.037,0.114-0.037,0.214
				C2.422-26.341,2.424-26.303,2.428-26.267"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M3.166-26.58c0.042-0.065,0.104-0.107,0.187-0.123v-0.005
				c-0.04-0.01-0.074-0.024-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.069c-0.016-0.027-0.028-0.055-0.035-0.083
				c-0.007-0.03-0.01-0.058-0.01-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.315,0.094c0.077,0.063,0.116,0.166,0.116,0.311c0,0.025-0.004,0.053-0.012,0.083
				c-0.007,0.028-0.019,0.056-0.036,0.083c-0.016,0.025-0.038,0.049-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.046v0.005
				c0.083,0.016,0.145,0.058,0.187,0.123c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.005,0.09-0.015,0.141
				c-0.011,0.052-0.033,0.099-0.066,0.143c-0.033,0.045-0.08,0.081-0.142,0.11c-0.061,0.028-0.143,0.044-0.245,0.044
				s-0.184-0.016-0.246-0.044c-0.062-0.029-0.109-0.065-0.142-0.11C3.152-26.102,3.13-26.148,3.12-26.2
				c-0.011-0.051-0.016-0.098-0.016-0.141C3.104-26.434,3.125-26.513,3.166-26.58 M3.41-26.248c0.005,0.032,0.014,0.06,0.027,0.082
				c0.013,0.022,0.031,0.041,0.053,0.055c0.022,0.013,0.05,0.019,0.083,0.019c0.033,0,0.06-0.006,0.083-0.019
				c0.021-0.014,0.039-0.032,0.052-0.055c0.013-0.022,0.023-0.05,0.027-0.082c0.005-0.029,0.008-0.063,0.008-0.098
				s-0.003-0.068-0.008-0.099c-0.004-0.031-0.014-0.058-0.027-0.08c-0.013-0.023-0.031-0.041-0.052-0.054
				c-0.023-0.014-0.05-0.02-0.083-0.02c-0.033,0-0.061,0.006-0.083,0.02c-0.022,0.013-0.04,0.03-0.053,0.054
				c-0.013,0.022-0.022,0.049-0.027,0.08c-0.005,0.03-0.007,0.063-0.007,0.099S3.405-26.277,3.41-26.248 M3.467-26.843
				c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155
				c0-0.135-0.048-0.201-0.145-0.201c-0.097,0-0.145,0.066-0.145,0.201C3.428-26.928,3.44-26.876,3.467-26.843"
        />
        <rect
          x="6.574"
          y="-27.904"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#008B4A"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M7.487-27.406c0.076,0,0.14,0.013,0.192,0.037
				c0.052,0.026,0.094,0.061,0.125,0.102c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				s-0.123,0.027-0.193,0.027h-0.16v0.59H6.965v-1.479H7.487z M7.396-26.736c0.059,0,0.107-0.019,0.144-0.055
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058H7.263v0.45H7.396z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          points="8.491,-27.406 8.702,-26.361 8.707,-26.361 8.919,-27.406
				9.361,-27.406 9.361,-25.928 9.088,-25.928 9.088,-27.108 9.083,-27.108 8.821,-25.928 8.588,-25.928 8.325,-27.108
				8.321,-27.108 8.321,-25.928 8.047,-25.928 8.047,-27.406 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M9.815-26.345c0,0.033,0.003,0.065,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.034,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.063-0.013-0.086
				c-0.009-0.025-0.025-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				C9.545-26.885,9.537-26.939,9.537-27c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.008,0.183,0.021c0.056,0.015,0.104,0.039,0.144,0.071c0.042,0.032,0.073,0.073,0.096,0.124
				s0.034,0.11,0.034,0.182v0.041h-0.285c0-0.071-0.014-0.125-0.038-0.162c-0.025-0.039-0.067-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.014c-0.022,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.061,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.026,0.065,0.056,0.086,0.087c0.021,0.03,0.036,0.064,0.045,0.102
				c0.007,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.105-0.378,0.105
				c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.072-0.108-0.179-0.108-0.314v-0.061h0.298V-26.345z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M11.406-26.16c0.021,0.045,0.064,0.068,0.13,0.068
				c0.035,0,0.064-0.007,0.085-0.02c0.022-0.013,0.038-0.028,0.05-0.051c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.028,0.005-0.06,0.005-0.091c0-0.034-0.002-0.064-0.007-0.095c-0.005-0.029-0.014-0.056-0.028-0.079
				c-0.013-0.024-0.033-0.043-0.058-0.057c-0.025-0.014-0.058-0.021-0.099-0.021h-0.1v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.019
				c0.022-0.013,0.039-0.03,0.052-0.051c0.014-0.022,0.022-0.047,0.029-0.075c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.07-0.012-0.119-0.035-0.149c-0.024-0.028-0.059-0.043-0.108-0.043c-0.031,0-0.058,0.006-0.077,0.018
				c-0.02,0.012-0.037,0.028-0.047,0.05c-0.011,0.021-0.019,0.047-0.022,0.076c-0.003,0.028-0.005,0.061-0.005,0.095h-0.273
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.071,0.18-0.106,0.322-0.106c0.134,0,0.237,0.029,0.312,0.092
				c0.074,0.06,0.11,0.155,0.11,0.286c0,0.088-0.019,0.16-0.059,0.216c-0.039,0.056-0.094,0.091-0.164,0.106v0.004
				c0.094,0.016,0.162,0.054,0.202,0.114c0.039,0.063,0.059,0.14,0.059,0.232c0,0.049-0.007,0.101-0.019,0.153
				c-0.013,0.053-0.037,0.1-0.072,0.143c-0.037,0.043-0.085,0.078-0.149,0.104c-0.062,0.026-0.145,0.039-0.247,0.039
				c-0.143,0-0.249-0.038-0.32-0.115c-0.07-0.077-0.105-0.188-0.105-0.329v-0.007h0.286C11.373-26.27,11.384-26.205,11.406-26.16"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M12.554-27.395h0.309v0.931h0.141v0.232h-0.141v0.305H12.59v-0.305h-0.522
				v-0.244L12.554-27.395z M12.586-27.021l-0.287,0.557h0.291v-0.557H12.586z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M13.124-26.579c0.042-0.066,0.104-0.108,0.187-0.124v-0.004
				c-0.04-0.011-0.073-0.025-0.101-0.047c-0.026-0.021-0.048-0.044-0.065-0.069c-0.016-0.027-0.028-0.055-0.035-0.083
				c-0.007-0.03-0.01-0.058-0.01-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.311c0,0.025-0.004,0.053-0.012,0.083
				c-0.007,0.028-0.019,0.056-0.036,0.083c-0.016,0.025-0.038,0.049-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.047v0.004
				c0.083,0.016,0.145,0.058,0.187,0.124c0.041,0.066,0.062,0.146,0.062,0.238c0,0.043-0.005,0.09-0.015,0.141
				c-0.01,0.052-0.033,0.099-0.066,0.143c-0.032,0.045-0.08,0.081-0.142,0.11c-0.061,0.028-0.143,0.044-0.245,0.044
				s-0.184-0.016-0.246-0.044c-0.062-0.029-0.109-0.065-0.142-0.11c-0.033-0.044-0.055-0.091-0.065-0.143
				c-0.01-0.051-0.015-0.098-0.015-0.141C13.063-26.434,13.083-26.513,13.124-26.579 M13.369-26.248
				c0.004,0.032,0.013,0.06,0.026,0.082c0.013,0.022,0.031,0.041,0.053,0.055c0.022,0.013,0.05,0.019,0.083,0.019
				c0.033,0,0.06-0.006,0.083-0.019c0.021-0.014,0.039-0.032,0.053-0.055c0.012-0.022,0.022-0.05,0.026-0.082
				c0.005-0.029,0.008-0.063,0.008-0.098s-0.003-0.068-0.008-0.099c-0.004-0.031-0.014-0.058-0.026-0.08
				c-0.014-0.023-0.032-0.041-0.053-0.054c-0.023-0.014-0.05-0.02-0.083-0.02c-0.033,0-0.061,0.006-0.083,0.02
				c-0.022,0.013-0.04,0.03-0.053,0.054c-0.013,0.022-0.022,0.049-0.026,0.08c-0.006,0.03-0.008,0.063-0.008,0.099
				S13.363-26.277,13.369-26.248 M13.425-26.843c0.026,0.033,0.062,0.05,0.106,0.05s0.079-0.017,0.105-0.05
				c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.201-0.145-0.201c-0.097,0-0.145,0.066-0.145,0.201
				C13.386-26.928,13.399-26.876,13.425-26.843"
        />
        <rect
          x="16.468"
          y="-27.904"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFDD00"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M17.382-27.405c0.076,0,0.14,0.012,0.192,0.036
				c0.052,0.026,0.094,0.061,0.125,0.102c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.055,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59H16.86v-1.479H17.382z M17.291-26.736c0.059,0,0.107-0.019,0.144-0.055
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H17.291z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          points="18.385,-27.406 18.596,-26.361 18.601,-26.361 18.814,-27.406
				19.256,-27.406 19.256,-25.928 18.982,-25.928 18.982,-27.108 18.978,-27.108 18.715,-25.928 18.482,-25.928 18.22,-27.108
				18.216,-27.108 18.216,-25.928 17.942,-25.928 17.942,-27.406 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M19.709-26.345c0,0.033,0.003,0.065,0.008,0.094
				c0.005,0.028,0.016,0.052,0.031,0.072c0.015,0.019,0.036,0.034,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.008-0.025-0.024-0.047-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				S19.432-26.939,19.432-27c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.008,0.184,0.021
				c0.056,0.015,0.104,0.039,0.144,0.071c0.042,0.032,0.073,0.073,0.096,0.124s0.034,0.11,0.034,0.182v0.041h-0.286
				c0-0.071-0.013-0.125-0.037-0.162C20-27.197,19.958-27.215,19.9-27.215c-0.033,0-0.061,0.004-0.083,0.014
				c-0.022,0.009-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.105-0.378,0.105c-0.169,0-0.29-0.036-0.362-0.108
				c-0.072-0.073-0.108-0.18-0.108-0.315v-0.061h0.298V-26.345z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M21.316-26.963h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.235-0.055
				c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.298V-26.963z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M21.981-26.904c0.006-0.095,0.025-0.178,0.056-0.25
				c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.147-0.066,0.256-0.066c0.09,0,0.164,0.014,0.223,0.039
				c0.059,0.026,0.106,0.067,0.141,0.123c0.035,0.057,0.06,0.13,0.075,0.221c0.014,0.091,0.022,0.201,0.022,0.33
				c0,0.103-0.004,0.2-0.011,0.295c-0.007,0.095-0.026,0.178-0.056,0.25c-0.03,0.072-0.077,0.129-0.138,0.17
				c-0.061,0.043-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.022-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.061-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C21.971-26.712,21.974-26.811,21.981-26.904
				 M22.276-26.319c0.005,0.059,0.013,0.104,0.026,0.139c0.012,0.034,0.028,0.058,0.05,0.069c0.02,0.013,0.046,0.019,0.078,0.019
				c0.033,0,0.06-0.008,0.081-0.023c0.02-0.017,0.037-0.042,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.152
				c0.004-0.063,0.007-0.143,0.007-0.235v-0.16c0-0.093-0.003-0.168-0.007-0.228c-0.005-0.058-0.014-0.104-0.026-0.139
				c-0.013-0.033-0.03-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.008-0.08,0.023
				c-0.021,0.017-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.09-0.025,0.152c-0.005,0.064-0.008,0.142-0.008,0.236v0.161
				C22.269-26.453,22.271-26.378,22.276-26.319"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M23.308-26.14c0.02,0.032,0.055,0.048,0.105,0.048
				c0.04,0,0.072-0.01,0.096-0.028c0.023-0.02,0.041-0.045,0.054-0.078c0.013-0.033,0.022-0.073,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.148v-0.07h-0.005c-0.025,0.037-0.06,0.066-0.106,0.089c-0.047,0.023-0.103,0.035-0.169,0.035
				c-0.065,0-0.12-0.012-0.166-0.035c-0.046-0.022-0.083-0.054-0.111-0.095s-0.049-0.089-0.062-0.146
				c-0.014-0.056-0.02-0.116-0.02-0.183c0-0.17,0.038-0.3,0.113-0.39c0.075-0.091,0.196-0.136,0.362-0.136
				c0.102,0,0.183,0.02,0.244,0.059c0.061,0.037,0.108,0.088,0.14,0.15c0.032,0.063,0.054,0.136,0.064,0.219
				c0.009,0.083,0.014,0.17,0.014,0.26c0,0.104-0.004,0.206-0.009,0.303c-0.006,0.098-0.025,0.184-0.057,0.258
				c-0.03,0.075-0.08,0.133-0.146,0.178c-0.068,0.043-0.162,0.064-0.281,0.064c-0.053,0-0.104-0.004-0.153-0.015
				s-0.092-0.03-0.129-0.059c-0.037-0.027-0.068-0.065-0.091-0.113c-0.023-0.049-0.034-0.107-0.036-0.179h0.287
				C23.279-26.213,23.288-26.17,23.308-26.14 M23.293-26.695c0.025,0.042,0.066,0.064,0.124,0.064c0.059,0,0.102-0.022,0.126-0.064
				c0.025-0.043,0.038-0.115,0.038-0.213c0-0.042-0.002-0.081-0.006-0.117s-0.012-0.065-0.024-0.092
				c-0.012-0.025-0.028-0.045-0.05-0.061c-0.021-0.014-0.049-0.021-0.084-0.021c-0.036,0-0.064,0.008-0.086,0.021
				c-0.021,0.016-0.037,0.035-0.049,0.062c-0.011,0.026-0.018,0.057-0.021,0.092c-0.004,0.036-0.005,0.074-0.005,0.116
				C23.256-26.811,23.268-26.738,23.293-26.695"
        />
        <rect
          x="26.337"
          y="-27.904"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#F5DB8B"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M27.25-27.405c0.076,0,0.14,0.012,0.192,0.036
				c0.052,0.026,0.094,0.061,0.125,0.102c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.055,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.144-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H27.25z M27.159-26.736c0.059,0,0.107-0.019,0.144-0.055
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H27.159z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          points="28.253,-27.406 28.464,-26.361 28.469,-26.361 28.682,-27.406
				29.125,-27.406 29.125,-25.927 28.85,-25.927 28.85,-27.108 28.846,-27.108 28.583,-25.927 28.35,-25.927 28.088,-27.108
				28.084,-27.108 28.084,-25.927 27.811,-25.927 27.811,-27.406 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M29.578-26.345c0,0.033,0.003,0.065,0.008,0.094s0.016,0.052,0.031,0.072
				c0.015,0.019,0.036,0.034,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.014,0.123-0.044
				c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086c-0.008-0.025-0.024-0.047-0.044-0.066
				c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048c-0.066-0.022-0.124-0.046-0.172-0.073
				c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122C29.308-26.885,29.3-26.939,29.3-27
				c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.008,0.184,0.021
				c0.056,0.015,0.104,0.039,0.144,0.071c0.042,0.032,0.073,0.073,0.096,0.124s0.034,0.11,0.034,0.182v0.041H29.93
				c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.123-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.023,0.009-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.011,0.079,0.011,0.125
				c0,0.156-0.045,0.271-0.135,0.342c-0.09,0.07-0.216,0.105-0.378,0.105c-0.169,0-0.29-0.036-0.362-0.108
				c-0.072-0.073-0.108-0.18-0.108-0.315v-0.061h0.298V-26.345z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M31.184-26.963h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.235-0.055
				c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.298V-26.963z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M31.839-25.927c0-0.065,0.007-0.125,0.021-0.182
				c0.013-0.058,0.032-0.111,0.06-0.162c0.026-0.051,0.06-0.102,0.103-0.15c0.039-0.05,0.088-0.099,0.141-0.147l0.121-0.112
				c0.033-0.029,0.06-0.056,0.08-0.081c0.02-0.025,0.035-0.05,0.045-0.075c0.012-0.024,0.02-0.051,0.022-0.081
				c0.003-0.028,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.022
				c-0.022,0.015-0.038,0.036-0.051,0.063c-0.011,0.026-0.019,0.057-0.022,0.092c-0.003,0.034-0.004,0.07-0.004,0.109h-0.287v-0.056
				c0-0.137,0.037-0.242,0.11-0.314c0.072-0.074,0.188-0.11,0.346-0.11c0.15,0,0.26,0.034,0.332,0.103
				c0.072,0.07,0.107,0.168,0.107,0.297c0,0.048-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.081-0.039,0.116
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.027,0.035-0.063,0.072-0.105,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.023,0.039-0.041,0.071-0.047,0.1h0.553v0.231H31.839z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M32.845-26.904c0.006-0.095,0.024-0.178,0.056-0.25
				c0.03-0.073,0.076-0.131,0.137-0.174c0.063-0.044,0.147-0.066,0.257-0.066c0.09,0,0.163,0.014,0.223,0.039
				c0.06,0.026,0.106,0.067,0.142,0.123c0.035,0.057,0.06,0.13,0.074,0.221c0.014,0.091,0.022,0.201,0.022,0.33
				c0,0.103-0.004,0.2-0.011,0.295c-0.008,0.095-0.026,0.178-0.057,0.25c-0.029,0.072-0.076,0.129-0.138,0.17
				c-0.062,0.043-0.147,0.063-0.256,0.063c-0.089,0-0.162-0.012-0.222-0.036c-0.06-0.022-0.106-0.063-0.142-0.12
				c-0.036-0.056-0.062-0.13-0.075-0.221c-0.014-0.09-0.021-0.201-0.021-0.33C32.834-26.712,32.837-26.811,32.845-26.904
				 M33.14-26.319c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.027,0.058,0.05,0.069c0.021,0.013,0.046,0.02,0.078,0.02
				s0.061-0.009,0.081-0.024c0.02-0.017,0.037-0.042,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.152
				c0.004-0.063,0.006-0.143,0.006-0.235v-0.16c0-0.093-0.002-0.168-0.006-0.228c-0.006-0.058-0.015-0.104-0.026-0.139
				c-0.013-0.033-0.03-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.008-0.08,0.023
				c-0.021,0.017-0.038,0.043-0.05,0.081s-0.02,0.09-0.024,0.152c-0.005,0.064-0.008,0.142-0.008,0.236v0.161
				C33.132-26.453,33.135-26.378,33.14-26.319"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M34.131-27.133L34.1-26.799h0.004c0.028-0.039,0.064-0.067,0.108-0.084
				c0.044-0.018,0.099-0.026,0.162-0.026c0.069,0,0.128,0.014,0.175,0.041c0.047,0.026,0.083,0.063,0.11,0.107
				c0.026,0.044,0.046,0.093,0.057,0.149c0.011,0.056,0.017,0.113,0.017,0.17c0,0.082-0.008,0.156-0.021,0.224
				c-0.013,0.065-0.038,0.122-0.072,0.171c-0.037,0.047-0.084,0.083-0.145,0.107c-0.061,0.023-0.139,0.035-0.232,0.035
				c-0.093,0-0.168-0.012-0.225-0.037c-0.057-0.024-0.102-0.058-0.132-0.099c-0.03-0.04-0.05-0.085-0.062-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.287c0,0.023,0.001,0.051,0.004,0.079c0.002,0.026,0.009,0.053,0.02,0.077
				c0.01,0.023,0.025,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.075,0.024c0.063,0,0.107-0.024,0.136-0.071
				c0.026-0.048,0.039-0.12,0.039-0.214c0-0.063-0.002-0.112-0.009-0.152c-0.005-0.04-0.014-0.072-0.026-0.096
				c-0.015-0.023-0.031-0.04-0.055-0.049c-0.021-0.011-0.049-0.016-0.082-0.016c-0.043,0-0.076,0.013-0.099,0.036
				c-0.022,0.025-0.037,0.059-0.042,0.1h-0.268l0.054-0.799h0.765v0.232H34.131z"
        />
        <rect
          x="46.074"
          y="-27.904"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#5087C7"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M46.987-27.405c0.076,0,0.14,0.012,0.192,0.036
				c0.052,0.026,0.094,0.061,0.125,0.102c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.055,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.144-0.033,0.199c-0.023,0.057-0.055,0.103-0.096,0.139s-0.09,0.063-0.147,0.081s-0.123,0.027-0.192,0.027h-0.16
				v0.59h-0.299v-1.479H46.987z M46.896-26.736c0.059,0,0.107-0.019,0.144-0.055c0.037-0.036,0.056-0.092,0.056-0.168
				c0-0.074-0.017-0.131-0.049-0.17c-0.031-0.039-0.084-0.058-0.16-0.058h-0.123v0.45H46.896z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          points="47.99,-27.406 48.201,-26.361 48.206,-26.361 48.419,-27.406
				48.861,-27.406 48.861,-25.927 48.587,-25.927 48.587,-27.108 48.583,-27.108 48.32,-25.927 48.087,-25.927 47.825,-27.108
				47.821,-27.108 47.821,-25.927 47.547,-25.927 47.547,-27.406 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M49.314-26.345c0,0.033,0.004,0.065,0.008,0.094
				c0.006,0.028,0.017,0.052,0.031,0.072c0.016,0.019,0.036,0.034,0.062,0.045c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.063-0.014-0.086
				c-0.008-0.025-0.023-0.047-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.065-0.022-0.124-0.046-0.172-0.073c-0.05-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.058-0.076-0.071-0.122
				c-0.016-0.046-0.023-0.101-0.023-0.161c0-0.147,0.041-0.256,0.121-0.327c0.082-0.072,0.195-0.108,0.336-0.108
				c0.068,0,0.129,0.008,0.185,0.021c0.056,0.015,0.104,0.039,0.144,0.071c0.043,0.032,0.074,0.073,0.097,0.124
				s0.034,0.11,0.034,0.182v0.041h-0.286c0-0.071-0.013-0.125-0.038-0.162c-0.023-0.039-0.066-0.057-0.123-0.057
				c-0.033,0-0.061,0.004-0.083,0.014c-0.022,0.009-0.04,0.023-0.054,0.038c-0.014,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.06,0.059,0.123,0.086l0.23,0.099
				c0.057,0.025,0.104,0.051,0.139,0.078c0.037,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.037,0.065,0.045,0.102
				C49.996-26.438,50-26.397,50-26.352c0,0.156-0.045,0.271-0.135,0.342c-0.09,0.07-0.217,0.105-0.379,0.105
				c-0.168,0-0.289-0.036-0.361-0.108c-0.072-0.073-0.108-0.18-0.108-0.315v-0.061h0.298V-26.345z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M50.581-25.927c0-0.065,0.007-0.125,0.02-0.182
				c0.013-0.058,0.033-0.111,0.06-0.162c0.027-0.051,0.061-0.102,0.103-0.15c0.04-0.05,0.088-0.099,0.142-0.147l0.121-0.112
				c0.032-0.029,0.06-0.056,0.08-0.081c0.02-0.025,0.034-0.05,0.045-0.075c0.012-0.024,0.02-0.051,0.022-0.081
				c0.003-0.028,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.022
				c-0.022,0.015-0.039,0.036-0.051,0.063c-0.011,0.026-0.019,0.057-0.022,0.092c-0.003,0.034-0.005,0.07-0.005,0.109h-0.286v-0.056
				c0-0.137,0.036-0.242,0.11-0.314c0.072-0.074,0.188-0.11,0.346-0.11c0.15,0,0.26,0.034,0.332,0.103
				c0.072,0.07,0.107,0.168,0.107,0.297c0,0.048-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.081-0.039,0.116
				c-0.019,0.038-0.041,0.074-0.071,0.109c-0.027,0.035-0.063,0.072-0.105,0.109l-0.165,0.153c-0.046,0.04-0.079,0.079-0.104,0.116
				c-0.023,0.039-0.041,0.071-0.047,0.1h0.553v0.231H50.581z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M52.488-27.137c-0.062,0.086-0.116,0.178-0.164,0.275
				c-0.047,0.099-0.088,0.199-0.125,0.303c-0.035,0.104-0.065,0.21-0.09,0.316c-0.022,0.107-0.041,0.212-0.053,0.315h-0.313
				c0.012-0.088,0.028-0.182,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.134-0.305
				c0.053-0.104,0.114-0.203,0.186-0.301h-0.617v-0.257h0.904V-27.137z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M52.91-26.963h-0.292v-0.195h0.027c0.101,0,0.177-0.018,0.235-0.055
				c0.057-0.038,0.094-0.098,0.108-0.181h0.22v1.467H52.91V-26.963z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M53.619-26.579c0.042-0.066,0.104-0.108,0.187-0.124v-0.004
				c-0.04-0.011-0.073-0.025-0.101-0.047c-0.026-0.021-0.049-0.044-0.065-0.069c-0.016-0.027-0.028-0.055-0.034-0.083
				c-0.008-0.03-0.011-0.058-0.011-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.181-0.094,0.315-0.094
				s0.239,0.031,0.315,0.094c0.077,0.063,0.116,0.166,0.116,0.311c0,0.025-0.004,0.053-0.012,0.083
				c-0.008,0.028-0.02,0.056-0.037,0.083c-0.016,0.025-0.037,0.049-0.064,0.069c-0.027,0.021-0.061,0.036-0.099,0.047v0.004
				c0.083,0.016,0.145,0.058,0.187,0.124c0.041,0.066,0.063,0.146,0.063,0.238c0,0.043-0.006,0.091-0.016,0.141
				c-0.011,0.052-0.033,0.099-0.065,0.143c-0.033,0.045-0.08,0.081-0.142,0.11c-0.062,0.028-0.144,0.044-0.246,0.044
				c-0.102,0-0.184-0.016-0.246-0.044c-0.062-0.029-0.108-0.065-0.142-0.11c-0.032-0.044-0.055-0.091-0.065-0.143
				c-0.01-0.05-0.016-0.098-0.016-0.141C53.557-26.434,53.578-26.513,53.619-26.579 M53.863-26.247
				c0.005,0.031,0.014,0.059,0.026,0.081s0.03,0.041,0.054,0.055c0.021,0.013,0.05,0.019,0.082,0.019
				c0.033,0,0.061-0.006,0.084-0.019c0.021-0.014,0.039-0.032,0.052-0.055s0.022-0.05,0.026-0.081
				c0.006-0.03,0.008-0.063,0.008-0.099s-0.002-0.068-0.008-0.099c-0.004-0.031-0.014-0.058-0.026-0.08
				c-0.013-0.023-0.031-0.041-0.052-0.054c-0.023-0.014-0.051-0.02-0.084-0.02c-0.032,0-0.061,0.006-0.082,0.02
				c-0.023,0.013-0.041,0.03-0.054,0.054c-0.013,0.022-0.021,0.049-0.026,0.08c-0.006,0.03-0.008,0.063-0.008,0.099
				S53.857-26.277,53.863-26.247 M53.92-26.843c0.025,0.033,0.061,0.05,0.105,0.05s0.08-0.017,0.105-0.05
				c0.027-0.032,0.04-0.085,0.04-0.154c0-0.136-0.048-0.202-0.146-0.202c-0.097,0-0.145,0.066-0.145,0.202
				C53.881-26.928,53.895-26.875,53.92-26.843"
        />
        <rect
          x="55.941"
          y="-27.904"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          width="8.813"
          height="8.813"
        />

        <rect
          x="55.941"
          y="-27.904"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.813"
          height="8.813"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          points="56.532,-27.405 56.695,-26.312 56.7,-26.312 56.875,-27.405 57.16,-27.405
				57.334,-26.312 57.338,-26.312 57.502,-27.405 57.788,-27.405 57.519,-25.927 57.186,-25.927 57.02,-27 57.016,-27
				56.85,-25.927 56.516,-25.927 56.246,-27.405 			"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          points="58.207,-27.405 58.207,-26.84 58.556,-26.84 58.556,-27.405 58.854,-27.405
				58.854,-25.927 58.556,-25.927 58.556,-26.583 58.207,-26.583 58.207,-25.927 57.908,-25.927 57.908,-27.405 			"
        />
        <rect
          x="59.076"
          y="-27.406"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          width="0.298"
          height="1.479"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          points="60.462,-27.161 60.139,-27.161 60.139,-25.927 59.84,-25.927 59.84,-27.161
				59.517,-27.161 59.517,-27.406 60.462,-27.406 			"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          points="61.417,-27.161 60.889,-27.161 60.889,-26.813 61.385,-26.813 61.385,-26.568
				60.889,-26.568 60.889,-26.171 61.438,-26.171 61.438,-25.927 60.59,-25.927 60.59,-27.406 61.417,-27.406 			"
        />
        <rect
          x="36.205"
          y="-27.904"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#005891"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M37.119-27.406c0.076,0,0.14,0.013,0.191,0.037
				c0.053,0.026,0.094,0.061,0.125,0.102c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.012,0.143-0.035,0.199c-0.022,0.057-0.055,0.103-0.094,0.138c-0.041,0.037-0.092,0.064-0.148,0.081
				c-0.059,0.019-0.123,0.028-0.193,0.028h-0.16v0.59h-0.298v-1.479H37.119z M37.027-26.736c0.06,0,0.107-0.019,0.145-0.055
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.159-0.058h-0.125v0.45H37.027z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          points="38.121,-27.406 38.332,-26.361 38.338,-26.361 38.551,-27.406
				38.992,-27.406 38.992,-25.927 38.719,-25.927 38.719,-27.108 38.715,-27.108 38.451,-25.927 38.219,-25.927 37.957,-27.108
				37.953,-27.108 37.953,-25.927 37.679,-25.927 37.679,-27.406 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M39.446-26.345c0,0.033,0.003,0.065,0.009,0.094
				c0.004,0.028,0.016,0.052,0.03,0.072c0.015,0.019,0.036,0.034,0.062,0.045c0.025,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.055-0.076,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.009-0.025-0.024-0.047-0.044-0.066c-0.021-0.019-0.049-0.036-0.082-0.053s-0.075-0.032-0.125-0.048
				c-0.066-0.023-0.124-0.048-0.172-0.073c-0.049-0.026-0.09-0.057-0.121-0.093c-0.033-0.035-0.057-0.075-0.072-0.122
				c-0.016-0.045-0.023-0.1-0.023-0.16c0-0.147,0.041-0.256,0.123-0.327c0.082-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.008,0.184,0.021c0.056,0.015,0.104,0.039,0.145,0.071s0.072,0.073,0.096,0.124
				c0.022,0.051,0.033,0.11,0.033,0.182v0.041h-0.285c0-0.071-0.014-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.125-0.057
				c-0.032,0-0.061,0.004-0.082,0.014c-0.023,0.009-0.04,0.022-0.055,0.038c-0.013,0.016-0.021,0.035-0.025,0.055
				c-0.006,0.021-0.008,0.042-0.008,0.065c0,0.045,0.011,0.083,0.029,0.114c0.02,0.031,0.061,0.06,0.125,0.086l0.229,0.1
				c0.057,0.024,0.103,0.051,0.139,0.078s0.063,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.046,0.102
				c0.006,0.038,0.01,0.079,0.01,0.125c0,0.156-0.045,0.27-0.135,0.342c-0.09,0.07-0.216,0.105-0.377,0.105
				c-0.17,0-0.291-0.036-0.363-0.108c-0.071-0.074-0.107-0.18-0.107-0.316v-0.06h0.298V-26.345z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M40.713-25.927c0-0.065,0.006-0.126,0.02-0.183s0.033-0.11,0.061-0.161
				c0.026-0.051,0.06-0.102,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.147l0.121-0.112c0.033-0.03,0.061-0.056,0.08-0.081
				s0.035-0.05,0.046-0.075c0.011-0.024,0.019-0.052,0.022-0.081c0.003-0.028,0.005-0.062,0.005-0.099
				c0-0.122-0.048-0.183-0.146-0.183c-0.037,0-0.066,0.008-0.088,0.022s-0.037,0.036-0.05,0.063
				c-0.011,0.026-0.019,0.057-0.022,0.092C41-26.988,41-26.952,41-26.913h-0.287v-0.057c0-0.136,0.037-0.241,0.109-0.313
				c0.073-0.074,0.188-0.11,0.346-0.11c0.15,0,0.26,0.034,0.332,0.103c0.072,0.069,0.108,0.168,0.108,0.297
				c0,0.047-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.081-0.039,0.116c-0.018,0.038-0.041,0.074-0.071,0.109
				c-0.028,0.035-0.063,0.072-0.106,0.109l-0.164,0.152c-0.045,0.041-0.08,0.079-0.104,0.117c-0.024,0.039-0.041,0.071-0.047,0.1
				h0.553v0.231H40.713z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M42.051-26.139c0.02,0.03,0.055,0.047,0.104,0.047
				c0.04,0,0.071-0.01,0.096-0.028c0.022-0.019,0.041-0.045,0.054-0.078c0.014-0.033,0.022-0.073,0.026-0.118
				c0.005-0.045,0.007-0.096,0.007-0.148v-0.07h-0.005c-0.024,0.036-0.06,0.066-0.106,0.089c-0.047,0.023-0.103,0.034-0.168,0.034
				s-0.121-0.011-0.166-0.034c-0.047-0.022-0.084-0.054-0.111-0.095c-0.028-0.041-0.049-0.089-0.063-0.146
				c-0.014-0.056-0.02-0.116-0.02-0.183c0-0.17,0.038-0.3,0.113-0.39c0.074-0.091,0.195-0.136,0.361-0.136
				c0.103,0,0.184,0.02,0.244,0.059c0.061,0.037,0.108,0.088,0.141,0.149c0.031,0.064,0.054,0.137,0.063,0.22
				c0.009,0.083,0.015,0.17,0.015,0.26c0,0.104-0.004,0.205-0.01,0.303s-0.025,0.184-0.057,0.258
				c-0.03,0.074-0.08,0.133-0.146,0.178c-0.068,0.043-0.162,0.064-0.281,0.064c-0.053,0-0.104-0.004-0.152-0.015
				s-0.092-0.03-0.129-0.059s-0.068-0.065-0.092-0.113c-0.022-0.048-0.033-0.107-0.035-0.179h0.287
				C42.021-26.213,42.03-26.17,42.051-26.139 M42.035-26.695c0.025,0.042,0.066,0.064,0.124,0.064c0.06,0,0.103-0.022,0.126-0.064
				c0.025-0.043,0.038-0.115,0.038-0.213c0-0.042-0.002-0.081-0.007-0.117c-0.004-0.036-0.012-0.066-0.023-0.092
				s-0.027-0.045-0.05-0.06c-0.021-0.016-0.049-0.022-0.084-0.022c-0.036,0-0.063,0.007-0.086,0.022
				c-0.021,0.015-0.037,0.034-0.05,0.061c-0.01,0.026-0.018,0.057-0.021,0.092c-0.004,0.036-0.005,0.074-0.005,0.116
				C41.998-26.811,42.01-26.738,42.035-26.695"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M43.182-27.395h0.309v0.932h0.141v0.231H43.49v0.305h-0.273v-0.305h-0.522
				v-0.243L43.182-27.395z M43.212-27.021l-0.286,0.559h0.291v-0.559H43.212z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M44-27.133l-0.031,0.334h0.004c0.027-0.039,0.063-0.067,0.107-0.085
				c0.045-0.017,0.098-0.025,0.162-0.025c0.07,0,0.129,0.014,0.176,0.041c0.045,0.026,0.082,0.063,0.109,0.106
				c0.025,0.045,0.046,0.094,0.057,0.15c0.01,0.056,0.016,0.112,0.016,0.171c0,0.081-0.006,0.155-0.021,0.223
				c-0.013,0.064-0.038,0.122-0.073,0.171c-0.035,0.047-0.084,0.082-0.144,0.107c-0.062,0.023-0.138,0.035-0.231,0.035
				s-0.168-0.012-0.226-0.036c-0.058-0.025-0.101-0.059-0.132-0.1c-0.03-0.04-0.051-0.085-0.061-0.132
				c-0.011-0.048-0.017-0.092-0.017-0.134v-0.039h0.287c0,0.023,0.001,0.05,0.004,0.079c0.002,0.026,0.009,0.053,0.021,0.077
				c0.01,0.023,0.024,0.044,0.043,0.06c0.021,0.016,0.045,0.023,0.078,0.023c0.062,0,0.105-0.023,0.133-0.07
				c0.027-0.048,0.041-0.12,0.041-0.214c0-0.063-0.004-0.112-0.01-0.152c-0.005-0.04-0.014-0.072-0.027-0.096
				s-0.03-0.04-0.053-0.049c-0.021-0.011-0.05-0.016-0.082-0.016c-0.043,0-0.076,0.013-0.1,0.036
				c-0.021,0.024-0.037,0.059-0.042,0.1h-0.267l0.053-0.799h0.766v0.232H44z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M12.708,75.938c0.592,0,0.973,0.392,0.973,0.991
				c0,0.594-0.381,0.986-0.973,0.986c-0.588,0-0.968-0.393-0.968-0.986C11.74,76.329,12.12,75.938,12.708,75.938 M12.708,77.526
				c0.352,0,0.458-0.301,0.458-0.598c0-0.302-0.106-0.603-0.458-0.603c-0.348,0-0.453,0.301-0.453,0.603
				C12.255,77.226,12.36,77.526,12.708,77.526"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M14.666,75.988h0.378v0.345h-0.378v0.929c0,0.174,0.044,0.219,0.218,0.219
				c0.054,0,0.105-0.005,0.16-0.016v0.403c-0.087,0.014-0.2,0.018-0.301,0.018c-0.316,0-0.592-0.072-0.592-0.445v-1.107h-0.312
				v-0.345h0.312v-0.563h0.515V75.988z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M15.714,77.052c0.014,0.326,0.174,0.475,0.46,0.475
				c0.208,0,0.375-0.127,0.408-0.242h0.453c-0.145,0.442-0.453,0.631-0.878,0.631c-0.592,0-0.958-0.406-0.958-0.986
				c0-0.563,0.388-0.992,0.958-0.992c0.639,0,0.946,0.538,0.911,1.115H15.714z M16.552,76.725c-0.047-0.261-0.16-0.398-0.41-0.398
				c-0.327,0-0.421,0.254-0.428,0.398H16.552z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M17.771,77.255c0.004,0.225,0.192,0.315,0.396,0.315
				c0.149,0,0.337-0.058,0.337-0.24c0-0.155-0.214-0.21-0.584-0.289c-0.298-0.065-0.595-0.171-0.595-0.502
				c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.138,0.828,0.598h-0.491c-0.014-0.198-0.166-0.254-0.351-0.254
				c-0.116,0-0.287,0.022-0.287,0.176c0,0.185,0.29,0.209,0.584,0.278c0.301,0.069,0.596,0.178,0.596,0.526
				c0,0.494-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.162-0.882-0.659H17.771z"
        />
        <rect
          x="8.914"
          y="80.466"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M12.019,79.029h0.889c0.798,0,1.244,0.399,1.244,1.234
				c0,0.867-0.38,1.357-1.244,1.357h-0.889V79.029z M12.363,81.33h0.574c0.235,0,0.871-0.064,0.871-1.02
				c0-0.616-0.228-0.99-0.864-0.99h-0.581V81.33z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M15.33,79.701c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.419,80.133,14.735,79.701,15.33,79.701 M15.33,81.392
				c0.323,0,0.584-0.254,0.584-0.706c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
				C14.746,81.138,15.007,81.392,15.33,81.392"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M17.611,79.744h0.291v0.298h0.006c0.131-0.232,0.341-0.342,0.61-0.342
				c0.494,0,0.646,0.283,0.646,0.687v1.233h-0.308v-1.27c0-0.228-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
				h-0.309V79.744z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M20.437,79.701c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.526,80.133,19.842,79.701,20.437,79.701 M20.437,81.392
				c0.323,0,0.584-0.254,0.584-0.706c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
				C19.853,81.138,20.114,81.392,20.437,81.392"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M22.137,79.744h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.167,0.233,0.167
				h0.141v0.271h-0.236c-0.319,0-0.447-0.064-0.447-0.409v-1.194H21.51v-0.272h0.318v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M25.522,81.613c-0.055,0.032-0.123,0.051-0.222,0.051
				c-0.16,0-0.261-0.087-0.261-0.29c-0.171,0.2-0.399,0.29-0.66,0.29c-0.341,0-0.621-0.152-0.621-0.526
				c0-0.424,0.316-0.515,0.635-0.577c0.341-0.064,0.631-0.043,0.631-0.274c0-0.27-0.221-0.313-0.417-0.313
				c-0.261,0-0.453,0.081-0.468,0.356h-0.309c0.018-0.466,0.378-0.628,0.795-0.628c0.338,0,0.704,0.076,0.704,0.515v0.966
				c0,0.146,0,0.21,0.098,0.21c0.026,0,0.055-0.003,0.095-0.018V81.613z M25.021,80.651c-0.12,0.087-0.352,0.092-0.559,0.127
				c-0.203,0.036-0.378,0.109-0.378,0.337c0,0.204,0.175,0.276,0.363,0.276c0.407,0,0.574-0.254,0.574-0.424V80.651z"
        />
        <rect
          x="25.826"
          y="79.03"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          width="0.308"
          height="2.591"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M27.041,79.744h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.409v-1.194h-0.319v-0.272h0.319v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M29.356,81.026c-0.084,0.413-0.381,0.638-0.803,0.638
				c-0.598,0-0.881-0.414-0.899-0.987c0-0.562,0.37-0.976,0.881-0.976c0.664,0,0.868,0.62,0.849,1.074H27.98
				c-0.011,0.326,0.175,0.616,0.585,0.616c0.254,0,0.432-0.122,0.486-0.365H29.356z M29.058,80.503
				c-0.014-0.294-0.236-0.53-0.541-0.53c-0.323,0-0.515,0.243-0.537,0.53H29.058z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M29.692,79.744h0.291v0.396h0.006c0.149-0.301,0.356-0.45,0.69-0.44v0.327
				c-0.497,0-0.678,0.283-0.678,0.758v0.835h-0.309V79.744z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M33.581,81.613c-0.056,0.032-0.123,0.051-0.222,0.051
				c-0.16,0-0.262-0.087-0.262-0.29c-0.17,0.2-0.398,0.29-0.66,0.29c-0.34,0-0.621-0.152-0.621-0.526
				c0-0.424,0.316-0.515,0.637-0.577c0.34-0.064,0.631-0.043,0.631-0.274c0-0.27-0.223-0.313-0.418-0.313
				c-0.262,0-0.453,0.081-0.469,0.356h-0.307c0.018-0.466,0.377-0.628,0.793-0.628c0.338,0,0.705,0.076,0.705,0.515v0.966
				c0,0.146,0,0.21,0.098,0.21c0.025,0,0.055-0.003,0.095-0.018V81.613z M33.08,80.651c-0.119,0.087-0.352,0.092-0.559,0.127
				c-0.203,0.036-0.377,0.109-0.377,0.337c0,0.204,0.174,0.276,0.362,0.276c0.406,0,0.573-0.254,0.573-0.424V80.651z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M33.855,79.744h0.291v0.396h0.006c0.148-0.301,0.357-0.45,0.689-0.44v0.327
				c-0.496,0-0.678,0.283-0.678,0.758v0.835h-0.309V79.744z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M35.503,79.744h0.374v0.272h-0.374v1.165c0,0.142,0.038,0.167,0.231,0.167
				h0.143v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.409v-1.194h-0.32v-0.272h0.32v-0.563h0.31V79.744z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          points="38.074,81.621 37.748,81.621 37.367,80.129 37.359,80.129
				36.982,81.621 36.648,81.621 36.046,79.745 36.387,79.745 36.812,81.279 36.82,81.279 37.197,79.745 37.533,79.745
				37.926,81.279 37.934,81.279 38.354,79.745 38.673,79.745 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M39.775,79.701c0.595,0,0.91,0.432,0.91,0.984
				c0,0.547-0.315,0.979-0.91,0.979c-0.596,0-0.911-0.432-0.911-0.979C38.864,80.133,39.18,79.701,39.775,79.701 M39.775,81.392
				c0.322,0,0.584-0.254,0.584-0.706c0-0.459-0.262-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
				C39.191,81.138,39.452,81.392,39.775,81.392"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M41.037,79.744h0.29v0.396h0.007c0.149-0.301,0.356-0.45,0.69-0.44v0.327
				c-0.497,0-0.679,0.283-0.679,0.758v0.835h-0.309V79.744z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          points="42.273,79.029 42.583,79.029 42.583,80.564 43.439,79.744
				43.854,79.744 43.109,80.427 43.906,81.62 43.515,81.62 42.872,80.641 42.583,80.909 42.583,81.62 42.273,81.62 			"
        />
        <rect
          x="8.914"
          y="84.223"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M14.087,84.441c0,0.652-0.375,0.998-1.02,0.998
				c-0.668,0-1.063-0.31-1.063-0.998v-1.655h0.345v1.655c0,0.457,0.26,0.707,0.718,0.707c0.435,0,0.675-0.25,0.675-0.707v-1.655
				h0.345V84.441z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M14.774,84.786c0.011,0.275,0.25,0.362,0.505,0.362
				c0.192,0,0.453-0.044,0.453-0.279c0-0.239-0.305-0.279-0.613-0.349c-0.305-0.068-0.614-0.171-0.614-0.529
				c0-0.378,0.374-0.533,0.701-0.533c0.414,0,0.744,0.13,0.769,0.588h-0.308c-0.022-0.24-0.233-0.316-0.436-0.316
				c-0.185,0-0.399,0.052-0.399,0.239c0,0.222,0.326,0.258,0.614,0.327c0.308,0.069,0.612,0.171,0.612,0.534
				c0,0.445-0.417,0.591-0.798,0.591c-0.421,0-0.777-0.171-0.794-0.635H14.774z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M18,84.782c-0.083,0.413-0.381,0.639-0.802,0.639
				c-0.599,0-0.882-0.414-0.9-0.987c0-0.563,0.37-0.976,0.882-0.976c0.663,0,0.867,0.619,0.849,1.073h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.366H18z M17.702,84.26c-0.014-0.294-0.236-0.53-0.54-0.53
				c-0.323,0-0.515,0.243-0.538,0.53H17.702z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M19.783,83.501h0.374v0.272h-0.374v1.165c0,0.142,0.039,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V83.501z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M20.825,83.163h-0.308v-0.378h0.308V83.163z M20.516,83.501h0.308v1.876
				h-0.308V83.501z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M22.921,85.217c0,0.61-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.142-0.776-0.551h0.308c0.015,0.226,0.283,0.305,0.486,0.305c0.403,0,0.563-0.29,0.563-0.711v-0.123h-0.007
				c-0.102,0.228-0.349,0.341-0.585,0.341c-0.558,0-0.838-0.439-0.838-0.951c0-0.443,0.218-0.991,0.867-0.991
				c0.236,0,0.447,0.106,0.559,0.313h0.004v-0.269h0.29V85.217z M22.616,84.401c0-0.331-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.322-0.556,0.683c0,0.337,0.124,0.714,0.53,0.714C22.464,85.126,22.616,84.753,22.616,84.401"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M23.385,82.786h0.308v0.991h0.008c0.102-0.226,0.366-0.319,0.591-0.319
				c0.494,0,0.646,0.282,0.646,0.686v1.233H24.63v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.059
				h-0.308V82.786z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M25.83,83.501h0.374v0.272H25.83v1.164c0,0.143,0.04,0.168,0.232,0.168
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V83.501z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M28.361,83.457c0.595,0,0.911,0.433,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.433-0.911-0.979C27.45,83.89,27.766,83.457,28.361,83.457 M28.361,85.148
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C27.777,84.895,28.038,85.148,28.361,85.148"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          points="30.479,85.377 30.149,85.377 29.453,83.501 29.797,83.501
				30.323,85.065 30.33,85.065 30.842,83.501 31.165,83.501 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M33.047,84.782c-0.082,0.413-0.38,0.639-0.801,0.639
				c-0.6,0-0.883-0.414-0.901-0.987c0-0.563,0.371-0.977,0.883-0.977c0.662,0,0.867,0.62,0.849,1.074h-1.405
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.255,0,0.431-0.123,0.486-0.366H33.047z M32.75,84.26c-0.014-0.294-0.236-0.53-0.54-0.53
				c-0.323,0-0.517,0.243-0.538,0.53H32.75z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M33.384,83.501h0.29v0.396h0.008c0.148-0.301,0.355-0.449,0.689-0.439v0.327
				c-0.497,0-0.68,0.283-0.68,0.758v0.835h-0.308V83.501z"
        />
        <rect
          x="34.621"
          y="82.786"
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          width="0.309"
          height="2.591"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M36.217,83.457c0.596,0,0.911,0.433,0.911,0.984
				c0,0.547-0.315,0.979-0.911,0.979c-0.595,0-0.91-0.433-0.91-0.979C35.307,83.89,35.622,83.457,36.217,83.457 M36.217,85.148
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.322,0-0.584,0.254-0.584,0.712
				C35.633,84.895,35.895,85.148,36.217,85.148"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M38.764,84.104c-0.047-0.236-0.211-0.374-0.465-0.374
				c-0.447,0-0.584,0.353-0.584,0.733c0,0.345,0.155,0.686,0.547,0.686c0.297,0,0.477-0.174,0.516-0.457h0.316
				c-0.069,0.457-0.355,0.729-0.828,0.729c-0.572,0-0.877-0.399-0.877-0.958c0-0.563,0.289-1.005,0.885-1.005
				c0.425,0,0.766,0.199,0.81,0.646H38.764z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          points="39.455,82.786 39.765,82.786 39.765,84.321 40.621,83.501
				41.035,83.501 40.291,84.183 41.088,85.377 40.696,85.377 40.054,84.396 39.765,84.666 39.765,85.377 39.455,85.377 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M41.646,83.163h-0.309v-0.378h0.309V83.163z M41.338,83.501h0.309v1.876
				h-0.309V83.501z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M42.125,83.501h0.291v0.297h0.006c0.131-0.231,0.341-0.341,0.609-0.341
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.309v-1.27c0-0.229-0.146-0.379-0.381-0.379c-0.374,0-0.556,0.252-0.556,0.59v1.059
				h-0.308V83.501z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#EC008C"
          d="M45.76,85.217c0,0.61-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.142-0.776-0.551h0.308c0.016,0.226,0.283,0.305,0.486,0.305c0.403,0,0.563-0.29,0.563-0.712v-0.122h-0.007
				c-0.103,0.228-0.35,0.341-0.586,0.341c-0.558,0-0.838-0.439-0.838-0.951c0-0.443,0.219-0.991,0.867-0.991
				c0.236,0,0.447,0.106,0.559,0.313h0.005v-0.269h0.29V85.217z M45.455,84.401c0-0.331-0.146-0.672-0.533-0.672
				c-0.396,0-0.557,0.322-0.557,0.683c0,0.337,0.124,0.714,0.53,0.714C45.303,85.126,45.455,84.753,45.455,84.401"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-12.208-27.458c0.076,0,0.14,0.013,0.192,0.038s0.094,0.06,0.125,0.101
				c0.032,0.042,0.055,0.088,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163c0,0.076-0.011,0.143-0.034,0.199
				s-0.054,0.103-0.095,0.138c-0.041,0.037-0.091,0.064-0.148,0.081c-0.058,0.019-0.123,0.028-0.193,0.028h-0.16v0.59h-0.298v-1.479
				H-12.208z M-12.3-26.788c0.059,0,0.107-0.019,0.144-0.055c0.037-0.036,0.055-0.092,0.055-0.168c0-0.073-0.016-0.131-0.048-0.17
				c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H-12.3z"
        />
        <polygon
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          points="-11.206,-27.458 -10.995,-26.413 -10.99,-26.413 -10.776,-27.458
				-10.334,-27.458 -10.334,-25.979 -10.608,-25.979 -10.608,-27.16 -10.613,-27.16 -10.875,-25.979 -11.108,-25.979
				-11.371,-27.16 -11.375,-27.16 -11.375,-25.979 -11.648,-25.979 -11.648,-27.458 			"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-9.881-26.396c0,0.034,0.003,0.065,0.008,0.094s0.016,0.052,0.031,0.072
				c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.014,0.123-0.044
				c0.036-0.028,0.056-0.076,0.056-0.137c0-0.034-0.006-0.063-0.015-0.086c-0.008-0.025-0.023-0.047-0.044-0.066
				c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048c-0.066-0.023-0.124-0.047-0.172-0.073
				s-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.075-0.072-0.122c-0.015-0.045-0.023-0.099-0.023-0.16
				c0-0.146,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.107,0.336-0.107c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.11,0.034,0.182v0.041h-0.286
				c0-0.071-0.012-0.125-0.038-0.162c-0.023-0.039-0.065-0.057-0.123-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.023,0.009-0.04,0.022-0.054,0.038c-0.013,0.017-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.043-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.114c0.019,0.032,0.06,0.06,0.124,0.086l0.23,0.1c0.057,0.024,0.103,0.051,0.139,0.078
				s0.064,0.057,0.086,0.087s0.036,0.065,0.045,0.103c0.008,0.038,0.012,0.079,0.012,0.125c0,0.155-0.046,0.269-0.136,0.341
				c-0.09,0.071-0.216,0.105-0.378,0.105c-0.169,0-0.29-0.035-0.362-0.108c-0.072-0.074-0.108-0.18-0.108-0.316v-0.06h0.298V-26.396
				z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-8.29-26.212c0.021,0.046,0.065,0.068,0.13,0.068
				c0.035,0,0.064-0.007,0.086-0.019c0.021-0.014,0.037-0.03,0.049-0.052c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.028,0.005-0.06,0.005-0.092s-0.002-0.063-0.007-0.094c-0.005-0.029-0.014-0.056-0.028-0.08
				c-0.014-0.022-0.033-0.042-0.058-0.056c-0.025-0.014-0.059-0.021-0.1-0.021h-0.099v-0.194h0.097c0.032,0,0.059-0.006,0.08-0.02
				c0.021-0.013,0.039-0.029,0.052-0.052c0.013-0.021,0.023-0.046,0.029-0.074c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.07-0.012-0.119-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.057,0.006-0.077,0.017
				c-0.021,0.012-0.036,0.029-0.047,0.05c-0.011,0.022-0.018,0.047-0.022,0.076c-0.003,0.029-0.005,0.061-0.005,0.095h-0.273
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.07,0.181-0.106,0.322-0.106c0.133,0,0.237,0.03,0.311,0.092
				c0.074,0.061,0.111,0.155,0.111,0.285c0,0.089-0.02,0.161-0.059,0.217s-0.094,0.091-0.164,0.107v0.004
				c0.095,0.015,0.162,0.053,0.201,0.113c0.04,0.063,0.06,0.14,0.06,0.232c0,0.049-0.006,0.102-0.019,0.153
				c-0.012,0.052-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.077-0.149,0.104C-8-25.969-8.084-25.955-8.186-25.955
				c-0.141,0-0.248-0.038-0.319-0.116C-8.575-26.148-8.61-26.26-8.61-26.4v-0.007h0.286C-8.323-26.321-8.311-26.257-8.29-26.212"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-7.042-27.206c-0.02-0.029-0.056-0.045-0.105-0.045
				c-0.039,0-0.07,0.009-0.095,0.028c-0.024,0.018-0.042,0.045-0.056,0.078c-0.013,0.033-0.022,0.072-0.025,0.118
				c-0.005,0.045-0.007,0.095-0.007,0.148v0.072h0.004c0.026-0.038,0.061-0.068,0.108-0.092c0.046-0.022,0.102-0.034,0.168-0.034
				c0.065,0,0.12,0.012,0.166,0.034c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.089,0.062,0.146
				c0.013,0.056,0.02,0.116,0.02,0.183c0,0.17-0.038,0.298-0.113,0.387C-6.879-25.999-7-25.956-7.166-25.956
				c-0.102,0-0.184-0.018-0.245-0.053c-0.06-0.037-0.107-0.086-0.139-0.149c-0.033-0.063-0.054-0.136-0.064-0.219
				c-0.009-0.082-0.014-0.168-0.014-0.259c0-0.103,0.003-0.204,0.009-0.301c0.006-0.099,0.025-0.185,0.057-0.259
				c0.03-0.075,0.079-0.137,0.145-0.183c0.067-0.045,0.161-0.067,0.282-0.067c0.052,0,0.104,0.007,0.152,0.019
				c0.05,0.012,0.093,0.033,0.13,0.062c0.037,0.029,0.068,0.067,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.178h-0.286
				C-7.013-27.13-7.022-27.175-7.042-27.206 M-7.309-26.317c0.005,0.034,0.012,0.065,0.024,0.092c0.012,0.025,0.028,0.046,0.05,0.06
				c0.021,0.015,0.049,0.022,0.084,0.022c0.036,0,0.064-0.008,0.086-0.022c0.021-0.014,0.037-0.034,0.048-0.061
				c0.011-0.026,0.019-0.058,0.022-0.093c0.003-0.034,0.005-0.073,0.005-0.115c0-0.1-0.012-0.171-0.037-0.213
				c-0.025-0.043-0.066-0.064-0.124-0.064c-0.06,0-0.102,0.021-0.127,0.064c-0.024,0.042-0.037,0.113-0.037,0.213
				C-7.315-26.393-7.313-26.354-7.309-26.317"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-6.572-26.631c0.042-0.066,0.104-0.107,0.187-0.125v-0.004
				c-0.04-0.01-0.074-0.024-0.101-0.046c-0.026-0.02-0.048-0.043-0.065-0.07c-0.016-0.025-0.028-0.054-0.035-0.083
				c-0.007-0.028-0.01-0.056-0.01-0.083c0-0.144,0.038-0.247,0.115-0.309c0.076-0.063,0.181-0.095,0.316-0.095
				c0.134,0,0.239,0.031,0.315,0.095c0.077,0.062,0.116,0.165,0.116,0.309c0,0.027-0.004,0.055-0.011,0.083
				c-0.008,0.029-0.02,0.058-0.037,0.083c-0.016,0.027-0.038,0.051-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.018,0.145,0.059,0.187,0.125c0.041,0.066,0.062,0.146,0.062,0.238c0,0.043-0.005,0.09-0.015,0.14
				c-0.011,0.053-0.032,0.101-0.066,0.145c-0.033,0.044-0.08,0.08-0.141,0.11c-0.062,0.028-0.144,0.043-0.246,0.043
				s-0.184-0.015-0.246-0.043c-0.062-0.03-0.108-0.066-0.142-0.11c-0.033-0.044-0.054-0.092-0.065-0.145
				c-0.011-0.05-0.016-0.097-0.016-0.14C-6.634-26.485-6.612-26.564-6.572-26.631 M-6.328-26.299
				c0.005,0.031,0.014,0.059,0.027,0.081c0.013,0.023,0.031,0.041,0.053,0.054c0.022,0.014,0.05,0.021,0.083,0.021
				c0.033,0,0.06-0.007,0.083-0.021c0.022-0.013,0.039-0.03,0.052-0.054c0.013-0.022,0.023-0.05,0.028-0.081
				c0.004-0.031,0.007-0.063,0.007-0.098c0-0.036-0.003-0.07-0.007-0.1c-0.005-0.03-0.015-0.058-0.028-0.079
				c-0.013-0.024-0.03-0.041-0.052-0.055c-0.023-0.014-0.05-0.02-0.083-0.02c-0.033,0-0.061,0.006-0.083,0.02
				s-0.04,0.03-0.053,0.055c-0.013,0.021-0.022,0.049-0.027,0.079c-0.005,0.029-0.007,0.063-0.007,0.1
				C-6.335-26.362-6.333-26.33-6.328-26.299 M-6.271-26.895c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05
				c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.201-0.145-0.201c-0.097,0-0.145,0.066-0.145,0.201
				C-6.31-26.979-6.297-26.928-6.271-26.895"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-11.802-25.212c-0.064,0.091-0.122,0.189-0.173,0.293
				c-0.05,0.104-0.095,0.212-0.133,0.322c-0.038,0.109-0.07,0.221-0.095,0.335c-0.024,0.114-0.044,0.227-0.057,0.336h-0.332
				c0.011-0.094,0.029-0.193,0.053-0.296c0.023-0.103,0.055-0.209,0.093-0.316c0.039-0.106,0.086-0.215,0.142-0.323
				c0.056-0.109,0.122-0.215,0.198-0.319h-0.656v-0.273h0.96V-25.212z"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-11.702-24.965c0.007-0.1,0.026-0.188,0.059-0.266
				c0.032-0.076,0.081-0.139,0.146-0.185c0.066-0.047,0.156-0.069,0.272-0.069c0.096,0,0.174,0.014,0.237,0.041
				c0.063,0.026,0.112,0.07,0.149,0.131c0.038,0.06,0.065,0.139,0.08,0.233c0.015,0.098,0.023,0.214,0.023,0.353
				c0,0.107-0.004,0.213-0.011,0.313c-0.007,0.101-0.027,0.188-0.06,0.266c-0.032,0.075-0.08,0.138-0.146,0.182
				c-0.065,0.044-0.156,0.064-0.272,0.064c-0.094,0-0.172-0.012-0.236-0.036c-0.063-0.025-0.112-0.068-0.15-0.128
				c-0.038-0.061-0.065-0.139-0.08-0.234c-0.015-0.096-0.023-0.213-0.023-0.352C-11.714-24.761-11.71-24.865-11.702-24.965
				 M-11.389-24.343c0.005,0.062,0.014,0.111,0.028,0.146c0.013,0.036,0.031,0.062,0.052,0.074c0.023,0.013,0.051,0.021,0.084,0.021
				c0.035,0,0.064-0.008,0.086-0.025c0.022-0.017,0.04-0.046,0.052-0.086c0.012-0.04,0.021-0.095,0.026-0.162
				c0.006-0.066,0.008-0.15,0.008-0.251v-0.169c0-0.099-0.002-0.179-0.008-0.242c-0.005-0.062-0.015-0.11-0.027-0.146
				c-0.013-0.036-0.031-0.062-0.053-0.074s-0.05-0.021-0.084-0.021c-0.035,0-0.064,0.01-0.086,0.026
				c-0.022,0.016-0.039,0.045-0.052,0.085c-0.012,0.041-0.021,0.095-0.026,0.162c-0.005,0.068-0.008,0.152-0.008,0.251v0.17
				C-11.397-24.484-11.395-24.405-11.389-24.343"
        />
        <path
          clipPath="url(#NAVIGATOR_SVGID_64_)"
          fill="#FFFFFF"
          d="M-10.624-25.249c0.009-0.054,0.025-0.098,0.049-0.132
				c0.025-0.035,0.058-0.062,0.101-0.078c0.044-0.018,0.1-0.025,0.167-0.025c0.069,0,0.125,0.008,0.168,0.025
				c0.042,0.017,0.075,0.043,0.1,0.078c0.024,0.034,0.04,0.078,0.049,0.132c0.009,0.053,0.013,0.114,0.013,0.185
				c0,0.071-0.004,0.132-0.013,0.186c-0.009,0.053-0.025,0.097-0.049,0.132c-0.025,0.036-0.058,0.061-0.1,0.079
				c-0.043,0.016-0.099,0.024-0.168,0.024c-0.067,0-0.123-0.009-0.167-0.024c-0.043-0.019-0.076-0.043-0.101-0.079
				c-0.024-0.035-0.04-0.079-0.049-0.132c-0.009-0.054-0.013-0.114-0.013-0.186C-10.637-25.135-10.633-25.196-10.624-25.249
				 M-10.389-24.875c0.011,0.043,0.038,0.064,0.082,0.064c0.044,0,0.071-0.021,0.082-0.064c0.009-0.044,0.015-0.11,0.015-0.198
				c0-0.079-0.006-0.139-0.015-0.181c-0.011-0.042-0.038-0.063-0.082-0.063c-0.044,0-0.071,0.021-0.082,0.063
				c-0.01,0.042-0.015,0.102-0.015,0.181C-10.404-24.985-10.399-24.919-10.389-24.875 M-9.299-25.521l-0.894,1.657h-0.196
				l0.895-1.657H-9.299z M-9.69-24.5c0.009-0.054,0.025-0.098,0.049-0.132c0.024-0.035,0.058-0.062,0.102-0.078
				c0.043-0.018,0.098-0.026,0.165-0.026c0.07,0,0.125,0.009,0.168,0.026c0.042,0.017,0.076,0.043,0.1,0.078
				c0.025,0.034,0.041,0.078,0.05,0.132c0.008,0.053,0.013,0.113,0.013,0.185c0,0.072-0.005,0.134-0.013,0.187
				c-0.009,0.052-0.025,0.096-0.05,0.13c-0.024,0.034-0.058,0.059-0.1,0.075c-0.043,0.015-0.098,0.022-0.168,0.022
				c-0.067,0-0.122-0.008-0.165-0.022c-0.044-0.017-0.078-0.041-0.102-0.075c-0.024-0.034-0.04-0.078-0.049-0.13
				c-0.01-0.053-0.013-0.114-0.013-0.187C-9.703-24.387-9.7-24.447-9.69-24.5 M-9.455-24.127c0.01,0.044,0.037,0.064,0.081,0.064
				s0.072-0.021,0.082-0.064c0.01-0.043,0.016-0.109,0.016-0.197c0-0.079-0.006-0.139-0.016-0.181
				c-0.01-0.043-0.038-0.063-0.082-0.063s-0.071,0.021-0.081,0.063c-0.01,0.042-0.015,0.102-0.015,0.181
				C-9.47-24.236-9.465-24.17-9.455-24.127"
        />
      </g>
    </g>
  );
};

const Geocaching = () => {
  return (
    <g id="Geocaching">
      <g>
        <defs>
          <rect
            id="GEOCACHING_SVGID_65_"
            x="0"
            y="0.001"
            width="74.906"
            height="61.687"
          />
        </defs>
        <clipPath id="GEOCACHING_SVGID_66_">
          <use xlinkHref="#GEOCACHING_SVGID_65_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#GEOCACHING_SVGID_66_)"
          fill="#72BF44"
          d="M55.507,61.07H6.179c-3.059,0-5.563-2.504-5.563-5.563V6.181
				c0-3.061,2.504-5.564,5.563-5.564h49.328c3.06,0,5.563,2.504,5.563,5.564v49.327C61.069,58.566,58.566,61.07,55.507,61.07"
        />
      </g>
      <g>
        <defs>
          <path
            id="GEOCACHING_SVGID_67_"
            d="M5.676,0.001C2.588,0.001,0.054,2.5,0,5.576v50.535c0.053,3.043,2.533,5.521,5.576,5.576H56.11
					c3.076-0.055,5.576-2.589,5.576-5.678V5.677c0-3.122-2.554-5.676-5.677-5.676H5.676z"
          />
        </defs>
        <clipPath id="GEOCACHING_SVGID_68_">
          <use xlinkHref="#GEOCACHING_SVGID_67_" overflow="visible" />
        </clipPath>
        <polygon
          clipPath="url(#GEOCACHING_SVGID_68_)"
          fill="#008B4A"
          points="22.258,43.804 68.874,60.414 82.538,28.094 38.996,14.544 			"
        />
      </g>
      <g>
        <defs>
          <rect
            id="GEOCACHING_SVGID_69_"
            x="0"
            y="0.001"
            width="74.906"
            height="61.687"
          />
        </defs>
        <clipPath id="GEOCACHING_SVGID_70_">
          <use xlinkHref="#GEOCACHING_SVGID_69_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#FFFFFF"
          d="M17.871,8.789c0.018,0.143,0.024,0.262,0.024,0.363
				c0,1.206-0.862,2.075-2.064,2.075c-1.207,0-2.147-0.91-2.147-2.082c0-1.165,0.935-2.08,2.13-2.08
				c0.885,0,1.665,0.416,1.944,1.194l-0.945,0.274c-0.232-0.453-0.613-0.572-0.999-0.572c-0.649,0-1.142,0.513-1.142,1.184
				c0,0.679,0.505,1.189,1.172,1.189c0.523,0,0.903-0.272,1.052-0.714h-1.219V8.789H17.871z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#FFFFFF"
          d="M21.374,9.889h-2.188c0.077,0.357,0.321,0.577,0.642,0.577
				c0.22,0,0.476-0.053,0.637-0.345l0.844,0.173c-0.256,0.63-0.797,0.934-1.481,0.934c-0.862,0-1.575-0.637-1.575-1.583
				c0-0.938,0.713-1.581,1.581-1.581c0.845,0,1.522,0.606,1.54,1.581V9.889z M19.204,9.307h1.231c-0.089-0.32-0.327-0.47-0.601-0.47
				C19.567,8.837,19.293,9.003,19.204,9.307"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#FFFFFF"
          d="M24.929,9.646c0,0.946-0.75,1.576-1.636,1.576
				c-0.903,0-1.653-0.63-1.653-1.576c0-0.939,0.75-1.57,1.653-1.57C24.179,8.075,24.929,8.706,24.929,9.646 M24.014,9.646
				c0-0.464-0.351-0.773-0.72-0.773c-0.386,0-0.737,0.31-0.737,0.773c0,0.47,0.351,0.772,0.737,0.772
				C23.663,10.418,24.014,10.115,24.014,9.646"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#FFFFFF"
          d="M27.476,9.871l0.874,0.19c-0.149,0.707-0.779,1.166-1.522,1.166
				c-0.892,0-1.63-0.636-1.63-1.582c0-0.939,0.738-1.582,1.63-1.582c0.725,0,1.356,0.446,1.522,1.148l-0.892,0.214
				c-0.083-0.363-0.327-0.529-0.63-0.529c-0.428,0-0.714,0.315-0.714,0.749c0,0.44,0.286,0.749,0.714,0.749
				C27.131,10.395,27.375,10.233,27.476,9.871"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#FFFFFF"
          d="M31.94,11.138h-0.951v-0.238c-0.232,0.209-0.541,0.328-0.927,0.328
				c-0.791,0-1.457-0.636-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.386,0,0.695,0.12,0.927,0.328V8.152h0.951V11.138z
				 M30.989,9.646c0-0.458-0.345-0.773-0.732-0.773c-0.415,0-0.719,0.315-0.719,0.773c0,0.464,0.304,0.772,0.719,0.772
				C30.644,10.418,30.989,10.109,30.989,9.646"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#FFFFFF"
          d="M34.668,9.871l0.875,0.19c-0.149,0.707-0.779,1.166-1.522,1.166
				c-0.892,0-1.63-0.636-1.63-1.582c0-0.939,0.738-1.582,1.63-1.582c0.726,0,1.356,0.446,1.522,1.148L34.65,9.426
				c-0.083-0.363-0.326-0.529-0.63-0.529c-0.428,0-0.714,0.315-0.714,0.749c0,0.44,0.286,0.749,0.714,0.749
				C34.324,10.395,34.567,10.233,34.668,9.871"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#FFFFFF"
          d="M39.01,9.307v1.831h-0.946V9.509c0-0.351-0.208-0.589-0.518-0.589
				c-0.368,0-0.612,0.256-0.612,0.833v1.385h-0.951V6.856h0.951v1.582c0.231-0.238,0.541-0.375,0.922-0.375
				C38.546,8.063,39.01,8.558,39.01,9.307"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#FFFFFF"
          d="M40.667,7.273c0,0.303-0.231,0.528-0.553,0.528s-0.547-0.226-0.547-0.528
				c0-0.292,0.226-0.541,0.547-0.541S40.667,6.981,40.667,7.273 M39.639,8.153h0.951v2.984h-0.951V8.153z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#FFFFFF"
          d="M44.272,9.307v1.831h-0.946V9.509c0-0.351-0.208-0.589-0.512-0.589
				c-0.374,0-0.618,0.256-0.618,0.833v1.385h-0.951V8.152h0.951v0.286c0.227-0.238,0.542-0.375,0.916-0.375
				C43.808,8.063,44.272,8.558,44.272,9.307"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#FFFFFF"
          d="M47.954,10.841c0,0.922-0.702,1.427-1.672,1.427
				c-0.684,0-1.207-0.238-1.498-0.719l0.791-0.43c0.226,0.27,0.393,0.37,0.701,0.37c0.435,0,0.744-0.257,0.744-0.726V10.68
				c-0.215,0.185-0.512,0.286-0.898,0.286c-0.779,0-1.439-0.618-1.439-1.463c0-0.826,0.66-1.439,1.439-1.439
				c0.387,0,0.684,0.103,0.898,0.286V8.152h0.934V10.841z M47.021,9.503c0-0.398-0.314-0.679-0.697-0.679
				c-0.416,0-0.713,0.28-0.713,0.679c0,0.397,0.297,0.683,0.713,0.683C46.706,10.186,47.021,9.9,47.021,9.503"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#9CCE78"
          d="M56.009,4.406c0.689,0,1.271,0.582,1.271,1.271V56.01
				c0,0.689-0.581,1.271-1.271,1.271H5.676c-0.689,0-1.271-0.582-1.271-1.271V5.677c0-0.688,0.582-1.271,1.271-1.271H56.009
				 M56.009,0H5.676C2.554,0,0,2.555,0,5.677V56.01c0,3.122,2.555,5.678,5.677,5.678h50.333c3.123,0,5.678-2.556,5.678-5.678V5.677
				C61.687,2.555,59.132,0,56.009,0"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          d="M38.174,44.101H23.336c-1.124,0-2.035-0.91-2.035-2.034V16.094
				c0-1.124,0.911-2.034,2.035-2.034h14.837c1.122,0,2.034,0.91,2.034,2.034v25.973C40.208,43.19,39.296,44.101,38.174,44.101"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="#F5DB8B"
          d="M37.974,42.205H23.537c-0.55,0-0.995-0.445-0.995-0.995V17.794
				c0-0.55,0.445-0.995,0.995-0.995h14.437c0.549,0,0.994,0.445,0.994,0.995V41.21C38.968,41.76,38.522,42.205,37.974,42.205"
        />

        <line
          clipPath="url(#GEOCACHING_SVGID_70_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.518"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="29.527"
          y1="15.455"
          x2="32.184"
          y2="15.455"
        />
      </g>
      <g>
        <defs>
          <path
            id="GEOCACHING_SVGID_71_"
            d="M23.529,16.799c-0.545,0-0.987,0.441-0.987,0.986V41.22c0,0.543,0.442,0.984,0.987,0.984h14.455
					c0.543,0,0.984-0.441,0.984-0.984V17.785c0-0.545-0.441-0.986-0.984-0.986H23.529z"
          />
        </defs>
        <clipPath id="GEOCACHING_SVGID_72_">
          <use xlinkHref="#GEOCACHING_SVGID_71_" overflow="visible" />
        </clipPath>
        <line
          clipPath="url(#GEOCACHING_SVGID_72_)"
          fill="#F5DB8B"
          x1="43.617"
          y1="29.377"
          x2="18.232"
          y2="29.377"
        />

        <line
          clipPath="url(#GEOCACHING_SVGID_72_)"
          fill="none"
          stroke="#FFDD00"
          strokeWidth="7.175"
          strokeMiterlimit="10"
          x1="43.617"
          y1="29.377"
          x2="18.232"
          y2="29.377"
        />
        <line
          clipPath="url(#GEOCACHING_SVGID_72_)"
          fill="#F5DB8B"
          x1="30.924"
          y1="42.205"
          x2="30.924"
          y2="16.684"
        />

        <line
          clipPath="url(#GEOCACHING_SVGID_72_)"
          fill="none"
          stroke="#FFDD00"
          strokeWidth="7.175"
          strokeMiterlimit="10"
          x1="30.924"
          y1="42.205"
          x2="30.924"
          y2="16.684"
        />
      </g>
      <g>
        <defs>
          <rect
            id="GEOCACHING_SVGID_73_"
            x="0"
            y="0.001"
            width="74.906"
            height="61.687"
          />
        </defs>
        <clipPath id="GEOCACHING_SVGID_74_">
          <use xlinkHref="#GEOCACHING_SVGID_73_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#5087C7"
          d="M30.774,36.1c-3.745,0-6.791-3.047-6.791-6.791s3.046-6.79,6.791-6.79
				c3.746,0,6.79,3.046,6.79,6.79S34.52,36.1,30.774,36.1"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M30.774,22.88c3.551,0,6.43,2.878,6.43,6.429c0,3.552-2.879,6.43-6.43,6.43
				c-3.551,0-6.43-2.878-6.43-6.43C24.344,25.758,27.223,22.88,30.774,22.88 M30.774,22.157c-3.944,0-7.151,3.208-7.151,7.151
				s3.207,7.152,7.151,7.152c3.943,0,7.152-3.209,7.152-7.152S34.717,22.157,30.774,22.157"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#ED1C24"
          d="M29.542,27.988l5.218-2.831c0.126-0.069,0.265,0.069,0.195,0.197
				l-2.83,5.217"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M32.125,30.571c-0.014,0.024-0.034,0.045-0.059,0.059l-5.217,2.831
				c-0.127,0.068-0.265-0.07-0.197-0.197l2.831-5.217c0.013-0.025,0.034-0.045,0.059-0.059"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M32.028,30.533c-0.676,0.676-1.772,0.676-2.448,0
				c-0.676-0.677-0.676-1.772,0-2.448c0.676-0.677,1.772-0.677,2.448,0C32.704,28.761,32.704,29.856,32.028,30.533"
        />

        <circle
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="none"
          stroke="#5087C7"
          strokeWidth="0.722"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          cx="30.804"
          cy="29.309"
          r="1.731"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#5087C7"
          d="M30.854,55.36c-2.638,0-4.773-2.135-4.773-4.771s2.135-4.773,4.773-4.773
				s4.774,2.137,4.774,4.773S33.492,55.36,30.854,55.36"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M30.854,45.816c2.638,0,4.774,2.136,4.774,4.771
				c0,2.638-2.136,4.773-4.774,4.773s-4.773-2.136-4.773-4.773C26.081,47.952,28.216,45.816,30.854,45.816 M30.854,45.404
				c-2.859,0-5.185,2.325-5.185,5.184c0,2.859,2.326,5.185,5.185,5.185c2.86,0,5.186-2.325,5.186-5.185
				C36.04,47.729,33.714,45.404,30.854,45.404"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M33.348,48.644c-0.238,0-0.465,0.107-0.619,0.251l-0.014-0.012
				c0.072-0.12,0.168-0.346,0.168-0.716c0-0.883-0.884-1.552-2.029-1.552s-2.041,0.669-2.041,1.552c0,0.37,0.12,0.596,0.179,0.716
				l-0.012,0.012c-0.155-0.144-0.382-0.251-0.619-0.251c-0.73,0-1.457,0.703-1.457,2.018c0,1.288,0.716,2.016,1.492,2.016
				c0.716,0,1.217-0.396,1.67-0.847c-0.071-0.108-0.179-0.217-0.298-0.217s-0.251,0.086-0.477,0.265
				c-0.299,0.227-0.561,0.357-0.908,0.357c-0.608,0-0.87-0.682-0.87-1.574c0-0.955,0.25-1.587,0.895-1.587
				c0.286,0,0.476,0.082,0.668,0.262c0.286,0.263,0.382,0.334,0.513,0.334c0.096,0,0.155-0.061,0.179-0.095
				c-0.214-0.216-0.525-0.669-0.525-1.254c0-0.668,0.561-1.134,1.611-1.134s1.611,0.466,1.611,1.134
				c0,0.585-0.311,1.038-0.525,1.254c0.024,0.034,0.083,0.095,0.179,0.095c0.132,0,0.227-0.071,0.513-0.334
				c0.179-0.18,0.382-0.262,0.668-0.262c0.644,0,0.896,0.632,0.896,1.587c0,0.893-0.264,1.574-0.871,1.574
				c-0.346,0-0.62-0.131-0.907-0.357c-0.228-0.179-0.358-0.265-0.477-0.265c-0.119,0-0.226,0.108-0.299,0.217
				c0.442,0.451,0.955,0.847,1.671,0.847c0.775,0,1.492-0.728,1.492-2.016C34.805,49.347,34.075,48.644,33.348,48.644"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M31.653,48.62h-0.537c-0.035,0-0.059-0.024-0.071-0.048l-0.168-0.514
				c-0.011-0.035-0.023-0.035-0.035,0l-0.167,0.514c-0.013,0.035-0.036,0.048-0.06,0.048h-0.537c-0.024,0-0.036,0.011-0.012,0.023
				l0.442,0.322c0.023,0.023,0.023,0.061,0.011,0.083l-0.167,0.501c-0.011,0.024-0.011,0.049,0.025,0.024l0.441-0.31
				c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.023,0.024,0.036,0.012,0.023-0.012L31.2,49.049c-0.012-0.035,0-0.071,0.024-0.083
				l0.44-0.322C31.689,48.631,31.677,48.62,31.653,48.62"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M31.248,52.151c-0.226-0.573-0.406-0.966-0.406-1.443
				c0-0.204,0.06-0.382,0.096-0.477c0.048-0.097,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.167-0.322,0.312
				c-0.072,0.117-0.155,0.346-0.155,0.523c0,0.335,0.108,0.692,0.179,0.907c0.132,0.37,0.251,0.799,0.251,1.206
				c0,0.645-0.287,1.084-0.799,1.478c0.047,0.037,0.166,0.097,0.31,0.097c0.5,0,1.097-0.562,1.097-1.324
				C31.51,52.819,31.379,52.509,31.248,52.151"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M-2.473-25.894c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081s-0.123,0.027-0.193,0.027h-0.16
				v0.59h-0.298v-1.479H-2.473z M-2.563-25.225c0.059,0,0.107-0.018,0.144-0.054s0.055-0.092,0.055-0.168
				c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.449H-2.563z"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          points="-1.469,-25.894 -1.258,-24.849 -1.253,-24.849 -1.04,-25.894
				-0.599,-25.894 -0.599,-24.415 -0.872,-24.415 -0.872,-25.596 -0.876,-25.596 -1.139,-24.415 -1.372,-24.415 -1.635,-25.596
				-1.639,-25.596 -1.639,-24.415 -1.913,-24.415 -1.913,-25.894 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M-0.145-24.833c0,0.035,0.003,0.066,0.008,0.096
				c0.005,0.027,0.016,0.051,0.031,0.07c0.015,0.02,0.036,0.035,0.062,0.046c0.025,0.012,0.057,0.017,0.096,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.013-0.087
				c-0.009-0.023-0.024-0.047-0.045-0.065c-0.021-0.02-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.047-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.047-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
				c0.067,0,0.128,0.007,0.184,0.022c0.056,0.014,0.104,0.038,0.145,0.07s0.072,0.073,0.095,0.123
				c0.023,0.052,0.034,0.111,0.034,0.183v0.041H0.208c0-0.07-0.012-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.015c-0.023,0.008-0.04,0.021-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114s0.06,0.06,0.124,0.087l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.026,0.064,0.056,0.086,0.087C0.505-25.034,0.521-25,0.529-24.964
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.289-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062h0.298V-24.833z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M1.446-24.646c0.021,0.045,0.065,0.066,0.13,0.066
				c0.035,0,0.064-0.006,0.086-0.018c0.021-0.014,0.037-0.03,0.049-0.051c0.012-0.021,0.019-0.048,0.023-0.076
				c0.003-0.029,0.005-0.061,0.005-0.091c0-0.034-0.002-0.065-0.007-0.095c-0.005-0.029-0.014-0.057-0.028-0.08
				c-0.014-0.022-0.033-0.042-0.058-0.057c-0.025-0.013-0.058-0.02-0.099-0.02h-0.1v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.021
				c0.021-0.013,0.039-0.029,0.052-0.051c0.013-0.021,0.023-0.046,0.029-0.074c0.006-0.027,0.009-0.057,0.009-0.087
				c0-0.07-0.011-0.12-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.005-0.077,0.017
				c-0.021,0.012-0.036,0.028-0.047,0.049c-0.011,0.022-0.019,0.048-0.022,0.077c-0.003,0.029-0.005,0.061-0.005,0.094H1.147
				c0-0.146,0.036-0.254,0.109-0.324c0.073-0.071,0.181-0.108,0.322-0.108c0.133,0,0.237,0.031,0.312,0.092
				C1.964-25.729,2-25.634,2-25.504c0,0.089-0.019,0.161-0.059,0.217c-0.039,0.056-0.094,0.091-0.164,0.106v0.004
				c0.095,0.016,0.162,0.054,0.202,0.115C2.018-25,2.038-24.923,2.038-24.83c0,0.049-0.006,0.101-0.019,0.152
				c-0.012,0.053-0.037,0.101-0.072,0.144c-0.037,0.043-0.086,0.077-0.149,0.104c-0.062,0.025-0.145,0.039-0.247,0.039
				c-0.143,0-0.249-0.039-0.32-0.116c-0.07-0.077-0.105-0.188-0.105-0.33v-0.006h0.286C1.413-24.757,1.424-24.691,1.446-24.646"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M2.693-25.641c-0.019-0.031-0.055-0.046-0.104-0.046
				c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.019-0.043,0.045-0.056,0.077c-0.013,0.033-0.021,0.073-0.025,0.118
				c-0.005,0.046-0.007,0.096-0.007,0.149v0.073H2.41c0.025-0.039,0.061-0.069,0.107-0.093c0.047-0.022,0.103-0.034,0.169-0.034
				c0.065,0,0.12,0.012,0.166,0.034c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.089,0.062,0.145
				c0.013,0.056,0.02,0.117,0.02,0.185c0,0.169-0.038,0.298-0.113,0.386c-0.075,0.088-0.196,0.132-0.362,0.132
				c-0.102,0-0.184-0.018-0.245-0.054c-0.06-0.036-0.107-0.086-0.139-0.148c-0.033-0.063-0.054-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.205,0.009-0.303c0.006-0.097,0.025-0.184,0.056-0.259
				c0.031-0.075,0.08-0.135,0.146-0.181c0.067-0.046,0.161-0.069,0.282-0.069c0.052,0,0.104,0.007,0.152,0.02
				c0.05,0.013,0.093,0.032,0.13,0.062s0.067,0.068,0.09,0.115c0.023,0.048,0.035,0.106,0.036,0.178H2.723
				C2.723-25.565,2.714-25.61,2.693-25.641 M2.427-24.754c0.004,0.036,0.012,0.066,0.024,0.092c0.012,0.026,0.028,0.047,0.05,0.061
				c0.021,0.014,0.049,0.021,0.084,0.021c0.036,0,0.064-0.008,0.086-0.021c0.021-0.014,0.037-0.034,0.048-0.062
				c0.011-0.025,0.019-0.057,0.022-0.093c0.004-0.034,0.005-0.073,0.005-0.114c0-0.1-0.012-0.171-0.037-0.214
				c-0.025-0.043-0.066-0.063-0.124-0.063c-0.06,0-0.102,0.021-0.127,0.063c-0.025,0.043-0.037,0.114-0.037,0.214
				C2.421-24.829,2.423-24.79,2.427-24.754"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M3.165-25.067c0.042-0.065,0.104-0.106,0.187-0.124v-0.004
				c-0.04-0.01-0.074-0.024-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.069c-0.016-0.027-0.028-0.055-0.035-0.083
				c-0.007-0.029-0.01-0.058-0.01-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.311c0,0.025-0.004,0.054-0.012,0.083
				c-0.007,0.028-0.019,0.056-0.036,0.083c-0.016,0.025-0.038,0.049-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.018,0.145,0.059,0.187,0.124C4.019-25,4.04-24.921,4.04-24.828c0,0.043-0.005,0.09-0.015,0.141
				c-0.011,0.052-0.033,0.099-0.066,0.143c-0.033,0.045-0.08,0.081-0.142,0.111c-0.061,0.027-0.143,0.043-0.245,0.043
				c-0.102,0-0.184-0.016-0.246-0.043c-0.062-0.03-0.109-0.066-0.142-0.111c-0.033-0.044-0.055-0.091-0.065-0.143
				c-0.011-0.051-0.016-0.098-0.016-0.141C3.103-24.921,3.124-25,3.165-25.067 M3.409-24.734c0.005,0.031,0.014,0.059,0.027,0.081
				c0.013,0.022,0.031,0.041,0.053,0.055c0.022,0.013,0.05,0.019,0.083,0.019c0.033,0,0.06-0.006,0.083-0.019
				c0.022-0.014,0.04-0.032,0.052-0.055c0.013-0.022,0.023-0.05,0.027-0.081c0.005-0.03,0.008-0.063,0.008-0.099
				s-0.003-0.069-0.008-0.099c-0.004-0.031-0.014-0.058-0.027-0.08c-0.012-0.023-0.03-0.041-0.052-0.055
				c-0.023-0.013-0.05-0.019-0.083-0.019c-0.033,0-0.061,0.006-0.083,0.019c-0.022,0.014-0.04,0.031-0.053,0.055
				c-0.013,0.022-0.022,0.049-0.027,0.08c-0.005,0.029-0.007,0.063-0.007,0.099S3.403-24.765,3.409-24.734 M3.465-25.33
				c0.026,0.033,0.062,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155
				c0-0.135-0.048-0.2-0.145-0.2c-0.097,0-0.145,0.065-0.145,0.2C3.427-25.415,3.439-25.363,3.465-25.33"
        />
        <rect
          x="6.573"
          y="-26.393"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#008B4A"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M7.486-25.894c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081s-0.123,0.027-0.193,0.027h-0.16
				v0.59H6.964v-1.479H7.486z M7.395-25.225c0.059,0,0.107-0.018,0.144-0.054s0.055-0.092,0.055-0.168
				c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058H7.262v0.449H7.395z"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          points="8.49,-25.894 8.701,-24.849 8.706,-24.849 8.918,-25.894
				9.359,-25.894 9.359,-24.415 9.086,-24.415 9.086,-25.596 9.083,-25.596 8.819,-24.415 8.586,-24.415 8.324,-25.596
				8.319,-25.596 8.319,-24.415 8.045,-24.415 8.045,-25.894 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M9.813-24.833c0,0.035,0.003,0.066,0.009,0.096
				c0.004,0.027,0.015,0.051,0.03,0.07c0.015,0.02,0.036,0.035,0.062,0.046c0.025,0.012,0.058,0.017,0.096,0.017
				c0.045,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.063-0.014-0.087
				c-0.009-0.023-0.024-0.047-0.045-0.065c-0.021-0.02-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.047-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.047-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.022c0.056,0.014,0.104,0.038,0.145,0.07s0.072,0.073,0.095,0.123
				c0.023,0.052,0.034,0.111,0.034,0.183v0.041h-0.285c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.015c-0.023,0.008-0.04,0.021-0.053,0.037s-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114s0.061,0.06,0.124,0.087l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.026,0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.168,0-0.289-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062h0.298V-24.833z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M11.404-24.646c0.021,0.045,0.065,0.066,0.13,0.066
				c0.035,0,0.064-0.006,0.086-0.018c0.021-0.014,0.037-0.03,0.049-0.051c0.012-0.021,0.019-0.048,0.023-0.076
				c0.003-0.029,0.005-0.061,0.005-0.091c0-0.034-0.002-0.065-0.007-0.095s-0.015-0.057-0.028-0.08
				c-0.013-0.022-0.033-0.042-0.058-0.057c-0.025-0.013-0.058-0.02-0.1-0.02h-0.099v-0.195h0.097c0.032,0,0.059-0.006,0.08-0.021
				c0.022-0.013,0.039-0.029,0.052-0.051c0.013-0.021,0.023-0.046,0.029-0.074c0.006-0.027,0.009-0.057,0.009-0.087
				c0-0.07-0.012-0.12-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.031,0-0.057,0.005-0.077,0.017
				s-0.036,0.028-0.047,0.049c-0.011,0.022-0.018,0.048-0.022,0.077c-0.003,0.029-0.005,0.061-0.005,0.094h-0.273
				c0-0.146,0.036-0.254,0.109-0.324c0.073-0.071,0.181-0.108,0.322-0.108c0.134,0,0.237,0.031,0.312,0.092
				c0.074,0.062,0.11,0.156,0.11,0.286c0,0.089-0.019,0.161-0.059,0.217c-0.039,0.056-0.094,0.091-0.164,0.106v0.004
				c0.095,0.016,0.162,0.054,0.202,0.115c0.039,0.062,0.059,0.139,0.059,0.231c0,0.049-0.007,0.101-0.019,0.152
				c-0.012,0.053-0.037,0.101-0.072,0.144c-0.037,0.043-0.085,0.077-0.149,0.104c-0.062,0.025-0.145,0.039-0.247,0.039
				c-0.142,0-0.249-0.039-0.32-0.116c-0.07-0.077-0.105-0.188-0.105-0.33v-0.006h0.286C11.372-24.757,11.383-24.691,11.404-24.646"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M12.553-25.882h0.309v0.932h0.142v0.231h-0.142v0.305h-0.273v-0.305h-0.522
				v-0.244L12.553-25.882z M12.585-25.508l-0.287,0.558h0.291v-0.558H12.585z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M13.123-25.067c0.042-0.065,0.104-0.106,0.187-0.124v-0.004
				c-0.04-0.01-0.073-0.024-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.069c-0.016-0.027-0.028-0.055-0.034-0.083
				c-0.008-0.029-0.011-0.058-0.011-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.182-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.311c0,0.025-0.004,0.054-0.011,0.083
				c-0.008,0.028-0.02,0.056-0.037,0.083c-0.016,0.025-0.038,0.049-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.018,0.145,0.059,0.187,0.124c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.005,0.09-0.015,0.141
				c-0.01,0.052-0.032,0.099-0.066,0.143c-0.032,0.045-0.08,0.081-0.141,0.111c-0.062,0.027-0.144,0.043-0.246,0.043
				c-0.102,0-0.184-0.016-0.246-0.043c-0.062-0.03-0.108-0.066-0.142-0.111c-0.032-0.044-0.054-0.091-0.065-0.143
				c-0.01-0.051-0.015-0.098-0.015-0.141C13.062-24.921,13.083-25,13.123-25.067 M13.368-24.734
				c0.004,0.031,0.013,0.059,0.026,0.081s0.031,0.041,0.053,0.055c0.022,0.013,0.05,0.019,0.083,0.019
				c0.033,0,0.06-0.006,0.083-0.019c0.022-0.014,0.04-0.032,0.053-0.055c0.012-0.022,0.022-0.05,0.027-0.081
				c0.004-0.03,0.007-0.063,0.007-0.099s-0.003-0.069-0.007-0.099c-0.005-0.031-0.015-0.058-0.027-0.08
				c-0.013-0.023-0.031-0.041-0.053-0.055c-0.023-0.013-0.05-0.019-0.083-0.019c-0.033,0-0.061,0.006-0.083,0.019
				c-0.022,0.014-0.04,0.031-0.053,0.055c-0.013,0.022-0.022,0.049-0.026,0.08c-0.006,0.029-0.008,0.063-0.008,0.099
				S13.362-24.765,13.368-24.734 M13.424-25.33c0.026,0.033,0.062,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05
				c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.2-0.145-0.2c-0.097,0-0.145,0.065-0.145,0.2
				C13.385-25.415,13.398-25.363,13.424-25.33"
        />
        <rect
          x="16.984"
          y="-26.393"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFDD00"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M17.898-25.894c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199s-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16
				v0.59h-0.298v-1.479H17.898z M17.808-25.225c0.059,0,0.107-0.018,0.144-0.054s0.055-0.092,0.055-0.168
				c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.449H17.808z"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          points="18.902,-25.894 19.113,-24.849 19.118,-24.849 19.331,-25.894
				19.772,-25.894 19.772,-24.415 19.499,-24.415 19.499,-25.596 19.495,-25.596 19.232,-24.415 18.999,-24.415 18.736,-25.596
				18.732,-25.596 18.732,-24.415 18.458,-24.415 18.458,-25.894 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M20.226-24.833c0,0.035,0.003,0.066,0.008,0.096
				c0.005,0.027,0.016,0.051,0.031,0.07c0.015,0.02,0.036,0.035,0.061,0.046c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.013-0.087
				c-0.009-0.023-0.024-0.047-0.045-0.065c-0.021-0.02-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.047-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.047-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
				c0.067,0,0.128,0.007,0.184,0.022c0.056,0.014,0.104,0.038,0.145,0.07s0.072,0.073,0.095,0.123
				c0.023,0.052,0.034,0.111,0.034,0.183v0.041h-0.286c0-0.07-0.012-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.015c-0.023,0.008-0.04,0.021-0.053,0.037s-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114s0.06,0.06,0.124,0.087l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.026,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.289-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062h0.298V-24.833z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M21.833-25.45h-0.292v-0.194h0.027c0.099,0,0.177-0.019,0.235-0.056
				c0.057-0.038,0.094-0.099,0.109-0.182h0.219v1.468h-0.298V-25.45z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M22.498-25.392c0.006-0.094,0.025-0.178,0.056-0.25
				c0.03-0.073,0.076-0.13,0.137-0.174c0.062-0.043,0.147-0.066,0.256-0.066c0.09,0,0.165,0.013,0.223,0.039
				c0.059,0.026,0.106,0.067,0.141,0.123c0.035,0.057,0.06,0.131,0.075,0.221c0.014,0.09,0.022,0.201,0.022,0.331
				c0,0.103-0.004,0.2-0.011,0.294c-0.007,0.095-0.025,0.178-0.056,0.25c-0.03,0.072-0.076,0.129-0.138,0.17
				c-0.061,0.042-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.022-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.06-0.13-0.075-0.221c-0.014-0.09-0.022-0.2-0.022-0.33C22.487-25.199,22.491-25.298,22.498-25.392
				 M22.793-24.807c0.005,0.059,0.013,0.104,0.026,0.139c0.012,0.034,0.029,0.058,0.05,0.069c0.02,0.013,0.046,0.019,0.078,0.019
				c0.034,0,0.061-0.008,0.081-0.022c0.021-0.018,0.037-0.043,0.049-0.082c0.011-0.038,0.02-0.089,0.025-0.152
				c0.004-0.063,0.007-0.142,0.007-0.235v-0.16c0-0.092-0.003-0.168-0.007-0.228c-0.005-0.058-0.014-0.104-0.026-0.138
				c-0.013-0.034-0.029-0.059-0.05-0.07c-0.021-0.013-0.047-0.018-0.079-0.018c-0.033,0-0.06,0.008-0.08,0.022
				c-0.021,0.017-0.038,0.044-0.049,0.081c-0.012,0.038-0.02,0.089-0.025,0.153c-0.005,0.063-0.007,0.141-0.007,0.235v0.16
				C22.786-24.94,22.788-24.865,22.793-24.807"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M23.826-24.627c0.019,0.032,0.054,0.047,0.104,0.047
				c0.04,0,0.072-0.009,0.096-0.027c0.023-0.02,0.042-0.044,0.054-0.078c0.013-0.033,0.022-0.073,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.069h-0.005c-0.024,0.037-0.06,0.066-0.106,0.089c-0.046,0.023-0.103,0.035-0.169,0.035
				s-0.12-0.012-0.166-0.035c-0.046-0.022-0.083-0.054-0.111-0.095c-0.028-0.04-0.049-0.09-0.062-0.145
				c-0.013-0.057-0.02-0.118-0.02-0.184c0-0.17,0.038-0.3,0.113-0.391c0.075-0.089,0.196-0.135,0.362-0.135
				c0.102,0,0.184,0.02,0.244,0.058c0.061,0.037,0.108,0.089,0.14,0.151c0.033,0.063,0.054,0.135,0.064,0.219
				c0.009,0.082,0.014,0.169,0.014,0.26c0,0.104-0.003,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.056,0.258
				c-0.031,0.075-0.081,0.133-0.147,0.177c-0.067,0.043-0.161,0.066-0.281,0.066c-0.053,0-0.104-0.005-0.153-0.016
				s-0.092-0.03-0.129-0.059s-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.106-0.037-0.178h0.286
				C23.795-24.701,23.805-24.658,23.826-24.627 M23.81-25.183c0.025,0.043,0.066,0.063,0.124,0.063c0.059,0,0.102-0.021,0.127-0.063
				s0.037-0.114,0.037-0.213c0-0.042-0.002-0.081-0.006-0.117s-0.012-0.066-0.024-0.091c-0.012-0.026-0.028-0.046-0.05-0.062
				c-0.021-0.014-0.049-0.021-0.084-0.021c-0.036,0-0.064,0.008-0.086,0.021c-0.021,0.016-0.037,0.035-0.048,0.062
				c-0.012,0.026-0.019,0.057-0.022,0.093c-0.004,0.035-0.005,0.073-0.005,0.115C23.772-25.297,23.785-25.226,23.81-25.183"
        />
        <rect
          x="26.854"
          y="-26.393"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#F5DB8B"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M27.768-25.894c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H27.768z M27.677-25.225c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.449H27.677z"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          points="28.771,-25.894 28.982,-24.849 28.987,-24.849 29.2,-25.894
				29.642,-25.894 29.642,-24.415 29.368,-24.415 29.368,-25.596 29.364,-25.596 29.101,-24.415 28.868,-24.415 28.606,-25.596
				28.602,-25.596 28.602,-24.415 28.328,-24.415 28.328,-25.894 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M30.096-24.832c0,0.034,0.003,0.065,0.008,0.095
				c0.005,0.027,0.016,0.051,0.031,0.071c0.015,0.019,0.036,0.034,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.037-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.008-0.024-0.024-0.048-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.047-0.172-0.073c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.184,0.022c0.056,0.014,0.104,0.038,0.144,0.07c0.042,0.032,0.073,0.073,0.096,0.123
				c0.023,0.052,0.034,0.111,0.034,0.183v0.041h-0.286c0-0.07-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.015c-0.022,0.008-0.04,0.021-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114s0.06,0.06,0.124,0.087l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.026,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.065,0.045,0.102
				c0.007,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.106-0.378,0.106
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062h0.298V-24.832z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M31.702-25.45H31.41v-0.194h0.027c0.1,0,0.177-0.019,0.235-0.056
				c0.057-0.038,0.094-0.099,0.109-0.182H32v1.468h-0.298V-25.45z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M32.357-24.414c0-0.065,0.006-0.125,0.02-0.182
				c0.013-0.058,0.033-0.11,0.06-0.162c0.027-0.052,0.06-0.102,0.103-0.15c0.039-0.05,0.088-0.099,0.141-0.148l0.121-0.111
				c0.033-0.029,0.061-0.057,0.08-0.081c0.02-0.025,0.035-0.049,0.046-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.002-0.028,0.004-0.061,0.004-0.099c0-0.121-0.047-0.183-0.145-0.183c-0.037,0-0.066,0.008-0.088,0.022
				s-0.038,0.036-0.05,0.063c-0.011,0.026-0.019,0.057-0.023,0.092c-0.003,0.033-0.004,0.07-0.004,0.109h-0.286v-0.057
				c0-0.136,0.036-0.241,0.109-0.314c0.072-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.104s0.108,0.167,0.108,0.296
				c0,0.047-0.005,0.091-0.013,0.132c-0.008,0.04-0.021,0.08-0.039,0.116c-0.019,0.038-0.041,0.074-0.071,0.11
				c-0.028,0.035-0.063,0.071-0.106,0.107l-0.164,0.154c-0.045,0.04-0.08,0.079-0.104,0.116c-0.024,0.039-0.041,0.071-0.048,0.099
				h0.554v0.232H32.357z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M33.363-25.392c0.006-0.094,0.024-0.178,0.056-0.25
				c0.03-0.073,0.075-0.13,0.137-0.174c0.062-0.043,0.147-0.066,0.256-0.066c0.091,0,0.164,0.013,0.224,0.039
				c0.059,0.026,0.105,0.067,0.141,0.123c0.035,0.057,0.061,0.131,0.074,0.221c0.015,0.09,0.022,0.201,0.022,0.331
				c0,0.103-0.004,0.2-0.011,0.294c-0.007,0.095-0.025,0.178-0.057,0.25c-0.029,0.072-0.076,0.129-0.137,0.17
				c-0.062,0.042-0.147,0.063-0.257,0.063c-0.088,0-0.161-0.012-0.221-0.036c-0.06-0.022-0.106-0.063-0.142-0.12
				c-0.037-0.056-0.062-0.13-0.076-0.221c-0.014-0.09-0.021-0.2-0.021-0.33C33.352-25.199,33.354-25.298,33.363-25.392
				 M33.658-24.807c0.004,0.059,0.013,0.104,0.025,0.139c0.012,0.034,0.028,0.058,0.051,0.069c0.02,0.013,0.045,0.019,0.077,0.019
				c0.033,0,0.06-0.008,0.081-0.022c0.02-0.018,0.037-0.043,0.049-0.082c0.012-0.038,0.021-0.089,0.025-0.152
				c0.004-0.063,0.007-0.142,0.007-0.235v-0.16c0-0.092-0.003-0.168-0.007-0.228c-0.005-0.058-0.014-0.104-0.025-0.138
				c-0.014-0.034-0.031-0.059-0.051-0.07c-0.021-0.013-0.047-0.018-0.079-0.018s-0.06,0.008-0.08,0.022
				c-0.021,0.017-0.038,0.044-0.049,0.081c-0.012,0.038-0.021,0.089-0.024,0.153c-0.006,0.063-0.008,0.141-0.008,0.235v0.16
				C33.65-24.94,33.652-24.865,33.658-24.807"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M34.648-25.62l-0.03,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.084
				c0.043-0.018,0.098-0.026,0.162-0.026c0.069,0,0.127,0.014,0.174,0.041c0.047,0.026,0.084,0.063,0.11,0.107
				c0.026,0.044,0.046,0.093,0.058,0.149c0.01,0.057,0.016,0.113,0.016,0.17c0,0.082-0.007,0.156-0.021,0.224
				c-0.013,0.065-0.037,0.122-0.072,0.171c-0.036,0.047-0.084,0.083-0.145,0.106c-0.061,0.025-0.138,0.037-0.232,0.037
				c-0.092,0-0.168-0.013-0.225-0.038c-0.057-0.024-0.101-0.058-0.132-0.098c-0.03-0.041-0.05-0.085-0.062-0.133
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.287c0,0.023,0.001,0.051,0.004,0.078c0.002,0.028,0.01,0.054,0.02,0.077
				c0.011,0.024,0.025,0.045,0.045,0.061s0.045,0.023,0.076,0.023c0.063,0,0.106-0.023,0.135-0.07
				c0.027-0.048,0.04-0.119,0.04-0.216c0-0.061-0.003-0.11-0.009-0.15s-0.015-0.072-0.027-0.095
				c-0.014-0.024-0.031-0.041-0.055-0.051c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.075,0.012-0.099,0.036
				c-0.022,0.024-0.036,0.058-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H34.648z"
        />
        <rect
          x="36.608"
          y="-26.393"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#ED1C24"
          width="8.812"
          height="8.813"
        />
        <rect
          x="46.475"
          y="-26.393"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#5087C7"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M47.39-25.894c0.076,0,0.141,0.013,0.191,0.038
				c0.053,0.025,0.095,0.06,0.125,0.101c0.033,0.042,0.056,0.089,0.07,0.142c0.012,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199s-0.056,0.103-0.095,0.139c-0.041,0.036-0.092,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.297v-1.479H47.39z M47.299-25.225c0.06,0,0.106-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.032-0.039-0.086-0.058-0.16-0.058h-0.125v0.449H47.299z"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          points="48.393,-25.894 48.604,-24.849 48.608,-24.849 48.821,-25.894
				49.264,-25.894 49.264,-24.415 48.989,-24.415 48.989,-25.596 48.985,-25.596 48.723,-24.415 48.489,-24.415 48.228,-25.596
				48.224,-25.596 48.224,-24.415 47.95,-24.415 47.95,-25.894 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M49.718-24.832c0,0.034,0.002,0.065,0.008,0.095
				c0.005,0.027,0.016,0.051,0.031,0.071c0.015,0.019,0.035,0.034,0.061,0.045c0.025,0.012,0.059,0.017,0.097,0.017
				c0.046,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.006-0.063-0.015-0.086
				c-0.008-0.024-0.024-0.048-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.074-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.047-0.172-0.073c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.022-0.101-0.022-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.128,0.007,0.184,0.022c0.057,0.014,0.104,0.038,0.145,0.07c0.041,0.032,0.072,0.073,0.096,0.123
				c0.023,0.052,0.034,0.111,0.034,0.183v0.041h-0.286c0-0.07-0.014-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.124-0.057
				c-0.032,0-0.062,0.004-0.083,0.015c-0.021,0.008-0.04,0.021-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.028,0.114c0.02,0.031,0.061,0.06,0.125,0.087l0.229,0.099
				c0.058,0.025,0.103,0.051,0.14,0.078c0.035,0.026,0.063,0.056,0.086,0.087c0.021,0.031,0.035,0.065,0.045,0.102
				c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.271-0.136,0.342c-0.09,0.07-0.215,0.106-0.377,0.106
				c-0.17,0-0.291-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062h0.299V-24.832z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M50.983-24.414c0-0.065,0.007-0.125,0.02-0.182
				c0.014-0.058,0.033-0.11,0.061-0.162s0.061-0.102,0.102-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.12-0.111
				c0.033-0.029,0.061-0.057,0.08-0.081c0.021-0.025,0.035-0.049,0.047-0.075c0.011-0.025,0.019-0.051,0.021-0.081
				c0.004-0.028,0.006-0.061,0.006-0.099c0-0.121-0.049-0.183-0.146-0.183c-0.037,0-0.067,0.008-0.088,0.022
				c-0.022,0.015-0.038,0.036-0.05,0.063s-0.02,0.057-0.023,0.092c-0.003,0.033-0.004,0.07-0.004,0.109h-0.287v-0.057
				c0-0.136,0.037-0.241,0.109-0.314c0.074-0.073,0.188-0.109,0.347-0.109c0.149,0,0.26,0.034,0.332,0.104
				c0.071,0.069,0.108,0.167,0.108,0.296c0,0.047-0.004,0.091-0.014,0.132c-0.008,0.04-0.021,0.08-0.039,0.116
				c-0.018,0.038-0.041,0.074-0.07,0.11c-0.028,0.035-0.064,0.071-0.106,0.107l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H50.983z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M52.891-25.624c-0.062,0.086-0.116,0.178-0.163,0.275
				c-0.047,0.099-0.089,0.199-0.126,0.302c-0.035,0.104-0.065,0.211-0.089,0.317c-0.023,0.107-0.041,0.212-0.053,0.315h-0.314
				c0.012-0.088,0.027-0.182,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.297c0.036-0.103,0.081-0.203,0.133-0.306
				c0.053-0.103,0.115-0.202,0.187-0.3h-0.617v-0.257h0.904V-25.624z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M53.313-25.45h-0.293v-0.194h0.027c0.1,0,0.178-0.019,0.234-0.056
				c0.058-0.038,0.095-0.099,0.109-0.182h0.219v1.468h-0.297V-25.45z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M54.021-25.066c0.042-0.066,0.104-0.107,0.187-0.125v-0.004
				c-0.039-0.01-0.074-0.024-0.1-0.046c-0.027-0.021-0.049-0.044-0.066-0.069c-0.016-0.027-0.027-0.055-0.035-0.083
				c-0.006-0.029-0.01-0.058-0.01-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.182-0.094,0.316-0.094
				c0.134,0,0.238,0.031,0.314,0.094c0.077,0.063,0.116,0.166,0.116,0.311c0,0.025-0.004,0.054-0.012,0.083
				c-0.007,0.028-0.019,0.056-0.036,0.083c-0.016,0.025-0.038,0.049-0.064,0.069c-0.027,0.021-0.061,0.036-0.1,0.046v0.004
				c0.083,0.018,0.145,0.059,0.188,0.125c0.041,0.066,0.062,0.146,0.062,0.238c0,0.043-0.005,0.09-0.015,0.141
				c-0.012,0.052-0.033,0.099-0.066,0.143c-0.033,0.045-0.08,0.081-0.143,0.111c-0.061,0.027-0.143,0.043-0.244,0.043
				c-0.103,0-0.184-0.016-0.246-0.043c-0.063-0.03-0.109-0.066-0.143-0.111c-0.033-0.044-0.055-0.091-0.064-0.143
				c-0.01-0.051-0.016-0.098-0.016-0.141C53.96-24.921,53.98-25,54.021-25.066 M54.266-24.734c0.005,0.031,0.014,0.059,0.026,0.081
				c0.014,0.022,0.031,0.041,0.053,0.055c0.022,0.013,0.051,0.019,0.084,0.019s0.06-0.006,0.082-0.019
				c0.021-0.014,0.039-0.032,0.053-0.055c0.013-0.022,0.023-0.05,0.027-0.081c0.004-0.03,0.008-0.063,0.008-0.099
				s-0.004-0.069-0.008-0.099c-0.004-0.031-0.015-0.058-0.027-0.08c-0.014-0.023-0.031-0.041-0.053-0.055
				c-0.022-0.013-0.049-0.019-0.082-0.019s-0.062,0.006-0.084,0.019c-0.021,0.014-0.039,0.031-0.053,0.055
				c-0.013,0.022-0.021,0.049-0.026,0.08c-0.005,0.029-0.007,0.063-0.007,0.099S54.261-24.765,54.266-24.734 M54.322-25.33
				c0.026,0.033,0.062,0.05,0.106,0.05c0.044,0,0.078-0.017,0.104-0.05c0.027-0.033,0.04-0.085,0.04-0.155
				c0-0.135-0.048-0.2-0.145-0.2c-0.098,0-0.146,0.065-0.146,0.2C54.283-25.415,54.296-25.363,54.322-25.33"
        />
        <rect
          x="56.345"
          y="-26.393"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          width="8.813"
          height="8.813"
        />

        <rect
          x="56.345"
          y="-26.393"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.812"
          height="8.813"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          points="56.935,-25.894 57.098,-24.799 57.103,-24.799 57.276,-25.894 57.563,-25.894
				57.737,-24.799 57.741,-24.799 57.905,-25.894 58.19,-25.894 57.921,-24.414 57.588,-24.414 57.422,-25.487 57.418,-25.487
				57.252,-24.414 56.918,-24.414 56.649,-25.894 			"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          points="58.609,-25.894 58.609,-25.327 58.958,-25.327 58.958,-25.894 59.257,-25.894
				59.257,-24.414 58.958,-24.414 58.958,-25.07 58.609,-25.07 58.609,-24.414 58.312,-24.414 58.312,-25.894 			"
        />
        <rect
          x="59.478"
          y="-25.893"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          width="0.299"
          height="1.479"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          points="60.864,-25.648 60.542,-25.648 60.542,-24.414 60.243,-24.414 60.243,-25.648
				59.919,-25.648 59.919,-25.894 60.864,-25.894 			"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          points="61.819,-25.648 61.29,-25.648 61.29,-25.301 61.788,-25.301 61.788,-25.056
				61.29,-25.056 61.29,-24.657 61.841,-24.657 61.841,-24.414 60.993,-24.414 60.993,-25.894 61.819,-25.894 			"
        />
        <rect
          x="66.212"
          y="-26.393"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M67.126-25.893c0.076,0,0.141,0.012,0.192,0.037s0.095,0.06,0.125,0.101
				c0.032,0.042,0.056,0.089,0.069,0.143c0.013,0.054,0.02,0.107,0.02,0.163c0,0.075-0.011,0.143-0.033,0.198
				c-0.023,0.058-0.056,0.104-0.096,0.14c-0.041,0.035-0.091,0.063-0.148,0.081c-0.057,0.017-0.123,0.026-0.192,0.026h-0.16v0.59
				h-0.298v-1.479H67.126z M67.036-25.224c0.059,0,0.106-0.019,0.144-0.054c0.037-0.036,0.055-0.093,0.055-0.168
				c0-0.074-0.016-0.132-0.048-0.17c-0.032-0.039-0.085-0.059-0.16-0.059h-0.124v0.45H67.036z"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          points="68.13,-25.894 68.341,-24.848 68.346,-24.848 68.559,-25.894
				69.001,-25.894 69.001,-24.414 68.727,-24.414 68.727,-25.595 68.723,-25.595 68.46,-24.414 68.227,-24.414 67.964,-25.595
				67.96,-25.595 67.96,-24.414 67.687,-24.414 67.687,-25.894 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M69.454-24.832c0,0.034,0.003,0.066,0.008,0.094
				c0.006,0.028,0.016,0.053,0.031,0.072s0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.016,0.098,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.035-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.024-0.024-0.047-0.045-0.065c-0.021-0.02-0.047-0.036-0.082-0.054c-0.032-0.016-0.074-0.032-0.125-0.048
				c-0.065-0.021-0.123-0.046-0.172-0.073c-0.049-0.025-0.088-0.057-0.121-0.092c-0.032-0.035-0.057-0.076-0.071-0.122
				s-0.022-0.1-0.022-0.161c0-0.146,0.041-0.256,0.121-0.327c0.082-0.071,0.194-0.107,0.336-0.107c0.067,0,0.129,0.007,0.185,0.021
				c0.056,0.016,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.035,0.111,0.035,0.182v0.041h-0.287
				c0-0.07-0.013-0.125-0.037-0.161c-0.024-0.039-0.066-0.058-0.123-0.058c-0.033,0-0.062,0.004-0.084,0.015
				c-0.022,0.009-0.039,0.022-0.053,0.037c-0.014,0.017-0.021,0.035-0.027,0.056c-0.004,0.021-0.006,0.042-0.006,0.064
				c0,0.045,0.01,0.083,0.028,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.052,0.139,0.078
				c0.036,0.027,0.064,0.057,0.086,0.087s0.036,0.065,0.045,0.103c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.045,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.361-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.832z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M67.104-23.95c0.061,0,0.116,0.006,0.168,0.019
				c0.053,0.013,0.096,0.033,0.135,0.063c0.035,0.029,0.065,0.067,0.086,0.116c0.021,0.048,0.031,0.107,0.031,0.178
				c0,0.08-0.019,0.149-0.056,0.206c-0.038,0.058-0.094,0.097-0.168,0.117v0.005c0.083,0.01,0.147,0.046,0.197,0.104
				c0.048,0.061,0.071,0.14,0.071,0.239c0,0.058-0.006,0.113-0.022,0.165c-0.015,0.053-0.041,0.1-0.077,0.138
				c-0.037,0.04-0.085,0.071-0.143,0.095c-0.06,0.023-0.133,0.035-0.219,0.035h-0.504v-1.479H67.104z M66.987-23.343
				c0.084,0,0.144-0.015,0.182-0.044c0.038-0.03,0.057-0.08,0.057-0.151c0-0.07-0.017-0.12-0.052-0.149
				c-0.034-0.027-0.089-0.043-0.164-0.043h-0.108v0.388H66.987z M67.019-22.69c0.072,0,0.131-0.016,0.174-0.048
				c0.045-0.031,0.066-0.09,0.066-0.176c0-0.043-0.006-0.078-0.018-0.107c-0.012-0.027-0.027-0.05-0.047-0.067
				c-0.021-0.019-0.045-0.029-0.071-0.036c-0.028-0.007-0.058-0.011-0.093-0.011h-0.129v0.445H67.019z"
        />
        <rect
          x="67.759"
          y="-23.95"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          width="0.286"
          height="1.479"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M68.243-23.271c0-0.065,0.011-0.119,0.031-0.162
				c0.021-0.044,0.049-0.079,0.084-0.106c0.035-0.026,0.076-0.047,0.125-0.058c0.047-0.012,0.099-0.018,0.152-0.018
				c0.085,0,0.155,0.008,0.207,0.024c0.053,0.018,0.094,0.04,0.122,0.071c0.028,0.03,0.049,0.065,0.06,0.107
				c0.01,0.042,0.015,0.089,0.015,0.139v0.587c0,0.052,0.003,0.093,0.008,0.122c0.005,0.028,0.015,0.06,0.028,0.093h-0.274
				c-0.009-0.017-0.017-0.037-0.021-0.057c-0.006-0.021-0.01-0.04-0.015-0.06h-0.004c-0.032,0.06-0.071,0.097-0.114,0.113
				c-0.045,0.017-0.101,0.026-0.17,0.026c-0.05,0-0.092-0.01-0.126-0.026s-0.063-0.041-0.083-0.071
				c-0.021-0.029-0.035-0.064-0.046-0.103c-0.01-0.038-0.014-0.075-0.014-0.113c0-0.052,0.005-0.097,0.016-0.135
				c0.013-0.039,0.028-0.071,0.052-0.099c0.022-0.026,0.051-0.05,0.086-0.066c0.035-0.02,0.078-0.034,0.129-0.048l0.16-0.044
				c0.043-0.012,0.073-0.026,0.09-0.045c0.017-0.021,0.024-0.049,0.024-0.086c0-0.043-0.01-0.075-0.03-0.101
				c-0.02-0.024-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.014-0.1,0.039c-0.022,0.027-0.034,0.063-0.034,0.106v0.03h-0.257V-23.271
				z M68.704-22.992c-0.022,0.01-0.043,0.017-0.061,0.021c-0.059,0.012-0.1,0.033-0.123,0.062c-0.025,0.029-0.039,0.068-0.039,0.118
				c0,0.043,0.01,0.079,0.025,0.11c0.018,0.03,0.044,0.046,0.083,0.046c0.019,0,0.039-0.004,0.06-0.01
				c0.021-0.006,0.041-0.017,0.058-0.03s0.03-0.032,0.043-0.054c0.01-0.022,0.016-0.048,0.016-0.079v-0.219
				C68.745-23.013,68.726-23.001,68.704-22.992"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M69.774-23.265c-0.004-0.025-0.011-0.05-0.02-0.072
				c-0.008-0.021-0.021-0.038-0.037-0.051c-0.018-0.013-0.04-0.019-0.066-0.019c-0.035,0-0.063,0.007-0.086,0.022
				c-0.021,0.017-0.039,0.04-0.049,0.07c-0.012,0.032-0.02,0.071-0.023,0.119s-0.006,0.104-0.006,0.169
				c0,0.069,0.002,0.128,0.006,0.177s0.012,0.09,0.023,0.121c0.01,0.032,0.026,0.055,0.047,0.07c0.02,0.014,0.045,0.021,0.076,0.021
				c0.049,0,0.084-0.021,0.106-0.063c0.022-0.041,0.034-0.107,0.034-0.199h0.272c0,0.146-0.032,0.258-0.098,0.335
				c-0.066,0.077-0.175,0.115-0.327,0.115c-0.072,0-0.134-0.009-0.188-0.026c-0.054-0.017-0.098-0.048-0.133-0.093
				s-0.063-0.105-0.08-0.181c-0.018-0.074-0.027-0.168-0.027-0.281c0-0.114,0.012-0.209,0.033-0.285
				c0.022-0.075,0.054-0.134,0.095-0.179c0.04-0.046,0.087-0.077,0.142-0.096c0.057-0.018,0.117-0.026,0.184-0.026
				c0.132,0,0.231,0.039,0.3,0.116c0.067,0.077,0.1,0.182,0.1,0.314H69.78C69.78-23.211,69.778-23.238,69.774-23.265"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          points="70.499,-23.169 70.503,-23.169 70.79,-23.586 71.105,-23.586
				70.784,-23.146 71.153,-22.471 70.839,-22.471 70.601,-22.929 70.499,-22.796 70.499,-22.471 70.212,-22.471 70.212,-23.95
				70.499,-23.95 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M37.765-25.895c0.076,0,0.141,0.014,0.192,0.038
				c0.052,0.026,0.095,0.061,0.125,0.102c0.032,0.042,0.056,0.088,0.069,0.142c0.013,0.053,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.142-0.033,0.199c-0.023,0.057-0.055,0.103-0.096,0.138c-0.041,0.037-0.09,0.064-0.148,0.081
				c-0.057,0.019-0.123,0.028-0.192,0.028h-0.16v0.59h-0.298v-1.48H37.765z M37.675-25.225c0.059,0,0.106-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.159-0.058h-0.125v0.449H37.675z"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          points="38.769,-25.895 38.979,-24.849 38.984,-24.849 39.198,-25.895
				39.639,-25.895 39.639,-24.414 39.366,-24.414 39.366,-25.596 39.362,-25.596 39.099,-24.414 38.866,-24.414 38.603,-25.596
				38.599,-25.596 38.599,-24.414 38.325,-24.414 38.325,-25.895 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M40.093-24.832c0,0.033,0.003,0.065,0.01,0.094
				c0.004,0.027,0.014,0.052,0.029,0.072c0.016,0.019,0.036,0.034,0.061,0.045c0.026,0.012,0.06,0.016,0.098,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.035-0.029,0.056-0.076,0.056-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.009-0.025-0.024-0.048-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.023-0.123-0.048-0.173-0.073c-0.047-0.026-0.088-0.058-0.12-0.093c-0.033-0.035-0.058-0.075-0.072-0.122
				c-0.015-0.045-0.022-0.1-0.022-0.16c0-0.147,0.041-0.256,0.123-0.327c0.08-0.072,0.192-0.108,0.336-0.108
				c0.065,0,0.127,0.008,0.183,0.021c0.056,0.015,0.104,0.039,0.146,0.071s0.071,0.073,0.094,0.124
				c0.023,0.051,0.035,0.11,0.035,0.182v0.041h-0.285c0-0.071-0.014-0.125-0.039-0.162c-0.023-0.039-0.065-0.057-0.123-0.057
				c-0.033,0-0.062,0.004-0.084,0.014c-0.022,0.009-0.039,0.022-0.053,0.038c-0.013,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114c0.019,0.031,0.061,0.06,0.123,0.086l0.23,0.1
				c0.057,0.024,0.104,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.27-0.135,0.342c-0.091,0.07-0.217,0.105-0.379,0.105
				c-0.168,0-0.289-0.036-0.361-0.108c-0.072-0.074-0.108-0.18-0.108-0.316v-0.061h0.298V-24.832z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M41.699-25.45h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.055
				c0.058-0.038,0.094-0.099,0.108-0.181h0.22v1.467h-0.298V-25.45z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M43.267-25.624c-0.062,0.085-0.115,0.178-0.162,0.275
				c-0.049,0.099-0.091,0.199-0.127,0.302c-0.035,0.104-0.065,0.211-0.089,0.316c-0.024,0.108-0.042,0.213-0.054,0.316h-0.313
				c0.01-0.088,0.026-0.182,0.049-0.278c0.022-0.098,0.053-0.197,0.089-0.298c0.036-0.102,0.081-0.202,0.133-0.304
				c0.053-0.104,0.114-0.203,0.187-0.302h-0.617v-0.257h0.904V-25.624z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M43.692-24.627c0.02,0.031,0.054,0.047,0.104,0.047
				c0.041,0,0.072-0.009,0.096-0.027c0.023-0.02,0.043-0.045,0.055-0.078c0.014-0.033,0.021-0.073,0.025-0.118
				c0.006-0.045,0.008-0.096,0.008-0.149v-0.069h-0.006c-0.023,0.036-0.06,0.065-0.105,0.089s-0.104,0.034-0.169,0.034
				s-0.12-0.011-0.165-0.034c-0.047-0.023-0.084-0.054-0.111-0.095c-0.028-0.041-0.049-0.089-0.063-0.146
				c-0.014-0.056-0.02-0.117-0.02-0.183c0-0.17,0.037-0.3,0.112-0.391c0.076-0.09,0.196-0.135,0.362-0.135
				c0.102,0,0.184,0.02,0.244,0.059c0.061,0.036,0.107,0.088,0.14,0.149c0.033,0.064,0.054,0.136,0.063,0.22
				c0.01,0.082,0.015,0.169,0.015,0.26c0,0.104-0.003,0.205-0.009,0.303c-0.008,0.098-0.025,0.184-0.057,0.258
				s-0.08,0.133-0.146,0.177c-0.066,0.043-0.161,0.065-0.281,0.065c-0.053,0-0.104-0.004-0.152-0.015s-0.093-0.03-0.129-0.059
				c-0.037-0.029-0.067-0.065-0.091-0.114c-0.022-0.048-0.034-0.106-0.036-0.178h0.285C43.661-24.7,43.671-24.657,43.692-24.627
				 M43.677-25.183c0.024,0.042,0.065,0.063,0.123,0.063c0.06,0,0.103-0.021,0.127-0.063c0.025-0.043,0.037-0.115,0.037-0.213
				c0-0.042-0.002-0.081-0.006-0.117s-0.012-0.067-0.023-0.092c-0.012-0.025-0.028-0.045-0.051-0.061
				c-0.021-0.015-0.049-0.021-0.084-0.021s-0.063,0.007-0.086,0.021c-0.021,0.016-0.037,0.035-0.047,0.062
				c-0.012,0.025-0.02,0.057-0.022,0.092c-0.003,0.036-0.005,0.074-0.005,0.116C43.64-25.298,43.651-25.226,43.677-25.183"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M44.646-25.62l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.085
				c0.044-0.018,0.098-0.025,0.16-0.025c0.072,0,0.129,0.013,0.177,0.041c0.046,0.026,0.083,0.063,0.108,0.106
				c0.027,0.045,0.047,0.094,0.058,0.15c0.011,0.056,0.017,0.112,0.017,0.17c0,0.082-0.007,0.156-0.021,0.224
				c-0.013,0.064-0.038,0.122-0.073,0.171c-0.036,0.047-0.084,0.082-0.145,0.106s-0.138,0.036-0.231,0.036
				c-0.093,0-0.168-0.012-0.226-0.037c-0.058-0.024-0.101-0.058-0.131-0.099c-0.031-0.04-0.052-0.085-0.062-0.132
				c-0.011-0.048-0.017-0.092-0.017-0.134v-0.039h0.287c0,0.023,0.002,0.05,0.004,0.079c0.002,0.026,0.01,0.053,0.02,0.076
				c0.011,0.024,0.025,0.044,0.045,0.061c0.02,0.016,0.045,0.022,0.076,0.022c0.063,0,0.107-0.022,0.134-0.069
				c0.027-0.048,0.041-0.12,0.041-0.215c0-0.062-0.003-0.111-0.009-0.151c-0.005-0.04-0.016-0.072-0.028-0.096
				s-0.03-0.04-0.053-0.05s-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036c-0.023,0.024-0.037,0.058-0.043,0.099h-0.268
				l0.055-0.798h0.765v0.232H44.646z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M12.708,75.937c0.592,0,0.973,0.392,0.973,0.991
				c0,0.594-0.381,0.986-0.973,0.986c-0.588,0-0.969-0.393-0.969-0.986C11.739,76.328,12.12,75.937,12.708,75.937 M12.708,77.526
				c0.352,0,0.457-0.302,0.457-0.599c0-0.302-0.105-0.603-0.457-0.603c-0.348,0-0.453,0.301-0.453,0.603
				C12.255,77.225,12.36,77.526,12.708,77.526"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M14.666,75.987h0.378v0.345h-0.378v0.929c0,0.175,0.044,0.219,0.218,0.219
				c0.054,0,0.105-0.004,0.16-0.016v0.403c-0.087,0.014-0.2,0.018-0.302,0.018c-0.315,0-0.591-0.072-0.591-0.445v-1.107h-0.312
				v-0.345h0.312v-0.563h0.515V75.987z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M15.714,77.051c0.014,0.326,0.174,0.476,0.46,0.476
				c0.208,0,0.374-0.128,0.407-0.243h0.454c-0.146,0.442-0.454,0.631-0.878,0.631c-0.592,0-0.958-0.406-0.958-0.986
				c0-0.563,0.388-0.992,0.958-0.992c0.638,0,0.946,0.538,0.911,1.115H15.714z M16.553,76.724c-0.047-0.261-0.16-0.398-0.41-0.398
				c-0.327,0-0.421,0.254-0.428,0.398H16.553z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M17.771,77.254c0.004,0.225,0.193,0.315,0.396,0.315
				c0.149,0,0.337-0.058,0.337-0.239c0-0.156-0.214-0.21-0.584-0.29c-0.298-0.065-0.595-0.17-0.595-0.501
				c0-0.479,0.413-0.603,0.816-0.603c0.41,0,0.788,0.138,0.828,0.599h-0.49c-0.015-0.199-0.167-0.255-0.352-0.255
				c-0.117,0-0.287,0.022-0.287,0.176c0,0.185,0.29,0.21,0.584,0.278c0.301,0.069,0.596,0.179,0.596,0.526
				c0,0.494-0.429,0.653-0.857,0.653c-0.436,0-0.86-0.163-0.882-0.66H17.771z"
        />
        <rect
          x="8.914"
          y="80.465"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M12.018,79.028h0.89c0.798,0,1.244,0.399,1.244,1.234
				c0,0.868-0.381,1.357-1.244,1.357h-0.89V79.028z M12.363,81.329h0.574c0.235,0,0.871-0.064,0.871-1.02
				c0-0.616-0.229-0.99-0.864-0.99h-0.581V81.329z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M15.33,79.7c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.419,80.132,14.735,79.7,15.33,79.7 M15.33,81.391
				c0.323,0,0.584-0.254,0.584-0.706c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C14.746,81.137,15.007,81.391,15.33,81.391"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M17.611,79.744h0.291v0.297h0.006c0.131-0.232,0.341-0.342,0.61-0.342
				c0.494,0,0.646,0.283,0.646,0.687v1.233h-0.308v-1.27c0-0.228-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.059
				h-0.308V79.744z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M20.437,79.7c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.526,80.132,19.842,79.7,20.437,79.7 M20.437,81.391
				c0.323,0,0.584-0.254,0.584-0.706c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C19.853,81.137,20.114,81.391,20.437,81.391"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M22.138,79.744h0.374v0.271h-0.374v1.165c0,0.142,0.04,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.409v-1.194H21.51v-0.271h0.319v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M25.522,81.613c-0.055,0.031-0.123,0.05-0.222,0.05
				c-0.159,0-0.261-0.087-0.261-0.29c-0.17,0.2-0.399,0.29-0.66,0.29c-0.341,0-0.621-0.152-0.621-0.526
				c0-0.424,0.316-0.515,0.636-0.577c0.34-0.064,0.631-0.043,0.631-0.274c0-0.27-0.222-0.313-0.417-0.313
				c-0.262,0-0.454,0.081-0.469,0.356h-0.308c0.018-0.465,0.377-0.628,0.795-0.628c0.337,0,0.703,0.077,0.703,0.515v0.966
				c0,0.146,0,0.21,0.099,0.21c0.025,0,0.054-0.003,0.094-0.018V81.613z M25.021,80.651c-0.12,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.036-0.377,0.108-0.377,0.337c0,0.204,0.174,0.275,0.363,0.275c0.406,0,0.573-0.254,0.573-0.424V80.651z"
        />
        <rect
          x="25.826"
          y="79.029"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          width="0.308"
          height="2.591"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M27.041,79.743h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.409v-1.194h-0.319v-0.272h0.319v-0.563h0.309V79.743z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M29.356,81.025c-0.083,0.413-0.381,0.638-0.802,0.638
				c-0.599,0-0.882-0.414-0.9-0.987c0-0.562,0.37-0.976,0.882-0.976c0.663,0,0.867,0.62,0.848,1.074H27.98
				c-0.011,0.326,0.174,0.616,0.585,0.616c0.254,0,0.431-0.122,0.486-0.365H29.356z M29.058,80.502
				c-0.014-0.294-0.236-0.53-0.54-0.53c-0.324,0-0.516,0.243-0.538,0.53H29.058z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M29.692,79.743h0.29v0.396h0.007c0.149-0.301,0.356-0.45,0.69-0.44v0.327
				c-0.497,0-0.679,0.283-0.679,0.758v0.835h-0.308V79.743z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M33.58,81.613c-0.055,0.031-0.122,0.05-0.221,0.05
				c-0.16,0-0.262-0.087-0.262-0.29c-0.17,0.2-0.398,0.29-0.66,0.29c-0.341,0-0.621-0.152-0.621-0.526
				c0-0.424,0.316-0.515,0.636-0.577c0.34-0.064,0.631-0.043,0.631-0.274c0-0.27-0.223-0.313-0.417-0.313
				c-0.263,0-0.454,0.081-0.47,0.356H31.89c0.018-0.465,0.378-0.628,0.795-0.628c0.337,0,0.704,0.077,0.704,0.515v0.966
				c0,0.146,0,0.21,0.098,0.21c0.025,0,0.055-0.003,0.094-0.018V81.613z M33.08,80.65c-0.119,0.088-0.353,0.092-0.559,0.128
				c-0.203,0.035-0.378,0.108-0.378,0.337c0,0.204,0.175,0.275,0.363,0.275c0.405,0,0.573-0.254,0.573-0.424V80.65z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M33.855,79.743h0.29v0.396h0.007c0.148-0.301,0.355-0.45,0.689-0.44v0.327
				c-0.496,0-0.678,0.283-0.678,0.758v0.835h-0.309V79.743z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M35.502,79.743h0.374v0.272h-0.374v1.165c0,0.142,0.039,0.167,0.232,0.167
				h0.142v0.271h-0.235c-0.32,0-0.447-0.064-0.447-0.409v-1.194h-0.318v-0.272h0.318v-0.563h0.309V79.743z"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          points="38.075,81.62 37.749,81.62 37.368,80.128 37.359,80.128
				36.982,81.62 36.648,81.62 36.046,79.744 36.387,79.744 36.812,81.279 36.819,81.279 37.196,79.744 37.534,79.744 37.925,81.279
				37.934,81.279 38.354,79.744 38.673,79.744 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M39.774,79.7c0.596,0,0.912,0.432,0.912,0.984
				c0,0.547-0.316,0.979-0.912,0.979c-0.594,0-0.91-0.432-0.91-0.979C38.864,80.132,39.181,79.7,39.774,79.7 M39.774,81.391
				c0.324,0,0.584-0.254,0.584-0.706c0-0.458-0.26-0.713-0.584-0.713c-0.322,0-0.584,0.255-0.584,0.713
				C39.19,81.137,39.452,81.391,39.774,81.391"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M41.037,79.743h0.29v0.396h0.007c0.149-0.301,0.356-0.45,0.69-0.44v0.327
				c-0.498,0-0.68,0.283-0.68,0.758v0.835h-0.308V79.743z"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          points="42.273,79.028 42.583,79.028 42.583,80.563 43.438,79.744
				43.853,79.744 43.108,80.426 43.907,81.62 43.515,81.62 42.872,80.64 42.583,80.908 42.583,81.62 42.273,81.62 			"
        />
        <rect
          x="8.914"
          y="84.222"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M14.087,84.44c0,0.653-0.374,0.998-1.02,0.998
				c-0.668,0-1.063-0.31-1.063-0.998v-1.655h0.345v1.655c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.655
				h0.345V84.44z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M14.774,84.785c0.011,0.275,0.25,0.362,0.505,0.362
				c0.192,0,0.453-0.044,0.453-0.279c0-0.239-0.305-0.279-0.613-0.349c-0.305-0.068-0.614-0.171-0.614-0.529
				c0-0.379,0.374-0.533,0.701-0.533c0.414,0,0.744,0.13,0.769,0.588h-0.308c-0.022-0.24-0.233-0.316-0.436-0.316
				c-0.185,0-0.399,0.052-0.399,0.239c0,0.222,0.326,0.258,0.613,0.327c0.309,0.069,0.613,0.171,0.613,0.534
				c0,0.445-0.417,0.591-0.798,0.591c-0.421,0-0.777-0.171-0.794-0.635H14.774z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M18,84.781c-0.083,0.413-0.381,0.639-0.802,0.639
				c-0.599,0-0.882-0.414-0.9-0.987c0-0.562,0.37-0.976,0.882-0.976c0.663,0,0.867,0.619,0.848,1.073h-1.404
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.366H18z M17.702,84.259c-0.014-0.294-0.236-0.53-0.54-0.53
				c-0.324,0-0.516,0.243-0.538,0.53H17.702z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M19.783,83.5h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319V83.5h0.319v-0.563h0.309V83.5z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M20.825,83.162h-0.308v-0.378h0.308V83.162z M20.516,83.5h0.308v1.876
				h-0.308V83.5z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M22.921,85.217c0,0.609-0.28,0.922-0.871,0.922
				c-0.352,0-0.758-0.143-0.776-0.552h0.308c0.014,0.226,0.283,0.305,0.486,0.305c0.403,0,0.563-0.29,0.563-0.711v-0.123h-0.008
				c-0.101,0.228-0.348,0.341-0.584,0.341c-0.559,0-0.838-0.439-0.838-0.951c0-0.443,0.218-0.991,0.867-0.991
				c0.236,0,0.446,0.106,0.559,0.313h0.004V83.5h0.29V85.217z M22.616,84.4c0-0.331-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.322-0.556,0.683c0,0.337,0.124,0.714,0.53,0.714C22.464,85.125,22.616,84.752,22.616,84.4"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M23.385,82.785h0.308v0.991h0.008c0.102-0.226,0.366-0.319,0.591-0.319
				c0.494,0,0.646,0.282,0.646,0.687v1.232H24.63v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.059
				h-0.308V82.785z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M25.83,83.5h0.374v0.272H25.83v1.165c0,0.142,0.04,0.167,0.232,0.167h0.142
				v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319V83.5h0.319v-0.563h0.309V83.5z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M28.361,83.457c0.595,0,0.911,0.432,0.911,0.983
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.433-0.911-0.979C27.45,83.889,27.767,83.457,28.361,83.457 M28.361,85.147
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712s-0.584,0.254-0.584,0.712
				C27.777,84.894,28.038,85.147,28.361,85.147"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          points="30.479,85.376 30.149,85.376 29.453,83.5 29.797,83.5
				30.323,85.064 30.331,85.064 30.842,83.5 31.166,83.5 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M33.048,84.781c-0.084,0.413-0.381,0.639-0.802,0.639
				c-0.599,0-0.882-0.414-0.9-0.987c0-0.562,0.37-0.976,0.883-0.976c0.662,0,0.866,0.619,0.848,1.073h-1.404
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.433-0.123,0.485-0.366H33.048z M32.75,84.259
				c-0.014-0.294-0.236-0.53-0.54-0.53c-0.323,0-0.516,0.243-0.538,0.53H32.75z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M33.384,83.5h0.291v0.396h0.006c0.149-0.302,0.356-0.45,0.69-0.44v0.327
				c-0.497,0-0.678,0.283-0.678,0.758v0.835h-0.31V83.5z"
        />
        <rect
          x="34.621"
          y="82.785"
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          width="0.308"
          height="2.591"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M36.217,83.457c0.595,0,0.911,0.432,0.911,0.983
				c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.433-0.911-0.979C35.306,83.889,35.622,83.457,36.217,83.457 M36.217,85.147
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712s-0.584,0.254-0.584,0.712
				C35.633,84.894,35.894,85.147,36.217,85.147"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M38.764,84.103c-0.048-0.235-0.21-0.374-0.464-0.374
				c-0.447,0-0.586,0.353-0.586,0.733c0,0.345,0.156,0.686,0.549,0.686c0.297,0,0.475-0.174,0.515-0.457h0.316
				c-0.069,0.457-0.356,0.729-0.827,0.729c-0.573,0-0.879-0.399-0.879-0.958c0-0.563,0.291-1.005,0.886-1.005
				c0.424,0,0.767,0.199,0.81,0.646H38.764z"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          points="39.455,82.785 39.765,82.785 39.765,84.32 40.62,83.5 41.034,83.5
				40.29,84.183 41.089,85.376 40.696,85.376 40.054,84.396 39.765,84.665 39.765,85.376 39.455,85.376 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M41.646,83.162h-0.309v-0.378h0.309V83.162z M41.338,83.5h0.309v1.876
				h-0.309V83.5z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M42.124,83.5h0.291v0.297h0.006c0.131-0.231,0.342-0.341,0.61-0.341
				c0.494,0,0.646,0.283,0.646,0.687v1.233h-0.308v-1.27c0-0.229-0.146-0.378-0.381-0.378c-0.374,0-0.556,0.251-0.556,0.589v1.059
				h-0.309V83.5z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#EC008C"
          d="M45.76,85.216c0,0.61-0.28,0.923-0.871,0.923
				c-0.353,0-0.759-0.143-0.776-0.552h0.309c0.014,0.226,0.283,0.305,0.486,0.305c0.402,0,0.563-0.29,0.563-0.711v-0.123h-0.008
				c-0.102,0.228-0.348,0.341-0.584,0.341c-0.559,0-0.838-0.439-0.838-0.951c0-0.443,0.217-0.991,0.867-0.991
				c0.235,0,0.445,0.106,0.559,0.313h0.004V83.5h0.29V85.216z M45.454,84.4c0-0.331-0.145-0.672-0.532-0.672
				c-0.396,0-0.556,0.322-0.556,0.683c0,0.337,0.123,0.714,0.529,0.714C45.303,85.125,45.454,84.752,45.454,84.4"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M-12.276-25.894c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.143c0.013,0.053,0.02,0.107,0.02,0.163
				c0,0.075-0.012,0.142-0.034,0.198c-0.023,0.058-0.054,0.104-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.276z M-12.367-25.224c0.059,0,0.107-0.019,0.144-0.054
				c0.037-0.036,0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.048-0.17c-0.032-0.039-0.085-0.059-0.159-0.059H-12.5v0.45H-12.367z
				"
        />
        <polygon
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          points="-11.273,-25.894 -11.063,-24.849 -11.058,-24.849 -10.844,-25.894
				-10.402,-25.894 -10.402,-24.414 -10.676,-24.414 -10.676,-25.596 -10.68,-25.596 -10.943,-24.414 -11.176,-24.414
				-11.438,-25.596 -11.442,-25.596 -11.442,-24.414 -11.716,-24.414 -11.716,-25.894 			"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M-9.949-24.832c0,0.034,0.003,0.066,0.008,0.094
				c0.005,0.028,0.016,0.053,0.031,0.072s0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.028,0.056-0.075,0.056-0.137c0-0.034-0.006-0.062-0.015-0.086
				c-0.008-0.024-0.023-0.047-0.044-0.065c-0.021-0.02-0.048-0.036-0.082-0.054c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.023-0.124-0.047-0.172-0.073c-0.048-0.025-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.075-0.072-0.122
				c-0.015-0.045-0.023-0.099-0.023-0.16c0-0.146,0.041-0.256,0.122-0.327c0.082-0.071,0.194-0.107,0.336-0.107
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.012-0.125-0.037-0.161c-0.024-0.039-0.066-0.058-0.124-0.058
				c-0.033,0-0.061,0.004-0.083,0.015c-0.023,0.009-0.04,0.021-0.054,0.037c-0.013,0.017-0.021,0.035-0.026,0.056
				c-0.005,0.021-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.029,0.114c0.019,0.032,0.06,0.06,0.124,0.086l0.23,0.1
				c0.057,0.024,0.103,0.052,0.139,0.078c0.036,0.027,0.064,0.057,0.086,0.087S-9.284-25-9.274-24.963
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.269-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.169,0-0.29-0.036-0.362-0.109c-0.072-0.074-0.108-0.179-0.108-0.316v-0.06h0.298V-24.832z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M-8.358-24.646c0.021,0.045,0.065,0.067,0.13,0.067
				c0.035,0,0.064-0.007,0.086-0.019c0.021-0.013,0.037-0.03,0.049-0.051c0.012-0.021,0.019-0.047,0.023-0.076
				c0.003-0.028,0.005-0.06,0.005-0.091c0-0.033-0.002-0.064-0.007-0.095c-0.005-0.028-0.014-0.056-0.028-0.08
				c-0.014-0.022-0.033-0.042-0.058-0.056s-0.059-0.021-0.1-0.021h-0.099v-0.195h0.097c0.032,0,0.059-0.006,0.08-0.02
				c0.021-0.013,0.039-0.029,0.052-0.052c0.013-0.021,0.023-0.046,0.029-0.074c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.069-0.012-0.119-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.057,0.006-0.077,0.017
				c-0.021,0.012-0.036,0.029-0.047,0.05c-0.011,0.022-0.018,0.047-0.022,0.076c-0.003,0.029-0.005,0.062-0.005,0.095h-0.273
				c0-0.146,0.036-0.255,0.109-0.325c0.073-0.071,0.181-0.107,0.322-0.107c0.133,0,0.237,0.03,0.311,0.092
				c0.074,0.061,0.111,0.155,0.111,0.285c0,0.089-0.02,0.161-0.059,0.217c-0.039,0.057-0.094,0.092-0.164,0.107v0.004
				c0.095,0.015,0.162,0.053,0.201,0.114c0.04,0.062,0.06,0.139,0.06,0.231c0,0.049-0.007,0.102-0.019,0.153
				c-0.012,0.052-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.077-0.149,0.104c-0.062,0.025-0.146,0.039-0.248,0.039
				c-0.141,0-0.248-0.038-0.319-0.116c-0.07-0.077-0.105-0.188-0.105-0.329v-0.007h0.286C-8.391-24.757-8.379-24.691-8.358-24.646"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M-7.11-25.641c-0.02-0.03-0.056-0.046-0.105-0.046
				c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.019-0.042,0.045-0.056,0.078c-0.013,0.033-0.022,0.072-0.026,0.118
				c-0.004,0.045-0.006,0.095-0.006,0.148v0.072h0.004c0.026-0.038,0.061-0.067,0.108-0.092c0.046-0.022,0.102-0.034,0.168-0.034
				c0.065,0,0.12,0.012,0.166,0.034c0.045,0.024,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.089,0.062,0.146
				c0.013,0.056,0.02,0.117,0.02,0.183c0,0.17-0.038,0.299-0.113,0.387c-0.075,0.089-0.196,0.132-0.362,0.132
				c-0.102,0-0.184-0.019-0.245-0.054c-0.06-0.037-0.107-0.086-0.139-0.148c-0.033-0.063-0.054-0.137-0.064-0.22
				c-0.009-0.082-0.014-0.168-0.014-0.259c0-0.103,0.003-0.204,0.009-0.301c0.006-0.098,0.025-0.184,0.057-0.259
				c0.03-0.075,0.079-0.137,0.145-0.183c0.067-0.045,0.161-0.067,0.282-0.067c0.052,0,0.104,0.007,0.152,0.019
				C-7-25.85-6.958-25.829-6.921-25.8c0.037,0.028,0.068,0.066,0.09,0.114c0.023,0.049,0.035,0.107,0.036,0.178h-0.286
				C-7.081-25.565-7.09-25.61-7.11-25.641 M-7.376-24.753c0.005,0.035,0.012,0.065,0.024,0.092c0.012,0.026,0.028,0.046,0.05,0.06
				c0.021,0.015,0.049,0.022,0.084,0.022c0.036,0,0.064-0.008,0.086-0.022c0.021-0.014,0.037-0.033,0.048-0.061
				c0.011-0.026,0.019-0.057,0.022-0.093c0.003-0.034,0.005-0.073,0.005-0.115c0-0.099-0.012-0.171-0.037-0.213
				c-0.025-0.043-0.066-0.064-0.124-0.064c-0.06,0-0.102,0.021-0.127,0.064c-0.024,0.042-0.037,0.114-0.037,0.213
				C-7.383-24.828-7.381-24.789-7.376-24.753"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M-6.639-25.066c0.042-0.066,0.104-0.107,0.187-0.125v-0.004
				c-0.04-0.01-0.074-0.024-0.101-0.046c-0.026-0.02-0.048-0.043-0.065-0.069c-0.016-0.026-0.028-0.055-0.035-0.083
				c-0.007-0.029-0.01-0.057-0.01-0.083c0-0.145,0.038-0.247,0.115-0.31c0.076-0.063,0.181-0.095,0.316-0.095
				c0.134,0,0.239,0.031,0.315,0.095c0.077,0.063,0.116,0.165,0.116,0.31c0,0.026-0.004,0.054-0.011,0.083
				c-0.008,0.028-0.02,0.057-0.037,0.083c-0.016,0.026-0.038,0.05-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.018,0.145,0.059,0.187,0.125c0.041,0.066,0.062,0.146,0.062,0.238c0,0.043-0.005,0.09-0.015,0.141
				c-0.011,0.052-0.032,0.1-0.066,0.144c-0.033,0.044-0.08,0.08-0.141,0.11c-0.062,0.028-0.144,0.043-0.246,0.043
				s-0.184-0.015-0.246-0.043c-0.062-0.03-0.108-0.066-0.142-0.11c-0.033-0.044-0.054-0.092-0.065-0.144
				c-0.011-0.051-0.016-0.098-0.016-0.141C-6.701-24.921-6.679-25-6.639-25.066 M-6.396-24.734c0.005,0.031,0.014,0.059,0.027,0.081
				c0.013,0.023,0.031,0.041,0.053,0.055c0.022,0.013,0.05,0.02,0.083,0.02c0.033,0,0.06-0.007,0.083-0.02
				c0.022-0.014,0.039-0.031,0.052-0.055c0.013-0.022,0.023-0.05,0.028-0.081c0.004-0.03,0.007-0.063,0.007-0.098
				c0-0.036-0.003-0.07-0.007-0.1c-0.005-0.03-0.015-0.058-0.028-0.079c-0.013-0.024-0.03-0.041-0.052-0.054
				c-0.023-0.015-0.05-0.021-0.083-0.021c-0.033,0-0.061,0.006-0.083,0.021c-0.022,0.013-0.04,0.029-0.053,0.054
				c-0.013,0.021-0.022,0.049-0.027,0.079c-0.005,0.029-0.007,0.063-0.007,0.1C-6.402-24.798-6.4-24.765-6.396-24.734 M-6.338-25.33
				c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155
				c0-0.135-0.048-0.2-0.145-0.2s-0.145,0.065-0.145,0.2C-6.377-25.415-6.364-25.363-6.338-25.33"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M-11.87-23.647c-0.065,0.092-0.122,0.189-0.173,0.294
				c-0.051,0.104-0.095,0.212-0.133,0.321c-0.039,0.109-0.07,0.222-0.095,0.335c-0.025,0.114-0.044,0.227-0.057,0.335h-0.333
				c0.012-0.093,0.03-0.191,0.053-0.295c0.024-0.104,0.055-0.209,0.094-0.315c0.039-0.107,0.086-0.215,0.142-0.324
				s0.122-0.215,0.198-0.319h-0.656v-0.272h0.96V-23.647z"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M-11.771-23.4c0.007-0.1,0.027-0.188,0.06-0.266
				c0.032-0.076,0.081-0.139,0.146-0.186c0.065-0.046,0.156-0.068,0.272-0.068c0.095,0,0.174,0.014,0.237,0.041
				c0.062,0.026,0.112,0.07,0.149,0.131c0.038,0.06,0.065,0.138,0.08,0.233c0.015,0.098,0.023,0.215,0.023,0.353
				c0,0.107-0.004,0.213-0.011,0.312c-0.007,0.101-0.027,0.189-0.06,0.267c-0.032,0.076-0.081,0.138-0.146,0.182
				c-0.066,0.044-0.156,0.065-0.272,0.065c-0.094,0-0.173-0.013-0.236-0.037c-0.063-0.025-0.113-0.068-0.15-0.128
				c-0.038-0.061-0.065-0.139-0.08-0.235c-0.015-0.095-0.023-0.212-0.023-0.351C-11.782-23.196-11.778-23.3-11.771-23.4
				 M-11.457-22.778c0.005,0.063,0.014,0.111,0.027,0.147c0.014,0.035,0.032,0.061,0.053,0.073c0.022,0.014,0.05,0.021,0.084,0.021
				c0.035,0,0.063-0.008,0.086-0.025c0.022-0.017,0.039-0.046,0.052-0.086c0.012-0.04,0.021-0.095,0.026-0.162
				c0.005-0.066,0.008-0.15,0.008-0.251v-0.17c0-0.098-0.003-0.179-0.008-0.241c-0.005-0.062-0.015-0.111-0.027-0.146
				c-0.014-0.036-0.031-0.061-0.053-0.074c-0.022-0.013-0.051-0.02-0.084-0.02c-0.035,0-0.064,0.009-0.086,0.024
				c-0.022,0.018-0.039,0.046-0.052,0.086c-0.012,0.041-0.021,0.095-0.026,0.162c-0.005,0.068-0.008,0.152-0.008,0.251v0.17
				C-11.465-22.92-11.462-22.841-11.457-22.778"
        />
        <path
          clipPath="url(#GEOCACHING_SVGID_74_)"
          fill="#FFFFFF"
          d="M-10.691-23.685c0.008-0.053,0.025-0.097,0.049-0.132
				c0.024-0.035,0.058-0.062,0.101-0.078c0.044-0.018,0.099-0.026,0.167-0.026c0.069,0,0.125,0.009,0.167,0.026
				c0.043,0.017,0.076,0.043,0.101,0.078c0.023,0.035,0.04,0.079,0.049,0.132c0.009,0.053,0.013,0.115,0.013,0.185
				c0,0.071-0.004,0.133-0.013,0.186c-0.009,0.053-0.026,0.097-0.049,0.132c-0.025,0.036-0.058,0.061-0.101,0.079
				c-0.042,0.016-0.098,0.024-0.167,0.024c-0.068,0-0.123-0.009-0.167-0.024c-0.043-0.019-0.077-0.043-0.101-0.079
				c-0.024-0.035-0.041-0.079-0.049-0.132c-0.009-0.053-0.013-0.114-0.013-0.186C-10.704-23.569-10.7-23.632-10.691-23.685
				 M-10.457-23.312c0.011,0.044,0.038,0.065,0.082,0.065s0.071-0.021,0.081-0.065c0.01-0.043,0.016-0.109,0.016-0.197
				c0-0.079-0.006-0.139-0.016-0.181c-0.01-0.042-0.037-0.063-0.081-0.063s-0.071,0.021-0.082,0.063
				c-0.01,0.042-0.015,0.102-0.015,0.181C-10.471-23.421-10.466-23.354-10.457-23.312 M-9.366-23.958L-10.26-22.3h-0.196
				l0.894-1.658H-9.366z M-9.758-22.936c0.009-0.053,0.026-0.097,0.05-0.133c0.024-0.034,0.058-0.062,0.101-0.077
				c0.044-0.018,0.099-0.026,0.166-0.026c0.069,0,0.125,0.009,0.168,0.026c0.042,0.016,0.075,0.043,0.1,0.077
				c0.024,0.036,0.041,0.08,0.049,0.133c0.009,0.053,0.013,0.114,0.013,0.185c0,0.071-0.004,0.134-0.013,0.187
				c-0.008,0.052-0.025,0.095-0.049,0.13c-0.025,0.034-0.058,0.059-0.1,0.075c-0.043,0.015-0.099,0.022-0.168,0.022
				c-0.067,0-0.122-0.008-0.166-0.022c-0.043-0.017-0.077-0.041-0.101-0.075c-0.024-0.035-0.041-0.078-0.05-0.13
				c-0.009-0.053-0.013-0.115-0.013-0.187C-9.771-22.821-9.768-22.883-9.758-22.936 M-9.522-22.563
				c0.01,0.044,0.037,0.064,0.081,0.064s0.071-0.021,0.082-0.064c0.01-0.043,0.015-0.109,0.015-0.197
				c0-0.079-0.005-0.14-0.015-0.181c-0.011-0.043-0.038-0.063-0.082-0.063s-0.071,0.021-0.081,0.063
				c-0.01,0.041-0.016,0.102-0.016,0.181C-9.539-22.672-9.532-22.605-9.522-22.563"
        />
      </g>
    </g>
  );
};

const BackwoodsCooking = () => {
  return (
    <g id="Backwoods_Cooking">
      <g>
        <defs>
          <rect
            id="BACKWOODSCOOKING_SVGID_75_"
            x="0"
            width="69.851"
            height="64.914"
          />
        </defs>
        <clipPath id="BACKWOODSCOOKING_SVGID_76_">
          <use xlinkHref="#BACKWOODSCOOKING_SVGID_75_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_76_)"
          fill="#72BF44"
          d="M55.118,61.07H5.792c-3.06,0-5.564-2.504-5.564-5.563V6.181
				c0-3.061,2.504-5.563,5.564-5.563h49.326c3.06,0,5.563,2.503,5.563,5.563v49.327C60.682,58.566,58.178,61.07,55.118,61.07"
        />
      </g>
      <g>
        <defs>
          <path
            id="BACKWOODSCOOKING_SVGID_77_"
            d="M9.168,6.082c-2.705,0-4.917,2.212-4.917,4.917v43.596c0,2.703,2.212,4.917,4.917,4.917h43.596
					c2.704,0,4.916-2.214,4.916-4.917V10.999c0-2.705-2.212-4.917-4.916-4.917H9.168z"
          />
        </defs>
        <clipPath id="BACKWOODSCOOKING_SVGID_78_">
          <use xlinkHref="#BACKWOODSCOOKING_SVGID_77_" overflow="visible" />
        </clipPath>
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_78_)"
          fill="#008B4A"
          points="15.399,48.236 43.641,64.914 69.852,49.982 42.653,37.787 			"
        />
      </g>
      <g>
        <defs>
          <rect
            id="BACKWOODSCOOKING_SVGID_79_"
            x="0"
            width="69.851"
            height="64.914"
          />
        </defs>
        <clipPath id="BACKWOODSCOOKING_SVGID_80_">
          <use xlinkHref="#BACKWOODSCOOKING_SVGID_79_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M17.241,9.105c0.369,0.028,0.767,0.314,0.767,0.914
				c0,0.66-0.446,1.117-1.314,1.117h-2.069V7.154h2.062c0.732,0,1.208,0.434,1.208,1.088c0,0.547-0.386,0.809-0.654,0.839V9.105z
				 M15.605,8.801h0.857c0.291,0,0.481-0.178,0.481-0.445c0-0.25-0.196-0.411-0.446-0.411h-0.892V8.801z M16.521,10.347
				c0.315,0,0.488-0.173,0.488-0.445c0-0.263-0.191-0.429-0.47-0.429h-0.934v0.874H16.521z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M21.724,11.137h-0.952v-0.237c-0.232,0.209-0.541,0.328-0.927,0.328
				c-0.791,0-1.457-0.638-1.457-1.582c0-0.939,0.666-1.581,1.457-1.581c0.386,0,0.695,0.119,0.927,0.326V8.152h0.952V11.137z
				 M20.772,9.646c0-0.458-0.345-0.773-0.731-0.773c-0.416,0-0.72,0.315-0.72,0.773c0,0.464,0.304,0.772,0.72,0.772
				C20.427,10.418,20.772,10.109,20.772,9.646"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M24.454,9.871l0.874,0.19c-0.149,0.707-0.779,1.166-1.522,1.166
				c-0.892,0-1.63-0.638-1.63-1.582c0-0.939,0.738-1.581,1.63-1.581c0.725,0,1.356,0.445,1.522,1.146l-0.892,0.215
				c-0.083-0.363-0.327-0.529-0.63-0.529c-0.428,0-0.714,0.314-0.714,0.749c0,0.439,0.286,0.749,0.714,0.749
				C24.109,10.395,24.353,10.233,24.454,9.871"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="27.861,11.138 26.892,10.062 26.72,10.062 26.72,11.138
				25.769,11.138 25.769,6.856 26.72,6.856 26.72,9.169 26.851,9.169 27.796,8.152 28.943,8.152 27.635,9.573 29.08,11.138 			"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="32.83,11.144 32.002,11.144 31.468,9.526 30.927,11.144
				30.1,11.144 29.143,8.152 30.077,8.152 30.563,9.759 31.128,8.152 31.802,8.152 32.365,9.765 32.854,8.152 33.787,8.152 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M37.125,9.646c0,0.944-0.75,1.575-1.635,1.575
				c-0.904,0-1.653-0.631-1.653-1.575c0-0.939,0.749-1.569,1.653-1.569C36.375,8.076,37.125,8.706,37.125,9.646 M36.209,9.646
				c0-0.465-0.352-0.773-0.719-0.773c-0.388,0-0.737,0.309-0.737,0.773c0,0.469,0.35,0.772,0.737,0.772
				C35.857,10.418,36.209,10.114,36.209,9.646"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M40.683,9.646c0,0.944-0.75,1.575-1.635,1.575
				c-0.903,0-1.653-0.631-1.653-1.575c0-0.939,0.75-1.569,1.653-1.569C39.933,8.076,40.683,8.706,40.683,9.646 M39.767,9.646
				c0-0.465-0.351-0.773-0.719-0.773c-0.388,0-0.737,0.309-0.737,0.773c0,0.469,0.35,0.772,0.737,0.772
				C39.416,10.418,39.767,10.114,39.767,9.646"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M44.289,11.137h-0.953v-0.237c-0.231,0.209-0.541,0.328-0.926,0.328
				c-0.791,0-1.457-0.638-1.457-1.582c0-0.939,0.666-1.581,1.457-1.581c0.385,0,0.694,0.119,0.926,0.326V6.856h0.953V11.137z
				 M43.336,9.646c0-0.458-0.344-0.773-0.73-0.773c-0.417,0-0.72,0.315-0.72,0.773c0,0.464,0.303,0.772,0.72,0.772
				C42.992,10.418,43.336,10.109,43.336,9.646"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M45.666,10.168c0.041,0.255,0.273,0.352,0.529,0.352
				c0.25,0,0.387-0.107,0.387-0.238c0-0.101-0.078-0.178-0.299-0.22l-0.605-0.119c-0.553-0.101-0.868-0.398-0.868-0.868
				c0-0.612,0.529-1.011,1.284-1.011c0.744,0,1.236,0.339,1.355,0.856l-0.867,0.172c-0.03-0.185-0.209-0.351-0.5-0.351
				c-0.256,0-0.34,0.125-0.34,0.232c0,0.083,0.037,0.166,0.228,0.208l0.701,0.143c0.563,0.119,0.825,0.457,0.825,0.898
				c0,0.66-0.569,1.005-1.354,1.005c-0.702,0-1.302-0.256-1.403-0.869L45.666,10.168z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M22.486,15.535l0.875,0.191c-0.15,0.707-0.78,1.165-1.523,1.165
				c-0.892,0-1.63-0.636-1.63-1.582c0-0.939,0.738-1.581,1.63-1.581c0.725,0,1.356,0.445,1.523,1.146l-0.893,0.215
				c-0.083-0.363-0.327-0.529-0.63-0.529c-0.428,0-0.713,0.314-0.713,0.749c0,0.44,0.285,0.75,0.713,0.75
				C22.141,16.06,22.385,15.898,22.486,15.535"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M26.9,15.31c0,0.945-0.75,1.575-1.636,1.575
				c-0.903,0-1.653-0.63-1.653-1.575c0-0.939,0.75-1.569,1.653-1.569C26.15,13.74,26.9,14.37,26.9,15.31 M25.984,15.31
				c0-0.464-0.351-0.773-0.72-0.773c-0.386,0-0.737,0.31-0.737,0.773c0,0.469,0.351,0.773,0.737,0.773
				C25.634,16.083,25.984,15.778,25.984,15.31"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M30.459,15.31c0,0.945-0.751,1.575-1.636,1.575
				c-0.904,0-1.654-0.63-1.654-1.575c0-0.939,0.75-1.569,1.654-1.569C29.708,13.74,30.459,14.37,30.459,15.31 M29.542,15.31
				c0-0.464-0.351-0.773-0.719-0.773c-0.387,0-0.737,0.31-0.737,0.773c0,0.469,0.35,0.773,0.737,0.773
				C29.191,16.083,29.542,15.778,29.542,15.31"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="33.004,16.802 32.035,15.726 31.863,15.726 31.863,16.802
				30.911,16.802 30.911,12.52 31.863,12.52 31.863,14.834 31.994,14.834 32.939,13.816 34.088,13.816 32.779,15.238 34.225,16.802
							"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M35.562,12.938c0,0.303-0.233,0.529-0.554,0.529
				c-0.321,0-0.547-0.227-0.547-0.529c0-0.292,0.226-0.542,0.547-0.542C35.328,12.396,35.562,12.646,35.562,12.938 M34.533,13.817
				h0.95v2.985h-0.95V13.817z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M39.166,14.971v1.831h-0.945v-1.629c0-0.351-0.209-0.589-0.513-0.589
				c-0.374,0-0.618,0.255-0.618,0.832v1.386h-0.951v-2.984h0.951v0.285c0.227-0.238,0.541-0.374,0.916-0.374
				C38.702,13.729,39.166,14.221,39.166,14.971"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M42.848,16.505c0,0.922-0.702,1.427-1.672,1.427
				c-0.684,0-1.207-0.238-1.498-0.719l0.791-0.429c0.227,0.268,0.393,0.369,0.701,0.369c0.435,0,0.744-0.256,0.744-0.726v-0.083
				c-0.215,0.184-0.512,0.285-0.898,0.285c-0.779,0-1.439-0.618-1.439-1.464c0-0.825,0.66-1.438,1.439-1.438
				c0.387,0,0.684,0.1,0.898,0.285v-0.196h0.934V16.505z M41.914,15.166c0-0.397-0.315-0.678-0.696-0.678
				c-0.416,0-0.713,0.28-0.713,0.678c0,0.398,0.297,0.685,0.713,0.685C41.599,15.851,41.914,15.564,41.914,15.166"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#F5DB8B"
          d="M46.912,45.023"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M21.4,32.36l25.314,10.995l-2.397,5.609L19.003,37.971
				c-1.55-0.662-2.269-2.455-1.607-4.005C18.059,32.417,19.851,31.698,21.4,32.36"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M42.711,44.961c-0.662,1.549,0.057,3.342,1.606,4.004
				c1.549,0.662,3.342-0.057,4.004-1.605c0.662-1.55-0.058-3.343-1.606-4.004C45.165,42.693,43.373,43.412,42.711,44.961"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M43.272,45.201c-0.53,1.239,0.046,2.674,1.284,3.203
				c1.24,0.529,2.674-0.046,3.203-1.285c0.531-1.239-0.045-2.674-1.283-3.203C45.236,43.386,43.803,43.962,43.272,45.201"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#AC6E26"
          d="M44.39,38.218L18.083,48.43l-2.271-5.662l26.306-10.212
				c1.564-0.628,3.34,0.131,3.967,1.696C46.711,35.813,45.953,37.591,44.39,38.218"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M19.778,44.464c-0.627-1.564-2.404-2.323-3.967-1.695
				c-1.564,0.626-2.323,2.403-1.695,3.966c0.627,1.564,2.403,2.323,3.967,1.695C19.646,47.803,20.405,46.026,19.778,44.464"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#AC6E26"
          d="M19.212,44.69c-0.502-1.251-1.923-1.858-3.174-1.356
				c-1.25,0.502-1.858,1.923-1.356,3.174s1.923,1.858,3.174,1.357C19.106,47.363,19.714,45.941,19.212,44.69"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M18.668,44.909c-0.381-0.951-1.461-1.412-2.411-1.031
				c-0.951,0.381-1.413,1.461-1.032,2.411c0.382,0.951,1.462,1.413,2.412,1.031C18.589,46.939,19.05,45.859,18.668,44.909"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M28.334,42.861l-4.036,1.619c-0.14,0.055-0.208,0.215-0.151,0.355
				c0.055,0.141,0.215,0.209,0.355,0.151l4.036-1.618c0.14-0.056,0.208-0.216,0.152-0.355
				C28.633,42.873,28.474,42.806,28.334,42.861"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M26.66,40.08l-4.035,1.619c-0.141,0.056-0.209,0.215-0.152,0.355
				c0.056,0.14,0.215,0.208,0.356,0.151l4.035-1.618c0.14-0.057,0.208-0.216,0.152-0.356C26.958,40.092,26.799,40.023,26.66,40.08"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M41.337,34.075l-4.035,1.619c-0.14,0.056-0.209,0.215-0.151,0.355
				c0.056,0.141,0.215,0.209,0.355,0.151l4.035-1.617c0.141-0.057,0.208-0.217,0.152-0.356
				C41.636,34.087,41.477,34.02,41.337,34.075"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M43.336,35.059l-1.93,0.774c-0.14,0.057-0.207,0.216-0.152,0.355
				c0.057,0.14,0.216,0.208,0.357,0.152l1.93-0.774c0.14-0.057,0.207-0.216,0.151-0.355C43.636,35.069,43.477,35.003,43.336,35.059"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M41.409,37.302l-1.931,0.774c-0.139,0.057-0.207,0.216-0.151,0.356
				c0.056,0.14,0.215,0.208,0.356,0.151l1.93-0.773c0.141-0.057,0.208-0.217,0.152-0.356C41.709,37.313,41.549,37.246,41.409,37.302
				"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          d="M23.482,42.808l-1.93,0.773c-0.14,0.058-0.209,0.216-0.152,0.356
				c0.056,0.14,0.215,0.208,0.355,0.152l1.931-0.774c0.14-0.056,0.208-0.216,0.152-0.356C23.781,42.818,23.622,42.751,23.482,42.808
				"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#AC6E26"
          d="M17.936,45.202c-0.22-0.547-0.84-0.811-1.387-0.593
				c-0.545,0.219-0.81,0.841-0.592,1.386c0.219,0.547,0.84,0.813,1.386,0.593C17.89,46.369,18.155,45.749,17.936,45.202"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#AC6E26"
          points="59.515,25.063 31.549,25.142 31.553,23.167 59.519,23.112 			"
        />

        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="none"
          stroke="#623B23"
          strokeWidth="0.587"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
				M55.814,24.468c0,0-2.811,4.334-6.271,5.141"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#008B4A"
          d="M50.588,30.793c-1.459,0.657-3.055,0.616-4.412,0.021
				c0.451-1.413,1.477-2.637,2.936-3.294c1.458-0.659,3.055-0.618,4.412-0.021C53.071,28.91,52.047,30.134,50.588,30.793"
        />

        <line
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="none"
          stroke="#623B23"
          strokeWidth="0.689"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="45.782"
          y1="24.363"
          x2="53.819"
          y2="24.363"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#AC6E26"
          d="M31.387,24.856h-17.03c-0.381,0-0.689-0.308-0.689-0.688
				c0-0.38,0.308-0.688,0.689-0.688h17.03c0.38,0,0.689,0.309,0.689,0.688C32.076,24.549,31.767,24.856,31.387,24.856"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#9CCE78"
          d="M56.01,4.406c0.689,0,1.271,0.582,1.271,1.271v50.333
				c0,0.688-0.581,1.271-1.271,1.271H5.677c-0.689,0-1.271-0.582-1.271-1.271V5.678c0-0.689,0.582-1.271,1.271-1.271H56.01 M56.01,0
				H5.677C2.555,0,0,2.555,0,5.678v50.333c0,3.122,2.555,5.677,5.677,5.677H56.01c3.123,0,5.678-2.555,5.678-5.677V5.678
				C61.688,2.555,59.133,0,56.01,0"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#F15D2A"
          d="M24.982,24.168c0,1.988-1.611,3.6-3.599,3.6c-1.988,0-3.6-1.611-3.6-3.6
				s1.612-3.599,3.6-3.599C23.371,20.569,24.982,22.18,24.982,24.168"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#F15D2A"
          d="M44.288,28.271c-2.267,0-4.103-1.837-4.103-4.103s1.836-4.103,4.103-4.103"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#008B4A"
          points="21.604,20.655 22.356,20.26 22.212,21.097 22.821,21.69
				21.979,21.813 21.604,22.574 21.228,21.813 20.387,21.69 20.996,21.097 20.852,20.26 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="none"
          stroke="#008B4A"
          strokeWidth="2.066"
          strokeMiterlimit="10"
          d="M26.761,20.097
				c1.555,1.647,1.119,6.563,0.303,7.731"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="none"
          stroke="#FFDD00"
          strokeWidth="2.066"
          strokeMiterlimit="10"
          d="M24.891,20.307
				c0.475,0.848,0.809,1.872,0.936,2.996c0.21,1.875-0.205,3.61-1.021,4.778"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M32.739,26.392l-3.121-2.589c-0.68-0.563-0.774-1.572-0.21-2.252
				c1.377-1.66,3.838-1.889,5.498-0.512c1.539,1.277,1.753,3.563,0.476,5.104l-0.004,0.003
				C34.717,26.943,33.535,27.053,32.739,26.392"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M30.323,27.467l-0.803-0.666c-0.364-0.302-0.414-0.841-0.113-1.205
				l1.839-2.217c0.302-0.362,0.841-0.414,1.205-0.11l0.803,0.666c0.364,0.301,0.414,0.841,0.113,1.204l-1.839,2.217
				C31.226,27.718,30.686,27.77,30.323,27.467"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#AC6E26"
          d="M33.088,25.858c-0.071,0-0.143-0.022-0.205-0.068
				c-0.152-0.113-0.186-0.329-0.071-0.481l0.278-0.375c0.146-0.178,0.167-0.409,0.063-0.594c-0.093-0.165-0.035-0.375,0.131-0.469
				c0.164-0.095,0.377-0.036,0.469,0.13c0.244,0.431,0.195,0.978-0.121,1.358l-0.266,0.358
				C33.297,25.809,33.193,25.858,33.088,25.858"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#AC6E26"
          d="M31.246,23.725c-0.078,0-0.156-0.026-0.22-0.079
				c-0.146-0.123-0.167-0.34-0.045-0.486c0.424-0.509,1.181-0.579,1.69-0.157c0.147,0.121,0.167,0.34,0.045,0.485
				c-0.121,0.147-0.337,0.166-0.485,0.045c-0.217-0.179-0.54-0.149-0.72,0.068C31.443,23.683,31.344,23.725,31.246,23.725"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="none"
          stroke="#008B4A"
          strokeWidth="2.066"
          strokeMiterlimit="10"
          d="M38.162,20.097
				c1.555,1.647,1.119,6.563,0.303,7.731"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="none"
          stroke="#FFDD00"
          strokeWidth="2.066"
          strokeMiterlimit="10"
          d="M36.291,20.307
				c0.475,0.848,0.809,1.872,0.936,2.996c0.211,1.875-0.205,3.61-1.021,4.778"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFDD00"
          d="M42.961,23.8c0.355,0,0.644,0.127,0.644,0.283s-0.288,0.284-0.644,0.284
				c-0.356,0-0.645-0.128-0.645-0.284S42.604,23.8,42.961,23.8"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFDD00"
          d="M43.551,22.672c0.252,0.251,0.366,0.546,0.256,0.656
				c-0.11,0.109-0.404-0.003-0.656-0.256c-0.252-0.252-0.365-0.546-0.256-0.655C43.005,22.306,43.301,22.42,43.551,22.672"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFDD00"
          d="M43.551,25.495c-0.25,0.251-0.546,0.365-0.656,0.255
				c-0.109-0.111,0.004-0.405,0.256-0.656c0.252-0.252,0.546-0.366,0.656-0.255C43.917,24.949,43.803,25.243,43.551,25.495"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#F15D2A"
          d="M36.585,36.913c-0.008-0.163-0.022-0.324-0.044-0.481
				c0.002,0,0.003,0,0.005-0.001c-0.192-1.642-0.985-3.4-2.11-4.562c-0.002,0.384-0.057,0.756-0.157,1.111
				c-0.113,0.401-0.286,0.779-0.509,1.126c0.056-0.333,0.086-0.679,0.086-1.031c0-0.107-0.002-0.214-0.008-0.319
				c-0.031-0.594-0.146-1.145-0.336-1.646c-0.029-0.082-0.062-0.163-0.097-0.242c-0.226-0.521-0.532-0.983-0.903-1.376
				c-0.226-0.238-0.475-0.452-0.744-0.637c-0.007,0.009-0.015,0.017-0.023,0.026c0.062,0.267,0.1,0.546,0.109,0.837
				c0.003,0.058,0.004,0.116,0.004,0.176c0,0.225-0.016,0.446-0.046,0.663c-0.02,0.149-0.049,0.297-0.083,0.44
				c-0.112,0.475-0.296,0.921-0.538,1.329c-0.103,0.174-0.215,0.342-0.339,0.498c-0.036-0.144-0.064-0.291-0.086-0.44
				c-0.032-0.224-0.049-0.451-0.049-0.686c0-0.138,0.006-0.274,0.018-0.408c0.018-0.206,0.049-0.405,0.093-0.6
				c0.037-0.16,0.082-0.315,0.136-0.468c-0.302,0.218-3.263,4.021-3.516,4.471c-0.173,0.307-0.312,0.636-0.416,0.989
				c-0.113,0.385-0.183,0.796-0.204,1.23c-0.005,0.094-0.007,0.186-0.007,0.277c0,0.292,0.023,0.578,0.064,0.857
				c0.379,2.518,2.393,4.439,4.822,4.439c1.854,0,3.467-1.119,4.293-2.768c0.352-0.701,0.563-1.498,0.588-2.345
				c0.004-0.062,0.004-0.123,0.004-0.185C36.592,37.099,36.59,37.007,36.585,36.913"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFDD00"
          d="M33.008,35.794c-0.037,0.167-0.236,0.592-0.305,0.745
				c-0.303-1.048-1.114-1.959-1.717-2.127c-0.051,1.146-1.23,1.448-2.04,3.176c-0.182,0.387-0.282,0.824-0.282,1.282
				c0,0.167,0.015,0.332,0.04,0.493v0.004c0.103,0.607,0.381,1.156,0.788,1.586c0.551,0.588,1.341,0.958,2.213,0.958
				c1.683,0,3.044-1.362,3.044-3.041C34.749,37.107,33.568,36.023,33.008,35.794"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#5087C7"
          d="M30.854,55.36c-2.638,0-4.773-2.135-4.773-4.771
				c0-2.638,2.135-4.773,4.773-4.773s4.774,2.136,4.774,4.773C35.629,53.226,33.492,55.36,30.854,55.36"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M30.854,45.816c2.638,0,4.774,2.136,4.774,4.771
				c0,2.637-2.137,4.773-4.774,4.773s-4.773-2.137-4.773-4.773C26.082,47.952,28.217,45.816,30.854,45.816 M30.854,45.404
				c-2.859,0-5.185,2.325-5.185,5.184c0,2.859,2.326,5.185,5.185,5.185c2.86,0,5.187-2.325,5.187-5.185
				C36.041,47.729,33.715,45.404,30.854,45.404"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M33.348,48.644c-0.238,0-0.463,0.107-0.619,0.251l-0.014-0.012
				c0.073-0.12,0.17-0.347,0.17-0.717c0-0.883-0.885-1.552-2.029-1.552c-1.145,0-2.041,0.669-2.041,1.552
				c0,0.37,0.119,0.597,0.179,0.717l-0.013,0.012c-0.154-0.144-0.381-0.251-0.619-0.251c-0.729,0-1.457,0.703-1.457,2.017
				c0,1.288,0.717,2.017,1.492,2.017c0.716,0,1.218-0.396,1.671-0.848c-0.072-0.107-0.18-0.216-0.298-0.216
				c-0.12,0-0.252,0.085-0.478,0.265c-0.298,0.227-0.561,0.357-0.907,0.357c-0.609,0-0.87-0.682-0.87-1.575
				c0-0.955,0.251-1.587,0.895-1.587c0.286,0,0.476,0.083,0.668,0.263c0.286,0.263,0.381,0.334,0.512,0.334
				c0.096,0,0.156-0.061,0.18-0.096c-0.215-0.215-0.525-0.668-0.525-1.253c0-0.668,0.56-1.134,1.611-1.134
				c1.049,0,1.611,0.466,1.611,1.134c0,0.585-0.312,1.038-0.525,1.253c0.023,0.035,0.083,0.096,0.178,0.096
				c0.133,0,0.227-0.071,0.514-0.334c0.179-0.18,0.381-0.263,0.668-0.263c0.645,0,0.896,0.632,0.896,1.587
				c0,0.894-0.263,1.575-0.871,1.575c-0.346,0-0.621-0.131-0.907-0.357c-0.227-0.18-0.357-0.265-0.477-0.265
				c-0.12,0-0.227,0.108-0.299,0.216c0.442,0.452,0.956,0.848,1.671,0.848c0.774,0,1.491-0.729,1.491-2.017
				C34.805,49.347,34.076,48.644,33.348,48.644"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M31.654,48.62h-0.537c-0.035,0-0.059-0.024-0.071-0.049l-0.168-0.513
				c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.513c-0.012,0.036-0.035,0.049-0.059,0.049h-0.537c-0.025,0-0.036,0.011-0.012,0.023
				l0.442,0.322c0.023,0.022,0.023,0.06,0.011,0.083l-0.167,0.501c-0.011,0.024-0.011,0.049,0.024,0.024l0.442-0.31
				c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.023,0.024,0.036,0.012,0.023-0.012l-0.166-0.514c-0.012-0.035,0-0.071,0.024-0.083
				l0.44-0.322C31.69,48.631,31.678,48.62,31.654,48.62"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M31.249,52.151c-0.226-0.573-0.406-0.966-0.406-1.443
				c0-0.204,0.06-0.382,0.096-0.477c0.048-0.097,0.06-0.156,0.012-0.156c-0.072,0-0.227,0.167-0.322,0.311
				c-0.073,0.118-0.156,0.347-0.156,0.524c0,0.335,0.108,0.691,0.179,0.907c0.132,0.37,0.251,0.799,0.251,1.206
				c0,0.645-0.287,1.084-0.799,1.478c0.047,0.037,0.167,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.323
				C31.511,52.819,31.38,52.509,31.249,52.151"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M-2.477-26.003c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.107,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.477z M-2.568-25.334c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.059-0.159-0.059h-0.125v0.45H-2.568z"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="-1.474,-26.003 -1.263,-24.958 -1.258,-24.958 -1.045,-26.003
				-0.604,-26.003 -0.604,-24.523 -0.877,-24.523 -0.877,-25.705 -0.881,-25.705 -1.144,-24.523 -1.377,-24.523 -1.64,-25.705
				-1.644,-25.705 -1.644,-24.523 -1.917,-24.523 -1.917,-26.003 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M-0.149-24.942c0,0.035,0.003,0.066,0.009,0.095
				c0.004,0.028,0.015,0.053,0.03,0.071c0.015,0.02,0.036,0.035,0.062,0.045c0.025,0.013,0.058,0.018,0.096,0.018
				c0.045,0,0.087-0.016,0.123-0.045s0.056-0.075,0.056-0.138c0-0.033-0.005-0.062-0.014-0.086
				c-0.009-0.023-0.024-0.046-0.045-0.065s-0.048-0.036-0.082-0.054c-0.033-0.015-0.075-0.03-0.125-0.048
				c-0.066-0.021-0.123-0.046-0.172-0.073c-0.048-0.025-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.326c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.022c0.056,0.015,0.104,0.037,0.145,0.069c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.183v0.041H0.204c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.058-0.124-0.058
				c-0.033,0-0.061,0.005-0.083,0.016c-0.023,0.009-0.04,0.021-0.053,0.037c-0.013,0.016-0.022,0.034-0.027,0.055
				s-0.007,0.042-0.007,0.064c0,0.046,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.06,0.124,0.086l0.23,0.1
				c0.057,0.024,0.103,0.051,0.139,0.078c0.036,0.026,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.341C0.311-24.536,0.185-24.5,0.022-24.5
				c-0.168,0-0.289-0.037-0.362-0.11c-0.072-0.073-0.108-0.179-0.108-0.314v-0.062h0.298V-24.942z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M1.441-24.757c0.021,0.045,0.065,0.067,0.13,0.067
				c0.035,0,0.064-0.006,0.086-0.018c0.021-0.014,0.037-0.03,0.049-0.052c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.029,0.005-0.06,0.005-0.092c0-0.033-0.002-0.063-0.007-0.094c-0.005-0.03-0.015-0.056-0.028-0.08
				c-0.013-0.022-0.033-0.042-0.058-0.056c-0.025-0.015-0.058-0.021-0.099-0.021h-0.1v-0.194h0.097c0.032,0,0.059-0.006,0.08-0.02
				c0.022-0.013,0.039-0.03,0.052-0.052c0.014-0.021,0.023-0.046,0.029-0.074s0.009-0.056,0.009-0.087
				c0-0.071-0.012-0.12-0.035-0.148c-0.024-0.029-0.059-0.044-0.108-0.044c-0.031,0-0.057,0.006-0.077,0.018
				s-0.036,0.029-0.047,0.05c-0.011,0.021-0.018,0.047-0.022,0.076c-0.003,0.029-0.005,0.061-0.005,0.095H1.142
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.07,0.181-0.106,0.322-0.106c0.134,0,0.237,0.029,0.312,0.091
				c0.074,0.061,0.11,0.156,0.11,0.286c0,0.089-0.019,0.161-0.059,0.217c-0.039,0.056-0.094,0.091-0.164,0.106v0.004
				c0.095,0.016,0.162,0.054,0.202,0.115c0.039,0.062,0.059,0.139,0.059,0.231c0,0.049-0.007,0.101-0.019,0.153
				c-0.012,0.052-0.037,0.1-0.072,0.143c-0.037,0.043-0.085,0.077-0.149,0.104C1.731-24.514,1.648-24.5,1.546-24.5
				c-0.142,0-0.249-0.039-0.32-0.116c-0.07-0.077-0.105-0.188-0.105-0.33v-0.006h0.286C1.408-24.866,1.42-24.802,1.441-24.757"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M2.689-25.75c-0.02-0.031-0.056-0.046-0.105-0.046
				c-0.039,0-0.07,0.01-0.095,0.027c-0.023,0.02-0.042,0.046-0.056,0.078c-0.013,0.033-0.021,0.073-0.025,0.118
				c-0.004,0.046-0.007,0.096-0.007,0.149v0.072h0.005c0.025-0.038,0.06-0.068,0.107-0.092c0.046-0.022,0.102-0.034,0.168-0.034
				c0.065,0,0.12,0.012,0.166,0.034c0.045,0.023,0.082,0.054,0.111,0.095c0.028,0.041,0.049,0.09,0.062,0.146s0.02,0.117,0.02,0.184
				c0,0.169-0.038,0.299-0.113,0.387C2.853-24.544,2.731-24.5,2.565-24.5c-0.102,0-0.183-0.019-0.244-0.054
				c-0.061-0.036-0.108-0.085-0.14-0.148c-0.033-0.064-0.054-0.136-0.064-0.219c-0.009-0.083-0.014-0.169-0.014-0.259
				c0-0.104,0.003-0.204,0.009-0.303c0.006-0.097,0.025-0.184,0.057-0.259c0.03-0.075,0.079-0.135,0.145-0.181
				c0.067-0.046,0.161-0.068,0.282-0.068c0.052,0,0.104,0.006,0.152,0.018c0.05,0.014,0.093,0.033,0.13,0.063
				s0.068,0.068,0.091,0.115c0.022,0.048,0.034,0.106,0.035,0.178H2.718C2.718-25.675,2.709-25.719,2.689-25.75 M2.422-24.863
				c0.005,0.036,0.012,0.067,0.024,0.092c0.012,0.026,0.028,0.046,0.05,0.061c0.021,0.015,0.049,0.021,0.084,0.021
				c0.036,0,0.064-0.007,0.086-0.021c0.021-0.015,0.037-0.034,0.048-0.062c0.012-0.025,0.019-0.057,0.022-0.092
				c0.004-0.035,0.005-0.074,0.005-0.115c0-0.1-0.012-0.171-0.037-0.214s-0.066-0.063-0.124-0.063c-0.06,0-0.102,0.021-0.126,0.063
				c-0.025,0.043-0.038,0.114-0.038,0.214C2.417-24.938,2.418-24.899,2.422-24.863"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M3.16-25.176c0.042-0.066,0.104-0.107,0.187-0.125v-0.004
				c-0.04-0.01-0.073-0.024-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.07c-0.016-0.026-0.028-0.054-0.035-0.083
				c-0.007-0.028-0.01-0.057-0.01-0.082c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.311c0,0.025-0.004,0.054-0.011,0.082
				c-0.008,0.029-0.02,0.057-0.037,0.083c-0.016,0.026-0.038,0.05-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.018,0.145,0.059,0.187,0.125c0.041,0.066,0.062,0.146,0.062,0.238c0,0.043-0.005,0.09-0.015,0.14
				c-0.01,0.053-0.032,0.1-0.066,0.145c-0.032,0.044-0.08,0.08-0.141,0.11C3.751-24.516,3.669-24.5,3.567-24.5
				s-0.184-0.016-0.246-0.043c-0.062-0.03-0.108-0.066-0.142-0.11c-0.033-0.045-0.054-0.092-0.065-0.145
				c-0.01-0.05-0.015-0.097-0.015-0.14C3.099-25.03,3.12-25.109,3.16-25.176 M3.405-24.844c0.004,0.031,0.013,0.059,0.026,0.081
				c0.013,0.023,0.031,0.041,0.053,0.055c0.022,0.013,0.05,0.019,0.083,0.019c0.033,0,0.06-0.006,0.083-0.019
				c0.022-0.014,0.04-0.031,0.053-0.055c0.012-0.022,0.022-0.05,0.027-0.081c0.004-0.03,0.007-0.063,0.007-0.099
				s-0.003-0.069-0.007-0.099c-0.005-0.03-0.015-0.058-0.027-0.08c-0.013-0.023-0.031-0.04-0.053-0.054
				c-0.023-0.014-0.05-0.02-0.083-0.02c-0.033,0-0.061,0.006-0.083,0.02s-0.04,0.03-0.053,0.054c-0.013,0.022-0.022,0.05-0.026,0.08
				c-0.006,0.029-0.008,0.063-0.008,0.099S3.399-24.874,3.405-24.844 M3.461-25.439c0.026,0.033,0.062,0.05,0.106,0.05
				s0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.201-0.145-0.201c-0.097,0-0.145,0.066-0.145,0.201
				C3.422-25.524,3.435-25.473,3.461-25.439"
        />
        <rect
          x="6.567"
          y="-26.501"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#008B4A"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M7.482-26.003c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.107,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59H6.96v-1.479H7.482z M7.391-25.334c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.059-0.16-0.059H7.258v0.45H7.391z"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="8.485,-26.003 8.696,-24.958 8.701,-24.958 8.914,-26.003
				9.356,-26.003 9.356,-24.523 9.082,-24.523 9.082,-25.705 9.078,-25.705 8.815,-24.523 8.582,-24.523 8.32,-25.705
				8.316,-25.705 8.316,-24.523 8.042,-24.523 8.042,-26.003 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M9.81-24.942c0,0.035,0.003,0.066,0.008,0.095s0.016,0.053,0.031,0.071
				c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.013,0.058,0.018,0.097,0.018c0.045,0,0.086-0.016,0.123-0.045
				c0.036-0.029,0.055-0.075,0.055-0.138c0-0.033-0.005-0.062-0.014-0.086c-0.008-0.023-0.024-0.046-0.044-0.065
				c-0.021-0.02-0.048-0.036-0.082-0.054c-0.033-0.015-0.075-0.03-0.125-0.048c-0.066-0.021-0.124-0.046-0.172-0.073
				c-0.049-0.025-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122c-0.015-0.046-0.023-0.1-0.023-0.161
				c0-0.146,0.041-0.255,0.122-0.326c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.022
				c0.056,0.015,0.104,0.037,0.144,0.069c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.183v0.041h-0.286
				c0-0.07-0.013-0.124-0.037-0.162C10.1-25.793,10.058-25.813,10-25.813c-0.033,0-0.061,0.005-0.083,0.016
				c-0.023,0.009-0.04,0.021-0.054,0.037c-0.013,0.016-0.021,0.034-0.026,0.055s-0.007,0.042-0.007,0.064
				c0,0.046,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.06,0.124,0.086l0.23,0.1c0.057,0.024,0.103,0.051,0.139,0.078
				c0.036,0.026,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.011,0.079,0.011,0.125
				c0,0.156-0.045,0.271-0.135,0.341c-0.09,0.071-0.216,0.107-0.378,0.107c-0.169,0-0.29-0.037-0.362-0.11
				c-0.072-0.073-0.108-0.179-0.108-0.314v-0.062H9.81V-24.942z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M11.4-24.757c0.021,0.045,0.064,0.067,0.13,0.067
				c0.035,0,0.064-0.006,0.085-0.018c0.022-0.014,0.038-0.03,0.05-0.052c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.029,0.005-0.06,0.005-0.092c0-0.033-0.002-0.063-0.007-0.094c-0.005-0.03-0.015-0.056-0.028-0.08
				c-0.014-0.022-0.033-0.042-0.058-0.056c-0.025-0.015-0.059-0.021-0.1-0.021h-0.099v-0.194h0.097c0.032,0,0.058-0.006,0.08-0.02
				c0.021-0.013,0.039-0.03,0.052-0.052c0.013-0.021,0.022-0.046,0.029-0.074c0.006-0.028,0.009-0.056,0.009-0.087
				c0-0.071-0.012-0.12-0.035-0.148c-0.024-0.029-0.059-0.044-0.108-0.044c-0.032,0-0.058,0.006-0.077,0.018
				c-0.021,0.012-0.037,0.029-0.047,0.05c-0.011,0.021-0.019,0.047-0.022,0.076s-0.005,0.061-0.005,0.095h-0.273
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.07,0.18-0.106,0.322-0.106c0.133,0,0.237,0.029,0.311,0.091
				c0.074,0.061,0.111,0.156,0.111,0.286c0,0.089-0.02,0.161-0.059,0.217c-0.039,0.056-0.094,0.091-0.164,0.106v0.004
				c0.094,0.016,0.162,0.054,0.201,0.115c0.04,0.062,0.06,0.139,0.06,0.231c0,0.049-0.007,0.101-0.019,0.153
				c-0.013,0.052-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.077-0.149,0.104c-0.062,0.025-0.146,0.039-0.248,0.039
				c-0.142,0-0.248-0.039-0.319-0.116c-0.07-0.077-0.105-0.188-0.105-0.33v-0.006h0.286C11.367-24.866,11.378-24.802,11.4-24.757"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M12.549-25.99h0.309v0.931H13v0.232h-0.141v0.304h-0.274v-0.304h-0.522
				v-0.245L12.549-25.99z M12.58-25.617l-0.286,0.558h0.29v-0.558H12.58z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M13.119-25.176c0.042-0.066,0.104-0.107,0.187-0.125v-0.004
				c-0.04-0.01-0.074-0.024-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.07c-0.016-0.026-0.028-0.054-0.035-0.083
				c-0.007-0.028-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.115-0.31c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.315,0.094c0.077,0.063,0.116,0.166,0.116,0.31c0,0.026-0.004,0.055-0.012,0.083
				c-0.007,0.029-0.019,0.057-0.036,0.083c-0.016,0.026-0.038,0.05-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.018,0.145,0.059,0.187,0.125c0.041,0.066,0.062,0.146,0.062,0.238c0,0.043-0.005,0.09-0.015,0.14
				c-0.011,0.053-0.033,0.1-0.066,0.145c-0.033,0.044-0.08,0.08-0.142,0.11c-0.061,0.027-0.143,0.043-0.245,0.043
				s-0.184-0.016-0.246-0.043c-0.062-0.03-0.109-0.066-0.142-0.11c-0.033-0.045-0.055-0.092-0.065-0.145
				c-0.011-0.05-0.016-0.097-0.016-0.14C13.057-25.03,13.078-25.109,13.119-25.176 M13.363-24.844
				c0.005,0.031,0.014,0.059,0.027,0.081c0.013,0.023,0.031,0.041,0.053,0.054c0.022,0.014,0.05,0.02,0.083,0.02
				c0.033,0,0.06-0.006,0.083-0.02c0.021-0.013,0.039-0.03,0.052-0.054c0.013-0.022,0.023-0.05,0.027-0.081
				c0.005-0.031,0.008-0.063,0.008-0.099s-0.003-0.069-0.008-0.099c-0.004-0.03-0.014-0.058-0.027-0.08
				c-0.013-0.023-0.031-0.04-0.052-0.054c-0.023-0.014-0.05-0.02-0.083-0.02c-0.033,0-0.061,0.006-0.083,0.02
				s-0.04,0.03-0.053,0.054c-0.013,0.022-0.022,0.05-0.027,0.08c-0.005,0.029-0.007,0.063-0.007,0.099S13.358-24.875,13.363-24.844
				 M13.42-25.439c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155
				c0-0.135-0.048-0.201-0.145-0.201c-0.097,0-0.145,0.066-0.145,0.201C13.381-25.524,13.394-25.473,13.42-25.439"
        />
        <rect
          x="16.98"
          y="-26.501"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFDD00"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M17.894-26.003c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.107,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.026-0.193,0.026h-0.16v0.591h-0.298v-1.479H17.894z M17.803-25.334c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.059-0.16-0.059H17.67v0.45H17.803z"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="18.897,-26.003 19.108,-24.958 19.113,-24.958 19.326,-26.003
				19.768,-26.003 19.768,-24.523 19.494,-24.523 19.494,-25.705 19.49,-25.705 19.227,-24.523 18.994,-24.523 18.732,-25.705
				18.728,-25.705 18.728,-24.523 18.454,-24.523 18.454,-26.003 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M20.222-24.942c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.053,0.031,0.071c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.013,0.058,0.018,0.097,0.018
				c0.045,0,0.086-0.016,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.138c0-0.033-0.005-0.062-0.014-0.086
				c-0.008-0.023-0.024-0.046-0.044-0.065c-0.021-0.02-0.048-0.036-0.082-0.054c-0.033-0.015-0.075-0.03-0.125-0.048
				c-0.066-0.021-0.124-0.046-0.172-0.073c-0.049-0.025-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.326c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.022
				c0.056,0.015,0.104,0.037,0.144,0.069c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.183v0.041h-0.286
				c0-0.07-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.058-0.124-0.058c-0.033,0-0.061,0.005-0.083,0.016
				c-0.023,0.009-0.04,0.021-0.054,0.037c-0.013,0.016-0.021,0.034-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.064
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.06,0.124,0.086l0.23,0.1c0.057,0.024,0.103,0.051,0.139,0.078
				c0.036,0.026,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.271-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107c-0.169,0-0.29-0.037-0.362-0.11
				c-0.072-0.073-0.108-0.179-0.108-0.314v-0.062h0.298V-24.942z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M21.828-25.56h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.235-0.056
				c0.057-0.038,0.094-0.097,0.109-0.18h0.219v1.467h-0.298V-25.56z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M22.494-25.502c0.006-0.094,0.025-0.177,0.056-0.249
				c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.147-0.066,0.256-0.066c0.09,0,0.164,0.014,0.223,0.039
				c0.059,0.025,0.106,0.066,0.141,0.123s0.06,0.13,0.075,0.221c0.014,0.091,0.022,0.2,0.022,0.331c0,0.102-0.004,0.199-0.011,0.294
				c-0.007,0.094-0.026,0.178-0.056,0.25c-0.03,0.071-0.076,0.13-0.138,0.171c-0.061,0.042-0.147,0.063-0.256,0.063
				c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.023-0.107-0.063-0.142-0.12c-0.036-0.056-0.061-0.13-0.075-0.221
				c-0.014-0.09-0.022-0.201-0.022-0.33C22.483-25.31,22.486-25.408,22.494-25.502 M22.789-24.916
				c0.005,0.06,0.013,0.105,0.026,0.14c0.012,0.033,0.028,0.057,0.05,0.068c0.02,0.013,0.046,0.019,0.078,0.019
				c0.033,0,0.061-0.008,0.081-0.023s0.037-0.043,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.153
				c0.004-0.063,0.007-0.142,0.007-0.235v-0.159c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.06-0.014-0.105-0.026-0.14
				c-0.013-0.034-0.03-0.057-0.05-0.068c-0.021-0.014-0.047-0.02-0.079-0.02c-0.033,0-0.06,0.008-0.08,0.024
				c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.088-0.025,0.151c-0.005,0.063-0.008,0.143-0.008,0.236v0.16
				C22.781-25.051,22.784-24.975,22.789-24.916"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M23.82-24.736c0.02,0.032,0.055,0.047,0.105,0.047
				c0.04,0,0.072-0.009,0.096-0.027c0.023-0.02,0.041-0.045,0.054-0.078s0.022-0.072,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.025,0.037-0.06,0.067-0.106,0.091c-0.047,0.022-0.103,0.034-0.169,0.034
				c-0.065,0-0.12-0.012-0.166-0.034c-0.046-0.023-0.083-0.056-0.111-0.097c-0.028-0.04-0.049-0.089-0.062-0.144
				c-0.014-0.057-0.02-0.118-0.02-0.185c0-0.17,0.038-0.3,0.113-0.39c0.075-0.089,0.196-0.134,0.362-0.134
				c0.102,0,0.183,0.018,0.244,0.057c0.061,0.038,0.108,0.088,0.14,0.151c0.032,0.063,0.054,0.136,0.064,0.219
				c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.004,0.206-0.009,0.304c-0.006,0.098-0.025,0.184-0.057,0.259
				c-0.03,0.074-0.08,0.133-0.146,0.176c-0.068,0.044-0.162,0.066-0.281,0.066c-0.053,0-0.104-0.005-0.153-0.017
				c-0.049-0.01-0.092-0.029-0.129-0.058c-0.037-0.028-0.068-0.066-0.091-0.114s-0.034-0.106-0.036-0.177h0.287
				C23.791-24.811,23.8-24.768,23.82-24.736 M23.805-25.292c0.025,0.043,0.066,0.063,0.124,0.063c0.059,0,0.102-0.021,0.126-0.063
				c0.025-0.042,0.038-0.114,0.038-0.213c0-0.042-0.002-0.08-0.006-0.117c-0.004-0.035-0.012-0.066-0.024-0.091
				c-0.012-0.026-0.028-0.047-0.05-0.062c-0.021-0.014-0.049-0.021-0.084-0.021c-0.036,0-0.064,0.008-0.086,0.021
				c-0.021,0.015-0.037,0.035-0.049,0.062c-0.011,0.026-0.018,0.057-0.021,0.093c-0.004,0.035-0.005,0.073-0.005,0.115
				C23.768-25.406,23.78-25.334,23.805-25.292"
        />
        <rect
          x="26.85"
          y="-26.501"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#F15D2A"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M27.764-26.003c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.107,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.026-0.193,0.026h-0.16v0.591h-0.298v-1.479H27.764z M27.672-25.334c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.059-0.159-0.059H27.54v0.45H27.672z"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="28.767,-26.003 28.978,-24.958 28.983,-24.958 29.196,-26.003
				29.637,-26.003 29.637,-24.523 29.364,-24.523 29.364,-25.705 29.36,-25.705 29.097,-24.523 28.864,-24.523 28.601,-25.705
				28.597,-25.705 28.597,-24.523 28.323,-24.523 28.323,-26.003 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M30.091-24.942c0,0.035,0.003,0.066,0.009,0.095
				c0.004,0.028,0.015,0.053,0.03,0.071c0.015,0.02,0.036,0.035,0.062,0.045c0.025,0.013,0.058,0.018,0.096,0.018
				c0.045,0,0.087-0.016,0.123-0.045s0.056-0.075,0.056-0.138c0-0.033-0.005-0.062-0.014-0.086
				c-0.009-0.023-0.024-0.046-0.045-0.065s-0.048-0.036-0.082-0.054c-0.033-0.015-0.075-0.03-0.125-0.048
				c-0.066-0.021-0.123-0.046-0.172-0.073c-0.048-0.025-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.326c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.022c0.056,0.015,0.104,0.037,0.145,0.069c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.183v0.041h-0.285c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.058-0.124-0.058
				c-0.033,0-0.061,0.005-0.083,0.016c-0.023,0.009-0.04,0.021-0.053,0.037c-0.013,0.016-0.022,0.034-0.027,0.055
				s-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.06,0.124,0.086l0.23,0.1
				c0.057,0.024,0.103,0.051,0.139,0.078c0.036,0.026,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.168,0-0.289-0.037-0.362-0.11c-0.072-0.073-0.108-0.179-0.108-0.314v-0.062h0.298V-24.942z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M31.698-25.56h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.056
				c0.058-0.038,0.094-0.097,0.109-0.18h0.22v1.467h-0.298V-25.56z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M32.93-25.75c-0.02-0.031-0.056-0.046-0.104-0.046
				c-0.039,0-0.07,0.01-0.095,0.027c-0.023,0.02-0.043,0.045-0.057,0.078c-0.013,0.033-0.021,0.073-0.025,0.118
				c-0.004,0.046-0.006,0.096-0.006,0.149v0.072h0.004c0.025-0.039,0.061-0.068,0.107-0.092c0.047-0.022,0.102-0.034,0.168-0.034
				c0.065,0,0.12,0.012,0.166,0.034c0.045,0.023,0.082,0.054,0.111,0.095c0.027,0.041,0.049,0.09,0.063,0.146
				c0.012,0.056,0.02,0.117,0.02,0.184c0,0.169-0.038,0.298-0.113,0.387c-0.074,0.088-0.195,0.132-0.361,0.132
				c-0.103,0-0.184-0.019-0.244-0.055c-0.062-0.035-0.108-0.085-0.141-0.147c-0.033-0.064-0.054-0.136-0.064-0.219
				c-0.008-0.083-0.014-0.169-0.014-0.26c0-0.104,0.004-0.203,0.01-0.302c0.006-0.097,0.024-0.184,0.057-0.259
				c0.029-0.075,0.079-0.135,0.145-0.181c0.067-0.046,0.162-0.068,0.282-0.068c0.052,0,0.104,0.006,0.152,0.018
				c0.05,0.014,0.093,0.033,0.13,0.063s0.068,0.068,0.091,0.115c0.022,0.048,0.034,0.106,0.035,0.178h-0.286
				C32.959-25.675,32.95-25.719,32.93-25.75 M32.663-24.863c0.005,0.036,0.013,0.067,0.024,0.092
				c0.012,0.026,0.027,0.046,0.05,0.061c0.021,0.015,0.049,0.021,0.084,0.021c0.036,0,0.063-0.007,0.086-0.021
				c0.021-0.015,0.037-0.034,0.048-0.062c0.012-0.025,0.02-0.057,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.115
				c0-0.1-0.012-0.171-0.037-0.214s-0.066-0.063-0.124-0.063c-0.06,0-0.103,0.021-0.126,0.063c-0.025,0.043-0.038,0.114-0.038,0.214
				C32.657-24.938,32.659-24.899,32.663-24.863"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M33.65-25.729l-0.031,0.334h0.004c0.027-0.039,0.063-0.067,0.107-0.085
				c0.045-0.017,0.098-0.025,0.161-0.025c0.071,0,0.13,0.014,0.177,0.04c0.045,0.027,0.082,0.063,0.108,0.107
				s0.046,0.094,0.058,0.149c0.01,0.057,0.017,0.113,0.017,0.172c0,0.082-0.007,0.155-0.021,0.222
				c-0.014,0.065-0.039,0.123-0.074,0.171s-0.084,0.083-0.144,0.108c-0.062,0.023-0.138,0.035-0.231,0.035s-0.168-0.012-0.226-0.036
				c-0.058-0.025-0.101-0.059-0.132-0.1c-0.03-0.041-0.051-0.085-0.061-0.132c-0.01-0.048-0.017-0.093-0.017-0.134v-0.039h0.286
				c0,0.023,0.002,0.05,0.005,0.078c0.002,0.027,0.009,0.054,0.019,0.078c0.012,0.023,0.025,0.044,0.045,0.06
				s0.045,0.023,0.076,0.023c0.063,0,0.107-0.023,0.135-0.071c0.027-0.047,0.041-0.119,0.041-0.214c0-0.062-0.004-0.111-0.01-0.151
				c-0.004-0.041-0.015-0.072-0.027-0.096c-0.014-0.024-0.03-0.04-0.053-0.049c-0.022-0.011-0.049-0.016-0.082-0.016
				c-0.043,0-0.076,0.013-0.1,0.036c-0.023,0.024-0.037,0.058-0.042,0.1h-0.269l0.055-0.799h0.766v0.232H33.65z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M34.645-25.729l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.085
				c0.044-0.017,0.099-0.025,0.161-0.025c0.07,0,0.129,0.014,0.176,0.04c0.047,0.027,0.083,0.063,0.109,0.107
				c0.027,0.044,0.046,0.094,0.057,0.149c0.012,0.057,0.018,0.113,0.018,0.172c0,0.082-0.008,0.155-0.021,0.222
				c-0.013,0.065-0.038,0.123-0.072,0.171c-0.037,0.048-0.084,0.083-0.145,0.108c-0.061,0.023-0.139,0.035-0.232,0.035
				c-0.093,0-0.168-0.012-0.225-0.036c-0.059-0.025-0.102-0.059-0.132-0.1s-0.052-0.085-0.062-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.286c0,0.023,0.002,0.05,0.005,0.078c0.002,0.027,0.009,0.054,0.02,0.078
				c0.01,0.023,0.024,0.044,0.045,0.06c0.019,0.016,0.045,0.023,0.075,0.023c0.063,0,0.107-0.023,0.134-0.071
				c0.027-0.047,0.041-0.119,0.041-0.214c0-0.062-0.002-0.111-0.009-0.151c-0.005-0.041-0.015-0.072-0.028-0.096
				c-0.013-0.024-0.029-0.04-0.053-0.049c-0.021-0.011-0.049-0.016-0.082-0.016c-0.043,0-0.076,0.013-0.099,0.036
				c-0.022,0.024-0.037,0.058-0.042,0.1h-0.268l0.054-0.799h0.765v0.232H34.645z"
        />
        <rect
          x="36.603"
          y="-26.501"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#623B23"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M37.517-26.003c0.075,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.031,0.041,0.055,0.089,0.068,0.142c0.014,0.054,0.02,0.107,0.02,0.163
				c0,0.076-0.01,0.143-0.033,0.198c-0.023,0.058-0.055,0.104-0.096,0.14s-0.09,0.063-0.147,0.081
				c-0.058,0.018-0.123,0.026-0.192,0.026h-0.16v0.591h-0.299v-1.479H37.517z M37.426-25.334c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.056-0.092,0.056-0.168c0-0.074-0.017-0.131-0.049-0.17c-0.031-0.038-0.084-0.059-0.158-0.059h-0.125v0.45H37.426z
				"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="38.52,-26.003 38.73,-24.958 38.736,-24.958 38.949,-26.003
				39.391,-26.003 39.391,-24.523 39.117,-24.523 39.117,-25.705 39.113,-25.705 38.85,-24.523 38.617,-24.523 38.354,-25.705
				38.35,-25.705 38.35,-24.523 38.076,-24.523 38.076,-26.003 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M39.844-24.942c0,0.035,0.004,0.066,0.01,0.095
				c0.004,0.028,0.015,0.053,0.029,0.071c0.016,0.02,0.037,0.035,0.063,0.045c0.025,0.013,0.059,0.018,0.096,0.018
				c0.045,0,0.088-0.016,0.123-0.045c0.036-0.029,0.057-0.075,0.057-0.138c0-0.034-0.006-0.062-0.015-0.086
				c-0.009-0.023-0.024-0.046-0.045-0.065s-0.048-0.036-0.082-0.054c-0.032-0.015-0.075-0.03-0.125-0.048
				c-0.065-0.021-0.123-0.046-0.172-0.073c-0.048-0.025-0.089-0.056-0.121-0.092c-0.032-0.035-0.058-0.076-0.071-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.326c0.08-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.022c0.056,0.015,0.104,0.037,0.146,0.069c0.041,0.033,0.072,0.074,0.095,0.124
				c0.022,0.051,0.034,0.111,0.034,0.183v0.041h-0.285c0-0.07-0.014-0.124-0.038-0.162s-0.065-0.058-0.124-0.058
				c-0.033,0-0.061,0.005-0.083,0.016c-0.022,0.009-0.04,0.021-0.053,0.037s-0.022,0.034-0.027,0.055s-0.007,0.042-0.007,0.064
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.06,0.123,0.086l0.23,0.1c0.057,0.024,0.104,0.051,0.139,0.078
				c0.037,0.026,0.065,0.056,0.086,0.087c0.022,0.03,0.037,0.065,0.045,0.102c0.009,0.038,0.013,0.079,0.013,0.125
				c0,0.156-0.046,0.271-0.136,0.341c-0.09,0.071-0.217,0.107-0.379,0.107c-0.168,0-0.289-0.037-0.361-0.11
				s-0.107-0.179-0.107-0.314v-0.062h0.297V-24.942z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M41.589-25.99h0.309v0.931h0.142v0.231h-0.142v0.305h-0.272v-0.305h-0.522
				v-0.244L41.589-25.99z M41.621-25.617l-0.287,0.558h0.291v-0.558H41.621z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M42.684-25.75c-0.021-0.031-0.057-0.046-0.105-0.046
				c-0.039,0-0.07,0.01-0.095,0.027c-0.023,0.02-0.042,0.045-0.056,0.078s-0.021,0.073-0.025,0.118
				c-0.004,0.046-0.008,0.096-0.008,0.149v0.072H42.4c0.025-0.039,0.06-0.068,0.107-0.092c0.045-0.022,0.102-0.034,0.168-0.034
				c0.064,0,0.119,0.012,0.166,0.034c0.045,0.023,0.082,0.054,0.11,0.095s0.049,0.09,0.062,0.146
				c0.014,0.056,0.021,0.117,0.021,0.184c0,0.169-0.038,0.298-0.112,0.387c-0.076,0.088-0.197,0.132-0.363,0.132
				c-0.102,0-0.183-0.019-0.243-0.055c-0.062-0.035-0.108-0.085-0.14-0.147c-0.033-0.064-0.055-0.136-0.064-0.219
				s-0.014-0.169-0.014-0.26c0-0.104,0.002-0.204,0.009-0.302c0.006-0.097,0.024-0.184,0.057-0.259
				c0.03-0.075,0.079-0.135,0.146-0.181c0.066-0.047,0.16-0.068,0.281-0.068c0.053,0,0.104,0.006,0.152,0.018
				c0.05,0.014,0.093,0.033,0.13,0.063s0.068,0.068,0.091,0.115c0.022,0.048,0.034,0.106,0.035,0.178h-0.285
				C42.713-25.675,42.703-25.719,42.684-25.75 M42.416-24.863c0.006,0.036,0.012,0.067,0.024,0.092
				c0.012,0.026,0.028,0.046,0.05,0.061s0.049,0.021,0.084,0.021c0.036,0,0.064-0.007,0.086-0.021s0.037-0.034,0.048-0.062
				c0.013-0.025,0.019-0.057,0.022-0.092s0.005-0.074,0.005-0.115c0-0.1-0.013-0.171-0.037-0.214s-0.065-0.063-0.124-0.063
				c-0.061,0-0.102,0.021-0.126,0.063s-0.038,0.114-0.038,0.214C42.41-24.938,42.412-24.899,42.416-24.863"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M43.443-24.736c0.02,0.032,0.055,0.047,0.104,0.047
				c0.04,0,0.071-0.009,0.096-0.028c0.022-0.019,0.042-0.044,0.054-0.077c0.014-0.033,0.022-0.072,0.026-0.118
				c0.005-0.045,0.007-0.096,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.067-0.106,0.091c-0.045,0.022-0.103,0.033-0.168,0.033
				s-0.121-0.011-0.166-0.033c-0.047-0.023-0.084-0.056-0.111-0.097c-0.028-0.04-0.049-0.089-0.063-0.144
				c-0.013-0.058-0.019-0.118-0.019-0.185c0-0.17,0.036-0.3,0.112-0.39c0.076-0.089,0.195-0.134,0.361-0.134
				c0.102,0,0.184,0.018,0.244,0.057c0.061,0.038,0.107,0.088,0.141,0.151c0.032,0.063,0.053,0.136,0.063,0.219
				c0.009,0.082,0.015,0.169,0.015,0.259c0,0.105-0.004,0.206-0.01,0.304c-0.007,0.098-0.025,0.184-0.057,0.259
				c-0.03,0.074-0.079,0.133-0.146,0.176c-0.066,0.044-0.16,0.066-0.281,0.066c-0.053,0-0.103-0.006-0.152-0.017
				c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.106-0.037-0.177h0.285
				C43.412-24.811,43.423-24.768,43.443-24.736 M43.428-25.292c0.025,0.043,0.066,0.063,0.124,0.063c0.06,0,0.103-0.021,0.127-0.063
				c0.024-0.042,0.037-0.114,0.037-0.214c0-0.041-0.003-0.079-0.007-0.116c-0.004-0.035-0.012-0.066-0.023-0.091
				c-0.011-0.026-0.027-0.047-0.05-0.062c-0.021-0.014-0.05-0.022-0.084-0.022c-0.035,0-0.063,0.009-0.086,0.022
				c-0.021,0.015-0.037,0.035-0.048,0.062c-0.012,0.026-0.02,0.057-0.022,0.093c-0.003,0.035-0.005,0.073-0.005,0.114
				C43.391-25.406,43.402-25.334,43.428-25.292"
        />
        <rect
          x="46.494"
          y="-26.501"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#AC6E26"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M47.407-26.003c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.031,0.041,0.055,0.089,0.068,0.142c0.014,0.054,0.02,0.107,0.02,0.163
				c0,0.075-0.012,0.143-0.033,0.198c-0.023,0.058-0.055,0.104-0.096,0.14s-0.09,0.063-0.147,0.081
				c-0.058,0.018-0.123,0.026-0.192,0.026h-0.16v0.591h-0.299v-1.479H47.407z M47.316-25.334c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.056-0.092,0.056-0.168c0-0.074-0.017-0.131-0.049-0.17c-0.031-0.038-0.084-0.059-0.16-0.059h-0.123v0.45H47.316z"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="48.41,-26.003 48.621,-24.958 48.626,-24.958 48.84,-26.003
				49.281,-26.003 49.281,-24.523 49.008,-24.523 49.008,-25.705 49.004,-25.705 48.74,-24.523 48.508,-24.523 48.245,-25.705
				48.241,-25.705 48.241,-24.523 47.967,-24.523 47.967,-26.003 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M49.734-24.942c0,0.035,0.004,0.066,0.008,0.095
				c0.006,0.028,0.017,0.053,0.031,0.071c0.016,0.02,0.037,0.035,0.062,0.045c0.026,0.013,0.058,0.018,0.097,0.018
				c0.045,0,0.086-0.016,0.123-0.046c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.004-0.062-0.014-0.086
				c-0.008-0.023-0.023-0.046-0.044-0.065s-0.048-0.036-0.082-0.054c-0.032-0.015-0.075-0.03-0.125-0.048
				c-0.065-0.021-0.124-0.046-0.172-0.073c-0.049-0.025-0.089-0.056-0.121-0.092c-0.032-0.035-0.057-0.076-0.071-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.326c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.185,0.022c0.055,0.015,0.104,0.037,0.143,0.069c0.043,0.033,0.074,0.074,0.097,0.124
				c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.069-0.013-0.123-0.038-0.161c-0.023-0.038-0.066-0.058-0.123-0.058
				c-0.033,0-0.061,0.005-0.083,0.016c-0.022,0.009-0.04,0.021-0.054,0.037s-0.021,0.034-0.026,0.055s-0.007,0.042-0.007,0.064
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.06,0.123,0.086l0.23,0.1c0.057,0.024,0.104,0.051,0.139,0.077
				c0.037,0.027,0.064,0.057,0.086,0.088c0.021,0.03,0.037,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.045,0.27-0.135,0.341S50.068-24.5,49.906-24.5c-0.168,0-0.289-0.037-0.361-0.11s-0.107-0.179-0.107-0.314v-0.062
				h0.297V-24.942z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M51.341-25.56h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.234-0.056
				c0.057-0.038,0.094-0.097,0.109-0.18h0.219v1.467h-0.298V-25.56z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M52.32-24.757c0.021,0.045,0.064,0.067,0.131,0.067
				c0.034,0,0.063-0.006,0.084-0.019c0.022-0.013,0.039-0.029,0.051-0.051c0.012-0.021,0.019-0.047,0.022-0.076
				c0.003-0.028,0.005-0.059,0.005-0.091c0-0.033-0.002-0.063-0.007-0.094s-0.015-0.056-0.028-0.08
				c-0.014-0.023-0.033-0.042-0.058-0.056c-0.024-0.015-0.06-0.021-0.101-0.021h-0.099v-0.194h0.097c0.033,0,0.059-0.007,0.08-0.02
				s0.039-0.03,0.053-0.052c0.013-0.021,0.021-0.046,0.029-0.074c0.006-0.028,0.008-0.056,0.008-0.087
				c0-0.071-0.012-0.12-0.034-0.149c-0.024-0.028-0.06-0.043-0.108-0.043c-0.031,0-0.058,0.005-0.076,0.018
				c-0.021,0.012-0.037,0.028-0.048,0.05c-0.011,0.021-0.019,0.047-0.022,0.076c-0.002,0.029-0.004,0.061-0.004,0.095h-0.273
				c0-0.147,0.036-0.255,0.109-0.326c0.072-0.071,0.18-0.106,0.322-0.106c0.133,0,0.236,0.029,0.311,0.091
				c0.074,0.061,0.111,0.156,0.111,0.286c0,0.089-0.021,0.161-0.06,0.217s-0.094,0.091-0.164,0.105v0.005
				c0.095,0.016,0.162,0.054,0.201,0.115c0.04,0.062,0.06,0.139,0.06,0.231c0,0.049-0.007,0.101-0.019,0.153
				c-0.013,0.052-0.037,0.1-0.072,0.143c-0.036,0.043-0.086,0.077-0.149,0.104c-0.062,0.025-0.146,0.038-0.248,0.038
				c-0.141,0-0.248-0.038-0.318-0.115S52-24.805,52-24.946v-0.006h0.287C52.287-24.866,52.299-24.802,52.32-24.757"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M53.334-24.736c0.02,0.032,0.055,0.047,0.104,0.047
				c0.04,0,0.071-0.009,0.096-0.028c0.022-0.019,0.041-0.044,0.054-0.077c0.014-0.033,0.022-0.072,0.026-0.118
				c0.005-0.045,0.007-0.096,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.067-0.106,0.091c-0.047,0.022-0.103,0.033-0.168,0.033
				s-0.121-0.011-0.166-0.033c-0.047-0.023-0.084-0.056-0.111-0.097c-0.028-0.04-0.049-0.089-0.063-0.144
				c-0.014-0.058-0.02-0.118-0.02-0.185c0-0.17,0.038-0.3,0.113-0.39c0.074-0.089,0.195-0.134,0.361-0.134
				c0.102,0,0.184,0.018,0.244,0.057c0.061,0.038,0.107,0.088,0.141,0.151c0.031,0.063,0.054,0.136,0.063,0.219
				c0.009,0.082,0.015,0.169,0.015,0.259c0,0.105-0.004,0.206-0.01,0.304c-0.007,0.098-0.025,0.184-0.057,0.259
				c-0.03,0.074-0.08,0.133-0.146,0.176c-0.068,0.044-0.162,0.066-0.281,0.066c-0.053,0-0.104-0.006-0.152-0.017
				c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.068-0.066-0.092-0.114c-0.022-0.048-0.033-0.106-0.035-0.177h0.285
				C53.303-24.811,53.313-24.768,53.334-24.736 M53.318-25.292c0.025,0.043,0.066,0.063,0.124,0.063c0.06,0,0.103-0.021,0.126-0.063
				c0.025-0.042,0.038-0.114,0.038-0.214c0-0.041-0.002-0.079-0.007-0.116c-0.004-0.035-0.012-0.066-0.023-0.091
				c-0.012-0.026-0.027-0.047-0.05-0.062c-0.021-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.063,0.009-0.086,0.022
				c-0.021,0.015-0.037,0.035-0.05,0.062c-0.01,0.026-0.018,0.057-0.021,0.093c-0.004,0.035-0.005,0.073-0.005,0.114
				C53.281-25.406,53.293-25.334,53.318-25.292"
        />
        <rect
          x="56.361"
          y="-26.501"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#5087C7"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M57.275-26.003c0.076,0,0.141,0.012,0.192,0.038s0.094,0.059,0.125,0.101
				c0.032,0.041,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.107,0.02,0.163c0,0.075-0.012,0.143-0.034,0.198
				c-0.022,0.058-0.055,0.104-0.095,0.14c-0.041,0.036-0.092,0.063-0.148,0.081c-0.058,0.018-0.123,0.026-0.193,0.026h-0.159v0.591
				h-0.298v-1.479H57.275z M57.185-25.334c0.06,0,0.106-0.018,0.144-0.054s0.056-0.092,0.056-0.168c0-0.074-0.017-0.131-0.048-0.17
				c-0.032-0.038-0.086-0.059-0.16-0.059h-0.124v0.45H57.185z"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="58.278,-26.003 58.49,-24.958 58.494,-24.958 58.707,-26.003
				59.149,-26.003 59.149,-24.523 58.875,-24.523 58.875,-25.705 58.871,-25.705 58.608,-24.523 58.375,-24.523 58.113,-25.705
				58.109,-25.705 58.109,-24.523 57.836,-24.523 57.836,-26.003 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M59.604-24.942c0,0.035,0.003,0.066,0.008,0.095s0.016,0.053,0.031,0.071
				c0.015,0.02,0.035,0.035,0.061,0.045c0.026,0.013,0.059,0.018,0.098,0.018c0.045,0,0.086-0.016,0.123-0.046
				c0.035-0.028,0.055-0.074,0.055-0.137c0-0.034-0.006-0.062-0.014-0.086c-0.008-0.023-0.024-0.046-0.045-0.065
				s-0.048-0.036-0.082-0.054c-0.033-0.015-0.074-0.03-0.125-0.048c-0.065-0.021-0.123-0.046-0.172-0.073
				c-0.049-0.025-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122c-0.014-0.046-0.022-0.1-0.022-0.161
				c0-0.146,0.041-0.255,0.122-0.326c0.082-0.072,0.193-0.108,0.336-0.108c0.066,0,0.128,0.007,0.184,0.022
				c0.057,0.015,0.104,0.037,0.145,0.069c0.042,0.033,0.072,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.069-0.013-0.123-0.037-0.161s-0.066-0.058-0.123-0.058c-0.033,0-0.062,0.005-0.084,0.016
				c-0.022,0.009-0.039,0.021-0.054,0.037c-0.013,0.016-0.021,0.034-0.026,0.055c-0.004,0.021-0.006,0.042-0.006,0.064
				c0,0.045,0.01,0.083,0.028,0.115c0.019,0.031,0.06,0.06,0.124,0.086l0.23,0.1c0.057,0.024,0.103,0.051,0.139,0.077
				c0.035,0.027,0.064,0.057,0.086,0.088c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.011,0.079,0.011,0.125
				c0,0.156-0.045,0.27-0.135,0.341S59.938-24.5,59.775-24.5c-0.169,0-0.29-0.037-0.362-0.11c-0.071-0.073-0.108-0.179-0.108-0.314
				v-0.062h0.299V-24.942z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M60.869-24.523c0-0.064,0.007-0.126,0.02-0.183
				c0.014-0.057,0.033-0.11,0.061-0.161s0.061-0.102,0.102-0.15c0.041-0.05,0.088-0.099,0.142-0.147l0.12-0.112
				c0.033-0.03,0.061-0.056,0.08-0.081c0.021-0.024,0.035-0.05,0.047-0.075c0.011-0.024,0.019-0.052,0.021-0.081
				c0.004-0.028,0.006-0.062,0.006-0.099c0-0.122-0.049-0.183-0.146-0.183c-0.037,0-0.067,0.008-0.088,0.022
				c-0.022,0.016-0.038,0.036-0.05,0.063s-0.02,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.109h-0.287v-0.057
				c0-0.136,0.037-0.241,0.109-0.314c0.074-0.073,0.188-0.109,0.347-0.109c0.149,0,0.26,0.034,0.332,0.103
				c0.071,0.069,0.108,0.169,0.108,0.297c0,0.047-0.004,0.091-0.014,0.132c-0.008,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.037-0.041,0.073-0.07,0.108c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.152c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.041,0.071-0.047,0.1h0.553v0.231H60.869z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M62.776-25.733c-0.062,0.086-0.116,0.178-0.163,0.274
				c-0.047,0.1-0.089,0.2-0.126,0.304c-0.035,0.103-0.065,0.21-0.089,0.315c-0.023,0.108-0.041,0.213-0.053,0.316h-0.314
				c0.012-0.088,0.027-0.181,0.051-0.278c0.021-0.098,0.051-0.196,0.088-0.298c0.037-0.101,0.082-0.202,0.133-0.304
				c0.054-0.104,0.115-0.203,0.187-0.301h-0.617v-0.258h0.904V-25.733z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M63.199-25.56h-0.293v-0.195h0.027c0.1,0,0.178-0.018,0.235-0.056
				c0.057-0.038,0.094-0.097,0.108-0.18h0.219v1.467h-0.297V-25.56z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M63.907-25.176c0.042-0.066,0.104-0.107,0.187-0.125v-0.004
				c-0.039-0.01-0.073-0.024-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.07c-0.016-0.026-0.027-0.054-0.034-0.083
				c-0.007-0.028-0.011-0.057-0.011-0.083c0-0.144,0.039-0.247,0.115-0.31c0.076-0.063,0.182-0.094,0.316-0.094
				c0.134,0,0.238,0.03,0.314,0.094c0.078,0.063,0.116,0.166,0.116,0.31c0,0.026-0.004,0.055-0.012,0.083
				c-0.007,0.029-0.019,0.057-0.036,0.083c-0.016,0.026-0.038,0.05-0.064,0.07c-0.027,0.021-0.061,0.036-0.1,0.046v0.004
				c0.083,0.018,0.145,0.059,0.188,0.125c0.041,0.066,0.062,0.146,0.062,0.238c0,0.043-0.005,0.09-0.015,0.14
				c-0.012,0.053-0.033,0.1-0.066,0.145c-0.033,0.044-0.08,0.08-0.142,0.109c-0.062,0.028-0.144,0.043-0.245,0.043
				s-0.184-0.015-0.246-0.043c-0.063-0.029-0.109-0.065-0.143-0.109c-0.032-0.045-0.055-0.092-0.064-0.145
				c-0.012-0.05-0.016-0.097-0.016-0.14C63.846-25.03,63.866-25.109,63.907-25.176 M64.151-24.844
				c0.005,0.031,0.015,0.059,0.026,0.081c0.014,0.023,0.031,0.041,0.054,0.054c0.022,0.014,0.05,0.02,0.083,0.02
				s0.061-0.006,0.083-0.02c0.021-0.013,0.039-0.03,0.052-0.054c0.014-0.022,0.023-0.05,0.027-0.081
				c0.005-0.031,0.008-0.063,0.008-0.099s-0.003-0.069-0.008-0.1c-0.004-0.029-0.014-0.057-0.027-0.079
				c-0.013-0.023-0.031-0.04-0.052-0.054c-0.022-0.014-0.05-0.02-0.083-0.02s-0.061,0.006-0.083,0.02s-0.04,0.03-0.054,0.054
				c-0.012,0.022-0.021,0.05-0.026,0.079c-0.005,0.03-0.007,0.064-0.007,0.1S64.146-24.875,64.151-24.844 M64.209-25.439
				c0.025,0.033,0.061,0.05,0.105,0.05c0.044,0,0.079-0.017,0.105-0.05s0.039-0.085,0.039-0.155c0-0.135-0.048-0.201-0.145-0.201
				c-0.098,0-0.145,0.066-0.145,0.201C64.17-25.524,64.182-25.473,64.209-25.439"
        />
        <rect
          x="66.23"
          y="-26.501"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          width="8.813"
          height="8.813"
        />

        <rect
          x="66.23"
          y="-26.501"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.812"
          height="8.812"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          points="66.82,-26.003 66.984,-24.909 66.988,-24.909 67.162,-26.003 67.449,-26.003
				67.623,-24.909 67.627,-24.909 67.791,-26.003 68.076,-26.003 67.807,-24.522 67.474,-24.522 67.308,-25.597 67.304,-25.597
				67.138,-24.522 66.804,-24.522 66.535,-26.003 			"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          points="68.495,-26.003 68.495,-25.437 68.844,-25.437 68.844,-26.003 69.143,-26.003
				69.143,-24.522 68.844,-24.522 68.844,-25.18 68.495,-25.18 68.495,-24.522 68.197,-24.522 68.197,-26.003 			"
        />
        <rect
          x="69.363"
          y="-26.003"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          width="0.299"
          height="1.48"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          points="70.75,-25.759 70.428,-25.759 70.428,-24.522 70.129,-24.522 70.129,-25.759
				69.805,-25.759 69.805,-26.003 70.75,-26.003 			"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          points="71.705,-25.759 71.177,-25.759 71.177,-25.41 71.674,-25.41 71.674,-25.165
				71.177,-25.165 71.177,-24.768 71.727,-24.768 71.727,-24.522 70.879,-24.522 70.879,-26.003 71.705,-26.003 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M12.709,75.937c0.592,0,0.973,0.391,0.973,0.991
				c0,0.594-0.381,0.985-0.973,0.985c-0.588,0-0.969-0.392-0.969-0.985C11.74,76.327,12.121,75.937,12.709,75.937 M12.709,77.525
				c0.352,0,0.457-0.301,0.457-0.598c0-0.302-0.105-0.604-0.457-0.604c-0.349,0-0.454,0.302-0.454,0.604
				C12.255,77.225,12.36,77.525,12.709,77.525"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M14.667,75.986h0.378v0.346h-0.378v0.929c0,0.174,0.044,0.218,0.218,0.218
				c0.054,0,0.105-0.004,0.16-0.015v0.403c-0.087,0.014-0.2,0.018-0.302,0.018c-0.315,0-0.591-0.073-0.591-0.446v-1.106H13.84
				v-0.346h0.312v-0.563h0.515V75.986z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M15.715,77.051c0.014,0.326,0.174,0.475,0.46,0.475
				c0.208,0,0.374-0.127,0.407-0.243h0.454c-0.146,0.443-0.454,0.631-0.879,0.631c-0.591,0-0.957-0.405-0.957-0.985
				c0-0.563,0.388-0.992,0.957-0.992c0.639,0,0.947,0.538,0.911,1.115H15.715z M16.553,76.724c-0.047-0.261-0.16-0.399-0.41-0.399
				c-0.327,0-0.421,0.254-0.428,0.399H16.553z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M17.771,77.253c0.004,0.226,0.193,0.316,0.396,0.316
				c0.149,0,0.337-0.058,0.337-0.24c0-0.155-0.214-0.21-0.584-0.29c-0.298-0.064-0.595-0.17-0.595-0.501
				c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.138,0.828,0.598h-0.49c-0.015-0.199-0.167-0.254-0.352-0.254
				c-0.116,0-0.287,0.022-0.287,0.175c0,0.186,0.29,0.21,0.584,0.279c0.302,0.069,0.596,0.178,0.596,0.526
				c0,0.493-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.163-0.882-0.66H17.771z"
        />
        <rect
          x="8.915"
          y="80.465"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M12.019,79.028h0.89c0.798,0,1.244,0.398,1.244,1.233
				c0,0.868-0.381,1.358-1.244,1.358h-0.89V79.028z M12.364,81.329h0.573c0.236,0,0.871-0.065,0.871-1.021
				c0-0.615-0.228-0.989-0.864-0.989h-0.58V81.329z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M15.331,79.7c0.595,0,0.911,0.432,0.911,0.983
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.433-0.911-0.979C14.42,80.132,14.736,79.7,15.331,79.7 M15.331,81.391
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C14.747,81.137,15.008,81.391,15.331,81.391"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M17.611,79.743h0.291v0.297h0.006c0.132-0.231,0.341-0.341,0.61-0.341
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
				h-0.309V79.743z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M20.438,79.7c0.595,0,0.911,0.432,0.911,0.983
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.433-0.911-0.979C19.526,80.132,19.842,79.7,20.438,79.7 M20.438,81.391
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C19.854,81.137,20.114,81.391,20.438,81.391"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M22.139,79.743h0.374v0.272h-0.374v1.165c0,0.142,0.039,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V79.743z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M25.523,81.612c-0.055,0.032-0.123,0.05-0.222,0.05
				c-0.16,0-0.261-0.087-0.261-0.289c-0.17,0.199-0.399,0.289-0.66,0.289c-0.341,0-0.621-0.151-0.621-0.525
				c0-0.424,0.316-0.516,0.635-0.577c0.341-0.065,0.632-0.043,0.632-0.275c0-0.269-0.222-0.313-0.418-0.313
				c-0.261,0-0.453,0.081-0.468,0.355h-0.309c0.018-0.465,0.378-0.628,0.795-0.628c0.338,0,0.704,0.077,0.704,0.516v0.966
				c0,0.145,0,0.21,0.098,0.21c0.026,0,0.055-0.003,0.095-0.018V81.612z M25.022,80.65c-0.12,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.036-0.378,0.109-0.378,0.337c0,0.204,0.175,0.276,0.364,0.276c0.406,0,0.573-0.254,0.573-0.424V80.65z"
        />
        <rect
          x="25.827"
          y="79.028"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          width="0.308"
          height="2.591"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M27.042,79.743h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.167,0.232,0.167
				h0.142v0.271H27.18c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V79.743z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M29.356,81.024c-0.083,0.413-0.381,0.639-0.802,0.639
				c-0.599,0-0.882-0.414-0.9-0.987c0-0.563,0.37-0.976,0.882-0.976c0.663,0,0.867,0.619,0.849,1.073H27.98
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.366H29.356z M29.059,80.502
				c-0.014-0.294-0.236-0.53-0.54-0.53c-0.323,0-0.516,0.243-0.538,0.53H29.059z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M29.692,79.743h0.291v0.396h0.006c0.149-0.302,0.356-0.45,0.69-0.44v0.327
				c-0.497,0-0.678,0.283-0.678,0.758v0.835h-0.309V79.743z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M33.582,81.612c-0.055,0.032-0.123,0.05-0.223,0.05
				c-0.158,0-0.261-0.087-0.261-0.289c-0.17,0.199-0.399,0.289-0.66,0.289c-0.341,0-0.621-0.151-0.621-0.525
				c0-0.424,0.316-0.516,0.636-0.577c0.34-0.065,0.631-0.043,0.631-0.275c0-0.269-0.221-0.313-0.416-0.313
				c-0.262,0-0.454,0.081-0.469,0.355h-0.309c0.018-0.465,0.378-0.628,0.795-0.628c0.337,0,0.704,0.077,0.704,0.516v0.966
				c0,0.145,0,0.21,0.097,0.21c0.026,0,0.055-0.003,0.096-0.018V81.612z M33.08,80.65c-0.119,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.036-0.377,0.109-0.377,0.337c0,0.204,0.174,0.276,0.363,0.276c0.406,0,0.572-0.254,0.572-0.424V80.65z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M33.856,79.743h0.29v0.396h0.007c0.149-0.302,0.356-0.45,0.69-0.44v0.327
				c-0.498,0-0.68,0.283-0.68,0.758v0.835h-0.308V79.743z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M35.504,79.743h0.373v0.272h-0.373v1.165c0,0.142,0.039,0.167,0.231,0.167
				h0.142v0.271h-0.236c-0.319,0-0.446-0.064-0.446-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.31V79.743z"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          points="38.075,81.619 37.749,81.619 37.368,80.127 37.36,80.127
				36.983,81.619 36.649,81.619 36.047,79.743 36.387,79.743 36.813,81.278 36.82,81.278 37.197,79.743 37.535,79.743
				37.926,81.278 37.934,81.278 38.354,79.743 38.674,79.743 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M39.775,79.7c0.596,0,0.912,0.432,0.912,0.983
				c0,0.547-0.316,0.979-0.912,0.979c-0.594,0-0.91-0.433-0.91-0.979C38.865,80.132,39.182,79.7,39.775,79.7 M39.775,81.391
				c0.324,0,0.584-0.254,0.584-0.707c0-0.458-0.26-0.712-0.584-0.712c-0.322,0-0.584,0.254-0.584,0.712
				C39.191,81.137,39.453,81.391,39.775,81.391"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M41.037,79.743h0.291v0.396h0.007c0.149-0.302,0.356-0.45,0.69-0.44v0.327
				c-0.498,0-0.68,0.283-0.68,0.758v0.835h-0.309V79.743z"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          points="42.274,79.028 42.584,79.028 42.584,80.563 43.439,79.743
				43.854,79.743 43.109,80.426 43.908,81.619 43.516,81.619 42.873,80.64 42.584,80.908 42.584,81.619 42.274,81.619 			"
        />
        <rect
          x="8.915"
          y="84.222"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M14.087,84.439c0,0.653-0.374,0.998-1.02,0.998
				c-0.668,0-1.063-0.309-1.063-0.998v-1.654h0.345v1.654c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.654
				h0.345V84.439z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M14.775,84.784c0.011,0.276,0.25,0.363,0.505,0.363
				c0.192,0,0.454-0.044,0.454-0.28c0-0.238-0.306-0.278-0.614-0.348c-0.305-0.069-0.614-0.171-0.614-0.53
				c0-0.378,0.374-0.533,0.701-0.533c0.414,0,0.744,0.13,0.769,0.589h-0.308c-0.022-0.24-0.233-0.316-0.435-0.316
				c-0.186,0-0.4,0.051-0.4,0.239c0,0.222,0.326,0.258,0.614,0.327c0.308,0.068,0.612,0.171,0.612,0.533
				c0,0.446-0.417,0.592-0.798,0.592c-0.421,0-0.777-0.172-0.794-0.636H14.775z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M18,84.781c-0.083,0.413-0.38,0.638-0.802,0.638
				c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.882-0.977c0.664,0,0.868,0.62,0.849,1.074h-1.404
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.366H18z M17.703,84.258
				c-0.015-0.294-0.236-0.529-0.541-0.529c-0.323,0-0.515,0.243-0.537,0.529H17.703z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M19.784,83.5h0.374v0.271h-0.374v1.165c0,0.143,0.04,0.167,0.232,0.167
				h0.142v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319V83.5h0.319v-0.563h0.309V83.5z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M20.825,83.162h-0.308v-0.378h0.308V83.162z M20.517,83.5h0.308v1.876
				h-0.308V83.5z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M22.922,85.216c0,0.61-0.28,0.922-0.871,0.922
				c-0.352,0-0.759-0.142-0.777-0.551h0.309c0.014,0.225,0.283,0.304,0.486,0.304c0.403,0,0.563-0.29,0.563-0.711v-0.123h-0.008
				c-0.102,0.229-0.348,0.341-0.584,0.341c-0.559,0-0.838-0.438-0.838-0.95c0-0.443,0.217-0.991,0.867-0.991
				c0.236,0,0.446,0.105,0.559,0.312h0.004V83.5h0.29V85.216z M22.617,84.4c0-0.331-0.145-0.673-0.533-0.673
				c-0.396,0-0.556,0.323-0.556,0.684c0,0.337,0.124,0.714,0.53,0.714C22.465,85.125,22.617,84.752,22.617,84.4"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M23.385,82.785h0.309v0.99h0.007c0.102-0.225,0.366-0.318,0.591-0.318
				c0.494,0,0.646,0.281,0.646,0.686v1.233H24.63v-1.271c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.06
				h-0.309V82.785z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M25.83,83.5h0.374v0.271H25.83v1.165c0,0.143,0.04,0.167,0.232,0.167h0.142
				v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319V83.5h0.319v-0.563h0.309V83.5z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M28.362,83.456c0.595,0,0.911,0.433,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.451,83.889,27.767,83.456,28.362,83.456 M28.362,85.147
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C27.778,84.894,28.039,85.147,28.362,85.147"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          points="30.48,85.376 30.149,85.376 29.453,83.5 29.798,83.5 30.324,85.063
				30.332,85.063 30.843,83.5 31.167,83.5 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M33.049,84.781c-0.083,0.413-0.381,0.638-0.803,0.638
				c-0.598,0-0.881-0.414-0.899-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.62,0.85,1.074h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.366H33.049z M32.751,84.258
				c-0.015-0.294-0.236-0.529-0.54-0.529c-0.323,0-0.516,0.242-0.538,0.529H32.751z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M33.385,83.5h0.291v0.396h0.006c0.148-0.301,0.355-0.45,0.689-0.439v0.326
				c-0.496,0-0.678,0.283-0.678,0.759v0.835h-0.309V83.5z"
        />
        <rect
          x="34.621"
          y="82.785"
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          width="0.309"
          height="2.591"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M36.218,83.456c0.595,0,0.911,0.433,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.432-0.911-0.979C35.307,83.889,35.623,83.456,36.218,83.456 M36.218,85.147
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712s-0.584,0.254-0.584,0.712
				C35.634,84.894,35.895,85.147,36.218,85.147"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M38.765,84.103c-0.048-0.236-0.21-0.374-0.464-0.374
				c-0.447,0-0.586,0.352-0.586,0.732c0,0.346,0.156,0.687,0.549,0.687c0.297,0,0.475-0.174,0.515-0.457h0.315
				c-0.068,0.457-0.355,0.729-0.826,0.729c-0.573,0-0.879-0.398-0.879-0.958c0-0.563,0.291-1.005,0.886-1.005
				c0.424,0,0.767,0.2,0.81,0.646H38.765z"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          points="39.456,82.785 39.766,82.785 39.766,84.319 40.621,83.5
				41.035,83.5 40.291,84.182 41.09,85.376 40.697,85.376 40.055,84.396 39.766,84.665 39.766,85.376 39.456,85.376 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M41.647,83.162h-0.309v-0.378h0.309V83.162z M41.339,83.5h0.309v1.876
				h-0.309V83.5z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M42.125,83.5h0.291v0.297h0.006c0.131-0.232,0.342-0.341,0.61-0.341
				c0.494,0,0.646,0.282,0.646,0.686v1.234H43.37v-1.271c0-0.228-0.146-0.378-0.381-0.378c-0.374,0-0.556,0.251-0.556,0.589v1.06
				h-0.309V83.5z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#EC008C"
          d="M45.761,85.216c0,0.61-0.28,0.922-0.871,0.922
				c-0.353,0-0.759-0.142-0.776-0.551h0.309c0.014,0.225,0.283,0.304,0.486,0.304c0.402,0,0.562-0.29,0.562-0.711v-0.123h-0.007
				c-0.102,0.229-0.348,0.341-0.584,0.341c-0.559,0-0.838-0.438-0.838-0.95c0-0.443,0.217-0.991,0.867-0.991
				c0.235,0,0.445,0.105,0.559,0.312h0.003V83.5h0.291V85.216z M45.455,84.399c0-0.331-0.145-0.672-0.532-0.672
				c-0.396,0-0.556,0.323-0.556,0.684c0,0.337,0.123,0.714,0.529,0.714C45.304,85.125,45.455,84.752,45.455,84.399"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M-12.196-26.004c0.076,0,0.14,0.014,0.192,0.038
				c0.052,0.026,0.094,0.061,0.125,0.102c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.053,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.138c-0.041,0.037-0.091,0.064-0.148,0.081
				c-0.058,0.019-0.123,0.028-0.193,0.028h-0.16v0.59h-0.298v-1.48H-12.196z M-12.287-25.333c0.059,0,0.107-0.019,0.144-0.055
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.159-0.058h-0.125v0.45H-12.287z"
        />
        <polygon
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          points="-11.193,-26.003 -10.982,-24.958 -10.977,-24.958 -10.764,-26.003
				-10.322,-26.003 -10.322,-24.523 -10.596,-24.523 -10.596,-25.705 -10.6,-25.705 -10.863,-24.523 -11.096,-24.523
				-11.358,-25.705 -11.362,-25.705 -11.362,-24.523 -11.636,-24.523 -11.636,-26.003 			"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M-9.868-24.941c0,0.033,0.003,0.065,0.008,0.094
				c0.005,0.027,0.016,0.052,0.031,0.072c0.015,0.019,0.036,0.034,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.056-0.076,0.056-0.137c0-0.034-0.006-0.063-0.015-0.086
				c-0.008-0.025-0.023-0.048-0.044-0.066s-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
				c-0.066-0.023-0.124-0.048-0.172-0.073c-0.048-0.026-0.089-0.058-0.121-0.093c-0.033-0.035-0.057-0.075-0.072-0.122
				c-0.015-0.045-0.023-0.1-0.023-0.16c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.008,0.184,0.021c0.056,0.015,0.104,0.039,0.145,0.071s0.072,0.073,0.095,0.124s0.034,0.11,0.034,0.182v0.041
				h-0.286c0-0.071-0.012-0.125-0.038-0.162c-0.023-0.039-0.065-0.057-0.123-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.023,0.009-0.04,0.022-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.114s0.06,0.06,0.124,0.086l0.23,0.1c0.057,0.024,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.342c-0.09,0.07-0.216,0.105-0.378,0.105c-0.169,0-0.29-0.036-0.362-0.108
				c-0.072-0.074-0.108-0.18-0.108-0.316v-0.061h0.298V-24.941z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M-8.277-24.757c0.021,0.045,0.065,0.068,0.13,0.068
				c0.035,0,0.064-0.007,0.086-0.02c0.021-0.013,0.037-0.029,0.049-0.051c0.012-0.021,0.019-0.047,0.023-0.076
				c0.003-0.027,0.005-0.059,0.005-0.091c0-0.033-0.002-0.063-0.007-0.094c-0.005-0.029-0.015-0.056-0.028-0.08
				c-0.014-0.023-0.033-0.042-0.058-0.056c-0.025-0.015-0.059-0.021-0.1-0.021h-0.099v-0.195h0.097c0.032,0,0.059-0.006,0.08-0.019
				s0.039-0.03,0.052-0.052c0.013-0.021,0.023-0.046,0.029-0.074c0.006-0.028,0.009-0.056,0.009-0.087
				c0-0.07-0.012-0.119-0.035-0.149c-0.024-0.028-0.059-0.043-0.108-0.043c-0.032,0-0.057,0.006-0.077,0.018
				c-0.021,0.012-0.036,0.028-0.047,0.05c-0.011,0.021-0.018,0.047-0.022,0.076c-0.003,0.028-0.005,0.061-0.005,0.095h-0.273
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.071,0.181-0.106,0.322-0.106c0.133,0,0.237,0.029,0.311,0.092
				c0.074,0.06,0.111,0.154,0.111,0.285c0,0.089-0.02,0.16-0.059,0.217c-0.039,0.056-0.094,0.091-0.164,0.106v0.005
				c0.095,0.015,0.162,0.053,0.201,0.114c0.04,0.062,0.06,0.139,0.06,0.231c0,0.049-0.007,0.101-0.019,0.152
				c-0.012,0.053-0.037,0.101-0.072,0.144c-0.037,0.043-0.086,0.077-0.149,0.104c-0.062,0.026-0.146,0.039-0.248,0.039
				c-0.141,0-0.248-0.038-0.319-0.115c-0.07-0.077-0.105-0.188-0.105-0.329v-0.008h0.286C-8.31-24.866-8.298-24.802-8.277-24.757"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M-7.029-25.751c-0.02-0.03-0.056-0.045-0.105-0.045
				c-0.039,0-0.07,0.009-0.095,0.028c-0.024,0.018-0.042,0.045-0.056,0.078c-0.013,0.032-0.022,0.071-0.026,0.117
				s-0.006,0.096-0.006,0.149v0.072h0.004c0.026-0.038,0.061-0.068,0.108-0.092c0.046-0.022,0.102-0.034,0.168-0.034
				c0.065,0,0.12,0.012,0.166,0.034c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.089,0.062,0.145
				c0.013,0.057,0.02,0.117,0.02,0.184c0,0.17-0.038,0.298-0.113,0.387c-0.075,0.088-0.196,0.131-0.362,0.131
				c-0.102,0-0.184-0.018-0.245-0.053c-0.06-0.037-0.107-0.086-0.139-0.149c-0.033-0.063-0.054-0.136-0.064-0.219
				c-0.009-0.082-0.014-0.168-0.014-0.259c0-0.104,0.003-0.204,0.009-0.301c0.006-0.099,0.025-0.185,0.057-0.26
				c0.03-0.074,0.079-0.136,0.145-0.182c0.067-0.045,0.161-0.068,0.282-0.068c0.052,0,0.104,0.008,0.152,0.02
				c0.05,0.012,0.093,0.033,0.13,0.062c0.037,0.029,0.068,0.067,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.177H-7
				C-7-25.675-7.009-25.72-7.029-25.751 M-7.296-24.863c0.005,0.035,0.012,0.066,0.024,0.093c0.012,0.025,0.028,0.046,0.05,0.06
				c0.021,0.014,0.049,0.022,0.084,0.022c0.036,0,0.064-0.009,0.086-0.022c0.021-0.014,0.037-0.034,0.048-0.061
				c0.011-0.026,0.019-0.058,0.022-0.094c0.003-0.033,0.005-0.072,0.005-0.114c0-0.1-0.012-0.171-0.037-0.213
				c-0.025-0.044-0.066-0.064-0.124-0.064c-0.06,0-0.102,0.021-0.127,0.064c-0.024,0.042-0.037,0.113-0.037,0.213
				C-7.302-24.938-7.3-24.898-7.296-24.863"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M-6.559-25.176c0.042-0.066,0.104-0.107,0.187-0.125v-0.004
				c-0.04-0.01-0.074-0.025-0.101-0.046c-0.026-0.021-0.048-0.043-0.065-0.07c-0.016-0.025-0.028-0.054-0.035-0.083
				c-0.007-0.028-0.01-0.056-0.01-0.083c0-0.144,0.038-0.247,0.115-0.309c0.076-0.064,0.182-0.095,0.316-0.095
				s0.239,0.03,0.315,0.095c0.077,0.062,0.116,0.165,0.116,0.309c0,0.027-0.004,0.055-0.011,0.083
				c-0.008,0.029-0.02,0.058-0.037,0.083c-0.016,0.027-0.038,0.05-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.018,0.145,0.059,0.187,0.125c0.041,0.066,0.062,0.146,0.062,0.238c0,0.043-0.005,0.09-0.015,0.14
				c-0.011,0.052-0.032,0.101-0.066,0.145c-0.033,0.044-0.08,0.08-0.141,0.109c-0.062,0.029-0.144,0.043-0.246,0.043
				s-0.184-0.014-0.246-0.043s-0.108-0.065-0.142-0.109c-0.033-0.044-0.054-0.093-0.065-0.145c-0.011-0.05-0.016-0.097-0.016-0.14
				C-6.621-25.03-6.599-25.109-6.559-25.176 M-6.315-24.844c0.005,0.031,0.014,0.059,0.027,0.081
				c0.013,0.023,0.031,0.041,0.053,0.054c0.022,0.014,0.05,0.021,0.083,0.021s0.06-0.007,0.083-0.021
				c0.022-0.013,0.039-0.03,0.052-0.054c0.013-0.022,0.023-0.05,0.028-0.081c0.004-0.031,0.007-0.063,0.007-0.098
				c0-0.036-0.003-0.07-0.007-0.101c-0.005-0.029-0.015-0.057-0.028-0.079c-0.013-0.023-0.03-0.041-0.052-0.054
				c-0.023-0.014-0.05-0.02-0.083-0.02s-0.061,0.006-0.083,0.02c-0.022,0.013-0.04,0.03-0.053,0.054
				c-0.013,0.022-0.022,0.05-0.027,0.079c-0.005,0.03-0.007,0.064-0.007,0.101C-6.322-24.907-6.32-24.875-6.315-24.844
				 M-6.258-25.439c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155
				c0-0.135-0.048-0.201-0.145-0.201s-0.145,0.066-0.145,0.201C-6.297-25.524-6.284-25.473-6.258-25.439"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M-11.79-23.757c-0.064,0.091-0.122,0.189-0.173,0.293
				c-0.05,0.104-0.095,0.212-0.133,0.322c-0.038,0.108-0.07,0.222-0.095,0.335c-0.024,0.114-0.044,0.226-0.057,0.336h-0.332
				c0.011-0.094,0.029-0.193,0.053-0.296c0.023-0.104,0.055-0.209,0.093-0.316c0.039-0.106,0.086-0.215,0.142-0.323
				c0.056-0.108,0.122-0.216,0.198-0.319h-0.656v-0.273h0.96V-23.757z"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M-11.689-23.51c0.007-0.101,0.026-0.188,0.059-0.266
				c0.032-0.076,0.081-0.139,0.146-0.185c0.066-0.046,0.156-0.069,0.272-0.069c0.096,0,0.174,0.014,0.237,0.04
				c0.062,0.027,0.112,0.071,0.149,0.132c0.038,0.06,0.065,0.139,0.08,0.234c0.015,0.096,0.023,0.213,0.023,0.352
				c0,0.108-0.004,0.212-0.011,0.312c-0.007,0.101-0.027,0.189-0.06,0.266c-0.032,0.077-0.08,0.139-0.146,0.183
				c-0.065,0.044-0.156,0.065-0.272,0.065c-0.094,0-0.172-0.013-0.236-0.038c-0.063-0.024-0.112-0.067-0.15-0.128
				c-0.038-0.059-0.065-0.138-0.08-0.233c-0.015-0.097-0.023-0.214-0.023-0.352C-11.702-23.306-11.697-23.41-11.689-23.51
				 M-11.376-22.888c0.005,0.063,0.014,0.111,0.028,0.147c0.013,0.035,0.031,0.061,0.052,0.074c0.023,0.013,0.051,0.02,0.084,0.02
				c0.035,0,0.064-0.009,0.086-0.025c0.022-0.018,0.04-0.046,0.052-0.086c0.012-0.04,0.021-0.094,0.027-0.162
				c0.005-0.067,0.007-0.151,0.007-0.251v-0.169c0-0.098-0.002-0.179-0.007-0.242c-0.006-0.062-0.016-0.111-0.028-0.146
				c-0.013-0.036-0.031-0.062-0.053-0.074c-0.022-0.014-0.05-0.021-0.084-0.021c-0.035,0-0.064,0.009-0.086,0.026
				c-0.022,0.016-0.039,0.045-0.052,0.086c-0.012,0.04-0.021,0.094-0.026,0.162c-0.005,0.066-0.008,0.15-0.008,0.251v0.169
				C-11.384-23.03-11.381-22.95-11.376-22.888"
        />
        <path
          clipPath="url(#BACKWOODSCOOKING_SVGID_80_)"
          fill="#FFFFFF"
          d="M-10.611-23.794c0.009-0.053,0.025-0.097,0.049-0.132
				c0.025-0.035,0.058-0.063,0.101-0.078c0.044-0.017,0.1-0.025,0.167-0.025c0.069,0,0.125,0.009,0.168,0.025
				c0.042,0.016,0.075,0.043,0.1,0.078c0.024,0.035,0.04,0.079,0.049,0.132s0.013,0.115,0.013,0.185c0,0.07-0.004,0.133-0.013,0.186
				s-0.025,0.097-0.049,0.132c-0.025,0.035-0.058,0.062-0.1,0.078c-0.043,0.017-0.099,0.024-0.168,0.024
				c-0.067,0-0.123-0.008-0.167-0.024c-0.043-0.017-0.076-0.043-0.101-0.078c-0.024-0.035-0.04-0.079-0.049-0.132
				c-0.009-0.053-0.013-0.115-0.013-0.186C-10.624-23.679-10.62-23.741-10.611-23.794 M-10.376-23.421
				c0.011,0.044,0.038,0.065,0.082,0.065s0.071-0.021,0.082-0.065c0.009-0.043,0.015-0.108,0.015-0.197
				c0-0.079-0.006-0.14-0.015-0.182c-0.011-0.041-0.038-0.063-0.082-0.063s-0.071,0.021-0.082,0.063
				c-0.01,0.042-0.015,0.103-0.015,0.182C-10.391-23.529-10.386-23.464-10.376-23.421 M-9.286-24.067l-0.894,1.658h-0.196
				l0.895-1.658H-9.286z M-9.677-23.046c0.009-0.052,0.025-0.096,0.049-0.132c0.024-0.035,0.058-0.062,0.102-0.077
				c0.043-0.017,0.098-0.026,0.165-0.026c0.069,0,0.125,0.01,0.168,0.026c0.042,0.016,0.076,0.042,0.1,0.077
				c0.025,0.036,0.041,0.08,0.05,0.132c0.008,0.053,0.013,0.115,0.013,0.186c0,0.071-0.005,0.135-0.013,0.186
				c-0.009,0.053-0.025,0.096-0.05,0.13c-0.024,0.035-0.058,0.06-0.1,0.076c-0.043,0.015-0.099,0.022-0.168,0.022
				c-0.067,0-0.122-0.008-0.165-0.022c-0.044-0.017-0.078-0.041-0.102-0.076c-0.024-0.034-0.04-0.077-0.049-0.13
				c-0.01-0.051-0.014-0.114-0.014-0.186C-9.691-22.931-9.687-22.993-9.677-23.046 M-9.442-22.672
				c0.01,0.043,0.037,0.064,0.081,0.064c0.044,0,0.072-0.021,0.082-0.064c0.01-0.044,0.016-0.109,0.016-0.197
				c0-0.079-0.006-0.14-0.016-0.182c-0.01-0.042-0.038-0.063-0.082-0.063c-0.044,0-0.071,0.021-0.081,0.063
				c-0.01,0.042-0.015,0.103-0.015,0.182C-9.457-22.781-9.452-22.716-9.442-22.672"
        />
      </g>
    </g>
  );
};

function HaveAdventures(props: any) {
  const { badge, fallback = null, ...otherProps } = props;

  switch (badge) {
    case 'Whittling':
      return <Whittling {...otherProps} />;
    case 'Navigator':
      return <Navigator {...otherProps} />;
    case 'Geocaching':
      return <Geocaching {...otherProps} />;
    case 'Backwoods cooking':
      return <BackwoodsCooking {...otherProps} />;
    default:
      return fallback;
  }
}

export default HaveAdventures;
