import { Heading, Span } from 'components/typography';
import './logo.scss';

function Logo(props: any) {
  const { iconOnly = false } = props;
  return (
    <div className="logo-wrapper">
      <svg width={208} height={144} viewBox="0 0 208 144" fill="none">
        {/* <rect width="224" height="164" fill="white" /> */}
        <path
          d="M8 72C8 36.6538 36.6538 8 72 8C107.346 8 136 36.6538 136 72C136 107.346 107.346 136 72 136C36.6538 136 8 107.346 8 72Z"
          fill="#323232"
        />
        <path
          d="M72 72C72 36.6538 100.654 8 136 8C171.346 8 200 36.6538 200 72C200 107.346 171.346 136 136 136C100.654 136 72 107.346 72 72Z"
          fill="#323232"
        />
        <path
          d="M169.61 72.305C169.61 90.6988 154.699 105.61 136.305 105.61C117.911 105.61 103 90.6988 103 72.305C103 53.9112 117.911 39 136.305 39C154.699 39 169.61 53.9112 169.61 72.305Z"
          stroke="#EC008C"
          strokeWidth={16}
        />
        <path
          d="M72.2817 101.833C79.8917 101.833 86.7377 98.8811 91.9429 94.1158L76.3877 78.5606L73.827 76H77.4483H113.615H115.115V77.5V113.667V117.288L112.554 114.727L101.455 103.628C93.8017 110.774 83.5786 115.167 72.2817 115.167C48.6141 115.167 29.5 95.9959 29.5 72.3333C29.5 48.6707 48.6141 29.5 72.2817 29.5C92.2593 29.5 108.909 43.1592 113.672 61.6253L114.156 63.5H112.22H101.473H100.411L100.058 62.4979C96.0264 51.0411 85.1104 42.8333 72.2817 42.8333C56.0084 42.8333 42.7817 56.0601 42.7817 72.3333C42.7817 88.6065 56.0084 101.833 72.2817 101.833Z"
          fill="white"
          stroke="white"
          strokeWidth={3}
        />
      </svg>

      {!iconOnly ? (
        <>
          <Heading size={1}>
            {'GO4'}
            <Span color="primary">GUIDES</Span>
          </Heading>
          <Span className="slogan">We discover, we grow</Span>
        </>
      ) : null}
    </div>
  );
}

export default Logo;
