import React, { PropsWithChildren } from 'react';
import './error-page.scss';

type ErrorPageProps = PropsWithChildren<any> & {
  errorCode: number | string;
};

function ErrorPage(props: ErrorPageProps) {
  const { children, className, errorCode, ...otherProps } = props;

  const classes = ['error-page', `error-page--${errorCode}`];
  if (className) classes.push(className);

  return (
    <div {...otherProps} className={classes.join(' ')}>
      {children}
    </div>
  );
}

export default ErrorPage;
