// #startregion Innovate Groups
const InnovateStage5 = () => (
  <g id="Stage_5_4_">
    <polygon
      fill="#EBBCC2"
      points="57.57,19.717 57.57,50.559 30.86,65.979 4.15,50.559 4.15,19.717 30.86,4.296 			"
    />
    <polygon fill="#EE95A7" points="57.569,50.56 30.861,65.979 30.861,35.14 			" />
    <polygon fill="#EE95A7" points="57.569,19.719 57.569,50.56 30.861,35.14 			" />
    <polygon
      fill="#EE95A7"
      points="30.861,4.293 57.569,19.713 30.861,35.133 			"
    />
    <polygon fill="#EE95A7" points="30.858,65.979 4.15,50.56 30.858,35.14 			" />
    <polygon fill="#EE95A7" points="30.861,35.14 4.153,50.56 4.153,19.719 			" />
    <g>
      <defs>
        <rect
          id="SVGID_23_"
          x="0.017"
          y="0.034"
          width="61.688"
          height="70.205"
        />
      </defs>
      <clipPath id="SVGID_24_">
        <use xlinkHref="#SVGID_23_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_24_)"
        fill="#EB008B"
        d="M30.86,4.609l26.437,15.263V50.4L30.86,65.663L4.423,50.4V19.873
    L30.86,4.609 M30.86,0.034c-0.573,0-1.146,0.148-1.658,0.444L1.675,16.372c-1.026,0.593-1.657,1.687-1.657,2.872v31.786
    c0,1.185,0.631,2.279,1.657,2.872l27.527,15.893c0.513,0.297,1.085,0.444,1.658,0.444s1.145-0.147,1.658-0.444l27.526-15.893
    c1.027-0.593,1.658-1.688,1.658-2.872V19.243c0-1.185-0.631-2.279-1.658-2.872L32.519,0.479
    C32.005,0.183,31.434,0.034,30.86,0.034"
      />
      <rect
        x="17.475"
        y="29.342"
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        width="1.069"
        height="4.337"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M22.526,31.687v1.994h-1.03v-1.774c0-0.382-0.226-0.641-0.558-0.641
    c-0.406,0-0.673,0.279-0.673,0.907v1.508h-1.036v-3.25h1.036v0.31c0.246-0.258,0.589-0.407,0.997-0.407
    C22.021,30.334,22.526,30.871,22.526,31.687"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M26.464,31.687v1.994h-1.029v-1.774c0-0.382-0.228-0.641-0.558-0.641
    c-0.408,0-0.673,0.279-0.673,0.907v1.508h-1.036v-3.25h1.036v0.31c0.246-0.258,0.589-0.407,0.997-0.407
    C25.958,30.334,26.464,30.871,26.464,31.687"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M30.473,32.056c0,1.03-0.815,1.717-1.78,1.717
    c-0.984,0-1.8-0.687-1.8-1.717c0-1.023,0.815-1.709,1.8-1.709C29.657,30.347,30.473,31.033,30.473,32.056 M29.476,32.056
    c0-0.505-0.382-0.841-0.783-0.841c-0.421,0-0.804,0.336-0.804,0.841c0,0.512,0.383,0.842,0.804,0.842
    C29.094,32.897,29.476,32.568,29.476,32.056"
      />
      <polygon
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        points="32.902,33.681 31.847,33.681 30.5,30.425 31.594,30.425
    32.377,32.431 33.154,30.425 34.249,30.425 				"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M37.908,33.681h-1.036v-0.259c-0.253,0.227-0.59,0.355-1.01,0.355
    c-0.861,0-1.587-0.691-1.587-1.722c0-1.023,0.726-1.722,1.587-1.722c0.42,0,0.757,0.129,1.01,0.356v-0.259h1.036V33.681z
     M36.872,32.056c0-0.498-0.376-0.841-0.796-0.841c-0.454,0-0.784,0.343-0.784,0.841c0,0.505,0.33,0.842,0.784,0.842
    C36.496,32.897,36.872,32.561,36.872,32.056"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M41.005,33.539c-0.254,0.136-0.519,0.238-0.889,0.238
    c-0.808,0-1.294-0.439-1.294-1.294v-1.229h-0.59v-0.823h0.59v-0.959h1.035v0.959h0.959v0.823h-0.959v1.126
    c0,0.337,0.163,0.472,0.435,0.472c0.143,0,0.336-0.051,0.473-0.129L41.005,33.539z"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M44.457,32.322h-2.384c0.084,0.388,0.351,0.628,0.699,0.628
    c0.239,0,0.519-0.058,0.693-0.376l0.919,0.188c-0.279,0.686-0.868,1.016-1.612,1.016c-0.938,0-1.716-0.692-1.716-1.722
    c0-1.023,0.777-1.722,1.724-1.722c0.918,0,1.656,0.66,1.677,1.722V32.322z M42.093,31.687h1.34
    c-0.097-0.35-0.355-0.511-0.652-0.511C42.487,31.176,42.189,31.355,42.093,31.687"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#EB008B"
        d="M20.056,37.746l-1.47,0.299c-0.108-0.571-0.58-0.853-1.061-0.853
    c-0.499,0-0.816,0.272-0.816,0.617c0,0.244,0.109,0.426,0.49,0.508l1.26,0.281c1.052,0.235,1.669,0.807,1.669,1.769
    c0,1.297-1.151,1.967-2.503,1.967c-1.369,0-2.494-0.651-2.657-1.84l1.541-0.3c0.155,0.625,0.59,0.897,1.197,0.897
    c0.563,0,0.898-0.254,0.898-0.626c0-0.271-0.127-0.443-0.563-0.535l-1.27-0.271c-0.87-0.199-1.633-0.653-1.633-1.76
    c0-1.179,0.979-1.914,2.403-1.914C18.867,35.985,19.829,36.639,20.056,37.746"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#EB008B"
        d="M24.126,41.999c-0.353,0.19-0.725,0.336-1.241,0.336
    c-1.134,0-1.814-0.617-1.814-1.814v-1.723h-0.825v-1.151h0.825v-1.342h1.451v1.342h1.342v1.151h-1.342v1.578
    c0,0.471,0.227,0.662,0.608,0.662c0.199,0,0.471-0.072,0.662-0.183L24.126,41.999z"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#EB008B"
        d="M29.195,42.198h-1.451v-0.363c-0.353,0.318-0.825,0.499-1.414,0.499
    c-1.206,0-2.222-0.97-2.222-2.412c0-1.433,1.016-2.411,2.222-2.411c0.589,0,1.062,0.18,1.414,0.498v-0.363h1.451V42.198z
     M27.744,39.922c0-0.698-0.524-1.179-1.114-1.179c-0.636,0-1.098,0.48-1.098,1.179c0,0.707,0.462,1.179,1.098,1.179
    C27.22,41.101,27.744,40.629,27.744,39.922"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#EB008B"
        d="M34.755,41.744c0,1.406-1.07,2.177-2.549,2.177
    c-1.044,0-1.841-0.363-2.285-1.097l1.205-0.653c0.346,0.408,0.6,0.563,1.07,0.563c0.663,0,1.135-0.391,1.135-1.106V41.5
    c-0.327,0.281-0.78,0.437-1.369,0.437c-1.189,0-2.195-0.944-2.195-2.232c0-1.26,1.006-2.193,2.195-2.193
    c0.589,0,1.042,0.153,1.369,0.435v-0.3h1.424V41.744z M33.331,39.704c0-0.607-0.481-1.033-1.062-1.033
    c-0.635,0-1.088,0.426-1.088,1.033s0.453,1.044,1.088,1.044C32.85,40.748,33.331,40.312,33.331,39.704"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#EB008B"
        d="M40.095,40.294h-3.337c0.117,0.544,0.489,0.879,0.979,0.879
    c0.336,0,0.726-0.08,0.971-0.525l1.287,0.263c-0.39,0.962-1.214,1.424-2.258,1.424c-1.313,0-2.403-0.97-2.403-2.412
    c0-1.433,1.09-2.411,2.412-2.411c1.288,0,2.322,0.924,2.35,2.411V40.294z M36.784,39.405h1.877
    c-0.136-0.489-0.499-0.717-0.916-0.717C37.337,38.688,36.921,38.942,36.784,39.405"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#EB008B"
        d="M44.846,38.253c1.343,0,2.141,0.771,2.141,1.959
    c0,1.252-0.907,2.123-2.304,2.123c-1.251,0-2.313-0.717-2.349-2.086l1.415-0.281c0.008,0.789,0.462,1.097,0.96,1.097
    c0.517,0,0.871-0.335,0.871-0.834c0-0.454-0.344-0.753-0.871-0.753h-2.085l0.109-3.355h3.989v1.297h-2.685l-0.036,0.834H44.846z
    "
      />
      <rect
        x="30.511"
        y="21.618"
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        width="0.681"
        height="1.934"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M32.431,20.781c0-0.222-0.182-0.402-0.402-0.402
    c-0.222,0-0.403,0.18-0.403,0.402v0.402h0.403C32.249,21.183,32.431,21.002,32.431,20.781"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M30.493,12.52c-2.625,0.18-4.719,2.36-4.803,4.989
    c-0.066,2.079,1.094,3.893,2.813,4.775c0.063,0.032,0.103,0.095,0.103,0.166v0.821c0,0.155,0.126,0.281,0.281,0.281h1.188
    v-1.934h-0.401c-0.461,0-0.837-0.376-0.837-0.837s0.376-0.836,0.837-0.836s0.837,0.375,0.837,0.836v0.402h0.681v-0.402
    c0-0.461,0.375-0.836,0.836-0.836c0.462,0,0.837,0.375,0.837,0.836s-0.375,0.837-0.837,0.837h-0.401v1.934h1.208
    c0.154,0,0.28-0.126,0.28-0.281V22.45c0-0.071,0.04-0.134,0.104-0.166c1.671-0.858,2.814-2.597,2.814-4.605
    C36.032,14.701,33.516,12.311,30.493,12.52 M30.851,14.072c-1.895,0-3.436,1.541-3.436,3.435c0,0.12-0.097,0.217-0.218,0.217
    c-0.12,0-0.217-0.097-0.217-0.217c0-2.133,1.736-3.87,3.87-3.87c0.12,0,0.217,0.098,0.217,0.217
    C31.067,13.975,30.971,14.072,30.851,14.072"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M30.075,20.781c0-0.222-0.181-0.402-0.402-0.402s-0.401,0.18-0.401,0.402
    c0,0.222,0.18,0.402,0.401,0.402h0.402V20.781z"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M32.696,24.134h-3.672c-0.239,0-0.435,0.194-0.435,0.435
    c0,0.24,0.195,0.434,0.435,0.434h3.672c0.24,0,0.435-0.194,0.435-0.434C33.131,24.328,32.937,24.134,32.696,24.134"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#88155E"
        d="M32.228,25.544h-2.734c-0.241,0-0.435,0.194-0.435,0.435
    c0,0.24,0.193,0.434,0.435,0.434h2.734c0.24,0,0.435-0.194,0.435-0.434C32.662,25.738,32.468,25.544,32.228,25.544"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#4F86C6"
        d="M30.86,59.55c-2.639,0-4.773-2.135-4.773-4.771
    c0-2.636,2.135-4.771,4.773-4.771c2.638,0,4.772,2.136,4.772,4.771C35.633,57.415,33.498,59.55,30.86,59.55"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#FFFFFF"
        d="M30.86,50.007c2.638,0,4.772,2.136,4.772,4.771
    c0,2.637-2.135,4.771-4.772,4.771c-2.639,0-4.773-2.135-4.773-4.771C26.087,52.143,28.222,50.007,30.86,50.007 M30.86,49.595
    c-2.859,0-5.186,2.326-5.186,5.184s2.326,5.184,5.186,5.184c2.858,0,5.185-2.326,5.185-5.184S33.719,49.595,30.86,49.595"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#FFFFFF"
        d="M33.354,52.833c-0.239,0-0.465,0.107-0.62,0.252l-0.013-0.012
    c0.072-0.121,0.168-0.347,0.168-0.717c0-0.883-0.884-1.551-2.028-1.551s-2.042,0.668-2.042,1.551c0,0.37,0.12,0.596,0.181,0.717
    l-0.014,0.012c-0.153-0.145-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.703-1.457,2.018c0,1.288,0.718,2.016,1.492,2.016
    c0.717,0,1.219-0.395,1.67-0.848c-0.07-0.107-0.179-0.215-0.298-0.215s-0.251,0.085-0.476,0.264
    c-0.299,0.228-0.563,0.358-0.908,0.358c-0.609,0-0.87-0.683-0.87-1.575c0-0.955,0.25-1.587,0.896-1.587
    c0.285,0,0.476,0.083,0.668,0.263c0.285,0.262,0.381,0.332,0.512,0.332c0.096,0,0.156-0.059,0.179-0.094
    c-0.214-0.215-0.524-0.668-0.524-1.253c0-0.668,0.561-1.134,1.611-1.134c1.049,0,1.61,0.466,1.61,1.134
    c0,0.585-0.31,1.038-0.525,1.253c0.024,0.035,0.084,0.094,0.18,0.094c0.132,0,0.227-0.07,0.513-0.332
    c0.179-0.18,0.382-0.263,0.668-0.263c0.645,0,0.895,0.632,0.895,1.587c0,0.893-0.263,1.575-0.87,1.575
    c-0.346,0-0.619-0.131-0.907-0.358c-0.227-0.179-0.357-0.264-0.478-0.264c-0.118,0-0.226,0.107-0.298,0.215
    c0.441,0.453,0.955,0.848,1.67,0.848c0.776,0,1.492-0.728,1.492-2.016C34.81,53.536,34.082,52.833,33.354,52.833"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#FFFFFF"
        d="M31.66,52.81h-0.537c-0.036,0-0.06-0.023-0.071-0.048l-0.168-0.512
    c-0.012-0.036-0.023-0.036-0.035,0l-0.168,0.512c-0.012,0.036-0.035,0.048-0.06,0.048h-0.537c-0.024,0-0.035,0.012-0.013,0.024
    l0.442,0.321c0.024,0.023,0.024,0.061,0.012,0.084l-0.167,0.5c-0.011,0.024-0.011,0.05,0.024,0.024l0.442-0.309
    c0.035-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.035,0.013,0.023-0.011l-0.166-0.514
    c-0.014-0.035,0-0.071,0.023-0.084l0.44-0.321C31.694,52.821,31.683,52.81,31.66,52.81"
      />
      <path
        clipPath="url(#SVGID_24_)"
        fill="#FFFFFF"
        d="M31.254,56.341c-0.226-0.572-0.406-0.966-0.406-1.443
    c0-0.203,0.061-0.382,0.097-0.477c0.047-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.227,0.167-0.321,0.311
    c-0.073,0.119-0.156,0.347-0.156,0.524c0,0.335,0.108,0.693,0.18,0.907c0.132,0.37,0.251,0.8,0.251,1.206
    c0,0.646-0.287,1.084-0.8,1.478c0.048,0.037,0.166,0.096,0.311,0.096c0.5,0,1.097-0.561,1.097-1.323
    C31.516,57.009,31.385,56.698,31.254,56.341"
      />
    </g>
  </g>
);

const InnovateStage4 = () => (
  <g id="Stage_4_4_">
    <polygon
      fill="#EBBCC2"
      points="57.568,19.682 57.568,50.523 30.859,65.944 4.149,50.523 4.149,19.682 30.859,4.261 			"
    />
    <polygon fill="#EE95A7" points="57.568,50.524 30.86,65.944 30.86,35.104 			" />
    <polygon
      fill="#EE95A7"
      points="57.568,19.684 57.568,50.524 30.86,35.104 			"
    />
    <polygon
      fill="#EE95A7"
      points="30.859,4.258 57.567,19.678 30.859,35.098 			"
    />
    <polygon
      fill="#EE95A7"
      points="30.857,65.944 4.149,50.524 30.857,35.104 			"
    />
    <g>
      <defs>
        <rect
          id="SVGID_25_"
          x="0.016"
          y="-0.001"
          width="61.687"
          height="70.205"
        />
      </defs>
      <clipPath id="SVGID_26_">
        <use xlinkHref="#SVGID_25_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_26_)"
        fill="#EB008B"
        d="M30.859,4.574l26.437,15.263v30.528L30.859,65.628L4.422,50.365V19.837
					L30.859,4.574 M30.859-0.001c-0.573,0-1.146,0.148-1.659,0.444L1.674,16.336c-1.025,0.593-1.658,1.688-1.658,2.872v31.786
					c0,1.185,0.633,2.279,1.658,2.872L29.2,69.759c0.513,0.297,1.086,0.444,1.659,0.444c0.572,0,1.145-0.147,1.658-0.444
					l27.526-15.893c1.027-0.593,1.658-1.688,1.658-2.872V19.208c0-1.185-0.631-2.279-1.658-2.872L32.518,0.443
					C32.004,0.147,31.432-0.001,30.859-0.001"
      />
      <rect
        x="17.474"
        y="29.307"
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        width="1.069"
        height="4.337"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M22.525,31.651v1.994h-1.03v-1.774c0-0.382-0.226-0.641-0.558-0.641
					c-0.407,0-0.673,0.279-0.673,0.907v1.508h-1.036v-3.25h1.036v0.31c0.246-0.258,0.589-0.407,0.997-0.407
					C22.02,30.299,22.525,30.835,22.525,31.651"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M26.463,31.651v1.994h-1.03v-1.774c0-0.382-0.226-0.641-0.556-0.641
					c-0.408,0-0.674,0.279-0.674,0.907v1.508h-1.036v-3.25h1.036v0.31c0.245-0.258,0.589-0.407,0.996-0.407
					C25.958,30.299,26.463,30.835,26.463,31.651"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M30.472,32.021c0,1.03-0.815,1.717-1.781,1.717
					c-0.983,0-1.799-0.687-1.799-1.717c0-1.023,0.815-1.709,1.799-1.709C29.656,30.312,30.472,30.998,30.472,32.021 M29.475,32.021
					c0-0.505-0.382-0.841-0.784-0.841c-0.421,0-0.803,0.336-0.803,0.841c0,0.512,0.382,0.842,0.803,0.842
					C29.093,32.862,29.475,32.532,29.475,32.021"
      />
      <polygon
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        points="32.901,33.646 31.846,33.646 30.498,30.39 31.593,30.39
					32.376,32.396 33.153,30.39 34.248,30.39 				"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M37.907,33.646h-1.036v-0.259c-0.252,0.227-0.589,0.355-1.01,0.355
					c-0.861,0-1.586-0.691-1.586-1.722c0-1.023,0.725-1.722,1.586-1.722c0.421,0,0.758,0.129,1.01,0.356v-0.259h1.036V33.646z
					 M36.871,32.021c0-0.498-0.376-0.841-0.796-0.841c-0.454,0-0.784,0.343-0.784,0.841c0,0.505,0.33,0.842,0.784,0.842
					C36.495,32.862,36.871,32.525,36.871,32.021"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M41.003,33.504c-0.252,0.136-0.519,0.238-0.887,0.238
					c-0.81,0-1.296-0.439-1.296-1.294v-1.23h-0.589v-0.823h0.589v-0.959h1.036v0.959h0.958v0.823h-0.958v1.126
					c0,0.337,0.162,0.472,0.435,0.472c0.142,0,0.336-0.051,0.473-0.129L41.003,33.504z"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M44.456,32.287h-2.384c0.084,0.388,0.35,0.628,0.699,0.628
					c0.239,0,0.519-0.058,0.693-0.376l0.919,0.188c-0.279,0.686-0.868,1.016-1.612,1.016c-0.938,0-1.716-0.693-1.716-1.722
					c0-1.023,0.777-1.722,1.722-1.722c0.92,0,1.658,0.66,1.679,1.722V32.287z M42.092,31.651h1.34
					c-0.097-0.35-0.355-0.511-0.654-0.511C42.486,31.141,42.188,31.32,42.092,31.651"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#EB008B"
        d="M19.827,37.711l-1.469,0.299c-0.108-0.571-0.581-0.853-1.062-0.853
					c-0.498,0-0.816,0.272-0.816,0.617c0,0.244,0.109,0.426,0.49,0.508l1.261,0.28c1.051,0.236,1.669,0.808,1.669,1.769
					c0,1.298-1.152,1.968-2.504,1.968c-1.369,0-2.493-0.652-2.657-1.84l1.542-0.3c0.154,0.625,0.59,0.897,1.197,0.897
					c0.563,0,0.898-0.254,0.898-0.626c0-0.271-0.127-0.443-0.563-0.535l-1.27-0.271c-0.871-0.199-1.633-0.653-1.633-1.76
					c0-1.179,0.979-1.914,2.403-1.914C18.64,35.95,19.601,36.604,19.827,37.711"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#EB008B"
        d="M23.898,41.964c-0.354,0.19-0.725,0.336-1.242,0.336
					c-1.134,0-1.814-0.617-1.814-1.814v-1.723h-0.824v-1.151h0.824v-1.343h1.451v1.343h1.343v1.151h-1.343v1.578
					c0,0.471,0.228,0.661,0.608,0.661c0.199,0,0.471-0.071,0.662-0.182L23.898,41.964z"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#EB008B"
        d="M28.967,42.163h-1.451V41.8c-0.353,0.318-0.824,0.499-1.413,0.499
					c-1.207,0-2.223-0.97-2.223-2.412c0-1.433,1.016-2.411,2.223-2.411c0.589,0,1.061,0.18,1.413,0.498V37.61h1.451V42.163z
					 M27.516,39.887c0-0.698-0.525-1.179-1.114-1.179c-0.636,0-1.098,0.48-1.098,1.179c0,0.707,0.462,1.179,1.098,1.179
					C26.99,41.065,27.516,40.594,27.516,39.887"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#EB008B"
        d="M34.525,41.709c0,1.405-1.069,2.177-2.548,2.177
					c-1.043,0-1.841-0.363-2.285-1.097l1.206-0.653c0.345,0.408,0.598,0.563,1.07,0.563c0.662,0,1.134-0.391,1.134-1.106v-0.127
					c-0.327,0.281-0.78,0.437-1.37,0.437c-1.188,0-2.194-0.944-2.194-2.232c0-1.26,1.006-2.193,2.194-2.193
					c0.59,0,1.043,0.153,1.37,0.435v-0.3h1.423V41.709z M33.103,39.669c0-0.607-0.481-1.033-1.062-1.033
					c-0.635,0-1.088,0.426-1.088,1.033s0.453,1.044,1.088,1.044C32.621,40.713,33.103,40.276,33.103,39.669"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#EB008B"
        d="M39.866,40.259h-3.337c0.118,0.544,0.49,0.879,0.979,0.879
					c0.336,0,0.727-0.081,0.972-0.525l1.287,0.263c-0.391,0.962-1.216,1.424-2.259,1.424c-1.314,0-2.402-0.97-2.402-2.412
					c0-1.433,1.088-2.411,2.412-2.411c1.287,0,2.321,0.924,2.349,2.411V40.259z M36.557,39.37h1.877
					c-0.137-0.489-0.499-0.717-0.916-0.717C37.109,38.653,36.692,38.907,36.557,39.37"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#EB008B"
        d="M46.559,41.048v1.115h-1.406v-1.115h-3.156v-1.343l2.711-3.616h1.852v3.762
					h0.734v1.197H46.559z M45.152,37.529l-1.641,2.321h1.641V37.529z"
      />
      <rect
        x="30.509"
        y="21.583"
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        width="0.681"
        height="1.934"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M32.429,20.745c0-0.222-0.181-0.402-0.401-0.402
					c-0.223,0-0.402,0.18-0.402,0.402v0.402h0.402C32.248,21.147,32.429,20.967,32.429,20.745"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M30.492,12.484c-2.625,0.18-4.719,2.36-4.803,4.989
					c-0.066,2.079,1.094,3.893,2.813,4.775c0.063,0.032,0.103,0.095,0.103,0.166v0.821c0,0.155,0.125,0.281,0.281,0.281h1.188
					v-1.934h-0.401c-0.462,0-0.837-0.376-0.837-0.837c0-0.461,0.375-0.836,0.837-0.836c0.461,0,0.836,0.375,0.836,0.836v0.402h0.681
					v-0.402c0-0.461,0.376-0.836,0.837-0.836s0.837,0.375,0.837,0.836c0,0.461-0.376,0.837-0.837,0.837h-0.401v1.934h1.208
					c0.154,0,0.28-0.126,0.28-0.281v-0.821c0-0.071,0.04-0.134,0.104-0.166c1.671-0.858,2.814-2.597,2.814-4.605
					C36.031,14.666,33.515,12.275,30.492,12.484 M30.85,14.036c-1.895,0-3.436,1.541-3.436,3.436c0,0.12-0.098,0.217-0.218,0.217
					s-0.217-0.097-0.217-0.217c0-2.133,1.736-3.87,3.87-3.87c0.12,0,0.217,0.098,0.217,0.217
					C31.066,13.939,30.97,14.036,30.85,14.036"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M30.074,20.745c0-0.222-0.181-0.402-0.402-0.402s-0.402,0.18-0.402,0.402
					c0,0.222,0.181,0.402,0.402,0.402h0.402V20.745z"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M32.695,24.099h-3.672c-0.24,0-0.435,0.194-0.435,0.435
					c0,0.24,0.194,0.434,0.435,0.434h3.672c0.24,0,0.435-0.194,0.435-0.434C33.13,24.292,32.936,24.099,32.695,24.099"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#88155E"
        d="M32.227,25.509h-2.735c-0.24,0-0.434,0.194-0.434,0.435
					c0,0.24,0.193,0.434,0.434,0.434h2.735c0.24,0,0.435-0.194,0.435-0.434C32.661,25.703,32.467,25.509,32.227,25.509"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#4F86C6"
        d="M30.858,59.515c-2.638,0-4.772-2.135-4.772-4.771s2.135-4.771,4.772-4.771
					c2.639,0,4.774,2.135,4.774,4.771S33.497,59.515,30.858,59.515"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#FFFFFF"
        d="M30.858,49.972c2.639,0,4.774,2.135,4.774,4.771s-2.136,4.771-4.774,4.771
					c-2.638,0-4.772-2.135-4.772-4.771S28.221,49.972,30.858,49.972 M30.858,49.56c-2.858,0-5.185,2.326-5.185,5.184
					s2.326,5.184,5.185,5.184c2.86,0,5.186-2.326,5.186-5.184S33.719,49.56,30.858,49.56"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#FFFFFF"
        d="M33.353,52.798c-0.239,0-0.464,0.107-0.62,0.252l-0.014-0.012
					c0.073-0.121,0.17-0.347,0.17-0.717c0-0.883-0.885-1.551-2.029-1.551c-1.146,0-2.041,0.668-2.041,1.551
					c0,0.37,0.119,0.596,0.179,0.717l-0.012,0.012c-0.155-0.145-0.382-0.252-0.62-0.252c-0.729,0-1.456,0.703-1.456,2.018
					c0,1.288,0.716,2.016,1.492,2.016c0.716,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.18-0.215-0.298-0.215
					c-0.119,0-0.252,0.085-0.479,0.263c-0.298,0.229-0.561,0.359-0.906,0.359c-0.609,0-0.87-0.683-0.87-1.575
					c0-0.955,0.251-1.587,0.895-1.587c0.286,0,0.477,0.083,0.668,0.262c0.286,0.263,0.381,0.333,0.513,0.333
					c0.096,0,0.155-0.059,0.18-0.094c-0.215-0.215-0.525-0.668-0.525-1.253c0-0.668,0.561-1.134,1.611-1.134
					c1.049,0,1.611,0.466,1.611,1.134c0,0.585-0.312,1.038-0.525,1.253c0.023,0.035,0.083,0.094,0.179,0.094
					c0.131,0,0.227-0.07,0.513-0.333c0.179-0.179,0.382-0.262,0.668-0.262c0.645,0,0.896,0.632,0.896,1.587
					c0,0.893-0.263,1.575-0.871,1.575c-0.346,0-0.621-0.131-0.907-0.359c-0.227-0.178-0.357-0.263-0.477-0.263
					c-0.12,0-0.227,0.107-0.299,0.215c0.44,0.453,0.955,0.848,1.671,0.848c0.774,0,1.491-0.728,1.491-2.016
					C34.809,53.501,34.08,52.798,33.353,52.798"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#FFFFFF"
        d="M31.658,52.774h-0.537c-0.035,0-0.059-0.023-0.071-0.048l-0.168-0.512
					c-0.012-0.036-0.022-0.036-0.034,0l-0.168,0.512c-0.012,0.036-0.035,0.048-0.06,0.048h-0.537c-0.024,0-0.035,0.012-0.012,0.024
					l0.442,0.321c0.022,0.023,0.022,0.061,0.011,0.084l-0.167,0.5c-0.012,0.024-0.012,0.05,0.024,0.024l0.441-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.036,0.013,0.023-0.011l-0.166-0.514
					c-0.012-0.035,0-0.071,0.024-0.084l0.439-0.321C31.694,52.786,31.682,52.774,31.658,52.774"
      />
      <path
        clipPath="url(#SVGID_26_)"
        fill="#FFFFFF"
        d="M31.253,56.306c-0.228-0.572-0.406-0.967-0.406-1.443
					c0-0.203,0.061-0.382,0.097-0.477c0.047-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.227,0.167-0.322,0.311
					c-0.072,0.119-0.155,0.347-0.155,0.524c0,0.335,0.108,0.692,0.18,0.907c0.132,0.369,0.251,0.8,0.251,1.206
					c0,0.645-0.287,1.084-0.8,1.478c0.048,0.037,0.166,0.096,0.311,0.096c0.5,0,1.097-0.561,1.097-1.323
					C31.515,56.974,31.384,56.663,31.253,56.306"
      />
    </g>
  </g>
);

const InnovateStage3 = () => (
  <g id="Stage_3_4_">
    <polygon
      fill="#EBBCC2"
      points="57.566,19.717 57.566,50.559 30.857,65.979 4.147,50.559 4.147,19.717 30.857,4.296 			"
    />
    <polygon fill="#EE95A7" points="57.566,50.56 30.857,65.979 30.857,35.14 			" />
    <polygon fill="#EE95A7" points="57.566,19.719 57.566,50.56 30.858,35.14 			" />
    <polygon
      fill="#EE95A7"
      points="30.857,4.293 57.565,19.713 30.857,35.133 			"
    />
    <g>
      <defs>
        <rect
          id="SVGID_27_"
          x="0.014"
          y="0.034"
          width="61.687"
          height="70.205"
        />
      </defs>
      <clipPath id="SVGID_28_">
        <use xlinkHref="#SVGID_27_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_28_)"
        fill="#EB008B"
        d="M30.856,4.609l26.438,15.263V50.4L30.856,65.663L4.42,50.4V19.872
					L30.856,4.609 M30.856,0.034c-0.573,0-1.145,0.148-1.657,0.444L1.672,16.371c-1.027,0.593-1.658,1.688-1.658,2.872v31.786
					c0,1.185,0.631,2.279,1.658,2.872l27.527,15.893c0.513,0.297,1.084,0.444,1.657,0.444s1.146-0.147,1.659-0.444l27.526-15.893
					c1.026-0.593,1.659-1.688,1.659-2.872V19.243c0-1.185-0.633-2.279-1.659-2.872L32.516,0.479
					C32.002,0.182,31.43,0.034,30.856,0.034"
      />
      <rect
        x="17.471"
        y="29.342"
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        width="1.068"
        height="4.337"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M22.522,31.687v1.994h-1.03v-1.774c0-0.382-0.226-0.641-0.557-0.641
					c-0.407,0-0.673,0.279-0.673,0.907v1.508h-1.036v-3.25h1.036v0.31c0.246-0.258,0.589-0.407,0.997-0.407
					C22.018,30.334,22.522,30.871,22.522,31.687"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M26.461,31.687v1.994h-1.03v-1.774c0-0.382-0.228-0.641-0.558-0.641
					c-0.406,0-0.673,0.279-0.673,0.907v1.508h-1.036v-3.25H24.2v0.31c0.246-0.258,0.589-0.407,0.997-0.407
					C25.955,30.334,26.461,30.871,26.461,31.687"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M30.47,32.056c0,1.03-0.816,1.717-1.78,1.717c-0.985,0-1.8-0.687-1.8-1.717
					c0-1.023,0.814-1.709,1.8-1.709C29.653,30.347,30.47,31.033,30.47,32.056 M29.473,32.056c0-0.505-0.383-0.841-0.783-0.841
					c-0.422,0-0.804,0.336-0.804,0.841c0,0.512,0.382,0.842,0.804,0.842C29.09,32.897,29.473,32.567,29.473,32.056"
      />
      <polygon
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        points="32.898,33.681 31.844,33.681 30.496,30.425 31.591,30.425
					32.374,32.431 33.151,30.425 34.245,30.425 				"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M37.904,33.681h-1.035v-0.259c-0.252,0.227-0.59,0.355-1.011,0.355
					c-0.861,0-1.586-0.691-1.586-1.722c0-1.023,0.725-1.722,1.586-1.722c0.421,0,0.759,0.129,1.011,0.356v-0.259h1.035V33.681z
					 M36.869,32.056c0-0.498-0.377-0.841-0.797-0.841c-0.453,0-0.783,0.343-0.783,0.841c0,0.505,0.33,0.842,0.783,0.842
					C36.492,32.897,36.869,32.561,36.869,32.056"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M41.001,33.539c-0.252,0.136-0.519,0.238-0.888,0.238
					c-0.809,0-1.295-0.439-1.295-1.294v-1.23h-0.59v-0.823h0.59v-0.959h1.036v0.959h0.958v0.823h-0.958v1.126
					c0,0.337,0.162,0.472,0.435,0.472c0.142,0,0.336-0.051,0.473-0.129L41.001,33.539z"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M44.453,32.322h-2.384c0.085,0.388,0.35,0.628,0.7,0.628
					c0.238,0,0.518-0.058,0.693-0.376l0.918,0.188c-0.278,0.686-0.867,1.016-1.611,1.016c-0.939,0-1.716-0.693-1.716-1.722
					c0-1.023,0.776-1.722,1.723-1.722c0.919,0,1.657,0.66,1.677,1.722V32.322z M42.09,31.687h1.34
					c-0.098-0.35-0.356-0.511-0.653-0.511C42.484,31.176,42.187,31.355,42.09,31.687"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#EB008B"
        d="M20.047,37.746l-1.469,0.299c-0.109-0.571-0.581-0.853-1.061-0.853
					c-0.499,0-0.816,0.272-0.816,0.617c0,0.244,0.108,0.426,0.489,0.508l1.261,0.28c1.052,0.236,1.669,0.808,1.669,1.769
					c0,1.298-1.151,1.968-2.504,1.968c-1.368,0-2.493-0.652-2.657-1.84l1.542-0.3c0.154,0.625,0.59,0.897,1.197,0.897
					c0.562,0,0.897-0.254,0.897-0.626c0-0.271-0.127-0.443-0.562-0.535l-1.27-0.271c-0.87-0.199-1.633-0.653-1.633-1.76
					c0-1.179,0.979-1.914,2.403-1.914C18.859,35.985,19.82,36.639,20.047,37.746"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#EB008B"
        d="M24.118,41.999c-0.354,0.19-0.725,0.336-1.242,0.336
					c-1.134,0-1.813-0.617-1.813-1.814v-1.723h-0.825v-1.151h0.825v-1.343h1.451v1.343h1.342v1.151h-1.342v1.578
					c0,0.471,0.227,0.661,0.607,0.661c0.199,0,0.471-0.071,0.662-0.182L24.118,41.999z"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#EB008B"
        d="M29.188,42.198h-1.451v-0.363c-0.354,0.318-0.825,0.499-1.414,0.499
					c-1.207,0-2.223-0.97-2.223-2.412c0-1.433,1.016-2.411,2.223-2.411c0.589,0,1.061,0.18,1.414,0.498v-0.363h1.451V42.198z
					 M27.736,39.922c0-0.698-0.526-1.179-1.115-1.179c-0.635,0-1.097,0.48-1.097,1.179c0,0.707,0.462,1.179,1.097,1.179
					C27.21,41.101,27.736,40.629,27.736,39.922"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#EB008B"
        d="M34.746,41.744c0,1.405-1.07,2.177-2.548,2.177
					c-1.043,0-1.841-0.363-2.286-1.097l1.206-0.653c0.345,0.408,0.599,0.563,1.069,0.563c0.664,0,1.135-0.391,1.135-1.106V41.5
					c-0.326,0.281-0.78,0.437-1.369,0.437c-1.189,0-2.195-0.944-2.195-2.232c0-1.26,1.006-2.193,2.195-2.193
					c0.589,0,1.043,0.153,1.369,0.435v-0.3h1.424V41.744z M33.322,39.704c0-0.607-0.481-1.033-1.062-1.033
					c-0.635,0-1.088,0.426-1.088,1.033s0.453,1.044,1.088,1.044C32.841,40.748,33.322,40.312,33.322,39.704"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#EB008B"
        d="M40.086,40.294h-3.337c0.118,0.544,0.49,0.879,0.979,0.879
					c0.336,0,0.726-0.081,0.97-0.525l1.288,0.263c-0.391,0.962-1.215,1.424-2.258,1.424c-1.315,0-2.403-0.97-2.403-2.412
					c0-1.433,1.088-2.411,2.412-2.411c1.288,0,2.321,0.924,2.349,2.411V40.294z M36.776,39.405h1.877
					c-0.136-0.489-0.499-0.717-0.916-0.717C37.329,38.688,36.912,38.942,36.776,39.405"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#EB008B"
        d="M46.959,40.375c0,1.152-0.934,1.959-2.267,1.959
					c-1.279,0-2.214-0.743-2.35-1.923l1.415-0.262c0.081,0.599,0.408,0.943,0.943,0.943c0.508,0,0.87-0.291,0.87-0.735
					c0-0.399-0.299-0.636-0.824-0.636H43.93v-1.188h0.754c0.517,0,0.815-0.227,0.815-0.616c0-0.417-0.317-0.698-0.807-0.698
					c-0.49,0-0.854,0.299-0.907,0.979l-1.369-0.263c0.136-1.207,1.034-1.95,2.268-1.95c1.297,0,2.177,0.798,2.177,1.914
					c0,0.553-0.236,0.979-0.654,1.224C46.688,39.369,46.959,39.804,46.959,40.375"
      />
      <rect
        x="30.506"
        y="21.618"
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        width="0.682"
        height="1.934"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M32.427,20.78c0-0.222-0.181-0.402-0.401-0.402
					c-0.223,0-0.402,0.18-0.402,0.402v0.402h0.402C32.246,21.183,32.427,21.002,32.427,20.78"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M30.489,12.52c-2.624,0.18-4.718,2.36-4.802,4.989
					c-0.067,2.079,1.094,3.893,2.813,4.775c0.062,0.032,0.103,0.095,0.103,0.166v0.821c0,0.155,0.125,0.281,0.281,0.281h1.188
					v-1.934h-0.401c-0.462,0-0.837-0.376-0.837-0.837c0-0.461,0.375-0.836,0.837-0.836c0.461,0,0.836,0.375,0.836,0.836v0.402h0.681
					v-0.402c0-0.461,0.376-0.836,0.837-0.836s0.837,0.375,0.837,0.836c0,0.461-0.376,0.837-0.837,0.837h-0.402v1.934h1.208
					c0.155,0,0.281-0.126,0.281-0.281V22.45c0-0.071,0.04-0.134,0.104-0.166c1.671-0.858,2.813-2.597,2.813-4.605
					C36.028,14.701,33.513,12.311,30.489,12.52 M30.848,14.071c-1.895,0-3.436,1.541-3.436,3.436c0,0.12-0.098,0.217-0.218,0.217
					s-0.217-0.097-0.217-0.217c0-2.133,1.736-3.87,3.87-3.87c0.12,0,0.217,0.098,0.217,0.217
					C31.064,13.975,30.968,14.071,30.848,14.071"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M30.072,20.78c0-0.222-0.181-0.402-0.402-0.402s-0.401,0.18-0.401,0.402
					c0,0.222,0.18,0.402,0.401,0.402h0.402V20.78z"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M32.692,24.134h-3.672c-0.239,0-0.434,0.194-0.434,0.435
					c0,0.24,0.194,0.434,0.434,0.434h3.672c0.24,0,0.435-0.194,0.435-0.434C33.127,24.328,32.933,24.134,32.692,24.134"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#88155E"
        d="M32.225,25.544h-2.735c-0.24,0-0.434,0.194-0.434,0.435
					c0,0.24,0.193,0.434,0.434,0.434h2.735c0.239,0,0.435-0.194,0.435-0.434C32.659,25.738,32.464,25.544,32.225,25.544"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#4F86C6"
        d="M30.856,59.55c-2.638,0-4.772-2.135-4.772-4.771s2.135-4.771,4.772-4.771
					c2.639,0,4.773,2.135,4.773,4.771S33.495,59.55,30.856,59.55"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#FFFFFF"
        d="M30.856,50.007c2.639,0,4.773,2.135,4.773,4.771s-2.135,4.771-4.773,4.771
					c-2.638,0-4.772-2.135-4.772-4.771S28.219,50.007,30.856,50.007 M30.856,49.595c-2.858,0-5.185,2.326-5.185,5.184
					s2.326,5.184,5.185,5.184c2.859,0,5.186-2.326,5.186-5.184S33.716,49.595,30.856,49.595"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#FFFFFF"
        d="M33.351,52.833c-0.239,0-0.465,0.107-0.62,0.252l-0.014-0.012
					c0.072-0.121,0.169-0.347,0.169-0.717c0-0.883-0.884-1.551-2.028-1.551s-2.041,0.668-2.041,1.551c0,0.37,0.12,0.596,0.179,0.717
					l-0.012,0.012c-0.155-0.145-0.382-0.252-0.62-0.252c-0.729,0-1.456,0.703-1.456,2.018c0,1.288,0.716,2.016,1.492,2.016
					c0.716,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.18-0.215-0.299-0.215c-0.118,0-0.251,0.085-0.477,0.263
					c-0.299,0.229-0.562,0.359-0.908,0.359c-0.608,0-0.869-0.683-0.869-1.575c0-0.955,0.25-1.587,0.895-1.587
					c0.286,0,0.477,0.083,0.668,0.262c0.286,0.263,0.381,0.333,0.513,0.333c0.096,0,0.155-0.059,0.179-0.094
					c-0.214-0.215-0.524-0.668-0.524-1.253c0-0.668,0.561-1.134,1.611-1.134c1.049,0,1.609,0.466,1.609,1.134
					c0,0.585-0.31,1.038-0.523,1.253c0.023,0.035,0.083,0.094,0.178,0.094c0.132,0,0.228-0.07,0.514-0.333
					c0.179-0.179,0.382-0.262,0.668-0.262c0.645,0,0.895,0.632,0.895,1.587c0,0.893-0.263,1.575-0.87,1.575
					c-0.346,0-0.621-0.131-0.908-0.359c-0.226-0.178-0.356-0.263-0.476-0.263c-0.12,0-0.227,0.107-0.299,0.215
					c0.44,0.453,0.955,0.848,1.67,0.848c0.775,0,1.492-0.728,1.492-2.016C34.807,53.536,34.078,52.833,33.351,52.833"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#FFFFFF"
        d="M31.656,52.81h-0.537c-0.036,0-0.059-0.023-0.071-0.048L30.88,52.25
					c-0.012-0.036-0.022-0.036-0.034,0l-0.168,0.512c-0.013,0.036-0.035,0.048-0.06,0.048H30.08c-0.022,0-0.035,0.012-0.012,0.024
					l0.443,0.321c0.022,0.023,0.022,0.061,0.011,0.084l-0.167,0.5c-0.012,0.024-0.012,0.05,0.024,0.024l0.441-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.024,0.025,0.035,0.013,0.024-0.011l-0.167-0.514
					c-0.013-0.035,0-0.071,0.023-0.084l0.44-0.321C31.692,52.821,31.68,52.81,31.656,52.81"
      />
      <path
        clipPath="url(#SVGID_28_)"
        fill="#FFFFFF"
        d="M31.25,56.341c-0.226-0.572-0.405-0.967-0.405-1.443
					c0-0.203,0.06-0.382,0.096-0.477c0.048-0.096,0.06-0.156,0.011-0.156c-0.07,0-0.226,0.167-0.32,0.311
					c-0.073,0.119-0.156,0.347-0.156,0.524c0,0.335,0.107,0.692,0.179,0.907c0.132,0.369,0.251,0.8,0.251,1.206
					c0,0.645-0.287,1.084-0.799,1.478c0.046,0.037,0.167,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.323
					C31.513,57.009,31.382,56.698,31.25,56.341"
      />
    </g>
  </g>
);
// #endregion

// #startregion Communicate Groups
const CommunicateStage5 = () => (
  <g id="Stage_5_5_">
    <polygon
      fill="#EBBCC2"
      points="57.571,19.693 57.571,50.535 30.861,65.955 4.151,50.535 4.151,19.693 30.861,4.272 			"
    />
    <polygon
      fill="#EE95A7"
      points="57.571,50.535 30.863,65.955 30.863,35.115 			"
    />
    <polygon
      fill="#EE95A7"
      points="57.571,19.694 57.571,50.534 30.863,35.114 			"
    />
    <polygon fill="#EE95A7" points="30.862,4.269 57.57,19.688 30.862,35.108 			" />
    <polygon fill="#EE95A7" points="30.86,65.955 4.152,50.535 30.86,35.115 			" />
    <polygon fill="#EE95A7" points="30.862,35.115 4.155,50.535 4.155,19.695 			" />
    <g>
      <defs>
        <rect
          id="SVGID_29_"
          x="0.018"
          y="0.01"
          width="61.687"
          height="70.204"
        />
      </defs>
      <clipPath id="SVGID_30_">
        <use xlinkHref="#SVGID_29_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_30_)"
        fill="#EB008B"
        d="M30.861,4.585l26.438,15.263v30.528L30.861,65.639L4.425,50.376V19.848
					L30.861,4.585 M30.861,0.01c-0.573,0-1.145,0.148-1.658,0.445L1.677,16.347c-1.027,0.593-1.658,1.687-1.658,2.872v31.786
					c0,1.185,0.631,2.279,1.658,2.872L29.203,69.77c0.514,0.297,1.085,0.444,1.658,0.444s1.146-0.147,1.658-0.444l27.527-15.893
					c1.026-0.593,1.657-1.688,1.657-2.872V19.219c0-1.185-0.631-2.279-1.657-2.872L32.52,0.455
					C32.007,0.158,31.435,0.01,30.861,0.01"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M13.674,32.032c-0.202,1.087-1.075,1.722-2.189,1.722
					c-1.288,0-2.305-0.99-2.305-2.266c0-1.269,1.017-2.266,2.305-2.266c1.069,0,1.975,0.634,2.182,1.664l-1.048,0.246
					c-0.137-0.628-0.577-0.933-1.134-0.933c-0.686,0-1.236,0.551-1.236,1.289c0,0.738,0.55,1.295,1.236,1.295
					c0.551,0,0.991-0.311,1.14-0.958L13.674,32.032z"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M17.482,32.032c0,1.03-0.816,1.716-1.781,1.716
					c-0.984,0-1.799-0.687-1.799-1.716c0-1.023,0.815-1.709,1.799-1.709C16.666,30.323,17.482,31.009,17.482,32.032 M16.485,32.032
					c0-0.505-0.382-0.842-0.784-0.842c-0.421,0-0.803,0.337-0.803,0.842c0,0.511,0.382,0.842,0.803,0.842
					C16.104,32.874,16.485,32.542,16.485,32.032"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M23.311,31.624v2.033h-1.03v-1.813c0-0.369-0.238-0.602-0.543-0.602
					c-0.382,0-0.635,0.278-0.635,0.822v1.593h-1.036v-1.813c0-0.369-0.24-0.602-0.55-0.602c-0.376,0-0.622,0.278-0.622,0.822v1.593
					h-1.036v-3.25h1.036v0.298c0.22-0.246,0.538-0.395,0.958-0.395c0.454,0,0.842,0.195,1.062,0.551
					c0.26-0.337,0.648-0.551,1.153-0.551C22.761,30.309,23.311,30.833,23.311,31.624"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M29.309,31.624v2.033h-1.03v-1.813c0-0.369-0.239-0.602-0.544-0.602
					c-0.382,0-0.634,0.278-0.634,0.822v1.593h-1.037v-1.813c0-0.369-0.239-0.602-0.55-0.602c-0.377,0-0.621,0.278-0.621,0.822v1.593
					h-1.036v-3.25h1.036v0.298c0.22-0.246,0.537-0.395,0.958-0.395c0.452,0,0.842,0.195,1.062,0.551
					c0.259-0.337,0.647-0.551,1.152-0.551C28.758,30.309,29.309,30.833,29.309,31.624"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M33.078,33.656h-1.036v-0.311c-0.246,0.26-0.589,0.408-0.997,0.408
					c-0.758,0-1.263-0.537-1.263-1.353v-1.994h1.029v1.774c0,0.382,0.227,0.641,0.558,0.641c0.406,0,0.673-0.278,0.673-0.907v-1.508
					h1.036V33.656z"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M36.964,31.662v1.994h-1.029v-1.774c0-0.382-0.227-0.641-0.557-0.641
					c-0.408,0-0.674,0.279-0.674,0.907v1.508h-1.036v-3.25h1.036v0.31c0.246-0.258,0.589-0.407,0.997-0.407
					C36.459,30.31,36.964,30.846,36.964,31.662"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M38.682,29.448c0,0.33-0.254,0.576-0.603,0.576
					c-0.35,0-0.596-0.246-0.596-0.576c0-0.317,0.246-0.589,0.596-0.589C38.428,28.859,38.682,29.131,38.682,29.448 M37.562,30.406
					h1.036v3.25h-1.036V30.406z"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M41.505,32.278l0.952,0.207c-0.162,0.771-0.85,1.269-1.658,1.269
					c-0.971,0-1.774-0.693-1.774-1.722c0-1.023,0.804-1.722,1.774-1.722c0.79,0,1.476,0.485,1.658,1.249l-0.973,0.234
					c-0.09-0.396-0.355-0.577-0.686-0.577c-0.467,0-0.777,0.343-0.777,0.816c0,0.479,0.311,0.816,0.777,0.816
					C41.129,32.848,41.395,32.673,41.505,32.278"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M46.252,33.656h-1.036v-0.259c-0.252,0.228-0.589,0.356-1.011,0.356
					c-0.86,0-1.587-0.692-1.587-1.722c0-1.023,0.727-1.722,1.587-1.722c0.422,0,0.759,0.129,1.011,0.356v-0.259h1.036V33.656z
					 M45.216,32.032c0-0.498-0.376-0.842-0.796-0.842c-0.454,0-0.784,0.344-0.784,0.842c0,0.505,0.33,0.842,0.784,0.842
					C44.84,32.874,45.216,32.537,45.216,32.032"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M49.25,33.514c-0.252,0.137-0.518,0.24-0.887,0.24
					c-0.809,0-1.295-0.44-1.295-1.294v-1.231h-0.59v-0.822h0.59v-0.958h1.036v0.958h0.958v0.822h-0.958v1.127
					c0,0.337,0.162,0.472,0.434,0.472c0.143,0,0.336-0.051,0.473-0.129L49.25,33.514z"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M52.605,32.297h-2.384c0.085,0.388,0.351,0.628,0.7,0.628
					c0.239,0,0.519-0.058,0.692-0.376l0.92,0.188c-0.278,0.687-0.868,1.017-1.612,1.017c-0.938,0-1.716-0.693-1.716-1.723
					c0-1.023,0.777-1.722,1.722-1.722c0.92,0,1.658,0.66,1.678,1.722V32.297z M50.242,31.662h1.34
					c-0.098-0.35-0.357-0.511-0.654-0.511C50.637,31.151,50.339,31.333,50.242,31.662"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#EB008B"
        d="M20.057,37.722l-1.469,0.299c-0.109-0.571-0.58-0.853-1.062-0.853
					c-0.499,0-0.815,0.272-0.815,0.617c0,0.244,0.108,0.426,0.489,0.508l1.261,0.28c1.052,0.236,1.669,0.808,1.669,1.769
					c0,1.298-1.152,1.969-2.503,1.969c-1.37,0-2.495-0.653-2.657-1.841l1.541-0.3c0.154,0.625,0.59,0.897,1.196,0.897
					c0.563,0,0.898-0.254,0.898-0.626c0-0.271-0.127-0.443-0.563-0.535l-1.27-0.271c-0.87-0.199-1.632-0.652-1.632-1.76
					c0-1.179,0.979-1.914,2.403-1.914C18.868,35.961,19.83,36.615,20.057,37.722"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#EB008B"
        d="M24.128,41.975c-0.354,0.19-0.726,0.336-1.242,0.336
					c-1.134,0-1.813-0.617-1.813-1.814v-1.723h-0.825v-1.152h0.825v-1.342h1.45v1.342h1.343v1.152h-1.343v1.578
					c0,0.471,0.228,0.662,0.608,0.662c0.2,0,0.471-0.073,0.662-0.183L24.128,41.975z"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#EB008B"
        d="M29.196,42.174h-1.45v-0.363c-0.354,0.318-0.825,0.499-1.414,0.499
					c-1.206,0-2.223-0.97-2.223-2.412c0-1.433,1.017-2.411,2.223-2.411c0.589,0,1.061,0.18,1.414,0.498v-0.363h1.45V42.174z
					 M27.746,39.897c0-0.698-0.525-1.179-1.115-1.179c-0.635,0-1.098,0.48-1.098,1.179c0,0.707,0.463,1.179,1.098,1.179
					C27.221,41.076,27.746,40.604,27.746,39.897"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#EB008B"
        d="M34.756,41.721c0,1.405-1.07,2.176-2.548,2.176
					c-1.044,0-1.841-0.362-2.286-1.096l1.206-0.654c0.345,0.408,0.599,0.563,1.069,0.563c0.664,0,1.135-0.39,1.135-1.107v-0.127
					c-0.327,0.282-0.78,0.437-1.369,0.437c-1.189,0-2.195-0.942-2.195-2.231c0-1.261,1.006-2.193,2.195-2.193
					c0.589,0,1.042,0.154,1.369,0.434v-0.299h1.424V41.721z M33.332,39.68c0-0.607-0.481-1.033-1.062-1.033
					c-0.635,0-1.088,0.426-1.088,1.033s0.453,1.043,1.088,1.043C32.851,40.723,33.332,40.287,33.332,39.68"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#EB008B"
        d="M40.096,40.27h-3.337c0.118,0.545,0.49,0.879,0.979,0.879
					c0.337,0,0.727-0.08,0.972-0.525l1.287,0.263c-0.391,0.962-1.215,1.425-2.259,1.425c-1.313,0-2.402-0.971-2.402-2.412
					c0-1.434,1.089-2.412,2.412-2.412c1.288,0,2.321,0.925,2.349,2.412V40.27z M36.786,39.381h1.877
					c-0.137-0.49-0.499-0.717-0.916-0.717C37.339,38.664,36.923,38.918,36.786,39.381"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#EB008B"
        d="M44.848,38.229c1.342,0,2.14,0.771,2.14,1.96
					c0,1.251-0.907,2.122-2.303,2.122c-1.251,0-2.313-0.717-2.35-2.086l1.415-0.281c0.008,0.789,0.462,1.097,0.96,1.097
					c0.518,0,0.871-0.335,0.871-0.834c0-0.454-0.344-0.753-0.871-0.753h-2.085l0.109-3.355h3.99v1.297h-2.686l-0.036,0.834H44.848z"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M37.262,16.941h-3.388v4.143c0,0.916-0.745,1.66-1.66,1.66h-3.774
					l-0.143,0.11v0.54c0,0.67,0.543,1.212,1.212,1.212h3.927l2.52,1.942c0.149,0.116,0.367,0.009,0.367-0.18v-1.762h0.939
					c0.669,0,1.212-0.542,1.212-1.212v-5.241C38.474,17.483,37.931,16.941,37.262,16.941"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#88155E"
        d="M28.286,22.296h3.928c0.67,0,1.212-0.543,1.212-1.212v-5.241
					c0-0.67-0.542-1.212-1.212-1.212h-7.752c-0.671,0-1.214,0.542-1.214,1.212v5.241c0,0.669,0.543,1.212,1.214,1.212h0.94v1.762
					c0,0.135,0.11,0.228,0.229,0.228c0.047,0,0.095-0.015,0.138-0.048L28.286,22.296z"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#4F86C6"
        d="M30.861,59.525c-2.638,0-4.772-2.135-4.772-4.771
					c0-2.636,2.135-4.771,4.772-4.771c2.639,0,4.773,2.136,4.773,4.771C35.635,57.391,33.5,59.525,30.861,59.525"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#FFFFFF"
        d="M30.861,49.981c2.639,0,4.773,2.136,4.773,4.772s-2.135,4.771-4.773,4.771
					c-2.638,0-4.772-2.135-4.772-4.771S28.224,49.981,30.861,49.981 M30.861,49.57c-2.858,0-5.185,2.325-5.185,5.184
					s2.326,5.184,5.185,5.184c2.859,0,5.186-2.325,5.186-5.184S33.721,49.57,30.861,49.57"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#FFFFFF"
        d="M33.355,52.809c-0.239,0-0.465,0.108-0.62,0.252l-0.014-0.012
					c0.072-0.119,0.169-0.347,0.169-0.717c0-0.883-0.885-1.551-2.028-1.551c-1.146,0-2.042,0.668-2.042,1.551
					c0,0.37,0.12,0.598,0.18,0.717l-0.013,0.012c-0.154-0.144-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.705-1.457,2.018
					c0,1.288,0.717,2.016,1.492,2.016c0.716,0,1.218-0.395,1.67-0.847c-0.071-0.108-0.179-0.215-0.298-0.215
					s-0.251,0.084-0.477,0.263c-0.299,0.228-0.562,0.358-0.908,0.358c-0.608,0-0.869-0.683-0.869-1.575
					c0-0.955,0.25-1.587,0.895-1.587c0.286,0,0.477,0.083,0.668,0.262c0.286,0.263,0.381,0.334,0.512,0.334
					c0.097,0,0.156-0.06,0.18-0.095c-0.214-0.215-0.525-0.668-0.525-1.253c0-0.668,0.562-1.133,1.612-1.133
					c1.049,0,1.609,0.465,1.609,1.133c0,0.585-0.31,1.038-0.524,1.253c0.023,0.035,0.084,0.095,0.179,0.095
					c0.132,0,0.228-0.071,0.513-0.334c0.18-0.179,0.383-0.262,0.668-0.262c0.645,0,0.896,0.632,0.896,1.587
					c0,0.893-0.263,1.575-0.871,1.575c-0.345,0-0.619-0.131-0.907-0.358c-0.226-0.179-0.356-0.263-0.477-0.263
					c-0.119,0-0.227,0.106-0.298,0.215c0.44,0.452,0.955,0.847,1.67,0.847c0.775,0,1.492-0.728,1.492-2.016
					C34.812,53.514,34.083,52.809,33.355,52.809"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#FFFFFF"
        d="M31.661,52.785h-0.537c-0.036,0-0.059-0.023-0.071-0.047l-0.168-0.514
					c-0.012-0.035-0.022-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.047-0.06,0.047h-0.537c-0.024,0-0.035,0.012-0.012,0.023
					l0.441,0.323c0.024,0.022,0.024,0.06,0.013,0.083l-0.167,0.501c-0.012,0.024-0.012,0.048,0.023,0.024l0.442-0.31
					c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.023,0.023,0.035,0.012,0.023-0.012l-0.166-0.514
					c-0.013-0.035,0-0.071,0.024-0.083l0.439-0.323C31.696,52.797,31.684,52.785,31.661,52.785"
      />
      <path
        clipPath="url(#SVGID_30_)"
        fill="#FFFFFF"
        d="M31.255,56.316c-0.226-0.573-0.405-0.966-0.405-1.443
					c0-0.203,0.06-0.382,0.096-0.477c0.047-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.167-0.321,0.311
					c-0.072,0.119-0.155,0.347-0.155,0.524c0,0.335,0.107,0.693,0.179,0.907c0.132,0.371,0.251,0.8,0.251,1.206
					c0,0.644-0.287,1.084-0.799,1.478c0.047,0.037,0.166,0.097,0.31,0.097c0.5,0,1.098-0.562,1.098-1.324
					C31.518,56.984,31.387,56.674,31.255,56.316"
      />
    </g>
  </g>
);

const CommunicateStage4 = () => (
  <g id="Stage_4_5_">
    <polygon
      fill="#EBBCC2"
      points="57.57,19.721 57.57,50.563 30.861,65.983 4.151,50.563 4.151,19.721 30.861,4.3 			"
    />
    <polygon
      fill="#EE95A7"
      points="57.569,50.563 30.861,65.983 30.861,35.144 			"
    />
    <polygon
      fill="#EE95A7"
      points="57.569,19.723 57.569,50.563 30.861,35.143 			"
    />
    <polygon
      fill="#EE95A7"
      points="30.861,4.297 57.569,19.717 30.861,35.137 			"
    />
    <polygon
      fill="#EE95A7"
      points="30.859,65.983 4.151,50.563 30.859,35.144 			"
    />
    <g>
      <defs>
        <rect
          id="SVGID_31_"
          x="0.017"
          y="0.038"
          width="61.687"
          height="70.204"
        />
      </defs>
      <clipPath id="SVGID_32_">
        <use xlinkHref="#SVGID_31_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_32_)"
        fill="#EB008B"
        d="M30.86,4.613l26.438,15.263v30.528L30.86,65.667L4.424,50.404V19.876
					L30.86,4.613 M30.86,0.038c-0.573,0-1.146,0.148-1.659,0.445L1.676,16.375c-1.026,0.593-1.659,1.687-1.659,2.872v31.786
					c0,1.185,0.633,2.279,1.659,2.872l27.526,15.893c0.513,0.297,1.086,0.444,1.659,0.444c0.572,0,1.146-0.147,1.658-0.444
					l27.527-15.893c1.026-0.593,1.658-1.688,1.658-2.872V19.247c0-1.185-0.632-2.279-1.658-2.872L32.519,0.483
					C32.006,0.187,31.433,0.038,30.86,0.038"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M13.672,32.06c-0.201,1.088-1.075,1.721-2.189,1.721
					c-1.288,0-2.305-0.989-2.305-2.265c0-1.269,1.017-2.266,2.305-2.266c1.069,0,1.976,0.634,2.182,1.664l-1.048,0.246
					c-0.136-0.628-0.577-0.933-1.134-0.933c-0.686,0-1.236,0.551-1.236,1.289c0,0.738,0.55,1.295,1.236,1.295
					c0.551,0,0.991-0.311,1.14-0.958L13.672,32.06z"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M17.48,32.06c0,1.03-0.815,1.715-1.78,1.715c-0.984,0-1.8-0.686-1.8-1.715
					c0-1.023,0.816-1.709,1.8-1.709C16.665,30.351,17.48,31.037,17.48,32.06 M16.484,32.06c0-0.505-0.382-0.842-0.783-0.842
					c-0.421,0-0.804,0.337-0.804,0.842c0,0.511,0.383,0.842,0.804,0.842C16.102,32.902,16.484,32.571,16.484,32.06"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M23.31,31.652v2.034h-1.03v-1.813c0-0.369-0.239-0.602-0.544-0.602
					c-0.381,0-0.634,0.278-0.634,0.822v1.594h-1.036v-1.813c0-0.369-0.24-0.602-0.55-0.602c-0.376,0-0.622,0.278-0.622,0.822v1.594
					h-1.036v-3.251h1.036v0.298c0.22-0.246,0.538-0.395,0.958-0.395c0.453,0,0.842,0.195,1.062,0.551
					c0.259-0.337,0.648-0.551,1.153-0.551C22.76,30.337,23.31,30.862,23.31,31.652"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M29.307,31.652v2.034h-1.029v-1.813c0-0.369-0.24-0.602-0.544-0.602
					c-0.382,0-0.635,0.278-0.635,0.822v1.594h-1.036v-1.813c0-0.369-0.239-0.602-0.55-0.602c-0.376,0-0.622,0.278-0.622,0.822v1.594
					h-1.036v-3.251h1.036v0.298c0.22-0.246,0.538-0.395,0.958-0.395c0.454,0,0.842,0.195,1.063,0.551
					c0.258-0.337,0.647-0.551,1.152-0.551C28.757,30.337,29.307,30.862,29.307,31.652"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M33.077,33.686h-1.036v-0.312c-0.246,0.259-0.589,0.408-0.997,0.408
					c-0.758,0-1.263-0.537-1.263-1.353v-1.994h1.03v1.774c0,0.382,0.226,0.641,0.557,0.641c0.407,0,0.673-0.278,0.673-0.907v-1.508
					h1.036V33.686z"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M36.963,31.69v1.994h-1.03v-1.774c0-0.382-0.227-0.641-0.557-0.641
					c-0.407,0-0.673,0.279-0.673,0.907v1.508h-1.036v-3.25h1.036v0.31c0.246-0.258,0.589-0.407,0.997-0.407
					C36.458,30.338,36.963,30.875,36.963,31.69"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M38.68,29.476c0,0.33-0.253,0.576-0.602,0.576
					c-0.35,0-0.596-0.246-0.596-0.576c0-0.317,0.246-0.589,0.596-0.589C38.427,28.887,38.68,29.159,38.68,29.476 M37.561,30.434
					h1.035v3.25h-1.035V30.434z"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M41.503,32.306l0.952,0.207c-0.162,0.771-0.848,1.269-1.656,1.269
					c-0.973,0-1.775-0.693-1.775-1.722c0-1.023,0.803-1.722,1.775-1.722c0.788,0,1.475,0.485,1.656,1.249l-0.971,0.234
					c-0.092-0.396-0.357-0.577-0.686-0.577c-0.468,0-0.777,0.343-0.777,0.816c0,0.479,0.31,0.816,0.777,0.816
					C41.127,32.876,41.393,32.701,41.503,32.306"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M46.251,33.686h-1.036v-0.26c-0.252,0.227-0.591,0.355-1.011,0.355
					c-0.861,0-1.587-0.691-1.587-1.721c0-1.023,0.726-1.722,1.587-1.722c0.42,0,0.759,0.129,1.011,0.356v-0.259h1.036V33.686z
					 M45.215,32.06c0-0.498-0.376-0.842-0.796-0.842c-0.454,0-0.784,0.344-0.784,0.842c0,0.505,0.33,0.842,0.784,0.842
					C44.839,32.902,45.215,32.565,45.215,32.06"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M49.249,33.543c-0.252,0.136-0.518,0.238-0.887,0.238
					c-0.809,0-1.295-0.439-1.295-1.293v-1.231h-0.589v-0.822h0.589v-0.958h1.036v0.958h0.958v0.822h-0.958v1.127
					c0,0.337,0.162,0.472,0.434,0.472c0.143,0,0.336-0.051,0.473-0.129L49.249,33.543z"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M52.604,32.326h-2.383c0.084,0.388,0.35,0.628,0.699,0.628
					c0.238,0,0.518-0.058,0.692-0.376l0.919,0.188c-0.277,0.686-0.867,1.018-1.611,1.018c-0.939,0-1.716-0.694-1.716-1.724
					c0-1.023,0.776-1.722,1.723-1.722c0.919,0,1.657,0.66,1.677,1.722V32.326z M50.241,31.69h1.34
					c-0.098-0.35-0.356-0.511-0.653-0.511C50.636,31.18,50.338,31.361,50.241,31.69"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#EB008B"
        d="M19.829,37.75l-1.469,0.299c-0.109-0.571-0.581-0.853-1.062-0.853
					c-0.498,0-0.816,0.272-0.816,0.617c0,0.244,0.109,0.426,0.49,0.508l1.261,0.28c1.051,0.236,1.669,0.808,1.669,1.769
					c0,1.298-1.152,1.969-2.504,1.969c-1.369,0-2.493-0.653-2.657-1.841l1.542-0.3c0.154,0.625,0.59,0.897,1.197,0.897
					c0.562,0,0.898-0.254,0.898-0.626c0-0.271-0.127-0.443-0.562-0.535l-1.27-0.271c-0.871-0.199-1.632-0.652-1.632-1.76
					c0-1.179,0.979-1.914,2.403-1.914C18.641,35.989,19.602,36.644,19.829,37.75"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#EB008B"
        d="M23.899,42.003c-0.354,0.19-0.725,0.336-1.242,0.336
					c-1.134,0-1.814-0.617-1.814-1.814v-1.723h-0.825v-1.152h0.825v-1.342h1.451v1.342h1.342v1.152h-1.342v1.578
					c0,0.471,0.227,0.662,0.608,0.662c0.199,0,0.471-0.073,0.662-0.183L23.899,42.003z"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#EB008B"
        d="M28.969,42.202h-1.451v-0.363c-0.353,0.318-0.825,0.499-1.414,0.499
					c-1.207,0-2.222-0.97-2.222-2.412c0-1.433,1.015-2.411,2.222-2.411c0.589,0,1.061,0.18,1.414,0.498v-0.363h1.451V42.202z
					 M27.518,39.926c0-0.698-0.526-1.179-1.115-1.179c-0.635,0-1.097,0.48-1.097,1.179c0,0.707,0.462,1.179,1.097,1.179
					C26.991,41.104,27.518,40.633,27.518,39.926"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#EB008B"
        d="M34.526,41.749c0,1.405-1.069,2.176-2.548,2.176
					c-1.043,0-1.841-0.362-2.285-1.096l1.206-0.654c0.345,0.408,0.598,0.563,1.07,0.563c0.663,0,1.134-0.39,1.134-1.107v-0.127
					c-0.327,0.282-0.78,0.437-1.37,0.437c-1.188,0-2.194-0.942-2.194-2.231c0-1.261,1.006-2.193,2.194-2.193
					c0.59,0,1.043,0.154,1.37,0.434v-0.299h1.423V41.749z M33.104,39.708c0-0.607-0.481-1.033-1.061-1.033
					c-0.635,0-1.088,0.426-1.088,1.033s0.453,1.043,1.088,1.043C32.623,40.751,33.104,40.315,33.104,39.708"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#EB008B"
        d="M39.867,40.298H36.53c0.118,0.545,0.49,0.879,0.979,0.879
					c0.336,0,0.726-0.08,0.971-0.525l1.287,0.263c-0.391,0.962-1.215,1.425-2.258,1.425c-1.315,0-2.403-0.971-2.403-2.412
					c0-1.434,1.088-2.412,2.412-2.412c1.288,0,2.322,0.925,2.349,2.412V40.298z M36.558,39.409h1.877
					c-0.136-0.49-0.499-0.717-0.916-0.717C37.11,38.692,36.693,38.946,36.558,39.409"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#EB008B"
        d="M46.56,41.087v1.115h-1.405v-1.115h-3.156v-1.343l2.711-3.618h1.851v3.764
					h0.734v1.197H46.56z M45.154,37.568l-1.642,2.321h1.642V37.568z"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M37.261,16.969h-3.388v4.143c0,0.916-0.745,1.66-1.66,1.66h-3.774
					l-0.143,0.11v0.54c0,0.67,0.543,1.212,1.212,1.212h3.927l2.52,1.942c0.149,0.116,0.366,0.009,0.366-0.18v-1.762h0.94
					c0.669,0,1.212-0.542,1.212-1.212v-5.241C38.473,17.511,37.93,16.969,37.261,16.969"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#88155E"
        d="M28.286,22.325h3.927c0.669,0,1.212-0.543,1.212-1.212v-5.241
					c0-0.67-0.543-1.212-1.212-1.212h-7.753c-0.669,0-1.212,0.542-1.212,1.212v5.241c0,0.669,0.543,1.212,1.212,1.212H25.4v1.762
					c0,0.135,0.111,0.228,0.229,0.228c0.047,0,0.095-0.015,0.138-0.048L28.286,22.325z"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#4F86C6"
        d="M30.86,59.554c-2.638,0-4.773-2.135-4.773-4.771
					c0-2.636,2.135-4.771,4.773-4.771c2.638,0,4.774,2.136,4.774,4.771C35.635,57.419,33.498,59.554,30.86,59.554"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#FFFFFF"
        d="M30.86,50.01c2.638,0,4.774,2.136,4.774,4.772s-2.137,4.771-4.774,4.771
					c-2.638,0-4.773-2.135-4.773-4.771S28.222,50.01,30.86,50.01 M30.86,49.599c-2.859,0-5.186,2.325-5.186,5.184
					s2.326,5.184,5.186,5.184c2.86,0,5.185-2.325,5.185-5.184S33.721,49.599,30.86,49.599"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#FFFFFF"
        d="M33.354,52.837c-0.238,0-0.464,0.108-0.62,0.252l-0.013-0.012
					c0.073-0.119,0.169-0.347,0.169-0.717c0-0.883-0.884-1.551-2.029-1.551s-2.041,0.668-2.041,1.551
					c0,0.37,0.119,0.598,0.179,0.717l-0.012,0.012c-0.155-0.144-0.382-0.252-0.62-0.252c-0.729,0-1.456,0.705-1.456,2.018
					c0,1.288,0.716,2.016,1.492,2.016c0.716,0,1.217-0.395,1.67-0.847c-0.072-0.108-0.18-0.215-0.298-0.215
					c-0.119,0-0.252,0.084-0.478,0.263c-0.298,0.227-0.561,0.358-0.907,0.358c-0.609,0-0.87-0.683-0.87-1.575
					c0-0.955,0.251-1.587,0.895-1.587c0.286,0,0.476,0.083,0.668,0.262c0.286,0.263,0.381,0.334,0.512,0.334
					c0.096,0,0.156-0.06,0.18-0.095c-0.215-0.215-0.524-0.668-0.524-1.253c0-0.668,0.56-1.134,1.611-1.134
					c1.049,0,1.611,0.466,1.611,1.134c0,0.585-0.311,1.038-0.525,1.253c0.023,0.035,0.083,0.095,0.179,0.095
					c0.131,0,0.226-0.071,0.512-0.334c0.179-0.179,0.382-0.262,0.668-0.262c0.646,0,0.896,0.632,0.896,1.587
					c0,0.893-0.263,1.575-0.871,1.575c-0.345,0-0.62-0.132-0.906-0.358c-0.227-0.179-0.358-0.263-0.477-0.263
					c-0.12,0-0.227,0.106-0.299,0.215c0.441,0.452,0.955,0.847,1.671,0.847c0.775,0,1.491-0.728,1.491-2.016
					C34.81,53.542,34.082,52.837,33.354,52.837"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#FFFFFF"
        d="M31.66,52.813h-0.537c-0.035,0-0.059-0.023-0.071-0.047l-0.168-0.514
					c-0.012-0.035-0.023-0.035-0.035,0l-0.167,0.514c-0.012,0.035-0.036,0.047-0.06,0.047h-0.537c-0.024,0-0.035,0.012-0.012,0.023
					l0.442,0.323c0.023,0.022,0.023,0.06,0.011,0.083l-0.167,0.501c-0.011,0.024-0.011,0.048,0.025,0.024l0.441-0.31
					c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.023,0.023,0.036,0.012,0.023-0.012l-0.166-0.514
					c-0.012-0.035,0-0.071,0.024-0.083l0.44-0.323C31.695,52.825,31.684,52.813,31.66,52.813"
      />
      <path
        clipPath="url(#SVGID_32_)"
        fill="#FFFFFF"
        d="M31.254,56.345c-0.227-0.573-0.406-0.966-0.406-1.443
					c0-0.203,0.06-0.382,0.096-0.477c0.047-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.167-0.322,0.311
					c-0.072,0.119-0.155,0.347-0.155,0.524c0,0.335,0.108,0.693,0.179,0.907c0.132,0.371,0.251,0.8,0.251,1.206
					c0,0.644-0.287,1.084-0.8,1.478c0.048,0.037,0.167,0.096,0.311,0.096c0.5,0,1.097-0.561,1.097-1.323
					C31.517,57.013,31.385,56.702,31.254,56.345"
      />
    </g>
  </g>
);

const CommunicateStage3 = () => (
  <g id="Stage_3_5_">
    <polygon
      fill="#EBBCC2"
      points="57.566,19.718 57.566,50.561 30.858,65.98 4.148,50.561 4.148,19.718 30.858,4.297 			"
    />
    <polygon
      fill="#EE95A7"
      points="57.566,50.561 30.858,65.98 30.858,35.141 			"
    />
    <polygon fill="#EE95A7" points="57.566,19.72 57.566,50.56 30.858,35.14 			" />
    <polygon
      fill="#EE95A7"
      points="30.858,4.294 57.566,19.714 30.858,35.134 			"
    />
    <g>
      <defs>
        <rect
          id="SVGID_33_"
          x="0.014"
          y="0.035"
          width="61.688"
          height="70.204"
        />
      </defs>
      <clipPath id="SVGID_34_">
        <use xlinkHref="#SVGID_33_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_34_)"
        fill="#EB008B"
        d="M30.857,4.61l26.437,15.263v30.528L30.857,65.664L4.42,50.401V19.873
					L30.857,4.61 M30.857,0.035c-0.573,0-1.145,0.148-1.658,0.445L1.672,16.372c-1.027,0.593-1.658,1.688-1.658,2.872V51.03
					c0,1.185,0.631,2.279,1.658,2.872l27.527,15.893c0.513,0.297,1.085,0.444,1.658,0.444c0.573,0,1.145-0.147,1.659-0.444
					l27.526-15.893c1.027-0.593,1.659-1.688,1.659-2.872V19.244c0-1.185-0.632-2.279-1.659-2.872L32.516,0.48
					C32.002,0.183,31.43,0.035,30.857,0.035"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M13.669,32.057c-0.201,1.088-1.075,1.723-2.189,1.723
					c-1.288,0-2.305-0.99-2.305-2.267c0-1.269,1.017-2.266,2.305-2.266c1.069,0,1.975,0.634,2.182,1.664l-1.048,0.246
					c-0.136-0.628-0.577-0.933-1.134-0.933c-0.686,0-1.236,0.551-1.236,1.289c0,0.738,0.55,1.295,1.236,1.295
					c0.551,0,0.991-0.311,1.14-0.958L13.669,32.057z"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M17.478,32.057c0,1.03-0.816,1.717-1.78,1.717
					c-0.985,0-1.8-0.687-1.8-1.717c0-1.022,0.815-1.709,1.8-1.709C16.662,30.348,17.478,31.034,17.478,32.057 M16.48,32.057
					c0-0.505-0.382-0.842-0.783-0.842c-0.422,0-0.804,0.337-0.804,0.842c0,0.511,0.382,0.842,0.804,0.842
					C16.099,32.899,16.48,32.568,16.48,32.057"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M23.306,31.649v2.033h-1.03v-1.813c0-0.369-0.239-0.602-0.544-0.602
					c-0.381,0-0.634,0.278-0.634,0.822v1.593h-1.036v-1.813c0-0.369-0.239-0.602-0.55-0.602c-0.376,0-0.622,0.278-0.622,0.822v1.593
					h-1.036v-3.25h1.036v0.298c0.22-0.246,0.538-0.395,0.958-0.395c0.454,0,0.842,0.195,1.062,0.551
					c0.259-0.337,0.648-0.551,1.153-0.551C22.756,30.334,23.306,30.859,23.306,31.649"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M29.304,31.649v2.033h-1.03v-1.813c0-0.369-0.239-0.602-0.544-0.602
					c-0.381,0-0.634,0.278-0.634,0.822v1.593H26.06v-1.813c0-0.369-0.239-0.602-0.55-0.602c-0.376,0-0.622,0.278-0.622,0.822v1.593
					h-1.036v-3.25h1.036v0.298c0.22-0.246,0.538-0.395,0.958-0.395c0.454,0,0.842,0.195,1.062,0.551
					c0.259-0.337,0.648-0.551,1.153-0.551C28.753,30.334,29.304,30.859,29.304,31.649"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M33.074,33.682h-1.037v-0.311c-0.246,0.259-0.589,0.408-0.997,0.408
					c-0.758,0-1.263-0.537-1.263-1.353v-1.994h1.03v1.774c0,0.382,0.226,0.641,0.557,0.641c0.407,0,0.673-0.278,0.673-0.907v-1.508
					h1.037V33.682z"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M36.96,31.688v1.994h-1.03v-1.774c0-0.382-0.227-0.641-0.557-0.641
					c-0.407,0-0.674,0.279-0.674,0.907v1.508h-1.035v-3.25h1.035v0.31c0.246-0.258,0.59-0.407,0.998-0.407
					C36.455,30.335,36.96,30.872,36.96,31.688"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M38.677,29.473c0,0.33-0.253,0.576-0.603,0.576s-0.596-0.246-0.596-0.576
					c0-0.317,0.246-0.589,0.596-0.589S38.677,29.156,38.677,29.473 M37.556,30.431h1.036v3.25h-1.036V30.431z"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M41.5,32.303l0.952,0.207c-0.162,0.771-0.849,1.27-1.658,1.27
					c-0.971,0-1.774-0.693-1.774-1.722c0-1.023,0.804-1.722,1.774-1.722c0.79,0,1.476,0.485,1.658,1.249l-0.972,0.234
					c-0.09-0.396-0.356-0.577-0.687-0.577c-0.466,0-0.776,0.343-0.776,0.816c0,0.479,0.311,0.816,0.776,0.816
					C41.124,32.873,41.391,32.698,41.5,32.303"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M46.247,33.682h-1.036v-0.259c-0.252,0.228-0.589,0.356-1.01,0.356
					c-0.861,0-1.586-0.692-1.586-1.723c0-1.022,0.725-1.722,1.586-1.722c0.421,0,0.758,0.129,1.01,0.356v-0.259h1.036V33.682z
					 M45.211,32.057c0-0.498-0.376-0.842-0.796-0.842c-0.454,0-0.784,0.344-0.784,0.842c0,0.505,0.33,0.842,0.784,0.842
					C44.835,32.899,45.211,32.562,45.211,32.057"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M49.246,33.539c-0.252,0.137-0.518,0.24-0.887,0.24
					c-0.81,0-1.295-0.44-1.295-1.294v-1.231h-0.59v-0.822h0.59v-0.958H48.1v0.958h0.959v0.822H48.1v1.127
					c0,0.337,0.162,0.472,0.435,0.472c0.142,0,0.336-0.051,0.473-0.129L49.246,33.539z"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M52.602,32.323h-2.384c0.085,0.388,0.35,0.628,0.7,0.628
					c0.238,0,0.518-0.058,0.692-0.376l0.919,0.188c-0.277,0.687-0.867,1.017-1.611,1.017c-0.939,0-1.717-0.693-1.717-1.723
					c0-1.023,0.777-1.722,1.723-1.722c0.92,0,1.658,0.66,1.678,1.722V32.323z M50.237,31.688h1.341
					c-0.098-0.35-0.356-0.511-0.654-0.511C50.633,31.177,50.334,31.358,50.237,31.688"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#EB008B"
        d="M20.048,37.746l-1.469,0.3c-0.109-0.571-0.581-0.853-1.061-0.853
					c-0.499,0-0.816,0.272-0.816,0.617c0,0.244,0.108,0.426,0.489,0.508l1.261,0.28c1.052,0.236,1.669,0.808,1.669,1.769
					c0,1.298-1.152,1.969-2.504,1.969c-1.368,0-2.493-0.653-2.657-1.842l1.542-0.299c0.154,0.625,0.59,0.897,1.197,0.897
					c0.562,0,0.898-0.254,0.898-0.626c0-0.271-0.127-0.443-0.562-0.535l-1.27-0.271c-0.87-0.199-1.632-0.652-1.632-1.76
					c0-1.179,0.979-1.914,2.403-1.914C18.859,35.986,19.821,36.641,20.048,37.746"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#EB008B"
        d="M24.119,42c-0.354,0.19-0.725,0.336-1.242,0.336
					c-1.134,0-1.814-0.617-1.814-1.814v-1.723h-0.825v-1.152h0.825v-1.342h1.451v1.342h1.342v1.152h-1.342v1.578
					c0,0.471,0.227,0.662,0.608,0.662c0.199,0,0.471-0.073,0.662-0.183L24.119,42z"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#EB008B"
        d="M29.188,42.199h-1.451v-0.363c-0.353,0.318-0.825,0.499-1.414,0.499
					c-1.207,0-2.222-0.97-2.222-2.412c0-1.433,1.015-2.412,2.222-2.412c0.589,0,1.061,0.181,1.414,0.499v-0.363h1.451V42.199z
					 M27.736,39.923c0-0.698-0.526-1.179-1.115-1.179c-0.635,0-1.097,0.48-1.097,1.179c0,0.707,0.462,1.179,1.097,1.179
					C27.21,41.102,27.736,40.63,27.736,39.923"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#EB008B"
        d="M34.746,41.746c0,1.405-1.07,2.176-2.548,2.176
					c-1.043,0-1.841-0.362-2.286-1.097l1.206-0.653c0.345,0.408,0.599,0.563,1.07,0.563c0.663,0,1.134-0.391,1.134-1.107V41.5
					c-0.326,0.282-0.78,0.437-1.369,0.437c-1.188,0-2.195-0.943-2.195-2.231c0-1.261,1.006-2.193,2.195-2.193
					c0.589,0,1.043,0.154,1.369,0.434v-0.299h1.424V41.746z M33.322,39.705c0-0.607-0.481-1.033-1.061-1.033
					c-0.635,0-1.088,0.426-1.088,1.033s0.453,1.043,1.088,1.043C32.841,40.748,33.322,40.313,33.322,39.705"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#EB008B"
        d="M40.086,40.295H36.75c0.117,0.545,0.489,0.879,0.979,0.879
					c0.336,0,0.727-0.08,0.97-0.525l1.288,0.263c-0.39,0.962-1.215,1.424-2.258,1.424c-1.314,0-2.403-0.97-2.403-2.411
					c0-1.434,1.089-2.412,2.412-2.412c1.288,0,2.322,0.924,2.349,2.412V40.295z M36.776,39.406h1.877
					c-0.136-0.49-0.499-0.717-0.916-0.717C37.33,38.689,36.912,38.943,36.776,39.406"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#EB008B"
        d="M46.959,40.377c0,1.151-0.934,1.959-2.267,1.959
					c-1.278,0-2.213-0.744-2.349-1.924l1.414-0.262c0.082,0.598,0.408,0.943,0.943,0.943c0.508,0,0.87-0.291,0.87-0.735
					c0-0.399-0.299-0.635-0.823-0.635h-0.817v-1.188h0.753c0.517,0,0.816-0.226,0.816-0.617c0-0.416-0.317-0.696-0.808-0.696
					S43.84,37.52,43.785,38.2l-1.369-0.263c0.137-1.207,1.034-1.95,2.268-1.95c1.297,0,2.177,0.798,2.177,1.913
					c0,0.555-0.235,0.98-0.653,1.225C46.688,39.371,46.959,39.805,46.959,40.377"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M37.258,16.966h-3.389v4.143c0,0.916-0.744,1.66-1.659,1.66h-3.774
					l-0.143,0.11v0.54c0,0.67,0.543,1.212,1.212,1.212h3.927l2.52,1.942c0.148,0.116,0.367,0.009,0.367-0.18v-1.762h0.939
					c0.669,0,1.212-0.542,1.212-1.212v-5.241C38.47,17.508,37.927,16.966,37.258,16.966"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#88155E"
        d="M28.282,22.322h3.927c0.669,0,1.212-0.543,1.212-1.212v-5.241
					c0-0.67-0.543-1.212-1.212-1.212h-7.752c-0.67,0-1.213,0.542-1.213,1.212v5.241c0,0.669,0.543,1.212,1.213,1.212h0.94v1.762
					c0,0.135,0.111,0.228,0.229,0.228c0.047,0,0.095-0.015,0.138-0.048L28.282,22.322z"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#4F86C6"
        d="M30.857,59.551c-2.638,0-4.773-2.135-4.773-4.771
					c0-2.636,2.135-4.771,4.773-4.771c2.638,0,4.773,2.136,4.773,4.771C35.631,57.416,33.495,59.551,30.857,59.551"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#FFFFFF"
        d="M30.857,50.007c2.638,0,4.773,2.136,4.773,4.772s-2.136,4.771-4.773,4.771
					c-2.638,0-4.773-2.135-4.773-4.771S28.219,50.007,30.857,50.007 M30.857,49.596c-2.859,0-5.185,2.325-5.185,5.184
					s2.326,5.184,5.185,5.184s5.185-2.325,5.185-5.184S33.717,49.596,30.857,49.596"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#FFFFFF"
        d="M33.351,52.834c-0.239,0-0.465,0.108-0.62,0.252l-0.013-0.012
					c0.072-0.119,0.168-0.347,0.168-0.717c0-0.883-0.883-1.551-2.028-1.551s-2.041,0.668-2.041,1.551c0,0.37,0.12,0.598,0.179,0.717
					l-0.012,0.012c-0.155-0.144-0.382-0.252-0.62-0.252c-0.729,0-1.456,0.705-1.456,2.018c0,1.288,0.716,2.016,1.492,2.016
					c0.716,0,1.217-0.395,1.67-0.847c-0.072-0.108-0.18-0.215-0.299-0.215c-0.118,0-0.251,0.084-0.476,0.263
					c-0.299,0.227-0.562,0.358-0.909,0.358c-0.608,0-0.869-0.683-0.869-1.575c0-0.955,0.25-1.587,0.895-1.587
					c0.286,0,0.476,0.083,0.668,0.262c0.286,0.263,0.381,0.334,0.512,0.334c0.096,0,0.156-0.06,0.179-0.095
					c-0.214-0.215-0.523-0.668-0.523-1.253c0-0.668,0.56-1.134,1.611-1.134c1.049,0,1.61,0.466,1.61,1.134
					c0,0.585-0.31,1.038-0.524,1.253c0.023,0.035,0.083,0.095,0.178,0.095c0.132,0,0.227-0.071,0.513-0.334
					c0.179-0.179,0.382-0.262,0.668-0.262c0.645,0,0.895,0.632,0.895,1.587c0,0.893-0.263,1.575-0.869,1.575
					c-0.346,0-0.621-0.132-0.908-0.358c-0.226-0.179-0.357-0.263-0.476-0.263c-0.12,0-0.227,0.106-0.299,0.215
					c0.441,0.452,0.955,0.847,1.67,0.847c0.776,0,1.492-0.728,1.492-2.016C34.807,53.539,34.078,52.834,33.351,52.834"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#FFFFFF"
        d="M31.657,52.811H31.12c-0.036,0-0.059-0.023-0.071-0.047l-0.168-0.514
					c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.047-0.059,0.047h-0.538c-0.023,0-0.035,0.012-0.012,0.023
					l0.443,0.323c0.023,0.022,0.023,0.06,0.011,0.083l-0.167,0.501c-0.011,0.024-0.011,0.048,0.025,0.024l0.441-0.31
					c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.024,0.023,0.035,0.012,0.024-0.012l-0.167-0.514
					c-0.013-0.035,0-0.071,0.023-0.083l0.441-0.323C31.692,52.822,31.68,52.811,31.657,52.811"
      />
      <path
        clipPath="url(#SVGID_34_)"
        fill="#FFFFFF"
        d="M31.251,56.342c-0.226-0.573-0.406-0.966-0.406-1.443
					c0-0.203,0.06-0.382,0.096-0.477c0.048-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.227,0.167-0.321,0.311
					c-0.073,0.119-0.156,0.347-0.156,0.524c0,0.335,0.108,0.693,0.179,0.907c0.132,0.371,0.251,0.8,0.251,1.206
					c0,0.644-0.287,1.084-0.799,1.478c0.046,0.037,0.167,0.096,0.31,0.096c0.5,0,1.097-0.561,1.097-1.323
					C31.513,57.01,31.382,56.699,31.251,56.342"
      />
    </g>
  </g>
);
// #endregion

const Innovate = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <InnovateStage3 {...otherProps} />;
    case 4:
      return <InnovateStage4 {...otherProps} />;
    case 5:
      return <InnovateStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

const Communicate = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <CommunicateStage3 {...otherProps} />;
    case 4:
      return <CommunicateStage4 {...otherProps} />;
    case 5:
      return <CommunicateStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

function ExpressMyself(props: any) {
  const { skill, stage, fallback = null, ...otherProps } = props;

  switch (skill) {
    case 'Innovate':
      return <Innovate stage={stage} {...otherProps} fallback={fallback} />;
    case 'Communicate':
      return <Communicate stage={stage} {...otherProps} fallback={fallback} />;
    default:
      return fallback;
  }
}

export default ExpressMyself;
