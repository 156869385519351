import { useContext } from 'react';

import { Context as AuthContext } from 'context/Auth';
import { Context as DatabaseContext } from 'context/Database';
import Button from 'components/button';
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal';
import { Roles } from '../../constants';
import { FirebaseUser } from 'types';
import { Small } from 'components/typography';

function SignInAsModal(props: any) {
  const { signInAs } = useContext(AuthContext);
  const { myUnit } = useContext(DatabaseContext);
  const { onClose } = props;
  const members = myUnit?.members || [];

  const patrolName = (patrolId?: string) =>
    patrolId !== undefined ? myUnit?.patrols?.[patrolId] : 'Unknown';

  const onSignInAsClick = (member: FirebaseUser) => {
    if (signInAs === undefined) return;
    const confirm = window.confirm(
      `Are you sure you want to sign in as user ${member.displayName}?`
    );

    if (confirm) {
      signInAs(member.uid, '');
      onClose();
    }
  };

  return (
    <Modal {...props} color="light">
      <ModalHeader title="Sign In As" color="dark" />
      <ModalBody className="pt-2">
        <ul>
          {members
            .filter((member) => member.role === Roles.Member)
            .map((member, i) => (
              <li
                key={`member_${i}`}
                style={{
                  padding: '0.5rem 1rem',
                  display: 'grid',
                  gridTemplateColumns: '1fr auto',
                  gridGap: '0.5rem',
                  alignItems: 'center'
                }}
              >
                <div>
                  <span>{member.displayName}</span>
                  <br />
                  <Small>Role: </Small>
                  <Small color="primary">{member.role}</Small>
                  {', '}
                  <Small>Patrol: </Small>
                  <Small color="primary">{patrolName(member.patrolId)}</Small>
                </div>
                <Button
                  color="warning"
                  className="button--small"
                  onClick={() => onSignInAsClick(member)}
                  disabled={signInAs === undefined}
                >
                  Sign In As
                </Button>
              </li>
            ))}
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button color="dark" block>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default SignInAsModal;
