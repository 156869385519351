// import React from 'react';
import BaseIcon, { UIIconProps } from './BaseIcon';

function Bolt(props: UIIconProps) {
  const { ...otherProps } = props;
  return (
    <BaseIcon {...otherProps}>
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <path d="M14.69,2.21L4.33,11.49c-0.64,0.58-0.28,1.65,0.58,1.73L13,14l-4.85,6.76c-0.22,0.31-0.19,0.74,0.08,1.01h0 c0.3,0.3,0.77,0.31,1.08,0.02l10.36-9.28c0.64-0.58,0.28-1.65-0.58-1.73L11,10l4.85-6.76c0.22-0.31,0.19-0.74-0.08-1.01l0,0 C15.47,1.93,15,1.92,14.69,2.21z" />
        </g>
      </g>
    </BaseIcon>
  );
}

export default Bolt;
