import React, {
  HTMLProps,
  PropsWithChildren,
  useEffect,
  useState
} from 'react';
import './collapse.scss';

type CollapseProps = HTMLProps<HTMLDivElement> &
  PropsWithChildren<{ show: boolean }>;

function Collapse(props: CollapseProps) {
  const [open, setOpen] = useState(false);
  const { children, show } = props;

  const classes = ['collapse'];
  if (open) classes.push('collapse--open');

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return <div className={classes.join(' ')}>{children}</div>;
}

export default Collapse;
