import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSwipeable } from 'react-swipeable';

import { GuidesTheme } from 'types';
import { GuidesThemesAreas as GTA } from '../../constants';
import { randomString, toCamelCase, toKebabCase } from 'utilities/string';
import DatabaseContext from 'context/Database';
import useMediaQuery from 'hooks/useMediaQuery';
import { Appbar, MenuButton } from 'components/layout';
import { LinkButton } from 'components/button';
import Dots from 'components/dots';
import GuidesThemeIcon from 'components/icons/GuidesTheme';
import { Heading, Paragraph } from 'components/typography';
import OverallProgress from './OverallProgress';

import './guides-themes.scss';
import Award from 'components/award';

function onSwipedHOC(
  guidesThemes: GuidesTheme[],
  currentThemeIndex: number,
  callback: (index: number) => void
) {
  const length = guidesThemes.length - 1;

  return function onSwiped({ dir: direction, event }: any) {
    // NOTE: this stops the propagation of the event
    // from reaching the document swipe listeners
    event.stopPropagation();

    let newActiveIndex = currentThemeIndex;

    if (direction === 'Left')
      newActiveIndex =
        currentThemeIndex < length ? currentThemeIndex + 1 : length;
    else if (direction === 'Right')
      newActiveIndex = currentThemeIndex > 0 ? currentThemeIndex - 1 : 0;

    callback(newActiveIndex);
  };
}

function GuidesThemes() {
  const urlParams = new URLSearchParams(window.location.search);
  const activeParamValue: string | null = urlParams.get('t');
  const activeIndex: number = parseInt(activeParamValue || '0', 10);

  const { guidesThemes, awardTargets, awardProgress, getAwards } =
    useContext(DatabaseContext);
  const [activeThemeIndex, setActiveThemeIndex] = useState(activeIndex);
  const [animated, setAnimated] = useState(false);

  const onSwipe = onSwipedHOC(
    guidesThemes,
    activeThemeIndex,
    setActiveThemeIndex
  );

  const swippableProps = useSwipeable({
    onSwipedLeft: onSwipe,
    onSwipedRight: onSwipe
    // NOTE: another approach via onSwiping
    // onSwiping: ({ event }) => event.stopPropagation(),
    // preventDefaultTouchmoveEvent: true
  });

  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 991px)');
  const styles = isMobile
    ? { transform: `translateX(-${100 * activeThemeIndex}vw)` }
    : {};

  const getToComplete = (activityType: GTA): [number, number] => {
    if (!awardTargets) return [0, 0];

    const key = toCamelCase(activityType);
    const targets = (awardTargets as Record<string, any>)[key];
    const activitiesToComplete =
      activityType === GTA.UnitMeetingActivities ? targets : targets[1];

    const typesToComplete =
      activityType === GTA.UnitMeetingActivities ? 1 : targets[0];

    return [activitiesToComplete, typesToComplete];
  };

  const getCompletedCount = (guidesTheme: GuidesTheme, activityType: GTA) => {
    if (!guidesTheme) return 0;

    const filteredActivities = awardProgress.filter(
      (item) =>
        item.program === activityType &&
        (item.theme === guidesTheme.name || false)
    );

    const groupedActivities = filteredActivities.reduce((acc, item) => {
      let key = item.category || item.program;

      if (item.stage) key += `_Stage_${item.stage}`;
      const group = acc[key] || [];

      group.push(item);
      acc[key] = group;
      return acc;
    }, {} as Record<string, any[]>);

    const toComplete = getToComplete(activityType);
    const highestProgressGroups =
      Object.keys(groupedActivities)
        .sort(
          (a, b) => groupedActivities[b].length - groupedActivities[a].length
        )
        .map((key) => groupedActivities[key])
        .filter((_, i) => i <= toComplete[1] - 1) || [];

    let completeCount = 0;
    if (activityType === GTA.UnitMeetingActivities)
      completeCount =
        highestProgressGroups.length > 0
          ? highestProgressGroups[0].reduce(
              (acc: any, current: { duration: any }) => acc + current.duration,
              0
            )
          : 0;
    else
      completeCount = highestProgressGroups.reduce(
        (acc, current) => acc + current.length,
        0
      );

    return completeCount;
  };

  const getOverallProgress = (guidesTheme: GuidesTheme, activityType: GTA) => {
    if (!guidesTheme || !awardTargets) return 0;

    const toComplete = getToComplete(activityType);
    const activitiesToComplete = toComplete[0];
    const typesToComplete = toComplete[1];
    const completeCount = getCompletedCount(guidesTheme, activityType);
    const progress =
      (100 / (activitiesToComplete * typesToComplete)) * completeCount;

    return progress <= 100 ? progress : 100;
  };

  const getNotification = (guidesTheme: GuidesTheme, activityType: GTA) => {
    if (!guidesTheme) return;
    const toComplete = getToComplete(activityType);
    const completeCount = getCompletedCount(guidesTheme, activityType);
    const remaining = toComplete[0] * toComplete[1] - completeCount;

    switch (activityType) {
      case GTA.UnitMeetingActivities:
        if (remaining <= 60 && remaining > 0)
          return `${remaining} minutes remaining`;
        break;
      case GTA.InterestBadges:
      case GTA.SkillsBuilders:
        if (remaining === 1) return `${remaining} activity remaining`;
        break;
      default:
        break;
    }

    return;
  };

  const awards = getAwards(getOverallProgress);

  useEffect(() => {
    navigate(`?t=${activeThemeIndex}`, { replace: true });
  }, [activeThemeIndex, navigate]);

  useEffect(() => {
    const root = document.getElementById('root');

    if (root) root.style.overflow = 'hidden';
    setTimeout(setAnimated, 100, true);

    return () => {
      if (root) root.style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Guides Themes</title>
      </Helmet>

      <Appbar
        title=""
        className="bg-none"
        rightButtons={[() => <MenuButton className="text-light" />]}
      />

      <div
        className={`g-themes${animated ? ' g-themes--animated' : ''}`}
        style={styles}
        {...swippableProps}
      >
        {guidesThemes.map((theme: GuidesTheme) => {
          const slug = toKebabCase(theme.name);
          const newClassName = `g-themes__item g-themes__item--${slug}`;

          const interestBadgesProgress = getOverallProgress(
            theme,
            GTA.InterestBadges
          );
          const skillsBuildersProgress = getOverallProgress(
            theme,
            GTA.SkillsBuilders
          );
          const umaProgress = getOverallProgress(
            theme,
            GTA.UnitMeetingActivities
          );

          const guidesThemeProgress =
            (interestBadgesProgress + skillsBuildersProgress + umaProgress) / 3;

          const showAward = guidesThemeProgress >= 100;
          const award = Object.keys(awards).find((key) =>
            awards[key].includes(theme.name)
          );

          return (
            <div key={randomString()} className={newClassName}>
              {showAward && (
                <div className="gt-award">
                  <Award name={theme.name} className="mb-2" />

                  <OverallProgress
                    title={`${award
                      ?.substring(0, 1)
                      .toUpperCase()}${award?.substring(1)} Award`}
                    color={award}
                    progress={(100 / 2) * awards[award!].length}
                  />

                  <LinkButton
                    to={`/guides-themes/${slug}`}
                    color={slug}
                    className={`button--large text-light mt-2`}
                    block
                  >
                    Do More
                  </LinkButton>
                </div>
              )}

              <GuidesThemeIcon
                name={theme.name}
                width={128}
                height={128}
                invert
              />

              <Heading size={1} color="light">
                {theme.name}
              </Heading>

              <Paragraph color="light" className="mb-3">
                {theme.description}
              </Paragraph>

              <OverallProgress
                title="Interest Badges"
                // color={slug}
                progress={interestBadgesProgress}
                notification={getNotification(theme, GTA.InterestBadges)}
                glass
              />

              <OverallProgress
                title="Skills Builders"
                // color={slug}
                progress={skillsBuildersProgress}
                notification={getNotification(theme, GTA.SkillsBuilders)}
                glass
              />

              <OverallProgress
                title="Unit Meeting Activities"
                // color={slug}
                progress={umaProgress}
                notification={getNotification(theme, GTA.UnitMeetingActivities)}
                glass
              />

              <LinkButton
                to={`/guides-themes/${slug}`}
                color="light"
                className={`button--large text-${slug} mt-2`}
                block
              >
                {guidesThemeProgress > 0 ? 'Continue' : 'Get Started'}
              </LinkButton>
            </div>
          );
        })}
      </div>

      {isMobile ? (
        <Dots
          count={guidesThemes.length}
          selectedIndexes={[activeThemeIndex]}
          onDotClick={(index) => setActiveThemeIndex(index)}
          className="g-themes-dots"
        />
      ) : null}
    </>
  );
}

export default GuidesThemes;
