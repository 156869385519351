type ClassesObject = { [key: string]: boolean };

export function randomString(length: number = 8) {
  return (Math.random() + 1).toString(36).substring(length);
}

export function classesToString(classes: ClassesObject, className?: string) {
  return (
    Object.keys(classes)
      .filter((key) => classes[key])
      .join(' ') + (className ? ` ${className}` : '')
  );
}

// function to convert a string to camel case
export function toCamelCase(value: string, seperator: string = ' ') {
  const words = value.split(seperator);
  const firstWord = words[0].toLowerCase();
  const restOfWords = words
    .slice(1)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  return [firstWord, ...restOfWords].join('');
}

// function to convert a string to title case
export function toTitleCase(value: string, seperator: string = ' ') {
  const words = value.split(seperator);
  const result = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return result;
}

// function to convert a string to pascal case
export function toPascalCase(value: string, seperator: string = ' ') {
  const camelCase = toCamelCase(value, seperator);
  const firstLetter = camelCase.charAt(0).toUpperCase();
  const restOfLetters = camelCase.slice(1);

  return firstLetter + restOfLetters;
}

// function to convert a string to kebab case
export function toKebabCase(value: string, seperator: string = ' ') {
  const words = value.split(seperator);
  const result = words.join('-').toLowerCase();

  return result;
}
