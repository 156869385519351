import React from 'react';

import { LinkButton } from 'components/button';
import { Heading, Paragraph } from 'components/typography';

import ErrorPage from './ErrorPage';

function SomethingWentWrong() {
  return (
    <ErrorPage errorCode={'generic'}>
      <div>
        <Heading size={1}>Oh no!</Heading>
        <Heading size={2} color="danger">
          Something went wrong
        </Heading>

        <Paragraph>
          Don't worry, it wasn't you - it was us.
          <br />
          Sorry about that.
        </Paragraph>

        <br />
        <LinkButton color="danger" to="/">
          Go to Home page
        </LinkButton>
      </div>
    </ErrorPage>
  );
}

export default SomethingWentWrong;
