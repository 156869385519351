import React from 'react';

const Craftivism = () => {
  return (
    <g id="Craftivism">
      <g display="inline">
        <defs>
          <rect
            id="CRAFTIVISM_SVGID_1_"
            x="-0.051"
            width="61.792"
            height="61.688"
          />
        </defs>
        <clipPath id="CRAFTIVISM_SVGID_2_">
          <use xlinkHref="#CRAFTIVISM_SVGID_1_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M28.1,34.748l-2.837,3.057c-0.155,0.168,0.017,0.34,0.184,0.188l3.487-3.209"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M43.155,21.703c1.305-1.199,2.043-2.654,1.583-3.115
				c-0.457-0.455-1.894,0.27-3.09,1.559l-7.879,8.492c0.281,0.068,2.085-0.221,2.085-0.221L43.155,21.703"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#D76427"
          d="M41.271,22.057c-0.182-0.184,0.234-0.895,0.93-1.59
				c0.696-0.695,1.407-1.113,1.589-0.93c0.183,0.182-0.234,0.893-0.93,1.59C42.164,21.822,41.453,22.238,41.271,22.057"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="none"
          stroke="#5087C7"
          strokeWidth="0.454"
          strokeMiterlimit="10"
          d="M38.553,21.969
				c0.042,0.131,6.312-5.271,10.313,1.717c0,0,2.427,5.016,6.148,2.266"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M43.791,19.537c0.183,0.182-0.235,0.893-0.931,1.588
				c-0.695,0.697-1.406,1.113-1.589,0.93l-1.34,1.35l0.678,0.641l2.546-2.342c1.304-1.199,2.043-2.654,1.582-3.115"
        />
        <polyline
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="27.121,28.775 18.561,20.244 18.987,19.965 27.514,28.629 			"
        />
        <polyline
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="32.994,34.289 36.668,38.082 36.559,38.193 32.573,34.277 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#ED1C24"
          d="M19.231,19.543c0,0.572-0.464,1.037-1.036,1.037
				c-0.572,0-1.037-0.465-1.037-1.037c0-0.57,0.465-1.035,1.037-1.035C18.768,18.508,19.231,18.973,19.231,19.543"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#ED1C24"
          d="M40.616,18.166c0.011,1.217-4.444,2.244-9.951,2.293
				c-5.506,0.049-9.979-0.898-9.99-2.115c-0.011-1.219,4.444-2.246,9.951-2.295c5.162-0.045,9.417,0.785,9.936,1.891
				C40.598,18.014,40.615,18.09,40.616,18.166"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#ED1C24"
          points="40.579,16.217 40.596,18.166 20.676,18.342 20.658,16.373 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBB683"
          d="M40.574,16.217
				c0.012,1.207-4.438,2.225-9.938,2.275c-5.5,0.047-9.967-0.893-9.978-2.1c-0.011-1.209,4.438-2.227,9.938-2.275
				C36.096,14.068,40.563,15.008,40.574,16.217"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#A1233D"
          d="M33.936,16.275c0.004,0.4-1.475,0.736-3.299,0.754
				c-1.827,0.016-3.31-0.295-3.313-0.695c-0.005-0.402,1.473-0.74,3.299-0.756C32.448,15.563,33.932,15.875,33.936,16.275"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#5087C7"
          d="M30.804,55.361c-2.638,0-4.774-2.137-4.774-4.773s2.136-4.771,4.774-4.771
				c2.638,0,4.774,2.135,4.774,4.771S33.441,55.361,30.804,55.361"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M30.804,45.816c2.638,0,4.774,2.135,4.774,4.771s-2.137,4.773-4.774,4.773
				c-2.638,0-4.774-2.137-4.774-4.773S28.166,45.816,30.804,45.816 M30.804,45.404c-2.86,0-5.186,2.326-5.186,5.184
				c0,2.859,2.326,5.186,5.186,5.186c2.858,0,5.187-2.326,5.187-5.186C35.99,47.73,33.662,45.404,30.804,45.404"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M33.297,48.643c-0.238,0-0.464,0.107-0.619,0.252l-0.014-0.012
				c0.073-0.119,0.169-0.348,0.169-0.717c0-0.883-0.885-1.551-2.029-1.551c-1.145,0-2.042,0.668-2.042,1.551
				c0,0.369,0.12,0.598,0.18,0.717l-0.013,0.012c-0.155-0.145-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.705-1.457,2.018
				c0,1.287,0.717,2.016,1.492,2.016c0.716,0,1.218-0.395,1.67-0.848c-0.071-0.107-0.179-0.215-0.298-0.215
				c-0.119,0-0.251,0.084-0.476,0.264c-0.299,0.227-0.562,0.357-0.909,0.357c-0.608,0-0.869-0.68-0.869-1.574
				c0-0.955,0.25-1.588,0.895-1.588c0.286,0,0.476,0.084,0.668,0.264c0.286,0.262,0.381,0.332,0.512,0.332
				c0.096,0,0.156-0.059,0.179-0.094c-0.214-0.215-0.525-0.668-0.525-1.252c0-0.67,0.561-1.135,1.612-1.135
				c1.049,0,1.611,0.465,1.611,1.135c0,0.584-0.312,1.037-0.526,1.252c0.024,0.035,0.084,0.094,0.179,0.094
				c0.132,0,0.227-0.07,0.513-0.332c0.179-0.18,0.382-0.264,0.668-0.264c0.646,0,0.896,0.633,0.896,1.588
				c0,0.895-0.263,1.574-0.871,1.574c-0.346,0-0.62-0.131-0.907-0.357c-0.226-0.18-0.356-0.264-0.477-0.264
				c-0.119,0-0.226,0.107-0.298,0.215c0.441,0.453,0.955,0.848,1.671,0.848c0.775,0,1.491-0.729,1.491-2.016
				C34.753,49.348,34.025,48.643,33.297,48.643"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M31.603,48.619h-0.537c-0.036,0-0.059-0.023-0.071-0.047l-0.168-0.514
				c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.047-0.059,0.047h-0.538c-0.024,0-0.035,0.012-0.012,0.023
				l0.442,0.322c0.024,0.023,0.024,0.061,0.012,0.084l-0.167,0.5c-0.011,0.025-0.011,0.049,0.024,0.025l0.442-0.311
				c0.036-0.023,0.059-0.023,0.095,0l0.43,0.311c0.023,0.023,0.035,0.012,0.023-0.012l-0.166-0.514c-0.012-0.035,0-0.07,0.024-0.084
				l0.44-0.322C31.638,48.631,31.626,48.619,31.603,48.619"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M31.197,52.15c-0.226-0.572-0.406-0.965-0.406-1.443
				c0-0.203,0.06-0.381,0.096-0.477c0.047-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.168-0.321,0.311
				c-0.073,0.119-0.156,0.348-0.156,0.525c0,0.334,0.108,0.691,0.179,0.906c0.132,0.371,0.251,0.799,0.251,1.207
				c0,0.643-0.287,1.084-0.799,1.477c0.047,0.037,0.166,0.098,0.31,0.098c0.5,0,1.098-0.563,1.098-1.324
				C31.46,52.818,31.328,52.51,31.197,52.15"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M68.439-25.968c0.075,0,0.14,0.012,0.192,0.037
				c0.051,0.026,0.094,0.06,0.125,0.102c0.031,0.041,0.055,0.088,0.068,0.141c0.014,0.055,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.144-0.033,0.199c-0.023,0.058-0.055,0.104-0.096,0.14c-0.041,0.035-0.09,0.063-0.147,0.08
				s-0.123,0.027-0.192,0.027h-0.16v0.59h-0.299v-1.479H68.439z M68.349-25.299c0.059,0,0.106-0.019,0.144-0.054
				c0.037-0.036,0.056-0.093,0.056-0.168c0-0.074-0.017-0.132-0.049-0.171c-0.031-0.038-0.085-0.058-0.16-0.058h-0.123v0.45H68.349z
				"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="69.442,-25.969 69.653,-24.924 69.658,-24.924 69.871,-25.969
				70.313,-25.969 70.313,-24.489 70.039,-24.489 70.039,-25.671 70.035,-25.671 69.772,-24.489 69.539,-24.489 69.277,-25.671
				69.273,-25.671 69.273,-24.489 68.999,-24.489 68.999,-25.969 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M70.767-24.908c0,0.034,0.004,0.066,0.008,0.094
				c0.006,0.028,0.017,0.053,0.031,0.072c0.016,0.019,0.036,0.035,0.061,0.045c0.027,0.012,0.059,0.016,0.098,0.016
				c0.045,0,0.086-0.013,0.123-0.044c0.036-0.028,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.025-0.023-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.065-0.022-0.124-0.046-0.172-0.073c-0.05-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
				s-0.022-0.1-0.022-0.161c0-0.146,0.041-0.256,0.121-0.327c0.082-0.071,0.194-0.107,0.336-0.107c0.067,0,0.129,0.007,0.185,0.021
				c0.056,0.017,0.104,0.039,0.144,0.071c0.043,0.033,0.073,0.074,0.097,0.124c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.287
				c0-0.07-0.012-0.124-0.036-0.162c-0.025-0.038-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
				c-0.022,0.011-0.04,0.023-0.054,0.038c-0.014,0.017-0.021,0.035-0.026,0.056s-0.007,0.042-0.007,0.064
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.123,0.086l0.23,0.099c0.057,0.025,0.104,0.052,0.139,0.078
				c0.036,0.027,0.064,0.057,0.086,0.087s0.036,0.065,0.045,0.103c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.045,0.27-0.135,0.341s-0.217,0.106-0.379,0.106c-0.168,0-0.289-0.036-0.361-0.109s-0.108-0.179-0.108-0.315v-0.061
				h0.298V-24.908z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M72.033-24.489c0-0.065,0.007-0.126,0.02-0.183s0.033-0.11,0.06-0.161
				c0.027-0.052,0.061-0.102,0.103-0.151c0.04-0.049,0.088-0.098,0.142-0.146l0.121-0.113c0.032-0.029,0.06-0.055,0.08-0.08
				c0.02-0.025,0.034-0.05,0.045-0.075c0.012-0.024,0.02-0.052,0.022-0.081s0.005-0.062,0.005-0.099
				c0-0.122-0.048-0.184-0.145-0.184c-0.037,0-0.067,0.009-0.088,0.023c-0.022,0.015-0.039,0.035-0.051,0.062
				c-0.011,0.026-0.019,0.057-0.022,0.092c-0.003,0.035-0.005,0.071-0.005,0.109h-0.286v-0.056c0-0.136,0.036-0.241,0.11-0.315
				c0.072-0.072,0.188-0.108,0.346-0.108c0.15,0,0.26,0.034,0.332,0.103c0.072,0.069,0.107,0.168,0.107,0.296
				c0,0.048-0.004,0.092-0.013,0.133c-0.008,0.04-0.021,0.079-0.039,0.116c-0.019,0.038-0.041,0.074-0.071,0.109
				c-0.027,0.035-0.063,0.072-0.105,0.109l-0.165,0.152c-0.044,0.04-0.079,0.079-0.104,0.117c-0.023,0.039-0.041,0.071-0.047,0.098
				h0.553v0.233H72.033z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M73.039-25.467c0.006-0.094,0.024-0.177,0.056-0.25
				c0.03-0.072,0.076-0.13,0.137-0.174c0.063-0.043,0.147-0.065,0.256-0.065c0.091,0,0.164,0.013,0.224,0.039
				c0.06,0.025,0.106,0.066,0.142,0.123c0.034,0.057,0.06,0.13,0.074,0.22c0.014,0.091,0.021,0.201,0.021,0.331
				c0,0.103-0.004,0.2-0.01,0.294c-0.008,0.095-0.026,0.177-0.057,0.25c-0.029,0.072-0.077,0.13-0.139,0.171
				c-0.061,0.042-0.146,0.063-0.256,0.063c-0.088,0-0.162-0.011-0.221-0.035c-0.06-0.024-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.062-0.13-0.075-0.221s-0.021-0.201-0.021-0.33C73.028-25.276,73.03-25.374,73.039-25.467 M73.334-24.881
				c0.005,0.059,0.013,0.104,0.026,0.139c0.012,0.033,0.027,0.057,0.049,0.069c0.021,0.013,0.047,0.018,0.078,0.018
				c0.033,0,0.062-0.007,0.082-0.022c0.02-0.017,0.037-0.043,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.153
				c0.004-0.063,0.006-0.142,0.006-0.236v-0.158c0-0.094-0.002-0.168-0.006-0.228c-0.006-0.059-0.015-0.104-0.027-0.139
				c-0.012-0.034-0.029-0.057-0.049-0.069c-0.021-0.013-0.047-0.019-0.08-0.019c-0.032,0-0.06,0.007-0.08,0.023
				s-0.037,0.043-0.049,0.081c-0.012,0.037-0.02,0.088-0.024,0.152c-0.005,0.063-0.009,0.142-0.009,0.236v0.159
				C73.325-25.016,73.329-24.941,73.334-24.881"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M74.362-25.525H74.07v-0.195h0.027c0.101,0,0.177-0.018,0.235-0.056
				c0.057-0.038,0.094-0.097,0.108-0.181h0.22v1.468h-0.299V-25.525z"
        />

        <rect
          x="-13.247"
          y="-26.606"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBB683"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M-12.333-26.108c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.143c0.013,0.054,0.02,0.107,0.02,0.162
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.058-0.054,0.104-0.095,0.14c-0.041,0.035-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.026-0.193,0.026h-0.16v0.59h-0.298v-1.479H-12.333z M-12.424-25.439c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.048-0.171c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.45
				H-12.424z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="-11.33,-26.109 -11.119,-25.064 -11.114,-25.064 -10.9,-26.109
				-10.459,-26.109 -10.459,-24.629 -10.732,-24.629 -10.732,-25.81 -10.736,-25.81 -11,-24.629 -11.232,-24.629 -11.496,-25.81
				-11.5,-25.81 -11.5,-24.629 -11.773,-24.629 -11.773,-26.109 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M-10.005-25.047c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.037-0.028,0.056-0.074,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.035-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.123-0.327c0.081-0.071,0.193-0.107,0.336-0.107c0.066,0,0.127,0.007,0.183,0.021
				c0.056,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285
				c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
				s-0.04,0.023-0.053,0.038c-0.013,0.017-0.022,0.035-0.027,0.056c-0.005,0.021-0.007,0.042-0.007,0.064
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.052,0.139,0.078
				c0.036,0.027,0.065,0.057,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.103c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-25.047z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M-7.827-25.839c-0.061,0.086-0.115,0.178-0.162,0.276
				c-0.048,0.098-0.09,0.198-0.126,0.302c-0.036,0.104-0.066,0.21-0.089,0.316c-0.024,0.107-0.042,0.213-0.054,0.315h-0.313
				c0.011-0.088,0.027-0.181,0.05-0.278c0.022-0.098,0.052-0.196,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
				c0.053-0.104,0.115-0.203,0.187-0.3h-0.618v-0.257h0.904V-25.839z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M-7.404-25.666h-0.292v-0.194h0.026c0.1,0,0.178-0.019,0.235-0.056
				c0.058-0.038,0.094-0.098,0.109-0.181h0.22v1.467h-0.298V-25.666z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M-6.425-24.862c0.021,0.045,0.065,0.067,0.13,0.067
				c0.035,0,0.064-0.007,0.086-0.019c0.021-0.014,0.037-0.029,0.049-0.052c0.012-0.021,0.019-0.047,0.023-0.074
				c0.003-0.029,0.005-0.061,0.005-0.092c0-0.033-0.002-0.064-0.007-0.095c-0.005-0.028-0.014-0.056-0.028-0.079
				c-0.013-0.023-0.033-0.043-0.058-0.057c-0.025-0.013-0.058-0.021-0.099-0.021h-0.1v-0.194h0.097c0.032,0,0.059-0.006,0.08-0.02
				c0.022-0.013,0.039-0.029,0.052-0.051c0.014-0.021,0.023-0.047,0.029-0.075c0.006-0.026,0.009-0.056,0.009-0.087
				c0-0.069-0.012-0.119-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.031,0-0.057,0.006-0.077,0.017
				c-0.02,0.012-0.036,0.029-0.047,0.05c-0.011,0.022-0.018,0.047-0.022,0.076c-0.003,0.029-0.005,0.062-0.005,0.095h-0.273
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.07,0.181-0.106,0.322-0.106c0.134,0,0.237,0.03,0.312,0.092
				c0.074,0.061,0.11,0.155,0.11,0.286c0,0.088-0.019,0.16-0.059,0.216c-0.039,0.057-0.094,0.091-0.164,0.107v0.004
				c0.095,0.015,0.162,0.053,0.202,0.114c0.039,0.062,0.059,0.139,0.059,0.231c0,0.049-0.006,0.102-0.019,0.153
				c-0.012,0.053-0.037,0.1-0.072,0.143c-0.037,0.043-0.085,0.078-0.149,0.104c-0.062,0.025-0.145,0.039-0.247,0.039
				c-0.142,0-0.249-0.038-0.32-0.116c-0.07-0.077-0.105-0.188-0.105-0.329v-0.007h0.286C-6.458-24.972-6.446-24.908-6.425-24.862"
        />

        <rect
          x="26.732"
          y="-26.606"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBB683"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M27.646-26.108c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.143c0.013,0.054,0.02,0.107,0.02,0.162
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.058-0.054,0.104-0.095,0.14c-0.041,0.035-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.026-0.193,0.026h-0.16v0.59h-0.298v-1.479H27.646z M27.555-25.439c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.048-0.171c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.45H27.555
				z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="28.649,-26.109 28.86,-25.064 28.866,-25.064 29.079,-26.109
				29.52,-26.109 29.52,-24.629 29.247,-24.629 29.247,-25.81 29.243,-25.81 28.979,-24.629 28.747,-24.629 28.483,-25.81
				28.479,-25.81 28.479,-24.629 28.206,-24.629 28.206,-26.109 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M29.974-25.047c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.028,0.056-0.074,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.035-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.123-0.327c0.081-0.071,0.193-0.107,0.336-0.107c0.066,0,0.127,0.007,0.183,0.021
				c0.056,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285
				c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.023,0.01-0.04,0.023-0.053,0.038c-0.013,0.017-0.022,0.035-0.027,0.056c-0.005,0.021-0.007,0.042-0.007,0.064
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.052,0.139,0.078
				c0.036,0.027,0.065,0.057,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.103c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-25.047z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M32.152-25.839c-0.062,0.086-0.115,0.178-0.162,0.276
				c-0.048,0.098-0.09,0.198-0.126,0.302c-0.036,0.104-0.066,0.21-0.089,0.316c-0.024,0.107-0.042,0.213-0.054,0.315h-0.313
				c0.011-0.088,0.027-0.181,0.05-0.278c0.022-0.098,0.051-0.196,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
				c0.053-0.104,0.115-0.203,0.187-0.3h-0.618v-0.257h0.904V-25.839z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M32.575-25.666h-0.292v-0.194h0.025c0.101,0,0.179-0.019,0.235-0.056
				c0.058-0.038,0.095-0.098,0.108-0.181h0.221v1.467h-0.298V-25.666z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M33.555-24.862c0.021,0.045,0.064,0.067,0.129,0.067
				c0.035,0,0.064-0.007,0.086-0.019c0.021-0.014,0.037-0.029,0.05-0.052c0.012-0.021,0.019-0.047,0.022-0.074
				c0.004-0.029,0.006-0.061,0.006-0.092c0-0.033-0.002-0.064-0.008-0.095c-0.005-0.028-0.014-0.056-0.027-0.079
				s-0.033-0.043-0.059-0.057c-0.025-0.013-0.058-0.021-0.099-0.021h-0.101v-0.194h0.098c0.031,0,0.059-0.006,0.08-0.02
				c0.021-0.013,0.039-0.029,0.052-0.051c0.015-0.021,0.022-0.047,0.028-0.075c0.007-0.026,0.01-0.056,0.01-0.087
				c0-0.069-0.012-0.119-0.035-0.148s-0.059-0.043-0.107-0.043c-0.031,0-0.058,0.006-0.078,0.017
				c-0.02,0.012-0.035,0.029-0.047,0.05c-0.011,0.022-0.018,0.047-0.021,0.076c-0.003,0.029-0.005,0.062-0.005,0.095h-0.272
				c0-0.146,0.035-0.255,0.108-0.326c0.073-0.07,0.181-0.106,0.321-0.106c0.135,0,0.238,0.03,0.313,0.092
				c0.074,0.061,0.11,0.155,0.11,0.286c0,0.088-0.019,0.16-0.06,0.216c-0.039,0.057-0.094,0.091-0.164,0.107v0.004
				c0.096,0.015,0.162,0.053,0.202,0.114c0.039,0.062,0.06,0.139,0.06,0.231c0,0.049-0.008,0.102-0.02,0.153
				c-0.012,0.053-0.037,0.1-0.072,0.143c-0.037,0.043-0.084,0.078-0.148,0.104c-0.063,0.025-0.145,0.039-0.247,0.039
				c-0.142,0-0.249-0.038-0.319-0.116c-0.07-0.077-0.105-0.188-0.105-0.329v-0.007h0.285C33.521-24.972,33.533-24.908,33.555-24.862
				"
        />
        <rect
          x="-3.415"
          y="-26.606"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#F78E1E"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M-2.5-26.108c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.142c0.013,0.054,0.02,0.107,0.02,0.162
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.058-0.054,0.104-0.095,0.14c-0.041,0.035-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.026-0.193,0.026h-0.16v0.59h-0.298v-1.479H-2.5z M-2.592-25.439c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.048-0.171c-0.032-0.037-0.085-0.058-0.159-0.058h-0.125v0.45H-2.592
				z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="-1.497,-26.109 -1.286,-25.062 -1.281,-25.062 -1.068,-26.109
				-0.627,-26.109 -0.627,-24.628 -0.9,-24.628 -0.9,-25.81 -0.904,-25.81 -1.167,-24.628 -1.4,-24.628 -1.663,-25.81
				-1.667,-25.81 -1.667,-24.628 -1.941,-24.628 -1.941,-26.109 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M-0.173-25.047c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.054,0.03,0.072s0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.028,0.056-0.074,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.035-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.123-0.327c0.081-0.071,0.193-0.107,0.336-0.107
				c0.066,0,0.127,0.007,0.183,0.021c0.056,0.017,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0.18c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.037-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.014c-0.022,0.011-0.04,0.023-0.053,0.038c-0.013,0.017-0.022,0.035-0.027,0.056
				c-0.005,0.021-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.052,0.139,0.078c0.036,0.027,0.065,0.057,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.103
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-25.047z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M1.434-25.665H1.142v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.056
				c0.058-0.038,0.094-0.098,0.109-0.18h0.22v1.467H1.434V-25.665z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M2.566-26.096h0.309v0.931h0.142v0.232H2.875v0.305H2.603v-0.305H2.081
				v-0.245L2.566-26.096z M2.599-25.723l-0.287,0.558h0.291v-0.558H2.599z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M3.561-26.096H3.87v0.931h0.142v0.232H3.87v0.305H3.597v-0.305H3.075v-0.245
				L3.561-26.096z M3.593-25.723l-0.287,0.558h0.291v-0.558H3.593z"
        />
        <rect
          x="6.743"
          y="-26.606"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#D76427"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M7.657-26.108c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.142c0.013,0.054,0.02,0.107,0.02,0.162
				c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.058-0.055,0.104-0.095,0.14c-0.041,0.035-0.091,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.026-0.193,0.026h-0.16v0.59H7.135v-1.479H7.657z M7.566-25.439c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.048-0.171c-0.032-0.037-0.085-0.058-0.16-0.058H7.433v0.45H7.566z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="8.66,-26.109 8.871,-25.062 8.876,-25.062 9.089,-26.109
				9.531,-26.109 9.531,-24.628 9.257,-24.628 9.257,-25.81 9.253,-25.81 8.99,-24.628 8.757,-24.628 8.495,-25.81 8.491,-25.81
				8.491,-24.628 8.217,-24.628 8.217,-26.109 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M9.984-25.047c0,0.034,0.003,0.066,0.008,0.094
				c0.005,0.028,0.016,0.054,0.031,0.072s0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.025-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.035-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.122-0.327c0.082-0.071,0.194-0.107,0.336-0.107c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.017,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.07-0.013-0.124-0.037-0.162c-0.025-0.037-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
				c-0.023,0.011-0.04,0.023-0.054,0.038c-0.013,0.017-0.021,0.035-0.026,0.056c-0.005,0.021-0.007,0.042-0.007,0.064
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.052,0.139,0.078
				c0.036,0.027,0.064,0.057,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.103c0.007,0.038,0.011,0.079,0.011,0.125
				c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109s-0.108-0.179-0.108-0.315
				v-0.061h0.298V-25.047z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M11.591-25.665h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.235-0.056
				c0.057-0.038,0.094-0.098,0.109-0.18h0.219v1.467h-0.298V-25.665z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M12.548-25.834L12.517-25.5h0.004c0.028-0.04,0.064-0.067,0.108-0.085
				s0.098-0.025,0.162-0.025c0.07,0,0.128,0.013,0.175,0.04c0.046,0.027,0.083,0.063,0.11,0.107
				c0.026,0.045,0.046,0.094,0.057,0.149c0.01,0.057,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.223
				c-0.013,0.065-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.083-0.144,0.107c-0.061,0.024-0.138,0.035-0.232,0.035
				c-0.093,0-0.168-0.011-0.225-0.036c-0.057-0.024-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.287c0,0.023,0.001,0.05,0.004,0.078c0.002,0.027,0.009,0.054,0.02,0.077
				c0.01,0.024,0.025,0.044,0.044,0.061c0.021,0.016,0.045,0.022,0.077,0.022c0.062,0,0.106-0.022,0.134-0.07
				c0.027-0.047,0.04-0.119,0.04-0.215c0-0.061-0.003-0.11-0.009-0.15c-0.005-0.041-0.014-0.072-0.027-0.096
				c-0.014-0.024-0.031-0.04-0.054-0.051c-0.021-0.009-0.049-0.014-0.082-0.014c-0.043,0-0.076,0.012-0.099,0.036
				c-0.022,0.024-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H12.548z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M13.24-24.628c0-0.065,0.007-0.126,0.02-0.183
				c0.013-0.057,0.033-0.11,0.06-0.161c0.027-0.053,0.06-0.102,0.102-0.151c0.04-0.049,0.088-0.098,0.141-0.147l0.121-0.112
				c0.033-0.029,0.06-0.056,0.08-0.08c0.02-0.025,0.035-0.05,0.046-0.075s0.019-0.052,0.022-0.081s0.005-0.062,0.005-0.099
				c0-0.122-0.048-0.184-0.145-0.184c-0.037,0-0.067,0.009-0.088,0.023c-0.022,0.015-0.038,0.035-0.05,0.062
				c-0.011,0.026-0.019,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.109H13.24v-0.056c0-0.136,0.037-0.241,0.11-0.315
				c0.073-0.072,0.188-0.108,0.346-0.108c0.15,0,0.26,0.034,0.332,0.103c0.072,0.069,0.108,0.168,0.108,0.296
				c0,0.047-0.004,0.092-0.013,0.133c-0.008,0.039-0.021,0.08-0.039,0.116c-0.018,0.038-0.041,0.074-0.071,0.109
				c-0.028,0.035-0.064,0.072-0.106,0.108l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117c-0.024,0.039-0.041,0.071-0.047,0.098
				h0.553v0.233H13.24z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M14.538-25.834L14.506-25.5h0.004c0.028-0.04,0.064-0.067,0.108-0.085
				c0.044-0.018,0.098-0.025,0.162-0.025c0.07,0,0.128,0.013,0.175,0.04c0.046,0.027,0.083,0.063,0.11,0.107
				c0.026,0.045,0.046,0.094,0.057,0.149c0.01,0.057,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.223
				c-0.013,0.065-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.083-0.144,0.107c-0.061,0.024-0.138,0.035-0.232,0.035
				c-0.093,0-0.168-0.011-0.225-0.036c-0.057-0.024-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.287c0,0.023,0.001,0.05,0.004,0.078c0.002,0.027,0.009,0.054,0.02,0.077
				c0.01,0.024,0.025,0.044,0.044,0.061c0.02,0.016,0.045,0.022,0.077,0.022c0.062,0,0.106-0.022,0.134-0.07
				c0.027-0.047,0.04-0.119,0.04-0.215c0-0.061-0.003-0.11-0.009-0.15c-0.005-0.041-0.014-0.072-0.027-0.096
				c-0.014-0.024-0.031-0.04-0.054-0.051c-0.021-0.009-0.049-0.014-0.082-0.014c-0.043,0-0.076,0.012-0.099,0.036
				c-0.022,0.024-0.037,0.057-0.042,0.099H14.26l0.053-0.798h0.765v0.232H14.538z"
        />
        <rect
          x="36.893"
          y="-26.606"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#213466"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M37.807-26.108c0.076,0,0.141,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.142c0.013,0.054,0.02,0.107,0.02,0.162
				c0,0.076-0.011,0.144-0.034,0.199c-0.022,0.058-0.054,0.104-0.095,0.14c-0.041,0.035-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.026-0.192,0.026h-0.16v0.59h-0.298v-1.479H37.807z M37.716-25.439c0.06,0,0.106-0.019,0.144-0.054
				c0.037-0.036,0.056-0.093,0.056-0.168c0-0.074-0.017-0.132-0.048-0.171c-0.032-0.037-0.086-0.058-0.159-0.058h-0.125v0.45H37.716
				z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="38.811,-26.109 39.021,-25.062 39.026,-25.062 39.239,-26.109
				39.681,-26.109 39.681,-24.628 39.407,-24.628 39.407,-25.81 39.403,-25.81 39.141,-24.628 38.907,-24.628 38.645,-25.81
				38.641,-25.81 38.641,-24.628 38.367,-24.628 38.367,-26.109 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M40.135-25.047c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.054,0.03,0.072c0.015,0.019,0.035,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.035-0.028,0.056-0.074,0.056-0.137c0-0.034-0.005-0.062-0.015-0.086
				c-0.008-0.025-0.023-0.046-0.045-0.066c-0.021-0.019-0.048-0.035-0.082-0.053c-0.033-0.015-0.074-0.031-0.125-0.048
				c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.014-0.046-0.022-0.1-0.022-0.161c0-0.146,0.041-0.256,0.123-0.327c0.081-0.071,0.192-0.107,0.336-0.107
				c0.065,0,0.127,0.007,0.183,0.021c0.057,0.017,0.104,0.039,0.146,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.284c0-0.07-0.014-0.124-0.039-0.162c-0.023-0.037-0.065-0.057-0.123-0.057
				c-0.033,0-0.062,0.005-0.084,0.014c-0.022,0.011-0.039,0.023-0.053,0.038c-0.013,0.017-0.021,0.035-0.027,0.056
				c-0.004,0.021-0.006,0.042-0.006,0.064c0,0.045,0.01,0.083,0.028,0.115c0.019,0.031,0.062,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.052,0.139,0.078c0.035,0.027,0.064,0.057,0.086,0.087s0.036,0.065,0.045,0.103
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341s-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.362-0.109c-0.071-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-25.047z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M41.401-24.628c0-0.065,0.007-0.126,0.021-0.183
				c0.012-0.057,0.032-0.11,0.059-0.161c0.027-0.053,0.061-0.102,0.102-0.151c0.041-0.049,0.088-0.098,0.143-0.147l0.121-0.112
				c0.032-0.029,0.06-0.056,0.078-0.08c0.021-0.025,0.036-0.05,0.047-0.075c0.012-0.025,0.02-0.052,0.022-0.081
				s0.005-0.062,0.005-0.099c0-0.122-0.048-0.184-0.145-0.184c-0.039,0-0.067,0.009-0.088,0.023
				c-0.022,0.015-0.039,0.035-0.051,0.062s-0.02,0.057-0.022,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.056
				c0-0.136,0.036-0.241,0.11-0.315c0.072-0.072,0.188-0.108,0.346-0.108c0.148,0,0.26,0.034,0.332,0.103
				c0.07,0.069,0.107,0.168,0.107,0.296c0,0.047-0.005,0.092-0.013,0.133c-0.009,0.039-0.021,0.08-0.039,0.116
				c-0.019,0.038-0.042,0.074-0.071,0.109s-0.063,0.072-0.105,0.108l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.041,0.071-0.048,0.098h0.554v0.233H41.401z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M43.308-25.838c-0.061,0.086-0.115,0.177-0.162,0.274
				c-0.048,0.1-0.09,0.2-0.126,0.303c-0.036,0.104-0.065,0.211-0.089,0.316c-0.023,0.107-0.042,0.212-0.055,0.316h-0.313
				c0.012-0.088,0.027-0.182,0.051-0.279c0.021-0.097,0.051-0.196,0.088-0.297c0.035-0.102,0.08-0.202,0.133-0.305
				c0.053-0.104,0.115-0.203,0.187-0.301h-0.617v-0.257h0.903V-25.838z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M43.868-26.096h0.31v0.931h0.142v0.232h-0.142v0.305h-0.272v-0.305h-0.522
				v-0.245L43.868-26.096z M43.901-25.723l-0.287,0.558h0.291v-0.558H43.901z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M45.298-25.838c-0.062,0.086-0.115,0.177-0.162,0.274
				c-0.049,0.1-0.09,0.2-0.127,0.303c-0.035,0.104-0.065,0.211-0.088,0.316c-0.024,0.107-0.043,0.212-0.055,0.316h-0.313
				c0.011-0.088,0.026-0.182,0.05-0.279c0.022-0.097,0.052-0.196,0.088-0.297c0.036-0.102,0.081-0.202,0.133-0.305
				c0.054-0.104,0.115-0.203,0.187-0.301h-0.617v-0.257h0.904V-25.838z"
        />
        <rect
          x="47.032"
          y="-26.606"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#5087C7"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M47.945-26.108c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.025,0.094,0.06,0.125,0.101c0.031,0.042,0.055,0.089,0.068,0.143s0.021,0.107,0.021,0.162
				c0,0.076-0.013,0.144-0.034,0.199c-0.023,0.058-0.055,0.104-0.095,0.14c-0.041,0.035-0.091,0.063-0.147,0.081
				c-0.059,0.017-0.123,0.026-0.193,0.026h-0.16v0.59h-0.299v-1.479H47.945z M47.854-25.439c0.059,0,0.107-0.019,0.145-0.054
				c0.037-0.036,0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.049-0.171c-0.031-0.039-0.084-0.058-0.159-0.058h-0.124v0.45H47.854
				z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="48.948,-26.109 49.159,-25.064 49.165,-25.064 49.378,-26.109
				49.819,-26.109 49.819,-24.629 49.546,-24.629 49.546,-25.81 49.542,-25.81 49.278,-24.629 49.046,-24.629 48.784,-25.81
				48.78,-25.81 48.78,-24.629 48.505,-24.629 48.505,-26.109 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M50.272-25.047c0,0.034,0.004,0.066,0.009,0.094
				c0.005,0.028,0.017,0.052,0.03,0.072c0.016,0.019,0.037,0.035,0.062,0.045c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.004-0.062-0.014-0.086
				c-0.008-0.025-0.023-0.047-0.043-0.066c-0.021-0.019-0.049-0.035-0.082-0.053c-0.033-0.016-0.076-0.032-0.125-0.048
				c-0.066-0.022-0.125-0.046-0.173-0.073c-0.049-0.025-0.089-0.057-0.12-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.122-0.327c0.082-0.071,0.194-0.107,0.336-0.107
				c0.067,0,0.128,0.007,0.185,0.021c0.056,0.016,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.125-0.038-0.162c-0.023-0.039-0.066-0.057-0.123-0.057
				c-0.033,0-0.061,0.004-0.082,0.014c-0.023,0.01-0.041,0.023-0.055,0.038c-0.014,0.017-0.021,0.035-0.025,0.056
				c-0.006,0.021-0.008,0.042-0.008,0.064c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099
				c0.058,0.025,0.104,0.052,0.14,0.078c0.036,0.027,0.063,0.057,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.103
				c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341s-0.217,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
				s-0.107-0.179-0.107-0.315v-0.061h0.297V-25.047z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M51.539-24.629c0-0.064,0.007-0.125,0.021-0.182
				c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.103-0.149c0.039-0.051,0.088-0.1,0.141-0.148l0.121-0.112
				c0.033-0.028,0.061-0.056,0.08-0.081c0.02-0.024,0.035-0.05,0.046-0.074c0.011-0.025,0.019-0.052,0.022-0.081
				c0.002-0.029,0.004-0.063,0.004-0.1c0-0.121-0.047-0.183-0.145-0.183c-0.037,0-0.066,0.008-0.088,0.022
				c-0.021,0.016-0.038,0.036-0.05,0.063c-0.011,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.057
				c0-0.137,0.037-0.241,0.11-0.314c0.072-0.074,0.188-0.11,0.346-0.11c0.15,0,0.26,0.034,0.332,0.104s0.107,0.168,0.107,0.297
				c0,0.047-0.004,0.09-0.012,0.131c-0.009,0.041-0.021,0.081-0.039,0.117c-0.019,0.038-0.041,0.073-0.071,0.108
				c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.045,0.04-0.079,0.079-0.104,0.115c-0.024,0.039-0.041,0.071-0.048,0.1
				h0.554v0.231H51.539z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M53.446-25.839c-0.061,0.086-0.116,0.178-0.163,0.276
				c-0.047,0.098-0.089,0.198-0.126,0.302c-0.035,0.104-0.064,0.21-0.089,0.316c-0.022,0.107-0.041,0.213-0.054,0.315h-0.313
				c0.013-0.088,0.028-0.181,0.052-0.278c0.021-0.098,0.051-0.196,0.088-0.297c0.035-0.102,0.08-0.203,0.133-0.305
				c0.053-0.104,0.115-0.203,0.186-0.3h-0.617v-0.257h0.904V-25.839z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M53.868-25.666h-0.291v-0.194h0.026c0.101,0,0.177-0.019,0.235-0.056
				c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.299V-25.666z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M54.577-25.282c0.042-0.066,0.104-0.108,0.188-0.124v-0.004
				c-0.041-0.01-0.074-0.025-0.102-0.046c-0.025-0.021-0.048-0.044-0.064-0.07c-0.017-0.027-0.028-0.054-0.035-0.083
				c-0.008-0.03-0.01-0.057-0.01-0.083c0-0.144,0.037-0.247,0.115-0.31c0.075-0.063,0.18-0.095,0.315-0.095
				c0.134,0,0.239,0.031,0.315,0.095c0.076,0.063,0.115,0.166,0.115,0.31c0,0.026-0.004,0.053-0.012,0.083
				c-0.007,0.029-0.02,0.056-0.036,0.083c-0.017,0.026-0.038,0.049-0.065,0.07c-0.026,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.016,0.146,0.058,0.187,0.124c0.041,0.067,0.063,0.146,0.063,0.239c0,0.043-0.005,0.09-0.015,0.14
				c-0.011,0.052-0.033,0.099-0.066,0.144c-0.032,0.045-0.08,0.08-0.142,0.109c-0.061,0.029-0.143,0.044-0.245,0.044
				s-0.185-0.015-0.246-0.044s-0.108-0.064-0.142-0.109s-0.056-0.092-0.065-0.144c-0.011-0.05-0.017-0.097-0.017-0.14
				C54.515-25.136,54.536-25.214,54.577-25.282 M54.821-24.95c0.005,0.032,0.014,0.059,0.027,0.082
				c0.013,0.021,0.031,0.041,0.053,0.054c0.021,0.014,0.05,0.02,0.083,0.02c0.032,0,0.06-0.006,0.083-0.02
				c0.021-0.013,0.039-0.032,0.052-0.054c0.013-0.023,0.022-0.05,0.027-0.082c0.005-0.03,0.008-0.063,0.008-0.098
				c0-0.035-0.003-0.069-0.008-0.1c-0.005-0.031-0.015-0.057-0.027-0.08c-0.013-0.022-0.03-0.041-0.052-0.054
				c-0.023-0.013-0.051-0.019-0.083-0.019c-0.033,0-0.062,0.006-0.083,0.019s-0.04,0.031-0.053,0.054
				c-0.014,0.023-0.022,0.049-0.027,0.08c-0.005,0.03-0.007,0.064-0.007,0.1C54.814-25.013,54.816-24.98,54.821-24.95
				 M54.878-25.544c0.026,0.032,0.062,0.05,0.106,0.05c0.044,0,0.079-0.018,0.104-0.05c0.027-0.033,0.041-0.085,0.041-0.155
				c0-0.135-0.049-0.201-0.146-0.201s-0.146,0.066-0.146,0.201C54.839-25.629,54.853-25.578,54.878-25.544"
        />
        <rect
          x="57.047"
          y="-26.606"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          width="8.812"
          height="8.813"
        />

        <rect
          x="57.047"
          y="-26.606"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.812"
          height="8.812"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          points="57.638,-26.108 57.8,-25.014 57.806,-25.014 57.979,-26.108 58.266,-26.108
				58.439,-25.014 58.443,-25.014 58.607,-26.108 58.894,-26.108 58.624,-24.629 58.29,-24.629 58.124,-25.702 58.12,-25.702
				57.954,-24.629 57.62,-24.629 57.352,-26.108 			"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          points="59.312,-26.108 59.312,-25.542 59.661,-25.542 59.661,-26.108 59.959,-26.108
				59.959,-24.629 59.661,-24.629 59.661,-25.285 59.312,-25.285 59.312,-24.629 59.014,-24.629 59.014,-26.108 			"
        />
        <rect
          x="60.181"
          y="-26.108"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          width="0.299"
          height="1.479"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          points="61.567,-25.864 61.244,-25.864 61.244,-24.628 60.944,-24.628 60.944,-25.864
				60.622,-25.864 60.622,-26.109 61.567,-26.109 			"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          points="62.522,-25.864 61.993,-25.864 61.993,-25.515 62.49,-25.515 62.49,-25.271
				61.993,-25.271 61.993,-24.874 62.544,-24.874 62.544,-24.628 61.695,-24.628 61.695,-26.109 62.522,-26.109 			"
        />
        <rect
          x="16.884"
          y="-26.606"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#ED1C24"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M17.782-26.067c0.076,0,0.14,0.014,0.191,0.038
				c0.053,0.026,0.095,0.061,0.126,0.102c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.053,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.142-0.034,0.199c-0.023,0.057-0.055,0.103-0.096,0.138c-0.04,0.037-0.09,0.064-0.147,0.081
				c-0.058,0.018-0.123,0.028-0.194,0.028h-0.159v0.59H17.26v-1.48H17.782z M17.691-25.397c0.059,0,0.107-0.018,0.144-0.054
				c0.036-0.036,0.054-0.092,0.054-0.168c0-0.074-0.015-0.131-0.047-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.449H17.691z
				"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="18.785,-26.066 18.996,-25.021 19.001,-25.021 19.214,-26.066
				19.655,-26.066 19.655,-24.586 19.382,-24.586 19.382,-25.768 19.378,-25.768 19.115,-24.586 18.882,-24.586 18.619,-25.768
				18.615,-25.768 18.615,-24.586 18.342,-24.586 18.342,-26.066 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M20.109-25.005c0,0.034,0.003,0.066,0.008,0.095
				c0.005,0.027,0.016,0.052,0.031,0.071c0.015,0.02,0.036,0.035,0.061,0.046c0.025,0.012,0.058,0.016,0.096,0.016
				c0.046,0,0.087-0.014,0.124-0.044c0.036-0.029,0.055-0.076,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.009-0.025-0.024-0.048-0.044-0.066c-0.021-0.02-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.126-0.049
				c-0.065-0.022-0.123-0.047-0.172-0.072c-0.048-0.026-0.088-0.058-0.12-0.094c-0.034-0.034-0.057-0.074-0.072-0.121
				c-0.015-0.045-0.023-0.1-0.023-0.16c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.193-0.108,0.336-0.108
				c0.067,0,0.128,0.008,0.183,0.021c0.057,0.015,0.104,0.039,0.145,0.072c0.042,0.031,0.073,0.072,0.096,0.123
				c0.022,0.051,0.034,0.11,0.034,0.182v0.041h-0.286c0-0.071-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.125-0.057
				c-0.033,0-0.06,0.004-0.083,0.014c-0.022,0.009-0.039,0.021-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.004,0.021-0.007,0.042-0.007,0.065c0,0.045,0.011,0.083,0.03,0.113c0.019,0.032,0.06,0.061,0.124,0.086l0.23,0.101
				c0.057,0.024,0.103,0.051,0.138,0.078c0.037,0.026,0.065,0.056,0.087,0.087c0.021,0.03,0.036,0.064,0.044,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.27-0.135,0.342c-0.09,0.07-0.216,0.105-0.378,0.105
				c-0.169,0-0.29-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-25.005z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M21.715-25.623h-0.292v-0.195h0.027c0.099,0,0.177-0.018,0.234-0.055
				c0.058-0.038,0.095-0.098,0.11-0.182h0.219v1.468h-0.298V-25.623z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M23.282-25.796c-0.061,0.086-0.115,0.178-0.162,0.275
				c-0.048,0.099-0.09,0.199-0.126,0.303c-0.036,0.103-0.065,0.21-0.089,0.315c-0.024,0.108-0.042,0.213-0.054,0.316h-0.313
				c0.011-0.088,0.027-0.182,0.05-0.278c0.022-0.098,0.052-0.197,0.088-0.298c0.036-0.102,0.081-0.202,0.133-0.304
				c0.053-0.104,0.115-0.203,0.187-0.302h-0.618v-0.257h0.904V-25.796z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M23.708-24.799c0.02,0.032,0.055,0.048,0.105,0.048
				c0.04,0,0.072-0.01,0.096-0.029c0.023-0.019,0.041-0.044,0.054-0.078c0.013-0.031,0.022-0.071,0.026-0.117
				c0.005-0.046,0.007-0.096,0.007-0.149v-0.07h-0.005c-0.025,0.037-0.06,0.067-0.107,0.09c-0.046,0.023-0.102,0.034-0.169,0.034
				c-0.064,0-0.119-0.011-0.165-0.034c-0.046-0.022-0.083-0.055-0.111-0.096c-0.028-0.04-0.05-0.088-0.062-0.144
				c-0.014-0.058-0.02-0.118-0.02-0.185c0-0.17,0.038-0.3,0.113-0.389c0.075-0.09,0.196-0.135,0.362-0.135
				c0.102,0,0.183,0.019,0.244,0.057s0.108,0.088,0.14,0.151c0.032,0.063,0.054,0.136,0.063,0.219
				c0.01,0.082,0.015,0.169,0.015,0.259c0,0.105-0.004,0.206-0.009,0.304c-0.007,0.099-0.025,0.184-0.057,0.259
				c-0.03,0.073-0.08,0.133-0.146,0.176c-0.068,0.044-0.162,0.065-0.282,0.065c-0.053,0-0.103-0.005-0.152-0.015
				c-0.049-0.011-0.092-0.031-0.129-0.059c-0.037-0.028-0.068-0.066-0.091-0.113c-0.023-0.049-0.034-0.108-0.036-0.178h0.286
				C23.678-24.874,23.688-24.83,23.708-24.799 M23.693-25.355c0.024,0.043,0.066,0.063,0.124,0.063c0.059,0,0.102-0.021,0.126-0.063
				c0.025-0.044,0.038-0.114,0.038-0.214c0-0.041-0.003-0.08-0.007-0.116c-0.004-0.035-0.012-0.066-0.023-0.092
				c-0.012-0.025-0.028-0.046-0.05-0.061c-0.022-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.064,0.009-0.086,0.022
				c-0.021,0.015-0.038,0.035-0.049,0.062c-0.011,0.026-0.019,0.057-0.021,0.092c-0.004,0.035-0.005,0.074-0.005,0.115
				C23.656-25.469,23.668-25.399,23.693-25.355"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M24.662-25.792l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.085
				c0.044-0.018,0.098-0.025,0.162-0.025c0.07,0,0.129,0.013,0.175,0.041c0.046,0.026,0.083,0.063,0.109,0.106
				c0.027,0.045,0.047,0.094,0.058,0.15c0.01,0.056,0.016,0.112,0.016,0.17c0,0.082-0.007,0.156-0.021,0.223
				c-0.013,0.065-0.037,0.123-0.073,0.172c-0.035,0.047-0.084,0.082-0.144,0.106c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.024-0.101-0.058-0.132-0.099c-0.03-0.04-0.05-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.287c0,0.023,0.001,0.05,0.004,0.079c0.002,0.026,0.009,0.053,0.019,0.076
				c0.011,0.024,0.025,0.045,0.045,0.061s0.045,0.022,0.077,0.022c0.062,0,0.106-0.022,0.133-0.069
				c0.028-0.048,0.041-0.12,0.041-0.215c0-0.062-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
				c-0.013-0.023-0.03-0.04-0.053-0.05c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				s-0.037,0.058-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H24.662z"
        />
        <rect
          x="-4.139"
          y="76.945"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          width="5.987"
          height="5.986"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          d="M-2.266,78.982v1.273c0,0.166-0.011,0.43-0.221,0.572
				c-0.082,0.053-0.195,0.084-0.308,0.084c-0.136,0-0.297-0.045-0.432-0.176l0.223-0.27c0.036,0.055,0.096,0.104,0.184,0.104
				c0.079,0,0.124-0.035,0.144-0.066c0.045-0.064,0.043-0.189,0.043-0.305v-1.217H-2.266z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#FFFFFF"
          points="-0.885,80.869 -1.162,80.869 -1.818,78.982 -1.428,78.982
				-1.006,80.268 -0.546,78.965 -0.342,78.965 0.094,80.268 0.547,78.982 0.937,78.982 0.238,80.869 -0.039,80.869 -0.452,79.674
							"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M12.545,75.764c0.592,0,0.973,0.391,0.973,0.992
				c0,0.594-0.381,0.984-0.973,0.984c-0.588,0-0.969-0.391-0.969-0.984C11.576,76.154,11.957,75.764,12.545,75.764 M12.545,77.354
				c0.352,0,0.458-0.301,0.458-0.598c0-0.303-0.106-0.604-0.458-0.604c-0.348,0-0.453,0.301-0.453,0.604
				C12.092,77.053,12.197,77.354,12.545,77.354"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M14.503,75.814h0.378v0.346h-0.378v0.928c0,0.174,0.044,0.219,0.218,0.219
				c0.054,0,0.105-0.004,0.16-0.016v0.402c-0.087,0.016-0.2,0.02-0.302,0.02c-0.315,0-0.591-0.074-0.591-0.447V76.16h-0.313v-0.346
				h0.313v-0.563h0.515V75.814z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M15.551,76.879c0.014,0.324,0.174,0.475,0.46,0.475
				c0.208,0,0.374-0.127,0.408-0.244h0.453c-0.146,0.443-0.453,0.631-0.878,0.631c-0.592,0-0.958-0.404-0.958-0.984
				c0-0.564,0.388-0.992,0.958-0.992c0.639,0,0.946,0.537,0.911,1.115H15.551z M16.389,76.551c-0.047-0.26-0.16-0.398-0.41-0.398
				c-0.327,0-0.421,0.254-0.428,0.398H16.389z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M17.608,77.08c0.004,0.227,0.192,0.316,0.396,0.316
				c0.149,0,0.337-0.059,0.337-0.24c0-0.156-0.214-0.209-0.584-0.289c-0.298-0.066-0.595-0.17-0.595-0.502
				c0-0.479,0.413-0.602,0.816-0.602c0.41,0,0.788,0.139,0.828,0.598h-0.491c-0.014-0.199-0.166-0.254-0.351-0.254
				c-0.117,0-0.287,0.021-0.287,0.176c0,0.184,0.29,0.209,0.584,0.279c0.301,0.068,0.595,0.178,0.595,0.525
				c0,0.494-0.428,0.652-0.856,0.652c-0.436,0-0.86-0.162-0.882-0.66H17.608z"
        />
        <rect
          x="8.751"
          y="80.293"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M11.855,78.855h0.89c0.798,0,1.244,0.398,1.244,1.234
				c0,0.867-0.381,1.357-1.244,1.357h-0.89V78.855z M12.2,81.156h0.574c0.235,0,0.871-0.064,0.871-1.02
				c0-0.615-0.229-0.99-0.864-0.99H12.2V81.156z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M15.167,79.527c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.256,79.959,14.572,79.527,15.167,79.527 M15.167,81.219
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.256-0.584,0.713
				C14.583,80.965,14.844,81.219,15.167,81.219"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M17.448,79.57h0.291v0.297h0.006c0.131-0.23,0.341-0.34,0.61-0.34
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.271c0-0.227-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.59v1.059
				h-0.308V79.57z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M20.274,79.527c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.363,79.959,19.679,79.527,20.274,79.527 M20.274,81.219
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.256-0.584,0.713
				C19.69,80.965,19.951,81.219,20.274,81.219"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M21.975,79.57h0.374v0.271h-0.374v1.166c0,0.143,0.04,0.166,0.232,0.166
				h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319V79.57h0.319v-0.563h0.309V79.57z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M25.359,81.439c-0.055,0.033-0.123,0.051-0.222,0.051
				c-0.159,0-0.261-0.088-0.261-0.291c-0.17,0.201-0.399,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.525
				c0-0.424,0.316-0.516,0.636-0.578c0.341-0.064,0.631-0.043,0.631-0.275c0-0.268-0.222-0.313-0.417-0.313
				c-0.262,0-0.454,0.082-0.469,0.355h-0.308c0.018-0.465,0.378-0.627,0.795-0.627c0.338,0,0.704,0.076,0.704,0.516v0.965
				c0,0.145,0,0.211,0.098,0.211c0.025,0,0.054-0.004,0.094-0.02V81.439z M24.858,80.479c-0.119,0.086-0.352,0.09-0.559,0.127
				c-0.203,0.035-0.377,0.109-0.377,0.336c0,0.205,0.174,0.277,0.363,0.277c0.406,0,0.573-0.254,0.573-0.424V80.479z"
        />
        <rect
          x="25.663"
          y="78.855"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          width="0.309"
          height="2.592"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M26.878,79.57h0.374v0.271h-0.374v1.166c0,0.143,0.039,0.166,0.232,0.166
				h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195H26.25V79.57h0.319v-0.563h0.309V79.57z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M29.192,80.852c-0.083,0.414-0.38,0.639-0.802,0.639
				c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.882-0.977c0.664,0,0.868,0.619,0.849,1.074h-1.404
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.367H29.192z M28.895,80.33
				c-0.015-0.295-0.236-0.531-0.541-0.531c-0.323,0-0.515,0.244-0.537,0.531H28.895z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M29.529,79.57h0.29v0.396h0.007c0.149-0.301,0.356-0.449,0.69-0.439v0.326
				c-0.497,0-0.679,0.283-0.679,0.758v0.836h-0.308V79.57z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M33.418,81.439c-0.055,0.033-0.123,0.051-0.222,0.051
				c-0.16,0-0.261-0.088-0.261-0.291c-0.17,0.201-0.399,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.525
				c0-0.424,0.316-0.516,0.635-0.578c0.341-0.064,0.632-0.043,0.632-0.275c0-0.268-0.222-0.313-0.418-0.313
				c-0.261,0-0.453,0.082-0.468,0.355h-0.309c0.018-0.465,0.377-0.627,0.795-0.627c0.338,0,0.703,0.076,0.703,0.516v0.965
				c0,0.145,0,0.211,0.099,0.211c0.026,0,0.055-0.004,0.095-0.02V81.439z M32.917,80.479c-0.12,0.086-0.353,0.09-0.56,0.127
				c-0.202,0.035-0.377,0.109-0.377,0.336c0,0.205,0.175,0.277,0.364,0.277c0.406,0,0.573-0.254,0.573-0.424V80.479z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M33.692,79.57h0.291v0.396h0.006c0.149-0.301,0.356-0.449,0.69-0.439v0.326
				c-0.498,0-0.678,0.283-0.678,0.758v0.836h-0.31V79.57z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M35.34,79.57h0.373v0.271H35.34v1.166c0,0.143,0.039,0.166,0.231,0.166h0.142
				v0.273h-0.236c-0.319,0-0.446-0.066-0.446-0.41v-1.195h-0.319V79.57h0.319v-0.563h0.31V79.57z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          points="37.912,81.447 37.585,81.447 37.203,79.955 37.197,79.955
				36.82,81.447 36.486,81.447 35.883,79.57 36.224,79.57 36.648,81.105 36.656,81.105 37.033,79.57 37.371,79.57 37.763,81.105
				37.771,81.105 38.191,79.57 38.51,79.57 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M39.612,79.527c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.432-0.911-0.979C38.701,79.959,39.018,79.527,39.612,79.527 M39.612,81.219
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.713-0.584-0.713s-0.584,0.256-0.584,0.713
				C39.028,80.965,39.289,81.219,39.612,81.219"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M40.874,79.57h0.291v0.396h0.006c0.149-0.301,0.356-0.449,0.69-0.439v0.326
				c-0.498,0-0.679,0.283-0.679,0.758v0.836h-0.309V79.57z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          points="42.111,78.855 42.419,78.855 42.419,80.391 43.276,79.57
				43.688,79.57 42.945,80.252 43.744,81.447 43.353,81.447 42.709,80.467 42.419,80.736 42.419,81.447 42.111,81.447 			"
        />
        <rect
          x="8.751"
          y="84.049"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M13.924,84.268c0,0.652-0.374,0.998-1.02,0.998
				c-0.668,0-1.063-0.309-1.063-0.998v-1.654h0.345v1.654c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.654
				h0.345V84.268z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M14.612,84.611c0.011,0.277,0.25,0.363,0.505,0.363
				c0.192,0,0.453-0.043,0.453-0.279c0-0.24-0.305-0.279-0.613-0.348c-0.305-0.07-0.614-0.172-0.614-0.531
				c0-0.377,0.374-0.533,0.701-0.533c0.414,0,0.744,0.131,0.769,0.588h-0.308c-0.022-0.238-0.233-0.314-0.436-0.314
				c-0.185,0-0.399,0.051-0.399,0.238c0,0.223,0.326,0.258,0.613,0.326c0.309,0.07,0.613,0.172,0.613,0.535
				c0,0.445-0.417,0.59-0.798,0.59c-0.421,0-0.777-0.17-0.795-0.635H14.612z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M17.837,84.609c-0.083,0.412-0.381,0.637-0.802,0.637
				c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.621,0.849,1.074h-1.405
				c-0.011,0.328,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.365H17.837z M17.539,84.086
				c-0.014-0.295-0.236-0.529-0.54-0.529c-0.323,0-0.516,0.242-0.538,0.529H17.539z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M19.621,83.328h0.374V83.6h-0.374v1.164c0,0.143,0.039,0.168,0.232,0.168
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41V83.6h-0.319v-0.271h0.319v-0.564h0.309V83.328z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M20.662,82.99h-0.309v-0.379h0.309V82.99z M20.353,83.328h0.309v1.875h-0.309
				V83.328z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M22.758,85.043c0,0.611-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.141-0.776-0.551h0.308c0.014,0.225,0.283,0.305,0.486,0.305c0.403,0,0.562-0.291,0.562-0.711v-0.123h-0.006
				c-0.102,0.229-0.349,0.34-0.585,0.34c-0.558,0-0.838-0.438-0.838-0.949c0-0.443,0.218-0.992,0.867-0.992
				c0.236,0,0.447,0.105,0.559,0.313h0.003v-0.268h0.291V85.043z M22.453,84.227c0-0.33-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.324-0.556,0.684c0,0.338,0.124,0.715,0.53,0.715C22.301,84.953,22.453,84.58,22.453,84.227"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M23.222,82.611h0.308v0.992h0.008c0.102-0.225,0.366-0.318,0.591-0.318
				c0.494,0,0.646,0.281,0.646,0.686v1.232h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.059
				h-0.308V82.611z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M25.667,83.328h0.374V83.6h-0.374v1.164c0,0.143,0.039,0.168,0.232,0.168
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41V83.6h-0.319v-0.271h0.319v-0.564h0.309V83.328z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M28.198,83.283c0.595,0,0.911,0.434,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.287,83.717,27.603,83.283,28.198,83.283 M28.198,84.975
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
				C27.614,84.721,27.875,84.975,28.198,84.975"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          points="30.316,85.203 29.986,85.203 29.29,83.328 29.634,83.328
				30.16,84.891 30.167,84.891 30.679,83.328 31.002,83.328 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M32.885,84.609c-0.084,0.412-0.381,0.637-0.803,0.637
				c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.882-0.977c0.664,0,0.867,0.621,0.849,1.074h-1.404
				c-0.011,0.328,0.174,0.617,0.584,0.617c0.254,0,0.432-0.123,0.486-0.365H32.885z M32.588,84.086
				c-0.015-0.295-0.236-0.529-0.541-0.529c-0.324,0-0.516,0.242-0.538,0.529H32.588z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M33.221,83.328h0.291v0.395h0.006c0.15-0.301,0.356-0.449,0.691-0.439v0.326
				c-0.498,0-0.68,0.283-0.68,0.76v0.834h-0.309V83.328z"
        />
        <rect
          x="34.458"
          y="82.611"
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          width="0.31"
          height="2.592"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M36.055,83.283c0.595,0,0.91,0.434,0.91,0.984
				c0,0.547-0.315,0.979-0.91,0.979c-0.596,0-0.912-0.432-0.912-0.979C35.143,83.717,35.459,83.283,36.055,83.283 M36.055,84.975
				c0.322,0,0.584-0.254,0.584-0.707c0-0.457-0.262-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
				C35.471,84.721,35.731,84.975,36.055,84.975"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M38.601,83.93c-0.047-0.236-0.21-0.373-0.464-0.373
				c-0.447,0-0.586,0.352-0.586,0.732c0,0.346,0.156,0.686,0.549,0.686c0.297,0,0.477-0.174,0.515-0.457h0.317
				c-0.07,0.457-0.355,0.729-0.828,0.729c-0.573,0-0.878-0.398-0.878-0.957c0-0.563,0.29-1.006,0.885-1.006
				c0.425,0,0.767,0.201,0.81,0.646H38.601z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          points="39.293,82.611 39.601,82.611 39.601,84.146 40.457,83.328
				40.871,83.328 40.127,84.01 40.926,85.203 40.533,85.203 39.891,84.223 39.601,84.492 39.601,85.203 39.293,85.203 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M41.482,82.99h-0.308v-0.379h0.308V82.99z M41.175,83.326h0.308v1.877h-0.308
				V83.326z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M41.962,83.328h0.291v0.297h0.006c0.131-0.232,0.341-0.342,0.609-0.342
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.27c0-0.229-0.146-0.377-0.382-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.059
				h-0.308V83.328z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_2_)"
          fill="#EC008C"
          d="M45.597,85.043c0,0.611-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.141-0.775-0.551h0.308c0.015,0.225,0.283,0.305,0.485,0.305c0.404,0,0.563-0.291,0.563-0.711v-0.123H45.3
				c-0.102,0.229-0.35,0.34-0.585,0.34c-0.558,0-0.838-0.438-0.838-0.949c0-0.443,0.218-0.992,0.866-0.992
				c0.236,0,0.447,0.105,0.56,0.313h0.003v-0.268h0.291V85.043z M45.292,84.227c0-0.33-0.145-0.672-0.533-0.672
				c-0.396,0-0.557,0.324-0.557,0.684c0,0.338,0.125,0.715,0.531,0.715S45.292,84.58,45.292,84.227"
        />
      </g>
      <g display="inline">
        <defs>
          <path
            id="CRAFTIVISM_SVGID_3_"
            d="M36.797,18.598l-12.294,0.111c-0.257,0.002-0.502-0.027-0.725-0.055
					c-0.685-0.088-1.166-0.176-1.159,0.625l0.158,18.973c0.01,1.061,3.494,1.51,4.432,1.615c0,0,3.614,0.178,3.789,0.164l3.1-0.225
					c2.611-0.346,4.784-0.637,4.774-1.699l-0.158-18.973c-0.004-0.535-0.228-0.67-0.581-0.67
					C37.787,18.465,37.314,18.592,36.797,18.598"
          />
        </defs>
        <clipPath id="CRAFTIVISM_SVGID_4_">
          <use xlinkHref="#CRAFTIVISM_SVGID_3_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.547,18.42
				c0.012,1.219-4.412,2.246-9.878,2.295c-5.467,0.049-9.908-0.9-9.919-2.117"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.561,19.893
				c0.012,1.227-4.412,2.258-9.879,2.309c-5.467,0.049-9.908-0.906-9.918-2.131"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.574,21.367
				c0.011,1.23-4.412,2.27-9.879,2.318c-5.467,0.049-9.908-0.91-9.919-2.143"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.587,22.84
				c0.011,1.24-4.412,2.283-9.878,2.332c-5.468,0.049-9.909-0.916-9.92-2.156"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.6,24.313
				c0.012,1.246-4.412,2.297-9.877,2.346c-5.467,0.049-9.909-0.922-9.92-2.17"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.613,25.785
				c0.011,1.254-4.411,2.311-9.878,2.359c-5.467,0.049-9.909-0.928-9.919-2.182"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.627,27.258
				c0.011,1.262-4.412,2.324-9.878,2.373c-5.467,0.049-9.909-0.934-9.92-2.195"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.64,28.73
				c0.011,1.27-4.411,2.338-9.878,2.387c-5.467,0.049-9.908-0.939-9.919-2.209"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.652,30.203
				c0.012,1.277-4.41,2.352-9.878,2.4c-5.467,0.049-9.908-0.947-9.919-2.223"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.666,31.678
				c0.011,1.281-4.411,2.363-9.877,2.412c-5.468,0.049-9.909-0.951-9.921-2.236"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.68,33.15
				c0.01,1.291-4.412,2.377-9.877,2.426c-5.468,0.049-9.909-0.959-9.921-2.25"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.693,34.623
				c0.01,1.297-4.412,2.391-9.878,2.439c-5.467,0.049-9.909-0.963-9.92-2.262"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_4_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.485"
          strokeMiterlimit="10"
          d="M40.706,36.096
				c0.011,1.307-4.411,2.402-9.878,2.451c-5.467,0.051-9.908-0.969-9.919-2.273"
        />
      </g>
      <g display="inline">
        <defs>
          <rect
            id="CRAFTIVISM_SVGID_5_"
            x="-0.051"
            width="61.792"
            height="61.688"
          />
        </defs>
        <clipPath id="CRAFTIVISM_SVGID_6_">
          <use xlinkHref="#CRAFTIVISM_SVGID_5_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#F78E1E"
          d="M54.954,61.07H5.628c-3.06,0-5.564-2.504-5.564-5.563V6.18
				c0-3.061,2.504-5.563,5.564-5.563h49.326c3.061,0,5.563,2.502,5.563,5.563v49.328C60.518,58.566,58.015,61.07,54.954,61.07"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#A1233D"
          d="M19.552,9.764c-0.184,0.998-0.987,1.582-2.01,1.582
				c-1.183,0-2.116-0.91-2.116-2.082c0-1.166,0.933-2.08,2.116-2.08c0.981,0,1.814,0.582,2.004,1.527l-0.963,0.227
				c-0.125-0.578-0.53-0.857-1.041-0.857c-0.631,0-1.136,0.506-1.136,1.184s0.505,1.189,1.136,1.189c0.505,0,0.91-0.285,1.047-0.881
				L19.552,9.764z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#A1233D"
          d="M22.278,8.252l-0.042,0.951h-0.173c-0.665,0-1.028,0.346-1.028,1.119v0.934
				h-0.951V8.27h0.951v0.572c0.214-0.357,0.541-0.613,1.028-0.613C22.146,8.229,22.207,8.234,22.278,8.252"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#A1233D"
          d="M25.789,11.256h-0.952v-0.238c-0.231,0.209-0.541,0.328-0.927,0.328
				c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.386,0,0.696,0.119,0.927,0.328V8.271h0.952V11.256z
				 M24.836,9.764c0-0.459-0.345-0.773-0.731-0.773c-0.416,0-0.719,0.314-0.719,0.773c0,0.463,0.303,0.771,0.719,0.771
				C24.491,10.535,24.836,10.227,24.836,9.764"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#A1233D"
          d="M28.396,8.271v0.754h-0.821v2.23h-0.946v-2.23h-0.428V8.271h0.428V8.039
				c0-0.703,0.483-1.146,1.202-1.146c0.202,0,0.398,0.041,0.588,0.123l-0.202,0.736c-0.089-0.027-0.184-0.047-0.256-0.047
				c-0.225,0-0.386,0.137-0.386,0.375v0.191H28.396z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#A1233D"
          d="M31.036,11.125c-0.232,0.123-0.476,0.221-0.815,0.221
				c-0.744,0-1.19-0.404-1.19-1.189V9.025H28.49V8.271h0.541V7.391h0.952v0.881h0.88v0.754h-0.88v1.035
				c0,0.309,0.149,0.434,0.399,0.434c0.131,0,0.309-0.047,0.434-0.117L31.036,11.125z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#A1233D"
          d="M32.438,7.391c0,0.303-0.231,0.529-0.553,0.529
				c-0.322,0-0.548-0.227-0.548-0.529c0-0.291,0.226-0.541,0.548-0.541C32.206,6.85,32.438,7.1,32.438,7.391 M31.409,8.27h0.951
				v2.986h-0.951V8.27z"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#A1233D"
          points="34.897,11.256 33.929,11.256 32.691,8.264 33.695,8.264
				34.414,10.107 35.129,8.264 36.135,8.264 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#A1233D"
          d="M37.502,7.391c0,0.303-0.232,0.529-0.555,0.529
				c-0.319,0-0.545-0.227-0.545-0.529c0-0.291,0.226-0.541,0.545-0.541C37.27,6.85,37.502,7.1,37.502,7.391 M36.473,8.27h0.951
				v2.986h-0.951V8.27z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#A1233D"
          d="M38.812,10.287c0.042,0.254,0.272,0.35,0.528,0.35
				c0.25,0,0.388-0.105,0.388-0.236c0-0.104-0.078-0.18-0.298-0.221l-0.606-0.119c-0.553-0.102-0.868-0.398-0.868-0.867
				c0-0.613,0.529-1.012,1.284-1.012c0.743,0,1.237,0.34,1.356,0.855l-0.868,0.172c-0.03-0.184-0.208-0.35-0.5-0.35
				c-0.255,0-0.339,0.125-0.339,0.23c0,0.084,0.035,0.168,0.226,0.209l0.701,0.143c0.565,0.119,0.828,0.459,0.828,0.898
				c0,0.66-0.571,1.004-1.356,1.004c-0.701,0-1.302-0.254-1.402-0.867L38.812,10.287z"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#A1233D"
          d="M46.124,9.389v1.867h-0.945V9.59c0-0.338-0.22-0.553-0.5-0.553
				c-0.35,0-0.582,0.256-0.582,0.756v1.463h-0.951V9.59c0-0.338-0.221-0.553-0.506-0.553c-0.345,0-0.571,0.256-0.571,0.756v1.463
				h-0.95V8.27h0.95v0.273c0.203-0.225,0.495-0.361,0.881-0.361c0.416,0,0.772,0.18,0.975,0.504c0.238-0.309,0.595-0.504,1.06-0.504
				C45.618,8.182,46.124,8.664,46.124,9.389"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#D76427"
          points="21.19,42.123 38.134,59.168 59.223,59.168 60.881,37.15
				40.305,16.574 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#FBB683"
          d="M55.958,4.406c0.689,0,1.271,0.582,1.271,1.27V56.01
				c0,0.689-0.581,1.271-1.271,1.271H5.625c-0.689,0-1.271-0.582-1.271-1.271V5.676c0-0.688,0.582-1.27,1.271-1.27H55.958 M55.958,0
				H5.625C2.502,0-0.051,2.555-0.051,5.676V56.01c0,3.121,2.554,5.678,5.677,5.678h50.333c3.123,0,5.678-2.557,5.678-5.678V5.676
				C61.636,2.555,59.081,0,55.958,0"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBB683"
          d="M40.754,41.432
				c0.011,1.32-4.41,2.43-9.877,2.479s-9.909-0.982-9.921-2.301c-0.011-1.32,4.411-2.43,9.878-2.479
				c5.125-0.047,9.349,0.855,9.867,2.057C40.734,41.268,40.752,41.35,40.754,41.432"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBB683"
          points="40.733,39.008
				40.754,41.432 20.956,41.609 20.935,39.219 			"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#ED1C24"
          d="M40.732,39.041c0.011,1.32-4.411,2.43-9.877,2.479
				c-5.467,0.049-9.909-0.98-9.921-2.301c-0.011-1.318,4.411-2.43,9.878-2.479C36.279,36.691,40.721,37.721,40.732,39.041"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#ED1C24"
          d="M40.732,39.037c0.011,1.32-4.411,2.43-9.877,2.479
				c-5.467,0.049-9.909-0.98-9.921-2.301c-0.011-1.318,4.411-2.43,9.878-2.479C36.279,36.688,40.721,37.717,40.732,39.037"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#5087C7"
          d="M34.099,39.805l-3.101,0.227c-0.175,0.014-3.789-0.164-3.789-0.164
				c-0.938-0.105-4.422-0.553-4.432-1.615L22.62,19.279c-0.01-1.063,0.838-0.563,1.883-0.572l12.294-0.109
				c1.046-0.01,1.908-0.523,1.918,0.537l0.158,18.973C38.881,39.168,36.709,39.461,34.099,39.805"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="none"
          stroke="#5087C7"
          strokeWidth="0.454"
          strokeMiterlimit="10"
          d="M8.215,38.059
				c0.699,1.414,2.841,1.248,3.972,0.516c1.362-0.883,1.948-2.863,2.329-4.326c0.573-2.201,0.303-7.906,4.981-5.82
				c5.121,2.285,3.666,10.057,3.666,10.057"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#FFFFFF"
          d="M44.737,18.59c-0.455-0.457-1.893,0.268-3.089,1.557l-7.879,8.49l-5.67,6.109
				l-2.836,3.059c-0.156,0.168,0.016,0.342,0.184,0.186l3.487-3.207l6.92-6.365l7.301-6.715
				C44.458,20.504,45.198,19.049,44.737,18.59 M41.271,22.057c-0.182-0.184,0.234-0.895,0.93-1.59
				c0.696-0.695,1.407-1.113,1.589-0.93c0.183,0.182-0.234,0.893-0.93,1.588C42.164,21.822,41.453,22.238,41.271,22.057"
        />
        <path
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#5087C7"
          d="M38.467,26.859c0.026,0.063,0.041,0.127,0.041,0.191
				c0.01,1.039-3.42,1.203-7.723,1.242c-4.301,0.039-7.845-0.064-7.854-1.102v5.906c0.009,1.037,3.547,1.152,7.849,1.113
				s7.737-2.965,7.728-4.004c0-0.066-0.015-0.129-0.041-0.193"
        />
        <polygon
          clipPath="url(#CRAFTIVISM_SVGID_6_)"
          fill="#FFFFFF"
          points="18.988,19.965 27.513,28.629 32.994,34.289 36.668,38.084
				36.559,38.193 32.574,34.277 27.121,28.775 18.562,20.244 			"
        />
      </g>
    </g>
  );
};

const ConsciousConsumer = () => {
  return (
    <g id="Conscious_Consumer">
      <g display="inline">
        <defs>
          <rect
            id="CONSCIOUSCONSUMER_SVGID_7_"
            x="-0.052"
            y="0"
            width="72.887"
            height="74.907"
          />
        </defs>
        <clipPath id="CONSCIOUSCONSUMER_SVGID_8_">
          <use xlinkHref="#CONSCIOUSCONSUMER_SVGID_7_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#CONSCIOUSCONSUMER_SVGID_8_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F78E1E"
          d="M55.066,61.071H5.74
				c-3.061,0-5.564-2.504-5.564-5.563V6.182c0-3.061,2.504-5.564,5.564-5.564h49.326c3.06,0,5.563,2.504,5.563,5.564v49.327
				C60.63,58.567,58.126,61.071,55.066,61.071"
        />
        <path
          clipPath="url(#CONSCIOUSCONSUMER_SVGID_8_)"
          fill="none"
          stroke="#F5DB8B"
          strokeWidth="2.738"
          strokeMiterlimit="10"
          d="M22.758,34.413
				V31.25c0-4.438,1.688-8.811,8.033-8.811c6.303,0,8.035,4.373,8.035,8.811v3.164"
        />
        <path
          clipPath="url(#CONSCIOUSCONSUMER_SVGID_8_)"
          fill="#78401A"
          d="M36.738,21.32c-0.006-0.041-0.009-0.082-0.016-0.123
				c-0.032-0.227-0.08-0.449-0.137-0.668l0,0c-0.017-0.059-0.033-0.117-0.051-0.176l0,0c-0.023-0.08-0.055-0.154-0.081-0.232
				c-0.043-0.125-0.082-0.254-0.134-0.375c-0.025-0.063-0.055-0.123-0.083-0.186c-0.001,0-0.001,0-0.001,0
				c-0.079-0.172-0.164-0.342-0.26-0.506l-0.002-0.002c-0.037-0.066-0.077-0.131-0.117-0.195c-0.098-0.156-0.203-0.305-0.314-0.451
				c-0.039-0.053-0.078-0.105-0.119-0.156c-0.103-0.127-0.209-0.248-0.322-0.365c-0.036-0.037-0.077-0.072-0.114-0.109
				c-0.043-0.043-0.087-0.086-0.132-0.129c-0.032-0.029-0.069-0.053-0.102-0.084c-0.137-0.121-0.273-0.24-0.421-0.348l-0.001-0.002
				c-0.035-0.025-0.07-0.051-0.107-0.074c-0.176-0.125-0.356-0.242-0.547-0.35v-10.3h-6.172v10.497
				c-1.718,1.096-2.787,2.996-2.787,5.084v1.426v0.219v2.877c0,0.826,0.674,1.502,1.502,1.502c0.441,0,0.836-0.195,1.11-0.5
				c0.104,0.729,0.726,1.291,1.479,1.291c0.472,0,0.888-0.223,1.164-0.563c0.189,0.611,0.755,1.061,1.428,1.061
				s1.238-0.449,1.428-1.061c0.275,0.34,0.691,0.563,1.162,0.563c0.828,0,1.502-0.674,1.502-1.502v-1.461
				c0.773-0.479,1.295-1.328,1.295-2.303v-1.551C36.789,21.816,36.77,21.566,36.738,21.32"
        />
        <path
          clipPath="url(#CONSCIOUSCONSUMER_SVGID_8_)"
          fill="#F78E1E"
          d="M36.869,21.394c-0.005-0.041-0.009-0.084-0.015-0.125
				c-0.034-0.232-0.083-0.459-0.142-0.684c-0.017-0.061-0.032-0.119-0.049-0.18h-0.002c-0.023-0.08-0.057-0.156-0.084-0.236
				c-0.043-0.129-0.082-0.26-0.136-0.385c-0.026-0.064-0.058-0.125-0.085-0.188c0-0.002-0.001-0.002-0.001-0.002
				c-0.081-0.176-0.168-0.35-0.265-0.516c0-0.002,0-0.002-0.002-0.004c-0.039-0.068-0.08-0.133-0.121-0.199
				c-0.1-0.158-0.207-0.311-0.32-0.459c-0.041-0.055-0.08-0.109-0.123-0.162c-0.104-0.127-0.214-0.252-0.329-0.371
				c-0.037-0.041-0.077-0.074-0.116-0.113c-0.043-0.043-0.089-0.088-0.135-0.129c-0.033-0.031-0.07-0.059-0.104-0.088
				c-0.139-0.123-0.279-0.244-0.43-0.357c0,0,0,0-0.002,0c-0.035-0.027-0.072-0.051-0.109-0.076
				c-0.178-0.127-0.364-0.248-0.56-0.355V6.239h-6.306v10.726c-1.756,1.121-2.848,3.063-2.848,5.195v1.459v0.223v2.939
				c0,0.846,0.689,1.533,1.535,1.533c0.45,0,0.853-0.199,1.133-0.51c0.107,0.744,0.742,1.318,1.514,1.318
				c0.482,0,0.906-0.227,1.188-0.574c0.195,0.625,0.771,1.082,1.459,1.082c0.689,0,1.266-0.457,1.461-1.082
				c0.28,0.348,0.705,0.574,1.187,0.574c0.846,0,1.535-0.688,1.535-1.533v-1.494c0.791-0.488,1.323-1.355,1.323-2.354V22.16
				C36.922,21.902,36.9,21.646,36.869,21.394 M27.857,6.661h5.462v9.89c-0.022-0.01-0.046-0.021-0.067-0.029
				c-0.08-0.037-0.16-0.066-0.242-0.098c-0.063-0.025-0.125-0.053-0.188-0.076l-0.001,0.002c-0.106-0.039-0.229,0.018-0.269,0.129
				c-0.039,0.109,0.018,0.229,0.128,0.268v0.002c0.044,0.016,0.087,0.035,0.131,0.051c0.055,0.021,0.11,0.043,0.164,0.066
				c0.038,0.016,0.077,0.029,0.114,0.047c0.031,0.014,0.063,0.029,0.094,0.045c0.096,0.043,0.193,0.09,0.285,0.141
				c0.162,0.088,0.314,0.188,0.466,0.289c0.038,0.025,0.079,0.047,0.116,0.072c0.039,0.027,0.076,0.055,0.115,0.082
				c0.17,0.127,0.334,0.262,0.489,0.408c0.044,0.041,0.089,0.084,0.132,0.125c0.145,0.143,0.282,0.291,0.41,0.449
				c0.041,0.049,0.079,0.102,0.118,0.154c0.088,0.113,0.168,0.232,0.247,0.354c0.016,0.021,0.031,0.045,0.047,0.066
				c0.04,0.064,0.08,0.129,0.117,0.193c0.09,0.156,0.172,0.316,0.246,0.48c0.027,0.059,0.056,0.117,0.08,0.178
				c0.08,0.188,0.146,0.381,0.205,0.578c0.017,0.055,0.033,0.109,0.047,0.166c0.056,0.209,0.101,0.42,0.132,0.637
				c0.006,0.039,0.01,0.078,0.014,0.117c0.017,0.125,0.019,0.254,0.026,0.381c0.006,0.111,0.023,0.221,0.023,0.332v1.582
				c0,0.748-0.357,1.406-0.901,1.838c-0.069,0.055-0.138,0.107-0.212,0.154c-0.067,0.041-0.139,0.078-0.211,0.113
				c-0.312,0.154-0.657,0.246-1.027,0.246h-1.197h-0.212h-0.211h-0.924c-0.218,0-0.396-0.178-0.396-0.395v-0.438
				c0-0.721,0.586-1.309,1.307-1.309h0.013h0.211h0.212h1.35v-1.42V22.32v-0.16c0-0.02-0.006-0.035-0.012-0.053
				c-0.019-0.072-0.074-0.127-0.146-0.148v-0.012c-0.036,0-0.07-0.008-0.107-0.01c-1.379-0.059-2.486-1.189-2.486-2.584h-0.422
				c0,1.395,0.957,2.561,2.244,2.904c-0.265,0.135-0.482,0.342-0.631,0.598c-0.265-0.457-0.757-0.771-1.324-0.771
				c-0.565,0-1.058,0.314-1.322,0.771c-0.266-0.457-0.758-0.771-1.324-0.771s-1.059,0.314-1.324,0.771
				c-0.265-0.457-0.756-0.771-1.322-0.771c-0.166,0-0.324,0.033-0.474,0.084c-0.246,0.08-0.465,0.217-0.64,0.4V22.16
				c0-2.049,1.084-3.912,2.812-4.938c0.013-0.008,0.024-0.014,0.036-0.021c0.139-0.078,0.279-0.154,0.424-0.223
				c0.079-0.037,0.157-0.076,0.238-0.111c0.107-0.045,0.156-0.168,0.113-0.277c-0.047-0.107-0.17-0.154-0.278-0.111
				c-0.171,0.072-0.335,0.152-0.497,0.238V6.661z M35.176,27.589c0,0.613-0.498,1.111-1.112,1.111c-0.613,0-1.112-0.498-1.112-1.111
				v-1.072h1.197c0.363,0,0.709-0.074,1.027-0.203V27.589z M32.528,27.589v0.508c0,0.613-0.498,1.111-1.112,1.111
				c-0.613,0-1.111-0.498-1.111-1.111v-0.504c0-0.002,0-0.002,0-0.004v-3.971v-0.002c0.002-0.613,0.5-1.111,1.111-1.111
				c0.584,0,1.06,0.453,1.104,1.025h-0.005c-0.953,0-1.729,0.777-1.729,1.73v0.438c0,0.451,0.366,0.818,0.817,0.818h0.924V27.589z
				 M29.883,23.619v3.971c0,0.002,0,0.002,0,0.004c-0.004,0.611-0.502,1.107-1.113,1.107c-0.613,0-1.112-0.498-1.112-1.111v-0.809
				v-3.162c0-0.613,0.499-1.113,1.112-1.113s1.111,0.498,1.113,1.111V23.619 M25.01,26.781v-2.939v-0.223
				c0-0.09,0.015-0.178,0.035-0.262c0.041-0.166,0.119-0.316,0.225-0.443c0.205-0.246,0.509-0.408,0.854-0.408
				c0.613,0,1.112,0.5,1.112,1.113v3.162c0,0.613-0.499,1.111-1.112,1.111S25.01,27.394,25.01,26.781 M32.961,23.531
				c0.039-0.508,0.42-0.92,0.918-1.006v1.006H32.961z"
        />
        <rect
          x="25.599"
          y="4.323"
          clipPath="url(#CONSCIOUSCONSUMER_SVGID_8_)"
          fill="#AFCDE1"
          width="10.407"
          height="13.229"
        />
      </g>
      <g display="inline">
        <defs>
          <path
            id="CONSCIOUSCONSUMER_SVGID_9_"
            d="M7.349,0C4.228,0,1.672,2.555,1.672,5.678v50.333c0,3.121,2.556,5.676,5.677,5.676H55.57
					c3.122,0,5.675-2.555,5.675-5.676V5.678C61.245,2.555,58.692,0,55.57,0H7.349z"
          />
        </defs>
        <clipPath id="CONSCIOUSCONSUMER_SVGID_10_">
          <use xlinkHref="#CONSCIOUSCONSUMER_SVGID_9_" overflow="visible" />
        </clipPath>
        <polygon
          clipPath="url(#CONSCIOUSCONSUMER_SVGID_10_)"
          fill="#D76427"
          points="15.392,58.628 44.575,77.358 72.835,43.979 46.291,29.835 			"
        />
      </g>
      <rect
        x="15.219"
        y="29.81"
        display="inline"
        fill="#F5DB8B"
        width="31.072"
        height="28.978"
      />
      <path display="inline" fill="#F5DB8B" d="M47.172,46.358" />
      <path
        display="inline"
        fill="#A1233D"
        d="M19.773,9.763c-0.184,1-0.988,1.582-2.01,1.582c-1.184,0-2.119-0.908-2.119-2.08
			c0-1.166,0.936-2.08,2.119-2.08c0.98,0,1.813,0.582,2.004,1.527l-0.965,0.227c-0.123-0.578-0.528-0.857-1.039-0.857
			c-0.631,0-1.137,0.506-1.137,1.184s0.506,1.189,1.137,1.189c0.504,0,0.908-0.285,1.045-0.881L19.773,9.763z"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M23.393,9.763c0,0.947-0.75,1.576-1.636,1.576c-0.903,0-1.651-0.629-1.651-1.576
			c0-0.938,0.748-1.568,1.651-1.568C22.643,8.195,23.393,8.826,23.393,9.763 M22.477,9.763c0-0.463-0.351-0.771-0.72-0.771
			c-0.386,0-0.737,0.309-0.737,0.771c0,0.471,0.352,0.775,0.737,0.775C22.126,10.539,22.477,10.234,22.477,9.763"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M26.873,9.425v1.832h-0.946V9.628c0-0.352-0.208-0.59-0.511-0.59
			c-0.375,0-0.619,0.256-0.619,0.834v1.385h-0.951V8.273h0.951v0.283c0.227-0.236,0.541-0.373,0.916-0.373
			C26.408,8.183,26.873,8.675,26.873,9.425"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M28.219,10.289c0.042,0.254,0.273,0.35,0.529,0.35c0.25,0,0.387-0.105,0.387-0.238
			c0-0.1-0.078-0.178-0.298-0.219l-0.606-0.119c-0.553-0.102-0.867-0.398-0.867-0.869c0-0.611,0.528-1.01,1.283-1.01
			c0.744,0,1.237,0.338,1.355,0.855L29.135,9.21c-0.029-0.184-0.208-0.35-0.499-0.35c-0.256,0-0.34,0.123-0.34,0.23
			c0,0.084,0.036,0.168,0.228,0.209l0.7,0.143c0.564,0.119,0.826,0.457,0.826,0.898c0,0.66-0.569,1.006-1.354,1.006
			c-0.703,0-1.304-0.256-1.403-0.869L28.219,10.289z"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M32.6,9.992l0.875,0.189c-0.149,0.707-0.779,1.164-1.522,1.164
			c-0.892,0-1.63-0.635-1.63-1.58c0-0.939,0.738-1.582,1.63-1.582c0.726,0,1.354,0.445,1.522,1.146l-0.893,0.215
			c-0.083-0.363-0.326-0.529-0.63-0.529c-0.428,0-0.714,0.314-0.714,0.75c0,0.439,0.286,0.748,0.714,0.748
			C32.256,10.513,32.499,10.353,32.6,9.992"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M34.97,7.392c0,0.303-0.233,0.529-0.554,0.529c-0.321,0-0.547-0.227-0.547-0.529
			c0-0.291,0.226-0.541,0.547-0.541C34.736,6.851,34.97,7.101,34.97,7.392 M33.939,8.273h0.951v2.984h-0.951V8.273z"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M38.643,9.763c0,0.947-0.75,1.576-1.637,1.576c-0.903,0-1.652-0.629-1.652-1.576
			c0-0.938,0.749-1.568,1.652-1.568C37.893,8.195,38.643,8.826,38.643,9.763 M37.727,9.763c0-0.463-0.352-0.771-0.721-0.771
			c-0.387,0-0.737,0.309-0.737,0.771c0,0.471,0.351,0.775,0.737,0.775C37.375,10.539,37.727,10.234,37.727,9.763"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M42.08,11.257h-0.951v-0.285c-0.227,0.236-0.541,0.375-0.916,0.375
			c-0.696,0-1.159-0.494-1.159-1.244V8.271h0.944V9.9c0,0.354,0.209,0.592,0.513,0.592c0.374,0,0.618-0.258,0.618-0.834V8.271h0.951
			V11.257z"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M43.453,10.289c0.043,0.254,0.274,0.35,0.529,0.35c0.25,0,0.387-0.105,0.387-0.238
			c0-0.1-0.077-0.178-0.297-0.219l-0.606-0.119c-0.554-0.102-0.869-0.398-0.869-0.869c0-0.611,0.53-1.01,1.285-1.01
			c0.744,0,1.237,0.338,1.354,0.855L44.369,9.21c-0.029-0.184-0.207-0.35-0.498-0.35c-0.256,0-0.34,0.123-0.34,0.23
			c0,0.084,0.035,0.168,0.227,0.209l0.701,0.143c0.564,0.119,0.826,0.457,0.826,0.898c0,0.66-0.57,1.006-1.355,1.006
			c-0.702,0-1.303-0.256-1.404-0.869L43.453,10.289z"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M18.44,15.656l0.875,0.189c-0.149,0.707-0.78,1.164-1.522,1.164
			c-0.893,0-1.629-0.635-1.629-1.58c0-0.939,0.736-1.582,1.629-1.582c0.725,0,1.355,0.445,1.522,1.148l-0.894,0.213
			c-0.082-0.363-0.326-0.529-0.629-0.529c-0.429,0-0.713,0.316-0.713,0.75c0,0.439,0.284,0.748,0.713,0.748
			C18.096,16.177,18.34,16.017,18.44,15.656"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M22.855,15.429c0,0.945-0.75,1.576-1.635,1.576c-0.904,0-1.654-0.631-1.654-1.576
			c0-0.939,0.75-1.57,1.654-1.57C22.105,13.859,22.855,14.49,22.855,15.429 M21.939,15.429c0-0.465-0.352-0.773-0.719-0.773
			c-0.387,0-0.738,0.309-0.738,0.773c0,0.469,0.352,0.773,0.738,0.773C21.588,16.203,21.939,15.898,21.939,15.429"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M26.335,15.089v1.832h-0.944v-1.629c0-0.352-0.209-0.588-0.513-0.588
			c-0.374,0-0.618,0.254-0.618,0.83v1.387h-0.951v-2.986h0.951v0.287c0.227-0.238,0.541-0.375,0.916-0.375
			C25.871,13.847,26.335,14.341,26.335,15.089"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M27.682,15.953c0.041,0.254,0.273,0.35,0.529,0.35c0.25,0,0.387-0.105,0.387-0.238
			c0-0.1-0.077-0.178-0.299-0.219l-0.606-0.119c-0.552-0.102-0.866-0.398-0.866-0.869c0-0.611,0.528-1.01,1.283-1.01
			c0.744,0,1.237,0.338,1.355,0.857l-0.867,0.172c-0.029-0.186-0.209-0.352-0.5-0.352c-0.256,0-0.34,0.125-0.34,0.232
			c0,0.082,0.037,0.166,0.227,0.207l0.702,0.143c0.565,0.119,0.827,0.457,0.827,0.898c0,0.66-0.571,1.006-1.356,1.006
			c-0.702,0-1.302-0.256-1.403-0.869L27.682,15.953z"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M32.965,16.921h-0.951v-0.285c-0.227,0.236-0.541,0.375-0.916,0.375
			c-0.695,0-1.159-0.494-1.159-1.244v-1.832h0.945v1.631c0,0.352,0.208,0.59,0.512,0.59c0.374,0,0.618-0.258,0.618-0.834v-1.387
			h0.951V16.921z"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M38.606,15.054v1.867h-0.944v-1.666c0-0.338-0.221-0.553-0.5-0.553
			c-0.352,0-0.584,0.256-0.584,0.756v1.463h-0.951v-1.666c0-0.338-0.22-0.553-0.506-0.553c-0.345,0-0.57,0.256-0.57,0.756v1.463
			H33.6v-2.984h0.951v0.271c0.203-0.225,0.494-0.361,0.879-0.361c0.418,0,0.773,0.178,0.976,0.506
			c0.238-0.311,0.595-0.506,1.06-0.506C38.101,13.847,38.606,14.328,38.606,15.054"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M42.156,15.673h-2.188c0.077,0.355,0.321,0.576,0.642,0.576
			c0.221,0,0.477-0.055,0.637-0.346l0.845,0.172c-0.256,0.631-0.797,0.936-1.481,0.936c-0.861,0-1.574-0.637-1.574-1.582
			c0-0.939,0.713-1.582,1.582-1.582c0.844,0,1.521,0.607,1.539,1.582V15.673z M39.986,15.089h1.23
			c-0.089-0.32-0.327-0.469-0.6-0.469C40.348,14.621,40.074,14.787,39.986,15.089"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M44.811,13.917l-0.041,0.953h-0.174c-0.666,0-1.027,0.344-1.027,1.117v0.934h-0.953
			v-2.984h0.953v0.57c0.213-0.357,0.539-0.613,1.027-0.613C44.679,13.894,44.738,13.9,44.811,13.917"
      />
      <path
        display="inline"
        fill="#A1233D"
        d="M30.703,33.665c-0.536-0.514-1.25-0.844-2.037-0.891
			c-2.26-0.135-3.854,2.117-3.281,4.307c0.402,1.539,1.775,3.4,5.438,5.748c0.002,0.002,0.006,0.002,0.008,0
			c4.475-3.004,5.431-5.182,5.412-6.916H36.24c0-0.006,0.002-0.014,0.002-0.02c-0.02-1.783-1.479-3.213-3.262-3.193
			c-0.889,0.008-1.69,0.377-2.269,0.965C30.71,33.669,30.706,33.669,30.703,33.665"
      />
      <path
        display="inline"
        fill="#FBB683"
        d="M55.958,4.407c0.689,0,1.271,0.582,1.271,1.271v50.333c0,0.689-0.581,1.271-1.271,1.271
			H5.625c-0.688,0-1.271-0.582-1.271-1.271V5.678c0-0.689,0.582-1.271,1.271-1.271H55.958 M55.958,0H5.625
			C2.504,0-0.052,2.555-0.052,5.678v50.333c0,3.121,2.556,5.676,5.677,5.676h50.333c3.123,0,5.678-2.555,5.678-5.676V5.678
			C61.636,2.555,59.081,0,55.958,0"
      />
      <path
        display="inline"
        fill="#5087C7"
        d="M30.803,55.36c-2.639,0-4.773-2.135-4.773-4.771s2.135-4.773,4.773-4.773
			c2.638,0,4.773,2.137,4.773,4.773S33.44,55.36,30.803,55.36"
      />
      <path
        display="inline"
        fill="#FFFFFF"
        d="M30.803,45.815c2.638,0,4.773,2.137,4.773,4.773s-2.136,4.771-4.773,4.771
			c-2.639,0-4.773-2.135-4.773-4.771S28.164,45.815,30.803,45.815 M30.803,45.403c-2.859,0-5.186,2.326-5.186,5.186
			c0,2.857,2.326,5.184,5.186,5.184s5.186-2.326,5.186-5.184C35.988,47.729,33.662,45.403,30.803,45.403"
      />
      <path
        display="inline"
        fill="#FFFFFF"
        d="M33.297,48.644c-0.239,0-0.465,0.107-0.621,0.25l-0.012-0.012
			c0.071-0.119,0.168-0.346,0.168-0.715c0-0.883-0.885-1.551-2.029-1.551s-2.041,0.668-2.041,1.551c0,0.369,0.119,0.596,0.18,0.715
			l-0.013,0.012c-0.155-0.143-0.382-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.018c0,1.287,0.717,2.016,1.492,2.016
			c0.716,0,1.218-0.395,1.67-0.848c-0.071-0.107-0.179-0.215-0.298-0.215s-0.252,0.084-0.478,0.264
			c-0.298,0.227-0.561,0.357-0.907,0.357c-0.608,0-0.871-0.682-0.871-1.574c0-0.955,0.252-1.588,0.896-1.588
			c0.286,0,0.477,0.084,0.667,0.264c0.286,0.262,0.382,0.332,0.513,0.332c0.096,0,0.156-0.059,0.18-0.094
			c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.67,0.561-1.135,1.611-1.135c1.05,0,1.611,0.465,1.611,1.135
			c0,0.584-0.311,1.037-0.525,1.252c0.023,0.035,0.084,0.094,0.18,0.094c0.131,0,0.227-0.07,0.512-0.332
			c0.18-0.18,0.383-0.264,0.668-0.264c0.646,0,0.896,0.633,0.896,1.588c0,0.893-0.263,1.574-0.871,1.574
			c-0.345,0-0.62-0.131-0.907-0.357c-0.226-0.18-0.357-0.264-0.477-0.264s-0.227,0.107-0.299,0.215
			c0.441,0.453,0.955,0.848,1.671,0.848c0.776,0,1.491-0.729,1.491-2.016C34.752,49.347,34.024,48.644,33.297,48.644"
      />
      <path
        display="inline"
        fill="#FFFFFF"
        d="M31.603,48.62h-0.537c-0.036,0-0.06-0.025-0.071-0.047l-0.168-0.514
			c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.047-0.06,0.047h-0.537c-0.024,0-0.034,0.012-0.012,0.023
			l0.442,0.322c0.023,0.023,0.023,0.059,0.012,0.084l-0.168,0.5c-0.01,0.025-0.01,0.049,0.025,0.025l0.441-0.311
			c0.035-0.023,0.059-0.023,0.095,0l0.431,0.311c0.022,0.023,0.035,0.012,0.022-0.014l-0.167-0.512
			c-0.012-0.035,0-0.072,0.023-0.084l0.441-0.322C31.638,48.632,31.625,48.62,31.603,48.62"
      />
      <path
        display="inline"
        fill="#FFFFFF"
        d="M31.197,52.151c-0.227-0.572-0.406-0.967-0.406-1.443c0-0.203,0.06-0.383,0.096-0.477
			c0.047-0.096,0.061-0.156,0.011-0.156c-0.071,0-0.226,0.166-0.321,0.311c-0.072,0.119-0.156,0.346-0.156,0.523
			c0,0.336,0.108,0.693,0.18,0.908c0.132,0.371,0.251,0.799,0.251,1.205c0,0.646-0.287,1.086-0.8,1.48
			c0.048,0.035,0.167,0.096,0.311,0.096c0.5,0,1.098-0.561,1.098-1.326C31.459,52.819,31.328,52.509,31.197,52.151"
      />
    </g>
  );
};

const Campaigning = () => {
  return (
    <g id="Campaigning">
      <g display="inline">
        <defs>
          <rect
            id="CAMPAIGNING_SVGID_11_"
            x="0.002"
            y="0.001"
            width="74.907"
            height="68.127"
          />
        </defs>
        <clipPath id="CAMPAIGNING_SVGID_12_">
          <use xlinkHref="#CAMPAIGNING_SVGID_11_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#CAMPAIGNING_SVGID_12_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F78E1E"
          d="M55.12,61.071H5.793
  c-3.059,0-5.563-2.504-5.563-5.563V6.181c0-3.059,2.504-5.563,5.563-5.563H55.12c3.061,0,5.563,2.504,5.563,5.563v49.328
  C60.684,58.567,58.181,61.071,55.12,61.071"
        />
      </g>
      <g display="inline">
        <defs>
          <path
            id="CAMPAIGNING_SVGID_13_"
            d="M12.674,0.001C9.588,0.056,6.707,2.589,6.707,5.679l0.39,50.334c0,3.121,2.554,5.676,5.677,5.676h42.851
    c3.121,0,5.677-2.555,5.677-5.676V5.679c0-3.09-2.5-5.623-5.577-5.678H12.674z"
          />
        </defs>
        <clipPath id="CAMPAIGNING_SVGID_14_">
          <use xlinkHref="#CAMPAIGNING_SVGID_13_" overflow="visible" />
        </clipPath>
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_14_)"
          fill="#D76427"
          points="29.046,49.396 58.231,68.128 80.649,34.784 51.981,16.169 			"
        />
      </g>
      <g display="inline">
        <defs>
          <rect
            id="CAMPAIGNING_SVGID_15_"
            x="0.002"
            y="0.001"
            width="74.907"
            height="68.127"
          />
        </defs>
        <clipPath id="CAMPAIGNING_SVGID_16_">
          <use xlinkHref="#CAMPAIGNING_SVGID_15_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FBB683"
          d="M56.013,4.407c0.688,0,1.27,0.582,1.27,1.271v50.334
  c0,0.688-0.581,1.27-1.27,1.27H5.68c-0.689,0-1.271-0.582-1.271-1.27V5.679c0-0.689,0.582-1.271,1.271-1.271H56.013
   M56.013,0.001H5.68c-3.123,0-5.678,2.555-5.678,5.678v50.334c0,3.121,2.555,5.676,5.678,5.676h50.333
  c3.121,0,5.677-2.555,5.677-5.676V5.679C61.689,2.556,59.134,0.001,56.013,0.001"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#F5DB8B"
          d="M47.226,46.358"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M15.075,9.765c-0.185,0.998-0.987,1.582-2.01,1.582
  c-1.185,0-2.117-0.91-2.117-2.082c0-1.166,0.933-2.08,2.117-2.08c0.981,0,1.813,0.582,2.004,1.527l-0.963,0.227
  c-0.125-0.578-0.529-0.857-1.041-0.857c-0.63,0-1.136,0.506-1.136,1.184s0.506,1.189,1.136,1.189
  c0.505,0,0.909-0.287,1.046-0.881L15.075,9.765z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M18.752,11.257h-0.951v-0.238c-0.232,0.209-0.541,0.328-0.928,0.328
  c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.387,0,0.695,0.119,0.928,0.328V8.272h0.951V11.257z
   M17.801,9.765c0-0.459-0.346-0.773-0.73-0.773c-0.418,0-0.721,0.314-0.721,0.773c0,0.463,0.303,0.773,0.721,0.773
  C17.455,10.538,17.801,10.228,17.801,9.765"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M24.396,9.39v1.867h-0.945V9.593c0-0.34-0.221-0.555-0.5-0.555
  c-0.351,0-0.582,0.256-0.582,0.756v1.463h-0.951V9.593c0-0.34-0.221-0.555-0.506-0.555c-0.346,0-0.57,0.256-0.57,0.756v1.463
  H19.39V8.271h0.952v0.275c0.201-0.227,0.492-0.363,0.88-0.363c0.416,0,0.772,0.178,0.974,0.504
  c0.238-0.309,0.596-0.504,1.059-0.504C23.891,8.183,24.396,8.665,24.396,9.39"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M28.351,9.765c0,0.945-0.667,1.582-1.457,1.582
  c-0.386,0-0.696-0.119-0.929-0.328v1.25h-0.951V8.272h0.951v0.238c0.232-0.209,0.543-0.328,0.929-0.328
  C27.684,8.183,28.351,8.825,28.351,9.765 M27.416,9.765c0-0.459-0.303-0.773-0.719-0.773c-0.387,0-0.732,0.314-0.732,0.773
  c0,0.463,0.346,0.773,0.732,0.773C27.113,10.538,27.416,10.228,27.416,9.765"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M31.969,11.257h-0.951v-0.238c-0.232,0.209-0.541,0.328-0.928,0.328
  c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.387,0,0.695,0.119,0.928,0.328V8.272h0.951V11.257z
   M31.018,9.765c0-0.459-0.345-0.773-0.732-0.773c-0.416,0-0.719,0.314-0.719,0.773c0,0.463,0.303,0.773,0.719,0.773
  C30.673,10.538,31.018,10.228,31.018,9.765"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M33.666,7.392c0,0.303-0.232,0.529-0.554,0.529s-0.547-0.227-0.547-0.529
  c0-0.291,0.226-0.541,0.547-0.541S33.666,7.101,33.666,7.392 M32.637,8.272h0.951v2.986h-0.951V8.272z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M37.309,10.96c0,0.922-0.701,1.426-1.672,1.426
  c-0.683,0-1.207-0.238-1.498-0.719l0.791-0.428c0.226,0.268,0.393,0.369,0.701,0.369c0.434,0,0.744-0.256,0.744-0.727V10.8
  c-0.215,0.184-0.512,0.285-0.898,0.285c-0.778,0-1.438-0.619-1.438-1.463c0-0.826,0.66-1.439,1.438-1.439
  c0.387,0,0.684,0.1,0.898,0.285V8.272h0.934V10.96z M36.375,9.622c0-0.398-0.316-0.68-0.695-0.68
  c-0.416,0-0.715,0.281-0.715,0.68s0.299,0.684,0.715,0.684C36.059,10.306,36.375,10.021,36.375,9.622"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M40.976,9.425v1.832h-0.946V9.628c0-0.352-0.207-0.59-0.512-0.59
  c-0.374,0-0.618,0.256-0.618,0.834v1.385h-0.95V8.272h0.95v0.285c0.228-0.238,0.542-0.375,0.916-0.375
  C40.512,8.183,40.976,8.677,40.976,9.425"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M42.631,7.392c0,0.303-0.233,0.529-0.553,0.529
  c-0.322,0-0.547-0.227-0.547-0.529c0-0.291,0.225-0.541,0.547-0.541C42.397,6.851,42.631,7.101,42.631,7.392 M41.602,8.272h0.952
  v2.986h-0.952V8.272z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M46.235,9.425v1.832h-0.946V9.628c0-0.352-0.207-0.59-0.512-0.59
  c-0.374,0-0.618,0.256-0.618,0.834v1.385h-0.95V8.272h0.95v0.285c0.226-0.238,0.541-0.375,0.916-0.375
  C45.771,8.183,46.235,8.677,46.235,9.425"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M49.917,10.96c0,0.922-0.702,1.426-1.671,1.426
  c-0.685,0-1.208-0.238-1.499-0.719l0.791-0.428c0.227,0.268,0.393,0.369,0.702,0.369c0.434,0,0.743-0.256,0.743-0.727V10.8
  c-0.215,0.184-0.512,0.285-0.897,0.285c-0.78,0-1.44-0.619-1.44-1.463c0-0.826,0.66-1.439,1.44-1.439
  c0.386,0,0.683,0.1,0.897,0.285V8.272h0.934V10.96z M48.983,9.622c0-0.398-0.315-0.68-0.695-0.68
  c-0.417,0-0.714,0.281-0.714,0.68s0.297,0.684,0.714,0.684C48.668,10.306,48.983,10.021,48.983,9.622"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#F5DB8B"
          d="M50.752,51.185H30.018c-1.322,0-2.392-1.068-2.392-2.391V18.222
  c0-1.32,1.069-2.391,2.392-2.391h20.734c1.321,0,2.392,1.07,2.392,2.391v30.572C53.144,50.116,52.073,51.185,50.752,51.185"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M47.872,48.804H29.646V19.796h21.665v25.568
  C49.968,46.708,49.216,47.46,47.872,48.804"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#97A0A4"
          d="M45.872,22.06H34.896v-2.133c0-0.818,0.664-1.482,1.481-1.482h8.015
  c0.817,0,1.48,0.664,1.48,1.482V22.06z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#97A0A4"
          d="M40.385,20.243L40.385,20.243c-1.035,0-1.873-0.84-1.873-1.873
  s0.838-1.873,1.873-1.873c1.034,0,1.873,0.84,1.873,1.873S41.419,20.243,40.385,20.243"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#F5DB8B"
          d="M40.385,19.12L40.385,19.12c-0.414,0-0.75-0.336-0.75-0.75
  s0.336-0.75,0.75-0.75c0.413,0,0.75,0.336,0.75,0.75S40.798,19.12,40.385,19.12"
        />

        <line
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="none"
          stroke="#97A0A4"
          strokeWidth="0.523"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="33.227"
          y1="27.823"
          x2="47.731"
          y2="27.823"
        />

        <line
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="none"
          stroke="#97A0A4"
          strokeWidth="0.523"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="33.227"
          y1="32.937"
          x2="47.731"
          y2="32.937"
        />
        <polyline
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#97A0A4"
          points="47.796,48.892 47.796,45.284 51.404,45.284 			"
        />

        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="none"
          stroke="#5087C7"
          strokeWidth="0.615"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
  M33.398,37.812c0.586-0.701,1.654-0.832,2.491-0.564c0.347,0.111,0.712,0.287,0.984,0.537c0.554,0.506,0.831,1.111,1.651,1.156
  c0.815,0.045,1.566-0.336,1.938-1.078c0.111-1-1.292-0.662-1.705-0.25c-0.973,0.969-1.361,2.318-1.572,3.639
  c-0.158,0.984-0.031,1.369-0.34,2.338c-0.339,1.064-1.248,0.141-1.439-0.291c-0.606-1.377-0.4-2.885,1.304-2.986
  c0.698-0.041,1.433,0.188,2.147,0.197c0.375,0.006,0.758-0.025,1.094-0.205c0.361-0.191,0.539-0.48,0.986-0.504
  c0.984-0.049,1.576,1.104,2.502,1.281c0.565,0.107,1.004-0.287,1.508-0.484c0.786-0.307,1.63-0.465,2.474-0.457"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#5087C7"
          d="M30.856,55.362c-2.638,0-4.772-2.137-4.772-4.771
  c0-2.639,2.135-4.773,4.772-4.773s4.772,2.135,4.772,4.773C35.629,53.226,33.494,55.362,30.856,55.362"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M30.856,45.817c2.638,0,4.772,2.135,4.772,4.773
  c0,2.635-2.135,4.771-4.772,4.771s-4.772-2.137-4.772-4.771C26.084,47.952,28.219,45.817,30.856,45.817 M30.856,45.405
  c-2.858,0-5.185,2.326-5.185,5.186c0,2.857,2.326,5.184,5.185,5.184s5.185-2.326,5.185-5.184
  C36.041,47.731,33.715,45.405,30.856,45.405"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M33.351,48.644c-0.239,0-0.466,0.109-0.62,0.252l-0.014-0.012
  c0.072-0.119,0.168-0.346,0.168-0.717c0-0.883-0.883-1.551-2.028-1.551s-2.041,0.668-2.041,1.551c0,0.371,0.12,0.598,0.18,0.717
  l-0.013,0.012c-0.155-0.143-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.705-1.457,2.018c0,1.289,0.716,2.016,1.492,2.016
  c0.716,0,1.217-0.393,1.67-0.846c-0.071-0.109-0.18-0.217-0.298-0.217c-0.119,0-0.251,0.086-0.478,0.264
  c-0.298,0.227-0.561,0.359-0.908,0.359c-0.607,0-0.869-0.682-0.869-1.576c0-0.955,0.25-1.586,0.896-1.586
  c0.286,0,0.476,0.082,0.667,0.262c0.286,0.262,0.383,0.334,0.514,0.334c0.096,0,0.155-0.061,0.179-0.096
  c-0.214-0.215-0.525-0.668-0.525-1.252c0-0.668,0.562-1.135,1.611-1.135s1.61,0.467,1.61,1.135c0,0.584-0.31,1.037-0.524,1.252
  c0.024,0.035,0.084,0.096,0.179,0.096c0.133,0,0.227-0.072,0.514-0.334c0.179-0.18,0.381-0.262,0.668-0.262
  c0.644,0,0.895,0.631,0.895,1.586c0,0.895-0.263,1.576-0.871,1.576c-0.345,0-0.619-0.133-0.906-0.359
  c-0.227-0.178-0.358-0.264-0.478-0.264s-0.226,0.107-0.298,0.217c0.441,0.453,0.955,0.846,1.671,0.846
  c0.774,0,1.491-0.727,1.491-2.016C34.807,49.349,34.078,48.644,33.351,48.644"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M31.656,48.622h-0.537c-0.036,0-0.059-0.025-0.07-0.049l-0.168-0.514
  c-0.013-0.035-0.023-0.035-0.037,0l-0.166,0.514c-0.013,0.035-0.037,0.049-0.06,0.049H30.08c-0.023,0-0.035,0.01-0.012,0.021
  l0.441,0.324c0.024,0.021,0.024,0.059,0.013,0.082l-0.167,0.502c-0.012,0.023-0.012,0.047,0.023,0.023l0.442-0.309
  c0.036-0.025,0.06-0.025,0.095,0l0.43,0.309c0.023,0.023,0.035,0.012,0.023-0.012l-0.166-0.514c-0.013-0.035,0-0.07,0.023-0.082
  l0.44-0.324C31.691,48.632,31.68,48.622,31.656,48.622"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M31.25,52.151c-0.227-0.572-0.406-0.965-0.406-1.441
  c0-0.205,0.061-0.383,0.097-0.479c0.047-0.096,0.06-0.156,0.011-0.156c-0.07,0-0.226,0.168-0.322,0.313
  c-0.071,0.117-0.154,0.346-0.154,0.523c0,0.334,0.107,0.693,0.179,0.906c0.132,0.371,0.251,0.801,0.251,1.207
  c0,0.645-0.287,1.084-0.799,1.479c0.047,0.035,0.166,0.096,0.311,0.096c0.5,0,1.097-0.561,1.097-1.324
  C31.513,52.819,31.381,52.511,31.25,52.151"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#F5DB8B"
          d="M23.439,16.847c-0.211,0.074-0.448,0.076-0.673-0.012
  c-0.224-0.088-0.396-0.252-0.5-0.449l-2.579,2.146l-0.645,1.641l4.176,1.643l0.646-1.641L23.439,16.847z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#213466"
          d="M23.439,16.847l-0.166-1.299l-1.007,0.838
  c0.104,0.197,0.276,0.361,0.5,0.449C22.991,16.923,23.229,16.921,23.439,16.847"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#5087C7"
          d="M23.021,20.265c-0.231-0.092-0.406-0.258-0.502-0.453
  c-0.038-0.078-0.131-0.113-0.211-0.082c-0.203,0.076-0.444,0.08-0.676-0.012c-0.231-0.09-0.406-0.258-0.502-0.451
  c-0.039-0.078-0.131-0.115-0.211-0.084c-0.203,0.078-0.445,0.08-0.676-0.01c-0.297-0.117-0.501-0.359-0.563-0.623l-8.813,22.4
  l4.178,1.643l8.812-22.4C23.631,20.343,23.318,20.382,23.021,20.265"
        />

        <rect
          x="10.218"
          y="41.678"
          transform="matrix(0.9306 0.3661 -0.3661 0.9306 16.6168 -1.5751)"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#97A0A4"
          width="4.487"
          height="2.707"
        />

        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.361"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
  M10.686,42.839l3.135,1.232 M11.01,42.017l3.133,1.232"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          d="M11.041,48.118l-1.006-0.395c-0.875-0.346-1.306-1.334-0.961-2.209
  l0.803-2.045l4.178,1.643l-0.803,2.045C12.906,48.032,11.918,48.464,11.041,48.118"
        />
        <line
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#F5DB8B"
          x1="21.729"
          y1="22.026"
          x2="14.648"
          y2="39.644"
        />

        <line
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="none"
          stroke="#213466"
          strokeWidth="0.523"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="21.729"
          y1="22.026"
          x2="14.648"
          y2="39.644"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-12.222-25.828c0.075,0,0.14,0.012,0.192,0.037
  c0.052,0.026,0.094,0.06,0.125,0.102c0.031,0.041,0.055,0.088,0.068,0.142s0.02,0.107,0.02,0.162c0,0.076-0.01,0.144-0.033,0.199
  c-0.023,0.058-0.055,0.104-0.096,0.14s-0.09,0.063-0.147,0.081c-0.058,0.017-0.123,0.026-0.192,0.026h-0.16v0.59h-0.299v-1.479
  H-12.222z M-12.313-25.159c0.059,0,0.107-0.018,0.144-0.054c0.037-0.036,0.056-0.093,0.056-0.168
  c0-0.074-0.017-0.132-0.049-0.171c-0.031-0.037-0.084-0.058-0.158-0.058h-0.125v0.45H-12.313z"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          points="-11.219,-25.829 -11.008,-24.783 -11.002,-24.783 -10.789,-25.829
  -10.349,-25.829 -10.349,-24.348 -10.621,-24.348 -10.621,-25.53 -10.625,-25.53 -10.889,-24.348 -11.121,-24.348
  -11.385,-25.53 -11.389,-25.53 -11.389,-24.348 -11.662,-24.348 -11.662,-25.829 			"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-9.895-24.767c0,0.034,0.004,0.066,0.008,0.094
  c0.006,0.028,0.017,0.054,0.031,0.072c0.016,0.019,0.036,0.035,0.063,0.045c0.025,0.012,0.057,0.017,0.096,0.017
  c0.045,0,0.086-0.015,0.123-0.045c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086
  c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.035-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
  c-0.065-0.021-0.124-0.046-0.172-0.073c-0.048-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
  s-0.022-0.1-0.022-0.161c0-0.146,0.041-0.256,0.123-0.327c0.08-0.071,0.193-0.107,0.334-0.107c0.068,0,0.129,0.007,0.185,0.021
  c0.056,0.017,0.104,0.039,0.146,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
  c0-0.07-0.013-0.124-0.037-0.162c-0.024-0.037-0.065-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
  c-0.022,0.011-0.04,0.023-0.054,0.038c-0.012,0.017-0.021,0.035-0.026,0.056s-0.007,0.042-0.007,0.064
  c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.123,0.086l0.23,0.1c0.057,0.024,0.104,0.051,0.139,0.077
  C-9.315-25.06-9.287-25.031-9.266-25s0.037,0.065,0.045,0.103c0.008,0.038,0.013,0.079,0.013,0.125
  c0,0.156-0.046,0.27-0.136,0.341s-0.217,0.106-0.379,0.106c-0.168,0-0.289-0.036-0.361-0.109s-0.108-0.179-0.108-0.315v-0.061
  h0.298V-24.767z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-7.716-25.558c-0.062,0.086-0.115,0.177-0.163,0.274
  c-0.047,0.1-0.089,0.2-0.125,0.303c-0.036,0.104-0.066,0.211-0.089,0.316c-0.024,0.107-0.042,0.212-0.054,0.316H-8.46
  c0.011-0.088,0.026-0.182,0.05-0.279c0.022-0.097,0.051-0.196,0.088-0.297c0.036-0.102,0.082-0.202,0.133-0.305
  c0.054-0.104,0.115-0.203,0.188-0.301H-8.62v-0.257h0.904V-25.558z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-7.293-25.384h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.056
  s0.095-0.097,0.109-0.18h0.219v1.467h-0.298V-25.384z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-6.313-24.582c0.021,0.046,0.065,0.067,0.13,0.067
  c0.035,0,0.064-0.006,0.086-0.019s0.037-0.029,0.049-0.051s0.02-0.048,0.023-0.075c0.003-0.029,0.005-0.061,0.005-0.091
  c0-0.034-0.002-0.065-0.007-0.096c-0.006-0.028-0.016-0.056-0.028-0.079c-0.015-0.023-0.033-0.042-0.058-0.057
  C-6.139-24.994-6.172-25-6.213-25h-0.1v-0.196h0.097c0.032,0,0.058-0.005,0.08-0.02c0.021-0.013,0.039-0.029,0.052-0.052
  c0.014-0.021,0.023-0.046,0.029-0.073s0.009-0.057,0.009-0.087c0-0.07-0.012-0.119-0.034-0.149
  c-0.024-0.029-0.06-0.043-0.108-0.043c-0.032,0-0.058,0.006-0.077,0.018c-0.021,0.011-0.036,0.028-0.047,0.049
  c-0.012,0.022-0.02,0.047-0.022,0.076s-0.005,0.062-0.005,0.095h-0.273c0-0.146,0.037-0.255,0.109-0.325
  c0.073-0.071,0.182-0.107,0.322-0.107c0.133,0,0.236,0.031,0.313,0.091c0.073,0.062,0.109,0.156,0.109,0.286
  c0,0.089-0.02,0.161-0.059,0.217c-0.039,0.057-0.094,0.091-0.164,0.107v0.004c0.095,0.015,0.162,0.053,0.201,0.115
  c0.039,0.061,0.06,0.139,0.06,0.23c0,0.049-0.007,0.102-0.019,0.153c-0.013,0.053-0.037,0.101-0.072,0.143
  c-0.037,0.043-0.086,0.078-0.149,0.104c-0.062,0.026-0.146,0.039-0.247,0.039c-0.143,0-0.248-0.038-0.32-0.116
  c-0.069-0.077-0.104-0.188-0.104-0.329v-0.006h0.286C-6.347-24.692-6.336-24.626-6.313-24.582"
        />
        <rect
          x="-3.305"
          y="-26.327"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#F78E1E"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-2.39-25.828c0.076,0,0.14,0.012,0.192,0.038
  c0.052,0.025,0.094,0.059,0.125,0.101c0.031,0.041,0.055,0.088,0.068,0.142s0.021,0.107,0.021,0.162
  c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.058-0.054,0.104-0.095,0.14s-0.09,0.063-0.147,0.081
  c-0.059,0.018-0.123,0.026-0.193,0.026h-0.16v0.59h-0.299v-1.479H-2.39z M-2.48-25.159c0.059,0,0.107-0.018,0.145-0.054
  s0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.049-0.171c-0.031-0.037-0.084-0.058-0.158-0.058h-0.125v0.45H-2.48z"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          points="-1.387,-25.829 -1.176,-24.783 -1.17,-24.783 -0.957,-25.829
  -0.516,-25.829 -0.516,-24.348 -0.789,-24.348 -0.789,-25.53 -0.793,-25.53 -1.057,-24.348 -1.289,-24.348 -1.553,-25.53
  -1.557,-25.53 -1.557,-24.348 -1.83,-24.348 -1.83,-25.829 			"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-0.062-24.767c0,0.035,0.003,0.066,0.008,0.095s0.017,0.053,0.031,0.071
  s0.036,0.035,0.062,0.045c0.025,0.012,0.057,0.017,0.096,0.017c0.045,0,0.086-0.015,0.123-0.045
  c0.037-0.028,0.056-0.074,0.056-0.137c0-0.034-0.005-0.062-0.013-0.086c-0.01-0.023-0.024-0.046-0.045-0.066
  c-0.021-0.019-0.049-0.035-0.082-0.053c-0.033-0.015-0.076-0.031-0.125-0.048c-0.066-0.021-0.125-0.046-0.172-0.073
  c-0.049-0.025-0.09-0.056-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122c-0.016-0.046-0.023-0.1-0.023-0.161
  c0-0.146,0.041-0.255,0.123-0.327c0.081-0.071,0.193-0.107,0.335-0.107c0.067,0,0.128,0.007,0.185,0.021
  c0.056,0.016,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.096,0.124c0.022,0.051,0.033,0.111,0.033,0.182v0.041H0.291
  c0-0.069-0.013-0.124-0.037-0.162c-0.025-0.037-0.066-0.057-0.125-0.057c-0.033,0-0.061,0.005-0.082,0.016
  c-0.023,0.009-0.041,0.021-0.054,0.036c-0.013,0.017-0.022,0.035-0.026,0.056c-0.006,0.021-0.008,0.042-0.008,0.064
  c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.1c0.058,0.024,0.104,0.051,0.14,0.077
  C0.518-25.06,0.545-25.031,0.567-25c0.021,0.031,0.036,0.066,0.045,0.103c0.008,0.038,0.013,0.079,0.013,0.125
  c0,0.156-0.047,0.27-0.137,0.341s-0.216,0.107-0.378,0.107c-0.169,0-0.289-0.037-0.362-0.11
  c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.298V-24.767z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M1.545-25.384H1.252v-0.195h0.027c0.1,0,0.178-0.018,0.235-0.056
  s0.094-0.097,0.108-0.18h0.22v1.467H1.545V-25.384z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M2.678-25.816h0.31v0.932h0.142v0.231H2.987v0.305H2.713v-0.305H2.191
  v-0.245L2.678-25.816z M2.709-25.443l-0.285,0.559h0.289v-0.559H2.709z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M3.674-25.816h0.309v0.932h0.141v0.231H3.982v0.305H3.708v-0.305H3.186
  v-0.245L3.674-25.816z M3.704-25.443l-0.286,0.559h0.29v-0.559H3.704z"
        />
        <rect
          x="17.067"
          y="-26.327"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#213466"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M17.981-25.828c0.076,0,0.14,0.012,0.192,0.038
  c0.052,0.025,0.094,0.059,0.125,0.101c0.031,0.041,0.055,0.088,0.068,0.142s0.021,0.107,0.021,0.162
  c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.058-0.054,0.104-0.095,0.14s-0.09,0.063-0.147,0.081
  c-0.059,0.018-0.123,0.026-0.193,0.026h-0.16v0.59h-0.299v-1.479H17.981z M17.891-25.159c0.059,0,0.107-0.018,0.145-0.054
  s0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.049-0.171c-0.031-0.037-0.084-0.058-0.158-0.058h-0.125v0.45H17.891z"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          points="18.984,-25.829 19.195,-24.783 19.201,-24.783 19.414,-25.829
  19.855,-25.829 19.855,-24.348 19.582,-24.348 19.582,-25.53 19.578,-25.53 19.314,-24.348 19.082,-24.348 18.818,-25.53
  18.814,-25.53 18.814,-24.348 18.541,-24.348 18.541,-25.829 			"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M20.31-24.767c0,0.035,0.003,0.066,0.008,0.095s0.017,0.053,0.03,0.071
  c0.016,0.019,0.037,0.035,0.063,0.045c0.025,0.012,0.057,0.017,0.096,0.017c0.045,0,0.086-0.015,0.123-0.045
  c0.037-0.028,0.056-0.074,0.056-0.137c0-0.034-0.005-0.062-0.013-0.086c-0.01-0.023-0.024-0.046-0.045-0.066
  c-0.021-0.019-0.049-0.035-0.082-0.053c-0.033-0.015-0.076-0.031-0.125-0.048c-0.066-0.021-0.125-0.046-0.173-0.073
  c-0.048-0.025-0.089-0.056-0.12-0.092c-0.033-0.035-0.058-0.076-0.072-0.122c-0.016-0.046-0.023-0.1-0.023-0.161
  c0-0.146,0.041-0.255,0.123-0.327c0.081-0.071,0.193-0.107,0.335-0.107c0.067,0,0.128,0.007,0.185,0.021
  c0.056,0.016,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
  c0-0.069-0.012-0.124-0.036-0.162c-0.025-0.037-0.066-0.057-0.125-0.057c-0.033,0-0.061,0.005-0.082,0.016
  c-0.023,0.009-0.041,0.021-0.054,0.036c-0.013,0.017-0.022,0.035-0.026,0.056c-0.006,0.021-0.008,0.042-0.008,0.064
  c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.1c0.058,0.024,0.104,0.051,0.14,0.077
  c0.036,0.027,0.063,0.057,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.103c0.008,0.038,0.012,0.079,0.012,0.125
  c0,0.156-0.046,0.27-0.136,0.341s-0.216,0.107-0.378,0.107c-0.169,0-0.289-0.037-0.362-0.11
  c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.298V-24.767z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M21.576-24.348c0-0.065,0.006-0.126,0.02-0.183
  c0.013-0.057,0.033-0.11,0.061-0.161c0.026-0.051,0.06-0.102,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.147l0.121-0.112
  c0.033-0.03,0.061-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.024,0.019-0.052,0.022-0.081
  c0.002-0.028,0.005-0.062,0.005-0.099c0-0.122-0.048-0.184-0.146-0.184c-0.037,0-0.066,0.009-0.088,0.023
  s-0.038,0.036-0.05,0.063c-0.013,0.026-0.019,0.057-0.022,0.092c-0.004,0.033-0.006,0.07-0.006,0.108h-0.285v-0.056
  c0-0.136,0.037-0.241,0.109-0.315c0.073-0.072,0.188-0.108,0.346-0.108c0.149,0,0.26,0.034,0.332,0.103
  c0.072,0.069,0.107,0.168,0.107,0.296c0,0.047-0.004,0.092-0.012,0.133s-0.021,0.079-0.039,0.116
  c-0.019,0.038-0.043,0.074-0.071,0.109s-0.063,0.072-0.106,0.109l-0.164,0.152c-0.045,0.04-0.08,0.079-0.104,0.117
  c-0.024,0.039-0.041,0.071-0.048,0.099h0.554v0.232H21.576z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M23.482-25.558c-0.061,0.086-0.115,0.178-0.163,0.274
  c-0.047,0.1-0.089,0.2-0.125,0.303c-0.036,0.104-0.065,0.211-0.089,0.316c-0.023,0.108-0.042,0.213-0.055,0.316h-0.313
  c0.012-0.088,0.027-0.182,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.298c0.035-0.102,0.08-0.202,0.133-0.304
  c0.053-0.104,0.115-0.203,0.187-0.302h-0.618v-0.257h0.904V-25.558z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M24.045-25.816h0.309v0.932h0.141v0.231h-0.141v0.305h-0.274v-0.305h-0.522
  v-0.245L24.045-25.816z M24.075-25.443l-0.286,0.559h0.29v-0.559H24.075z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M25.473-25.558c-0.062,0.086-0.115,0.178-0.164,0.274
  c-0.047,0.1-0.088,0.2-0.125,0.303c-0.035,0.104-0.065,0.211-0.088,0.316c-0.025,0.108-0.043,0.213-0.055,0.316h-0.313
  c0.011-0.088,0.026-0.182,0.049-0.278c0.022-0.098,0.052-0.197,0.089-0.298c0.036-0.102,0.081-0.202,0.133-0.304
  c0.053-0.104,0.115-0.203,0.187-0.302h-0.617v-0.257h0.904V-25.558z"
        />
        <rect
          x="26.938"
          y="-26.327"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#F5DB8B"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M27.852-25.828c0.076,0,0.14,0.012,0.191,0.038
  c0.053,0.025,0.094,0.059,0.125,0.101c0.032,0.041,0.056,0.088,0.069,0.142c0.013,0.054,0.021,0.107,0.021,0.163
  c0,0.075-0.012,0.143-0.034,0.198c-0.023,0.058-0.056,0.104-0.095,0.14c-0.041,0.036-0.092,0.063-0.148,0.081
  c-0.059,0.018-0.123,0.026-0.193,0.026h-0.16v0.59H27.33v-1.479H27.852z M27.76-25.159c0.06,0,0.107-0.018,0.145-0.054
  s0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.048-0.17s-0.085-0.059-0.159-0.059h-0.125v0.45H27.76z"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          points="28.854,-25.829 29.065,-24.783 29.07,-24.783 29.283,-25.829
  29.725,-25.829 29.725,-24.348 29.451,-24.348 29.451,-25.53 29.447,-25.53 29.184,-24.348 28.951,-24.348 28.689,-25.53
  28.686,-25.53 28.686,-24.348 28.411,-24.348 28.411,-25.829 			"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M30.18-24.767c0,0.035,0.002,0.066,0.008,0.095
  c0.004,0.028,0.016,0.053,0.03,0.071s0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.018,0.097,0.018
  c0.045,0,0.086-0.016,0.123-0.046c0.036-0.028,0.056-0.074,0.056-0.137c0-0.034-0.006-0.062-0.015-0.086
  c-0.008-0.023-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.035-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
  c-0.066-0.021-0.124-0.046-0.172-0.073c-0.049-0.025-0.09-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
  c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.326c0.082-0.072,0.193-0.108,0.336-0.108
  c0.066,0,0.127,0.007,0.184,0.021c0.057,0.016,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.096,0.124
  c0.023,0.051,0.033,0.111,0.033,0.182v0.041h-0.285c0-0.069-0.014-0.124-0.037-0.161c-0.025-0.038-0.067-0.058-0.124-0.058
  c-0.033,0-0.062,0.005-0.083,0.016c-0.023,0.009-0.04,0.021-0.055,0.036c-0.012,0.017-0.021,0.035-0.025,0.056
  S30.2-25.488,30.2-25.465c0,0.045,0.01,0.083,0.028,0.115c0.02,0.031,0.061,0.059,0.125,0.086l0.229,0.1
  c0.058,0.024,0.103,0.051,0.14,0.077c0.035,0.027,0.063,0.057,0.086,0.087c0.021,0.031,0.035,0.066,0.045,0.103
  c0.006,0.038,0.011,0.079,0.011,0.125c0,0.156-0.046,0.27-0.136,0.341s-0.215,0.107-0.377,0.107c-0.17,0-0.291-0.037-0.362-0.11
  c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.767z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M31.785-25.384h-0.293v-0.195h0.027c0.1,0,0.178-0.018,0.235-0.056
  s0.094-0.097,0.108-0.18h0.22v1.467h-0.298V-25.384z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M32.439-24.348c0-0.065,0.007-0.126,0.02-0.183
  c0.014-0.057,0.033-0.11,0.061-0.161s0.061-0.102,0.102-0.15c0.041-0.05,0.088-0.099,0.142-0.147l0.12-0.112
  c0.033-0.03,0.061-0.056,0.08-0.081c0.021-0.025,0.035-0.05,0.047-0.075c0.011-0.024,0.019-0.052,0.021-0.081
  c0.004-0.028,0.006-0.062,0.006-0.099c0-0.122-0.049-0.184-0.146-0.184c-0.037,0-0.067,0.009-0.088,0.023
  c-0.022,0.015-0.038,0.036-0.05,0.063s-0.02,0.057-0.023,0.092c-0.003,0.033-0.004,0.07-0.004,0.108h-0.287v-0.056
  c0-0.136,0.037-0.241,0.109-0.315c0.074-0.072,0.188-0.108,0.347-0.108c0.149,0,0.26,0.034,0.332,0.103
  c0.071,0.069,0.108,0.168,0.108,0.296c0,0.047-0.004,0.092-0.014,0.133c-0.008,0.041-0.021,0.079-0.039,0.116
  c-0.018,0.038-0.041,0.074-0.07,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.152c-0.044,0.04-0.079,0.079-0.104,0.117
  c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H32.439z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M33.445-25.327c0.006-0.094,0.025-0.177,0.057-0.249
  c0.029-0.073,0.075-0.131,0.137-0.174c0.062-0.044,0.146-0.066,0.256-0.066c0.09,0,0.164,0.013,0.223,0.039
  c0.059,0.025,0.106,0.066,0.141,0.123c0.035,0.057,0.061,0.13,0.076,0.22c0.014,0.092,0.021,0.201,0.021,0.331
  c0,0.103-0.004,0.2-0.012,0.294c-0.006,0.095-0.025,0.179-0.055,0.25c-0.031,0.072-0.076,0.13-0.139,0.171
  c-0.061,0.042-0.146,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.061-0.022-0.107-0.063-0.143-0.12
  c-0.035-0.056-0.061-0.13-0.074-0.221c-0.015-0.09-0.022-0.201-0.022-0.33C33.435-25.135,33.438-25.233,33.445-25.327
   M33.74-24.741c0.006,0.059,0.014,0.105,0.026,0.139c0.012,0.034,0.028,0.058,0.05,0.069c0.02,0.013,0.046,0.018,0.078,0.018
  c0.033,0,0.061-0.007,0.081-0.022c0.021-0.017,0.036-0.043,0.049-0.081c0.011-0.038,0.021-0.089,0.024-0.153
  c0.004-0.063,0.008-0.142,0.008-0.235v-0.159c0-0.094-0.004-0.168-0.008-0.228c-0.004-0.059-0.014-0.104-0.025-0.139
  c-0.014-0.034-0.03-0.057-0.05-0.069c-0.021-0.013-0.048-0.019-0.079-0.019c-0.033,0-0.061,0.008-0.08,0.023
  c-0.021,0.017-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.088-0.025,0.152c-0.005,0.063-0.008,0.142-0.008,0.236v0.16
  C33.732-24.875,33.735-24.799,33.74-24.741"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M34.732-25.554l-0.031,0.334h0.004c0.027-0.039,0.063-0.067,0.107-0.085
  c0.045-0.018,0.098-0.025,0.162-0.025c0.07,0,0.129,0.014,0.176,0.04c0.045,0.027,0.082,0.063,0.109,0.107
  c0.025,0.044,0.046,0.094,0.057,0.149c0.01,0.057,0.016,0.113,0.016,0.171c0,0.082-0.006,0.156-0.021,0.223
  c-0.013,0.065-0.036,0.123-0.073,0.171c-0.035,0.048-0.084,0.083-0.144,0.107c-0.062,0.024-0.138,0.036-0.231,0.036
  s-0.168-0.012-0.226-0.037c-0.058-0.024-0.101-0.058-0.132-0.099c-0.03-0.041-0.051-0.085-0.061-0.132
  c-0.011-0.048-0.017-0.093-0.017-0.134v-0.039h0.287c0,0.023,0.001,0.05,0.004,0.078c0.002,0.027,0.009,0.054,0.021,0.078
  c0.01,0.023,0.024,0.044,0.043,0.06c0.021,0.016,0.045,0.022,0.078,0.022c0.062,0,0.105-0.022,0.133-0.07
  c0.027-0.047,0.041-0.119,0.041-0.214c0-0.062-0.004-0.111-0.01-0.151c-0.005-0.041-0.014-0.072-0.027-0.096
  s-0.03-0.04-0.053-0.049c-0.021-0.011-0.05-0.016-0.082-0.016c-0.043,0-0.076,0.012-0.1,0.036
  c-0.021,0.024-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H34.732z"
        />
        <rect
          x="36.806"
          y="-26.327"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#D76427"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M37.72-25.828c0.075,0,0.14,0.012,0.192,0.038
  c0.051,0.025,0.094,0.059,0.125,0.101c0.031,0.041,0.055,0.088,0.068,0.142s0.02,0.107,0.02,0.162
  c0,0.076-0.011,0.144-0.033,0.199c-0.023,0.058-0.055,0.104-0.096,0.14s-0.091,0.063-0.147,0.081
  c-0.058,0.018-0.123,0.026-0.193,0.026h-0.159v0.59h-0.299v-1.479H37.72z M37.629-25.159c0.059,0,0.106-0.018,0.144-0.054
  s0.056-0.093,0.056-0.168c0-0.074-0.017-0.132-0.049-0.171c-0.031-0.037-0.085-0.058-0.16-0.058h-0.123v0.45H37.629z"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          points="38.723,-25.829 38.934,-24.783 38.938,-24.783 39.151,-25.829
  39.594,-25.829 39.594,-24.348 39.319,-24.348 39.319,-25.53 39.315,-25.53 39.053,-24.348 38.819,-24.348 38.558,-25.53
  38.553,-25.53 38.553,-24.348 38.279,-24.348 38.279,-25.829 			"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M40.047-24.767c0,0.035,0.004,0.066,0.008,0.095
  c0.006,0.028,0.017,0.053,0.031,0.071c0.016,0.019,0.036,0.035,0.061,0.045c0.027,0.012,0.059,0.017,0.098,0.017
  c0.045,0,0.086-0.015,0.123-0.045c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
  c-0.008-0.023-0.023-0.046-0.044-0.066c-0.021-0.019-0.048-0.035-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
  c-0.065-0.021-0.124-0.046-0.172-0.073c-0.05-0.025-0.089-0.056-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
  s-0.022-0.1-0.022-0.161c0-0.146,0.041-0.255,0.121-0.327c0.082-0.071,0.194-0.107,0.336-0.107c0.067,0,0.129,0.007,0.185,0.021
  c0.056,0.016,0.104,0.038,0.144,0.07c0.043,0.033,0.074,0.074,0.097,0.124c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
  c0-0.069-0.013-0.124-0.038-0.162c-0.023-0.037-0.066-0.057-0.123-0.057c-0.033,0-0.061,0.005-0.083,0.016
  c-0.022,0.009-0.04,0.021-0.054,0.036c-0.014,0.017-0.021,0.035-0.026,0.056s-0.007,0.042-0.007,0.064
  c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.123,0.086l0.23,0.1c0.057,0.024,0.104,0.051,0.139,0.077
  c0.036,0.027,0.064,0.057,0.086,0.087c0.021,0.031,0.037,0.066,0.045,0.103c0.008,0.038,0.012,0.079,0.012,0.125
  c0,0.156-0.045,0.27-0.135,0.341s-0.217,0.107-0.379,0.107c-0.168,0-0.289-0.037-0.361-0.11s-0.108-0.179-0.108-0.315v-0.061
  h0.298V-24.767z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M41.653-25.384h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.234-0.056
  c0.057-0.038,0.094-0.097,0.109-0.18h0.219v1.467h-0.298V-25.384z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M42.61-25.554l-0.03,0.334h0.004c0.027-0.039,0.063-0.067,0.107-0.085
  s0.098-0.025,0.162-0.025c0.07,0,0.128,0.014,0.175,0.04c0.046,0.027,0.083,0.063,0.11,0.107
  c0.025,0.044,0.046,0.094,0.057,0.149c0.01,0.057,0.016,0.113,0.016,0.171c0,0.082-0.006,0.156-0.021,0.223
  c-0.013,0.065-0.038,0.123-0.073,0.171s-0.084,0.083-0.144,0.107c-0.062,0.024-0.138,0.036-0.231,0.036s-0.169-0.012-0.226-0.037
  c-0.058-0.024-0.101-0.058-0.132-0.099c-0.03-0.041-0.051-0.085-0.062-0.132c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287
  c0,0.023,0.001,0.05,0.004,0.078c0.002,0.027,0.009,0.054,0.021,0.077c0.01,0.024,0.024,0.044,0.043,0.061
  c0.021,0.016,0.045,0.022,0.076,0.022c0.063,0,0.107-0.022,0.135-0.07c0.027-0.047,0.041-0.119,0.041-0.214
  c0-0.062-0.004-0.111-0.01-0.151c-0.005-0.041-0.014-0.072-0.027-0.096s-0.03-0.04-0.054-0.049
  c-0.021-0.011-0.049-0.016-0.081-0.016c-0.044,0-0.076,0.012-0.1,0.036c-0.021,0.024-0.037,0.057-0.042,0.099h-0.267l0.053-0.798
  h0.765v0.232H42.61z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M43.303-24.348c0-0.065,0.007-0.126,0.02-0.183
  c0.014-0.057,0.033-0.11,0.061-0.161s0.061-0.102,0.102-0.15c0.041-0.05,0.088-0.099,0.141-0.147l0.121-0.112
  c0.033-0.03,0.061-0.057,0.08-0.081c0.021-0.025,0.035-0.05,0.047-0.075c0.011-0.025,0.019-0.052,0.021-0.081
  c0.003-0.028,0.006-0.062,0.006-0.099c0-0.122-0.049-0.184-0.146-0.184c-0.037,0-0.067,0.009-0.089,0.023
  s-0.037,0.036-0.049,0.062c-0.012,0.027-0.02,0.058-0.023,0.093c-0.003,0.033-0.004,0.07-0.004,0.108h-0.287v-0.056
  c0-0.136,0.037-0.241,0.109-0.315c0.074-0.072,0.188-0.108,0.347-0.108c0.149,0,0.26,0.034,0.332,0.103
  c0.071,0.069,0.108,0.168,0.108,0.296c0,0.047-0.004,0.092-0.014,0.133c-0.008,0.041-0.021,0.079-0.039,0.116
  c-0.018,0.038-0.041,0.074-0.07,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.152c-0.044,0.04-0.079,0.079-0.104,0.117
  c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H43.303z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M44.6-25.554l-0.03,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.085
  c0.043-0.018,0.098-0.025,0.162-0.025c0.069,0,0.127,0.014,0.174,0.04c0.047,0.027,0.084,0.063,0.11,0.107
  s0.046,0.094,0.058,0.149c0.01,0.057,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.223
  c-0.013,0.065-0.037,0.123-0.072,0.171c-0.036,0.048-0.084,0.083-0.145,0.107s-0.138,0.036-0.232,0.036
  c-0.092,0-0.168-0.012-0.225-0.037c-0.057-0.024-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.062-0.132
  c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287c0,0.023,0.001,0.05,0.004,0.078c0.002,0.027,0.01,0.054,0.02,0.077
  c0.011,0.024,0.025,0.044,0.045,0.061c0.02,0.016,0.045,0.022,0.076,0.022c0.063,0,0.106-0.022,0.135-0.07
  c0.027-0.047,0.04-0.119,0.04-0.214c0-0.062-0.003-0.111-0.009-0.151c-0.006-0.041-0.015-0.072-0.027-0.096
  c-0.014-0.023-0.031-0.04-0.055-0.049c-0.021-0.011-0.049-0.016-0.082-0.016c-0.043,0-0.075,0.012-0.099,0.036
  c-0.022,0.024-0.036,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H44.6z"
        />
        <rect
          x="46.604"
          y="-26.327"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#97A0A4"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M47.519-25.828c0.075,0,0.14,0.012,0.191,0.038
  c0.053,0.025,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.068,0.142s0.021,0.107,0.021,0.162
  c0,0.076-0.012,0.144-0.034,0.199c-0.022,0.058-0.055,0.104-0.095,0.14c-0.041,0.035-0.091,0.063-0.147,0.081
  c-0.059,0.018-0.123,0.026-0.193,0.026h-0.16v0.59h-0.298v-1.479H47.519z M47.427-25.159c0.059,0,0.107-0.018,0.143-0.054
  c0.039-0.036,0.057-0.093,0.057-0.168c0-0.074-0.016-0.132-0.048-0.171c-0.032-0.037-0.085-0.058-0.16-0.058h-0.124v0.45H47.427z
  "
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          points="48.521,-25.829 48.731,-24.784 48.737,-24.784 48.95,-25.829
  49.392,-25.829 49.392,-24.348 49.118,-24.348 49.118,-25.53 49.114,-25.53 48.851,-24.348 48.618,-24.348 48.356,-25.53
  48.353,-25.53 48.353,-24.348 48.078,-24.348 48.078,-25.829 			"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M49.846-24.767c0,0.035,0.003,0.066,0.008,0.095s0.017,0.053,0.031,0.071
  s0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017c0.046,0,0.087-0.015,0.123-0.045
  c0.037-0.028,0.056-0.074,0.056-0.137c0-0.034-0.005-0.062-0.015-0.086c-0.008-0.024-0.023-0.046-0.043-0.066
  c-0.021-0.019-0.049-0.035-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048c-0.066-0.022-0.125-0.046-0.172-0.073
  c-0.049-0.025-0.09-0.056-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122c-0.016-0.046-0.023-0.1-0.023-0.161
  c0-0.146,0.041-0.255,0.122-0.327c0.082-0.071,0.194-0.107,0.337-0.107c0.066,0,0.127,0.007,0.184,0.021
  c0.056,0.016,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.097,0.124c0.022,0.051,0.033,0.111,0.033,0.182v0.041h-0.285
  c0-0.069-0.014-0.124-0.037-0.162c-0.025-0.037-0.067-0.057-0.125-0.057c-0.032,0-0.061,0.005-0.082,0.016
  c-0.022,0.009-0.041,0.021-0.055,0.036c-0.013,0.017-0.021,0.035-0.025,0.056c-0.006,0.021-0.008,0.042-0.008,0.064
  c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099c0.058,0.025,0.104,0.052,0.14,0.078
  c0.036,0.027,0.063,0.057,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.103c0.007,0.038,0.013,0.079,0.013,0.125
  c0,0.156-0.047,0.27-0.137,0.341s-0.216,0.107-0.378,0.107c-0.169,0-0.29-0.037-0.362-0.11s-0.107-0.179-0.107-0.315v-0.061
  h0.298V-24.767z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M51.591-25.816h0.309v0.932h0.141v0.231h-0.141v0.305h-0.273v-0.305h-0.522
  v-0.245L51.591-25.816z M51.622-25.443l-0.286,0.559h0.29v-0.559H51.622z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M52.106-24.348c0-0.065,0.007-0.126,0.02-0.183
  c0.014-0.057,0.033-0.11,0.061-0.161c0.027-0.052,0.061-0.102,0.102-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.12-0.111
  c0.033-0.03,0.061-0.057,0.08-0.081c0.021-0.025,0.035-0.05,0.047-0.075c0.011-0.025,0.019-0.052,0.021-0.081
  c0.004-0.029,0.006-0.062,0.006-0.099c0-0.122-0.049-0.184-0.146-0.184c-0.037,0-0.067,0.009-0.088,0.023
  c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.02,0.058-0.023,0.093c-0.003,0.033-0.004,0.07-0.004,0.108h-0.287v-0.056
  c0-0.136,0.037-0.241,0.109-0.315c0.074-0.072,0.188-0.108,0.347-0.108c0.149,0,0.26,0.034,0.332,0.103
  c0.071,0.069,0.108,0.168,0.108,0.296c0,0.047-0.004,0.092-0.014,0.133c-0.008,0.04-0.021,0.079-0.039,0.116
  c-0.018,0.038-0.041,0.074-0.07,0.109c-0.028,0.035-0.064,0.072-0.106,0.108l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
  c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H52.106z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M53.444-24.561c0.02,0.032,0.055,0.047,0.105,0.047
  c0.039,0,0.071-0.009,0.096-0.028c0.022-0.019,0.041-0.044,0.054-0.078c0.013-0.032,0.022-0.071,0.026-0.117
  s0.006-0.096,0.006-0.149v-0.07h-0.004c-0.025,0.037-0.061,0.067-0.106,0.09c-0.047,0.023-0.103,0.034-0.169,0.034
  c-0.064,0-0.12-0.011-0.166-0.034c-0.046-0.022-0.083-0.055-0.111-0.096c-0.027-0.04-0.049-0.089-0.062-0.144
  c-0.015-0.058-0.021-0.118-0.021-0.185c0-0.17,0.038-0.3,0.113-0.39c0.075-0.089,0.196-0.134,0.361-0.134
  c0.103,0,0.184,0.018,0.244,0.057c0.062,0.038,0.108,0.088,0.141,0.151c0.031,0.063,0.054,0.136,0.064,0.219
  c0.008,0.082,0.014,0.169,0.014,0.259c0,0.105-0.004,0.206-0.01,0.303c-0.006,0.099-0.024,0.185-0.057,0.26
  c-0.029,0.073-0.08,0.133-0.146,0.176c-0.067,0.044-0.161,0.066-0.28,0.066c-0.054,0-0.104-0.006-0.153-0.017
  c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.068-0.066-0.091-0.114s-0.034-0.107-0.036-0.177h0.287
  C53.415-24.635,53.425-24.592,53.444-24.561 M53.429-25.118c0.025,0.043,0.066,0.064,0.125,0.064s0.102-0.021,0.125-0.064
  c0.025-0.042,0.039-0.113,0.039-0.213c0-0.042-0.003-0.08-0.007-0.116c-0.004-0.035-0.012-0.066-0.024-0.092
  c-0.012-0.025-0.027-0.046-0.049-0.061c-0.021-0.014-0.05-0.022-0.084-0.022c-0.037,0-0.064,0.009-0.086,0.022
  c-0.021,0.015-0.037,0.035-0.05,0.061c-0.011,0.027-0.019,0.058-0.021,0.094c-0.004,0.034-0.006,0.072-0.006,0.114
  C53.392-25.231,53.404-25.16,53.429-25.118"
        />
        <rect
          x="56.402"
          y="-26.327"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#5087C7"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M57.316-25.828c0.076,0,0.14,0.012,0.192,0.038
  c0.052,0.025,0.094,0.059,0.125,0.101c0.031,0.041,0.055,0.088,0.068,0.142s0.021,0.107,0.021,0.162
  c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.058-0.055,0.104-0.095,0.14c-0.041,0.035-0.091,0.063-0.147,0.081
  c-0.059,0.018-0.123,0.026-0.193,0.026h-0.16v0.59h-0.299v-1.479H57.316z M57.226-25.159c0.059,0,0.107-0.019,0.145-0.054
  c0.037-0.036,0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.049-0.171c-0.031-0.037-0.084-0.058-0.159-0.058h-0.124v0.45H57.226
  z"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          points="58.319,-25.829 58.53,-24.784 58.536,-24.784 58.749,-25.829
  59.19,-25.829 59.19,-24.348 58.917,-24.348 58.917,-25.53 58.913,-25.53 58.649,-24.348 58.417,-24.348 58.155,-25.53
  58.15,-25.53 58.15,-24.348 57.876,-24.348 57.876,-25.829 			"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M59.644-24.767c0,0.035,0.004,0.066,0.009,0.095s0.016,0.053,0.03,0.071
  c0.016,0.019,0.037,0.035,0.062,0.045c0.026,0.012,0.058,0.017,0.097,0.017c0.045,0,0.086-0.015,0.123-0.045
  c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.004-0.062-0.014-0.086c-0.008-0.024-0.023-0.046-0.044-0.066
  c-0.021-0.019-0.048-0.035-0.081-0.053c-0.033-0.015-0.076-0.031-0.125-0.048c-0.066-0.022-0.125-0.046-0.173-0.073
  c-0.049-0.025-0.089-0.056-0.121-0.092c-0.032-0.035-0.057-0.076-0.071-0.122c-0.016-0.046-0.023-0.1-0.023-0.161
  c0-0.146,0.041-0.255,0.122-0.327c0.082-0.071,0.194-0.107,0.336-0.107c0.067,0,0.128,0.007,0.185,0.021
  c0.055,0.016,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
  c0-0.069-0.013-0.124-0.037-0.162c-0.024-0.037-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.015
  s-0.04,0.022-0.054,0.037c-0.014,0.017-0.021,0.035-0.025,0.056c-0.006,0.021-0.008,0.042-0.008,0.064
  c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099c0.057,0.025,0.104,0.052,0.139,0.078
  c0.037,0.027,0.064,0.057,0.087,0.087c0.021,0.031,0.036,0.066,0.045,0.103c0.007,0.038,0.012,0.079,0.012,0.125
  c0,0.156-0.046,0.27-0.136,0.341s-0.217,0.107-0.378,0.107c-0.169,0-0.29-0.037-0.362-0.11s-0.107-0.179-0.107-0.315v-0.061
  h0.297V-24.767z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M60.91-24.348c0-0.065,0.007-0.126,0.021-0.183
  c0.013-0.057,0.033-0.11,0.06-0.161c0.026-0.052,0.06-0.102,0.103-0.15c0.039-0.05,0.088-0.099,0.141-0.148l0.121-0.111
  c0.033-0.03,0.06-0.057,0.08-0.081c0.02-0.025,0.035-0.05,0.046-0.075s0.019-0.052,0.022-0.081
  c0.002-0.029,0.004-0.062,0.004-0.099c0-0.122-0.047-0.184-0.145-0.184c-0.037,0-0.066,0.009-0.088,0.023
  s-0.038,0.036-0.051,0.062c-0.01,0.027-0.019,0.058-0.022,0.093c-0.003,0.033-0.004,0.07-0.004,0.108H60.91v-0.056
  c0-0.136,0.037-0.241,0.11-0.315c0.072-0.072,0.188-0.108,0.346-0.108c0.15,0,0.26,0.034,0.332,0.103
  c0.072,0.069,0.107,0.168,0.107,0.296c0,0.047-0.004,0.092-0.012,0.133c-0.009,0.04-0.021,0.079-0.039,0.116
  c-0.019,0.038-0.041,0.074-0.072,0.109c-0.027,0.035-0.063,0.072-0.105,0.108l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
  c-0.023,0.039-0.041,0.071-0.047,0.099h0.554v0.232H60.91z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M62.817-25.558c-0.061,0.086-0.116,0.178-0.163,0.274
  c-0.047,0.1-0.089,0.2-0.126,0.303c-0.035,0.104-0.064,0.211-0.089,0.316c-0.022,0.108-0.041,0.213-0.054,0.316h-0.313
  c0.013-0.088,0.028-0.182,0.052-0.278c0.021-0.098,0.051-0.197,0.088-0.298c0.035-0.102,0.08-0.202,0.133-0.305
  s0.115-0.202,0.186-0.301h-0.617v-0.257h0.904V-25.558z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M63.239-25.384h-0.291v-0.195h0.026c0.101,0,0.177-0.018,0.235-0.056
  c0.057-0.038,0.094-0.098,0.109-0.18h0.219v1.467h-0.299V-25.384z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M63.948-25c0.042-0.067,0.104-0.108,0.188-0.125v-0.004
  c-0.041-0.01-0.074-0.025-0.102-0.046c-0.025-0.021-0.048-0.044-0.064-0.07c-0.017-0.027-0.028-0.054-0.035-0.083
  c-0.008-0.029-0.01-0.057-0.01-0.083c0-0.144,0.037-0.247,0.114-0.31c0.076-0.063,0.181-0.095,0.316-0.095
  c0.134,0,0.239,0.031,0.315,0.095c0.076,0.063,0.115,0.166,0.115,0.31c0,0.026-0.004,0.054-0.012,0.083s-0.02,0.056-0.036,0.083
  c-0.017,0.026-0.038,0.049-0.065,0.07c-0.026,0.021-0.06,0.036-0.099,0.046v0.004c0.083,0.017,0.146,0.058,0.187,0.125
  c0.041,0.066,0.063,0.145,0.063,0.238c0,0.043-0.005,0.09-0.016,0.14c-0.01,0.052-0.032,0.099-0.065,0.144
  c-0.032,0.045-0.08,0.08-0.142,0.11c-0.062,0.028-0.144,0.043-0.245,0.043c-0.103,0-0.185-0.015-0.246-0.043
  c-0.062-0.03-0.108-0.065-0.142-0.11s-0.056-0.092-0.065-0.144c-0.011-0.05-0.017-0.097-0.017-0.14
  C63.886-24.856,63.907-24.934,63.948-25 M64.192-24.668c0.005,0.031,0.014,0.058,0.027,0.081
  c0.013,0.022,0.031,0.041,0.053,0.054c0.021,0.014,0.05,0.02,0.083,0.02c0.032,0,0.06-0.006,0.083-0.02
  c0.021-0.013,0.039-0.031,0.052-0.054c0.013-0.023,0.022-0.05,0.026-0.081c0.006-0.031,0.009-0.064,0.009-0.099
  c0-0.035-0.003-0.069-0.009-0.1c-0.004-0.03-0.014-0.057-0.026-0.08c-0.013-0.022-0.03-0.04-0.052-0.053
  c-0.023-0.014-0.051-0.02-0.083-0.02c-0.033,0-0.062,0.006-0.083,0.02c-0.021,0.013-0.04,0.03-0.053,0.053
  c-0.014,0.023-0.022,0.05-0.027,0.08s-0.008,0.064-0.008,0.1C64.185-24.733,64.188-24.7,64.192-24.668 M64.249-25.264
  c0.026,0.032,0.061,0.05,0.106,0.05c0.044,0,0.079-0.018,0.104-0.05c0.027-0.033,0.041-0.085,0.041-0.155
  c0-0.135-0.049-0.201-0.146-0.201s-0.146,0.066-0.146,0.201C64.21-25.349,64.224-25.297,64.249-25.264"
        />
        <rect
          x="66.271"
          y="-26.327"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          width="8.812"
          height="8.812"
        />

        <rect
          x="66.271"
          y="-26.327"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.813"
          height="8.814"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          points="66.861,-25.828 67.025,-24.734 67.029,-24.734 67.203,-25.828 67.489,-25.828
  67.663,-24.734 67.668,-24.734 67.831,-25.828 68.117,-25.828 67.849,-24.349 67.515,-24.349 67.349,-25.421 67.345,-25.421
  67.179,-24.349 66.845,-24.349 66.575,-25.828 			"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          points="68.536,-25.828 68.536,-25.262 68.884,-25.262 68.884,-25.828 69.183,-25.828
  69.183,-24.349 68.884,-24.349 68.884,-25.004 68.536,-25.004 68.536,-24.349 68.238,-24.349 68.238,-25.828 			"
        />
        <rect
          x="69.404"
          y="-25.828"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          width="0.298"
          height="1.479"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          points="70.791,-25.583 70.468,-25.583 70.468,-24.348 70.169,-24.348 70.169,-25.583
  69.846,-25.583 69.846,-25.828 70.791,-25.828 			"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          points="71.746,-25.583 71.218,-25.583 71.218,-25.235 71.715,-25.235 71.715,-24.991
  71.218,-24.991 71.218,-24.592 71.767,-24.592 71.767,-24.348 70.919,-24.348 70.919,-25.828 71.746,-25.828 			"
        />
        <rect
          x="6.879"
          y="-26.43"
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#A1233D"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M7.793-25.931c0.076,0,0.141,0.011,0.192,0.037s0.095,0.061,0.125,0.102
  c0.032,0.042,0.056,0.088,0.069,0.142c0.013,0.053,0.02,0.107,0.02,0.163c0,0.076-0.012,0.142-0.033,0.198
  c-0.023,0.057-0.056,0.104-0.096,0.139c-0.041,0.036-0.091,0.064-0.148,0.082c-0.058,0.017-0.123,0.026-0.192,0.026h-0.16v0.591
  H7.271v-1.479H7.793z M7.703-25.262c0.059,0,0.106-0.019,0.144-0.054c0.036-0.036,0.055-0.092,0.055-0.168
  c0-0.075-0.017-0.132-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058H7.569v0.449H7.703z"
        />
        <polygon
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          points="8.797,-25.931 9.008,-24.886 9.013,-24.886 9.226,-25.931
  9.668,-25.931 9.668,-24.453 9.394,-24.453 9.394,-25.633 9.39,-25.633 9.127,-24.453 8.894,-24.453 8.631,-25.633
  8.627,-25.633 8.627,-24.453 8.354,-24.453 8.354,-25.931 			"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M10.121-24.871c0,0.034,0.003,0.065,0.008,0.095
  c0.006,0.028,0.016,0.052,0.031,0.071s0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.017,0.098,0.017
  c0.045,0,0.086-0.014,0.123-0.045c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086
  c-0.008-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.047-0.036-0.082-0.052c-0.032-0.016-0.074-0.032-0.125-0.049
  c-0.065-0.021-0.123-0.046-0.172-0.073c-0.049-0.025-0.088-0.056-0.121-0.091c-0.032-0.036-0.057-0.077-0.071-0.123
  s-0.022-0.1-0.022-0.16c0-0.146,0.041-0.257,0.121-0.328c0.082-0.071,0.194-0.107,0.336-0.107c0.067,0,0.129,0.008,0.185,0.021
  c0.056,0.017,0.104,0.039,0.144,0.072c0.043,0.032,0.073,0.073,0.096,0.124c0.023,0.051,0.035,0.11,0.035,0.181v0.042h-0.287
  c0-0.071-0.013-0.125-0.037-0.163s-0.066-0.057-0.123-0.057c-0.033,0-0.062,0.005-0.084,0.015
  c-0.022,0.009-0.039,0.022-0.053,0.037c-0.014,0.018-0.021,0.035-0.026,0.056c-0.005,0.021-0.007,0.041-0.007,0.064
  c0,0.046,0.01,0.084,0.029,0.115c0.018,0.031,0.059,0.06,0.123,0.086l0.23,0.1c0.057,0.024,0.103,0.051,0.139,0.078
  c0.036,0.026,0.064,0.055,0.086,0.086c0.021,0.03,0.036,0.065,0.045,0.104c0.008,0.037,0.012,0.078,0.012,0.124
  c0,0.155-0.045,0.27-0.135,0.341c-0.091,0.071-0.217,0.105-0.379,0.105c-0.169,0-0.29-0.035-0.361-0.107
  c-0.072-0.074-0.108-0.18-0.108-0.316v-0.06h0.298V-24.871z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M11.387-24.452c0-0.065,0.008-0.125,0.021-0.182
  c0.013-0.058,0.032-0.111,0.06-0.162c0.027-0.052,0.061-0.102,0.103-0.15c0.04-0.05,0.088-0.099,0.141-0.148l0.121-0.112
  c0.033-0.029,0.06-0.056,0.08-0.08c0.021-0.025,0.035-0.05,0.046-0.074c0.012-0.026,0.02-0.052,0.021-0.082
  c0.004-0.029,0.006-0.062,0.006-0.099c0-0.122-0.049-0.184-0.145-0.184c-0.037,0-0.068,0.008-0.089,0.023
  c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.026-0.02,0.057-0.023,0.092c-0.002,0.034-0.004,0.071-0.004,0.109h-0.287v-0.056
  c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.108,0.347-0.108c0.149,0,0.26,0.033,0.332,0.103
  c0.071,0.069,0.107,0.168,0.107,0.297c0,0.046-0.004,0.091-0.014,0.131c-0.008,0.04-0.021,0.081-0.039,0.118
  c-0.018,0.037-0.041,0.073-0.07,0.108c-0.027,0.035-0.064,0.072-0.105,0.108l-0.166,0.154c-0.043,0.039-0.078,0.078-0.104,0.116
  c-0.023,0.039-0.041,0.071-0.047,0.098h0.553v0.233H11.387z"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M12.393-25.429c0.006-0.096,0.025-0.179,0.057-0.251
  c0.029-0.071,0.076-0.13,0.137-0.174c0.063-0.043,0.146-0.065,0.256-0.065c0.09,0,0.164,0.013,0.223,0.039
  c0.06,0.026,0.106,0.067,0.142,0.123c0.035,0.057,0.06,0.13,0.075,0.22c0.014,0.091,0.021,0.201,0.021,0.331
  c0,0.103-0.004,0.2-0.011,0.295c-0.007,0.093-0.026,0.177-0.056,0.25c-0.03,0.071-0.077,0.129-0.139,0.171
  c-0.061,0.041-0.146,0.062-0.256,0.062c-0.088,0-0.162-0.012-0.221-0.035c-0.061-0.024-0.107-0.063-0.143-0.119
  c-0.035-0.057-0.061-0.131-0.074-0.222c-0.014-0.092-0.022-0.201-0.022-0.33C12.382-25.238,12.385-25.336,12.393-25.429
   M12.688-24.844c0.006,0.059,0.014,0.104,0.026,0.139s0.028,0.057,0.05,0.069c0.021,0.013,0.047,0.018,0.078,0.018
  c0.033,0,0.062-0.007,0.081-0.022c0.021-0.016,0.037-0.043,0.05-0.081c0.01-0.038,0.02-0.088,0.024-0.153
  c0.004-0.063,0.007-0.142,0.007-0.235v-0.158c0-0.095-0.003-0.169-0.007-0.229c-0.005-0.058-0.015-0.104-0.026-0.139
  c-0.013-0.033-0.029-0.058-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.007-0.08,0.024
  c-0.021,0.016-0.037,0.042-0.049,0.08s-0.02,0.088-0.025,0.152c-0.004,0.063-0.008,0.143-0.008,0.235v0.16
  C12.68-24.978,12.684-24.904,12.688-24.844"
        />
        <path
          clipPath="url(#CAMPAIGNING_SVGID_16_)"
          fill="#FFFFFF"
          d="M13.717-25.488h-0.292v-0.195h0.026c0.101,0,0.178-0.018,0.235-0.055
  c0.058-0.038,0.095-0.099,0.109-0.182h0.219v1.468h-0.298V-25.488z"
        />
      </g>
    </g>
  );
};

const BePrepared = () => {
  return (
    <g id="Be_Prepared">
      <defs>
        <rect
          id="BEPREPARED_SVGID_17_"
          x="0.002"
          y="0"
          width="61.687"
          height="61.687"
        />
      </defs>
      <clipPath id="BEPREPARED_SVGID_18_">
        <use xlinkHref="#BEPREPARED_SVGID_17_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F78E1E"
        d="M55.5,61.07H6.174
c-3.06,0-5.564-2.504-5.564-5.563V6.181c0-3.06,2.504-5.564,5.564-5.564H55.5c3.06,0,5.563,2.505,5.563,5.564v49.327
C61.063,58.566,58.56,61.07,55.5,61.07"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        d="M16.023,9.223c0.369,0.029,0.767,0.314,0.767,0.916
c0,0.659-0.446,1.117-1.314,1.117h-2.07V7.273h2.063c0.732,0,1.208,0.434,1.208,1.088c0,0.547-0.387,0.809-0.654,0.838V9.223z
 M14.387,8.919h0.857c0.291,0,0.481-0.178,0.481-0.445c0-0.25-0.196-0.411-0.446-0.411h-0.892V8.919z M15.303,10.465
c0.315,0,0.488-0.173,0.488-0.445c0-0.263-0.191-0.429-0.47-0.429h-0.934v0.874H15.303z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        d="M20.277,10.007h-2.188c0.077,0.356,0.321,0.577,0.642,0.577
c0.22,0,0.477-0.054,0.637-0.345l0.844,0.172c-0.256,0.631-0.797,0.935-1.481,0.935c-0.862,0-1.575-0.638-1.575-1.582
c0-0.94,0.713-1.581,1.581-1.581c0.845,0,1.522,0.605,1.54,1.581V10.007z M18.107,9.425h1.231c-0.09-0.321-0.327-0.47-0.601-0.47
C18.47,8.956,18.196,9.122,18.107,9.425"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        d="M25.369,9.764c0,0.945-0.667,1.582-1.457,1.582
c-0.387,0-0.696-0.119-0.928-0.327v1.248h-0.951V8.271h0.951v0.238c0.232-0.208,0.541-0.327,0.928-0.327
C24.702,8.182,25.369,8.825,25.369,9.764 M24.435,9.764c0-0.458-0.304-0.773-0.72-0.773c-0.386,0-0.731,0.315-0.731,0.773
c0,0.464,0.345,0.772,0.731,0.772C24.131,10.537,24.435,10.228,24.435,9.764"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        d="M28.025,8.253l-0.041,0.951h-0.173c-0.666,0-1.028,0.345-1.028,1.118v0.934
h-0.951V8.272h0.951v0.57c0.214-0.356,0.54-0.612,1.028-0.612C27.894,8.23,27.953,8.236,28.025,8.253"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        d="M31.318,10.007H29.13c0.077,0.356,0.322,0.577,0.643,0.577
c0.22,0,0.476-0.054,0.636-0.345l0.844,0.172c-0.256,0.631-0.797,0.935-1.48,0.935c-0.863,0-1.576-0.638-1.576-1.582
c0-0.94,0.713-1.581,1.582-1.581c0.844,0,1.521,0.605,1.539,1.581V10.007z M29.148,9.425h1.231c-0.089-0.321-0.327-0.47-0.6-0.47
C29.511,8.956,29.237,9.122,29.148,9.425"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        d="M35.114,9.764c0,0.945-0.666,1.582-1.457,1.582
c-0.386,0-0.696-0.119-0.927-0.327v1.248h-0.952V8.271h0.952v0.238c0.23-0.208,0.541-0.327,0.927-0.327
C34.448,8.182,35.114,8.825,35.114,9.764 M34.181,9.764c0-0.458-0.303-0.773-0.72-0.773c-0.387,0-0.73,0.315-0.73,0.773
c0,0.464,0.344,0.772,0.73,0.772C33.878,10.537,34.181,10.228,34.181,9.764"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        d="M38.732,11.257h-0.951v-0.238c-0.231,0.208-0.541,0.327-0.927,0.327
c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.386,0,0.695,0.119,0.927,0.327V8.271h0.951V11.257z
 M37.781,9.764c0-0.458-0.345-0.773-0.731-0.773c-0.416,0-0.719,0.315-0.719,0.773c0,0.464,0.303,0.772,0.719,0.772
C37.437,10.537,37.781,10.228,37.781,9.764"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        d="M41.563,8.253l-0.041,0.951h-0.172c-0.666,0-1.028,0.345-1.028,1.118v0.934
H39.37V8.272h0.952v0.57c0.214-0.356,0.54-0.612,1.028-0.612C41.433,8.23,41.492,8.236,41.563,8.253"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        d="M44.858,10.007H42.67c0.077,0.356,0.321,0.577,0.642,0.577
c0.221,0,0.476-0.054,0.637-0.345l0.845,0.172c-0.256,0.631-0.797,0.935-1.481,0.935c-0.861,0-1.576-0.638-1.576-1.582
c0-0.94,0.715-1.581,1.582-1.581c0.846,0,1.522,0.605,1.541,1.581V10.007z M42.688,9.425h1.23c-0.09-0.321-0.327-0.47-0.602-0.47
C43.051,8.956,42.776,9.122,42.688,9.425"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        d="M48.474,11.257h-0.951v-0.238c-0.232,0.208-0.541,0.327-0.928,0.327
c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.387,0,0.695,0.119,0.928,0.327V6.975h0.951V11.257z
 M47.522,9.764c0-0.458-0.346-0.773-0.732-0.773c-0.416,0-0.719,0.315-0.719,0.773c0,0.464,0.303,0.772,0.719,0.772
C47.177,10.537,47.522,10.228,47.522,9.764"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#D76427"
        points="11.979,42.852 27.574,58.445 58.986,60.129 59.396,36.168
40.177,16.948 		"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#D76427"
        points="15.176,37.604 22.494,44.92 37.326,61.416 37.736,39.223
18.516,20.003 14.767,23.011 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#213466"
        d="M36.927,42.912H20.913c-0.615,0-1.113-0.498-1.113-1.111V30.861
c0-0.615,0.498-1.113,1.113-1.113h16.014c0.615,0,1.112,0.498,1.112,1.113v10.939C38.039,42.414,37.542,42.912,36.927,42.912"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#213466"
        d="M48.532,42.912H32.519c-0.615,0-1.113-0.498-1.113-1.111V30.861
c0-0.615,0.498-1.113,1.113-1.113h16.014c0.615,0,1.112,0.498,1.112,1.113v10.939C49.645,42.414,49.147,42.912,48.532,42.912"
      />

      <line
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="none"
        stroke="#5087C7"
        strokeWidth="0.659"
        strokeMiterlimit="10"
        x1="19.8"
        y1="32.602"
        x2="49.604"
        y2="32.602"
      />

      <circle
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="none"
        stroke="#213466"
        strokeWidth="1.036"
        strokeMiterlimit="10"
        cx="34.729"
        cy="15.849"
        r="2.758"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#5087C7"
        d="M42.837,44.918H26.402c-1.737,0-3.145-1.408-3.145-3.146v-15.9
c0-6.08,4.929-11.009,11.009-11.009h0.705c6.082,0,11.01,4.929,11.01,11.009v15.9C45.981,43.51,44.573,44.918,42.837,44.918"
      />

      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="none"
        stroke="#213466"
        strokeWidth="0.42"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="
M24.726,25.873c0-5.262,4.28-9.541,9.541-9.541h0.705c5.262,0,9.541,4.279,9.541,9.541"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#213466"
        d="M42.627,42.766H26.613c-0.615,0-1.113-0.498-1.113-1.111V30.715
c0-0.615,0.498-1.113,1.113-1.113h16.014c0.614,0,1.112,0.498,1.112,1.113v10.939C43.739,42.268,43.241,42.766,42.627,42.766"
      />

      <line
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="none"
        stroke="#5087C7"
        strokeWidth="0.518"
        strokeMiterlimit="10"
        x1="25.228"
        y1="32.455"
        x2="44.011"
        y2="32.455"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M27.907,35.02L27.907,35.02c-0.215,0-0.389-0.174-0.389-0.389v-1.166
c0-0.215,0.174-0.389,0.389-0.389c0.215,0,0.389,0.174,0.389,0.389v1.166C28.296,34.846,28.122,35.02,27.907,35.02"
      />

      <rect
        x="32.202"
        y="21.773"
        transform="matrix(0.7072 0.707 -0.707 0.7072 27.24 -17.3936)"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#213466"
        width="4.837"
        height="4.837"
      />

      <line
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="none"
        stroke="#5087C7"
        strokeWidth="0.518"
        strokeMiterlimit="10"
        x1="33.972"
        y1="23.328"
        x2="33.972"
        y2="25.14"
      />

      <line
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="none"
        stroke="#5087C7"
        strokeWidth="0.518"
        strokeMiterlimit="10"
        x1="35.267"
        y1="23.328"
        x2="35.267"
        y2="25.14"
      />
      <rect
        x="12.139"
        y="25.117"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#ED1C24"
        width="6.606"
        height="17.606"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M18.745,25.386h-6.606V20.54c0-0.406,0.328-0.734,0.734-0.734h5.137
c0.406,0,0.735,0.328,0.735,0.734V25.386z"
      />

      <circle
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="1.036"
        strokeMiterlimit="10"
        cx="11.577"
        cy="22.603"
        r="1.231"
      />
      <rect
        x="12.139"
        y="25.586"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        width="6.606"
        height="1.461"
      />
      <rect
        x="12.139"
        y="28.801"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        width="6.606"
        height="1.461"
      />
      <rect
        x="12.139"
        y="32.016"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        width="6.606"
        height="1.461"
      />
      <rect
        x="16.172"
        y="26.318"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        width="1.461"
        height="16.405"
      />
      <rect
        x="12.808"
        y="26.318"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        width="1.461"
        height="16.405"
      />
      <rect
        x="12.139"
        y="35.23"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        width="6.606"
        height="1.459"
      />
      <rect
        x="12.139"
        y="38.443"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        width="6.606"
        height="1.461"
      />
      <rect
        x="12.139"
        y="41.557"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        width="6.606"
        height="1.166"
      />

      <line
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="none"
        stroke="#97A0A4"
        strokeWidth="0.42"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="18.745"
        y1="25.487"
        x2="12.139"
        y2="25.487"
      />

      <line
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="none"
        stroke="#97A0A4"
        strokeWidth="0.42"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="18.745"
        y1="42.723"
        x2="12.139"
        y2="42.723"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FBB683"
        d="M56.011,4.407c0.689,0,1.271,0.582,1.271,1.271v50.333
c0,0.688-0.581,1.27-1.271,1.27H5.678c-0.689,0-1.271-0.582-1.271-1.27V5.678c0-0.689,0.582-1.271,1.271-1.271H56.011 M56.011,0
H5.678C2.556,0,0.001,2.556,0.001,5.678v50.333c0,3.121,2.555,5.676,5.677,5.676h50.333c3.123,0,5.678-2.555,5.678-5.676V5.678
C61.688,2.556,59.134,0,56.011,0"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#5087C7"
        d="M30.855,55.361c-2.638,0-4.773-2.137-4.773-4.771
c0-2.637,2.135-4.773,4.773-4.773c2.638,0,4.774,2.137,4.774,4.773C35.63,53.225,33.493,55.361,30.855,55.361"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M30.855,45.816c2.638,0,4.774,2.137,4.774,4.773
c0,2.635-2.137,4.771-4.774,4.771c-2.638,0-4.773-2.137-4.773-4.771C26.083,47.953,28.217,45.816,30.855,45.816 M30.855,45.404
c-2.859,0-5.185,2.326-5.185,5.186c0,2.857,2.326,5.184,5.185,5.184c2.86,0,5.187-2.326,5.187-5.184
C36.042,47.73,33.716,45.404,30.855,45.404"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M33.35,48.645c-0.239,0-0.465,0.107-0.62,0.25l-0.013-0.012
c0.071-0.119,0.168-0.346,0.168-0.717c0-0.883-0.885-1.551-2.029-1.551c-1.145,0-2.041,0.668-2.041,1.551
c0,0.371,0.12,0.598,0.18,0.717l-0.013,0.012c-0.155-0.143-0.381-0.25-0.62-0.25c-0.728,0-1.456,0.703-1.456,2.016
c0,1.289,0.716,2.018,1.492,2.018c0.716,0,1.217-0.395,1.67-0.848c-0.071-0.107-0.179-0.217-0.298-0.217
c-0.119,0-0.251,0.086-0.477,0.266c-0.298,0.227-0.561,0.357-0.908,0.357c-0.608,0-0.87-0.682-0.87-1.576
c0-0.955,0.251-1.586,0.896-1.586c0.286,0,0.476,0.082,0.667,0.264c0.286,0.262,0.382,0.332,0.513,0.332
c0.096,0,0.156-0.061,0.179-0.096c-0.214-0.215-0.525-0.668-0.525-1.252c0-0.668,0.56-1.133,1.611-1.133
c1.05,0,1.611,0.465,1.611,1.133c0,0.584-0.311,1.037-0.525,1.252c0.024,0.035,0.084,0.096,0.179,0.096
c0.132,0,0.228-0.07,0.513-0.332c0.18-0.182,0.383-0.264,0.668-0.264c0.645,0,0.896,0.631,0.896,1.586
c0,0.895-0.264,1.576-0.871,1.576c-0.345,0-0.62-0.131-0.907-0.357c-0.226-0.18-0.357-0.266-0.477-0.266s-0.226,0.109-0.299,0.217
c0.442,0.453,0.956,0.848,1.671,0.848c0.775,0,1.492-0.729,1.492-2.018C34.806,49.348,34.077,48.645,33.35,48.645"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M31.655,48.621h-0.537c-0.036,0-0.059-0.025-0.071-0.049l-0.168-0.512
c-0.012-0.035-0.024-0.035-0.036,0l-0.167,0.512c-0.012,0.037-0.036,0.049-0.06,0.049h-0.537c-0.024,0-0.035,0.01-0.012,0.023
l0.442,0.322c0.024,0.023,0.024,0.059,0.012,0.082l-0.167,0.502c-0.011,0.023-0.011,0.049,0.024,0.023l0.442-0.309
c0.035-0.025,0.059-0.025,0.095,0l0.429,0.309c0.024,0.025,0.036,0.012,0.024-0.012l-0.167-0.514c-0.012-0.033,0-0.07,0.024-0.082
l0.441-0.322C31.69,48.631,31.678,48.621,31.655,48.621"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M31.25,52.152c-0.227-0.572-0.406-0.967-0.406-1.443
c0-0.205,0.06-0.383,0.096-0.477c0.047-0.098,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.166-0.322,0.311
c-0.072,0.117-0.155,0.346-0.155,0.523c0,0.336,0.108,0.693,0.179,0.908c0.132,0.371,0.251,0.799,0.251,1.205
c0,0.645-0.287,1.086-0.8,1.479c0.048,0.037,0.167,0.096,0.311,0.096c0.5,0,1.097-0.561,1.097-1.324
C31.512,52.82,31.38,52.51,31.25,52.152"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M-12.117-25.967c0.076,0,0.14,0.012,0.192,0.037
c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.143c0.013,0.054,0.02,0.107,0.02,0.163
c0,0.075-0.011,0.143-0.034,0.198c-0.023,0.058-0.055,0.104-0.095,0.14c-0.041,0.036-0.091,0.063-0.148,0.081
c-0.058,0.017-0.123,0.026-0.193,0.026h-0.16v0.59h-0.298v-1.479H-12.117z M-12.208-25.298c0.059,0,0.107-0.018,0.144-0.054
s0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.048-0.17c-0.032-0.039-0.085-0.059-0.16-0.059h-0.124v0.45H-12.208z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        points="-11.114,-25.968 -10.903,-24.923 -10.898,-24.923 -10.686,-25.968
-10.244,-25.968 -10.244,-24.489 -10.518,-24.489 -10.518,-25.669 -10.521,-25.669 -10.784,-24.489 -11.018,-24.489
-11.279,-25.669 -11.283,-25.669 -11.283,-24.489 -11.558,-24.489 -11.558,-25.968 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M-9.79-24.907c0,0.034,0.003,0.066,0.008,0.094
c0.005,0.028,0.016,0.052,0.031,0.072c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
c0.045,0,0.086-0.014,0.123-0.044c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
c-0.008-0.025-0.024-0.047-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.122-0.327c0.082-0.071,0.194-0.107,0.336-0.107
c0.067,0,0.128,0.007,0.184,0.021c0.056,0.016,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124
c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.038-0.066-0.057-0.123-0.057
c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.01-0.04,0.023-0.054,0.038c-0.013,0.017-0.021,0.035-0.026,0.056
s-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
c0.057,0.025,0.103,0.052,0.139,0.078c0.036,0.027,0.064,0.057,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.103
c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341s-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.907z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M-7.611-25.699c-0.061,0.086-0.116,0.178-0.163,0.276
c-0.047,0.098-0.089,0.199-0.126,0.302c-0.035,0.104-0.065,0.21-0.089,0.317c-0.023,0.106-0.041,0.212-0.053,0.314h-0.314
c0.012-0.088,0.028-0.181,0.051-0.277c0.021-0.099,0.051-0.197,0.088-0.298c0.036-0.102,0.081-0.202,0.133-0.305
c0.053-0.103,0.115-0.203,0.186-0.3h-0.617v-0.257h0.904V-25.699z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M-7.189-25.525h-0.292v-0.194h0.027c0.1,0,0.177-0.019,0.235-0.056
c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.298V-25.525z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M-6.209-24.721c0.021,0.045,0.064,0.067,0.13,0.067
c0.035,0,0.064-0.007,0.085-0.019c0.022-0.013,0.038-0.029,0.05-0.051s0.019-0.048,0.023-0.075
c0.003-0.029,0.005-0.061,0.005-0.091c0-0.034-0.002-0.065-0.007-0.096c-0.005-0.028-0.015-0.056-0.028-0.079
c-0.014-0.023-0.033-0.043-0.058-0.057c-0.025-0.013-0.059-0.021-0.1-0.021h-0.099v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.02
c0.021-0.013,0.039-0.029,0.052-0.052c0.013-0.021,0.022-0.046,0.029-0.074c0.006-0.026,0.009-0.056,0.009-0.087
c0-0.069-0.012-0.118-0.035-0.148C-6-25.747-6.035-25.76-6.084-25.76c-0.032,0-0.058,0.006-0.077,0.017
c-0.021,0.012-0.037,0.029-0.047,0.05c-0.011,0.022-0.019,0.047-0.022,0.076s-0.005,0.062-0.005,0.095h-0.273
c0-0.146,0.036-0.255,0.109-0.325c0.073-0.071,0.18-0.107,0.322-0.107c0.133,0,0.237,0.03,0.311,0.092
c0.074,0.061,0.111,0.155,0.111,0.285c0,0.089-0.02,0.161-0.059,0.217c-0.039,0.057-0.094,0.091-0.164,0.107v0.004
c0.094,0.015,0.162,0.053,0.201,0.114c0.04,0.062,0.06,0.139,0.06,0.231c0,0.049-0.007,0.102-0.019,0.153
c-0.013,0.053-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.078-0.149,0.104c-0.062,0.026-0.146,0.039-0.248,0.039
c-0.142,0-0.248-0.038-0.319-0.116c-0.071-0.077-0.105-0.188-0.105-0.329v-0.007h0.286C-6.242-24.832-6.231-24.766-6.209-24.721"
      />
      <rect
        x="26.745"
        y="-26.465"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#A1233D"
        width="8.813"
        height="8.812"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M27.659-25.967c0.076,0,0.14,0.012,0.192,0.037
c0.052,0.026,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.142c0.013,0.054,0.02,0.107,0.02,0.163
c0,0.075-0.012,0.143-0.034,0.198c-0.023,0.058-0.055,0.104-0.095,0.14c-0.041,0.036-0.091,0.063-0.148,0.081
c-0.058,0.017-0.123,0.026-0.193,0.026h-0.16v0.59h-0.298v-1.479H27.659z M27.568-25.298c0.059,0,0.107-0.018,0.144-0.054
s0.055-0.093,0.055-0.168c0-0.074-0.016-0.132-0.048-0.17c-0.032-0.038-0.085-0.059-0.16-0.059h-0.124v0.45H27.568z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        points="28.662,-25.968 28.873,-24.923 28.878,-24.923 29.091,-25.968
29.533,-25.968 29.533,-24.488 29.259,-24.488 29.259,-25.669 29.255,-25.669 28.992,-24.488 28.759,-24.488 28.497,-25.669
28.493,-25.669 28.493,-24.488 28.219,-24.488 28.219,-25.968 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M29.987-24.907c0,0.034,0.003,0.066,0.008,0.094
c0.005,0.028,0.016,0.054,0.031,0.072s0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
c0.045,0,0.086-0.014,0.123-0.044c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
c-0.008-0.025-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.122-0.327c0.082-0.071,0.194-0.107,0.336-0.107c0.067,0,0.128,0.007,0.184,0.021
c0.056,0.017,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
c0-0.07-0.013-0.124-0.037-0.162c-0.025-0.037-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
c-0.023,0.011-0.04,0.023-0.054,0.038c-0.013,0.017-0.021,0.035-0.026,0.056c-0.005,0.021-0.007,0.042-0.007,0.064
c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.052,0.139,0.078
c0.036,0.027,0.064,0.057,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.103c0.007,0.038,0.012,0.079,0.012,0.125
c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.907z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M31.253-24.488c0-0.064,0.007-0.126,0.02-0.183s0.033-0.11,0.06-0.161
c0.027-0.052,0.06-0.102,0.102-0.151c0.04-0.049,0.088-0.098,0.141-0.146l0.121-0.113c0.033-0.029,0.06-0.055,0.08-0.08
s0.035-0.05,0.046-0.075c0.011-0.024,0.019-0.052,0.022-0.081c0.003-0.028,0.005-0.062,0.005-0.099
c0-0.122-0.048-0.184-0.145-0.184c-0.037,0-0.067,0.009-0.088,0.023c-0.022,0.015-0.038,0.035-0.05,0.063
c-0.011,0.025-0.019,0.056-0.023,0.091c-0.003,0.035-0.004,0.071-0.004,0.109h-0.287v-0.056c0-0.136,0.037-0.241,0.11-0.314
c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103c0.072,0.069,0.107,0.168,0.107,0.296
c0,0.048-0.004,0.092-0.012,0.133c-0.009,0.04-0.021,0.08-0.039,0.116c-0.019,0.038-0.041,0.074-0.072,0.109
c-0.027,0.035-0.063,0.072-0.105,0.109l-0.165,0.152c-0.044,0.04-0.079,0.079-0.104,0.117c-0.024,0.039-0.041,0.071-0.047,0.099
h0.553v0.232H31.253z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M32.259-25.466c0.007-0.094,0.024-0.177,0.056-0.25
c0.03-0.072,0.076-0.13,0.138-0.174c0.062-0.043,0.147-0.065,0.256-0.065c0.09,0,0.164,0.013,0.223,0.039
c0.06,0.025,0.106,0.066,0.142,0.123s0.06,0.13,0.074,0.22c0.015,0.092,0.022,0.201,0.022,0.331c0,0.103-0.004,0.2-0.011,0.294
c-0.008,0.095-0.026,0.178-0.057,0.25c-0.029,0.072-0.076,0.13-0.138,0.171c-0.062,0.042-0.147,0.063-0.256,0.063
c-0.088,0-0.162-0.011-0.222-0.035s-0.106-0.063-0.142-0.12c-0.036-0.056-0.062-0.13-0.075-0.221s-0.021-0.201-0.021-0.33
C32.248-25.275,32.251-25.373,32.259-25.466 M32.554-24.88c0.005,0.059,0.013,0.104,0.026,0.139
c0.012,0.033,0.027,0.057,0.05,0.069c0.021,0.013,0.046,0.018,0.078,0.018s0.062-0.007,0.081-0.022
c0.02-0.017,0.037-0.043,0.049-0.081s0.02-0.089,0.025-0.153c0.004-0.063,0.007-0.142,0.007-0.236v-0.158
c0-0.094-0.003-0.168-0.007-0.228c-0.006-0.059-0.014-0.104-0.026-0.139s-0.03-0.057-0.05-0.069
c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.007-0.08,0.023s-0.038,0.043-0.049,0.081
c-0.013,0.037-0.021,0.088-0.025,0.152c-0.005,0.063-0.008,0.142-0.008,0.236v0.16C32.546-25.015,32.549-24.939,32.554-24.88"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M33.582-25.524h-0.291v-0.195h0.026c0.101,0,0.177-0.019,0.235-0.056
c0.057-0.038,0.094-0.097,0.109-0.18h0.218v1.467h-0.298V-25.524z"
      />
      <rect
        x="47.226"
        y="-26.465"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#213466"
        width="8.812"
        height="8.812"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M48.139-25.967c0.076,0,0.14,0.012,0.192,0.037
c0.052,0.026,0.094,0.06,0.125,0.102c0.031,0.041,0.055,0.088,0.068,0.142s0.021,0.107,0.021,0.163
c0,0.075-0.013,0.143-0.034,0.198c-0.023,0.058-0.055,0.104-0.095,0.14c-0.041,0.036-0.091,0.063-0.147,0.081
c-0.059,0.017-0.123,0.026-0.193,0.026h-0.16v0.591h-0.299v-1.479H48.139z M48.048-25.298c0.059,0,0.107-0.018,0.145-0.054
s0.055-0.093,0.055-0.168c0-0.074-0.016-0.131-0.049-0.17c-0.031-0.038-0.084-0.059-0.159-0.059h-0.124v0.45H48.048z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        points="49.142,-25.968 49.353,-24.922 49.358,-24.922 49.571,-25.968
50.013,-25.968 50.013,-24.488 49.739,-24.488 49.739,-25.669 49.735,-25.669 49.472,-24.488 49.239,-24.488 48.978,-25.669
48.973,-25.669 48.973,-24.488 48.698,-24.488 48.698,-25.968 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M50.466-24.907c0,0.034,0.004,0.066,0.009,0.094
c0.005,0.028,0.017,0.054,0.03,0.072c0.016,0.019,0.037,0.035,0.062,0.045c0.026,0.012,0.058,0.017,0.097,0.017
c0.045,0,0.086-0.015,0.123-0.045c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.004-0.062-0.014-0.086
c-0.008-0.025-0.023-0.046-0.043-0.066c-0.021-0.019-0.049-0.036-0.082-0.053c-0.033-0.015-0.076-0.031-0.125-0.048
c-0.066-0.021-0.125-0.046-0.173-0.073c-0.049-0.025-0.089-0.057-0.12-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.122-0.327c0.082-0.071,0.194-0.107,0.336-0.107
c0.067,0,0.128,0.007,0.185,0.021c0.056,0.017,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124
c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.124-0.038-0.162c-0.023-0.037-0.066-0.057-0.123-0.057
c-0.033,0-0.061,0.005-0.082,0.014c-0.023,0.011-0.041,0.023-0.055,0.038c-0.014,0.017-0.021,0.035-0.025,0.056
c-0.006,0.021-0.008,0.042-0.008,0.064c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.1
c0.058,0.024,0.104,0.051,0.139,0.077c0.037,0.027,0.064,0.057,0.087,0.087c0.021,0.03,0.036,0.065,0.045,0.103
c0.007,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341s-0.217,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
s-0.107-0.179-0.107-0.315v-0.061h0.297V-24.907z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M51.732-24.488c0-0.064,0.007-0.126,0.021-0.183
c0.013-0.057,0.033-0.11,0.06-0.161c0.026-0.052,0.06-0.102,0.103-0.151c0.039-0.049,0.088-0.098,0.141-0.146l0.121-0.113
c0.033-0.029,0.06-0.055,0.08-0.08c0.02-0.025,0.035-0.05,0.046-0.075c0.011-0.024,0.019-0.052,0.022-0.081
c0.002-0.028,0.004-0.062,0.004-0.099c0-0.122-0.047-0.183-0.145-0.183c-0.037,0-0.066,0.008-0.088,0.022
s-0.038,0.035-0.051,0.063c-0.01,0.025-0.019,0.056-0.022,0.091c-0.003,0.035-0.004,0.071-0.004,0.109h-0.287v-0.056
c0-0.136,0.037-0.241,0.11-0.314c0.072-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
c0.072,0.069,0.107,0.168,0.107,0.296c0,0.048-0.004,0.092-0.012,0.133c-0.009,0.04-0.021,0.08-0.039,0.116
c-0.019,0.038-0.041,0.074-0.072,0.109c-0.027,0.035-0.063,0.072-0.105,0.109l-0.165,0.152c-0.044,0.04-0.079,0.079-0.104,0.117
c-0.023,0.039-0.041,0.071-0.047,0.099h0.554v0.232H51.732z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M53.64-25.698c-0.061,0.086-0.115,0.177-0.163,0.274
c-0.047,0.1-0.089,0.2-0.126,0.303c-0.035,0.104-0.064,0.211-0.089,0.316c-0.022,0.107-0.041,0.212-0.054,0.316h-0.313
c0.013-0.088,0.028-0.182,0.052-0.279c0.021-0.097,0.051-0.196,0.088-0.297c0.035-0.102,0.08-0.202,0.133-0.305
c0.053-0.104,0.115-0.203,0.186-0.301h-0.617v-0.257h0.904V-25.698z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M54.201-25.956h0.31v0.931h0.141v0.232h-0.141v0.305h-0.274v-0.305h-0.522
v-0.245L54.201-25.956z M54.232-25.583l-0.286,0.558h0.29v-0.558H54.232z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M55.63-25.698c-0.062,0.086-0.117,0.177-0.164,0.274
c-0.047,0.1-0.089,0.2-0.125,0.303c-0.035,0.104-0.065,0.211-0.09,0.316c-0.023,0.107-0.041,0.212-0.053,0.316h-0.314
c0.012-0.088,0.028-0.182,0.051-0.279c0.021-0.097,0.052-0.196,0.089-0.297c0.036-0.102,0.081-0.202,0.133-0.305
c0.053-0.104,0.114-0.203,0.187-0.301h-0.617v-0.257h0.904V-25.698z"
      />
      <rect
        x="36.985"
        y="-26.465"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#97A0A4"
        width="8.813"
        height="8.812"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M37.899-25.967c0.076,0,0.14,0.012,0.191,0.037
c0.053,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.142c0.013,0.054,0.021,0.107,0.021,0.163
c0,0.075-0.013,0.143-0.035,0.198c-0.022,0.058-0.055,0.104-0.094,0.14c-0.041,0.036-0.092,0.063-0.148,0.081
c-0.059,0.017-0.123,0.026-0.193,0.026h-0.16v0.591h-0.298v-1.479H37.899z M37.808-25.298c0.06,0,0.107-0.018,0.145-0.054
s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.059-0.159-0.059h-0.125v0.45H37.808z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        points="38.902,-25.968 39.113,-24.922 39.118,-24.922 39.331,-25.968
39.772,-25.968 39.772,-24.488 39.499,-24.488 39.499,-25.669 39.495,-25.669 39.231,-24.488 38.999,-24.488 38.737,-25.669
38.733,-25.669 38.733,-24.488 38.459,-24.488 38.459,-25.968 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M40.227-24.907c0,0.034,0.003,0.066,0.009,0.094
c0.004,0.028,0.016,0.054,0.03,0.072s0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.097,0.017
c0.045,0,0.086-0.015,0.123-0.045c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
c-0.009-0.025-0.024-0.046-0.044-0.066c-0.021-0.019-0.049-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
c-0.066-0.021-0.124-0.046-0.172-0.073c-0.049-0.025-0.09-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.123-0.326c0.082-0.072,0.193-0.108,0.336-0.108
c0.066,0,0.127,0.007,0.184,0.021c0.057,0.017,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.096,0.124
c0.023,0.051,0.033,0.111,0.033,0.182v0.041h-0.285c0-0.07-0.014-0.124-0.037-0.161c-0.025-0.038-0.067-0.058-0.124-0.058
c-0.033,0-0.062,0.005-0.083,0.014c-0.023,0.011-0.04,0.023-0.055,0.038c-0.013,0.017-0.021,0.035-0.025,0.056
s-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.028,0.115c0.02,0.031,0.061,0.059,0.125,0.086l0.229,0.1
c0.058,0.024,0.103,0.051,0.14,0.077c0.035,0.027,0.063,0.057,0.086,0.087c0.021,0.03,0.035,0.065,0.045,0.103
c0.006,0.038,0.01,0.079,0.01,0.125c0,0.156-0.045,0.27-0.135,0.341s-0.215,0.106-0.377,0.106c-0.17,0-0.291-0.036-0.363-0.109
c-0.071-0.073-0.107-0.179-0.107-0.315v-0.061h0.298V-24.907z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M41.972-25.956h0.309v0.931h0.142v0.232H42.28v0.305h-0.273v-0.305h-0.521
v-0.245L41.972-25.956z M42.003-25.583l-0.286,0.558h0.29v-0.558H42.003z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M42.487-24.488c0-0.064,0.008-0.126,0.02-0.183
c0.014-0.057,0.033-0.11,0.061-0.161c0.027-0.052,0.061-0.102,0.102-0.151c0.041-0.049,0.088-0.098,0.142-0.146l0.121-0.113
c0.032-0.029,0.06-0.055,0.08-0.08c0.02-0.024,0.034-0.05,0.046-0.075c0.011-0.024,0.02-0.052,0.021-0.081
c0.004-0.028,0.006-0.062,0.006-0.099c0-0.122-0.049-0.183-0.146-0.183c-0.037,0-0.067,0.008-0.088,0.022
c-0.022,0.016-0.038,0.035-0.05,0.063c-0.012,0.025-0.02,0.056-0.023,0.091c-0.003,0.035-0.004,0.071-0.004,0.109h-0.287v-0.056
c0-0.136,0.037-0.241,0.11-0.314s0.188-0.109,0.346-0.109c0.149,0,0.261,0.034,0.332,0.103c0.072,0.069,0.108,0.168,0.108,0.296
c0,0.048-0.004,0.092-0.014,0.133c-0.008,0.04-0.021,0.08-0.039,0.117c-0.018,0.037-0.041,0.073-0.07,0.108
c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.152c-0.044,0.04-0.078,0.079-0.104,0.117c-0.024,0.039-0.041,0.071-0.047,0.099
h0.553v0.232H42.487z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M43.825-24.701c0.02,0.032,0.055,0.047,0.105,0.047
c0.039,0,0.071-0.009,0.096-0.028c0.023-0.019,0.041-0.045,0.054-0.077c0.013-0.033,0.022-0.072,0.026-0.118
c0.005-0.045,0.007-0.096,0.007-0.149v-0.07h-0.005c-0.025,0.037-0.061,0.066-0.106,0.091c-0.047,0.021-0.103,0.033-0.169,0.033
c-0.064,0-0.119-0.012-0.166-0.033c-0.046-0.024-0.083-0.056-0.111-0.097c-0.027-0.041-0.049-0.089-0.062-0.145
c-0.015-0.057-0.021-0.117-0.021-0.184c0-0.17,0.039-0.3,0.113-0.39c0.075-0.09,0.196-0.134,0.362-0.134
c0.103,0,0.183,0.018,0.244,0.057c0.062,0.038,0.108,0.088,0.14,0.151c0.032,0.063,0.055,0.136,0.064,0.219
c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.004,0.206-0.009,0.304c-0.007,0.098-0.025,0.184-0.058,0.259
c-0.029,0.074-0.08,0.133-0.146,0.176c-0.067,0.044-0.162,0.065-0.281,0.065c-0.053,0-0.104-0.005-0.153-0.016
c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.106-0.037-0.177h0.287
C43.796-24.775,43.806-24.732,43.825-24.701 M43.81-25.256c0.025,0.042,0.066,0.063,0.125,0.063s0.102-0.021,0.125-0.063
c0.025-0.042,0.039-0.115,0.039-0.214c0-0.042-0.002-0.08-0.006-0.116c-0.004-0.035-0.013-0.066-0.024-0.093
c-0.013-0.024-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.064,0.009-0.086,0.022
c-0.021,0.015-0.037,0.035-0.049,0.061c-0.012,0.026-0.019,0.058-0.021,0.093c-0.004,0.035-0.005,0.073-0.005,0.115
C43.773-25.372,43.785-25.298,43.81-25.256"
      />
      <rect
        x="6.704"
        y="-26.465"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#D76427"
        width="8.812"
        height="8.812"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M7.618-25.967c0.076,0,0.14,0.012,0.191,0.037
c0.053,0.027,0.095,0.06,0.126,0.102c0.032,0.041,0.055,0.088,0.069,0.142c0.013,0.054,0.02,0.107,0.02,0.163
c0,0.075-0.011,0.143-0.034,0.198c-0.023,0.058-0.055,0.104-0.096,0.14c-0.04,0.036-0.09,0.063-0.147,0.081
c-0.058,0.017-0.123,0.026-0.194,0.026H7.394v0.591H7.096v-1.479H7.618z M7.527-25.298c0.059,0,0.107-0.018,0.144-0.054
c0.036-0.036,0.054-0.092,0.054-0.168c0-0.074-0.015-0.131-0.047-0.17c-0.032-0.038-0.085-0.059-0.16-0.059H7.394v0.45H7.527z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        points="8.621,-25.968 8.832,-24.922 8.837,-24.922 9.05,-25.968
9.491,-25.968 9.491,-24.488 9.218,-24.488 9.218,-25.669 9.214,-25.669 8.951,-24.488 8.718,-24.488 8.455,-25.669
8.451,-25.669 8.451,-24.488 8.178,-24.488 8.178,-25.968 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M9.946-24.907c0,0.034,0.003,0.066,0.008,0.094
c0.005,0.028,0.016,0.054,0.031,0.072c0.015,0.019,0.036,0.035,0.061,0.045c0.025,0.012,0.058,0.017,0.096,0.017
c0.046,0,0.087-0.015,0.124-0.045c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
c-0.009-0.025-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.126-0.048
c-0.065-0.021-0.123-0.046-0.172-0.073c-0.048-0.025-0.088-0.057-0.12-0.092c-0.034-0.035-0.057-0.076-0.072-0.122
c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.122-0.326c0.082-0.072,0.193-0.108,0.336-0.108
c0.067,0,0.128,0.007,0.183,0.021c0.057,0.017,0.104,0.039,0.145,0.071c0.042,0.033,0.073,0.074,0.096,0.124
c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.124-0.037-0.161c-0.025-0.038-0.067-0.058-0.125-0.058
c-0.033,0-0.06,0.005-0.083,0.014c-0.022,0.011-0.039,0.023-0.053,0.038c-0.013,0.017-0.022,0.035-0.027,0.056
c-0.004,0.021-0.007,0.042-0.007,0.064c0,0.045,0.011,0.083,0.03,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.1
c0.057,0.024,0.103,0.051,0.138,0.077c0.037,0.027,0.065,0.057,0.086,0.087c0.022,0.03,0.037,0.065,0.045,0.103
c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.27-0.135,0.341s-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.907z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M11.552-25.524H11.26v-0.195h0.027c0.099,0,0.177-0.019,0.234-0.056
c0.058-0.038,0.095-0.097,0.11-0.18h0.219v1.467h-0.298V-25.524z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M12.509-25.694l-0.031,0.334h0.004c0.028-0.04,0.063-0.067,0.108-0.085
c0.044-0.018,0.098-0.025,0.162-0.025c0.07,0,0.129,0.014,0.175,0.04c0.046,0.027,0.083,0.063,0.109,0.107
c0.027,0.044,0.047,0.094,0.058,0.149c0.01,0.057,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.223
c-0.013,0.065-0.038,0.123-0.073,0.171c-0.035,0.048-0.084,0.083-0.144,0.107c-0.061,0.024-0.138,0.035-0.232,0.035
c-0.093,0-0.168-0.011-0.225-0.036c-0.058-0.024-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.061-0.132
c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287c0,0.023,0.001,0.05,0.004,0.078c0.002,0.027,0.009,0.054,0.019,0.078
c0.011,0.023,0.025,0.044,0.045,0.06s0.045,0.022,0.076,0.022c0.063,0,0.107-0.022,0.134-0.07
c0.028-0.047,0.041-0.119,0.041-0.214c0-0.062-0.003-0.111-0.009-0.151c-0.005-0.041-0.015-0.072-0.028-0.096
c-0.013-0.024-0.03-0.04-0.053-0.051c-0.022-0.009-0.049-0.014-0.082-0.014c-0.043,0-0.076,0.012-0.099,0.036
s-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H12.509z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M13.201-24.488c0-0.064,0.007-0.126,0.02-0.183s0.033-0.11,0.06-0.161
c0.027-0.052,0.06-0.102,0.101-0.151c0.041-0.049,0.088-0.098,0.142-0.146l0.121-0.113c0.033-0.029,0.06-0.055,0.079-0.08
c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.024,0.019-0.052,0.022-0.081c0.003-0.028,0.005-0.062,0.005-0.099
c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.022c-0.022,0.015-0.038,0.035-0.05,0.063
c-0.011,0.025-0.02,0.056-0.023,0.091s-0.004,0.071-0.004,0.109h-0.287v-0.056c0-0.136,0.036-0.241,0.11-0.314
c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103c0.071,0.069,0.108,0.168,0.108,0.296
c0,0.048-0.004,0.092-0.013,0.133c-0.009,0.04-0.021,0.08-0.039,0.116c-0.018,0.038-0.041,0.074-0.071,0.109
c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.152c-0.044,0.04-0.079,0.079-0.104,0.117c-0.024,0.039-0.041,0.071-0.048,0.099
h0.554v0.232H13.201z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M14.499-25.694l-0.031,0.334h0.004c0.028-0.04,0.063-0.067,0.108-0.085
c0.044-0.018,0.098-0.025,0.162-0.025c0.07,0,0.129,0.014,0.175,0.04c0.046,0.027,0.083,0.063,0.109,0.107
c0.027,0.044,0.047,0.094,0.058,0.149c0.01,0.057,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.223
c-0.013,0.065-0.038,0.123-0.073,0.171c-0.035,0.048-0.084,0.083-0.144,0.107c-0.061,0.024-0.138,0.035-0.232,0.035
c-0.093,0-0.168-0.011-0.225-0.036c-0.058-0.024-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.061-0.132
c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287c0,0.023,0.001,0.05,0.004,0.078c0.002,0.027,0.009,0.054,0.019,0.078
c0.011,0.023,0.025,0.044,0.045,0.06s0.045,0.022,0.077,0.022c0.062,0,0.106-0.022,0.133-0.07
c0.028-0.047,0.041-0.119,0.041-0.214c0-0.062-0.003-0.111-0.009-0.151c-0.005-0.041-0.015-0.072-0.028-0.096
c-0.013-0.024-0.03-0.04-0.053-0.051c-0.022-0.009-0.049-0.014-0.082-0.014c-0.043,0-0.076,0.012-0.099,0.036
c-0.023,0.024-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H14.499z"
      />
      <rect
        x="-3.193"
        y="-26.465"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#F78E1E"
        width="8.812"
        height="8.812"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M-2.279-25.967c0.076,0,0.14,0.012,0.191,0.037
c0.053,0.026,0.095,0.06,0.126,0.101c0.032,0.042,0.055,0.089,0.069,0.143c0.013,0.054,0.02,0.107,0.02,0.163
c0,0.075-0.012,0.143-0.034,0.198c-0.023,0.058-0.055,0.104-0.096,0.14c-0.04,0.036-0.09,0.063-0.147,0.081
c-0.058,0.017-0.123,0.026-0.194,0.026h-0.159v0.591h-0.298v-1.479H-2.279z M-2.37-25.298c0.059,0,0.107-0.018,0.144-0.054
c0.036-0.036,0.054-0.093,0.054-0.168c0-0.074-0.015-0.131-0.047-0.17c-0.032-0.039-0.085-0.059-0.16-0.059h-0.124v0.45H-2.37z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        points="-1.276,-25.968 -1.065,-24.922 -1.06,-24.922 -0.847,-25.968
-0.406,-25.968 -0.406,-24.489 -0.679,-24.489 -0.679,-25.669 -0.683,-25.669 -0.946,-24.489 -1.179,-24.489 -1.442,-25.669
-1.446,-25.669 -1.446,-24.489 -1.719,-24.489 -1.719,-25.968 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M0.048-24.907c0,0.034,0.003,0.066,0.008,0.094
c0.005,0.028,0.016,0.053,0.031,0.072c0.015,0.019,0.036,0.035,0.061,0.045c0.025,0.012,0.058,0.017,0.096,0.017
c0.046,0,0.087-0.015,0.124-0.045c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
c-0.009-0.025-0.024-0.047-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.126-0.048
c-0.065-0.021-0.123-0.046-0.172-0.073c-0.048-0.025-0.088-0.057-0.12-0.092c-0.034-0.035-0.057-0.076-0.072-0.122
s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.122-0.327c0.082-0.071,0.193-0.107,0.336-0.107c0.067,0,0.128,0.007,0.183,0.021
c0.057,0.016,0.104,0.039,0.145,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.022,0.051,0.034,0.111,0.034,0.182v0.041H0.4
c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057c-0.033,0-0.06,0.004-0.083,0.014
c-0.022,0.01-0.039,0.023-0.053,0.038c-0.013,0.017-0.022,0.035-0.027,0.056c-0.004,0.021-0.007,0.042-0.007,0.064
c0,0.045,0.011,0.083,0.03,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.1c0.057,0.024,0.103,0.051,0.138,0.077
C0.627-25.2,0.655-25.17,0.676-25.14c0.022,0.03,0.037,0.065,0.045,0.103C0.729-25,0.733-24.958,0.733-24.913
c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.907z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M1.654-25.525H1.362v-0.194H1.39c0.099,0,0.177-0.019,0.234-0.056
c0.058-0.037,0.095-0.098,0.11-0.181h0.219v1.467H1.654V-25.525z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M2.787-25.957h0.309v0.932h0.142v0.231H3.096v0.306H2.823v-0.306H2.301
v-0.244L2.787-25.957z M2.819-25.583l-0.287,0.558h0.291v-0.558H2.819z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M3.782-25.957h0.309v0.932h0.142v0.231H4.091v0.306H3.818v-0.306H3.296
v-0.244L3.782-25.957z M3.814-25.583l-0.287,0.558h0.291v-0.558H3.814z"
      />
      <rect
        x="57.362"
        y="-26.465"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#5087C7"
        width="8.813"
        height="8.812"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M58.276-25.967c0.076,0,0.141,0.012,0.193,0.037
c0.051,0.026,0.094,0.06,0.125,0.101c0.031,0.042,0.055,0.089,0.068,0.143c0.013,0.054,0.02,0.107,0.02,0.163
c0,0.075-0.012,0.143-0.033,0.198c-0.023,0.058-0.055,0.104-0.096,0.14s-0.091,0.063-0.147,0.081
c-0.058,0.017-0.123,0.026-0.193,0.026h-0.159v0.591h-0.299v-1.479H58.276z M58.187-25.298c0.059,0,0.106-0.018,0.144-0.054
s0.055-0.093,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.031-0.039-0.085-0.059-0.158-0.059h-0.125v0.45H58.187z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        points="59.28,-25.968 59.491,-24.922 59.496,-24.922 59.709,-25.968
60.151,-25.968 60.151,-24.489 59.877,-24.489 59.877,-25.669 59.873,-25.669 59.61,-24.489 59.377,-24.489 59.114,-25.669
59.11,-25.669 59.11,-24.489 58.837,-24.489 58.837,-25.968 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M60.604-24.907c0,0.034,0.003,0.066,0.008,0.094
c0.006,0.028,0.016,0.053,0.031,0.072c0.016,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.017,0.098,0.017
c0.045,0,0.086-0.015,0.123-0.045c0.035-0.028,0.056-0.074,0.056-0.137c0-0.034-0.006-0.062-0.015-0.086
c-0.008-0.025-0.023-0.047-0.045-0.066c-0.021-0.019-0.047-0.036-0.081-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
c-0.066-0.021-0.124-0.046-0.173-0.073c-0.047-0.025-0.088-0.057-0.121-0.092c-0.032-0.035-0.057-0.076-0.071-0.122
s-0.022-0.1-0.022-0.161c0-0.146,0.041-0.256,0.121-0.327c0.082-0.071,0.194-0.107,0.336-0.107c0.067,0,0.129,0.007,0.185,0.021
c0.056,0.016,0.104,0.039,0.146,0.071c0.041,0.033,0.071,0.074,0.094,0.124c0.023,0.051,0.035,0.111,0.035,0.182v0.041h-0.287
c0-0.07-0.012-0.125-0.037-0.162c-0.023-0.038-0.065-0.057-0.123-0.057c-0.033,0-0.062,0.004-0.084,0.014
s-0.039,0.023-0.053,0.038c-0.014,0.017-0.021,0.035-0.026,0.056s-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115
c0.019,0.031,0.06,0.059,0.123,0.086l0.23,0.1c0.057,0.024,0.104,0.051,0.139,0.077c0.036,0.027,0.064,0.057,0.086,0.087
c0.022,0.03,0.036,0.065,0.045,0.103c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.27-0.135,0.341
c-0.091,0.071-0.217,0.106-0.379,0.106c-0.169,0-0.289-0.036-0.361-0.109s-0.108-0.179-0.108-0.315v-0.061h0.298V-24.907z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M61.87-24.488c0-0.065,0.008-0.125,0.021-0.183
c0.013-0.057,0.033-0.11,0.06-0.162c0.027-0.051,0.061-0.101,0.103-0.149c0.041-0.051,0.088-0.1,0.142-0.148l0.12-0.112
c0.033-0.028,0.06-0.056,0.08-0.081c0.021-0.024,0.035-0.05,0.046-0.074c0.012-0.025,0.02-0.051,0.021-0.081
c0.004-0.029,0.006-0.063,0.006-0.1c0-0.122-0.049-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
c-0.023,0.015-0.039,0.035-0.051,0.062c-0.011,0.027-0.02,0.057-0.023,0.092c-0.002,0.034-0.004,0.071-0.004,0.11H61.87v-0.056
c0-0.138,0.037-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.347-0.109c0.149,0,0.26,0.034,0.332,0.104
c0.071,0.069,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.014,0.131c-0.008,0.041-0.021,0.081-0.038,0.117
c-0.019,0.038-0.041,0.074-0.071,0.108c-0.027,0.035-0.064,0.072-0.105,0.109l-0.166,0.154c-0.043,0.04-0.078,0.079-0.104,0.116
c-0.023,0.039-0.041,0.07-0.047,0.099h0.553v0.232H61.87z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M63.778-25.699c-0.062,0.086-0.115,0.178-0.162,0.276
c-0.049,0.098-0.09,0.199-0.127,0.302c-0.035,0.104-0.065,0.21-0.088,0.317c-0.024,0.106-0.043,0.212-0.055,0.314h-0.314
c0.013-0.088,0.028-0.181,0.052-0.277c0.021-0.099,0.051-0.197,0.088-0.298c0.036-0.102,0.081-0.202,0.133-0.305
c0.054-0.103,0.115-0.203,0.187-0.3h-0.617v-0.257h0.904V-25.699z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M64.2-25.525h-0.292v-0.194h0.026c0.101,0,0.179-0.019,0.236-0.056
c0.057-0.037,0.094-0.098,0.108-0.181h0.219v1.467H64.2V-25.525z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M64.909-25.141c0.041-0.066,0.104-0.108,0.187-0.124v-0.004
c-0.04-0.01-0.073-0.025-0.101-0.046c-0.026-0.021-0.049-0.044-0.065-0.07s-0.028-0.054-0.035-0.083
c-0.007-0.03-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.114-0.31c0.076-0.063,0.183-0.095,0.316-0.095
c0.135,0,0.239,0.031,0.315,0.095c0.077,0.063,0.116,0.166,0.116,0.31c0,0.026-0.004,0.053-0.012,0.083
c-0.008,0.029-0.02,0.057-0.037,0.083c-0.016,0.026-0.037,0.049-0.064,0.07c-0.027,0.021-0.061,0.036-0.1,0.046v0.004
c0.084,0.016,0.146,0.058,0.188,0.124c0.041,0.067,0.063,0.146,0.063,0.239c0,0.043-0.006,0.09-0.016,0.141
c-0.011,0.051-0.031,0.098-0.066,0.144c-0.032,0.044-0.08,0.079-0.141,0.108c-0.063,0.029-0.144,0.044-0.246,0.044
c-0.102,0-0.184-0.015-0.246-0.044c-0.062-0.029-0.107-0.064-0.142-0.108c-0.033-0.046-0.054-0.093-0.065-0.144
c-0.011-0.051-0.016-0.098-0.016-0.141C64.847-24.996,64.868-25.074,64.909-25.141 M65.153-24.809
c0.004,0.032,0.014,0.059,0.026,0.082c0.013,0.021,0.031,0.041,0.054,0.054c0.021,0.014,0.049,0.02,0.082,0.02
s0.061-0.006,0.084-0.02c0.021-0.013,0.038-0.032,0.051-0.054c0.014-0.023,0.023-0.05,0.028-0.082
c0.005-0.03,0.007-0.063,0.007-0.098c0-0.035-0.002-0.069-0.007-0.1s-0.015-0.057-0.028-0.08
c-0.013-0.022-0.029-0.041-0.051-0.054c-0.023-0.013-0.051-0.019-0.084-0.019s-0.061,0.006-0.082,0.019
c-0.022,0.013-0.041,0.031-0.054,0.054c-0.013,0.023-0.022,0.05-0.026,0.08c-0.006,0.03-0.008,0.064-0.008,0.1
C65.146-24.873,65.147-24.839,65.153-24.809 M65.21-25.404c0.025,0.033,0.061,0.05,0.105,0.05s0.079-0.017,0.105-0.05
c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.201-0.146-0.201c-0.097,0-0.145,0.066-0.145,0.201
C65.171-25.489,65.184-25.437,65.21-25.404"
      />
      <rect
        x="67.378"
        y="-26.465"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        width="8.813"
        height="8.812"
      />

      <rect
        x="67.378"
        y="-26.466"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="none"
        stroke="#000000"
        strokeWidth="0.13"
        strokeMiterlimit="10"
        width="8.813"
        height="8.813"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        points="67.969,-25.967 68.133,-24.874 68.137,-24.874 68.312,-25.967 68.597,-25.967
68.771,-24.874 68.776,-24.874 68.938,-25.967 69.225,-25.967 68.956,-24.489 68.622,-24.489 68.456,-25.561 68.452,-25.561
68.286,-24.489 67.952,-24.489 67.683,-25.967 		"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        points="69.644,-25.967 69.644,-25.401 69.991,-25.401 69.991,-25.967 70.29,-25.967
70.29,-24.489 69.991,-24.489 69.991,-25.144 69.644,-25.144 69.644,-24.489 69.346,-24.489 69.346,-25.967 		"
      />
      <rect
        x="70.512"
        y="-25.967"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        width="0.298"
        height="1.479"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        points="71.898,-25.723 71.575,-25.723 71.575,-24.488 71.276,-24.488 71.276,-25.723
70.954,-25.723 70.954,-25.968 71.898,-25.968 		"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        points="72.854,-25.723 72.325,-25.723 72.325,-25.375 72.823,-25.375 72.823,-25.13
72.325,-25.13 72.325,-24.733 72.874,-24.733 72.874,-24.488 72.026,-24.488 72.026,-25.968 72.854,-25.968 		"
      />
      <rect
        x="16.682"
        y="-26.465"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#ED1C24"
        width="8.812"
        height="8.812"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M17.579-25.925c0.076,0,0.14,0.013,0.192,0.037
c0.052,0.026,0.094,0.061,0.125,0.102c0.032,0.042,0.055,0.088,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.138c-0.041,0.037-0.09,0.064-0.148,0.081
c-0.058,0.019-0.123,0.028-0.193,0.028h-0.16v0.59h-0.298v-1.479H17.579z M17.487-25.255c0.059,0,0.107-0.019,0.144-0.055
s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H17.487z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        points="18.582,-25.926 18.793,-24.88 18.798,-24.88 19.011,-25.926
19.452,-25.926 19.452,-24.446 19.179,-24.446 19.179,-25.627 19.175,-25.627 18.912,-24.446 18.679,-24.446 18.416,-25.627
18.412,-25.627 18.412,-24.446 18.138,-24.446 18.138,-25.926 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M19.906-24.864c0,0.033,0.003,0.065,0.009,0.094
c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.034,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.076,0.056-0.137c0-0.034-0.005-0.063-0.014-0.086
c-0.009-0.025-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
c-0.066-0.023-0.123-0.048-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.075-0.072-0.122
c-0.015-0.045-0.023-0.1-0.023-0.16c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
c0.066,0,0.127,0.008,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071s0.072,0.073,0.095,0.124s0.034,0.11,0.034,0.182v0.041
h-0.285c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
c-0.023,0.009-0.04,0.022-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
c0,0.045,0.01,0.083,0.029,0.114s0.061,0.06,0.124,0.086l0.23,0.1c0.057,0.024,0.103,0.051,0.139,0.078
c0.036,0.026,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.064,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
c0,0.156-0.046,0.27-0.136,0.342c-0.09,0.07-0.216,0.105-0.378,0.105c-0.168,0-0.289-0.036-0.362-0.109
c-0.072-0.073-0.108-0.179-0.108-0.315v-0.06h0.298V-24.864z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M21.513-25.482h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.055
c0.058-0.038,0.094-0.098,0.109-0.181h0.22v1.467h-0.298V-25.482z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M23.079-25.656c-0.061,0.085-0.115,0.178-0.162,0.275
c-0.048,0.099-0.09,0.199-0.126,0.303c-0.036,0.103-0.066,0.21-0.089,0.315c-0.024,0.108-0.042,0.213-0.054,0.316h-0.313
c0.011-0.088,0.027-0.182,0.05-0.278c0.022-0.098,0.052-0.197,0.088-0.298c0.036-0.102,0.081-0.202,0.133-0.304
c0.053-0.104,0.115-0.203,0.187-0.302h-0.618v-0.257h0.904V-25.656z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M23.505-24.659c0.019,0.031,0.054,0.047,0.104,0.047
c0.041,0,0.072-0.009,0.096-0.027c0.023-0.02,0.042-0.045,0.054-0.078c0.014-0.033,0.022-0.073,0.026-0.118
c0.005-0.045,0.007-0.096,0.007-0.148v-0.07h-0.005c-0.024,0.036-0.06,0.065-0.106,0.089c-0.046,0.022-0.103,0.034-0.169,0.034
c-0.065,0-0.12-0.012-0.165-0.034c-0.047-0.023-0.084-0.054-0.112-0.095c-0.028-0.041-0.049-0.09-0.062-0.146
c-0.013-0.056-0.019-0.117-0.019-0.183c0-0.17,0.037-0.3,0.112-0.391c0.076-0.09,0.196-0.135,0.362-0.135
c0.102,0,0.184,0.02,0.244,0.059c0.061,0.037,0.108,0.088,0.14,0.149c0.033,0.064,0.054,0.136,0.064,0.22
c0.009,0.082,0.014,0.17,0.014,0.26c0,0.104-0.003,0.205-0.009,0.303c-0.007,0.098-0.025,0.184-0.056,0.258
c-0.031,0.074-0.08,0.133-0.147,0.177c-0.067,0.043-0.161,0.065-0.281,0.065c-0.053,0-0.103-0.004-0.153-0.015
c-0.049-0.011-0.092-0.03-0.129-0.059s-0.067-0.065-0.09-0.114c-0.023-0.048-0.035-0.106-0.037-0.178h0.286
C23.475-24.732,23.484-24.689,23.505-24.659 M23.49-25.214c0.025,0.042,0.066,0.064,0.124,0.064c0.059,0,0.102-0.022,0.127-0.064
c0.025-0.043,0.037-0.115,0.037-0.213c0-0.042-0.002-0.081-0.006-0.117s-0.012-0.067-0.024-0.092
c-0.011-0.025-0.028-0.045-0.05-0.061c-0.021-0.015-0.049-0.021-0.084-0.021c-0.035,0-0.064,0.007-0.086,0.021
c-0.021,0.016-0.037,0.035-0.048,0.062c-0.011,0.026-0.019,0.057-0.022,0.092c-0.003,0.036-0.005,0.074-0.005,0.116
C23.453-25.33,23.464-25.257,23.49-25.214"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#FFFFFF"
        d="M24.459-25.652l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.085
c0.044-0.018,0.098-0.025,0.161-0.025c0.071,0,0.13,0.014,0.176,0.041c0.046,0.026,0.083,0.063,0.109,0.106
c0.027,0.045,0.046,0.094,0.057,0.15c0.011,0.056,0.017,0.112,0.017,0.17c0,0.082-0.007,0.156-0.021,0.224
c-0.013,0.064-0.038,0.122-0.073,0.171c-0.035,0.047-0.084,0.082-0.144,0.107c-0.061,0.023-0.138,0.035-0.232,0.035
c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.024-0.101-0.058-0.132-0.099c-0.03-0.04-0.051-0.085-0.061-0.132
c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.286c0,0.023,0.002,0.05,0.005,0.079c0.002,0.026,0.009,0.053,0.019,0.076
c0.011,0.024,0.025,0.045,0.045,0.061s0.045,0.023,0.076,0.023c0.063,0,0.107-0.023,0.134-0.07
c0.027-0.048,0.041-0.12,0.041-0.215c0-0.062-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
s-0.03-0.04-0.053-0.049c-0.022-0.011-0.049-0.016-0.082-0.016c-0.043,0-0.076,0.013-0.099,0.036
c-0.023,0.024-0.037,0.059-0.042,0.099h-0.268l0.054-0.798H25v0.232H24.459z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M12.901,75.904c0.592,0,0.973,0.391,0.973,0.992
c0,0.594-0.381,0.984-0.973,0.984c-0.588,0-0.969-0.391-0.969-0.984C11.932,76.295,12.313,75.904,12.901,75.904 M12.901,77.494
c0.352,0,0.457-0.301,0.457-0.598c0-0.303-0.105-0.604-0.457-0.604c-0.349,0-0.454,0.301-0.454,0.604
C12.447,77.193,12.552,77.494,12.901,77.494"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M14.859,75.955h0.378v0.346h-0.378v0.928c0,0.174,0.044,0.219,0.218,0.219
c0.054,0,0.105-0.004,0.16-0.016v0.404c-0.087,0.014-0.2,0.018-0.302,0.018c-0.315,0-0.591-0.074-0.591-0.447v-1.105h-0.312
v-0.346h0.312v-0.563h0.515V75.955z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M15.907,77.02c0.014,0.324,0.174,0.475,0.46,0.475
c0.208,0,0.374-0.127,0.407-0.244h0.454c-0.146,0.443-0.454,0.631-0.879,0.631c-0.591,0-0.957-0.404-0.957-0.984
c0-0.564,0.388-0.992,0.957-0.992c0.639,0,0.947,0.537,0.911,1.115H15.907z M16.745,76.691c-0.047-0.26-0.16-0.398-0.41-0.398
c-0.327,0-0.421,0.254-0.428,0.398H16.745z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M17.963,77.221c0.004,0.227,0.193,0.316,0.396,0.316
c0.149,0,0.337-0.057,0.337-0.24c0-0.156-0.214-0.209-0.584-0.289c-0.298-0.064-0.595-0.17-0.595-0.502
c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.139,0.828,0.598h-0.49c-0.015-0.199-0.167-0.254-0.352-0.254
c-0.116,0-0.287,0.021-0.287,0.176c0,0.186,0.29,0.209,0.584,0.279c0.302,0.068,0.596,0.178,0.596,0.525
c0,0.494-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.162-0.882-0.66H17.963z"
      />
      <rect
        x="9.106"
        y="80.434"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        width="1.815"
        height="0.291"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M12.21,78.996h0.89c0.798,0,1.244,0.398,1.244,1.234
c0,0.867-0.381,1.357-1.244,1.357h-0.89V78.996z M12.556,81.297h0.573c0.236,0,0.871-0.064,0.871-1.02
c0-0.615-0.228-0.99-0.864-0.99h-0.58V81.297z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M15.522,79.668c0.595,0,0.911,0.432,0.911,0.984
c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.612,80.1,14.928,79.668,15.522,79.668 M15.522,81.359
c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
C14.938,81.105,15.2,81.359,15.522,81.359"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M17.803,79.711h0.291v0.297H18.1c0.131-0.23,0.341-0.34,0.61-0.34
c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
h-0.309V79.711z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M20.629,79.668c0.595,0,0.911,0.432,0.911,0.984
c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.718,80.1,20.034,79.668,20.629,79.668 M20.629,81.359
c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
C20.045,81.105,20.306,81.359,20.629,81.359"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M22.331,79.711h0.374v0.273h-0.374v1.164c0,0.143,0.039,0.168,0.232,0.168
h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.273h0.319v-0.563h0.309V79.711z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M25.715,81.58c-0.055,0.033-0.123,0.051-0.222,0.051
c-0.16,0-0.261-0.088-0.261-0.291c-0.17,0.201-0.399,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.525
c0-0.424,0.316-0.516,0.635-0.578c0.341-0.064,0.632-0.043,0.632-0.275c0-0.268-0.222-0.313-0.418-0.313
c-0.261,0-0.453,0.082-0.468,0.355h-0.309c0.018-0.465,0.378-0.627,0.795-0.627c0.338,0,0.704,0.076,0.704,0.516v0.965
c0,0.145,0,0.211,0.098,0.211c0.026,0,0.055-0.004,0.095-0.02V81.58z M25.214,80.619c-0.12,0.086-0.352,0.09-0.559,0.127
c-0.203,0.035-0.378,0.109-0.378,0.336c0,0.205,0.175,0.277,0.364,0.277c0.406,0,0.573-0.254,0.573-0.424V80.619z"
      />
      <rect
        x="26.019"
        y="78.996"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        width="0.308"
        height="2.592"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M27.233,79.711h0.374v0.273h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.273h0.319v-0.563h0.309V79.711z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M29.548,80.992c-0.084,0.414-0.381,0.639-0.802,0.639
c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.619,0.849,1.074h-1.405
c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.367H29.548z M29.25,80.471
c-0.014-0.295-0.236-0.531-0.54-0.531c-0.323,0-0.516,0.244-0.538,0.531H29.25z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M29.884,79.711h0.291v0.396h0.006c0.149-0.301,0.356-0.449,0.69-0.439v0.326
c-0.497,0-0.678,0.283-0.678,0.758v0.836h-0.309V79.711z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M33.773,81.58c-0.056,0.033-0.123,0.051-0.223,0.051
c-0.158,0-0.26-0.088-0.26-0.291c-0.17,0.201-0.399,0.291-0.66,0.291c-0.342,0-0.621-0.152-0.621-0.525
c0-0.424,0.316-0.516,0.636-0.578c0.34-0.064,0.631-0.043,0.631-0.275c0-0.268-0.222-0.313-0.417-0.313
c-0.262,0-0.454,0.082-0.469,0.355h-0.309c0.019-0.465,0.379-0.627,0.795-0.627c0.338,0,0.705,0.076,0.705,0.516v0.965
c0,0.145,0,0.211,0.097,0.211c0.025,0,0.055-0.004,0.095-0.02V81.58z M33.272,80.619c-0.119,0.086-0.353,0.09-0.56,0.127
c-0.203,0.035-0.377,0.109-0.377,0.336c0,0.205,0.174,0.277,0.363,0.277c0.406,0,0.573-0.254,0.573-0.424V80.619z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M34.048,79.711h0.291v0.396h0.006c0.149-0.301,0.356-0.449,0.69-0.439v0.326
c-0.497,0-0.679,0.283-0.679,0.758v0.836h-0.309V79.711z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M35.695,79.711h0.374v0.273h-0.374v1.164c0,0.143,0.039,0.168,0.231,0.168
h0.143v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.318v-0.273h0.318v-0.563h0.31V79.711z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        points="38.267,81.588 37.941,81.588 37.56,80.096 37.552,80.096
37.175,81.588 36.841,81.588 36.238,79.711 36.579,79.711 37.005,81.246 37.013,81.246 37.39,79.711 37.728,79.711 38.118,81.246
38.126,81.246 38.546,79.711 38.866,79.711 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M39.968,79.668c0.596,0,0.911,0.432,0.911,0.984
c0,0.547-0.315,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C39.057,80.1,39.373,79.668,39.968,79.668 M39.968,81.359
c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.322,0-0.584,0.254-0.584,0.713
C39.384,81.105,39.646,81.359,39.968,81.359"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M41.229,79.711h0.291v0.396h0.006c0.149-0.301,0.356-0.449,0.69-0.439v0.326
c-0.497,0-0.679,0.283-0.679,0.758v0.836h-0.309V79.711z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        points="42.466,78.996 42.775,78.996 42.775,80.531 43.632,79.711
44.046,79.711 43.302,80.393 44.1,81.588 43.708,81.588 43.064,80.607 42.775,80.877 42.775,81.588 42.466,81.588 		"
      />
      <rect
        x="9.106"
        y="84.191"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        width="1.815"
        height="0.291"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M14.279,84.408c0,0.652-0.374,0.998-1.02,0.998
c-0.668,0-1.063-0.309-1.063-0.998v-1.654h0.345v1.654c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.654
h0.345V84.408z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M14.967,84.752c0.011,0.277,0.25,0.363,0.505,0.363
c0.192,0,0.454-0.043,0.454-0.279c0-0.238-0.306-0.279-0.614-0.348c-0.305-0.07-0.614-0.172-0.614-0.531
c0-0.377,0.374-0.533,0.701-0.533c0.414,0,0.744,0.131,0.769,0.59H15.86c-0.022-0.24-0.233-0.316-0.435-0.316
c-0.186,0-0.4,0.051-0.4,0.238c0,0.223,0.326,0.258,0.614,0.328c0.308,0.068,0.612,0.17,0.612,0.533
c0,0.445-0.417,0.592-0.798,0.592c-0.421,0-0.777-0.172-0.794-0.637H14.967z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M18.192,84.75c-0.083,0.412-0.38,0.637-0.802,0.637
c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.882-0.977c0.664,0,0.868,0.621,0.849,1.074h-1.404
c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.365H18.192z M17.895,84.227
c-0.015-0.295-0.236-0.529-0.541-0.529c-0.323,0-0.515,0.242-0.537,0.529H17.895z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M19.976,83.469h0.374v0.271h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41V83.74h-0.319v-0.271h0.319v-0.564h0.309V83.469z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M21.017,83.131h-0.308v-0.379h0.308V83.131z M20.709,83.467h0.308v1.877
h-0.308V83.467z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M23.114,85.184c0,0.611-0.28,0.922-0.871,0.922
c-0.352,0-0.759-0.141-0.777-0.551h0.309c0.014,0.225,0.283,0.305,0.486,0.305c0.403,0,0.563-0.291,0.563-0.711v-0.123h-0.008
c-0.102,0.229-0.348,0.34-0.584,0.34c-0.559,0-0.838-0.438-0.838-0.949c0-0.443,0.217-0.992,0.867-0.992
c0.236,0,0.446,0.105,0.559,0.313h0.004v-0.268h0.29V85.184z M22.809,84.367c0-0.33-0.145-0.672-0.533-0.672
c-0.396,0-0.556,0.324-0.556,0.684c0,0.338,0.124,0.715,0.53,0.715C22.657,85.094,22.809,84.721,22.809,84.367"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M23.577,82.754h0.309v0.99h0.007c0.102-0.225,0.366-0.318,0.591-0.318
c0.494,0,0.646,0.281,0.646,0.686v1.232h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
h-0.309V82.754z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M26.022,83.469h0.374v0.271h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
h0.142v0.271H26.16c-0.32,0-0.447-0.064-0.447-0.41V83.74h-0.319v-0.271h0.319v-0.564h0.309V83.469z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M28.554,83.424c0.595,0,0.911,0.434,0.911,0.984
c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.643,83.857,27.959,83.424,28.554,83.424 M28.554,85.115
c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
C27.97,84.861,28.231,85.115,28.554,85.115"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        points="30.672,85.344 30.341,85.344 29.645,83.469 29.99,83.469
30.516,85.031 30.523,85.031 31.035,83.469 31.357,83.469 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M33.24,84.75c-0.082,0.412-0.381,0.637-0.802,0.637
c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.881-0.977c0.664,0,0.867,0.621,0.85,1.074h-1.405
c-0.011,0.326,0.174,0.617,0.584,0.617c0.254,0,0.433-0.123,0.486-0.365H33.24z M32.942,84.227
c-0.014-0.295-0.235-0.529-0.54-0.529c-0.322,0-0.516,0.242-0.538,0.529H32.942z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M33.576,83.469h0.292v0.395h0.006c0.148-0.301,0.355-0.449,0.689-0.439v0.328
c-0.497,0-0.678,0.281-0.678,0.758v0.834h-0.31V83.469z"
      />
      <rect
        x="34.813"
        y="82.752"
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        width="0.309"
        height="2.592"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M36.409,83.424c0.596,0,0.912,0.434,0.912,0.984
c0,0.547-0.316,0.979-0.912,0.979c-0.595,0-0.91-0.432-0.91-0.979C35.499,83.857,35.814,83.424,36.409,83.424 M36.409,85.115
c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.322,0-0.584,0.254-0.584,0.711
C35.825,84.861,36.087,85.115,36.409,85.115"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M38.956,84.07c-0.048-0.236-0.21-0.373-0.464-0.373
c-0.446,0-0.585,0.352-0.585,0.732c0,0.346,0.156,0.686,0.548,0.686c0.298,0,0.476-0.174,0.515-0.457h0.316
c-0.068,0.457-0.355,0.729-0.826,0.729c-0.574,0-0.88-0.398-0.88-0.957c0-0.563,0.291-1.006,0.886-1.006
c0.424,0,0.767,0.201,0.81,0.646H38.956z"
      />
      <polygon
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        points="39.647,82.754 39.957,82.754 39.957,84.287 40.813,83.469
41.228,83.469 40.483,84.15 41.281,85.344 40.89,85.344 40.246,84.363 39.957,84.633 39.957,85.344 39.647,85.344 		"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M41.839,83.131H41.53v-0.379h0.309V83.131z M41.53,83.467h0.309v1.877H41.53
V83.467z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M42.317,83.469h0.291v0.297h0.006c0.131-0.232,0.341-0.342,0.609-0.342
c0.494,0,0.646,0.283,0.646,0.688v1.232h-0.309v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.373,0-0.555,0.25-0.555,0.588v1.059
h-0.309V83.469z"
      />
      <path
        clipPath="url(#BEPREPARED_SVGID_18_)"
        fill="#EC008C"
        d="M45.952,85.184c0,0.611-0.279,0.922-0.871,0.922
c-0.352,0-0.758-0.141-0.776-0.551h0.31c0.014,0.225,0.282,0.305,0.485,0.305c0.403,0,0.563-0.291,0.563-0.711v-0.123h-0.008
c-0.103,0.229-0.349,0.34-0.584,0.34c-0.56,0-0.839-0.438-0.839-0.949c0-0.443,0.218-0.992,0.867-0.992
c0.235,0,0.446,0.105,0.56,0.313h0.004v-0.268h0.289V85.184z M45.647,84.367c0-0.33-0.145-0.672-0.533-0.672
c-0.396,0-0.556,0.324-0.556,0.684c0,0.338,0.124,0.715,0.53,0.715S45.647,84.721,45.647,84.367"
      />
    </g>
  );
};

function TakeAction(props: any) {
  const { badge, fallback = null, ...otherProps } = props;

  switch (badge) {
    case 'Craftivism':
      return <Craftivism {...otherProps} />;
    case 'Conscious consumer':
      return <ConsciousConsumer {...otherProps} />;
    case 'Campaigning':
      return <Campaigning {...otherProps} />;
    case 'Be prepared':
      return <BePrepared {...otherProps} />;
    default:
      return fallback;
  }
}

export default TakeAction;
