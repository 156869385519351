import React from 'react';

const Fixing = () => {
  return (
    <g id="Fixing">
      <g>
        <defs>
          <rect
            id="FIXING_SVGID_1_"
            x="-13.217"
            y="0.001"
            width="88.125"
            height="61.686"
          />
        </defs>
        <clipPath id="FIXING_SVGID_2_">
          <use xlinkHref="#FIXING_SVGID_1_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#FIXING_SVGID_2_)"
          fill="#F6ADCD"
          d="M55.241,60.991H6.044c-3.051,0-5.548-2.498-5.548-5.549V6.245
				c0-3.053,2.497-5.549,5.548-5.549h49.197c3.052,0,5.55,2.496,5.55,5.549v49.197C60.791,58.493,58.293,60.991,55.241,60.991"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_2_)"
          fill="#7B2959"
          points="23.407,8.972 25.274,8.972 25.274,9.827 23.407,9.827 23.407,11.255
				22.425,11.255 22.425,7.271 25.506,7.271 25.506,8.134 23.407,8.134 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_2_)"
          fill="#7B2959"
          d="M26.982,7.39c0,0.303-0.232,0.529-0.552,0.529
				c-0.322,0-0.548-0.227-0.548-0.529c0-0.291,0.226-0.541,0.548-0.541C26.75,6.849,26.982,7.099,26.982,7.39 M25.955,8.269h0.952
				v2.986h-0.952V8.269z"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_2_)"
          fill="#7B2959"
          points="30.612,11.255 29.519,11.255 28.947,10.394 28.371,11.255
				27.282,11.255 28.264,9.81 27.282,8.265 28.371,8.265 28.947,9.19 29.519,8.265 30.612,8.265 29.625,9.81 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_2_)"
          fill="#7B2959"
          d="M32.025,7.39c0,0.303-0.232,0.529-0.553,0.529
				c-0.322,0-0.547-0.227-0.547-0.529c0-0.291,0.225-0.541,0.547-0.541C31.793,6.849,32.025,7.099,32.025,7.39 M30.996,8.269h0.951
				v2.986h-0.951V8.269z"
        />
        <path
          clipPath="url(#FIXING_SVGID_2_)"
          fill="#7B2959"
          d="M35.631,9.423v1.832h-0.945V9.626c0-0.352-0.209-0.59-0.512-0.59
				c-0.375,0-0.619,0.256-0.619,0.834v1.385h-0.951V8.271h0.951v0.283c0.227-0.236,0.541-0.373,0.916-0.373
				C35.166,8.181,35.631,8.673,35.631,9.423"
        />
        <path
          clipPath="url(#FIXING_SVGID_2_)"
          fill="#7B2959"
          d="M39.313,10.958c0,0.92-0.702,1.426-1.672,1.426
				c-0.684,0-1.207-0.236-1.498-0.719l0.791-0.428c0.226,0.268,0.393,0.369,0.701,0.369c0.435,0,0.744-0.256,0.744-0.727v-0.084
				c-0.214,0.186-0.512,0.287-0.897,0.287c-0.779,0-1.438-0.619-1.438-1.463c0-0.828,0.659-1.439,1.438-1.439
				c0.386,0,0.684,0.102,0.897,0.285V8.271h0.934V10.958z M38.379,9.62c0-0.4-0.315-0.678-0.695-0.678
				c-0.418,0-0.715,0.277-0.715,0.678c0,0.398,0.297,0.684,0.715,0.684C38.063,10.304,38.379,10.019,38.379,9.62"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_2_)"
          fill="#AC6A93"
          points="52.566,30.452 59.264,35.239 58.428,58.464 29.43,58.317
				2.021,30.909 			"
        />
      </g>
      <g>
        <defs>
          <path
            id="FIXING_SVGID_3_"
            d="M6.209,0.696c-3.051,0-5.549,2.496-5.549,5.551v49.195c0,3.051,2.498,5.549,5.549,5.549h49.197
					c3.053,0,5.549-2.498,5.549-5.549V6.247c0-3.055-2.496-5.551-5.549-5.551H6.209z"
          />
        </defs>
        <clipPath id="FIXING_SVGID_4_">
          <use xlinkHref="#FIXING_SVGID_3_" overflow="visible" />
        </clipPath>
        <rect
          x="22.919"
          y="27.694"
          clipPath="url(#FIXING_SVGID_4_)"
          fill="#56626C"
          width="73.554"
          height="5.85"
        />
        <path
          clipPath="url(#FIXING_SVGID_4_)"
          fill="#7B2959"
          d="M107.283,14.913c0,0-45.246-0.605-45.592,0.096l-4.951,0.043
				c-2.774-0.043-5.756-0.559-7.838,1.674c-1.862,1.996-2.477,4.895-2.477,7.57c0,0,0.001,12.49,0.001,12.502
				c0.001,2.9,0.929,6.514,3.458,8.277c1.381,0.961,3.245,1.07,4.858,1.131c1.858,0.068,3.427-0.057,5.288-0.043
				c1.047,0.008,2.092,0.008,3.137,0.008l44.119-0.004c4.514,0,8.207-5.434,8.207-12.076l-0.002-7.105
				C115.492,20.345,111.799,14.911,107.283,14.913"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_4_)"
          fill="#56626C"
          points="19.684,34.669 19.684,26.28 33.156,23.597 33.444,23.597
				37.695,28.31 37.695,32.96 33.301,37.667 			"
        />
        <rect
          x="-27.106"
          y="28.271"
          clipPath="url(#FIXING_SVGID_4_)"
          fill="#C8CCCC"
          width="44.876"
          height="4.719"
        />
        <rect
          x="-27.106"
          y="30.452"
          clipPath="url(#FIXING_SVGID_4_)"
          fill="#97A0A4"
          width="44.876"
          height="2.535"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_4_)"
          fill="#C8CCCC"
          points="22.296,30.63 22.296,39.501 18.564,35.065 14.833,30.63
				18.564,26.194 22.296,21.759 			"
        />
        <polyline
          clipPath="url(#FIXING_SVGID_4_)"
          fill="#97A0A4"
          points="22.296,30.63 22.296,39.501 18.564,35.065 14.833,30.63 			"
        />

        <line
          clipPath="url(#FIXING_SVGID_4_)"
          fill="none"
          stroke="#56626C"
          strokeWidth="1.036"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="15.517"
          y1="33.362"
          x2="13.84"
          y2="28.103"
        />

        <line
          clipPath="url(#FIXING_SVGID_4_)"
          fill="none"
          stroke="#56626C"
          strokeWidth="1.036"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="12.715"
          y1="33.362"
          x2="11.039"
          y2="28.103"
        />

        <line
          clipPath="url(#FIXING_SVGID_4_)"
          fill="none"
          stroke="#56626C"
          strokeWidth="1.036"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="10.117"
          y1="33.362"
          x2="8.44"
          y2="28.103"
        />

        <line
          clipPath="url(#FIXING_SVGID_4_)"
          fill="none"
          stroke="#56626C"
          strokeWidth="1.036"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="7.518"
          y1="33.362"
          x2="5.842"
          y2="28.103"
        />

        <line
          clipPath="url(#FIXING_SVGID_4_)"
          fill="none"
          stroke="#56626C"
          strokeWidth="1.036"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="4.717"
          y1="33.362"
          x2="3.041"
          y2="28.103"
        />
      </g>
      <g>
        <defs>
          <rect
            id="FIXING_SVGID_5_"
            x="-13.217"
            y="0.001"
            width="88.125"
            height="61.686"
          />
        </defs>
        <clipPath id="FIXING_SVGID_6_">
          <use xlinkHref="#FIXING_SVGID_5_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EACBD9"
          d="M56.012,4.407c0.689,0,1.27,0.582,1.27,1.27v50.334
				c0,0.689-0.58,1.271-1.27,1.271H5.679c-0.689,0-1.271-0.582-1.271-1.271V5.677c0-0.688,0.582-1.27,1.271-1.27H56.012
				 M56.012,0.001H5.679c-3.123,0-5.677,2.553-5.677,5.676v50.334c0,3.121,2.554,5.678,5.677,5.678h50.333
				c3.123,0,5.678-2.557,5.678-5.678V5.677C61.689,2.554,59.135,0.001,56.012,0.001"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#5087C7"
          d="M30.855,55.53c-2.745,0-4.979-2.234-4.979-4.979s2.233-4.979,4.979-4.979
				c2.746,0,4.979,2.234,4.979,4.979S33.602,55.53,30.855,55.53"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M30.855,45.778c2.639,0,4.775,2.137,4.775,4.773s-2.137,4.771-4.775,4.771
				c-2.637,0-4.773-2.135-4.773-4.771S28.219,45.778,30.855,45.778 M30.855,45.366c-2.859,0-5.185,2.326-5.185,5.186
				c0,2.857,2.326,5.184,5.185,5.184s5.186-2.326,5.186-5.184C36.041,47.692,33.715,45.366,30.855,45.366"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M33.35,48.606c-0.238,0-0.464,0.107-0.619,0.25l-0.014-0.012
				c0.072-0.119,0.169-0.346,0.169-0.715c0-0.883-0.885-1.551-2.028-1.551c-1.146,0-2.043,0.668-2.043,1.551
				c0,0.369,0.12,0.596,0.18,0.715l-0.012,0.012c-0.156-0.143-0.382-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.018
				c0,1.287,0.717,2.016,1.492,2.016c0.715,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.18-0.215-0.299-0.215s-0.251,0.084-0.477,0.264
				c-0.299,0.227-0.561,0.357-0.908,0.357c-0.607,0-0.869-0.682-0.869-1.574c0-0.955,0.25-1.588,0.895-1.588
				c0.287,0,0.477,0.084,0.668,0.262c0.287,0.264,0.382,0.334,0.513,0.334c0.097,0,0.155-0.061,0.179-0.094
				c-0.214-0.217-0.525-0.668-0.525-1.254c0-0.668,0.562-1.135,1.613-1.135c1.049,0,1.61,0.467,1.61,1.135
				c0,0.586-0.312,1.037-0.526,1.254c0.024,0.033,0.084,0.094,0.18,0.094c0.132,0,0.227-0.07,0.513-0.334
				c0.179-0.178,0.382-0.262,0.668-0.262c0.646,0,0.896,0.633,0.896,1.588c0,0.893-0.263,1.574-0.871,1.574
				c-0.346,0-0.621-0.131-0.907-0.357c-0.226-0.18-0.356-0.264-0.478-0.264c-0.119,0-0.226,0.107-0.298,0.215
				c0.441,0.453,0.955,0.848,1.671,0.848c0.775,0,1.491-0.729,1.491-2.016C34.806,49.31,34.078,48.606,33.35,48.606"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M31.656,48.583h-0.537c-0.037,0-0.06-0.025-0.071-0.049l-0.168-0.512
				c-0.013-0.035-0.023-0.035-0.035,0l-0.168,0.512c-0.013,0.037-0.035,0.049-0.06,0.049H30.08c-0.024,0-0.035,0.01-0.012,0.023
				l0.441,0.322c0.023,0.023,0.023,0.061,0.012,0.084l-0.167,0.5c-0.011,0.023-0.011,0.049,0.024,0.023l0.441-0.309
				c0.036-0.023,0.06-0.023,0.096,0l0.43,0.309c0.023,0.025,0.035,0.012,0.023-0.01l-0.166-0.514c-0.014-0.037,0-0.072,0.023-0.084
				l0.439-0.322C31.691,48.593,31.679,48.583,31.656,48.583"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M31.25,52.114c-0.227-0.572-0.406-0.967-0.406-1.443
				c0-0.203,0.061-0.383,0.096-0.477c0.047-0.096,0.061-0.156,0.012-0.156c-0.071,0-0.227,0.166-0.321,0.311
				c-0.073,0.119-0.155,0.346-0.155,0.523c0,0.336,0.107,0.693,0.178,0.908c0.133,0.369,0.252,0.799,0.252,1.205
				c0,0.645-0.287,1.086-0.799,1.479c0.047,0.037,0.166,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.324
				C31.513,52.782,31.381,52.472,31.25,52.114"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#5087C7"
          d="M30.855,55.36c-2.637,0-4.773-2.135-4.773-4.771s2.137-4.773,4.773-4.773
				c2.639,0,4.775,2.137,4.775,4.773S33.494,55.36,30.855,55.36"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M30.855,45.815c2.639,0,4.775,2.137,4.775,4.773s-2.137,4.773-4.775,4.773
				c-2.637,0-4.773-2.137-4.773-4.773S28.219,45.815,30.855,45.815 M30.855,45.405c-2.859,0-5.185,2.324-5.185,5.184
				s2.326,5.184,5.185,5.184s5.186-2.324,5.186-5.184S33.715,45.405,30.855,45.405"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M33.35,48.644c-0.238,0-0.464,0.107-0.619,0.252l-0.014-0.014
				c0.072-0.119,0.169-0.346,0.169-0.715c0-0.883-0.885-1.551-2.028-1.551c-1.146,0-2.043,0.668-2.043,1.551
				c0,0.369,0.12,0.596,0.18,0.715l-0.012,0.014c-0.156-0.145-0.382-0.252-0.619-0.252c-0.729,0-1.457,0.703-1.457,2.018
				c0,1.287,0.717,2.016,1.492,2.016c0.715,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.18-0.215-0.299-0.215s-0.251,0.084-0.477,0.264
				c-0.299,0.227-0.561,0.357-0.908,0.357c-0.607,0-0.869-0.682-0.869-1.574c0-0.955,0.25-1.588,0.895-1.588
				c0.287,0,0.477,0.084,0.668,0.264c0.287,0.262,0.382,0.332,0.513,0.332c0.097,0,0.155-0.059,0.179-0.094
				c-0.214-0.215-0.525-0.668-0.525-1.252c0-0.67,0.562-1.135,1.613-1.135c1.049,0,1.61,0.465,1.61,1.135
				c0,0.584-0.312,1.037-0.526,1.252c0.024,0.035,0.084,0.094,0.18,0.094c0.132,0,0.227-0.07,0.513-0.332
				c0.179-0.18,0.382-0.264,0.668-0.264c0.646,0,0.896,0.633,0.896,1.588c0,0.893-0.263,1.574-0.871,1.574
				c-0.346,0-0.621-0.131-0.907-0.357c-0.226-0.18-0.356-0.264-0.478-0.264c-0.119,0-0.226,0.107-0.298,0.215
				c0.441,0.453,0.955,0.848,1.671,0.848c0.775,0,1.491-0.729,1.491-2.016C34.806,49.347,34.078,48.644,33.35,48.644"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M31.656,48.62h-0.537c-0.037,0-0.06-0.025-0.071-0.047L30.88,48.06
				c-0.013-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.013,0.035-0.035,0.047-0.06,0.047H30.08c-0.024,0-0.035,0.012-0.012,0.023
				l0.441,0.322c0.023,0.023,0.023,0.061,0.012,0.084l-0.167,0.5c-0.011,0.025-0.011,0.049,0.024,0.025l0.441-0.311
				c0.036-0.023,0.06-0.023,0.096,0l0.43,0.311c0.023,0.023,0.035,0.012,0.023-0.014l-0.166-0.512c-0.014-0.037,0-0.072,0.023-0.084
				l0.439-0.322C31.69,48.632,31.679,48.62,31.656,48.62"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M31.25,52.151c-0.227-0.572-0.406-0.967-0.406-1.443
				c0-0.203,0.061-0.381,0.096-0.477c0.047-0.096,0.061-0.156,0.012-0.156c-0.071,0-0.227,0.168-0.321,0.311
				c-0.073,0.119-0.156,0.346-0.156,0.525c0,0.334,0.108,0.691,0.179,0.906c0.133,0.371,0.252,0.801,0.252,1.205
				c0,0.646-0.287,1.086-0.799,1.48c0.047,0.035,0.166,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.326
				C31.513,52.819,31.381,52.509,31.25,52.151"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M-12.142-25.938c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.026,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.142z M-12.233-25.269c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.059-0.16-0.059h-0.124v0.45H-12.233z"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          points="-11.139,-25.938 -10.928,-24.893 -10.923,-24.893 -10.71,-25.938
				-10.268,-25.938 -10.268,-24.458 -10.542,-24.458 -10.542,-25.64 -10.546,-25.64 -10.809,-24.458 -11.042,-24.458
				-11.304,-25.64 -11.308,-25.64 -11.308,-24.458 -11.582,-24.458 -11.582,-25.938 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M-9.814-24.877c0,0.035,0.003,0.066,0.008,0.096
				c0.005,0.027,0.016,0.052,0.031,0.07c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.013,0.058,0.018,0.097,0.018
				c0.045,0,0.086-0.016,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.138c0-0.033-0.005-0.062-0.014-0.086
				c-0.008-0.023-0.024-0.046-0.044-0.065c-0.021-0.02-0.048-0.036-0.082-0.054c-0.033-0.015-0.075-0.03-0.125-0.048
				c-0.066-0.021-0.124-0.046-0.172-0.072c-0.049-0.026-0.089-0.057-0.121-0.093c-0.033-0.034-0.057-0.075-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.16c0-0.146,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.144,0.069c0.042,0.033,0.073,0.074,0.096,0.124
				c0.023,0.052,0.034,0.111,0.034,0.183v0.041h-0.286c0-0.07-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.058-0.124-0.058
				c-0.033,0-0.061,0.006-0.083,0.016c-0.023,0.009-0.04,0.021-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114s0.06,0.06,0.124,0.086l0.23,0.1
				c0.057,0.024,0.103,0.051,0.139,0.078c0.036,0.026,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
				c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.271-0.135,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062h0.298V-24.877z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M-7.971-25.685c-0.019-0.031-0.055-0.046-0.104-0.046
				c-0.039,0-0.07,0.01-0.095,0.027c-0.024,0.02-0.043,0.046-0.056,0.078c-0.013,0.033-0.022,0.073-0.025,0.118
				c-0.005,0.046-0.007,0.096-0.007,0.149v0.072h0.004c0.025-0.038,0.061-0.068,0.107-0.092c0.047-0.022,0.103-0.034,0.169-0.034
				c0.065,0,0.12,0.012,0.166,0.034c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.089,0.062,0.145
				c0.013,0.056,0.019,0.117,0.019,0.184c0,0.171-0.037,0.299-0.112,0.387c-0.076,0.088-0.196,0.132-0.362,0.132
				c-0.103,0-0.184-0.019-0.245-0.054c-0.06-0.036-0.107-0.085-0.139-0.148c-0.033-0.063-0.054-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.204,0.009-0.303c0.006-0.097,0.025-0.184,0.056-0.259
				c0.031-0.075,0.08-0.135,0.146-0.181c0.067-0.046,0.161-0.068,0.282-0.068c0.052,0,0.103,0.006,0.152,0.019
				c0.05,0.013,0.093,0.032,0.13,0.062c0.037,0.029,0.067,0.068,0.09,0.115c0.023,0.048,0.035,0.106,0.036,0.178h-0.286
				C-7.941-25.61-7.95-25.654-7.971-25.685 M-8.237-24.798c0.004,0.036,0.012,0.067,0.024,0.092c0.011,0.026,0.028,0.046,0.05,0.061
				c0.021,0.015,0.049,0.021,0.084,0.021c0.035,0,0.064-0.007,0.086-0.021c0.021-0.015,0.037-0.034,0.048-0.062
				c0.011-0.025,0.019-0.057,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.115c0-0.1-0.012-0.171-0.037-0.214
				s-0.066-0.063-0.124-0.063c-0.06,0-0.103,0.021-0.127,0.063c-0.025,0.043-0.037,0.114-0.037,0.214
				C-8.243-24.874-8.241-24.834-8.237-24.798"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M-6.641-25.668c-0.061,0.085-0.116,0.178-0.163,0.275
				c-0.047,0.099-0.089,0.199-0.126,0.303c-0.035,0.103-0.065,0.21-0.089,0.315c-0.023,0.108-0.041,0.214-0.053,0.316h-0.314
				c0.012-0.088,0.028-0.181,0.051-0.278c0.021-0.098,0.051-0.196,0.088-0.298c0.036-0.101,0.081-0.202,0.133-0.304
				c0.053-0.104,0.115-0.203,0.186-0.301h-0.617v-0.257h0.904V-25.668z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M-6.547-25.437c0.006-0.094,0.025-0.177,0.056-0.249
				c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.147-0.066,0.256-0.066c0.09,0,0.164,0.014,0.223,0.039
				s0.106,0.066,0.141,0.123c0.035,0.058,0.06,0.13,0.075,0.221c0.014,0.091,0.022,0.2,0.022,0.331c0,0.102-0.004,0.199-0.011,0.294
				c-0.007,0.094-0.026,0.178-0.056,0.25c-0.03,0.071-0.077,0.13-0.138,0.171c-0.061,0.042-0.147,0.063-0.256,0.063
				c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.023-0.107-0.063-0.142-0.12c-0.036-0.056-0.061-0.13-0.075-0.221
				c-0.014-0.09-0.022-0.201-0.022-0.33C-6.559-25.245-6.555-25.343-6.547-25.437 M-6.252-24.851c0.005,0.06,0.013,0.105,0.026,0.14
				c0.012,0.033,0.028,0.057,0.05,0.068c0.02,0.014,0.046,0.019,0.078,0.019c0.033,0,0.061-0.008,0.081-0.023
				s0.037-0.043,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.153c0.004-0.063,0.007-0.142,0.007-0.235v-0.159
				c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.06-0.014-0.105-0.026-0.14C-5.982-25.677-6-25.7-6.02-25.711
				c-0.021-0.014-0.047-0.02-0.079-0.02c-0.033,0-0.06,0.008-0.08,0.024c-0.021,0.016-0.038,0.043-0.049,0.081
				c-0.012,0.038-0.02,0.088-0.025,0.151s-0.008,0.143-0.008,0.236v0.16C-6.26-24.986-6.257-24.91-6.252-24.851"
        />
        <rect
          x="-3.223"
          y="-26.437"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#F6ADCD"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M-2.309-25.938c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.058-0.055,0.104-0.095,0.138c-0.041,0.037-0.091,0.064-0.148,0.081
				c-0.058,0.019-0.123,0.028-0.193,0.028h-0.16v0.59h-0.298v-1.479H-2.309z M-2.4-25.268c0.059,0,0.107-0.019,0.144-0.054
				c0.037-0.036,0.055-0.093,0.055-0.169c0-0.073-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H-2.4z"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          points="-1.306,-25.938 -1.095,-24.893 -1.09,-24.893 -0.877,-25.938
				-0.436,-25.938 -0.436,-24.458 -0.709,-24.458 -0.709,-25.64 -0.713,-25.64 -0.977,-24.458 -1.209,-24.458 -1.471,-25.64
				-1.476,-25.64 -1.476,-24.458 -1.75,-24.458 -1.75,-25.938 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M0.019-24.876c0,0.034,0.003,0.065,0.008,0.094s0.016,0.052,0.031,0.072
				c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.014,0.123-0.044
				c0.036-0.029,0.055-0.076,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086c-0.008-0.025-0.024-0.047-0.044-0.066
				c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048c-0.066-0.023-0.124-0.047-0.172-0.073
				c-0.049-0.026-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.075-0.072-0.122c-0.015-0.045-0.023-0.099-0.023-0.16
				c0-0.146,0.041-0.256,0.122-0.327c0.082-0.071,0.194-0.107,0.336-0.107c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.016,0.104,0.039,0.144,0.071C0.568-25.841,0.6-25.8,0.623-25.75c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0.371
				c0-0.071-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.023,0.009-0.04,0.022-0.054,0.038c-0.013,0.017-0.021,0.035-0.026,0.056S0.04-25.598,0.04-25.575
				c0,0.045,0.01,0.083,0.029,0.114c0.019,0.032,0.06,0.06,0.124,0.086l0.23,0.1c0.057,0.024,0.103,0.051,0.139,0.078
				s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.103c0.007,0.038,0.012,0.079,0.012,0.125
				c0,0.155-0.046,0.269-0.136,0.341c-0.09,0.071-0.216,0.105-0.378,0.105c-0.169,0-0.29-0.035-0.362-0.108
				c-0.072-0.074-0.108-0.18-0.108-0.316v-0.06h0.298V-24.876z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M1.284-24.458c0-0.064,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161s0.06-0.101,0.102-0.149c0.04-0.051,0.088-0.1,0.141-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.08-0.081c0.02-0.024,0.035-0.05,0.046-0.074c0.011-0.025,0.019-0.053,0.022-0.081
				c0.003-0.029,0.005-0.063,0.005-0.1c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.022
				c-0.022,0.016-0.038,0.036-0.05,0.063c-0.011,0.026-0.019,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.11H1.284v-0.058
				c0-0.136,0.037-0.24,0.11-0.314c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.104
				c0.072,0.068,0.108,0.168,0.108,0.297c0,0.046-0.004,0.09-0.013,0.131c-0.008,0.041-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.041,0.073-0.071,0.108c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.041-0.079,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.047,0.1h0.553v0.231H1.284z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M2.291-25.436c0.006-0.095,0.025-0.178,0.056-0.25
				c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.147-0.066,0.256-0.066c0.09,0,0.164,0.014,0.223,0.039
				c0.059,0.026,0.106,0.066,0.141,0.123c0.035,0.058,0.06,0.13,0.075,0.221C3.192-25.453,3.2-25.342,3.2-25.212
				c0,0.102-0.004,0.199-0.011,0.295c-0.007,0.094-0.026,0.177-0.056,0.25c-0.03,0.07-0.077,0.129-0.138,0.17
				c-0.061,0.042-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.023-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.061-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C2.279-25.244,2.282-25.342,2.291-25.436 M2.585-24.851
				c0.005,0.06,0.013,0.105,0.026,0.14c0.012,0.033,0.028,0.057,0.05,0.068c0.02,0.014,0.046,0.02,0.078,0.02
				c0.033,0,0.061-0.009,0.081-0.024s0.037-0.042,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.152
				c0.004-0.063,0.007-0.143,0.007-0.236v-0.159c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.06-0.014-0.105-0.026-0.14
				c-0.013-0.033-0.03-0.057-0.05-0.068c-0.021-0.014-0.047-0.02-0.079-0.02c-0.033,0-0.06,0.008-0.08,0.024
				c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.088-0.025,0.151c-0.005,0.064-0.008,0.143-0.008,0.236v0.16
				C2.577-24.985,2.581-24.91,2.585-24.851"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M3.599-24.692c0.021,0.046,0.064,0.068,0.13,0.068
				c0.035,0,0.064-0.007,0.085-0.019c0.022-0.014,0.038-0.03,0.05-0.052c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.028,0.005-0.06,0.005-0.092s-0.002-0.063-0.007-0.094c-0.005-0.029-0.015-0.056-0.028-0.08
				c-0.014-0.022-0.033-0.042-0.058-0.056s-0.059-0.021-0.1-0.021H3.6v-0.194h0.097c0.032,0,0.058-0.006,0.08-0.02
				c0.021-0.013,0.039-0.029,0.052-0.052c0.013-0.021,0.022-0.046,0.029-0.074c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.07-0.012-0.119-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.006-0.077,0.017
				c-0.021,0.012-0.037,0.029-0.047,0.05c-0.011,0.022-0.019,0.047-0.022,0.076s-0.005,0.061-0.005,0.095H3.3
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.07,0.18-0.106,0.322-0.106c0.133,0,0.237,0.03,0.311,0.092
				c0.074,0.06,0.111,0.155,0.111,0.285c0,0.089-0.02,0.161-0.059,0.217S4-25.241,3.93-25.224v0.004
				c0.094,0.015,0.162,0.053,0.201,0.113c0.04,0.063,0.06,0.14,0.06,0.232c0,0.049-0.007,0.101-0.019,0.153
				c-0.013,0.052-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.077-0.149,0.104c-0.062,0.025-0.146,0.039-0.248,0.039
				c-0.142,0-0.248-0.038-0.319-0.116c-0.07-0.077-0.105-0.188-0.105-0.329v-0.007h0.286C3.566-24.801,3.577-24.737,3.599-24.692"
        />
        <rect
          x="56.619"
          y="-26.437"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#5087C7"
          width="8.814"
          height="8.813"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M57.533-25.938c0.076,0,0.141,0.013,0.193,0.038
				c0.051,0.025,0.094,0.06,0.125,0.101c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.01,0.143-0.033,0.199c-0.023,0.058-0.055,0.104-0.096,0.138c-0.041,0.037-0.09,0.064-0.148,0.081
				c-0.057,0.019-0.123,0.028-0.191,0.028h-0.16v0.59h-0.299v-1.479H57.533z M57.443-25.268c0.059,0,0.105-0.019,0.143-0.054
				c0.037-0.036,0.057-0.093,0.057-0.169c0-0.073-0.018-0.131-0.049-0.17s-0.086-0.058-0.158-0.058h-0.125v0.45H57.443z"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          points="58.537,-25.938 58.748,-24.893 58.754,-24.893 58.967,-25.938
				59.408,-25.938 59.408,-24.458 59.135,-24.458 59.135,-25.64 59.131,-25.64 58.867,-24.458 58.635,-24.458 58.371,-25.64
				58.367,-25.64 58.367,-24.458 58.094,-24.458 58.094,-25.938 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M59.861-24.876c0,0.034,0.004,0.065,0.01,0.094
				c0.004,0.028,0.014,0.052,0.029,0.072c0.016,0.019,0.035,0.035,0.063,0.045c0.025,0.012,0.057,0.016,0.096,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.035-0.029,0.057-0.076,0.057-0.137c0-0.034-0.006-0.063-0.016-0.086
				c-0.008-0.025-0.023-0.047-0.045-0.066c-0.02-0.019-0.047-0.036-0.082-0.053c-0.033-0.016-0.074-0.032-0.125-0.048
				c-0.064-0.023-0.123-0.047-0.172-0.073c-0.047-0.026-0.088-0.057-0.121-0.093c-0.033-0.035-0.057-0.075-0.07-0.122
				c-0.016-0.045-0.023-0.099-0.023-0.16c0-0.146,0.041-0.256,0.123-0.327c0.08-0.071,0.193-0.107,0.336-0.107
				c0.066,0,0.127,0.007,0.182,0.021c0.057,0.016,0.105,0.039,0.146,0.071c0.041,0.033,0.072,0.074,0.094,0.124
				c0.023,0.051,0.035,0.11,0.035,0.182v0.041h-0.285c0-0.071-0.014-0.125-0.039-0.162c-0.023-0.039-0.064-0.057-0.123-0.057
				c-0.033,0-0.061,0.004-0.084,0.014c-0.021,0.009-0.039,0.022-0.053,0.038c-0.012,0.017-0.021,0.035-0.027,0.055
				c-0.004,0.021-0.006,0.042-0.006,0.065c0,0.045,0.01,0.083,0.029,0.114c0.018,0.032,0.061,0.06,0.123,0.086l0.23,0.1
				c0.057,0.024,0.104,0.051,0.139,0.078c0.037,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.037,0.065,0.045,0.103
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.155-0.045,0.269-0.135,0.341c-0.09,0.071-0.217,0.105-0.379,0.105
				c-0.168,0-0.289-0.035-0.361-0.108c-0.072-0.074-0.109-0.18-0.109-0.316v-0.06h0.299V-24.876z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M61.129-24.458c0-0.064,0.006-0.126,0.02-0.183
				c0.012-0.056,0.033-0.11,0.059-0.161c0.027-0.051,0.061-0.101,0.102-0.15s0.088-0.099,0.143-0.147l0.121-0.112
				c0.033-0.029,0.061-0.056,0.078-0.081c0.021-0.024,0.037-0.05,0.047-0.074c0.012-0.025,0.02-0.053,0.023-0.081
				c0.002-0.029,0.004-0.063,0.004-0.1c0-0.122-0.047-0.183-0.145-0.183c-0.037,0-0.066,0.008-0.088,0.022
				c-0.021,0.016-0.039,0.036-0.051,0.063s-0.02,0.057-0.021,0.092c-0.004,0.034-0.006,0.071-0.006,0.11h-0.285v-0.058
				c0-0.136,0.035-0.24,0.109-0.314c0.072-0.073,0.188-0.109,0.346-0.109c0.148,0,0.26,0.034,0.332,0.104
				c0.07,0.068,0.107,0.168,0.107,0.297c0,0.046-0.004,0.09-0.012,0.131c-0.01,0.041-0.021,0.081-0.039,0.117
				c-0.02,0.038-0.043,0.073-0.072,0.108s-0.063,0.072-0.105,0.109l-0.164,0.153c-0.045,0.041-0.08,0.078-0.105,0.116
				c-0.025,0.039-0.041,0.071-0.047,0.1h0.553v0.231H61.129z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M63.035-25.668c-0.063,0.085-0.115,0.178-0.162,0.276
				c-0.049,0.098-0.09,0.198-0.127,0.302c-0.035,0.103-0.066,0.21-0.088,0.315c-0.025,0.108-0.043,0.214-0.055,0.316h-0.313
				c0.01-0.088,0.027-0.181,0.049-0.278c0.023-0.098,0.051-0.196,0.088-0.298c0.037-0.101,0.082-0.202,0.135-0.304
				c0.053-0.104,0.113-0.203,0.186-0.301h-0.617v-0.257h0.904V-25.668z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M63.457-25.495h-0.291v-0.195h0.025c0.1,0,0.178-0.018,0.234-0.055
				c0.059-0.038,0.096-0.098,0.109-0.181h0.221v1.467h-0.299V-25.495z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M64.166-25.111c0.041-0.066,0.104-0.107,0.188-0.125v-0.004
				c-0.041-0.01-0.074-0.024-0.102-0.046c-0.027-0.02-0.049-0.043-0.064-0.07c-0.018-0.025-0.029-0.054-0.035-0.082
				c-0.008-0.029-0.012-0.057-0.012-0.083c0-0.145,0.039-0.247,0.115-0.31c0.076-0.063,0.182-0.095,0.316-0.095
				s0.24,0.031,0.316,0.095c0.076,0.063,0.115,0.165,0.115,0.31c0,0.026-0.004,0.054-0.012,0.083
				c-0.008,0.028-0.02,0.057-0.037,0.082c-0.016,0.027-0.037,0.051-0.064,0.07c-0.027,0.021-0.061,0.036-0.1,0.046v0.004
				c0.084,0.018,0.146,0.059,0.188,0.125s0.063,0.146,0.063,0.238c0,0.043-0.006,0.09-0.016,0.14
				c-0.01,0.053-0.031,0.101-0.066,0.145c-0.031,0.044-0.08,0.08-0.141,0.11c-0.063,0.028-0.145,0.043-0.246,0.043
				s-0.184-0.015-0.246-0.043c-0.063-0.03-0.107-0.066-0.143-0.11c-0.031-0.044-0.053-0.092-0.064-0.145
				c-0.01-0.05-0.014-0.097-0.014-0.14C64.105-24.965,64.125-25.044,64.166-25.111 M64.41-24.779
				c0.004,0.031,0.014,0.059,0.027,0.081c0.012,0.023,0.029,0.041,0.053,0.055c0.021,0.013,0.049,0.02,0.082,0.02
				s0.061-0.007,0.084-0.02c0.021-0.014,0.039-0.031,0.053-0.055c0.012-0.022,0.021-0.05,0.027-0.081
				c0.004-0.03,0.006-0.063,0.006-0.098c0-0.036-0.002-0.069-0.006-0.1c-0.006-0.03-0.016-0.058-0.027-0.079
				c-0.014-0.024-0.031-0.041-0.053-0.055c-0.023-0.014-0.051-0.02-0.084-0.02s-0.061,0.006-0.082,0.02
				c-0.023,0.014-0.041,0.03-0.053,0.055c-0.014,0.021-0.023,0.049-0.027,0.079c-0.006,0.03-0.008,0.063-0.008,0.1
				C64.402-24.842,64.404-24.809,64.41-24.779 M64.467-25.375c0.025,0.033,0.063,0.05,0.105,0.05c0.045,0,0.08-0.017,0.105-0.05
				c0.027-0.033,0.039-0.085,0.039-0.155c0-0.135-0.047-0.201-0.145-0.201s-0.145,0.066-0.145,0.201
				C64.428-25.459,64.441-25.408,64.467-25.375"
        />
        <rect
          x="6.645"
          y="-26.437"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#7B2959"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M7.559-25.938c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.025,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.013,0.055,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.144-0.034,0.199c-0.023,0.057-0.055,0.104-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.019-0.123,0.027-0.193,0.027h-0.16v0.59H7.037v-1.479H7.559z M7.468-25.269c0.059,0,0.107-0.019,0.144-0.055
				c0.037-0.035,0.055-0.092,0.055-0.168c0-0.073-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.057-0.16-0.057H7.335v0.449H7.468z"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          points="8.562,-25.938 8.773,-24.893 8.778,-24.893 8.991,-25.938
				9.433,-25.938 9.433,-24.459 9.159,-24.459 9.159,-25.64 9.155,-25.64 8.892,-24.459 8.659,-24.459 8.397,-25.64 8.393,-25.64
				8.393,-24.459 8.119,-24.459 8.119,-25.938 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M9.887-24.877c0,0.035,0.003,0.065,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.008-0.024-0.024-0.046-0.044-0.065c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.049
				c-0.066-0.021-0.124-0.046-0.172-0.072c-0.049-0.027-0.089-0.057-0.121-0.092c-0.033-0.036-0.057-0.077-0.072-0.123
				s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.107,0.336-0.107c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.015,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.07-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.015
				c-0.023,0.009-0.04,0.022-0.054,0.037c-0.013,0.017-0.021,0.035-0.026,0.056c-0.005,0.021-0.007,0.041-0.007,0.064
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.06,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.103c0.007,0.038,0.012,0.079,0.012,0.125
				c0,0.155-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.037-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.877z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M11.73-25.686c-0.019-0.03-0.055-0.046-0.104-0.046
				c-0.039,0-0.07,0.011-0.095,0.028c-0.024,0.019-0.043,0.045-0.056,0.078c-0.013,0.034-0.022,0.073-0.026,0.119
				c-0.004,0.045-0.006,0.095-0.006,0.147v0.073h0.004c0.025-0.038,0.061-0.069,0.107-0.092c0.047-0.023,0.103-0.034,0.169-0.034
				c0.065,0,0.12,0.011,0.166,0.034c0.045,0.022,0.082,0.055,0.111,0.095c0.028,0.041,0.049,0.089,0.062,0.146
				c0.013,0.056,0.019,0.116,0.019,0.184c0,0.17-0.037,0.299-0.112,0.386c-0.076,0.088-0.196,0.132-0.362,0.132
				c-0.103,0-0.184-0.018-0.245-0.054c-0.06-0.036-0.107-0.085-0.139-0.147c-0.033-0.063-0.054-0.137-0.064-0.219
				c-0.009-0.083-0.014-0.17-0.014-0.26c0-0.104,0.003-0.204,0.009-0.301c0.006-0.099,0.025-0.186,0.056-0.259
				c0.031-0.076,0.08-0.137,0.146-0.183c0.067-0.046,0.161-0.067,0.282-0.067c0.052,0,0.103,0.006,0.152,0.018
				c0.05,0.013,0.093,0.033,0.13,0.063c0.037,0.028,0.067,0.067,0.09,0.114c0.023,0.049,0.035,0.107,0.036,0.179H11.76
				C11.76-25.611,11.751-25.655,11.73-25.686 M11.464-24.798c0.004,0.036,0.012,0.066,0.024,0.092
				c0.011,0.025,0.028,0.046,0.05,0.061c0.021,0.015,0.049,0.021,0.084,0.021c0.035,0,0.064-0.007,0.086-0.021
				c0.021-0.015,0.037-0.035,0.048-0.062c0.011-0.026,0.019-0.058,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.115
				c0-0.101-0.012-0.171-0.037-0.214c-0.025-0.043-0.066-0.064-0.124-0.064c-0.06,0-0.103,0.021-0.127,0.064
				c-0.025,0.043-0.037,0.113-0.037,0.214C11.458-24.874,11.46-24.833,11.464-24.798"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M12.201-25.112c0.042-0.066,0.104-0.107,0.187-0.124v-0.004
				c-0.04-0.011-0.074-0.025-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.071c-0.016-0.025-0.028-0.053-0.035-0.083
				c-0.007-0.028-0.01-0.056-0.01-0.082c0-0.144,0.038-0.247,0.115-0.311c0.076-0.062,0.182-0.093,0.316-0.093
				c0.134,0,0.239,0.031,0.315,0.093c0.077,0.063,0.116,0.167,0.116,0.311c0,0.026-0.004,0.054-0.012,0.082
				c-0.007,0.03-0.019,0.058-0.036,0.083c-0.016,0.027-0.038,0.051-0.065,0.071s-0.06,0.035-0.099,0.046v0.004
				c0.083,0.017,0.145,0.058,0.187,0.124c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.005,0.089-0.015,0.14
				c-0.011,0.052-0.033,0.1-0.066,0.145c-0.033,0.044-0.08,0.08-0.142,0.109c-0.061,0.028-0.143,0.044-0.245,0.044
				s-0.184-0.016-0.246-0.044c-0.062-0.029-0.109-0.065-0.142-0.109c-0.033-0.045-0.055-0.093-0.065-0.145
				c-0.011-0.051-0.016-0.097-0.016-0.14C12.139-24.965,12.16-25.044,12.201-25.112 M12.445-24.78
				c0.005,0.031,0.014,0.06,0.027,0.082c0.013,0.022,0.031,0.041,0.053,0.055c0.022,0.013,0.05,0.019,0.083,0.019
				c0.033,0,0.06-0.006,0.083-0.019c0.021-0.014,0.039-0.032,0.052-0.055c0.013-0.022,0.023-0.051,0.027-0.082
				c0.005-0.029,0.008-0.063,0.008-0.098s-0.003-0.069-0.008-0.099c-0.004-0.031-0.014-0.058-0.027-0.08
				c-0.013-0.023-0.031-0.041-0.052-0.055c-0.023-0.013-0.05-0.019-0.083-0.019c-0.033,0-0.061,0.006-0.083,0.019
				c-0.022,0.014-0.04,0.031-0.053,0.055c-0.013,0.022-0.022,0.049-0.027,0.08c-0.005,0.029-0.007,0.063-0.007,0.099
				S12.44-24.809,12.445-24.78 M12.502-25.375c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05
				c0.027-0.033,0.04-0.085,0.04-0.156c0-0.134-0.048-0.2-0.145-0.2c-0.097,0-0.145,0.066-0.145,0.2
				C12.463-25.459,12.476-25.408,12.502-25.375"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M13.467-24.692c0.021,0.045,0.064,0.066,0.13,0.066
				c0.035,0,0.064-0.006,0.085-0.018c0.022-0.013,0.038-0.029,0.05-0.051c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.03,0.005-0.06,0.005-0.091c0-0.034-0.002-0.065-0.007-0.095c-0.005-0.03-0.015-0.057-0.028-0.079
				c-0.014-0.024-0.033-0.043-0.058-0.058c-0.025-0.013-0.059-0.021-0.1-0.021h-0.099v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.019
				c0.021-0.014,0.039-0.03,0.052-0.052c0.013-0.021,0.022-0.047,0.029-0.074c0.006-0.027,0.009-0.057,0.009-0.087
				c0-0.071-0.012-0.12-0.035-0.149c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.005-0.077,0.017
				c-0.021,0.013-0.037,0.029-0.047,0.051c-0.011,0.021-0.019,0.047-0.022,0.075c-0.003,0.029-0.005,0.062-0.005,0.096h-0.273
				c0-0.147,0.036-0.255,0.109-0.326c0.073-0.071,0.18-0.107,0.322-0.107c0.133,0,0.237,0.03,0.311,0.091
				c0.074,0.062,0.111,0.156,0.111,0.287c0,0.088-0.02,0.16-0.059,0.217c-0.039,0.056-0.094,0.091-0.164,0.105v0.004
				c0.094,0.017,0.162,0.054,0.201,0.116c0.04,0.061,0.06,0.138,0.06,0.23c0,0.05-0.007,0.102-0.019,0.153
				c-0.013,0.053-0.037,0.101-0.072,0.144c-0.037,0.043-0.086,0.077-0.149,0.104c-0.062,0.026-0.146,0.039-0.248,0.039
				c-0.142,0-0.248-0.039-0.319-0.116c-0.07-0.077-0.105-0.187-0.105-0.33v-0.006h0.286C13.434-24.802,13.445-24.737,13.467-24.692"
        />
        <rect
          x="16.801"
          y="-26.437"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#AC6A93"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M17.714-25.938c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.025,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.013,0.055,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.144-0.034,0.199c-0.023,0.057-0.055,0.104-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.019-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.714z M17.624-25.269c0.059,0,0.107-0.019,0.144-0.055
				c0.037-0.035,0.055-0.092,0.055-0.168c0-0.073-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.057-0.16-0.057H17.49v0.449H17.624z"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          points="18.717,-25.938 18.928,-24.893 18.933,-24.893 19.146,-25.938
				19.588,-25.938 19.588,-24.459 19.314,-24.459 19.314,-25.64 19.311,-25.64 19.047,-24.459 18.814,-24.459 18.552,-25.64
				18.548,-25.64 18.548,-24.459 18.274,-24.459 18.274,-25.938 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M20.042-24.877c0,0.035,0.003,0.065,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086
				c-0.008-0.024-0.024-0.046-0.044-0.065c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.049
				c-0.066-0.021-0.124-0.046-0.172-0.072c-0.049-0.027-0.089-0.057-0.121-0.092c-0.033-0.036-0.057-0.077-0.072-0.123
				s-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.107,0.336-0.107
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.015,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.123-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.023,0.009-0.04,0.022-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.056
				c-0.005,0.021-0.007,0.041-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.06,0.06,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078s0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.103
				c0.007,0.038,0.012,0.078,0.012,0.125c0,0.155-0.046,0.27-0.136,0.341c-0.09,0.07-0.216,0.106-0.378,0.106
				c-0.169,0-0.29-0.037-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.877z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M21.885-25.686c-0.019-0.03-0.055-0.046-0.104-0.046
				c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.019-0.043,0.045-0.056,0.078c-0.013,0.034-0.022,0.073-0.026,0.119
				c-0.004,0.045-0.006,0.095-0.006,0.147v0.073h0.004c0.025-0.038,0.061-0.069,0.107-0.092c0.047-0.023,0.103-0.034,0.169-0.034
				c0.065,0,0.12,0.011,0.166,0.034c0.045,0.022,0.082,0.055,0.111,0.095c0.028,0.041,0.049,0.089,0.062,0.146
				c0.013,0.056,0.019,0.116,0.019,0.184c0,0.17-0.037,0.299-0.112,0.386c-0.076,0.088-0.196,0.132-0.362,0.132
				c-0.103,0-0.184-0.018-0.245-0.054c-0.06-0.036-0.107-0.085-0.139-0.147c-0.033-0.063-0.054-0.137-0.064-0.219
				c-0.009-0.083-0.014-0.17-0.014-0.26c0-0.104,0.003-0.204,0.009-0.301c0.006-0.099,0.025-0.186,0.056-0.259
				c0.031-0.076,0.08-0.137,0.146-0.183c0.067-0.046,0.161-0.067,0.282-0.067c0.052,0,0.103,0.006,0.152,0.018
				c0.05,0.013,0.093,0.033,0.13,0.062c0.037,0.029,0.067,0.068,0.09,0.115c0.023,0.049,0.035,0.107,0.036,0.179h-0.286
				C21.915-25.611,21.906-25.655,21.885-25.686 M21.619-24.798c0.004,0.036,0.012,0.066,0.024,0.092
				c0.011,0.025,0.028,0.046,0.05,0.061c0.021,0.015,0.049,0.021,0.084,0.021c0.035,0,0.064-0.007,0.086-0.021
				c0.021-0.015,0.037-0.035,0.048-0.062c0.011-0.026,0.019-0.058,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.115
				c0-0.101-0.012-0.171-0.037-0.214s-0.066-0.064-0.124-0.064c-0.06,0-0.103,0.021-0.127,0.064
				c-0.025,0.043-0.037,0.113-0.037,0.214C21.613-24.874,21.615-24.833,21.619-24.798"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M22.356-25.112c0.042-0.066,0.104-0.107,0.187-0.124v-0.004
				c-0.04-0.011-0.074-0.025-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.071c-0.016-0.025-0.028-0.053-0.035-0.083
				c-0.007-0.028-0.01-0.056-0.01-0.082c0-0.144,0.038-0.247,0.115-0.311c0.076-0.062,0.182-0.093,0.316-0.093
				c0.134,0,0.239,0.031,0.315,0.093c0.077,0.063,0.116,0.167,0.116,0.311c0,0.026-0.004,0.054-0.012,0.082
				c-0.007,0.03-0.019,0.058-0.036,0.083c-0.016,0.027-0.038,0.051-0.065,0.071s-0.06,0.035-0.099,0.046v0.004
				c0.083,0.017,0.145,0.058,0.187,0.124c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.005,0.089-0.015,0.14
				c-0.011,0.052-0.033,0.1-0.066,0.145c-0.033,0.044-0.08,0.08-0.142,0.108c-0.061,0.029-0.143,0.045-0.245,0.045
				s-0.184-0.016-0.246-0.045c-0.062-0.028-0.109-0.064-0.142-0.108c-0.033-0.045-0.055-0.093-0.065-0.145
				c-0.011-0.051-0.016-0.097-0.016-0.14C22.294-24.965,22.315-25.044,22.356-25.112 M22.601-24.78
				c0.005,0.031,0.014,0.06,0.027,0.082c0.013,0.022,0.031,0.041,0.053,0.055c0.022,0.013,0.05,0.019,0.083,0.019
				c0.033,0,0.06-0.006,0.083-0.019c0.021-0.014,0.039-0.032,0.052-0.055c0.013-0.022,0.023-0.051,0.027-0.082
				c0.005-0.029,0.008-0.063,0.008-0.098s-0.003-0.069-0.008-0.099c-0.004-0.031-0.014-0.058-0.027-0.08
				c-0.013-0.023-0.031-0.041-0.052-0.055c-0.023-0.013-0.05-0.019-0.083-0.019c-0.033,0-0.061,0.006-0.083,0.019
				c-0.022,0.014-0.04,0.031-0.053,0.055c-0.013,0.022-0.022,0.049-0.027,0.08c-0.005,0.029-0.007,0.063-0.007,0.099
				S22.596-24.809,22.601-24.78 M22.658-25.375c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05
				c0.027-0.033,0.04-0.085,0.04-0.156c0-0.134-0.048-0.2-0.145-0.2c-0.097,0-0.145,0.066-0.145,0.2
				C22.619-25.459,22.631-25.408,22.658-25.375"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M23.638-25.495h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.235-0.056
				c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.298V-25.495z"
        />
        <rect
          x="26.955"
          y="-26.437"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#C8CCCC"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M27.869-25.938c0.076,0,0.141,0.012,0.191,0.038
				c0.053,0.025,0.095,0.059,0.125,0.101c0.033,0.041,0.056,0.089,0.07,0.141c0.014,0.055,0.021,0.108,0.021,0.163
				c0,0.076-0.013,0.144-0.034,0.199c-0.023,0.057-0.055,0.104-0.096,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.059,0.019-0.123,0.027-0.193,0.027h-0.16v0.59h-0.297v-1.479H27.869z M27.778-25.269c0.06,0,0.106-0.019,0.144-0.055
				c0.037-0.035,0.055-0.092,0.055-0.168c0-0.073-0.016-0.131-0.047-0.17c-0.033-0.038-0.084-0.057-0.16-0.057h-0.125v0.449H27.778z
				"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          points="28.873,-25.938 29.084,-24.893 29.089,-24.893 29.302,-25.938
				29.744,-25.938 29.744,-24.459 29.47,-24.459 29.47,-25.64 29.466,-25.64 29.203,-24.459 28.97,-24.459 28.707,-25.64
				28.703,-25.64 28.703,-24.459 28.429,-24.459 28.429,-25.938 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M30.197-24.877c0,0.035,0.002,0.065,0.009,0.095
				c0.005,0.028,0.015,0.052,0.03,0.071c0.015,0.019,0.035,0.035,0.062,0.045c0.024,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.063-0.014-0.086
				c-0.009-0.024-0.023-0.046-0.045-0.065c-0.021-0.019-0.048-0.037-0.08-0.053c-0.035-0.016-0.076-0.032-0.127-0.049
				c-0.066-0.021-0.123-0.046-0.172-0.072c-0.048-0.027-0.089-0.057-0.121-0.092c-0.033-0.036-0.057-0.077-0.072-0.123
				c-0.015-0.046-0.021-0.101-0.021-0.161c0-0.146,0.04-0.255,0.122-0.327c0.081-0.072,0.192-0.107,0.336-0.107
				c0.065,0,0.127,0.007,0.183,0.021c0.057,0.015,0.104,0.038,0.146,0.07c0.041,0.033,0.073,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041H30.55c0-0.07-0.013-0.124-0.038-0.162c-0.023-0.038-0.066-0.057-0.124-0.057
				c-0.032,0-0.062,0.005-0.083,0.015c-0.021,0.009-0.04,0.022-0.053,0.037c-0.014,0.016-0.021,0.035-0.025,0.056
				c-0.007,0.021-0.009,0.041-0.009,0.064c0,0.045,0.011,0.083,0.029,0.115c0.019,0.03,0.062,0.06,0.124,0.086l0.23,0.099
				c0.057,0.025,0.102,0.051,0.139,0.078c0.035,0.027,0.064,0.056,0.086,0.087s0.037,0.066,0.045,0.103
				c0.008,0.037,0.012,0.078,0.012,0.125c0,0.155-0.046,0.27-0.136,0.341c-0.091,0.07-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.037-0.361-0.109c-0.072-0.073-0.109-0.179-0.109-0.315v-0.061h0.299V-24.877z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M31.942-25.926h0.31v0.931h0.141v0.231h-0.141v0.306h-0.274v-0.306h-0.522
				v-0.243L31.942-25.926z M31.973-25.552l-0.285,0.557h0.29v-0.557H31.973z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M32.458-24.458c0-0.065,0.007-0.125,0.021-0.183
				c0.013-0.057,0.033-0.11,0.06-0.161c0.026-0.052,0.06-0.102,0.103-0.15c0.039-0.05,0.088-0.099,0.141-0.148l0.121-0.111
				c0.033-0.029,0.061-0.057,0.08-0.081c0.02-0.025,0.035-0.05,0.046-0.075s0.019-0.051,0.022-0.081
				c0.002-0.029,0.004-0.063,0.004-0.099c0-0.122-0.047-0.184-0.145-0.184c-0.037,0-0.066,0.008-0.088,0.023
				c-0.021,0.015-0.038,0.036-0.051,0.062c-0.012,0.027-0.018,0.058-0.022,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286V-25.5
				c0-0.138,0.037-0.242,0.11-0.316c0.072-0.073,0.188-0.108,0.346-0.108c0.15,0,0.26,0.033,0.332,0.103
				c0.072,0.07,0.107,0.168,0.107,0.296c0,0.048-0.004,0.091-0.012,0.132c-0.009,0.041-0.021,0.08-0.039,0.117
				c-0.019,0.038-0.043,0.074-0.072,0.109c-0.027,0.035-0.063,0.071-0.105,0.108l-0.165,0.154c-0.044,0.039-0.079,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.554v0.232H32.458z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M34.365-25.669c-0.061,0.086-0.115,0.179-0.163,0.275
				c-0.047,0.099-0.089,0.2-0.126,0.303c-0.035,0.104-0.064,0.21-0.089,0.317c-0.022,0.106-0.041,0.212-0.054,0.314H33.62
				c0.013-0.088,0.028-0.181,0.052-0.277c0.021-0.099,0.051-0.197,0.088-0.297c0.035-0.103,0.08-0.203,0.133-0.306
				s0.115-0.203,0.186-0.3h-0.617v-0.257h0.904V-25.669z"
        />
        <rect
          x="36.824"
          y="-26.437"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#97A0A4"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M37.739-25.938c0.075,0,0.14,0.012,0.192,0.038
				c0.052,0.025,0.094,0.059,0.125,0.101c0.031,0.041,0.055,0.089,0.068,0.141c0.014,0.055,0.02,0.108,0.02,0.163
				c0,0.076-0.01,0.144-0.033,0.199c-0.023,0.057-0.055,0.104-0.096,0.139c-0.041,0.036-0.09,0.063-0.147,0.081
				c-0.058,0.019-0.123,0.027-0.192,0.027h-0.16v0.59h-0.299v-1.479H37.739z M37.648-25.269c0.059,0,0.107-0.019,0.144-0.055
				c0.037-0.035,0.056-0.092,0.056-0.168c0-0.073-0.017-0.131-0.049-0.17c-0.031-0.038-0.084-0.057-0.16-0.057h-0.123v0.449H37.648z
				"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          points="38.742,-25.938 38.953,-24.893 38.958,-24.893 39.171,-25.938
				39.613,-25.938 39.613,-24.459 39.339,-24.459 39.339,-25.64 39.335,-25.64 39.072,-24.459 38.839,-24.459 38.577,-25.64
				38.573,-25.64 38.573,-24.459 38.299,-24.459 38.299,-25.938 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M40.066-24.877c0,0.035,0.004,0.065,0.008,0.095
				c0.006,0.028,0.017,0.052,0.031,0.071c0.016,0.019,0.036,0.035,0.062,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.063-0.014-0.086
				c-0.008-0.024-0.023-0.046-0.044-0.065c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.049
				c-0.065-0.021-0.124-0.046-0.172-0.072c-0.05-0.027-0.089-0.057-0.121-0.092c-0.033-0.036-0.058-0.077-0.071-0.123
				c-0.016-0.046-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.121-0.327c0.082-0.072,0.195-0.107,0.336-0.107
				c0.068,0,0.129,0.007,0.185,0.021s0.104,0.038,0.144,0.07c0.043,0.033,0.074,0.074,0.097,0.124
				c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.124-0.038-0.162c-0.023-0.038-0.066-0.057-0.123-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.022,0.009-0.04,0.022-0.054,0.037c-0.014,0.016-0.021,0.035-0.026,0.056
				s-0.007,0.041-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.06,0.06,0.123,0.086l0.23,0.099
				c0.057,0.025,0.104,0.051,0.139,0.078c0.037,0.027,0.064,0.056,0.086,0.087s0.037,0.066,0.045,0.103
				c0.008,0.037,0.012,0.078,0.012,0.125c0,0.155-0.045,0.27-0.135,0.341c-0.09,0.07-0.217,0.106-0.379,0.106
				c-0.168,0-0.289-0.037-0.361-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.877z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M41.813-25.926h0.309v0.931h0.141v0.231h-0.141v0.306h-0.274v-0.306h-0.522
				v-0.243L41.813-25.926z M41.843-25.553l-0.286,0.558h0.29v-0.558H41.843z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M42.328-24.458c0-0.065,0.006-0.125,0.02-0.183
				c0.013-0.057,0.033-0.11,0.061-0.162c0.026-0.051,0.06-0.101,0.102-0.149c0.039-0.05,0.088-0.099,0.141-0.148l0.121-0.111
				c0.033-0.029,0.061-0.057,0.08-0.081c0.02-0.025,0.035-0.05,0.046-0.075s0.019-0.051,0.022-0.081
				c0.002-0.029,0.004-0.063,0.004-0.099c0-0.122-0.047-0.184-0.145-0.184c-0.037,0-0.066,0.008-0.088,0.023
				c-0.021,0.015-0.037,0.036-0.05,0.062c-0.011,0.027-0.019,0.058-0.022,0.092s-0.004,0.071-0.004,0.109h-0.287V-25.5
				c0-0.138,0.037-0.242,0.109-0.316c0.073-0.073,0.188-0.108,0.346-0.108c0.15,0,0.26,0.033,0.332,0.103
				c0.072,0.07,0.108,0.168,0.108,0.296c0,0.048-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.063,0.071-0.106,0.108l-0.164,0.154c-0.045,0.039-0.08,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H42.328z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M43.665-24.671c0.021,0.031,0.056,0.047,0.104,0.047
				c0.041,0,0.072-0.01,0.097-0.028c0.022-0.019,0.041-0.044,0.054-0.077c0.014-0.034,0.022-0.073,0.026-0.119
				c0.005-0.045,0.007-0.095,0.007-0.148v-0.07h-0.005c-0.024,0.037-0.06,0.067-0.106,0.09c-0.047,0.023-0.103,0.034-0.168,0.034
				s-0.121-0.011-0.166-0.034c-0.047-0.022-0.084-0.055-0.111-0.095c-0.028-0.041-0.049-0.089-0.063-0.146s-0.02-0.116-0.02-0.184
				c0-0.17,0.037-0.3,0.113-0.39c0.074-0.089,0.195-0.135,0.361-0.135c0.102,0,0.184,0.02,0.244,0.058s0.107,0.089,0.141,0.15
				c0.031,0.063,0.053,0.137,0.063,0.22c0.009,0.083,0.014,0.169,0.014,0.259c0,0.104-0.004,0.206-0.009,0.303
				c-0.007,0.099-0.025,0.185-0.057,0.259c-0.03,0.074-0.08,0.134-0.146,0.177c-0.068,0.044-0.162,0.065-0.281,0.065
				c-0.053,0-0.104-0.005-0.152-0.016c-0.049-0.01-0.092-0.029-0.129-0.058s-0.068-0.066-0.092-0.114
				c-0.022-0.048-0.033-0.106-0.035-0.178h0.285C43.635-24.745,43.645-24.703,43.665-24.671 M43.65-25.228
				c0.025,0.043,0.066,0.065,0.123,0.065c0.06,0,0.103-0.022,0.127-0.065c0.025-0.042,0.037-0.114,0.037-0.213
				c0-0.042-0.002-0.08-0.006-0.117c-0.004-0.035-0.012-0.065-0.023-0.091c-0.012-0.026-0.027-0.046-0.05-0.061
				c-0.021-0.015-0.05-0.022-0.085-0.022s-0.063,0.008-0.086,0.022c-0.021,0.015-0.036,0.034-0.049,0.061
				c-0.01,0.027-0.018,0.058-0.021,0.093c-0.004,0.035-0.005,0.073-0.005,0.115C43.613-25.342,43.625-25.27,43.65-25.228"
        />
        <rect
          x="46.693"
          y="-26.437"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#56626C"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M47.607-25.938c0.076,0,0.141,0.012,0.192,0.038
				c0.052,0.025,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.012,0.055,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.144-0.034,0.199c-0.022,0.057-0.055,0.104-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.058,0.019-0.123,0.027-0.193,0.027h-0.159v0.59h-0.298v-1.479H47.607z M47.517-25.269c0.06,0,0.106-0.019,0.144-0.055
				c0.037-0.035,0.055-0.092,0.055-0.168c0-0.073-0.016-0.131-0.047-0.17c-0.032-0.038-0.086-0.057-0.16-0.057h-0.124v0.449H47.517z
				"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          points="48.611,-25.938 48.822,-24.893 48.827,-24.893 49.04,-25.938
				49.48,-25.938 49.48,-24.459 49.208,-24.459 49.208,-25.64 49.204,-25.64 48.941,-24.459 48.708,-24.459 48.445,-25.64
				48.441,-25.64 48.441,-24.459 48.167,-24.459 48.167,-25.938 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M49.936-24.877c0,0.035,0.002,0.065,0.009,0.095
				c0.004,0.028,0.015,0.052,0.03,0.071c0.015,0.019,0.035,0.035,0.062,0.045c0.024,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.063-0.014-0.086
				c-0.009-0.024-0.025-0.046-0.045-0.065c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.016-0.074-0.032-0.125-0.049
				c-0.066-0.021-0.123-0.046-0.172-0.072c-0.049-0.027-0.089-0.057-0.121-0.092c-0.033-0.036-0.057-0.077-0.072-0.123
				c-0.015-0.046-0.022-0.101-0.022-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.192-0.107,0.336-0.107
				c0.065,0,0.127,0.007,0.183,0.021c0.057,0.015,0.104,0.038,0.145,0.07c0.042,0.033,0.072,0.074,0.096,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.07-0.014-0.124-0.038-0.162c-0.025-0.038-0.066-0.057-0.124-0.057
				c-0.032,0-0.062,0.005-0.083,0.015c-0.023,0.009-0.039,0.022-0.053,0.037c-0.014,0.016-0.021,0.035-0.027,0.056
				c-0.005,0.021-0.007,0.041-0.007,0.064c0,0.045,0.011,0.083,0.029,0.115c0.019,0.03,0.062,0.06,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.035,0.066,0.045,0.103
				c0.007,0.037,0.011,0.078,0.011,0.125c0,0.155-0.045,0.27-0.135,0.341c-0.091,0.07-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.037-0.362-0.109c-0.071-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.877z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M51.68-25.926h0.309v0.931h0.142v0.231h-0.142v0.306h-0.272v-0.306h-0.522
				v-0.243L51.68-25.926z M51.712-25.553l-0.287,0.558h0.291v-0.558H51.712z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M52.521-24.692c0.021,0.045,0.063,0.066,0.129,0.066
				c0.035,0,0.064-0.006,0.086-0.018c0.021-0.013,0.037-0.029,0.049-0.051c0.013-0.021,0.02-0.047,0.023-0.075
				c0.004-0.03,0.006-0.06,0.006-0.091c0-0.034-0.002-0.065-0.008-0.095c-0.005-0.03-0.015-0.057-0.027-0.079
				c-0.014-0.024-0.033-0.043-0.059-0.058c-0.025-0.013-0.059-0.021-0.1-0.021h-0.1v-0.195h0.098c0.031,0,0.058-0.006,0.08-0.019
				c0.021-0.014,0.039-0.03,0.052-0.052c0.014-0.021,0.022-0.047,0.028-0.074s0.01-0.057,0.01-0.087c0-0.071-0.012-0.12-0.035-0.149
				s-0.059-0.043-0.107-0.043c-0.031,0-0.059,0.005-0.078,0.017c-0.02,0.013-0.037,0.029-0.047,0.051
				c-0.011,0.021-0.019,0.047-0.021,0.075c-0.004,0.029-0.006,0.062-0.006,0.096h-0.272c0-0.147,0.036-0.256,0.108-0.326
				c0.074-0.071,0.181-0.107,0.322-0.107c0.135,0,0.237,0.03,0.313,0.091c0.074,0.062,0.109,0.156,0.109,0.287
				c0,0.088-0.019,0.16-0.059,0.216c-0.039,0.057-0.094,0.092-0.164,0.106v0.004c0.094,0.017,0.162,0.054,0.202,0.116
				c0.039,0.061,0.06,0.138,0.06,0.23c0,0.05-0.008,0.102-0.02,0.153c-0.014,0.053-0.037,0.101-0.072,0.144
				c-0.037,0.043-0.084,0.077-0.148,0.104c-0.063,0.026-0.145,0.039-0.247,0.039c-0.144,0-0.249-0.039-0.32-0.116
				c-0.069-0.077-0.104-0.187-0.104-0.33v-0.006h0.285C52.488-24.802,52.499-24.737,52.521-24.692"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          d="M53.531-25.495h-0.292v-0.195h0.026c0.1,0,0.178-0.019,0.234-0.056
				c0.059-0.038,0.094-0.098,0.109-0.181h0.22v1.467h-0.298V-25.495z"
        />
        <rect
          x="66.488"
          y="-26.437"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#FFFFFF"
          width="8.813"
          height="8.813"
        />

        <rect
          x="66.488"
          y="-26.436"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.813"
          height="8.812"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          points="67.078,-25.938 67.242,-24.844 67.246,-24.844 67.422,-25.938 67.707,-25.938
				67.881,-24.844 67.887,-24.844 68.049,-25.938 68.336,-25.938 68.066,-24.458 67.732,-24.458 67.566,-25.533 67.563,-25.533
				67.396,-24.458 67.063,-24.458 66.793,-25.938 			"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          points="68.754,-25.938 68.754,-25.373 69.102,-25.373 69.102,-25.938 69.4,-25.938
				69.4,-24.458 69.102,-24.458 69.102,-25.116 68.754,-25.116 68.754,-24.458 68.455,-24.458 68.455,-25.938 			"
        />
        <rect
          x="69.621"
          y="-25.939"
          clipPath="url(#FIXING_SVGID_6_)"
          width="0.299"
          height="1.48"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          points="71.008,-25.693 70.686,-25.693 70.686,-24.458 70.387,-24.458 70.387,-25.693
				70.064,-25.693 70.064,-25.938 71.008,-25.938 			"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          points="71.963,-25.693 71.436,-25.693 71.436,-25.345 71.934,-25.345 71.934,-25.1
				71.436,-25.1 71.436,-24.704 71.984,-24.704 71.984,-24.458 71.137,-24.458 71.137,-25.938 71.963,-25.938 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M12.71,75.937c0.592,0,0.973,0.391,0.973,0.992
				c0,0.594-0.381,0.984-0.973,0.984c-0.588,0-0.969-0.391-0.969-0.984C11.741,76.327,12.122,75.937,12.71,75.937 M12.71,77.526
				c0.352,0,0.458-0.301,0.458-0.598c0-0.303-0.106-0.604-0.458-0.604c-0.348,0-0.453,0.301-0.453,0.604
				C12.257,77.226,12.362,77.526,12.71,77.526"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M14.668,75.987h0.378v0.344h-0.378v0.93c0,0.174,0.044,0.219,0.218,0.219
				c0.054,0,0.105-0.004,0.16-0.016v0.402c-0.087,0.016-0.2,0.02-0.302,0.02c-0.315,0-0.591-0.074-0.591-0.447v-1.107h-0.312v-0.344
				h0.312v-0.563h0.515V75.987z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M15.716,77.05c0.014,0.326,0.174,0.477,0.46,0.477
				c0.208,0,0.374-0.127,0.408-0.244h0.453c-0.146,0.443-0.453,0.631-0.878,0.631c-0.592,0-0.958-0.406-0.958-0.984
				c0-0.564,0.388-0.992,0.958-0.992c0.639,0,0.946,0.537,0.911,1.113H15.716z M16.554,76.724c-0.047-0.262-0.16-0.398-0.41-0.398
				c-0.327,0-0.421,0.254-0.428,0.398H16.554z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M17.773,77.253c0.004,0.227,0.192,0.316,0.396,0.316
				c0.149,0,0.337-0.059,0.337-0.24c0-0.156-0.214-0.209-0.584-0.289c-0.298-0.066-0.595-0.17-0.595-0.502
				c0-0.479,0.413-0.602,0.816-0.602c0.41,0,0.788,0.139,0.828,0.598H18.48c-0.014-0.199-0.166-0.254-0.351-0.254
				c-0.117,0-0.287,0.021-0.287,0.176c0,0.184,0.29,0.209,0.584,0.279c0.301,0.068,0.595,0.178,0.595,0.525
				c0,0.494-0.428,0.652-0.856,0.652c-0.436,0-0.86-0.162-0.882-0.66H17.773z"
        />
        <rect
          x="8.915"
          y="80.466"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M12.02,79.028h0.89c0.798,0,1.244,0.398,1.244,1.234
				c0,0.867-0.381,1.357-1.244,1.357h-0.89V79.028z M12.365,81.329h0.574c0.235,0,0.871-0.064,0.871-1.02
				c0-0.617-0.229-0.99-0.864-0.99h-0.581V81.329z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M15.332,79.7c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.421,80.132,14.737,79.7,15.332,79.7 M15.332,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
				C14.748,81.138,15.009,81.392,15.332,81.392"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M17.613,79.743h0.291v0.297h0.006c0.131-0.232,0.341-0.34,0.61-0.34
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.271c0-0.227-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.061
				h-0.308V79.743z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M20.439,79.7c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.528,80.132,19.844,79.7,20.439,79.7 M20.439,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
				C19.855,81.138,20.116,81.392,20.439,81.392"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M22.14,79.743h0.374v0.271H22.14v1.166c0,0.143,0.04,0.166,0.232,0.166h0.142
				v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319v-0.563h0.309V79.743z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M25.524,81.612c-0.055,0.033-0.123,0.051-0.222,0.051
				c-0.159,0-0.261-0.088-0.261-0.291c-0.17,0.201-0.399,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.525
				c0-0.426,0.316-0.516,0.636-0.578c0.34-0.064,0.631-0.043,0.631-0.275c0-0.268-0.222-0.313-0.417-0.313
				c-0.262,0-0.454,0.082-0.469,0.355h-0.308c0.018-0.465,0.378-0.627,0.795-0.627c0.337,0,0.704,0.076,0.704,0.514v0.967
				c0,0.145,0,0.211,0.098,0.211c0.025,0,0.054-0.004,0.094-0.02V81.612z M25.023,80.651c-0.119,0.086-0.352,0.09-0.559,0.127
				c-0.203,0.035-0.377,0.109-0.377,0.336c0,0.205,0.174,0.277,0.363,0.277c0.406,0,0.573-0.254,0.573-0.426V80.651z"
        />
        <rect
          x="25.828"
          y="79.028"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          width="0.309"
          height="2.592"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M27.043,79.743h0.374v0.271h-0.374v1.166c0,0.143,0.039,0.166,0.232,0.166
				h0.142v0.273h-0.235c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319v-0.563h0.309V79.743z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M29.357,81.024c-0.084,0.414-0.381,0.639-0.803,0.639
				c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.883-0.977c0.664,0,0.867,0.619,0.85,1.074h-1.404
				c-0.012,0.326,0.174,0.617,0.584,0.617c0.254,0,0.432-0.123,0.486-0.367H29.357z M29.061,80.503
				c-0.016-0.295-0.236-0.531-0.541-0.531c-0.324,0-0.516,0.244-0.537,0.531H29.061z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M29.693,79.743h0.291v0.396h0.007c0.149-0.301,0.356-0.449,0.69-0.439v0.326
				c-0.498,0-0.68,0.283-0.68,0.758v0.836h-0.309V79.743z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M33.583,81.612c-0.055,0.033-0.123,0.051-0.222,0.051
				c-0.16,0-0.262-0.088-0.262-0.291c-0.17,0.201-0.398,0.291-0.66,0.291c-0.34,0-0.62-0.152-0.62-0.525
				c0-0.426,0.315-0.516,0.635-0.578c0.341-0.064,0.632-0.043,0.632-0.275c0-0.268-0.222-0.313-0.418-0.313
				c-0.261,0-0.453,0.082-0.468,0.355h-0.31c0.019-0.465,0.379-0.627,0.795-0.627c0.338,0,0.705,0.076,0.705,0.514v0.967
				c0,0.145,0,0.211,0.098,0.211c0.025,0,0.055-0.004,0.095-0.02V81.612z M33.082,80.651c-0.12,0.086-0.352,0.09-0.559,0.127
				c-0.203,0.035-0.379,0.109-0.379,0.336c0,0.205,0.176,0.277,0.365,0.277c0.405,0,0.572-0.254,0.572-0.426V80.651z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M33.857,79.743h0.291v0.396h0.006c0.148-0.301,0.355-0.449,0.689-0.439v0.326
				c-0.496,0-0.678,0.283-0.678,0.758v0.836h-0.309V79.743z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M35.504,79.743h0.375v0.271h-0.375v1.166c0,0.143,0.041,0.166,0.232,0.166
				h0.143v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.318v-0.271h0.318v-0.563h0.309V79.743z"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          points="38.076,81.62 37.75,81.62 37.369,80.128 37.361,80.128 36.984,81.62
				36.65,81.62 36.048,79.743 36.389,79.743 36.814,81.278 36.82,81.278 37.199,79.743 37.535,79.743 37.928,81.278 37.936,81.278
				38.355,79.743 38.675,79.743 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M39.777,79.7c0.596,0,0.911,0.432,0.911,0.984
				c0,0.547-0.315,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C38.866,80.132,39.183,79.7,39.777,79.7 M39.777,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.322,0-0.584,0.254-0.584,0.713
				C39.193,81.138,39.455,81.392,39.777,81.392"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M41.039,79.743h0.291v0.396h0.006c0.148-0.301,0.355-0.449,0.689-0.439v0.326
				c-0.496,0-0.678,0.283-0.678,0.758v0.836h-0.309V79.743z"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          points="42.275,79.028 42.584,79.028 42.584,80.563 43.441,79.743
				43.854,79.743 43.109,80.425 43.909,81.62 43.518,81.62 42.874,80.64 42.584,80.909 42.584,81.62 42.275,81.62 			"
        />
        <rect
          x="8.915"
          y="84.222"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M14.089,84.44c0,0.652-0.374,0.998-1.02,0.998
				c-0.668,0-1.063-0.309-1.063-0.998v-1.654h0.345v1.654c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.654
				h0.345V84.44z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M14.777,84.784c0.011,0.277,0.25,0.363,0.505,0.363
				c0.192,0,0.453-0.043,0.453-0.279c0-0.24-0.305-0.279-0.613-0.348c-0.305-0.07-0.614-0.172-0.614-0.531
				c0-0.377,0.374-0.533,0.701-0.533c0.414,0,0.744,0.131,0.769,0.588H15.67c-0.022-0.24-0.233-0.314-0.436-0.314
				c-0.185,0-0.399,0.051-0.399,0.238c0,0.223,0.326,0.258,0.613,0.326c0.309,0.07,0.613,0.172,0.613,0.535
				c0,0.445-0.417,0.59-0.798,0.59c-0.421,0-0.777-0.17-0.795-0.635H14.777z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M18.002,84.782c-0.083,0.412-0.381,0.637-0.802,0.637
				c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.621,0.849,1.074h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.365H18.002z M17.704,84.259
				c-0.014-0.295-0.236-0.531-0.54-0.531c-0.323,0-0.516,0.244-0.538,0.531H17.704z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M19.786,83.499h0.374v0.273h-0.374v1.164c0,0.143,0.039,0.168,0.232,0.168
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.273h0.319v-0.563h0.309V83.499z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M20.827,83.163h-0.309v-0.379h0.309V83.163z M20.518,83.501h0.309v1.875
				h-0.309V83.501z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M22.923,85.216c0,0.609-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.141-0.776-0.551h0.308c0.014,0.225,0.283,0.305,0.486,0.305c0.403,0,0.563-0.291,0.563-0.711v-0.123h-0.007
				c-0.102,0.229-0.349,0.34-0.585,0.34c-0.558,0-0.838-0.438-0.838-0.951c0-0.441,0.218-0.99,0.867-0.99
				c0.236,0,0.447,0.105,0.559,0.313h0.004v-0.268h0.29V85.216z M22.618,84.399c0-0.33-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.324-0.556,0.684c0,0.338,0.124,0.715,0.53,0.715C22.466,85.126,22.618,84.753,22.618,84.399"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M23.387,82.784h0.308v0.992h0.008c0.102-0.225,0.366-0.318,0.591-0.318
				c0.494,0,0.646,0.281,0.646,0.686v1.232h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.059
				h-0.308V82.784z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M25.832,83.499h0.374v0.273h-0.374v1.164c0,0.143,0.039,0.168,0.232,0.168
				h0.141v0.271H25.97c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.273h0.319v-0.563h0.309V83.499z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M28.363,83.456c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.596,0-0.911-0.432-0.911-0.979C27.452,83.888,27.768,83.456,28.363,83.456 M28.363,85.147
				c0.322,0,0.584-0.254,0.584-0.707c0-0.457-0.262-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
				C27.779,84.894,28.04,85.147,28.363,85.147"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          points="30.481,85.376 30.151,85.376 29.455,83.501 29.799,83.501
				30.325,85.063 30.332,85.063 30.844,83.501 31.168,83.501 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M33.049,84.782c-0.082,0.412-0.379,0.637-0.802,0.637
				c-0.598,0-0.882-0.414-0.899-0.986c0-0.563,0.371-0.977,0.881-0.977c0.664,0,0.869,0.621,0.85,1.074h-1.404
				c-0.011,0.326,0.174,0.617,0.586,0.617c0.254,0,0.431-0.123,0.485-0.365H33.049z M32.752,84.259
				c-0.015-0.295-0.236-0.531-0.541-0.531c-0.322,0-0.515,0.244-0.537,0.531H32.752z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M33.387,83.499h0.289v0.396h0.008c0.148-0.301,0.355-0.449,0.689-0.439v0.326
				c-0.496,0-0.679,0.283-0.679,0.758v0.836h-0.308V83.499z"
        />
        <rect
          x="34.623"
          y="82.786"
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          width="0.309"
          height="2.59"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M36.219,83.456c0.596,0,0.912,0.432,0.912,0.984
				c0,0.547-0.316,0.979-0.912,0.979c-0.594,0-0.91-0.432-0.91-0.979C35.309,83.888,35.625,83.456,36.219,83.456 M36.219,85.147
				c0.324,0,0.584-0.254,0.584-0.707c0-0.457-0.26-0.711-0.584-0.711c-0.322,0-0.584,0.254-0.584,0.711
				C35.635,84.894,35.896,85.147,36.219,85.147"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M38.766,84.103c-0.047-0.236-0.21-0.373-0.465-0.373
				c-0.446,0-0.584,0.352-0.584,0.732c0,0.346,0.156,0.686,0.548,0.686c0.297,0,0.476-0.174,0.515-0.457h0.316
				c-0.069,0.457-0.355,0.729-0.827,0.729c-0.573,0-0.878-0.398-0.878-0.957c0-0.563,0.29-1.006,0.885-1.006
				c0.426,0,0.766,0.201,0.809,0.646H38.766z"
        />
        <polygon
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          points="39.457,82.784 39.766,82.784 39.766,84.319 40.623,83.501
				41.035,83.501 40.291,84.183 41.091,85.376 40.699,85.376 40.056,84.396 39.766,84.665 39.766,85.376 39.457,85.376 			"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M41.648,83.163H41.34v-0.379h0.309V83.163z M41.34,83.501h0.309v1.875H41.34
				V83.501z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M42.127,83.499h0.291v0.299h0.006c0.131-0.232,0.341-0.342,0.609-0.342
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.309v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.373,0-0.556,0.25-0.556,0.588v1.059
				h-0.308V83.499z"
        />
        <path
          clipPath="url(#FIXING_SVGID_6_)"
          fill="#EC008C"
          d="M45.762,85.216c0,0.609-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.141-0.775-0.551h0.308c0.014,0.225,0.282,0.305,0.485,0.305c0.403,0,0.563-0.291,0.563-0.711v-0.123h-0.007
				c-0.102,0.229-0.35,0.34-0.585,0.34c-0.558,0-0.839-0.438-0.839-0.951c0-0.441,0.219-0.99,0.867-0.99
				c0.236,0,0.447,0.105,0.56,0.313h0.004v-0.268h0.29V85.216z M45.457,84.399c0-0.33-0.146-0.672-0.533-0.672
				c-0.396,0-0.557,0.324-0.557,0.684c0,0.338,0.125,0.715,0.531,0.715S45.457,84.753,45.457,84.399"
        />
      </g>
    </g>
  );
};

const Investigating = () => {
  return (
    <g id="Investigating">
      <g>
        <defs>
          <rect
            id="INVESTIGATING_SVGID_7_"
            y="0.002"
            width="74.906"
            height="61.686"
          />
        </defs>
        <clipPath id="INVESTIGATING_SVGID_8_">
          <use xlinkHref="#INVESTIGATING_SVGID_7_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#F6ADCD"
          d="M55.465,61.053H6.166c-3.057,0-5.559-2.502-5.559-5.561V6.195
      c0-3.057,2.502-5.559,5.559-5.559h49.299c3.057,0,5.559,2.502,5.559,5.559v49.297C61.023,58.551,58.521,61.053,55.465,61.053"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M15.506,31.85l-0.447-0.424L14.612,31l-0.447-0.426l-0.447-0.424l-0.446-0.424l-0.447-0.426c-0.254-0.242-0.478-0.504-0.67-0.781
      c-0.193-0.277-0.353-0.572-0.484-0.875c-0.13-0.305-0.229-0.619-0.297-0.941c-0.067-0.32-0.104-0.646-0.11-0.975"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M17.214,33.328c-0.288-0.178-0.561-0.387-0.815-0.629"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M20.403,22.225l0.447,0.424l0.447,0.426l0.446,0.424l0.447,0.426l0.447,0.426l0.447,0.424l0.446,0.424
      c0.255,0.242,0.478,0.504,0.67,0.781c0.193,0.279,0.354,0.572,0.484,0.877c0.13,0.303,0.229,0.619,0.297,0.941
      c0.068,0.32,0.105,0.646,0.11,0.975c0.006,0.328-0.02,0.658-0.078,0.982c-0.057,0.324-0.145,0.646-0.265,0.961
      c-0.12,0.311-0.272,0.615-0.454,0.906c-0.184,0.291-0.398,0.568-0.644,0.826l0,0l-0.001,0.002h-0.001l-0.001,0.002
      c-0.247,0.26-0.513,0.488-0.794,0.686c-0.281,0.195-0.577,0.363-0.883,0.498c-0.306,0.137-0.623,0.242-0.945,0.314
      c-0.322,0.074-0.649,0.117-0.977,0.127c-0.328,0.012-0.656-0.008-0.98-0.059"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M15.331,20.549c0.322-0.072,0.649-0.115,0.977-0.127s0.656,0.01,0.98,0.061c0.324,0.053,0.645,0.135,0.954,0.25
      c0.311,0.115,0.612,0.26,0.899,0.438"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M11.608,23.785c0.12-0.313,0.271-0.615,0.454-0.906s0.398-0.568,0.643-0.828h0.001l0,0h0.001v-0.002h0.001l0,0h0.001
      c0.247-0.26,0.512-0.488,0.793-0.686"
        />

        <polyline
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
      13.625,22.924 13.626,22.922 13.627,22.922 			"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
      M22.068,31.74c-0.134,0.309-0.444,0.529-0.678,0.621c-0.47,0.186-0.976,0.273-1.49,0.264c-0.514-0.012-1.035-0.119-1.532-0.324
      s-0.971-0.508-1.39-0.908l-0.738-0.701l-0.737-0.701l-0.738-0.699l-0.737-0.703c-0.42-0.398-0.747-0.855-0.977-1.342
      s-0.364-1-0.401-1.514c-0.036-0.512,0.025-1.023,0.187-1.502"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
      M16.378,21.813c-0.257-0.008-0.512,0.012-0.762,0.057c-0.249,0.043-0.493,0.111-0.728,0.205"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
      M23.628,28.703c-0.018-0.256-0.061-0.514-0.128-0.766c-0.067-0.254-0.158-0.504-0.273-0.748
      c-0.116-0.242-0.254-0.479-0.417-0.703c-0.163-0.225-0.349-0.439-0.559-0.639l-0.369-0.35l-0.369-0.352l-0.369-0.352
      l-0.369-0.352l-0.369-0.35l-0.368-0.35l-0.369-0.352l-0.369-0.35c-0.21-0.201-0.433-0.375-0.666-0.527
      c-0.233-0.15-0.476-0.277-0.724-0.381"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M25.625,25.625c0.235,0.342,0.431,0.703,0.59,1.078"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M10.667,21.904c0.229-0.361,0.496-0.705,0.802-1.027h0.001l0.001-0.002l0,0h0.001l0.001-0.002l0,0h0.001
      c0.305-0.322,0.636-0.605,0.985-0.854c0.348-0.246,0.716-0.455,1.095-0.625c0.38-0.17,0.772-0.303,1.17-0.396
      c0.398-0.094,0.802-0.148,1.207-0.168c0.405-0.016,0.81,0.006,1.21,0.066c0.4,0.061,0.793,0.162,1.176,0.299
      c0.382,0.139,0.753,0.316,1.105,0.535c0.353,0.217,0.689,0.473,1,0.77l0.548,0.521l0.548,0.52l0.547,0.521l0.549,0.521
      l0.547,0.52l0.548,0.521l0.548,0.52"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M19.214,35.602c-0.399-0.061-0.793-0.16-1.176-0.299c-0.382-0.139-0.752-0.316-1.105-0.533s-0.688-0.473-1-0.771l-0.549-0.52
      l-0.547-0.521l-0.548-0.52l-0.548-0.521l-0.547-0.52l-0.548-0.521l-0.548-0.521l-0.547-0.52c-0.312-0.297-0.585-0.619-0.82-0.961
      c-0.235-0.342-0.432-0.701-0.59-1.076c-0.157-0.375-0.277-0.764-0.358-1.16c-0.08-0.396-0.122-0.799-0.126-1.203
      c-0.004-0.406,0.031-0.813,0.105-1.215"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M26.257,31.469c-0.151,0.389-0.34,0.766-0.569,1.127c-0.228,0.359-0.496,0.705-0.802,1.025l-0.001,0.002h-0.001l-0.001,0.002
      l-0.001,0.002l0,0c-0.306,0.322-0.637,0.605-0.986,0.854c-0.348,0.246-0.715,0.453-1.095,0.625
      c-0.379,0.17-0.771,0.303-1.169,0.396"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M18.441,28.338c0,0-0.589-0.563-1.469-1.396c-0.582-0.553,0.206-1.756,1.347-0.756c0.816,0.717,1.748,1.662,1.748,1.662"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M18.516,29.594c0,0-2.475-2.029-2.726-2.59c-0.209-0.471-0.367-1.547,0.678-2.17c1.041-0.621,1.699-0.02,2.428,0.563
      c2.541,2.035,2.507,2.814,2.507,2.814"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M14.355,26.949c-0.355-0.668-0.645-1.82,0.555-2.965c1.249-1.191,2.312-0.814,2.874-0.439"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.555"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M18.095,30.627c0,0,0.903,0.953,2.101,0.709c1.198-0.244,1.484-0.99,1.484-0.99"
        />
        <rect
          x="12.108"
          y="7.271"
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          width="0.982"
          height="3.984"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M16.811,9.424v1.832h-0.946V9.627c0-0.352-0.208-0.59-0.511-0.59
      c-0.375,0-0.618,0.256-0.618,0.834v1.385h-0.951V8.27h0.951v0.285c0.226-0.236,0.541-0.373,0.915-0.373
      C16.347,8.182,16.811,8.676,16.811,9.424"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          points="19.163,11.256 18.193,11.256 16.957,8.264 17.961,8.264
      18.681,10.107 19.395,8.264 20.399,8.264 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M23.523,10.008h-2.188c0.078,0.355,0.322,0.576,0.642,0.576
      c0.221,0,0.476-0.053,0.637-0.344l0.844,0.172c-0.255,0.629-0.797,0.932-1.481,0.932c-0.862,0-1.575-0.635-1.575-1.58
      c0-0.939,0.713-1.582,1.582-1.582c0.844,0,1.522,0.607,1.539,1.582V10.008z M21.354,9.424h1.232
      c-0.091-0.32-0.328-0.469-0.601-0.469C21.716,8.955,21.443,9.121,21.354,9.424"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M24.701,10.287c0.041,0.254,0.273,0.35,0.529,0.35
      c0.25,0,0.387-0.105,0.387-0.236c0-0.104-0.078-0.18-0.298-0.221l-0.607-0.119c-0.552-0.102-0.867-0.398-0.867-0.867
      c0-0.613,0.529-1.012,1.284-1.012c0.743,0,1.237,0.34,1.356,0.855l-0.868,0.174c-0.031-0.186-0.209-0.352-0.5-0.352
      c-0.255,0-0.339,0.125-0.339,0.232c0,0.082,0.036,0.166,0.226,0.207l0.701,0.143c0.566,0.121,0.828,0.459,0.828,0.898
      c0,0.66-0.571,1.004-1.356,1.004c-0.702,0-1.303-0.254-1.403-0.867L24.701,10.287z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M29.257,11.125c-0.232,0.125-0.476,0.219-0.815,0.219
      c-0.744,0-1.189-0.402-1.189-1.188V9.027h-0.541V8.27h0.541V7.391h0.951V8.27h0.88v0.758h-0.88v1.033
      c0,0.309,0.149,0.434,0.398,0.434c0.132,0,0.31-0.047,0.435-0.119L29.257,11.125z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M30.659,7.391c0,0.305-0.232,0.529-0.554,0.529
      c-0.321,0-0.547-0.225-0.547-0.529c0-0.291,0.226-0.541,0.547-0.541C30.427,6.85,30.659,7.1,30.659,7.391 M29.629,8.27h0.952
      v2.986h-0.952V8.27z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M34.301,10.959c0,0.92-0.701,1.426-1.67,1.426
      c-0.685,0-1.208-0.236-1.499-0.719l0.79-0.428c0.226,0.266,0.393,0.369,0.701,0.369c0.436,0,0.744-0.256,0.744-0.727v-0.084
      c-0.214,0.186-0.511,0.285-0.896,0.285c-0.779,0-1.44-0.617-1.44-1.461c0-0.828,0.661-1.439,1.44-1.439
      c0.386,0,0.683,0.102,0.896,0.285V8.271h0.934V10.959z M33.367,9.621c0-0.4-0.314-0.68-0.695-0.68
      c-0.416,0-0.713,0.279-0.713,0.68c0,0.398,0.297,0.682,0.713,0.682C33.053,10.303,33.367,10.02,33.367,9.621"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M38.102,11.256H37.15v-0.238c-0.231,0.207-0.541,0.326-0.928,0.326
      c-0.791,0-1.457-0.635-1.457-1.58c0-0.939,0.666-1.582,1.457-1.582c0.387,0,0.696,0.119,0.928,0.326V8.27h0.951V11.256z
       M37.15,9.764c0-0.459-0.344-0.773-0.73-0.773c-0.417,0-0.72,0.314-0.72,0.773c0,0.463,0.303,0.773,0.72,0.773
      C36.807,10.537,37.15,10.227,37.15,9.764"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M40.975,11.125c-0.232,0.125-0.477,0.219-0.815,0.219
      c-0.743,0-1.188-0.402-1.188-1.188V9.027H38.43V8.27h0.541V7.391h0.951V8.27h0.879v0.758h-0.879v1.033
      c0,0.309,0.148,0.434,0.398,0.434c0.131,0,0.309-0.047,0.434-0.119L40.975,11.125z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M42.376,7.391c0,0.305-0.231,0.529-0.554,0.529
      c-0.32,0-0.547-0.225-0.547-0.529c0-0.291,0.227-0.541,0.547-0.541C42.145,6.85,42.376,7.1,42.376,7.391 M41.348,8.27h0.951
      v2.986h-0.951V8.27z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M45.98,9.424v1.832h-0.945V9.627c0-0.352-0.208-0.59-0.512-0.59
      c-0.375,0-0.618,0.256-0.618,0.834v1.385h-0.951V8.27h0.951v0.285c0.226-0.238,0.541-0.373,0.916-0.373
      C45.516,8.182,45.98,8.676,45.98,9.424"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#7B2959"
          d="M49.662,10.959c0,0.92-0.701,1.426-1.671,1.426
      c-0.685,0-1.207-0.236-1.498-0.719l0.79-0.428c0.227,0.266,0.393,0.369,0.701,0.369c0.436,0,0.744-0.256,0.744-0.727v-0.084
      c-0.215,0.186-0.512,0.285-0.896,0.285c-0.779,0-1.441-0.617-1.441-1.461c0-0.828,0.662-1.439,1.441-1.439
      c0.385,0,0.682,0.102,0.896,0.285V8.271h0.934V10.959z M48.729,9.621c0-0.4-0.315-0.68-0.696-0.68
      c-0.416,0-0.713,0.279-0.713,0.68c0,0.398,0.297,0.682,0.713,0.682C48.413,10.303,48.729,10.02,48.729,9.621"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_8_)"
          fill="#AC6A93"
          points="25.595,39.754 45.811,59.971 59.4,59.738 59.4,35.383 42.182,18.162
            "
        />
      </g>
      <g>
        <defs>
          <rect
            id="INVESTIGATING_SVGID_9_"
            x="19.683"
            y="13.206"
            transform="matrix(-0.7071 -0.7072 0.7072 -0.7071 37.313 73.0897)"
            width="28.225"
            height="31.222"
          />
        </defs>
        <clipPath id="INVESTIGATING_SVGID_10_">
          <use xlinkHref="#INVESTIGATING_SVGID_9_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#INVESTIGATING_SVGID_10_)"
          fill="#F6ADCD"
          d="M43.563,38.584c5.043-5.043,5.043-13.221,0-18.262
      c-5.043-5.045-13.219-5.045-18.262,0c-5.043,5.043-5.043,13.219,0,18.262C30.342,43.627,38.52,43.627,43.563,38.584"
        />
      </g>
      <line
        fill="none"
        stroke="#97A0A4"
        strokeWidth="1.798"
        x1="48.133"
        y1="43.125"
        x2="43.916"
        y2="38.908"
      />
      <g>
        <defs>
          <rect
            id="INVESTIGATING_SVGID_11_"
            y="0.002"
            width="74.906"
            height="61.686"
          />
        </defs>
        <clipPath id="INVESTIGATING_SVGID_12_">
          <use xlinkHref="#INVESTIGATING_SVGID_11_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#INVESTIGATING_SVGID_12_)"
          fill="#213466"
          d="M60.877,51.117l-2.947,6.766L45.84,45.795
      c-0.406-0.406-0.32-1.15,0.191-1.66l3.006-3.008c0.512-0.512,1.254-0.596,1.66-0.189L60.877,51.117z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_12_)"
          fill="#EACBD9"
          d="M56.028,4.408c0.688,0,1.271,0.582,1.271,1.27v50.334
      c0,0.689-0.582,1.271-1.271,1.271H5.694c-0.689,0-1.27-0.582-1.27-1.271V5.678c0-0.688,0.581-1.27,1.27-1.27H56.028
       M56.028,0.002H5.694c-3.122,0-5.677,2.553-5.677,5.676v50.334c0,3.121,2.555,5.676,5.677,5.676h50.334
      c3.122,0,5.677-2.555,5.677-5.676V5.678C61.705,2.555,59.15,0.002,56.028,0.002"
        />
      </g>
      <g>
        <defs>
          <path
            id="INVESTIGATING_SVGID_13_"
            d="M25.299,20.32c-5.042,5.043-5.042,13.221,0,18.264c5.044,5.043,13.221,5.043,18.264,0
        s5.043-13.221,0-18.264c-2.521-2.521-5.826-3.781-9.131-3.781C31.126,16.539,27.821,17.799,25.299,20.32"
          />
        </defs>
        <clipPath id="INVESTIGATING_SVGID_14_">
          <use xlinkHref="#INVESTIGATING_SVGID_13_" overflow="visible" />
        </clipPath>

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M24.542,40.131l-0.809-0.77l-0.811-0.771l-0.809-0.77l-0.81-0.77l-0.81-0.77l-0.81-0.77c-0.461-0.439-0.865-0.914-1.214-1.416
      c-0.348-0.504-0.64-1.035-0.876-1.588c-0.236-0.551-0.416-1.123-0.539-1.703c-0.122-0.582-0.189-1.176-0.199-1.77"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M27.637,42.811c-0.521-0.322-1.016-0.703-1.477-1.141"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M33.416,22.686l0.81,0.77l0.81,0.771l0.809,0.77l0.811,0.77l0.811,0.77l0.81,0.77l0.81,0.77c0.461,0.438,0.865,0.914,1.213,1.418
      c0.35,0.502,0.642,1.035,0.879,1.586c0.234,0.553,0.414,1.123,0.537,1.705s0.189,1.174,0.198,1.768
      c0.011,0.594-0.036,1.191-0.14,1.781s-0.264,1.172-0.482,1.738c-0.217,0.566-0.492,1.119-0.822,1.645
      c-0.332,0.527-0.721,1.031-1.166,1.5h-0.002l0,0v0.002h-0.002v0.002h-0.002v0.002c-0.447,0.469-0.929,0.883-1.438,1.24
      c-0.509,0.357-1.046,0.66-1.601,0.906c-0.556,0.246-1.129,0.436-1.713,0.568c-0.582,0.135-1.177,0.211-1.771,0.23
      c-0.595,0.02-1.189-0.016-1.777-0.109"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M24.224,19.65c0.583-0.133,1.177-0.209,1.771-0.23c0.594-0.02,1.189,0.018,1.777,0.109c0.586,0.094,1.166,0.244,1.729,0.453
      c0.562,0.207,1.108,0.471,1.629,0.793"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M17.477,25.516c0.218-0.566,0.492-1.117,0.824-1.645c0.331-0.527,0.72-1.029,1.166-1.498l0.001-0.002l0,0l0.001-0.002h0.001
      h0.001l0.001-0.002l0,0l0.001-0.002c0.446-0.469,0.928-0.881,1.437-1.24"
        />

        <polyline
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
      21.133,23.953 21.135,23.951 21.136,23.951 21.137,23.949 			"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
      M26.122,21.938c-0.465-0.01-0.927,0.025-1.38,0.104c-0.452,0.08-0.895,0.203-1.319,0.373"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
      M39.261,34.428c-0.033-0.465-0.11-0.93-0.231-1.389c-0.121-0.461-0.287-0.914-0.496-1.354c-0.209-0.441-0.461-0.869-0.756-1.277
      c-0.295-0.406-0.633-0.795-1.013-1.156l-0.668-0.637l-0.669-0.635l-0.669-0.635l-0.669-0.637l-0.668-0.635l-0.668-0.635
      l-0.668-0.635l-0.669-0.637c-0.379-0.361-0.784-0.68-1.207-0.953c-0.422-0.273-0.862-0.504-1.312-0.691"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M42.879,28.85c0.426,0.619,0.781,1.273,1.068,1.953"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M31.262,46.93c-0.724-0.109-1.438-0.291-2.131-0.541c-0.692-0.252-1.364-0.574-2.004-0.967c-0.64-0.395-1.247-0.859-1.812-1.396
      l-0.993-0.943l-0.993-0.943l-0.992-0.945l-0.993-0.943l-0.993-0.943l-0.993-0.943l-0.992-0.943l-0.993-0.943
      c-0.565-0.537-1.06-1.121-1.486-1.74c-0.425-0.619-0.782-1.273-1.067-1.953c-0.287-0.678-0.503-1.383-0.649-2.1
      c-0.147-0.717-0.224-1.449-0.23-2.184c-0.007-0.734,0.057-1.473,0.19-2.201"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M44.025,39.439c-0.273,0.703-0.617,1.387-1.031,2.041s-0.898,1.277-1.453,1.861l-0.002,0.002l0,0l-0.002,0.002l0,0l-0.002,0.002
      l0,0l-0.002,0.002l0,0c-0.556,0.584-1.154,1.1-1.787,1.545c-0.632,0.449-1.297,0.826-1.984,1.135s-1.397,0.549-2.119,0.719"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M29.862,33.766c0,0-1.068-1.018-2.664-2.533c-1.054-0.998,0.376-3.178,2.441-1.367c1.48,1.297,3.17,3.012,3.17,3.012"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M29.996,36.043c0,0-4.486-3.68-4.939-4.695c-0.38-0.852-0.667-2.805,1.229-3.934c1.886-1.123,3.078-0.033,4.399,1.021
      c4.604,3.688,4.544,5.098,4.544,5.098"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M22.456,31.248c-0.642-1.213-1.169-3.297,1.006-5.373c2.264-2.158,4.191-1.475,5.209-0.797"
        />

        <path
          clipPath="url(#INVESTIGATING_SVGID_14_)"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
      M29.234,37.914c0,0,1.636,1.727,3.809,1.285c2.17-0.443,2.688-1.797,2.688-1.797"
        />
      </g>
      <g>
        <defs>
          <rect
            id="INVESTIGATING_SVGID_15_"
            y="0.002"
            width="74.906"
            height="61.686"
          />
        </defs>
        <clipPath id="INVESTIGATING_SVGID_16_">
          <use xlinkHref="#INVESTIGATING_SVGID_15_" overflow="visible" />
        </clipPath>
        <circle
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="none"
          stroke="#97A0A4"
          strokeWidth="1.798"
          cx="34.431"
          cy="29.453"
          r="12.913"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M41.521,22.277c0,1.273-1.033,2.307-2.306,2.307s-2.306-1.033-2.306-2.307
      c0-1.271,1.033-2.305,2.306-2.305S41.521,21.006,41.521,22.277"
        />
        <g clipPath="url(#INVESTIGATING_SVGID_16_)">
          <defs>
            <path
              id="INVESTIGATING_SVGID_17_"
              d="M99.064,55.443V6.246c0-3.051,2.498-5.549,5.549-5.549h49.197c3.053,0,5.549,2.498,5.549,5.549v49.197
          c0,3.051-2.496,5.549-5.549,5.549h-49.197C101.563,60.992,99.064,58.494,99.064,55.443"
            />
          </defs>
          <clipPath id="INVESTIGATING_SVGID_18_">
            <use xlinkHref="#INVESTIGATING_SVGID_17_" overflow="visible" />
          </clipPath>
          <rect
            x="71.298"
            y="28.271"
            clipPath="url(#INVESTIGATING_SVGID_18_)"
            fill="#C8CCCC"
            width="44.876"
            height="4.719"
          />
          <rect
            x="71.298"
            y="30.453"
            clipPath="url(#INVESTIGATING_SVGID_18_)"
            fill="#97A0A4"
            width="44.876"
            height="2.537"
          />
          <path
            clipPath="url(#INVESTIGATING_SVGID_18_)"
            fill="#C8CCCC"
            d="M72.191,29.832v1.596v0.926c0,0.438-0.339,0.678-0.753,0.531l-2.084-0.73
        c-0.413-0.145-1.092-0.383-1.506-0.527l-2.083-0.732c-0.415-0.145-0.415-0.383,0-0.527l2.083-0.732
        c0.414-0.145,1.093-0.383,1.506-0.527l2.084-0.732c0.414-0.145,0.753,0.094,0.753,0.535V29.832z"
          />
        </g>
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#5087C7"
          d="M30.863,55.5c-2.746,0-4.979-2.234-4.979-4.979
      c0-2.746,2.233-4.979,4.979-4.979c2.746,0,4.98,2.232,4.98,4.979C35.843,53.266,33.608,55.5,30.863,55.5"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M30.863,45.748c2.638,0,4.773,2.137,4.773,4.773s-2.136,4.771-4.773,4.771
      c-2.638,0-4.774-2.135-4.774-4.771S28.225,45.748,30.863,45.748 M30.863,45.336c-2.86,0-5.186,2.326-5.186,5.186
      c0,2.857,2.326,5.184,5.186,5.184c2.859,0,5.186-2.326,5.186-5.184C36.049,47.662,33.723,45.336,30.863,45.336"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M33.357,48.576c-0.24,0-0.466,0.107-0.621,0.25l-0.013-0.012
      c0.072-0.119,0.168-0.346,0.168-0.717c0-0.883-0.884-1.551-2.029-1.551s-2.041,0.668-2.041,1.551c0,0.371,0.12,0.598,0.18,0.717
      l-0.013,0.012c-0.155-0.143-0.381-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.016c0,1.289,0.716,2.018,1.492,2.018
      c0.716,0,1.218-0.396,1.67-0.848c-0.071-0.107-0.179-0.217-0.298-0.217c-0.119,0-0.251,0.086-0.477,0.266
      c-0.298,0.227-0.561,0.357-0.908,0.357c-0.608,0-0.87-0.682-0.87-1.576c0-0.955,0.251-1.586,0.896-1.586
      c0.286,0,0.476,0.082,0.667,0.262c0.286,0.264,0.382,0.334,0.513,0.334c0.096,0,0.156-0.061,0.179-0.096
      c-0.214-0.215-0.525-0.668-0.525-1.252c0-0.668,0.561-1.135,1.611-1.135c1.05,0,1.611,0.467,1.611,1.135
      c0,0.584-0.31,1.037-0.525,1.252c0.024,0.035,0.084,0.096,0.179,0.096c0.132,0,0.227-0.07,0.513-0.334
      c0.18-0.18,0.383-0.262,0.668-0.262c0.645,0,0.895,0.631,0.895,1.586c0,0.895-0.262,1.576-0.869,1.576
      c-0.346,0-0.621-0.131-0.908-0.357c-0.227-0.18-0.357-0.266-0.477-0.266c-0.119,0-0.226,0.109-0.298,0.217
      c0.441,0.451,0.955,0.848,1.671,0.848c0.774,0,1.49-0.729,1.49-2.018C34.813,49.279,34.084,48.576,33.357,48.576"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M31.663,48.553h-0.537c-0.036,0-0.059-0.025-0.071-0.049l-0.168-0.512
      c-0.012-0.037-0.024-0.037-0.035,0l-0.168,0.512c-0.012,0.035-0.035,0.049-0.06,0.049h-0.537c-0.024,0-0.035,0.01-0.012,0.023
      l0.442,0.322c0.024,0.021,0.024,0.059,0.012,0.084l-0.167,0.5c-0.011,0.023-0.011,0.049,0.024,0.023l0.442-0.309
      c0.035-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.035,0.012,0.023-0.012l-0.167-0.512
      c-0.012-0.037,0-0.072,0.024-0.084l0.441-0.322C31.698,48.563,31.686,48.553,31.663,48.553"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M31.257,52.084c-0.226-0.574-0.406-0.967-0.406-1.443
      c0-0.205,0.06-0.383,0.096-0.477c0.047-0.098,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.166-0.322,0.311
      c-0.072,0.117-0.155,0.346-0.155,0.523c0,0.336,0.108,0.693,0.179,0.908c0.132,0.369,0.251,0.799,0.251,1.205
      c0,0.645-0.287,1.084-0.8,1.479c0.048,0.037,0.167,0.096,0.311,0.096c0.5,0,1.097-0.561,1.097-1.324
      C31.519,52.752,31.388,52.441,31.257,52.084"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#5087C7"
          d="M30.854,55.361c-2.638,0-4.773-2.135-4.773-4.771s2.135-4.773,4.773-4.773
      s4.774,2.137,4.774,4.773S33.492,55.361,30.854,55.361"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M30.854,45.816c2.638,0,4.774,2.137,4.774,4.773s-2.136,4.771-4.774,4.771
      s-4.773-2.135-4.773-4.771S28.216,45.816,30.854,45.816 M30.854,45.404c-2.859,0-5.185,2.326-5.185,5.186
      c0,2.857,2.326,5.184,5.185,5.184c2.86,0,5.186-2.326,5.186-5.184C36.04,47.73,33.714,45.404,30.854,45.404"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M33.348,48.645c-0.239,0-0.464,0.107-0.62,0.25l-0.013-0.012
      c0.072-0.119,0.169-0.346,0.169-0.715c0-0.883-0.884-1.551-2.029-1.551s-2.041,0.668-2.041,1.551
      c0,0.369,0.119,0.596,0.179,0.715l-0.013,0.012c-0.154-0.143-0.381-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.018
      c0,1.287,0.717,2.016,1.492,2.016c0.716,0,1.218-0.395,1.671-0.848c-0.072-0.107-0.18-0.215-0.298-0.215
      c-0.12,0-0.252,0.084-0.477,0.264c-0.299,0.227-0.562,0.357-0.908,0.357c-0.609,0-0.87-0.682-0.87-1.574
      c0-0.955,0.251-1.588,0.895-1.588c0.286,0,0.476,0.084,0.668,0.264c0.286,0.262,0.381,0.332,0.512,0.332
      c0.096,0,0.156-0.059,0.18-0.094c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.67,0.56-1.135,1.611-1.135
      c1.049,0,1.611,0.465,1.611,1.135c0,0.584-0.312,1.037-0.525,1.252c0.023,0.035,0.083,0.094,0.179,0.094
      c0.131,0,0.227-0.07,0.513-0.332c0.179-0.18,0.382-0.264,0.668-0.264c0.646,0,0.896,0.633,0.896,1.588
      c0,0.893-0.263,1.574-0.871,1.574c-0.346,0-0.621-0.131-0.908-0.357c-0.226-0.18-0.356-0.264-0.476-0.264
      c-0.12,0-0.227,0.107-0.299,0.215c0.44,0.453,0.955,0.848,1.671,0.848c0.775,0,1.491-0.729,1.491-2.016
      C34.804,49.348,34.076,48.645,33.348,48.645"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M31.653,48.621h-0.537c-0.035,0-0.059-0.025-0.071-0.049l-0.168-0.512
      c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.512c-0.012,0.037-0.035,0.049-0.059,0.049h-0.537c-0.024,0-0.036,0.012-0.012,0.023
      l0.442,0.322c0.023,0.023,0.023,0.059,0.011,0.084l-0.167,0.5c-0.011,0.023-0.011,0.049,0.024,0.023l0.442-0.309
      c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.036,0.014,0.023-0.012l-0.166-0.512
      c-0.012-0.037,0-0.072,0.024-0.084l0.44-0.322C31.689,48.633,31.678,48.621,31.653,48.621"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M31.248,52.152c-0.226-0.572-0.406-0.967-0.406-1.443
      c0-0.203,0.06-0.383,0.096-0.477c0.048-0.096,0.06-0.156,0.012-0.156c-0.072,0-0.227,0.166-0.322,0.311
      c-0.073,0.119-0.156,0.346-0.156,0.523c0,0.336,0.108,0.693,0.179,0.908c0.132,0.371,0.251,0.799,0.251,1.205
      c0,0.646-0.287,1.086-0.799,1.48c0.047,0.035,0.167,0.096,0.31,0.096c0.5,0,1.098-0.563,1.098-1.326
      C31.511,52.82,31.38,52.51,31.248,52.152"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-11.977-25.726c0.076,0,0.14,0.012,0.192,0.038
      c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.107,0.02,0.163
      c0,0.076-0.011,0.143-0.034,0.198c-0.023,0.058-0.054,0.104-0.095,0.14s-0.09,0.063-0.148,0.081
      c-0.058,0.018-0.123,0.026-0.193,0.026h-0.16v0.591h-0.298v-1.479H-11.977z M-12.068-25.057c0.059,0,0.107-0.018,0.144-0.054
      s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.059-0.159-0.059h-0.125v0.45H-12.068z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          points="-10.973,-25.726 -10.762,-24.681 -10.757,-24.681 -10.544,-25.726
      -10.104,-25.726 -10.104,-24.247 -10.376,-24.247 -10.376,-25.428 -10.38,-25.428 -10.644,-24.247 -10.876,-24.247
      -11.139,-25.428 -11.144,-25.428 -11.144,-24.247 -11.417,-24.247 -11.417,-25.726 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-9.649-24.666c0,0.035,0.003,0.066,0.009,0.095
      c0.004,0.028,0.015,0.053,0.03,0.071c0.015,0.02,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.018,0.096,0.018
      c0.045,0,0.087-0.016,0.123-0.045s0.056-0.075,0.056-0.138c0-0.034-0.005-0.062-0.014-0.086
      c-0.009-0.023-0.024-0.046-0.045-0.065s-0.048-0.036-0.082-0.054c-0.033-0.015-0.075-0.03-0.125-0.048
      c-0.066-0.021-0.123-0.046-0.172-0.073c-0.048-0.025-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
      c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.326c0.081-0.072,0.193-0.108,0.336-0.108
      c0.066,0,0.127,0.007,0.183,0.021c0.056,0.016,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124
      c0.023,0.051,0.034,0.111,0.034,0.183v0.041h-0.285c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.058-0.124-0.058
      c-0.033,0-0.061,0.005-0.083,0.016c-0.023,0.009-0.04,0.021-0.053,0.036c-0.013,0.017-0.022,0.035-0.027,0.056
      s-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.06,0.124,0.086l0.23,0.1
      c0.057,0.024,0.103,0.051,0.139,0.077c0.036,0.027,0.065,0.057,0.086,0.088c0.022,0.03,0.036,0.065,0.045,0.102
      c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341s-0.216,0.107-0.378,0.107c-0.168,0-0.289-0.037-0.362-0.11
      c-0.072-0.073-0.108-0.179-0.108-0.314v-0.062h0.298V-24.666z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-7.805-25.473c-0.02-0.031-0.056-0.046-0.105-0.046
      c-0.039,0-0.07,0.01-0.095,0.027c-0.023,0.02-0.042,0.045-0.056,0.078c-0.013,0.033-0.021,0.073-0.025,0.118
      C-8.09-25.25-8.093-25.2-8.093-25.146v0.072h0.005c0.025-0.039,0.06-0.068,0.107-0.092c0.046-0.022,0.102-0.034,0.168-0.034
      c0.065,0,0.12,0.012,0.166,0.034c0.045,0.023,0.082,0.054,0.111,0.095c0.028,0.041,0.049,0.09,0.062,0.146
      c0.013,0.056,0.02,0.117,0.02,0.184c0,0.169-0.038,0.298-0.113,0.387c-0.075,0.088-0.196,0.132-0.362,0.132
      c-0.102,0-0.183-0.019-0.244-0.055c-0.061-0.035-0.108-0.085-0.14-0.147c-0.033-0.064-0.054-0.136-0.064-0.219
      c-0.009-0.083-0.014-0.169-0.014-0.26c0-0.104,0.003-0.203,0.009-0.302c0.006-0.097,0.025-0.184,0.057-0.259
      c0.03-0.075,0.079-0.135,0.145-0.181c0.067-0.046,0.161-0.068,0.282-0.068c0.052,0,0.104,0.006,0.152,0.018
      c0.05,0.014,0.093,0.033,0.13,0.063c0.037,0.029,0.068,0.068,0.091,0.115c0.022,0.048,0.034,0.106,0.035,0.178h-0.286
      C-7.776-25.398-7.785-25.443-7.805-25.473 M-8.072-24.586c0.005,0.036,0.012,0.067,0.024,0.092
      c0.012,0.026,0.028,0.046,0.05,0.061c0.021,0.015,0.049,0.021,0.084,0.021c0.036,0,0.064-0.007,0.086-0.021
      c0.021-0.015,0.037-0.034,0.048-0.062c0.012-0.025,0.019-0.057,0.022-0.092c0.004-0.035,0.005-0.074,0.005-0.115
      c0-0.1-0.012-0.171-0.037-0.214s-0.066-0.063-0.124-0.063c-0.06,0-0.102,0.021-0.126,0.063c-0.025,0.043-0.038,0.114-0.038,0.214
      C-8.078-24.662-8.076-24.623-8.072-24.586"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-6.476-25.457c-0.061,0.086-0.115,0.178-0.162,0.274
      c-0.048,0.1-0.09,0.2-0.126,0.304c-0.036,0.103-0.066,0.21-0.089,0.315c-0.024,0.108-0.042,0.213-0.054,0.316H-7.22
      c0.011-0.088,0.027-0.182,0.05-0.278c0.022-0.098,0.052-0.197,0.088-0.298c0.036-0.101,0.081-0.202,0.133-0.304
      c0.053-0.104,0.115-0.203,0.187-0.302H-7.38v-0.257h0.904V-25.457z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-6.382-25.225c0.006-0.094,0.025-0.177,0.056-0.249
      c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.148-0.066,0.256-0.066c0.091,0,0.165,0.014,0.224,0.039
      c0.058,0.025,0.105,0.066,0.14,0.123c0.035,0.058,0.06,0.13,0.075,0.22c0.014,0.092,0.022,0.201,0.022,0.331
      c0,0.103-0.004,0.2-0.011,0.294c-0.007,0.095-0.025,0.179-0.055,0.25c-0.031,0.072-0.077,0.131-0.139,0.172
      c-0.061,0.042-0.146,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.059-0.023-0.107-0.063-0.142-0.12
      c-0.036-0.056-0.06-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C-6.394-25.033-6.39-25.131-6.382-25.225 M-6.087-24.639
      c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.029,0.058,0.05,0.069c0.021,0.013,0.046,0.018,0.078,0.018
      c0.034,0,0.061-0.007,0.081-0.022c0.021-0.016,0.037-0.043,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.153
      c0.004-0.063,0.007-0.142,0.007-0.235v-0.159c0-0.093-0.003-0.168-0.007-0.228c-0.005-0.059-0.014-0.104-0.026-0.139
      c-0.013-0.034-0.029-0.057-0.05-0.069s-0.047-0.019-0.079-0.019c-0.033,0-0.059,0.008-0.08,0.024
      c-0.021,0.016-0.038,0.043-0.049,0.08c-0.012,0.038-0.02,0.089-0.025,0.152c-0.005,0.063-0.007,0.143-0.007,0.236v0.16
      C-6.095-24.774-6.093-24.698-6.087-24.639"
        />
        <rect
          x="-3.385"
          y="-26.436"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#F6ADCD"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-2.472-25.936c0.076,0,0.14,0.012,0.192,0.036
      c0.052,0.026,0.094,0.061,0.125,0.102c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.055,0.02,0.108,0.02,0.163
      c0,0.076-0.011,0.144-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
      c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.472z M-2.563-25.267c0.059,0,0.107-0.019,0.144-0.055
      c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H-2.563z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          points="-1.469,-25.937 -1.258,-24.892 -1.253,-24.892 -1.04,-25.937
      -0.598,-25.937 -0.598,-24.458 -0.872,-24.458 -0.872,-25.639 -0.876,-25.639 -1.139,-24.458 -1.372,-24.458 -1.634,-25.639
      -1.638,-25.639 -1.638,-24.458 -1.912,-24.458 -1.912,-25.937 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M-0.144-24.875c0,0.033,0.003,0.065,0.008,0.094s0.016,0.052,0.031,0.072
      c0.015,0.019,0.036,0.034,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.014,0.123-0.044
      c0.037-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086c-0.008-0.025-0.024-0.047-0.044-0.066
      C0.152-25,0.125-25.018,0.091-25.035c-0.033-0.017-0.075-0.032-0.125-0.048c-0.066-0.022-0.124-0.046-0.172-0.073
      c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122c-0.015-0.046-0.023-0.101-0.023-0.161
      c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.008,0.184,0.021
      c0.056,0.015,0.104,0.039,0.144,0.071C0.406-25.841,0.437-25.8,0.46-25.75s0.034,0.11,0.034,0.182v0.041H0.208
      c0-0.071-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
      c-0.022,0.009-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
      c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
      c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.012,0.079,0.012,0.125
      c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.105-0.378,0.105c-0.169,0-0.29-0.036-0.362-0.108
      c-0.072-0.073-0.108-0.18-0.108-0.315v-0.061h0.298V-24.875z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M1.122-24.458c0-0.064,0.007-0.125,0.02-0.182
      c0.013-0.058,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.102,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.147l0.121-0.112
      c0.033-0.029,0.06-0.056,0.08-0.081s0.035-0.05,0.046-0.075c0.011-0.024,0.019-0.051,0.022-0.081
      c0.003-0.028,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.022
      c-0.022,0.015-0.038,0.036-0.05,0.063c-0.011,0.026-0.019,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.109H1.122V-25.5
      c0-0.137,0.037-0.242,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
      c0.072,0.07,0.108,0.168,0.108,0.298c0,0.047-0.004,0.09-0.013,0.131c-0.008,0.041-0.021,0.081-0.039,0.116
      c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.116
      c-0.024,0.039-0.041,0.071-0.047,0.1h0.553v0.231H1.122z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M2.128-25.435c0.006-0.095,0.025-0.178,0.056-0.25
      c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.147-0.066,0.256-0.066c0.09,0,0.164,0.014,0.223,0.039
      c0.059,0.026,0.106,0.067,0.141,0.123c0.035,0.057,0.06,0.13,0.075,0.221c0.014,0.091,0.022,0.201,0.022,0.33
      c0,0.103-0.004,0.2-0.011,0.295s-0.026,0.178-0.056,0.25c-0.03,0.072-0.077,0.129-0.138,0.17
      c-0.061,0.042-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.023-0.107-0.063-0.142-0.12
      c-0.036-0.056-0.061-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C2.117-25.243,2.12-25.341,2.128-25.435 M2.423-24.85
      c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.028,0.058,0.05,0.069c0.02,0.013,0.046,0.019,0.078,0.019
      c0.033,0,0.061-0.008,0.081-0.023c0.02-0.017,0.037-0.042,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.152
      c0.004-0.063,0.007-0.143,0.007-0.235v-0.16c0-0.093-0.003-0.168-0.007-0.228c-0.005-0.058-0.014-0.104-0.026-0.139
      c-0.013-0.033-0.03-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.008-0.08,0.023
      c-0.021,0.017-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.09-0.025,0.152c-0.005,0.064-0.008,0.142-0.008,0.236v0.161
      C2.415-24.984,2.418-24.909,2.423-24.85"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M3.437-24.691c0.021,0.045,0.064,0.068,0.13,0.068
      c0.035,0,0.064-0.007,0.085-0.02c0.022-0.013,0.038-0.028,0.05-0.051c0.012-0.021,0.019-0.047,0.023-0.075
      c0.003-0.028,0.005-0.06,0.005-0.091c0-0.034-0.002-0.064-0.007-0.095c-0.005-0.029-0.014-0.056-0.028-0.079
      c-0.014-0.024-0.033-0.043-0.058-0.057s-0.059-0.021-0.1-0.021H3.438v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.019
      c0.021-0.013,0.039-0.03,0.052-0.052c0.013-0.021,0.022-0.046,0.029-0.074c0.006-0.027,0.009-0.056,0.009-0.087
      c0-0.07-0.012-0.119-0.035-0.149C3.646-25.715,3.61-25.73,3.562-25.73c-0.032,0-0.058,0.006-0.077,0.018
      c-0.021,0.012-0.037,0.028-0.047,0.05c-0.011,0.021-0.019,0.047-0.022,0.076c-0.003,0.028-0.005,0.061-0.005,0.095H3.137
      c0-0.146,0.036-0.255,0.109-0.326c0.073-0.071,0.18-0.106,0.322-0.106c0.133,0,0.237,0.029,0.311,0.092
      c0.074,0.06,0.111,0.155,0.111,0.286c0,0.088-0.02,0.16-0.059,0.216s-0.094,0.091-0.164,0.106v0.005
      c0.094,0.015,0.162,0.053,0.201,0.113c0.04,0.063,0.06,0.14,0.06,0.232c0,0.049-0.007,0.101-0.019,0.153
      c-0.013,0.053-0.037,0.1-0.072,0.143C3.9-24.535,3.851-24.5,3.788-24.474c-0.062,0.026-0.146,0.039-0.248,0.039
      c-0.142,0-0.248-0.038-0.319-0.115c-0.07-0.077-0.105-0.188-0.105-0.329v-0.007h0.286C3.403-24.8,3.415-24.736,3.437-24.691"
        />
        <rect
          x="46.56"
          y="-26.436"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#5087C7"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M47.473-25.936c0.076,0,0.141,0.012,0.193,0.036
      c0.051,0.026,0.094,0.061,0.125,0.102c0.031,0.042,0.055,0.089,0.068,0.142c0.013,0.055,0.02,0.108,0.02,0.163
      c0,0.076-0.011,0.144-0.033,0.199c-0.023,0.057-0.055,0.103-0.096,0.139s-0.091,0.063-0.148,0.081
      c-0.057,0.018-0.122,0.027-0.192,0.027H47.25v0.59h-0.299v-1.479H47.473z M47.383-25.267c0.059,0,0.106-0.019,0.144-0.055
      s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.031-0.039-0.085-0.058-0.16-0.058H47.25v0.45H47.383z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          points="48.477,-25.937 48.688,-24.892 48.692,-24.892 48.905,-25.937
      49.348,-25.937 49.348,-24.458 49.073,-24.458 49.073,-25.639 49.069,-25.639 48.807,-24.458 48.573,-24.458 48.311,-25.639
      48.307,-25.639 48.307,-24.458 48.033,-24.458 48.033,-25.937 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M49.801-24.875c0,0.033,0.004,0.065,0.008,0.094
      c0.006,0.028,0.017,0.052,0.031,0.072c0.016,0.019,0.036,0.034,0.061,0.045c0.026,0.012,0.059,0.016,0.098,0.016
      c0.045,0,0.086-0.014,0.123-0.044c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.014-0.086
      c-0.008-0.025-0.023-0.047-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.048
      c-0.065-0.022-0.124-0.046-0.173-0.073c-0.049-0.026-0.088-0.057-0.12-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
      s-0.022-0.101-0.022-0.161c0-0.147,0.041-0.256,0.121-0.327c0.082-0.072,0.194-0.108,0.336-0.108
      c0.067,0,0.129,0.008,0.185,0.021c0.056,0.015,0.104,0.039,0.144,0.071c0.043,0.032,0.073,0.073,0.096,0.124
      c0.023,0.051,0.035,0.11,0.035,0.182v0.041h-0.287c0-0.071-0.012-0.125-0.037-0.162c-0.024-0.039-0.066-0.057-0.123-0.057
      c-0.033,0-0.061,0.004-0.083,0.014c-0.022,0.009-0.04,0.023-0.054,0.038c-0.014,0.016-0.021,0.035-0.026,0.055
      c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.03,0.06,0.059,0.123,0.086l0.23,0.099
      c0.057,0.025,0.104,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
      c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.271-0.135,0.342c-0.09,0.07-0.217,0.105-0.379,0.105
      c-0.168,0-0.289-0.036-0.361-0.108c-0.072-0.073-0.108-0.18-0.108-0.315v-0.061h0.298V-24.875z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M51.066-24.458c0-0.064,0.008-0.125,0.021-0.182
      c0.013-0.058,0.032-0.111,0.06-0.162s0.061-0.102,0.103-0.15c0.04-0.05,0.088-0.099,0.142-0.147l0.12-0.112
      c0.033-0.029,0.06-0.056,0.08-0.081s0.035-0.05,0.046-0.075c0.012-0.024,0.02-0.051,0.021-0.081
      c0.004-0.028,0.006-0.062,0.006-0.099c0-0.122-0.049-0.183-0.145-0.183c-0.037,0-0.068,0.008-0.089,0.022
      c-0.022,0.015-0.038,0.036-0.05,0.063s-0.02,0.057-0.023,0.092c-0.002,0.034-0.004,0.071-0.004,0.109h-0.287V-25.5
      c0-0.137,0.037-0.242,0.11-0.315s0.188-0.109,0.347-0.109c0.149,0,0.26,0.034,0.332,0.103c0.071,0.07,0.107,0.168,0.107,0.298
      c0,0.047-0.004,0.09-0.014,0.131c-0.008,0.041-0.021,0.081-0.039,0.116c-0.018,0.038-0.041,0.074-0.07,0.109
      c-0.027,0.035-0.064,0.072-0.105,0.109l-0.166,0.153c-0.043,0.04-0.078,0.079-0.104,0.116c-0.023,0.039-0.041,0.071-0.047,0.1
      h0.553v0.231H51.066z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M52.975-25.667c-0.062,0.086-0.117,0.178-0.164,0.275
      c-0.047,0.099-0.088,0.199-0.125,0.303c-0.035,0.104-0.065,0.21-0.09,0.316c-0.023,0.107-0.041,0.212-0.053,0.315h-0.314
      c0.013-0.088,0.028-0.182,0.051-0.278c0.021-0.098,0.052-0.197,0.089-0.297c0.036-0.102,0.081-0.203,0.133-0.305
      c0.054-0.104,0.115-0.203,0.187-0.3H52.07v-0.258h0.904V-25.667z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M53.396-25.494h-0.292v-0.195h0.026c0.101,0,0.178-0.018,0.236-0.055
      c0.057-0.038,0.094-0.098,0.108-0.181h0.219v1.467h-0.298V-25.494z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M54.105-25.11c0.041-0.066,0.104-0.108,0.187-0.124v-0.004
      c-0.04-0.011-0.074-0.025-0.101-0.047c-0.026-0.021-0.049-0.044-0.065-0.069c-0.017-0.027-0.028-0.055-0.035-0.083
      c-0.007-0.03-0.01-0.058-0.01-0.083c0-0.145,0.038-0.247,0.114-0.311c0.076-0.063,0.182-0.094,0.316-0.094
      s0.239,0.031,0.315,0.094c0.077,0.063,0.116,0.166,0.116,0.311c0,0.025-0.004,0.053-0.012,0.083
      c-0.008,0.028-0.02,0.056-0.037,0.083c-0.016,0.025-0.037,0.049-0.064,0.069c-0.027,0.021-0.061,0.036-0.1,0.047v0.004
      c0.084,0.016,0.146,0.058,0.188,0.124c0.041,0.066,0.063,0.146,0.063,0.238c0,0.043-0.006,0.09-0.016,0.142
      c-0.011,0.051-0.033,0.098-0.066,0.143c-0.033,0.044-0.08,0.08-0.142,0.109c-0.062,0.028-0.144,0.044-0.245,0.044
      s-0.184-0.016-0.246-0.044c-0.062-0.029-0.109-0.065-0.142-0.109c-0.033-0.045-0.056-0.092-0.065-0.143
      c-0.011-0.052-0.016-0.099-0.016-0.142C54.043-24.964,54.064-25.043,54.105-25.11 M54.35-24.778
      c0.004,0.031,0.014,0.059,0.026,0.081s0.03,0.041,0.054,0.055c0.021,0.013,0.049,0.019,0.082,0.019s0.061-0.006,0.084-0.019
      c0.02-0.014,0.038-0.032,0.051-0.055c0.014-0.022,0.023-0.05,0.027-0.081c0.006-0.03,0.008-0.063,0.008-0.099
      s-0.002-0.068-0.008-0.099c-0.004-0.031-0.014-0.058-0.027-0.08c-0.013-0.023-0.031-0.041-0.051-0.054
      c-0.023-0.014-0.051-0.02-0.084-0.02s-0.061,0.006-0.082,0.02c-0.023,0.013-0.041,0.03-0.054,0.054
      c-0.013,0.022-0.022,0.049-0.026,0.08c-0.006,0.03-0.008,0.063-0.008,0.099S54.344-24.808,54.35-24.778 M54.406-25.374
      c0.025,0.033,0.061,0.05,0.105,0.05s0.079-0.017,0.105-0.05c0.027-0.033,0.039-0.085,0.039-0.154
      c0-0.136-0.047-0.201-0.145-0.201c-0.097,0-0.145,0.065-0.145,0.201C54.367-25.458,54.38-25.407,54.406-25.374"
        />
        <rect
          x="6.483"
          y="-26.436"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#AC6A93"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M7.396-25.937c0.076,0,0.14,0.012,0.192,0.037
      c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.143c0.013,0.054,0.02,0.107,0.02,0.163
      c0,0.075-0.012,0.143-0.034,0.198c-0.023,0.058-0.055,0.104-0.095,0.14c-0.041,0.036-0.091,0.063-0.148,0.081
      c-0.058,0.017-0.123,0.026-0.193,0.026h-0.16v0.591H6.875v-1.479H7.396z M7.306-25.268c0.059,0,0.107-0.018,0.144-0.054
      s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.059-0.16-0.059H7.172v0.45H7.306z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          points="8.399,-25.937 8.61,-24.892 8.616,-24.892 8.829,-25.937
      9.271,-25.937 9.271,-24.458 8.997,-24.458 8.997,-25.639 8.993,-25.639 8.729,-24.458 8.497,-24.458 8.234,-25.639
      8.23,-25.639 8.23,-24.458 7.957,-24.458 7.957,-25.937 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M9.724-24.876c0,0.034,0.003,0.066,0.008,0.094
      c0.005,0.028,0.016,0.053,0.031,0.072s0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
      c0.045,0,0.086-0.015,0.123-0.045c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
      c-0.008-0.024-0.024-0.047-0.044-0.065c-0.021-0.02-0.048-0.036-0.082-0.054c-0.033-0.016-0.075-0.032-0.125-0.048
      c-0.066-0.021-0.124-0.046-0.172-0.073c-0.049-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
      c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.122-0.326c0.082-0.072,0.194-0.108,0.336-0.108
      c0.067,0,0.128,0.007,0.184,0.021c0.056,0.016,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124
      c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.125-0.037-0.161c-0.025-0.039-0.067-0.058-0.124-0.058
      c-0.033,0-0.061,0.004-0.083,0.015c-0.023,0.009-0.04,0.022-0.054,0.037c-0.013,0.017-0.021,0.035-0.026,0.056
      c-0.005,0.021-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.06,0.124,0.086l0.23,0.1
      c0.057,0.024,0.103,0.051,0.139,0.077c0.036,0.027,0.064,0.057,0.086,0.088c0.021,0.029,0.036,0.064,0.045,0.102
      c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
      c-0.169,0-0.29-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.314v-0.062h0.298V-24.876z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M11.567-25.685c-0.019-0.03-0.055-0.045-0.104-0.045
      c-0.039,0-0.07,0.01-0.095,0.027c-0.024,0.019-0.043,0.045-0.056,0.078s-0.022,0.073-0.026,0.118
      c-0.004,0.046-0.006,0.095-0.006,0.149v0.072h0.004c0.025-0.039,0.061-0.068,0.107-0.092c0.047-0.022,0.103-0.035,0.169-0.035
      c0.065,0,0.12,0.013,0.166,0.035c0.045,0.023,0.082,0.054,0.111,0.095c0.028,0.041,0.049,0.089,0.062,0.146
      c0.013,0.056,0.019,0.117,0.019,0.183c0,0.17-0.037,0.299-0.112,0.388c-0.076,0.088-0.196,0.131-0.362,0.131
      c-0.103,0-0.184-0.018-0.245-0.054c-0.06-0.035-0.107-0.086-0.139-0.147c-0.033-0.064-0.054-0.136-0.064-0.219
      c-0.009-0.083-0.014-0.169-0.014-0.26c0-0.104,0.003-0.205,0.009-0.302c0.006-0.098,0.025-0.184,0.056-0.259
      c0.031-0.075,0.08-0.135,0.146-0.182c0.067-0.046,0.161-0.068,0.282-0.068c0.052,0,0.103,0.007,0.152,0.019
      c0.05,0.014,0.093,0.033,0.13,0.063s0.067,0.066,0.09,0.115c0.023,0.048,0.035,0.106,0.036,0.178h-0.286
      C11.598-25.61,11.588-25.654,11.567-25.685 M11.301-24.797c0.004,0.036,0.012,0.066,0.024,0.092
      c0.011,0.026,0.028,0.046,0.05,0.061c0.021,0.014,0.049,0.021,0.084,0.021s0.064-0.008,0.086-0.021
      c0.021-0.015,0.037-0.034,0.048-0.062c0.011-0.025,0.019-0.057,0.021-0.093c0.004-0.034,0.006-0.073,0.006-0.115
      c0-0.099-0.012-0.17-0.037-0.213s-0.066-0.063-0.124-0.063c-0.06,0-0.103,0.021-0.127,0.063
      c-0.025,0.043-0.037,0.114-0.037,0.213C11.295-24.873,11.297-24.833,11.301-24.797"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M12.039-25.111c0.042-0.066,0.104-0.108,0.187-0.124v-0.004
      c-0.04-0.01-0.074-0.025-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.07c-0.016-0.026-0.028-0.054-0.035-0.083
      c-0.007-0.03-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.115-0.31c0.076-0.063,0.181-0.094,0.316-0.094
      c0.134,0,0.239,0.03,0.315,0.094c0.077,0.063,0.116,0.166,0.116,0.31c0,0.026-0.004,0.053-0.012,0.083
      c-0.007,0.029-0.019,0.057-0.036,0.083c-0.016,0.026-0.038,0.049-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
      c0.083,0.016,0.145,0.058,0.187,0.124c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.005,0.09-0.015,0.14
      c-0.011,0.052-0.033,0.1-0.066,0.145c-0.033,0.044-0.08,0.08-0.142,0.108c-0.061,0.029-0.143,0.044-0.245,0.044
      s-0.184-0.015-0.246-0.044c-0.062-0.028-0.109-0.064-0.142-0.108c-0.033-0.045-0.055-0.093-0.065-0.145
      c-0.011-0.05-0.016-0.097-0.016-0.14C11.977-24.964,11.998-25.043,12.039-25.111 M12.283-24.779
      c0.005,0.032,0.014,0.059,0.027,0.082c0.013,0.022,0.031,0.041,0.053,0.054c0.022,0.014,0.05,0.02,0.083,0.02
      c0.033,0,0.06-0.006,0.083-0.02c0.021-0.013,0.039-0.031,0.052-0.054c0.013-0.023,0.023-0.05,0.027-0.082
      c0.005-0.03,0.008-0.063,0.008-0.098s-0.003-0.069-0.008-0.1c-0.004-0.03-0.014-0.057-0.027-0.08
      c-0.013-0.022-0.031-0.041-0.052-0.054c-0.023-0.013-0.05-0.019-0.083-0.019c-0.033,0-0.061,0.006-0.083,0.019
      s-0.04,0.031-0.053,0.054c-0.013,0.023-0.022,0.05-0.027,0.08s-0.007,0.064-0.007,0.1S12.278-24.809,12.283-24.779 M12.34-25.374
      c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155
      c0-0.135-0.048-0.201-0.145-0.201c-0.097,0-0.145,0.066-0.145,0.201C12.301-25.458,12.314-25.407,12.34-25.374"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M13.32-25.495h-0.292v-0.194h0.027c0.1,0,0.177-0.019,0.235-0.056
      c0.057-0.037,0.094-0.098,0.109-0.181h0.219v1.467H13.32V-25.495z"
        />
        <rect
          x="16.364"
          y="-26.436"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#7B2959"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M17.277-25.937c0.076,0,0.14,0.012,0.192,0.037
      c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.143c0.013,0.054,0.02,0.107,0.02,0.163
      c0,0.075-0.011,0.143-0.034,0.198c-0.023,0.058-0.055,0.104-0.095,0.14c-0.041,0.036-0.091,0.063-0.148,0.081
      c-0.058,0.017-0.123,0.026-0.193,0.026h-0.16v0.591h-0.298v-1.479H17.277z M17.186-25.268c0.059,0,0.107-0.018,0.144-0.054
      s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.059-0.16-0.059h-0.124v0.45H17.186z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          points="18.28,-25.937 18.491,-24.892 18.496,-24.892 18.709,-25.937
      19.151,-25.937 19.151,-24.458 18.877,-24.458 18.877,-25.639 18.873,-25.639 18.61,-24.458 18.377,-24.458 18.115,-25.639
      18.111,-25.639 18.111,-24.458 17.837,-24.458 17.837,-25.937 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M19.605-24.876c0,0.034,0.003,0.066,0.008,0.094
      c0.005,0.028,0.016,0.053,0.031,0.072c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
      c0.045,0,0.086-0.015,0.123-0.045c0.036-0.028,0.055-0.074,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
      c-0.008-0.024-0.024-0.047-0.044-0.065c-0.021-0.02-0.048-0.036-0.082-0.054c-0.033-0.016-0.075-0.032-0.125-0.048
      c-0.066-0.021-0.124-0.046-0.172-0.073c-0.049-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
      c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.122-0.327c0.082-0.071,0.194-0.107,0.336-0.107
      c0.067,0,0.128,0.007,0.184,0.021c0.056,0.016,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124
      c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.125-0.037-0.161c-0.025-0.039-0.067-0.058-0.124-0.058
      c-0.033,0-0.061,0.004-0.083,0.015c-0.022,0.009-0.04,0.022-0.054,0.037c-0.013,0.017-0.021,0.035-0.026,0.056
      s-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.06,0.124,0.086l0.23,0.1
      c0.057,0.024,0.103,0.051,0.139,0.077c0.036,0.027,0.064,0.057,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.103
      c0.007,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341s-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
      c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.876z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M21.448-25.685c-0.019-0.03-0.055-0.045-0.104-0.045
      c-0.039,0-0.07,0.01-0.095,0.027c-0.024,0.019-0.043,0.045-0.056,0.078c-0.013,0.033-0.022,0.073-0.026,0.118
      c-0.004,0.046-0.006,0.095-0.006,0.149v0.072h0.004c0.025-0.039,0.061-0.068,0.107-0.092c0.047-0.022,0.103-0.035,0.169-0.035
      c0.065,0,0.12,0.013,0.166,0.035c0.045,0.023,0.082,0.054,0.111,0.095c0.028,0.041,0.049,0.089,0.062,0.146
      c0.013,0.056,0.019,0.117,0.019,0.183c0,0.17-0.037,0.299-0.112,0.388c-0.076,0.088-0.196,0.131-0.362,0.131
      c-0.103,0-0.184-0.018-0.245-0.054c-0.06-0.035-0.107-0.086-0.139-0.147c-0.033-0.064-0.054-0.136-0.064-0.219
      c-0.009-0.083-0.014-0.169-0.014-0.26c0-0.104,0.003-0.205,0.009-0.302c0.006-0.098,0.025-0.184,0.056-0.259
      c0.031-0.075,0.08-0.135,0.146-0.182c0.067-0.046,0.161-0.068,0.282-0.068c0.052,0,0.103,0.007,0.152,0.019
      c0.05,0.014,0.093,0.033,0.13,0.063c0.037,0.029,0.067,0.066,0.09,0.115c0.023,0.048,0.035,0.106,0.036,0.178h-0.286
      C21.478-25.61,21.468-25.654,21.448-25.685 M21.182-24.797c0.004,0.036,0.012,0.066,0.024,0.092
      c0.011,0.026,0.028,0.046,0.05,0.061c0.021,0.014,0.049,0.021,0.084,0.021c0.035,0,0.064-0.008,0.085-0.021
      c0.022-0.015,0.038-0.034,0.049-0.062c0.011-0.025,0.019-0.057,0.021-0.093c0.004-0.034,0.006-0.073,0.006-0.115
      c0-0.099-0.012-0.17-0.037-0.213s-0.066-0.063-0.124-0.063c-0.06,0-0.103,0.021-0.127,0.063
      c-0.025,0.043-0.037,0.114-0.037,0.213C21.176-24.873,21.178-24.833,21.182-24.797"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M21.919-25.111c0.042-0.066,0.104-0.108,0.187-0.124v-0.004
      c-0.04-0.01-0.074-0.025-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.07c-0.016-0.026-0.028-0.054-0.035-0.083
      c-0.007-0.03-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.115-0.31c0.076-0.063,0.181-0.095,0.316-0.095
      c0.134,0,0.239,0.031,0.315,0.095c0.077,0.063,0.116,0.166,0.116,0.31c0,0.026-0.004,0.053-0.012,0.083
      c-0.007,0.029-0.019,0.057-0.036,0.083c-0.016,0.026-0.038,0.049-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
      c0.083,0.016,0.145,0.058,0.187,0.124c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.005,0.09-0.015,0.14
      c-0.011,0.052-0.033,0.1-0.066,0.145c-0.033,0.044-0.08,0.08-0.142,0.108c-0.061,0.029-0.143,0.044-0.245,0.044
      s-0.184-0.015-0.246-0.044c-0.062-0.028-0.109-0.064-0.142-0.108c-0.033-0.045-0.055-0.093-0.065-0.145
      c-0.011-0.05-0.016-0.097-0.016-0.14C21.857-24.964,21.878-25.043,21.919-25.111 M22.163-24.779
      c0.005,0.032,0.014,0.059,0.027,0.082c0.013,0.021,0.031,0.041,0.053,0.054c0.022,0.014,0.05,0.02,0.083,0.02
      s0.06-0.006,0.083-0.02c0.021-0.013,0.039-0.032,0.052-0.054c0.013-0.023,0.023-0.05,0.027-0.082
      c0.005-0.03,0.008-0.063,0.008-0.098s-0.003-0.069-0.008-0.1c-0.004-0.03-0.014-0.057-0.027-0.08
      c-0.013-0.022-0.031-0.041-0.052-0.054c-0.023-0.013-0.05-0.019-0.083-0.019s-0.061,0.006-0.083,0.019s-0.04,0.031-0.053,0.054
      c-0.013,0.023-0.022,0.05-0.027,0.08s-0.007,0.064-0.007,0.1S22.158-24.809,22.163-24.779 M22.22-25.374
      c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155
      c0-0.135-0.048-0.201-0.145-0.201s-0.145,0.066-0.145,0.201C22.181-25.458,22.194-25.407,22.22-25.374"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M23.185-24.691c0.021,0.045,0.064,0.067,0.13,0.067
      c0.035,0,0.064-0.007,0.085-0.019c0.022-0.013,0.038-0.029,0.05-0.051s0.019-0.047,0.023-0.075
      c0.003-0.029,0.005-0.061,0.005-0.091c0-0.034-0.002-0.065-0.007-0.096c-0.005-0.028-0.014-0.056-0.028-0.078
      c-0.014-0.024-0.033-0.044-0.058-0.058c-0.025-0.013-0.059-0.021-0.1-0.021h-0.099v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.02
      c0.021-0.013,0.039-0.029,0.052-0.051c0.013-0.021,0.022-0.047,0.029-0.075c0.006-0.026,0.009-0.056,0.009-0.087
      c0-0.069-0.012-0.118-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.006-0.077,0.017
      c-0.021,0.012-0.037,0.029-0.047,0.05c-0.011,0.022-0.019,0.048-0.022,0.076c-0.003,0.029-0.005,0.062-0.005,0.095h-0.273
      c0-0.146,0.036-0.255,0.109-0.325c0.073-0.071,0.18-0.107,0.322-0.107c0.133,0,0.237,0.03,0.311,0.092
      c0.074,0.061,0.111,0.155,0.111,0.286c0,0.088-0.02,0.16-0.059,0.216c-0.039,0.057-0.094,0.092-0.164,0.107v0.004
      c0.094,0.015,0.162,0.053,0.201,0.115c0.04,0.061,0.06,0.138,0.06,0.23c0,0.049-0.006,0.102-0.019,0.153
      c-0.013,0.053-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.078-0.149,0.104c-0.062,0.026-0.146,0.039-0.248,0.039
      c-0.142,0-0.248-0.038-0.319-0.116c-0.07-0.077-0.105-0.187-0.105-0.329v-0.007h0.286C23.152-24.801,23.163-24.736,23.185-24.691
      "
        />
        <rect
          x="26.534"
          y="-26.436"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#97A0A4"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M27.447-25.937c0.076,0,0.14,0.012,0.192,0.037
      c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.143c0.013,0.054,0.02,0.107,0.02,0.163
      c0,0.075-0.011,0.143-0.034,0.198c-0.023,0.058-0.054,0.104-0.095,0.14s-0.09,0.063-0.148,0.081
      c-0.058,0.017-0.123,0.026-0.193,0.026h-0.16v0.591h-0.298v-1.479H27.447z M27.356-25.268c0.059,0,0.107-0.018,0.144-0.054
      s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.059-0.159-0.059h-0.125v0.45H27.356z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          points="28.451,-25.937 28.662,-24.892 28.667,-24.892 28.879,-25.937
      29.32,-25.937 29.32,-24.458 29.047,-24.458 29.047,-25.639 29.043,-25.639 28.78,-24.458 28.547,-24.458 28.285,-25.639
      28.28,-25.639 28.28,-24.458 28.006,-24.458 28.006,-25.937 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M29.775-24.876c0,0.034,0.003,0.066,0.009,0.094
      c0.004,0.028,0.015,0.053,0.03,0.072c0.015,0.02,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
      c0.045,0,0.087-0.015,0.123-0.045c0.036-0.028,0.056-0.074,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
      c-0.009-0.024-0.024-0.047-0.045-0.065c-0.021-0.02-0.048-0.036-0.082-0.054c-0.033-0.016-0.075-0.032-0.125-0.048
      c-0.066-0.021-0.123-0.046-0.172-0.073c-0.048-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
      s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.123-0.327c0.081-0.071,0.193-0.107,0.336-0.107c0.066,0,0.127,0.007,0.183,0.021
      c0.056,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285
      c0-0.07-0.013-0.125-0.038-0.161c-0.024-0.039-0.066-0.058-0.124-0.058c-0.033,0-0.061,0.004-0.083,0.015
      c-0.023,0.009-0.04,0.022-0.053,0.037c-0.013,0.017-0.022,0.035-0.027,0.056s-0.007,0.042-0.007,0.064
      c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.06,0.124,0.086l0.23,0.1c0.057,0.024,0.103,0.051,0.139,0.077
      c0.036,0.027,0.065,0.057,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.103c0.008,0.038,0.012,0.079,0.012,0.125
      c0,0.156-0.046,0.27-0.136,0.341s-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.036-0.362-0.109
      c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.876z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M31.519-25.926h0.309v0.932h0.142v0.231h-0.142v0.306h-0.273v-0.306h-0.522
      v-0.244L31.519-25.926z M31.551-25.552l-0.287,0.558h0.291v-0.558H31.551z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M32.036-24.458c0-0.065,0.007-0.125,0.021-0.183
      c0.012-0.057,0.032-0.11,0.059-0.162c0.027-0.051,0.061-0.101,0.102-0.149c0.041-0.05,0.088-0.1,0.143-0.148l0.121-0.112
      c0.033-0.028,0.06-0.056,0.078-0.08c0.021-0.025,0.037-0.051,0.047-0.075c0.012-0.025,0.02-0.051,0.022-0.081
      c0.003-0.029,0.005-0.063,0.005-0.1c0-0.121-0.048-0.183-0.145-0.183c-0.039,0-0.067,0.008-0.088,0.023
      c-0.022,0.015-0.039,0.035-0.051,0.062c-0.012,0.027-0.02,0.057-0.022,0.092c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286V-25.5
      c0-0.138,0.036-0.242,0.11-0.316c0.072-0.073,0.188-0.109,0.346-0.109c0.148,0,0.26,0.034,0.332,0.104
      c0.07,0.07,0.107,0.168,0.107,0.297c0,0.047-0.005,0.09-0.013,0.131c-0.009,0.041-0.021,0.081-0.039,0.117
      c-0.019,0.038-0.042,0.074-0.071,0.109c-0.029,0.034-0.063,0.071-0.105,0.108l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
      c-0.024,0.039-0.041,0.07-0.048,0.099h0.554v0.232H32.036z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M33.374-24.67c0.019,0.032,0.054,0.047,0.104,0.047
      c0.041,0,0.072-0.009,0.096-0.028c0.023-0.019,0.042-0.045,0.055-0.078c0.014-0.032,0.021-0.072,0.025-0.117
      c0.005-0.045,0.007-0.096,0.007-0.149v-0.07h-0.005c-0.023,0.037-0.061,0.066-0.105,0.09c-0.047,0.022-0.104,0.034-0.17,0.034
      c-0.064,0-0.119-0.012-0.164-0.034c-0.048-0.023-0.084-0.055-0.113-0.096c-0.027-0.041-0.049-0.089-0.062-0.145
      c-0.013-0.057-0.019-0.117-0.019-0.184c0-0.17,0.037-0.3,0.111-0.39c0.076-0.09,0.196-0.135,0.362-0.135
      c0.103,0,0.185,0.019,0.244,0.058c0.062,0.037,0.108,0.088,0.14,0.151c0.033,0.063,0.055,0.135,0.064,0.219
      c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.003,0.206-0.009,0.304s-0.024,0.184-0.056,0.258
      c-0.031,0.075-0.08,0.133-0.147,0.177c-0.067,0.043-0.161,0.065-0.28,0.065c-0.054,0-0.104-0.005-0.154-0.016
      c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.066-0.066-0.09-0.114c-0.022-0.048-0.035-0.107-0.037-0.178h0.287
      C33.344-24.745,33.354-24.702,33.374-24.67 M33.358-25.226c0.024,0.042,0.065,0.063,0.124,0.063s0.102-0.021,0.127-0.063
      c0.025-0.043,0.037-0.115,0.037-0.214c0-0.042-0.002-0.081-0.006-0.116c-0.004-0.036-0.012-0.066-0.024-0.093
      c-0.011-0.024-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022s-0.064,0.009-0.086,0.022
      c-0.021,0.015-0.037,0.035-0.049,0.061c-0.01,0.026-0.019,0.058-0.021,0.093c-0.003,0.036-0.005,0.073-0.005,0.115
      C33.321-25.341,33.333-25.269,33.358-25.226"
        />
        <rect
          x="36.633"
          y="-26.436"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#213466"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M37.547-25.937c0.076,0,0.14,0.012,0.191,0.037
      c0.053,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.143c0.013,0.054,0.021,0.107,0.021,0.163
      c0,0.075-0.012,0.143-0.035,0.198c-0.022,0.058-0.053,0.104-0.094,0.14s-0.09,0.063-0.148,0.081
      c-0.059,0.017-0.123,0.026-0.193,0.026h-0.16v0.59h-0.297v-1.479H37.547z M37.455-25.268c0.06,0,0.107-0.018,0.145-0.054
      s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.059-0.159-0.059h-0.125v0.45H37.455z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          points="38.551,-25.937 38.762,-24.892 38.766,-24.892 38.979,-25.937
      39.42,-25.937 39.42,-24.458 39.146,-24.458 39.146,-25.639 39.143,-25.639 38.881,-24.458 38.646,-24.458 38.385,-25.639
      38.381,-25.639 38.381,-24.458 38.105,-24.458 38.105,-25.937 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M39.875-24.876c0,0.034,0.002,0.066,0.008,0.094
      c0.004,0.028,0.016,0.053,0.031,0.072c0.014,0.02,0.035,0.035,0.061,0.045c0.025,0.012,0.059,0.016,0.097,0.016
      c0.045,0,0.087-0.014,0.123-0.044c0.036-0.028,0.056-0.074,0.056-0.137c0-0.034-0.004-0.062-0.014-0.086
      c-0.009-0.024-0.023-0.047-0.045-0.065c-0.021-0.02-0.048-0.036-0.082-0.054c-0.033-0.016-0.075-0.032-0.125-0.048
      c-0.066-0.021-0.123-0.046-0.172-0.073c-0.049-0.025-0.09-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
      c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.256,0.123-0.327s0.193-0.107,0.336-0.107c0.066,0,0.127,0.007,0.184,0.021
      c0.057,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.096,0.124c0.023,0.051,0.033,0.111,0.033,0.182v0.041h-0.284
      c0-0.07-0.013-0.125-0.038-0.161c-0.023-0.039-0.066-0.058-0.124-0.058c-0.033,0-0.062,0.004-0.083,0.015
      c-0.023,0.009-0.04,0.022-0.053,0.037c-0.014,0.017-0.022,0.035-0.027,0.056s-0.007,0.042-0.007,0.064
      c0,0.045,0.01,0.083,0.028,0.115c0.02,0.031,0.062,0.059,0.125,0.086l0.229,0.099c0.058,0.025,0.103,0.052,0.14,0.078
      c0.035,0.027,0.064,0.057,0.086,0.087s0.035,0.065,0.045,0.103c0.008,0.038,0.012,0.079,0.012,0.125
      c0,0.156-0.047,0.27-0.137,0.341s-0.215,0.106-0.377,0.106c-0.168,0-0.289-0.036-0.362-0.109
      c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.876z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M41.141-24.458c0-0.065,0.008-0.125,0.02-0.183
      c0.014-0.057,0.033-0.11,0.061-0.162c0.027-0.051,0.061-0.101,0.102-0.149c0.041-0.05,0.088-0.1,0.142-0.148l0.121-0.112
      c0.032-0.028,0.06-0.056,0.079-0.08c0.021-0.025,0.035-0.051,0.047-0.075c0.011-0.025,0.02-0.051,0.021-0.081
      c0.004-0.029,0.006-0.063,0.006-0.1c0-0.121-0.049-0.183-0.146-0.183c-0.037,0-0.067,0.008-0.088,0.023
      c-0.022,0.015-0.038,0.035-0.05,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286V-25.5
      c0-0.138,0.036-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.261,0.034,0.332,0.104
      c0.071,0.07,0.106,0.168,0.106,0.297c0,0.047-0.004,0.09-0.012,0.131s-0.021,0.081-0.039,0.117
      c-0.018,0.038-0.041,0.074-0.07,0.109c-0.029,0.034-0.064,0.071-0.105,0.108l-0.166,0.154c-0.043,0.04-0.078,0.079-0.104,0.116
      c-0.025,0.039-0.041,0.07-0.049,0.099h0.555v0.232H41.141z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M43.047-25.668c-0.061,0.086-0.114,0.178-0.162,0.276
      c-0.047,0.098-0.09,0.199-0.125,0.302c-0.036,0.104-0.066,0.21-0.09,0.317c-0.023,0.106-0.041,0.212-0.053,0.314h-0.314
      c0.012-0.088,0.027-0.181,0.051-0.277c0.021-0.099,0.052-0.197,0.088-0.298c0.036-0.102,0.081-0.202,0.133-0.305
      c0.053-0.103,0.115-0.203,0.188-0.3h-0.618v-0.257h0.903V-25.668z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M43.608-25.926h0.31v0.932h0.142v0.231h-0.142v0.306h-0.273v-0.306h-0.521
      v-0.244L43.608-25.926z M43.641-25.552l-0.287,0.558h0.291v-0.558H43.641z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M45.037-25.668c-0.061,0.086-0.115,0.178-0.162,0.276
      c-0.048,0.098-0.09,0.199-0.126,0.302c-0.036,0.104-0.065,0.21-0.089,0.317c-0.023,0.106-0.042,0.212-0.055,0.314h-0.313
      c0.012-0.088,0.027-0.181,0.05-0.277c0.022-0.099,0.052-0.197,0.089-0.298c0.035-0.102,0.08-0.202,0.133-0.305
      s0.115-0.203,0.187-0.3h-0.618v-0.257h0.904V-25.668z"
        />
        <rect
          x="56.428"
          y="-26.436"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          width="8.813"
          height="8.813"
        />

        <rect
          x="56.428"
          y="-26.436"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.813"
          height="8.813"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          points="57.018,-25.937 57.182,-24.843 57.186,-24.843 57.359,-25.937 57.646,-25.937
      57.82,-24.843 57.824,-24.843 57.988,-25.937 58.273,-25.937 58.005,-24.458 57.671,-24.458 57.505,-25.531 57.501,-25.531
      57.335,-24.458 57.002,-24.458 56.732,-25.937 			"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          points="58.693,-25.937 58.693,-25.372 59.041,-25.372 59.041,-25.937 59.34,-25.937
      59.34,-24.458 59.041,-24.458 59.041,-25.115 58.693,-25.115 58.693,-24.458 58.395,-24.458 58.395,-25.937 			"
        />
        <rect
          x="59.561"
          y="-25.938"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          width="0.299"
          height="1.48"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          points="60.947,-25.693 60.625,-25.693 60.625,-24.458 60.326,-24.458 60.326,-25.693
      60.002,-25.693 60.002,-25.938 60.947,-25.938 			"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          points="61.902,-25.693 61.373,-25.693 61.373,-25.344 61.871,-25.344 61.871,-25.1
      61.373,-25.1 61.373,-24.702 61.924,-24.702 61.924,-24.458 61.076,-24.458 61.076,-25.938 61.902,-25.938 			"
        />
        <rect
          x="66.296"
          y="-26.436"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M67.21-25.937c0.076,0,0.14,0.012,0.192,0.038
      c0.052,0.025,0.094,0.059,0.125,0.1c0.031,0.042,0.055,0.089,0.068,0.143s0.021,0.107,0.021,0.163
      c0,0.075-0.011,0.143-0.034,0.198c-0.023,0.058-0.055,0.104-0.095,0.14c-0.041,0.036-0.091,0.063-0.148,0.081
      c-0.058,0.018-0.122,0.026-0.192,0.026h-0.16v0.59h-0.299v-1.479H67.21z M67.119-25.268c0.059,0,0.107-0.018,0.145-0.054
      s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.049-0.17c-0.031-0.039-0.084-0.059-0.16-0.059h-0.123v0.45H67.119z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          points="68.213,-25.937 68.424,-24.892 68.43,-24.892 68.643,-25.937
      69.084,-25.937 69.084,-24.458 68.811,-24.458 68.811,-25.639 68.807,-25.639 68.543,-24.458 68.311,-24.458 68.047,-25.639
      68.043,-25.639 68.043,-24.458 67.77,-24.458 67.77,-25.937 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M69.537-24.876c0,0.035,0.004,0.066,0.01,0.095
      c0.004,0.028,0.016,0.052,0.029,0.071c0.016,0.02,0.037,0.035,0.062,0.045c0.026,0.012,0.06,0.018,0.097,0.018
      c0.045,0,0.088-0.016,0.123-0.046c0.037-0.028,0.055-0.074,0.055-0.137c0-0.034-0.004-0.062-0.012-0.086
      c-0.01-0.023-0.025-0.047-0.045-0.065c-0.021-0.02-0.049-0.036-0.082-0.054c-0.033-0.016-0.076-0.032-0.125-0.048
      c-0.066-0.021-0.123-0.046-0.173-0.073c-0.049-0.025-0.089-0.056-0.12-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
      c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.071,0.193-0.107,0.336-0.107
      c0.066,0,0.127,0.007,0.184,0.021c0.056,0.015,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124
      c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.069-0.014-0.125-0.037-0.161c-0.025-0.039-0.068-0.058-0.125-0.058
      c-0.033,0-0.061,0.004-0.082,0.016c-0.023,0.008-0.041,0.021-0.054,0.036c-0.013,0.017-0.022,0.035-0.026,0.056
      c-0.006,0.021-0.008,0.042-0.008,0.064c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099
      c0.058,0.025,0.104,0.052,0.139,0.078c0.037,0.027,0.066,0.057,0.087,0.087c0.021,0.031,0.036,0.066,0.045,0.103
      c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341s-0.217,0.107-0.378,0.107c-0.168,0-0.289-0.037-0.362-0.11
      c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.297V-24.876z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M67.188-23.736c0.061,0,0.117,0.007,0.169,0.02
      c0.052,0.012,0.097,0.033,0.134,0.062c0.037,0.029,0.066,0.068,0.088,0.116c0.02,0.048,0.03,0.108,0.03,0.179
      c0,0.079-0.019,0.148-0.056,0.205c-0.039,0.059-0.094,0.098-0.168,0.117v0.004c0.082,0.012,0.147,0.047,0.197,0.107
      c0.047,0.059,0.071,0.138,0.071,0.238c0,0.058-0.007,0.112-0.022,0.165s-0.041,0.099-0.078,0.138
      c-0.036,0.04-0.084,0.071-0.143,0.094c-0.059,0.024-0.132,0.036-0.219,0.036h-0.503v-1.48H67.188z M67.07-23.128
      c0.084,0,0.145-0.014,0.182-0.044c0.039-0.029,0.058-0.08,0.058-0.151c0-0.069-0.017-0.119-0.052-0.148
      c-0.035-0.028-0.09-0.043-0.164-0.043h-0.108v0.387H67.07z M67.103-22.475c0.071,0,0.13-0.017,0.174-0.048
      c0.045-0.031,0.065-0.091,0.065-0.177c0-0.042-0.004-0.078-0.017-0.106c-0.012-0.028-0.028-0.05-0.048-0.068
      c-0.02-0.017-0.043-0.029-0.07-0.036c-0.029-0.007-0.059-0.01-0.093-0.01h-0.129v0.445H67.103z"
        />
        <rect
          x="67.843"
          y="-23.735"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          width="0.286"
          height="1.479"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M68.327-23.055c0-0.065,0.011-0.12,0.031-0.163
      c0.021-0.044,0.049-0.079,0.084-0.105c0.034-0.027,0.077-0.046,0.124-0.059c0.048-0.012,0.1-0.017,0.152-0.017
      c0.086,0,0.156,0.007,0.207,0.023c0.053,0.018,0.094,0.04,0.123,0.071c0.029,0.03,0.049,0.066,0.059,0.108
      c0.011,0.042,0.016,0.088,0.016,0.139v0.586c0,0.052,0.002,0.093,0.008,0.122c0.004,0.03,0.014,0.061,0.028,0.093h-0.274
      c-0.01-0.017-0.018-0.036-0.021-0.057c-0.005-0.021-0.01-0.039-0.014-0.059h-0.004c-0.033,0.059-0.071,0.096-0.114,0.112
      c-0.044,0.018-0.101,0.027-0.17,0.027c-0.05,0-0.093-0.01-0.126-0.027c-0.035-0.017-0.063-0.041-0.084-0.07
      c-0.02-0.03-0.035-0.065-0.045-0.104s-0.014-0.075-0.014-0.113c0-0.052,0.004-0.097,0.016-0.135s0.029-0.071,0.051-0.098
      c0.023-0.027,0.051-0.05,0.087-0.067c0.035-0.018,0.078-0.034,0.128-0.048l0.161-0.044c0.043-0.01,0.073-0.026,0.089-0.045
      c0.018-0.021,0.025-0.048,0.025-0.085c0-0.043-0.01-0.076-0.03-0.102c-0.021-0.023-0.054-0.035-0.103-0.035
      c-0.045,0-0.078,0.013-0.1,0.039s-0.033,0.062-0.033,0.106v0.03h-0.257V-23.055z M68.787-22.777
      c-0.021,0.009-0.042,0.016-0.061,0.02c-0.059,0.013-0.1,0.033-0.123,0.063c-0.025,0.029-0.038,0.068-0.038,0.119
      c0,0.042,0.009,0.078,0.024,0.108c0.018,0.03,0.045,0.046,0.084,0.046c0.019,0,0.039-0.004,0.06-0.01s0.04-0.016,0.058-0.03
      c0.016-0.013,0.03-0.031,0.043-0.054c0.01-0.021,0.016-0.048,0.016-0.079v-0.219C68.829-22.798,68.809-22.786,68.787-22.777"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          d="M69.857-23.049c-0.004-0.026-0.01-0.05-0.019-0.072
      c-0.009-0.021-0.021-0.038-0.038-0.051c-0.017-0.014-0.039-0.02-0.066-0.02c-0.034,0-0.063,0.008-0.084,0.023
      c-0.023,0.017-0.039,0.039-0.051,0.07c-0.011,0.031-0.019,0.07-0.022,0.118s-0.007,0.104-0.007,0.169
      c0,0.069,0.003,0.128,0.007,0.178c0.004,0.048,0.012,0.089,0.022,0.12c0.012,0.033,0.026,0.056,0.047,0.07
      c0.021,0.014,0.045,0.021,0.078,0.021c0.047,0,0.082-0.021,0.104-0.062c0.022-0.042,0.034-0.108,0.034-0.2h0.273
      c0,0.146-0.033,0.258-0.099,0.336c-0.065,0.076-0.175,0.115-0.326,0.115c-0.071,0-0.135-0.01-0.188-0.027
      c-0.053-0.017-0.096-0.048-0.131-0.093c-0.037-0.044-0.063-0.105-0.082-0.18c-0.018-0.075-0.025-0.17-0.025-0.281
      c0-0.115,0.011-0.21,0.033-0.285c0.021-0.075,0.053-0.135,0.093-0.18s0.088-0.077,0.144-0.095
      c0.055-0.018,0.115-0.026,0.182-0.026c0.133,0,0.232,0.038,0.3,0.115s0.101,0.183,0.101,0.315h-0.273
      C69.863-22.997,69.861-23.023,69.857-23.049"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#FFFFFF"
          points="70.582,-22.955 70.587,-22.955 70.874,-23.372 71.189,-23.372
      70.868,-22.931 71.237,-22.256 70.922,-22.256 70.684,-22.713 70.582,-22.581 70.582,-22.256 70.296,-22.256 70.296,-23.735
      70.582,-23.735 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M12.708,75.938c0.592,0,0.973,0.391,0.973,0.99
      c0,0.596-0.381,0.986-0.973,0.986c-0.588,0-0.969-0.391-0.969-0.986C11.739,76.328,12.12,75.938,12.708,75.938 M12.708,77.527
      c0.352,0,0.457-0.303,0.457-0.6c0-0.301-0.105-0.602-0.457-0.602c-0.349,0-0.454,0.301-0.454,0.602
      C12.254,77.225,12.359,77.527,12.708,77.527"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M14.667,75.988h0.378v0.344h-0.378v0.93c0,0.174,0.044,0.219,0.218,0.219
      c0.054,0,0.105-0.004,0.16-0.016v0.402c-0.087,0.014-0.2,0.02-0.302,0.02c-0.315,0-0.591-0.074-0.591-0.447v-1.107h-0.312v-0.344
      h0.312v-0.564h0.515V75.988z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M15.714,77.051c0.014,0.326,0.174,0.477,0.46,0.477
      c0.208,0,0.374-0.127,0.407-0.244h0.454c-0.146,0.443-0.454,0.631-0.879,0.631c-0.591,0-0.957-0.406-0.957-0.986
      c0-0.563,0.388-0.99,0.957-0.99c0.639,0,0.947,0.537,0.911,1.113H15.714z M16.553,76.725c-0.047-0.262-0.16-0.398-0.41-0.398
      c-0.327,0-0.421,0.254-0.428,0.398H16.553z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M17.771,77.254c0.004,0.225,0.193,0.316,0.396,0.316
      c0.149,0,0.337-0.059,0.337-0.24c0-0.156-0.214-0.209-0.584-0.289c-0.298-0.066-0.595-0.17-0.595-0.502
      c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.139,0.828,0.598h-0.49c-0.015-0.199-0.167-0.254-0.352-0.254
      c-0.116,0-0.287,0.021-0.287,0.176c0,0.184,0.29,0.209,0.584,0.279c0.302,0.068,0.596,0.178,0.596,0.525
      c0,0.494-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.162-0.882-0.66H17.771z"
        />
        <rect
          x="8.914"
          y="80.467"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M12.018,79.029h0.89c0.798,0,1.244,0.398,1.244,1.234
      c0,0.867-0.381,1.357-1.244,1.357h-0.89V79.029z M12.363,81.33h0.573c0.236,0,0.871-0.064,0.871-1.02
      c0-0.617-0.228-0.99-0.864-0.99h-0.58V81.33z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M15.33,79.701c0.595,0,0.911,0.432,0.911,0.984
      c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.419,80.133,14.735,79.701,15.33,79.701 M15.33,81.393
      c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
      C14.746,81.139,15.007,81.393,15.33,81.393"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M17.611,79.744h0.291v0.297h0.006c0.131-0.232,0.341-0.34,0.61-0.34
      c0.494,0,0.646,0.281,0.646,0.686v1.234h-0.308V80.35c0-0.227-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.061
      h-0.309V79.744z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M20.437,79.701c0.595,0,0.911,0.432,0.911,0.984
      c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.525,80.133,19.842,79.701,20.437,79.701 M20.437,81.393
      c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
      C19.853,81.139,20.114,81.393,20.437,81.393"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M22.138,79.744h0.374v0.271h-0.374v1.166c0,0.143,0.039,0.166,0.232,0.166
      h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195H21.51v-0.271h0.319v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M25.522,81.613c-0.055,0.033-0.123,0.051-0.222,0.051
      c-0.16,0-0.261-0.088-0.261-0.291c-0.17,0.201-0.399,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.527
      c0-0.424,0.316-0.514,0.635-0.576c0.341-0.064,0.632-0.043,0.632-0.275c0-0.27-0.222-0.313-0.418-0.313
      c-0.261,0-0.453,0.08-0.468,0.355h-0.309c0.018-0.465,0.378-0.627,0.795-0.627c0.338,0,0.704,0.076,0.704,0.514v0.967
      c0,0.145,0,0.209,0.098,0.209c0.026,0,0.055-0.002,0.095-0.018V81.613z M25.021,80.652c-0.12,0.086-0.352,0.09-0.559,0.127
      c-0.203,0.035-0.378,0.107-0.378,0.336c0,0.205,0.175,0.275,0.364,0.275c0.406,0,0.573-0.254,0.573-0.424V80.652z"
        />
        <rect
          x="25.826"
          y="79.029"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          width="0.308"
          height="2.592"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M27.041,79.744h0.374v0.271h-0.374v1.166c0,0.143,0.04,0.166,0.232,0.166
      h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M29.355,81.025c-0.083,0.414-0.381,0.639-0.802,0.639
      c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.619,0.849,1.074h-1.405
      c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.367H29.355z M29.058,80.502
      c-0.014-0.293-0.236-0.529-0.54-0.529c-0.323,0-0.516,0.242-0.538,0.529H29.058z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M29.692,79.744h0.291v0.396h0.006c0.149-0.301,0.356-0.451,0.69-0.439v0.326
      c-0.497,0-0.678,0.283-0.678,0.758v0.836h-0.309V79.744z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M33.581,81.613c-0.056,0.033-0.123,0.051-0.222,0.051
      c-0.16,0-0.262-0.088-0.262-0.291c-0.17,0.201-0.398,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.527
      c0-0.424,0.316-0.514,0.636-0.576c0.34-0.064,0.631-0.043,0.631-0.275c0-0.27-0.223-0.313-0.417-0.313
      c-0.263,0-0.454,0.08-0.47,0.355H31.89c0.018-0.465,0.378-0.627,0.796-0.627c0.336,0,0.703,0.076,0.703,0.514v0.967
      c0,0.145,0,0.209,0.098,0.209c0.025,0,0.055-0.002,0.095-0.018V81.613z M33.08,80.652c-0.119,0.086-0.352,0.09-0.559,0.127
      c-0.203,0.035-0.377,0.107-0.377,0.336c0,0.205,0.174,0.275,0.362,0.275c0.405,0,0.573-0.254,0.573-0.424V80.652z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M33.855,79.744h0.29v0.396h0.007c0.149-0.301,0.355-0.451,0.69-0.439v0.326
      c-0.497,0-0.679,0.283-0.679,0.758v0.836h-0.309V79.744z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M35.502,79.744h0.375v0.271h-0.375v1.166c0,0.143,0.039,0.166,0.232,0.166
      h0.143v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.318v-0.271h0.318v-0.563h0.309V79.744z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          points="38.074,81.621 37.748,81.621 37.367,80.129 37.359,80.129
      36.982,81.621 36.648,81.621 36.045,79.744 36.387,79.744 36.812,81.279 36.819,81.279 37.196,79.744 37.535,79.744
      37.926,81.279 37.934,81.279 38.354,79.744 38.674,79.744 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M39.775,79.701c0.595,0,0.91,0.432,0.91,0.984
      c0,0.547-0.315,0.979-0.91,0.979c-0.596,0-0.911-0.432-0.911-0.979C38.864,80.133,39.18,79.701,39.775,79.701 M39.775,81.393
      c0.322,0,0.584-0.254,0.584-0.707c0-0.459-0.262-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
      C39.191,81.139,39.452,81.393,39.775,81.393"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M41.037,79.744h0.29v0.396h0.007c0.148-0.301,0.355-0.451,0.689-0.439v0.326
      c-0.496,0-0.678,0.283-0.678,0.758v0.836h-0.309V79.744z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          points="42.273,79.029 42.582,79.029 42.582,80.564 43.439,79.744
      43.853,79.744 43.109,80.426 43.906,81.621 43.515,81.621 42.871,80.641 42.582,80.91 42.582,81.621 42.273,81.621 			"
        />
        <rect
          x="8.914"
          y="84.223"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M14.087,84.441c0,0.652-0.374,0.998-1.02,0.998
      c-0.668,0-1.063-0.309-1.063-0.998v-1.656h0.345v1.656c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.656
      h0.345V84.441z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M14.774,84.785c0.011,0.275,0.25,0.363,0.505,0.363
      c0.192,0,0.454-0.045,0.454-0.279c0-0.24-0.306-0.279-0.614-0.35c-0.305-0.068-0.614-0.17-0.614-0.529
      c0-0.377,0.374-0.533,0.701-0.533c0.414,0,0.744,0.131,0.769,0.588h-0.308c-0.022-0.24-0.233-0.316-0.435-0.316
      c-0.186,0-0.4,0.053-0.4,0.24c0,0.223,0.326,0.258,0.614,0.326c0.308,0.07,0.612,0.172,0.612,0.535
      c0,0.445-0.417,0.59-0.798,0.59c-0.421,0-0.777-0.17-0.794-0.635H14.774z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M17.999,84.783c-0.083,0.412-0.38,0.637-0.802,0.637
      c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.882-0.977c0.664,0,0.868,0.621,0.849,1.074h-1.404
      c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.365H17.999z M17.702,84.26
      c-0.014-0.295-0.236-0.531-0.541-0.531c-0.323,0-0.515,0.244-0.537,0.531H17.702z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M19.783,83.5h0.374v0.273h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
      h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319V83.5h0.319v-0.563h0.309V83.5z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M20.824,83.164h-0.308v-0.379h0.308V83.164z M20.517,83.5h0.308v1.877
      h-0.308V83.5z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M22.921,85.217c0,0.609-0.28,0.922-0.871,0.922
      c-0.352,0-0.759-0.143-0.777-0.551h0.309c0.014,0.225,0.283,0.305,0.486,0.305c0.403,0,0.562-0.291,0.562-0.711v-0.123h-0.007
      c-0.102,0.227-0.348,0.34-0.584,0.34c-0.559,0-0.838-0.438-0.838-0.951c0-0.441,0.217-0.99,0.867-0.99
      c0.236,0,0.446,0.105,0.559,0.313h0.003V83.5h0.291V85.217z M22.617,84.4c0-0.33-0.145-0.672-0.533-0.672
      c-0.396,0-0.556,0.324-0.556,0.684c0,0.336,0.124,0.713,0.53,0.713C22.464,85.125,22.617,84.754,22.617,84.4"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M23.385,82.785h0.309v0.992h0.007c0.102-0.225,0.366-0.32,0.591-0.32
      c0.494,0,0.646,0.283,0.646,0.688v1.232h-0.308v-1.27c0-0.23-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
      h-0.309V82.785z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M25.83,83.5h0.374v0.273H25.83v1.164c0,0.143,0.04,0.168,0.232,0.168h0.142
      v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319V83.5h0.319v-0.563h0.309V83.5z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M28.361,83.457c0.595,0,0.911,0.432,0.911,0.984
      c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.451,83.889,27.767,83.457,28.361,83.457 M28.361,85.148
      c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
      C27.777,84.895,28.039,85.148,28.361,85.148"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          points="30.479,85.377 30.148,85.377 29.453,83.5 29.797,83.5
      30.324,85.064 30.331,85.064 30.843,83.5 31.165,83.5 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M33.048,84.783c-0.083,0.412-0.381,0.637-0.802,0.637
      c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.662,0,0.867,0.621,0.849,1.074h-1.405
      c-0.011,0.326,0.175,0.617,0.585,0.617c0.255,0,0.433-0.123,0.486-0.365H33.048z M32.75,84.26
      c-0.014-0.295-0.236-0.531-0.54-0.531c-0.323,0-0.516,0.244-0.538,0.531H32.75z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M33.384,83.5h0.291v0.396h0.007c0.148-0.301,0.355-0.449,0.689-0.439v0.326
      c-0.497,0-0.678,0.283-0.678,0.758v0.836h-0.31V83.5z"
        />
        <rect
          x="34.621"
          y="82.785"
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          width="0.308"
          height="2.592"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M36.217,83.457c0.596,0,0.911,0.432,0.911,0.984
      c0,0.547-0.315,0.979-0.911,0.979c-0.595,0-0.91-0.432-0.91-0.979C35.307,83.889,35.622,83.457,36.217,83.457 M36.217,85.148
      c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.322,0-0.584,0.254-0.584,0.713
      C35.633,84.895,35.895,85.148,36.217,85.148"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M38.764,84.104c-0.048-0.236-0.21-0.375-0.464-0.375
      c-0.447,0-0.585,0.354-0.585,0.734c0,0.344,0.156,0.686,0.548,0.686c0.297,0,0.476-0.174,0.515-0.457h0.316
      c-0.068,0.457-0.355,0.729-0.827,0.729c-0.573,0-0.879-0.398-0.879-0.957c0-0.563,0.29-1.006,0.886-1.006
      c0.424,0,0.766,0.201,0.809,0.646H38.764z"
        />
        <polygon
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          points="39.455,82.785 39.764,82.785 39.764,84.32 40.621,83.5 41.034,83.5
      40.291,84.184 41.088,85.377 40.696,85.377 40.053,84.396 39.764,84.666 39.764,85.377 39.455,85.377 			"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M41.646,83.164h-0.309v-0.379h0.309V83.164z M41.338,83.5h0.309v1.877
      h-0.309V83.5z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M42.125,83.5h0.291v0.297h0.006c0.131-0.23,0.341-0.34,0.609-0.34
      c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.309v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.373,0-0.555,0.25-0.555,0.588v1.059
      h-0.309V83.5z"
        />
        <path
          clipPath="url(#INVESTIGATING_SVGID_16_)"
          fill="#EC008C"
          d="M45.76,85.217c0,0.609-0.279,0.922-0.871,0.922
      c-0.352,0-0.759-0.143-0.777-0.551h0.31c0.015,0.225,0.283,0.305,0.486,0.305c0.403,0,0.562-0.291,0.562-0.711v-0.123h-0.007
      c-0.103,0.227-0.349,0.34-0.584,0.34c-0.56,0-0.838-0.438-0.838-0.951c0-0.441,0.217-0.99,0.867-0.99
      c0.235,0,0.446,0.105,0.559,0.313h0.003V83.5h0.291V85.217z M45.455,84.4c0-0.33-0.145-0.672-0.533-0.672
      c-0.396,0-0.556,0.324-0.556,0.684c0,0.336,0.124,0.713,0.53,0.713S45.455,84.754,45.455,84.4"
        />
      </g>
    </g>
  );
};

const Upcycling = () => {
  return (
    <g id="Upcycling">
      <g>
        <defs>
          <rect
            id="UPCYCLING_SVGID_19_"
            x="0.176"
            width="61.688"
            height="61.688"
          />
        </defs>
        <clipPath id="UPCYCLING_SVGID_20_">
          <use xlinkHref="#UPCYCLING_SVGID_19_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#F6ADCD"
          d="M55.994,61.07H6.667c-3.059,0-5.563-2.504-5.563-5.563V6.18
      c0-3.061,2.504-5.564,5.563-5.564h49.327c3.061,0,5.563,2.504,5.563,5.564v49.328C61.557,58.566,59.055,61.07,55.994,61.07"
        />
        <rect
          x="4.228"
          y="43.596"
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#7B2959"
          width="53.228"
          height="15.23"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#7B2959"
          d="M20.58,9.459c0,1.141-0.707,1.885-1.766,1.885
      c-1.082,0-1.807-0.744-1.807-1.885V7.27h0.981v2.189c0,0.605,0.327,0.98,0.826,0.98c0.471,0,0.785-0.375,0.785-0.98V7.27h0.981
      V9.459z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#7B2959"
          d="M24.579,9.762c0,0.945-0.666,1.582-1.457,1.582
      c-0.386,0-0.696-0.119-0.927-0.328v1.25h-0.952V8.27h0.952v0.236c0.231-0.207,0.541-0.326,0.927-0.326
      C23.913,8.18,24.579,8.822,24.579,9.762 M23.645,9.762c0-0.459-0.303-0.773-0.719-0.773c-0.387,0-0.731,0.314-0.731,0.773
      c0,0.463,0.344,0.773,0.731,0.773C23.342,10.535,23.645,10.225,23.645,9.762"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#7B2959"
          d="M27.139,9.988l0.874,0.189c-0.149,0.707-0.779,1.164-1.522,1.164
      c-0.892,0-1.629-0.635-1.629-1.58c0-0.939,0.737-1.582,1.629-1.582c0.725,0,1.356,0.445,1.522,1.148l-0.892,0.213
      c-0.082-0.361-0.327-0.527-0.63-0.527c-0.428,0-0.714,0.314-0.714,0.748c0,0.439,0.286,0.75,0.714,0.75
      C26.793,10.512,27.039,10.35,27.139,9.988"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#7B2959"
          points="29.826,12.266 28.797,12.266 29.279,11.152 28.043,8.262
      29.065,8.262 29.773,10.006 30.528,8.262 31.551,8.262 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#7B2959"
          d="M33.825,9.988l0.874,0.189c-0.148,0.707-0.779,1.164-1.523,1.164
      c-0.891,0-1.628-0.635-1.628-1.58c0-0.939,0.738-1.582,1.628-1.582c0.727,0,1.356,0.445,1.523,1.148l-0.893,0.213
      c-0.084-0.361-0.326-0.527-0.631-0.527c-0.428,0-0.713,0.314-0.713,0.748c0,0.439,0.285,0.75,0.713,0.75
      C33.48,10.512,33.723,10.35,33.825,9.988"
        />
        <rect
          x="35.146"
          y="6.973"
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#7B2959"
          width="0.951"
          height="4.281"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#7B2959"
          d="M37.781,7.389c0,0.303-0.231,0.529-0.553,0.529
      c-0.32,0-0.547-0.227-0.547-0.529c0-0.291,0.227-0.541,0.547-0.541C37.55,6.848,37.781,7.098,37.781,7.389 M36.753,8.27h0.95
      v2.984h-0.95V8.27z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#7B2959"
          d="M41.387,9.422v1.832h-0.945V9.625c0-0.35-0.209-0.588-0.514-0.588
      c-0.373,0-0.617,0.254-0.617,0.832v1.385h-0.951V8.27h0.951v0.285c0.227-0.238,0.541-0.375,0.916-0.375
      C40.922,8.18,41.387,8.674,41.387,9.422"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#7B2959"
          d="M45.068,10.957c0,0.92-0.701,1.428-1.672,1.428
      c-0.684,0-1.207-0.238-1.498-0.721l0.791-0.428c0.225,0.268,0.393,0.369,0.701,0.369c0.434,0,0.744-0.256,0.744-0.727v-0.084
      c-0.215,0.186-0.512,0.287-0.898,0.287c-0.779,0-1.439-0.619-1.439-1.463c0-0.828,0.66-1.439,1.439-1.439
      c0.387,0,0.684,0.102,0.898,0.285V8.27h0.934V10.957z M44.135,9.619c0-0.398-0.314-0.678-0.695-0.678
      c-0.416,0-0.715,0.279-0.715,0.678s0.299,0.684,0.715,0.684C43.82,10.303,44.135,10.018,44.135,9.619"
        />
        <rect
          x="16.199"
          y="16.848"
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#ACD1E3"
          width="4.236"
          height="2.246"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#5087C7"
          d="M21.155,17.57h-5.678v-1.463c0-0.822,0.667-1.488,1.489-1.488h2.7
      c0.823,0,1.489,0.666,1.489,1.488V17.57z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_20_)"
          fill="#ACD1E3"
          d="M19.304,43.365l0.724-0.27c0.424-0.158,0.889-0.156,1.311,0.008l1,0.391
      c0.774,0.303,1.695-0.082,1.915-0.885c0.034-0.123,0.052-0.254,0.052-0.391V31.607c-0.01,0-0.02,0.002-0.031,0.002
      c-0.459,0-0.832-0.373-0.832-0.832s0.373-0.83,0.832-0.83c0.011,0,0.021,0.002,0.031,0.002v-1.381
      c-0.01,0-0.02,0.004-0.031,0.004c-0.459,0-0.832-0.373-0.832-0.832s0.373-0.832,0.832-0.832c0.011,0,0.031-1.377,0.031-1.377
      c-0.01,0-0.02,0.002-0.031,0.002c-0.459,0-0.832-0.371-0.832-0.83s0.373-0.832,0.832-0.832c0.011,0,0.021,0.004,0.031,0.004
      v-0.814c0-2.434-1.972-4.406-4.406-4.406h-1.469h-1.448c-2.433,0-4.405,1.973-4.405,4.406v0.814c0.01,0,0.02-0.004,0.031-0.004
      c0.459,0,0.831,0.373,0.831,0.832s-0.372,0.83-0.831,0.83c-0.011,0-0.021-0.002-0.031-0.002c0,0,0.02,1.377,0.031,1.377
      c0.459,0,0.831,0.373,0.831,0.832s-0.372,0.832-0.831,0.832c-0.011,0-0.021-0.004-0.031-0.004v1.381
      c0.01,0,0.02-0.002,0.031-0.002c0.459,0,0.831,0.371,0.831,0.83s-0.372,0.832-0.831,0.832c-0.011,0-0.021-0.002-0.031-0.002
      v10.611c0,0.137,0.018,0.27,0.052,0.395c0.222,0.799,1.142,1.182,1.914,0.881l1.001-0.391c0.421-0.164,0.887-0.166,1.31-0.008
      l0.725,0.27C18.136,43.574,18.749,43.574,19.304,43.365"
        />
      </g>
      <g>
        <defs>
          <path
            id="UPCYCLING_SVGID_21_"
            d="M18.432,18.654h-1.448c-2.434,0-4.406,1.973-4.406,4.406v0.814c0.01-0.002,0.02-0.004,0.031-0.004
        c0.459,0,0.832,0.373,0.832,0.832s-0.373,0.832-0.832,0.832c-0.011,0-0.021-0.002-0.031-0.002c0,0,0.02,1.375,0.031,1.375
        c0.459,0,0.832,0.371,0.832,0.832c0,0.459-0.373,0.832-0.832,0.832c-0.011,0-0.021-0.004-0.031-0.004v1.383
        c0.01-0.002,0.02-0.006,0.031-0.006c0.459,0,0.832,0.373,0.832,0.832s-0.373,0.832-0.832,0.832
        c-0.011,0-0.021-0.002-0.031-0.002v10.611c0,0.137,0.018,0.27,0.053,0.395c0.221,0.799,1.142,1.182,1.914,0.881l1.001-0.391
        c0.42-0.164,0.886-0.166,1.309-0.01l0.726,0.271c0.555,0.209,1.167,0.209,1.724,0l0.724-0.271
        c0.424-0.156,0.889-0.154,1.31,0.01l1.001,0.391c0.773,0.301,1.695-0.084,1.915-0.885c0.033-0.125,0.051-0.254,0.051-0.391
        V31.607c-0.01,0-0.02,0.002-0.031,0.002c-0.458,0-0.831-0.373-0.831-0.832s0.373-0.832,0.831-0.832
        c0.011,0,0.021,0.004,0.031,0.006v-1.383c-0.01,0-0.02,0.004-0.031,0.004c-0.458,0-0.831-0.373-0.831-0.832
        c0-0.461,0.373-0.832,0.831-0.832c0.011,0,0.031-1.375,0.031-1.375c-0.01,0-0.02,0.002-0.031,0.002
        c-0.458,0-0.831-0.373-0.831-0.832s0.373-0.832,0.831-0.832c0.011,0,0.021,0.002,0.031,0.004v-0.814
        c0-2.434-1.972-4.406-4.405-4.406H18.432z"
          />
        </defs>
        <clipPath id="UPCYCLING_SVGID_22_">
          <use xlinkHref="#UPCYCLING_SVGID_21_" overflow="visible" />
        </clipPath>

        <line
          clipPath="url(#UPCYCLING_SVGID_22_)"
          fill="none"
          stroke="#5087C7"
          strokeWidth="1.554"
          strokeMiterlimit="10"
          x1="12.578"
          y1="24.697"
          x2="24.367"
          y2="24.697"
        />

        <line
          clipPath="url(#UPCYCLING_SVGID_22_)"
          fill="none"
          stroke="#5087C7"
          strokeWidth="1.554"
          strokeMiterlimit="10"
          x1="12.578"
          y1="27.721"
          x2="24.367"
          y2="27.721"
        />

        <line
          clipPath="url(#UPCYCLING_SVGID_22_)"
          fill="none"
          stroke="#5087C7"
          strokeWidth="1.554"
          strokeMiterlimit="10"
          x1="12.578"
          y1="30.756"
          x2="24.367"
          y2="30.756"
        />
      </g>
      <g>
        <defs>
          <rect
            id="UPCYCLING_SVGID_23_"
            x="0.176"
            width="61.688"
            height="61.688"
          />
        </defs>
        <clipPath id="UPCYCLING_SVGID_24_">
          <use xlinkHref="#UPCYCLING_SVGID_23_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#UPCYCLING_SVGID_24_)"
          fill="#5087C7"
          d="M18.442,43.521c0.292,0,0.584-0.051,0.862-0.156l0.725-0.27
      c0.423-0.158,0.889-0.156,1.31,0.008l1,0.391c0.774,0.303,1.695-0.084,1.915-0.885c0.034-0.123,0.052-0.254,0.052-0.391V31.607
      c-0.01,0-0.02,0.002-0.031,0.002c-0.459,0-0.831-0.373-0.831-0.832s0.372-0.83,0.831-0.83c0.011,0,0.021,0.002,0.031,0.002
      v-1.381c-0.01,0.002-0.02,0.004-0.031,0.004c-0.459,0-0.831-0.373-0.831-0.832s0.372-0.832,0.831-0.832
      c0.011,0,0.031-1.377,0.031-1.377c-0.01,0-0.02,0.002-0.031,0.002c-0.459,0-0.831-0.371-0.831-0.83s0.372-0.832,0.831-0.832
      c0.011,0,0.021,0.002,0.031,0.002v-0.813c0-2.434-1.972-4.406-4.406-4.406h-1.468"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_24_)"
          fill="#EACBD9"
          d="M56.186,4.406c0.689,0,1.27,0.582,1.27,1.27V56.01
      c0,0.689-0.58,1.271-1.27,1.271H5.852c-0.689,0-1.271-0.582-1.271-1.271V5.676c0-0.688,0.582-1.27,1.271-1.27H56.186 M56.186,0
      H5.852C2.729,0,0.175,2.555,0.175,5.676V56.01c0,3.121,2.554,5.676,5.677,5.676h50.333c3.121,0,5.678-2.555,5.678-5.676V5.676
      C61.863,2.555,59.307,0,56.186,0"
        />

        <line
          clipPath="url(#UPCYCLING_SVGID_24_)"
          fill="none"
          stroke="#7B2959"
          strokeWidth="2.591"
          strokeMiterlimit="10"
          x1="26.785"
          y1="30.576"
          x2="35.033"
          y2="30.576"
        />
        <polyline
          clipPath="url(#UPCYCLING_SVGID_24_)"
          fill="none"
          stroke="#7B2959"
          strokeWidth="2.591"
          strokeMiterlimit="10"
          points="
      31.26,26.146 35.69,30.576 31.26,35.006 			"
        />

        <line
          clipPath="url(#UPCYCLING_SVGID_24_)"
          fill="none"
          stroke="#9CCE78"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="44.982"
          y1="36.814"
          x2="44.982"
          y2="21.693"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_24_)"
          fill="#ACD1E3"
          d="M50.82,31.609c0.012,0,0.021-0.002,0.031-0.002v10.613
      c0,0.135-0.018,0.266-0.053,0.389c-0.219,0.801-1.141,1.186-1.914,0.885l-1-0.391c-0.422-0.164-0.887-0.166-1.311-0.008
      l-0.725,0.27c-0.557,0.209-1.168,0.209-1.725,0l-0.725-0.27c-0.424-0.158-0.889-0.156-1.311,0.008l-1,0.391
      c-0.773,0.301-1.693-0.082-1.916-0.881c-0.033-0.125-0.051-0.258-0.051-0.393c0,0-0.012-10.611,0-10.611l11.729-0.002"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_24_)"
          fill="#5087C7"
          d="M44.982,31.609l0.004,11.912c0.293,0,0.584-0.051,0.863-0.154l0.725-0.271
      c0.422-0.158,0.889-0.156,1.311,0.008l1,0.391c0.773,0.303,1.693-0.084,1.916-0.883c0.033-0.125,0.051-0.258,0.051-0.393V31.605
      c-0.01,0.002-0.02,0.006-0.031,0.006"
        />
      </g>
      <g>
        <defs>
          <path
            id="UPCYCLING_SVGID_25_"
            d="M50.842,31.607c-0.006,0.002-0.014,0.002-0.021,0.002L50.842,31.607z M39.121,31.609
        c-0.01,0,0,10.609,0,10.609c0,0.137,0.02,0.27,0.055,0.395c0.221,0.799,1.141,1.182,1.914,0.881l1-0.391
        c0.42-0.164,0.889-0.166,1.311-0.01l0.725,0.271c0.557,0.209,1.168,0.209,1.725,0l0.725-0.271
        c0.422-0.156,0.891-0.154,1.311,0.01l1,0.391c0.773,0.301,1.693-0.084,1.916-0.885c0.031-0.125,0.051-0.254,0.051-0.391V31.607
        L39.121,31.609z"
          />
        </defs>
        <clipPath id="UPCYCLING_SVGID_26_">
          <use xlinkHref="#UPCYCLING_SVGID_25_" overflow="visible" />
        </clipPath>
        <rect
          x="39.117"
          y="34.836"
          clipPath="url(#UPCYCLING_SVGID_26_)"
          fill="#623B23"
          width="11.734"
          height="9.539"
        />
      </g>
      <g>
        <defs>
          <rect
            id="UPCYCLING_SVGID_27_"
            x="0.176"
            width="61.688"
            height="61.688"
          />
        </defs>
        <clipPath id="UPCYCLING_SVGID_28_">
          <use xlinkHref="#UPCYCLING_SVGID_27_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#72BF44"
          d="M42.602,29.635c1.004,0,1.898-0.459,2.49-1.178
      c-0.592-0.719-1.486-1.178-2.49-1.178c-1.002,0-1.896,0.459-2.488,1.178C40.705,29.176,41.6,29.635,42.602,29.635"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#72BF44"
          d="M47.58,29.635c1.004,0,1.898-0.459,2.49-1.178
      c-0.592-0.719-1.486-1.178-2.49-1.178c-1.002,0-1.898,0.459-2.488,1.178C45.682,29.176,46.578,29.635,47.58,29.635"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EE5E23"
          d="M42.115,17.184c-2.26-0.764-3.594,0.852-3.594,0.852s0.094,2.098,2.34,2.855
      c0.498,0.168,1.064,0.203,1.58,0.111c-0.479,0.217-0.926,0.566-1.242,0.99c-1.424,1.912-0.301,3.68-0.301,3.68
      s2.023,0.561,3.439-1.342c0.314-0.424,0.523-0.953,0.594-1.475c0.063,0.523,0.256,1.053,0.561,1.484
      c1.379,1.943,3.406,1.422,3.406,1.422s1.156-1.75-0.215-3.686c-0.301-0.426-0.732-0.781-1.199-1.012
      c0.508,0.1,1.063,0.078,1.557-0.076c2.277-0.711,2.406-2.801,2.406-2.801s-1.307-1.641-3.572-0.936
      c-0.48,0.15-0.938,0.438-1.295,0.793c0.236-0.443,0.383-0.965,0.387-1.469c0.029-2.385-1.918-3.154-1.918-3.154
      s-1.967,0.736-1.996,3.107c-0.006,0.518,0.135,1.055,0.373,1.514C43.072,17.662,42.607,17.35,42.115,17.184"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#623B23"
          d="M45.09,22.373c-0.322,0-0.641-0.084-0.924-0.242
      c-0.443-0.248-0.764-0.654-0.9-1.141c-0.139-0.488-0.078-1,0.17-1.443c0.334-0.598,0.971-0.971,1.658-0.971
      c0.322,0,0.643,0.084,0.926,0.242c0.441,0.248,0.762,0.65,0.898,1.141c0.139,0.488,0.078,1-0.17,1.443
      C46.412,22,45.777,22.373,45.09,22.373"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M45.094,18.797L45.094,18.797c0.283,0,0.566,0.074,0.816,0.215
      c0.391,0.219,0.674,0.574,0.795,1.006c0.123,0.432,0.068,0.885-0.15,1.275c-0.297,0.529-0.857,0.857-1.465,0.857
      c-0.285,0-0.568-0.072-0.816-0.213c-0.807-0.451-1.098-1.477-0.645-2.283C43.924,19.125,44.486,18.797,45.094,18.797
       M45.094,18.355c-0.742,0-1.463,0.391-1.852,1.084c-0.572,1.021-0.207,2.313,0.814,2.885c0.328,0.184,0.684,0.27,1.033,0.27
      c0.742,0,1.463-0.391,1.852-1.084c0.57-1.021,0.205-2.314-0.814-2.885C45.799,18.441,45.445,18.354,45.094,18.355"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#5087C7"
          d="M31.029,55.566c-2.746,0-4.979-2.232-4.979-4.979
      c0-2.744,2.233-4.979,4.979-4.979s4.979,2.234,4.979,4.979C36.008,53.334,33.775,55.566,31.029,55.566"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M31.029,45.814c2.638,0,4.773,2.137,4.773,4.773s-2.136,4.771-4.773,4.771
      c-2.638,0-4.773-2.135-4.773-4.771S28.391,45.814,31.029,45.814 M31.029,45.402c-2.859,0-5.185,2.326-5.185,5.186
      c0,2.857,2.326,5.184,5.185,5.184s5.186-2.326,5.186-5.184C36.215,47.729,33.889,45.402,31.029,45.402"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M33.522,48.643c-0.239,0-0.464,0.107-0.62,0.252l-0.013-0.014
      c0.073-0.119,0.169-0.346,0.169-0.715c0-0.883-0.884-1.551-2.029-1.551s-2.041,0.668-2.041,1.551
      c0,0.369,0.119,0.596,0.179,0.715l-0.013,0.014c-0.154-0.145-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.703-1.457,2.018
      c0,1.287,0.717,2.016,1.493,2.016c0.715,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.18-0.215-0.298-0.215
      c-0.119,0-0.252,0.084-0.477,0.264c-0.299,0.227-0.562,0.357-0.908,0.357c-0.609,0-0.87-0.682-0.87-1.574
      c0-0.955,0.251-1.588,0.895-1.588c0.286,0,0.476,0.084,0.668,0.264c0.286,0.262,0.381,0.332,0.512,0.332
      c0.096,0,0.156-0.059,0.18-0.094c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.67,0.56-1.135,1.611-1.135
      c1.048,0,1.611,0.465,1.611,1.135c0,0.584-0.311,1.037-0.525,1.252c0.023,0.035,0.084,0.094,0.18,0.094
      c0.131,0,0.226-0.07,0.512-0.332c0.18-0.18,0.382-0.264,0.668-0.264c0.645,0,0.896,0.633,0.896,1.588
      c0,0.893-0.264,1.574-0.871,1.574c-0.346,0-0.621-0.131-0.907-0.357c-0.228-0.18-0.358-0.264-0.478-0.264
      c-0.12,0-0.227,0.107-0.299,0.215c0.441,0.453,0.955,0.848,1.672,0.848c0.774,0,1.49-0.729,1.49-2.016
      C34.979,49.346,34.25,48.643,33.522,48.643"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M31.829,48.619h-0.537c-0.035,0-0.059-0.025-0.071-0.047l-0.168-0.514
      c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.047-0.059,0.047h-0.537c-0.024,0-0.036,0.012-0.012,0.023
      l0.442,0.322c0.023,0.023,0.023,0.059,0.011,0.084l-0.167,0.5c-0.011,0.025-0.011,0.049,0.024,0.025l0.442-0.311
      c0.036-0.023,0.059-0.023,0.095,0l0.43,0.311c0.023,0.023,0.036,0.012,0.023-0.014l-0.166-0.512
      c-0.012-0.037,0-0.072,0.024-0.084l0.44-0.322C31.865,48.631,31.853,48.619,31.829,48.619"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M31.423,52.15c-0.226-0.572-0.406-0.967-0.406-1.443
      c0-0.203,0.06-0.383,0.096-0.477c0.048-0.096,0.06-0.156,0.012-0.156c-0.072,0-0.227,0.166-0.322,0.311
      c-0.073,0.119-0.156,0.346-0.156,0.523c0,0.336,0.108,0.693,0.179,0.908c0.132,0.371,0.251,0.799,0.251,1.205
      c0,0.646-0.287,1.086-0.799,1.48c0.047,0.035,0.167,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.326
      C31.686,52.818,31.555,52.508,31.423,52.15"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#5087C7"
          d="M31.029,55.359c-2.638,0-4.773-2.135-4.773-4.771s2.135-4.773,4.773-4.773
      c2.638,0,4.773,2.137,4.773,4.773S33.667,55.359,31.029,55.359"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M31.029,45.814c2.638,0,4.773,2.137,4.773,4.773s-2.136,4.771-4.773,4.771
      c-2.638,0-4.773-2.135-4.773-4.771S28.391,45.814,31.029,45.814 M31.029,45.402c-2.859,0-5.185,2.326-5.185,5.186
      c0,2.857,2.326,5.184,5.185,5.184s5.186-2.326,5.186-5.184C36.215,47.729,33.889,45.402,31.029,45.402"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M33.522,48.643c-0.239,0-0.464,0.107-0.62,0.252l-0.013-0.014
      c0.073-0.119,0.169-0.346,0.169-0.715c0-0.883-0.884-1.551-2.029-1.551s-2.041,0.668-2.041,1.551
      c0,0.369,0.119,0.596,0.179,0.715l-0.013,0.014c-0.154-0.145-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.703-1.457,2.018
      c0,1.287,0.717,2.016,1.493,2.016c0.715,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.18-0.215-0.298-0.215
      c-0.119,0-0.252,0.084-0.477,0.264c-0.299,0.227-0.562,0.357-0.908,0.357c-0.609,0-0.87-0.682-0.87-1.574
      c0-0.955,0.251-1.588,0.895-1.588c0.286,0,0.476,0.084,0.668,0.264c0.286,0.262,0.381,0.332,0.512,0.332
      c0.096,0,0.156-0.059,0.18-0.094c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.67,0.56-1.135,1.611-1.135
      c1.048,0,1.611,0.465,1.611,1.135c0,0.584-0.311,1.037-0.525,1.252c0.023,0.035,0.084,0.094,0.18,0.094
      c0.131,0,0.226-0.07,0.512-0.332c0.18-0.18,0.382-0.264,0.668-0.264c0.645,0,0.896,0.633,0.896,1.588
      c0,0.893-0.264,1.574-0.871,1.574c-0.346,0-0.621-0.131-0.907-0.357c-0.228-0.18-0.358-0.264-0.478-0.264
      c-0.12,0-0.227,0.107-0.299,0.215c0.441,0.453,0.955,0.848,1.672,0.848c0.774,0,1.49-0.729,1.49-2.016
      C34.979,49.346,34.25,48.643,33.522,48.643"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M31.829,48.619h-0.537c-0.035,0-0.059-0.025-0.071-0.047l-0.168-0.514
      c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.047-0.059,0.047h-0.537c-0.024,0-0.036,0.012-0.012,0.023
      l0.442,0.322c0.023,0.023,0.023,0.059,0.011,0.084l-0.167,0.5c-0.011,0.025-0.011,0.049,0.024,0.025l0.442-0.311
      c0.036-0.023,0.059-0.023,0.095,0l0.43,0.311c0.023,0.023,0.036,0.012,0.023-0.014l-0.166-0.512
      c-0.012-0.037,0-0.072,0.024-0.084l0.44-0.322C31.865,48.631,31.853,48.619,31.829,48.619"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M31.423,52.15c-0.226-0.572-0.406-0.967-0.406-1.443
      c0-0.203,0.06-0.383,0.096-0.477c0.048-0.096,0.06-0.156,0.012-0.156c-0.072,0-0.227,0.166-0.322,0.311
      c-0.073,0.119-0.156,0.346-0.156,0.523c0,0.336,0.108,0.693,0.179,0.908c0.132,0.371,0.251,0.799,0.251,1.205
      c0,0.646-0.287,1.086-0.799,1.48c0.047,0.035,0.167,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.326
      C31.686,52.818,31.555,52.508,31.423,52.15"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M-12.144-25.729c0.076,0,0.14,0.012,0.192,0.039
      c0.052,0.025,0.094,0.059,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.053,0.02,0.107,0.02,0.162
      c0,0.076-0.011,0.143-0.034,0.199s-0.055,0.104-0.095,0.139c-0.041,0.037-0.091,0.063-0.148,0.082
      c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.48H-12.144z M-12.235-25.059c0.059,0,0.107-0.018,0.144-0.055
      c0.037-0.035,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.037-0.085-0.059-0.16-0.059h-0.124v0.451H-12.235
      z"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          points="-11.141,-25.729 -10.93,-24.684 -10.925,-24.684 -10.712,-25.729
      -10.27,-25.729 -10.27,-24.248 -10.544,-24.248 -10.544,-25.43 -10.548,-25.43 -10.811,-24.248 -11.044,-24.248 -11.306,-25.43
      -11.31,-25.43 -11.31,-24.248 -11.584,-24.248 -11.584,-25.729 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M-9.816-24.668c0,0.035,0.003,0.066,0.008,0.096
      c0.005,0.027,0.016,0.053,0.031,0.07c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.014,0.058,0.018,0.097,0.018
      c0.045,0,0.086-0.016,0.123-0.045c0.036-0.029,0.055-0.074,0.055-0.137c0-0.033-0.005-0.063-0.014-0.086
      c-0.008-0.023-0.024-0.047-0.044-0.066c-0.021-0.02-0.048-0.035-0.082-0.053c-0.033-0.016-0.075-0.031-0.125-0.049
      c-0.066-0.021-0.124-0.045-0.172-0.072c-0.049-0.025-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.123
      c-0.015-0.045-0.023-0.1-0.023-0.16c0-0.146,0.041-0.254,0.122-0.326c0.082-0.072,0.194-0.109,0.336-0.109
      c0.067,0,0.128,0.008,0.184,0.023c0.056,0.014,0.104,0.037,0.144,0.068c0.042,0.033,0.073,0.074,0.096,0.125
      c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.123-0.037-0.162c-0.025-0.037-0.067-0.057-0.124-0.057
      c-0.033,0-0.061,0.006-0.083,0.016c-0.022,0.008-0.04,0.021-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055
      c-0.005,0.021-0.007,0.041-0.007,0.064c0,0.045,0.01,0.084,0.029,0.115s0.06,0.059,0.124,0.086l0.23,0.1
      c0.057,0.023,0.103,0.051,0.139,0.078c0.036,0.025,0.064,0.055,0.086,0.086c0.021,0.031,0.036,0.066,0.045,0.102
      c0.007,0.039,0.011,0.08,0.011,0.125c0,0.156-0.045,0.271-0.135,0.342s-0.216,0.107-0.378,0.107c-0.169,0-0.29-0.037-0.362-0.111
      c-0.072-0.072-0.108-0.178-0.108-0.314v-0.061h0.298V-24.668z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M-7.973-25.475c-0.019-0.031-0.055-0.047-0.104-0.047
      c-0.039,0-0.07,0.01-0.095,0.027c-0.024,0.02-0.043,0.047-0.056,0.078c-0.013,0.033-0.022,0.074-0.025,0.119
      c-0.005,0.045-0.007,0.096-0.007,0.148v0.072h0.004c0.025-0.037,0.061-0.068,0.107-0.092c0.047-0.021,0.103-0.033,0.169-0.033
      c0.065,0,0.12,0.012,0.166,0.033c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.09,0.062,0.145
      c0.013,0.057,0.019,0.117,0.019,0.184c0,0.17-0.037,0.299-0.112,0.387c-0.076,0.088-0.196,0.133-0.362,0.133
      c-0.103,0-0.184-0.02-0.245-0.055c-0.06-0.035-0.107-0.084-0.139-0.148c-0.033-0.064-0.054-0.135-0.064-0.219
      c-0.009-0.082-0.014-0.168-0.014-0.258c0-0.105,0.003-0.205,0.009-0.303c0.006-0.098,0.025-0.184,0.056-0.26
      c0.031-0.074,0.08-0.135,0.146-0.18c0.067-0.047,0.161-0.068,0.282-0.068c0.052,0,0.103,0.006,0.152,0.018
      c0.05,0.014,0.093,0.033,0.13,0.063c0.037,0.029,0.067,0.068,0.09,0.115s0.035,0.105,0.036,0.178h-0.286
      C-7.943-25.4-7.952-25.443-7.973-25.475 M-8.239-24.588c0.004,0.035,0.012,0.066,0.024,0.092c0.011,0.025,0.028,0.045,0.05,0.061
      c0.021,0.014,0.049,0.021,0.084,0.021c0.035,0,0.064-0.008,0.086-0.021c0.021-0.016,0.037-0.035,0.048-0.063
      c0.011-0.025,0.019-0.057,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.115c0-0.1-0.012-0.17-0.037-0.213
      s-0.066-0.064-0.124-0.064c-0.06,0-0.103,0.021-0.127,0.064c-0.025,0.043-0.037,0.113-0.037,0.213
      C-8.245-24.664-8.243-24.625-8.239-24.588"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M-6.643-25.459c-0.061,0.086-0.116,0.178-0.163,0.275
      c-0.047,0.1-0.089,0.199-0.126,0.303c-0.035,0.104-0.065,0.211-0.089,0.316c-0.023,0.107-0.041,0.213-0.053,0.316h-0.314
      c0.012-0.088,0.028-0.182,0.051-0.279c0.021-0.098,0.051-0.195,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
      c0.053-0.104,0.115-0.203,0.186-0.301h-0.617v-0.258h0.904V-25.459z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M-6.549-25.227c0.006-0.094,0.025-0.178,0.056-0.25
      c0.03-0.072,0.076-0.131,0.137-0.174c0.062-0.043,0.147-0.066,0.256-0.066c0.09,0,0.164,0.014,0.223,0.039
      s0.106,0.066,0.141,0.123c0.035,0.059,0.06,0.131,0.075,0.221c0.014,0.092,0.022,0.201,0.022,0.33
      c0,0.104-0.004,0.201-0.011,0.295s-0.026,0.178-0.056,0.25c-0.03,0.072-0.077,0.131-0.138,0.172
      c-0.061,0.041-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.037c-0.06-0.023-0.107-0.063-0.142-0.119
      c-0.036-0.057-0.061-0.131-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C-6.561-25.035-6.558-25.133-6.549-25.227
       M-6.254-24.641c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.033,0.028,0.057,0.05,0.068c0.02,0.014,0.046,0.02,0.078,0.02
      c0.033,0,0.061-0.008,0.081-0.023s0.037-0.043,0.049-0.082c0.011-0.037,0.02-0.088,0.025-0.152
      c0.004-0.063,0.007-0.143,0.007-0.236v-0.158c0-0.094-0.003-0.168-0.007-0.229c-0.005-0.059-0.014-0.104-0.026-0.139
      c-0.013-0.033-0.03-0.057-0.05-0.068c-0.021-0.014-0.047-0.02-0.079-0.02c-0.033,0-0.06,0.008-0.08,0.025
      c-0.021,0.016-0.038,0.043-0.049,0.08c-0.012,0.037-0.02,0.088-0.025,0.152c-0.005,0.063-0.008,0.143-0.008,0.236v0.16
      C-6.262-24.775-6.259-24.699-6.254-24.641"
        />
        <rect
          x="-2.994"
          y="-25.996"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#F6ADCD"
          width="8.35"
          height="8.35"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#F6ADCD"
          d="M5.125-17.877h-7.889v-7.889h7.889V-17.877z M5.587-26.227h-8.813v8.813
      h8.813V-26.227z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M-2.312-25.729c0.076,0,0.14,0.012,0.192,0.039
      c0.052,0.025,0.094,0.059,0.125,0.102c0.032,0.039,0.055,0.088,0.069,0.141c0.013,0.053,0.02,0.107,0.02,0.162
      c0,0.076-0.011,0.143-0.034,0.199s-0.055,0.104-0.095,0.139c-0.041,0.037-0.091,0.063-0.148,0.082
      c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.48H-2.312z M-2.402-25.059c0.059,0,0.107-0.018,0.144-0.055
      c0.037-0.035,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.037-0.085-0.057-0.16-0.057h-0.124v0.449H-2.402z
      "
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          points="-1.308,-25.729 -1.097,-24.684 -1.092,-24.684 -0.879,-25.729
      -0.438,-25.729 -0.438,-24.248 -0.711,-24.248 -0.711,-25.43 -0.715,-25.43 -0.979,-24.248 -1.211,-24.248 -1.473,-25.43
      -1.478,-25.43 -1.478,-24.248 -1.751,-24.248 -1.751,-25.729 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M0.016-24.668c0,0.035,0.003,0.066,0.008,0.096
      c0.005,0.027,0.016,0.053,0.031,0.07c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.014,0.058,0.018,0.097,0.018
      c0.045,0,0.086-0.016,0.123-0.045c0.036-0.029,0.055-0.074,0.055-0.137c0-0.033-0.005-0.063-0.014-0.086
      c-0.008-0.023-0.024-0.047-0.044-0.064c-0.021-0.02-0.048-0.037-0.082-0.053c-0.033-0.018-0.075-0.033-0.125-0.051
      c-0.066-0.021-0.124-0.045-0.172-0.07c-0.049-0.027-0.089-0.059-0.121-0.094c-0.033-0.035-0.057-0.076-0.072-0.123
      c-0.015-0.045-0.023-0.1-0.023-0.16c0-0.146,0.041-0.256,0.122-0.326c0.082-0.072,0.194-0.109,0.336-0.109
      c0.067,0,0.128,0.008,0.184,0.023c0.056,0.014,0.104,0.037,0.144,0.068c0.042,0.033,0.073,0.074,0.096,0.125
      s0.034,0.111,0.034,0.182v0.041H0.368c0-0.07-0.013-0.123-0.037-0.162c-0.025-0.037-0.067-0.057-0.124-0.057
      c-0.033,0-0.061,0.006-0.083,0.016c-0.022,0.008-0.04,0.021-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055
      c-0.005,0.021-0.007,0.041-0.007,0.064c0,0.045,0.01,0.084,0.029,0.115s0.06,0.061,0.124,0.086l0.23,0.1
      c0.057,0.023,0.103,0.051,0.139,0.078c0.036,0.025,0.064,0.055,0.086,0.086c0.021,0.031,0.036,0.066,0.045,0.102
      c0.007,0.039,0.012,0.08,0.012,0.125c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.107-0.378,0.107
      c-0.169,0-0.29-0.037-0.362-0.111c-0.072-0.072-0.108-0.178-0.108-0.314v-0.061h0.298V-24.668z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M1.282-24.248c0-0.064,0.007-0.125,0.02-0.182
      c0.013-0.057,0.033-0.111,0.06-0.162s0.06-0.102,0.102-0.15c0.04-0.051,0.088-0.1,0.141-0.148l0.121-0.111
      c0.033-0.029,0.06-0.057,0.08-0.082c0.02-0.023,0.035-0.049,0.046-0.074c0.011-0.025,0.019-0.051,0.022-0.082
      c0.003-0.027,0.005-0.061,0.005-0.098c0-0.123-0.048-0.184-0.145-0.184c-0.037,0-0.067,0.008-0.088,0.023
      c-0.022,0.016-0.038,0.035-0.05,0.063c-0.011,0.025-0.019,0.057-0.023,0.092c-0.003,0.033-0.004,0.07-0.004,0.109H1.282v-0.057
      c0-0.137,0.037-0.242,0.11-0.314c0.073-0.074,0.188-0.109,0.346-0.109c0.15,0,0.26,0.033,0.332,0.104
      c0.072,0.068,0.108,0.168,0.108,0.295c0,0.049-0.004,0.092-0.013,0.133c-0.008,0.041-0.021,0.08-0.039,0.117
      s-0.041,0.072-0.071,0.107c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.154C1.74-24.656,1.706-24.617,1.68-24.58
      c-0.024,0.039-0.041,0.072-0.047,0.1h0.553v0.232H1.282z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M2.288-25.227c0.006-0.094,0.025-0.178,0.056-0.25
      c0.03-0.072,0.076-0.131,0.137-0.174c0.062-0.043,0.147-0.066,0.256-0.066c0.09,0,0.164,0.014,0.223,0.039
      c0.059,0.025,0.106,0.068,0.141,0.123c0.035,0.059,0.06,0.131,0.075,0.223c0.014,0.09,0.022,0.199,0.022,0.328
      c0,0.104-0.004,0.201-0.011,0.295c-0.007,0.094-0.026,0.178-0.056,0.25c-0.03,0.072-0.077,0.131-0.138,0.172
      s-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.037c-0.06-0.023-0.107-0.063-0.142-0.119
      c-0.036-0.057-0.061-0.131-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C2.277-25.035,2.28-25.133,2.288-25.227 M2.583-24.641
      c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.033,0.028,0.057,0.05,0.068c0.02,0.014,0.046,0.02,0.078,0.02
      c0.033,0,0.061-0.008,0.081-0.023s0.037-0.043,0.049-0.082c0.011-0.037,0.02-0.088,0.025-0.152
      c0.004-0.063,0.007-0.143,0.007-0.234v-0.16c0-0.094-0.003-0.168-0.007-0.229c-0.005-0.057-0.014-0.104-0.026-0.139
      c-0.013-0.033-0.03-0.057-0.05-0.068c-0.021-0.014-0.047-0.02-0.079-0.02c-0.033,0-0.06,0.008-0.08,0.025
      c-0.021,0.016-0.038,0.043-0.049,0.08c-0.012,0.037-0.02,0.09-0.025,0.152c-0.005,0.063-0.008,0.143-0.008,0.236v0.16
      C2.575-24.775,2.578-24.699,2.583-24.641"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M3.597-24.482c0.021,0.047,0.064,0.068,0.13,0.068
      c0.035,0,0.064-0.006,0.085-0.018c0.022-0.014,0.038-0.029,0.05-0.053c0.012-0.02,0.019-0.047,0.023-0.074
      c0.003-0.029,0.005-0.061,0.005-0.092c0-0.033-0.002-0.064-0.007-0.094c-0.005-0.031-0.015-0.057-0.028-0.08
      c-0.014-0.023-0.033-0.043-0.058-0.057c-0.025-0.012-0.059-0.021-0.1-0.021H3.598v-0.193h0.097c0.032,0,0.058-0.006,0.08-0.02
      c0.021-0.014,0.039-0.029,0.052-0.051c0.013-0.021,0.022-0.047,0.029-0.076c0.006-0.025,0.009-0.055,0.009-0.086
      c0-0.072-0.012-0.121-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.004-0.077,0.016
      c-0.021,0.012-0.037,0.029-0.047,0.051c-0.011,0.021-0.019,0.047-0.022,0.076s-0.005,0.061-0.005,0.094H3.298
      c0-0.146,0.036-0.254,0.109-0.326c0.073-0.07,0.18-0.105,0.322-0.105c0.133,0,0.237,0.029,0.311,0.09
      c0.074,0.063,0.111,0.156,0.111,0.287c0,0.088-0.02,0.16-0.059,0.217c-0.039,0.055-0.094,0.09-0.164,0.105v0.004
      c0.094,0.016,0.162,0.055,0.201,0.115c0.04,0.063,0.06,0.139,0.06,0.232c0,0.049-0.007,0.102-0.019,0.152
      c-0.013,0.053-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.078-0.149,0.105c-0.062,0.025-0.146,0.039-0.248,0.039
      c-0.142,0-0.248-0.039-0.319-0.117c-0.07-0.076-0.105-0.188-0.105-0.33v-0.006h0.286C3.564-24.592,3.575-24.527,3.597-24.482"
        />
        <rect
          x="56.592"
          y="-26.227"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#5087C7"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M57.506-25.729c0.076,0,0.141,0.012,0.191,0.039
      c0.053,0.025,0.094,0.059,0.125,0.1c0.033,0.041,0.055,0.09,0.07,0.143c0.012,0.053,0.02,0.107,0.02,0.162
      c0,0.076-0.012,0.143-0.035,0.199c-0.021,0.057-0.055,0.104-0.094,0.139c-0.041,0.037-0.092,0.063-0.148,0.082
      c-0.059,0.018-0.123,0.025-0.193,0.025h-0.16v0.592h-0.297v-1.48H57.506z M57.414-25.059c0.061,0,0.107-0.018,0.145-0.055
      c0.037-0.035,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.033-0.037-0.086-0.057-0.16-0.057h-0.125v0.449H57.414z
      "
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          points="58.51,-25.729 58.721,-24.684 58.725,-24.684 58.938,-25.729
      59.379,-25.729 59.379,-24.248 59.105,-24.248 59.105,-25.43 59.102,-25.43 58.838,-24.248 58.605,-24.248 58.344,-25.43
      58.34,-25.43 58.34,-24.248 58.066,-24.248 58.066,-25.729 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M59.834-24.668c0,0.035,0.002,0.066,0.008,0.096
      c0.004,0.027,0.016,0.053,0.031,0.07c0.014,0.02,0.035,0.035,0.061,0.045c0.025,0.014,0.059,0.018,0.098,0.018
      c0.045,0,0.086-0.016,0.123-0.045c0.035-0.029,0.055-0.074,0.055-0.137c0-0.033-0.006-0.063-0.014-0.086
      s-0.025-0.047-0.045-0.064c-0.021-0.02-0.047-0.037-0.082-0.053c-0.033-0.018-0.074-0.033-0.125-0.051
      c-0.066-0.021-0.123-0.045-0.172-0.07c-0.049-0.027-0.09-0.059-0.121-0.094c-0.033-0.035-0.057-0.076-0.072-0.123
      c-0.014-0.045-0.023-0.1-0.023-0.16c0-0.146,0.041-0.256,0.123-0.326c0.082-0.072,0.193-0.109,0.336-0.109
      c0.066,0,0.127,0.008,0.184,0.023c0.057,0.014,0.104,0.037,0.145,0.068c0.041,0.033,0.072,0.074,0.096,0.125
      s0.033,0.111,0.033,0.182v0.041h-0.285c0-0.07-0.014-0.123-0.037-0.162c-0.025-0.037-0.066-0.057-0.123-0.057
      c-0.033,0-0.063,0.006-0.084,0.016c-0.023,0.008-0.039,0.021-0.055,0.037c-0.012,0.016-0.02,0.035-0.025,0.055
      c-0.004,0.021-0.006,0.041-0.006,0.064c0,0.045,0.01,0.084,0.027,0.115c0.02,0.031,0.061,0.061,0.125,0.086l0.23,0.1
      c0.057,0.023,0.102,0.051,0.139,0.078c0.035,0.025,0.064,0.055,0.086,0.086s0.035,0.066,0.045,0.102
      c0.006,0.039,0.012,0.08,0.012,0.125c0,0.156-0.047,0.271-0.137,0.342s-0.215,0.107-0.377,0.107c-0.17,0-0.291-0.037-0.361-0.111
      c-0.072-0.072-0.109-0.178-0.109-0.314v-0.061h0.299V-24.668z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M61.1-24.248c0-0.064,0.008-0.125,0.02-0.182
      c0.014-0.057,0.033-0.111,0.061-0.162s0.061-0.102,0.102-0.15c0.041-0.051,0.088-0.1,0.141-0.148l0.121-0.111
      c0.033-0.029,0.061-0.057,0.08-0.082c0.02-0.023,0.035-0.049,0.047-0.074c0.01-0.025,0.02-0.051,0.021-0.082
      c0.004-0.027,0.006-0.061,0.006-0.098c0-0.123-0.049-0.184-0.146-0.184c-0.037,0-0.066,0.008-0.088,0.023
      s-0.037,0.035-0.049,0.063c-0.012,0.025-0.02,0.057-0.023,0.092c-0.004,0.033-0.004,0.07-0.004,0.109H61.1v-0.057
      c0-0.137,0.037-0.242,0.109-0.314c0.074-0.074,0.189-0.109,0.346-0.109c0.15,0,0.262,0.033,0.332,0.104
      c0.072,0.068,0.109,0.168,0.109,0.295c0,0.049-0.004,0.092-0.014,0.133c-0.008,0.041-0.021,0.08-0.039,0.117
      s-0.041,0.072-0.07,0.107s-0.064,0.072-0.107,0.109l-0.164,0.154c-0.045,0.039-0.078,0.078-0.104,0.115
      c-0.025,0.039-0.041,0.072-0.047,0.1h0.553v0.232H61.1z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M63.006-25.459c-0.061,0.086-0.115,0.178-0.162,0.275
      c-0.047,0.1-0.09,0.199-0.127,0.303c-0.035,0.104-0.064,0.211-0.088,0.316c-0.023,0.107-0.041,0.213-0.053,0.316h-0.314
      c0.012-0.088,0.027-0.182,0.051-0.279c0.021-0.098,0.051-0.195,0.088-0.297s0.082-0.203,0.133-0.305
      c0.053-0.104,0.115-0.203,0.186-0.299h-0.615v-0.258h0.902V-25.459z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M63.43-25.285h-0.293v-0.193h0.027c0.1,0,0.178-0.02,0.234-0.057
      c0.059-0.039,0.096-0.098,0.109-0.18h0.219v1.467H63.43V-25.285z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M64.137-24.9c0.043-0.068,0.105-0.109,0.188-0.125v-0.004
      c-0.039-0.012-0.074-0.025-0.102-0.045c-0.025-0.021-0.047-0.045-0.064-0.072c-0.016-0.025-0.027-0.053-0.035-0.082
      c-0.006-0.029-0.01-0.057-0.01-0.082c0-0.145,0.039-0.248,0.115-0.311s0.182-0.094,0.316-0.094c0.133,0,0.238,0.031,0.314,0.094
      c0.078,0.063,0.115,0.166,0.115,0.311c0,0.025-0.004,0.053-0.012,0.082c-0.006,0.029-0.018,0.057-0.035,0.082
      c-0.016,0.027-0.039,0.051-0.064,0.072c-0.027,0.02-0.061,0.033-0.1,0.045v0.004c0.082,0.016,0.145,0.057,0.188,0.125
      c0.041,0.066,0.061,0.145,0.061,0.238c0,0.043-0.004,0.09-0.014,0.141c-0.012,0.051-0.033,0.098-0.066,0.143
      s-0.08,0.08-0.143,0.109c-0.061,0.029-0.143,0.045-0.244,0.045s-0.184-0.016-0.246-0.045s-0.109-0.064-0.143-0.109
      s-0.055-0.092-0.064-0.143c-0.012-0.051-0.016-0.098-0.016-0.141C64.076-24.756,64.096-24.834,64.137-24.9 M64.381-24.568
      c0.006,0.031,0.016,0.059,0.027,0.082c0.014,0.021,0.031,0.041,0.053,0.053c0.023,0.014,0.051,0.02,0.084,0.02
      s0.061-0.006,0.082-0.02c0.021-0.012,0.039-0.031,0.053-0.053c0.014-0.023,0.023-0.051,0.027-0.082s0.008-0.064,0.008-0.1
      c0-0.033-0.004-0.068-0.008-0.098c-0.004-0.031-0.014-0.057-0.027-0.08s-0.031-0.041-0.053-0.055
      c-0.021-0.012-0.049-0.02-0.082-0.02s-0.061,0.008-0.084,0.02c-0.021,0.014-0.039,0.031-0.053,0.055
      c-0.012,0.023-0.021,0.049-0.027,0.08c-0.004,0.029-0.006,0.064-0.006,0.098C64.375-24.633,64.377-24.6,64.381-24.568
       M64.439-25.164c0.025,0.033,0.061,0.051,0.105,0.051c0.043,0,0.078-0.018,0.105-0.051c0.025-0.033,0.039-0.086,0.039-0.156
      c0-0.133-0.049-0.199-0.145-0.199c-0.098,0-0.145,0.066-0.145,0.199C64.4-25.25,64.412-25.197,64.439-25.164"
        />
        <rect
          x="6.643"
          y="-26.227"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#7B2959"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M7.557-25.729c0.076,0,0.14,0.012,0.192,0.037
      c0.052,0.027,0.094,0.061,0.125,0.102c0.032,0.041,0.055,0.09,0.069,0.143c0.013,0.053,0.02,0.107,0.02,0.162
      c0,0.076-0.011,0.143-0.034,0.199s-0.054,0.104-0.095,0.139c-0.041,0.037-0.09,0.063-0.148,0.08S7.563-24.84,7.493-24.84h-0.16
      v0.592H7.035v-1.48H7.557z M7.466-25.059c0.059,0,0.107-0.018,0.144-0.055c0.037-0.035,0.055-0.092,0.055-0.168
      c0-0.074-0.016-0.131-0.048-0.17C7.585-25.49,7.532-25.51,7.458-25.51H7.333v0.451H7.466z"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          points="8.561,-25.729 8.771,-24.684 8.776,-24.684 8.99,-25.729
      9.431,-25.729 9.431,-24.248 9.158,-24.248 9.158,-25.432 9.154,-25.432 8.891,-24.248 8.658,-24.248 8.395,-25.432
      8.391,-25.432 8.391,-24.248 8.117,-24.248 8.117,-25.729 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M9.885-24.668c0,0.035,0.003,0.066,0.009,0.096
      c0.004,0.027,0.015,0.051,0.03,0.07c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.014,0.059,0.018,0.097,0.018
      c0.045,0,0.087-0.016,0.123-0.045s0.056-0.076,0.056-0.137c0-0.033-0.005-0.063-0.014-0.086
      c-0.009-0.025-0.024-0.047-0.045-0.066s-0.048-0.035-0.082-0.053c-0.033-0.016-0.075-0.031-0.125-0.049
      c-0.066-0.021-0.123-0.047-0.172-0.072c-0.048-0.025-0.089-0.057-0.121-0.094c-0.033-0.033-0.057-0.074-0.072-0.121
      c-0.015-0.045-0.023-0.1-0.023-0.16c0-0.146,0.041-0.256,0.123-0.326c0.081-0.072,0.193-0.109,0.336-0.109
      c0.066,0,0.127,0.008,0.183,0.021c0.056,0.016,0.104,0.039,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.125
      s0.034,0.111,0.034,0.182v0.041h-0.285c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
      c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.01-0.04,0.021-0.053,0.039c-0.013,0.016-0.022,0.035-0.027,0.055
      c-0.005,0.021-0.007,0.041-0.007,0.064c0,0.045,0.01,0.084,0.029,0.113c0.019,0.033,0.061,0.061,0.124,0.086l0.23,0.102
      c0.057,0.023,0.103,0.051,0.139,0.078c0.036,0.025,0.065,0.055,0.086,0.086c0.022,0.029,0.036,0.064,0.045,0.102
      c0.008,0.039,0.012,0.08,0.012,0.125c0,0.156-0.046,0.27-0.136,0.342c-0.09,0.07-0.216,0.105-0.378,0.105
      c-0.168,0-0.289-0.035-0.362-0.109c-0.072-0.072-0.108-0.178-0.108-0.314v-0.061h0.298V-24.668z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M11.729-25.477c-0.02-0.029-0.056-0.045-0.105-0.045
      c-0.039,0-0.07,0.01-0.095,0.027c-0.023,0.02-0.042,0.045-0.056,0.078c-0.013,0.033-0.021,0.072-0.025,0.119
      c-0.004,0.045-0.007,0.094-0.007,0.148v0.072h0.005c0.025-0.039,0.06-0.068,0.107-0.092c0.046-0.021,0.102-0.035,0.168-0.035
      c0.065,0,0.12,0.014,0.166,0.035c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.09,0.062,0.145
      c0.013,0.057,0.02,0.117,0.02,0.184c0,0.17-0.038,0.299-0.113,0.387c-0.075,0.088-0.196,0.131-0.362,0.131
      c-0.102,0-0.183-0.018-0.244-0.053c-0.061-0.037-0.108-0.086-0.14-0.148c-0.033-0.064-0.054-0.137-0.064-0.219
      c-0.009-0.082-0.014-0.168-0.014-0.26c0-0.104,0.003-0.203,0.009-0.301s0.025-0.184,0.057-0.26
      c0.03-0.074,0.079-0.135,0.145-0.182c0.067-0.045,0.161-0.068,0.282-0.068c0.052,0,0.104,0.008,0.152,0.02
      c0.05,0.012,0.093,0.033,0.13,0.063s0.068,0.066,0.091,0.115c0.022,0.047,0.034,0.105,0.035,0.176h-0.286
      C11.758-25.4,11.749-25.445,11.729-25.477 M11.462-24.588c0.005,0.035,0.012,0.066,0.024,0.092
      c0.012,0.025,0.028,0.045,0.05,0.061c0.021,0.014,0.049,0.021,0.084,0.021c0.036,0,0.064-0.008,0.086-0.021
      c0.021-0.016,0.037-0.035,0.048-0.063c0.012-0.025,0.019-0.057,0.022-0.092c0.004-0.035,0.005-0.074,0.005-0.115
      c0-0.1-0.012-0.172-0.037-0.213c-0.025-0.043-0.066-0.064-0.124-0.064c-0.06,0-0.102,0.021-0.126,0.064
      c-0.025,0.041-0.038,0.113-0.038,0.213C11.456-24.664,11.458-24.625,11.462-24.588"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M12.199-24.9c0.042-0.068,0.104-0.109,0.187-0.125v-0.004
      c-0.04-0.012-0.073-0.025-0.101-0.047c-0.026-0.02-0.048-0.043-0.065-0.07c-0.016-0.025-0.028-0.053-0.035-0.082
      c-0.007-0.029-0.01-0.057-0.01-0.084c0-0.145,0.038-0.246,0.115-0.309c0.076-0.064,0.181-0.096,0.316-0.096
      c0.134,0,0.239,0.031,0.316,0.096c0.076,0.063,0.115,0.164,0.115,0.309c0,0.027-0.004,0.055-0.011,0.084
      c-0.008,0.029-0.02,0.057-0.037,0.082c-0.016,0.027-0.038,0.051-0.065,0.07c-0.027,0.021-0.06,0.035-0.099,0.047v0.004
      c0.083,0.016,0.145,0.057,0.187,0.125c0.041,0.064,0.062,0.145,0.062,0.236c0,0.043-0.005,0.092-0.015,0.141
      c-0.01,0.053-0.032,0.1-0.066,0.145c-0.032,0.043-0.08,0.08-0.141,0.109c-0.062,0.029-0.144,0.043-0.246,0.043
      s-0.184-0.014-0.246-0.043s-0.108-0.066-0.142-0.109c-0.033-0.045-0.054-0.092-0.065-0.145c-0.01-0.049-0.015-0.098-0.015-0.141
      C12.138-24.756,12.159-24.836,12.199-24.9 M12.444-24.568c0.004,0.029,0.013,0.057,0.026,0.08s0.031,0.041,0.053,0.055
      c0.022,0.012,0.05,0.02,0.083,0.02c0.033,0,0.06-0.008,0.083-0.02c0.022-0.014,0.039-0.031,0.053-0.055
      c0.012-0.023,0.022-0.051,0.027-0.08c0.004-0.031,0.007-0.064,0.007-0.1s-0.003-0.068-0.007-0.1
      c-0.005-0.029-0.015-0.057-0.027-0.078c-0.014-0.025-0.031-0.041-0.053-0.055c-0.023-0.014-0.05-0.02-0.083-0.02
      c-0.033,0-0.061,0.006-0.083,0.02s-0.04,0.029-0.053,0.055c-0.013,0.021-0.022,0.049-0.026,0.078
      c-0.006,0.031-0.008,0.064-0.008,0.1S12.438-24.6,12.444-24.568 M12.5-25.166c0.026,0.033,0.062,0.051,0.106,0.051
      c0.044,0,0.079-0.018,0.105-0.051c0.027-0.031,0.04-0.084,0.04-0.154c0-0.135-0.048-0.201-0.145-0.201
      c-0.097,0-0.145,0.066-0.145,0.201C12.461-25.25,12.474-25.197,12.5-25.166"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M13.465-24.482c0.021,0.045,0.065,0.068,0.13,0.068
      c0.035,0,0.064-0.008,0.086-0.02c0.021-0.012,0.037-0.029,0.049-0.051c0.012-0.021,0.019-0.047,0.023-0.076
      c0.003-0.027,0.005-0.059,0.005-0.09c0-0.033-0.002-0.064-0.007-0.094c-0.005-0.029-0.014-0.057-0.028-0.08
      c-0.013-0.023-0.033-0.043-0.058-0.057s-0.058-0.021-0.099-0.021h-0.1v-0.195h0.097c0.032,0,0.059-0.006,0.08-0.018
      c0.022-0.014,0.039-0.031,0.052-0.053c0.014-0.021,0.023-0.047,0.029-0.074s0.009-0.057,0.009-0.086
      c0-0.07-0.012-0.119-0.035-0.15c-0.024-0.029-0.059-0.043-0.108-0.043c-0.031,0-0.057,0.006-0.077,0.018
      s-0.036,0.029-0.047,0.051c-0.011,0.021-0.018,0.047-0.022,0.074c-0.003,0.029-0.005,0.063-0.005,0.096h-0.273
      c0-0.146,0.036-0.254,0.109-0.326c0.073-0.07,0.181-0.107,0.322-0.107c0.134,0,0.237,0.031,0.312,0.094
      c0.074,0.059,0.11,0.154,0.11,0.283c0,0.09-0.019,0.162-0.059,0.219c-0.039,0.055-0.094,0.09-0.164,0.105v0.004
      c0.095,0.016,0.162,0.055,0.202,0.115c0.039,0.061,0.059,0.139,0.059,0.232c0,0.049-0.006,0.1-0.019,0.152
      c-0.012,0.051-0.037,0.1-0.072,0.143c-0.037,0.043-0.085,0.078-0.149,0.104c-0.062,0.027-0.145,0.039-0.247,0.039
      c-0.142,0-0.249-0.037-0.32-0.115c-0.07-0.078-0.105-0.188-0.105-0.33v-0.006h0.286C13.432-24.592,13.444-24.527,13.465-24.482"
        />
        <rect
          x="17.056"
          y="-26.227"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EE5E23"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M17.969-25.729c0.076,0,0.14,0.012,0.192,0.037
      c0.052,0.027,0.094,0.061,0.125,0.102c0.032,0.041,0.055,0.09,0.069,0.143c0.013,0.053,0.02,0.107,0.02,0.162
      c0,0.076-0.011,0.143-0.034,0.199s-0.054,0.104-0.095,0.139c-0.041,0.037-0.09,0.063-0.148,0.08
      c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.592h-0.298v-1.48H17.969z M17.878-25.059c0.059,0,0.107-0.018,0.144-0.055
      c0.037-0.035,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.059-0.159-0.059h-0.125v0.451H17.878
      z"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          points="18.973,-25.729 19.184,-24.684 19.189,-24.684 19.402,-25.729
      19.843,-25.729 19.843,-24.248 19.57,-24.248 19.57,-25.432 19.566,-25.432 19.303,-24.248 19.07,-24.248 18.807,-25.432
      18.803,-25.432 18.803,-24.248 18.529,-24.248 18.529,-25.729 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M20.297-24.668c0,0.035,0.003,0.066,0.009,0.096
      c0.004,0.027,0.015,0.051,0.03,0.07s0.036,0.035,0.062,0.045c0.025,0.014,0.058,0.018,0.096,0.018
      c0.045,0,0.087-0.016,0.123-0.045c0.036-0.029,0.056-0.076,0.056-0.137c0-0.033-0.005-0.063-0.014-0.086
      c-0.009-0.025-0.024-0.047-0.045-0.066s-0.048-0.035-0.082-0.053c-0.033-0.016-0.075-0.031-0.125-0.049
      c-0.066-0.021-0.123-0.047-0.172-0.072c-0.048-0.025-0.089-0.057-0.121-0.094c-0.033-0.033-0.057-0.074-0.072-0.121
      c-0.015-0.045-0.023-0.1-0.023-0.16c0-0.146,0.041-0.256,0.123-0.326c0.081-0.072,0.193-0.109,0.336-0.109
      c0.066,0,0.127,0.008,0.183,0.021c0.056,0.016,0.104,0.039,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.125
      s0.034,0.111,0.034,0.182v0.041H20.65c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
      c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.01-0.04,0.021-0.053,0.039c-0.013,0.016-0.022,0.035-0.027,0.055
      c-0.005,0.02-0.007,0.041-0.007,0.064c0,0.045,0.01,0.084,0.029,0.113c0.019,0.033,0.061,0.061,0.124,0.086l0.23,0.102
      c0.057,0.023,0.103,0.051,0.139,0.078c0.036,0.025,0.065,0.055,0.086,0.086c0.022,0.029,0.036,0.064,0.045,0.102
      c0.008,0.039,0.012,0.08,0.012,0.125c0,0.156-0.046,0.27-0.136,0.342c-0.09,0.07-0.216,0.105-0.378,0.105
      c-0.168,0-0.289-0.035-0.362-0.109c-0.072-0.074-0.108-0.178-0.108-0.314v-0.061h0.298V-24.668z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M21.904-25.285h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.055
      c0.058-0.037,0.094-0.098,0.109-0.182h0.22v1.467h-0.298V-25.285z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M23.136-25.477c-0.02-0.029-0.056-0.045-0.105-0.045
      c-0.039,0-0.07,0.01-0.095,0.027c-0.023,0.02-0.042,0.045-0.056,0.078c-0.013,0.033-0.021,0.072-0.025,0.119
      c-0.004,0.045-0.007,0.094-0.007,0.148v0.072h0.005c0.025-0.039,0.06-0.068,0.107-0.092c0.046-0.021,0.102-0.035,0.168-0.035
      c0.065,0,0.12,0.014,0.166,0.035c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.09,0.062,0.145
      c0.013,0.057,0.02,0.117,0.02,0.184c0,0.17-0.038,0.297-0.113,0.387c-0.075,0.088-0.196,0.131-0.362,0.131
      c-0.102,0-0.183-0.018-0.244-0.053c-0.061-0.037-0.108-0.086-0.14-0.148c-0.033-0.064-0.054-0.137-0.064-0.219
      c-0.009-0.082-0.014-0.168-0.014-0.26c0-0.104,0.003-0.203,0.009-0.301c0.006-0.098,0.025-0.184,0.057-0.26
      c0.03-0.074,0.079-0.135,0.145-0.182c0.067-0.045,0.161-0.068,0.282-0.068c0.052,0,0.104,0.008,0.152,0.02
      c0.05,0.012,0.093,0.033,0.13,0.063c0.037,0.029,0.068,0.066,0.091,0.115c0.022,0.047,0.034,0.105,0.035,0.176h-0.286
      C23.165-25.4,23.156-25.445,23.136-25.477 M22.869-24.588c0.005,0.035,0.012,0.066,0.024,0.092
      c0.012,0.025,0.028,0.045,0.05,0.061c0.021,0.014,0.049,0.021,0.084,0.021c0.036,0,0.064-0.008,0.086-0.021
      c0.021-0.016,0.037-0.035,0.048-0.063c0.012-0.025,0.019-0.057,0.022-0.092c0.004-0.035,0.005-0.074,0.005-0.115
      c0-0.1-0.012-0.172-0.037-0.213c-0.025-0.043-0.066-0.064-0.124-0.064c-0.06,0-0.102,0.021-0.126,0.064
      c-0.025,0.041-0.038,0.113-0.038,0.213C22.863-24.664,22.865-24.625,22.869-24.588"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M24.131-25.477c-0.02-0.029-0.056-0.045-0.105-0.045
      c-0.039,0-0.07,0.01-0.095,0.027c-0.023,0.02-0.042,0.045-0.056,0.078c-0.013,0.033-0.021,0.072-0.025,0.119
      c-0.004,0.045-0.007,0.094-0.007,0.148v0.072h0.005c0.025-0.039,0.06-0.068,0.107-0.092c0.046-0.021,0.102-0.035,0.168-0.035
      c0.065,0,0.12,0.014,0.166,0.035c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.09,0.062,0.145
      c0.013,0.057,0.02,0.117,0.02,0.184c0,0.17-0.038,0.297-0.113,0.387c-0.075,0.088-0.196,0.131-0.362,0.131
      c-0.102,0-0.183-0.018-0.244-0.053c-0.061-0.037-0.108-0.086-0.14-0.148c-0.033-0.064-0.054-0.137-0.064-0.219
      c-0.009-0.082-0.014-0.168-0.014-0.26c0-0.104,0.003-0.203,0.009-0.301s0.025-0.184,0.057-0.26
      c0.03-0.074,0.079-0.135,0.145-0.182c0.067-0.045,0.161-0.068,0.282-0.068c0.052,0,0.104,0.008,0.152,0.02
      c0.05,0.012,0.093,0.033,0.13,0.063s0.068,0.066,0.091,0.115c0.022,0.047,0.034,0.105,0.035,0.176H24.16
      C24.16-25.4,24.151-25.445,24.131-25.477 M23.864-24.588c0.005,0.035,0.012,0.066,0.024,0.092
      c0.012,0.025,0.028,0.045,0.05,0.061c0.021,0.014,0.049,0.021,0.084,0.021c0.036,0,0.064-0.008,0.086-0.021
      c0.021-0.016,0.037-0.035,0.048-0.063c0.012-0.025,0.019-0.057,0.022-0.092c0.004-0.035,0.005-0.074,0.005-0.115
      c0-0.1-0.012-0.172-0.037-0.213c-0.025-0.043-0.066-0.064-0.124-0.064c-0.06,0-0.102,0.021-0.126,0.064
      c-0.025,0.041-0.038,0.113-0.038,0.213C23.858-24.664,23.86-24.625,23.864-24.588"
        />
        <rect
          x="26.925"
          y="-26.227"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#623B23"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M27.839-25.729c0.076,0,0.14,0.012,0.192,0.037
      c0.052,0.027,0.094,0.061,0.125,0.102c0.032,0.041,0.055,0.09,0.069,0.143c0.013,0.053,0.02,0.107,0.02,0.162
      c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.104-0.095,0.139c-0.041,0.037-0.091,0.063-0.148,0.08
      c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.592h-0.298v-1.48H27.839z M27.748-25.059c0.059,0,0.107-0.018,0.144-0.055
      c0.037-0.035,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.059-0.16-0.059h-0.124v0.451H27.748z
      "
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          points="28.842,-25.729 29.053,-24.684 29.058,-24.684 29.271,-25.729
      29.713,-25.729 29.713,-24.248 29.439,-24.248 29.439,-25.432 29.435,-25.432 29.172,-24.248 28.939,-24.248 28.677,-25.432
      28.673,-25.432 28.673,-24.248 28.399,-24.248 28.399,-25.729 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M30.167-24.668c0,0.035,0.003,0.066,0.008,0.096
      c0.005,0.027,0.016,0.051,0.031,0.07s0.036,0.035,0.061,0.045c0.026,0.014,0.058,0.018,0.097,0.018
      c0.045,0,0.086-0.016,0.123-0.045c0.036-0.029,0.055-0.076,0.055-0.137c0-0.033-0.005-0.063-0.014-0.086
      c-0.008-0.025-0.024-0.047-0.044-0.066c-0.021-0.02-0.048-0.035-0.082-0.053c-0.033-0.016-0.075-0.031-0.125-0.049
      c-0.066-0.021-0.124-0.047-0.172-0.072c-0.049-0.025-0.089-0.057-0.121-0.094c-0.033-0.035-0.057-0.074-0.072-0.121
      c-0.015-0.045-0.023-0.1-0.023-0.16c0-0.146,0.041-0.256,0.122-0.326c0.082-0.072,0.194-0.109,0.336-0.109
      c0.067,0,0.128,0.008,0.184,0.021c0.056,0.016,0.104,0.039,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.125
      s0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.124-0.057
      c-0.033,0-0.061,0.004-0.083,0.014s-0.04,0.021-0.054,0.039c-0.013,0.016-0.021,0.033-0.026,0.055
      c-0.005,0.02-0.007,0.041-0.007,0.064c0,0.045,0.01,0.084,0.029,0.113c0.019,0.033,0.06,0.061,0.124,0.086l0.23,0.102
      c0.057,0.023,0.103,0.051,0.139,0.078c0.036,0.025,0.064,0.055,0.086,0.086c0.021,0.029,0.036,0.064,0.045,0.102
      c0.007,0.039,0.011,0.08,0.011,0.125c0,0.156-0.045,0.27-0.135,0.342c-0.09,0.07-0.216,0.105-0.378,0.105
      c-0.169,0-0.29-0.035-0.362-0.109c-0.072-0.074-0.108-0.178-0.108-0.314v-0.061h0.298V-24.668z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M31.912-25.717h0.309v0.932h0.141v0.232h-0.141v0.303h-0.274v-0.303h-0.522
      v-0.244L31.912-25.717z M31.943-25.344l-0.286,0.559h0.29v-0.559H31.943z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M33.005-25.477c-0.019-0.029-0.056-0.045-0.104-0.045
      c-0.039,0-0.069,0.01-0.095,0.027c-0.024,0.02-0.043,0.045-0.056,0.078c-0.014,0.033-0.021,0.072-0.025,0.119
      c-0.005,0.045-0.007,0.094-0.007,0.148v0.072h0.004c0.025-0.039,0.062-0.068,0.106-0.092c0.048-0.021,0.104-0.035,0.17-0.035
      c0.064,0,0.119,0.014,0.166,0.035c0.045,0.023,0.082,0.055,0.111,0.096c0.027,0.041,0.049,0.09,0.062,0.145
      c0.013,0.057,0.019,0.117,0.019,0.184c0,0.17-0.037,0.297-0.111,0.387c-0.076,0.088-0.196,0.131-0.362,0.131
      c-0.103,0-0.185-0.018-0.245-0.053c-0.061-0.037-0.107-0.086-0.139-0.148c-0.033-0.064-0.055-0.137-0.064-0.219
      c-0.009-0.082-0.014-0.168-0.014-0.26c0-0.104,0.003-0.203,0.009-0.301c0.007-0.098,0.024-0.184,0.056-0.26
      c0.031-0.074,0.08-0.135,0.146-0.182c0.066-0.045,0.161-0.068,0.281-0.068c0.053,0,0.104,0.008,0.152,0.02
      c0.051,0.012,0.094,0.033,0.131,0.063s0.066,0.066,0.09,0.115c0.022,0.047,0.035,0.105,0.035,0.176h-0.285
      C33.035-25.4,33.025-25.445,33.005-25.477 M32.738-24.588c0.004,0.035,0.013,0.066,0.024,0.092
      c0.011,0.025,0.028,0.045,0.05,0.061c0.021,0.014,0.049,0.021,0.084,0.021s0.064-0.008,0.086-0.021
      c0.021-0.016,0.037-0.035,0.049-0.063c0.011-0.025,0.019-0.057,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.115
      c0-0.1-0.012-0.172-0.036-0.213c-0.025-0.043-0.066-0.064-0.125-0.064c-0.06,0-0.103,0.021-0.127,0.064
      c-0.025,0.041-0.037,0.113-0.037,0.213C32.732-24.664,32.734-24.625,32.738-24.588"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M33.766-24.461c0.02,0.031,0.055,0.047,0.105,0.047
      c0.039,0,0.071-0.01,0.096-0.029c0.022-0.018,0.041-0.045,0.054-0.076c0.013-0.033,0.022-0.074,0.026-0.119
      s0.006-0.096,0.006-0.148v-0.07h-0.004c-0.025,0.035-0.061,0.066-0.106,0.09c-0.047,0.021-0.103,0.033-0.169,0.033
      c-0.064,0-0.12-0.012-0.166-0.033c-0.046-0.023-0.083-0.055-0.111-0.096c-0.027-0.041-0.049-0.09-0.062-0.145
      c-0.015-0.057-0.021-0.117-0.021-0.184c0-0.17,0.038-0.301,0.113-0.391s0.195-0.135,0.361-0.135c0.103,0,0.184,0.02,0.244,0.059
      c0.062,0.037,0.108,0.088,0.141,0.15c0.031,0.063,0.054,0.135,0.064,0.219c0.008,0.082,0.014,0.17,0.014,0.26
      c0,0.104-0.004,0.205-0.01,0.303s-0.024,0.184-0.057,0.258c-0.029,0.074-0.08,0.133-0.146,0.178
      c-0.067,0.043-0.161,0.064-0.28,0.064c-0.054,0-0.104-0.004-0.153-0.016c-0.049-0.01-0.092-0.029-0.129-0.057
      c-0.037-0.029-0.068-0.066-0.091-0.115c-0.022-0.047-0.034-0.105-0.036-0.178h0.287C33.736-24.535,33.746-24.492,33.766-24.461
       M33.75-25.018c0.025,0.043,0.066,0.064,0.125,0.064s0.102-0.021,0.125-0.064c0.025-0.043,0.039-0.115,0.039-0.213
      c0-0.041-0.003-0.08-0.007-0.117c-0.004-0.035-0.012-0.066-0.024-0.092c-0.012-0.025-0.027-0.045-0.049-0.061
      c-0.021-0.014-0.05-0.021-0.084-0.021c-0.037,0-0.064,0.008-0.086,0.021c-0.021,0.016-0.037,0.035-0.05,0.063
      c-0.011,0.025-0.019,0.057-0.021,0.092c-0.004,0.035-0.006,0.074-0.006,0.115C33.713-25.133,33.725-25.061,33.75-25.018"
        />
        <rect
          x="36.794"
          y="-26.227"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#72BF44"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M37.707-25.729c0.076,0,0.141,0.012,0.193,0.037
      c0.051,0.027,0.094,0.061,0.125,0.102s0.055,0.09,0.068,0.143c0.013,0.053,0.02,0.107,0.02,0.162
      c0,0.076-0.012,0.143-0.033,0.199c-0.023,0.057-0.055,0.104-0.096,0.139c-0.041,0.037-0.091,0.063-0.148,0.08
      c-0.057,0.018-0.122,0.027-0.192,0.027h-0.159v0.592h-0.299v-1.48H37.707z M37.617-25.059c0.059,0,0.106-0.018,0.144-0.055
      c0.037-0.035,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.031-0.039-0.085-0.059-0.16-0.059h-0.123v0.451H37.617z
      "
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          points="38.711,-25.729 38.922,-24.684 38.927,-24.684 39.139,-25.729
      39.582,-25.729 39.582,-24.248 39.307,-24.248 39.307,-25.432 39.303,-25.432 39.041,-24.248 38.808,-24.248 38.545,-25.432
      38.541,-25.432 38.541,-24.248 38.268,-24.248 38.268,-25.729 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M40.035-24.668c0,0.035,0.004,0.066,0.008,0.096
      c0.006,0.027,0.016,0.051,0.031,0.07s0.035,0.035,0.061,0.045c0.025,0.014,0.059,0.018,0.098,0.018
      c0.045,0,0.086-0.016,0.123-0.045c0.035-0.029,0.055-0.076,0.055-0.137c0-0.033-0.006-0.063-0.014-0.086
      c-0.008-0.025-0.023-0.047-0.045-0.066c-0.02-0.02-0.047-0.035-0.082-0.053c-0.033-0.016-0.074-0.031-0.125-0.049
      c-0.064-0.021-0.123-0.047-0.172-0.072s-0.088-0.057-0.121-0.094c-0.033-0.035-0.057-0.074-0.072-0.121
      c-0.014-0.045-0.021-0.1-0.021-0.16c0-0.146,0.041-0.256,0.121-0.326c0.082-0.072,0.193-0.109,0.336-0.109
      c0.066,0,0.129,0.008,0.184,0.021c0.057,0.016,0.105,0.039,0.145,0.07c0.043,0.033,0.072,0.074,0.096,0.125
      s0.035,0.111,0.035,0.182v0.041h-0.287c0-0.07-0.012-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.123-0.057
      c-0.033,0-0.061,0.004-0.084,0.014c-0.021,0.01-0.039,0.021-0.053,0.039c-0.014,0.016-0.021,0.033-0.027,0.055
      c-0.004,0.02-0.006,0.041-0.006,0.064c0,0.045,0.01,0.084,0.029,0.113c0.018,0.033,0.059,0.061,0.123,0.086l0.23,0.102
      c0.057,0.023,0.104,0.051,0.139,0.078c0.035,0.025,0.064,0.055,0.086,0.086c0.021,0.029,0.035,0.064,0.045,0.102
      c0.008,0.039,0.012,0.08,0.012,0.125c0,0.156-0.045,0.27-0.135,0.342c-0.09,0.07-0.217,0.105-0.379,0.105
      c-0.168,0-0.289-0.035-0.361-0.109s-0.109-0.178-0.109-0.314v-0.061h0.299V-24.668z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M41.625-24.482c0.021,0.045,0.064,0.068,0.131,0.068
      c0.035,0,0.064-0.008,0.086-0.02s0.037-0.029,0.049-0.051s0.02-0.047,0.023-0.076c0.002-0.027,0.004-0.059,0.004-0.09
      c0-0.033-0.002-0.064-0.006-0.094c-0.006-0.029-0.014-0.057-0.027-0.08c-0.016-0.023-0.033-0.043-0.059-0.057
      s-0.059-0.021-0.1-0.021h-0.1v-0.195h0.098c0.031,0,0.057-0.006,0.08-0.018c0.02-0.014,0.039-0.031,0.051-0.053
      c0.014-0.021,0.021-0.047,0.029-0.074c0.006-0.027,0.01-0.057,0.01-0.086c0-0.07-0.012-0.119-0.035-0.15
      c-0.025-0.029-0.059-0.043-0.109-0.043c-0.031,0-0.057,0.006-0.076,0.018c-0.021,0.012-0.037,0.029-0.047,0.051
      c-0.012,0.021-0.02,0.047-0.021,0.074c-0.004,0.029-0.006,0.063-0.006,0.096h-0.273c0-0.146,0.037-0.254,0.109-0.326
      c0.074-0.07,0.18-0.107,0.322-0.107c0.133,0,0.236,0.031,0.311,0.092s0.111,0.156,0.111,0.285c0,0.09-0.02,0.162-0.059,0.219
      c-0.039,0.055-0.094,0.09-0.164,0.105v0.004c0.094,0.016,0.162,0.053,0.201,0.115c0.039,0.061,0.061,0.139,0.061,0.232
      c0,0.049-0.006,0.1-0.02,0.152c-0.014,0.051-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.076-0.148,0.104
      s-0.146,0.039-0.248,0.039c-0.143,0-0.248-0.037-0.32-0.115c-0.068-0.078-0.104-0.188-0.104-0.33v-0.006h0.285
      C41.594-24.592,41.604-24.527,41.625-24.482"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M42.873-25.477c-0.02-0.029-0.055-0.045-0.104-0.045
      c-0.039,0-0.07,0.01-0.096,0.027c-0.023,0.02-0.043,0.045-0.057,0.078c-0.012,0.033-0.021,0.072-0.025,0.119
      c-0.004,0.045-0.006,0.094-0.006,0.148v0.072h0.004c0.025-0.039,0.061-0.068,0.107-0.092c0.047-0.021,0.104-0.035,0.168-0.035
      c0.066,0,0.121,0.014,0.166,0.035c0.045,0.023,0.082,0.055,0.111,0.096s0.049,0.09,0.063,0.145
      c0.014,0.057,0.02,0.117,0.02,0.184c0,0.17-0.037,0.297-0.113,0.387c-0.076,0.088-0.195,0.131-0.361,0.131
      c-0.104,0-0.184-0.018-0.244-0.053c-0.061-0.037-0.107-0.086-0.141-0.148c-0.031-0.064-0.053-0.137-0.063-0.219
      s-0.016-0.168-0.016-0.26c0-0.104,0.004-0.203,0.01-0.301s0.025-0.184,0.057-0.26c0.031-0.074,0.08-0.135,0.146-0.182
      c0.066-0.045,0.16-0.068,0.281-0.068c0.053,0,0.104,0.008,0.152,0.02s0.092,0.033,0.129,0.063s0.068,0.066,0.09,0.115
      c0.023,0.047,0.035,0.105,0.037,0.176h-0.287C42.902-25.4,42.895-25.445,42.873-25.477 M42.607-24.588
      c0.004,0.035,0.012,0.066,0.023,0.092s0.027,0.045,0.051,0.061c0.02,0.014,0.049,0.021,0.084,0.021s0.063-0.008,0.084-0.021
      c0.021-0.016,0.039-0.035,0.049-0.063c0.012-0.025,0.02-0.057,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.115
      c0-0.1-0.012-0.172-0.037-0.213c-0.025-0.043-0.066-0.064-0.123-0.064c-0.061,0-0.104,0.021-0.127,0.064
      c-0.025,0.041-0.037,0.113-0.037,0.213C42.602-24.664,42.604-24.625,42.607-24.588"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M43.344-24.9c0.043-0.068,0.105-0.109,0.188-0.125v-0.004
      c-0.039-0.012-0.074-0.025-0.102-0.047c-0.025-0.02-0.047-0.043-0.064-0.07c-0.016-0.025-0.027-0.055-0.035-0.082
      c-0.006-0.029-0.01-0.057-0.01-0.084c0-0.145,0.037-0.246,0.115-0.309c0.076-0.064,0.182-0.096,0.316-0.096
      c0.133,0,0.238,0.031,0.314,0.096c0.078,0.063,0.117,0.164,0.117,0.309c0,0.027-0.004,0.055-0.014,0.084
      c-0.006,0.027-0.018,0.057-0.035,0.082c-0.016,0.027-0.037,0.051-0.064,0.07c-0.027,0.021-0.061,0.035-0.1,0.047v0.004
      c0.084,0.016,0.145,0.057,0.188,0.125c0.041,0.064,0.063,0.145,0.063,0.236c0,0.043-0.006,0.092-0.016,0.141
      c-0.012,0.053-0.033,0.1-0.066,0.145c-0.033,0.043-0.08,0.08-0.143,0.109c-0.061,0.029-0.143,0.043-0.244,0.043
      c-0.104,0-0.186-0.014-0.246-0.043c-0.063-0.029-0.109-0.066-0.143-0.109c-0.033-0.045-0.055-0.092-0.064-0.145
      c-0.012-0.049-0.016-0.098-0.016-0.141C43.283-24.756,43.303-24.836,43.344-24.9 M43.588-24.568
      c0.006,0.029,0.014,0.057,0.027,0.08s0.029,0.041,0.053,0.055c0.021,0.012,0.051,0.02,0.084,0.02s0.059-0.008,0.082-0.02
      c0.021-0.014,0.039-0.031,0.053-0.055c0.012-0.023,0.023-0.051,0.027-0.08c0.004-0.031,0.008-0.064,0.008-0.1
      s-0.004-0.068-0.008-0.1c-0.004-0.029-0.016-0.057-0.027-0.078c-0.014-0.025-0.031-0.041-0.053-0.055
      c-0.023-0.014-0.049-0.02-0.082-0.02s-0.063,0.006-0.084,0.02c-0.023,0.014-0.039,0.029-0.053,0.055
      c-0.014,0.021-0.021,0.049-0.027,0.078c-0.004,0.031-0.006,0.064-0.006,0.1S43.584-24.6,43.588-24.568 M43.645-25.166
      c0.027,0.033,0.063,0.051,0.107,0.051c0.043,0,0.078-0.018,0.104-0.051c0.027-0.031,0.041-0.084,0.041-0.154
      c0-0.135-0.049-0.201-0.145-0.201c-0.098,0-0.146,0.066-0.146,0.201C43.605-25.25,43.619-25.197,43.645-25.166"
        />
        <rect
          x="46.662"
          y="-26.227"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#ACD1E3"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M47.576-25.729c0.076,0,0.141,0.012,0.191,0.037
      c0.053,0.027,0.094,0.061,0.125,0.102c0.033,0.041,0.057,0.09,0.07,0.143c0.012,0.053,0.02,0.107,0.02,0.162
      c0,0.076-0.012,0.143-0.033,0.199c-0.023,0.057-0.057,0.104-0.096,0.139c-0.041,0.037-0.092,0.063-0.148,0.08
      c-0.059,0.018-0.123,0.027-0.193,0.027h-0.16v0.592h-0.297v-1.48H47.576z M47.484-25.059c0.061,0,0.107-0.018,0.145-0.055
      c0.037-0.035,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.033-0.039-0.086-0.059-0.16-0.059h-0.125v0.451H47.484z
      "
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          points="48.58,-25.729 48.791,-24.684 48.795,-24.684 49.008,-25.729
      49.449,-25.729 49.449,-24.248 49.176,-24.248 49.176,-25.432 49.172,-25.432 48.908,-24.248 48.676,-24.248 48.414,-25.432
      48.41,-25.432 48.41,-24.248 48.137,-24.248 48.137,-25.729 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M49.904-24.668c0,0.035,0.002,0.066,0.008,0.096
      c0.004,0.027,0.016,0.051,0.031,0.07c0.014,0.02,0.035,0.035,0.061,0.045c0.025,0.014,0.059,0.018,0.098,0.018
      c0.045,0,0.086-0.016,0.123-0.045c0.035-0.029,0.055-0.076,0.055-0.137c0-0.033-0.006-0.063-0.014-0.086
      c-0.008-0.025-0.025-0.047-0.045-0.066c-0.021-0.02-0.047-0.035-0.082-0.053c-0.033-0.016-0.074-0.031-0.125-0.049
      c-0.066-0.021-0.123-0.047-0.172-0.072s-0.088-0.057-0.121-0.094c-0.033-0.035-0.057-0.074-0.072-0.121
      c-0.014-0.045-0.023-0.1-0.023-0.16c0-0.146,0.041-0.256,0.123-0.326c0.082-0.072,0.193-0.109,0.336-0.109
      c0.066,0,0.129,0.008,0.184,0.021c0.057,0.016,0.104,0.039,0.145,0.07c0.041,0.033,0.072,0.074,0.096,0.125
      s0.033,0.111,0.033,0.182v0.041h-0.285c0-0.07-0.014-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.123-0.057
      c-0.033,0-0.063,0.004-0.084,0.014c-0.023,0.01-0.039,0.021-0.055,0.039c-0.012,0.016-0.02,0.033-0.025,0.055
      c-0.004,0.02-0.006,0.041-0.006,0.064c0,0.045,0.01,0.082,0.027,0.113c0.02,0.033,0.061,0.061,0.125,0.086l0.23,0.102
      c0.057,0.023,0.102,0.051,0.139,0.078c0.035,0.025,0.064,0.055,0.086,0.086c0.021,0.029,0.035,0.064,0.045,0.102
      c0.008,0.039,0.012,0.08,0.012,0.125c0,0.156-0.045,0.27-0.137,0.342c-0.09,0.07-0.215,0.105-0.377,0.105
      c-0.17,0-0.291-0.035-0.361-0.109c-0.072-0.074-0.109-0.178-0.109-0.314v-0.061h0.299V-24.668z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M51.17-24.248c0-0.066,0.008-0.127,0.02-0.184
      c0.014-0.057,0.033-0.109,0.061-0.162c0.027-0.051,0.061-0.1,0.102-0.148c0.041-0.051,0.088-0.1,0.141-0.148l0.121-0.111
      c0.033-0.031,0.061-0.057,0.08-0.082s0.035-0.051,0.047-0.074c0.01-0.025,0.02-0.053,0.021-0.082
      c0.004-0.029,0.006-0.063,0.006-0.098c0-0.123-0.049-0.184-0.146-0.184c-0.037,0-0.066,0.008-0.088,0.023
      c-0.021,0.014-0.037,0.035-0.049,0.061c-0.012,0.027-0.02,0.059-0.023,0.092c-0.004,0.035-0.004,0.072-0.004,0.111H51.17v-0.057
      c0-0.137,0.037-0.242,0.109-0.316c0.074-0.072,0.189-0.109,0.346-0.109c0.15,0,0.262,0.035,0.332,0.104
      c0.072,0.07,0.109,0.168,0.109,0.297c0,0.047-0.004,0.09-0.014,0.131c-0.008,0.041-0.021,0.082-0.039,0.117
      c-0.018,0.039-0.041,0.074-0.07,0.109s-0.064,0.072-0.107,0.109l-0.164,0.152c-0.045,0.041-0.078,0.08-0.104,0.117
      c-0.025,0.039-0.041,0.07-0.047,0.1h0.553v0.232H51.17z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M52.508-24.461c0.02,0.031,0.055,0.047,0.105,0.047
      c0.039,0,0.07-0.01,0.096-0.029c0.023-0.018,0.041-0.045,0.053-0.076c0.014-0.033,0.023-0.074,0.027-0.119
      s0.006-0.096,0.006-0.148v-0.07h-0.004c-0.025,0.035-0.061,0.066-0.107,0.09c-0.047,0.021-0.102,0.033-0.168,0.033
      c-0.064,0-0.119-0.012-0.166-0.033c-0.047-0.023-0.084-0.055-0.111-0.096s-0.049-0.09-0.063-0.145
      c-0.014-0.057-0.02-0.117-0.02-0.184c0-0.17,0.039-0.301,0.113-0.391s0.195-0.135,0.361-0.135c0.104,0,0.184,0.02,0.244,0.059
      c0.063,0.037,0.109,0.088,0.141,0.15s0.055,0.135,0.064,0.219c0.008,0.082,0.014,0.17,0.014,0.26c0,0.104-0.004,0.205-0.01,0.303
      s-0.025,0.184-0.057,0.258c-0.029,0.074-0.08,0.133-0.146,0.178c-0.066,0.043-0.162,0.064-0.281,0.064
      c-0.053,0-0.104-0.004-0.152-0.016c-0.049-0.01-0.092-0.029-0.129-0.057c-0.037-0.029-0.068-0.066-0.09-0.115
      c-0.023-0.047-0.035-0.105-0.037-0.178h0.287C52.479-24.535,52.488-24.492,52.508-24.461 M52.492-25.018
      c0.025,0.043,0.066,0.064,0.125,0.064s0.102-0.021,0.125-0.064c0.025-0.043,0.039-0.115,0.039-0.213
      c0-0.041-0.002-0.08-0.006-0.117c-0.004-0.035-0.014-0.066-0.025-0.092s-0.027-0.045-0.049-0.061
      c-0.021-0.014-0.049-0.021-0.084-0.021c-0.037,0-0.064,0.008-0.086,0.021c-0.021,0.016-0.037,0.035-0.049,0.063
      c-0.012,0.025-0.02,0.057-0.021,0.092c-0.004,0.035-0.006,0.074-0.006,0.115C52.455-25.133,52.467-25.061,52.492-25.018"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          d="M53.17-25.227c0.006-0.096,0.025-0.178,0.057-0.25
      c0.029-0.072,0.076-0.131,0.137-0.174c0.063-0.045,0.146-0.066,0.256-0.066c0.09,0,0.164,0.014,0.223,0.039
      c0.061,0.025,0.107,0.066,0.143,0.123s0.059,0.131,0.074,0.221c0.014,0.09,0.021,0.201,0.021,0.33
      c0,0.102-0.004,0.201-0.01,0.295c-0.008,0.094-0.027,0.178-0.057,0.25s-0.076,0.129-0.139,0.17
      c-0.061,0.043-0.146,0.064-0.256,0.064c-0.088,0-0.162-0.014-0.221-0.037c-0.061-0.023-0.107-0.063-0.143-0.119
      s-0.061-0.131-0.074-0.221c-0.014-0.092-0.021-0.201-0.021-0.33C53.16-25.035,53.162-25.133,53.17-25.227 M53.465-24.641
      c0.006,0.059,0.014,0.104,0.027,0.139c0.012,0.033,0.027,0.057,0.049,0.068c0.021,0.014,0.047,0.02,0.078,0.02
      c0.033,0,0.063-0.008,0.082-0.023c0.02-0.018,0.037-0.043,0.049-0.082c0.01-0.037,0.02-0.09,0.025-0.152
      c0.004-0.064,0.006-0.143,0.006-0.236v-0.16c0-0.092-0.002-0.168-0.006-0.227c-0.006-0.059-0.016-0.105-0.027-0.139
      s-0.029-0.057-0.049-0.068c-0.021-0.014-0.047-0.02-0.08-0.02s-0.059,0.008-0.08,0.023c-0.02,0.018-0.037,0.043-0.049,0.082
      c-0.012,0.037-0.02,0.088-0.025,0.152c-0.004,0.063-0.008,0.141-0.008,0.234v0.16C53.457-24.775,53.461-24.701,53.465-24.641"
        />
        <rect
          x="66.398"
          y="-26.227"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#FFFFFF"
          width="8.813"
          height="8.813"
        />

        <rect
          x="66.398"
          y="-26.227"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.813"
          height="8.813"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          points="66.99,-25.729 67.152,-24.635 67.158,-24.635 67.332,-25.729 67.619,-25.729
      67.793,-24.635 67.797,-24.635 67.959,-25.729 68.246,-25.729 67.977,-24.248 67.643,-24.248 67.477,-25.322 67.473,-25.322
      67.307,-24.248 66.973,-24.248 66.705,-25.729 			"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          points="68.664,-25.729 68.664,-25.162 69.012,-25.162 69.012,-25.729 69.311,-25.729
      69.311,-24.248 69.012,-24.248 69.012,-24.906 68.664,-24.906 68.664,-24.248 68.365,-24.248 68.365,-25.729 			"
        />
        <rect
          x="69.531"
          y="-25.729"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          width="0.299"
          height="1.48"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          points="70.918,-25.484 70.596,-25.484 70.596,-24.248 70.299,-24.248 70.299,-25.484
      69.975,-25.484 69.975,-25.729 70.918,-25.729 			"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          points="71.875,-25.484 71.346,-25.484 71.346,-25.135 71.844,-25.135 71.844,-24.891
      71.346,-24.891 71.346,-24.494 71.895,-24.494 71.895,-24.248 71.047,-24.248 71.047,-25.729 71.875,-25.729 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M12.883,75.936c0.592,0,0.973,0.391,0.973,0.99
      c0,0.596-0.381,0.986-0.973,0.986c-0.588,0-0.969-0.391-0.969-0.986C11.914,76.326,12.295,75.936,12.883,75.936 M12.883,77.525
      c0.352,0,0.457-0.301,0.457-0.6c0-0.301-0.105-0.602-0.457-0.602c-0.349,0-0.454,0.301-0.454,0.602
      C12.429,77.225,12.534,77.525,12.883,77.525"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M14.841,75.986h0.378v0.344h-0.378v0.93c0,0.174,0.044,0.219,0.218,0.219
      c0.054,0,0.105-0.004,0.16-0.016v0.402c-0.087,0.014-0.2,0.02-0.302,0.02c-0.315,0-0.591-0.074-0.591-0.447V76.33h-0.312v-0.344
      h0.312v-0.563h0.515V75.986z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M15.89,77.049c0.014,0.326,0.174,0.477,0.46,0.477
      c0.208,0,0.374-0.127,0.407-0.244h0.454c-0.146,0.443-0.454,0.631-0.879,0.631c-0.591,0-0.957-0.406-0.957-0.986
      c0-0.563,0.388-0.99,0.957-0.99c0.639,0,0.947,0.537,0.911,1.113H15.89z M16.728,76.723c-0.047-0.262-0.16-0.398-0.41-0.398
      c-0.327,0-0.421,0.254-0.428,0.398H16.728z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M17.946,77.252c0.004,0.225,0.193,0.316,0.396,0.316
      c0.149,0,0.337-0.059,0.337-0.24c0-0.156-0.214-0.209-0.584-0.289c-0.298-0.066-0.595-0.17-0.595-0.502
      c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.139,0.828,0.598h-0.49c-0.015-0.199-0.167-0.254-0.352-0.254
      c-0.116,0-0.287,0.021-0.287,0.176c0,0.184,0.29,0.209,0.584,0.279c0.302,0.068,0.596,0.178,0.596,0.525
      c0,0.494-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.162-0.882-0.66H17.946z"
        />
        <rect
          x="9.089"
          y="80.465"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M12.193,79.027h0.89c0.798,0,1.244,0.398,1.244,1.234
      c0,0.867-0.381,1.357-1.244,1.357h-0.89V79.027z M12.538,81.328h0.573c0.236,0,0.871-0.064,0.871-1.02
      c0-0.617-0.228-0.99-0.864-0.99h-0.58V81.328z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M15.505,79.699c0.595,0,0.911,0.432,0.911,0.984
      c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.594,80.131,14.91,79.699,15.505,79.699 M15.505,81.391
      c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
      C14.921,81.137,15.182,81.391,15.505,81.391"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M17.786,79.742h0.291v0.297h0.006c0.131-0.232,0.341-0.34,0.61-0.34
      c0.494,0,0.646,0.281,0.646,0.686v1.234h-0.308v-1.271c0-0.227-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.061
      h-0.309V79.742z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M20.612,79.699c0.595,0,0.911,0.432,0.911,0.984
      c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.701,80.131,20.017,79.699,20.612,79.699 M20.612,81.391
      c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
      C20.028,81.137,20.289,81.391,20.612,81.391"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M22.313,79.742h0.374v0.271h-0.374v1.166c0,0.143,0.039,0.166,0.232,0.166
      h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319V79.18h0.309V79.742z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M25.698,81.611c-0.055,0.033-0.123,0.051-0.222,0.051
      c-0.16,0-0.261-0.088-0.261-0.291c-0.17,0.201-0.399,0.291-0.661,0.291c-0.34,0-0.62-0.152-0.62-0.527
      c0-0.424,0.316-0.514,0.635-0.576c0.341-0.064,0.632-0.043,0.632-0.275c0-0.268-0.222-0.313-0.418-0.313
      c-0.261,0-0.453,0.08-0.468,0.355h-0.309c0.018-0.465,0.378-0.627,0.795-0.627c0.338,0,0.704,0.076,0.704,0.514v0.967
      c0,0.145,0,0.211,0.098,0.211c0.026,0,0.055-0.004,0.095-0.02V81.611z M25.197,80.65c-0.12,0.086-0.352,0.09-0.559,0.127
      c-0.203,0.035-0.378,0.107-0.378,0.336c0,0.205,0.175,0.277,0.364,0.277c0.406,0,0.573-0.256,0.573-0.426V80.65z"
        />
        <rect
          x="26.002"
          y="79.027"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          width="0.308"
          height="2.59"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M27.216,79.742h0.374v0.271h-0.374v1.166c0,0.143,0.04,0.166,0.232,0.166
      h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319V79.18h0.309V79.742z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M29.531,81.023c-0.083,0.414-0.381,0.639-0.802,0.639
      c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.619,0.849,1.074h-1.405
      c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.367H29.531z M29.233,80.5
      c-0.014-0.293-0.236-0.529-0.54-0.529c-0.323,0-0.516,0.242-0.538,0.529H29.233z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M29.867,79.742h0.291v0.396h0.006c0.149-0.301,0.356-0.451,0.69-0.439v0.326
      c-0.497,0-0.678,0.283-0.678,0.758v0.836h-0.309V79.742z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M33.756,81.611c-0.055,0.033-0.123,0.051-0.222,0.051
      c-0.159,0-0.261-0.088-0.261-0.291c-0.17,0.201-0.399,0.291-0.66,0.291c-0.342,0-0.621-0.152-0.621-0.527
      c0-0.424,0.316-0.514,0.636-0.576c0.34-0.064,0.631-0.043,0.631-0.275c0-0.268-0.222-0.313-0.417-0.313
      c-0.262,0-0.453,0.08-0.469,0.355h-0.309c0.019-0.465,0.379-0.627,0.795-0.627c0.338,0,0.705,0.076,0.705,0.514v0.967
      c0,0.145,0,0.211,0.097,0.211c0.026,0,0.055-0.004,0.095-0.02V81.611z M33.255,80.65c-0.119,0.086-0.353,0.09-0.56,0.127
      c-0.202,0.035-0.377,0.107-0.377,0.336c0,0.205,0.175,0.277,0.363,0.277c0.406,0,0.573-0.256,0.573-0.426V80.65z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M34.031,79.742h0.289v0.396h0.008c0.148-0.301,0.355-0.451,0.689-0.439
      v0.326c-0.496,0-0.679,0.283-0.679,0.758v0.836h-0.308V79.742z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M35.678,79.742h0.374v0.271h-0.374v1.166c0,0.143,0.039,0.166,0.232,0.166
      h0.142v0.273h-0.236c-0.319,0-0.446-0.066-0.446-0.41v-1.195H35.05v-0.271h0.319V79.18h0.309V79.742z"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          points="38.25,81.619 37.924,81.619 37.543,80.127 37.535,80.127
      37.158,81.619 36.824,81.619 36.221,79.742 36.562,79.742 36.986,81.277 36.994,81.277 37.371,79.742 37.709,79.742
      38.101,81.277 38.109,81.277 38.529,79.742 38.849,79.742 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M39.949,79.699c0.596,0,0.912,0.432,0.912,0.984
      c0,0.547-0.316,0.979-0.912,0.979c-0.594,0-0.91-0.432-0.91-0.979C39.039,80.131,39.355,79.699,39.949,79.699 M39.949,81.391
      c0.324,0,0.584-0.254,0.584-0.707c0-0.459-0.26-0.713-0.584-0.713c-0.322,0-0.584,0.254-0.584,0.713
      C39.365,81.137,39.627,81.391,39.949,81.391"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M41.213,79.742h0.289v0.396h0.008c0.148-0.301,0.355-0.451,0.689-0.439
      v0.326c-0.498,0-0.68,0.283-0.68,0.758v0.836h-0.307V79.742z"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          points="42.449,79.027 42.758,79.027 42.758,80.563 43.613,79.742
      44.027,79.742 43.283,80.424 44.082,81.619 43.689,81.619 43.047,80.639 42.758,80.908 42.758,81.619 42.449,81.619 			"
        />
        <rect
          x="9.089"
          y="84.221"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M14.262,84.439c0,0.652-0.374,0.998-1.02,0.998
      c-0.668,0-1.063-0.309-1.063-0.998v-1.656h0.345v1.656c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.656
      h0.345V84.439z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M14.95,84.783c0.011,0.277,0.25,0.363,0.505,0.363
      c0.192,0,0.454-0.043,0.454-0.279c0-0.24-0.306-0.279-0.614-0.348c-0.305-0.07-0.614-0.172-0.614-0.531
      c0-0.377,0.374-0.533,0.701-0.533c0.414,0,0.744,0.131,0.769,0.588h-0.308c-0.022-0.24-0.233-0.314-0.435-0.314
      c-0.186,0-0.4,0.051-0.4,0.238c0,0.223,0.326,0.258,0.614,0.326c0.308,0.07,0.612,0.172,0.612,0.535
      c0,0.445-0.417,0.59-0.798,0.59c-0.421,0-0.777-0.17-0.794-0.635H14.95z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M18.174,84.781c-0.083,0.412-0.38,0.637-0.802,0.637
      c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.882-0.977c0.664,0,0.868,0.621,0.849,1.074h-1.404
      c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.365H18.174z M17.877,84.258
      c-0.015-0.295-0.236-0.531-0.541-0.531c-0.323,0-0.515,0.244-0.537,0.531H17.877z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M19.958,83.498h0.374v0.273h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
      h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.273h0.319v-0.563h0.309V83.498z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M21,83.162h-0.308v-0.379H21V83.162z M20.691,83.5h0.308v1.875h-0.308V83.5z
      "
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M23.097,85.215c0,0.609-0.28,0.922-0.871,0.922
      c-0.352,0-0.759-0.143-0.777-0.551h0.309c0.014,0.225,0.283,0.305,0.486,0.305c0.403,0,0.562-0.291,0.562-0.711v-0.123h-0.007
      c-0.102,0.227-0.348,0.34-0.584,0.34c-0.559,0-0.838-0.438-0.838-0.951c0-0.441,0.217-0.99,0.867-0.99
      c0.236,0,0.446,0.105,0.559,0.313h0.003V83.5h0.291V85.215z M22.792,84.398c0-0.33-0.145-0.672-0.533-0.672
      c-0.396,0-0.556,0.324-0.556,0.684c0,0.336,0.124,0.715,0.53,0.715C22.64,85.125,22.792,84.752,22.792,84.398"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M23.56,82.783h0.309v0.992h0.007c0.102-0.225,0.366-0.32,0.591-0.32
      c0.494,0,0.646,0.283,0.646,0.688v1.232h-0.308v-1.27c0-0.23-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
      H23.56V82.783z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M26.005,83.498h0.374v0.273h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
      h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.273h0.319v-0.563h0.309V83.498z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M28.537,83.455c0.595,0,0.911,0.432,0.911,0.984
      c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.625,83.887,27.942,83.455,28.537,83.455 M28.537,85.146
      c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.256-0.584,0.713
      C27.953,84.893,28.214,85.146,28.537,85.146"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          points="30.655,85.375 30.324,85.375 29.628,83.5 29.973,83.5
      30.499,85.063 30.506,85.063 31.018,83.5 31.34,83.5 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M33.223,84.781c-0.082,0.412-0.381,0.637-0.802,0.637
      c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.881-0.977c0.664,0,0.867,0.621,0.85,1.074h-1.405
      c-0.011,0.326,0.174,0.617,0.584,0.617c0.254,0,0.433-0.123,0.486-0.365H33.223z M32.926,84.258
      c-0.015-0.295-0.236-0.531-0.541-0.531c-0.322,0-0.516,0.244-0.538,0.531H32.926z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M33.559,83.498h0.291v0.396h0.006c0.15-0.301,0.357-0.449,0.691-0.439v0.326
      c-0.498,0-0.679,0.283-0.679,0.758v0.836h-0.31V83.498z"
        />
        <rect
          x="34.796"
          y="82.783"
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          width="0.308"
          height="2.592"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M36.393,83.455c0.595,0,0.91,0.432,0.91,0.984
      c0,0.547-0.315,0.979-0.91,0.979c-0.596,0-0.912-0.432-0.912-0.979C35.48,83.887,35.797,83.455,36.393,83.455 M36.393,85.146
      c0.322,0,0.584-0.254,0.584-0.707c0-0.457-0.262-0.713-0.584-0.713c-0.323,0-0.584,0.256-0.584,0.713
      C35.809,84.893,36.069,85.146,36.393,85.146"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M38.939,84.102c-0.049-0.236-0.211-0.373-0.465-0.373
      c-0.447,0-0.585,0.352-0.585,0.732c0,0.344,0.156,0.686,0.548,0.686c0.297,0,0.475-0.174,0.516-0.457h0.316
      c-0.07,0.457-0.357,0.729-0.828,0.729c-0.572,0-0.879-0.398-0.879-0.957c0-0.563,0.291-1.006,0.887-1.006
      c0.424,0,0.766,0.201,0.809,0.646H38.939z"
        />
        <polygon
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          points="39.631,82.783 39.939,82.783 39.939,84.318 40.795,83.498
      41.209,83.498 40.465,84.182 41.264,85.375 40.871,85.375 40.229,84.395 39.939,84.664 39.939,85.375 39.631,85.375 			"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M41.822,83.162h-0.311v-0.379h0.311V83.162z M41.512,83.5h0.311v1.875
      h-0.311V83.5z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M42.299,83.498h0.291v0.297h0.006c0.133-0.23,0.342-0.34,0.611-0.34
      c0.494,0,0.645,0.283,0.645,0.686v1.234h-0.307v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.375,0-0.555,0.25-0.555,0.588v1.059
      h-0.311V83.498z"
        />
        <path
          clipPath="url(#UPCYCLING_SVGID_28_)"
          fill="#EC008C"
          d="M45.936,85.215c0,0.609-0.279,0.922-0.871,0.922
      c-0.352,0-0.76-0.143-0.777-0.551h0.309c0.014,0.225,0.283,0.305,0.486,0.305c0.402,0,0.563-0.291,0.563-0.711v-0.123h-0.008
      c-0.102,0.227-0.348,0.34-0.584,0.34c-0.559,0-0.838-0.438-0.838-0.951c0-0.441,0.217-0.99,0.867-0.99
      c0.236,0,0.445,0.105,0.559,0.313h0.004V83.5h0.291V85.215z M45.631,84.398c0-0.33-0.146-0.672-0.533-0.672
      c-0.396,0-0.557,0.324-0.557,0.684c0,0.336,0.125,0.715,0.529,0.715C45.479,85.125,45.631,84.752,45.631,84.398"
        />
      </g>
    </g>
  );
};

const Saver = () => {
  return (
    <g id="Saver">
      <g>
        <defs>
          <rect
            id="SAVER_SVGID_29_"
            x="-13.146"
            y="0.242"
            width="74.905"
            height="61.768"
          />
        </defs>
        <clipPath id="SAVER_SVGID_30_">
          <use xlinkHref="#SAVER_SVGID_29_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#F6ADCD"
          d="M55.897,61.314H6.7c-3.052,0-5.549-2.498-5.549-5.549V6.568
      c0-3.053,2.497-5.549,5.549-5.549h49.197c3.052,0,5.549,2.496,5.549,5.549v49.197C61.446,58.816,58.949,61.314,55.897,61.314"
        />
        <rect
          x="17.848"
          y="39.404"
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#EC008C"
          width="25.521"
          height="4.682"
        />

        <rect
          x="17.848"
          y="39.402"
          clipPath="url(#SAVER_SVGID_30_)"
          fill="none"
          stroke="#EC008C"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          width="25.521"
          height="4.684"
        />
        <rect
          x="4.335"
          y="33.363"
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#AC6A93"
          width="53.18"
          height="25.971"
        />
        <path
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#7B2959"
          d="M26.033,8.578l-0.964,0.195c-0.071-0.375-0.38-0.559-0.695-0.559
      c-0.327,0-0.535,0.178-0.535,0.404c0,0.16,0.071,0.279,0.321,0.334l0.826,0.184c0.69,0.154,1.095,0.529,1.095,1.158
      c0,0.852-0.756,1.291-1.642,1.291c-0.898,0-1.635-0.43-1.742-1.207l1.011-0.195c0.101,0.41,0.386,0.588,0.785,0.588
      c0.368,0,0.589-0.166,0.589-0.41c0-0.178-0.084-0.291-0.369-0.352L23.88,9.832c-0.571-0.131-1.07-0.428-1.07-1.154
      c0-0.773,0.642-1.254,1.576-1.254C25.254,7.424,25.884,7.852,26.033,8.578"
        />
        <path
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#7B2959"
          d="M29.797,11.496h-0.951v-0.238c-0.232,0.209-0.541,0.328-0.928,0.328
      c-0.791,0-1.456-0.637-1.456-1.582c0-0.939,0.665-1.582,1.456-1.582c0.387,0,0.696,0.119,0.928,0.328V8.512h0.951V11.496z
       M28.847,10.004c0-0.457-0.345-0.773-0.731-0.773c-0.417,0-0.72,0.316-0.72,0.773c0,0.465,0.303,0.773,0.72,0.773
      C28.501,10.777,28.847,10.469,28.847,10.004"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#7B2959"
          points="32.318,11.496 31.35,11.496 30.113,8.506 31.118,8.506
      31.837,10.35 32.551,8.506 33.557,8.506 			"
        />
        <path
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#7B2959"
          d="M36.68,10.248h-2.188c0.078,0.357,0.321,0.576,0.643,0.576
      c0.221,0,0.476-0.053,0.637-0.344l0.844,0.172c-0.256,0.631-0.797,0.934-1.48,0.934c-0.862,0-1.576-0.637-1.576-1.582
      c0-0.939,0.714-1.582,1.583-1.582c0.844,0,1.521,0.607,1.538,1.582V10.248z M34.51,9.666h1.231
      c-0.09-0.322-0.327-0.469-0.6-0.469C34.873,9.197,34.6,9.363,34.51,9.666"
        />
        <path
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#7B2959"
          d="M39.335,8.494l-0.042,0.951h-0.172c-0.666,0-1.029,0.346-1.029,1.117v0.934
      h-0.951V8.512h0.951v0.57c0.215-0.355,0.541-0.613,1.029-0.613C39.204,8.469,39.264,8.477,39.335,8.494"
        />
        <path
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#EACBD9"
          d="M56.082,4.729c0.689,0,1.27,0.582,1.27,1.271v50.334
      c0,0.688-0.58,1.27-1.27,1.27H5.749c-0.689,0-1.271-0.582-1.271-1.27V6c0-0.689,0.582-1.271,1.271-1.271H56.082 M56.082,0.324
      H5.749C2.627,0.324,0.072,2.877,0.072,6v50.334c0,3.121,2.555,5.676,5.677,5.676h50.333c3.122,0,5.677-2.555,5.677-5.676V6
      C61.759,2.877,59.204,0.324,56.082,0.324"
        />
        <rect
          x="17.848"
          y="37.15"
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#7B2959"
          width="25.521"
          height="3.721"
        />
        <path
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#97A0A4"
          d="M42.188,29.902c0,6.412-5.199,11.611-11.611,11.611
      s-11.61-5.199-11.61-11.611s5.198-11.609,11.61-11.609S42.188,23.49,42.188,29.902"
        />
        <path
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#C8CCCC"
          d="M42.188,31.518c0,6.412-5.199,11.611-11.611,11.611
      s-11.61-5.199-11.61-11.611s5.198-11.609,11.61-11.609S42.188,25.105,42.188,31.518"
        />
        <path
          clipPath="url(#SAVER_SVGID_30_)"
          fill="#C8CCCC"
          d="M42.188,31.518c0,6.412-5.199,11.611-11.611,11.611
      s-11.61-5.199-11.61-11.611s5.198-11.609,11.61-11.609S42.188,25.105,42.188,31.518"
        />
      </g>
      <g>
        <defs>
          <path
            id="SAVER_SVGID_31_"
            d="M18.968,29.902c0,6.412,5.197,11.609,11.61,11.609c6.411,0,11.609-5.197,11.609-11.609
        s-5.198-11.609-11.609-11.609C24.165,18.293,18.968,23.49,18.968,29.902"
          />
        </defs>
        <clipPath id="SAVER_SVGID_32_">
          <use xlinkHref="#SAVER_SVGID_31_" overflow="visible" />
        </clipPath>

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="19.771"
          y1="16.709"
          x2="19.771"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="20.98"
          y1="16.709"
          x2="20.98"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="22.189"
          y1="16.709"
          x2="22.189"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="23.398"
          y1="16.709"
          x2="23.398"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="24.607"
          y1="16.709"
          x2="24.607"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="25.817"
          y1="16.709"
          x2="25.817"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="27.025"
          y1="16.709"
          x2="27.025"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="28.234"
          y1="16.709"
          x2="28.234"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="29.444"
          y1="16.709"
          x2="29.444"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="30.652"
          y1="16.709"
          x2="30.652"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="31.861"
          y1="16.709"
          x2="31.861"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="33.07"
          y1="16.709"
          x2="33.07"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="34.279"
          y1="16.709"
          x2="34.279"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="35.488"
          y1="16.709"
          x2="35.488"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="36.697"
          y1="16.709"
          x2="36.697"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="37.906"
          y1="16.709"
          x2="37.906"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="39.115"
          y1="16.709"
          x2="39.115"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="40.324"
          y1="16.709"
          x2="40.324"
          y2="43.508"
        />

        <line
          clipPath="url(#SAVER_SVGID_32_)"
          fill="none"
          stroke="#C8CCCC"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          x1="41.533"
          y1="16.709"
          x2="41.533"
          y2="43.508"
        />
      </g>
      <g>
        <defs>
          <rect
            id="SAVER_SVGID_33_"
            x="-13.146"
            y="0.242"
            width="74.905"
            height="61.768"
          />
        </defs>
        <clipPath id="SAVER_SVGID_34_">
          <use xlinkHref="#SAVER_SVGID_33_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#97A0A4"
          d="M30.162,41.178c0-0.232,0.187-0.424,0.425-0.424
      c0.232,0,0.425,0.191,0.425,0.424c0,0.236-0.187,0.424-0.425,0.424C30.355,41.602,30.162,41.414,30.162,41.178 M28.838,41.453
      L28.838,41.453c-0.233-0.039-0.385-0.26-0.346-0.49c0.04-0.232,0.261-0.387,0.493-0.346c0.232,0.039,0.385,0.26,0.345,0.492
      c-0.04,0.203-0.215,0.352-0.419,0.352C28.883,41.461,28.86,41.461,28.838,41.453 M31.844,41.104
      c-0.04-0.227,0.113-0.447,0.346-0.492c0.232-0.039,0.453,0.113,0.492,0.344c0.04,0.234-0.113,0.453-0.346,0.494
      c-0.021,0.004-0.05,0.004-0.073,0.004C32.059,41.453,31.884,41.313,31.844,41.104 M27.139,41.002
      c-0.221-0.078-0.334-0.322-0.255-0.545c0.079-0.219,0.323-0.334,0.544-0.254c0.221,0.08,0.334,0.322,0.255,0.543
      c-0.063,0.178-0.227,0.283-0.397,0.283C27.235,41.029,27.184,41.02,27.139,41.002 M33.492,40.74
      c-0.079-0.219,0.033-0.463,0.254-0.543c0.215-0.078,0.459,0.035,0.544,0.256c0.08,0.215-0.033,0.457-0.255,0.543
      c-0.051,0.018-0.096,0.023-0.147,0.023C33.718,41.02,33.554,40.91,33.492,40.74 M25.548,40.262L25.548,40.262
      c-0.204-0.115-0.277-0.375-0.159-0.58c0.119-0.203,0.38-0.277,0.578-0.158c0.209,0.119,0.277,0.381,0.159,0.584
      c-0.08,0.135-0.221,0.209-0.368,0.209C25.684,40.316,25.61,40.301,25.548,40.262 M35.043,40.096
      c-0.113-0.203-0.045-0.463,0.159-0.584v0.006c0.198-0.119,0.459-0.051,0.577,0.154c0.119,0.203,0.052,0.459-0.152,0.576
      c-0.068,0.039-0.143,0.059-0.215,0.059C35.264,40.307,35.123,40.232,35.043,40.096 M24.104,39.252
      c-0.181-0.146-0.204-0.42-0.051-0.594c0.148-0.182,0.419-0.205,0.595-0.057c0.181,0.152,0.203,0.418,0.056,0.6
      c-0.085,0.102-0.204,0.152-0.328,0.152C24.279,39.354,24.184,39.32,24.104,39.252 M36.464,39.189
      c-0.147-0.18-0.124-0.447,0.052-0.6c0.182-0.154,0.447-0.125,0.6,0.051c0.152,0.182,0.125,0.447-0.051,0.6
      c-0.08,0.068-0.176,0.098-0.271,0.098C36.669,39.338,36.549,39.285,36.464,39.189 M22.858,38.012h0.006
      c-0.153-0.174-0.131-0.447,0.045-0.594c0.182-0.152,0.448-0.131,0.601,0.051c0.152,0.176,0.13,0.447-0.052,0.594
      c-0.079,0.068-0.175,0.102-0.271,0.102C23.062,38.164,22.943,38.113,22.858,38.012 M37.705,38.053
      c-0.176-0.154-0.205-0.42-0.051-0.602c0.151-0.18,0.418-0.203,0.6-0.051c0.182,0.148,0.204,0.42,0.05,0.602v-0.008
      c-0.084,0.104-0.202,0.154-0.321,0.154C37.886,38.148,37.79,38.119,37.705,38.053 M21.85,36.574
      c-0.118-0.197-0.05-0.459,0.153-0.578c0.204-0.117,0.465-0.051,0.584,0.152c0.118,0.205,0.045,0.465-0.159,0.584
      c-0.062,0.039-0.136,0.057-0.209,0.057C22.071,36.789,21.93,36.711,21.85,36.574 M38.734,36.717
      c-0.203-0.121-0.271-0.381-0.158-0.584c0.119-0.203,0.38-0.273,0.584-0.154c0.203,0.115,0.271,0.375,0.152,0.578
      c-0.08,0.137-0.221,0.215-0.367,0.215C38.871,36.771,38.803,36.756,38.734,36.717 M21.103,34.988L21.103,34.988
      c-0.08-0.221,0.034-0.465,0.255-0.549c0.215-0.08,0.464,0.033,0.543,0.254s-0.033,0.465-0.255,0.545
      c-0.045,0.016-0.096,0.027-0.141,0.027C21.33,35.266,21.165,35.158,21.103,34.988 M39.511,35.221
      c-0.215-0.078-0.334-0.322-0.249-0.543c0.079-0.223,0.323-0.334,0.543-0.256c0.221,0.08,0.334,0.322,0.256,0.545
      c-0.063,0.168-0.228,0.277-0.402,0.277C39.607,35.244,39.563,35.238,39.511,35.221 M20.645,33.289
      c-0.04-0.23,0.113-0.451,0.345-0.492c0.232-0.039,0.453,0.113,0.492,0.346c0.04,0.232-0.113,0.447-0.345,0.492
      c-0.022,0.006-0.051,0.006-0.073,0.006C20.86,33.641,20.684,33.494,20.645,33.289 M40.02,33.613
      c-0.231-0.041-0.385-0.262-0.345-0.494c0.04-0.23,0.261-0.385,0.493-0.346c0.227,0.041,0.385,0.262,0.345,0.494
      c-0.04,0.209-0.221,0.35-0.419,0.35C40.066,33.617,40.043,33.617,40.02,33.613 M20.491,31.539c0-0.236,0.187-0.424,0.425-0.424
      c0.232,0,0.425,0.188,0.425,0.424c0,0.234-0.193,0.426-0.425,0.426S20.491,31.773,20.491,31.539 M39.811,31.518l0.006-0.006
      c-0.006-0.012-0.006-0.021-0.006-0.033c0-0.238,0.192-0.426,0.424-0.426c0.233-0.006,0.426,0.188,0.426,0.42v0.021v0.023
      c0,0.232-0.188,0.426-0.426,0.426C40.003,31.943,39.811,31.75,39.811,31.518 M20.984,30.283c-0.232-0.045-0.385-0.26-0.345-0.492
      c0.039-0.232,0.261-0.385,0.492-0.346c0.227,0.039,0.386,0.254,0.346,0.486c-0.04,0.209-0.216,0.357-0.419,0.357
      C21.035,30.289,21.007,30.283,20.984,30.283 M39.669,29.877c-0.045-0.232,0.108-0.453,0.34-0.494
      c0.231-0.045,0.453,0.113,0.493,0.34c0.039,0.232-0.114,0.453-0.34,0.498c-0.029,0-0.051,0.006-0.074,0.006
      C39.884,30.227,39.703,30.078,39.669,29.877 M21.347,28.637c-0.221-0.08-0.34-0.324-0.261-0.545s0.323-0.34,0.544-0.254
      c0.22,0.078,0.34,0.322,0.254,0.543c-0.062,0.17-0.226,0.277-0.396,0.277C21.437,28.658,21.392,28.652,21.347,28.637
       M39.244,28.324L39.244,28.324c-0.078-0.221,0.035-0.465,0.256-0.549c0.215-0.08,0.458,0.033,0.543,0.254
      c0.079,0.223-0.027,0.465-0.255,0.543c-0.045,0.018-0.097,0.029-0.142,0.029C39.471,28.602,39.309,28.494,39.244,28.324
       M21.987,27.078c-0.204-0.117-0.278-0.379-0.159-0.584c0.119-0.201,0.379-0.27,0.583-0.156c0.198,0.117,0.272,0.379,0.153,0.582
      c-0.079,0.135-0.221,0.215-0.368,0.215C22.122,27.135,22.048,27.111,21.987,27.078 M38.559,26.869
      c-0.117-0.205-0.05-0.465,0.154-0.584c0.203-0.119,0.458-0.051,0.577,0.154c0.118,0.203,0.051,0.463-0.153,0.582
      c-0.063,0.039-0.136,0.057-0.209,0.057C38.781,27.078,38.639,27.006,38.559,26.869 M22.887,25.65
      c-0.182-0.146-0.204-0.418-0.051-0.594c0.147-0.18,0.413-0.203,0.594-0.055c0.182,0.15,0.204,0.418,0.057,0.6
      c-0.085,0.102-0.204,0.152-0.329,0.152C23.062,25.754,22.966,25.719,22.887,25.65 M37.631,25.555
      c-0.152-0.182-0.131-0.447,0.051-0.6c0.176-0.152,0.442-0.131,0.594,0.051c0.153,0.182,0.131,0.447-0.045,0.6
      c-0.084,0.068-0.181,0.098-0.276,0.098C37.834,25.703,37.715,25.65,37.631,25.555 M24.025,24.406
      c-0.153-0.176-0.131-0.447,0.051-0.596c0.175-0.152,0.441-0.129,0.594,0.053c0.153,0.174,0.131,0.441-0.051,0.594
      c-0.079,0.068-0.175,0.102-0.271,0.102C24.229,24.559,24.104,24.508,24.025,24.406 M36.486,24.424L36.486,24.424
      c-0.181-0.154-0.203-0.42-0.049-0.602c0.146-0.182,0.412-0.203,0.592-0.055c0.183,0.152,0.205,0.418,0.058,0.598
      c-0.085,0.104-0.203,0.154-0.328,0.154C36.663,24.52,36.566,24.484,36.486,24.424 M25.356,23.377
      c-0.12-0.205-0.052-0.459,0.152-0.58c0.204-0.117,0.465-0.051,0.583,0.154c0.114,0.203,0.046,0.457-0.152,0.576
      c-0.069,0.041-0.142,0.059-0.216,0.059C25.576,23.586,25.435,23.512,25.356,23.377 M35.168,23.5
      c-0.204-0.119-0.277-0.373-0.158-0.576c0.118-0.205,0.379-0.279,0.577-0.16c0.203,0.119,0.276,0.379,0.159,0.584
      c-0.079,0.135-0.221,0.209-0.369,0.209C35.304,23.557,35.234,23.539,35.168,23.5 M26.845,22.594
      c-0.08-0.221,0.033-0.465,0.254-0.543c0.221-0.086,0.464,0.027,0.544,0.248c0.079,0.221-0.034,0.465-0.255,0.545
      c-0.045,0.021-0.096,0.027-0.147,0.027C27.071,22.871,26.906,22.764,26.845,22.594 M33.707,22.826
      c-0.221-0.08-0.334-0.322-0.254-0.543c0.078-0.221,0.322-0.336,0.543-0.256c0.215,0.08,0.334,0.324,0.255,0.543
      c-0.063,0.17-0.228,0.279-0.401,0.279C33.804,22.85,33.752,22.844,33.707,22.826 M28.453,22.084
      c-0.046-0.232,0.107-0.451,0.339-0.492c0.233-0.045,0.453,0.113,0.493,0.34c0.045,0.232-0.113,0.453-0.34,0.492
      c-0.028,0.006-0.05,0.012-0.073,0.012C28.668,22.436,28.486,22.287,28.453,22.084 M32.15,22.418
      c-0.232-0.045-0.385-0.26-0.347-0.492c0.04-0.232,0.261-0.385,0.488-0.346c0.231,0.039,0.39,0.256,0.351,0.486
      c-0.039,0.211-0.216,0.357-0.418,0.357C32.201,22.424,32.172,22.418,32.15,22.418 M30.123,21.857
      c-0.006-0.23,0.187-0.424,0.419-0.424c0.237,0,0.425,0.186,0.431,0.424c0,0.232-0.194,0.426-0.425,0.426
      C30.31,22.283,30.123,22.096,30.123,21.857"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#D1D3D4"
          d="M36.692,34.115c0.052,0.238-0.097,0.473-0.33,0.523l-9.365,2.041
      c-0.235,0.051-0.467-0.102-0.519-0.34c-0.051-0.238,0.096-0.475,0.33-0.525l9.366-2.039
      C36.408,33.723,36.641,33.875,36.692,34.115"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#97A0A4"
          points="29.599,27.41 29.498,27.732 29.238,28.568 30.093,28.377
      30.947,28.184 30.353,27.541 30.118,27.285 30.463,27.393 31.298,27.652 31.106,26.799 30.913,25.943 30.269,26.539
      29.998,26.789 30.113,26.438 30.385,25.605 30.389,25.604 28.671,25.965 29.255,26.619 29.496,26.887 29.167,26.777
      28.335,26.506 28.515,27.361 28.695,28.219 29.348,27.635 			"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#97A0A4"
          d="M33.467,27.063c-1.82,0.355-2.479,1.598-2.625,1.93
      c-0.183-0.199-0.435-0.301-0.683-0.246c-0.25,0.053-0.437,0.252-0.52,0.51c-0.326-0.277-1.423-1.045-3.152-0.631
      c-2.074,0.496-2.395,2.773-1.538,4.154c0.48,0.77,1.151,1.199,1.33,1.303l0.39,1.785c0.038-0.023,0.08-0.039,0.125-0.049
      l9.366-2.041c0.045-0.01,0.09-0.012,0.134-0.008l-0.39-1.793l-0.008,0.002c0.039-0.053,0.566-0.77,0.695-1.779
      C36.799,28.588,35.559,26.652,33.467,27.063"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#C8CCCC"
          d="M30.356,33.186l-2.275,0.496c-0.263-0.115-0.973-0.463-1.691-1.15
      c-0.992-0.949-0.63-2.549,0.53-2.764c1.608-0.297,2.692,0.854,2.942,1.15L30.356,33.186z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#C8CCCC"
          d="M30.533,30.453c-0.26,0.055-0.516-0.109-0.573-0.367
      c-0.056-0.26,0.108-0.518,0.368-0.574c0.26-0.057,0.516,0.109,0.572,0.369C30.958,30.139,30.793,30.396,30.533,30.453"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#C8CCCC"
          d="M34.071,32.377l-2.245,0.488l-0.491-2.254
      c0.054-0.223,0.517-1.902,2.214-2.33c1.145-0.287,2.139,1.018,1.631,2.293C34.776,31.592,34.209,32.23,34.071,32.377"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#AC6A93"
          points="43.369,44.549 23.723,44.549 17.848,40.828 43.369,40.828 			"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#C8CCCC"
          d="M28.897,34.553c0.047,0.215-0.09,0.428-0.305,0.475
      c-0.216,0.047-0.429-0.09-0.476-0.305c-0.047-0.217,0.09-0.43,0.305-0.477C28.637,34.199,28.85,34.336,28.897,34.553"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#C8CCCC"
          d="M30.775,34.143c0.047,0.217-0.09,0.43-0.305,0.477
      c-0.216,0.047-0.429-0.092-0.476-0.307c-0.047-0.215,0.09-0.428,0.305-0.475C30.515,33.791,30.728,33.928,30.775,34.143"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#C8CCCC"
          d="M32.652,33.734c0.047,0.215-0.09,0.428-0.307,0.477
      c-0.215,0.045-0.427-0.092-0.475-0.307c-0.047-0.217,0.09-0.43,0.305-0.477C32.393,33.383,32.605,33.518,32.652,33.734"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#C8CCCC"
          d="M34.529,33.326c0.047,0.215-0.09,0.428-0.305,0.475
      c-0.217,0.047-0.43-0.09-0.477-0.305c-0.047-0.217,0.09-0.43,0.307-0.477C34.27,32.973,34.482,33.109,34.529,33.326"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#5087C7"
          d="M30.908,55.854c-2.746,0-4.979-2.234-4.979-4.979
      c0-2.746,2.233-4.979,4.979-4.979c2.747,0,4.979,2.232,4.979,4.979C35.887,53.619,33.654,55.854,30.908,55.854"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M30.908,46.102c2.638,0,4.773,2.137,4.773,4.773s-2.135,4.771-4.773,4.771
      c-2.638,0-4.773-2.135-4.773-4.771S28.27,46.102,30.908,46.102 M30.908,45.689c-2.859,0-5.185,2.326-5.185,5.186
      c0,2.857,2.326,5.184,5.185,5.184c2.86,0,5.185-2.326,5.185-5.184C36.093,48.016,33.768,45.689,30.908,45.689"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M33.402,48.93c-0.24,0-0.466,0.107-0.621,0.25l-0.013-0.012
      c0.072-0.119,0.168-0.346,0.168-0.717c0-0.883-0.884-1.551-2.029-1.551s-2.041,0.668-2.041,1.551c0,0.371,0.12,0.598,0.179,0.717
      l-0.012,0.012c-0.155-0.143-0.381-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.016c0,1.289,0.716,2.018,1.492,2.018
      c0.716,0,1.217-0.395,1.67-0.848c-0.071-0.107-0.179-0.217-0.298-0.217s-0.251,0.086-0.477,0.266
      c-0.299,0.227-0.561,0.357-0.908,0.357c-0.608,0-0.869-0.682-0.869-1.576c0-0.955,0.25-1.586,0.895-1.586
      c0.285,0,0.476,0.082,0.667,0.262c0.286,0.264,0.382,0.334,0.513,0.334c0.096,0,0.155-0.061,0.179-0.096
      c-0.214-0.215-0.525-0.668-0.525-1.252c0-0.668,0.561-1.135,1.611-1.135s1.611,0.467,1.611,1.135c0,0.584-0.31,1.037-0.525,1.252
      c0.024,0.035,0.084,0.096,0.179,0.096c0.132,0,0.228-0.07,0.513-0.334c0.18-0.18,0.383-0.262,0.668-0.262
      c0.645,0,0.895,0.631,0.895,1.586c0,0.895-0.262,1.576-0.87,1.576c-0.345,0-0.62-0.131-0.907-0.357
      c-0.227-0.18-0.357-0.266-0.477-0.266c-0.119,0-0.226,0.109-0.299,0.217c0.442,0.453,0.956,0.848,1.67,0.848
      c0.776,0,1.492-0.729,1.492-2.018C34.857,49.633,34.13,48.93,33.402,48.93"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M31.707,48.906H31.17c-0.035,0-0.059-0.025-0.071-0.049l-0.168-0.512
      c-0.011-0.037-0.023-0.037-0.035,0l-0.167,0.512c-0.013,0.035-0.036,0.049-0.06,0.049h-0.537c-0.024,0-0.036,0.01-0.012,0.023
      l0.442,0.322c0.023,0.021,0.023,0.059,0.011,0.084l-0.167,0.5c-0.011,0.023-0.011,0.049,0.025,0.023l0.441-0.309
      c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.036,0.012,0.023-0.012l-0.166-0.512
      c-0.012-0.037,0-0.072,0.024-0.084l0.44-0.322C31.743,48.916,31.731,48.906,31.707,48.906"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M31.302,52.438c-0.226-0.572-0.406-0.967-0.406-1.443
      c0-0.205,0.06-0.383,0.096-0.477c0.048-0.098,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.166-0.322,0.311
      c-0.072,0.117-0.155,0.346-0.155,0.523c0,0.336,0.108,0.693,0.179,0.908c0.132,0.369,0.251,0.799,0.251,1.205
      c0,0.645-0.287,1.086-0.799,1.479c0.047,0.037,0.167,0.096,0.31,0.096c0.5,0,1.097-0.561,1.097-1.324
      C31.563,53.105,31.433,52.795,31.302,52.438"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#5087C7"
          d="M30.926,55.604c-2.638,0-4.773-2.137-4.773-4.771
      c0-2.639,2.135-4.773,4.773-4.773c2.638,0,4.774,2.135,4.774,4.773C35.7,53.467,33.564,55.604,30.926,55.604"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M30.926,46.059c2.638,0,4.774,2.135,4.774,4.773
      c0,2.635-2.136,4.771-4.774,4.771c-2.638,0-4.773-2.137-4.773-4.771C26.153,48.193,28.289,46.059,30.926,46.059 M30.926,45.646
      c-2.859,0-5.185,2.326-5.185,5.186c0,2.857,2.326,5.184,5.185,5.184c2.861,0,5.186-2.326,5.186-5.184
      C36.112,47.973,33.787,45.646,30.926,45.646"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M33.42,48.885c-0.238,0-0.465,0.109-0.619,0.252l-0.014-0.012
      c0.072-0.119,0.168-0.346,0.168-0.717c0-0.883-0.884-1.551-2.029-1.551s-2.041,0.668-2.041,1.551c0,0.371,0.12,0.598,0.179,0.717
      l-0.012,0.012c-0.155-0.143-0.382-0.252-0.619-0.252c-0.73,0-1.457,0.705-1.457,2.018c0,1.289,0.716,2.016,1.492,2.016
      c0.716,0,1.217-0.393,1.67-0.846c-0.071-0.109-0.179-0.217-0.298-0.217s-0.251,0.086-0.477,0.264
      c-0.299,0.227-0.561,0.359-0.908,0.359c-0.608,0-0.87-0.682-0.87-1.576c0-0.955,0.25-1.586,0.895-1.586
      c0.286,0,0.476,0.082,0.668,0.262c0.286,0.262,0.382,0.334,0.513,0.334c0.096,0,0.155-0.061,0.179-0.096
      c-0.214-0.215-0.525-0.668-0.525-1.252c0-0.668,0.561-1.135,1.611-1.135c1.05,0,1.611,0.467,1.611,1.135
      c0,0.584-0.31,1.037-0.525,1.252c0.025,0.035,0.084,0.096,0.18,0.096c0.132,0,0.227-0.072,0.514-0.334
      c0.178-0.18,0.381-0.262,0.668-0.262c0.644,0,0.895,0.631,0.895,1.586c0,0.895-0.264,1.576-0.871,1.576
      c-0.346,0-0.62-0.133-0.907-0.359c-0.228-0.178-0.358-0.264-0.478-0.264c-0.118,0-0.225,0.107-0.298,0.217
      c0.442,0.453,0.955,0.846,1.671,0.846c0.775,0,1.492-0.727,1.492-2.016C34.877,49.59,34.147,48.885,33.42,48.885"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M31.726,48.861h-0.537c-0.035,0-0.059-0.023-0.071-0.047l-0.168-0.514
      c-0.011-0.035-0.023-0.035-0.035,0l-0.167,0.514c-0.013,0.035-0.036,0.047-0.06,0.047h-0.537c-0.024,0-0.036,0.012-0.012,0.023
      l0.442,0.324c0.023,0.021,0.023,0.059,0.011,0.082l-0.167,0.502c-0.011,0.023-0.011,0.047,0.025,0.023l0.441-0.309
      c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.023,0.036,0.012,0.023-0.012l-0.166-0.514c-0.012-0.035,0-0.07,0.024-0.082
      l0.44-0.324C31.762,48.873,31.75,48.861,31.726,48.861"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M31.32,52.393c-0.226-0.572-0.406-0.965-0.406-1.441
      c0-0.205,0.06-0.383,0.096-0.479c0.048-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.168-0.322,0.313
      c-0.072,0.117-0.155,0.346-0.155,0.523c0,0.334,0.108,0.693,0.179,0.906c0.132,0.371,0.251,0.801,0.251,1.207
      c0,0.645-0.287,1.084-0.799,1.479c0.047,0.035,0.166,0.096,0.31,0.096c0.5,0,1.097-0.561,1.097-1.324
      C31.583,53.061,31.451,52.752,31.32,52.393"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M56.997-25.766c0.076,0,0.14,0.012,0.192,0.038
      c0.052,0.025,0.094,0.059,0.125,0.101c0.031,0.041,0.055,0.088,0.068,0.142s0.021,0.107,0.021,0.163
      c0,0.075-0.011,0.143-0.034,0.198c-0.023,0.058-0.055,0.104-0.096,0.14s-0.09,0.063-0.147,0.081
      c-0.058,0.018-0.123,0.026-0.192,0.026h-0.16v0.59h-0.299v-1.479H56.997z M56.906-25.097c0.059,0,0.107-0.018,0.145-0.054
      c0.036-0.036,0.055-0.093,0.055-0.168c0-0.074-0.017-0.132-0.049-0.17c-0.031-0.038-0.084-0.059-0.158-0.059h-0.125v0.45H56.906z
      "
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          points="58,-25.767 58.211,-24.721 58.217,-24.721 58.43,-25.767
      58.871,-25.767 58.871,-24.287 58.598,-24.287 58.598,-25.468 58.594,-25.468 58.33,-24.287 58.098,-24.287 57.834,-25.468
      57.83,-25.468 57.83,-24.287 57.557,-24.287 57.557,-25.767 			"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M59.324-24.706c0,0.035,0.004,0.066,0.01,0.095
      c0.004,0.028,0.015,0.053,0.029,0.071c0.016,0.019,0.037,0.035,0.063,0.045c0.025,0.012,0.059,0.018,0.096,0.018
      c0.045,0,0.088-0.016,0.123-0.046c0.036-0.028,0.057-0.074,0.057-0.137c0-0.034-0.006-0.062-0.015-0.086
      c-0.009-0.023-0.024-0.046-0.045-0.065s-0.048-0.036-0.082-0.054c-0.032-0.015-0.075-0.031-0.125-0.048
      c-0.065-0.021-0.123-0.046-0.172-0.073c-0.048-0.025-0.089-0.056-0.121-0.092c-0.032-0.035-0.058-0.076-0.071-0.122
      c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.08-0.071,0.193-0.107,0.336-0.107
      c0.066,0,0.127,0.007,0.183,0.021c0.056,0.016,0.104,0.038,0.146,0.07c0.041,0.033,0.072,0.074,0.095,0.124
      c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.069-0.014-0.124-0.038-0.161c-0.024-0.038-0.065-0.058-0.124-0.058
      c-0.033,0-0.061,0.005-0.083,0.016c-0.022,0.009-0.04,0.021-0.053,0.036c-0.013,0.017-0.022,0.035-0.027,0.056
      s-0.007,0.042-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.123,0.086l0.23,0.1
      c0.057,0.024,0.104,0.051,0.139,0.077c0.037,0.027,0.065,0.057,0.086,0.087c0.022,0.031,0.037,0.066,0.045,0.103
      c0.009,0.038,0.013,0.079,0.013,0.125c0,0.156-0.046,0.27-0.136,0.341s-0.217,0.107-0.379,0.107c-0.168,0-0.289-0.037-0.361-0.11
      s-0.107-0.179-0.107-0.315v-0.061h0.297V-24.706z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M60.592-24.287c0-0.065,0.006-0.126,0.02-0.183s0.033-0.11,0.061-0.161
      c0.026-0.051,0.06-0.102,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.147l0.121-0.112c0.033-0.03,0.061-0.056,0.079-0.081
      c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.024,0.019-0.052,0.022-0.081c0.003-0.028,0.005-0.062,0.005-0.099
      c0-0.122-0.048-0.184-0.146-0.184c-0.037,0-0.066,0.009-0.088,0.023s-0.037,0.036-0.05,0.063s-0.021,0.057-0.022,0.092
      c-0.004,0.034-0.006,0.07-0.006,0.108h-0.285v-0.056c0-0.136,0.035-0.241,0.109-0.315c0.073-0.072,0.188-0.108,0.346-0.108
      c0.149,0,0.26,0.034,0.332,0.103c0.071,0.069,0.107,0.168,0.107,0.296c0,0.047-0.004,0.092-0.012,0.133
      c-0.01,0.041-0.021,0.079-0.039,0.116c-0.018,0.038-0.043,0.074-0.071,0.109s-0.063,0.072-0.106,0.109l-0.164,0.152
      c-0.045,0.04-0.08,0.079-0.104,0.117c-0.024,0.039-0.041,0.071-0.048,0.099h0.554v0.232H60.592z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M62.498-25.497c-0.061,0.085-0.115,0.178-0.162,0.274
      c-0.048,0.1-0.09,0.2-0.126,0.304c-0.036,0.103-0.065,0.21-0.089,0.315c-0.024,0.108-0.042,0.213-0.055,0.316h-0.313
      c0.011-0.088,0.027-0.182,0.05-0.278c0.022-0.098,0.052-0.197,0.089-0.298c0.035-0.102,0.08-0.202,0.133-0.304
      c0.053-0.104,0.114-0.203,0.187-0.302h-0.618v-0.257h0.904V-25.497z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M62.921-25.323h-0.292v-0.195h0.025c0.101,0,0.179-0.018,0.235-0.056
      c0.058-0.038,0.095-0.097,0.109-0.18h0.22v1.467h-0.298V-25.323z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M63.629-24.939c0.042-0.067,0.104-0.108,0.188-0.125v-0.004
      c-0.041-0.01-0.074-0.025-0.102-0.046c-0.025-0.021-0.048-0.044-0.064-0.07c-0.017-0.027-0.029-0.054-0.035-0.083
      c-0.008-0.029-0.01-0.057-0.01-0.083c0-0.144,0.037-0.247,0.114-0.31c0.076-0.063,0.181-0.095,0.315-0.095
      s0.24,0.031,0.316,0.095c0.076,0.063,0.115,0.166,0.115,0.31c0,0.026-0.004,0.054-0.012,0.083s-0.02,0.056-0.036,0.083
      c-0.017,0.026-0.038,0.049-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004c0.083,0.017,0.146,0.058,0.187,0.125
      c0.041,0.066,0.063,0.145,0.063,0.238c0,0.043-0.005,0.09-0.016,0.141c-0.01,0.051-0.031,0.098-0.065,0.143
      c-0.032,0.045-0.08,0.08-0.142,0.11c-0.062,0.028-0.144,0.043-0.246,0.043c-0.102,0-0.184-0.015-0.245-0.043
      c-0.063-0.03-0.108-0.065-0.142-0.11s-0.055-0.092-0.065-0.143s-0.015-0.098-0.015-0.141
      C63.568-24.794,63.589-24.873,63.629-24.939 M63.874-24.607c0.004,0.031,0.013,0.058,0.026,0.081
      c0.012,0.022,0.03,0.041,0.053,0.054c0.021,0.013,0.05,0.02,0.082,0.02c0.033,0,0.061-0.007,0.084-0.02
      c0.021-0.013,0.039-0.031,0.053-0.054c0.012-0.023,0.021-0.05,0.027-0.081c0.004-0.031,0.006-0.064,0.006-0.099
      c0-0.035-0.002-0.068-0.006-0.1c-0.006-0.03-0.016-0.057-0.027-0.08c-0.014-0.022-0.031-0.04-0.053-0.053
      c-0.023-0.014-0.051-0.021-0.084-0.021c-0.032,0-0.061,0.007-0.082,0.021c-0.022,0.013-0.041,0.03-0.053,0.053
      c-0.014,0.023-0.022,0.05-0.026,0.08c-0.007,0.031-0.009,0.064-0.009,0.1C63.865-24.671,63.867-24.638,63.874-24.607
       M63.93-25.203c0.025,0.032,0.063,0.05,0.105,0.05c0.045,0,0.08-0.018,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155
      c0-0.135-0.048-0.201-0.146-0.201c-0.096,0-0.145,0.066-0.145,0.201C63.891-25.288,63.904-25.236,63.93-25.203"
        />
        <rect
          x="-13.112"
          y="-26.265"
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#EACBD9"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M-12.199-25.767c0.076,0,0.14,0.013,0.192,0.038
      c0.052,0.025,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
      c0,0.076-0.011,0.143-0.034,0.199s-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081c-0.058,0.019-0.123,0.027-0.193,0.027h-0.16
      v0.59h-0.298v-1.479H-12.199z M-12.29-25.098c0.059,0,0.107-0.018,0.144-0.054s0.055-0.092,0.055-0.168
      c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.449H-12.29z"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          points="-11.195,-25.767 -10.984,-24.722 -10.979,-24.722 -10.766,-25.767
      -10.325,-25.767 -10.325,-24.289 -10.598,-24.289 -10.598,-25.469 -10.603,-25.469 -10.865,-24.289 -11.098,-24.289
      -11.361,-25.469 -11.365,-25.469 -11.365,-24.289 -11.639,-24.289 -11.639,-25.767 			"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M-9.871-24.707c0,0.035,0.003,0.066,0.008,0.096
      c0.005,0.027,0.016,0.051,0.031,0.07c0.015,0.02,0.036,0.035,0.062,0.046c0.025,0.012,0.057,0.017,0.096,0.017
      c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.013-0.087
      c-0.009-0.023-0.024-0.047-0.045-0.065c-0.021-0.02-0.048-0.036-0.082-0.053c-0.033-0.017-0.075-0.032-0.125-0.049
      c-0.066-0.021-0.124-0.046-0.172-0.072c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
      c-0.015-0.047-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
      c0.067,0,0.128,0.007,0.184,0.022c0.056,0.014,0.104,0.038,0.145,0.069c0.041,0.033,0.072,0.074,0.095,0.124
      c0.023,0.052,0.034,0.111,0.034,0.183v0.041h-0.286c0-0.07-0.012-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.124-0.057
      c-0.033,0-0.061,0.004-0.083,0.015c-0.023,0.008-0.04,0.021-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
      c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114s0.06,0.06,0.124,0.087l0.23,0.099
      c0.057,0.024,0.103,0.051,0.139,0.078C-9.292-25-9.264-24.97-9.242-24.939c0.021,0.031,0.036,0.065,0.045,0.102
      c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
      c-0.169,0-0.289-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062h0.298V-24.707z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M-8.028-25.514c-0.019-0.031-0.055-0.046-0.104-0.046
      c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.019-0.043,0.045-0.056,0.077c-0.013,0.033-0.021,0.073-0.025,0.118
      c-0.005,0.046-0.007,0.096-0.007,0.149v0.073h0.004c0.025-0.039,0.061-0.069,0.107-0.093c0.047-0.022,0.103-0.034,0.169-0.034
      c0.065,0,0.12,0.012,0.166,0.034c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.089,0.062,0.145
      c0.013,0.057,0.02,0.117,0.02,0.185c0,0.17-0.038,0.298-0.113,0.386c-0.075,0.088-0.196,0.132-0.362,0.132
      c-0.102,0-0.184-0.018-0.245-0.054c-0.06-0.036-0.107-0.086-0.139-0.148c-0.033-0.063-0.054-0.136-0.064-0.219
      c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.205,0.009-0.303c0.006-0.097,0.025-0.184,0.056-0.259
      c0.031-0.075,0.08-0.135,0.146-0.181c0.067-0.046,0.161-0.069,0.282-0.069c0.052,0,0.104,0.007,0.152,0.02
      c0.05,0.013,0.093,0.033,0.13,0.062c0.037,0.029,0.067,0.068,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.178h-0.286
      C-7.998-25.439-8.007-25.483-8.028-25.514 M-8.294-24.627c0.004,0.036,0.012,0.066,0.024,0.093
      c0.012,0.025,0.028,0.046,0.05,0.06c0.021,0.014,0.049,0.022,0.084,0.022c0.036,0,0.064-0.009,0.086-0.022
      c0.021-0.014,0.037-0.034,0.048-0.061c0.011-0.026,0.019-0.058,0.022-0.094c0.004-0.034,0.005-0.072,0.005-0.113
      c0-0.101-0.012-0.172-0.037-0.215s-0.066-0.063-0.124-0.063c-0.06,0-0.102,0.021-0.127,0.063S-8.3-24.843-8.3-24.743
      C-8.3-24.702-8.298-24.664-8.294-24.627"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M-6.698-25.498c-0.061,0.086-0.115,0.178-0.163,0.275
      c-0.047,0.099-0.089,0.199-0.125,0.303s-0.066,0.21-0.089,0.316c-0.024,0.107-0.042,0.212-0.054,0.315h-0.313
      c0.011-0.088,0.027-0.182,0.05-0.278c0.022-0.098,0.051-0.197,0.088-0.297c0.036-0.103,0.081-0.203,0.133-0.305
      c0.053-0.104,0.115-0.203,0.187-0.301h-0.618v-0.257h0.904V-25.498z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M-6.604-25.265c0.006-0.094,0.025-0.178,0.056-0.25
      c0.03-0.073,0.076-0.13,0.137-0.174c0.062-0.043,0.147-0.066,0.256-0.066c0.09,0,0.165,0.013,0.223,0.039
      c0.059,0.026,0.106,0.067,0.141,0.123c0.035,0.057,0.06,0.131,0.075,0.221c0.014,0.091,0.022,0.201,0.022,0.331
      c0,0.103-0.004,0.2-0.011,0.294c-0.007,0.095-0.025,0.178-0.056,0.25c-0.03,0.072-0.076,0.129-0.138,0.17
      c-0.061,0.042-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.022-0.107-0.063-0.142-0.12
      c-0.036-0.056-0.06-0.13-0.075-0.221c-0.014-0.09-0.022-0.2-0.022-0.33C-6.615-25.073-6.611-25.171-6.604-25.265 M-6.309-24.68
      c0.005,0.059,0.013,0.104,0.026,0.139c0.012,0.034,0.029,0.058,0.05,0.069c0.02,0.013,0.046,0.019,0.078,0.019
      c0.034,0,0.061-0.008,0.081-0.022c0.021-0.018,0.037-0.043,0.049-0.082c0.011-0.038,0.02-0.089,0.025-0.152
      c0.004-0.063,0.007-0.142,0.007-0.235v-0.16c0-0.092-0.003-0.168-0.007-0.228c-0.005-0.058-0.014-0.104-0.026-0.138
      c-0.013-0.034-0.029-0.058-0.05-0.07s-0.047-0.018-0.079-0.018c-0.033,0-0.06,0.007-0.08,0.022
      c-0.021,0.017-0.038,0.044-0.049,0.081c-0.012,0.038-0.02,0.089-0.025,0.153c-0.005,0.063-0.007,0.141-0.007,0.235v0.161
      C-6.316-24.814-6.314-24.739-6.309-24.68"
        />
        <rect
          x="-3.28"
          y="-26.265"
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#F6ADCD"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M-2.366-25.767c0.076,0,0.14,0.013,0.192,0.038
      c0.052,0.026,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
      c0,0.076-0.011,0.143-0.034,0.199s-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081c-0.058,0.019-0.123,0.027-0.193,0.027h-0.16
      v0.59h-0.298v-1.479H-2.366z M-2.457-25.098c0.059,0,0.107-0.018,0.144-0.054s0.055-0.092,0.055-0.168
      c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058H-2.59v0.449H-2.457z"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          points="-1.363,-25.767 -1.152,-24.722 -1.147,-24.722 -0.934,-25.767
      -0.493,-25.767 -0.493,-24.287 -0.766,-24.287 -0.766,-25.469 -0.77,-25.469 -1.033,-24.287 -1.266,-24.287 -1.529,-25.469
      -1.533,-25.469 -1.533,-24.287 -1.807,-24.287 -1.807,-25.767 			"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M-0.039-24.707c0,0.035,0.003,0.066,0.008,0.096
      c0.005,0.027,0.016,0.052,0.031,0.07c0.015,0.02,0.036,0.035,0.062,0.046c0.025,0.012,0.057,0.017,0.096,0.017
      c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.013-0.087
      C0.314-24.77,0.3-24.792,0.278-24.812c-0.021-0.02-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.031-0.125-0.049
      c-0.066-0.021-0.124-0.046-0.172-0.072c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
      c-0.015-0.047-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327C-0.112-25.76,0-25.796,0.142-25.796
      c0.067,0,0.128,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.145,0.069c0.041,0.033,0.072,0.074,0.095,0.124
      C0.588-25.529,0.6-25.469,0.6-25.398v0.041H0.313c0-0.07-0.012-0.124-0.037-0.162c-0.025-0.038-0.066-0.057-0.124-0.057
      c-0.033,0-0.061,0.005-0.083,0.015c-0.022,0.009-0.04,0.021-0.053,0.037s-0.022,0.035-0.027,0.055
      c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114s0.06,0.06,0.124,0.087l0.23,0.099
      c0.057,0.024,0.103,0.051,0.139,0.078C0.541-25,0.568-24.97,0.591-24.939c0.021,0.031,0.036,0.065,0.045,0.102
      c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
      c-0.169,0-0.289-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062h0.298V-24.707z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M1.228-24.287c0-0.065,0.007-0.127,0.02-0.184
      c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.051,0.06-0.101,0.101-0.149c0.041-0.051,0.088-0.1,0.142-0.148l0.121-0.112
      c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.024,0.036-0.05,0.047-0.074c0.011-0.025,0.019-0.053,0.022-0.081
      c0.003-0.029,0.005-0.063,0.005-0.1c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
      c-0.022,0.015-0.038,0.035-0.05,0.062c-0.012,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.109H1.228v-0.057
      c0-0.136,0.037-0.24,0.11-0.314c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.104
      c0.072,0.068,0.107,0.168,0.107,0.296c0,0.047-0.004,0.091-0.012,0.132s-0.021,0.08-0.039,0.117
      c-0.018,0.038-0.042,0.074-0.071,0.108c-0.029,0.035-0.064,0.072-0.106,0.109L1.73-24.735c-0.044,0.04-0.079,0.079-0.104,0.117
      s-0.041,0.07-0.048,0.099h0.554v0.232H1.228z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M2.233-25.266c0.006-0.094,0.025-0.177,0.056-0.249
      c0.03-0.072,0.076-0.131,0.137-0.174c0.062-0.044,0.147-0.065,0.256-0.065c0.09,0,0.165,0.013,0.223,0.039
      c0.059,0.024,0.106,0.065,0.141,0.123c0.035,0.057,0.06,0.13,0.075,0.22c0.014,0.091,0.022,0.201,0.022,0.331
      c0,0.102-0.004,0.2-0.011,0.294c-0.007,0.094-0.025,0.178-0.056,0.25c-0.03,0.072-0.076,0.13-0.138,0.171
      c-0.061,0.042-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.035c-0.06-0.024-0.107-0.063-0.142-0.12
      c-0.036-0.057-0.06-0.13-0.075-0.222c-0.014-0.09-0.022-0.2-0.022-0.329C2.223-25.074,2.227-25.171,2.233-25.266 M2.528-24.68
      c0.005,0.06,0.013,0.105,0.026,0.14c0.012,0.034,0.029,0.057,0.05,0.068c0.02,0.014,0.046,0.019,0.078,0.019
      c0.034,0,0.061-0.007,0.081-0.023c0.021-0.016,0.037-0.043,0.049-0.081c0.011-0.037,0.02-0.089,0.025-0.152
      c0.004-0.063,0.007-0.143,0.007-0.236v-0.159c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.06-0.014-0.105-0.026-0.14
      c-0.013-0.033-0.029-0.057-0.05-0.068c-0.021-0.013-0.047-0.02-0.079-0.02c-0.033,0-0.06,0.009-0.08,0.024
      c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.038-0.021,0.088-0.025,0.152c-0.005,0.063-0.007,0.142-0.007,0.235v0.16
      C2.521-24.815,2.523-24.739,2.528-24.68"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M3.542-24.52c0.021,0.045,0.065,0.066,0.13,0.066
      c0.035,0,0.064-0.006,0.086-0.018c0.021-0.014,0.037-0.03,0.049-0.051c0.012-0.021,0.019-0.048,0.023-0.076
      c0.003-0.029,0.005-0.06,0.005-0.091c0-0.033-0.002-0.064-0.007-0.095c-0.005-0.029-0.015-0.056-0.028-0.08
      c-0.014-0.022-0.033-0.042-0.058-0.056s-0.058-0.021-0.1-0.021H3.543v-0.195H3.64c0.032,0,0.058-0.006,0.08-0.02
      c0.021-0.013,0.039-0.029,0.052-0.052c0.013-0.021,0.023-0.046,0.029-0.074c0.006-0.027,0.009-0.056,0.009-0.087
      c0-0.07-0.012-0.12-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.005-0.077,0.017
      c-0.021,0.012-0.036,0.029-0.047,0.05c-0.011,0.021-0.019,0.047-0.022,0.076s-0.005,0.062-0.005,0.095H3.243
      c0-0.146,0.036-0.255,0.109-0.325c0.073-0.071,0.181-0.107,0.322-0.107c0.133,0,0.237,0.03,0.312,0.091
      c0.074,0.062,0.11,0.156,0.11,0.286c0,0.089-0.019,0.161-0.059,0.217c-0.039,0.057-0.094,0.091-0.164,0.106v0.004
      c0.095,0.016,0.162,0.054,0.202,0.115c0.039,0.062,0.059,0.139,0.059,0.231c0,0.049-0.007,0.102-0.019,0.153
      c-0.012,0.052-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.077-0.149,0.104c-0.062,0.025-0.145,0.039-0.247,0.039
      c-0.143,0-0.249-0.039-0.32-0.116c-0.07-0.077-0.105-0.188-0.105-0.33v-0.006h0.286C3.509-24.63,3.52-24.565,3.542-24.52"
        />
        <rect
          x="6.679"
          y="-26.265"
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#7B2959"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M7.593-25.767c0.076,0,0.14,0.013,0.192,0.038
      c0.052,0.026,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
      c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
      c-0.058,0.019-0.123,0.027-0.193,0.027h-0.16v0.59H7.071v-1.479H7.593z M7.501-25.098c0.059,0,0.107-0.018,0.144-0.054
      c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058H7.369v0.449H7.501z"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          points="8.596,-25.767 8.807,-24.722 8.812,-24.722 9.025,-25.767
      9.466,-25.767 9.466,-24.287 9.193,-24.287 9.193,-25.469 9.189,-25.469 8.926,-24.287 8.693,-24.287 8.43,-25.469
      8.426,-25.469 8.426,-24.287 8.152,-24.287 8.152,-25.767 			"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M9.92-24.707c0,0.035,0.003,0.066,0.008,0.096
      c0.005,0.027,0.016,0.052,0.031,0.07c0.015,0.02,0.036,0.035,0.062,0.046c0.025,0.012,0.057,0.017,0.096,0.017
      c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.013-0.086
      c-0.009-0.024-0.024-0.047-0.045-0.066s-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.031-0.125-0.049
      c-0.066-0.021-0.124-0.046-0.172-0.072c-0.048-0.026-0.089-0.057-0.121-0.092C9.704-25.113,9.68-25.154,9.665-25.2
      C9.65-25.246,9.642-25.3,9.642-25.361c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
      c0.067,0,0.128,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.145,0.07s0.072,0.073,0.095,0.123
      c0.023,0.052,0.034,0.111,0.034,0.183v0.041h-0.286c0-0.07-0.012-0.124-0.037-0.162s-0.066-0.057-0.124-0.057
      c-0.033,0-0.061,0.005-0.083,0.015c-0.023,0.009-0.04,0.021-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
      c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114s0.06,0.06,0.124,0.087l0.23,0.099
      c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.026,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.065,0.045,0.102
      c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.106-0.378,0.106
      c-0.169,0-0.289-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062H9.92V-24.707z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M11.764-25.514c-0.019-0.031-0.055-0.046-0.104-0.046
      c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.019-0.043,0.045-0.056,0.077c-0.013,0.033-0.021,0.073-0.026,0.118
      c-0.004,0.046-0.006,0.097-0.006,0.149v0.073h0.004c0.025-0.039,0.061-0.069,0.107-0.093c0.047-0.022,0.103-0.033,0.169-0.033
      c0.065,0,0.12,0.011,0.166,0.033c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.089,0.062,0.145
      c0.013,0.057,0.02,0.117,0.02,0.185c0,0.17-0.038,0.298-0.113,0.386c-0.075,0.088-0.196,0.132-0.362,0.132
      c-0.102,0-0.184-0.018-0.245-0.054c-0.06-0.036-0.107-0.085-0.139-0.148c-0.033-0.063-0.054-0.136-0.064-0.219
      c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.204,0.009-0.302s0.025-0.185,0.056-0.26
      c0.031-0.075,0.08-0.135,0.146-0.181c0.067-0.046,0.161-0.068,0.282-0.068c0.052,0,0.104,0.006,0.152,0.019
      c0.05,0.013,0.093,0.033,0.13,0.062c0.037,0.029,0.067,0.068,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.178h-0.286
      C11.793-25.439,11.785-25.483,11.764-25.514 M11.498-24.627c0.004,0.036,0.012,0.067,0.024,0.093s0.028,0.046,0.05,0.06
      c0.021,0.015,0.049,0.022,0.084,0.022c0.036,0,0.064-0.008,0.086-0.022c0.021-0.014,0.037-0.034,0.048-0.061
      c0.011-0.026,0.019-0.058,0.022-0.093c0.004-0.035,0.005-0.073,0.005-0.114c0-0.101-0.012-0.172-0.037-0.215
      c-0.025-0.043-0.066-0.063-0.124-0.063c-0.06,0-0.102,0.021-0.127,0.063c-0.025,0.043-0.037,0.114-0.037,0.215
      C11.492-24.702,11.494-24.664,11.498-24.627"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M12.235-24.939c0.042-0.067,0.104-0.108,0.187-0.125v-0.005
      c-0.04-0.01-0.074-0.024-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.069c-0.016-0.027-0.028-0.055-0.035-0.083
      c-0.007-0.029-0.01-0.058-0.01-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.181-0.094,0.316-0.094
      c0.134,0,0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.311c0,0.025-0.004,0.054-0.012,0.083
      c-0.007,0.028-0.019,0.056-0.036,0.083c-0.016,0.025-0.038,0.049-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.046v0.005
      c0.083,0.017,0.145,0.058,0.187,0.125c0.041,0.065,0.062,0.145,0.062,0.237c0,0.043-0.005,0.09-0.015,0.141
      c-0.011,0.052-0.033,0.099-0.066,0.144c-0.033,0.044-0.08,0.08-0.142,0.11c-0.061,0.027-0.143,0.043-0.245,0.043
      c-0.102,0-0.184-0.016-0.246-0.043c-0.062-0.03-0.109-0.066-0.142-0.11c-0.033-0.045-0.055-0.092-0.065-0.144
      c-0.011-0.051-0.016-0.098-0.016-0.141C12.173-24.794,12.194-24.874,12.235-24.939 M12.479-24.607
      c0.005,0.03,0.014,0.058,0.027,0.08c0.013,0.023,0.031,0.041,0.053,0.055c0.022,0.013,0.05,0.019,0.083,0.019
      c0.033,0,0.06-0.006,0.083-0.019c0.022-0.014,0.039-0.031,0.052-0.055c0.013-0.022,0.023-0.05,0.027-0.08
      c0.005-0.031,0.008-0.064,0.008-0.1s-0.003-0.068-0.008-0.099c-0.004-0.03-0.014-0.058-0.027-0.08
      c-0.013-0.023-0.03-0.04-0.052-0.053c-0.023-0.015-0.05-0.021-0.083-0.021c-0.033,0-0.061,0.006-0.083,0.021
      c-0.022,0.013-0.04,0.029-0.053,0.053c-0.013,0.022-0.022,0.05-0.027,0.08c-0.005,0.03-0.007,0.063-0.007,0.099
      S12.474-24.638,12.479-24.607 M12.536-25.204c0.026,0.033,0.062,0.05,0.106,0.05s0.079-0.017,0.105-0.05
      c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.2-0.145-0.2c-0.097,0-0.145,0.065-0.145,0.2
      C12.497-25.289,12.51-25.237,12.536-25.204"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M13.5-24.52c0.021,0.045,0.065,0.066,0.13,0.066
      c0.035,0,0.064-0.006,0.086-0.018c0.021-0.014,0.037-0.03,0.049-0.051c0.012-0.021,0.019-0.048,0.023-0.076
      c0.003-0.029,0.005-0.06,0.005-0.091c0-0.033-0.002-0.064-0.007-0.095c-0.005-0.029-0.014-0.056-0.028-0.08
      c-0.014-0.022-0.033-0.042-0.058-0.056s-0.058-0.021-0.099-0.021h-0.1v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.02
      c0.021-0.013,0.039-0.029,0.052-0.052c0.013-0.021,0.023-0.046,0.029-0.074c0.006-0.027,0.009-0.056,0.009-0.087
      c0-0.07-0.011-0.12-0.035-0.148c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.005-0.077,0.017
      c-0.021,0.012-0.036,0.029-0.047,0.05c-0.011,0.021-0.019,0.047-0.022,0.076c-0.003,0.029-0.005,0.062-0.005,0.095h-0.273
      c0-0.146,0.036-0.255,0.109-0.325c0.073-0.071,0.181-0.107,0.322-0.107c0.133,0,0.237,0.03,0.312,0.091
      c0.074,0.062,0.11,0.156,0.11,0.286c0,0.089-0.019,0.161-0.059,0.217c-0.039,0.057-0.094,0.091-0.164,0.106v0.004
      c0.095,0.016,0.162,0.054,0.202,0.115c0.039,0.062,0.059,0.139,0.059,0.231c0,0.049-0.006,0.102-0.019,0.153
      c-0.012,0.052-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.077-0.149,0.104c-0.062,0.025-0.145,0.039-0.247,0.039
      c-0.143,0-0.249-0.039-0.32-0.116c-0.07-0.077-0.105-0.188-0.105-0.33v-0.006h0.286C13.468-24.63,13.479-24.565,13.5-24.52"
        />
        <rect
          x="26.432"
          y="-26.265"
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#C8CCCC"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M27.345-25.767c0.076,0,0.14,0.013,0.192,0.038
      c0.052,0.026,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
      c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
      c-0.058,0.019-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H27.345z M27.254-25.098c0.059,0,0.107-0.018,0.144-0.054
      c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.449H27.254
      z"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          points="28.349,-25.767 28.56,-24.722 28.564,-24.722 28.777,-25.767
      29.218,-25.767 29.218,-24.287 28.945,-24.287 28.945,-25.469 28.941,-25.469 28.678,-24.287 28.445,-24.287 28.183,-25.469
      28.178,-25.469 28.178,-24.287 27.904,-24.287 27.904,-25.767 			"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M29.673-24.707c0,0.035,0.003,0.066,0.008,0.096
      c0.005,0.027,0.016,0.052,0.031,0.07c0.015,0.02,0.036,0.035,0.062,0.046c0.025,0.012,0.057,0.017,0.096,0.017
      c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.013-0.086
      c-0.009-0.024-0.024-0.047-0.045-0.066s-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.031-0.125-0.049
      c-0.066-0.021-0.124-0.046-0.172-0.072s-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
      c-0.015-0.046-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
      c0.067,0,0.128,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.145,0.07s0.072,0.073,0.095,0.123
      c0.023,0.052,0.034,0.111,0.034,0.183v0.041h-0.286c0-0.07-0.012-0.124-0.037-0.162s-0.066-0.057-0.124-0.057
      c-0.033,0-0.061,0.005-0.083,0.015c-0.023,0.009-0.04,0.021-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
      c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114s0.06,0.06,0.124,0.087l0.23,0.099
      c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.026,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.065,0.045,0.102
      c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.106-0.378,0.106
      c-0.169,0-0.289-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062h0.298V-24.707z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M31.418-25.754h0.309v0.931h0.141v0.232h-0.141v0.304h-0.274v-0.304h-0.522
      v-0.245L31.418-25.754z M31.449-25.381l-0.286,0.558h0.29v-0.558H31.449z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M31.934-24.287c0-0.065,0.007-0.127,0.02-0.184
      c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.051,0.061-0.101,0.102-0.149c0.041-0.051,0.088-0.1,0.142-0.148l0.121-0.112
      c0.032-0.029,0.06-0.056,0.08-0.081c0.02-0.024,0.034-0.05,0.046-0.074c0.011-0.025,0.02-0.053,0.021-0.081
      c0.004-0.029,0.006-0.063,0.006-0.1c0-0.122-0.049-0.183-0.146-0.183c-0.037,0-0.067,0.008-0.088,0.023
      c-0.022,0.015-0.038,0.035-0.05,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.057
      c0-0.136,0.037-0.24,0.11-0.314c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.261,0.034,0.332,0.104
      c0.072,0.068,0.106,0.168,0.106,0.296c0,0.047-0.004,0.091-0.012,0.132s-0.021,0.08-0.039,0.117
      c-0.018,0.038-0.041,0.074-0.07,0.108c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.078,0.079-0.104,0.117
      c-0.025,0.039-0.041,0.07-0.047,0.099h0.553v0.232H31.934z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M33.841-25.498c-0.062,0.085-0.116,0.178-0.163,0.275
      c-0.047,0.099-0.089,0.2-0.126,0.303c-0.034,0.104-0.065,0.21-0.089,0.316c-0.023,0.107-0.041,0.213-0.053,0.315h-0.314
      c0.012-0.088,0.028-0.181,0.051-0.277c0.021-0.099,0.051-0.197,0.088-0.299c0.037-0.101,0.082-0.201,0.133-0.304
      c0.054-0.103,0.115-0.203,0.188-0.301h-0.617v-0.257h0.903V-25.498z"
        />
        <rect
          x="16.555"
          y="-26.265"
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#AC6A93"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M17.469-25.767c0.076,0,0.14,0.013,0.192,0.038
      c0.052,0.026,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
      c0,0.076-0.011,0.143-0.034,0.199s-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081c-0.058,0.019-0.123,0.027-0.193,0.027h-0.16
      v0.59h-0.298v-1.479H17.469z M17.378-25.098c0.059,0,0.107-0.018,0.144-0.054s0.055-0.092,0.055-0.168
      c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.449H17.378z"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          points="18.472,-25.767 18.683,-24.722 18.688,-24.722 18.901,-25.767
      19.342,-25.767 19.342,-24.287 19.069,-24.287 19.069,-25.469 19.065,-25.469 18.802,-24.287 18.569,-24.287 18.306,-25.469
      18.302,-25.469 18.302,-24.287 18.028,-24.287 18.028,-25.767 			"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M19.796-24.707c0,0.035,0.003,0.066,0.008,0.096
      c0.005,0.027,0.016,0.052,0.031,0.07c0.015,0.02,0.036,0.035,0.062,0.046c0.025,0.012,0.057,0.017,0.096,0.017
      c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.063-0.013-0.086
      c-0.009-0.024-0.024-0.047-0.045-0.066s-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.031-0.125-0.048
      c-0.066-0.022-0.124-0.047-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
      s-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
      c0.067,0,0.128,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.145,0.07s0.072,0.073,0.095,0.124
      c0.023,0.051,0.034,0.11,0.034,0.182v0.041h-0.286c0-0.07-0.012-0.124-0.037-0.162c-0.025-0.038-0.066-0.057-0.124-0.057
      c-0.033,0-0.061,0.005-0.083,0.015c-0.022,0.009-0.04,0.021-0.053,0.037s-0.022,0.035-0.027,0.055
      c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114s0.06,0.06,0.124,0.087l0.23,0.099
      c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.026,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.065,0.045,0.102
      c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.342c-0.09,0.07-0.216,0.106-0.378,0.106
      c-0.169,0-0.289-0.037-0.362-0.11c-0.072-0.072-0.108-0.179-0.108-0.314v-0.062h0.298V-24.707z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M21.64-25.514c-0.019-0.031-0.055-0.046-0.104-0.046
      c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.019-0.043,0.045-0.056,0.078c-0.013,0.032-0.021,0.072-0.026,0.117
      c-0.004,0.046-0.006,0.097-0.006,0.149v0.073h0.004c0.025-0.039,0.061-0.069,0.107-0.093c0.047-0.022,0.103-0.033,0.169-0.033
      c0.065,0,0.12,0.011,0.166,0.033c0.045,0.023,0.082,0.055,0.111,0.096c0.028,0.041,0.049,0.089,0.062,0.145
      c0.013,0.057,0.02,0.117,0.02,0.185c0,0.17-0.038,0.298-0.113,0.386c-0.075,0.088-0.196,0.132-0.362,0.132
      c-0.102,0-0.184-0.018-0.245-0.054c-0.06-0.036-0.107-0.085-0.139-0.148c-0.033-0.063-0.054-0.136-0.064-0.219
      c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.204,0.009-0.302c0.006-0.098,0.025-0.185,0.056-0.26
      c0.031-0.074,0.08-0.135,0.146-0.181c0.067-0.046,0.161-0.068,0.282-0.068c0.052,0,0.104,0.006,0.152,0.019
      c0.05,0.013,0.093,0.033,0.13,0.062c0.037,0.029,0.067,0.068,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.178H21.67
      C21.67-25.439,21.661-25.483,21.64-25.514 M21.374-24.627c0.004,0.036,0.012,0.067,0.024,0.093
      c0.012,0.025,0.028,0.046,0.05,0.06c0.021,0.015,0.049,0.022,0.084,0.022c0.036,0,0.064-0.008,0.085-0.022
      c0.022-0.014,0.038-0.034,0.049-0.061c0.011-0.026,0.019-0.058,0.022-0.093c0.004-0.035,0.005-0.073,0.005-0.114
      c0-0.101-0.012-0.172-0.037-0.215s-0.066-0.063-0.124-0.063c-0.06,0-0.102,0.021-0.127,0.063s-0.037,0.114-0.037,0.215
      C21.368-24.702,21.37-24.664,21.374-24.627"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M22.111-24.939c0.042-0.067,0.104-0.108,0.187-0.125v-0.005
      c-0.04-0.01-0.074-0.024-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.069c-0.016-0.027-0.028-0.055-0.035-0.083
      c-0.007-0.029-0.01-0.058-0.01-0.083c0-0.145,0.038-0.247,0.115-0.311c0.076-0.063,0.181-0.094,0.316-0.094
      c0.134,0,0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.311c0,0.025-0.004,0.054-0.012,0.083
      c-0.007,0.028-0.019,0.056-0.036,0.083c-0.016,0.025-0.038,0.049-0.065,0.069c-0.027,0.021-0.06,0.036-0.099,0.046v0.005
      c0.083,0.017,0.145,0.058,0.187,0.125c0.041,0.065,0.062,0.145,0.062,0.237c0,0.043-0.005,0.09-0.015,0.141
      c-0.011,0.052-0.033,0.099-0.066,0.144c-0.033,0.044-0.08,0.08-0.142,0.11c-0.061,0.027-0.143,0.043-0.245,0.043
      s-0.184-0.016-0.246-0.043c-0.062-0.03-0.109-0.066-0.142-0.11c-0.033-0.045-0.055-0.092-0.065-0.144
      c-0.011-0.051-0.016-0.098-0.016-0.141C22.049-24.794,22.07-24.874,22.111-24.939 M22.355-24.607
      c0.005,0.03,0.014,0.058,0.027,0.08c0.013,0.023,0.03,0.041,0.053,0.055c0.022,0.013,0.05,0.019,0.083,0.019
      c0.033,0,0.06-0.006,0.083-0.019c0.022-0.014,0.039-0.031,0.052-0.055c0.013-0.022,0.023-0.05,0.027-0.08
      c0.005-0.031,0.008-0.064,0.008-0.1s-0.003-0.068-0.008-0.099c-0.004-0.03-0.014-0.058-0.027-0.08
      c-0.013-0.023-0.03-0.04-0.052-0.053c-0.023-0.015-0.05-0.021-0.083-0.021c-0.033,0-0.061,0.006-0.083,0.021
      c-0.023,0.013-0.04,0.029-0.053,0.053c-0.013,0.022-0.022,0.05-0.027,0.08s-0.007,0.063-0.007,0.099S22.35-24.638,22.355-24.607
       M22.412-25.204c0.026,0.033,0.062,0.05,0.106,0.05s0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.154
      c0-0.136-0.048-0.201-0.145-0.201c-0.097,0-0.145,0.065-0.145,0.201C22.373-25.289,22.386-25.237,22.412-25.204"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M23.393-25.324h-0.292v-0.194h0.027c0.099,0,0.177-0.019,0.235-0.057
      c0.057-0.038,0.094-0.097,0.109-0.18h0.219v1.467h-0.298V-25.324z"
        />
        <rect
          x="46.199"
          y="-26.265"
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#97A0A4"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M47.113-25.767c0.076,0,0.14,0.013,0.191,0.038
      c0.053,0.026,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.021,0.108,0.021,0.163
      c0,0.076-0.012,0.143-0.035,0.199c-0.022,0.057-0.053,0.103-0.094,0.139s-0.091,0.063-0.148,0.081
      c-0.059,0.019-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H47.113z M47.021-25.098c0.06,0,0.107-0.018,0.145-0.054
      s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.449H47.021z"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          points="48.117,-25.767 48.328,-24.722 48.332,-24.722 48.545,-25.767
      48.986,-25.767 48.986,-24.287 48.713,-24.287 48.713,-25.469 48.709,-25.469 48.446,-24.287 48.213,-24.287 47.951,-25.469
      47.946,-25.469 47.946,-24.287 47.672,-24.287 47.672,-25.767 			"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M49.441-24.707c0,0.035,0.002,0.066,0.008,0.096
      c0.004,0.027,0.016,0.052,0.03,0.07c0.015,0.02,0.036,0.035,0.062,0.046c0.025,0.012,0.059,0.017,0.097,0.017
      c0.045,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.063-0.014-0.086
      c-0.009-0.024-0.023-0.047-0.045-0.066s-0.049-0.036-0.082-0.053c-0.033-0.016-0.075-0.031-0.125-0.048
      c-0.066-0.022-0.123-0.047-0.172-0.073s-0.09-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
      c-0.015-0.046-0.023-0.101-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327s0.193-0.108,0.336-0.108
      c0.066,0,0.127,0.008,0.184,0.022s0.104,0.038,0.145,0.07s0.072,0.073,0.096,0.124s0.033,0.11,0.033,0.182v0.041h-0.284
      c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057c-0.033,0-0.062,0.005-0.083,0.015
      c-0.023,0.009-0.04,0.022-0.053,0.037c-0.014,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
      c0,0.045,0.01,0.083,0.028,0.115c0.02,0.03,0.062,0.059,0.125,0.086l0.229,0.099c0.058,0.025,0.103,0.051,0.14,0.078
      C50.02-25,50.049-24.97,50.07-24.939s0.035,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
      c0,0.156-0.047,0.271-0.137,0.342c-0.09,0.07-0.215,0.106-0.377,0.106c-0.168,0-0.289-0.037-0.363-0.11
      c-0.071-0.072-0.107-0.179-0.107-0.314v-0.062h0.299V-24.707z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M51.186-25.754h0.309v0.931h0.143v0.232h-0.143v0.304h-0.273v-0.304h-0.521
      v-0.245L51.186-25.754z M51.217-25.381l-0.287,0.558h0.291v-0.558H51.217z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M51.702-24.287c0-0.065,0.007-0.126,0.021-0.184
      c0.012-0.056,0.032-0.109,0.06-0.161c0.026-0.051,0.06-0.101,0.101-0.149c0.041-0.051,0.088-0.1,0.143-0.148l0.121-0.112
      c0.033-0.029,0.06-0.056,0.078-0.08c0.021-0.025,0.037-0.051,0.047-0.075c0.012-0.025,0.02-0.053,0.022-0.081
      c0.003-0.029,0.005-0.063,0.005-0.1c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
      c-0.022,0.015-0.039,0.035-0.051,0.062c-0.012,0.027-0.02,0.057-0.022,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.056
      c0-0.137,0.036-0.241,0.11-0.315c0.072-0.073,0.188-0.109,0.346-0.109c0.148,0,0.26,0.034,0.332,0.104
      c0.07,0.068,0.107,0.168,0.107,0.296c0,0.047-0.005,0.091-0.013,0.132c-0.009,0.041-0.021,0.08-0.039,0.117
      c-0.019,0.038-0.042,0.074-0.071,0.109c-0.029,0.034-0.063,0.071-0.105,0.108l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
      c-0.024,0.039-0.041,0.07-0.048,0.099h0.554v0.232H51.702z"
        />
        <path
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          d="M53.04-24.5c0.019,0.032,0.054,0.047,0.104,0.047
      c0.041,0,0.072-0.009,0.096-0.027c0.023-0.02,0.042-0.044,0.054-0.078c0.015-0.033,0.022-0.072,0.026-0.118
      c0.005-0.045,0.007-0.095,0.007-0.148v-0.07h-0.005c-0.023,0.037-0.061,0.066-0.105,0.09c-0.047,0.023-0.104,0.034-0.17,0.034
      c-0.064,0-0.119-0.011-0.164-0.034c-0.048-0.023-0.084-0.055-0.113-0.096c-0.027-0.04-0.049-0.089-0.062-0.145
      c-0.013-0.057-0.019-0.118-0.019-0.184c0-0.17,0.037-0.3,0.111-0.391c0.076-0.089,0.196-0.134,0.362-0.134
      c0.103,0,0.185,0.019,0.244,0.058c0.062,0.038,0.108,0.088,0.14,0.15c0.033,0.063,0.055,0.137,0.064,0.219
      c0.009,0.082,0.014,0.17,0.014,0.26c0,0.104-0.003,0.206-0.009,0.303c-0.006,0.098-0.024,0.184-0.056,0.259
      c-0.031,0.074-0.08,0.133-0.147,0.176c-0.067,0.044-0.161,0.066-0.28,0.066c-0.054,0-0.104-0.005-0.154-0.016
      c-0.049-0.011-0.092-0.03-0.129-0.059c-0.037-0.027-0.066-0.065-0.09-0.114c-0.022-0.048-0.035-0.106-0.037-0.177h0.287
      C53.01-24.574,53.02-24.531,53.04-24.5 M53.023-25.056c0.025,0.043,0.066,0.064,0.125,0.064s0.102-0.021,0.127-0.064
      c0.025-0.042,0.037-0.114,0.037-0.213c0-0.042-0.002-0.08-0.006-0.117c-0.004-0.035-0.013-0.066-0.024-0.091
      c-0.011-0.026-0.028-0.046-0.05-0.062c-0.021-0.014-0.049-0.021-0.084-0.021s-0.064,0.008-0.086,0.021
      c-0.021,0.016-0.037,0.035-0.049,0.062c-0.011,0.027-0.019,0.057-0.021,0.093c-0.003,0.035-0.005,0.073-0.005,0.115
      C52.987-25.17,52.999-25.098,53.023-25.056"
        />
        <rect
          x="65.891"
          y="-26.265"
          clipPath="url(#SAVER_SVGID_34_)"
          fill="#FFFFFF"
          width="8.813"
          height="8.812"
        />

        <rect
          x="65.891"
          y="-26.265"
          clipPath="url(#SAVER_SVGID_34_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.813"
          height="8.813"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          points="66.48,-25.766 66.645,-24.672 66.648,-24.672 66.823,-25.766 67.109,-25.766
      67.283,-24.672 67.287,-24.672 67.451,-25.766 67.736,-25.766 67.467,-24.287 67.134,-24.287 66.969,-25.361 66.964,-25.361
      66.798,-24.287 66.464,-24.287 66.195,-25.766 			"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          points="68.155,-25.766 68.155,-25.201 68.504,-25.201 68.504,-25.766 68.803,-25.766
      68.803,-24.287 68.504,-24.287 68.504,-24.944 68.155,-24.944 68.155,-24.287 67.857,-24.287 67.857,-25.766 			"
        />
        <rect
          x="69.025"
          y="-25.766"
          clipPath="url(#SAVER_SVGID_34_)"
          width="0.297"
          height="1.479"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          points="70.41,-25.522 70.088,-25.522 70.088,-24.287 69.789,-24.287 69.789,-25.522
      69.465,-25.522 69.465,-25.767 70.41,-25.767 			"
        />
        <polygon
          clipPath="url(#SAVER_SVGID_34_)"
          points="71.366,-25.522 70.837,-25.522 70.837,-25.174 71.334,-25.174 71.334,-24.929
      70.837,-24.929 70.837,-24.531 71.387,-24.531 71.387,-24.287 70.539,-24.287 70.539,-25.767 71.366,-25.767 			"
        />
      </g>
    </g>
  );
};

function SkillsForMyFuture(props: any) {
  const { badge, fallback = null, ...otherProps } = props;

  switch (badge) {
    case 'Fixing':
      return <Fixing {...otherProps} />;
    case 'Investigating':
      return <Investigating {...otherProps} />;
    case 'Upcycling':
      return <Upcycling {...otherProps} />;
    case 'Saver':
      return <Saver {...otherProps} />;
    default:
      return fallback;
  }
}

export default SkillsForMyFuture;
