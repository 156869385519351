import Collapse from 'components/collapse';
import ChevronDownIcon from 'components/icons/ui/ChevronDown';

import './accordion.scss';

function AccordionItem(props: any) {
  const {
    children,
    className,
    title,
    open = false,
    onClick,
    ...otherProps
  } = props;

  const classes = ['accordion-toggle'];
  if (open) classes.push('accordion-toggle--open');
  if (className) classes.push(className);

  return (
    <>
      <div {...otherProps} onClick={onClick} className={classes.join(' ')}>
        {title}
        <ChevronDownIcon size={32} className="accordion-toggle__icon" />
      </div>
      <Collapse show={open}>{children}</Collapse>
    </>
  );
}

export default AccordionItem;
