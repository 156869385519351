import { HTMLProps, PropsWithChildren } from 'react';
import './button.scss';

type HTMLButtonProps = HTMLProps<HTMLButtonElement>;
type ButtonProps = HTMLButtonProps &
  PropsWithChildren<any & { color: string; block?: boolean }>;

function Button(props: ButtonProps) {
  const { children, className, color, block = false, ...otherProps } = props;

  const classes = ['button'];
  if (className) classes.push(className);
  if (block) classes.push('button--block');
  if (color) classes.push(`button--${color}`);

  return (
    <button type="button" {...otherProps} className={classes.join(' ')}>
      {children}
    </button>
  );
}

export function IconButton(props: ButtonProps) {
  const { children, className, ...otherProps } = props;

  const classes = ['button--icon-button'];
  if (className) classes.push(className);

  return (
    <Button {...otherProps} className={classes.join(' ')}>
      {children}
    </Button>
  );
}

export default Button;
