import Button from 'components/button';
import { Heading, Paragraph, Span } from 'components/typography';
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal';

function AboutModal(props: any) {
  return (
    <Modal {...props} color="light">
      <ModalHeader title="About" color="dark" />
      <ModalBody className="pt-2">
        <Heading size={2} color="primary">
          Go4Guides
        </Heading>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Paragraph>

        <Heading size={3}>Credits</Heading>
        <Span>Conceptualised by Diana Solly</Span>
        <br />
        <Span>Developed by Mark Bailey</Span>
      </ModalBody>
      <ModalFooter>
        <Button color="dark" block>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AboutModal;
