import Base, { AwardProps } from './Base';

function KnowMyself(props: AwardProps) {
  return (
    <Base {...props}>
      <g id="Know_Myself_Award">
        <g>
          <defs>
            <rect
              id="SVGID_11_"
              x="0"
              y="0.001"
              width="53.514"
              height="66.891"
            />
          </defs>
          <clipPath id="SVGID_12_">
            <use xlinkHref="#SVGID_11_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M26.757,66.224L26.757,66.224c-14.481,0-26.222-11.74-26.222-26.223V5.744
			c0-2.803,2.271-5.075,5.075-5.075h42.294c2.803,0,5.074,2.272,5.074,5.075v34.257C52.979,54.484,41.239,66.224,26.757,66.224"
          />
          <rect
            x="2.453"
            y="2.361"
            clipPath="url(#SVGID_12_)"
            fill="#00ADEE"
            width="48.464"
            height="19.121"
          />

          <line
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#60C1E5"
            strokeWidth="3.822"
            strokeMiterlimit="10"
            x1="3.093"
            y1="22.011"
            x2="50.642"
            y2="22.011"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M21.564,8.659c0.016,0.124,0.021,0.227,0.021,0.315
			c0,1.047-0.748,1.8-1.79,1.8c-1.048,0-1.861-0.789-1.861-1.806c0-1.011,0.81-1.805,1.847-1.805c0.768,0,1.444,0.361,1.686,1.037
			l-0.819,0.237c-0.201-0.392-0.532-0.495-0.866-0.495c-0.563,0-0.991,0.444-0.991,1.026c0,0.589,0.439,1.032,1.018,1.032
			c0.454,0,0.783-0.237,0.911-0.619h-1.057V8.659H21.564z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M24.655,10.696h-0.826v-0.248c-0.194,0.207-0.469,0.325-0.794,0.325
			c-0.603,0-1.006-0.428-1.006-1.078V8.107h0.82V9.52c0,0.304,0.181,0.511,0.443,0.511c0.325,0,0.536-0.222,0.536-0.723V8.107h0.826
			V10.696z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M26.188,7.344c0,0.262-0.201,0.458-0.48,0.458
			c-0.278,0-0.474-0.196-0.474-0.458c0-0.254,0.195-0.47,0.474-0.47C25.987,6.874,26.188,7.09,26.188,7.344 M25.294,8.107h0.825
			v2.589h-0.825V8.107z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M29.485,10.696h-0.827V10.49c-0.201,0.181-0.469,0.284-0.805,0.284
			c-0.686,0-1.264-0.552-1.264-1.372c0-0.815,0.578-1.372,1.264-1.372c0.336,0,0.604,0.103,0.805,0.284V6.981h0.827V10.696z
			 M28.659,9.402c0-0.397-0.299-0.671-0.634-0.671c-0.361,0-0.624,0.274-0.624,0.671c0,0.402,0.263,0.67,0.624,0.67
			C28.36,10.072,28.659,9.804,28.659,9.402"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M32.622,9.613h-1.898c0.066,0.309,0.277,0.5,0.557,0.5
			c0.189,0,0.412-0.046,0.553-0.299l0.73,0.15c-0.221,0.546-0.689,0.809-1.283,0.809c-0.748,0-1.367-0.552-1.367-1.372
			c0-0.815,0.619-1.372,1.373-1.372c0.732,0,1.318,0.526,1.336,1.372V9.613z M30.739,9.107h1.066C31.729,8.83,31.522,8.7,31.286,8.7
			C31.054,8.7,30.815,8.844,30.739,9.107"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M33.679,9.855c0.037,0.222,0.236,0.304,0.459,0.304
			c0.217,0,0.336-0.092,0.336-0.206c0-0.088-0.066-0.155-0.258-0.191L33.688,9.66c-0.479-0.088-0.752-0.346-0.752-0.753
			c0-0.531,0.459-0.877,1.115-0.877c0.645,0,1.072,0.294,1.176,0.742l-0.754,0.15c-0.025-0.16-0.182-0.304-0.434-0.304
			c-0.223,0-0.295,0.108-0.295,0.201c0,0.072,0.031,0.144,0.197,0.18l0.609,0.124c0.488,0.103,0.717,0.397,0.717,0.78
			c0,0.572-0.496,0.871-1.176,0.871c-0.609,0-1.131-0.222-1.219-0.753L33.679,9.855z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="13.18,16.347 12.05,15.047 11.87,15.047 11.87,16.347 11.018,16.347
			11.018,12.891 11.87,12.891 11.87,14.263 11.993,14.263 13.17,12.891 14.191,12.891 12.721,14.624 14.294,16.347 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M17.205,14.758v1.589h-0.82v-1.413c0-0.305-0.181-0.511-0.443-0.511
			c-0.325,0-0.537,0.222-0.537,0.722v1.202h-0.825v-2.59h0.825v0.248c0.196-0.206,0.47-0.325,0.795-0.325
			C16.802,13.68,17.205,14.109,17.205,14.758"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M20.451,15.052c0,0.82-0.651,1.367-1.42,1.367
			c-0.784,0-1.434-0.547-1.434-1.367c0-0.814,0.649-1.362,1.434-1.362C19.799,13.69,20.451,14.238,20.451,15.052 M19.656,15.052
			c0-0.403-0.304-0.671-0.625-0.671c-0.335,0-0.64,0.268-0.64,0.671c0,0.407,0.305,0.67,0.64,0.67
			C19.352,15.723,19.656,15.459,19.656,15.052"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="23.756,16.352 23.04,16.352 22.576,14.949 22.106,16.352
			21.39,16.352 20.559,13.758 21.369,13.758 21.792,15.15 22.282,13.758 22.865,13.758 23.355,15.156 23.778,13.758 24.587,13.758
					"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="30.108,16.347 29.282,16.347 29.282,14.505 28.313,16.347
			27.921,16.347 26.946,14.495 26.946,16.347 26.12,16.347 26.12,12.891 26.956,12.891 28.117,15.083 29.278,12.891 30.108,12.891
					"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="31.991,17.224 31.099,17.224 31.517,16.259 30.444,13.752
			31.331,13.752 31.946,15.264 32.601,13.752 33.487,13.752 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M34.376,15.506c0.035,0.222,0.236,0.304,0.459,0.304
			c0.217,0,0.336-0.092,0.336-0.206c0-0.088-0.068-0.155-0.26-0.191l-0.525-0.103c-0.479-0.088-0.752-0.346-0.752-0.753
			c0-0.531,0.459-0.877,1.113-0.877c0.645,0,1.072,0.294,1.176,0.742l-0.752,0.15c-0.025-0.16-0.182-0.304-0.434-0.304
			c-0.223,0-0.295,0.108-0.295,0.201c0,0.072,0.031,0.144,0.197,0.18l0.607,0.124c0.49,0.104,0.717,0.398,0.717,0.78
			c0,0.572-0.494,0.871-1.176,0.871c-0.607,0-1.129-0.222-1.217-0.753L34.376,15.506z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M38.94,15.264h-1.898c0.068,0.309,0.279,0.5,0.559,0.5
			c0.189,0,0.412-0.046,0.553-0.299l0.732,0.15c-0.223,0.546-0.691,0.809-1.285,0.809c-0.748,0-1.367-0.552-1.367-1.372
			c0-0.815,0.619-1.372,1.373-1.372c0.73,0,1.318,0.526,1.334,1.372V15.264z M37.06,14.758h1.066
			c-0.078-0.278-0.283-0.407-0.52-0.407C37.372,14.351,37.136,14.495,37.06,14.758"
          />
          <rect
            x="39.37"
            y="12.633"
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            width="0.826"
            height="3.714"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M42.476,13.758v0.655h-0.713v1.934h-0.82v-1.934h-0.371v-0.655h0.371v-0.202
			c0-0.608,0.42-0.995,1.043-0.995c0.176,0,0.346,0.036,0.512,0.108l-0.176,0.64c-0.078-0.026-0.16-0.041-0.223-0.041
			c-0.195,0-0.336,0.119-0.336,0.325v0.165H42.476z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            points="49.573,49.484 40.313,60.886 29.667,50.24 39.997,39.908 		"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            points="48.534,51.544 34.149,64.05 14.435,44.324 26.205,29.189 		"
          />

          <rect
            x="37.932"
            y="37.713"
            transform="matrix(0.7068 -0.7074 0.7074 0.7068 -18.7401 40.4233)"
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            width="2.921"
            height="10.209"
          />

          <rect
            x="23.959"
            y="27.035"
            transform="matrix(0.7072 -0.707 0.707 0.7072 -15.2794 27.3819)"
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            width="2.92"
            height="10.206"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            points="26.444,34.47 24.377,36.537 18.104,30.263 19.226,27.253 		"
          />

          <rect
            x="14.235"
            y="36.636"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.9173 23.3239)"
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            width="2.922"
            height="10.207"
          />

          <rect
            x="15.835"
            y="39.989"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -26.8192 25.4373)"
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            width="2.922"
            height="10.207"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            points="20.146,40.666 18.08,42.732 10.862,35.513 13.361,33.882 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M23.985,43.695l1.284-0.85c0.083-0.055,0.195-0.021,0.234,0.068l0.621,1.365
			c0.031,0.07,0.006,0.154-0.058,0.197l-0.895,0.59c-0.064,0.043-0.15,0.033-0.204-0.023l-1.012-1.105
			C23.888,43.865,23.902,43.748,23.985,43.695"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M16.69,29.864l-1.285,0.85c-0.083,0.054-0.195,0.021-0.234-0.07l-0.62-1.364
			c-0.031-0.071-0.007-0.154,0.058-0.197l0.895-0.59c0.064-0.043,0.15-0.033,0.203,0.023l1.013,1.106
			C16.786,29.694,16.773,29.81,16.69,29.864"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M13.422,40.425l0.85,1.285c0.054,0.084,0.021,0.195-0.07,0.234l-1.363,0.621
			c-0.071,0.031-0.155,0.006-0.197-0.059l-0.59-0.893c-0.043-0.064-0.033-0.15,0.022-0.205l1.106-1.012
			C13.252,40.33,13.368,40.343,13.422,40.425"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M27.252,33.132l-0.85-1.285c-0.055-0.084-0.021-0.195,0.069-0.234
			l1.364-0.621c0.071-0.031,0.155-0.006,0.197,0.059l0.59,0.892c0.043,0.066,0.033,0.152-0.023,0.205l-1.105,1.012
			C27.422,33.228,27.306,33.214,27.252,33.132"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M27.806,39.089l0.308-1.508c0.021-0.098,0.122-0.154,0.214-0.117l1.404,0.527
			c0.072,0.025,0.113,0.104,0.098,0.18l-0.213,1.049c-0.016,0.076-0.084,0.129-0.162,0.127l-1.498-0.066
			C27.859,39.277,27.786,39.187,27.806,39.089"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M12.869,34.468l-0.309,1.51c-0.02,0.096-0.122,0.152-0.214,0.117
			l-1.403-0.527c-0.072-0.027-0.113-0.104-0.099-0.18l0.214-1.049c0.017-0.076,0.083-0.131,0.161-0.129l1.498,0.066
			C12.815,34.281,12.888,34.371,12.869,34.468"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M18.027,44.248l1.509,0.309c0.097,0.02,0.153,0.123,0.117,0.213l-0.527,1.404
			c-0.027,0.072-0.103,0.113-0.18,0.098l-1.048-0.213c-0.076-0.016-0.13-0.084-0.128-0.162l0.064-1.498
			C17.838,44.3,17.929,44.228,18.027,44.248"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M22.648,29.311l-1.509-0.308c-0.098-0.02-0.153-0.122-0.117-0.214
			l0.527-1.404c0.026-0.072,0.104-0.113,0.18-0.098l1.048,0.214c0.076,0.016,0.13,0.083,0.128,0.161l-0.066,1.498
			C22.835,29.258,22.745,29.331,22.648,29.311"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M26.312,41.822l0.873-1.27c0.056-0.082,0.172-0.094,0.243-0.023l1.086,1.031
			c0.056,0.055,0.063,0.141,0.02,0.205l-0.605,0.883c-0.045,0.063-0.128,0.086-0.198,0.055l-1.354-0.646
			C26.287,42.015,26.255,41.904,26.312,41.822"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M14.363,31.738l-0.873,1.268c-0.056,0.082-0.172,0.094-0.243,0.025
			l-1.086-1.033c-0.056-0.053-0.064-0.141-0.02-0.203l0.605-0.883c0.044-0.064,0.127-0.086,0.198-0.054l1.353,0.645
			C14.387,31.542,14.418,31.656,14.363,31.738"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M15.295,42.753l1.269,0.873c0.082,0.055,0.093,0.172,0.024,0.242
			l-1.032,1.088c-0.054,0.055-0.142,0.064-0.204,0.02l-0.883-0.607c-0.064-0.043-0.086-0.127-0.055-0.197l0.646-1.352
			C15.101,42.728,15.213,42.699,15.295,42.753"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M25.379,30.805l-1.27-0.872c-0.081-0.057-0.093-0.173-0.023-0.244
			l1.032-1.086c0.054-0.056,0.141-0.064,0.204-0.02l0.883,0.606c0.063,0.044,0.086,0.127,0.055,0.198l-0.646,1.353
			C25.574,30.83,25.461,30.861,25.379,30.805"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M28.127,36.121l-0.28-1.516c-0.018-0.098,0.056-0.188,0.155-0.188
			l1.499-0.039c0.078-0.002,0.143,0.055,0.158,0.131l0.195,1.053c0.014,0.076-0.029,0.15-0.102,0.178l-1.413,0.502
			C28.248,36.275,28.145,36.218,28.127,36.121"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M12.547,37.439l0.279,1.514c0.019,0.098-0.056,0.188-0.154,0.189
			l-1.498,0.039c-0.078,0-0.145-0.055-0.158-0.131l-0.196-1.053c-0.013-0.078,0.029-0.152,0.103-0.18l1.412-0.5
			C12.427,37.283,12.529,37.341,12.547,37.439"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M20.997,44.57l1.515-0.281c0.097-0.018,0.188,0.057,0.188,0.154l0.039,1.5
			c0.001,0.078-0.055,0.145-0.131,0.158l-1.053,0.195c-0.077,0.014-0.151-0.029-0.179-0.104l-0.5-1.412
			C20.841,44.689,20.899,44.587,20.997,44.57"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M19.678,28.989l-1.516,0.28c-0.097,0.018-0.187-0.056-0.188-0.155
			l-0.039-1.498c-0.001-0.078,0.055-0.144,0.131-0.158l1.052-0.196c0.077-0.013,0.152,0.029,0.18,0.102l0.5,1.412
			C19.833,28.869,19.776,28.971,19.678,28.989"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#F89E2A"
            d="M27.064,32.333c-2.456-3.715-7.457-4.736-11.172-2.281
			c-3.716,2.457-4.736,7.458-2.281,11.173c2.455,3.713,7.457,4.734,11.173,2.279C28.498,41.05,29.519,36.048,27.064,32.333"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#60C1E5"
            d="M48.335,3.822c0.748,0,1.357,0.609,1.357,1.357v34.958
			c0,12.645-10.289,22.934-22.935,22.934c-12.646,0-22.934-10.289-22.934-22.934V5.179c0-0.748,0.607-1.357,1.355-1.357H48.335
			 M48.335,0H5.179C2.319,0,0,2.318,0,5.179v34.958C0,54.912,11.98,66.894,26.757,66.894c14.777,0,26.757-11.982,26.757-26.758
			V5.179C53.515,2.318,51.196,0,48.335,0"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FCBF60"
            d="M14.635,31.077c3.15-3.149,8.255-3.149,11.404,0
			c3.149,3.149,3.149,8.254,0,11.405"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            d="M14.895,38.527c0.965,3.002,4.189,4.658,7.19,3.695
			c3.001-0.965,4.658-4.191,3.694-7.191c-0.964-3.002-4.19-4.658-7.191-3.695C15.587,32.3,13.93,35.527,14.895,38.527"
          />

          <circle
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#A46B25"
            strokeWidth="1.218"
            strokeMiterlimit="10"
            cx="20.337"
            cy="36.779"
            r="1.601"
          />

          <line
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#D28027"
            strokeWidth="1.218"
            strokeMiterlimit="10"
            x1="18.752"
            y1="31.22"
            x2="19.877"
            y2="35.167"
          />

          <line
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#A46B25"
            strokeWidth="1.218"
            strokeMiterlimit="10"
            x1="20.796"
            y1="38.392"
            x2="21.921"
            y2="42.337"
          />

          <line
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#D28027"
            strokeWidth="1.218"
            strokeMiterlimit="10"
            x1="25.895"
            y1="35.195"
            x2="21.86"
            y2="36.345"
          />

          <line
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#A46B25"
            strokeWidth="1.218"
            strokeMiterlimit="10"
            x1="18.814"
            y1="37.214"
            x2="14.779"
            y2="38.363"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#A46B25"
            strokeWidth="1.218"
            strokeMiterlimit="10"
            d="M14.895,38.527
			c0.965,3.002,4.189,4.658,7.19,3.695c3.001-0.965,4.658-4.191,3.694-7.191c-0.964-3.002-4.19-4.658-7.191-3.695
			C15.587,32.3,13.93,35.527,14.895,38.527z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#D28027"
            strokeWidth="1.218"
            strokeMiterlimit="10"
            d="M16.295,32.736
			c2.232-2.232,5.853-2.232,8.084,0c2.233,2.234,2.233,5.854,0,8.086"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#D28027"
            strokeWidth="1.218"
            strokeMiterlimit="10"
            d="M19.206,35.648
			c0.625-0.627,1.639-0.627,2.264,0c0.625,0.625,0.625,1.639,0,2.264"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M40.431,44.06l-0.555-1.457c-0.035-0.094,0.021-0.197,0.121-0.215
			l1.482-0.311c0.078-0.016,0.154,0.027,0.184,0.1l0.385,1.014c0.027,0.072-0.002,0.156-0.07,0.195l-1.314,0.756
			C40.577,44.193,40.466,44.154,40.431,44.06"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M33.835,39.892l-1.455,0.553c-0.094,0.037-0.197-0.021-0.217-0.119
			l-0.311-1.484c-0.014-0.076,0.027-0.154,0.102-0.182l1.012-0.385c0.074-0.029,0.156,0,0.197,0.068l0.754,1.314
			C33.968,39.744,33.929,39.855,33.835,39.892"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M35.151,39.757l1.539,0.248c0.098,0.016,0.186-0.061,0.186-0.16l0.004-1.518
			c0-0.078-0.057-0.145-0.135-0.156l-1.07-0.174c-0.078-0.012-0.152,0.033-0.178,0.107l-0.473,1.439
			C34.991,39.64,35.052,39.742,35.151,39.757"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M29.667,46.486l0.555,1.457c0.035,0.094-0.021,0.195-0.121,0.215
			l-1.483,0.311c-0.077,0.014-0.154-0.027-0.183-0.102l-0.385-1.012c-0.028-0.074,0.001-0.156,0.069-0.197l1.315-0.754
			C29.521,46.353,29.632,46.392,29.667,46.486"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M36.261,50.654l1.457-0.553c0.094-0.037,0.197,0.02,0.217,0.119l0.311,1.484
			c0.014,0.076-0.029,0.154-0.102,0.182l-1.014,0.385c-0.072,0.029-0.156,0-0.195-0.068l-0.754-1.314
			C36.128,50.802,36.169,50.691,36.261,50.654"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M31.308,41.218l-0.984,1.209c-0.063,0.078-0.182,0.078-0.248,0.004
			l-1.01-1.131c-0.051-0.061-0.053-0.146-0.004-0.207l0.686-0.84c0.049-0.061,0.135-0.076,0.203-0.039l1.311,0.762
			C31.349,41.025,31.372,41.14,31.308,41.218"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M38.79,49.328l0.984-1.209c0.064-0.078,0.182-0.08,0.246-0.004l1.012,1.131
			c0.051,0.059,0.053,0.146,0.002,0.207l-0.684,0.84c-0.049,0.061-0.135,0.076-0.203,0.039l-1.313-0.762
			C38.749,49.519,38.728,49.404,38.79,49.328"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M29.782,43.632l-0.25,1.539c-0.016,0.1-0.117,0.158-0.211,0.125l-1.441-0.473
			c-0.073-0.025-0.118-0.1-0.106-0.178l0.173-1.07c0.012-0.076,0.079-0.135,0.158-0.135l1.515,0.004
			C29.722,43.445,29.798,43.533,29.782,43.632"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M40.315,46.914l0.25-1.539c0.014-0.1,0.115-0.16,0.211-0.127l1.439,0.473
			c0.074,0.027,0.119,0.102,0.107,0.18l-0.172,1.068c-0.014,0.078-0.08,0.135-0.158,0.137l-1.516-0.004
			C40.376,47.101,40.3,47.011,40.315,46.914"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M34.946,50.789l-1.539-0.25c-0.098-0.014-0.188,0.063-0.186,0.162
			l-0.004,1.516c0,0.08,0.057,0.146,0.135,0.158l1.07,0.172c0.076,0.014,0.152-0.031,0.178-0.105L35.073,51
			C35.106,50.906,35.046,50.804,34.946,50.789"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M32.202,50l-1.207-0.984c-0.078-0.064-0.193-0.043-0.244,0.045l-0.76,1.313
			c-0.039,0.066-0.023,0.152,0.037,0.201l0.84,0.686c0.061,0.049,0.148,0.049,0.209-0.002l1.129-1.012
			C32.282,50.179,32.28,50.062,32.202,50"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            d="M37.896,40.546l1.207,0.984c0.078,0.064,0.193,0.043,0.242-0.045l0.762-1.313
			c0.039-0.066,0.023-0.152-0.037-0.203l-0.84-0.684c-0.063-0.049-0.148-0.049-0.209,0.002L37.89,40.3
			C37.815,40.367,37.817,40.484,37.896,40.546"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#F89E2A"
            d="M37.097,50.656c2.973-1.131,4.467-4.457,3.336-7.43
			c-1.131-2.975-4.459-4.469-7.432-3.338c-2.973,1.133-4.467,4.459-3.336,7.432C30.796,50.292,34.122,51.787,37.097,50.656"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FCBF60"
            d="M30.976,41.199c2.25-2.25,5.896-2.25,8.146,0s2.25,5.898,0,8.146"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            d="M34.944,41.08c-2.313,0.076-4.131,2.021-4.055,4.332
			c0.076,2.313,2.02,4.129,4.332,4.053c2.311-0.076,4.129-2.02,4.053-4.33C39.198,42.822,37.255,41.005,34.944,41.08"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#A46B25"
            strokeWidth="0.945"
            strokeMiterlimit="10"
            d="M34.921,41.388
			c-2.145,0.07-3.828,1.871-3.756,4.014c0.07,2.143,1.869,3.826,4.012,3.756s3.828-1.871,3.756-4.014
			C38.862,43.001,37.062,41.316,34.921,41.388z"
          />

          <line
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#D28027"
            strokeWidth="0.945"
            strokeMiterlimit="10"
            x1="38.979"
            y1="45.273"
            x2="36.188"
            y2="45.273"
          />

          <line
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#A46B25"
            strokeWidth="0.945"
            strokeMiterlimit="10"
            x1="33.909"
            y1="45.273"
            x2="31.118"
            y2="45.273"
          />

          <line
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#A46B25"
            strokeWidth="0.945"
            strokeMiterlimit="10"
            x1="35.048"
            y1="49.203"
            x2="35.048"
            y2="46.349"
          />

          <line
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#D28027"
            strokeWidth="0.945"
            strokeMiterlimit="10"
            x1="35.048"
            y1="44.195"
            x2="35.048"
            y2="41.343"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#A46B25"
            strokeWidth="0.945"
            strokeMiterlimit="10"
            d="M35.013,44.185
			c-0.6,0.02-1.072,0.523-1.053,1.125c0.021,0.598,0.525,1.07,1.125,1.051s1.07-0.525,1.053-1.123
			C36.116,44.636,35.612,44.166,35.013,44.185z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#D28027"
            strokeWidth="0.945"
            strokeMiterlimit="10"
            d="M32.3,42.525
			c1.518-1.518,3.979-1.518,5.498,0c1.518,1.518,1.518,3.979,0,5.496"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#D28027"
            strokeWidth="0.945"
            strokeMiterlimit="10"
            d="M34.278,44.503
			c0.426-0.426,1.115-0.426,1.539,0c0.426,0.426,0.426,1.113,0,1.539"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#4F86C6"
            d="M26.757,61.279c-2.288,0-4.142-1.854-4.142-4.141
			c0-2.289,1.854-4.143,4.142-4.143c2.289,0,4.14,1.854,4.14,4.143C30.897,59.425,29.046,61.279,26.757,61.279"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M26.757,52.996c2.289,0,4.14,1.854,4.14,4.141
			c0,2.289-1.852,4.143-4.14,4.143c-2.288,0-4.142-1.854-4.142-4.143C22.616,54.849,24.469,52.996,26.757,52.996 M26.757,52.638
			c-2.481,0-4.498,2.02-4.498,4.498c0,2.482,2.017,4.498,4.498,4.498c2.482,0,4.498-2.016,4.498-4.498
			C31.255,54.658,29.239,52.638,26.757,52.638"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M28.921,55.449c-0.207,0-0.404,0.096-0.539,0.219l-0.011-0.012
			c0.063-0.102,0.146-0.299,0.146-0.621c0-0.766-0.767-1.346-1.76-1.346s-1.771,0.58-1.771,1.346c0,0.322,0.104,0.52,0.155,0.621
			l-0.011,0.012c-0.134-0.123-0.331-0.219-0.537-0.219c-0.633,0-1.264,0.611-1.264,1.75c0,1.119,0.621,1.75,1.294,1.75
			c0.621,0,1.057-0.342,1.449-0.734c-0.063-0.094-0.155-0.188-0.259-0.188s-0.218,0.074-0.414,0.229
			c-0.259,0.197-0.486,0.311-0.787,0.311c-0.527,0-0.754-0.59-0.754-1.367c0-0.828,0.217-1.377,0.775-1.377
			c0.248,0,0.413,0.072,0.58,0.229c0.248,0.229,0.33,0.289,0.444,0.289c0.084,0,0.135-0.053,0.155-0.082
			c-0.187-0.188-0.455-0.58-0.455-1.086c0-0.58,0.486-0.984,1.397-0.984c0.91,0,1.398,0.404,1.398,0.984
			c0,0.506-0.271,0.898-0.456,1.086c0.02,0.029,0.071,0.082,0.154,0.082c0.114,0,0.197-0.061,0.445-0.289
			c0.155-0.156,0.332-0.229,0.581-0.229c0.559,0,0.775,0.549,0.775,1.377c0,0.777-0.229,1.367-0.754,1.367
			c-0.301,0-0.54-0.113-0.788-0.311c-0.197-0.154-0.311-0.229-0.414-0.229c-0.104,0-0.196,0.094-0.26,0.188
			c0.384,0.393,0.828,0.734,1.45,0.734c0.672,0,1.293-0.631,1.293-1.75C30.183,56.06,29.552,55.449,28.921,55.449"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M27.451,55.429h-0.466c-0.031,0-0.051-0.021-0.063-0.041l-0.145-0.445
			c-0.01-0.031-0.021-0.031-0.031,0l-0.145,0.445c-0.01,0.031-0.031,0.041-0.053,0.041h-0.466c-0.021,0-0.03,0.01-0.01,0.02
			l0.384,0.281c0.021,0.02,0.021,0.051,0.01,0.072l-0.146,0.434c-0.009,0.021-0.009,0.043,0.021,0.021l0.384-0.27
			c0.03-0.02,0.052-0.02,0.083,0l0.373,0.27c0.02,0.021,0.03,0.01,0.02-0.01l-0.144-0.445c-0.012-0.031,0-0.063,0.021-0.072
			l0.381-0.281C27.482,55.439,27.471,55.429,27.451,55.429"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M27.099,58.494c-0.196-0.498-0.353-0.838-0.353-1.252
			c0-0.178,0.052-0.332,0.083-0.414c0.042-0.084,0.052-0.137,0.01-0.137c-0.062,0-0.195,0.145-0.278,0.27
			c-0.063,0.104-0.135,0.303-0.135,0.455c0,0.291,0.093,0.602,0.154,0.787c0.115,0.322,0.218,0.695,0.218,1.047
			c0,0.561-0.249,0.941-0.692,1.283c0.04,0.031,0.144,0.082,0.269,0.082c0.435,0,0.952-0.486,0.952-1.148
			C27.327,59.074,27.212,58.804,27.099,58.494"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M-5.577-18.361c0.065,0,0.121,0.011,0.166,0.034
			c0.045,0.022,0.082,0.051,0.108,0.087c0.028,0.036,0.047,0.077,0.061,0.123c0.011,0.046,0.018,0.093,0.018,0.141
			c0,0.066-0.011,0.124-0.029,0.173c-0.021,0.049-0.049,0.089-0.084,0.12c-0.034,0.031-0.078,0.055-0.128,0.07
			c-0.051,0.016-0.106,0.024-0.168,0.024h-0.138v0.512H-6.03v-1.284H-5.577z M-5.656-17.78c0.051,0,0.093-0.015,0.125-0.047
			c0.032-0.031,0.048-0.079,0.048-0.145c0-0.065-0.014-0.114-0.042-0.148c-0.028-0.033-0.073-0.05-0.139-0.05h-0.107v0.39H-5.656z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="-4.707,-18.36 -4.524,-17.454 -4.52,-17.454 -4.334,-18.36
			-3.951,-18.36 -3.951,-17.077 -4.189,-17.077 -4.189,-18.102 -4.192,-18.102 -4.421,-17.077 -4.622,-17.077 -4.85,-18.102
			-4.854,-18.102 -4.854,-17.077 -5.091,-17.077 -5.091,-18.36 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M-3.558-17.44c0,0.03,0.002,0.058,0.008,0.082
			c0.005,0.025,0.014,0.045,0.026,0.062c0.013,0.016,0.031,0.03,0.054,0.039c0.021,0.01,0.05,0.015,0.084,0.015
			c0.038,0,0.074-0.013,0.106-0.039c0.031-0.025,0.047-0.065,0.047-0.119c0-0.029-0.004-0.054-0.012-0.075
			c-0.007-0.021-0.021-0.04-0.038-0.057c-0.018-0.016-0.042-0.032-0.071-0.046c-0.028-0.013-0.065-0.027-0.108-0.041
			c-0.058-0.021-0.107-0.041-0.149-0.064c-0.042-0.022-0.077-0.049-0.104-0.08c-0.028-0.03-0.05-0.064-0.063-0.105
			c-0.013-0.04-0.02-0.086-0.02-0.139c0-0.127,0.034-0.222,0.105-0.284s0.168-0.094,0.291-0.094c0.059,0,0.111,0.007,0.16,0.019
			c0.048,0.013,0.09,0.033,0.125,0.061c0.036,0.029,0.063,0.065,0.083,0.108c0.02,0.044,0.029,0.096,0.029,0.157v0.036h-0.247
			c0-0.061-0.013-0.107-0.033-0.14c-0.021-0.034-0.059-0.05-0.108-0.05c-0.027,0-0.053,0.004-0.07,0.013
			c-0.021,0.008-0.035,0.019-0.048,0.033c-0.011,0.014-0.019,0.029-0.022,0.047s-0.006,0.037-0.006,0.056
			c0,0.04,0.009,0.073,0.024,0.1c0.018,0.027,0.054,0.052,0.108,0.074l0.199,0.087c0.05,0.021,0.09,0.044,0.121,0.068
			c0.03,0.023,0.056,0.048,0.075,0.075c0.018,0.027,0.03,0.057,0.037,0.089c0.008,0.032,0.012,0.068,0.012,0.108
			c0,0.135-0.039,0.234-0.118,0.295c-0.078,0.062-0.188,0.093-0.328,0.093c-0.146,0-0.251-0.032-0.313-0.095
			c-0.063-0.064-0.095-0.155-0.095-0.274v-0.052h0.259V-17.44z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M-2.459-17.077c0-0.057,0.006-0.109,0.017-0.159
			c0.012-0.049,0.029-0.095,0.053-0.139c0.023-0.045,0.053-0.088,0.089-0.131c0.035-0.042,0.076-0.086,0.123-0.128l0.104-0.097
			c0.029-0.026,0.052-0.049,0.069-0.071c0.018-0.021,0.031-0.043,0.041-0.064c0.009-0.022,0.016-0.045,0.019-0.07
			c0.003-0.026,0.005-0.054,0.005-0.087c0-0.105-0.041-0.158-0.127-0.158c-0.031,0-0.058,0.007-0.075,0.02
			c-0.02,0.013-0.033,0.031-0.044,0.054c-0.01,0.023-0.017,0.049-0.02,0.079c-0.003,0.03-0.004,0.061-0.004,0.095h-0.249v-0.049
			c0-0.118,0.032-0.209,0.096-0.273s0.163-0.095,0.301-0.095c0.129,0,0.225,0.03,0.288,0.09c0.062,0.06,0.093,0.145,0.093,0.257
			c0,0.041-0.003,0.079-0.011,0.114c-0.008,0.035-0.02,0.069-0.034,0.102c-0.017,0.032-0.036,0.064-0.062,0.094
			c-0.024,0.031-0.056,0.062-0.092,0.095l-0.143,0.133c-0.039,0.034-0.069,0.068-0.091,0.101c-0.021,0.034-0.036,0.062-0.042,0.086
			h0.48v0.201H-2.459z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M-1.299-17.261c0.019,0.027,0.048,0.04,0.091,0.04
			c0.035,0,0.063-0.008,0.084-0.024c0.02-0.016,0.035-0.039,0.047-0.067c0.012-0.029,0.019-0.063,0.022-0.103
			c0.004-0.039,0.006-0.083,0.006-0.129v-0.061h-0.005c-0.021,0.032-0.052,0.058-0.092,0.078c-0.04,0.019-0.089,0.029-0.146,0.029
			c-0.057,0-0.104-0.01-0.144-0.029c-0.04-0.02-0.072-0.048-0.096-0.083c-0.025-0.035-0.043-0.077-0.055-0.125
			c-0.012-0.049-0.017-0.102-0.017-0.16c0-0.147,0.032-0.26,0.097-0.338c0.066-0.078,0.17-0.117,0.314-0.117
			c0.089,0,0.16,0.016,0.212,0.05c0.053,0.033,0.094,0.076,0.122,0.131c0.028,0.055,0.046,0.118,0.055,0.19
			c0.008,0.071,0.013,0.146,0.013,0.225c0,0.09-0.004,0.178-0.009,0.263c-0.005,0.084-0.021,0.159-0.049,0.224
			c-0.026,0.064-0.069,0.115-0.127,0.153s-0.14,0.057-0.243,0.057c-0.046,0-0.09-0.004-0.133-0.014
			c-0.042-0.008-0.08-0.026-0.112-0.05c-0.031-0.025-0.059-0.058-0.079-0.099c-0.019-0.041-0.029-0.092-0.03-0.154h0.248
			C-1.325-17.325-1.316-17.288-1.299-17.261 M-1.312-17.744c0.021,0.037,0.058,0.056,0.107,0.056c0.052,0,0.089-0.019,0.109-0.056
			c0.022-0.037,0.033-0.099,0.033-0.185c0-0.036-0.002-0.069-0.005-0.101c-0.004-0.031-0.011-0.058-0.021-0.079
			c-0.01-0.023-0.024-0.04-0.044-0.053c-0.018-0.013-0.042-0.019-0.072-0.019c-0.031,0-0.056,0.006-0.074,0.019
			c-0.02,0.013-0.033,0.031-0.042,0.053c-0.011,0.023-0.017,0.049-0.02,0.08c-0.003,0.031-0.004,0.064-0.004,0.1
			C-1.344-17.843-1.333-17.781-1.312-17.744"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M0.058-18.126c-0.053,0.074-0.101,0.154-0.142,0.239
			c-0.041,0.085-0.077,0.173-0.108,0.262c-0.031,0.09-0.058,0.182-0.078,0.275c-0.019,0.093-0.036,0.184-0.046,0.274h-0.272
			c0.011-0.077,0.024-0.158,0.044-0.242c0.02-0.085,0.045-0.171,0.076-0.258c0.032-0.087,0.07-0.176,0.116-0.264
			c0.045-0.089,0.099-0.176,0.161-0.261h-0.535v-0.223h0.784V-18.126z"
          />
          <rect
            x="2.109"
            y="-18.793"
            clipPath="url(#SVGID_12_)"
            fill="#60C1E5"
            width="7.646"
            height="7.645"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M2.902-18.361c0.066,0,0.121,0.011,0.166,0.034
			c0.045,0.022,0.082,0.051,0.109,0.087s0.047,0.077,0.06,0.123c0.012,0.046,0.019,0.093,0.019,0.141
			c0,0.066-0.012,0.124-0.03,0.173c-0.021,0.049-0.048,0.089-0.083,0.12c-0.035,0.031-0.079,0.055-0.128,0.07
			c-0.051,0.016-0.107,0.024-0.168,0.024H2.708v0.512H2.449v-1.284H2.902z M2.823-17.78c0.051,0,0.094-0.015,0.125-0.047
			c0.032-0.031,0.048-0.079,0.048-0.145c0-0.065-0.014-0.114-0.042-0.148c-0.027-0.033-0.072-0.05-0.139-0.05H2.708v0.39H2.823z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="3.772,-18.361 3.955,-17.454 3.958,-17.454 4.144,-18.361
			4.528,-18.361 4.528,-17.077 4.29,-17.077 4.29,-18.102 4.287,-18.102 4.058,-17.077 3.857,-17.077 3.629,-18.102 3.625,-18.102
			3.625,-17.077 3.388,-17.077 3.388,-18.361 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M4.921-17.44c0,0.03,0.002,0.058,0.007,0.082
			c0.005,0.025,0.014,0.045,0.027,0.062c0.013,0.016,0.03,0.03,0.053,0.039c0.021,0.01,0.05,0.015,0.084,0.015
			c0.039,0,0.075-0.013,0.107-0.039c0.03-0.025,0.047-0.065,0.047-0.119c0-0.029-0.004-0.054-0.013-0.075
			c-0.007-0.021-0.02-0.04-0.038-0.057c-0.018-0.016-0.042-0.032-0.07-0.046c-0.029-0.013-0.066-0.027-0.109-0.041
			c-0.057-0.02-0.107-0.041-0.148-0.064c-0.043-0.022-0.077-0.049-0.105-0.08c-0.028-0.03-0.049-0.065-0.063-0.106
			c-0.013-0.04-0.02-0.086-0.02-0.139c0-0.127,0.035-0.222,0.106-0.284c0.07-0.062,0.168-0.094,0.29-0.094
			c0.059,0,0.111,0.007,0.16,0.02c0.049,0.013,0.09,0.033,0.125,0.061c0.036,0.029,0.063,0.065,0.083,0.108
			c0.021,0.044,0.029,0.096,0.029,0.157v0.036H5.227c0-0.061-0.012-0.107-0.033-0.14c-0.021-0.034-0.058-0.05-0.107-0.05
			c-0.028,0-0.053,0.004-0.071,0.013c-0.02,0.008-0.035,0.019-0.047,0.033c-0.011,0.014-0.019,0.029-0.023,0.047
			c-0.004,0.018-0.006,0.037-0.006,0.056c0,0.04,0.008,0.073,0.025,0.1c0.017,0.027,0.053,0.052,0.107,0.074l0.199,0.087
			c0.05,0.021,0.09,0.044,0.121,0.068c0.031,0.023,0.056,0.048,0.075,0.075c0.018,0.027,0.031,0.057,0.038,0.089
			c0.007,0.032,0.011,0.068,0.011,0.108c0,0.135-0.039,0.234-0.118,0.295C5.32-17.088,5.21-17.057,5.07-17.057
			c-0.146,0-0.251-0.032-0.313-0.095c-0.063-0.064-0.095-0.155-0.095-0.274v-0.052h0.259V-17.44z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M6.019-17.077c0-0.057,0.007-0.109,0.018-0.159
			c0.011-0.049,0.028-0.095,0.052-0.139c0.024-0.045,0.053-0.088,0.089-0.131c0.035-0.042,0.076-0.086,0.123-0.128l0.104-0.097
			c0.028-0.026,0.052-0.049,0.068-0.071c0.019-0.021,0.031-0.043,0.041-0.064c0.01-0.022,0.017-0.045,0.02-0.07
			c0.002-0.026,0.004-0.054,0.004-0.087c0-0.105-0.041-0.158-0.126-0.158c-0.032,0-0.059,0.007-0.076,0.02
			c-0.019,0.013-0.033,0.031-0.044,0.054c-0.009,0.023-0.016,0.049-0.02,0.079c-0.003,0.03-0.004,0.062-0.004,0.095H6.019v-0.049
			c0-0.118,0.032-0.209,0.097-0.273c0.063-0.064,0.163-0.095,0.3-0.095c0.129,0,0.225,0.03,0.288,0.09
			c0.062,0.06,0.093,0.145,0.093,0.257c0,0.041-0.003,0.079-0.011,0.114c-0.007,0.035-0.02,0.069-0.034,0.102
			c-0.017,0.032-0.036,0.064-0.062,0.094c-0.024,0.031-0.056,0.062-0.092,0.095l-0.143,0.133c-0.039,0.034-0.069,0.068-0.09,0.101
			c-0.022,0.034-0.036,0.061-0.042,0.086h0.479v0.201H6.019z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M7.18-17.261c0.018,0.027,0.048,0.04,0.091,0.04
			c0.035,0,0.062-0.008,0.083-0.024c0.02-0.016,0.036-0.039,0.047-0.067c0.012-0.029,0.019-0.063,0.023-0.103
			c0.004-0.039,0.006-0.083,0.006-0.129v-0.061H7.424c-0.021,0.032-0.052,0.058-0.092,0.078c-0.04,0.019-0.089,0.029-0.146,0.029
			c-0.058,0-0.104-0.01-0.145-0.029c-0.04-0.02-0.071-0.048-0.096-0.083c-0.025-0.035-0.043-0.077-0.054-0.125
			c-0.012-0.049-0.018-0.102-0.018-0.16c0-0.147,0.032-0.26,0.098-0.338c0.065-0.078,0.17-0.117,0.313-0.117
			c0.089,0,0.16,0.016,0.212,0.05c0.053,0.033,0.094,0.076,0.122,0.131c0.028,0.055,0.046,0.118,0.056,0.19
			c0.008,0.071,0.012,0.146,0.012,0.225c0,0.09-0.003,0.178-0.008,0.263c-0.006,0.084-0.021,0.159-0.049,0.224
			c-0.026,0.064-0.069,0.115-0.128,0.153c-0.058,0.038-0.14,0.057-0.242,0.057c-0.046,0-0.091-0.004-0.134-0.014
			c-0.042-0.008-0.08-0.026-0.111-0.05c-0.032-0.025-0.06-0.058-0.079-0.099s-0.03-0.092-0.031-0.154h0.248
			C7.154-17.325,7.163-17.288,7.18-17.261 M7.167-17.744c0.022,0.037,0.059,0.056,0.107,0.056c0.052,0,0.089-0.019,0.109-0.056
			c0.022-0.037,0.033-0.099,0.033-0.185c0-0.036-0.002-0.069-0.005-0.101c-0.004-0.031-0.011-0.058-0.021-0.079
			c-0.01-0.023-0.025-0.04-0.044-0.053c-0.019-0.013-0.042-0.019-0.073-0.019S7.219-18.175,7.2-18.162
			c-0.019,0.013-0.033,0.031-0.042,0.053c-0.01,0.023-0.016,0.049-0.019,0.08c-0.003,0.031-0.004,0.064-0.004,0.1
			C7.135-17.843,7.145-17.781,7.167-17.744"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M8.537-18.126c-0.053,0.074-0.1,0.154-0.141,0.239s-0.078,0.173-0.109,0.262
			c-0.03,0.09-0.057,0.182-0.078,0.275c-0.019,0.093-0.036,0.184-0.046,0.274H7.891c0.01-0.077,0.023-0.158,0.044-0.242
			c0.019-0.085,0.044-0.171,0.076-0.258c0.031-0.087,0.069-0.176,0.115-0.264c0.045-0.089,0.1-0.176,0.161-0.261H7.752v-0.223h0.784
			V-18.126z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            d="M-6.313-10.213c0.007-0.062,0.021-0.115,0.045-0.159
			c0.022-0.044,0.056-0.079,0.099-0.103c0.043-0.025,0.102-0.037,0.174-0.037c0.073,0,0.131,0.012,0.175,0.037
			c0.043,0.024,0.075,0.059,0.099,0.103s0.038,0.097,0.044,0.159c0.007,0.061,0.011,0.128,0.011,0.201
			c0,0.074-0.004,0.141-0.011,0.202c-0.006,0.061-0.021,0.114-0.044,0.158c-0.023,0.044-0.056,0.077-0.099,0.101
			c-0.044,0.024-0.102,0.035-0.175,0.035c-0.072,0-0.131-0.011-0.174-0.035c-0.043-0.024-0.076-0.057-0.099-0.101
			C-6.292-9.696-6.306-9.749-6.313-9.81s-0.011-0.128-0.011-0.202C-6.324-10.085-6.32-10.152-6.313-10.213 M-6.125-9.847
			c0.004,0.046,0.01,0.083,0.02,0.111c0.009,0.028,0.022,0.049,0.041,0.062c0.017,0.013,0.04,0.019,0.068,0.019
			c0.029,0,0.053-0.006,0.069-0.019c0.019-0.013,0.032-0.034,0.041-0.062c0.01-0.028,0.015-0.065,0.019-0.111
			c0.004-0.045,0.005-0.1,0.005-0.165s-0.001-0.12-0.005-0.164c-0.004-0.045-0.009-0.082-0.019-0.111
			c-0.009-0.029-0.022-0.05-0.041-0.063c-0.017-0.013-0.04-0.019-0.069-0.019c-0.028,0-0.052,0.006-0.068,0.019
			c-0.019,0.013-0.032,0.034-0.041,0.063c-0.01,0.029-0.016,0.066-0.02,0.111c-0.003,0.044-0.005,0.099-0.005,0.164
			S-6.127-9.892-6.125-9.847"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            points="-5.273,-9.782 -5.267,-9.782 -5.125,-10.494 -4.924,-10.494
			-5.146,-9.531 -5.394,-9.531 -5.617,-10.494 -5.409,-10.494 		"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            points="-4.315,-10.334 -4.659,-10.334 -4.659,-10.107 -4.335,-10.107
			-4.335,-9.949 -4.659,-9.949 -4.659,-9.689 -4.302,-9.689 -4.302,-9.53 -4.853,-9.53 -4.853,-10.493 -4.315,-10.493 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            d="M-3.845-10.494c0.081,0,0.143,0.02,0.188,0.061s0.068,0.104,0.068,0.187
			c0,0.065-0.014,0.118-0.039,0.159c-0.026,0.041-0.067,0.067-0.122,0.079v0.002c0.049,0.008,0.084,0.023,0.106,0.048
			c0.022,0.024,0.036,0.063,0.043,0.117C-3.6-9.823-3.598-9.803-3.597-9.783c0.001,0.021,0.002,0.045,0.002,0.071
			C-3.593-9.66-3.59-9.621-3.586-9.595c0.005,0.028,0.017,0.046,0.035,0.056v0.008h-0.211c-0.011-0.013-0.017-0.029-0.02-0.046
			c-0.002-0.018-0.004-0.037-0.005-0.056l-0.005-0.184c-0.002-0.038-0.012-0.067-0.029-0.089c-0.017-0.021-0.046-0.032-0.086-0.032
			H-4.01v0.407h-0.194v-0.963H-3.845z M-3.929-10.073c0.047,0,0.083-0.011,0.107-0.033c0.025-0.022,0.038-0.059,0.038-0.111
			c0-0.09-0.045-0.134-0.135-0.134H-4.01v0.278H-3.929z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            points="-3.455,-10.494 -3.26,-10.494 -3.26,-9.689 -2.917,-9.689
			-2.917,-9.531 -3.455,-9.531 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            d="M-2.84-10.213c0.007-0.062,0.021-0.115,0.045-0.159
			c0.021-0.044,0.056-0.079,0.099-0.103c0.043-0.025,0.102-0.037,0.174-0.037s0.13,0.012,0.174,0.037
			c0.043,0.024,0.076,0.059,0.1,0.103c0.022,0.044,0.038,0.097,0.044,0.159c0.007,0.061,0.011,0.128,0.011,0.201
			c0,0.074-0.004,0.141-0.011,0.202C-2.211-9.749-2.227-9.696-2.25-9.652c-0.023,0.044-0.057,0.077-0.1,0.101
			c-0.044,0.024-0.102,0.035-0.174,0.035s-0.131-0.011-0.174-0.035C-2.74-9.575-2.774-9.608-2.795-9.652
			C-2.819-9.696-2.833-9.749-2.84-9.81s-0.011-0.128-0.011-0.202C-2.851-10.085-2.847-10.152-2.84-10.213 M-2.652-9.847
			c0.003,0.046,0.009,0.083,0.02,0.111c0.009,0.028,0.022,0.049,0.04,0.062c0.018,0.013,0.041,0.019,0.069,0.019
			c0.029,0,0.053-0.006,0.069-0.019c0.017-0.013,0.031-0.034,0.041-0.062c0.009-0.028,0.015-0.065,0.019-0.111
			c0.004-0.045,0.004-0.1,0.004-0.165s0-0.12-0.004-0.164c-0.004-0.045-0.01-0.082-0.019-0.111c-0.01-0.029-0.024-0.05-0.041-0.063
			s-0.04-0.019-0.069-0.019c-0.028,0-0.052,0.006-0.069,0.019s-0.031,0.034-0.04,0.063c-0.011,0.029-0.017,0.066-0.02,0.111
			c-0.003,0.044-0.005,0.099-0.005,0.164S-2.655-9.892-2.652-9.847"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            d="M-1.694-10.322c-0.018-0.032-0.048-0.048-0.092-0.048
			c-0.023,0-0.044,0.007-0.06,0.02s-0.028,0.034-0.037,0.063c-0.009,0.029-0.015,0.067-0.019,0.115
			c-0.005,0.047-0.007,0.104-0.007,0.173c0,0.073,0.004,0.132,0.009,0.176c0.005,0.046,0.013,0.08,0.022,0.105
			c0.01,0.025,0.023,0.042,0.039,0.05c0.016,0.009,0.034,0.013,0.055,0.013c0.018,0,0.033-0.003,0.049-0.009
			c0.014-0.006,0.026-0.017,0.037-0.035c0.012-0.018,0.02-0.042,0.026-0.074c0.006-0.032,0.009-0.074,0.009-0.126h0.194
			c0,0.052-0.004,0.102-0.012,0.148c-0.009,0.047-0.023,0.088-0.045,0.123c-0.022,0.035-0.054,0.063-0.094,0.082
			c-0.04,0.02-0.092,0.03-0.155,0.03c-0.073,0-0.131-0.012-0.174-0.035c-0.043-0.024-0.076-0.057-0.1-0.103
			C-2.069-9.696-2.084-9.749-2.09-9.81C-2.097-9.871-2.1-9.938-2.1-10.013c0-0.073,0.003-0.14,0.01-0.201
			c0.006-0.061,0.021-0.115,0.044-0.158c0.023-0.044,0.057-0.079,0.1-0.104c0.043-0.024,0.101-0.037,0.174-0.037
			c0.069,0,0.124,0.012,0.164,0.034s0.069,0.051,0.09,0.086c0.02,0.034,0.031,0.071,0.037,0.111
			c0.005,0.041,0.007,0.078,0.007,0.113h-0.194C-1.669-10.239-1.677-10.29-1.694-10.322"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            points="-1.163,-10.108 -1.16,-10.108 -0.915,-10.494 -0.705,-10.494
			-0.966,-10.081 -0.674,-9.531 -0.892,-9.531 -1.091,-9.926 -1.163,-9.817 -1.163,-9.531 -1.357,-9.531 -1.357,-10.494
			-1.163,-10.494 		"
          />
          <rect
            x="-0.623"
            y="-10.494"
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            width="0.195"
            height="0.963"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            points="0.174,-9.834 0.177,-9.834 0.177,-10.494 0.354,-10.494
			0.354,-9.531 0.134,-9.531 -0.104,-10.205 -0.106,-10.205 -0.106,-9.531 -0.285,-9.531 -0.285,-10.494 -0.061,-10.494 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            d="M0.911-10.263c-0.004-0.019-0.01-0.036-0.02-0.052
			c-0.008-0.015-0.021-0.029-0.034-0.039c-0.014-0.011-0.031-0.016-0.05-0.016c-0.047,0-0.081,0.026-0.103,0.079
			c-0.021,0.052-0.029,0.138-0.029,0.259c0,0.057,0.001,0.109,0.004,0.156C0.683-9.829,0.69-9.79,0.699-9.757
			c0.01,0.033,0.023,0.058,0.041,0.076c0.017,0.017,0.04,0.026,0.068,0.026c0.011,0,0.023-0.003,0.038-0.009
			C0.86-9.671,0.873-9.68,0.885-9.693c0.013-0.013,0.021-0.028,0.03-0.048C0.924-9.76,0.928-9.783,0.928-9.809v-0.098H0.799v-0.143
			h0.314v0.519H0.971V-9.62H0.968C0.945-9.582,0.917-9.555,0.884-9.54C0.851-9.524,0.812-9.516,0.766-9.516
			c-0.061,0-0.108-0.01-0.146-0.031C0.583-9.568,0.555-9.6,0.533-9.643c-0.021-0.043-0.035-0.096-0.042-0.16
			C0.484-9.866,0.48-9.939,0.48-10.023c0-0.081,0.005-0.152,0.016-0.213s0.028-0.112,0.054-0.153
			c0.024-0.041,0.058-0.072,0.099-0.092c0.042-0.021,0.094-0.032,0.156-0.032c0.105,0,0.182,0.027,0.229,0.079
			c0.047,0.053,0.07,0.128,0.07,0.226H0.917C0.917-10.226,0.915-10.244,0.911-10.263"
          />
          <rect
            x="10.689"
            y="-18.793"
            clipPath="url(#SVGID_12_)"
            fill="#00ADEE"
            width="7.646"
            height="7.645"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M11.483-18.361c0.065,0,0.121,0.011,0.166,0.034
			c0.045,0.022,0.082,0.051,0.108,0.087c0.028,0.036,0.047,0.077,0.061,0.123c0.011,0.046,0.018,0.093,0.018,0.141
			c0,0.066-0.011,0.124-0.03,0.173c-0.02,0.049-0.048,0.089-0.083,0.12c-0.034,0.031-0.079,0.054-0.128,0.07
			c-0.051,0.016-0.106,0.024-0.168,0.024h-0.139v0.512H11.03v-1.284H11.483z M11.404-17.78c0.051,0,0.093-0.016,0.125-0.047
			c0.032-0.031,0.048-0.079,0.048-0.145c0-0.065-0.014-0.114-0.042-0.148s-0.074-0.05-0.14-0.05h-0.107v0.39H11.404z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="12.352,-18.36 12.536,-17.454 12.54,-17.454 12.724,-18.36
			13.109,-18.36 13.109,-17.076 12.871,-17.076 12.871,-18.101 12.868,-18.101 12.638,-17.076 12.438,-17.076 12.209,-18.101
			12.206,-18.101 12.206,-17.076 11.968,-17.076 11.968,-18.36 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M13.501-17.44c0,0.03,0.002,0.057,0.007,0.082
			c0.006,0.025,0.015,0.045,0.027,0.062c0.013,0.016,0.031,0.03,0.053,0.039c0.022,0.01,0.051,0.015,0.084,0.015
			c0.039,0,0.075-0.013,0.107-0.039c0.031-0.026,0.047-0.065,0.047-0.119c0-0.029-0.004-0.054-0.012-0.075
			c-0.007-0.021-0.021-0.04-0.038-0.057c-0.019-0.016-0.042-0.032-0.071-0.046c-0.029-0.013-0.066-0.027-0.109-0.042
			c-0.057-0.019-0.106-0.04-0.148-0.063c-0.042-0.021-0.077-0.049-0.105-0.08c-0.027-0.03-0.049-0.066-0.062-0.106
			s-0.021-0.086-0.021-0.139c0-0.127,0.035-0.222,0.106-0.284c0.071-0.063,0.168-0.094,0.291-0.094c0.058,0,0.111,0.007,0.16,0.019
			c0.048,0.013,0.09,0.033,0.125,0.061c0.036,0.029,0.063,0.065,0.083,0.108c0.02,0.044,0.029,0.096,0.029,0.157v0.036h-0.247
			c0-0.061-0.013-0.108-0.033-0.141c-0.021-0.033-0.059-0.049-0.108-0.049c-0.027,0-0.053,0.004-0.07,0.012
			c-0.021,0.009-0.035,0.021-0.048,0.034c-0.011,0.014-0.019,0.029-0.022,0.047c-0.004,0.018-0.006,0.037-0.006,0.056
			c0,0.04,0.008,0.073,0.024,0.1c0.018,0.027,0.054,0.052,0.108,0.074l0.199,0.087c0.05,0.021,0.09,0.044,0.121,0.067
			c0.03,0.024,0.056,0.049,0.074,0.076c0.019,0.027,0.031,0.056,0.038,0.089c0.007,0.032,0.011,0.068,0.011,0.108
			c0,0.135-0.038,0.234-0.117,0.295c-0.078,0.062-0.188,0.093-0.328,0.093c-0.146,0-0.251-0.032-0.314-0.095
			c-0.062-0.064-0.094-0.155-0.094-0.274v-0.052h0.259V-17.44z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M11.483-16.9c0.065,0,0.121,0.011,0.166,0.034
			c0.045,0.022,0.082,0.051,0.108,0.087c0.028,0.036,0.047,0.077,0.061,0.123c0.011,0.046,0.018,0.093,0.018,0.141
			c0,0.066-0.011,0.124-0.03,0.173s-0.048,0.089-0.083,0.12c-0.034,0.031-0.079,0.054-0.128,0.07
			c-0.051,0.016-0.106,0.024-0.168,0.024h-0.139v0.512H11.03V-16.9H11.483z M11.404-16.319c0.051,0,0.093-0.016,0.125-0.047
			c0.032-0.031,0.048-0.079,0.048-0.145c0-0.065-0.014-0.114-0.042-0.148c-0.028-0.033-0.074-0.05-0.14-0.05h-0.107v0.39H11.404z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M12.193-16.583v0.129h0.004c0.024-0.046,0.055-0.084,0.091-0.112
			c0.036-0.028,0.08-0.042,0.132-0.042c0.007,0,0.015,0,0.022,0.001c0.008,0,0.015,0.001,0.021,0.002v0.238
			c-0.013-0.002-0.025-0.002-0.038-0.003c-0.013,0-0.025-0.001-0.038-0.001c-0.021,0-0.044,0.003-0.065,0.009
			c-0.021,0.006-0.041,0.016-0.06,0.03c-0.018,0.013-0.031,0.031-0.043,0.054c-0.011,0.022-0.017,0.049-0.017,0.082v0.58h-0.247
			v-0.967H12.193z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M12.542-16.319c0.014-0.063,0.035-0.116,0.064-0.159s0.069-0.075,0.118-0.097
			c0.048-0.022,0.108-0.033,0.179-0.033c0.074-0.001,0.137,0.008,0.186,0.029s0.088,0.053,0.117,0.096
			c0.028,0.044,0.048,0.097,0.061,0.16c0.012,0.064,0.019,0.138,0.019,0.223c0,0.083-0.007,0.156-0.021,0.22
			c-0.014,0.063-0.035,0.116-0.064,0.159c-0.03,0.043-0.069,0.074-0.118,0.094c-0.048,0.02-0.108,0.031-0.179,0.031
			c-0.074,0.001-0.137-0.008-0.186-0.028c-0.049-0.021-0.088-0.051-0.117-0.094c-0.027-0.042-0.049-0.095-0.061-0.16
			c-0.012-0.064-0.018-0.138-0.018-0.222C12.522-16.182,12.529-16.256,12.542-16.319 M12.774-15.963
			c0.002,0.042,0.008,0.078,0.017,0.108c0.009,0.03,0.022,0.053,0.04,0.07c0.019,0.017,0.043,0.025,0.072,0.025
			c0.05,0,0.086-0.026,0.104-0.079c0.02-0.053,0.029-0.14,0.029-0.261c0-0.121-0.01-0.208-0.029-0.26
			c-0.019-0.053-0.055-0.079-0.104-0.079c-0.029,0-0.054,0.008-0.072,0.025c-0.018,0.016-0.031,0.04-0.04,0.07
			s-0.015,0.066-0.017,0.108c-0.004,0.041-0.004,0.087-0.004,0.136C12.77-16.051,12.77-16.005,12.774-15.963"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M13.878-16.305c-0.003-0.022-0.008-0.043-0.017-0.062
			c-0.008-0.018-0.019-0.033-0.032-0.045c-0.016-0.011-0.034-0.017-0.059-0.017c-0.029,0-0.054,0.007-0.073,0.021
			c-0.02,0.014-0.033,0.034-0.043,0.061c-0.011,0.027-0.017,0.061-0.021,0.102c-0.003,0.042-0.005,0.091-0.005,0.147
			c0,0.06,0.002,0.111,0.005,0.153c0.004,0.043,0.01,0.078,0.021,0.106c0.01,0.028,0.022,0.047,0.041,0.06
			c0.017,0.012,0.039,0.019,0.066,0.019c0.042,0,0.072-0.018,0.091-0.054c0.021-0.036,0.03-0.094,0.03-0.173h0.237
			c0,0.127-0.028,0.224-0.086,0.291c-0.058,0.066-0.151,0.099-0.283,0.099c-0.063,0-0.117-0.007-0.163-0.022
			c-0.046-0.015-0.084-0.042-0.115-0.081c-0.03-0.039-0.053-0.091-0.068-0.156c-0.016-0.066-0.023-0.147-0.023-0.244
			c0-0.099,0.009-0.182,0.029-0.247c0.019-0.065,0.046-0.118,0.081-0.157c0.033-0.038,0.075-0.066,0.124-0.082
			c0.048-0.015,0.1-0.023,0.157-0.023c0.114,0,0.202,0.034,0.261,0.101c0.058,0.067,0.087,0.158,0.087,0.273h-0.237
			C13.883-16.258,13.881-16.282,13.878-16.305"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M14.461-15.984c0,0.029,0.002,0.056,0.007,0.083
			c0.004,0.027,0.011,0.052,0.021,0.072c0.009,0.021,0.022,0.038,0.037,0.051c0.016,0.012,0.035,0.018,0.058,0.018
			c0.042,0,0.073-0.014,0.093-0.044c0.019-0.031,0.031-0.076,0.04-0.137h0.222c-0.005,0.113-0.035,0.198-0.09,0.257
			c-0.056,0.059-0.143,0.088-0.26,0.088c-0.091,0-0.16-0.015-0.212-0.045c-0.05-0.03-0.087-0.069-0.11-0.119
			c-0.024-0.049-0.039-0.104-0.044-0.165c-0.006-0.061-0.009-0.122-0.009-0.183c0-0.065,0.005-0.127,0.014-0.187
			s0.027-0.114,0.054-0.16c0.027-0.047,0.066-0.084,0.116-0.112c0.05-0.027,0.117-0.041,0.201-0.041
			c0.072,0,0.132,0.012,0.177,0.035c0.047,0.023,0.083,0.057,0.11,0.099c0.025,0.042,0.044,0.094,0.053,0.154
			c0.01,0.061,0.015,0.129,0.015,0.203v0.055h-0.491V-15.984z M14.708-16.307c-0.004-0.028-0.009-0.052-0.019-0.072
			c-0.009-0.02-0.021-0.035-0.038-0.045c-0.016-0.01-0.037-0.015-0.063-0.015c-0.024,0-0.045,0.006-0.062,0.017
			c-0.015,0.012-0.029,0.026-0.038,0.045c-0.01,0.018-0.017,0.039-0.021,0.062c-0.005,0.023-0.007,0.045-0.007,0.068v0.038h0.255
			C14.713-16.246,14.711-16.279,14.708-16.307"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M15.264-15.9c0,0.042,0.011,0.076,0.032,0.102
			c0.022,0.025,0.054,0.038,0.097,0.038c0.039,0,0.068-0.009,0.092-0.029c0.022-0.019,0.033-0.048,0.033-0.086
			c0-0.031-0.009-0.055-0.026-0.071c-0.019-0.016-0.039-0.029-0.063-0.039l-0.174-0.062c-0.067-0.024-0.12-0.058-0.154-0.102
			s-0.053-0.1-0.053-0.168c0-0.04,0.007-0.077,0.021-0.113c0.013-0.035,0.034-0.066,0.063-0.092
			c0.029-0.027,0.066-0.047,0.111-0.063s0.099-0.023,0.162-0.023c0.112,0,0.196,0.023,0.25,0.072
			c0.054,0.047,0.081,0.115,0.081,0.203v0.039h-0.224c0-0.05-0.008-0.087-0.023-0.11c-0.017-0.024-0.045-0.035-0.086-0.035
			c-0.031,0-0.059,0.008-0.081,0.026c-0.024,0.017-0.035,0.043-0.035,0.078c0,0.024,0.007,0.045,0.021,0.065
			c0.016,0.019,0.043,0.035,0.086,0.048l0.149,0.051c0.077,0.026,0.133,0.06,0.165,0.103c0.033,0.043,0.049,0.1,0.049,0.173
			c0,0.052-0.009,0.097-0.026,0.134c-0.019,0.038-0.043,0.07-0.075,0.095c-0.032,0.025-0.07,0.043-0.113,0.054
			c-0.044,0.01-0.092,0.016-0.145,0.016c-0.069,0-0.127-0.007-0.174-0.02c-0.046-0.013-0.082-0.033-0.109-0.059
			c-0.027-0.027-0.046-0.059-0.057-0.096c-0.012-0.038-0.017-0.08-0.017-0.127v-0.034h0.224V-15.9z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M16.062-15.9c0,0.042,0.011,0.076,0.032,0.102
			c0.023,0.025,0.056,0.038,0.097,0.038c0.039,0,0.068-0.009,0.092-0.029c0.022-0.019,0.034-0.048,0.034-0.086
			c0-0.031-0.008-0.055-0.026-0.071c-0.018-0.016-0.039-0.029-0.063-0.039l-0.175-0.062c-0.067-0.024-0.12-0.058-0.153-0.102
			c-0.035-0.044-0.053-0.1-0.053-0.168c0-0.04,0.006-0.077,0.02-0.113c0.014-0.035,0.035-0.066,0.063-0.092
			c0.029-0.027,0.066-0.047,0.111-0.063s0.099-0.023,0.162-0.023c0.113,0,0.196,0.023,0.25,0.072
			c0.054,0.047,0.081,0.115,0.081,0.203v0.039h-0.222c0-0.05-0.01-0.087-0.025-0.11c-0.016-0.024-0.045-0.035-0.085-0.035
			c-0.032,0-0.06,0.008-0.082,0.026c-0.024,0.017-0.035,0.043-0.035,0.078c0,0.024,0.007,0.045,0.022,0.065
			c0.015,0.019,0.044,0.035,0.085,0.048l0.149,0.051c0.078,0.026,0.133,0.06,0.165,0.103c0.033,0.043,0.049,0.1,0.049,0.173
			c0,0.052-0.009,0.097-0.026,0.134c-0.019,0.038-0.043,0.07-0.075,0.095c-0.031,0.025-0.069,0.043-0.113,0.054
			c-0.044,0.01-0.092,0.016-0.145,0.016c-0.069,0-0.127-0.007-0.173-0.02c-0.047-0.013-0.083-0.033-0.11-0.059
			c-0.027-0.027-0.046-0.059-0.057-0.096c-0.011-0.038-0.017-0.08-0.017-0.127v-0.034h0.224V-15.9z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M11.544-15.209c-0.023-0.042-0.063-0.064-0.121-0.064
			c-0.033,0-0.059,0.009-0.08,0.026c-0.021,0.018-0.038,0.046-0.05,0.085s-0.021,0.09-0.025,0.153
			c-0.005,0.063-0.007,0.139-0.007,0.231c0,0.097,0.003,0.175,0.01,0.235s0.018,0.107,0.03,0.139
			c0.014,0.034,0.031,0.056,0.052,0.067c0.022,0.011,0.046,0.017,0.073,0.017c0.023,0,0.044-0.004,0.064-0.012
			c0.02-0.007,0.037-0.023,0.051-0.046c0.015-0.024,0.025-0.057,0.034-0.099s0.013-0.099,0.013-0.168h0.259
			c0,0.069-0.005,0.135-0.016,0.197c-0.011,0.063-0.031,0.117-0.061,0.164c-0.029,0.047-0.07,0.084-0.124,0.11
			c-0.054,0.026-0.123,0.039-0.208,0.039c-0.097,0-0.175-0.015-0.231-0.046c-0.059-0.031-0.103-0.076-0.132-0.135
			c-0.031-0.059-0.052-0.129-0.061-0.211c-0.009-0.081-0.013-0.171-0.013-0.269c0-0.097,0.004-0.187,0.013-0.269
			s0.029-0.153,0.061-0.211c0.029-0.059,0.073-0.105,0.132-0.138c0.057-0.033,0.135-0.049,0.231-0.049
			c0.093,0,0.165,0.015,0.219,0.045c0.054,0.03,0.093,0.068,0.12,0.114c0.025,0.046,0.043,0.095,0.049,0.149
			c0.007,0.053,0.01,0.104,0.01,0.15h-0.259C11.578-15.099,11.567-15.167,11.544-15.209"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M12.052-14.024c0.046,0,0.078-0.012,0.098-0.036
			c0.021-0.024,0.031-0.056,0.035-0.095l-0.271-0.968h0.259l0.143,0.669h0.004l0.126-0.669h0.248L12.46-14.22
			c-0.021,0.081-0.042,0.145-0.062,0.195c-0.02,0.05-0.044,0.088-0.07,0.115c-0.027,0.027-0.06,0.045-0.098,0.053
			c-0.038,0.009-0.086,0.013-0.143,0.013h-0.102v-0.185C12.009-14.026,12.03-14.024,12.052-14.024"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M12.802-14.849c0-0.056,0.009-0.103,0.026-0.141
			c0.019-0.038,0.043-0.068,0.073-0.092c0.03-0.023,0.066-0.04,0.108-0.05c0.041-0.01,0.085-0.015,0.132-0.015
			c0.074,0,0.134,0.007,0.18,0.021c0.045,0.015,0.08,0.035,0.106,0.061c0.024,0.027,0.042,0.058,0.051,0.095
			c0.009,0.036,0.013,0.076,0.013,0.119v0.509c0,0.046,0.003,0.081,0.007,0.106c0.004,0.025,0.013,0.052,0.024,0.081h-0.238
			c-0.008-0.016-0.014-0.032-0.018-0.049c-0.004-0.018-0.009-0.035-0.013-0.051H13.25c-0.027,0.05-0.061,0.082-0.099,0.097
			c-0.038,0.015-0.087,0.023-0.147,0.023c-0.043,0-0.079-0.008-0.109-0.023c-0.029-0.015-0.054-0.035-0.072-0.062
			c-0.018-0.026-0.031-0.056-0.039-0.089s-0.013-0.065-0.013-0.098c0-0.045,0.005-0.085,0.015-0.117
			c0.01-0.034,0.024-0.061,0.044-0.085c0.021-0.023,0.045-0.043,0.076-0.058c0.03-0.016,0.067-0.029,0.11-0.042l0.14-0.037
			c0.037-0.01,0.063-0.023,0.077-0.04c0.015-0.017,0.021-0.041,0.021-0.074c0-0.037-0.009-0.066-0.025-0.087
			c-0.018-0.021-0.047-0.031-0.089-0.031c-0.038,0-0.067,0.011-0.086,0.034c-0.02,0.023-0.029,0.053-0.029,0.092v0.027h-0.223
			V-14.849z M13.201-14.607c-0.019,0.008-0.036,0.013-0.052,0.017c-0.05,0.011-0.086,0.029-0.108,0.054
			c-0.021,0.025-0.031,0.059-0.031,0.102c0,0.038,0.007,0.069,0.021,0.096c0.016,0.026,0.039,0.039,0.072,0.039
			c0.017,0,0.034-0.002,0.052-0.008c0.02-0.005,0.035-0.014,0.05-0.026c0.016-0.012,0.027-0.028,0.037-0.047
			c0.009-0.019,0.014-0.041,0.014-0.068v-0.19C13.238-14.625,13.22-14.615,13.201-14.607"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M13.906-15.122v0.113h0.004c0.026-0.048,0.061-0.083,0.103-0.105
			s0.09-0.033,0.144-0.033c0.079,0,0.14,0.021,0.182,0.065c0.044,0.043,0.064,0.113,0.064,0.21v0.717h-0.248v-0.667
			c0-0.05-0.008-0.085-0.025-0.105c-0.017-0.02-0.044-0.03-0.081-0.03c-0.088,0-0.132,0.053-0.132,0.16v0.642h-0.247v-0.967H13.906z
			"
          />
          <rect
            x="19.25"
            y="-18.793"
            clipPath="url(#SVGID_12_)"
            fill="#D28027"
            width="7.645"
            height="7.646"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M20.043-18.361c0.065,0,0.121,0.011,0.166,0.034
			c0.045,0.022,0.082,0.051,0.108,0.087c0.028,0.036,0.047,0.077,0.061,0.123c0.011,0.046,0.018,0.093,0.018,0.141
			c0,0.066-0.011,0.124-0.03,0.173c-0.02,0.049-0.048,0.089-0.082,0.12c-0.035,0.031-0.079,0.054-0.129,0.07
			c-0.051,0.016-0.106,0.024-0.168,0.024h-0.138v0.512H19.59v-1.284H20.043z M19.964-17.78c0.051,0,0.093-0.016,0.125-0.047
			c0.032-0.031,0.048-0.079,0.048-0.145c0-0.065-0.014-0.114-0.042-0.148s-0.073-0.05-0.139-0.05h-0.107v0.39H19.964z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="20.914,-18.36 21.096,-17.455 21.1,-17.455 21.286,-18.36
			21.669,-18.36 21.669,-17.076 21.431,-17.076 21.431,-18.101 21.428,-18.101 21.2,-17.076 20.999,-17.076 20.77,-18.101
			20.766,-18.101 20.766,-17.076 20.53,-17.076 20.53,-18.36 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M22.062-17.44c0,0.03,0.002,0.057,0.007,0.082
			c0.006,0.025,0.015,0.045,0.027,0.062c0.014,0.016,0.031,0.03,0.053,0.039c0.022,0.01,0.051,0.015,0.084,0.015
			c0.039,0,0.075-0.013,0.107-0.039c0.031-0.026,0.047-0.065,0.047-0.119c0-0.029-0.004-0.054-0.012-0.075
			c-0.007-0.021-0.021-0.04-0.038-0.057c-0.019-0.016-0.042-0.032-0.071-0.046c-0.028-0.013-0.065-0.027-0.108-0.042
			c-0.058-0.019-0.107-0.04-0.149-0.063c-0.042-0.022-0.077-0.049-0.105-0.08c-0.027-0.03-0.049-0.066-0.062-0.105
			c-0.013-0.04-0.02-0.086-0.02-0.139c0-0.127,0.034-0.222,0.105-0.284c0.071-0.063,0.168-0.094,0.291-0.094
			c0.058,0,0.111,0.007,0.16,0.019c0.048,0.013,0.09,0.033,0.125,0.061c0.036,0.029,0.063,0.065,0.083,0.108
			c0.02,0.044,0.029,0.096,0.029,0.157v0.036h-0.247c0-0.061-0.013-0.108-0.033-0.141c-0.021-0.033-0.058-0.049-0.108-0.049
			c-0.027,0-0.053,0.004-0.07,0.012c-0.021,0.009-0.035,0.02-0.047,0.034s-0.02,0.029-0.023,0.047s-0.006,0.037-0.006,0.056
			c0,0.04,0.009,0.073,0.024,0.1c0.018,0.027,0.054,0.052,0.108,0.074l0.199,0.087c0.05,0.021,0.09,0.044,0.121,0.067
			c0.03,0.024,0.056,0.049,0.074,0.076c0.019,0.027,0.031,0.056,0.038,0.089c0.007,0.032,0.012,0.068,0.012,0.108
			c0,0.135-0.039,0.234-0.118,0.295c-0.078,0.062-0.188,0.093-0.328,0.093c-0.146,0-0.251-0.032-0.313-0.095
			c-0.063-0.064-0.096-0.155-0.096-0.274v-0.052h0.259V-17.44z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M23.456-17.976h-0.253v-0.169h0.023c0.087,0,0.153-0.016,0.204-0.049
			c0.05-0.032,0.08-0.084,0.094-0.156h0.19v1.273h-0.259V-17.976z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M24.439-18.35h0.269v0.808h0.122v0.201h-0.122v0.264H24.47v-0.264h-0.454
			v-0.212L24.439-18.35z M24.466-18.026l-0.248,0.484h0.252v-0.484H24.466z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M25.149-18.123l-0.026,0.289h0.004c0.023-0.033,0.055-0.057,0.093-0.072
			c0.039-0.015,0.086-0.023,0.141-0.023c0.061,0,0.111,0.012,0.152,0.035c0.04,0.024,0.072,0.055,0.095,0.093
			c0.023,0.038,0.04,0.082,0.049,0.13c0.01,0.049,0.015,0.098,0.015,0.149c0,0.071-0.005,0.134-0.019,0.192
			c-0.011,0.057-0.032,0.107-0.063,0.148c-0.031,0.042-0.073,0.073-0.125,0.094c-0.054,0.021-0.12,0.031-0.201,0.031
			s-0.146-0.011-0.196-0.032c-0.049-0.022-0.087-0.05-0.113-0.086c-0.026-0.035-0.045-0.073-0.054-0.115
			c-0.009-0.041-0.014-0.08-0.014-0.116v-0.034h0.249c0,0.022,0.001,0.045,0.004,0.069c0.001,0.024,0.007,0.046,0.017,0.067
			c0.009,0.021,0.021,0.039,0.038,0.052c0.018,0.014,0.039,0.021,0.066,0.021c0.054,0,0.093-0.021,0.117-0.062
			c0.022-0.042,0.035-0.104,0.035-0.186c0-0.053-0.004-0.097-0.009-0.132c-0.004-0.034-0.012-0.062-0.022-0.082
			c-0.012-0.021-0.027-0.035-0.047-0.043c-0.02-0.009-0.043-0.013-0.071-0.013c-0.038,0-0.066,0.01-0.086,0.031
			c-0.019,0.022-0.032,0.05-0.037,0.086h-0.231l0.047-0.692h0.664v0.201H25.149z"
          />
          <rect
            x="27.759"
            y="-18.793"
            clipPath="url(#SVGID_12_)"
            fill="#FCBF60"
            width="7.644"
            height="7.646"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M28.551-18.361c0.066,0,0.122,0.011,0.167,0.034
			c0.045,0.022,0.082,0.051,0.109,0.087s0.047,0.077,0.059,0.123s0.02,0.093,0.02,0.141c0,0.066-0.01,0.124-0.031,0.173
			c-0.02,0.049-0.047,0.089-0.082,0.12s-0.078,0.054-0.128,0.07c-0.051,0.016-0.106,0.024-0.168,0.024h-0.138v0.512h-0.26v-1.284
			H28.551z M28.472-17.78c0.052,0,0.093-0.016,0.125-0.047c0.032-0.031,0.048-0.079,0.048-0.145c0-0.065-0.014-0.114-0.041-0.148
			c-0.028-0.034-0.073-0.05-0.139-0.05h-0.107v0.39H28.472z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="29.423,-18.36 29.604,-17.455 29.608,-17.455 29.794,-18.36
			30.177,-18.36 30.177,-17.076 29.94,-17.076 29.94,-18.101 29.937,-18.101 29.708,-17.076 29.507,-17.076 29.278,-18.101
			29.274,-18.101 29.274,-17.076 29.036,-17.076 29.036,-18.36 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M30.571-17.44c0,0.03,0.002,0.057,0.008,0.082
			c0.004,0.025,0.012,0.045,0.025,0.062c0.014,0.016,0.031,0.03,0.053,0.039c0.023,0.01,0.051,0.015,0.084,0.015
			c0.039,0,0.076-0.013,0.107-0.039c0.031-0.026,0.049-0.065,0.049-0.119c0-0.029-0.004-0.054-0.014-0.075
			c-0.008-0.021-0.02-0.04-0.037-0.057c-0.02-0.016-0.041-0.032-0.072-0.046c-0.029-0.013-0.064-0.027-0.109-0.042
			c-0.057-0.019-0.105-0.04-0.148-0.063c-0.041-0.022-0.076-0.049-0.105-0.08c-0.027-0.03-0.049-0.066-0.061-0.105
			c-0.014-0.04-0.021-0.086-0.021-0.139c0-0.127,0.037-0.222,0.107-0.284c0.07-0.063,0.168-0.094,0.291-0.094
			c0.057,0,0.109,0.007,0.158,0.019c0.051,0.013,0.092,0.033,0.127,0.061c0.035,0.029,0.063,0.065,0.082,0.108
			c0.02,0.044,0.029,0.096,0.029,0.157v0.036h-0.248c0-0.061-0.01-0.108-0.033-0.141c-0.02-0.033-0.057-0.049-0.107-0.049
			c-0.027,0-0.051,0.004-0.072,0.012c-0.018,0.009-0.033,0.02-0.045,0.034s-0.02,0.029-0.023,0.047s-0.006,0.037-0.006,0.056
			c0,0.04,0.008,0.073,0.025,0.1c0.016,0.027,0.053,0.052,0.107,0.074l0.199,0.087c0.051,0.021,0.09,0.044,0.121,0.067
			c0.031,0.024,0.057,0.049,0.074,0.076c0.02,0.027,0.031,0.056,0.039,0.089c0.006,0.032,0.01,0.068,0.01,0.108
			c0,0.135-0.039,0.234-0.117,0.295c-0.078,0.062-0.188,0.093-0.328,0.093c-0.146,0-0.252-0.032-0.314-0.095
			c-0.063-0.064-0.094-0.155-0.094-0.274v-0.052h0.26V-17.44z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M31.964-17.976H31.71v-0.169h0.023c0.088,0,0.154-0.016,0.203-0.049
			c0.053-0.032,0.082-0.084,0.096-0.156h0.191v1.273h-0.26V-17.976z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M32.813-17.279c0.018,0.039,0.057,0.058,0.111,0.058
			c0.031,0,0.059-0.005,0.076-0.016c0.018-0.011,0.033-0.025,0.043-0.044c0.01-0.018,0.018-0.04,0.02-0.065
			c0.004-0.026,0.004-0.052,0.004-0.08c0-0.028,0-0.055-0.006-0.081c-0.004-0.026-0.012-0.049-0.023-0.07s-0.029-0.036-0.051-0.048
			c-0.021-0.012-0.051-0.018-0.086-0.018h-0.086v-0.169h0.084c0.027,0,0.051-0.006,0.068-0.017c0.02-0.011,0.033-0.026,0.047-0.045
			c0.01-0.019,0.018-0.04,0.023-0.064s0.008-0.049,0.008-0.075c0-0.062-0.01-0.105-0.029-0.13c-0.021-0.025-0.053-0.038-0.094-0.038
			c-0.027,0-0.051,0.005-0.068,0.016c-0.018,0.01-0.031,0.024-0.039,0.043c-0.01,0.019-0.018,0.04-0.02,0.065
			c-0.004,0.026-0.004,0.053-0.004,0.083h-0.238c0-0.127,0.031-0.222,0.096-0.283c0.063-0.062,0.156-0.093,0.277-0.093
			c0.117,0,0.207,0.027,0.271,0.079c0.064,0.053,0.096,0.136,0.096,0.249c0,0.076-0.016,0.139-0.051,0.187
			c-0.033,0.049-0.082,0.08-0.143,0.093v0.004c0.084,0.013,0.141,0.046,0.176,0.099c0.033,0.054,0.051,0.121,0.051,0.201
			c0,0.043-0.006,0.088-0.016,0.133c-0.012,0.045-0.033,0.087-0.064,0.124c-0.031,0.037-0.074,0.067-0.127,0.09
			c-0.055,0.023-0.127,0.034-0.215,0.034c-0.123,0-0.217-0.034-0.277-0.101c-0.061-0.067-0.092-0.162-0.092-0.286v-0.005h0.248
			C32.786-17.374,32.796-17.318,32.813-17.279"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M33.657-18.123l-0.027,0.289h0.006c0.021-0.033,0.053-0.057,0.092-0.072
			c0.039-0.015,0.086-0.023,0.141-0.023c0.061,0,0.111,0.012,0.152,0.035c0.041,0.024,0.072,0.055,0.096,0.093
			c0.023,0.038,0.039,0.082,0.049,0.13c0.01,0.049,0.014,0.098,0.014,0.149c0,0.071-0.006,0.134-0.018,0.192
			c-0.012,0.057-0.033,0.107-0.063,0.148c-0.031,0.042-0.074,0.073-0.125,0.094c-0.055,0.021-0.121,0.031-0.203,0.031
			c-0.08,0-0.145-0.011-0.195-0.032c-0.049-0.022-0.086-0.05-0.113-0.086c-0.027-0.035-0.045-0.073-0.053-0.115
			c-0.01-0.041-0.014-0.08-0.014-0.116v-0.034h0.248c0,0.022,0.002,0.045,0.004,0.069s0.008,0.046,0.016,0.067
			c0.01,0.021,0.023,0.039,0.039,0.052c0.018,0.014,0.039,0.021,0.066,0.021c0.055,0,0.094-0.021,0.117-0.062
			c0.023-0.042,0.035-0.104,0.035-0.186c0-0.053-0.004-0.097-0.008-0.132c-0.004-0.034-0.014-0.063-0.023-0.082
			c-0.014-0.021-0.027-0.035-0.047-0.043c-0.02-0.009-0.043-0.013-0.072-0.013c-0.037,0-0.064,0.01-0.084,0.031
			c-0.021,0.022-0.033,0.05-0.037,0.086h-0.232l0.047-0.692h0.662v0.201H33.657z"
          />
          <rect
            x="36.319"
            y="-18.793"
            clipPath="url(#SVGID_12_)"
            fill="#F89E2A"
            width="7.645"
            height="7.645"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M37.112-18.361c0.066,0,0.121,0.011,0.166,0.034
			c0.045,0.022,0.082,0.051,0.109,0.087c0.027,0.036,0.047,0.077,0.061,0.123c0.01,0.046,0.018,0.093,0.018,0.141
			c0,0.066-0.01,0.124-0.031,0.173c-0.02,0.049-0.047,0.089-0.082,0.12c-0.035,0.031-0.078,0.054-0.129,0.07
			c-0.051,0.016-0.105,0.024-0.168,0.024h-0.137v0.512h-0.26v-1.284H37.112z M37.034-17.78c0.051,0,0.092-0.016,0.125-0.047
			c0.031-0.031,0.047-0.079,0.047-0.145c0-0.065-0.014-0.114-0.041-0.148c-0.029-0.034-0.074-0.05-0.139-0.05h-0.107v0.39H37.034z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="37.983,-18.36 38.165,-17.454 38.171,-17.454 38.354,-18.36
			38.737,-18.36 38.737,-17.076 38.501,-17.076 38.501,-18.101 38.497,-18.101 38.269,-17.076 38.067,-17.076 37.839,-18.101
			37.837,-18.101 37.837,-17.076 37.599,-17.076 37.599,-18.36 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M39.132-17.44c0,0.03,0.002,0.057,0.008,0.082
			c0.004,0.025,0.014,0.045,0.025,0.062c0.014,0.016,0.031,0.03,0.053,0.039c0.023,0.01,0.053,0.015,0.084,0.015
			c0.039,0,0.076-0.013,0.107-0.039s0.049-0.065,0.049-0.119c0-0.029-0.004-0.054-0.012-0.075c-0.008-0.021-0.021-0.04-0.039-0.057
			c-0.02-0.016-0.041-0.032-0.072-0.046c-0.029-0.013-0.064-0.027-0.107-0.042c-0.059-0.019-0.107-0.04-0.15-0.063
			c-0.041-0.021-0.076-0.049-0.105-0.08c-0.027-0.03-0.049-0.066-0.061-0.106c-0.014-0.04-0.021-0.086-0.021-0.139
			c0-0.127,0.037-0.222,0.107-0.284c0.07-0.063,0.168-0.094,0.291-0.094c0.057,0,0.111,0.007,0.16,0.019
			c0.049,0.013,0.09,0.033,0.125,0.061c0.035,0.029,0.063,0.065,0.082,0.108c0.02,0.044,0.031,0.096,0.031,0.157v0.036h-0.248
			c0-0.061-0.012-0.108-0.033-0.141c-0.021-0.033-0.059-0.049-0.109-0.049c-0.027,0-0.051,0.004-0.07,0.012
			c-0.02,0.009-0.035,0.021-0.047,0.034c-0.012,0.014-0.02,0.029-0.023,0.047c-0.004,0.018-0.006,0.037-0.006,0.056
			c0,0.04,0.01,0.073,0.025,0.1c0.018,0.027,0.053,0.052,0.107,0.074l0.201,0.087c0.049,0.021,0.088,0.044,0.119,0.067
			c0.031,0.024,0.057,0.049,0.074,0.076c0.02,0.027,0.031,0.056,0.039,0.089c0.008,0.032,0.012,0.068,0.012,0.108
			c0,0.135-0.039,0.234-0.119,0.295c-0.078,0.062-0.188,0.093-0.328,0.093c-0.146,0-0.25-0.032-0.313-0.095
			c-0.063-0.064-0.096-0.155-0.096-0.274v-0.052h0.26V-17.44z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M40.524-17.976h-0.252v-0.169h0.021c0.088,0,0.156-0.016,0.205-0.049
			c0.051-0.032,0.082-0.084,0.094-0.156h0.191v1.273h-0.26V-17.976z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M41.374-17.279c0.02,0.039,0.057,0.058,0.113,0.058
			c0.031,0,0.057-0.005,0.074-0.016c0.018-0.011,0.033-0.025,0.043-0.044c0.01-0.018,0.018-0.04,0.02-0.065
			c0.004-0.026,0.004-0.052,0.004-0.08s0-0.055-0.006-0.081c-0.004-0.026-0.012-0.049-0.023-0.07
			c-0.012-0.02-0.029-0.036-0.051-0.048c-0.021-0.012-0.051-0.018-0.086-0.018h-0.086v-0.169h0.084c0.027,0,0.051-0.006,0.07-0.017
			c0.018-0.011,0.033-0.026,0.045-0.045c0.01-0.019,0.018-0.04,0.025-0.064c0.004-0.024,0.008-0.049,0.008-0.075
			c0-0.062-0.012-0.105-0.031-0.13c-0.02-0.025-0.051-0.038-0.094-0.038c-0.027,0-0.049,0.005-0.068,0.016
			c-0.016,0.01-0.031,0.024-0.039,0.043c-0.01,0.019-0.016,0.04-0.02,0.065c-0.002,0.026-0.004,0.053-0.004,0.083h-0.238
			c0-0.127,0.033-0.222,0.096-0.283c0.064-0.062,0.156-0.093,0.279-0.093c0.117,0,0.205,0.027,0.27,0.079
			c0.064,0.053,0.098,0.136,0.098,0.249c0,0.076-0.018,0.139-0.051,0.187c-0.035,0.049-0.082,0.08-0.145,0.093v0.004
			c0.082,0.013,0.141,0.046,0.176,0.099c0.033,0.054,0.051,0.121,0.051,0.201c0,0.043-0.006,0.088-0.016,0.133
			c-0.012,0.045-0.033,0.087-0.063,0.124c-0.031,0.037-0.074,0.067-0.129,0.09s-0.127,0.034-0.215,0.034
			c-0.123,0-0.217-0.034-0.277-0.101c-0.061-0.067-0.092-0.162-0.092-0.286v-0.005h0.248C41.347-17.374,41.356-17.318,41.374-17.279
			"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M42.747-18.126c-0.053,0.075-0.1,0.154-0.141,0.239s-0.076,0.173-0.109,0.263
			c-0.031,0.089-0.057,0.181-0.076,0.274c-0.021,0.093-0.037,0.184-0.047,0.274h-0.271c0.008-0.076,0.023-0.157,0.043-0.242
			c0.02-0.084,0.045-0.171,0.076-0.258s0.07-0.176,0.115-0.264c0.045-0.089,0.1-0.176,0.162-0.261h-0.535v-0.223h0.783V-18.126z"
          />
          <rect
            x="44.88"
            y="-18.793"
            clipPath="url(#SVGID_12_)"
            fill="#A46B25"
            width="7.645"
            height="7.645"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M45.673-18.361c0.066,0,0.121,0.011,0.166,0.034
			c0.045,0.022,0.082,0.051,0.109,0.087c0.027,0.036,0.049,0.077,0.061,0.123c0.01,0.046,0.018,0.093,0.018,0.141
			c0,0.066-0.01,0.124-0.029,0.173c-0.021,0.049-0.047,0.089-0.084,0.12c-0.035,0.031-0.078,0.054-0.127,0.07
			c-0.051,0.016-0.107,0.024-0.168,0.024h-0.139v0.512h-0.26v-1.284H45.673z M45.595-17.78c0.051,0,0.092-0.016,0.125-0.047
			c0.031-0.031,0.047-0.079,0.047-0.145c0-0.065-0.014-0.114-0.041-0.148s-0.074-0.05-0.139-0.05h-0.107v0.39H45.595z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="46.544,-18.36 46.728,-17.454 46.731,-17.454 46.915,-18.36
			47.298,-18.36 47.298,-17.076 47.062,-17.076 47.062,-18.101 47.058,-18.101 46.829,-17.076 46.628,-17.076 46.399,-18.101
			46.397,-18.101 46.397,-17.076 46.159,-17.076 46.159,-18.36 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M47.692-17.44c0,0.03,0.004,0.057,0.008,0.082s0.014,0.045,0.025,0.062
			c0.014,0.016,0.031,0.03,0.055,0.039c0.021,0.01,0.051,0.015,0.084,0.015c0.039,0,0.074-0.013,0.105-0.039
			s0.049-0.065,0.049-0.119c0-0.029-0.004-0.054-0.012-0.075c-0.008-0.021-0.021-0.04-0.039-0.057
			c-0.018-0.016-0.041-0.032-0.07-0.046c-0.029-0.013-0.066-0.027-0.109-0.042c-0.057-0.019-0.107-0.04-0.15-0.063
			c-0.041-0.021-0.076-0.049-0.104-0.08c-0.029-0.03-0.049-0.066-0.063-0.106s-0.02-0.086-0.02-0.139
			c0-0.127,0.035-0.222,0.105-0.284c0.07-0.063,0.168-0.094,0.291-0.094c0.059,0,0.111,0.007,0.16,0.019
			c0.049,0.013,0.09,0.033,0.125,0.061c0.035,0.029,0.063,0.065,0.082,0.108c0.021,0.044,0.031,0.096,0.031,0.157v0.036h-0.248
			c0-0.061-0.012-0.108-0.033-0.141c-0.021-0.033-0.057-0.049-0.107-0.049c-0.029,0-0.053,0.004-0.072,0.012
			c-0.02,0.009-0.035,0.021-0.045,0.034c-0.014,0.014-0.021,0.029-0.025,0.047c-0.004,0.018-0.006,0.037-0.006,0.056
			c0,0.04,0.008,0.073,0.025,0.1s0.053,0.052,0.107,0.074l0.201,0.087c0.049,0.021,0.088,0.044,0.119,0.067
			c0.031,0.024,0.057,0.049,0.076,0.076c0.018,0.027,0.029,0.056,0.037,0.089c0.008,0.032,0.012,0.068,0.012,0.108
			c0,0.135-0.039,0.234-0.119,0.295c-0.078,0.062-0.186,0.093-0.328,0.093c-0.145,0-0.25-0.032-0.313-0.095
			c-0.063-0.064-0.096-0.155-0.096-0.274v-0.052h0.26V-17.44z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M49.087-17.976h-0.254v-0.169h0.021c0.088,0,0.156-0.016,0.205-0.049
			c0.051-0.032,0.082-0.084,0.094-0.156h0.191v1.273h-0.258V-17.976z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M50.069-18.35h0.268v0.808h0.123v0.201h-0.123v0.264h-0.236v-0.264h-0.453
			v-0.212L50.069-18.35z M50.097-18.026l-0.248,0.484h0.252v-0.484H50.097z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M51.019-18.14c-0.018-0.027-0.049-0.04-0.092-0.04
			c-0.033,0-0.061,0.008-0.082,0.024c-0.02,0.016-0.035,0.039-0.049,0.068c-0.01,0.028-0.018,0.063-0.021,0.102
			c-0.004,0.04-0.006,0.083-0.006,0.13v0.063h0.004c0.021-0.034,0.053-0.061,0.094-0.081c0.039-0.019,0.088-0.029,0.145-0.029
			s0.105,0.01,0.145,0.029c0.039,0.02,0.072,0.048,0.096,0.083c0.025,0.036,0.043,0.078,0.055,0.126
			c0.012,0.049,0.018,0.102,0.018,0.159c0,0.148-0.033,0.259-0.098,0.336c-0.066,0.075-0.172,0.114-0.314,0.114
			c-0.09,0-0.16-0.016-0.213-0.047c-0.053-0.032-0.092-0.074-0.121-0.129c-0.027-0.054-0.047-0.117-0.055-0.189
			c-0.008-0.072-0.012-0.147-0.012-0.225c0-0.09,0.002-0.177,0.008-0.262c0.006-0.084,0.021-0.159,0.049-0.224
			c0.025-0.066,0.068-0.118,0.125-0.158c0.059-0.039,0.141-0.059,0.246-0.059c0.045,0,0.09,0.005,0.131,0.016
			c0.043,0.011,0.082,0.029,0.113,0.054s0.059,0.059,0.078,0.1s0.029,0.092,0.031,0.154h-0.248
			C51.044-18.076,51.036-18.114,51.019-18.14 M50.786-17.372c0.004,0.031,0.01,0.058,0.021,0.08c0.01,0.022,0.023,0.04,0.043,0.052
			c0.02,0.013,0.043,0.019,0.072,0.019c0.031,0,0.057-0.006,0.076-0.019c0.018-0.012,0.031-0.03,0.041-0.053s0.018-0.049,0.02-0.08
			c0.004-0.03,0.004-0.064,0.004-0.1c0-0.086-0.01-0.148-0.031-0.185c-0.023-0.037-0.059-0.056-0.109-0.056
			s-0.088,0.019-0.109,0.056c-0.021,0.037-0.031,0.099-0.031,0.185C50.782-17.437,50.782-17.402,50.786-17.372"
          />
          <rect
            x="62.003"
            y="-27.354"
            clipPath="url(#SVGID_12_)"
            fill="#4F86C6"
            width="7.645"
            height="7.645"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M62.794-26.922c0.066,0,0.121,0.011,0.166,0.034
			c0.045,0.022,0.082,0.051,0.109,0.087c0.027,0.036,0.047,0.077,0.061,0.123c0.012,0.046,0.018,0.093,0.018,0.141
			c0,0.066-0.012,0.124-0.029,0.173c-0.021,0.049-0.049,0.089-0.084,0.12s-0.078,0.055-0.127,0.07
			c-0.051,0.016-0.107,0.024-0.168,0.024h-0.139v0.512h-0.26v-1.284H62.794z M62.716-26.341c0.051,0,0.094-0.015,0.125-0.047
			c0.031-0.031,0.047-0.079,0.047-0.145c0-0.065-0.014-0.114-0.041-0.148c-0.027-0.033-0.072-0.05-0.139-0.05h-0.107v0.39H62.716z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="63.665,-26.921 63.847,-26.014 63.853,-26.014 64.036,-26.921
			64.421,-26.921 64.421,-25.637 64.183,-25.637 64.183,-26.663 64.179,-26.663 63.95,-25.637 63.749,-25.637 63.522,-26.663
			63.519,-26.663 63.519,-25.637 63.28,-25.637 63.28,-26.921 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M64.813-26.001c0,0.03,0.002,0.058,0.008,0.082
			c0.004,0.025,0.014,0.045,0.027,0.062c0.012,0.017,0.029,0.03,0.053,0.039c0.021,0.01,0.049,0.015,0.084,0.015
			c0.039,0,0.074-0.013,0.105-0.039c0.031-0.025,0.049-0.065,0.049-0.119c0-0.029-0.006-0.054-0.014-0.075
			c-0.006-0.021-0.02-0.04-0.037-0.057c-0.018-0.016-0.043-0.032-0.07-0.046c-0.029-0.013-0.066-0.027-0.109-0.041
			c-0.057-0.02-0.107-0.041-0.15-0.064c-0.041-0.022-0.076-0.049-0.104-0.08c-0.029-0.03-0.049-0.065-0.063-0.106
			c-0.014-0.04-0.02-0.086-0.02-0.139c0-0.127,0.035-0.222,0.105-0.284s0.168-0.094,0.291-0.094c0.059,0,0.111,0.007,0.16,0.019
			c0.047,0.013,0.09,0.033,0.125,0.061c0.035,0.029,0.063,0.065,0.082,0.108c0.021,0.044,0.029,0.096,0.029,0.158v0.035H65.12
			c0-0.061-0.012-0.107-0.033-0.14c-0.021-0.033-0.059-0.05-0.107-0.05c-0.029,0-0.055,0.004-0.072,0.013
			c-0.02,0.008-0.035,0.019-0.047,0.033c-0.01,0.014-0.02,0.029-0.023,0.047s-0.006,0.037-0.006,0.056c0,0.04,0.01,0.073,0.025,0.1
			c0.018,0.027,0.053,0.052,0.107,0.075l0.199,0.086c0.051,0.021,0.09,0.044,0.121,0.068c0.031,0.023,0.057,0.048,0.076,0.075
			c0.018,0.027,0.031,0.057,0.037,0.089c0.008,0.032,0.012,0.068,0.012,0.108c0,0.135-0.039,0.234-0.119,0.295
			c-0.078,0.062-0.188,0.093-0.328,0.093c-0.145,0-0.25-0.032-0.313-0.095c-0.063-0.064-0.094-0.155-0.094-0.274v-0.052h0.258
			V-26.001z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M65.913-25.637c0-0.057,0.006-0.109,0.016-0.159
			c0.012-0.049,0.029-0.095,0.053-0.139c0.023-0.045,0.053-0.088,0.088-0.131s0.076-0.086,0.123-0.128l0.105-0.097
			c0.027-0.026,0.051-0.049,0.068-0.071c0.018-0.021,0.031-0.043,0.041-0.064c0.01-0.022,0.016-0.045,0.02-0.07
			c0.002-0.026,0.004-0.054,0.004-0.087c0-0.105-0.043-0.158-0.127-0.158c-0.031,0-0.057,0.007-0.076,0.02
			c-0.018,0.013-0.033,0.031-0.043,0.054s-0.016,0.049-0.02,0.079c-0.002,0.029-0.004,0.061-0.004,0.095h-0.248v-0.049
			c0-0.118,0.031-0.209,0.096-0.273c0.063-0.064,0.162-0.095,0.299-0.095c0.129,0,0.225,0.03,0.289,0.09
			c0.061,0.06,0.092,0.145,0.092,0.257c0,0.041-0.002,0.079-0.01,0.114c-0.008,0.035-0.02,0.069-0.035,0.102
			c-0.016,0.032-0.035,0.064-0.061,0.094c-0.025,0.031-0.057,0.062-0.092,0.095l-0.143,0.133c-0.039,0.034-0.07,0.068-0.09,0.101
			c-0.023,0.033-0.037,0.061-0.043,0.086h0.48v0.201H65.913z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M67.567-26.688c-0.055,0.074-0.102,0.154-0.143,0.239
			c-0.041,0.085-0.078,0.173-0.107,0.262c-0.031,0.09-0.059,0.182-0.078,0.275c-0.02,0.093-0.037,0.184-0.047,0.274h-0.271
			c0.01-0.077,0.023-0.158,0.043-0.242c0.02-0.085,0.045-0.171,0.076-0.258c0.033-0.087,0.07-0.176,0.117-0.264
			c0.045-0.089,0.098-0.176,0.16-0.261h-0.535v-0.223h0.785V-26.688z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M67.933-26.537h-0.252v-0.169h0.021c0.088,0,0.154-0.016,0.205-0.049
			c0.051-0.032,0.08-0.084,0.094-0.156h0.191v1.273h-0.26V-26.537z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M68.548-26.204c0.035-0.058,0.09-0.094,0.162-0.108v-0.004
			c-0.035-0.009-0.064-0.022-0.088-0.04c-0.021-0.018-0.041-0.038-0.057-0.061c-0.014-0.023-0.023-0.046-0.029-0.072
			c-0.006-0.025-0.01-0.049-0.01-0.071c0-0.125,0.033-0.215,0.1-0.27c0.066-0.055,0.158-0.082,0.275-0.082
			c0.115,0,0.207,0.027,0.271,0.082c0.068,0.054,0.102,0.145,0.102,0.27c0,0.021-0.004,0.046-0.01,0.071
			c-0.008,0.026-0.018,0.049-0.031,0.072c-0.016,0.023-0.033,0.043-0.057,0.061s-0.053,0.031-0.086,0.04v0.004
			c0.072,0.014,0.125,0.05,0.162,0.108c0.035,0.057,0.055,0.126,0.055,0.207c0,0.036-0.006,0.077-0.014,0.121
			c-0.01,0.045-0.027,0.086-0.057,0.125c-0.029,0.038-0.07,0.07-0.123,0.095s-0.125,0.037-0.213,0.037
			c-0.09,0-0.16-0.012-0.215-0.037c-0.053-0.025-0.094-0.057-0.123-0.095c-0.027-0.039-0.047-0.08-0.057-0.125
			c-0.008-0.044-0.014-0.085-0.014-0.121C68.493-26.077,68.511-26.146,68.548-26.204 M68.759-25.916
			c0.006,0.027,0.014,0.051,0.023,0.07c0.012,0.02,0.027,0.035,0.047,0.047c0.02,0.011,0.043,0.017,0.072,0.017
			c0.027,0,0.051-0.006,0.072-0.017c0.018-0.012,0.033-0.027,0.045-0.047c0.012-0.019,0.02-0.043,0.023-0.07
			c0.004-0.027,0.006-0.055,0.006-0.085c0-0.032-0.002-0.06-0.006-0.086c-0.004-0.027-0.012-0.05-0.023-0.07
			s-0.027-0.035-0.045-0.046c-0.021-0.012-0.045-0.017-0.072-0.017c-0.029,0-0.053,0.005-0.072,0.017
			c-0.02,0.011-0.035,0.026-0.047,0.046c-0.01,0.02-0.018,0.043-0.023,0.07c-0.004,0.026-0.006,0.054-0.006,0.086
			C68.753-25.97,68.755-25.942,68.759-25.916 M68.81-26.432c0.021,0.029,0.053,0.044,0.092,0.044c0.037,0,0.066-0.015,0.09-0.044
			c0.023-0.028,0.035-0.074,0.035-0.135c0-0.116-0.041-0.174-0.125-0.174s-0.127,0.058-0.127,0.174
			C68.774-26.506,68.786-26.46,68.81-26.432"
          />
          <rect
            x="53.44"
            y="-27.354"
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            width="7.645"
            height="7.645"
          />

          <rect
            x="53.44"
            y="-27.354"
            clipPath="url(#SVGID_12_)"
            fill="none"
            stroke="#231F20"
            strokeWidth="0.112"
            strokeMiterlimit="10"
            width="7.645"
            height="7.645"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            points="53.954,-26.921 54.095,-25.972 54.099,-25.972 54.251,-26.921
			54.499,-26.921 54.649,-25.972 54.653,-25.972 54.794,-26.921 55.044,-26.921 54.81,-25.637 54.521,-25.637 54.376,-26.569
			54.372,-26.569 54.228,-25.637 53.938,-25.637 53.706,-26.921 		"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            points="55.405,-26.921 55.405,-26.43 55.708,-26.43 55.708,-26.921
			55.968,-26.921 55.968,-25.637 55.708,-25.637 55.708,-26.208 55.405,-26.208 55.405,-25.637 55.147,-25.637 55.147,-26.921 		"
          />
          <rect
            x="56.159"
            y="-26.921"
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            width="0.26"
            height="1.284"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            points="57.362,-26.709 57.081,-26.709 57.081,-25.637 56.823,-25.637
			56.823,-26.709 56.542,-26.709 56.542,-26.922 57.362,-26.922 		"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#231F20"
            points="58.19,-26.709 57.733,-26.709 57.733,-26.407 58.163,-26.407
			58.163,-26.195 57.733,-26.195 57.733,-25.85 58.208,-25.85 58.208,-25.637 57.474,-25.637 57.474,-26.922 58.19,-26.922 		"
          />
          <rect
            x="53.44"
            y="-18.793"
            clipPath="url(#SVGID_12_)"
            fill="#D1D1CE"
            width="7.646"
            height="7.645"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M54.235-18.36c0.064,0,0.121,0.011,0.166,0.033
			c0.045,0.023,0.082,0.052,0.107,0.088c0.029,0.036,0.047,0.077,0.061,0.123c0.012,0.046,0.018,0.093,0.018,0.141
			c0,0.066-0.01,0.124-0.029,0.172c-0.02,0.05-0.049,0.09-0.084,0.121c-0.033,0.031-0.078,0.054-0.127,0.07
			c-0.051,0.015-0.107,0.024-0.168,0.024H54.04v0.512h-0.258v-1.284H54.235z M54.155-17.779c0.051,0,0.094-0.016,0.125-0.047
			c0.033-0.031,0.049-0.079,0.049-0.146c0-0.064-0.014-0.114-0.043-0.147c-0.027-0.034-0.074-0.05-0.139-0.05H54.04v0.39H54.155z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="55.104,-18.36 55.288,-17.454 55.292,-17.454 55.476,-18.36
			55.86,-18.36 55.86,-17.077 55.622,-17.077 55.622,-18.101 55.62,-18.101 55.39,-17.077 55.188,-17.077 54.962,-18.101
			54.958,-18.101 54.958,-17.077 54.72,-17.077 54.72,-18.36 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M56.253-17.439c0,0.03,0.002,0.057,0.008,0.082
			c0.006,0.024,0.014,0.045,0.027,0.062c0.012,0.016,0.031,0.03,0.053,0.039c0.021,0.01,0.051,0.014,0.084,0.014
			c0.039,0,0.074-0.012,0.107-0.038c0.031-0.026,0.047-0.065,0.047-0.119c0-0.03-0.004-0.054-0.012-0.075s-0.021-0.04-0.039-0.057
			c-0.018-0.016-0.041-0.032-0.07-0.046c-0.029-0.013-0.066-0.027-0.109-0.042c-0.057-0.019-0.107-0.04-0.148-0.063
			c-0.043-0.023-0.078-0.049-0.105-0.08c-0.027-0.03-0.049-0.066-0.063-0.106c-0.012-0.04-0.02-0.086-0.02-0.139
			c0-0.127,0.035-0.222,0.105-0.284c0.072-0.063,0.168-0.094,0.291-0.094c0.059,0,0.111,0.007,0.16,0.019
			c0.049,0.013,0.09,0.033,0.125,0.061c0.037,0.029,0.063,0.065,0.084,0.108c0.02,0.044,0.029,0.096,0.029,0.157v0.036H56.56
			c0-0.061-0.012-0.108-0.033-0.141c-0.021-0.033-0.059-0.049-0.107-0.049c-0.027,0-0.053,0.004-0.072,0.012
			c-0.02,0.009-0.033,0.02-0.047,0.034c-0.01,0.013-0.018,0.029-0.021,0.047s-0.006,0.037-0.006,0.056c0,0.04,0.008,0.072,0.023,0.1
			c0.018,0.027,0.055,0.052,0.109,0.074l0.199,0.086c0.049,0.022,0.09,0.045,0.121,0.068c0.029,0.024,0.055,0.049,0.074,0.076
			c0.018,0.026,0.031,0.056,0.037,0.089c0.008,0.032,0.012,0.068,0.012,0.108c0,0.135-0.039,0.234-0.117,0.295
			c-0.078,0.062-0.189,0.093-0.328,0.093c-0.146,0-0.252-0.032-0.314-0.095s-0.094-0.155-0.094-0.274v-0.052h0.258V-17.439z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M57.925-18.131c-0.021-0.043-0.063-0.064-0.121-0.064
			c-0.033,0-0.059,0.009-0.08,0.026c-0.021,0.018-0.037,0.046-0.051,0.085c-0.012,0.038-0.02,0.09-0.023,0.153
			c-0.006,0.063-0.008,0.139-0.008,0.231c0,0.097,0.004,0.175,0.01,0.235s0.018,0.107,0.031,0.139
			c0.014,0.033,0.029,0.056,0.051,0.067c0.021,0.011,0.047,0.017,0.074,0.017c0.021,0,0.043-0.004,0.063-0.012
			c0.021-0.007,0.037-0.023,0.051-0.046c0.016-0.024,0.027-0.057,0.035-0.099c0.008-0.043,0.014-0.099,0.014-0.168h0.258
			c0,0.069-0.004,0.135-0.016,0.197c-0.012,0.063-0.031,0.117-0.061,0.164s-0.07,0.083-0.123,0.11
			c-0.055,0.026-0.123,0.039-0.209,0.039c-0.096,0-0.174-0.015-0.232-0.046c-0.057-0.031-0.102-0.077-0.131-0.135
			c-0.031-0.059-0.051-0.129-0.061-0.211c-0.008-0.081-0.014-0.171-0.014-0.269c0-0.097,0.006-0.187,0.014-0.269
			c0.01-0.082,0.029-0.153,0.061-0.211c0.029-0.059,0.074-0.105,0.131-0.138c0.059-0.033,0.137-0.049,0.232-0.049
			c0.092,0,0.166,0.015,0.219,0.045c0.055,0.03,0.092,0.068,0.119,0.114s0.043,0.095,0.049,0.149
			c0.008,0.053,0.012,0.103,0.012,0.15h-0.26C57.958-18.02,57.946-18.088,57.925-18.131"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M58.362-17.987c0.008-0.082,0.027-0.153,0.059-0.212
			c0.031-0.058,0.074-0.104,0.133-0.137c0.057-0.033,0.135-0.05,0.232-0.05c0.096,0,0.174,0.017,0.23,0.05
			c0.059,0.033,0.102,0.079,0.133,0.137c0.029,0.059,0.051,0.13,0.059,0.212c0.01,0.082,0.014,0.172,0.014,0.269
			c0,0.098-0.004,0.188-0.014,0.269c-0.008,0.082-0.029,0.152-0.059,0.211c-0.031,0.058-0.074,0.104-0.133,0.135
			c-0.057,0.031-0.135,0.046-0.23,0.046c-0.098,0-0.176-0.015-0.232-0.046c-0.059-0.031-0.102-0.077-0.133-0.135
			c-0.031-0.059-0.051-0.129-0.059-0.211c-0.01-0.081-0.014-0.171-0.014-0.269C58.349-17.815,58.353-17.905,58.362-17.987
			 M58.614-17.498c0.004,0.061,0.012,0.11,0.023,0.147c0.014,0.038,0.031,0.066,0.055,0.083c0.023,0.018,0.055,0.026,0.094,0.026
			c0.037,0,0.068-0.008,0.092-0.026c0.023-0.017,0.041-0.045,0.053-0.083c0.014-0.037,0.021-0.086,0.025-0.147
			s0.006-0.134,0.006-0.22c0-0.087-0.002-0.16-0.006-0.22c-0.004-0.059-0.012-0.109-0.025-0.147
			c-0.012-0.039-0.029-0.066-0.053-0.084c-0.023-0.017-0.055-0.026-0.092-0.026c-0.039,0-0.07,0.009-0.094,0.026
			c-0.023,0.018-0.041,0.045-0.055,0.084c-0.012,0.038-0.02,0.088-0.023,0.147c-0.006,0.06-0.008,0.133-0.008,0.22
			C58.606-17.632,58.608-17.559,58.614-17.498"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M59.36-17.987c0.01-0.082,0.029-0.153,0.061-0.212
			c0.031-0.058,0.074-0.104,0.133-0.137c0.057-0.033,0.135-0.05,0.23-0.05c0.098,0,0.176,0.017,0.232,0.05
			c0.057,0.033,0.102,0.079,0.133,0.137c0.031,0.059,0.051,0.13,0.059,0.212c0.01,0.082,0.014,0.172,0.014,0.269
			c0,0.098-0.004,0.188-0.014,0.269c-0.008,0.082-0.027,0.152-0.059,0.211c-0.031,0.058-0.076,0.104-0.133,0.135
			s-0.135,0.046-0.232,0.046c-0.096,0-0.174-0.015-0.23-0.046c-0.059-0.031-0.102-0.077-0.133-0.135
			c-0.031-0.059-0.051-0.129-0.061-0.211c-0.008-0.081-0.012-0.171-0.012-0.269C59.349-17.815,59.353-17.905,59.36-17.987
			 M59.612-17.498c0.006,0.061,0.014,0.11,0.027,0.147c0.012,0.038,0.029,0.066,0.053,0.083c0.023,0.018,0.053,0.026,0.092,0.026
			s0.07-0.008,0.092-0.026c0.025-0.017,0.043-0.045,0.055-0.083c0.014-0.037,0.021-0.086,0.025-0.147s0.008-0.134,0.008-0.22
			c0-0.087-0.004-0.16-0.008-0.22c-0.004-0.059-0.012-0.109-0.025-0.147c-0.012-0.039-0.029-0.066-0.055-0.084
			c-0.021-0.017-0.053-0.026-0.092-0.026s-0.068,0.009-0.092,0.026c-0.023,0.018-0.041,0.045-0.053,0.084
			c-0.014,0.038-0.021,0.088-0.027,0.147c-0.004,0.06-0.006,0.133-0.006,0.22C59.606-17.632,59.608-17.559,59.612-17.498"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="60.374,-18.36 60.634,-18.36 60.634,-17.288 61.091,-17.288
			61.091,-17.077 60.374,-17.077 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M54.343-16.367c-0.006-0.025-0.014-0.048-0.027-0.069
			c-0.01-0.021-0.025-0.038-0.045-0.052c-0.018-0.014-0.041-0.021-0.066-0.021c-0.063,0-0.107,0.035-0.135,0.104
			c-0.027,0.07-0.043,0.185-0.043,0.346c0,0.076,0.004,0.146,0.008,0.208c0.006,0.062,0.014,0.116,0.025,0.159
			c0.014,0.044,0.031,0.077,0.055,0.101c0.023,0.023,0.055,0.035,0.092,0.035c0.016,0,0.033-0.004,0.051-0.013
			c0.018-0.008,0.037-0.021,0.053-0.038c0.016-0.016,0.029-0.038,0.039-0.063c0.012-0.026,0.018-0.056,0.018-0.091v-0.131h-0.17
			v-0.191h0.418v0.692h-0.191v-0.118h-0.004c-0.031,0.05-0.068,0.086-0.111,0.107c-0.045,0.02-0.098,0.031-0.158,0.031
			c-0.078,0-0.143-0.013-0.193-0.041c-0.049-0.028-0.088-0.07-0.115-0.128c-0.029-0.058-0.049-0.129-0.057-0.213
			c-0.012-0.085-0.016-0.183-0.016-0.294c0-0.108,0.006-0.202,0.021-0.284c0.014-0.082,0.037-0.15,0.07-0.204
			c0.033-0.055,0.078-0.096,0.133-0.123c0.055-0.028,0.125-0.042,0.207-0.042c0.141,0,0.242,0.035,0.305,0.106
			c0.063,0.07,0.094,0.17,0.094,0.301h-0.248C54.351-16.318,54.349-16.342,54.343-16.367"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M55.259-16.675c0.107,0,0.191,0.027,0.252,0.082
			c0.061,0.054,0.09,0.137,0.09,0.248c0,0.087-0.018,0.157-0.051,0.212c-0.035,0.055-0.09,0.09-0.162,0.105v0.003
			c0.064,0.01,0.111,0.031,0.141,0.064c0.031,0.032,0.049,0.084,0.057,0.156c0.002,0.024,0.004,0.05,0.006,0.078
			c0,0.029,0.002,0.06,0.002,0.094c0.004,0.069,0.008,0.121,0.012,0.157c0.006,0.036,0.021,0.061,0.047,0.074v0.01H55.37
			c-0.014-0.018-0.021-0.038-0.025-0.061c-0.002-0.024-0.006-0.048-0.006-0.073l-0.008-0.246c-0.002-0.051-0.016-0.09-0.039-0.119
			c-0.021-0.029-0.061-0.044-0.113-0.044H55.04v0.543h-0.26v-1.283H55.259z M55.147-16.114c0.063,0,0.111-0.015,0.143-0.044
			c0.035-0.03,0.053-0.079,0.053-0.149c0-0.118-0.061-0.177-0.182-0.177H55.04v0.37H55.147z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="56.499,-16.462 56.04,-16.462 56.04,-16.161 56.472,-16.161
			56.472,-15.949 56.04,-15.949 56.04,-15.604 56.517,-15.604 56.517,-15.391 55.78,-15.391 55.78,-16.674 56.499,-16.674 		"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            points="57.022,-16.179 57.026,-16.179 57.204,-16.674 57.485,-16.674
			57.153,-15.896 57.153,-15.391 56.894,-15.391 56.894,-15.896 56.562,-16.674 56.853,-16.674 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M58.249-16.29h-0.252v-0.169h0.021c0.088,0,0.154-0.016,0.205-0.048
			c0.049-0.033,0.08-0.085,0.094-0.157h0.191v1.273h-0.26V-16.29z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#FFFFFF"
            d="M59.39-16.445c-0.023-0.043-0.063-0.064-0.121-0.064
			c-0.033,0-0.059,0.009-0.08,0.026c-0.021,0.018-0.037,0.046-0.049,0.085c-0.012,0.038-0.021,0.09-0.025,0.153
			c-0.006,0.063-0.008,0.139-0.008,0.231c0,0.097,0.004,0.175,0.01,0.235c0.008,0.06,0.018,0.107,0.031,0.139
			c0.014,0.033,0.031,0.056,0.051,0.067c0.023,0.011,0.047,0.017,0.074,0.017c0.023,0,0.043-0.004,0.064-0.012
			c0.02-0.007,0.037-0.023,0.051-0.046c0.014-0.024,0.025-0.057,0.033-0.099c0.01-0.043,0.014-0.099,0.014-0.168h0.258
			c0,0.069-0.004,0.135-0.016,0.197c-0.01,0.063-0.031,0.117-0.061,0.164c-0.029,0.047-0.07,0.083-0.123,0.11
			c-0.055,0.026-0.123,0.039-0.209,0.039c-0.096,0-0.174-0.015-0.23-0.046c-0.059-0.031-0.104-0.077-0.133-0.135
			c-0.031-0.059-0.051-0.129-0.061-0.211c-0.008-0.081-0.012-0.171-0.012-0.269c0-0.097,0.004-0.187,0.012-0.269
			c0.01-0.082,0.029-0.153,0.061-0.211c0.029-0.059,0.074-0.105,0.133-0.138c0.057-0.033,0.135-0.049,0.23-0.049
			c0.094,0,0.166,0.015,0.219,0.045c0.055,0.03,0.094,0.068,0.121,0.114c0.025,0.046,0.043,0.095,0.049,0.149
			c0.006,0.053,0.01,0.103,0.01,0.15h-0.26C59.423-16.334,59.411-16.402,59.39-16.445"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M13.955,73.837c0.514,0,0.845,0.34,0.845,0.859
			c0,0.516-0.331,0.857-0.845,0.857c-0.51,0-0.841-0.342-0.841-0.857C13.114,74.177,13.445,73.837,13.955,73.837 M13.955,75.216
			c0.307,0,0.397-0.262,0.397-0.52c0-0.262-0.091-0.523-0.397-0.523c-0.302,0-0.393,0.262-0.393,0.523
			C13.562,74.955,13.653,75.216,13.955,75.216"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M15.654,73.882h0.328v0.299h-0.328v0.805c0,0.152,0.038,0.189,0.188,0.189
			c0.047,0,0.093-0.002,0.14-0.014v0.352c-0.076,0.012-0.173,0.014-0.263,0.014c-0.273,0-0.513-0.061-0.513-0.387v-0.959h-0.271
			v-0.299h0.271v-0.488h0.447V73.882z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M16.563,74.804c0.012,0.283,0.151,0.412,0.399,0.412
			c0.18,0,0.325-0.109,0.354-0.211h0.394c-0.126,0.385-0.394,0.547-0.763,0.547c-0.513,0-0.831-0.352-0.831-0.855
			c0-0.486,0.338-0.859,0.831-0.859c0.555,0,0.821,0.467,0.79,0.967H16.563z M17.291,74.521c-0.041-0.227-0.139-0.348-0.356-0.348
			c-0.283,0-0.365,0.221-0.371,0.348H17.291z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M18.347,74.98c0.003,0.195,0.167,0.273,0.343,0.273
			c0.129,0,0.293-0.051,0.293-0.207c0-0.135-0.186-0.182-0.507-0.252c-0.258-0.057-0.516-0.148-0.516-0.434
			c0-0.416,0.358-0.523,0.708-0.523c0.355,0,0.683,0.119,0.718,0.52h-0.425c-0.014-0.172-0.146-0.221-0.306-0.221
			c-0.101,0-0.249,0.02-0.249,0.15c0,0.162,0.252,0.184,0.508,0.244c0.261,0.059,0.516,0.154,0.516,0.455
			c0,0.43-0.371,0.566-0.743,0.566c-0.378,0-0.746-0.141-0.765-0.572H18.347z"
          />
          <rect
            x="10.663"
            y="77.767"
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            width="1.574"
            height="0.252"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M13.357,76.519h0.771c0.693,0,1.08,0.348,1.08,1.072
			c0,0.752-0.33,1.176-1.08,1.176h-0.771V76.519z M13.656,78.515h0.498c0.204,0,0.755-0.057,0.755-0.885
			c0-0.533-0.198-0.859-0.749-0.859h-0.504V78.515z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M16.23,77.103c0.516,0,0.79,0.375,0.79,0.852s-0.274,0.852-0.79,0.852
			c-0.517,0-0.79-0.375-0.79-0.852S15.713,77.103,16.23,77.103 M16.23,78.57c0.279,0,0.507-0.221,0.507-0.615
			s-0.228-0.615-0.507-0.615c-0.28,0-0.507,0.221-0.507,0.615S15.95,78.57,16.23,78.57"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M18.208,77.14h0.252v0.258h0.006c0.113-0.201,0.296-0.295,0.529-0.295
			c0.428,0,0.56,0.246,0.56,0.594v1.072h-0.267v-1.104c0-0.197-0.126-0.328-0.331-0.328c-0.324,0-0.482,0.219-0.482,0.512v0.92
			h-0.267V77.14z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M20.66,77.103c0.517,0,0.79,0.375,0.79,0.852s-0.273,0.852-0.79,0.852
			c-0.516,0-0.791-0.375-0.791-0.852S20.144,77.103,20.66,77.103 M20.66,78.57c0.28,0,0.507-0.221,0.507-0.615
			s-0.227-0.615-0.507-0.615s-0.507,0.221-0.507,0.615S20.379,78.57,20.66,78.57"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M22.135,77.14h0.325v0.236h-0.325v1.01c0,0.123,0.035,0.146,0.201,0.146
			h0.124v0.236h-0.205c-0.278,0-0.388-0.059-0.388-0.357v-1.035H21.59V77.14h0.277v-0.488h0.268V77.14z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M25.072,78.761c-0.048,0.029-0.106,0.045-0.192,0.045
			c-0.139,0-0.227-0.076-0.227-0.252c-0.148,0.174-0.346,0.252-0.573,0.252c-0.296,0-0.539-0.133-0.539-0.457
			c0-0.369,0.274-0.447,0.553-0.5c0.295-0.057,0.547-0.037,0.547-0.24c0-0.232-0.192-0.271-0.362-0.271
			c-0.227,0-0.393,0.07-0.406,0.311h-0.267c0.015-0.404,0.327-0.545,0.688-0.545c0.293,0,0.611,0.066,0.611,0.445v0.838
			c0,0.127,0,0.184,0.084,0.184c0.023,0,0.048-0.004,0.083-0.016V78.761z M24.637,77.927c-0.104,0.076-0.306,0.078-0.485,0.109
			c-0.176,0.033-0.327,0.096-0.327,0.293c0,0.176,0.151,0.24,0.315,0.24c0.352,0,0.497-0.221,0.497-0.369V77.927z"
          />
          <rect
            x="25.334"
            y="76.519"
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            width="0.269"
            height="2.248"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M26.389,77.14h0.324v0.236h-0.324v1.01c0,0.123,0.034,0.146,0.201,0.146
			h0.123v0.236h-0.204c-0.278,0-0.388-0.059-0.388-0.357v-1.035h-0.277V77.14h0.277v-0.488h0.268V77.14z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M28.397,78.251c-0.072,0.359-0.33,0.555-0.696,0.555
			c-0.519,0-0.765-0.359-0.781-0.857c0-0.488,0.322-0.846,0.766-0.846c0.577,0,0.753,0.537,0.736,0.932h-1.218
			c-0.01,0.283,0.151,0.535,0.507,0.535c0.221,0,0.374-0.107,0.423-0.318H28.397z M28.139,77.798
			c-0.014-0.256-0.205-0.459-0.47-0.459c-0.279,0-0.446,0.209-0.466,0.459H28.139z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M28.688,77.14h0.252v0.344h0.006c0.129-0.262,0.309-0.391,0.598-0.381v0.283
			c-0.43,0-0.588,0.244-0.588,0.656v0.727h-0.268V77.14z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M32.062,78.761c-0.047,0.029-0.105,0.045-0.191,0.045
			c-0.139,0-0.227-0.076-0.227-0.252c-0.148,0.174-0.346,0.252-0.572,0.252c-0.297,0-0.539-0.133-0.539-0.457
			c0-0.369,0.273-0.447,0.551-0.5c0.297-0.057,0.549-0.037,0.549-0.24c0-0.232-0.193-0.271-0.363-0.271
			c-0.227,0-0.393,0.07-0.406,0.311h-0.268c0.016-0.404,0.328-0.545,0.689-0.545c0.293,0,0.611,0.066,0.611,0.445v0.838
			c0,0.127,0,0.184,0.084,0.184c0.023,0,0.049-0.004,0.082-0.016V78.761z M31.628,77.927c-0.104,0.076-0.307,0.078-0.484,0.109
			c-0.176,0.033-0.328,0.096-0.328,0.293c0,0.176,0.152,0.24,0.316,0.24c0.352,0,0.496-0.221,0.496-0.369V77.927z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M32.3,77.14h0.252v0.344h0.006c0.131-0.262,0.311-0.391,0.6-0.381v0.283
			c-0.432,0-0.588,0.244-0.588,0.656v0.727H32.3V77.14z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M33.729,77.14h0.324v0.236h-0.324v1.01c0,0.123,0.035,0.146,0.201,0.146
			h0.123v0.236h-0.205c-0.277,0-0.387-0.059-0.387-0.357v-1.035h-0.277V77.14h0.277v-0.488h0.268V77.14z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            points="35.962,78.767 35.677,78.767 35.347,77.474 35.341,77.474
			35.013,78.767 34.724,78.767 34.2,77.14 34.497,77.14 34.864,78.472 34.87,78.472 35.198,77.14 35.491,77.14 35.831,78.472
			35.839,78.472 36.202,77.14 36.479,77.14 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M37.437,77.103c0.516,0,0.789,0.375,0.789,0.852s-0.273,0.852-0.789,0.852
			s-0.791-0.375-0.791-0.852S36.921,77.103,37.437,77.103 M37.437,78.57c0.279,0,0.506-0.221,0.506-0.615s-0.227-0.615-0.506-0.615
			c-0.281,0-0.508,0.221-0.508,0.615S37.155,78.57,37.437,78.57"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M38.53,77.14h0.252v0.344h0.006c0.129-0.262,0.309-0.391,0.598-0.381v0.283
			c-0.43,0-0.588,0.244-0.588,0.656v0.727H38.53V77.14z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            points="39.604,76.519 39.87,76.519 39.87,77.851 40.614,77.14 40.974,77.14
			40.327,77.732 41.021,78.767 40.681,78.767 40.122,77.917 39.87,78.152 39.87,78.767 39.604,78.767 		"
          />
          <rect
            x="10.663"
            y="81.025"
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            width="1.574"
            height="0.252"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M15.151,81.214c0,0.566-0.325,0.867-0.885,0.867
			c-0.579,0-0.922-0.27-0.922-0.867v-1.436h0.299v1.436c0,0.396,0.227,0.615,0.623,0.615c0.378,0,0.586-0.219,0.586-0.615v-1.436
			h0.299V81.214z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M15.749,81.513c0.009,0.24,0.217,0.314,0.438,0.314
			c0.167,0,0.393-0.037,0.393-0.242c0-0.207-0.264-0.242-0.531-0.303c-0.265-0.059-0.532-0.146-0.532-0.459
			c0-0.328,0.324-0.463,0.606-0.463c0.36,0,0.646,0.113,0.669,0.51h-0.269c-0.018-0.207-0.201-0.273-0.378-0.273
			c-0.16,0-0.346,0.045-0.346,0.209c0,0.191,0.282,0.223,0.532,0.283c0.268,0.059,0.531,0.146,0.531,0.463
			c0,0.387-0.361,0.512-0.691,0.512c-0.365,0-0.674-0.146-0.69-0.551H15.749z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M18.545,81.511c-0.072,0.357-0.33,0.553-0.695,0.553
			c-0.52,0-0.765-0.359-0.78-0.855c0-0.488,0.32-0.848,0.765-0.848c0.576,0,0.752,0.539,0.737,0.932h-1.22
			c-0.009,0.283,0.152,0.535,0.508,0.535c0.22,0,0.375-0.107,0.422-0.316H18.545z M18.288,81.056
			c-0.012-0.254-0.205-0.459-0.469-0.459c-0.28,0-0.447,0.211-0.467,0.459H18.288z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M20.093,80.4h0.324v0.234h-0.324v1.012c0,0.123,0.034,0.145,0.201,0.145
			h0.123v0.236h-0.204c-0.278,0-0.389-0.057-0.389-0.355v-1.037h-0.276V80.4h0.276v-0.488h0.269V80.4z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M20.996,80.105h-0.268v-0.328h0.268V80.105z M20.729,80.398h0.268v1.629
			h-0.268V80.398z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M22.815,81.888c0,0.529-0.242,0.799-0.756,0.799
			c-0.305,0-0.657-0.123-0.674-0.479h0.269c0.012,0.197,0.246,0.266,0.422,0.266c0.35,0,0.487-0.252,0.487-0.617V81.75h-0.006
			c-0.089,0.199-0.303,0.297-0.507,0.297c-0.485,0-0.728-0.381-0.728-0.826c0-0.383,0.188-0.859,0.753-0.859
			c0.204,0,0.387,0.092,0.483,0.271h0.004V80.4h0.252V81.888z M22.55,81.179c0-0.287-0.125-0.582-0.463-0.582
			c-0.343,0-0.481,0.281-0.481,0.592c0,0.293,0.107,0.619,0.459,0.619C22.418,81.808,22.55,81.486,22.55,81.179"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M23.217,79.779h0.267v0.859h0.008c0.088-0.195,0.317-0.277,0.513-0.277
			c0.428,0,0.56,0.246,0.56,0.596v1.07h-0.267v-1.102c0-0.199-0.126-0.328-0.331-0.328c-0.324,0-0.482,0.217-0.482,0.51v0.92h-0.267
			V79.779z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M25.338,80.4h0.324v0.234h-0.324v1.01c0,0.125,0.034,0.146,0.201,0.146h0.123
			v0.236h-0.204c-0.278,0-0.388-0.057-0.388-0.355v-1.037h-0.277V80.4h0.277v-0.488h0.268V80.4z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M27.535,80.361c0.516,0,0.79,0.375,0.79,0.854c0,0.477-0.274,0.85-0.79,0.85
			c-0.517,0-0.79-0.373-0.79-0.85C26.745,80.736,27.018,80.361,27.535,80.361 M27.535,81.828c0.279,0,0.507-0.219,0.507-0.613
			c0-0.396-0.228-0.617-0.507-0.617c-0.28,0-0.508,0.221-0.508,0.617C27.027,81.609,27.254,81.828,27.535,81.828"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            points="29.372,82.027 29.085,82.027 28.482,80.398 28.78,80.398
			29.237,81.755 29.243,81.755 29.687,80.398 29.968,80.398 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M31.601,81.511c-0.072,0.357-0.33,0.553-0.697,0.553
			c-0.518,0-0.764-0.359-0.781-0.855c0-0.488,0.322-0.848,0.766-0.848c0.576,0,0.754,0.539,0.736,0.932h-1.217
			c-0.01,0.283,0.15,0.535,0.506,0.535c0.221,0,0.375-0.107,0.422-0.316H31.601z M31.343,81.056
			c-0.014-0.254-0.205-0.459-0.471-0.459c-0.279,0-0.445,0.211-0.465,0.459H31.343z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M31.892,80.4h0.252v0.342h0.006c0.129-0.26,0.309-0.391,0.598-0.381v0.283
			c-0.43,0-0.588,0.246-0.588,0.658v0.725h-0.268V80.4z"
          />
          <rect
            x="32.966"
            y="79.779"
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            width="0.268"
            height="2.248"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M34.349,80.361c0.518,0,0.791,0.375,0.791,0.854
			c0,0.477-0.273,0.85-0.791,0.85c-0.516,0-0.791-0.373-0.791-0.85C33.558,80.736,33.833,80.361,34.349,80.361 M34.349,81.828
			c0.281,0,0.508-0.219,0.508-0.613c0-0.396-0.227-0.617-0.508-0.617c-0.279,0-0.506,0.221-0.506,0.617
			C33.843,81.609,34.069,81.828,34.349,81.828"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M36.558,80.921c-0.041-0.205-0.182-0.324-0.402-0.324
			c-0.387,0-0.508,0.305-0.508,0.637c0,0.299,0.137,0.596,0.477,0.596c0.258,0,0.412-0.152,0.447-0.398h0.273
			c-0.059,0.398-0.309,0.633-0.717,0.633c-0.498,0-0.762-0.346-0.762-0.83c0-0.488,0.252-0.873,0.768-0.873
			c0.367,0,0.664,0.174,0.701,0.561H36.558z"
          />
          <polygon
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            points="37.159,79.779 37.427,79.779 37.427,81.111 38.169,80.398
			38.528,80.398 37.884,80.992 38.575,82.027 38.235,82.027 37.679,81.177 37.427,81.41 37.427,82.027 37.159,82.027 		"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M39.06,80.105h-0.268v-0.328h0.268V80.105z M38.792,80.398h0.268v1.629
			h-0.268V80.398z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M39.474,80.4h0.252v0.258h0.006c0.113-0.201,0.297-0.297,0.529-0.297
			c0.428,0,0.561,0.246,0.561,0.596v1.07h-0.268v-1.102c0-0.199-0.125-0.328-0.33-0.328c-0.324,0-0.482,0.219-0.482,0.51v0.92
			h-0.268V80.4z"
          />
          <path
            clipPath="url(#SVGID_12_)"
            fill="#EB008B"
            d="M42.628,81.888c0,0.529-0.242,0.799-0.756,0.799
			c-0.305,0-0.658-0.123-0.674-0.479h0.268c0.012,0.197,0.246,0.266,0.422,0.266c0.35,0,0.488-0.252,0.488-0.617V81.75H42.37
			c-0.09,0.199-0.303,0.297-0.508,0.297c-0.484,0-0.727-0.381-0.727-0.826c0-0.383,0.188-0.859,0.752-0.859
			c0.205,0,0.387,0.092,0.484,0.271h0.004v-0.234h0.252V81.888z M42.362,81.179c0-0.287-0.125-0.582-0.463-0.582
			c-0.342,0-0.48,0.281-0.48,0.592c0,0.293,0.107,0.619,0.459,0.619C42.231,81.808,42.362,81.486,42.362,81.179"
          />
        </g>
      </g>
    </Base>
  );
}

export default KnowMyself;
