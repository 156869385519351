import Base, { AwardProps } from './Base';

function BeWell(props: AwardProps) {
  return (
    <Base {...props}>
      <g id="Be_Well_Award">
        <g>
          <defs>
            <rect id="SVGID_7_" y="0" width="53.514" height="66.893" />
          </defs>
          <clipPath id="SVGID_8_">
            <use xlinkHref="#SVGID_7_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M26.757,66.223L26.757,66.223c-14.481,0-26.222-11.738-26.222-26.221V5.744
			c0-2.803,2.273-5.075,5.075-5.075h42.294c2.803,0,5.075,2.272,5.075,5.075v34.258C52.979,54.485,41.239,66.223,26.757,66.223"
          />
          <rect
            x="2.452"
            y="2.361"
            clipPath="url(#SVGID_8_)"
            fill="#8A52A0"
            width="48.465"
            height="19.122"
          />

          <line
            clipPath="url(#SVGID_8_)"
            fill="none"
            stroke="#B293BF"
            strokeWidth="3.822"
            strokeMiterlimit="10"
            x1="3.093"
            y1="22.01"
            x2="50.643"
            y2="22.01"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M21.735,8.692c0.017,0.124,0.021,0.227,0.021,0.315
			c0,1.047-0.748,1.8-1.791,1.8c-1.048,0-1.861-0.789-1.861-1.806c0-1.011,0.81-1.805,1.846-1.805c0.77,0,1.445,0.362,1.688,1.037
			l-0.82,0.237c-0.201-0.392-0.532-0.495-0.867-0.495c-0.562,0-0.989,0.444-0.989,1.026c0,0.589,0.438,1.032,1.016,1.032
			c0.454,0,0.785-0.237,0.913-0.619h-1.059V8.692H21.735z"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M24.826,10.73h-0.825v-0.248c-0.196,0.207-0.469,0.325-0.795,0.325
			c-0.603,0-1.006-0.428-1.006-1.078V8.14h0.821v1.414c0,0.304,0.181,0.511,0.443,0.511c0.324,0,0.536-0.222,0.536-0.723V8.14h0.825
			V10.73z"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M26.359,7.377c0,0.263-0.201,0.459-0.48,0.459
			c-0.277,0-0.474-0.196-0.474-0.459c0-0.253,0.196-0.469,0.474-0.469C26.158,6.908,26.359,7.124,26.359,7.377 M25.467,8.141h0.825
			v2.59h-0.825V8.141z"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M29.655,10.73H28.83v-0.206c-0.201,0.18-0.469,0.283-0.804,0.283
			c-0.687,0-1.265-0.552-1.265-1.372c0-0.815,0.578-1.372,1.265-1.372c0.335,0,0.603,0.103,0.804,0.284V7.017h0.825V10.73z
			 M28.83,9.436c0-0.397-0.299-0.671-0.634-0.671c-0.361,0-0.625,0.274-0.625,0.671c0,0.403,0.264,0.671,0.625,0.671
			C28.531,10.106,28.83,9.838,28.83,9.436"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M32.793,9.647h-1.898c0.066,0.309,0.278,0.5,0.557,0.5
			c0.19,0,0.413-0.047,0.553-0.299l0.732,0.149c-0.223,0.547-0.691,0.81-1.285,0.81c-0.747,0-1.366-0.552-1.366-1.372
			c0-0.815,0.619-1.372,1.372-1.372c0.732,0,1.32,0.526,1.336,1.372V9.647z M30.91,9.142h1.068
			c-0.078-0.279-0.284-0.408-0.521-0.408C31.225,8.733,30.987,8.878,30.91,9.142"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M33.851,9.889c0.036,0.222,0.237,0.304,0.459,0.304
			c0.217,0,0.336-0.092,0.336-0.206c0-0.088-0.068-0.155-0.259-0.191L33.86,9.693c-0.479-0.088-0.753-0.346-0.753-0.753
			c0-0.531,0.459-0.877,1.114-0.877c0.645,0,1.073,0.294,1.177,0.743l-0.753,0.149c-0.026-0.16-0.181-0.304-0.435-0.304
			c-0.222,0-0.293,0.108-0.293,0.201c0,0.072,0.03,0.145,0.195,0.181l0.609,0.124c0.49,0.103,0.717,0.397,0.717,0.779
			c0,0.572-0.495,0.871-1.176,0.871c-0.609,0-1.13-0.221-1.219-0.753L33.851,9.889z"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M20.229,14.617c0.319,0.026,0.666,0.273,0.666,0.794
			c0,0.573-0.388,0.97-1.141,0.97h-1.796v-3.456h1.79c0.635,0,1.048,0.377,1.048,0.944c0,0.474-0.336,0.701-0.567,0.727V14.617z
			 M18.811,14.354h0.742c0.254,0,0.418-0.155,0.418-0.387c0-0.217-0.17-0.356-0.387-0.356h-0.773V14.354z M19.605,15.695
			c0.272,0,0.422-0.15,0.422-0.387c0-0.227-0.164-0.371-0.407-0.371h-0.81v0.758H19.605z"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M23.913,15.298h-1.897c0.065,0.309,0.277,0.5,0.556,0.5
			c0.191,0,0.413-0.047,0.553-0.299l0.732,0.149c-0.222,0.547-0.691,0.81-1.285,0.81c-0.747,0-1.366-0.552-1.366-1.372
			c0-0.815,0.619-1.372,1.372-1.372c0.733,0,1.32,0.526,1.336,1.372V15.298z M22.03,14.792h1.068
			c-0.077-0.279-0.284-0.408-0.521-0.408C22.346,14.384,22.107,14.529,22.03,14.792"
          />
          <polygon
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            points="29.242,16.381 28.329,16.381 27.751,14.246 27.169,16.381
			26.262,16.381 25.275,12.925 26.147,12.925 26.75,15.102 27.344,12.925 28.154,12.925 28.747,15.102 29.351,12.925 30.228,12.925
					"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M32.864,15.298h-1.897c0.066,0.309,0.278,0.5,0.557,0.5
			c0.19,0,0.412-0.047,0.553-0.299l0.731,0.149c-0.222,0.547-0.69,0.81-1.284,0.81c-0.748,0-1.367-0.552-1.367-1.372
			c0-0.815,0.619-1.372,1.373-1.372c0.731,0,1.319,0.526,1.335,1.372V15.298z M30.982,14.792h1.067
			c-0.078-0.279-0.284-0.408-0.521-0.408C31.297,14.384,31.059,14.529,30.982,14.792"
          />
          <rect
            x="33.293"
            y="12.667"
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            width="0.825"
            height="3.714"
          />
          <rect
            x="34.717"
            y="12.667"
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            width="0.826"
            height="3.714"
          />
          <polygon
            clipPath="url(#SVGID_8_)"
            fill="#D1D1CE"
            points="52.639,43.572 37.911,64.086 18.527,44.702 38.139,29.072 		"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#B293BF"
            d="M48.335,3.822c0.748,0,1.357,0.609,1.357,1.357v34.957
			c0,12.646-10.289,22.936-22.936,22.936c-12.646,0-22.934-10.289-22.934-22.936V5.179c0-0.748,0.607-1.357,1.355-1.357H48.335
			 M48.335,0H5.179C2.319,0,0,2.318,0,5.179v34.957c0,14.777,11.98,26.758,26.757,26.758c14.777,0,26.757-11.98,26.757-26.758V5.179
			C53.514,2.318,51.195,0,48.335,0"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#EE3A44"
            d="M39.439,38.16c0.417-1.037,0.652-2.19,0.652-3.413
			c0-4.484-3.131-8.123-6.994-8.123c-2.811,0-5.229,1.932-6.341,4.71c-1.11-2.778-3.528-4.71-6.34-4.71
			c-3.862,0-6.994,3.639-6.994,8.123c0,1.223,0.235,2.376,0.651,3.413l0.473,0.997c2.363,5.037,6.981,8.488,12.204,11.805v0.008
			l0.006-0.002l0.006,0.002l0.002-0.008c5.195-3.3,9.792-6.734,12.165-11.73C38.93,39.231,39.274,38.54,39.439,38.16"
          />

          <polyline
            clipPath="url(#SVGID_8_)"
            fill="none"
            stroke="#EE95A7"
            strokeWidth="1.473"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
			12.361,37.917 18.555,37.917 21.869,31.945 26.736,46.312 30.063,37.917 41.012,37.917 		"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#4F86C6"
            d="M26.757,61.278c-2.288,0-4.141-1.852-4.141-4.139
			c0-2.289,1.853-4.143,4.141-4.143s4.142,1.854,4.142,4.143C30.898,59.426,29.045,61.278,26.757,61.278"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M26.757,52.997c2.288,0,4.142,1.854,4.142,4.143
			c0,2.287-1.854,4.139-4.142,4.139s-4.141-1.852-4.141-4.139C22.616,54.85,24.469,52.997,26.757,52.997 M26.757,52.639
			c-2.479,0-4.498,2.018-4.498,4.5c0,2.479,2.019,4.496,4.498,4.496c2.481,0,4.498-2.018,4.498-4.496
			C31.255,54.657,29.238,52.639,26.757,52.639"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M28.92,55.45c-0.207,0-0.402,0.094-0.538,0.219l-0.011-0.012
			c0.063-0.104,0.146-0.301,0.146-0.621c0-0.766-0.768-1.346-1.761-1.346s-1.77,0.58-1.77,1.346c0,0.32,0.103,0.518,0.155,0.621
			l-0.012,0.012c-0.134-0.125-0.331-0.219-0.537-0.219c-0.633,0-1.264,0.609-1.264,1.75c0,1.119,0.621,1.75,1.294,1.75
			c0.621,0,1.058-0.344,1.449-0.736c-0.063-0.092-0.155-0.186-0.258-0.186c-0.104,0-0.219,0.072-0.414,0.229
			c-0.259,0.197-0.487,0.311-0.788,0.311c-0.527,0-0.754-0.592-0.754-1.367c0-0.828,0.217-1.377,0.775-1.377
			c0.248,0,0.413,0.072,0.58,0.229c0.248,0.229,0.331,0.289,0.444,0.289c0.084,0,0.135-0.053,0.156-0.082
			c-0.187-0.188-0.456-0.58-0.456-1.088c0-0.58,0.486-0.984,1.397-0.984s1.398,0.404,1.398,0.984c0,0.508-0.271,0.9-0.456,1.088
			c0.02,0.029,0.073,0.082,0.155,0.082c0.113,0,0.196-0.061,0.444-0.289c0.155-0.156,0.332-0.229,0.58-0.229
			c0.56,0,0.776,0.549,0.776,1.377c0,0.775-0.229,1.367-0.755,1.367c-0.301,0-0.538-0.113-0.787-0.311
			c-0.197-0.156-0.311-0.229-0.414-0.229c-0.104,0-0.196,0.094-0.259,0.186c0.383,0.393,0.827,0.736,1.448,0.736
			c0.674,0,1.295-0.631,1.295-1.75C30.184,56.059,29.552,55.45,28.92,55.45"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M27.45,55.43h-0.466c-0.031,0-0.051-0.021-0.062-0.043l-0.146-0.443
			c-0.01-0.031-0.021-0.031-0.031,0l-0.145,0.443c-0.01,0.033-0.031,0.043-0.052,0.043h-0.466c-0.021,0-0.03,0.008-0.011,0.02
			l0.384,0.279c0.021,0.021,0.021,0.053,0.01,0.072l-0.146,0.434c-0.009,0.023-0.009,0.043,0.021,0.023l0.383-0.27
			c0.031-0.021,0.052-0.021,0.083,0l0.373,0.27c0.02,0.02,0.031,0.01,0.02-0.01l-0.144-0.447c-0.011-0.029,0-0.063,0.021-0.072
			l0.381-0.279C27.481,55.438,27.471,55.43,27.45,55.43"
          />
          <path
            clipPath="url(#SVGID_8_)"
            fill="#FFFFFF"
            d="M27.099,58.493c-0.196-0.496-0.353-0.838-0.353-1.252
			c0-0.176,0.053-0.332,0.083-0.414c0.042-0.082,0.053-0.135,0.011-0.135c-0.063,0-0.196,0.145-0.279,0.27
			c-0.063,0.104-0.135,0.301-0.135,0.455c0,0.291,0.093,0.602,0.154,0.787c0.115,0.32,0.219,0.693,0.219,1.047
			c0,0.559-0.249,0.941-0.693,1.281c0.04,0.033,0.145,0.084,0.27,0.084c0.434,0,0.951-0.486,0.951-1.148
			C27.326,59.073,27.213,58.803,27.099,58.493"
          />
        </g>
      </g>
    </Base>
  );
}

export default BeWell;
