// import { lazy, Suspense } from 'react';
import EmptyBadge from './EmptyBadge';

import KnowMyself from './KnowMyself';
import ExpressMyself from './ExpressMyself';
import BeWell from './BeWell';
import HaveAdventures from './HaveAdventures';
import TakeAction from './TakeAction';
import SkillsForMyFuture from './SkillsForMyFuture';

// const KnowMyself = lazy(() => import('./KnowMyself'));
// const ExpressMyself = lazy(() => import('./ExpressMyself'));
// const BeWell = lazy(() => import('./BeWell'));
// const HaveAdventures = lazy(() => import('./HaveAdventures'));
// const TakeAction = lazy(() => import('./TakeAction'));
// const SkillsForMyFuture = lazy(() => import('./SkillsForMyFuture'));

function SVG(props: any) {
  const { children, ...otherProps } = props;

  return (
    <svg
      viewBox="0 0 61.688 61.687"
      enableBackground="new 0 0 61.688 61.687"
      {...otherProps}
    >
      {children}
    </svg>
  );
}

function Badge(props: any) {
  const { guidesTheme, name } = props;

  const Fallback = () => <EmptyBadge guidesTheme={guidesTheme} />;

  if (name)
    switch (guidesTheme) {
      case 'Know Myself':
        return <KnowMyself badge={name} fallback={<Fallback />} />;
      case 'Express Myself':
        return <ExpressMyself badge={name} fallback={<Fallback />} />;
      case 'Be Well':
        return <BeWell badge={name} fallback={<Fallback />} />;
      case 'Have Adventures':
        return <HaveAdventures badge={name} fallback={<Fallback />} />;
      case 'Take Action':
        return <TakeAction badge={name} fallback={<Fallback />} />;
      case 'Skills For My Future':
        return <SkillsForMyFuture badge={name} fallback={<Fallback />} />;
      default:
        return null;
    }

  return <Fallback />;
}

function InterestBadge(props: any) {
  const { guidesTheme, badge, ...otherProps } = props;
  return (
    <SVG {...otherProps}>
      {/* <Suspense fallback={<Badge guidesTheme={guidesTheme} />}> */}
      <Badge guidesTheme={guidesTheme} name={badge} />
      {/* </Suspense> */}
    </SVG>
  );
}

export default InterestBadge;
