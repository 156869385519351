import React, { PropsWithChildren, SVGProps } from 'react';

type BaseIconProps<T> = PropsWithChildren<T>;
export type UIIconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  fill?: string;
  filled?: boolean;
};

function BaseIcon(props: BaseIconProps<UIIconProps>) {
  const { children, size = 24, fill = 'currentColor', ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...otherProps}
    >
      {children}
    </svg>
  );
}

export default BaseIcon;
