import { PropsWithChildren, useState } from 'react';

import {
  Activity,
  CompletedTask,
  GuidesTheme,
  InterestBadge,
  Nullable,
  SkillsBuilder
} from 'types';
import { CRUD, GuidesThemesAreas as GTA } from '../../constants';
import { toKebabCase } from 'utilities/string';
import ActivityList, { ActivityAction } from 'components/activity-list';
import Button from 'components/button';
import { FixedGrid } from 'components/grid';
import { Span } from 'components/typography';
import InterestBadgeIcon from 'components/interest-badge';
import SkillsBuilderIcon from 'components/skills-builder';
import UMAIcon from 'components/icons/UMA';

import Modal, {
  ModalFooter as BaseModalFooter,
  ModalHeader,
  ModalProps
} from './Modal';

export type ActivitiesModalProps = ModalProps & {
  modalType: Nullable<GTA>;
  guidesTheme: Nullable<GuidesTheme>;
  activityProgress: CompletedTask[];
  title: string;
  activityIndex?: number;
  onUpdateProgress(items: CompletedTask[], action: CRUD): void;
  // onAddProgress(items: CompletedTask[]): void;
  // onRemoveProgress(items: CompletedTask[]): void;
};

type ActivityHeaderProps = PropsWithChildren<{
  icon: React.ReactNode;
  title: string;
  color: string;
}>;

type ModalFooterProps = {
  actionType: Nullable<ActivityAction>;
  selectedCount: number;
  onActionClick: (item: CompletedTask) => void;
  onClearClick: () => void;
  onCloseClick: () => void;
};

function Icon(props: any) {
  const { guidesTheme, type, name, stage, ...otherProps } = props;
  let IconComponent = null;
  let customProps = {};

  switch (type) {
    case 'Interest Badges':
      IconComponent = InterestBadgeIcon;
      customProps = { guidesTheme, badge: name };
      break;
    case 'Skills Builders':
      IconComponent = SkillsBuilderIcon;
      customProps = { guidesTheme, skill: name, stage };
      break;
    case 'Unit Meeting Activities':
      IconComponent = UMAIcon;
      customProps = { color: `var(--${toKebabCase(guidesTheme)})` };
      break;
    default:
      return null;
  }

  return (
    <IconComponent
      {...otherProps}
      {...customProps}
      width={64}
      height={64}
      style={{ width: '100%' }}
    />
  );
}

function ActivityHeader(props: ActivityHeaderProps) {
  const { children, icon, title, color } = props;
  const contentClassName = `bg-${color}--glass br-rounded flex-1 d-flex flex-column py-1 px-2`;

  return (
    <FixedGrid flex className={`bg-${color}-20 pt-1 pb-2 px-2`}>
      <div className={`d-grid bg-${color}--glass br-rounded p-1`}>{icon}</div>
      <div className={contentClassName}>
        <Span case="upper" color={`${color}-dark`}>
          {title}
        </Span>
        {children}
      </div>
    </FixedGrid>
  );
}

function ModalStepper(props: any) {
  const { guidesTheme, ...otherProps } = props;
  return <div {...otherProps} className="d-flex"></div>;
}

function ModalFooter(props: ModalFooterProps) {
  const {
    actionType,
    selectedCount,
    onActionClick,
    onClearClick,
    onCloseClick,
    ...otherProps
  } = props;

  const showActions = actionType !== null;
  const showComplete = actionType === ActivityAction.Complete;
  const showDelete = actionType === ActivityAction.Delete;
  const columns = showActions ? 2 : 1;

  return (
    <BaseModalFooter {...otherProps}>
      <FixedGrid columns={columns}>
        {showComplete && (
          <Button
            color="success"
            block
            className="button--large"
            onClick={onActionClick}
          >
            {selectedCount > 1 ? `Done (${selectedCount})` : 'Done'}
          </Button>
        )}

        {showDelete && (
          <Button
            color="danger"
            block
            className="button--large"
            onClick={onActionClick}
          >
            {selectedCount > 1 ? `Delete (${selectedCount})` : 'Delete'}
          </Button>
        )}

        {showActions && (
          <Button
            color="dark"
            block
            className="button--large"
            onClick={onClearClick}
          >
            Clear
          </Button>
        )}

        {!showActions && (
          <Button
            color="dark"
            block
            className="button--large"
            onClick={onCloseClick}
          >
            Close
          </Button>
        )}
      </FixedGrid>
    </BaseModalFooter>
  );
}

function ActivitiesModal(props: ActivitiesModalProps) {
  const [selectedItems, setSelectedItems] = useState<CompletedTask[]>([]);
  const [actionType, setActionType] = useState<Nullable<ActivityAction>>(null);

  const {
    modalType,
    guidesTheme,
    title,
    activityIndex = 0,
    activityProgress,
    onClose,
    onUpdateProgress,
    // onAddProgress,
    // onRemoveProgress,
    ...otherProps
  } = props;

  const color = guidesTheme ? toKebabCase(guidesTheme.name) : 'dark';

  const getCurrentType = () => {
    if (!guidesTheme) return null;

    switch (modalType) {
      case GTA.InterestBadges:
        return guidesTheme.interestBadges[activityIndex];
      case GTA.SkillsBuilders:
        return guidesTheme.skillsBuilders[activityIndex];
      default:
        return null;
    }
  };

  const activityHeaderTitle = () => {
    if (modalType === GTA.UnitMeetingActivities) return 'Total Duration';
    const currentType = getCurrentType();

    if (!currentType) return '';

    switch (modalType) {
      case GTA.SkillsBuilders:
        return `${currentType.name} (Stage ${
          (currentType as SkillsBuilder).stage
        })`;
      default:
        return currentType.name;
    }
  };

  const activities = (): CompletedTask[] => {
    const currentType = getCurrentType();

    if (!guidesTheme) return [];
    let newActivities = [];

    switch (modalType) {
      case GTA.InterestBadges:
      case GTA.SkillsBuilders:
        newActivities = currentType?.activities || [];
        break;
      case GTA.UnitMeetingActivities:
        newActivities = guidesTheme.unitMeetingActivities.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        break;
      default:
        return [];
    }

    return newActivities.map((activity) => {
      const isString = typeof activity === 'string';
      let newActivity = activityProgress.find(
        (progress) => progress.task === (isString ? activity : activity.name)
      );

      if (newActivity) return newActivity;

      switch (modalType) {
        case GTA.InterestBadges:
          newActivity = {
            task: activity as string,
            theme: guidesTheme.name,
            program: GTA.InterestBadges,
            category: (currentType as InterestBadge).name
          };
          break;
        case GTA.SkillsBuilders:
        case GTA.UnitMeetingActivities: {
          const { name, duration } = activity as Activity;
          let category = null;
          let stage = null;
          let program = GTA.UnitMeetingActivities;

          if (modalType === GTA.SkillsBuilders) {
            category = (currentType as SkillsBuilder).name;
            stage = (currentType as SkillsBuilder).stage;
            program = GTA.SkillsBuilders;
          }

          newActivity = {
            task: name,
            category,
            stage,
            duration,
            theme: guidesTheme.name,
            program
          };
          break;
        }
        default:
          break;
      }

      return newActivity as CompletedTask;
    });
  };

  const onClearButtonClick = () => {
    setActionType(null);
    setSelectedItems([]);
  };

  const onActionButtonClick = () => {
    if (!modalType || actionType === null) return;

    const confirmAction =
      actionType === ActivityAction.Delete ? 'delete' : 'complete';
    const confirmed = window.confirm(
      `Are you sure you want to ${confirmAction} the selected ${modalType
        .replace(' Activities', '')
        .toLowerCase()} activit${selectedItems.length > 1 ? 'ies' : 'y'}?`
    );

    if (!confirmed) return;

    const newProgress = selectedItems.map((item) => ({
      ...item,
      createdAt: new Date().toDateString(),
      completedAt: new Date().toDateString()
    }));

    switch (actionType) {
      case ActivityAction.Complete:
        onUpdateProgress(newProgress, CRUD.CREATE);
        break;
      case ActivityAction.Delete: {
        onUpdateProgress(newProgress, CRUD.DELETE);
        break;
      }
      default:
        break;
    }

    onClearButtonClick();
  };

  const onCloseButtonClick = () => {
    onClearButtonClick();
    if (typeof onClose === 'function') onClose();
  };

  const onToggleItem = (item: CompletedTask) => {
    const newSelectedItems = [...selectedItems];
    const index = newSelectedItems.findIndex(
      (selectedItem) => selectedItem.task === item.task
    );

    if (index > -1) newSelectedItems.splice(index, 1);
    else newSelectedItems.push(item);

    let newActionType = actionType;
    if (newSelectedItems.length === 0) newActionType = null;
    else
      newActionType = newSelectedItems[0].createdAt
        ? ActivityAction.Delete
        : ActivityAction.Complete;

    setSelectedItems(newSelectedItems);
    setActionType(newActionType);
  };

  return (
    <Modal {...otherProps} color={`${color}-20`}>
      <ModalHeader
        title={title}
        color={color}
        onCloseClicked={onCloseButtonClick}
      />

      <ActivityHeader
        icon={
          <Icon
            guidesTheme={guidesTheme?.name}
            type={title}
            name={getCurrentType()?.name}
            stage={(getCurrentType() as SkillsBuilder)?.stage}
          />
        }
        title={activityHeaderTitle()}
        color={color}
      />

      {modalType === GTA.SkillsBuilders && (
        <ModalStepper guidesTheme={guidesTheme} />
      )}

      <ActivityList
        items={activities()}
        selectedItems={selectedItems}
        itemColor={color}
        onItemToggle={onToggleItem}
        className="flex-1 px-2"
      />

      <ModalFooter
        actionType={actionType}
        selectedCount={selectedItems.length}
        onActionClick={onActionButtonClick}
        onClearClick={onClearButtonClick}
        onCloseClick={onCloseButtonClick}
      />
    </Modal>
  );
}

export default ActivitiesModal;
