// import React from 'react';
import Base from './Base';

function HaveAdventures(props: any) {
  const { invert, primaryColor, secondaryColor, ...otherProps } = props;
  const secondaryColour = secondaryColor || '#FFFFFF';
  const primaryColour = primaryColor || '#72BF44';

  return (
    <Base {...otherProps}>
      <g>
        <defs>
          <path
            id="HAVEADVENTURES_SVGID_5_"
            d="M2.031,34c0,17.589,14.262,31.851,31.853,31.851c17.591,0,31.851-14.262,31.851-31.851
    c0-17.592-14.26-31.853-31.851-31.853C16.293,2.148,2.031,16.409,2.031,34"
          />
        </defs>

        <clipPath id="HAVEADVENTURES_SVGID_6_">
          <use xlinkHref="#HAVEADVENTURES_SVGID_5_" overflow="visible" />
        </clipPath>
      </g>

      <g id="HaveAdventures_Theme">
        <path
          clipPath="url(#HAVEADVENTURES_SVGID_6_)"
          fill={invert ? primaryColour : secondaryColour}
          d="M64.461,34c0,16.886-13.689,30.576-30.577,30.576
			C16.997,64.576,3.305,50.887,3.305,34c0-16.889,13.691-30.58,30.579-30.58C50.771,3.421,64.461,17.112,64.461,34"
        />

        <path
          clipPath="url(#HAVEADVENTURES_SVGID_6_)"
          fill={invert ? secondaryColour : primaryColour}
          d="M41.232,44.43c2.49-1.979,4.039-4.869,3.895-8.057
			c-1.313,2.273-3.424,4.133-6.014,5.26c0.926-1.826,1.422-3.986,1.311-6.289c-0.264-5.506-3.855-9.899-8.333-10.62
			c0.109,0.351,0.191,0.715,0.236,1.095c0.197,1.664-0.287,3.236-1.208,4.422c-0.158,0.291-0.339,0.564-0.563,0.787l0.004,0.006
			c-2.343,2.339-3.893,4.384-3.27,8.269c0.162,1.014,0.705,1.926,1.391,2.719c-2.569-0.895-4.754-2.494-6.226-4.561
			c0.168,3.318,2.123,6.152,4.975,7.865c-5.233-1.855-9.005-6.176-9.256-11.394c-0.138-2.902,0.843-5.628,2.592-7.888
			c-0.074,0.554-0.114,1.113-0.088,1.686c0.123,2.562,1.323,4.827,3.157,6.582c-0.639-4.74,0.736-9.35,3.536-12.847l-0.008-0.007
			c0.889-1.102,1.291-2.59,0.937-4.092c-0.601-2.553-3.117-4.145-5.622-3.555c-0.15,0.035-0.284,0.099-0.429,0.148
			c1.041-0.527,2.149-0.953,3.333-1.232c8.193-1.929,16.428,3.281,18.395,11.636c0.592,2.512,0.531,5.014-0.043,7.347
			c1.98-2.231,3.105-5.037,2.963-8.04c-0.031-0.655-0.141-1.29-0.285-1.912c2.244,2.385,3.662,5.387,3.822,8.711
			C50.719,36.412,46.939,41.723,41.232,44.43 M26.671,50.514l-6.739-1.637c-0.968-0.236-1.561-1.209-1.325-2.176
			c0.234-0.967,1.209-1.561,2.175-1.326l13.524,3.283l13.522-3.283c0.965-0.234,1.939,0.359,2.174,1.326
			c0.236,0.967-0.357,1.939-1.326,2.176l-6.738,1.637l6.738,1.635c0.969,0.234,1.563,1.209,1.326,2.174
			c-0.201,0.826-0.936,1.377-1.748,1.377c-0.141,0-0.283-0.016-0.426-0.051l-13.522-3.283l-13.524,3.283
			c-0.143,0.035-0.286,0.051-0.427,0.051c-0.812,0-1.548-0.551-1.748-1.377c-0.235-0.965,0.357-1.939,1.325-2.174L26.671,50.514z
			 M65.736,34c0-17.593-14.262-31.853-31.853-31.853C16.292,2.147,2.032,16.408,2.032,34c0,17.589,14.261,31.851,31.852,31.851
			C51.475,65.852,65.736,51.59,65.736,34"
        />
      </g>
    </Base>
  );
}

export default HaveAdventures;
