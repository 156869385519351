// import React from 'react';
import Base from './Base';

function BeWell(props: any) {
  const { invert, primaryColor, secondaryColor, ...otherProps } = props;
  const secondaryColour = secondaryColor || '#FFFFFF';
  const primaryColour = primaryColor || '#8B52A1';

  return (
    <Base {...otherProps}>
      <g>
        <defs>
          <path
            id="BEWELL_SVGID_7_"
            d="M2.03,34c0,17.592,14.26,31.852,31.852,31.852c17.591,0,31.853-14.26,31.853-31.852
				c0-17.591-14.262-31.852-31.853-31.852C16.291,2.148,2.03,16.409,2.03,34"
          />
        </defs>

        <clipPath id="BEWELL_SVGID_8_">
          <use xlinkHref="#BEWELL_SVGID_7_" overflow="visible" />
        </clipPath>
      </g>

      <g id="BeWell_Theme">
        <path
          clipPath="url(#BEWELL_SVGID_8_)"
          fill={invert ? primaryColour : secondaryColour}
          d="M64.462,34c0,16.888-13.691,30.578-30.579,30.578
			c-16.889,0-30.58-13.689-30.58-30.578c0-16.889,13.691-30.579,30.58-30.579C50.771,3.421,64.462,17.112,64.462,34"
        />

        <path
          clipPath="url(#BEWELL_SVGID_8_)"
          fill={invert ? secondaryColour : primaryColour}
          d="M52.421,29.597c0,1.696-0.326,3.301-0.906,4.745H40.292l-1.182,3.49L36.632,27
			l-3.943,13.862l-3.717-14.529l-1.971,8.01H16.25c-0.579-1.444-0.906-3.049-0.906-4.745c0-6.238,4.353-11.296,9.724-11.296
			c3.909,0,7.271,2.685,8.815,6.549c1.545-3.864,4.905-6.549,8.815-6.549C48.069,18.301,52.421,23.359,52.421,29.597 M16.907,35.729
			h10.995l1.126-3.646l3.661,13.451l3.83-12.886l2.365,10.062l2.197-6.879h9.727c-3.303,6.945-9.691,11.721-16.917,16.309v0.012
			c-0.004-0.002-0.006-0.004-0.008-0.006c-0.004,0.002-0.006,0.004-0.01,0.006v-0.012C26.614,47.529,20.193,42.729,16.907,35.729
			 M65.735,34c0-17.593-14.262-31.853-31.852-31.853C16.291,2.148,2.03,16.408,2.03,34c0,17.589,14.261,31.851,31.854,31.851
			C51.474,65.852,65.735,51.59,65.735,34"
        />
      </g>
    </Base>
  );
}

export default BeWell;
