// import React from 'react';

const NaturalRemedies = () => {
  return (
    <g id="Natural_Remedies">
      <g>
        <defs>
          <rect
            id="NATURALREMEDIES_SVGID_81_"
            x="0.002"
            y="0.001"
            width="61.687"
            height="61.687"
          />
        </defs>
        <clipPath id="NATURALREMEDIES_SVGID_82_">
          <use xlinkHref="#NATURALREMEDIES_SVGID_81_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#8B52A1"
          d="M55.509,61.071H6.183c-3.06,0-5.564-2.504-5.564-5.563V6.181
				c0-3.06,2.504-5.563,5.564-5.563h49.326c3.06,0,5.563,2.503,5.563,5.563v49.327C61.072,58.567,58.568,61.071,55.509,61.071"
        />
        <rect
          x="3.559"
          y="42.631"
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#663372"
          width="54.573"
          height="15.146"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#B394BF"
          d="M56.013,4.408c0.688,0,1.271,0.581,1.271,1.27v50.334
				c0,0.688-0.582,1.27-1.271,1.27H5.68c-0.689,0-1.271-0.581-1.271-1.27V5.678c0-0.689,0.582-1.27,1.271-1.27H56.013 M56.013,0.001
				H5.68c-3.123,0-5.677,2.555-5.677,5.677v50.334c0,3.121,2.554,5.676,5.677,5.676h50.333c3.122,0,5.677-2.555,5.677-5.676V5.678
				C61.689,2.556,59.135,0.001,56.013,0.001"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#72BF44"
          d="M26.364,22.585c-1.259,1.26-1.806,2.959-1.645,4.602
				c1.643,0.16,3.342-0.387,4.601-1.646c1.259-1.258,1.806-2.958,1.645-4.6C29.322,20.779,27.623,21.326,26.364,22.585"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#008B4A"
          d="M27.56,23.925c-1.026-1.026-2.413-1.473-3.753-1.342
				c-0.132,1.341,0.315,2.727,1.342,3.752c1.026,1.027,2.412,1.474,3.752,1.343C29.032,26.337,28.585,24.95,27.56,23.925"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#97A0A4"
          d="M30.98,25.811l5.763-6.754c0.534-0.627,1.528-0.539,1.946,0.173l0.441,0.757
				c0.225,0.386,0.217,0.863-0.02,1.241l-3.152,5.02L30.98,25.811z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#C8CCCC"
          d="M40.254,24.036H21.443c-0.818,0-1.481,0.662-1.481,1.48v4.956
				c0,6.012,4.874,10.886,10.887,10.886s10.886-4.874,10.886-10.886v-4.956C41.734,24.698,41.072,24.036,40.254,24.036"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#97A0A4"
          d="M33.773,42.63h-5.852c-0.937,0-1.697-0.76-1.697-1.697v-0.621h9.246v0.621
				C35.471,41.87,34.712,42.63,33.773,42.63"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#008B4A"
          d="M21.582,41.486c1.333-1.332,1.912-3.132,1.741-4.872
				c-1.739-0.171-3.539,0.408-4.872,1.74c-1.332,1.334-1.912,3.134-1.741,4.873C18.45,43.397,20.25,42.819,21.582,41.486"
        />

        <line
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="none"
          stroke="#9CCE78"
          strokeWidth="0.518"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="16.71"
          y1="43.228"
          x2="22.012"
          y2="37.925"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#72BF44"
          d="M12.08,41.171c1.574,1.574,3.699,2.259,5.754,2.057
				c0.202-2.055-0.483-4.181-2.057-5.754c-1.573-1.574-3.699-2.259-5.754-2.057C9.821,37.472,10.506,39.597,12.08,41.171"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#008B4A"
          d="M15.344,44.407c1.004,0,1.9-0.461,2.491-1.18
				c-0.591-0.719-1.487-1.179-2.491-1.179s-1.9,0.46-2.491,1.179C13.444,43.946,14.34,44.407,15.344,44.407"
        />

        <line
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="none"
          stroke="#008B4A"
          strokeWidth="0.518"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="11.225"
          y1="36.618"
          x2="18.593"
          y2="43.987"
        />

        <line
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="none"
          stroke="#97A0A4"
          strokeWidth="0.969"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="22.791"
          y1="30.472"
          x2="38.906"
          y2="30.472"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#5087C7"
          d="M30.856,55.361c-2.638,0-4.773-2.136-4.773-4.772s2.135-4.772,4.773-4.772
				c2.638,0,4.774,2.136,4.774,4.772S33.494,55.361,30.856,55.361"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M30.856,45.816c2.638,0,4.774,2.136,4.774,4.773
				c0,2.636-2.137,4.771-4.774,4.771c-2.638,0-4.773-2.136-4.773-4.771C26.083,47.952,28.218,45.816,30.856,45.816 M30.856,45.404
				c-2.859,0-5.185,2.326-5.185,5.186c0,2.857,2.326,5.184,5.185,5.184c2.86,0,5.187-2.326,5.187-5.184
				C36.043,47.73,33.717,45.404,30.856,45.404"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M33.35,48.644c-0.238,0-0.464,0.108-0.619,0.252l-0.014-0.013
				c0.073-0.118,0.169-0.346,0.169-0.716c0-0.883-0.884-1.551-2.029-1.551s-2.041,0.668-2.041,1.551c0,0.37,0.119,0.598,0.179,0.716
				l-0.013,0.013c-0.154-0.144-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.705-1.457,2.018c0,1.289,0.717,2.017,1.493,2.017
				c0.715,0,1.217-0.395,1.67-0.848c-0.072-0.108-0.18-0.216-0.298-0.216c-0.119,0-0.252,0.085-0.477,0.264
				c-0.299,0.227-0.562,0.357-0.908,0.357c-0.609,0-0.87-0.68-0.87-1.574c0-0.955,0.251-1.587,0.895-1.587
				c0.286,0,0.476,0.083,0.668,0.263c0.286,0.262,0.381,0.333,0.512,0.333c0.096,0,0.156-0.06,0.18-0.095
				c-0.215-0.215-0.525-0.667-0.525-1.252c0-0.67,0.56-1.135,1.611-1.135c1.049,0,1.611,0.465,1.611,1.135
				c0,0.585-0.312,1.037-0.525,1.252c0.023,0.035,0.083,0.095,0.179,0.095c0.131,0,0.226-0.071,0.512-0.333
				c0.179-0.18,0.382-0.263,0.668-0.263c0.646,0,0.896,0.632,0.896,1.587c0,0.895-0.262,1.574-0.87,1.574
				c-0.347,0-0.621-0.131-0.907-0.357c-0.227-0.179-0.357-0.264-0.477-0.264c-0.12,0-0.227,0.107-0.299,0.216
				c0.441,0.453,0.956,0.848,1.67,0.848c0.775,0,1.492-0.728,1.492-2.017C34.807,49.349,34.078,48.644,33.35,48.644"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M31.656,48.62h-0.537c-0.035,0-0.059-0.023-0.071-0.047L30.88,48.06
				c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.047-0.059,0.047h-0.537c-0.025,0-0.036,0.012-0.012,0.023
				l0.442,0.323c0.023,0.022,0.023,0.06,0.011,0.083l-0.167,0.501c-0.011,0.024-0.011,0.048,0.024,0.024l0.442-0.31
				c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.023,0.023,0.036,0.012,0.023-0.013l-0.166-0.513c-0.012-0.035,0-0.071,0.024-0.083
				l0.44-0.323C31.692,48.632,31.68,48.62,31.656,48.62"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M31.25,52.151c-0.226-0.572-0.406-0.965-0.406-1.443
				c0-0.203,0.06-0.381,0.096-0.477c0.048-0.096,0.06-0.155,0.012-0.155c-0.072,0-0.227,0.167-0.322,0.31
				c-0.073,0.119-0.156,0.348-0.156,0.525c0,0.335,0.108,0.691,0.179,0.906c0.132,0.371,0.251,0.8,0.251,1.207
				c0,0.644-0.287,1.084-0.799,1.478c0.047,0.037,0.167,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.323
				C31.513,52.819,31.382,52.51,31.25,52.151"
        />
        <polygon
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          points="23.762,11.138 22.953,11.138 20.938,8.831 20.938,11.138
				19.956,11.138 19.956,7.153 20.771,7.153 22.787,9.532 22.787,7.153 23.762,7.153 			"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M27.63,11.138h-0.951v-0.238c-0.232,0.209-0.541,0.328-0.928,0.328
				c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.387,0,0.696,0.119,0.928,0.328v-0.24h0.951V11.138z
				 M26.679,9.646c0-0.457-0.345-0.772-0.732-0.772c-0.416,0-0.719,0.315-0.719,0.772c0,0.463,0.303,0.772,0.719,0.772
				C26.334,10.418,26.679,10.108,26.679,9.646"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M30.502,11.007c-0.232,0.125-0.476,0.22-0.814,0.22
				c-0.744,0-1.19-0.403-1.19-1.188V8.909h-0.541V8.152h0.541v-0.88h0.952v0.88h0.88v0.757h-0.88v1.033
				c0,0.311,0.148,0.434,0.398,0.434c0.131,0,0.309-0.047,0.434-0.118L30.502,11.007z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M33.808,11.138h-0.952v-0.285c-0.227,0.237-0.541,0.374-0.915,0.374
				c-0.696,0-1.16-0.493-1.16-1.241V8.153h0.945v1.629c0,0.351,0.209,0.588,0.512,0.588c0.374,0,0.618-0.256,0.618-0.832V8.153
				h0.952V11.138z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M36.636,8.135l-0.042,0.952h-0.172c-0.666,0-1.027,0.345-1.027,1.117v0.934
				h-0.953V8.152h0.953v0.571c0.213-0.355,0.539-0.612,1.027-0.612C36.505,8.111,36.564,8.116,36.636,8.135"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M40.146,11.138h-0.952v-0.238c-0.231,0.209-0.541,0.328-0.927,0.328
				c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.386,0,0.695,0.119,0.927,0.328v-0.24h0.952V11.138z
				 M39.194,9.646c0-0.457-0.345-0.772-0.731-0.772c-0.416,0-0.72,0.315-0.72,0.772c0,0.463,0.304,0.772,0.72,0.772
				C38.85,10.418,39.194,10.108,39.194,9.646"
        />
        <rect
          x="40.783"
          y="6.856"
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          width="0.951"
          height="4.28"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M19.875,13.8l-0.042,0.951H19.66c-0.666,0-1.029,0.346-1.029,1.119v0.933
				h-0.951v-2.985h0.951v0.571c0.214-0.356,0.541-0.612,1.029-0.612C19.743,13.776,19.803,13.782,19.875,13.8"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M23.167,15.554h-2.188c0.078,0.356,0.321,0.577,0.642,0.577
				c0.221,0,0.476-0.053,0.637-0.345l0.844,0.172c-0.256,0.631-0.797,0.934-1.481,0.934c-0.862,0-1.576-0.637-1.576-1.582
				c0-0.939,0.714-1.58,1.583-1.58c0.844,0,1.522,0.605,1.539,1.58V15.554z M20.997,14.972h1.231c-0.09-0.322-0.327-0.471-0.6-0.471
				C21.36,14.501,21.086,14.668,20.997,14.972"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M28.633,14.936v1.867h-0.945v-1.665c0-0.339-0.22-0.553-0.5-0.553
				c-0.35,0-0.582,0.256-0.582,0.755v1.463h-0.951v-1.665c0-0.339-0.22-0.553-0.506-0.553c-0.346,0-0.571,0.256-0.571,0.755v1.463
				h-0.951v-2.985h0.951v0.273c0.202-0.225,0.493-0.362,0.88-0.362c0.416,0,0.773,0.179,0.976,0.505
				c0.237-0.309,0.594-0.505,1.058-0.505C28.128,13.729,28.633,14.211,28.633,14.936"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M32.184,15.554h-2.188c0.077,0.356,0.321,0.577,0.642,0.577
				c0.22,0,0.475-0.053,0.637-0.345l0.844,0.172c-0.256,0.631-0.797,0.934-1.481,0.934c-0.862,0-1.575-0.637-1.575-1.582
				c0-0.939,0.713-1.58,1.581-1.58c0.845,0,1.522,0.605,1.541,1.58V15.554z M30.013,14.972h1.231
				c-0.09-0.322-0.327-0.471-0.601-0.471C30.375,14.501,30.102,14.668,30.013,14.972"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M35.799,16.803h-0.951v-0.238c-0.231,0.209-0.541,0.327-0.928,0.327
				c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.581,1.457-1.581c0.387,0,0.696,0.119,0.928,0.327v-1.534h0.951V16.803z
				 M34.848,15.31c0-0.457-0.346-0.771-0.731-0.771c-0.416,0-0.719,0.314-0.719,0.771c0,0.465,0.303,0.773,0.719,0.773
				C34.502,16.083,34.848,15.774,34.848,15.31"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M37.496,12.938c0,0.303-0.231,0.528-0.553,0.528
				c-0.32,0-0.548-0.226-0.548-0.528c0-0.293,0.228-0.541,0.548-0.541C37.265,12.397,37.496,12.646,37.496,12.938 M36.467,13.817
				h0.951v2.986h-0.951V13.817z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M41.002,15.554h-2.188c0.078,0.356,0.321,0.577,0.643,0.577
				c0.221,0,0.476-0.053,0.637-0.345l0.844,0.172c-0.256,0.631-0.797,0.934-1.48,0.934c-0.862,0-1.576-0.637-1.576-1.582
				c0-0.939,0.714-1.58,1.582-1.58c0.845,0,1.522,0.605,1.539,1.58V15.554z M38.832,14.972h1.231
				c-0.09-0.322-0.327-0.471-0.601-0.471C39.195,14.501,38.922,14.668,38.832,14.972"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M42.18,15.833c0.042,0.256,0.273,0.352,0.529,0.352
				c0.25,0,0.387-0.106,0.387-0.238c0-0.102-0.078-0.178-0.298-0.219l-0.606-0.12c-0.553-0.101-0.867-0.397-0.867-0.868
				c0-0.611,0.528-1.011,1.283-1.011c0.744,0,1.237,0.339,1.355,0.856l-0.867,0.172c-0.03-0.185-0.208-0.351-0.499-0.351
				c-0.256,0-0.34,0.124-0.34,0.231c0,0.084,0.036,0.168,0.227,0.209l0.701,0.142c0.563,0.12,0.826,0.459,0.826,0.899
				c0,0.66-0.57,1.004-1.354,1.004c-0.703,0-1.303-0.256-1.404-0.868L42.18,15.833z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M-12.302-25.932c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.302z M-12.393-25.263c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.057-0.159-0.057h-0.125v0.449
				H-12.393z"
        />
        <polygon
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          points="-11.299,-25.932 -11.088,-24.886 -11.083,-24.886 -10.87,-25.932
				-10.429,-25.932 -10.429,-24.453 -10.702,-24.453 -10.702,-25.633 -10.706,-25.633 -10.969,-24.453 -11.202,-24.453
				-11.465,-25.633 -11.469,-25.633 -11.469,-24.453 -11.743,-24.453 -11.743,-25.932 			"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M-9.975-24.871c0,0.035,0.003,0.066,0.009,0.095
				c0.004,0.028,0.015,0.052,0.03,0.071c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.017,0.097,0.017
				c0.045,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.024-0.024-0.046-0.045-0.065c-0.021-0.019-0.048-0.038-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.049
				c-0.066-0.022-0.123-0.046-0.172-0.072c-0.048-0.027-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108c0.066,0,0.127,0.007,0.183,0.022
				c0.056,0.015,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.112,0.034,0.182v0.041h-0.285
				c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.015
				c-0.023,0.009-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.056c-0.005,0.02-0.007,0.041-0.007,0.064
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.06,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				s0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.066,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107c-0.168,0-0.289-0.037-0.362-0.11
				c-0.072-0.073-0.108-0.178-0.108-0.315v-0.061h0.298V-24.871z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M-8.406-25.658l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.084
				c0.044-0.017,0.098-0.026,0.161-0.026c0.071,0,0.13,0.013,0.176,0.04c0.046,0.027,0.083,0.063,0.109,0.107
				c0.027,0.044,0.046,0.094,0.057,0.15c0.011,0.056,0.017,0.114,0.017,0.171c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.067-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.084-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.041-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.051,0.005,0.078c0.002,0.028,0.009,0.054,0.019,0.078
				c0.011,0.024,0.025,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.077,0.024c0.062,0,0.106-0.024,0.133-0.072
				c0.027-0.047,0.041-0.119,0.041-0.214c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.041-0.015-0.072-0.028-0.096
				c-0.013-0.024-0.03-0.04-0.053-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.025-0.037,0.057-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H-8.406z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M-7.713-24.452c0-0.065,0.007-0.125,0.02-0.182
				c0.013-0.057,0.033-0.111,0.06-0.162s0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.055
				c0-0.137,0.036-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
				c0.071,0.07,0.107,0.168,0.107,0.296c0,0.048-0.004,0.091-0.012,0.132c-0.009,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.039-0.079,0.079-0.104,0.116
				c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H-7.713z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M-6.718-24.452c0-0.065,0.007-0.125,0.02-0.182
				c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.038,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.055
				c0-0.137,0.036-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
				c0.071,0.07,0.107,0.168,0.107,0.296c0,0.048-0.004,0.091-0.012,0.132c-0.009,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154C-6.26-24.86-6.295-24.82-6.32-24.783
				c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H-6.718z"
        />
        <rect
          x="-3.384"
          y="-26.43"
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#8B52A1"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M-2.47-25.931c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.47z M-2.561-25.262c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H-2.561z
				"
        />
        <polygon
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          points="-1.466,-25.932 -1.255,-24.886 -1.25,-24.886 -1.037,-25.932
				-0.596,-25.932 -0.596,-24.453 -0.869,-24.453 -0.869,-25.633 -0.873,-25.633 -1.136,-24.453 -1.369,-24.453 -1.632,-25.633
				-1.636,-25.633 -1.636,-24.453 -1.91,-24.453 -1.91,-25.932 			"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M-0.142-24.87c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.037-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0.211c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.014c-0.022,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.87z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M1.427-25.658l-0.031,0.334H1.4c0.028-0.039,0.063-0.067,0.108-0.084
				c0.044-0.017,0.098-0.026,0.161-0.026c0.071,0,0.129,0.013,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.106
				C1.981-25.242,2-25.193,2.011-25.136c0.011,0.055,0.017,0.113,0.017,0.17c0,0.082-0.007,0.156-0.021,0.223
				c-0.013,0.066-0.038,0.122-0.073,0.17c-0.036,0.048-0.084,0.084-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.04-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039H1.41c0,0.024,0.002,0.051,0.005,0.079c0.002,0.027,0.009,0.053,0.019,0.077
				c0.011,0.024,0.025,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.077,0.024c0.062,0,0.106-0.024,0.133-0.071
				c0.027-0.048,0.041-0.12,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
				c-0.013-0.023-0.03-0.04-0.053-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.025-0.037,0.058-0.042,0.099H1.149l0.054-0.798h0.765v0.232H1.427z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M2.459-25.488H2.167v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.055
				c0.058-0.038,0.094-0.098,0.109-0.181h0.22v1.467H2.459V-25.488z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M3.438-24.685c0.021,0.045,0.065,0.068,0.13,0.068
				c0.035,0,0.064-0.007,0.086-0.019c0.021-0.013,0.037-0.029,0.049-0.051c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.029,0.005-0.06,0.005-0.091c0-0.034-0.002-0.065-0.007-0.095c-0.005-0.029-0.014-0.056-0.028-0.079
				c-0.013-0.024-0.033-0.043-0.058-0.057c-0.025-0.013-0.058-0.021-0.099-0.021h-0.1V-25.3h0.097c0.032,0,0.059-0.006,0.08-0.019
				c0.022-0.013,0.039-0.03,0.052-0.051c0.014-0.022,0.023-0.047,0.029-0.075c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.07-0.011-0.119-0.035-0.149c-0.024-0.029-0.059-0.043-0.108-0.043c-0.031,0-0.057,0.006-0.077,0.017
				c-0.02,0.012-0.036,0.029-0.047,0.05c-0.011,0.022-0.018,0.047-0.022,0.076c-0.003,0.029-0.005,0.061-0.005,0.095H3.139
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.071,0.181-0.107,0.322-0.107c0.134,0,0.237,0.03,0.312,0.092
				c0.074,0.06,0.11,0.155,0.11,0.286c0,0.088-0.019,0.16-0.059,0.216c-0.039,0.056-0.094,0.091-0.164,0.107v0.004
				c0.095,0.015,0.162,0.053,0.202,0.115c0.039,0.061,0.059,0.138,0.059,0.231c0,0.049-0.006,0.101-0.019,0.153
				c-0.012,0.053-0.037,0.1-0.072,0.143c-0.037,0.043-0.085,0.078-0.149,0.104c-0.062,0.026-0.145,0.039-0.247,0.039
				c-0.142,0-0.249-0.038-0.32-0.116c-0.07-0.077-0.105-0.187-0.105-0.329v-0.007h0.286C3.405-24.795,3.417-24.73,3.438-24.685"
        />
        <rect
          x="6.484"
          y="-26.43"
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#663372"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M7.398-25.931c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59H6.876v-1.479H7.398z M7.308-25.262c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058H7.175v0.45H7.308z"
        />
        <polygon
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          points="8.402,-25.932 8.613,-24.886 8.618,-24.886 8.831,-25.932
				9.272,-25.932 9.272,-24.453 8.999,-24.453 8.999,-25.633 8.995,-25.633 8.732,-24.453 8.499,-24.453 8.236,-25.633
				8.232,-25.633 8.232,-24.453 7.958,-24.453 7.958,-25.932 			"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M9.726-24.87c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108c0.066,0,0.127,0.007,0.183,0.021
				c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285
				c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.023,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.065,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.87z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M11.295-25.658l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.084
				c0.044-0.017,0.098-0.026,0.161-0.026c0.071,0,0.13,0.013,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.106
				c0.027,0.045,0.046,0.094,0.057,0.151c0.011,0.055,0.017,0.113,0.017,0.17c0,0.082-0.007,0.156-0.021,0.223
				c-0.013,0.066-0.038,0.122-0.073,0.17s-0.084,0.084-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.04-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.051,0.005,0.079c0.002,0.027,0.009,0.053,0.019,0.077
				c0.011,0.024,0.025,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.077,0.024c0.062,0,0.106-0.024,0.133-0.071
				c0.027-0.048,0.041-0.12,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
				c-0.013-0.023-0.03-0.04-0.053-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.025-0.037,0.058-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H11.295z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M11.987-24.452c0-0.065,0.007-0.125,0.02-0.182
				c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.038,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.021,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.056
				c0-0.137,0.036-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
				c0.071,0.07,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.009,0.041-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H11.987z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M12.993-25.431c0.006-0.094,0.025-0.177,0.056-0.249
				c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.148-0.066,0.256-0.066c0.091,0,0.165,0.013,0.224,0.039
				c0.058,0.026,0.105,0.067,0.14,0.123c0.035,0.057,0.06,0.13,0.075,0.221c0.014,0.09,0.022,0.201,0.022,0.33
				c0,0.102-0.004,0.2-0.011,0.295c-0.007,0.094-0.025,0.177-0.055,0.25c-0.031,0.072-0.077,0.129-0.139,0.17
				c-0.061,0.042-0.146,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.059-0.024-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.06-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C12.982-25.237,12.986-25.335,12.993-25.431
				 M13.288-24.845c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.029,0.057,0.05,0.069c0.021,0.013,0.046,0.019,0.078,0.019
				c0.034,0,0.061-0.008,0.081-0.024c0.021-0.016,0.037-0.042,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.152
				c0.004-0.064,0.007-0.143,0.007-0.236v-0.16c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.058-0.014-0.105-0.026-0.139
				c-0.013-0.033-0.029-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.059,0.008-0.08,0.024
				s-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.089-0.025,0.152c-0.005,0.064-0.007,0.142-0.007,0.236v0.161
				C13.281-24.979,13.283-24.903,13.288-24.845"
        />
        <rect
          x="16.314"
          y="-26.43"
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#72BF44"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M17.229-25.931c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.229z M17.138-25.262c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H17.138z"
        />
        <polygon
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          points="18.232,-25.932 18.443,-24.887 18.448,-24.887 18.661,-25.932
				19.103,-25.932 19.103,-24.453 18.829,-24.453 18.829,-25.633 18.825,-25.633 18.562,-24.453 18.329,-24.453 18.067,-25.633
				18.063,-25.633 18.063,-24.453 17.789,-24.453 17.789,-25.932 			"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M19.557-24.87c0,0.034,0.003,0.066,0.008,0.094
				c0.005,0.028,0.016,0.052,0.031,0.072c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.025-0.024-0.047-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.015,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.071-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.023,0.009-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.011,0.079,0.011,0.125
				c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.87z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M21.147-24.686c0.021,0.045,0.064,0.068,0.13,0.068
				c0.035,0,0.064-0.007,0.085-0.019c0.022-0.013,0.038-0.029,0.05-0.051c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.029,0.005-0.06,0.005-0.091c0-0.034-0.002-0.065-0.007-0.095c-0.005-0.029-0.014-0.056-0.028-0.079
				c-0.014-0.024-0.033-0.043-0.058-0.057c-0.025-0.013-0.059-0.021-0.1-0.021h-0.099V-25.3h0.097c0.032,0,0.058-0.006,0.08-0.019
				c0.021-0.013,0.039-0.03,0.052-0.051c0.013-0.022,0.022-0.047,0.029-0.075c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.07-0.012-0.119-0.035-0.149c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.006-0.077,0.017
				c-0.021,0.012-0.037,0.029-0.047,0.05c-0.011,0.022-0.019,0.047-0.022,0.076c-0.003,0.029-0.005,0.061-0.005,0.095h-0.273
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.071,0.18-0.107,0.322-0.107c0.133,0,0.237,0.03,0.311,0.092
				c0.074,0.06,0.111,0.155,0.111,0.286c0,0.088-0.02,0.16-0.059,0.216c-0.039,0.056-0.094,0.091-0.164,0.107v0.004
				c0.094,0.015,0.162,0.053,0.201,0.115c0.04,0.061,0.06,0.138,0.06,0.231c0,0.049-0.006,0.101-0.019,0.153
				c-0.013,0.053-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.078-0.149,0.104c-0.062,0.026-0.146,0.039-0.248,0.039
				c-0.142,0-0.248-0.038-0.319-0.116c-0.07-0.077-0.105-0.187-0.105-0.329v-0.007h0.286C21.115-24.795,21.125-24.73,21.147-24.686"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M22.395-25.68c-0.019-0.03-0.055-0.045-0.104-0.045
				c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.018-0.043,0.045-0.056,0.078c-0.013,0.033-0.022,0.073-0.025,0.118
				c-0.005,0.046-0.007,0.095-0.007,0.149v0.073h0.004c0.025-0.039,0.061-0.069,0.107-0.092c0.047-0.023,0.103-0.035,0.169-0.035
				c0.065,0,0.12,0.012,0.166,0.035c0.045,0.023,0.082,0.054,0.111,0.095c0.028,0.041,0.049,0.089,0.062,0.145
				c0.013,0.056,0.019,0.117,0.019,0.183c0,0.17-0.037,0.299-0.112,0.387c-0.076,0.088-0.196,0.131-0.362,0.131
				c-0.103,0-0.184-0.017-0.245-0.053c-0.06-0.036-0.107-0.086-0.139-0.148c-0.033-0.064-0.054-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.205,0.009-0.302c0.006-0.098,0.025-0.184,0.056-0.259
				c0.031-0.075,0.08-0.135,0.146-0.182c0.067-0.045,0.161-0.068,0.282-0.068c0.052,0,0.103,0.007,0.152,0.019
				c0.05,0.013,0.093,0.033,0.13,0.062c0.037,0.029,0.067,0.067,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.178h-0.286
				C22.425-25.604,22.416-25.647,22.395-25.68 M22.129-24.792c0.004,0.036,0.012,0.066,0.024,0.092
				c0.011,0.026,0.028,0.046,0.05,0.06c0.021,0.014,0.049,0.022,0.084,0.022s0.064-0.008,0.086-0.022
				c0.021-0.014,0.037-0.034,0.048-0.061c0.011-0.026,0.019-0.057,0.021-0.093c0.004-0.034,0.006-0.073,0.006-0.115
				c0-0.099-0.012-0.17-0.037-0.213s-0.066-0.064-0.124-0.064c-0.06,0-0.103,0.021-0.127,0.064
				c-0.025,0.043-0.037,0.114-0.037,0.213C22.123-24.866,22.125-24.828,22.129-24.792"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M22.866-25.105c0.042-0.066,0.104-0.108,0.187-0.124v-0.004
				c-0.04-0.01-0.074-0.025-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.07c-0.016-0.027-0.028-0.054-0.035-0.083
				c-0.007-0.03-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.115-0.31c0.076-0.063,0.182-0.094,0.316-0.094
				s0.239,0.031,0.315,0.094c0.077,0.063,0.116,0.166,0.116,0.31c0,0.026-0.004,0.053-0.012,0.083
				c-0.007,0.029-0.019,0.056-0.036,0.083c-0.016,0.026-0.038,0.049-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.016,0.145,0.058,0.187,0.124c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.005,0.09-0.015,0.14
				c-0.011,0.052-0.033,0.099-0.066,0.143c-0.033,0.045-0.08,0.081-0.142,0.11c-0.061,0.029-0.143,0.044-0.245,0.044
				s-0.184-0.015-0.246-0.044c-0.062-0.029-0.109-0.065-0.142-0.11c-0.033-0.044-0.055-0.091-0.065-0.143
				c-0.011-0.05-0.016-0.097-0.016-0.14C22.804-24.959,22.825-25.038,22.866-25.105 M23.11-24.773
				c0.005,0.032,0.014,0.059,0.027,0.082c0.013,0.022,0.031,0.041,0.053,0.054c0.022,0.013,0.05,0.019,0.083,0.019
				s0.06-0.006,0.083-0.019c0.021-0.013,0.039-0.032,0.052-0.054c0.013-0.023,0.023-0.05,0.027-0.082
				c0.005-0.03,0.008-0.063,0.008-0.098c0-0.035-0.003-0.069-0.008-0.099c-0.004-0.031-0.014-0.057-0.027-0.08
				c-0.013-0.023-0.031-0.041-0.052-0.054c-0.023-0.013-0.05-0.019-0.083-0.019s-0.061,0.006-0.083,0.019
				c-0.022,0.013-0.04,0.031-0.053,0.054c-0.013,0.023-0.022,0.049-0.027,0.08c-0.005,0.03-0.007,0.064-0.007,0.099
				C23.103-24.836,23.105-24.803,23.11-24.773 M23.167-25.368c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05
				c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.201-0.145-0.201s-0.145,0.066-0.145,0.201
				C23.128-25.453,23.141-25.401,23.167-25.368"
        />
        <rect
          x="36.052"
          y="-26.43"
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#C8CCCC"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M36.966-25.931c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.013,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.096,0.139c-0.041,0.036-0.09,0.063-0.147,0.081
				c-0.058,0.017-0.123,0.027-0.192,0.027h-0.16v0.59h-0.299v-1.479H36.966z M36.875-25.262c0.059,0,0.107-0.018,0.143-0.054
				c0.038-0.036,0.057-0.092,0.057-0.168c0-0.074-0.017-0.131-0.049-0.17c-0.031-0.039-0.084-0.058-0.158-0.058h-0.125v0.45H36.875z
				"
        />
        <polygon
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          points="37.969,-25.932 38.18,-24.887 38.186,-24.887 38.398,-25.932
				38.84,-25.932 38.84,-24.453 38.566,-24.453 38.566,-25.633 38.563,-25.633 38.299,-24.453 38.066,-24.453 37.804,-25.633
				37.8,-25.633 37.8,-24.453 37.525,-24.453 37.525,-25.932 			"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M39.293-24.871c0,0.034,0.004,0.066,0.008,0.094
				c0.006,0.028,0.017,0.052,0.031,0.072c0.016,0.019,0.037,0.035,0.062,0.045c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.037-0.029,0.057-0.075,0.057-0.137c0-0.034-0.006-0.062-0.016-0.086
				c-0.008-0.025-0.023-0.047-0.044-0.066s-0.048-0.036-0.082-0.053c-0.032-0.016-0.075-0.032-0.125-0.048
				c-0.065-0.022-0.124-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.032-0.035-0.058-0.076-0.071-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.122-0.327c0.081-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.015,0.104,0.039,0.146,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.071-0.013-0.125-0.037-0.162c-0.024-0.039-0.065-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.014c-0.022,0.009-0.04,0.023-0.054,0.038c-0.014,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.123,0.086l0.23,0.099
				c0.057,0.025,0.104,0.051,0.139,0.078c0.037,0.027,0.064,0.056,0.086,0.087c0.022,0.03,0.037,0.065,0.045,0.102
				c0.009,0.038,0.013,0.079,0.013,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.217,0.106-0.379,0.106
				c-0.168,0-0.289-0.036-0.361-0.109c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.297V-24.871z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M41.039-25.92h0.309v0.931h0.141v0.232h-0.141v0.305h-0.273v-0.305h-0.522
				v-0.244L41.039-25.92z M41.069-25.546l-0.286,0.557h0.291v-0.557H41.069z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M41.555-24.452c0-0.065,0.006-0.125,0.02-0.182
				c0.014-0.057,0.033-0.111,0.061-0.162c0.026-0.051,0.06-0.101,0.102-0.15c0.041-0.05,0.088-0.099,0.143-0.148l0.119-0.112
				c0.033-0.029,0.061-0.056,0.08-0.081s0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.146-0.183c-0.037,0-0.066,0.008-0.088,0.023
				c-0.021,0.015-0.037,0.036-0.049,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.002,0.034-0.004,0.071-0.004,0.11h-0.287v-0.056
				c0-0.137,0.037-0.242,0.109-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
				c0.072,0.07,0.108,0.168,0.108,0.297c0,0.047-0.004,0.09-0.013,0.131c-0.008,0.041-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.063,0.072-0.104,0.109l-0.166,0.154c-0.045,0.04-0.08,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H41.555z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M43.461-25.662c-0.061,0.086-0.115,0.178-0.162,0.276
				s-0.09,0.199-0.125,0.302c-0.037,0.104-0.066,0.21-0.09,0.317s-0.041,0.212-0.054,0.315h-0.313
				c0.012-0.088,0.027-0.181,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
				c0.053-0.104,0.115-0.203,0.188-0.3h-0.619v-0.257h0.904V-25.662z"
        />
        <rect
          x="45.921"
          y="-26.43"
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#97A0A4"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M46.834-25.931c0.076,0,0.141,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.022,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.192,0.027h-0.16v0.59h-0.298v-1.479H46.834z M46.743-25.262c0.06,0,0.106-0.018,0.144-0.054
				s0.056-0.092,0.056-0.168c0-0.074-0.017-0.131-0.048-0.17c-0.032-0.039-0.086-0.058-0.159-0.058H46.61v0.45H46.743z"
        />
        <polygon
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          points="47.838,-25.932 48.049,-24.887 48.054,-24.887 48.267,-25.932
				48.708,-25.932 48.708,-24.453 48.435,-24.453 48.435,-25.633 48.431,-25.633 48.168,-24.453 47.935,-24.453 47.672,-25.633
				47.668,-25.633 47.668,-24.453 47.394,-24.453 47.394,-25.932 			"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M49.162-24.871c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.035,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.035-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.015-0.086
				c-0.009-0.025-0.023-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.074-0.032-0.125-0.048
				c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.014-0.046-0.022-0.1-0.022-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.192-0.108,0.336-0.108
				c0.065,0,0.127,0.007,0.183,0.021c0.057,0.015,0.104,0.039,0.146,0.071c0.041,0.033,0.071,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.071-0.013-0.125-0.038-0.162c-0.023-0.039-0.065-0.057-0.123-0.057
				c-0.033,0-0.062,0.004-0.084,0.014c-0.022,0.009-0.039,0.023-0.053,0.038c-0.013,0.016-0.021,0.035-0.027,0.055
				c-0.004,0.021-0.006,0.042-0.006,0.065c0,0.045,0.01,0.083,0.028,0.115c0.019,0.031,0.062,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.035,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.362-0.109c-0.071-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.871z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M50.906-25.92h0.309v0.931h0.143v0.232h-0.143v0.305h-0.272v-0.305H50.42
				v-0.244L50.906-25.92z M50.938-25.546l-0.287,0.557h0.291v-0.557H50.938z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M51.424-24.452c0-0.065,0.006-0.125,0.02-0.182
				c0.013-0.057,0.033-0.111,0.061-0.162c0.026-0.051,0.059-0.101,0.1-0.15c0.041-0.05,0.089-0.099,0.143-0.148l0.121-0.112
				c0.033-0.029,0.061-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.002-0.029,0.004-0.062,0.004-0.099c0-0.122-0.047-0.183-0.145-0.183c-0.037,0-0.066,0.008-0.088,0.023
				c-0.021,0.015-0.038,0.036-0.05,0.062c-0.013,0.027-0.021,0.057-0.022,0.092c-0.004,0.034-0.006,0.071-0.006,0.11h-0.285v-0.056
				c0-0.137,0.035-0.242,0.109-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
				c0.071,0.07,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.01,0.041-0.021,0.081-0.039,0.117
				c-0.019,0.038-0.043,0.074-0.071,0.109c-0.029,0.035-0.063,0.072-0.106,0.109l-0.164,0.154c-0.045,0.04-0.08,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.048,0.099h0.554v0.232H51.424z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M52.762-24.665c0.019,0.032,0.054,0.047,0.104,0.047
				c0.041,0,0.072-0.009,0.096-0.028s0.043-0.045,0.055-0.078c0.014-0.033,0.021-0.073,0.025-0.118
				c0.006-0.045,0.008-0.095,0.008-0.149v-0.07h-0.006c-0.023,0.037-0.06,0.066-0.105,0.089s-0.104,0.035-0.169,0.035
				s-0.12-0.012-0.165-0.035c-0.047-0.023-0.084-0.054-0.112-0.095s-0.049-0.089-0.062-0.145c-0.014-0.056-0.02-0.117-0.02-0.183
				c0-0.17,0.037-0.3,0.112-0.39c0.076-0.09,0.196-0.135,0.362-0.135c0.102,0,0.184,0.019,0.244,0.058
				c0.061,0.037,0.107,0.088,0.14,0.151s0.054,0.135,0.063,0.219c0.01,0.082,0.014,0.169,0.014,0.259
				c0,0.105-0.002,0.206-0.008,0.303c-0.006,0.098-0.025,0.184-0.057,0.258c-0.031,0.075-0.08,0.133-0.146,0.177
				c-0.067,0.043-0.161,0.065-0.281,0.065c-0.053,0-0.104-0.004-0.153-0.015c-0.049-0.01-0.092-0.03-0.129-0.058
				c-0.037-0.028-0.067-0.066-0.09-0.114c-0.022-0.048-0.035-0.107-0.037-0.178h0.286C52.73-24.739,52.74-24.695,52.762-24.665
				 M52.746-25.221c0.024,0.042,0.065,0.064,0.123,0.064c0.059,0,0.102-0.022,0.127-0.064c0.025-0.043,0.037-0.115,0.037-0.213
				c0-0.042-0.002-0.081-0.006-0.117c-0.004-0.036-0.012-0.066-0.023-0.092c-0.012-0.025-0.028-0.045-0.051-0.06
				c-0.021-0.014-0.049-0.022-0.084-0.022s-0.063,0.008-0.086,0.022c-0.021,0.015-0.037,0.035-0.048,0.061s-0.019,0.057-0.022,0.092
				c-0.002,0.036-0.004,0.074-0.004,0.116C52.709-25.335,52.721-25.264,52.746-25.221"
        />
        <rect
          x="26.184"
          y="-26.43"
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#008B4A"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M27.098-25.931c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H27.098z M27.006-25.262c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H27.006z"
        />
        <polygon
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          points="28.101,-25.932 28.312,-24.887 28.317,-24.887 28.53,-25.932
				28.971,-25.932 28.971,-24.453 28.698,-24.453 28.698,-25.633 28.694,-25.633 28.431,-24.453 28.198,-24.453 27.935,-25.633
				27.931,-25.633 27.931,-24.453 27.657,-24.453 27.657,-25.932 			"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M29.425-24.871c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.063,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.062-0.013-0.086
				c-0.009-0.025-0.025-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.071-0.014-0.125-0.038-0.162c-0.025-0.039-0.067-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
				c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.871z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M31.016-24.686c0.021,0.045,0.064,0.068,0.13,0.068
				c0.035,0,0.064-0.007,0.085-0.019c0.022-0.013,0.038-0.029,0.05-0.051c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.029,0.005-0.06,0.005-0.091c0-0.034-0.002-0.065-0.007-0.095c-0.005-0.029-0.015-0.056-0.028-0.079
				c-0.013-0.024-0.033-0.043-0.058-0.057c-0.025-0.013-0.058-0.021-0.1-0.021h-0.099V-25.3h0.097c0.032,0,0.058-0.006,0.08-0.019
				c0.022-0.013,0.039-0.03,0.052-0.051c0.013-0.022,0.022-0.047,0.029-0.075c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.07-0.012-0.119-0.035-0.149c-0.024-0.029-0.059-0.043-0.108-0.043c-0.031,0-0.058,0.006-0.077,0.017
				c-0.02,0.012-0.037,0.029-0.047,0.05c-0.011,0.022-0.019,0.047-0.022,0.076c-0.003,0.029-0.005,0.061-0.005,0.095h-0.273
				c0-0.146,0.036-0.255,0.109-0.326c0.073-0.071,0.18-0.107,0.322-0.107c0.134,0,0.237,0.03,0.312,0.092
				c0.074,0.06,0.11,0.155,0.11,0.286c0,0.088-0.019,0.16-0.059,0.216c-0.039,0.056-0.094,0.091-0.164,0.107v0.004
				c0.094,0.015,0.162,0.053,0.202,0.115c0.039,0.061,0.059,0.138,0.059,0.231c0,0.049-0.007,0.101-0.019,0.153
				c-0.013,0.053-0.037,0.1-0.072,0.143c-0.037,0.043-0.085,0.078-0.149,0.104c-0.062,0.026-0.145,0.039-0.247,0.039
				c-0.143,0-0.249-0.038-0.32-0.116c-0.07-0.077-0.104-0.187-0.104-0.329v-0.007h0.286C30.983-24.795,30.994-24.73,31.016-24.686"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M32.164-25.92h0.31v0.931h0.142v0.232h-0.142v0.305h-0.272v-0.305h-0.522
				v-0.244L32.164-25.92z M32.197-25.546l-0.288,0.557h0.292v-0.557H32.197z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M32.734-25.105c0.042-0.066,0.104-0.108,0.188-0.124v-0.004
				c-0.041-0.01-0.073-0.025-0.102-0.046c-0.025-0.021-0.048-0.044-0.064-0.07c-0.017-0.027-0.028-0.054-0.035-0.083
				c-0.008-0.03-0.01-0.057-0.01-0.083c0-0.144,0.037-0.247,0.114-0.31c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.315,0.094s0.115,0.166,0.115,0.31c0,0.026-0.004,0.053-0.012,0.083c-0.008,0.029-0.02,0.056-0.036,0.083
				c-0.017,0.026-0.038,0.049-0.065,0.07c-0.026,0.021-0.06,0.036-0.099,0.046v0.004c0.083,0.016,0.146,0.058,0.187,0.124
				c0.041,0.067,0.063,0.146,0.063,0.239c0,0.043-0.005,0.09-0.016,0.14c-0.01,0.052-0.032,0.099-0.065,0.143
				c-0.032,0.045-0.08,0.081-0.142,0.11c-0.062,0.029-0.143,0.044-0.245,0.044s-0.185-0.015-0.246-0.044
				c-0.062-0.029-0.108-0.065-0.142-0.11c-0.033-0.044-0.056-0.091-0.065-0.143c-0.011-0.05-0.015-0.097-0.015-0.14
				C32.674-24.959,32.693-25.038,32.734-25.105 M32.979-24.773c0.004,0.032,0.013,0.059,0.026,0.082
				c0.013,0.022,0.031,0.041,0.053,0.054c0.021,0.013,0.05,0.019,0.083,0.019c0.032,0,0.06-0.006,0.083-0.019
				c0.021-0.013,0.039-0.032,0.053-0.054c0.012-0.023,0.021-0.05,0.025-0.082c0.006-0.03,0.009-0.063,0.009-0.098
				c0-0.035-0.003-0.069-0.009-0.099c-0.004-0.031-0.014-0.057-0.025-0.08c-0.014-0.023-0.031-0.041-0.053-0.054
				c-0.023-0.013-0.051-0.019-0.083-0.019c-0.033,0-0.062,0.006-0.083,0.019c-0.021,0.013-0.04,0.031-0.053,0.054
				c-0.014,0.023-0.022,0.049-0.026,0.08c-0.006,0.03-0.008,0.064-0.008,0.099C32.972-24.836,32.974-24.803,32.979-24.773
				 M33.035-25.368c0.026,0.033,0.063,0.05,0.106,0.05s0.079-0.017,0.104-0.05c0.027-0.033,0.041-0.085,0.041-0.155
				c0-0.135-0.049-0.201-0.146-0.201s-0.146,0.066-0.146,0.201C32.996-25.453,33.01-25.401,33.035-25.368"
        />
        <rect
          x="55.789"
          y="-26.43"
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#5087C7"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M56.703-25.931c0.076,0,0.14,0.012,0.191,0.037
				c0.053,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.012,0.143-0.035,0.199c-0.022,0.057-0.055,0.103-0.095,0.139c-0.04,0.036-0.091,0.063-0.147,0.081
				c-0.059,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H56.703z M56.611-25.262c0.06,0,0.107-0.018,0.145-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H56.611z"
        />
        <polygon
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          points="57.705,-25.932 57.916,-24.887 57.922,-24.887 58.135,-25.932
				58.576,-25.932 58.576,-24.453 58.303,-24.453 58.303,-25.634 58.299,-25.634 58.035,-24.453 57.803,-24.453 57.541,-25.634
				57.537,-25.634 57.537,-24.453 57.263,-24.453 57.263,-25.932 			"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M59.03-24.871c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.016,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.047-0.044-0.066c-0.021-0.019-0.049-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.09-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.082-0.072,0.193-0.108,0.336-0.108c0.066,0,0.127,0.007,0.184,0.021
				c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.096,0.124c0.022,0.051,0.033,0.111,0.033,0.182v0.041h-0.285
				c0-0.071-0.014-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.125-0.057c-0.032,0-0.061,0.004-0.082,0.014
				c-0.023,0.009-0.041,0.023-0.055,0.038c-0.013,0.016-0.021,0.035-0.025,0.055c-0.006,0.021-0.008,0.042-0.008,0.065
				c0,0.045,0.011,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				s0.063,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.013,0.079,0.013,0.125
				c0,0.156-0.047,0.27-0.137,0.341c-0.09,0.071-0.216,0.105-0.378,0.105c-0.169,0-0.29-0.036-0.362-0.109
				c-0.071-0.073-0.107-0.179-0.107-0.315v-0.061h0.298V-24.871z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M60.297-24.452c0-0.065,0.006-0.125,0.02-0.182
				c0.013-0.057,0.033-0.111,0.061-0.162c0.026-0.051,0.06-0.101,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.148l0.121-0.112
				c0.033-0.029,0.061-0.056,0.08-0.081s0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.002-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.146-0.183c-0.037,0-0.066,0.008-0.088,0.023
				c-0.021,0.015-0.037,0.036-0.05,0.062c-0.011,0.027-0.019,0.057-0.022,0.092c-0.004,0.034-0.004,0.071-0.004,0.11h-0.287v-0.056
				c0-0.137,0.037-0.242,0.109-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
				c0.072,0.07,0.108,0.168,0.108,0.297c0,0.047-0.004,0.09-0.013,0.131c-0.008,0.041-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.063,0.072-0.106,0.109l-0.164,0.154c-0.045,0.04-0.08,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.047,0.099H61.2v0.232H60.297z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M62.203-25.662c-0.061,0.086-0.115,0.178-0.162,0.276
				s-0.09,0.199-0.127,0.302c-0.035,0.104-0.064,0.21-0.089,0.317c-0.022,0.107-0.04,0.212-0.053,0.315h-0.313
				c0.012-0.088,0.027-0.181,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.297c0.035-0.102,0.08-0.203,0.133-0.305
				c0.053-0.103,0.115-0.203,0.186-0.3h-0.617v-0.257h0.904V-25.662z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M62.626-25.488h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.234-0.055
				c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.298V-25.488z"
        />
        <path
          clipPath="url(#NATURALREMEDIES_SVGID_82_)"
          fill="#FFFFFF"
          d="M63.334-25.105c0.043-0.066,0.104-0.108,0.188-0.124v-0.004
				c-0.04-0.01-0.074-0.025-0.102-0.046c-0.025-0.021-0.047-0.044-0.064-0.07c-0.016-0.027-0.027-0.054-0.035-0.083
				c-0.007-0.03-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.115-0.31c0.076-0.063,0.181-0.094,0.315-0.094
				c0.134,0,0.239,0.031,0.315,0.094s0.115,0.166,0.115,0.31c0,0.026-0.004,0.053-0.012,0.083c-0.007,0.029-0.019,0.056-0.035,0.083
				c-0.017,0.026-0.039,0.049-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004c0.082,0.016,0.145,0.058,0.187,0.124
				c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.004,0.09-0.015,0.14c-0.011,0.052-0.032,0.099-0.065,0.143
				c-0.033,0.045-0.08,0.081-0.143,0.11c-0.061,0.029-0.143,0.044-0.245,0.044c-0.102,0-0.184-0.015-0.245-0.044
				c-0.063-0.029-0.109-0.065-0.143-0.11c-0.033-0.044-0.055-0.091-0.064-0.143c-0.012-0.05-0.017-0.097-0.017-0.14
				C63.272-24.959,63.293-25.038,63.334-25.105 M63.578-24.773c0.006,0.032,0.014,0.059,0.027,0.082
				c0.013,0.022,0.029,0.041,0.053,0.054c0.022,0.013,0.051,0.019,0.083,0.019c0.033,0,0.06-0.006,0.083-0.019
				c0.021-0.013,0.039-0.032,0.053-0.054c0.013-0.023,0.022-0.05,0.026-0.082c0.005-0.03,0.009-0.063,0.009-0.098
				c0-0.035-0.004-0.069-0.009-0.099c-0.004-0.031-0.014-0.057-0.026-0.08c-0.014-0.023-0.031-0.041-0.053-0.054
				c-0.023-0.013-0.05-0.019-0.083-0.019c-0.032,0-0.061,0.006-0.083,0.019c-0.023,0.013-0.04,0.031-0.053,0.054
				c-0.014,0.023-0.021,0.049-0.027,0.08c-0.005,0.03-0.007,0.064-0.007,0.099C63.571-24.836,63.573-24.803,63.578-24.773
				 M63.635-25.368c0.027,0.033,0.062,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05c0.026-0.033,0.04-0.085,0.04-0.155
				c0-0.135-0.049-0.201-0.146-0.201s-0.145,0.066-0.145,0.201C63.597-25.453,63.609-25.401,63.635-25.368"
        />
      </g>
    </g>
  );
};

const Mixology = () => {
  return (
    <g id="Mixology">
      <g>
        <defs>
          <rect
            id="MIXOLOGY_SVGID_83_"
            x="0.001"
            y="0.001"
            width="61.687"
            height="61.687"
          />
        </defs>
        <clipPath id="MIXOLOGY_SVGID_84_">
          <use xlinkHref="#MIXOLOGY_SVGID_83_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#8B52A1"
          d="M55.639,61.071H6.312c-3.059,0-5.563-2.504-5.563-5.563V6.181
				c0-3.06,2.504-5.563,5.563-5.563h49.327c3.06,0,5.563,2.503,5.563,5.563v49.327C61.201,58.567,58.698,61.071,55.639,61.071"
        />

        <line
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="none"
          stroke="#663372"
          strokeWidth="1.554"
          strokeMiterlimit="10"
          x1="36.364"
          y1="15.813"
          x2="34.6"
          y2="37.024"
        />
        <rect
          x="3.559"
          y="42.631"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#663372"
          width="54.573"
          height="15.146"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="22.662,10.914 21.711,10.914 21.711,8.791 20.594,10.914
				20.141,10.914 19.017,8.778 19.017,10.914 18.066,10.914 18.066,6.93 19.029,6.93 20.367,9.458 21.705,6.93 22.662,6.93 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M24.43,7.049c0,0.303-0.232,0.529-0.553,0.529
				c-0.321,0-0.547-0.226-0.547-0.529c0-0.292,0.226-0.541,0.547-0.541C24.198,6.508,24.43,6.757,24.43,7.049 M23.401,7.928h0.951
				v2.986h-0.951V7.928z"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="28.059,10.914 26.965,10.914 26.394,10.051 25.817,10.914
				24.728,10.914 25.71,9.467 24.728,7.922 25.817,7.922 26.394,8.85 26.965,7.922 28.059,7.922 27.071,9.467 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M31.439,9.42c0,0.946-0.749,1.577-1.635,1.577
				c-0.904,0-1.653-0.631-1.653-1.577c0-0.939,0.749-1.569,1.653-1.569C30.69,7.852,31.439,8.481,31.439,9.42 M30.523,9.42
				c0-0.464-0.351-0.772-0.719-0.772c-0.387,0-0.739,0.308-0.739,0.772c0,0.47,0.352,0.774,0.739,0.774
				C30.172,10.194,30.523,9.89,30.523,9.42"
        />
        <rect
          x="31.898"
          y="6.633"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          width="0.951"
          height="4.281"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M36.59,9.42c0,0.946-0.75,1.577-1.636,1.577
				c-0.903,0-1.653-0.631-1.653-1.577c0-0.939,0.75-1.569,1.653-1.569C35.84,7.852,36.59,8.481,36.59,9.42 M35.674,9.42
				c0-0.464-0.352-0.772-0.72-0.772c-0.388,0-0.738,0.308-0.738,0.772c0,0.47,0.351,0.774,0.738,0.774
				C35.322,10.194,35.674,9.89,35.674,9.42"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M40.129,10.617c0,0.921-0.701,1.427-1.67,1.427
				c-0.685,0-1.209-0.238-1.5-0.72l0.791-0.428c0.227,0.268,0.394,0.369,0.703,0.369c0.434,0,0.742-0.256,0.742-0.725v-0.084
				c-0.214,0.184-0.511,0.286-0.896,0.286c-0.779,0-1.439-0.618-1.439-1.464c0-0.826,0.66-1.438,1.439-1.438
				c0.386,0,0.683,0.101,0.896,0.285V7.929h0.934V10.617z M39.195,9.278c0-0.398-0.314-0.677-0.695-0.677
				c-0.416,0-0.713,0.279-0.713,0.677c0,0.4,0.297,0.684,0.713,0.684C38.881,9.962,39.195,9.678,39.195,9.278"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="42.23,11.924 41.203,11.924 41.684,10.812 40.447,7.922
				41.47,7.922 42.178,9.665 42.934,7.922 43.955,7.922 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFDD00"
          d="M27.676,19.627c0,2.634-2.135,4.769-4.769,4.769
				c-2.635,0-4.77-2.135-4.77-4.769c0-2.634,2.135-4.769,4.77-4.769C25.541,14.858,27.676,16.993,27.676,19.627"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M21.043,16.072l1.35,2.337c0.096,0.166,0.349,0.098,0.349-0.093V15.62
				c0-0.109-0.095-0.2-0.204-0.19c-0.502,0.043-0.978,0.172-1.415,0.375C21.024,15.851,20.989,15.977,21.043,16.072"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M23.07,20.938v2.695c0,0.11,0.095,0.2,0.204,0.19
				c0.502-0.043,0.978-0.172,1.415-0.375c0.099-0.047,0.135-0.172,0.08-0.267l-1.349-2.338C23.324,20.678,23.07,20.745,23.07,20.938
				"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M23.959,18.829l2.335-1.349c0.095-0.054,0.126-0.181,0.063-0.271
				c-0.282-0.402-0.632-0.752-1.034-1.034c-0.09-0.063-0.217-0.032-0.272,0.063l-1.348,2.335
				C23.607,18.739,23.793,18.925,23.959,18.829"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M26.728,21.409c0.204-0.436,0.333-0.913,0.376-1.415
				c0.009-0.108-0.082-0.204-0.191-0.204h-2.695c-0.192,0-0.26,0.254-0.094,0.351l2.337,1.349
				C26.556,21.544,26.682,21.509,26.728,21.409"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M24.217,19.463h2.695c0.11,0,0.2-0.095,0.19-0.204
				c-0.043-0.502-0.172-0.979-0.375-1.415c-0.046-0.099-0.171-0.135-0.266-0.08l-2.338,1.349
				C23.957,19.21,24.025,19.463,24.217,19.463"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M22.742,23.633v-2.695c0-0.192-0.253-0.259-0.349-0.094l-1.35,2.338
				c-0.055,0.095-0.019,0.22,0.08,0.267c0.437,0.203,0.913,0.332,1.415,0.375C22.647,23.833,22.742,23.743,22.742,23.633"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M23.07,15.62v2.695c0,0.191,0.254,0.26,0.35,0.094l1.349-2.337
				c0.055-0.096,0.019-0.221-0.08-0.267c-0.437-0.203-0.913-0.332-1.415-0.376C23.165,15.42,23.07,15.511,23.07,15.62"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M21.595,19.79H18.9c-0.11,0-0.2,0.096-0.19,0.205
				c0.043,0.502,0.172,0.978,0.375,1.414c0.046,0.1,0.171,0.136,0.266,0.08l2.338-1.35C21.855,20.044,21.787,19.79,21.595,19.79"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M23.704,20.68l1.349,2.335c0.054,0.096,0.181,0.127,0.271,0.063
				c0.402-0.28,0.752-0.631,1.034-1.033c0.063-0.09,0.032-0.217-0.063-0.271l-2.335-1.348C23.793,20.329,23.607,20.515,23.704,20.68
				"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M21.853,20.425l-2.335,1.348c-0.095,0.055-0.126,0.182-0.063,0.271
				c0.282,0.402,0.632,0.752,1.034,1.034c0.09,0.063,0.217,0.032,0.272-0.063l1.348-2.336
				C22.205,20.515,22.019,20.328,21.853,20.425"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M22.109,18.573l-1.349-2.335c-0.054-0.095-0.181-0.126-0.271-0.063
				c-0.402,0.282-0.752,0.632-1.034,1.035c-0.063,0.09-0.032,0.217,0.063,0.271l2.335,1.348
				C22.019,18.925,22.205,18.739,22.109,18.573"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M19.085,17.845c-0.204,0.436-0.333,0.912-0.376,1.414
				c-0.009,0.108,0.082,0.205,0.192,0.205h2.694c0.192,0,0.26-0.255,0.094-0.351l-2.337-1.349
				C19.257,17.709,19.132,17.745,19.085,17.845"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M23.678,19.627c0,0.426-0.346,0.771-0.771,0.771
				c-0.427,0-0.772-0.345-0.772-0.771c0-0.427,0.345-0.772,0.772-0.772C23.332,18.854,23.678,19.2,23.678,19.627"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFDD00"
          d="M39.221,37.563c-0.006,0.097-0.014,0.192-0.014,0.29
				c0,2.635,2.135,4.77,4.769,4.77s4.769-2.135,4.769-4.77c0-0.098-0.008-0.193-0.014-0.29H39.221z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M44.14,39.163v2.695c0,0.11,0.095,0.2,0.204,0.19
				c0.502-0.043,0.979-0.172,1.415-0.375c0.099-0.046,0.135-0.172,0.079-0.267l-1.35-2.338C44.393,38.903,44.14,38.972,44.14,39.163
				"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M47.797,39.636c0.203-0.437,0.333-0.914,0.376-1.416
				c0.009-0.108-0.082-0.203-0.192-0.203h-2.693c-0.192,0-0.26,0.254-0.094,0.35l2.336,1.35
				C47.625,39.771,47.75,39.734,47.797,39.636"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M43.812,41.858v-2.695c0-0.191-0.254-0.258-0.35-0.094l-1.349,2.338
				c-0.056,0.096-0.02,0.221,0.08,0.268c0.436,0.201,0.912,0.332,1.413,0.375C43.717,42.06,43.812,41.969,43.812,41.858"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M42.664,38.017H39.97c-0.11,0-0.2,0.095-0.19,0.204
				c0.043,0.502,0.172,0.978,0.375,1.415c0.047,0.099,0.172,0.135,0.266,0.079l2.339-1.349
				C42.925,38.27,42.855,38.017,42.664,38.017"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M44.773,38.905l1.348,2.336c0.055,0.094,0.182,0.127,0.271,0.063
				c0.402-0.281,0.752-0.633,1.035-1.034c0.063-0.089,0.031-0.216-0.063-0.272l-2.336-1.347
				C44.863,38.555,44.678,38.739,44.773,38.905"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M42.922,38.649l-2.334,1.349c-0.096,0.056-0.127,0.183-0.063,0.272
				c0.28,0.401,0.632,0.752,1.034,1.033c0.088,0.063,0.217,0.032,0.271-0.063l1.348-2.336C43.274,38.74,43.088,38.554,42.922,38.649
				"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M35.309,42.63h-9.11c-0.779,0-1.428-0.597-1.493-1.373l-1.764-21.213
				c-0.019-0.224,0.159-0.417,0.384-0.417h14.856c0.226,0,0.403,0.193,0.385,0.417l-1.765,21.213
				C36.736,42.033,36.088,42.63,35.309,42.63"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFDD00"
          points="36.794,29.991 24.713,29.991 24.261,24.901 37.217,24.901 			"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          points="36.371,35.082 25.136,35.082 24.712,29.991 36.794,29.991 			"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F37321"
          points="35.947,40.172 25.56,40.172 25.137,35.082 36.371,35.082 			"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          points="
				35.947,40.172 25.56,40.172 25.137,35.082 36.371,35.082 			"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#663372"
          points="35.947,40.172 36.064,38.774 25.443,38.774 25.56,40.172 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M28.182,27.435l-1.295,1.295c-0.123,0.123-0.323,0.123-0.446,0l-1.294-1.295
				c-0.124-0.123-0.124-0.322,0-0.445l1.294-1.295c0.123-0.123,0.323-0.123,0.446,0l1.295,1.295
				C28.305,27.112,28.305,27.312,28.182,27.435"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M31.023,27.807h-1.831c-0.174,0-0.316-0.142-0.316-0.315v-1.832
				c0-0.174,0.142-0.314,0.316-0.314h1.831c0.174,0,0.315,0.141,0.315,0.314v1.832C31.338,27.665,31.197,27.807,31.023,27.807"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#B394BF"
          d="M56.012,4.408c0.689,0,1.271,0.581,1.271,1.27v50.334
				c0,0.688-0.581,1.271-1.271,1.271H5.678c-0.689,0-1.27-0.582-1.27-1.271V5.678c0-0.689,0.581-1.27,1.27-1.27H56.012
				 M56.012,0.001H5.678c-3.122,0-5.677,2.555-5.677,5.677v50.334c0,3.121,2.555,5.676,5.677,5.676h50.333
				c3.123,0,5.678-2.555,5.678-5.676V5.678C61.689,2.556,59.135,0.001,56.012,0.001"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F37321"
          d="M54.671,39.81c-1.583,2.707-5.062,3.617-7.769,2.035
				c-2.707-1.584-3.617-5.062-2.035-7.768c1.584-2.707,5.062-3.619,7.769-2.035C55.342,33.624,56.254,37.103,54.671,39.81"
        />

        <circle
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="49.769"
          cy="36.943"
          r="5.678"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M52.965,33.192c-0.097,0.164-0.509,0.103-0.921-0.139
				c-0.413-0.24-0.669-0.57-0.573-0.736c0.098-0.164,0.511-0.103,0.922,0.139C52.805,32.698,53.063,33.026,52.965,33.192"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M46.155,34.278c-0.079,0.137-0.255,0.184-0.392,0.104
				s-0.182-0.256-0.102-0.393c0.078-0.136,0.254-0.182,0.391-0.102C46.188,33.968,46.234,34.142,46.155,34.278"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M45.607,35.216c-0.08,0.137-0.255,0.183-0.393,0.103
				c-0.135-0.08-0.182-0.255-0.102-0.392s0.256-0.183,0.391-0.103C45.641,34.904,45.687,35.079,45.607,35.216"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M46.604,35.141c-0.08,0.136-0.255,0.182-0.391,0.103
				c-0.137-0.08-0.184-0.256-0.104-0.392s0.256-0.183,0.392-0.103C46.638,34.829,46.684,35.003,46.604,35.141"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M50.727,40.858c-0.08,0.137-0.255,0.183-0.393,0.103
				c-0.135-0.079-0.182-0.254-0.102-0.392c0.08-0.136,0.256-0.182,0.391-0.102C50.761,40.547,50.807,40.722,50.727,40.858"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          d="M51.72,40.597c-0.08,0.137-0.255,0.183-0.392,0.103
				s-0.182-0.255-0.102-0.392c0.078-0.137,0.254-0.183,0.391-0.103S51.799,40.46,51.72,40.597"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#72BF44"
          d="M33.057,24.19c-0.606,0.621-0.862,1.453-0.773,2.254
				c0.803,0.067,1.627-0.209,2.232-0.832c0.606-0.621,0.863-1.453,0.773-2.254C34.487,23.291,33.662,23.567,33.057,24.19"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#72BF44"
          d="M35.5,25.942c0.372,0.361,0.87,0.516,1.35,0.463
				c0.041-0.481-0.125-0.975-0.497-1.338c-0.372-0.362-0.871-0.516-1.349-0.463C34.963,25.086,35.127,25.579,35.5,25.942"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#5087C7"
          d="M30.856,55.361c-2.638,0-4.774-2.136-4.774-4.772s2.136-4.772,4.774-4.772
				c2.638,0,4.774,2.136,4.774,4.772S33.494,55.361,30.856,55.361"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M30.856,45.816c2.638,0,4.774,2.136,4.774,4.773
				c0,2.636-2.137,4.771-4.774,4.771c-2.638,0-4.774-2.136-4.774-4.771C26.082,47.952,28.218,45.816,30.856,45.816 M30.856,45.404
				c-2.86,0-5.186,2.326-5.186,5.186c0,2.857,2.326,5.184,5.186,5.184c2.858,0,5.185-2.326,5.185-5.184
				C36.041,47.73,33.715,45.404,30.856,45.404"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M33.35,48.644c-0.238,0-0.464,0.108-0.619,0.252l-0.014-0.013
				c0.072-0.118,0.169-0.346,0.169-0.716c0-0.883-0.885-1.551-2.029-1.551c-1.145,0-2.042,0.668-2.042,1.551
				c0,0.37,0.12,0.598,0.18,0.716l-0.013,0.013c-0.155-0.144-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.705-1.457,2.018
				c0,1.289,0.716,2.017,1.492,2.017c0.716,0,1.218-0.395,1.67-0.848c-0.071-0.108-0.179-0.216-0.298-0.216
				c-0.119,0-0.251,0.085-0.477,0.264c-0.298,0.227-0.561,0.357-0.908,0.357c-0.608,0-0.869-0.68-0.869-1.574
				c0-0.955,0.25-1.587,0.895-1.587c0.286,0,0.476,0.083,0.668,0.263c0.286,0.262,0.381,0.333,0.512,0.333
				c0.096,0,0.156-0.06,0.179-0.095c-0.214-0.215-0.525-0.667-0.525-1.252c0-0.67,0.561-1.135,1.612-1.135
				c1.049,0,1.611,0.465,1.611,1.135c0,0.585-0.312,1.037-0.526,1.252c0.024,0.035,0.083,0.095,0.179,0.095
				c0.132,0,0.227-0.071,0.513-0.333c0.179-0.18,0.382-0.263,0.668-0.263c0.646,0,0.896,0.632,0.896,1.587
				c0,0.895-0.263,1.574-0.871,1.574c-0.346,0-0.621-0.131-0.907-0.357c-0.226-0.179-0.357-0.264-0.477-0.264
				c-0.119,0-0.226,0.107-0.298,0.216c0.441,0.453,0.955,0.848,1.671,0.848c0.775,0,1.491-0.728,1.491-2.017
				C34.806,49.349,34.078,48.644,33.35,48.644"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M31.656,48.62h-0.537c-0.036,0-0.059-0.023-0.071-0.047L30.88,48.06
				c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.047-0.06,0.047H30.08c-0.024,0-0.035,0.012-0.012,0.023
				l0.442,0.323c0.024,0.022,0.024,0.06,0.012,0.083l-0.167,0.501c-0.011,0.024-0.011,0.048,0.024,0.024l0.442-0.31
				c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.023,0.023,0.035,0.012,0.023-0.013l-0.166-0.513c-0.013-0.035,0-0.071,0.024-0.083
				l0.44-0.323C31.69,48.632,31.679,48.62,31.656,48.62"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M31.25,52.151c-0.226-0.572-0.406-0.965-0.406-1.443
				c0-0.203,0.06-0.381,0.096-0.477c0.047-0.096,0.06-0.155,0.011-0.155c-0.071,0-0.226,0.167-0.321,0.31
				c-0.073,0.119-0.156,0.348-0.156,0.525c0,0.335,0.108,0.691,0.179,0.906c0.132,0.371,0.251,0.8,0.251,1.207
				c0,0.644-0.287,1.085-0.799,1.478c0.047,0.037,0.166,0.096,0.31,0.096c0.5,0,1.098-0.56,1.098-1.323
				C31.513,52.819,31.381,52.51,31.25,52.151"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M-12.303-25.939c0.076,0,0.14,0.012,0.191,0.038
				c0.053,0.025,0.095,0.059,0.126,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.096,0.139c-0.04,0.036-0.09,0.063-0.147,0.081
				c-0.058,0.018-0.123,0.027-0.194,0.027h-0.159v0.59h-0.298v-1.479H-12.303z M-12.394-25.27c0.059,0,0.107-0.018,0.144-0.054
				c0.036-0.036,0.054-0.092,0.054-0.168c0-0.074-0.015-0.131-0.047-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H-12.394z
				"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="-11.3,-25.939 -11.088,-24.895 -11.083,-24.895 -10.871,-25.939
				-10.43,-25.939 -10.43,-24.46 -10.703,-24.46 -10.703,-25.642 -10.707,-25.642 -10.97,-24.46 -11.203,-24.46 -11.466,-25.642
				-11.47,-25.642 -11.47,-24.46 -11.743,-24.46 -11.743,-25.939 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M-9.975-24.878c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.051,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.025,0.012,0.058,0.017,0.096,0.017
				c0.046,0,0.087-0.015,0.124-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.024-0.024-0.047-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.126-0.048
				c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.088-0.056-0.12-0.092c-0.034-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.193-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.183,0.022c0.057,0.014,0.104,0.038,0.145,0.07c0.042,0.033,0.073,0.074,0.096,0.124
				c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.125-0.057
				c-0.033,0-0.06,0.004-0.083,0.015c-0.022,0.008-0.039,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.004,0.021-0.007,0.042-0.007,0.065c0,0.045,0.011,0.083,0.03,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.138,0.078c0.037,0.027,0.065,0.056,0.087,0.087c0.021,0.031,0.036,0.066,0.044,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.27-0.135,0.341s-0.216,0.107-0.378,0.107c-0.169,0-0.29-0.037-0.362-0.11
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.878z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M-8.406-25.666l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.084
				c0.044-0.017,0.098-0.026,0.162-0.026c0.07,0,0.128,0.014,0.175,0.041c0.046,0.026,0.083,0.062,0.109,0.107
				c0.027,0.044,0.047,0.093,0.058,0.15c0.01,0.056,0.016,0.113,0.016,0.17c0,0.082-0.007,0.156-0.021,0.223
				c-0.013,0.066-0.038,0.122-0.073,0.171c-0.036,0.047-0.084,0.083-0.144,0.107c-0.061,0.025-0.138,0.037-0.232,0.037
				c-0.093,0-0.168-0.013-0.225-0.038c-0.058-0.025-0.101-0.058-0.132-0.098c-0.03-0.041-0.05-0.085-0.061-0.133
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.287c0,0.024,0.001,0.051,0.004,0.079c0.002,0.028,0.009,0.053,0.019,0.077
				c0.011,0.024,0.025,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.076,0.024c0.063,0,0.107-0.024,0.134-0.071
				c0.028-0.048,0.041-0.119,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.095
				c-0.013-0.024-0.03-0.041-0.053-0.05c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.025-0.037,0.058-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H-8.406z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M-7.714-24.46c0-0.065,0.007-0.125,0.02-0.182
				c0.013-0.057,0.033-0.11,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.049,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.028,0.005-0.061,0.005-0.099c0-0.121-0.048-0.182-0.145-0.182c-0.037,0-0.067,0.007-0.088,0.022
				c-0.022,0.015-0.038,0.037-0.05,0.063c-0.011,0.026-0.02,0.056-0.023,0.091c-0.003,0.034-0.004,0.071-0.004,0.11h-0.287v-0.056
				c0-0.137,0.036-0.242,0.11-0.315c0.073-0.074,0.188-0.11,0.346-0.11c0.149,0,0.26,0.034,0.332,0.104
				c0.071,0.069,0.108,0.167,0.108,0.296c0,0.047-0.004,0.09-0.013,0.131s-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.11c-0.029,0.035-0.064,0.071-0.106,0.108l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.048,0.099h0.554v0.232H-7.714z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M-6.719-24.46c0-0.065,0.007-0.125,0.02-0.182
				c0.013-0.057,0.033-0.11,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.049,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.028,0.005-0.061,0.005-0.099c0-0.121-0.048-0.182-0.145-0.182c-0.037,0-0.067,0.007-0.088,0.022
				c-0.022,0.015-0.038,0.037-0.05,0.063c-0.011,0.026-0.02,0.056-0.023,0.091c-0.003,0.034-0.004,0.071-0.004,0.11h-0.287v-0.056
				c0-0.137,0.036-0.242,0.11-0.315c0.073-0.074,0.188-0.11,0.346-0.11c0.149,0,0.26,0.034,0.332,0.104
				c0.071,0.069,0.108,0.167,0.108,0.296c0,0.047-0.004,0.09-0.013,0.131c-0.009,0.041-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.11c-0.029,0.035-0.064,0.071-0.106,0.108l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.048,0.099h0.554v0.232H-6.719z"
        />
        <rect
          x="-3.383"
          y="-26.438"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#8B52A1"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M-2.47-25.939c0.076,0,0.14,0.012,0.191,0.038
				c0.053,0.026,0.095,0.059,0.126,0.101c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.096,0.139c-0.04,0.036-0.09,0.063-0.147,0.081
				c-0.058,0.018-0.123,0.027-0.194,0.027h-0.159v0.59h-0.298v-1.479H-2.47z M-2.561-25.27c0.059,0,0.107-0.018,0.144-0.054
				c0.036-0.036,0.054-0.092,0.054-0.168c0-0.074-0.015-0.131-0.047-0.17C-2.442-25.7-2.495-25.72-2.57-25.72h-0.124v0.45H-2.561z"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="-1.467,-25.939 -1.256,-24.895 -1.251,-24.895 -1.038,-25.939
				-0.597,-25.939 -0.597,-24.459 -0.87,-24.459 -0.87,-25.642 -0.874,-25.642 -1.137,-24.459 -1.37,-24.459 -1.633,-25.642
				-1.637,-25.642 -1.637,-24.459 -1.91,-24.459 -1.91,-25.939 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M-0.143-24.878c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.025,0.012,0.058,0.017,0.096,0.017
				c0.046,0,0.087-0.014,0.124-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.024-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.126-0.048
				c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.088-0.056-0.12-0.092c-0.034-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.193-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.183,0.022c0.057,0.015,0.104,0.038,0.145,0.07c0.042,0.033,0.073,0.074,0.096,0.124
				c0.022,0.051,0.034,0.111,0.034,0.182v0.041H0.209c0-0.07-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.057-0.125-0.057
				c-0.033,0-0.06,0.005-0.083,0.015c-0.022,0.009-0.039,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.004,0.021-0.007,0.042-0.007,0.065c0,0.045,0.011,0.083,0.03,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.044,0.102
				c0.008,0.038,0.013,0.079,0.013,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.878z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M1.426-25.666l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.085
				c0.044-0.017,0.098-0.025,0.162-0.025c0.07,0,0.129,0.013,0.175,0.04c0.046,0.027,0.083,0.063,0.109,0.107
				C1.98-25.25,2-25.201,2.011-25.145c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.066-0.037,0.123-0.073,0.171c-0.035,0.048-0.084,0.083-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039H1.41c0,0.024,0.001,0.05,0.004,0.078c0.002,0.028,0.009,0.054,0.019,0.078
				c0.011,0.024,0.025,0.044,0.045,0.06s0.045,0.023,0.077,0.023c0.062,0,0.106-0.023,0.133-0.071
				c0.028-0.047,0.041-0.119,0.041-0.214c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.041-0.015-0.072-0.028-0.096
				c-0.013-0.024-0.03-0.04-0.053-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.024-0.037,0.057-0.042,0.099H1.149l0.053-0.798h0.765v0.232H1.426z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M2.458-25.496H2.167v-0.195h0.027c0.099,0,0.177-0.018,0.234-0.056
				c0.058-0.038,0.095-0.097,0.11-0.18h0.219v1.467H2.458V-25.496z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M3.438-24.693c0.021,0.045,0.064,0.067,0.13,0.067
				c0.035,0,0.064-0.006,0.085-0.018c0.022-0.013,0.038-0.03,0.05-0.051c0.011-0.021,0.019-0.047,0.023-0.076
				c0.003-0.029,0.005-0.059,0.005-0.091c0-0.033-0.002-0.064-0.008-0.094c-0.004-0.03-0.014-0.056-0.027-0.08
				c-0.014-0.023-0.033-0.042-0.059-0.057c-0.024-0.013-0.058-0.02-0.099-0.02H3.439v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.019
				c0.021-0.013,0.039-0.03,0.052-0.052c0.013-0.021,0.022-0.046,0.029-0.074c0.006-0.028,0.009-0.056,0.009-0.087
				c0-0.071-0.012-0.12-0.035-0.149c-0.024-0.029-0.06-0.043-0.108-0.043c-0.032,0-0.058,0.005-0.077,0.017
				c-0.021,0.012-0.037,0.029-0.047,0.05c-0.011,0.021-0.019,0.047-0.022,0.076c-0.003,0.029-0.005,0.061-0.005,0.095H3.139
				c0-0.147,0.036-0.255,0.109-0.326c0.073-0.071,0.18-0.107,0.322-0.107c0.133,0,0.237,0.03,0.311,0.091
				c0.074,0.061,0.111,0.156,0.111,0.286c0,0.089-0.02,0.161-0.059,0.217c-0.04,0.056-0.094,0.091-0.165,0.106v0.004
				c0.095,0.016,0.163,0.054,0.202,0.115c0.04,0.062,0.059,0.139,0.059,0.232c0,0.049-0.006,0.101-0.018,0.153
				c-0.013,0.052-0.037,0.1-0.073,0.143s-0.085,0.077-0.148,0.104c-0.062,0.026-0.146,0.039-0.248,0.039
				c-0.142,0-0.249-0.039-0.319-0.116c-0.07-0.077-0.106-0.188-0.106-0.33v-0.006h0.287C3.405-24.803,3.416-24.738,3.438-24.693"
        />
        <rect
          x="6.485"
          y="-26.438"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#663372"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M7.398-25.939c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.069,0.141c0.014,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.024,0.057-0.055,0.103-0.096,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				S7.404-25.05,7.334-25.05h-0.16v0.59H6.876v-1.479H7.398z M7.307-25.27c0.06,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17C7.426-25.7,7.374-25.72,7.299-25.72H7.174v0.45H7.307z"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="8.401,-25.939 8.612,-24.895 8.617,-24.895 8.83,-25.939
				9.272,-25.939 9.272,-24.459 8.999,-24.459 8.999,-25.642 8.994,-25.642 8.731,-24.459 8.499,-24.459 8.236,-25.642
				8.232,-25.642 8.232,-24.459 7.958,-24.459 7.958,-25.939 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M9.726-24.878c0,0.035,0.003,0.066,0.009,0.095
				c0.005,0.028,0.015,0.052,0.03,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.017,0.097,0.017
				c0.046,0,0.087-0.014,0.124-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.024-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.081-0.053c-0.034-0.015-0.076-0.031-0.126-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.146,0.04-0.255,0.122-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.022c0.056,0.015,0.104,0.038,0.145,0.07c0.041,0.033,0.073,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.022,0.009-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
				C9.749-25.62,9.747-25.6,9.747-25.576c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.14,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.044,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.168,0-0.289-0.037-0.361-0.11c-0.073-0.073-0.109-0.179-0.109-0.315v-0.061h0.298V-24.878z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M11.294-25.666l-0.031,0.334h0.004c0.028-0.039,0.064-0.067,0.108-0.085
				c0.044-0.017,0.098-0.025,0.161-0.025c0.071,0,0.129,0.013,0.176,0.04c0.046,0.027,0.083,0.063,0.11,0.107
				c0.026,0.044,0.045,0.094,0.056,0.15c0.011,0.056,0.017,0.113,0.017,0.171c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.066-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.083-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037s-0.101-0.058-0.132-0.099c-0.03-0.041-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.05,0.005,0.078c0.002,0.028,0.009,0.054,0.02,0.078
				c0.01,0.024,0.025,0.044,0.044,0.06c0.02,0.016,0.045,0.023,0.076,0.023c0.063,0,0.107-0.023,0.135-0.071
				c0.026-0.047,0.04-0.119,0.04-0.214c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.041-0.014-0.072-0.027-0.096
				c-0.014-0.024-0.031-0.04-0.054-0.049c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.022,0.024-0.037,0.057-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H11.294z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M11.987-24.459c0-0.065,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.051,0.06-0.101,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.148l0.121-0.112
				c0.033-0.03,0.06-0.056,0.08-0.081s0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
				c0.072,0.069,0.107,0.168,0.107,0.296c0,0.047-0.004,0.091-0.012,0.132c-0.008,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.042,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.025,0.039-0.041,0.071-0.047,0.099h0.553v0.232H11.987z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M12.993-25.438c0.006-0.094,0.025-0.177,0.055-0.249
				c0.031-0.073,0.077-0.131,0.138-0.174c0.062-0.044,0.147-0.066,0.256-0.066c0.09,0,0.164,0.013,0.223,0.039
				c0.059,0.025,0.106,0.066,0.141,0.123s0.06,0.13,0.075,0.22c0.014,0.091,0.022,0.201,0.022,0.331c0,0.102-0.004,0.2-0.011,0.294
				c-0.007,0.094-0.026,0.178-0.056,0.25c-0.031,0.072-0.077,0.13-0.138,0.171c-0.061,0.042-0.147,0.063-0.256,0.063
				c-0.088,0-0.162-0.012-0.222-0.036c-0.059-0.024-0.106-0.063-0.141-0.12c-0.036-0.056-0.061-0.13-0.075-0.221
				c-0.014-0.09-0.022-0.201-0.022-0.33C12.981-25.246,12.985-25.344,12.993-25.438 M13.288-24.852
				c0.005,0.059,0.013,0.105,0.025,0.139c0.013,0.034,0.029,0.057,0.051,0.069c0.02,0.013,0.046,0.018,0.078,0.018
				c0.033,0,0.061-0.007,0.081-0.023c0.02-0.016,0.037-0.043,0.048-0.081c0.012-0.038,0.021-0.089,0.026-0.153
				c0.004-0.063,0.007-0.142,0.007-0.236v-0.159c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.059-0.014-0.105-0.027-0.139
				c-0.012-0.034-0.029-0.057-0.049-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.008-0.08,0.024
				c-0.021,0.016-0.038,0.043-0.05,0.081c-0.011,0.038-0.019,0.088-0.024,0.152c-0.005,0.063-0.008,0.142-0.008,0.236v0.16
				C13.28-24.987,13.283-24.911,13.288-24.852"
        />
        <rect
          x="16.313"
          y="-26.438"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFDD00"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M17.228-25.939c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.228z M17.137-25.27c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.45H17.137z
				"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="18.232,-25.939 18.443,-24.895 18.448,-24.895 18.661,-25.939
				19.102,-25.939 19.102,-24.459 18.829,-24.459 18.829,-25.642 18.825,-25.642 18.562,-24.459 18.329,-24.459 18.066,-25.642
				18.062,-25.642 18.062,-24.459 17.788,-24.459 17.788,-25.939 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M19.556-24.878c0,0.035,0.003,0.066,0.009,0.095
				c0.004,0.028,0.015,0.052,0.03,0.071c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
				c0.045,0,0.087-0.014,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				s-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.022s0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.022,0.009-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.066,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.168,0-0.289-0.037-0.362-0.11c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.878z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M21.163-25.496h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.056
				c0.058-0.038,0.094-0.097,0.109-0.18h0.22v1.467h-0.298V-25.496z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M21.828-25.438c0.006-0.094,0.025-0.177,0.056-0.249
				c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.148-0.066,0.256-0.066c0.091,0,0.165,0.013,0.224,0.039
				c0.058,0.025,0.105,0.066,0.14,0.123c0.035,0.057,0.06,0.13,0.075,0.22c0.014,0.091,0.022,0.201,0.022,0.331
				c0,0.102-0.004,0.2-0.011,0.294c-0.007,0.094-0.025,0.178-0.055,0.25c-0.031,0.072-0.077,0.13-0.139,0.171
				c-0.061,0.042-0.146,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.059-0.024-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.06-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C21.817-25.246,21.821-25.344,21.828-25.438
				 M22.123-24.852c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.029,0.057,0.05,0.069c0.021,0.013,0.046,0.018,0.078,0.018
				c0.034,0,0.06-0.007,0.081-0.023s0.037-0.043,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.153
				c0.004-0.063,0.007-0.142,0.007-0.236v-0.159c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.059-0.014-0.105-0.026-0.139
				c-0.013-0.034-0.029-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.059,0.008-0.08,0.024
				s-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.088-0.025,0.152c-0.005,0.063-0.007,0.142-0.007,0.236v0.16
				C22.116-24.987,22.118-24.911,22.123-24.852"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M23.155-24.671c0.019,0.031,0.054,0.046,0.104,0.046
				c0.041,0,0.072-0.009,0.096-0.028c0.023-0.018,0.042-0.044,0.054-0.078c0.014-0.033,0.022-0.072,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.067-0.106,0.09c-0.046,0.023-0.103,0.034-0.169,0.034
				c-0.065,0-0.12-0.011-0.165-0.034c-0.047-0.023-0.084-0.055-0.112-0.096c-0.028-0.04-0.049-0.089-0.062-0.144
				c-0.013-0.057-0.019-0.118-0.019-0.184c0-0.17,0.037-0.3,0.112-0.39c0.076-0.089,0.196-0.134,0.362-0.134
				c0.102,0,0.184,0.018,0.244,0.057c0.061,0.038,0.108,0.088,0.14,0.151c0.033,0.063,0.054,0.136,0.064,0.219
				c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.003,0.206-0.009,0.303c-0.006,0.098-0.025,0.184-0.056,0.259
				c-0.031,0.074-0.08,0.133-0.147,0.176c-0.067,0.044-0.161,0.066-0.281,0.066c-0.053,0-0.103-0.005-0.153-0.016
				c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.047-0.035-0.107-0.037-0.177h0.287
				C23.125-24.747,23.134-24.704,23.155-24.671 M23.139-25.229c0.025,0.043,0.066,0.064,0.124,0.064
				c0.059,0,0.102-0.021,0.127-0.064c0.025-0.042,0.037-0.114,0.037-0.213c0-0.042-0.002-0.08-0.006-0.117
				c-0.004-0.035-0.012-0.066-0.024-0.091c-0.011-0.026-0.028-0.046-0.05-0.061c-0.021-0.014-0.049-0.022-0.084-0.022
				c-0.035,0-0.064,0.008-0.086,0.022c-0.021,0.015-0.037,0.035-0.048,0.061c-0.011,0.027-0.019,0.057-0.022,0.093
				c-0.003,0.035-0.005,0.073-0.005,0.115C23.103-25.343,23.114-25.271,23.139-25.229"
        />
        <rect
          x="36.052"
          y="-26.438"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F37321"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M36.965-25.939c0.076,0,0.141,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.022,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.159v0.59h-0.298v-1.479H36.965z M36.874-25.27c0.06,0,0.106-0.018,0.144-0.054
				s0.056-0.092,0.056-0.168c0-0.074-0.017-0.131-0.048-0.17c-0.032-0.038-0.086-0.058-0.159-0.058h-0.125v0.45H36.874z"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="37.969,-25.939 38.18,-24.895 38.185,-24.895 38.397,-25.939
				38.838,-25.939 38.838,-24.459 38.565,-24.459 38.565,-25.642 38.562,-25.642 38.299,-24.459 38.065,-24.459 37.803,-25.642
				37.799,-25.642 37.799,-24.459 37.524,-24.459 37.524,-25.939 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M39.293-24.878c0,0.035,0.003,0.066,0.009,0.095
				c0.004,0.028,0.015,0.052,0.03,0.071c0.015,0.019,0.035,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.014,0.123-0.045c0.035-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.015-0.086
				c-0.009-0.024-0.023-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.074-0.031-0.125-0.048
				c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.014-0.046-0.022-0.1-0.022-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.192-0.108,0.336-0.108
				c0.065,0,0.127,0.007,0.183,0.022c0.057,0.015,0.104,0.038,0.146,0.07c0.041,0.033,0.071,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.07-0.013-0.124-0.038-0.162c-0.023-0.038-0.065-0.057-0.123-0.057
				c-0.033,0-0.062,0.005-0.084,0.015c-0.022,0.009-0.039,0.022-0.053,0.037c-0.013,0.016-0.021,0.035-0.027,0.055
				c-0.004,0.021-0.006,0.042-0.006,0.065c0,0.045,0.01,0.083,0.028,0.115c0.019,0.031,0.062,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.035,0.066,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341s-0.216,0.107-0.378,0.107c-0.168,0-0.289-0.037-0.362-0.11
				c-0.071-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.878z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M40.899-25.496h-0.292v-0.195h0.025c0.101,0,0.178-0.018,0.235-0.056
				s0.095-0.097,0.108-0.18h0.221v1.467h-0.298V-25.496z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M41.856-25.666l-0.03,0.334h0.004c0.027-0.039,0.063-0.067,0.107-0.085
				c0.044-0.017,0.098-0.025,0.161-0.025c0.071,0,0.13,0.013,0.176,0.04c0.046,0.027,0.083,0.063,0.109,0.107
				s0.046,0.094,0.057,0.15c0.011,0.056,0.017,0.113,0.017,0.171c0,0.082-0.006,0.156-0.021,0.222
				c-0.013,0.066-0.038,0.123-0.073,0.171c-0.035,0.048-0.084,0.083-0.144,0.108c-0.062,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.041-0.051-0.085-0.062-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.05,0.005,0.078c0.002,0.028,0.009,0.054,0.019,0.078
				c0.012,0.024,0.025,0.044,0.045,0.06c0.021,0.016,0.045,0.023,0.077,0.023c0.062,0,0.106-0.023,0.134-0.071
				c0.026-0.047,0.041-0.119,0.041-0.214c0-0.061-0.004-0.111-0.01-0.151c-0.005-0.041-0.015-0.072-0.027-0.096
				c-0.014-0.024-0.03-0.04-0.054-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.075,0.012-0.099,0.036
				s-0.037,0.057-0.042,0.099h-0.269l0.055-0.798h0.765v0.232H41.856z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M42.56-25.438c0.006-0.094,0.024-0.177,0.056-0.249
				c0.03-0.073,0.076-0.131,0.137-0.174c0.063-0.044,0.148-0.066,0.256-0.066c0.092,0,0.166,0.013,0.225,0.039
				c0.059,0.025,0.105,0.066,0.141,0.123c0.034,0.057,0.06,0.13,0.074,0.22c0.014,0.091,0.021,0.201,0.021,0.331
				c0,0.102-0.004,0.2-0.01,0.294c-0.008,0.094-0.025,0.178-0.056,0.25c-0.03,0.072-0.077,0.13-0.139,0.171
				c-0.062,0.042-0.146,0.063-0.257,0.063c-0.088,0-0.161-0.012-0.221-0.036c-0.059-0.024-0.106-0.063-0.142-0.12
				c-0.036-0.056-0.06-0.13-0.075-0.221c-0.014-0.09-0.021-0.201-0.021-0.33C42.549-25.246,42.553-25.344,42.56-25.438
				 M42.854-24.852c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.029,0.057,0.05,0.069c0.021,0.013,0.046,0.018,0.077,0.018
				c0.035,0,0.062-0.007,0.082-0.023c0.021-0.016,0.037-0.043,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.153
				c0.004-0.063,0.006-0.142,0.006-0.236v-0.159c0-0.093-0.002-0.168-0.006-0.227c-0.006-0.059-0.015-0.105-0.026-0.139
				c-0.013-0.034-0.029-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.08-0.019c-0.032,0-0.059,0.008-0.08,0.024
				c-0.021,0.016-0.037,0.043-0.049,0.081s-0.02,0.088-0.024,0.152c-0.005,0.063-0.007,0.142-0.007,0.236v0.16
				C42.848-24.987,42.85-24.911,42.854-24.852"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M43.846-25.666l-0.031,0.334h0.005c0.028-0.039,0.063-0.067,0.108-0.085
				c0.043-0.017,0.098-0.025,0.16-0.025c0.071,0,0.131,0.013,0.176,0.04c0.047,0.027,0.084,0.063,0.109,0.107
				c0.027,0.044,0.046,0.094,0.057,0.15c0.012,0.056,0.018,0.113,0.018,0.171c0,0.082-0.008,0.156-0.021,0.222
				c-0.013,0.066-0.037,0.123-0.072,0.171c-0.036,0.048-0.084,0.083-0.145,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.092,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.041-0.051-0.085-0.062-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.05,0.005,0.078c0.002,0.028,0.01,0.054,0.02,0.078
				c0.011,0.024,0.024,0.044,0.045,0.06c0.02,0.016,0.045,0.023,0.076,0.023c0.063,0,0.106-0.023,0.134-0.071
				c0.026-0.047,0.04-0.119,0.04-0.214c0-0.061-0.002-0.111-0.008-0.151c-0.006-0.041-0.016-0.072-0.028-0.096
				c-0.013-0.024-0.03-0.04-0.054-0.049c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.075,0.012-0.099,0.036
				c-0.022,0.024-0.036,0.057-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H43.846z"
        />
        <rect
          x="45.92"
          y="-26.438"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#72BF44"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M46.834-25.939c0.076,0,0.14,0.012,0.191,0.038
				c0.053,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.068,0.141c0.014,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.022,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.147,0.081
				c-0.059,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H46.834z M46.742-25.27c0.059,0,0.107-0.018,0.145-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.45H46.742z"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="47.837,-25.939 48.048,-24.895 48.053,-24.895 48.266,-25.939
				48.707,-25.939 48.707,-24.459 48.434,-24.459 48.434,-25.642 48.43,-25.642 48.167,-24.459 47.934,-24.459 47.671,-25.642
				47.667,-25.642 47.667,-24.459 47.393,-24.459 47.393,-25.939 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M49.161-24.878c0,0.035,0.003,0.066,0.009,0.095
				c0.004,0.028,0.016,0.052,0.03,0.071s0.036,0.035,0.062,0.045c0.025,0.012,0.059,0.017,0.097,0.017
				c0.045,0,0.087-0.014,0.122-0.045c0.037-0.029,0.057-0.075,0.057-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.01-0.024-0.024-0.046-0.045-0.066c-0.021-0.019-0.049-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.049-0.026-0.09-0.056-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.096,0.124
				c0.022,0.051,0.033,0.111,0.033,0.182v0.041h-0.285c0-0.07-0.013-0.124-0.037-0.162s-0.066-0.057-0.125-0.057
				c-0.032,0-0.061,0.005-0.082,0.015c-0.022,0.009-0.041,0.022-0.053,0.037c-0.014,0.016-0.022,0.035-0.027,0.055
				c-0.006,0.021-0.008,0.042-0.008,0.065c0,0.045,0.011,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099
				c0.058,0.025,0.104,0.051,0.14,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.066,0.045,0.102
				c0.009,0.038,0.013,0.079,0.013,0.125c0,0.156-0.047,0.27-0.137,0.341s-0.216,0.107-0.378,0.107c-0.168,0-0.289-0.037-0.362-0.11
				c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.298V-24.878z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M50.752-24.693c0.021,0.045,0.064,0.067,0.13,0.067
				c0.035,0,0.063-0.006,0.086-0.018c0.021-0.013,0.037-0.03,0.05-0.051c0.012-0.021,0.019-0.047,0.022-0.076
				c0.003-0.029,0.005-0.059,0.005-0.091c0-0.033-0.002-0.064-0.007-0.094c-0.005-0.03-0.015-0.056-0.028-0.08
				c-0.013-0.023-0.033-0.042-0.058-0.057c-0.025-0.013-0.058-0.02-0.099-0.02h-0.101v-0.195h0.097c0.032,0,0.06-0.006,0.08-0.019
				c0.022-0.013,0.039-0.03,0.053-0.052c0.014-0.021,0.022-0.046,0.028-0.074c0.007-0.028,0.009-0.056,0.009-0.087
				c0-0.071-0.012-0.12-0.035-0.149c-0.023-0.029-0.059-0.043-0.107-0.043c-0.031,0-0.057,0.005-0.077,0.017
				c-0.021,0.012-0.036,0.029-0.047,0.05s-0.019,0.047-0.022,0.076c-0.002,0.029-0.004,0.061-0.004,0.095h-0.273
				c0-0.147,0.035-0.255,0.109-0.326c0.072-0.071,0.181-0.107,0.321-0.107c0.134,0,0.237,0.03,0.312,0.091
				c0.074,0.061,0.111,0.156,0.111,0.286c0,0.089-0.02,0.161-0.06,0.217c-0.039,0.056-0.095,0.091-0.164,0.106v0.004
				c0.095,0.016,0.162,0.054,0.202,0.115c0.039,0.062,0.059,0.139,0.059,0.232c0,0.049-0.006,0.101-0.019,0.153
				c-0.013,0.052-0.037,0.1-0.072,0.143c-0.037,0.043-0.085,0.077-0.149,0.104c-0.062,0.026-0.145,0.039-0.246,0.039
				c-0.143,0-0.25-0.039-0.32-0.116s-0.105-0.188-0.105-0.33v-0.006h0.286C50.719-24.803,50.73-24.738,50.752-24.693"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M52-25.687c-0.02-0.031-0.056-0.046-0.105-0.046
				c-0.039,0-0.069,0.01-0.094,0.028c-0.023,0.019-0.043,0.045-0.057,0.078c-0.014,0.033-0.021,0.073-0.025,0.118
				c-0.004,0.046-0.007,0.096-0.007,0.149v0.073h0.005c0.025-0.039,0.061-0.069,0.107-0.092c0.046-0.023,0.102-0.034,0.168-0.034
				c0.064,0,0.12,0.011,0.166,0.034c0.045,0.023,0.082,0.054,0.111,0.095c0.027,0.041,0.049,0.089,0.062,0.145
				c0.013,0.056,0.021,0.117,0.021,0.184c0,0.17-0.039,0.298-0.113,0.386c-0.075,0.088-0.196,0.132-0.362,0.132
				c-0.103,0-0.183-0.018-0.243-0.054c-0.062-0.036-0.109-0.085-0.141-0.148c-0.033-0.064-0.055-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.204,0.01-0.302c0.006-0.097,0.024-0.184,0.057-0.259
				c0.029-0.075,0.078-0.135,0.145-0.181c0.067-0.046,0.161-0.068,0.282-0.068c0.052,0,0.104,0.006,0.151,0.018
				c0.051,0.013,0.094,0.033,0.131,0.062c0.037,0.029,0.068,0.068,0.091,0.115c0.022,0.048,0.034,0.107,0.034,0.178h-0.285
				C52.029-25.611,52.02-25.655,52-25.687 M51.733-24.8c0.005,0.036,0.012,0.067,0.024,0.092c0.012,0.026,0.027,0.046,0.049,0.06
				c0.021,0.015,0.049,0.022,0.084,0.022c0.037,0,0.064-0.007,0.086-0.022c0.021-0.014,0.037-0.034,0.049-0.061
				c0.012-0.026,0.019-0.057,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.115c0-0.1-0.013-0.171-0.037-0.214
				c-0.025-0.043-0.066-0.064-0.125-0.064c-0.06,0-0.102,0.021-0.125,0.064c-0.025,0.043-0.039,0.114-0.039,0.214
				C51.727-24.875,51.729-24.835,51.733-24.8"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M52.471-25.112c0.042-0.067,0.104-0.108,0.187-0.125v-0.004
				c-0.04-0.01-0.073-0.025-0.101-0.046c-0.026-0.021-0.049-0.044-0.065-0.07c-0.016-0.027-0.028-0.054-0.034-0.083
				c-0.008-0.029-0.011-0.057-0.011-0.083c0-0.144,0.038-0.247,0.115-0.31c0.076-0.063,0.181-0.094,0.315-0.094
				s0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.31c0,0.026-0.004,0.054-0.012,0.083
				c-0.008,0.029-0.02,0.056-0.037,0.083c-0.016,0.026-0.037,0.049-0.064,0.07s-0.061,0.036-0.099,0.046v0.004
				c0.083,0.017,0.145,0.058,0.187,0.125c0.041,0.066,0.063,0.145,0.063,0.238c0,0.043-0.006,0.09-0.016,0.14
				c-0.01,0.052-0.031,0.099-0.065,0.144c-0.032,0.044-0.08,0.08-0.142,0.11c-0.062,0.028-0.144,0.043-0.246,0.043
				c-0.102,0-0.184-0.015-0.246-0.043c-0.062-0.03-0.107-0.066-0.142-0.11c-0.032-0.045-0.054-0.092-0.065-0.144
				c-0.01-0.05-0.015-0.097-0.015-0.14C52.409-24.967,52.431-25.046,52.471-25.112 M52.715-24.78
				c0.004,0.031,0.014,0.058,0.026,0.081s0.031,0.041,0.054,0.054c0.021,0.013,0.049,0.019,0.082,0.019s0.061-0.006,0.084-0.019
				c0.021-0.013,0.039-0.031,0.053-0.054c0.012-0.023,0.021-0.05,0.027-0.081c0.004-0.031,0.006-0.064,0.006-0.099
				s-0.002-0.069-0.006-0.099c-0.006-0.03-0.016-0.057-0.027-0.08c-0.014-0.023-0.031-0.04-0.053-0.053
				c-0.023-0.014-0.051-0.02-0.084-0.02s-0.061,0.006-0.082,0.02c-0.022,0.013-0.041,0.03-0.054,0.053
				c-0.013,0.023-0.022,0.05-0.026,0.08c-0.006,0.03-0.008,0.064-0.008,0.099S52.709-24.811,52.715-24.78 M52.771-25.376
				c0.025,0.033,0.063,0.05,0.105,0.05c0.045,0,0.08-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155
				c0-0.135-0.048-0.201-0.146-0.201c-0.097,0-0.145,0.066-0.145,0.201C52.732-25.461,52.746-25.409,52.771-25.376"
        />
        <rect
          x="26.184"
          y="-26.438"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#F99F2A"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M27.097-25.939c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081s-0.123,0.027-0.193,0.027h-0.16
				v0.59h-0.298v-1.479H27.097z M27.006-25.27c0.059,0,0.107-0.018,0.144-0.054c0.037-0.036,0.055-0.092,0.055-0.168
				c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.45H27.006z"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="28.1,-25.939 28.311,-24.895 28.316,-24.895 28.529,-25.939
				28.97,-25.939 28.97,-24.459 28.697,-24.459 28.697,-25.642 28.693,-25.642 28.43,-24.459 28.197,-24.459 27.934,-25.642
				27.93,-25.642 27.93,-24.459 27.656,-24.459 27.656,-25.939 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M29.424-24.878c0,0.035,0.003,0.066,0.009,0.095
				c0.004,0.028,0.015,0.052,0.03,0.071s0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.017,0.097,0.017
				c0.045,0,0.087-0.014,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.024-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.022c0.056,0.015,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.023,0.009-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.066,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.168,0-0.289-0.037-0.362-0.11c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.878z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M31.031-25.496h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.056
				c0.058-0.038,0.094-0.097,0.109-0.18h0.22v1.467h-0.298V-25.496z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M32.01-24.693c0.021,0.045,0.064,0.067,0.131,0.067
				c0.034,0,0.063-0.006,0.086-0.018c0.021-0.013,0.036-0.03,0.049-0.051c0.012-0.021,0.019-0.047,0.022-0.076
				c0.003-0.029,0.005-0.059,0.005-0.091c0-0.033-0.002-0.064-0.007-0.094c-0.005-0.03-0.015-0.056-0.028-0.08
				c-0.013-0.023-0.033-0.042-0.058-0.057c-0.024-0.013-0.058-0.02-0.099-0.02h-0.101v-0.195h0.097c0.033,0,0.06-0.006,0.08-0.019
				c0.022-0.013,0.039-0.03,0.053-0.052c0.014-0.021,0.022-0.046,0.029-0.074c0.006-0.028,0.008-0.056,0.008-0.087
				c0-0.071-0.012-0.12-0.034-0.149c-0.024-0.029-0.06-0.043-0.108-0.043c-0.031,0-0.057,0.005-0.076,0.017
				c-0.021,0.012-0.037,0.029-0.048,0.05s-0.018,0.047-0.022,0.076c-0.003,0.029-0.005,0.061-0.005,0.095h-0.273
				c0-0.147,0.036-0.255,0.109-0.326c0.073-0.071,0.181-0.107,0.323-0.107c0.133,0,0.236,0.03,0.312,0.091
				c0.073,0.061,0.11,0.156,0.11,0.286c0,0.089-0.02,0.161-0.06,0.217c-0.039,0.056-0.094,0.091-0.164,0.106v0.004
				c0.095,0.016,0.162,0.054,0.202,0.115c0.039,0.062,0.059,0.139,0.059,0.232c0,0.049-0.007,0.101-0.019,0.153
				c-0.013,0.052-0.037,0.1-0.072,0.143c-0.036,0.043-0.085,0.077-0.149,0.104c-0.062,0.026-0.145,0.039-0.246,0.039
				c-0.142,0-0.25-0.039-0.32-0.116c-0.07-0.077-0.105-0.188-0.105-0.33v-0.006h0.286C31.977-24.803,31.989-24.738,32.01-24.693"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M33.592-25.67c-0.061,0.085-0.115,0.178-0.162,0.275
				c-0.047,0.099-0.09,0.2-0.125,0.303c-0.037,0.103-0.066,0.21-0.09,0.316c-0.023,0.108-0.041,0.213-0.054,0.316h-0.313
				c0.012-0.088,0.027-0.181,0.051-0.278c0.021-0.098,0.052-0.197,0.088-0.298c0.035-0.101,0.081-0.202,0.133-0.304
				c0.053-0.103,0.116-0.203,0.188-0.301h-0.619v-0.257h0.904V-25.67z"
        />
        <rect
          x="55.789"
          y="-26.438"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#5087C7"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M56.701-25.939c0.076,0,0.141,0.012,0.193,0.038
				c0.051,0.026,0.094,0.059,0.125,0.101c0.031,0.041,0.055,0.088,0.068,0.141c0.014,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.033,0.199c-0.023,0.057-0.055,0.103-0.096,0.139s-0.09,0.063-0.147,0.081s-0.123,0.027-0.193,0.027
				h-0.159v0.59H56.18v-1.479H56.701z M56.611-25.27c0.059,0,0.106-0.018,0.144-0.054s0.056-0.092,0.056-0.168
				c0-0.074-0.017-0.131-0.049-0.17c-0.031-0.038-0.085-0.058-0.158-0.058h-0.125v0.45H56.611z"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          points="57.705,-25.939 57.916,-24.895 57.922,-24.895 58.135,-25.939
				58.575,-25.939 58.575,-24.459 58.303,-24.459 58.303,-25.642 58.299,-25.642 58.035,-24.459 57.803,-24.459 57.539,-25.642
				57.535,-25.642 57.535,-24.459 57.262,-24.459 57.262,-25.939 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M59.029-24.878c0,0.035,0.004,0.066,0.01,0.095
				c0.004,0.028,0.015,0.052,0.029,0.071c0.016,0.019,0.036,0.035,0.061,0.045c0.027,0.012,0.06,0.017,0.098,0.017
				c0.045,0,0.087-0.014,0.123-0.045c0.036-0.029,0.057-0.075,0.057-0.137c0-0.034-0.006-0.062-0.015-0.086
				s-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
				c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.146,0.041-0.255,0.123-0.327c0.08-0.072,0.192-0.108,0.336-0.108
				c0.065,0,0.127,0.007,0.183,0.022c0.056,0.015,0.104,0.038,0.146,0.07c0.041,0.033,0.071,0.074,0.095,0.124
				c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.07-0.014-0.124-0.038-0.162s-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.022,0.009-0.04,0.022-0.054,0.037c-0.012,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.123,0.086l0.23,0.099
				c0.057,0.025,0.104,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.066,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.27-0.135,0.341s-0.217,0.107-0.379,0.107c-0.168,0-0.289-0.037-0.361-0.11
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.878z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M60.296-24.459c0-0.065,0.007-0.126,0.021-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.143-0.148l0.121-0.112
				c0.033-0.03,0.061-0.056,0.078-0.081c0.021-0.025,0.037-0.05,0.048-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				c0.002-0.029,0.004-0.062,0.004-0.099c0-0.122-0.047-0.183-0.145-0.183c-0.038,0-0.066,0.008-0.088,0.023
				s-0.038,0.036-0.051,0.062c-0.012,0.027-0.02,0.057-0.022,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.056
				c0-0.136,0.036-0.241,0.11-0.315c0.072-0.073,0.188-0.109,0.346-0.109c0.148,0,0.26,0.034,0.332,0.103
				c0.071,0.069,0.107,0.168,0.107,0.296c0,0.047-0.004,0.091-0.012,0.132c-0.01,0.041-0.021,0.08-0.039,0.117
				c-0.019,0.038-0.043,0.074-0.072,0.109c-0.028,0.035-0.063,0.072-0.105,0.109l-0.165,0.153c-0.045,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.04,0.071-0.047,0.099H61.2v0.232H60.296z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M62.203-25.67c-0.062,0.085-0.115,0.178-0.162,0.275
				c-0.049,0.099-0.09,0.2-0.127,0.303c-0.035,0.103-0.065,0.21-0.088,0.316c-0.024,0.108-0.043,0.213-0.055,0.316h-0.313
				c0.011-0.088,0.026-0.181,0.05-0.278c0.022-0.098,0.052-0.197,0.088-0.298s0.081-0.202,0.133-0.304
				c0.054-0.103,0.115-0.203,0.187-0.301h-0.617v-0.257h0.904V-25.67z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M62.625-25.496h-0.291v-0.195h0.025c0.1,0,0.178-0.018,0.235-0.056
				s0.094-0.097,0.108-0.18h0.221v1.467h-0.299V-25.496z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#FFFFFF"
          d="M63.334-25.112c0.041-0.067,0.104-0.108,0.187-0.125v-0.004
				c-0.04-0.01-0.073-0.025-0.101-0.046c-0.026-0.021-0.049-0.044-0.065-0.07c-0.017-0.027-0.028-0.054-0.035-0.083
				c-0.007-0.029-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.115-0.31c0.075-0.063,0.181-0.094,0.315-0.094
				s0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.31c0,0.026-0.004,0.054-0.012,0.083
				c-0.008,0.029-0.02,0.056-0.037,0.083c-0.016,0.026-0.037,0.049-0.064,0.07s-0.061,0.036-0.1,0.046v0.004
				c0.084,0.017,0.146,0.058,0.188,0.125c0.041,0.066,0.063,0.145,0.063,0.238c0,0.043-0.006,0.09-0.016,0.14
				c-0.01,0.052-0.031,0.099-0.066,0.144c-0.031,0.044-0.08,0.08-0.141,0.11c-0.062,0.028-0.144,0.043-0.246,0.043
				c-0.102,0-0.184-0.015-0.246-0.043c-0.062-0.03-0.107-0.066-0.142-0.11c-0.033-0.045-0.054-0.092-0.065-0.144
				c-0.01-0.05-0.015-0.097-0.015-0.14C63.272-24.967,63.293-25.046,63.334-25.112 M63.578-24.78
				c0.004,0.031,0.014,0.058,0.026,0.081s0.031,0.041,0.054,0.054c0.021,0.013,0.049,0.019,0.082,0.019s0.061-0.006,0.084-0.019
				c0.021-0.013,0.039-0.031,0.053-0.054c0.012-0.023,0.021-0.05,0.026-0.081s0.007-0.064,0.007-0.099s-0.002-0.069-0.007-0.099
				c-0.005-0.03-0.015-0.057-0.026-0.08c-0.014-0.023-0.031-0.04-0.053-0.053c-0.023-0.014-0.051-0.02-0.084-0.02
				s-0.061,0.006-0.082,0.02c-0.022,0.013-0.041,0.03-0.054,0.053c-0.013,0.023-0.022,0.05-0.026,0.08
				c-0.006,0.03-0.008,0.063-0.008,0.099S63.572-24.811,63.578-24.78 M63.635-25.376c0.025,0.033,0.063,0.05,0.105,0.05
				c0.045,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.201-0.146-0.201
				c-0.097,0-0.145,0.066-0.145,0.201C63.596-25.461,63.608-25.409,63.635-25.376"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M12.71,75.938c0.592,0,0.973,0.391,0.973,0.991
				c0,0.594-0.381,0.986-0.973,0.986c-0.588,0-0.968-0.393-0.968-0.986C11.742,76.328,12.122,75.938,12.71,75.938 M12.71,77.526
				c0.352,0,0.458-0.301,0.458-0.598c0-0.302-0.106-0.604-0.458-0.604c-0.348,0-0.453,0.302-0.453,0.604
				C12.257,77.226,12.362,77.526,12.71,77.526"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M14.668,75.988h0.378v0.345h-0.378v0.929c0,0.174,0.044,0.218,0.218,0.218
				c0.054,0,0.105-0.004,0.16-0.015v0.403c-0.087,0.014-0.2,0.018-0.301,0.018c-0.316,0-0.592-0.073-0.592-0.446v-1.106h-0.312
				v-0.345h0.312v-0.563h0.515V75.988z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M15.716,77.052c0.014,0.326,0.174,0.475,0.46,0.475
				c0.208,0,0.375-0.127,0.408-0.243h0.453c-0.145,0.443-0.453,0.632-0.878,0.632c-0.592,0-0.958-0.406-0.958-0.986
				c0-0.563,0.388-0.992,0.958-0.992c0.639,0,0.946,0.538,0.911,1.115H15.716z M16.555,76.725c-0.047-0.261-0.16-0.399-0.41-0.399
				c-0.327,0-0.421,0.255-0.428,0.399H16.555z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M17.773,77.254c0.004,0.226,0.192,0.316,0.396,0.316
				c0.149,0,0.337-0.058,0.337-0.24c0-0.155-0.214-0.21-0.584-0.29c-0.298-0.064-0.595-0.17-0.595-0.501
				c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.138,0.828,0.598H18.48c-0.014-0.199-0.166-0.254-0.351-0.254
				c-0.116,0-0.287,0.022-0.287,0.175c0,0.186,0.29,0.21,0.584,0.279c0.301,0.069,0.596,0.178,0.596,0.526
				c0,0.494-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.163-0.882-0.66H17.773z"
        />
        <rect
          x="8.916"
          y="80.467"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M12.021,79.029h0.889c0.798,0,1.244,0.398,1.244,1.233
				c0,0.868-0.38,1.358-1.244,1.358h-0.889V79.029z M12.365,81.33h0.574c0.235,0,0.871-0.064,0.871-1.02
				c0-0.616-0.228-0.99-0.864-0.99h-0.581V81.33z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M15.332,79.701c0.595,0,0.911,0.432,0.911,0.983
				c0,0.548-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.421,80.133,14.737,79.701,15.332,79.701 M15.332,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C14.748,81.138,15.009,81.392,15.332,81.392"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M17.613,79.744h0.291v0.297h0.006c0.131-0.231,0.341-0.341,0.61-0.341
				c0.494,0,0.646,0.283,0.646,0.687v1.233h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
				h-0.309V79.744z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M20.439,79.701c0.595,0,0.911,0.432,0.911,0.983
				c0,0.548-0.316,0.979-0.911,0.979s-0.911-0.432-0.911-0.979C19.528,80.133,19.844,79.701,20.439,79.701 M20.439,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C19.855,81.138,20.116,81.392,20.439,81.392"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M22.14,79.744h0.374v0.272H22.14v1.165c0,0.142,0.04,0.167,0.233,0.167
				h0.141v0.271h-0.236c-0.319,0-0.447-0.064-0.447-0.41v-1.193h-0.318v-0.272h0.318v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M25.525,81.613c-0.055,0.032-0.123,0.051-0.222,0.051
				c-0.16,0-0.261-0.088-0.261-0.29c-0.171,0.199-0.399,0.29-0.66,0.29c-0.341,0-0.621-0.152-0.621-0.526
				c0-0.424,0.316-0.515,0.635-0.577c0.341-0.064,0.631-0.043,0.631-0.275c0-0.269-0.221-0.313-0.417-0.313
				c-0.261,0-0.453,0.081-0.468,0.355h-0.309c0.018-0.465,0.378-0.628,0.795-0.628c0.338,0,0.704,0.077,0.704,0.516v0.966
				c0,0.145,0,0.21,0.098,0.21c0.026,0,0.055-0.003,0.095-0.018V81.613z M25.024,80.651c-0.12,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.036-0.378,0.109-0.378,0.337c0,0.204,0.175,0.276,0.363,0.276c0.407,0,0.574-0.254,0.574-0.424V80.651z"
        />
        <rect
          x="25.829"
          y="79.029"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          width="0.308"
          height="2.591"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M27.043,79.744h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M29.358,81.025c-0.084,0.413-0.381,0.639-0.803,0.639
				c-0.598,0-0.881-0.414-0.899-0.987c0-0.562,0.37-0.976,0.881-0.976c0.664,0,0.868,0.62,0.849,1.074h-1.404
				c-0.011,0.325,0.175,0.616,0.585,0.616c0.254,0,0.432-0.123,0.486-0.366H29.358z M29.06,80.503
				c-0.014-0.294-0.236-0.53-0.541-0.53c-0.323,0-0.515,0.243-0.537,0.53H29.06z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M29.694,79.744h0.291v0.396h0.006c0.149-0.302,0.356-0.45,0.69-0.44v0.327
				c-0.497,0-0.678,0.283-0.678,0.758v0.835h-0.309V79.744z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M33.584,81.613c-0.056,0.032-0.123,0.051-0.223,0.051
				c-0.16,0-0.262-0.088-0.262-0.29c-0.17,0.199-0.398,0.29-0.659,0.29c-0.341,0-0.621-0.152-0.621-0.526
				c0-0.424,0.315-0.515,0.636-0.577c0.34-0.064,0.631-0.043,0.631-0.275c0-0.269-0.222-0.313-0.418-0.313
				c-0.261,0-0.453,0.081-0.468,0.355h-0.308c0.018-0.465,0.378-0.628,0.794-0.628c0.338,0,0.704,0.077,0.704,0.516v0.966
				c0,0.145,0,0.21,0.098,0.21c0.025,0,0.055-0.003,0.096-0.018V81.613z M33.082,80.651c-0.119,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.036-0.377,0.109-0.377,0.337c0,0.204,0.174,0.276,0.363,0.276c0.405,0,0.572-0.254,0.572-0.424V80.651z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M33.857,79.744h0.291v0.396h0.006c0.148-0.302,0.357-0.45,0.689-0.44v0.327
				c-0.496,0-0.678,0.283-0.678,0.758v0.835h-0.309V79.744z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M35.505,79.744h0.374v0.272h-0.374v1.165c0,0.142,0.039,0.167,0.231,0.167
				h0.143v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.318v-0.272h0.318v-0.563h0.31V79.744z"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          points="38.076,81.621 37.751,81.621 37.369,80.129 37.361,80.129
				36.984,81.621 36.65,81.621 36.048,79.744 36.389,79.744 36.814,81.279 36.822,81.279 37.199,79.744 37.535,79.744
				37.928,81.279 37.936,81.279 38.355,79.744 38.675,79.744 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M39.777,79.701c0.596,0,0.911,0.432,0.911,0.983
				c0,0.548-0.315,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C38.866,80.133,39.183,79.701,39.777,79.701 M39.777,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.322,0-0.584,0.254-0.584,0.712
				C39.193,81.138,39.455,81.392,39.777,81.392"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M41.039,79.744h0.291v0.396h0.006c0.149-0.302,0.356-0.45,0.69-0.44v0.327
				c-0.497,0-0.679,0.283-0.679,0.758v0.835h-0.309V79.744z"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          points="42.275,79.029 42.585,79.029 42.585,80.564 43.441,79.744
				43.855,79.744 43.111,80.427 43.909,81.62 43.518,81.62 42.874,80.641 42.585,80.909 42.585,81.62 42.275,81.62 			"
        />
        <rect
          x="8.916"
          y="84.223"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M14.089,84.441c0,0.652-0.375,0.998-1.02,0.998
				c-0.668,0-1.063-0.31-1.063-0.998v-1.655h0.345v1.655c0,0.457,0.26,0.707,0.718,0.707c0.435,0,0.675-0.25,0.675-0.707v-1.655
				h0.345V84.441z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M14.777,84.785c0.011,0.276,0.25,0.363,0.505,0.363
				c0.192,0,0.453-0.044,0.453-0.28c0-0.238-0.305-0.278-0.613-0.348c-0.305-0.069-0.614-0.171-0.614-0.53
				c0-0.378,0.374-0.532,0.701-0.532c0.414,0,0.744,0.13,0.769,0.588H15.67c-0.022-0.24-0.233-0.316-0.436-0.316
				c-0.185,0-0.399,0.051-0.399,0.239c0,0.222,0.326,0.258,0.614,0.327c0.308,0.068,0.612,0.171,0.612,0.534
				c0,0.445-0.417,0.591-0.798,0.591c-0.421,0-0.777-0.171-0.794-0.636H14.777z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M18.002,84.782c-0.083,0.413-0.381,0.638-0.802,0.638
				c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.62,0.849,1.074h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.366H18.002z M17.704,84.26
				c-0.014-0.294-0.236-0.53-0.54-0.53c-0.323,0-0.515,0.243-0.538,0.53H17.704z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M19.786,83.501h0.374v0.271h-0.374v1.165c0,0.143,0.039,0.167,0.232,0.167
				h0.142v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319v-0.271h0.319v-0.563h0.309V83.501z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M20.827,83.164h-0.308v-0.378h0.308V83.164z M20.519,83.501h0.308v1.876
				h-0.308V83.501z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M22.923,85.217c0,0.61-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.142-0.776-0.551h0.308c0.015,0.225,0.283,0.304,0.486,0.304c0.403,0,0.563-0.29,0.563-0.711v-0.123h-0.007
				c-0.102,0.229-0.349,0.341-0.585,0.341c-0.558,0-0.837-0.438-0.837-0.95c0-0.443,0.217-0.991,0.866-0.991
				c0.236,0,0.447,0.105,0.559,0.312h0.004v-0.268h0.29V85.217z M22.618,84.401c0-0.331-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.322-0.556,0.683c0,0.337,0.124,0.714,0.53,0.714C22.466,85.126,22.618,84.753,22.618,84.401"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M23.387,82.786h0.308v0.991h0.008c0.102-0.226,0.366-0.319,0.591-0.319
				c0.494,0,0.646,0.282,0.646,0.686v1.233h-0.308v-1.271c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.06
				h-0.308V82.786z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M25.832,83.501h0.374v0.271h-0.374v1.165c0,0.143,0.04,0.167,0.232,0.167
				h0.142v0.272H25.97c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319v-0.271h0.319v-0.563h0.309V83.501z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M28.363,83.457c0.595,0,0.911,0.433,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.452,83.89,27.768,83.457,28.363,83.457 M28.363,85.148
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C27.779,84.895,28.04,85.148,28.363,85.148"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          points="30.481,85.377 30.151,85.377 29.456,83.501 29.799,83.501
				30.325,85.065 30.333,85.065 30.844,83.501 31.167,83.501 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M33.049,84.782c-0.082,0.413-0.379,0.638-0.801,0.638
				c-0.599,0-0.883-0.414-0.901-0.986c0-0.563,0.371-0.977,0.883-0.977c0.662,0,0.867,0.62,0.849,1.074h-1.405
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.485-0.366H33.049z M32.752,84.26
				c-0.014-0.295-0.236-0.53-0.539-0.53c-0.324,0-0.517,0.243-0.539,0.53H32.752z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M33.387,83.501h0.289v0.396h0.008c0.148-0.301,0.355-0.45,0.689-0.439v0.327
				c-0.496,0-0.679,0.282-0.679,0.758v0.835h-0.308V83.501z"
        />
        <rect
          x="34.623"
          y="82.786"
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          width="0.309"
          height="2.591"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M36.219,83.457c0.596,0,0.912,0.433,0.912,0.984
				c0,0.547-0.316,0.979-0.912,0.979c-0.594,0-0.91-0.432-0.91-0.979C35.309,83.89,35.625,83.457,36.219,83.457 M36.219,85.148
				c0.324,0,0.584-0.254,0.584-0.707c0-0.458-0.26-0.712-0.584-0.712c-0.322,0-0.584,0.254-0.584,0.712
				C35.635,84.895,35.896,85.148,36.219,85.148"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M38.766,84.104c-0.047-0.236-0.21-0.374-0.465-0.374
				c-0.446,0-0.584,0.352-0.584,0.733c0,0.345,0.156,0.686,0.548,0.686c0.297,0,0.476-0.174,0.515-0.457h0.317
				c-0.07,0.457-0.356,0.729-0.828,0.729c-0.573,0-0.878-0.398-0.878-0.957c0-0.563,0.29-1.006,0.885-1.006
				c0.426,0,0.766,0.2,0.811,0.646H38.766z"
        />
        <polygon
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          points="39.458,82.786 39.767,82.786 39.767,84.32 40.623,83.501
				41.037,83.501 40.293,84.183 41.091,85.377 40.699,85.377 40.056,84.396 39.767,84.666 39.767,85.377 39.458,85.377 			"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M41.648,83.164H41.34v-0.378h0.309V83.164z M41.34,83.501h0.309v1.876H41.34
				V83.501z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M42.127,83.501h0.291v0.297h0.006c0.131-0.232,0.341-0.341,0.609-0.341
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.309v-1.271c0-0.228-0.146-0.378-0.381-0.378c-0.373,0-0.556,0.251-0.556,0.589v1.06
				h-0.308V83.501z"
        />
        <path
          clipPath="url(#MIXOLOGY_SVGID_84_)"
          fill="#EC008C"
          d="M45.762,85.217c0,0.61-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.142-0.775-0.551h0.308c0.015,0.225,0.282,0.304,0.485,0.304c0.403,0,0.563-0.29,0.563-0.711v-0.123h-0.007
				c-0.102,0.229-0.35,0.341-0.585,0.341c-0.558,0-0.838-0.438-0.838-0.95c0-0.443,0.218-0.991,0.866-0.991
				c0.236,0,0.447,0.105,0.56,0.312h0.004v-0.268h0.29V85.217z M45.457,84.401c0-0.331-0.146-0.672-0.533-0.672
				c-0.396,0-0.557,0.322-0.557,0.683c0,0.337,0.125,0.714,0.531,0.714S45.457,84.753,45.457,84.401"
        />
      </g>
    </g>
  );
};

const Meditation = () => {
  return (
    <g id="Meditation">
      <g>
        <defs>
          <rect
            id="MEDITATION_SVGID_85_"
            x="0.001"
            y="0.001"
            width="61.687"
            height="61.687"
          />
        </defs>
        <clipPath id="MEDITATION_SVGID_86_">
          <use xlinkHref="#MEDITATION_SVGID_85_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#8B52A1"
          d="M55.508,61.071H6.181c-3.059,0-5.563-2.504-5.563-5.563V6.181
				c0-3.06,2.504-5.563,5.563-5.563h49.327c3.061,0,5.563,2.503,5.563,5.563v49.327C61.071,58.567,58.568,61.071,55.508,61.071"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M50.379,22.555
				c0,3.664-2.972,6.636-6.637,6.636c-3.664,0-6.635-2.972-6.635-6.636c0-3.663,2.971-6.635,6.635-6.635
				C47.407,15.92,50.379,18.892,50.379,22.555"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M41.549,25.038
				c0,2.131-1.728,3.859-3.859,3.859c-2.131,0-3.859-1.729-3.859-3.859s1.729-3.859,3.859-3.859
				C39.821,21.179,41.549,22.907,41.549,25.038"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M54.622,24.52
				c0,2.58-2.091,4.671-4.671,4.671c-2.579,0-4.67-2.091-4.67-4.671c0-2.579,2.091-4.671,4.67-4.671
				C52.531,19.849,54.622,21.94,54.622,24.52"
        />

        <rect
          x="37.689"
          y="24.899"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          width="12.262"
          height="4.291"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          points="19.868,11.139 18.917,11.139 18.917,9.017 17.799,11.139
				17.347,11.139 16.222,9.005 16.222,11.139 15.271,11.139 15.271,7.155 16.234,7.155 17.572,9.683 18.91,7.155 19.868,7.155
				"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M23.522,9.891h-2.188c0.077,0.356,0.321,0.576,0.642,0.576
				c0.22,0,0.476-0.054,0.637-0.345l0.844,0.172c-0.256,0.631-0.797,0.934-1.481,0.934c-0.862,0-1.575-0.636-1.575-1.582
				c0-0.938,0.713-1.58,1.581-1.58c0.845,0,1.522,0.605,1.54,1.58V9.891z M21.352,9.308h1.231c-0.09-0.321-0.327-0.471-0.601-0.471
				C21.715,8.837,21.441,9.005,21.352,9.308"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M27.138,11.14h-0.951v-0.238c-0.232,0.207-0.541,0.326-0.928,0.326
				c-0.79,0-1.456-0.636-1.456-1.582c0-0.938,0.666-1.58,1.456-1.58c0.387,0,0.696,0.117,0.928,0.326V6.856h0.951V11.14z
				 M26.187,9.646c0-0.457-0.345-0.771-0.732-0.771c-0.416,0-0.719,0.314-0.719,0.771c0,0.465,0.303,0.773,0.719,0.773
				C25.841,10.419,26.187,10.11,26.187,9.646"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M28.834,7.274c0,0.303-0.232,0.528-0.553,0.528
				c-0.321,0-0.547-0.226-0.547-0.528c0-0.293,0.226-0.541,0.547-0.541C28.603,6.733,28.834,6.981,28.834,7.274 M27.806,8.153h0.951
				v2.985h-0.951V8.153z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M31.626,11.008c-0.232,0.125-0.476,0.22-0.815,0.22
				c-0.743,0-1.189-0.402-1.189-1.189V8.909h-0.541V8.153h0.541V7.274h0.952v0.879h0.879v0.756h-0.879v1.035
				c0,0.31,0.148,0.434,0.398,0.434c0.131,0,0.309-0.049,0.434-0.119L31.626,11.008z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M35.083,11.14h-0.951v-0.238c-0.231,0.207-0.541,0.326-0.927,0.326
				c-0.791,0-1.458-0.636-1.458-1.582c0-0.938,0.667-1.58,1.458-1.58c0.386,0,0.695,0.117,0.927,0.326V8.153h0.951V11.14z
				 M34.132,9.646c0-0.457-0.345-0.771-0.731-0.771c-0.416,0-0.72,0.314-0.72,0.771c0,0.465,0.304,0.773,0.72,0.773
				C33.787,10.419,34.132,10.11,34.132,9.646"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M37.955,11.008c-0.232,0.125-0.477,0.22-0.814,0.22
				c-0.743,0-1.189-0.402-1.189-1.189V8.909H35.41V8.153h0.541V7.274h0.952v0.879h0.879v0.756h-0.879v1.035
				c0,0.31,0.147,0.434,0.397,0.434c0.131,0,0.31-0.049,0.435-0.119L37.955,11.008z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M39.356,7.274c0,0.303-0.231,0.528-0.554,0.528
				c-0.32,0-0.547-0.226-0.547-0.528c0-0.293,0.227-0.541,0.547-0.541C39.125,6.733,39.356,6.981,39.356,7.274 M38.327,8.153h0.951
				v2.985h-0.951V8.153z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M43.029,9.646c0,0.946-0.75,1.576-1.635,1.576
				c-0.903,0-1.653-0.63-1.653-1.576c0-0.938,0.75-1.569,1.653-1.569C42.279,8.076,43.029,8.707,43.029,9.646 M42.114,9.646
				c0-0.463-0.351-0.771-0.72-0.771c-0.387,0-0.737,0.309-0.737,0.771c0,0.471,0.351,0.773,0.737,0.773
				C41.764,10.419,42.114,10.116,42.114,9.646"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M46.51,9.308v1.831h-0.945V9.511c0-0.352-0.209-0.59-0.513-0.59
				c-0.374,0-0.618,0.256-0.618,0.832v1.386h-0.951V8.153h0.951v0.286c0.228-0.239,0.541-0.374,0.916-0.374
				C46.046,8.065,46.51,8.559,46.51,9.308"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M15.416,33.315
				c0,3.664-2.97,6.637-6.635,6.637s-6.636-2.973-6.636-6.637s2.971-6.635,6.636-6.635S15.416,29.651,15.416,33.315"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M19.659,35.28
				c0,2.58-2.091,4.671-4.67,4.671c-2.58,0-4.671-2.091-4.671-4.671s2.091-4.671,4.671-4.671
				C17.568,30.609,19.659,32.7,19.659,35.28"
        />

        <rect
          x="2.728"
          y="35.661"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          width="12.262"
          height="4.29"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          d="M41.461,28.399c0.418,4.336-0.667,13.748-0.667,13.748H21.341c0,0-1.397-7.899-1.068-13.16"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#F1B67B"
          d="M38.756,27.163c0,6.358-2.721,11.366-7.889,11.366
				s-7.889-5.008-7.889-11.366c0-6.359,2.721-11.516,7.889-11.516S38.756,20.804,38.756,27.163"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#ED1C24"
          d="M15.837,51.94v-5.066c0-3.639,2.488-6.688,5.853-7.562V51.94H15.837z"
        />
        <rect
          x="17.027"
          y="51.876"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#F1B67B"
          width="4.663"
          height="6.427"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#ED1C24"
          d="M45.898,51.94v-5.066c0-3.639-2.488-6.688-5.854-7.562V51.94H45.898z"
        />
        <rect
          x="40.045"
          y="51.876"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#F1B67B"
          width="4.664"
          height="6.427"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M26.223,29.924
				c0,0.655,0.532,1.187,1.187,1.187c0.655,0,1.187-0.531,1.187-1.187"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M33.125,29.924
				c0,0.655,0.532,1.187,1.188,1.187s1.187-0.531,1.187-1.187"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="none"
          stroke="#623B23"
          strokeWidth="0.389"
          strokeMiterlimit="10"
          d="M32.105,32.091
				c-1.14,1.382-2.177,0.345-2.365,0"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          d="M41.461,28.399c-0.455-7.524-3.292-13.437-10.594-13.437c-6.878,0-10.138,6.5-10.594,14.024
				l4.501,0.041l1.537-3.367l0.617,3.55l0.601-0.073h11.979l-0.285-0.588C39.924,28.606,40.665,28.575,41.461,28.399"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#F1B67B"
          d="M34.97,38.676c0,0-1.837,4.106-4.103,4.106
				c-2.265,0-4.102-4.106-4.102-4.106s1.837-4.202,4.102-4.202C33.133,34.474,34.97,38.676,34.97,38.676"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="none"
          stroke="#623B23"
          strokeWidth="0.389"
          strokeMiterlimit="10"
          d="M32.712,33.886
				c-1.724,2.089-3.293,0.521-3.577,0"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#0E4C8C"
          d="M35.967,38.364c-0.44,1.996-2.555,3.509-5.1,3.509
				c-2.545,0-4.658-1.513-5.099-3.509c-1.922,0.299-3.053,0.718-4.078,0.949v18.59h9.177h9.178v-18.59
				C39.021,39.082,37.889,38.663,35.967,38.364"
        />

        <rect
          x="23.367"
          y="39.675"
          transform="matrix(0.7071 0.7071 -0.7071 0.7071 36.8383 -7.3551)"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#ED1C24"
          width="7.861"
          height="2.235"
        />

        <rect
          x="30.506"
          y="39.676"
          transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 87.6318 45.2877)"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#ED1C24"
          width="7.861"
          height="2.235"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#F1B67B"
          points="30.867,42.782 32.969,40.683 28.767,40.683 			"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#B394BF"
          d="M56.012,4.407c0.688,0,1.27,0.582,1.27,1.271v50.334
				c0,0.688-0.581,1.271-1.27,1.271H5.678c-0.689,0-1.271-0.582-1.271-1.271V5.678c0-0.689,0.582-1.271,1.271-1.271H56.012
				 M56.012,0.001H5.678c-3.122,0-5.677,2.555-5.677,5.677v50.334c0,3.121,2.555,5.676,5.677,5.676h50.333
				c3.121,0,5.676-2.555,5.676-5.676V5.678C61.688,2.556,59.133,0.001,56.012,0.001"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#5087C7"
          d="M30.855,55.362c-2.638,0-4.773-2.136-4.773-4.771
				c0-2.637,2.135-4.773,4.773-4.773c2.639,0,4.773,2.137,4.773,4.773C35.629,53.227,33.494,55.362,30.855,55.362"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M30.855,45.817c2.639,0,4.773,2.137,4.773,4.773
				c0,2.636-2.135,4.771-4.773,4.771c-2.638,0-4.773-2.136-4.773-4.771C26.083,47.954,28.218,45.817,30.855,45.817 M30.855,45.405
				c-2.859,0-5.185,2.326-5.185,5.186c0,2.857,2.326,5.184,5.185,5.184c2.86,0,5.186-2.326,5.186-5.184
				C36.041,47.731,33.716,45.405,30.855,45.405"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M33.35,48.646c-0.239,0-0.465,0.107-0.62,0.25l-0.013-0.012
				c0.072-0.119,0.168-0.346,0.168-0.716c0-0.883-0.885-1.551-2.029-1.551c-1.145,0-2.041,0.668-2.041,1.551
				c0,0.37,0.12,0.597,0.179,0.716l-0.012,0.012c-0.155-0.143-0.382-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.017
				c0,1.289,0.716,2.017,1.492,2.017c0.716,0,1.217-0.395,1.67-0.848c-0.071-0.107-0.179-0.216-0.298-0.216
				s-0.251,0.085-0.477,0.264c-0.299,0.228-0.561,0.358-0.908,0.358c-0.608,0-0.87-0.682-0.87-1.575c0-0.955,0.25-1.587,0.895-1.587
				c0.286,0,0.476,0.083,0.668,0.263c0.286,0.263,0.382,0.333,0.513,0.333c0.096,0,0.155-0.06,0.179-0.095
				c-0.214-0.215-0.525-0.667-0.525-1.252c0-0.669,0.561-1.134,1.611-1.134c1.05,0,1.611,0.465,1.611,1.134
				c0,0.585-0.311,1.037-0.525,1.252c0.024,0.035,0.084,0.095,0.18,0.095c0.131,0,0.227-0.07,0.513-0.333
				c0.179-0.18,0.382-0.263,0.668-0.263c0.644,0,0.896,0.632,0.896,1.587c0,0.894-0.264,1.575-0.871,1.575
				c-0.346,0-0.621-0.131-0.908-0.358c-0.227-0.179-0.357-0.264-0.477-0.264c-0.119,0-0.226,0.108-0.299,0.216
				c0.441,0.453,0.955,0.848,1.671,0.848c0.776,0,1.492-0.728,1.492-2.017C34.806,49.349,34.076,48.646,33.35,48.646"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M31.655,48.622h-0.537c-0.035,0-0.059-0.024-0.071-0.049l-0.168-0.513
				c-0.011-0.034-0.023-0.034-0.035,0l-0.167,0.513c-0.013,0.037-0.036,0.049-0.06,0.049H30.08c-0.024,0-0.036,0.011-0.012,0.022
				l0.442,0.323c0.023,0.023,0.023,0.061,0.011,0.083l-0.167,0.501c-0.011,0.023-0.011,0.048,0.025,0.023l0.441-0.309
				c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.024,0.036,0.013,0.023-0.012l-0.166-0.513
				c-0.012-0.035,0-0.071,0.024-0.083l0.44-0.323C31.691,48.633,31.679,48.622,31.655,48.622"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M31.25,52.153c-0.226-0.573-0.406-0.967-0.406-1.444
				c0-0.203,0.06-0.381,0.096-0.476c0.048-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.167-0.322,0.311
				c-0.072,0.118-0.155,0.347-0.155,0.524c0,0.335,0.108,0.692,0.179,0.907c0.132,0.369,0.251,0.799,0.251,1.205
				c0,0.645-0.287,1.086-0.799,1.479c0.047,0.037,0.166,0.097,0.31,0.097c0.5,0,1.097-0.561,1.097-1.324
				C31.512,52.821,31.38,52.511,31.25,52.153"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M67.01-26.018c0.075,0,0.14,0.012,0.191,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.068,0.141c0.014,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.022,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.147,0.081
				c-0.059,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H67.01z M66.918-25.349c0.059,0,0.107-0.018,0.145-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H66.918z"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          points="68.012,-26.018 68.223,-24.973 68.229,-24.973 68.441,-26.018
				68.883,-26.018 68.883,-24.538 68.609,-24.538 68.609,-25.72 68.605,-25.72 68.342,-24.538 68.109,-24.538 67.848,-25.72
				67.844,-25.72 67.844,-24.538 67.569,-24.538 67.569,-26.018 			"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M69.337-24.957c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.017,0.052,0.031,0.071s0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
				c0.046,0,0.087-0.015,0.123-0.045c0.037-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.015-0.086
				c-0.008-0.024-0.023-0.046-0.043-0.066c-0.021-0.019-0.049-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.125-0.046-0.172-0.073c-0.049-0.026-0.09-0.056-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.337-0.108
				c0.066,0,0.127,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.097,0.124
				c0.022,0.051,0.033,0.111,0.033,0.182v0.041h-0.285c0-0.07-0.014-0.124-0.039-0.162c-0.023-0.038-0.065-0.057-0.123-0.057
				c-0.032,0-0.061,0.005-0.082,0.015c-0.023,0.009-0.041,0.022-0.055,0.037c-0.013,0.016-0.021,0.035-0.025,0.055
				c-0.006,0.021-0.008,0.042-0.008,0.065c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099
				c0.058,0.025,0.104,0.051,0.14,0.078c0.036,0.027,0.063,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102
				c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.073-0.107-0.179-0.107-0.315V-25h0.298V-24.957z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M66.986-24.075c0.061,0,0.117,0.007,0.17,0.02
				c0.051,0.012,0.096,0.033,0.133,0.063c0.037,0.028,0.066,0.067,0.088,0.115c0.02,0.049,0.031,0.108,0.031,0.179
				c0,0.08-0.02,0.149-0.057,0.206c-0.038,0.057-0.094,0.097-0.168,0.117v0.005c0.083,0.01,0.148,0.046,0.197,0.105
				c0.048,0.059,0.072,0.139,0.072,0.239c0,0.057-0.008,0.113-0.022,0.165c-0.017,0.053-0.042,0.099-0.078,0.138
				c-0.037,0.04-0.085,0.071-0.144,0.094c-0.059,0.024-0.131,0.036-0.219,0.036h-0.502v-1.481H66.986z M66.869-23.467
				c0.084,0,0.145-0.015,0.183-0.045c0.038-0.029,0.057-0.079,0.057-0.15c0-0.07-0.017-0.12-0.052-0.149
				c-0.034-0.029-0.089-0.044-0.164-0.044h-0.107v0.388H66.869z M66.901-22.814c0.072,0,0.13-0.016,0.175-0.048
				c0.043-0.031,0.065-0.09,0.065-0.176c0-0.043-0.006-0.079-0.017-0.107c-0.012-0.028-0.028-0.051-0.049-0.068
				c-0.02-0.018-0.043-0.029-0.07-0.037c-0.028-0.006-0.059-0.01-0.092-0.01h-0.129v0.446H66.901z"
        />
        <rect
          x="67.641"
          y="-24.075"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          width="0.287"
          height="1.48"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M68.127-23.395c0-0.065,0.01-0.119,0.03-0.163
				c0.021-0.043,0.05-0.078,0.084-0.106c0.035-0.026,0.076-0.046,0.124-0.057c0.049-0.012,0.1-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025s0.093,0.04,0.122,0.07c0.029,0.031,0.049,0.067,0.059,0.109
				c0.012,0.042,0.016,0.088,0.016,0.138v0.587c0,0.052,0.002,0.093,0.008,0.121c0.005,0.03,0.014,0.061,0.027,0.094h-0.273
				c-0.01-0.018-0.018-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059h-0.004c-0.033,0.058-0.071,0.096-0.115,0.113
				c-0.044,0.017-0.1,0.026-0.169,0.026c-0.05,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.072
				c-0.021-0.03-0.036-0.064-0.046-0.102s-0.014-0.076-0.014-0.113c0-0.052,0.005-0.098,0.016-0.136
				c0.012-0.038,0.027-0.07,0.051-0.097c0.023-0.028,0.051-0.05,0.088-0.068c0.034-0.018,0.077-0.033,0.127-0.048l0.162-0.043
				c0.043-0.011,0.072-0.026,0.088-0.045c0.018-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.077-0.029-0.101
				c-0.021-0.024-0.055-0.036-0.103-0.036c-0.045,0-0.078,0.013-0.101,0.039c-0.021,0.027-0.033,0.062-0.033,0.106v0.031h-0.256
				V-23.395z M68.586-23.116c-0.021,0.009-0.041,0.015-0.06,0.02c-0.06,0.012-0.101,0.033-0.124,0.061
				c-0.025,0.03-0.038,0.069-0.038,0.119c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.044,0.045,0.083,0.045
				c0.019,0,0.039-0.003,0.06-0.009c0.021-0.007,0.04-0.016,0.058-0.03c0.017-0.014,0.031-0.032,0.043-0.054
				c0.01-0.022,0.016-0.048,0.016-0.079v-0.219C68.629-23.137,68.608-23.125,68.586-23.116"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M69.656-23.389c-0.004-0.026-0.01-0.05-0.019-0.072
				c-0.008-0.021-0.021-0.038-0.037-0.051c-0.019-0.014-0.04-0.02-0.067-0.02c-0.035,0-0.063,0.008-0.084,0.024
				c-0.022,0.016-0.039,0.039-0.051,0.07c-0.011,0.031-0.019,0.071-0.023,0.118c-0.004,0.048-0.006,0.105-0.006,0.169
				c0,0.07,0.002,0.128,0.006,0.178c0.005,0.048,0.013,0.089,0.023,0.121c0.012,0.032,0.027,0.055,0.047,0.069
				c0.021,0.015,0.047,0.022,0.077,0.022c0.05,0,0.084-0.021,0.106-0.062c0.021-0.042,0.033-0.108,0.033-0.199h0.273
				c0,0.146-0.033,0.258-0.098,0.335c-0.066,0.076-0.176,0.115-0.327,0.115c-0.072,0-0.134-0.009-0.188-0.026
				c-0.053-0.018-0.097-0.048-0.132-0.094c-0.036-0.044-0.062-0.104-0.079-0.18c-0.019-0.075-0.027-0.169-0.027-0.281
				c0-0.115,0.011-0.209,0.033-0.285c0.021-0.075,0.053-0.135,0.094-0.18c0.039-0.045,0.087-0.076,0.142-0.094
				c0.056-0.018,0.117-0.027,0.183-0.027c0.133,0,0.232,0.038,0.301,0.116c0.066,0.077,0.1,0.182,0.1,0.315h-0.273
				C69.662-23.336,69.66-23.363,69.656-23.389"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          points="70.381,-23.293 70.385,-23.293 70.674,-23.71 70.988,-23.71
				70.667,-23.271 71.036,-22.595 70.721,-22.595 70.483,-23.053 70.381,-22.919 70.381,-22.595 70.096,-22.595 70.096,-24.075
				70.381,-24.075 			"
        />
        <rect
          x="-13.217"
          y="-26.429"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#B394BF"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M-12.303-25.93c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.104-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.303z M-12.394-25.261c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H-12.394
				z"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          points="-11.3,-25.93 -11.089,-24.885 -11.084,-24.885 -10.871,-25.93
				-10.43,-25.93 -10.43,-24.451 -10.703,-24.451 -10.703,-25.632 -10.707,-25.632 -10.97,-24.451 -11.203,-24.451 -11.466,-25.632
				-11.47,-25.632 -11.47,-24.451 -11.744,-24.451 -11.744,-25.93 			"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M-9.976-24.869c0,0.034,0.003,0.066,0.008,0.094
				c0.005,0.028,0.016,0.052,0.031,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.057,0.016,0.096,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086
				c-0.009-0.025-0.024-0.047-0.045-0.066s-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.071-0.012-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.169,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M-8.407-25.657l-0.031,0.334h0.004c0.028-0.039,0.064-0.067,0.108-0.084
				c0.044-0.017,0.098-0.026,0.161-0.026c0.071,0,0.13,0.013,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.107
				c0.027,0.044,0.047,0.093,0.057,0.15c0.011,0.055,0.017,0.113,0.017,0.17c0,0.082-0.007,0.156-0.021,0.223
				C-7.84-24.676-7.865-24.62-7.9-24.571c-0.035,0.047-0.084,0.083-0.144,0.107c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.057-0.025-0.101-0.058-0.132-0.099c-0.03-0.04-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.051,0.005,0.079c0.002,0.027,0.009,0.053,0.019,0.077
				c0.011,0.024,0.026,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.076,0.024c0.063,0,0.107-0.024,0.134-0.071
				c0.027-0.048,0.041-0.12,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.014-0.072-0.028-0.096
				c-0.013-0.023-0.03-0.04-0.053-0.049c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.025-0.037,0.058-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H-8.407z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M-7.714-24.451c0-0.065,0.007-0.125,0.02-0.182
				c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.056
				c0-0.137,0.037-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
				c0.072,0.07,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131s-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H-7.714z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M-6.72-24.451c0-0.065,0.007-0.125,0.02-0.182s0.033-0.111,0.06-0.162
				c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112c0.033-0.029,0.06-0.056,0.079-0.081
				c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081c0.003-0.029,0.005-0.062,0.005-0.099
				c0-0.122-0.048-0.183-0.145-0.183c-0.038,0-0.067,0.008-0.088,0.023c-0.022,0.015-0.038,0.036-0.05,0.062
				c-0.012,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.11H-6.72v-0.056c0-0.137,0.037-0.242,0.11-0.316
				c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103c0.072,0.07,0.107,0.168,0.107,0.297
				c0,0.047-0.004,0.09-0.012,0.131c-0.008,0.041-0.021,0.081-0.039,0.117c-0.018,0.038-0.042,0.074-0.071,0.109
				c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.045,0.04-0.079,0.079-0.104,0.116c-0.025,0.039-0.041,0.071-0.048,0.099
				h0.554v0.232H-6.72z"
        />
        <rect
          x="-3.303"
          y="-26.429"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#8B52A1"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M-2.389-25.93c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.025,0.094,0.059,0.125,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.104-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				s-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.389z M-2.48-25.261c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H-2.48z"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          points="-1.386,-25.93 -1.175,-24.885 -1.17,-24.885 -0.957,-25.93
				-0.516,-25.93 -0.516,-24.451 -0.789,-24.451 -0.789,-25.632 -0.793,-25.632 -1.056,-24.451 -1.289,-24.451 -1.552,-25.632
				-1.556,-25.632 -1.556,-24.451 -1.83,-24.451 -1.83,-25.93 			"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M-0.062-24.869c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.051,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086
				c-0.009-0.024-0.024-0.047-0.045-0.066s-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.014,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0.291c0-0.07-0.012-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.015c-0.023,0.008-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087C0.588-25.071,0.604-25.036,0.612-25
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.289-0.037-0.362-0.11c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M1.507-25.657l-0.031,0.334H1.48c0.028-0.039,0.064-0.067,0.108-0.084
				c0.044-0.017,0.098-0.026,0.161-0.026c0.071,0,0.13,0.014,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.107
				c0.027,0.044,0.047,0.093,0.057,0.15c0.011,0.056,0.017,0.113,0.017,0.17c0,0.082-0.007,0.156-0.021,0.223
				c-0.013,0.066-0.038,0.122-0.073,0.171c-0.035,0.047-0.084,0.083-0.144,0.107c-0.061,0.025-0.138,0.037-0.232,0.037
				c-0.093,0-0.168-0.013-0.225-0.038c-0.057-0.025-0.101-0.058-0.132-0.098c-0.03-0.041-0.051-0.085-0.061-0.133
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039H1.49c0,0.024,0.002,0.051,0.005,0.079c0.002,0.028,0.009,0.053,0.019,0.077
				c0.011,0.024,0.026,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.077,0.024c0.062,0,0.106-0.024,0.133-0.071
				c0.027-0.048,0.041-0.119,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.014-0.072-0.028-0.095
				c-0.013-0.024-0.03-0.041-0.053-0.05c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.025-0.037,0.058-0.042,0.099H1.229l0.054-0.798h0.765v0.232H1.507z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M2.54-25.487H2.248v-0.194h0.027c0.099,0,0.177-0.019,0.235-0.056
				c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467H2.54V-25.487z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M3.519-24.684c0.021,0.045,0.065,0.067,0.13,0.067
				c0.035,0,0.064-0.006,0.086-0.018c0.021-0.013,0.037-0.03,0.049-0.051c0.012-0.021,0.019-0.047,0.023-0.076
				c0.003-0.029,0.005-0.06,0.005-0.091c0-0.034-0.002-0.065-0.007-0.094c-0.005-0.03-0.014-0.057-0.028-0.08
				c-0.014-0.023-0.033-0.042-0.058-0.056s-0.058-0.021-0.099-0.021h-0.1v-0.195h0.097c0.032,0,0.058-0.006,0.08-0.02
				c0.021-0.013,0.039-0.03,0.052-0.051c0.013-0.021,0.023-0.046,0.029-0.074c0.006-0.028,0.009-0.057,0.009-0.087
				c0-0.071-0.011-0.12-0.035-0.149c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.005-0.077,0.017
				c-0.021,0.012-0.036,0.028-0.047,0.049c-0.011,0.022-0.019,0.048-0.022,0.077c-0.003,0.029-0.005,0.06-0.005,0.094H3.22
				c0-0.146,0.036-0.254,0.109-0.325c0.073-0.071,0.181-0.108,0.322-0.108c0.133,0,0.237,0.031,0.312,0.092
				c0.074,0.061,0.11,0.156,0.11,0.286c0,0.089-0.019,0.161-0.059,0.217c-0.039,0.055-0.094,0.091-0.164,0.106v0.004
				c0.095,0.016,0.162,0.054,0.202,0.115c0.039,0.062,0.059,0.139,0.059,0.232c0,0.049-0.006,0.1-0.019,0.152
				C4.08-24.662,4.055-24.613,4.02-24.57c-0.037,0.043-0.086,0.077-0.149,0.104c-0.062,0.026-0.145,0.039-0.247,0.039
				c-0.143,0-0.249-0.039-0.32-0.116c-0.07-0.077-0.105-0.188-0.105-0.33v-0.006h0.286C3.486-24.793,3.497-24.729,3.519-24.684"
        />
        <rect
          x="6.61"
          y="-26.429"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#ED1C24"
          width="8.813"
          height="8.812"
        />
        <rect
          x="26.439"
          y="-26.429"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#F1B67B"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M27.353-25.93c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.025,0.094,0.059,0.125,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.104-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H27.353z M27.262-25.261c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H27.262z
				"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          points="28.356,-25.93 28.567,-24.885 28.572,-24.885 28.785,-25.93
				29.226,-25.93 29.226,-24.451 28.953,-24.451 28.953,-25.632 28.949,-25.632 28.686,-24.451 28.453,-24.451 28.19,-25.632
				28.186,-25.632 28.186,-24.451 27.912,-24.451 27.912,-25.93 			"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M29.681-24.869c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.051,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086
				c-0.009-0.024-0.024-0.047-0.045-0.066s-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.014,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.012-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.015c-0.023,0.008-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.289-0.037-0.362-0.11c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M31.287-25.487h-0.292v-0.194h0.027c0.099,0,0.177-0.019,0.235-0.056
				c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.298V-25.487z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M32.244-25.657l-0.031,0.334h0.004c0.028-0.039,0.064-0.067,0.108-0.084
				c0.044-0.017,0.098-0.026,0.161-0.026c0.07,0,0.13,0.014,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.107
				c0.026,0.044,0.047,0.093,0.058,0.15c0.01,0.056,0.016,0.113,0.016,0.17c0,0.082-0.007,0.156-0.021,0.223
				c-0.014,0.066-0.038,0.122-0.073,0.171c-0.036,0.047-0.084,0.083-0.144,0.107c-0.062,0.025-0.139,0.037-0.232,0.037
				c-0.093,0-0.168-0.013-0.225-0.038c-0.058-0.025-0.102-0.058-0.133-0.098c-0.03-0.041-0.05-0.085-0.061-0.133
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.288c0,0.024,0,0.051,0.004,0.079c0.002,0.028,0.009,0.053,0.02,0.077
				c0.01,0.024,0.025,0.045,0.044,0.06c0.021,0.016,0.045,0.024,0.077,0.024c0.063,0,0.105-0.024,0.134-0.071
				c0.027-0.048,0.04-0.119,0.04-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.027-0.095
				c-0.014-0.024-0.03-0.041-0.054-0.05c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.1,0.036
				c-0.021,0.025-0.037,0.058-0.041,0.099h-0.268l0.053-0.798h0.766v0.232H32.244z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M33.514-25.677c-0.02-0.031-0.055-0.046-0.104-0.046
				c-0.039,0-0.069,0.01-0.095,0.028c-0.023,0.019-0.043,0.045-0.057,0.078c-0.013,0.033-0.021,0.073-0.024,0.118
				c-0.005,0.046-0.007,0.095-0.007,0.149v0.073h0.004c0.025-0.039,0.061-0.069,0.107-0.092s0.103-0.035,0.168-0.035
				s0.121,0.012,0.166,0.035s0.082,0.054,0.111,0.095c0.028,0.041,0.049,0.089,0.063,0.145c0.013,0.056,0.019,0.117,0.019,0.184
				c0,0.169-0.037,0.298-0.112,0.386c-0.076,0.088-0.195,0.132-0.361,0.132c-0.104,0-0.184-0.018-0.245-0.054
				c-0.06-0.036-0.106-0.086-0.14-0.148c-0.033-0.064-0.054-0.136-0.063-0.219c-0.009-0.083-0.015-0.169-0.015-0.259
				c0-0.104,0.004-0.205,0.01-0.302c0.006-0.097,0.025-0.184,0.056-0.259c0.031-0.075,0.08-0.135,0.146-0.181
				c0.068-0.046,0.162-0.069,0.283-0.069c0.051,0,0.103,0.007,0.151,0.019c0.05,0.013,0.093,0.033,0.13,0.062
				c0.037,0.029,0.067,0.068,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.178h-0.286C33.543-25.602,33.534-25.646,33.514-25.677
				 M33.248-24.79c0.004,0.036,0.012,0.066,0.023,0.092c0.011,0.026,0.027,0.046,0.05,0.06c0.021,0.014,0.049,0.022,0.084,0.022
				s0.063-0.008,0.086-0.022c0.021-0.014,0.037-0.034,0.048-0.061c0.012-0.026,0.02-0.057,0.021-0.093
				c0.004-0.034,0.006-0.073,0.006-0.114c0-0.1-0.012-0.171-0.037-0.214s-0.066-0.064-0.124-0.064c-0.06,0-0.103,0.021-0.127,0.064
				s-0.037,0.114-0.037,0.214C33.241-24.865,33.243-24.826,33.248-24.79"
        />
        <rect
          x="36.354"
          y="-26.429"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#623B23"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M37.268-25.93c0.075,0,0.139,0.012,0.191,0.038
				c0.052,0.025,0.094,0.059,0.125,0.1c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.147,0.081
				c-0.059,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H37.268z M37.176-25.261c0.059,0,0.107-0.018,0.145-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.16-0.058h-0.124v0.45H37.176z"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          points="38.27,-25.93 38.48,-24.885 38.486,-24.885 38.699,-25.93
				39.141,-25.93 39.141,-24.451 38.867,-24.451 38.867,-25.632 38.863,-25.632 38.6,-24.451 38.367,-24.451 38.105,-25.632
				38.102,-25.632 38.102,-24.451 37.826,-24.451 37.826,-25.93 			"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M39.595-24.869c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.017,0.051,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.037-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.015-0.086
				c-0.008-0.024-0.023-0.047-0.043-0.066c-0.021-0.019-0.049-0.036-0.082-0.053c-0.033-0.016-0.076-0.032-0.125-0.048
				c-0.066-0.022-0.125-0.046-0.172-0.073c-0.05-0.026-0.09-0.056-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.185,0.022c0.056,0.014,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.097,0.124
				c0.022,0.051,0.033,0.111,0.033,0.182v0.041h-0.285c0-0.07-0.014-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.125-0.057
				c-0.033,0-0.061,0.004-0.082,0.015c-0.023,0.008-0.041,0.022-0.055,0.037c-0.013,0.016-0.021,0.035-0.025,0.055
				c-0.006,0.021-0.008,0.042-0.008,0.065c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099
				c0.058,0.025,0.104,0.051,0.14,0.078c0.036,0.027,0.063,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102
				c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M41.34-25.919h0.309v0.932h0.141v0.231h-0.141v0.305h-0.273v-0.305h-0.522
				V-25L41.34-25.919z M41.371-25.545l-0.287,0.558h0.291v-0.558H41.371z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M42.433-25.677c-0.019-0.031-0.056-0.046-0.104-0.046
				c-0.039,0-0.069,0.01-0.095,0.028c-0.024,0.019-0.043,0.045-0.056,0.078c-0.014,0.033-0.021,0.073-0.026,0.118
				c-0.004,0.046-0.006,0.095-0.006,0.149v0.073h0.004c0.025-0.039,0.062-0.069,0.106-0.092c0.048-0.023,0.104-0.035,0.17-0.035
				c0.064,0,0.119,0.012,0.166,0.035c0.045,0.023,0.082,0.054,0.111,0.095c0.027,0.041,0.049,0.089,0.062,0.145
				s0.019,0.117,0.019,0.184c0,0.169-0.037,0.298-0.111,0.386c-0.076,0.088-0.196,0.132-0.362,0.132
				c-0.103,0-0.185-0.018-0.245-0.054c-0.061-0.036-0.107-0.086-0.139-0.148c-0.033-0.064-0.055-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.205,0.009-0.302c0.006-0.097,0.024-0.184,0.056-0.259
				c0.031-0.075,0.08-0.135,0.146-0.181c0.066-0.046,0.161-0.069,0.281-0.069c0.053,0,0.104,0.007,0.152,0.019
				c0.051,0.013,0.094,0.033,0.131,0.062c0.037,0.029,0.066,0.068,0.09,0.115c0.022,0.048,0.035,0.107,0.035,0.178h-0.285
				C42.463-25.603,42.453-25.646,42.433-25.677 M42.166-24.79c0.004,0.036,0.013,0.066,0.024,0.092
				c0.011,0.026,0.028,0.046,0.05,0.06s0.049,0.022,0.084,0.022s0.064-0.008,0.086-0.022s0.037-0.034,0.049-0.061
				c0.011-0.026,0.019-0.057,0.021-0.093c0.004-0.034,0.006-0.073,0.006-0.114c0-0.1-0.012-0.171-0.036-0.214
				c-0.025-0.043-0.066-0.064-0.125-0.064c-0.06,0-0.103,0.021-0.127,0.064c-0.025,0.043-0.037,0.114-0.037,0.214
				C42.16-24.865,42.162-24.826,42.166-24.79"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M43.193-24.664c0.02,0.032,0.055,0.047,0.104,0.047
				c0.04,0,0.072-0.009,0.097-0.028c0.022-0.019,0.041-0.044,0.054-0.078c0.013-0.033,0.022-0.073,0.026-0.118
				c0.004-0.045,0.006-0.095,0.006-0.149v-0.07h-0.004c-0.025,0.037-0.061,0.067-0.106,0.089c-0.047,0.024-0.103,0.035-0.169,0.035
				c-0.064,0-0.12-0.011-0.166-0.035c-0.046-0.022-0.083-0.054-0.111-0.095c-0.027-0.04-0.049-0.089-0.062-0.144
				c-0.015-0.057-0.021-0.118-0.021-0.184c0-0.17,0.038-0.3,0.113-0.39c0.075-0.089,0.195-0.135,0.361-0.135
				c0.103,0,0.184,0.019,0.244,0.058c0.062,0.037,0.108,0.088,0.141,0.151c0.031,0.063,0.054,0.135,0.064,0.219
				c0.008,0.082,0.014,0.169,0.014,0.259c0,0.105-0.004,0.206-0.01,0.303c-0.006,0.098-0.024,0.184-0.057,0.258
				c-0.029,0.075-0.08,0.133-0.146,0.177c-0.067,0.043-0.161,0.066-0.28,0.066c-0.054,0-0.104-0.005-0.153-0.016
				c-0.049-0.01-0.092-0.03-0.129-0.058s-0.068-0.066-0.091-0.114c-0.022-0.048-0.034-0.107-0.036-0.178h0.286
				C43.163-24.737,43.173-24.694,43.193-24.664 M43.178-25.22c0.025,0.043,0.066,0.064,0.125,0.064s0.102-0.021,0.125-0.064
				c0.025-0.043,0.038-0.114,0.038-0.213c0-0.042-0.002-0.081-0.006-0.116c-0.004-0.037-0.012-0.067-0.024-0.092
				c-0.012-0.026-0.027-0.046-0.049-0.061c-0.021-0.014-0.05-0.022-0.084-0.022c-0.037,0-0.064,0.008-0.087,0.022
				c-0.021,0.015-0.036,0.035-0.049,0.061c-0.011,0.027-0.019,0.057-0.021,0.093c-0.004,0.035-0.006,0.073-0.006,0.115
				C43.141-25.333,43.152-25.263,43.178-25.22"
        />
        <rect
          x="16.525"
          y="-26.429"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#0E4C8C"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M17.439-25.93c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.025,0.094,0.059,0.125,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081s-0.123,0.027-0.193,0.027h-0.16
				v0.59h-0.298v-1.479H17.439z M17.348-25.261c0.059,0,0.107-0.018,0.144-0.054s0.055-0.092,0.055-0.168
				c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H17.348z"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          points="18.442,-25.93 18.653,-24.885 18.658,-24.885 18.871,-25.93
				19.312,-25.93 19.312,-24.451 19.039,-24.451 19.039,-25.632 19.035,-25.632 18.772,-24.451 18.539,-24.451 18.276,-25.632
				18.272,-25.632 18.272,-24.451 17.998,-24.451 17.998,-25.93 			"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M19.767-24.869c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.051,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086
				c-0.009-0.024-0.024-0.047-0.045-0.066s-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
				c0.067,0,0.128,0.007,0.184,0.022c0.056,0.014,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.012-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.015c-0.023,0.008-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.289-0.037-0.362-0.11c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M21.033-24.451c0-0.064,0.007-0.125,0.02-0.182
				c0.013-0.057,0.033-0.11,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.049,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.028,0.005-0.061,0.005-0.099c0-0.121-0.048-0.182-0.145-0.182c-0.037,0-0.067,0.007-0.088,0.021
				c-0.022,0.015-0.038,0.037-0.05,0.063c-0.012,0.026-0.019,0.056-0.023,0.091c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.056
				c0-0.137,0.037-0.242,0.11-0.315c0.073-0.074,0.188-0.11,0.346-0.11c0.149,0,0.26,0.034,0.332,0.104
				c0.072,0.069,0.107,0.167,0.107,0.296c0,0.047-0.004,0.09-0.012,0.131c-0.008,0.041-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.042,0.074-0.071,0.11c-0.029,0.035-0.064,0.071-0.106,0.108l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H21.033z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M22.081-25.104c0.042-0.066,0.104-0.107,0.187-0.124v-0.004
				c-0.04-0.01-0.074-0.025-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.07c-0.016-0.027-0.028-0.054-0.035-0.083
				c-0.007-0.029-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.115-0.31c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.31c0,0.026-0.004,0.054-0.012,0.083
				c-0.007,0.029-0.019,0.056-0.036,0.083c-0.016,0.026-0.038,0.049-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.017,0.145,0.058,0.187,0.124c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.005,0.09-0.015,0.14
				c-0.011,0.052-0.033,0.099-0.066,0.143c-0.033,0.045-0.08,0.081-0.142,0.111c-0.061,0.028-0.143,0.043-0.245,0.043
				s-0.184-0.015-0.246-0.043c-0.062-0.03-0.109-0.066-0.142-0.111c-0.033-0.044-0.055-0.091-0.065-0.143
				c-0.011-0.05-0.016-0.097-0.016-0.14C22.019-24.958,22.04-25.037,22.081-25.104 M22.325-24.771
				c0.005,0.031,0.014,0.058,0.027,0.081c0.013,0.021,0.031,0.041,0.053,0.054c0.022,0.013,0.05,0.019,0.083,0.019
				c0.033,0,0.06-0.006,0.083-0.019c0.022-0.013,0.039-0.032,0.052-0.054c0.013-0.023,0.023-0.05,0.027-0.081
				c0.005-0.031,0.008-0.064,0.008-0.099c0-0.035-0.003-0.069-0.008-0.099c-0.004-0.031-0.014-0.057-0.027-0.08
				c-0.013-0.023-0.03-0.041-0.052-0.054c-0.023-0.013-0.05-0.019-0.083-0.019c-0.033,0-0.061,0.006-0.083,0.019
				c-0.022,0.013-0.04,0.031-0.053,0.054c-0.013,0.023-0.022,0.049-0.027,0.08c-0.005,0.03-0.007,0.064-0.007,0.099
				C22.318-24.835,22.32-24.802,22.325-24.771 M22.382-25.367c0.026,0.033,0.062,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05
				c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.201-0.145-0.201c-0.097,0-0.145,0.066-0.145,0.201
				C22.343-25.452,22.356-25.4,22.382-25.367"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M23.599-25.677c-0.019-0.031-0.055-0.046-0.104-0.046
				c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.019-0.043,0.045-0.056,0.078c-0.013,0.033-0.021,0.073-0.025,0.118
				c-0.005,0.046-0.007,0.095-0.007,0.149v0.073h0.004c0.025-0.039,0.061-0.069,0.107-0.092c0.047-0.023,0.103-0.035,0.169-0.035
				c0.065,0,0.12,0.012,0.166,0.035c0.045,0.023,0.082,0.054,0.111,0.095c0.028,0.041,0.049,0.089,0.062,0.145
				c0.013,0.056,0.02,0.117,0.02,0.184c0,0.169-0.038,0.298-0.113,0.386c-0.075,0.088-0.196,0.132-0.362,0.132
				c-0.102,0-0.184-0.018-0.245-0.054c-0.06-0.036-0.107-0.086-0.139-0.148c-0.033-0.064-0.054-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.205,0.009-0.302c0.006-0.097,0.025-0.184,0.056-0.259
				c0.031-0.075,0.08-0.135,0.146-0.181c0.067-0.046,0.161-0.069,0.282-0.069c0.052,0,0.104,0.007,0.152,0.019
				c0.05,0.013,0.093,0.033,0.13,0.062c0.037,0.029,0.067,0.068,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.178h-0.286
				C23.629-25.603,23.62-25.646,23.599-25.677 M23.333-24.791c0.004,0.036,0.012,0.066,0.024,0.092
				c0.012,0.026,0.028,0.046,0.05,0.06c0.021,0.014,0.049,0.022,0.084,0.022c0.036,0,0.064-0.008,0.086-0.022
				c0.021-0.014,0.037-0.034,0.048-0.061c0.011-0.026,0.019-0.057,0.022-0.093c0.004-0.034,0.005-0.073,0.005-0.114
				c0-0.1-0.012-0.171-0.037-0.214s-0.066-0.064-0.124-0.064c-0.06,0-0.102,0.021-0.127,0.064s-0.037,0.114-0.037,0.214
				C23.327-24.865,23.329-24.826,23.333-24.791"
        />
        <rect
          x="46.268"
          y="-26.429"
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#5087C7"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M47.181-25.93c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.025,0.094,0.059,0.125,0.1c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.013,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.147,0.081
				c-0.059,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.299v-1.479H47.181z M47.09-25.261c0.059,0,0.107-0.018,0.145-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.049-0.17c-0.031-0.039-0.084-0.058-0.159-0.058h-0.124v0.45H47.09z"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          points="48.184,-25.93 48.395,-24.885 48.4,-24.885 48.613,-25.93
				49.055,-25.93 49.055,-24.451 48.781,-24.451 48.781,-25.632 48.777,-25.632 48.514,-24.451 48.281,-24.451 48.019,-25.632
				48.015,-25.632 48.015,-24.451 47.74,-24.451 47.74,-25.93 			"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M49.508-24.869c0,0.035,0.004,0.066,0.009,0.095
				c0.005,0.028,0.016,0.051,0.03,0.071c0.016,0.019,0.037,0.035,0.062,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.062-0.014-0.086
				c-0.008-0.024-0.023-0.047-0.043-0.066c-0.021-0.019-0.049-0.036-0.082-0.053c-0.033-0.016-0.076-0.032-0.125-0.048
				c-0.066-0.022-0.125-0.046-0.173-0.073c-0.049-0.026-0.089-0.056-0.12-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.185,0.022c0.056,0.014,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124
				c0.022,0.051,0.034,0.111,0.034,0.182v0.041H49.86c0-0.07-0.013-0.125-0.037-0.162c-0.024-0.039-0.067-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.015c-0.022,0.008-0.04,0.022-0.054,0.037c-0.014,0.016-0.021,0.035-0.025,0.055
				c-0.006,0.021-0.008,0.042-0.008,0.065c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099
				c0.058,0.025,0.104,0.051,0.139,0.078c0.037,0.027,0.064,0.056,0.087,0.087c0.021,0.031,0.036,0.066,0.045,0.102
				c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.217,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.297V-24.869z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M50.774-24.451c0-0.065,0.007-0.125,0.021-0.182
				c0.013-0.057,0.033-0.11,0.06-0.162c0.026-0.051,0.06-0.101,0.103-0.15c0.039-0.05,0.088-0.099,0.141-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.08-0.081c0.02-0.025,0.035-0.049,0.045-0.075c0.012-0.025,0.02-0.051,0.022-0.081
				c0.003-0.028,0.005-0.061,0.005-0.099c0-0.121-0.048-0.182-0.145-0.182c-0.037,0-0.066,0.007-0.088,0.021
				c-0.022,0.015-0.038,0.037-0.051,0.063c-0.01,0.026-0.019,0.056-0.022,0.091c-0.003,0.034-0.004,0.071-0.004,0.11h-0.287v-0.056
				c0-0.137,0.037-0.242,0.11-0.315c0.072-0.074,0.188-0.11,0.346-0.11c0.15,0,0.26,0.034,0.332,0.104
				c0.072,0.069,0.107,0.167,0.107,0.296c0,0.047-0.004,0.09-0.013,0.131c-0.008,0.041-0.021,0.081-0.038,0.117
				c-0.019,0.038-0.041,0.074-0.072,0.11c-0.027,0.035-0.063,0.071-0.105,0.108l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.023,0.039-0.041,0.071-0.047,0.099h0.554v0.232H50.774z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M52.682-25.661c-0.061,0.086-0.116,0.178-0.163,0.276
				s-0.089,0.199-0.126,0.302c-0.035,0.104-0.064,0.21-0.089,0.317c-0.022,0.107-0.041,0.212-0.054,0.315h-0.313
				c0.013-0.088,0.028-0.181,0.052-0.278c0.021-0.098,0.051-0.197,0.088-0.297c0.035-0.102,0.08-0.203,0.133-0.305
				c0.053-0.103,0.115-0.203,0.186-0.3h-0.617v-0.257h0.904V-25.661z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M53.104-25.487h-0.291v-0.194h0.026c0.101,0,0.177-0.019,0.235-0.056
				c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.299V-25.487z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M53.813-25.104c0.042-0.066,0.104-0.107,0.188-0.124v-0.004
				c-0.041-0.01-0.074-0.025-0.102-0.046c-0.025-0.021-0.048-0.044-0.064-0.07c-0.017-0.027-0.028-0.054-0.035-0.083
				c-0.008-0.029-0.01-0.057-0.01-0.083c0-0.144,0.037-0.247,0.114-0.31c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.315,0.094s0.115,0.166,0.115,0.31c0,0.026-0.004,0.054-0.012,0.083c-0.008,0.029-0.02,0.056-0.036,0.083
				c-0.017,0.026-0.038,0.049-0.065,0.07c-0.026,0.021-0.06,0.036-0.099,0.046v0.004c0.083,0.017,0.146,0.058,0.187,0.124
				c0.041,0.067,0.063,0.146,0.063,0.239c0,0.043-0.005,0.09-0.016,0.14c-0.01,0.052-0.032,0.099-0.065,0.143
				c-0.032,0.045-0.08,0.081-0.142,0.111c-0.062,0.028-0.144,0.043-0.245,0.043c-0.103,0-0.185-0.015-0.246-0.043
				c-0.062-0.03-0.108-0.066-0.142-0.111c-0.033-0.044-0.056-0.091-0.065-0.143c-0.011-0.05-0.017-0.097-0.017-0.14
				C53.75-24.958,53.771-25.037,53.813-25.104 M54.057-24.771c0.005,0.031,0.014,0.058,0.027,0.081
				c0.013,0.022,0.031,0.041,0.053,0.054s0.05,0.019,0.083,0.019c0.032,0,0.06-0.006,0.083-0.019
				c0.021-0.013,0.039-0.032,0.052-0.054c0.013-0.023,0.022-0.05,0.026-0.081c0.006-0.031,0.009-0.064,0.009-0.099
				c0-0.035-0.003-0.069-0.009-0.099c-0.004-0.031-0.014-0.057-0.026-0.08s-0.03-0.041-0.052-0.054
				c-0.023-0.013-0.051-0.019-0.083-0.019c-0.033,0-0.062,0.006-0.083,0.019c-0.021,0.013-0.04,0.031-0.053,0.054
				c-0.014,0.023-0.022,0.049-0.027,0.08c-0.005,0.03-0.008,0.064-0.008,0.099C54.049-24.835,54.052-24.802,54.057-24.771
				 M54.113-25.367c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.104-0.05c0.027-0.033,0.041-0.085,0.041-0.155
				c0-0.135-0.049-0.201-0.146-0.201s-0.146,0.066-0.146,0.201C54.074-25.452,54.088-25.4,54.113-25.367"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M7.537-26.015c0.076,0,0.14,0.013,0.192,0.038
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.053,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.142-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.138c-0.041,0.037-0.09,0.064-0.148,0.081
				c-0.058,0.018-0.123,0.028-0.193,0.028h-0.16v0.59H7.015v-1.48H7.537z M7.446-25.345c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058H7.313v0.45H7.446z"
        />
        <polygon
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          points="8.54,-26.015 8.751,-24.97 8.756,-24.97 8.969,-26.015
				9.41,-26.015 9.41,-24.535 9.137,-24.535 9.137,-25.717 9.133,-25.717 8.87,-24.535 8.637,-24.535 8.374,-25.717 8.37,-25.717
				8.37,-24.535 8.096,-24.535 8.096,-26.015 			"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M9.864-24.953c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.016,0.097,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.076,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.023-0.123-0.047-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.075-0.072-0.122
				c-0.015-0.045-0.023-0.099-0.023-0.16c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.009-0.04,0.022-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.114c0.019,0.032,0.061,0.06,0.124,0.086l0.23,0.1
				c0.057,0.024,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.269-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.074-0.108-0.179-0.108-0.316v-0.06h0.298V-24.953z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M11.471-25.571h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.055
				c0.058-0.038,0.094-0.098,0.109-0.181h0.22v1.467h-0.298V-25.571z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M13.038-25.745c-0.061,0.086-0.115,0.178-0.162,0.276
				c-0.048,0.098-0.09,0.199-0.126,0.302c-0.036,0.103-0.066,0.21-0.089,0.316c-0.024,0.108-0.042,0.213-0.054,0.316h-0.313
				c0.011-0.088,0.027-0.181,0.05-0.278c0.022-0.098,0.052-0.197,0.088-0.298s0.081-0.202,0.133-0.304
				c0.053-0.103,0.115-0.203,0.187-0.301h-0.618v-0.257h0.904V-25.745z"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M13.464-24.748c0.019,0.031,0.054,0.047,0.104,0.047
				c0.041,0,0.072-0.009,0.096-0.028c0.023-0.019,0.042-0.045,0.054-0.078c0.014-0.033,0.022-0.073,0.026-0.118
				c0.005-0.045,0.007-0.096,0.007-0.149v-0.07h-0.005c-0.024,0.036-0.06,0.066-0.106,0.089c-0.046,0.023-0.103,0.034-0.169,0.034
				c-0.065,0-0.12-0.011-0.165-0.034c-0.047-0.023-0.084-0.054-0.112-0.095s-0.049-0.089-0.062-0.145s-0.019-0.117-0.019-0.183
				c0-0.17,0.037-0.3,0.112-0.39c0.076-0.09,0.196-0.135,0.362-0.135c0.102,0,0.184,0.019,0.244,0.058
				c0.061,0.037,0.108,0.088,0.14,0.15c0.033,0.064,0.054,0.136,0.064,0.22c0.009,0.082,0.014,0.169,0.014,0.259
				c0,0.104-0.003,0.205-0.009,0.303c-0.007,0.098-0.025,0.184-0.056,0.258c-0.031,0.074-0.08,0.133-0.147,0.177
				c-0.067,0.043-0.161,0.065-0.281,0.065c-0.053,0-0.103-0.004-0.153-0.015c-0.049-0.01-0.092-0.03-0.129-0.058
				c-0.037-0.029-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.107-0.037-0.178h0.286C13.433-24.821,13.443-24.778,13.464-24.748
				 M13.448-25.304c0.025,0.043,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.127-0.064s0.037-0.115,0.037-0.213
				c0-0.042-0.002-0.081-0.006-0.117c-0.004-0.036-0.012-0.067-0.024-0.092c-0.011-0.025-0.028-0.045-0.05-0.06
				c-0.021-0.015-0.049-0.022-0.084-0.022c-0.035,0-0.064,0.007-0.086,0.022c-0.021,0.015-0.037,0.035-0.048,0.061
				c-0.011,0.026-0.019,0.057-0.022,0.092c-0.003,0.036-0.005,0.074-0.005,0.116C13.411-25.418,13.423-25.347,13.448-25.304"
        />
        <path
          clipPath="url(#MEDITATION_SVGID_86_)"
          fill="#FFFFFF"
          d="M14.418-25.741l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.085
				c0.044-0.017,0.098-0.025,0.161-0.025c0.071,0,0.129,0.013,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.106
				c0.027,0.045,0.046,0.094,0.057,0.151c0.011,0.055,0.017,0.112,0.017,0.17c0,0.082-0.007,0.156-0.021,0.223
				c-0.013,0.065-0.038,0.122-0.073,0.17c-0.036,0.048-0.084,0.083-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.04-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.05,0.005,0.079c0.002,0.027,0.009,0.053,0.019,0.077
				c0.011,0.024,0.025,0.044,0.045,0.06c0.02,0.016,0.045,0.023,0.077,0.023c0.062,0,0.106-0.023,0.133-0.07
				c0.027-0.048,0.041-0.12,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
				c-0.013-0.023-0.03-0.04-0.053-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				s-0.037,0.058-0.042,0.099H14.14l0.054-0.798h0.765v0.232H14.418z"
        />
      </g>
    </g>
  );
};

const Fitness = () => {
  return (
    <g id="Fitness">
      <g>
        <defs>
          <rect
            id="FITNESS_SVGID_87_"
            x="0.002"
            y="0.001"
            width="61.687"
            height="61.687"
          />
        </defs>
        <clipPath id="FITNESS_SVGID_88_">
          <use xlinkHref="#FITNESS_SVGID_87_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#8B52A1"
          d="M55.509,61.071H6.181c-3.059,0-5.563-2.504-5.563-5.563V6.181
				c0-3.06,2.504-5.563,5.563-5.563h49.328c3.06,0,5.563,2.503,5.563,5.563v49.327C61.071,58.567,58.568,61.071,55.509,61.071"
        />
        <rect
          x="3.558"
          y="42.631"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#663372"
          width="54.573"
          height="15.146"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          points="21.785,8.674 23.651,8.674 23.651,9.53 21.785,9.53 21.785,10.958
				20.803,10.958 20.803,6.974 23.882,6.974 23.882,7.836 21.785,7.836 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M25.36,7.093c0,0.303-0.232,0.529-0.553,0.529
				c-0.321,0-0.547-0.226-0.547-0.529c0-0.292,0.226-0.541,0.547-0.541C25.128,6.552,25.36,6.801,25.36,7.093 M24.332,7.972h0.951
				v2.986h-0.951V7.972z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M28.151,10.827c-0.232,0.125-0.476,0.22-0.815,0.22
				c-0.743,0-1.189-0.403-1.189-1.189v-1.13h-0.541V7.973h0.541v-0.88H27.1v0.88h0.879v0.755H27.1v1.034
				c0,0.31,0.148,0.434,0.398,0.434c0.131,0,0.309-0.047,0.434-0.118L28.151,10.827z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M31.529,9.126v1.832h-0.946V9.329c0-0.351-0.208-0.589-0.512-0.589
				c-0.374,0-0.618,0.255-0.618,0.832v1.386h-0.951V7.972h0.951v0.286c0.226-0.238,0.541-0.374,0.916-0.374
				C31.065,7.884,31.529,8.377,31.529,9.126"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M35.071,9.709h-2.187c0.076,0.356,0.32,0.576,0.642,0.576
				c0.22,0,0.475-0.053,0.637-0.345l0.845,0.173c-0.257,0.631-0.798,0.934-1.481,0.934c-0.862,0-1.575-0.637-1.575-1.583
				c0-0.939,0.713-1.58,1.581-1.58c0.845,0,1.522,0.606,1.539,1.58V9.709z M32.902,9.126h1.23c-0.09-0.321-0.326-0.47-0.601-0.47
				C33.264,8.656,32.991,8.823,32.902,9.126"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M36.25,9.988c0.041,0.256,0.273,0.351,0.528,0.351
				c0.25,0,0.387-0.106,0.387-0.238c0-0.101-0.078-0.178-0.298-0.219l-0.605-0.12c-0.553-0.1-0.868-0.398-0.868-0.868
				c0-0.612,0.528-1.01,1.283-1.01c0.744,0,1.237,0.339,1.355,0.856l-0.867,0.172c-0.029-0.184-0.207-0.351-0.498-0.351
				c-0.257,0-0.341,0.125-0.341,0.232c0,0.083,0.036,0.167,0.228,0.208l0.701,0.143c0.563,0.12,0.825,0.458,0.825,0.898
				c0,0.659-0.569,1.005-1.354,1.005c-0.702,0-1.302-0.255-1.403-0.869L36.25,9.988z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M39.278,9.988c0.041,0.256,0.273,0.351,0.528,0.351
				c0.25,0,0.388-0.106,0.388-0.238c0-0.101-0.078-0.178-0.299-0.219l-0.605-0.12c-0.553-0.1-0.868-0.398-0.868-0.868
				c0-0.612,0.528-1.01,1.284-1.01c0.742,0,1.236,0.339,1.355,0.856l-0.867,0.172c-0.031-0.184-0.209-0.351-0.5-0.351
				c-0.256,0-0.34,0.125-0.34,0.232c0,0.083,0.036,0.167,0.227,0.208l0.701,0.143c0.565,0.12,0.828,0.458,0.828,0.898
				c0,0.659-0.572,1.005-1.356,1.005c-0.702,0-1.302-0.255-1.403-0.869L39.278,9.988z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          d="M34.793,38.639c0,0.197-0.16,0.357-0.357,0.357s-0.357-0.16-0.357-0.357
				c0-0.196,0.16-0.356,0.357-0.356S34.793,38.442,34.793,38.639"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          d="M36.184,38.639c0,0.197-0.16,0.357-0.357,0.357s-0.357-0.16-0.357-0.357
				c0-0.196,0.16-0.356,0.357-0.356S36.184,38.442,36.184,38.639"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#ED1C24"
          d="M34.436,42.63c0,0-1.996-7.533-0.663-15.896
				c0.289-1.817,1.86-3.152,3.699-3.152h19.891V42.63H34.436z"
        />
        <rect
          x="36.593"
          y="37.807"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#97A0A4"
          width="20.77"
          height="4.823"
        />
        <rect
          x="42.597"
          y="23.581"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#97A0A4"
          width="2.741"
          height="14.225"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M44.317,42.63h-30.65c-0.805,0-1.458-0.652-1.458-1.458v-0.446h32.926v1.086
				C45.136,42.263,44.77,42.63,44.317,42.63"
        />
        <rect
          x="12.209"
          y="39.72"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#0E4C8C"
          width="32.927"
          height="1.006"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M12.209,39.72c0-1.13,1.39-3.436,5.734-3.826v3.826H12.209z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#00AEEF"
          d="M31.453,31.206c0,0,1.781-0.956,2.736-1.564c0,0,0.955,0.912,0.304,2.867
				S31.453,31.206,31.453,31.206"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#00AEEF"
          d="M17.943,35.894c3.947-0.478,9.357-2.666,12.839-4.925
				c0.522-0.338,1.198-0.325,1.698,0.048c0.93,0.693,2.518,1.698,4.012,1.793c2.736,0.174,4.3-0.174,6.081-1.91
				c0,0,2.563,2.131,2.563,8.82H17.943V35.894z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#0E4C8C"
          d="M40.055,39.72h5.081c0-2.703-0.419-4.651-0.918-6.026
				C39.522,34.903,40.142,38.614,40.055,39.72"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#0E4C8C"
          d="M23.282,36.118c0,0.313-0.254,0.568-0.568,0.568
				c-0.313,0-0.568-0.255-0.568-0.568c0-0.313,0.255-0.568,0.568-0.568C23.028,35.55,23.282,35.806,23.282,36.118"
        />

        <line
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.518"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="22.714"
          y1="36.118"
          x2="22.266"
          y2="34.901"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#0E4C8C"
          d="M25.239,35.472c0,0.313-0.254,0.568-0.568,0.568s-0.568-0.256-0.568-0.568
				c0-0.314,0.254-0.568,0.568-0.568S25.239,35.157,25.239,35.472"
        />

        <line
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.518"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="24.671"
          y1="35.472"
          x2="24.154"
          y2="34.245"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#0E4C8C"
          d="M27.07,34.655c0,0.313-0.254,0.567-0.568,0.567s-0.568-0.255-0.568-0.567
				c0-0.313,0.254-0.568,0.568-0.568S27.07,34.342,27.07,34.655"
        />

        <line
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.518"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="26.502"
          y1="34.655"
          x2="25.992"
          y2="33.499"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#0E4C8C"
          d="M28.85,33.884c0,0.313-0.254,0.568-0.568,0.568
				c-0.313,0-0.568-0.255-0.568-0.568c0-0.313,0.255-0.568,0.568-0.568C28.596,33.315,28.85,33.571,28.85,33.884"
        />

        <line
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.518"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="28.282"
          y1="33.884"
          x2="27.673"
          y2="32.718"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#0E4C8C"
          d="M30.598,32.985c0,0.313-0.254,0.568-0.568,0.568
				c-0.313,0-0.568-0.255-0.568-0.568c0-0.313,0.255-0.567,0.568-0.567C30.344,32.418,30.598,32.673,30.598,32.985"
        />

        <line
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.518"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="30.031"
          y1="32.985"
          x2="29.333"
          y2="31.844"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M43.37,31.903c-0.443-0.709-0.797-1.004-0.797-1.004
				c-1.781,1.736-3.344,2.084-6.081,1.91c-1.494-0.095-3.083-1.101-4.012-1.793c-0.5-0.373-1.175-0.387-1.698-0.048
				c-0.07,0.046-0.144,0.091-0.215,0.136c1.012,0.986,3.44,2.897,7.077,2.897C40.329,34.002,42.212,32.899,43.37,31.903"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="none"
          stroke="#97A0A4"
          strokeWidth="2.746"
          strokeMiterlimit="10"
          d="M43.967,23.581
				c0-4.328,3.508-7.836,7.837-7.836c4.328,0,7.837,3.508,7.837,7.836"
        />
        <rect
          x="45.338"
          y="27.595"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#0E4C8C"
          width="12.024"
          height="1.557"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#B394BF"
          d="M56.012,4.408c0.688,0,1.271,0.581,1.271,1.27v50.334
				c0,0.688-0.582,1.27-1.271,1.27H5.679c-0.689,0-1.271-0.582-1.271-1.27V5.678c0-0.689,0.582-1.27,1.271-1.27H56.012 M56.012,0
				H5.679C2.556,0,0.002,2.556,0.002,5.678v50.334c0,3.121,2.554,5.676,5.677,5.676h50.333c3.122,0,5.677-2.555,5.677-5.676V5.678
				C61.688,2.556,59.134,0,56.012,0"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M56.3,33.316c0,1.29-1.641,2.336-3.666,2.336
				c-2.024,0-3.666-1.046-3.666-2.336s1.642-2.336,3.666-2.336C54.659,30.98,56.3,32.026,56.3,33.316"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#5087C7"
          d="M30.856,55.361c-2.638,0-4.773-2.136-4.773-4.772s2.135-4.772,4.773-4.772
				s4.774,2.136,4.774,4.772S33.494,55.361,30.856,55.361"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M30.856,45.816c2.638,0,4.774,2.136,4.774,4.772s-2.136,4.772-4.774,4.772
				s-4.773-2.136-4.773-4.772S28.218,45.816,30.856,45.816 M30.856,45.404c-2.859,0-5.185,2.326-5.185,5.185
				s2.326,5.185,5.185,5.185c2.86,0,5.186-2.326,5.186-5.185S33.716,45.404,30.856,45.404"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M33.35,48.644c-0.239,0-0.464,0.108-0.62,0.252l-0.013-0.013
				c0.072-0.119,0.169-0.346,0.169-0.716c0-0.883-0.884-1.551-2.029-1.551c-1.145,0-2.041,0.668-2.041,1.551
				c0,0.37,0.119,0.597,0.179,0.716l-0.013,0.013c-0.154-0.144-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.704-1.457,2.018
				c0,1.289,0.717,2.017,1.492,2.017c0.716,0,1.218-0.396,1.671-0.848c-0.072-0.108-0.18-0.216-0.298-0.216
				c-0.12,0-0.252,0.084-0.478,0.264c-0.298,0.227-0.561,0.357-0.907,0.357c-0.609,0-0.87-0.681-0.87-1.574
				c0-0.955,0.251-1.588,0.895-1.588c0.286,0,0.476,0.084,0.668,0.264c0.286,0.262,0.381,0.333,0.512,0.333
				c0.096,0,0.156-0.06,0.18-0.095c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.67,0.56-1.135,1.611-1.135
				c1.049,0,1.61,0.465,1.61,1.135c0,0.584-0.311,1.037-0.524,1.252c0.023,0.035,0.083,0.095,0.179,0.095
				c0.131,0,0.227-0.071,0.512-0.333c0.18-0.18,0.383-0.264,0.668-0.264c0.646,0,0.896,0.633,0.896,1.588
				c0,0.894-0.263,1.574-0.871,1.574c-0.346,0-0.621-0.131-0.908-0.357c-0.226-0.18-0.356-0.264-0.476-0.264
				c-0.12,0-0.227,0.107-0.299,0.216c0.44,0.452,0.955,0.848,1.671,0.848c0.775,0,1.491-0.728,1.491-2.017
				C34.806,49.348,34.078,48.644,33.35,48.644"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M31.655,48.62h-0.537c-0.035,0-0.059-0.023-0.071-0.047l-0.168-0.514
				c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.047-0.059,0.047h-0.537c-0.025,0-0.036,0.012-0.012,0.023
				l0.442,0.323c0.023,0.022,0.023,0.06,0.011,0.083l-0.167,0.501c-0.011,0.024-0.011,0.048,0.024,0.024l0.442-0.31
				c0.036-0.024,0.059-0.024,0.095,0l0.43,0.31c0.023,0.023,0.036,0.012,0.023-0.013l-0.166-0.513c-0.012-0.035,0-0.071,0.024-0.083
				l0.44-0.323C31.691,48.632,31.679,48.62,31.655,48.62"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M31.25,52.151c-0.226-0.572-0.406-0.965-0.406-1.443
				c0-0.203,0.06-0.381,0.096-0.477c0.048-0.096,0.06-0.155,0.012-0.155c-0.072,0-0.227,0.167-0.322,0.31
				c-0.073,0.119-0.156,0.348-0.156,0.525c0,0.335,0.108,0.691,0.179,0.906c0.132,0.371,0.251,0.8,0.251,1.207
				c0,0.644-0.287,1.084-0.799,1.478c0.047,0.037,0.167,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.324
				C31.513,52.819,31.381,52.51,31.25,52.151"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M-12.302-25.932c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.302z M-12.394-25.263c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H-12.394z
				"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          points="-11.299,-25.932 -11.088,-24.887 -11.083,-24.887 -10.871,-25.932
				-10.428,-25.932 -10.428,-24.452 -10.703,-24.452 -10.703,-25.634 -10.707,-25.634 -10.969,-24.452 -11.203,-24.452
				-11.464,-25.634 -11.468,-25.634 -11.468,-24.452 -11.742,-24.452 -11.742,-25.932 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M-9.975-24.871c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.024-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.123-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.023,0.009-0.04,0.021-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078s0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102
				c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.871z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M-8.406-25.658l-0.031,0.334h0.004c0.028-0.039,0.064-0.067,0.108-0.085
				c0.044-0.017,0.098-0.025,0.162-0.025c0.07,0,0.128,0.013,0.175,0.04c0.046,0.027,0.083,0.063,0.11,0.107
				c0.026,0.044,0.046,0.094,0.057,0.15c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.066-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.083-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037s-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287c0,0.024,0.001,0.05,0.004,0.078c0.002,0.028,0.009,0.054,0.02,0.078
				c0.01,0.024,0.025,0.044,0.044,0.06c0.02,0.016,0.045,0.023,0.076,0.023c0.063,0,0.107-0.023,0.135-0.071
				c0.027-0.047,0.04-0.119,0.04-0.214c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.041-0.014-0.072-0.027-0.096
				c-0.014-0.024-0.031-0.04-0.054-0.049c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.022,0.024-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H-8.406z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M-7.714-24.452c0-0.065,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.051,0.06-0.101,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.148l0.121-0.112
				c0.033-0.03,0.06-0.056,0.08-0.081c0.02-0.025,0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.011,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.109h-0.287v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
				c0.072,0.069,0.108,0.168,0.108,0.296c0,0.047-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109L-7.212-24.9c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H-7.714z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M-6.719-24.452c0-0.065,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.051,0.06-0.101,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.148l0.121-0.112
				c0.033-0.03,0.06-0.056,0.08-0.081c0.02-0.025,0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.011,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.109h-0.287v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
				c0.072,0.069,0.108,0.168,0.108,0.296c0,0.047-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109L-6.217-24.9c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H-6.719z"
        />
        <rect
          x="-3.383"
          y="-26.43"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#8B52A1"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M-2.47-25.932c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.47z M-2.561-25.263c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.057-0.16-0.057h-0.124v0.449H-2.561z"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          points="-1.467,-25.932 -1.256,-24.887 -1.251,-24.887 -1.038,-25.932
				-0.596,-25.932 -0.596,-24.453 -0.87,-24.453 -0.87,-25.633 -0.874,-25.633 -1.137,-24.453 -1.37,-24.453 -1.632,-25.633
				-1.636,-25.633 -1.636,-24.453 -1.91,-24.453 -1.91,-25.932 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M-0.142-24.871c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.024-0.024-0.046-0.044-0.065c-0.021-0.02-0.048-0.038-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.049
				c-0.066-0.022-0.124-0.046-0.172-0.072c-0.049-0.027-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.184,0.022s0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124
				c0.023,0.051,0.034,0.112,0.034,0.182v0.041H0.209c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.123-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.023,0.009-0.04,0.021-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.056
				c-0.005,0.02-0.007,0.041-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.06,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102
				c0.007,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.073-0.108-0.178-0.108-0.315v-0.061h0.298V-24.871z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M1.426-25.658l-0.031,0.334h0.004c0.028-0.039,0.064-0.067,0.108-0.084
				s0.098-0.026,0.162-0.026c0.07,0,0.129,0.013,0.175,0.04c0.046,0.027,0.083,0.063,0.11,0.107C1.98-25.243,2-25.193,2.011-25.137
				c0.01,0.056,0.016,0.114,0.016,0.171c0,0.082-0.007,0.156-0.021,0.222c-0.013,0.067-0.038,0.123-0.073,0.171
				c-0.035,0.048-0.084,0.084-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036c-0.093,0-0.168-0.012-0.225-0.037
				c-0.057-0.025-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.061-0.132c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039H1.41
				c0,0.024,0.001,0.051,0.004,0.078c0.002,0.028,0.009,0.054,0.02,0.078c0.01,0.024,0.025,0.045,0.044,0.06
				c0.02,0.016,0.045,0.024,0.076,0.024c0.063,0,0.107-0.024,0.135-0.072c0.027-0.047,0.04-0.119,0.04-0.214
				c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.041-0.014-0.072-0.027-0.096c-0.014-0.024-0.031-0.04-0.054-0.049
				c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036c-0.022,0.025-0.037,0.057-0.042,0.099H1.149l0.053-0.798
				h0.765v0.232H1.426z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M2.458-25.488H2.167v-0.195h0.027c0.1,0,0.177-0.018,0.235-0.056
				c0.057-0.038,0.094-0.097,0.109-0.18h0.219v1.467H2.458V-25.488z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M3.438-24.685c0.021,0.045,0.064,0.067,0.13,0.067
				c0.035,0,0.064-0.006,0.085-0.018c0.022-0.013,0.038-0.029,0.05-0.051c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.03,0.005-0.06,0.005-0.091c0-0.034-0.002-0.064-0.007-0.095s-0.015-0.056-0.028-0.079
				c-0.014-0.024-0.033-0.043-0.058-0.057c-0.025-0.013-0.059-0.021-0.1-0.021H3.439V-25.3h0.097c0.032,0,0.058-0.006,0.08-0.019
				c0.021-0.013,0.039-0.03,0.052-0.051c0.013-0.022,0.022-0.047,0.029-0.075c0.006-0.027,0.009-0.056,0.009-0.087
				c0-0.071-0.012-0.12-0.035-0.149c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.005-0.077,0.017
				c-0.021,0.012-0.037,0.029-0.047,0.05c-0.011,0.021-0.019,0.047-0.022,0.076c-0.003,0.029-0.005,0.061-0.005,0.095H3.139
				c0-0.147,0.036-0.255,0.109-0.326c0.073-0.071,0.18-0.107,0.322-0.107c0.133,0,0.237,0.03,0.311,0.091
				c0.074,0.061,0.111,0.156,0.111,0.287c0,0.088-0.02,0.16-0.059,0.216s-0.094,0.091-0.164,0.106v0.004
				c0.094,0.016,0.162,0.054,0.201,0.116c0.04,0.061,0.06,0.138,0.06,0.231c0,0.049-0.007,0.101-0.019,0.153
				c-0.013,0.053-0.037,0.1-0.072,0.143c-0.037,0.043-0.086,0.078-0.149,0.104c-0.062,0.026-0.146,0.039-0.248,0.039
				c-0.142,0-0.248-0.039-0.319-0.116c-0.07-0.077-0.105-0.187-0.105-0.33v-0.006h0.286C3.405-24.795,3.416-24.73,3.438-24.685"
        />
        <rect
          x="6.484"
          y="-26.43"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#663372"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M7.398-25.932c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59H6.876v-1.479H7.398z M7.308-25.263c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.057-0.16-0.057H7.174v0.449H7.308z"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          points="8.401,-25.932 8.612,-24.887 8.618,-24.887 8.831,-25.932
				9.272,-25.932 9.272,-24.453 8.999,-24.453 8.999,-25.633 8.995,-25.633 8.731,-24.453 8.499,-24.453 8.236,-25.633
				8.232,-25.633 8.232,-24.453 7.958,-24.453 7.958,-25.932 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M9.726-24.871c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.024-0.024-0.046-0.044-0.065c-0.021-0.02-0.048-0.038-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.049
				C9.77-25.1,9.712-25.124,9.664-25.15c-0.049-0.027-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124
				c0.023,0.051,0.034,0.112,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.123-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.023,0.009-0.04,0.021-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.056
				c-0.005,0.02-0.007,0.041-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.06,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078s0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102
				c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.073-0.108-0.178-0.108-0.315v-0.061h0.298V-24.871z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M11.295-25.658l-0.031,0.334h0.004c0.028-0.039,0.064-0.067,0.108-0.084
				c0.044-0.017,0.098-0.026,0.162-0.026c0.07,0,0.129,0.013,0.175,0.04c0.046,0.027,0.083,0.063,0.11,0.107
				c0.026,0.044,0.046,0.094,0.057,0.15c0.01,0.056,0.016,0.114,0.016,0.171c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.067-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.084-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037s-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287c0,0.024,0.001,0.051,0.004,0.078c0.002,0.028,0.009,0.054,0.02,0.078
				c0.01,0.024,0.025,0.045,0.044,0.06c0.02,0.016,0.045,0.024,0.076,0.024c0.063,0,0.107-0.024,0.135-0.072
				c0.027-0.047,0.04-0.119,0.04-0.214c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.041-0.014-0.072-0.027-0.096
				c-0.014-0.024-0.031-0.04-0.054-0.049c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.022,0.025-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H11.295z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M11.987-24.452c0-0.065,0.007-0.125,0.02-0.182
				c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.08-0.081c0.02-0.025,0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.011,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.109h-0.287v-0.055
				c0-0.137,0.037-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
				c0.072,0.07,0.108,0.168,0.108,0.296c0,0.048-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.039-0.079,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H11.987z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M12.993-25.431c0.006-0.094,0.025-0.177,0.056-0.249
				c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.147-0.066,0.256-0.066c0.09,0,0.164,0.013,0.223,0.039
				c0.059,0.025,0.106,0.067,0.141,0.123c0.035,0.057,0.06,0.13,0.075,0.221c0.014,0.09,0.022,0.2,0.022,0.33
				c0,0.102-0.004,0.2-0.011,0.294c-0.007,0.094-0.026,0.178-0.056,0.25c-0.03,0.073-0.077,0.13-0.138,0.171
				c-0.061,0.042-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.024-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.061-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C12.982-25.239,12.985-25.336,12.993-25.431
				 M13.288-24.845c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.028,0.057,0.05,0.069c0.02,0.013,0.046,0.018,0.078,0.018
				c0.033,0,0.061-0.007,0.081-0.023s0.037-0.043,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.153
				c0.004-0.063,0.007-0.142,0.007-0.235v-0.16c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.058-0.014-0.105-0.026-0.139
				c-0.013-0.034-0.03-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.008-0.08,0.024
				c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.089-0.025,0.152c-0.005,0.063-0.008,0.142-0.008,0.236v0.161
				C13.28-24.979,13.283-24.903,13.288-24.845"
        />
        <rect
          x="16.315"
          y="-26.43"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#00AEEF"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M17.229-25.932c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.229z M17.137-25.263c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.057-0.159-0.057h-0.125v0.449H17.137
				z"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          points="18.232,-25.932 18.443,-24.887 18.448,-24.887 18.661,-25.932
				19.102,-25.932 19.102,-24.453 18.829,-24.453 18.829,-25.633 18.825,-25.633 18.562,-24.453 18.329,-24.453 18.066,-25.633
				18.062,-25.633 18.062,-24.453 17.788,-24.453 17.788,-25.932 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M19.556-24.871c0,0.035,0.003,0.066,0.009,0.095
				c0.004,0.028,0.015,0.052,0.03,0.071c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
				c0.045,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.024-0.024-0.046-0.045-0.065s-0.048-0.038-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.049
				c-0.066-0.021-0.123-0.046-0.172-0.072c-0.048-0.027-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.022c0.056,0.015,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.112,0.034,0.182v0.041h-0.285c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.023,0.009-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.056
				c-0.005,0.02-0.007,0.041-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.06,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078s0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.066,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.168,0-0.289-0.037-0.362-0.11c-0.072-0.073-0.108-0.178-0.108-0.315v-0.061h0.298V-24.871z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M17.229-23.988c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081S17.234-23.1,17.165-23.1h-0.16
				v0.59h-0.298v-1.479H17.229z M17.137-23.319c0.059,0,0.107-0.018,0.144-0.054s0.055-0.092,0.055-0.168
				c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.057-0.159-0.057h-0.125v0.449H17.137z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M18.047-23.624v0.15h0.005c0.028-0.054,0.064-0.097,0.105-0.13
				c0.041-0.032,0.092-0.049,0.151-0.049c0.009,0,0.017,0,0.027,0.001c0.008,0.001,0.017,0.002,0.025,0.003v0.274
				c-0.015-0.001-0.03-0.002-0.044-0.003c-0.015-0.001-0.03-0.001-0.045-0.001c-0.025,0-0.05,0.003-0.075,0.01
				s-0.047,0.018-0.068,0.035c-0.021,0.015-0.037,0.036-0.049,0.061c-0.013,0.026-0.02,0.058-0.02,0.095v0.669h-0.285v-1.115H18.047
				z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M18.45-23.319c0.015-0.073,0.04-0.134,0.074-0.183
				c0.035-0.049,0.08-0.086,0.137-0.112c0.055-0.026,0.124-0.039,0.205-0.039c0.086-0.001,0.157,0.011,0.214,0.035
				s0.101,0.061,0.134,0.111c0.034,0.049,0.057,0.111,0.071,0.184c0.014,0.074,0.021,0.159,0.021,0.257
				c0,0.095-0.008,0.18-0.023,0.253c-0.015,0.073-0.04,0.135-0.075,0.183c-0.034,0.05-0.08,0.086-0.135,0.11
				c-0.057,0.023-0.125,0.034-0.207,0.034c-0.085,0.002-0.157-0.009-0.213-0.031c-0.057-0.024-0.101-0.059-0.135-0.109
				c-0.033-0.048-0.056-0.11-0.07-0.184s-0.021-0.159-0.021-0.256C18.427-23.162,18.435-23.246,18.45-23.319 M18.717-22.909
				c0.003,0.048,0.01,0.09,0.02,0.124c0.011,0.035,0.026,0.061,0.046,0.081c0.021,0.019,0.049,0.029,0.083,0.029
				c0.058,0,0.099-0.03,0.121-0.092c0.022-0.06,0.033-0.16,0.033-0.3c0-0.139-0.011-0.24-0.033-0.301
				c-0.022-0.06-0.063-0.091-0.121-0.091c-0.034,0-0.062,0.01-0.083,0.03c-0.02,0.019-0.035,0.046-0.046,0.08
				c-0.01,0.035-0.017,0.077-0.02,0.125s-0.004,0.1-0.004,0.157C18.713-23.01,18.714-22.958,18.717-22.909"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M19.99-23.303c-0.004-0.026-0.01-0.05-0.019-0.071
				c-0.008-0.022-0.021-0.039-0.037-0.052c-0.018-0.013-0.04-0.02-0.067-0.02c-0.035,0-0.063,0.008-0.085,0.024
				s-0.039,0.04-0.05,0.07c-0.011,0.031-0.019,0.071-0.023,0.118c-0.004,0.048-0.006,0.105-0.006,0.169
				c0,0.07,0.002,0.129,0.006,0.178c0.004,0.049,0.012,0.09,0.023,0.121c0.011,0.032,0.027,0.055,0.047,0.07
				c0.02,0.014,0.046,0.021,0.077,0.021c0.049,0,0.084-0.02,0.106-0.062c0.022-0.041,0.034-0.108,0.034-0.199h0.273
				c0,0.146-0.033,0.258-0.098,0.335c-0.066,0.077-0.175,0.115-0.327,0.115c-0.072,0-0.134-0.009-0.188-0.026
				c-0.053-0.017-0.097-0.048-0.132-0.093c-0.036-0.045-0.063-0.105-0.08-0.181c-0.018-0.075-0.027-0.168-0.027-0.281
				c0-0.114,0.011-0.209,0.033-0.285c0.022-0.075,0.053-0.134,0.094-0.18c0.04-0.045,0.087-0.076,0.142-0.094
				c0.056-0.018,0.117-0.027,0.183-0.027c0.132,0,0.232,0.039,0.3,0.116c0.067,0.078,0.1,0.182,0.1,0.315h-0.273
				C19.996-23.25,19.994-23.277,19.99-23.303"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M20.663-22.934c0,0.033,0.002,0.066,0.007,0.096
				c0.005,0.031,0.013,0.059,0.024,0.083c0.011,0.024,0.026,0.043,0.044,0.059c0.017,0.014,0.04,0.021,0.066,0.021
				c0.048,0,0.083-0.017,0.106-0.052c0.022-0.034,0.037-0.087,0.045-0.158h0.257c-0.005,0.131-0.04,0.229-0.103,0.297
				c-0.064,0.068-0.164,0.102-0.301,0.102c-0.104,0-0.184-0.017-0.242-0.053c-0.059-0.034-0.101-0.079-0.129-0.136
				s-0.044-0.12-0.051-0.19c-0.006-0.071-0.009-0.142-0.009-0.212c0-0.075,0.005-0.147,0.016-0.216
				c0.009-0.068,0.03-0.13,0.062-0.184c0.03-0.054,0.075-0.097,0.133-0.128c0.058-0.033,0.136-0.048,0.233-0.048
				c0.082,0,0.15,0.013,0.203,0.04c0.054,0.027,0.096,0.066,0.126,0.114c0.03,0.049,0.051,0.109,0.062,0.179
				c0.011,0.069,0.017,0.147,0.017,0.233v0.064h-0.566V-22.934z M20.947-23.306c-0.005-0.032-0.011-0.06-0.022-0.083
				c-0.01-0.023-0.025-0.04-0.043-0.052c-0.019-0.011-0.043-0.017-0.072-0.017s-0.053,0.006-0.071,0.019
				c-0.019,0.014-0.034,0.031-0.045,0.053c-0.011,0.021-0.019,0.045-0.024,0.07c-0.005,0.027-0.007,0.053-0.007,0.079v0.044h0.292
				C20.954-23.236,20.951-23.273,20.947-23.306"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M21.587-22.836c0,0.048,0.013,0.088,0.038,0.117
				c0.026,0.03,0.063,0.045,0.111,0.045c0.045,0,0.08-0.012,0.106-0.034c0.026-0.022,0.039-0.054,0.039-0.099
				c0-0.036-0.01-0.063-0.03-0.082c-0.021-0.019-0.045-0.034-0.073-0.044l-0.201-0.073c-0.079-0.028-0.138-0.067-0.178-0.117
				c-0.041-0.05-0.06-0.115-0.06-0.194c0-0.046,0.007-0.089,0.022-0.13c0.016-0.04,0.04-0.076,0.073-0.106s0.076-0.055,0.127-0.073
				c0.052-0.018,0.115-0.027,0.188-0.027c0.129,0,0.226,0.028,0.288,0.083c0.062,0.055,0.094,0.133,0.094,0.235v0.045h-0.257
				c0-0.058-0.01-0.1-0.029-0.127c-0.019-0.028-0.051-0.041-0.098-0.041c-0.036,0-0.067,0.01-0.095,0.03
				c-0.027,0.02-0.04,0.05-0.04,0.09c0,0.028,0.009,0.053,0.026,0.075s0.05,0.041,0.098,0.056l0.172,0.058
				c0.09,0.03,0.154,0.07,0.191,0.119c0.038,0.049,0.056,0.116,0.056,0.2c0,0.059-0.01,0.111-0.031,0.155
				c-0.021,0.043-0.049,0.079-0.086,0.109c-0.036,0.028-0.08,0.049-0.131,0.062c-0.05,0.012-0.105,0.018-0.167,0.018
				c-0.079,0-0.146-0.008-0.2-0.023c-0.052-0.015-0.094-0.037-0.126-0.068c-0.031-0.03-0.053-0.067-0.065-0.111
				c-0.012-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.836z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M22.507-22.836c0,0.048,0.012,0.088,0.038,0.117
				c0.026,0.03,0.063,0.045,0.111,0.045c0.044,0,0.08-0.012,0.106-0.034c0.026-0.022,0.039-0.054,0.039-0.099
				c0-0.036-0.01-0.063-0.031-0.082s-0.045-0.034-0.072-0.044l-0.202-0.073c-0.078-0.028-0.138-0.067-0.178-0.117
				c-0.04-0.05-0.06-0.115-0.06-0.194c0-0.046,0.008-0.089,0.023-0.13c0.015-0.04,0.039-0.076,0.073-0.106
				c0.033-0.03,0.075-0.055,0.127-0.073c0.052-0.018,0.115-0.027,0.188-0.027c0.129,0,0.226,0.028,0.288,0.083
				c0.062,0.055,0.093,0.133,0.093,0.235v0.045h-0.257c0-0.058-0.009-0.1-0.028-0.127c-0.019-0.028-0.052-0.041-0.098-0.041
				c-0.037,0-0.068,0.01-0.095,0.03c-0.027,0.02-0.04,0.05-0.04,0.09c0,0.028,0.008,0.053,0.026,0.075
				c0.017,0.022,0.05,0.041,0.098,0.056l0.172,0.058c0.09,0.03,0.154,0.07,0.191,0.119s0.056,0.116,0.056,0.2
				c0,0.059-0.01,0.111-0.031,0.155c-0.021,0.043-0.05,0.079-0.086,0.109c-0.037,0.028-0.081,0.049-0.131,0.062
				c-0.05,0.012-0.106,0.018-0.167,0.018c-0.079,0-0.146-0.008-0.2-0.023c-0.052-0.015-0.095-0.037-0.126-0.068
				c-0.032-0.03-0.053-0.067-0.065-0.111c-0.013-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.836z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M17.3-21.781c-0.026-0.049-0.073-0.074-0.14-0.074
				c-0.038,0-0.068,0.01-0.092,0.03c-0.024,0.021-0.044,0.053-0.058,0.098c-0.013,0.044-0.023,0.104-0.028,0.176
				c-0.006,0.073-0.009,0.161-0.009,0.266c0,0.112,0.004,0.203,0.012,0.272c0.007,0.069,0.019,0.123,0.035,0.16
				c0.016,0.038,0.036,0.064,0.06,0.077c0.024,0.013,0.052,0.02,0.084,0.02c0.026,0,0.051-0.005,0.074-0.014
				c0.023-0.008,0.042-0.027,0.058-0.053c0.017-0.027,0.03-0.065,0.04-0.114c0.01-0.05,0.015-0.114,0.015-0.194h0.298
				c0,0.08-0.007,0.156-0.018,0.227c-0.013,0.072-0.036,0.135-0.07,0.189c-0.034,0.054-0.082,0.096-0.143,0.127
				c-0.062,0.03-0.141,0.045-0.239,0.045c-0.112,0-0.201-0.018-0.268-0.054c-0.066-0.035-0.117-0.088-0.152-0.155
				c-0.035-0.068-0.058-0.149-0.069-0.242c-0.01-0.095-0.015-0.198-0.015-0.311c0-0.113,0.005-0.216,0.015-0.31
				c0.011-0.095,0.034-0.176,0.069-0.244c0.035-0.068,0.086-0.12,0.152-0.158c0.067-0.038,0.156-0.057,0.268-0.057
				c0.106,0,0.19,0.017,0.252,0.051c0.061,0.035,0.107,0.079,0.137,0.132c0.03,0.054,0.05,0.111,0.057,0.172
				c0.008,0.061,0.011,0.119,0.011,0.173h-0.298C17.338-21.654,17.325-21.732,17.3-21.781"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M17.886-20.415c0.052,0,0.09-0.013,0.113-0.041
				c0.023-0.028,0.036-0.065,0.04-0.11l-0.313-1.115h0.299l0.166,0.771h0.004l0.145-0.771h0.286l-0.27,1.04
				c-0.025,0.093-0.049,0.168-0.071,0.225c-0.023,0.057-0.05,0.102-0.081,0.133c-0.031,0.03-0.069,0.051-0.112,0.061
				c-0.044,0.01-0.098,0.014-0.165,0.014h-0.116v-0.214C17.836-20.417,17.861-20.415,17.886-20.415"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M18.75-21.366c0-0.065,0.01-0.119,0.031-0.162
				c0.021-0.044,0.049-0.079,0.084-0.107c0.035-0.026,0.076-0.046,0.124-0.057c0.048-0.012,0.099-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025c0.052,0.017,0.093,0.041,0.122,0.071c0.029,0.03,0.049,0.066,0.059,0.108
				c0.011,0.042,0.015,0.088,0.015,0.138v0.587c0,0.052,0.003,0.093,0.008,0.122c0.005,0.029,0.014,0.06,0.028,0.093h-0.274
				c-0.009-0.018-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059h-0.004c-0.033,0.059-0.071,0.096-0.115,0.113
				c-0.044,0.017-0.1,0.026-0.169,0.026c-0.05,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.072
				c-0.021-0.03-0.036-0.064-0.046-0.102c-0.01-0.038-0.014-0.076-0.014-0.113c0-0.052,0.005-0.097,0.016-0.136
				c0.012-0.038,0.028-0.07,0.051-0.097c0.023-0.028,0.051-0.05,0.087-0.068c0.035-0.018,0.078-0.033,0.128-0.048l0.161-0.043
				c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.076-0.03-0.101
				c-0.02-0.024-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.1,0.039c-0.022,0.027-0.034,0.062-0.034,0.106v0.031H18.75V-21.366
				z M19.21-21.087c-0.022,0.009-0.042,0.015-0.06,0.02c-0.059,0.012-0.1,0.033-0.124,0.062c-0.025,0.029-0.038,0.068-0.038,0.118
				c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.044,0.045,0.083,0.045c0.019,0,0.039-0.003,0.06-0.009
				c0.021-0.006,0.04-0.016,0.057-0.03s0.031-0.032,0.043-0.054c0.01-0.022,0.016-0.048,0.016-0.079v-0.219
				C19.252-21.108,19.232-21.096,19.21-21.087"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M20.022-21.681v0.131h0.005c0.03-0.055,0.069-0.096,0.117-0.121
				c0.049-0.026,0.104-0.039,0.167-0.039c0.09,0,0.16,0.025,0.209,0.075c0.05,0.05,0.074,0.13,0.074,0.243v0.826h-0.285v-0.769
				c0-0.057-0.011-0.098-0.03-0.121c-0.019-0.022-0.05-0.034-0.093-0.034c-0.101,0-0.152,0.061-0.152,0.185v0.739h-0.285v-1.115
				H20.022z"
        />
        <rect
          x="36.052"
          y="-26.43"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#ED1C24"
          width="8.813"
          height="8.812"
        />
        <rect
          x="45.92"
          y="-26.43"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#97A0A4"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M46.834-25.932c0.076,0,0.14,0.012,0.19,0.038
				c0.054,0.026,0.096,0.059,0.127,0.101c0.031,0.041,0.055,0.089,0.068,0.141c0.014,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.013,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.096,0.139s-0.09,0.063-0.147,0.081
				c-0.058,0.018-0.123,0.027-0.194,0.027H46.61v0.59h-0.299v-1.479H46.834z M46.743-25.263c0.059,0,0.107-0.018,0.145-0.054
				c0.035-0.036,0.053-0.092,0.053-0.168c0-0.074-0.014-0.131-0.047-0.17c-0.031-0.038-0.084-0.057-0.16-0.057H46.61v0.449H46.743z"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          points="47.837,-25.932 48.048,-24.886 48.053,-24.886 48.267,-25.932
				48.707,-25.932 48.707,-24.453 48.435,-24.453 48.435,-25.633 48.431,-25.633 48.167,-24.453 47.935,-24.453 47.671,-25.633
				47.667,-25.633 47.667,-24.453 47.394,-24.453 47.394,-25.932 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M49.161-24.871c0,0.035,0.004,0.066,0.009,0.095
				c0.005,0.028,0.016,0.052,0.03,0.071c0.016,0.02,0.037,0.035,0.062,0.045c0.024,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.015,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.062-0.014-0.086
				c-0.009-0.024-0.023-0.046-0.044-0.065c-0.021-0.019-0.048-0.038-0.081-0.053c-0.033-0.016-0.076-0.032-0.127-0.049
				c-0.064-0.022-0.123-0.046-0.172-0.072c-0.048-0.027-0.088-0.057-0.12-0.093c-0.034-0.035-0.057-0.076-0.071-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.192-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.183,0.022c0.057,0.015,0.104,0.038,0.146,0.07c0.042,0.033,0.073,0.074,0.096,0.124
				c0.022,0.051,0.034,0.112,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.124-0.037-0.162c-0.024-0.038-0.067-0.057-0.125-0.057
				c-0.032,0-0.06,0.005-0.083,0.015c-0.021,0.009-0.039,0.022-0.053,0.037c-0.014,0.016-0.021,0.035-0.027,0.056
				c-0.004,0.02-0.007,0.041-0.007,0.064c0,0.045,0.011,0.083,0.03,0.115c0.02,0.031,0.061,0.06,0.124,0.086l0.229,0.099
				c0.057,0.025,0.104,0.051,0.139,0.078c0.037,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.037,0.066,0.045,0.102
				c0.008,0.038,0.013,0.079,0.013,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.217,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.073-0.107-0.178-0.107-0.315v-0.061h0.297V-24.871z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M50.905-25.92h0.31v0.931h0.142v0.232h-0.142v0.305h-0.272v-0.305H50.42
				v-0.244L50.905-25.92z M50.938-25.546l-0.287,0.557h0.291v-0.557H50.938z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M51.423-24.452c0-0.065,0.007-0.125,0.02-0.182
				c0.014-0.057,0.033-0.111,0.061-0.162s0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.061-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.048-0.075c0.01-0.025,0.019-0.051,0.021-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.146-0.183c-0.037,0-0.066,0.008-0.088,0.023
				c-0.021,0.015-0.037,0.036-0.05,0.063c-0.011,0.027-0.02,0.057-0.022,0.092c-0.004,0.034-0.004,0.071-0.004,0.109h-0.287v-0.055
				c0-0.137,0.035-0.242,0.109-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.261,0.034,0.332,0.103
				c0.072,0.07,0.108,0.168,0.108,0.296c0,0.048-0.004,0.091-0.013,0.132c-0.01,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.063,0.072-0.106,0.109l-0.164,0.154c-0.045,0.039-0.079,0.079-0.104,0.116
				c-0.024,0.039-0.04,0.071-0.048,0.099h0.555v0.232H51.423z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M52.761-24.665c0.02,0.032,0.055,0.047,0.104,0.047
				c0.04,0,0.071-0.009,0.096-0.028c0.023-0.019,0.041-0.044,0.054-0.077c0.014-0.034,0.022-0.073,0.026-0.119
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.067-0.106,0.09s-0.103,0.034-0.17,0.034
				c-0.063,0-0.119-0.011-0.164-0.034c-0.047-0.023-0.084-0.055-0.111-0.095c-0.027-0.041-0.05-0.089-0.063-0.145
				c-0.014-0.057-0.02-0.117-0.02-0.184c0-0.17,0.038-0.3,0.113-0.39c0.074-0.089,0.195-0.134,0.361-0.134
				c0.103,0,0.184,0.019,0.244,0.057c0.061,0.038,0.108,0.089,0.141,0.151c0.031,0.063,0.054,0.136,0.063,0.219
				c0.01,0.083,0.014,0.169,0.014,0.259c0,0.105-0.002,0.206-0.008,0.303c-0.007,0.098-0.025,0.184-0.057,0.259
				c-0.03,0.074-0.08,0.133-0.146,0.176c-0.067,0.044-0.162,0.066-0.281,0.066c-0.053,0-0.104-0.005-0.152-0.016
				c-0.049-0.01-0.092-0.029-0.129-0.057c-0.037-0.029-0.068-0.067-0.092-0.115c-0.022-0.048-0.033-0.106-0.035-0.177h0.285
				C52.729-24.738,52.74-24.696,52.761-24.665 M52.745-25.221c0.023,0.043,0.066,0.065,0.124,0.065c0.06,0,0.103-0.021,0.126-0.065
				c0.025-0.042,0.038-0.114,0.038-0.213c0-0.042-0.003-0.08-0.007-0.117c-0.004-0.035-0.012-0.066-0.023-0.091
				c-0.012-0.026-0.027-0.046-0.05-0.061c-0.022-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.063,0.008-0.086,0.022
				c-0.021,0.015-0.038,0.035-0.049,0.061c-0.011,0.027-0.019,0.058-0.021,0.093c-0.005,0.035-0.006,0.073-0.006,0.115
				C52.708-25.335,52.72-25.263,52.745-25.221"
        />
        <rect
          x="26.183"
          y="-26.43"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#0E4C8C"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M27.097-25.932c0.076,0,0.14,0.012,0.191,0.038
				c0.053,0.026,0.095,0.059,0.126,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.096,0.139c-0.04,0.036-0.09,0.063-0.147,0.081
				c-0.058,0.018-0.123,0.027-0.194,0.027h-0.159v0.59h-0.298v-1.479H27.097z M27.006-25.263c0.059,0,0.107-0.018,0.144-0.054
				c0.036-0.036,0.054-0.092,0.054-0.168c0-0.074-0.015-0.131-0.047-0.17c-0.032-0.038-0.085-0.057-0.16-0.057h-0.124v0.449H27.006z
				"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          points="28.1,-25.932 28.311,-24.886 28.316,-24.886 28.529,-25.932
				28.97,-25.932 28.97,-24.453 28.697,-24.453 28.697,-25.633 28.693,-25.633 28.43,-24.453 28.197,-24.453 27.934,-25.633
				27.93,-25.633 27.93,-24.453 27.657,-24.453 27.657,-25.932 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M29.425-24.871c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.02,0.036,0.035,0.061,0.045c0.025,0.012,0.058,0.017,0.096,0.017
				c0.046,0,0.087-0.015,0.124-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.024-0.024-0.046-0.044-0.065c-0.021-0.019-0.048-0.038-0.082-0.053c-0.033-0.016-0.075-0.032-0.126-0.049
				c-0.065-0.022-0.123-0.046-0.172-0.072c-0.048-0.027-0.088-0.057-0.12-0.093c-0.034-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.193-0.108,0.336-0.108c0.067,0,0.128,0.007,0.183,0.022
				c0.057,0.015,0.104,0.038,0.145,0.07c0.042,0.033,0.073,0.074,0.096,0.124c0.022,0.051,0.034,0.112,0.034,0.182v0.041h-0.286
				c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057c-0.033,0-0.06,0.005-0.083,0.015
				c-0.022,0.009-0.039,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.056c-0.004,0.02-0.007,0.041-0.007,0.064
				c0,0.045,0.011,0.083,0.03,0.115c0.019,0.031,0.06,0.06,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.138,0.078
				c0.037,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.037,0.066,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.107-0.378,0.107c-0.169,0-0.29-0.037-0.362-0.11
				c-0.072-0.073-0.108-0.178-0.108-0.315v-0.061h0.298V-24.871z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M30.691-24.452c0-0.065,0.007-0.125,0.02-0.182
				c0.013-0.057,0.033-0.111,0.06-0.162s0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.063c-0.011,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.109h-0.287v-0.055
				c0-0.137,0.036-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
				c0.071,0.07,0.108,0.168,0.108,0.296c0,0.048-0.004,0.091-0.013,0.132c-0.009,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.039-0.079,0.079-0.104,0.116
				c-0.024,0.039-0.041,0.071-0.048,0.099h0.554v0.232H30.691z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M31.739-25.104c0.042-0.067,0.105-0.108,0.188-0.124v-0.004
				c-0.041-0.011-0.074-0.026-0.102-0.046c-0.026-0.021-0.048-0.044-0.064-0.071c-0.017-0.026-0.029-0.053-0.036-0.083
				c-0.007-0.029-0.01-0.056-0.01-0.082c0-0.144,0.038-0.248,0.115-0.311c0.076-0.062,0.181-0.093,0.316-0.093
				c0.133,0,0.238,0.031,0.315,0.093c0.077,0.063,0.115,0.167,0.115,0.311c0,0.026-0.005,0.053-0.011,0.082
				c-0.008,0.03-0.021,0.057-0.037,0.083c-0.016,0.027-0.039,0.05-0.065,0.071c-0.026,0.02-0.06,0.035-0.099,0.046v0.004
				c0.084,0.016,0.145,0.057,0.187,0.124c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.004,0.089-0.014,0.14
				c-0.011,0.051-0.033,0.099-0.065,0.143c-0.033,0.045-0.081,0.081-0.142,0.11c-0.062,0.029-0.145,0.044-0.246,0.044
				c-0.103,0-0.185-0.015-0.247-0.044c-0.062-0.029-0.108-0.065-0.141-0.11c-0.034-0.044-0.055-0.092-0.066-0.143
				c-0.01-0.051-0.015-0.097-0.015-0.14C31.678-24.958,31.699-25.038,31.739-25.104 M31.984-24.772
				c0.004,0.031,0.014,0.059,0.026,0.082c0.014,0.022,0.031,0.041,0.053,0.054c0.023,0.013,0.051,0.019,0.084,0.019
				c0.032,0,0.061-0.006,0.082-0.019c0.022-0.013,0.039-0.032,0.053-0.054c0.014-0.023,0.022-0.051,0.027-0.082
				c0.004-0.03,0.008-0.063,0.008-0.098c0-0.035-0.004-0.069-0.008-0.099c-0.005-0.031-0.014-0.057-0.027-0.08
				s-0.03-0.041-0.053-0.054c-0.021-0.013-0.05-0.019-0.082-0.019c-0.033,0-0.061,0.006-0.084,0.019
				c-0.021,0.013-0.039,0.031-0.053,0.054c-0.012,0.023-0.022,0.049-0.026,0.08c-0.006,0.03-0.008,0.064-0.008,0.099
				C31.976-24.836,31.978-24.803,31.984-24.772 M32.04-25.368c0.026,0.033,0.062,0.05,0.106,0.05c0.043,0,0.078-0.017,0.104-0.05
				c0.026-0.033,0.04-0.085,0.04-0.156c0-0.134-0.048-0.2-0.145-0.2c-0.098,0-0.146,0.066-0.146,0.2
				C32.001-25.453,32.014-25.401,32.04-25.368"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M33.258-25.679c-0.02-0.031-0.056-0.046-0.104-0.046
				c-0.039,0-0.07,0.01-0.095,0.028c-0.023,0.019-0.043,0.045-0.057,0.078c-0.013,0.034-0.021,0.073-0.025,0.119
				c-0.004,0.045-0.006,0.095-0.006,0.148v0.073h0.004c0.025-0.038,0.062-0.069,0.107-0.092c0.046-0.023,0.102-0.034,0.168-0.034
				c0.064,0,0.12,0.011,0.166,0.034c0.045,0.023,0.083,0.055,0.111,0.095c0.029,0.041,0.049,0.089,0.063,0.145
				c0.012,0.056,0.02,0.117,0.02,0.184c0,0.17-0.038,0.299-0.113,0.386c-0.074,0.088-0.195,0.132-0.361,0.132
				c-0.103,0-0.184-0.018-0.244-0.054c-0.062-0.036-0.107-0.085-0.141-0.148c-0.031-0.063-0.053-0.136-0.064-0.219
				c-0.008-0.082-0.014-0.169-0.014-0.259c0-0.104,0.004-0.204,0.01-0.301c0.006-0.098,0.025-0.185,0.057-0.259
				c0.029-0.076,0.079-0.136,0.146-0.182c0.065-0.046,0.159-0.068,0.28-0.068c0.052,0,0.104,0.006,0.153,0.018
				c0.049,0.013,0.092,0.033,0.129,0.062c0.038,0.029,0.068,0.068,0.091,0.115c0.022,0.049,0.034,0.107,0.036,0.178h-0.287
				C33.287-25.604,33.278-25.648,33.258-25.679 M32.991-24.792c0.005,0.036,0.012,0.067,0.024,0.092
				c0.012,0.026,0.028,0.046,0.049,0.061c0.021,0.014,0.05,0.021,0.084,0.021c0.037,0,0.066-0.007,0.087-0.021
				c0.021-0.015,0.036-0.035,0.048-0.063c0.012-0.026,0.02-0.057,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.115
				c0-0.1-0.012-0.171-0.037-0.214s-0.066-0.064-0.125-0.064s-0.102,0.021-0.125,0.064c-0.025,0.043-0.038,0.114-0.038,0.214
				C32.985-24.867,32.987-24.827,32.991-24.792"
        />
        <rect
          x="55.789"
          y="-26.43"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#5087C7"
          width="8.812"
          height="8.812"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M56.702-25.932c0.076,0,0.14,0.012,0.191,0.038
				c0.053,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.013,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.012,0.143-0.035,0.199c-0.022,0.057-0.053,0.103-0.094,0.139s-0.09,0.063-0.148,0.081s-0.123,0.027-0.193,0.027h-0.16
				v0.59h-0.297v-1.479H56.702z M56.61-25.263c0.06,0,0.107-0.018,0.145-0.054s0.055-0.092,0.055-0.168
				c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.057-0.159-0.057h-0.125v0.449H56.61z"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          points="57.706,-25.932 57.917,-24.886 57.921,-24.886 58.134,-25.932
				58.575,-25.932 58.575,-24.452 58.302,-24.452 58.302,-25.633 58.298,-25.633 58.035,-24.452 57.802,-24.452 57.54,-25.633
				57.535,-25.633 57.535,-24.452 57.261,-24.452 57.261,-25.932 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M59.03-24.871c0,0.035,0.002,0.066,0.008,0.095
				c0.004,0.028,0.016,0.052,0.03,0.071s0.036,0.035,0.062,0.045c0.025,0.012,0.059,0.017,0.097,0.017
				c0.045,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				s-0.023-0.046-0.045-0.065c-0.021-0.019-0.049-0.038-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.049
				c-0.066-0.022-0.123-0.046-0.172-0.072c-0.049-0.027-0.09-0.057-0.121-0.093c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.082-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.184,0.022c0.057,0.015,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.096,0.124
				c0.023,0.051,0.033,0.112,0.033,0.182v0.041h-0.284c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057
				c-0.033,0-0.062,0.005-0.083,0.015c-0.023,0.009-0.04,0.022-0.053,0.037c-0.014,0.016-0.022,0.035-0.027,0.056
				c-0.005,0.02-0.007,0.041-0.007,0.064c0,0.045,0.01,0.083,0.028,0.115c0.02,0.031,0.062,0.06,0.125,0.086l0.229,0.099
				c0.058,0.025,0.103,0.051,0.14,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.035,0.066,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.047,0.27-0.137,0.341c-0.09,0.071-0.215,0.107-0.377,0.107
				c-0.168,0-0.289-0.037-0.363-0.11c-0.071-0.073-0.107-0.178-0.107-0.314v-0.062h0.299V-24.871z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M60.296-24.452c0-0.064,0.008-0.125,0.02-0.182
				c0.014-0.057,0.033-0.111,0.061-0.162s0.061-0.101,0.102-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.032-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.035-0.05,0.047-0.075c0.011-0.025,0.02-0.051,0.021-0.081
				c0.004-0.029,0.006-0.062,0.006-0.099c0-0.122-0.049-0.183-0.146-0.183c-0.037,0-0.067,0.008-0.088,0.022
				c-0.022,0.015-0.038,0.036-0.05,0.063c-0.012,0.027-0.021,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.055
				c0-0.137,0.036-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.261,0.034,0.332,0.103
				c0.071,0.07,0.106,0.168,0.106,0.296c0,0.048-0.004,0.091-0.012,0.132c-0.009,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.07,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.039-0.078,0.079-0.104,0.116
				c-0.025,0.039-0.041,0.071-0.049,0.099H61.2v0.232H60.296z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M62.202-25.662c-0.061,0.086-0.114,0.178-0.162,0.275
				c-0.047,0.099-0.09,0.2-0.125,0.303c-0.036,0.104-0.066,0.21-0.09,0.317s-0.041,0.212-0.054,0.315h-0.313
				c0.012-0.088,0.027-0.181,0.051-0.278c0.021-0.098,0.052-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
				c0.053-0.103,0.115-0.203,0.188-0.3h-0.618v-0.257h0.903V-25.662z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M62.626-25.488h-0.293v-0.195h0.027c0.1,0,0.178-0.018,0.234-0.056
				c0.059-0.038,0.094-0.097,0.109-0.18h0.22v1.467h-0.298V-25.488z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M63.333-25.104c0.043-0.067,0.104-0.108,0.188-0.124v-0.004
				c-0.04-0.011-0.072-0.026-0.102-0.046c-0.025-0.021-0.047-0.044-0.064-0.071c-0.016-0.026-0.027-0.053-0.035-0.083
				c-0.007-0.029-0.01-0.056-0.01-0.082c0-0.144,0.038-0.248,0.115-0.311c0.076-0.062,0.181-0.093,0.316-0.093
				c0.133,0,0.238,0.031,0.315,0.093c0.076,0.063,0.115,0.167,0.115,0.311c0,0.026-0.005,0.053-0.011,0.082
				c-0.008,0.03-0.021,0.057-0.037,0.083c-0.017,0.027-0.039,0.05-0.065,0.071c-0.026,0.02-0.06,0.035-0.099,0.046v0.004
				c0.082,0.016,0.145,0.057,0.187,0.124c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.004,0.089-0.014,0.14
				c-0.011,0.051-0.033,0.099-0.066,0.143c-0.032,0.045-0.08,0.081-0.141,0.11c-0.063,0.029-0.145,0.044-0.246,0.044
				c-0.103,0-0.185-0.015-0.246-0.044c-0.063-0.029-0.108-0.065-0.143-0.11c-0.033-0.044-0.054-0.092-0.064-0.143
				c-0.01-0.051-0.016-0.097-0.016-0.14C63.272-24.958,63.294-25.038,63.333-25.104 M63.579-24.772
				c0.004,0.031,0.013,0.059,0.025,0.082c0.014,0.022,0.031,0.041,0.053,0.054c0.022,0.013,0.051,0.019,0.084,0.019
				c0.032,0,0.06-0.006,0.082-0.019c0.022-0.013,0.041-0.032,0.053-0.054c0.013-0.023,0.022-0.051,0.027-0.082
				c0.004-0.03,0.008-0.063,0.008-0.098s-0.004-0.069-0.008-0.099c-0.005-0.031-0.015-0.057-0.027-0.08
				c-0.012-0.023-0.03-0.041-0.053-0.054c-0.022-0.013-0.05-0.019-0.082-0.019c-0.033,0-0.062,0.006-0.084,0.019
				c-0.021,0.013-0.039,0.031-0.053,0.054c-0.013,0.023-0.021,0.049-0.025,0.08c-0.006,0.03-0.008,0.064-0.008,0.099
				S63.573-24.803,63.579-24.772 M63.635-25.368c0.026,0.033,0.062,0.05,0.106,0.05c0.043,0,0.078-0.017,0.104-0.05
				c0.026-0.033,0.04-0.085,0.04-0.156c0-0.134-0.048-0.2-0.145-0.2c-0.098,0-0.146,0.066-0.146,0.2
				C63.596-25.453,63.608-25.4,63.635-25.368"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M36.978-25.896c0.076,0,0.14,0.013,0.191,0.038
				c0.053,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.056,0.088,0.069,0.142c0.013,0.053,0.021,0.108,0.021,0.163
				c0,0.076-0.012,0.142-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.138c-0.041,0.037-0.09,0.064-0.148,0.081
				c-0.059,0.018-0.123,0.028-0.193,0.028h-0.16v0.59h-0.298v-1.48H36.978z M36.886-25.227c0.06,0,0.107-0.018,0.145-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H36.886z
				"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          points="37.981,-25.896 38.192,-24.852 38.196,-24.852 38.409,-25.896
				38.851,-25.896 38.851,-24.417 38.577,-24.417 38.577,-25.599 38.573,-25.599 38.311,-24.417 38.077,-24.417 37.815,-25.599
				37.811,-25.599 37.811,-24.417 37.536,-24.417 37.536,-25.896 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M39.306-24.835c0,0.034,0.002,0.066,0.008,0.094
				c0.004,0.028,0.016,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.059,0.016,0.097,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.076,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.023-0.047-0.045-0.066s-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.023-0.123-0.047-0.172-0.073s-0.09-0.057-0.121-0.093c-0.033-0.035-0.057-0.075-0.072-0.122
				c-0.015-0.045-0.023-0.099-0.023-0.16c0-0.147,0.041-0.256,0.123-0.327c0.082-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.184,0.021c0.057,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.096,0.124
				c0.023,0.051,0.033,0.111,0.033,0.182v0.041h-0.284c0-0.071-0.013-0.125-0.038-0.162c-0.023-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.062,0.004-0.083,0.014c-0.023,0.009-0.04,0.022-0.053,0.038c-0.014,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.028,0.114c0.02,0.032,0.062,0.06,0.125,0.086l0.229,0.1
				c0.058,0.024,0.103,0.051,0.14,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.035,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.047,0.269-0.137,0.341c-0.09,0.071-0.215,0.106-0.377,0.106
				c-0.168,0-0.289-0.036-0.363-0.109c-0.071-0.074-0.107-0.179-0.107-0.316v-0.06h0.299V-24.835z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M40.911-25.453H40.62v-0.195h0.025c0.1,0,0.178-0.018,0.234-0.055
				c0.059-0.038,0.095-0.098,0.109-0.181h0.221v1.467h-0.299V-25.453z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M42.478-25.626c-0.061,0.085-0.114,0.178-0.162,0.276
				c-0.047,0.098-0.09,0.199-0.125,0.302c-0.036,0.103-0.066,0.21-0.09,0.316c-0.023,0.108-0.041,0.213-0.054,0.316h-0.313
				c0.012-0.088,0.027-0.181,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.298c0.036-0.101,0.081-0.202,0.133-0.304
				c0.053-0.103,0.115-0.203,0.188-0.301h-0.618v-0.257h0.903V-25.626z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M42.904-24.629c0.019,0.031,0.054,0.047,0.104,0.047
				c0.041,0,0.072-0.009,0.096-0.028s0.042-0.045,0.055-0.078c0.014-0.033,0.021-0.073,0.025-0.118
				c0.005-0.045,0.007-0.096,0.007-0.149v-0.07h-0.005c-0.023,0.036-0.061,0.066-0.105,0.089c-0.047,0.023-0.104,0.034-0.17,0.034
				c-0.064,0-0.119-0.011-0.164-0.034c-0.047-0.023-0.084-0.054-0.113-0.095c-0.027-0.041-0.049-0.089-0.062-0.145
				c-0.013-0.056-0.019-0.117-0.019-0.183c0-0.17,0.037-0.3,0.111-0.39c0.076-0.09,0.196-0.135,0.362-0.135
				c0.103,0,0.185,0.019,0.244,0.058c0.062,0.037,0.108,0.088,0.14,0.15c0.033,0.064,0.055,0.136,0.064,0.219
				c0.009,0.083,0.014,0.17,0.014,0.26c0,0.104-0.003,0.205-0.009,0.303s-0.024,0.184-0.056,0.258
				c-0.031,0.074-0.08,0.133-0.147,0.177c-0.067,0.043-0.161,0.065-0.28,0.065c-0.054,0-0.104-0.004-0.154-0.015
				c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.029-0.066-0.066-0.09-0.114c-0.022-0.048-0.035-0.107-0.037-0.178h0.287
				C42.874-24.703,42.884-24.66,42.904-24.629 M42.889-25.185c0.024,0.042,0.065,0.064,0.124,0.064s0.102-0.022,0.127-0.064
				c0.025-0.043,0.037-0.115,0.037-0.213c0-0.042-0.002-0.081-0.006-0.117c-0.004-0.036-0.012-0.067-0.024-0.092
				c-0.011-0.025-0.028-0.045-0.05-0.06s-0.049-0.022-0.084-0.022s-0.064,0.007-0.086,0.022s-0.037,0.035-0.049,0.061
				c-0.01,0.026-0.019,0.057-0.021,0.092c-0.003,0.036-0.005,0.074-0.005,0.116C42.852-25.3,42.863-25.228,42.889-25.185"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#FFFFFF"
          d="M43.858-25.623l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.085
				c0.044-0.017,0.099-0.025,0.161-0.025c0.07,0,0.129,0.013,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.106
				c0.027,0.045,0.045,0.094,0.057,0.151c0.011,0.055,0.018,0.112,0.018,0.17c0,0.082-0.008,0.156-0.021,0.223
				c-0.014,0.065-0.038,0.122-0.073,0.171c-0.036,0.047-0.084,0.082-0.144,0.107c-0.062,0.024-0.139,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.059-0.025-0.102-0.058-0.133-0.099c-0.029-0.04-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.285c0,0.024,0.002,0.05,0.006,0.079c0.002,0.027,0.009,0.053,0.019,0.077
				c0.011,0.024,0.025,0.044,0.045,0.06c0.019,0.016,0.046,0.023,0.076,0.023c0.063,0,0.106-0.023,0.134-0.07
				c0.027-0.048,0.041-0.12,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
				c-0.013-0.023-0.029-0.04-0.053-0.049c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.024-0.037,0.058-0.042,0.099h-0.268l0.053-0.798h0.766v0.232H43.858z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M12.71,75.938c0.592,0,0.973,0.391,0.973,0.991
				c0,0.594-0.381,0.985-0.973,0.985c-0.588,0-0.969-0.392-0.969-0.985C11.741,76.328,12.122,75.938,12.71,75.938 M12.71,77.526
				c0.352,0,0.457-0.301,0.457-0.598c0-0.302-0.105-0.604-0.457-0.604c-0.349,0-0.454,0.302-0.454,0.604
				C12.256,77.226,12.361,77.526,12.71,77.526"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M14.668,75.988h0.378v0.345h-0.378v0.929c0,0.174,0.044,0.218,0.218,0.218
				c0.054,0,0.105-0.004,0.16-0.015v0.403c-0.087,0.014-0.2,0.018-0.302,0.018c-0.315,0-0.591-0.073-0.591-0.446v-1.106h-0.312
				v-0.345h0.312v-0.563h0.515V75.988z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M15.716,77.052c0.014,0.326,0.174,0.475,0.46,0.475
				c0.208,0,0.374-0.127,0.407-0.243h0.454c-0.146,0.443-0.454,0.632-0.879,0.632c-0.591,0-0.957-0.406-0.957-0.986
				c0-0.563,0.388-0.992,0.957-0.992c0.639,0,0.947,0.538,0.911,1.115H15.716z M16.555,76.725c-0.047-0.261-0.16-0.399-0.41-0.399
				c-0.327,0-0.421,0.255-0.428,0.399H16.555z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M17.772,77.254c0.004,0.226,0.193,0.316,0.396,0.316
				c0.149,0,0.337-0.058,0.337-0.24c0-0.156-0.214-0.21-0.584-0.29c-0.298-0.064-0.595-0.17-0.595-0.501
				c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.138,0.828,0.598h-0.49c-0.015-0.199-0.167-0.254-0.352-0.254
				c-0.116,0-0.287,0.022-0.287,0.175c0,0.186,0.29,0.21,0.584,0.279c0.302,0.069,0.596,0.178,0.596,0.526
				c0,0.493-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.163-0.882-0.66H17.772z"
        />
        <rect
          x="8.916"
          y="80.467"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M12.02,79.029h0.89c0.798,0,1.244,0.398,1.244,1.233
				c0,0.868-0.381,1.358-1.244,1.358h-0.89V79.029z M12.365,81.33h0.573c0.236,0,0.871-0.064,0.871-1.02
				c0-0.616-0.228-0.99-0.864-0.99h-0.58V81.33z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M15.332,79.701c0.595,0,0.911,0.432,0.911,0.983
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.433-0.911-0.979C14.421,80.133,14.737,79.701,15.332,79.701 M15.332,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C14.748,81.138,15.009,81.392,15.332,81.392"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M17.612,79.744h0.291v0.297h0.006c0.132-0.231,0.341-0.341,0.61-0.341
				c0.494,0,0.646,0.283,0.646,0.687v1.233h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
				h-0.309V79.744z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M20.438,79.701c0.595,0,0.911,0.432,0.911,0.983
				c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.433-0.911-0.979C19.527,80.133,19.843,79.701,20.438,79.701 M20.438,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712s-0.584,0.254-0.584,0.712
				C19.854,81.138,20.115,81.392,20.438,81.392"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M22.14,79.744h0.374v0.272H22.14v1.165c0,0.142,0.039,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M25.524,81.613c-0.055,0.032-0.123,0.051-0.222,0.051
				c-0.16,0-0.261-0.088-0.261-0.29c-0.17,0.199-0.399,0.29-0.66,0.29c-0.341,0-0.621-0.152-0.621-0.526
				c0-0.424,0.316-0.515,0.635-0.577c0.341-0.064,0.632-0.043,0.632-0.275c0-0.269-0.222-0.313-0.418-0.313
				c-0.261,0-0.453,0.081-0.468,0.355h-0.309c0.018-0.465,0.378-0.628,0.795-0.628c0.338,0,0.704,0.077,0.704,0.516v0.966
				c0,0.145,0,0.21,0.098,0.21c0.026,0,0.055-0.003,0.095-0.018V81.613z M25.023,80.651c-0.12,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.036-0.378,0.109-0.378,0.337c0,0.204,0.175,0.276,0.364,0.276c0.406,0,0.573-0.254,0.573-0.424V80.651z"
        />
        <rect
          x="25.828"
          y="79.029"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          width="0.308"
          height="2.591"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M27.042,79.744h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.167,0.232,0.167
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M29.358,81.025c-0.083,0.413-0.381,0.639-0.802,0.639
				c-0.599,0-0.882-0.414-0.9-0.987c0-0.562,0.37-0.976,0.882-0.976c0.663,0,0.867,0.62,0.849,1.073h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.366H29.358z M29.06,80.503
				c-0.014-0.294-0.236-0.53-0.54-0.53c-0.323,0-0.516,0.243-0.538,0.53H29.06z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M29.693,79.744h0.291v0.396h0.006c0.149-0.301,0.356-0.45,0.69-0.44v0.327
				c-0.497,0-0.678,0.283-0.678,0.758v0.835h-0.309V79.744z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M33.583,81.613c-0.056,0.032-0.123,0.051-0.222,0.051
				c-0.16,0-0.262-0.088-0.262-0.29c-0.17,0.199-0.398,0.29-0.66,0.29c-0.341,0-0.621-0.152-0.621-0.526
				c0-0.424,0.316-0.515,0.636-0.577c0.34-0.064,0.631-0.043,0.631-0.275c0-0.269-0.223-0.313-0.417-0.313
				c-0.263,0-0.454,0.081-0.47,0.355h-0.308c0.018-0.465,0.378-0.628,0.796-0.628c0.336,0,0.703,0.077,0.703,0.516v0.966
				c0,0.145,0,0.21,0.098,0.21c0.025,0,0.055-0.003,0.095-0.018V81.613z M33.082,80.651c-0.119,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.036-0.377,0.109-0.377,0.337c0,0.204,0.174,0.276,0.362,0.276c0.405,0,0.573-0.254,0.573-0.424V80.651z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M33.857,79.744h0.29v0.396h0.007c0.149-0.301,0.355-0.45,0.69-0.44v0.327
				c-0.497,0-0.679,0.283-0.679,0.758v0.835h-0.309V79.744z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M35.504,79.744h0.375v0.272h-0.375v1.165c0,0.142,0.039,0.167,0.232,0.167
				h0.143v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.318v-0.272h0.318v-0.563h0.309V79.744z"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          points="38.076,81.62 37.751,81.62 37.369,80.129 37.361,80.129
				36.984,81.62 36.65,81.62 36.047,79.744 36.389,79.744 36.813,81.279 36.821,81.279 37.198,79.744 37.536,79.744 37.927,81.279
				37.936,81.279 38.355,79.744 38.675,79.744 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M39.776,79.701c0.596,0,0.912,0.432,0.912,0.983
				c0,0.547-0.316,0.979-0.912,0.979c-0.594,0-0.91-0.433-0.91-0.979C38.866,80.133,39.183,79.701,39.776,79.701 M39.776,81.392
				c0.324,0,0.585-0.254,0.585-0.707c0-0.458-0.261-0.712-0.585-0.712c-0.322,0-0.584,0.254-0.584,0.712
				C39.192,81.138,39.454,81.392,39.776,81.392"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M41.039,79.744h0.29v0.396h0.007c0.149-0.301,0.356-0.45,0.69-0.44v0.327
				c-0.498,0-0.68,0.283-0.68,0.758v0.835h-0.308V79.744z"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          points="42.276,79.029 42.585,79.029 42.585,80.564 43.44,79.744
				43.854,79.744 43.11,80.427 43.909,81.62 43.517,81.62 42.874,80.641 42.585,80.909 42.585,81.62 42.276,81.62 			"
        />
        <rect
          x="8.916"
          y="84.223"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M14.089,84.441c0,0.652-0.374,0.998-1.02,0.998
				c-0.668,0-1.063-0.31-1.063-0.998v-1.655h0.345v1.655c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.655
				h0.345V84.441z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M14.776,84.785c0.011,0.276,0.25,0.363,0.505,0.363
				c0.192,0,0.454-0.044,0.454-0.28c0-0.238-0.306-0.278-0.614-0.348c-0.305-0.069-0.614-0.171-0.614-0.53
				c0-0.378,0.374-0.532,0.701-0.532c0.414,0,0.744,0.13,0.769,0.588h-0.308c-0.022-0.24-0.233-0.316-0.435-0.316
				c-0.186,0-0.4,0.051-0.4,0.239c0,0.222,0.326,0.258,0.614,0.327c0.308,0.068,0.612,0.171,0.612,0.534
				c0,0.445-0.417,0.591-0.798,0.591c-0.421,0-0.777-0.171-0.794-0.636H14.776z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M18.001,84.782c-0.083,0.413-0.38,0.638-0.802,0.638
				c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.882-0.977c0.664,0,0.868,0.62,0.849,1.074h-1.404
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.366H18.001z M17.704,84.26
				c-0.015-0.294-0.236-0.53-0.541-0.53c-0.323,0-0.515,0.243-0.537,0.53H17.704z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M19.785,83.501h0.374v0.271h-0.374v1.165c0,0.143,0.04,0.167,0.232,0.167
				h0.142v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319v-0.271h0.319v-0.563h0.309V83.501z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M20.826,83.164h-0.308v-0.378h0.308V83.164z M20.518,83.501h0.308v1.876
				h-0.308V83.501z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M22.923,85.217c0,0.61-0.28,0.922-0.871,0.922
				c-0.352,0-0.759-0.142-0.777-0.551h0.309c0.014,0.226,0.283,0.304,0.486,0.304c0.403,0,0.563-0.29,0.563-0.711v-0.123h-0.008
				c-0.102,0.229-0.348,0.342-0.584,0.342c-0.559,0-0.838-0.439-0.838-0.951c0-0.443,0.217-0.991,0.867-0.991
				c0.236,0,0.446,0.106,0.559,0.313h0.004v-0.269h0.29V85.217z M22.618,84.401c0-0.331-0.145-0.671-0.533-0.671
				c-0.396,0-0.556,0.321-0.556,0.682c0,0.337,0.124,0.714,0.53,0.714C22.466,85.126,22.618,84.753,22.618,84.401"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M23.387,82.786h0.309v0.991h0.007c0.102-0.226,0.366-0.319,0.591-0.319
				c0.494,0,0.646,0.282,0.646,0.686v1.233h-0.308v-1.271c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.06
				h-0.309V82.786z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M25.832,83.501h0.374v0.271h-0.374v1.165c0,0.143,0.04,0.167,0.232,0.167
				h0.142v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319v-0.271h0.319v-0.563h0.309V83.501z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M28.363,83.457c0.595,0,0.911,0.433,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.452,83.89,27.769,83.457,28.363,83.457 M28.363,85.148
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C27.779,84.895,28.041,85.148,28.363,85.148"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          points="30.481,85.377 30.15,85.377 29.455,83.501 29.799,83.501
				30.326,85.065 30.333,85.065 30.844,83.501 31.167,83.501 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M33.05,84.782c-0.083,0.413-0.381,0.638-0.802,0.638
				c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.883-0.977c0.662,0,0.866,0.62,0.849,1.074h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.433-0.123,0.486-0.366H33.05z M32.752,84.26c-0.014-0.295-0.236-0.53-0.54-0.53
				c-0.323,0-0.516,0.243-0.538,0.53H32.752z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M33.386,83.501h0.291v0.396h0.007c0.148-0.301,0.355-0.449,0.689-0.439
				v0.327c-0.497,0-0.678,0.282-0.678,0.758v0.835h-0.31V83.501z"
        />
        <rect
          x="34.623"
          y="82.786"
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          width="0.309"
          height="2.591"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M36.219,83.457c0.595,0,0.911,0.433,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979s-0.91-0.432-0.91-0.979C35.309,83.89,35.624,83.457,36.219,83.457 M36.219,85.148
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.322,0-0.584,0.254-0.584,0.712
				C35.635,84.895,35.896,85.148,36.219,85.148"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M38.766,84.104c-0.048-0.236-0.21-0.374-0.464-0.374
				c-0.447,0-0.585,0.352-0.585,0.733c0,0.345,0.155,0.686,0.548,0.686c0.297,0,0.475-0.174,0.516-0.457h0.315
				c-0.069,0.457-0.356,0.729-0.827,0.729c-0.572,0-0.879-0.399-0.879-0.958c0-0.563,0.291-1.006,0.886-1.006
				c0.425,0,0.767,0.2,0.81,0.646H38.766z"
        />
        <polygon
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          points="39.457,82.786 39.767,82.786 39.767,84.32 40.622,83.501
				41.036,83.501 40.292,84.183 41.091,85.377 40.698,85.377 40.056,84.396 39.767,84.666 39.767,85.377 39.457,85.377 			"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M41.647,83.164h-0.309v-0.378h0.309V83.164z M41.34,83.501h0.31v1.876h-0.31
				V83.501z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M42.126,83.501h0.291v0.297h0.006c0.131-0.232,0.342-0.341,0.61-0.341
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.307v-1.271c0-0.228-0.146-0.377-0.382-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.06
				h-0.31V83.501z"
        />
        <path
          clipPath="url(#FITNESS_SVGID_88_)"
          fill="#EC008C"
          d="M45.762,85.217c0,0.61-0.28,0.922-0.871,0.922
				c-0.352,0-0.759-0.142-0.776-0.551h0.309c0.014,0.226,0.283,0.304,0.486,0.304c0.402,0,0.562-0.29,0.562-0.711v-0.123h-0.007
				c-0.102,0.229-0.348,0.342-0.584,0.342c-0.559,0-0.838-0.439-0.838-0.951c0-0.443,0.217-0.991,0.867-0.991
				c0.236,0,0.445,0.106,0.559,0.313h0.003v-0.269h0.291V85.217z M45.457,84.401c0-0.331-0.146-0.671-0.533-0.671
				c-0.396,0-0.556,0.321-0.556,0.682c0,0.337,0.123,0.714,0.529,0.714C45.305,85.126,45.457,84.753,45.457,84.401"
        />
      </g>
    </g>
  );
};

function BeWell(props: any) {
  const { badge, fallback = null, ...otherProps } = props;

  switch (badge) {
    case 'Natural remedies':
      return <NaturalRemedies {...otherProps} />;
    case 'Mixology':
      return <Mixology {...otherProps} />;
    case 'Meditation':
      return <Meditation {...otherProps} />;
    case 'Fitness':
      return <Fitness {...otherProps} />;
    default:
      return fallback;
  }
}

export default BeWell;
