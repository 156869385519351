import { PropsWithChildren } from 'react';

import { Provider as AuthProvider } from 'context/Auth';
import { Provider as DatabaseProvider } from 'context/Database';

function RootProvider(props: PropsWithChildren<unknown>) {
  const { children } = props;
  return (
    <AuthProvider>
      <DatabaseProvider>{children}</DatabaseProvider>
    </AuthProvider>
  );
}

export default RootProvider;
