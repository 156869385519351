import React from 'react';

const Vlogging = () => {
  return (
    <g id="Vlogging">
      <g>
        <defs>
          <rect
            id="VLOGGING_SVGID_89_"
            x="0.029"
            y="0.001"
            width="61.688"
            height="61.688"
          />
        </defs>
        <clipPath id="VLOGGING_SVGID_90_">
          <use xlinkHref="#VLOGGING_SVGID_89_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M6.209,60.812c-2.925,0-5.304-2.379-5.304-5.305V6.181
				c0-2.924,2.379-5.304,5.304-5.304h49.326c2.925,0,5.305,2.38,5.305,5.304v49.326c0,2.926-2.38,5.305-5.305,5.305H6.209z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M55.535,1.136c2.782,0,5.045,2.263,5.045,5.045v49.326
				c0,2.783-2.263,5.045-5.045,5.045H6.209c-2.782,0-5.046-2.262-5.046-5.045V6.181c0-2.782,2.264-5.045,5.046-5.045H55.535
				 M55.535,0.618H6.209c-3.06,0-5.564,2.504-5.564,5.563v49.326c0,3.061,2.504,5.564,5.564,5.564h49.326
				c3.061,0,5.563-2.504,5.563-5.564V6.181C61.098,3.122,58.596,0.618,55.535,0.618"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#88155D"
          points="48.436,15.944 59.395,24.479 59.395,59.499 35.156,60.081
				13.235,43.009 			"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="20.592,11.14 19.646,11.14 17.953,7.156 19.034,7.156 20.116,9.796
				21.205,7.156 22.288,7.156 			"
        />
        <rect
          x="22.633"
          y="6.859"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          width="0.952"
          height="4.281"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M27.327,9.647c0,0.946-0.75,1.576-1.635,1.576
				c-0.904,0-1.654-0.63-1.654-1.576c0-0.939,0.75-1.57,1.654-1.57C26.577,8.077,27.327,8.708,27.327,9.647 M26.411,9.647
				c0-0.464-0.351-0.773-0.719-0.773c-0.387,0-0.738,0.309-0.738,0.773c0,0.47,0.351,0.773,0.738,0.773
				C26.06,10.42,26.411,10.117,26.411,9.647"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M30.867,10.842c0,0.921-0.702,1.427-1.671,1.427
				c-0.684,0-1.208-0.237-1.499-0.72l0.791-0.428c0.226,0.268,0.393,0.369,0.702,0.369c0.434,0,0.743-0.255,0.743-0.725v-0.084
				c-0.214,0.185-0.511,0.286-0.897,0.286c-0.78,0-1.44-0.618-1.44-1.463c0-0.826,0.66-1.439,1.44-1.439
				c0.386,0,0.683,0.101,0.897,0.285V8.154h0.934V10.842z M29.933,9.504c0-0.398-0.315-0.678-0.695-0.678
				c-0.417,0-0.714,0.28-0.714,0.678c0,0.399,0.297,0.684,0.714,0.684C29.618,10.188,29.933,9.903,29.933,9.504"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M34.584,10.842c0,0.921-0.703,1.427-1.672,1.427
				c-0.684,0-1.207-0.237-1.498-0.72l0.791-0.428c0.226,0.268,0.393,0.369,0.701,0.369c0.434,0,0.743-0.255,0.743-0.725v-0.084
				c-0.214,0.185-0.511,0.286-0.897,0.286c-0.778,0-1.438-0.618-1.438-1.463c0-0.826,0.66-1.439,1.438-1.439
				c0.387,0,0.684,0.101,0.897,0.285V8.154h0.935V10.842z M33.649,9.504c0-0.398-0.315-0.678-0.694-0.678
				c-0.418,0-0.715,0.28-0.715,0.678c0,0.399,0.297,0.684,0.715,0.684C33.334,10.188,33.649,9.903,33.649,9.504"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M36.277,7.274c0,0.303-0.232,0.53-0.554,0.53s-0.546-0.227-0.546-0.53
				c0-0.291,0.225-0.541,0.546-0.541S36.277,6.983,36.277,7.274 M35.248,8.154h0.951v2.986h-0.951V8.154z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M39.882,9.309v1.831h-0.946V9.511c0-0.351-0.207-0.589-0.511-0.589
				c-0.374,0-0.618,0.256-0.618,0.833v1.385h-0.951V8.155h0.951V8.44c0.227-0.238,0.541-0.374,0.915-0.374
				C39.418,8.066,39.882,8.56,39.882,9.309"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M43.563,10.842c0,0.921-0.702,1.427-1.671,1.427
				c-0.684,0-1.207-0.237-1.5-0.72l0.791-0.428c0.227,0.268,0.394,0.369,0.701,0.369c0.436,0,0.744-0.255,0.744-0.725v-0.084
				c-0.213,0.185-0.511,0.286-0.896,0.286c-0.78,0-1.439-0.618-1.439-1.463c0-0.826,0.659-1.439,1.439-1.439
				c0.386,0,0.684,0.101,0.896,0.285V8.154h0.935V10.842z M42.629,9.504c0-0.398-0.314-0.678-0.695-0.678
				c-0.416,0-0.713,0.28-0.713,0.678c0,0.399,0.297,0.684,0.713,0.684C42.314,10.188,42.629,9.903,42.629,9.504"
        />
        <rect
          x="13.235"
          y="15.944"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          width="35.2"
          height="22.66"
        />
        <polyline
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#60C2E6"
          points="34.597,38.604 13.235,38.604 13.235,15.944 41.074,15.944 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M45.586,27.274c0,3.35-2.717,6.066-6.066,6.066
				c-3.352,0-6.067-2.716-6.067-6.066c0-3.351,2.716-6.066,6.067-6.066C42.869,21.208,45.586,23.923,45.586,27.274"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#EC008C"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="39.519"
          cy="27.274"
          r="6.067"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="37.658,31.041 37.658,23.508 42.939,27.274 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EF97A8"
          d="M56.039,4.407c0.688,0,1.27,0.582,1.27,1.271v50.333
				c0,0.689-0.581,1.271-1.27,1.271H5.706c-0.689,0-1.271-0.582-1.271-1.271V5.678c0-0.689,0.582-1.271,1.271-1.271H56.039
				 M56.039,0.001H5.706c-3.122,0-5.677,2.555-5.677,5.677v50.333c0,3.121,2.555,5.678,5.677,5.678h50.333
				c3.121,0,5.677-2.557,5.677-5.678V5.678C61.716,2.556,59.16,0.001,56.039,0.001"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M29.109,30.023c0,0.536-0.434,0.97-0.969,0.97
				c-0.536,0-0.97-0.434-0.97-0.97c0-0.535,0.434-0.969,0.97-0.969C28.675,29.054,29.109,29.488,29.109,30.023"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="28.139"
          cy="30.023"
          r="0.97"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M29.434,24.555c-0.194,3.144-0.434,4.246-0.55,6.626l-1.555-0.245
				c0,0-0.388-0.777,0.162-1.586c0,0-0.324-1.101,0.291-1.878l0.357-3.012L29.434,24.555z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M29.434,24.555
				c-0.194,3.144-0.434,4.246-0.55,6.626l-1.555-0.245c0,0-0.388-0.777,0.162-1.586c0,0-0.324-1.101,0.291-1.878l0.357-3.012
				L29.434,24.555z"
        />
        <rect
          x="17.645"
          y="37.136"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F0CB99"
          width="2.178"
          height="1.48"
        />
        <rect
          x="28.767"
          y="37.136"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F0CB99"
          width="2.178"
          height="1.48"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          d="M28.766,30.595L28.766,30.595c-0.298-0.077-0.61-0.123-0.933-0.123h-1.248
				c0,1.266-1.025,2.291-2.29,2.291c-1.265,0-2.291-1.025-2.291-2.291h-1.248c-0.323,0-0.635,0.046-0.933,0.123
				c-1.594,0.414-2.773,1.859-2.773,3.583v2.957h2.773v1.48h8.943v-1.48h2.774v-2.957C31.54,32.454,30.359,31.009,28.766,30.595"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M29.268,28.832c0,0.541-0.439,0.979-0.979,0.979
				c-0.541,0-0.979-0.438-0.979-0.979c0-0.54,0.438-0.979,0.979-0.979C28.829,27.853,29.268,28.292,29.268,28.832"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="28.289"
          cy="28.832"
          r="0.979"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M21.394,28.954c0,0.541-0.439,0.979-0.98,0.979
				c-0.54,0-0.979-0.438-0.979-0.979c0-0.54,0.439-0.979,0.979-0.979C20.955,27.975,21.394,28.414,21.394,28.954"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="20.414"
          cy="28.954"
          r="0.98"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M19.823,29.674c-1.061-3.303-1.724-6.995,0.18-9.532h2.209l-1.069,7.104
				c0,0,0.194,1.219,0.065,2.294L19.823,29.674z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M19.823,29.674
				c-1.061-3.303-1.724-6.995,0.18-9.532h2.209l-1.069,7.104c0,0,0.194,1.219,0.065,2.294L19.823,29.674z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F0CB99"
          d="M28.766,24.363c0,3.038-2.002,5.502-4.471,5.502
				c-2.47,0-4.472-2.464-4.472-5.502c0-3.038,2.002-5.502,4.472-5.502C26.764,18.861,28.766,21.326,28.766,24.363"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M21.418,30.047c0,0.474-0.384,0.858-0.858,0.858s-0.859-0.384-0.859-0.858
				s0.385-0.858,0.859-0.858S21.418,29.573,21.418,30.047"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="20.56"
          cy="30.047"
          r="0.858"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M21.474,31.092c0,0.405-0.33,0.735-0.736,0.735s-0.736-0.33-0.736-0.735
				c0-0.407,0.33-0.736,0.736-0.736S21.474,30.685,21.474,31.092"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="20.738"
          cy="31.092"
          r="0.736"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M21.698,32.065c0,0.389-0.313,0.701-0.701,0.701
				c-0.387,0-0.7-0.313-0.7-0.701c0-0.386,0.313-0.7,0.7-0.7C21.385,31.366,21.698,31.679,21.698,32.065"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="20.997"
          cy="32.065"
          r="0.7"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M21.721,33.013c0,0.291-0.237,0.529-0.529,0.529s-0.529-0.238-0.529-0.529
				c0-0.293,0.237-0.529,0.529-0.529S21.721,32.72,21.721,33.013"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="21.192"
          cy="33.013"
          r="0.529"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M21.749,33.769c0,0.227-0.184,0.41-0.411,0.41s-0.411-0.184-0.411-0.41
				s0.184-0.41,0.411-0.41S21.749,33.542,21.749,33.769"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="21.337"
          cy="33.769"
          r="0.411"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M21.715,34.415c0,0.219-0.177,0.395-0.394,0.395
				c-0.217,0-0.394-0.176-0.394-0.395s0.177-0.395,0.394-0.395C21.538,34.021,21.715,34.196,21.715,34.415"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="21.321"
          cy="34.415"
          r="0.394"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M21.669,35.032c0,0.219-0.177,0.395-0.394,0.395
				c-0.218,0-0.394-0.176-0.394-0.395c0-0.217,0.176-0.393,0.394-0.393C21.493,34.64,21.669,34.815,21.669,35.032"
        />

        <circle
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          cx="21.275"
          cy="35.032"
          r="0.394"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M20.789,37.03c0.522-0.258,0.816-0.688,0.925-1.09
				c0.078-0.283-0.114-0.582-0.397-0.658c-0.282-0.076-0.574,0.09-0.651,0.373C20.525,36.173,21.183,36.308,20.789,37.03"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M20.789,37.03
				c0.522-0.258,0.816-0.688,0.925-1.09c0.078-0.283-0.114-0.582-0.397-0.658c-0.282-0.076-0.574,0.09-0.651,0.373
				C20.525,36.173,21.183,36.308,20.789,37.03z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M19.719,23.651c0,0,0.389-1.198,1.684-2.073l-0.745-1.068l-0.939,1.36
				V23.651z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M19.719,23.651
				c0,0,0.389-1.198,1.684-2.073l-0.745-1.068l-0.939,1.36V23.651z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M22.211,18.387c0.619-0.253,1.272-0.421,1.962-0.421
				c3.084,0,5.607,1.847,5.26,6.588c-0.574,0.112-0.722,0.174-1.327,0.174c-3.366,0-6.336-1.818-8.103-4.586
				c0.352-0.485,0.859-0.491,1.075-0.477C21.197,18.977,21.908,18.511,22.211,18.387"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M22.211,18.387
				c0.619-0.253,1.272-0.421,1.962-0.421c3.084,0,5.607,1.847,5.26,6.588c-0.574,0.112-0.722,0.174-1.327,0.174
				c-3.366,0-6.336-1.818-8.103-4.586c0.352-0.485,0.859-0.491,1.075-0.477C21.197,18.977,21.908,18.511,22.211,18.387z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M19.5,28.608c-0.317-0.488-0.757-2.707-0.706-4.309
				C18.844,22.696,19.5,28.608,19.5,28.608"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M19.5,28.608
				c-0.317-0.488-0.757-2.707-0.706-4.309C18.844,22.696,19.5,28.608,19.5,28.608z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          d="M29.109,28.296c0,0,0.485-1.682,0.356-4.646l-0.549,3.773L29.109,28.296z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M29.109,28.296
				c0,0,0.485-1.682,0.356-4.646l-0.549,3.773L29.109,28.296z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F0CB99"
          d="M26.584,30.472c0,1.266-1.025,2.291-2.29,2.291
				c-1.264,0-2.29-1.025-2.29-2.291"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.389"
          strokeMiterlimit="10"
          d="M25.86,27.289
				c-1.391,1.24-2.655,0.423-2.884,0.115"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          d="M22.75,25.343c0,0.241-0.195,0.436-0.435,0.436c-0.241,0-0.436-0.195-0.436-0.436
				c0-0.24,0.195-0.435,0.436-0.435C22.554,24.908,22.75,25.104,22.75,25.343"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          d="M26.71,25.343c0,0.241-0.195,0.436-0.435,0.436c-0.241,0-0.436-0.195-0.436-0.436
				c0-0.24,0.195-0.435,0.436-0.435C26.515,24.908,26.71,25.104,26.71,25.343"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.389"
          strokeMiterlimit="10"
          d="M24.93,26.387
				c-0.597,0.723-1.14,0.18-1.238,0"
        />
        <rect
          x="22.714"
          y="28.751"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F0CB99"
          width="3.238"
          height="2.526"
        />
        <rect
          x="13.235"
          y="38.604"
          clipPath="url(#VLOGGING_SVGID_90_)"
          width="35.2"
          height="4.404"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M46.654,41.288H30.401v-0.961h16.253V41.288z M47.184,39.798h-17.31v2.02
				h17.31V39.798z"
        />
        <rect
          x="14.411"
          y="39.796"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          width="16.135"
          height="2.02"
        />

        <rect
          x="14.411"
          y="39.798"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#EC008C"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          width="16.135"
          height="2.018"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#5087C7"
          d="M30.883,55.362c-2.638,0-4.773-2.137-4.773-4.771
				c0-2.637,2.135-4.773,4.773-4.773s4.774,2.137,4.774,4.773C35.657,53.226,33.521,55.362,30.883,55.362"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M30.883,45.817c2.638,0,4.774,2.137,4.774,4.773
				c0,2.635-2.136,4.771-4.774,4.771s-4.773-2.137-4.773-4.771C26.11,47.954,28.245,45.817,30.883,45.817 M30.883,45.405
				c-2.859,0-5.185,2.324-5.185,5.186c0,2.857,2.326,5.182,5.185,5.182c2.86,0,5.186-2.324,5.186-5.182
				C36.069,47.729,33.743,45.405,30.883,45.405"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M33.377,48.646c-0.239,0-0.465,0.107-0.62,0.25l-0.013-0.012
				c0.072-0.119,0.168-0.346,0.168-0.717c0-0.883-0.883-1.551-2.028-1.551c-1.145,0-2.041,0.668-2.041,1.551
				c0,0.371,0.119,0.598,0.179,0.717l-0.013,0.012c-0.154-0.143-0.381-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.016
				c0,1.289,0.717,2.016,1.493,2.016c0.715,0,1.217-0.395,1.67-0.846c-0.072-0.109-0.18-0.217-0.298-0.217
				c-0.119,0-0.252,0.086-0.477,0.264c-0.299,0.229-0.562,0.359-0.908,0.359c-0.609,0-0.87-0.684-0.87-1.576
				c0-0.955,0.251-1.586,0.895-1.586c0.286,0,0.476,0.082,0.668,0.262c0.286,0.264,0.381,0.334,0.512,0.334
				c0.096,0,0.156-0.061,0.18-0.096c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.668,0.56-1.135,1.611-1.135
				c1.049,0,1.61,0.467,1.61,1.135c0,0.584-0.311,1.037-0.524,1.252c0.023,0.035,0.083,0.096,0.179,0.096
				c0.131,0,0.227-0.07,0.512-0.334c0.18-0.18,0.383-0.262,0.668-0.262c0.646,0,0.896,0.631,0.896,1.586
				c0,0.893-0.263,1.576-0.871,1.576c-0.346,0-0.621-0.131-0.906-0.359c-0.228-0.178-0.358-0.264-0.478-0.264
				c-0.12,0-0.227,0.107-0.299,0.217c0.44,0.451,0.954,0.846,1.671,0.846c0.775,0,1.49-0.727,1.49-2.016
				C34.832,49.349,34.105,48.646,33.377,48.646"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M31.683,48.62h-0.537c-0.035,0-0.059-0.023-0.071-0.047l-0.168-0.512
				c-0.012-0.037-0.023-0.037-0.035,0l-0.168,0.512c-0.012,0.035-0.035,0.047-0.059,0.047h-0.537c-0.025,0-0.036,0.012-0.012,0.025
				l0.442,0.322c0.023,0.021,0.023,0.059,0.011,0.084l-0.167,0.5c-0.011,0.023-0.011,0.049,0.024,0.023l0.442-0.309
				c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.036,0.012,0.023-0.012l-0.166-0.512
				c-0.012-0.037,0-0.072,0.024-0.084l0.44-0.322C31.719,48.632,31.707,48.62,31.683,48.62"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M31.277,52.151c-0.226-0.572-0.406-0.965-0.406-1.441
				c0-0.205,0.06-0.383,0.096-0.479c0.048-0.096,0.06-0.156,0.012-0.156c-0.072,0-0.227,0.168-0.322,0.313
				c-0.073,0.117-0.156,0.346-0.156,0.523c0,0.336,0.108,0.693,0.179,0.906c0.132,0.371,0.251,0.801,0.251,1.207
				c0,0.645-0.287,1.084-0.799,1.479c0.047,0.037,0.167,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.324
				C31.54,52.819,31.409,52.511,31.277,52.151"
        />
        <rect
          x="-0.938"
          y="76.327"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          width="5.986"
          height="5.986"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M0.935,78.364v1.271c0,0.168-0.011,0.432-0.221,0.572
				c-0.082,0.053-0.195,0.086-0.308,0.086c-0.136,0-0.297-0.047-0.433-0.176l0.224-0.27c0.036,0.055,0.096,0.102,0.184,0.102
				c0.079,0,0.124-0.033,0.144-0.064c0.045-0.064,0.042-0.189,0.042-0.305v-1.217H0.935z"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="2.315,80.251 2.039,80.251 1.381,78.364 1.771,78.364 2.193,79.647
				2.654,78.347 2.858,78.347 3.294,79.647 3.747,78.364 4.137,78.364 3.438,80.251 3.162,80.251 2.749,79.054 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-12.271-25.929c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.089,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081s-0.123,0.027-0.193,0.027h-0.16
				v0.59h-0.298v-1.479H-12.271z M-12.362-25.26c0.059,0,0.107-0.018,0.144-0.054c0.037-0.036,0.055-0.092,0.055-0.168
				c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.057-0.159-0.057h-0.125v0.449H-12.362z"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="-11.268,-25.929 -11.057,-24.884 -11.051,-24.884 -10.838,-25.929
				-10.397,-25.929 -10.397,-24.45 -10.67,-24.45 -10.67,-25.631 -10.674,-25.631 -10.938,-24.45 -11.17,-24.45 -11.434,-25.631
				-11.438,-25.631 -11.438,-24.45 -11.711,-24.45 -11.711,-25.929 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-9.943-24.869c0,0.035,0.003,0.066,0.009,0.095
				c0.004,0.028,0.015,0.052,0.03,0.071c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
				c0.045,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.024-0.024-0.046-0.045-0.065s-0.048-0.038-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.049
				c-0.066-0.021-0.123-0.046-0.172-0.072c-0.048-0.027-0.089-0.057-0.121-0.093c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.022c0.056,0.015,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.112,0.034,0.182v0.041H-9.59c0-0.07-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.023,0.009-0.04,0.022-0.053,0.037c-0.013,0.016-0.022,0.035-0.027,0.056
				c-0.005,0.02-0.007,0.041-0.007,0.064c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.06,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087C-9.292-25.07-9.278-25.036-9.269-25
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.168,0-0.289-0.037-0.362-0.11c-0.072-0.073-0.108-0.178-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-8.337-25.486h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.056
				c0.058-0.038,0.094-0.097,0.109-0.18h0.22v1.467h-0.298V-25.486z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-7.339-24.663c0.019,0.032,0.054,0.047,0.104,0.047
				c0.041,0,0.072-0.009,0.096-0.028c0.023-0.019,0.042-0.044,0.054-0.077c0.014-0.034,0.022-0.073,0.026-0.119
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.067-0.106,0.09s-0.103,0.034-0.169,0.034
				c-0.065,0-0.12-0.011-0.165-0.034c-0.047-0.023-0.084-0.055-0.112-0.095c-0.028-0.041-0.049-0.089-0.062-0.145
				c-0.013-0.057-0.019-0.117-0.019-0.184c0-0.17,0.037-0.3,0.112-0.39c0.076-0.089,0.196-0.134,0.362-0.134
				c0.102,0,0.184,0.019,0.244,0.057c0.061,0.038,0.108,0.089,0.14,0.151c0.033,0.063,0.054,0.136,0.064,0.219
				c0.009,0.083,0.014,0.169,0.014,0.259c0,0.105-0.003,0.206-0.009,0.303c-0.006,0.098-0.025,0.184-0.056,0.259
				c-0.031,0.074-0.08,0.133-0.147,0.176c-0.067,0.044-0.161,0.066-0.281,0.066c-0.053,0-0.103-0.005-0.153-0.016
				c-0.049-0.01-0.092-0.029-0.129-0.057c-0.037-0.029-0.067-0.067-0.09-0.115c-0.023-0.048-0.035-0.106-0.037-0.177h0.287
				C-7.369-24.735-7.36-24.693-7.339-24.663 M-7.354-25.219c0.025,0.043,0.066,0.065,0.124,0.065c0.059,0,0.102-0.021,0.127-0.065
				c0.025-0.042,0.037-0.114,0.037-0.214c0-0.041-0.002-0.079-0.006-0.116c-0.004-0.035-0.012-0.066-0.024-0.091
				c-0.011-0.026-0.028-0.046-0.05-0.061c-0.021-0.014-0.049-0.022-0.084-0.022c-0.035,0-0.064,0.008-0.086,0.022
				c-0.021,0.015-0.037,0.035-0.048,0.061c-0.011,0.027-0.019,0.058-0.022,0.093c-0.003,0.035-0.005,0.073-0.005,0.114
				C-7.392-25.333-7.38-25.261-7.354-25.219"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-5.775-25.66c-0.061,0.086-0.115,0.178-0.162,0.275
				c-0.048,0.099-0.09,0.2-0.126,0.303c-0.036,0.104-0.066,0.21-0.089,0.317c-0.024,0.107-0.042,0.212-0.054,0.315H-6.52
				c0.011-0.088,0.027-0.181,0.05-0.278c0.021-0.098,0.052-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
				c0.053-0.103,0.115-0.203,0.187-0.3H-6.68v-0.257h0.904V-25.66z"
        />
        <rect
          x="-3.094"
          y="-26.169"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          width="8.295"
          height="8.294"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M4.941-18.134h-7.777v-7.776h7.777V-18.134z M5.46-26.428h-8.813v8.812H5.46
				V-26.428z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-2.438-25.929c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59H-2.96v-1.479H-2.438z M-2.529-25.26c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H-2.529z
				"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="-1.435,-25.929 -1.224,-24.884 -1.219,-24.884 -1.006,-25.929
				-0.565,-25.929 -0.565,-24.45 -0.838,-24.45 -0.838,-25.631 -0.842,-25.631 -1.105,-24.45 -1.338,-24.45 -1.601,-25.631
				-1.605,-25.631 -1.605,-24.45 -1.879,-24.45 -1.879,-25.929 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-0.111-24.868c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0.242c0-0.071-0.013-0.125-0.038-0.162C0.18-25.72,0.138-25.738,0.08-25.738
				c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115C-0.042-25.42,0-25.392,0.063-25.365l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078s0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.868z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-2.438-23.985c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59H-2.96v-1.479H-2.438z M-2.529-23.316c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H-2.529z
				"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-1.62-23.621v0.149h0.005c0.028-0.054,0.064-0.097,0.105-0.129
				c0.041-0.033,0.092-0.05,0.151-0.05c0.009,0,0.017,0.001,0.027,0.001l0.025,0.004v0.273c-0.015-0.001-0.03-0.002-0.044-0.002
				c-0.015-0.001-0.03-0.001-0.045-0.001c-0.025,0-0.05,0.003-0.075,0.009c-0.025,0.008-0.047,0.019-0.068,0.035
				s-0.037,0.036-0.049,0.062c-0.013,0.026-0.02,0.057-0.02,0.094v0.67h-0.285v-1.115H-1.62z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-1.217-23.316c0.015-0.074,0.04-0.135,0.074-0.184
				c0.035-0.049,0.08-0.086,0.137-0.112c0.055-0.025,0.124-0.039,0.205-0.039c0.086,0,0.157,0.011,0.214,0.035
				c0.057,0.025,0.101,0.061,0.134,0.111c0.034,0.049,0.057,0.111,0.071,0.185c0.014,0.073,0.021,0.159,0.021,0.256
				c0,0.096-0.008,0.18-0.023,0.253c-0.015,0.073-0.04,0.135-0.075,0.184c-0.034,0.049-0.08,0.085-0.135,0.109
				c-0.057,0.023-0.125,0.034-0.207,0.034c-0.085,0.002-0.157-0.008-0.213-0.031c-0.057-0.023-0.101-0.059-0.135-0.108
				c-0.033-0.049-0.056-0.111-0.07-0.185c-0.014-0.074-0.021-0.159-0.021-0.256C-1.24-23.159-1.232-23.243-1.217-23.316
				 M-0.95-22.906c0.003,0.048,0.01,0.09,0.02,0.124c0.011,0.035,0.026,0.061,0.046,0.081c0.021,0.019,0.049,0.029,0.083,0.029
				c0.058,0,0.099-0.03,0.121-0.091s0.033-0.161,0.033-0.301c0-0.139-0.011-0.239-0.033-0.3s-0.063-0.092-0.121-0.092
				c-0.034,0-0.062,0.01-0.083,0.03c-0.02,0.019-0.035,0.046-0.046,0.08c-0.01,0.035-0.017,0.077-0.02,0.125
				s-0.004,0.101-0.004,0.157C-0.955-23.007-0.953-22.955-0.95-22.906"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M0.323-23.3c-0.004-0.026-0.01-0.05-0.019-0.071
				c-0.008-0.022-0.021-0.039-0.037-0.052c-0.018-0.013-0.04-0.019-0.067-0.019c-0.035,0-0.063,0.007-0.085,0.023
				s-0.039,0.04-0.05,0.07c-0.011,0.032-0.019,0.071-0.023,0.119c-0.004,0.048-0.006,0.104-0.006,0.169
				c0,0.069,0.002,0.128,0.006,0.177c0.004,0.049,0.012,0.09,0.023,0.121c0.011,0.032,0.027,0.055,0.047,0.07
				c0.02,0.014,0.046,0.022,0.077,0.022c0.049,0,0.084-0.021,0.106-0.063c0.022-0.041,0.034-0.107,0.034-0.199h0.273
				c0,0.146-0.033,0.258-0.098,0.335c-0.066,0.077-0.175,0.115-0.327,0.115c-0.072,0-0.134-0.009-0.188-0.026
				c-0.053-0.017-0.097-0.048-0.132-0.093c-0.036-0.045-0.063-0.105-0.08-0.181c-0.018-0.074-0.027-0.168-0.027-0.281
				c0-0.114,0.011-0.209,0.033-0.285c0.022-0.075,0.053-0.134,0.094-0.179c0.04-0.046,0.087-0.077,0.142-0.095
				c0.056-0.018,0.117-0.027,0.183-0.027c0.132,0,0.232,0.039,0.3,0.116c0.067,0.078,0.1,0.182,0.1,0.315H0.329
				C0.329-23.247,0.327-23.274,0.323-23.3"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M0.996-22.931c0,0.032,0.002,0.065,0.007,0.095
				c0.005,0.032,0.013,0.059,0.024,0.083c0.011,0.025,0.026,0.044,0.044,0.059c0.017,0.014,0.04,0.022,0.066,0.022
				c0.048,0,0.083-0.018,0.106-0.053c0.022-0.034,0.037-0.087,0.045-0.157h0.257c-0.005,0.13-0.04,0.229-0.103,0.297
				c-0.064,0.067-0.164,0.101-0.301,0.101c-0.104,0-0.184-0.017-0.242-0.052c-0.059-0.034-0.101-0.08-0.129-0.136
				c-0.028-0.057-0.044-0.121-0.051-0.191c-0.006-0.071-0.009-0.142-0.009-0.212c0-0.075,0.005-0.146,0.016-0.216
				c0.009-0.068,0.03-0.13,0.062-0.184c0.03-0.054,0.075-0.097,0.133-0.128c0.058-0.032,0.136-0.048,0.233-0.048
				c0.082,0,0.15,0.013,0.203,0.04c0.054,0.027,0.096,0.066,0.126,0.114c0.03,0.05,0.051,0.109,0.062,0.179
				c0.011,0.069,0.017,0.147,0.017,0.233v0.064H0.996V-22.931z M1.28-23.304c-0.005-0.032-0.011-0.06-0.022-0.083
				c-0.01-0.023-0.025-0.04-0.043-0.052c-0.019-0.011-0.043-0.017-0.072-0.017S1.09-23.45,1.072-23.437
				c-0.019,0.014-0.034,0.031-0.045,0.053c-0.011,0.021-0.019,0.045-0.024,0.071c-0.005,0.026-0.007,0.053-0.007,0.079v0.044h0.292
				C1.287-23.233,1.284-23.271,1.28-23.304"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M1.92-22.834c0,0.049,0.013,0.088,0.038,0.118
				c0.026,0.029,0.063,0.044,0.111,0.044c0.045,0,0.08-0.011,0.106-0.033c0.026-0.023,0.039-0.055,0.039-0.1
				c0-0.036-0.01-0.063-0.03-0.082c-0.021-0.018-0.045-0.034-0.073-0.044L1.91-23.004c-0.079-0.027-0.138-0.067-0.178-0.117
				c-0.04-0.05-0.06-0.115-0.06-0.194c0-0.045,0.007-0.089,0.022-0.129c0.016-0.041,0.04-0.077,0.073-0.107
				c0.033-0.03,0.076-0.054,0.127-0.072c0.052-0.019,0.115-0.028,0.188-0.028c0.129,0,0.226,0.028,0.288,0.083
				s0.094,0.134,0.094,0.235v0.045H2.207c0-0.058-0.01-0.1-0.029-0.127c-0.019-0.027-0.051-0.041-0.098-0.041
				c-0.036,0-0.067,0.01-0.095,0.03c-0.027,0.02-0.04,0.051-0.04,0.09c0,0.028,0.009,0.053,0.026,0.075s0.05,0.041,0.098,0.056
				l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.119c0.038,0.049,0.056,0.116,0.056,0.2c0,0.06-0.01,0.112-0.031,0.155
				c-0.021,0.044-0.049,0.079-0.086,0.109c-0.036,0.028-0.08,0.049-0.131,0.062c-0.05,0.012-0.105,0.018-0.167,0.018
				c-0.079,0-0.146-0.007-0.2-0.023c-0.052-0.015-0.094-0.037-0.126-0.068c-0.031-0.03-0.053-0.067-0.065-0.111
				c-0.012-0.043-0.019-0.092-0.019-0.146v-0.039H1.92V-22.834z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M2.84-22.834c0,0.049,0.012,0.088,0.038,0.118
				c0.026,0.029,0.063,0.044,0.111,0.044c0.044,0,0.08-0.011,0.106-0.033c0.026-0.023,0.039-0.055,0.039-0.1
				c0-0.036-0.01-0.063-0.031-0.082c-0.021-0.018-0.045-0.034-0.072-0.044L2.83-23.004c-0.078-0.027-0.138-0.067-0.178-0.117
				c-0.039-0.05-0.06-0.115-0.06-0.194c0-0.045,0.008-0.089,0.023-0.129c0.015-0.041,0.039-0.077,0.073-0.107
				c0.033-0.03,0.075-0.054,0.127-0.072c0.052-0.019,0.115-0.028,0.188-0.028c0.129,0,0.226,0.028,0.288,0.083
				s0.093,0.134,0.093,0.235v0.045H3.126c0-0.058-0.009-0.1-0.028-0.127C3.08-23.442,3.046-23.456,3-23.456
				c-0.037,0-0.068,0.01-0.095,0.03c-0.027,0.02-0.04,0.051-0.04,0.09c0,0.028,0.008,0.053,0.026,0.075
				c0.017,0.022,0.05,0.041,0.098,0.056l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.119s0.056,0.116,0.056,0.2
				c0,0.06-0.01,0.112-0.031,0.155c-0.021,0.044-0.05,0.079-0.086,0.109c-0.037,0.028-0.081,0.049-0.131,0.062
				c-0.05,0.012-0.106,0.018-0.167,0.018c-0.079,0-0.146-0.007-0.2-0.023c-0.052-0.015-0.095-0.037-0.126-0.068
				c-0.032-0.03-0.053-0.067-0.065-0.111c-0.013-0.043-0.019-0.092-0.019-0.146v-0.039H2.84V-22.834z"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="-2.511,-22.043 -2.3,-20.998 -2.295,-20.998 -2.082,-22.043
				-1.641,-22.043 -1.641,-20.564 -1.914,-20.564 -1.914,-21.745 -1.918,-21.745 -2.181,-20.564 -2.414,-20.564 -2.677,-21.745
				-2.681,-21.745 -2.681,-20.564 -2.955,-20.564 -2.955,-22.043 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M-1.437-21.364c0-0.065,0.01-0.119,0.031-0.162
				c0.021-0.044,0.049-0.079,0.084-0.106c0.035-0.027,0.076-0.047,0.124-0.058c0.048-0.012,0.099-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025c0.052,0.017,0.093,0.04,0.122,0.071c0.029,0.03,0.049,0.066,0.059,0.108
				c0.011,0.042,0.015,0.088,0.015,0.138v0.587c0,0.052,0.003,0.093,0.008,0.122c0.005,0.029,0.014,0.06,0.028,0.093H-0.88
				c-0.009-0.017-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059h-0.004c-0.033,0.059-0.071,0.096-0.115,0.113
				c-0.044,0.017-0.1,0.026-0.169,0.026c-0.05,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.071
				c-0.021-0.03-0.036-0.065-0.046-0.103c-0.01-0.038-0.014-0.075-0.014-0.113c0-0.052,0.005-0.097,0.016-0.135
				c0.012-0.039,0.028-0.071,0.051-0.098c0.023-0.027,0.051-0.05,0.087-0.067c0.035-0.019,0.078-0.034,0.128-0.048l0.161-0.044
				c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.076-0.03-0.101
				c-0.02-0.024-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.1,0.039c-0.022,0.027-0.034,0.062-0.034,0.106v0.031h-0.256
				V-21.364z M-0.977-21.084c-0.022,0.009-0.042,0.016-0.06,0.02c-0.059,0.012-0.1,0.033-0.124,0.063
				c-0.025,0.029-0.038,0.068-0.038,0.118c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.044,0.046,0.083,0.046
				c0.019,0,0.039-0.004,0.06-0.01s0.04-0.016,0.057-0.03c0.017-0.014,0.031-0.032,0.043-0.054c0.01-0.022,0.016-0.048,0.016-0.079
				v-0.219C-0.935-21.106-0.955-21.094-0.977-21.084"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M0.397-20.62c0,0.144-0.036,0.252-0.107,0.324
				c-0.071,0.072-0.19,0.108-0.357,0.108c-0.122,0-0.215-0.026-0.281-0.078c-0.066-0.051-0.099-0.126-0.099-0.221h0.274
				c0,0.036,0.014,0.064,0.043,0.085c0.014,0.01,0.029,0.018,0.045,0.023c0.016,0.006,0.033,0.008,0.051,0.008
				c0.058,0,0.099-0.019,0.122-0.055c0.023-0.038,0.035-0.085,0.035-0.14v-0.147H0.119c-0.029,0.042-0.064,0.075-0.106,0.1
				c-0.043,0.025-0.086,0.037-0.132,0.037c-0.113,0-0.199-0.044-0.256-0.13c-0.058-0.086-0.086-0.231-0.086-0.433
				c0-0.066,0.003-0.132,0.011-0.2c0.008-0.067,0.024-0.128,0.048-0.183c0.024-0.055,0.059-0.099,0.106-0.134
				c0.046-0.035,0.108-0.052,0.185-0.052c0.04,0,0.081,0.011,0.124,0.034c0.043,0.022,0.079,0.062,0.106,0.12h0.004v-0.124h0.274
				V-20.62z M-0.17-20.976c0.004,0.034,0.011,0.065,0.021,0.092c0.011,0.027,0.025,0.048,0.043,0.064
				c0.019,0.016,0.043,0.024,0.074,0.024c0.03,0,0.055-0.008,0.075-0.024c0.021-0.016,0.036-0.037,0.048-0.065
				c0.012-0.027,0.02-0.06,0.025-0.1s0.007-0.083,0.007-0.13c0-0.135-0.011-0.231-0.032-0.288s-0.06-0.085-0.117-0.085
				c-0.032,0-0.057,0.007-0.077,0.021c-0.019,0.014-0.034,0.036-0.045,0.068c-0.011,0.03-0.018,0.072-0.022,0.124
				c-0.004,0.051-0.005,0.115-0.005,0.189C-0.175-21.047-0.174-21.01-0.17-20.976"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M0.842-20.987c0,0.032,0.003,0.065,0.007,0.095
				c0.005,0.031,0.013,0.059,0.024,0.083c0.011,0.025,0.026,0.044,0.044,0.059c0.018,0.014,0.04,0.022,0.067,0.022
				c0.047,0,0.083-0.018,0.105-0.053c0.022-0.034,0.037-0.087,0.046-0.157h0.256c-0.005,0.13-0.04,0.229-0.103,0.297
				c-0.064,0.067-0.164,0.101-0.301,0.101c-0.103,0-0.184-0.017-0.242-0.052c-0.058-0.034-0.101-0.08-0.128-0.136
				c-0.028-0.057-0.045-0.121-0.052-0.191c-0.006-0.071-0.009-0.142-0.009-0.212c0-0.075,0.005-0.146,0.016-0.216
				c0.01-0.068,0.031-0.13,0.062-0.184c0.031-0.054,0.075-0.097,0.134-0.128C0.825-21.691,0.903-21.708,1-21.708
				c0.082,0,0.151,0.013,0.204,0.04c0.053,0.027,0.095,0.066,0.126,0.114c0.03,0.049,0.05,0.109,0.061,0.179
				c0.011,0.069,0.017,0.147,0.017,0.233v0.063H0.842V-20.987z M1.126-21.36c-0.004-0.032-0.011-0.06-0.022-0.083
				c-0.01-0.023-0.025-0.04-0.043-0.052c-0.019-0.011-0.043-0.017-0.072-0.017s-0.053,0.006-0.071,0.019
				C0.9-21.479,0.884-21.462,0.873-21.44c-0.011,0.021-0.019,0.045-0.024,0.071c-0.004,0.026-0.007,0.053-0.007,0.079v0.043h0.293
				C1.133-21.291,1.13-21.328,1.126-21.36"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M1.831-21.678v0.131h0.004c0.03-0.056,0.07-0.096,0.118-0.122
				c0.048-0.025,0.104-0.038,0.166-0.038c0.091,0,0.161,0.024,0.21,0.075c0.05,0.049,0.074,0.13,0.074,0.242v0.827H2.117v-0.769
				c0-0.058-0.01-0.098-0.029-0.121c-0.02-0.023-0.051-0.035-0.094-0.035c-0.1,0-0.151,0.062-0.151,0.185v0.74H1.557v-1.115H1.831z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M2.942-21.998v0.319h0.166v0.195H2.942v0.603
				c0,0.044,0.007,0.076,0.02,0.094c0.013,0.019,0.04,0.028,0.082,0.028c0.011,0,0.022,0,0.033-0.001
				c0.011,0,0.021-0.001,0.031-0.003v0.199c-0.032,0-0.063,0.001-0.092,0.003c-0.03,0.002-0.062,0.003-0.095,0.003
				c-0.055,0-0.1-0.004-0.135-0.011c-0.036-0.008-0.063-0.023-0.081-0.046c-0.019-0.022-0.032-0.053-0.038-0.09
				c-0.007-0.037-0.011-0.084-0.011-0.141v-0.638H2.511v-0.195h0.145v-0.319H2.942z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M3.205-21.364c0-0.065,0.01-0.119,0.031-0.162
				c0.021-0.044,0.049-0.079,0.084-0.106c0.035-0.027,0.076-0.047,0.124-0.058c0.048-0.012,0.099-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025c0.052,0.017,0.093,0.04,0.122,0.071c0.029,0.03,0.049,0.066,0.059,0.108
				C3.996-21.461,4-21.416,4-21.366v0.587c0,0.052,0.003,0.093,0.008,0.122c0.005,0.029,0.014,0.06,0.028,0.093H3.762
				c-0.009-0.017-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059H3.723c-0.033,0.059-0.071,0.096-0.115,0.113
				c-0.044,0.017-0.1,0.026-0.169,0.026c-0.05,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.071
				s-0.036-0.065-0.046-0.103c-0.01-0.038-0.014-0.075-0.014-0.113c0-0.052,0.005-0.097,0.016-0.135
				c0.012-0.039,0.028-0.071,0.051-0.098c0.023-0.027,0.051-0.05,0.087-0.067c0.035-0.019,0.078-0.034,0.128-0.048l0.161-0.044
				c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.076-0.03-0.101
				c-0.02-0.024-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.1,0.039c-0.022,0.027-0.034,0.062-0.034,0.106v0.031H3.205V-21.364
				z M3.665-21.084c-0.022,0.009-0.042,0.016-0.06,0.02c-0.059,0.012-0.1,0.033-0.124,0.063c-0.025,0.029-0.038,0.068-0.038,0.118
				c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.044,0.046,0.083,0.046c0.019,0,0.039-0.004,0.06-0.01s0.04-0.016,0.057-0.03
				c0.017-0.014,0.031-0.032,0.043-0.054c0.01-0.022,0.016-0.048,0.016-0.079v-0.219C3.707-21.106,3.687-21.094,3.665-21.084"
        />
        <rect
          x="6.515"
          y="-26.428"
          clipPath="url(#VLOGGING_SVGID_90_)"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M7.43-25.929c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				C7.5-25.049,7.436-25.04,7.366-25.04h-0.16v0.59H6.908v-1.479H7.43z M7.339-25.26c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058H7.206v0.45H7.339z"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="8.433,-25.929 8.644,-24.884 8.649,-24.884 8.862,-25.929
				9.303,-25.929 9.303,-24.45 9.03,-24.45 9.03,-25.631 9.026,-25.631 8.763,-24.45 8.53,-24.45 8.267,-25.631 8.263,-25.631
				8.263,-24.45 7.989,-24.45 7.989,-25.929 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M9.757-24.868c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108c0.066,0,0.127,0.007,0.183,0.021
				c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041H10.11
				c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.022,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.868z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M7.407-23.986c0.061,0,0.118,0.006,0.169,0.019
				c0.052,0.012,0.096,0.033,0.134,0.063c0.037,0.029,0.066,0.067,0.087,0.116c0.02,0.048,0.031,0.108,0.031,0.178
				c0,0.08-0.019,0.149-0.056,0.206c-0.038,0.058-0.094,0.097-0.168,0.117v0.005c0.083,0.01,0.149,0.046,0.197,0.105
				c0.048,0.06,0.072,0.139,0.072,0.238c0,0.059-0.007,0.114-0.022,0.166c-0.016,0.053-0.042,0.099-0.078,0.138
				c-0.037,0.04-0.084,0.071-0.143,0.094c-0.059,0.024-0.132,0.036-0.219,0.036H6.907v-1.48H7.407z M7.291-23.379
				c0.083,0,0.143-0.014,0.181-0.044c0.038-0.03,0.057-0.08,0.057-0.151c0-0.07-0.017-0.12-0.052-0.149
				c-0.034-0.028-0.089-0.043-0.163-0.043H7.206v0.387H7.291z M7.322-22.726c0.072,0,0.13-0.016,0.174-0.048
				c0.045-0.031,0.066-0.09,0.066-0.176c0-0.043-0.005-0.078-0.017-0.107c-0.012-0.028-0.028-0.05-0.048-0.068
				s-0.043-0.029-0.071-0.036c-0.028-0.008-0.058-0.011-0.092-0.011H7.206v0.446H7.322z"
        />
        <rect
          x="8.063"
          y="-23.985"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          width="0.286"
          height="1.479"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M8.547-23.307c0-0.065,0.011-0.119,0.031-0.162
				c0.021-0.044,0.049-0.079,0.084-0.106c0.035-0.027,0.077-0.047,0.124-0.058c0.048-0.012,0.099-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025c0.053,0.017,0.093,0.041,0.122,0.071c0.03,0.03,0.049,0.066,0.059,0.108
				c0.011,0.042,0.016,0.088,0.016,0.138v0.587c0,0.052,0.002,0.094,0.007,0.122c0.005,0.029,0.014,0.06,0.028,0.093H9.104
				c-0.009-0.017-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059H9.065c-0.033,0.059-0.071,0.096-0.114,0.113
				c-0.044,0.017-0.101,0.026-0.17,0.026c-0.049,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.071
				c-0.021-0.03-0.036-0.065-0.046-0.103c-0.009-0.038-0.014-0.075-0.014-0.113c0-0.052,0.005-0.097,0.016-0.135
				c0.012-0.039,0.029-0.071,0.052-0.098c0.022-0.027,0.051-0.05,0.087-0.067c0.034-0.019,0.077-0.034,0.126-0.048l0.162-0.044
				c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.076-0.03-0.101
				c-0.02-0.024-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.099,0.039c-0.023,0.027-0.034,0.062-0.034,0.106v0.031H8.547
				V-23.307z M9.007-23.028c-0.022,0.009-0.042,0.016-0.06,0.02c-0.058,0.012-0.1,0.033-0.124,0.062
				c-0.025,0.029-0.038,0.068-0.038,0.118c0,0.043,0.008,0.079,0.025,0.11c0.017,0.03,0.045,0.046,0.083,0.046
				c0.019,0,0.039-0.004,0.06-0.01s0.04-0.016,0.058-0.03c0.017-0.014,0.031-0.032,0.042-0.054c0.01-0.022,0.016-0.048,0.016-0.079
				v-0.219C9.05-23.049,9.029-23.037,9.007-23.028"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M10.078-23.3c-0.004-0.026-0.01-0.05-0.019-0.071
				c-0.009-0.021-0.021-0.039-0.038-0.052c-0.017-0.013-0.039-0.019-0.066-0.019c-0.035,0-0.063,0.007-0.085,0.023
				c-0.023,0.016-0.039,0.04-0.05,0.07c-0.011,0.032-0.019,0.071-0.023,0.119c-0.004,0.048-0.007,0.104-0.007,0.169
				c0,0.069,0.003,0.128,0.007,0.177c0.004,0.049,0.012,0.09,0.023,0.121c0.011,0.032,0.026,0.055,0.047,0.07
				c0.02,0.014,0.046,0.022,0.077,0.022c0.048,0,0.084-0.021,0.105-0.063c0.023-0.041,0.034-0.107,0.034-0.199h0.274
				c0,0.146-0.033,0.258-0.099,0.335c-0.065,0.077-0.174,0.115-0.326,0.115c-0.072,0-0.135-0.009-0.188-0.026
				c-0.053-0.017-0.097-0.048-0.132-0.093c-0.036-0.045-0.063-0.105-0.081-0.181c-0.017-0.074-0.026-0.168-0.026-0.281
				c0-0.114,0.011-0.209,0.033-0.285c0.022-0.075,0.053-0.134,0.093-0.179c0.04-0.046,0.088-0.077,0.143-0.095
				C9.83-23.641,9.89-23.65,9.957-23.65c0.132,0,0.232,0.039,0.299,0.116c0.067,0.078,0.101,0.182,0.101,0.315h-0.274
				C10.083-23.247,10.082-23.274,10.078-23.3"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="10.802,-23.205 10.807,-23.205 11.094,-23.622 11.41,-23.622
				11.089,-23.182 11.457,-22.507 11.142,-22.507 10.904,-22.965 10.802,-22.832 10.802,-22.507 10.516,-22.507 10.516,-23.986
				10.802,-23.986 			"
        />
        <rect
          x="16.346"
          y="-26.428"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F0CB99"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M17.26-25.928c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.26z M17.169-25.259c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H17.169z
				"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="18.264,-25.929 18.475,-24.884 18.479,-24.884 18.693,-25.929
				19.134,-25.929 19.134,-24.45 18.861,-24.45 18.861,-25.631 18.857,-25.631 18.594,-24.45 18.361,-24.45 18.098,-25.631
				18.094,-25.631 18.094,-24.45 17.82,-24.45 17.82,-25.929 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M19.588-24.868c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.868z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M21.194-25.486h-0.292v-0.195h0.026c0.1,0,0.178-0.018,0.235-0.055
				c0.058-0.038,0.094-0.098,0.109-0.181h0.22v1.467h-0.298V-25.486z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M22.152-25.656l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.084
				c0.044-0.017,0.098-0.026,0.161-0.026c0.071,0,0.129,0.013,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.106
				c0.027,0.045,0.046,0.094,0.057,0.151c0.011,0.055,0.017,0.113,0.017,0.17c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.067-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.084-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.04-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.051,0.005,0.079c0.002,0.027,0.009,0.053,0.019,0.077
				c0.011,0.024,0.025,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.077,0.024c0.062,0,0.106-0.024,0.133-0.071
				c0.027-0.048,0.041-0.12,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
				c-0.013-0.023-0.03-0.04-0.053-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.025-0.037,0.058-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H22.152z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M23.146-25.656l-0.031,0.334h0.004c0.027-0.039,0.063-0.067,0.108-0.084
				c0.044-0.017,0.098-0.026,0.161-0.026c0.071,0,0.129,0.013,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.106
				c0.027,0.045,0.046,0.094,0.057,0.151c0.011,0.055,0.017,0.113,0.017,0.17c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.067-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.084-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.031-0.04-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.051,0.005,0.079c0.002,0.027,0.009,0.053,0.019,0.077
				c0.011,0.024,0.025,0.045,0.045,0.06c0.019,0.016,0.045,0.024,0.076,0.024c0.063,0,0.107-0.024,0.134-0.071
				c0.027-0.048,0.041-0.12,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
				c-0.013-0.023-0.03-0.04-0.053-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.025-0.037,0.058-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H23.146z"
        />
        <rect
          x="45.95"
          y="-26.428"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#F37321"
          width="8.813"
          height="8.813"
        />

        <rect
          x="45.951"
          y="-26.428"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          width="8.813"
          height="8.812"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M46.865-25.928c0.076,0,0.141,0.012,0.191,0.037
				c0.053,0.026,0.095,0.06,0.125,0.101c0.033,0.042,0.056,0.089,0.07,0.142c0.012,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.022,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.297v-1.479H46.865z M46.774-25.259c0.06,0,0.106-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.032-0.039-0.086-0.058-0.16-0.058h-0.125v0.45H46.774z"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="47.869,-25.929 48.08,-24.884 48.085,-24.884 48.298,-25.929
				48.738,-25.929 48.738,-24.45 48.466,-24.45 48.466,-25.631 48.462,-25.631 48.199,-24.45 47.966,-24.45 47.703,-25.631
				47.699,-25.631 47.699,-24.45 47.425,-24.45 47.425,-25.929 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M49.193-24.868c0,0.034,0.002,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.035,0.035,0.062,0.045c0.024,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.014,0.123-0.044c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.062-0.014-0.086
				c-0.009-0.025-0.023-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.074-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.192-0.108,0.336-0.108
				c0.065,0,0.127,0.007,0.183,0.021c0.057,0.015,0.104,0.039,0.146,0.071c0.041,0.033,0.071,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.071-0.013-0.125-0.038-0.162c-0.023-0.039-0.066-0.057-0.123-0.057
				c-0.033,0-0.062,0.004-0.084,0.014c-0.021,0.009-0.039,0.023-0.053,0.038c-0.014,0.016-0.021,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.011,0.083,0.029,0.115c0.019,0.031,0.062,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.035,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.091,0.071-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.362-0.109c-0.071-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.868z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M50.799-25.486h-0.291v-0.195h0.025c0.1,0,0.178-0.018,0.235-0.055
				c0.058-0.038,0.095-0.098,0.108-0.181h0.221v1.467h-0.299V-25.486z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M51.757-25.656l-0.03,0.334h0.004c0.027-0.039,0.063-0.067,0.107-0.084
				c0.044-0.017,0.098-0.026,0.161-0.026c0.071,0,0.129,0.013,0.176,0.041c0.046,0.026,0.083,0.062,0.108,0.106
				c0.027,0.045,0.047,0.094,0.058,0.151c0.011,0.055,0.017,0.113,0.017,0.17c0,0.082-0.006,0.156-0.021,0.222
				c-0.013,0.067-0.038,0.123-0.073,0.171c-0.035,0.048-0.084,0.084-0.144,0.108c-0.062,0.024-0.138,0.036-0.231,0.036
				s-0.169-0.012-0.226-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.04-0.051-0.085-0.062-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.051,0.005,0.079c0.002,0.027,0.009,0.053,0.019,0.077
				c0.012,0.024,0.025,0.045,0.045,0.06c0.021,0.016,0.045,0.024,0.077,0.024c0.062,0,0.106-0.024,0.134-0.071
				c0.026-0.048,0.041-0.12,0.041-0.215c0-0.061-0.004-0.111-0.01-0.151c-0.005-0.04-0.015-0.072-0.027-0.096
				c-0.014-0.023-0.03-0.04-0.054-0.049c-0.022-0.01-0.049-0.015-0.081-0.015c-0.044,0-0.076,0.012-0.1,0.036
				c-0.023,0.025-0.037,0.058-0.042,0.099h-0.269l0.055-0.798h0.765v0.232H51.757z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M52.46-25.427c0.006-0.095,0.024-0.178,0.056-0.25
				c0.03-0.073,0.076-0.131,0.137-0.174c0.063-0.044,0.148-0.066,0.256-0.066c0.092,0,0.166,0.013,0.225,0.039
				s0.105,0.067,0.141,0.123c0.034,0.057,0.059,0.13,0.074,0.221c0.014,0.09,0.021,0.201,0.021,0.33c0,0.102-0.004,0.2-0.01,0.295
				c-0.008,0.094-0.025,0.177-0.056,0.25c-0.03,0.072-0.077,0.129-0.14,0.17c-0.061,0.042-0.146,0.063-0.256,0.063
				c-0.088,0-0.162-0.012-0.221-0.036c-0.059-0.023-0.107-0.063-0.142-0.12c-0.036-0.056-0.06-0.13-0.075-0.221
				c-0.014-0.09-0.021-0.201-0.021-0.33C52.449-25.235,52.453-25.333,52.46-25.427 M52.755-24.842
				c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.028,0.057,0.049,0.069c0.021,0.013,0.047,0.019,0.078,0.019
				c0.035,0,0.062-0.008,0.082-0.024s0.037-0.042,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.152
				c0.004-0.064,0.006-0.143,0.006-0.236v-0.16c0-0.093-0.002-0.168-0.006-0.227c-0.006-0.058-0.015-0.105-0.027-0.139
				c-0.012-0.033-0.028-0.057-0.049-0.069c-0.021-0.013-0.047-0.019-0.08-0.019s-0.059,0.008-0.08,0.024
				c-0.021,0.016-0.037,0.043-0.049,0.081s-0.02,0.089-0.024,0.152c-0.005,0.064-0.007,0.142-0.007,0.236v0.161
				C52.748-24.976,52.75-24.901,52.755-24.842"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M53.746-25.656l-0.031,0.334h0.004c0.029-0.039,0.063-0.067,0.109-0.084
				c0.043-0.017,0.098-0.026,0.16-0.026c0.071,0,0.131,0.013,0.176,0.041c0.047,0.026,0.084,0.062,0.109,0.106
				c0.027,0.045,0.046,0.094,0.057,0.151c0.012,0.055,0.018,0.113,0.018,0.17c0,0.082-0.008,0.156-0.021,0.222
				c-0.013,0.067-0.037,0.123-0.072,0.171c-0.037,0.048-0.084,0.084-0.145,0.108c-0.061,0.024-0.139,0.036-0.232,0.036
				c-0.092,0-0.168-0.012-0.225-0.037c-0.059-0.025-0.102-0.058-0.132-0.099c-0.03-0.04-0.052-0.085-0.062-0.132
				c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.051,0.005,0.079c0.002,0.027,0.01,0.053,0.02,0.077
				c0.011,0.024,0.024,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.076,0.024c0.063,0,0.106-0.024,0.133-0.071
				c0.027-0.048,0.041-0.12,0.041-0.215c0-0.061-0.002-0.111-0.008-0.151c-0.006-0.04-0.016-0.072-0.029-0.096
				c-0.012-0.023-0.029-0.04-0.053-0.049c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.022,0.025-0.036,0.058-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H53.746z"
        />
        <rect
          x="26.215"
          y="-26.428"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#60C2E6"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M27.128-25.928c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H27.128z M27.038-25.259c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H27.038z"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="28.132,-25.929 28.343,-24.884 28.348,-24.884 28.561,-25.929
				29.002,-25.929 29.002,-24.45 28.729,-24.45 28.729,-25.631 28.725,-25.631 28.462,-24.45 28.229,-24.45 27.966,-25.631
				27.962,-25.631 27.962,-24.45 27.688,-24.45 27.688,-25.929 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M29.456-24.868c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.014,0.123-0.044c0.037-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108c0.066,0,0.127,0.007,0.183,0.021
				c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285
				c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.022,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.868z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M30.723-24.449c0-0.065,0.007-0.125,0.02-0.182
				c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.056
				c0-0.137,0.036-0.242,0.11-0.315c0.073-0.074,0.188-0.11,0.346-0.11c0.149,0,0.26,0.034,0.332,0.103
				c0.071,0.07,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.009,0.041-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H30.723z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M32.061-24.662c0.02,0.032,0.055,0.047,0.104,0.047
				c0.041,0,0.071-0.009,0.096-0.028c0.023-0.019,0.042-0.045,0.054-0.078c0.015-0.033,0.022-0.073,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.066-0.106,0.089c-0.045,0.023-0.103,0.035-0.168,0.035
				s-0.121-0.012-0.166-0.035c-0.047-0.023-0.084-0.054-0.112-0.095c-0.028-0.04-0.049-0.089-0.062-0.145s-0.019-0.117-0.019-0.183
				c0-0.17,0.037-0.3,0.112-0.39c0.076-0.09,0.196-0.135,0.362-0.135c0.103,0,0.185,0.019,0.244,0.058
				c0.061,0.037,0.108,0.088,0.141,0.151c0.033,0.063,0.054,0.135,0.063,0.219c0.009,0.082,0.015,0.169,0.015,0.259
				c0,0.105-0.004,0.206-0.01,0.303c-0.007,0.098-0.025,0.184-0.056,0.258c-0.031,0.075-0.08,0.133-0.147,0.177
				c-0.066,0.043-0.16,0.065-0.281,0.065c-0.053,0-0.103-0.004-0.152-0.015c-0.049-0.01-0.092-0.03-0.129-0.058
				c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.107-0.037-0.178h0.285C32.029-24.736,32.04-24.693,32.061-24.662
				 M32.045-25.218c0.025,0.043,0.066,0.064,0.124,0.064c0.06,0,0.103-0.021,0.127-0.064s0.037-0.115,0.037-0.213
				c0-0.042-0.002-0.081-0.006-0.117c-0.005-0.036-0.013-0.066-0.024-0.092c-0.011-0.025-0.027-0.045-0.05-0.06
				c-0.021-0.014-0.049-0.022-0.084-0.022s-0.063,0.008-0.086,0.022c-0.021,0.015-0.037,0.035-0.048,0.061
				c-0.012,0.026-0.02,0.057-0.021,0.092c-0.004,0.036-0.006,0.074-0.006,0.116C32.008-25.333,32.02-25.261,32.045-25.218"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M33.624-25.66c-0.062,0.086-0.114,0.178-0.161,0.276
				c-0.049,0.098-0.091,0.199-0.127,0.302c-0.035,0.104-0.066,0.21-0.089,0.317c-0.024,0.107-0.042,0.212-0.054,0.315h-0.313
				c0.01-0.088,0.026-0.181,0.049-0.278c0.022-0.098,0.053-0.197,0.088-0.297c0.037-0.102,0.082-0.203,0.134-0.305
				c0.053-0.103,0.114-0.203,0.187-0.3h-0.617v-0.257h0.903V-25.66z"
        />
        <rect
          x="55.82"
          y="-26.428"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#5087C7"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M56.734-25.928c0.075,0,0.139,0.012,0.191,0.037
				c0.053,0.026,0.094,0.06,0.125,0.101c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.021,0.108,0.021,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.147,0.081
				c-0.059,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H56.734z M56.643-25.259c0.059,0,0.107-0.018,0.145-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.159-0.058H56.51v0.45H56.643z"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="57.737,-25.929 57.948,-24.884 57.953,-24.884 58.166,-25.929
				58.607,-25.929 58.607,-24.45 58.334,-24.45 58.334,-25.631 58.33,-25.631 58.067,-24.45 57.834,-24.45 57.571,-25.631
				57.567,-25.631 57.567,-24.45 57.293,-24.45 57.293,-25.929 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M59.062-24.868c0,0.034,0.003,0.066,0.009,0.094
				c0.004,0.028,0.016,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.059,0.016,0.096,0.016
				c0.045,0,0.088-0.014,0.123-0.044c0.037-0.029,0.057-0.075,0.057-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.01-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.049-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.049-0.026-0.09-0.057-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.184,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.096,0.124
				c0.022,0.051,0.033,0.111,0.033,0.182v0.041h-0.285c0-0.071-0.013-0.125-0.037-0.162c-0.024-0.039-0.066-0.057-0.125-0.057
				c-0.033,0-0.061,0.004-0.082,0.014c-0.023,0.009-0.041,0.023-0.053,0.038c-0.014,0.016-0.023,0.035-0.027,0.055
				c-0.006,0.021-0.008,0.042-0.008,0.065c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099
				c0.058,0.025,0.104,0.051,0.14,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
				c0.009,0.038,0.013,0.079,0.013,0.125c0,0.156-0.047,0.27-0.137,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.298V-24.868z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M60.328-24.449c0-0.065,0.007-0.125,0.02-0.182
				c0.014-0.057,0.033-0.111,0.061-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.089-0.099,0.142-0.148l0.121-0.112
				c0.033-0.029,0.061-0.056,0.08-0.081c0.021-0.025,0.035-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.021-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.146-0.183c-0.037,0-0.066,0.008-0.088,0.023
				s-0.037,0.036-0.049,0.062c-0.013,0.027-0.021,0.057-0.023,0.092c-0.003,0.034-0.006,0.071-0.006,0.11h-0.285v-0.056
				c0-0.137,0.035-0.242,0.109-0.315c0.074-0.074,0.188-0.11,0.347-0.11c0.149,0,0.26,0.034,0.332,0.103
				c0.071,0.07,0.106,0.168,0.106,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.009,0.041-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.042,0.074-0.07,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.045,0.04-0.079,0.079-0.104,0.116
				c-0.025,0.039-0.041,0.071-0.049,0.099h0.555v0.232H60.328z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M62.234-25.66c-0.061,0.086-0.115,0.178-0.162,0.276
				c-0.048,0.098-0.09,0.199-0.125,0.302c-0.037,0.104-0.066,0.21-0.09,0.317s-0.042,0.212-0.054,0.315H61.49
				c0.012-0.088,0.027-0.181,0.051-0.278c0.021-0.098,0.052-0.197,0.088-0.297c0.035-0.102,0.08-0.203,0.133-0.305
				c0.053-0.103,0.115-0.203,0.188-0.3H61.33v-0.257h0.904V-25.66z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M62.658-25.486h-0.293v-0.195h0.026c0.101,0,0.179-0.018,0.235-0.055
				c0.058-0.038,0.094-0.098,0.108-0.181h0.22v1.467h-0.297V-25.486z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M63.365-25.102c0.043-0.066,0.104-0.108,0.188-0.124v-0.004
				c-0.04-0.01-0.073-0.025-0.102-0.046c-0.025-0.021-0.048-0.044-0.064-0.07c-0.016-0.027-0.028-0.054-0.035-0.083
				c-0.008-0.03-0.01-0.057-0.01-0.083c0-0.144,0.037-0.247,0.115-0.31c0.076-0.063,0.181-0.094,0.315-0.094
				c0.134,0,0.239,0.031,0.315,0.094s0.115,0.166,0.115,0.31c0,0.026-0.004,0.053-0.011,0.083c-0.008,0.029-0.021,0.056-0.037,0.083
				c-0.016,0.026-0.038,0.049-0.065,0.07c-0.026,0.021-0.06,0.036-0.098,0.046v0.004c0.082,0.016,0.145,0.058,0.187,0.124
				c0.04,0.067,0.062,0.146,0.062,0.239c0,0.043-0.005,0.09-0.015,0.14c-0.011,0.052-0.032,0.099-0.065,0.143
				c-0.033,0.045-0.08,0.081-0.142,0.11s-0.144,0.044-0.246,0.044s-0.185-0.015-0.246-0.044s-0.108-0.065-0.142-0.11
				c-0.033-0.044-0.055-0.091-0.065-0.143c-0.01-0.05-0.015-0.097-0.015-0.14C63.305-24.956,63.326-25.035,63.365-25.102
				 M63.61-24.77c0.005,0.032,0.013,0.059,0.026,0.082c0.013,0.022,0.031,0.041,0.053,0.054c0.021,0.013,0.051,0.019,0.083,0.019
				c0.033,0,0.06-0.006,0.083-0.019c0.021-0.013,0.039-0.032,0.053-0.054c0.012-0.023,0.022-0.05,0.027-0.082
				c0.004-0.03,0.007-0.063,0.007-0.098s-0.003-0.069-0.007-0.099c-0.005-0.031-0.016-0.057-0.027-0.08
				c-0.014-0.023-0.031-0.041-0.053-0.054c-0.023-0.013-0.05-0.019-0.083-0.019c-0.032,0-0.062,0.006-0.083,0.019
				c-0.021,0.013-0.04,0.031-0.053,0.054c-0.014,0.023-0.021,0.049-0.026,0.08c-0.006,0.03-0.008,0.064-0.008,0.099
				S63.604-24.8,63.61-24.77 M63.666-25.365c0.026,0.033,0.063,0.05,0.106,0.05s0.079-0.017,0.104-0.05
				c0.027-0.033,0.041-0.085,0.041-0.155c0-0.135-0.049-0.202-0.146-0.202s-0.146,0.067-0.146,0.202
				C63.627-25.45,63.641-25.398,63.666-25.365"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M36.997-25.928c0.075,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.01,0.143-0.033,0.199c-0.023,0.057-0.055,0.103-0.096,0.139s-0.09,0.063-0.147,0.081
				c-0.058,0.017-0.123,0.027-0.192,0.027h-0.16v0.59h-0.299v-1.479H36.997z M36.906-25.259c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.056-0.092,0.056-0.168c0-0.074-0.017-0.131-0.049-0.17c-0.031-0.039-0.084-0.058-0.158-0.058h-0.125v0.45H36.906z
				"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          points="38,-25.929 38.211,-24.884 38.217,-24.884 38.43,-25.929
				38.871,-25.929 38.871,-24.45 38.598,-24.45 38.598,-25.631 38.594,-25.631 38.33,-24.45 38.098,-24.45 37.834,-25.631
				37.83,-25.631 37.83,-24.45 37.557,-24.45 37.557,-25.929 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M39.324-24.868c0,0.034,0.004,0.066,0.01,0.094
				c0.004,0.028,0.015,0.052,0.029,0.072c0.016,0.019,0.037,0.035,0.063,0.045c0.025,0.012,0.059,0.016,0.096,0.016
				c0.045,0,0.088-0.014,0.123-0.044c0.036-0.029,0.057-0.075,0.057-0.137c0-0.034-0.006-0.062-0.015-0.086
				c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.032-0.016-0.075-0.032-0.125-0.048
				c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092s-0.058-0.076-0.071-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.08-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.146,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.071-0.014-0.125-0.038-0.162c-0.024-0.039-0.065-0.057-0.124-0.057
				c-0.033,0-0.061,0.004-0.083,0.014c-0.022,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.123,0.086l0.23,0.099
				c0.057,0.025,0.104,0.051,0.139,0.078c0.037,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.037,0.065,0.045,0.102
				c0.009,0.038,0.013,0.079,0.013,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.217,0.106-0.379,0.106
				c-0.168,0-0.289-0.036-0.361-0.109c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.297V-24.868z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M40.592-24.449c0-0.065,0.006-0.125,0.02-0.182s0.033-0.111,0.061-0.162
				c0.026-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.121-0.112c0.033-0.029,0.061-0.056,0.079-0.081
				c0.021-0.025,0.036-0.05,0.047-0.075s0.019-0.051,0.022-0.081c0.002-0.029,0.005-0.062,0.005-0.099
				c0-0.122-0.048-0.183-0.146-0.183c-0.037,0-0.066,0.008-0.088,0.023s-0.037,0.036-0.05,0.062
				c-0.013,0.027-0.021,0.057-0.022,0.092c-0.004,0.034-0.006,0.071-0.006,0.11h-0.285v-0.056c0-0.137,0.035-0.242,0.109-0.315
				c0.073-0.074,0.188-0.11,0.346-0.11c0.149,0,0.26,0.034,0.332,0.103c0.071,0.07,0.107,0.168,0.107,0.297
				c0,0.047-0.004,0.09-0.012,0.131c-0.01,0.041-0.021,0.081-0.039,0.117c-0.018,0.038-0.043,0.074-0.071,0.109
				c-0.028,0.035-0.063,0.072-0.106,0.109l-0.164,0.154c-0.045,0.04-0.08,0.079-0.104,0.116c-0.024,0.039-0.041,0.071-0.048,0.099
				h0.554v0.232H40.592z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M41.586-24.449c0-0.065,0.007-0.125,0.02-0.182
				c0.014-0.057,0.033-0.111,0.061-0.162c0.027-0.051,0.061-0.101,0.102-0.15c0.041-0.05,0.088-0.099,0.142-0.148l0.12-0.112
				c0.033-0.029,0.061-0.056,0.08-0.081c0.021-0.025,0.035-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.021-0.081
				c0.004-0.029,0.006-0.062,0.006-0.099c0-0.122-0.049-0.183-0.146-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.021,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.056
				c0-0.137,0.036-0.242,0.109-0.315c0.074-0.074,0.188-0.11,0.347-0.11c0.149,0,0.26,0.034,0.332,0.103
				c0.071,0.07,0.106,0.168,0.106,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.009,0.041-0.021,0.081-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.07,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
				c-0.025,0.039-0.041,0.071-0.049,0.099h0.555v0.232H41.586z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#FFFFFF"
          d="M42.634-25.102c0.042-0.066,0.104-0.108,0.187-0.124v-0.004
				c-0.039-0.01-0.072-0.025-0.1-0.046s-0.049-0.044-0.066-0.07c-0.016-0.027-0.027-0.054-0.033-0.083
				c-0.008-0.03-0.012-0.057-0.012-0.083c0-0.144,0.039-0.247,0.115-0.31s0.182-0.094,0.316-0.094c0.134,0,0.238,0.031,0.316,0.094
				c0.076,0.063,0.115,0.166,0.115,0.31c0,0.026-0.005,0.053-0.012,0.083c-0.008,0.029-0.02,0.056-0.037,0.083
				c-0.016,0.026-0.037,0.049-0.064,0.07s-0.061,0.036-0.1,0.046v0.004c0.083,0.016,0.145,0.058,0.188,0.124
				c0.041,0.067,0.062,0.146,0.062,0.239c0,0.043-0.005,0.09-0.015,0.14c-0.01,0.052-0.032,0.099-0.066,0.143
				c-0.031,0.045-0.08,0.081-0.141,0.11c-0.063,0.029-0.145,0.044-0.246,0.044s-0.184-0.015-0.246-0.044s-0.107-0.065-0.143-0.11
				c-0.031-0.044-0.053-0.091-0.064-0.143c-0.01-0.05-0.016-0.097-0.016-0.14C42.572-24.956,42.594-25.035,42.634-25.102
				 M42.879-24.77c0.004,0.032,0.014,0.059,0.025,0.082c0.014,0.022,0.031,0.041,0.054,0.054c0.022,0.013,0.05,0.019,0.083,0.019
				s0.061-0.006,0.083-0.019c0.022-0.013,0.04-0.032,0.053-0.054c0.013-0.023,0.022-0.05,0.027-0.082
				c0.004-0.03,0.007-0.063,0.007-0.098s-0.003-0.069-0.007-0.099c-0.005-0.031-0.015-0.057-0.027-0.08s-0.03-0.041-0.053-0.054
				c-0.022-0.013-0.05-0.019-0.083-0.019s-0.061,0.006-0.083,0.019c-0.022,0.013-0.04,0.031-0.054,0.054
				c-0.012,0.023-0.021,0.049-0.025,0.08c-0.006,0.03-0.008,0.064-0.008,0.099S42.873-24.8,42.879-24.77 M42.936-25.365
				c0.025,0.033,0.062,0.05,0.105,0.05s0.079-0.017,0.105-0.05c0.026-0.033,0.039-0.085,0.039-0.155
				c0-0.135-0.047-0.202-0.145-0.202s-0.145,0.067-0.145,0.202C42.896-25.45,42.909-25.398,42.936-25.365"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M12.737,75.938c0.592,0,0.973,0.391,0.973,0.99
				c0,0.594-0.381,0.986-0.973,0.986c-0.588,0-0.969-0.393-0.969-0.986C11.768,76.329,12.149,75.938,12.737,75.938 M12.737,77.526
				c0.352,0,0.457-0.301,0.457-0.598c0-0.303-0.105-0.604-0.457-0.604c-0.349,0-0.454,0.301-0.454,0.604
				C12.283,77.226,12.388,77.526,12.737,77.526"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M14.695,75.987h0.378v0.346h-0.378v0.93c0,0.174,0.044,0.217,0.218,0.217
				c0.054,0,0.105-0.004,0.16-0.014v0.402c-0.087,0.014-0.2,0.018-0.302,0.018c-0.315,0-0.591-0.072-0.591-0.445v-1.107h-0.312
				v-0.346h0.312v-0.563h0.515V75.987z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M15.744,77.052c0.014,0.326,0.174,0.475,0.46,0.475
				c0.208,0,0.374-0.127,0.407-0.242h0.454c-0.146,0.443-0.454,0.631-0.879,0.631c-0.591,0-0.957-0.406-0.957-0.986
				c0-0.563,0.388-0.992,0.957-0.992c0.639,0,0.947,0.539,0.911,1.115H15.744z M16.582,76.726c-0.047-0.262-0.16-0.4-0.41-0.4
				c-0.327,0-0.421,0.254-0.428,0.4H16.582z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M17.8,77.255c0.004,0.225,0.193,0.316,0.396,0.316
				c0.149,0,0.337-0.059,0.337-0.24c0-0.156-0.214-0.211-0.584-0.291c-0.298-0.064-0.595-0.17-0.595-0.5
				c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.137,0.828,0.598h-0.49c-0.015-0.199-0.167-0.254-0.352-0.254
				c-0.116,0-0.287,0.021-0.287,0.174c0,0.186,0.29,0.211,0.584,0.279c0.302,0.068,0.596,0.178,0.596,0.525
				c0,0.494-0.429,0.654-0.857,0.654c-0.436,0-0.86-0.164-0.882-0.66H17.8z"
        />
        <rect
          x="8.943"
          y="80.468"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M12.047,79.03h0.89c0.798,0,1.244,0.398,1.244,1.232
				c0,0.867-0.381,1.359-1.244,1.359h-0.89V79.03z M12.392,81.331h0.573c0.236,0,0.871-0.066,0.871-1.021
				c0-0.615-0.228-0.988-0.864-0.988h-0.58V81.331z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M15.359,79.702c0.595,0,0.911,0.432,0.911,0.982
				c0,0.547-0.316,0.98-0.911,0.98s-0.911-0.434-0.911-0.98C14.448,80.134,14.764,79.702,15.359,79.702 M15.359,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
				C14.775,81.138,15.036,81.392,15.359,81.392"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M17.64,79.745h0.291v0.297h0.006c0.131-0.232,0.341-0.342,0.61-0.342
				c0.494,0,0.646,0.283,0.646,0.688v1.232h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
				H17.64V79.745z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M20.466,79.702c0.595,0,0.911,0.432,0.911,0.982
				c0,0.547-0.316,0.98-0.911,0.98s-0.911-0.434-0.911-0.98C19.555,80.134,19.871,79.702,20.466,79.702 M20.466,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
				C19.882,81.138,20.143,81.392,20.466,81.392"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M22.167,79.745h0.374v0.271h-0.374v1.166c0,0.141,0.039,0.166,0.232,0.166
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.271h0.319v-0.563h0.309V79.745z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M25.552,81.614c-0.055,0.031-0.123,0.049-0.222,0.049
				c-0.16,0-0.261-0.086-0.261-0.289c-0.17,0.199-0.399,0.289-0.661,0.289c-0.34,0-0.62-0.15-0.62-0.525
				c0-0.424,0.316-0.516,0.635-0.576c0.341-0.066,0.632-0.043,0.632-0.275c0-0.27-0.222-0.313-0.418-0.313
				c-0.261,0-0.453,0.08-0.468,0.355H23.86c0.018-0.465,0.378-0.629,0.795-0.629c0.338,0,0.704,0.078,0.704,0.516v0.967
				c0,0.145,0,0.209,0.098,0.209c0.026,0,0.055-0.002,0.095-0.018V81.614z M25.051,80.651c-0.12,0.088-0.352,0.092-0.559,0.127
				c-0.203,0.037-0.378,0.109-0.378,0.338c0,0.203,0.175,0.275,0.364,0.275c0.406,0,0.573-0.254,0.573-0.424V80.651z"
        />
        <rect
          x="25.855"
          y="79.03"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          width="0.308"
          height="2.59"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M27.07,79.745h0.374v0.271H27.07v1.166c0,0.141,0.04,0.166,0.232,0.166
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.271h0.319v-0.563h0.309V79.745z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M29.385,81.026c-0.083,0.412-0.381,0.639-0.802,0.639
				c-0.599,0-0.882-0.414-0.9-0.988c0-0.563,0.37-0.975,0.882-0.975c0.663,0,0.867,0.619,0.849,1.072h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.365H29.385z M29.087,80.503
				c-0.014-0.293-0.236-0.529-0.54-0.529c-0.323,0-0.516,0.242-0.538,0.529H29.087z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M29.721,79.745h0.291v0.396h0.006c0.149-0.303,0.356-0.451,0.69-0.441v0.328
				c-0.497,0-0.678,0.283-0.678,0.758v0.834h-0.309V79.745z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M33.609,81.614c-0.055,0.031-0.123,0.049-0.221,0.049
				c-0.16,0-0.262-0.086-0.262-0.289c-0.17,0.199-0.398,0.289-0.66,0.289c-0.341,0-0.621-0.15-0.621-0.525
				c0-0.424,0.316-0.516,0.636-0.576c0.34-0.066,0.631-0.043,0.631-0.275c0-0.27-0.223-0.313-0.418-0.313
				c-0.262,0-0.453,0.08-0.469,0.355h-0.308c0.018-0.465,0.378-0.629,0.795-0.629c0.337,0,0.704,0.078,0.704,0.516v0.967
				c0,0.145,0,0.209,0.098,0.209c0.025,0,0.055-0.002,0.094-0.018V81.614z M33.109,80.651c-0.119,0.088-0.353,0.092-0.56,0.127
				c-0.202,0.037-0.377,0.109-0.377,0.338c0,0.203,0.175,0.275,0.363,0.275c0.405,0,0.573-0.254,0.573-0.424V80.651z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M33.885,79.745h0.29v0.396h0.007c0.148-0.303,0.355-0.451,0.689-0.441v0.328
				c-0.496,0-0.678,0.283-0.678,0.758v0.834h-0.309V79.745z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M35.531,79.745h0.375v0.271h-0.375v1.166c0,0.141,0.039,0.166,0.232,0.166
				h0.143v0.271H35.67c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.318v-0.271h0.318v-0.563h0.309V79.745z"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          points="38.104,81.62 37.777,81.62 37.396,80.13 37.389,80.13 37.012,81.62
				36.678,81.62 36.074,79.745 36.416,79.745 36.841,81.28 36.849,81.28 37.226,79.745 37.564,79.745 37.955,81.28 37.963,81.28
				38.383,79.745 38.703,79.745 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M39.805,79.702c0.595,0,0.91,0.432,0.91,0.982c0,0.547-0.315,0.98-0.91,0.98
				c-0.596,0-0.912-0.434-0.912-0.98C38.893,80.134,39.209,79.702,39.805,79.702 M39.805,81.392c0.322,0,0.584-0.254,0.584-0.707
				c0-0.457-0.262-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711C39.221,81.138,39.481,81.392,39.805,81.392"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M41.066,79.745h0.289v0.396h0.008c0.148-0.303,0.355-0.451,0.689-0.441
				v0.328c-0.496,0-0.679,0.283-0.679,0.758v0.834h-0.308V79.745z"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          points="42.303,79.03 42.611,79.03 42.611,80.565 43.468,79.745
				43.882,79.745 43.138,80.427 43.936,81.62 43.544,81.62 42.9,80.642 42.611,80.909 42.611,81.62 42.303,81.62 			"
        />
        <rect
          x="8.943"
          y="84.224"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M14.116,84.44c0,0.654-0.374,0.998-1.02,0.998
				c-0.668,0-1.063-0.309-1.063-0.998v-1.654h0.345v1.654c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.654
				h0.345V84.44z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M14.804,84.786c0.011,0.275,0.25,0.363,0.505,0.363
				c0.192,0,0.454-0.045,0.454-0.281c0-0.238-0.306-0.279-0.614-0.348c-0.305-0.068-0.614-0.17-0.614-0.529
				c0-0.379,0.374-0.533,0.701-0.533c0.414,0,0.744,0.129,0.769,0.588h-0.308c-0.022-0.24-0.233-0.316-0.436-0.316
				c-0.185,0-0.399,0.051-0.399,0.24c0,0.221,0.326,0.258,0.613,0.326c0.309,0.068,0.613,0.172,0.613,0.533
				c0,0.447-0.417,0.592-0.798,0.592c-0.421,0-0.777-0.172-0.794-0.635H14.804z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M18.028,84.782c-0.083,0.414-0.38,0.639-0.802,0.639
				c-0.598,0-0.882-0.414-0.9-0.988c0-0.561,0.371-0.975,0.882-0.975c0.664,0,0.868,0.619,0.849,1.074h-1.404
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.367H18.028z M17.731,84.259
				c-0.015-0.293-0.236-0.529-0.541-0.529c-0.323,0-0.515,0.242-0.537,0.529H17.731z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M19.813,83.501h0.374v0.271h-0.374v1.166c0,0.143,0.04,0.166,0.232,0.166
				h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319v-0.563h0.309V83.501z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M20.854,83.163h-0.308v-0.377h0.308V83.163z M20.545,83.501h0.308v1.877
				h-0.308V83.501z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M22.951,85.218c0,0.609-0.28,0.922-0.871,0.922
				c-0.352,0-0.759-0.143-0.777-0.551h0.309c0.014,0.225,0.283,0.303,0.486,0.303c0.403,0,0.562-0.289,0.562-0.711v-0.123h-0.007
				c-0.102,0.229-0.348,0.342-0.584,0.342c-0.559,0-0.838-0.439-0.838-0.951c0-0.443,0.217-0.99,0.867-0.99
				c0.236,0,0.446,0.105,0.559,0.311h0.003v-0.268h0.291V85.218z M22.646,84.401c0-0.332-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.322-0.556,0.684c0,0.336,0.124,0.713,0.53,0.713C22.494,85.126,22.646,84.753,22.646,84.401"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M23.414,82.786h0.309v0.99h0.007c0.102-0.225,0.366-0.318,0.591-0.318
				c0.494,0,0.646,0.281,0.646,0.686v1.234h-0.308v-1.271c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.061
				h-0.309V82.786z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M25.858,83.501h0.374v0.271h-0.374v1.166c0,0.143,0.04,0.166,0.232,0.166
				h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195H25.23v-0.271h0.319v-0.563h0.309V83.501z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M28.391,83.458c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.479,83.89,27.795,83.458,28.391,83.458 M28.391,85.149
				c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
				C27.807,84.896,28.067,85.149,28.391,85.149"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          points="30.509,85.378 30.178,85.378 29.482,83.501 29.827,83.501
				30.353,85.065 30.36,85.065 30.872,83.501 31.195,83.501 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M33.077,84.782c-0.083,0.414-0.381,0.639-0.802,0.639
				c-0.599,0-0.882-0.414-0.9-0.988c0-0.561,0.37-0.975,0.883-0.975c0.662,0,0.866,0.619,0.848,1.074h-1.404
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.433-0.123,0.485-0.367H33.077z M32.779,84.259
				c-0.014-0.293-0.236-0.529-0.54-0.529c-0.323,0-0.516,0.242-0.538,0.529H32.779z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M33.413,83.501h0.291v0.396h0.006c0.149-0.301,0.356-0.451,0.69-0.439v0.326
				c-0.498,0-0.678,0.283-0.678,0.758v0.836h-0.31V83.501z"
        />
        <rect
          x="34.65"
          y="82.786"
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          width="0.309"
          height="2.59"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M36.246,83.458c0.596,0,0.911,0.432,0.911,0.984
				c0,0.547-0.315,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C35.335,83.89,35.651,83.458,36.246,83.458 M36.246,85.149
				c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.322,0-0.584,0.254-0.584,0.713
				C35.662,84.896,35.924,85.149,36.246,85.149"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M38.793,84.104c-0.049-0.236-0.211-0.375-0.465-0.375
				c-0.446,0-0.584,0.352-0.584,0.732c0,0.346,0.156,0.688,0.548,0.688c0.297,0,0.475-0.174,0.515-0.457h0.316
				c-0.069,0.457-0.356,0.729-0.827,0.729c-0.573,0-0.879-0.398-0.879-0.959c0-0.563,0.29-1.004,0.886-1.004
				c0.424,0,0.766,0.199,0.809,0.646H38.793z"
        />
        <polygon
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          points="39.484,82.786 39.793,82.786 39.793,84.321 40.649,83.501
				41.063,83.501 40.319,84.183 41.117,85.378 40.726,85.378 40.082,84.397 39.793,84.667 39.793,85.378 39.484,85.378 			"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M41.676,83.163h-0.31v-0.377h0.31V83.163z M41.366,83.501h0.31v1.877h-0.31
				V83.501z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M42.154,83.501h0.291v0.297h0.006c0.131-0.232,0.34-0.34,0.609-0.34
				c0.494,0,0.646,0.281,0.646,0.686v1.234h-0.309v-1.271c0-0.227-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.061
				h-0.309V83.501z"
        />
        <path
          clipPath="url(#VLOGGING_SVGID_90_)"
          fill="#EC008C"
          d="M45.789,85.218c0,0.609-0.279,0.922-0.871,0.922
				c-0.352,0-0.759-0.143-0.777-0.551h0.31c0.014,0.225,0.282,0.303,0.485,0.303c0.403,0,0.563-0.289,0.563-0.711v-0.123h-0.007
				c-0.103,0.229-0.349,0.342-0.584,0.342c-0.56,0-0.839-0.439-0.839-0.951c0-0.443,0.218-0.99,0.867-0.99
				c0.236,0,0.447,0.105,0.56,0.311h0.003v-0.268h0.291V85.218z M45.484,84.401c0-0.332-0.146-0.672-0.533-0.672
				c-0.396,0-0.557,0.322-0.557,0.684c0,0.336,0.125,0.713,0.531,0.713S45.484,84.753,45.484,84.401"
        />
      </g>
    </g>
  );
};

const Photography = () => {
  return (
    <g id="Photography">
      <g>
        <defs>
          <rect
            id="PHOTOGRAPHY_SVGID_91_"
            x="0.029"
            y="0.001"
            width="61.687"
            height="61.688"
          />
        </defs>
        <clipPath id="PHOTOGRAPHY_SVGID_92_">
          <use xlinkHref="#PHOTOGRAPHY_SVGID_91_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M6.209,60.813c-2.925,0-5.304-2.379-5.304-5.305V6.182
				c0-2.924,2.379-5.304,5.304-5.304h49.327c2.926,0,5.305,2.38,5.305,5.304v49.327c0,2.926-2.379,5.305-5.305,5.305H6.209z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M55.536,1.137c2.781,0,5.045,2.263,5.045,5.045v49.327
				c0,2.781-2.264,5.045-5.045,5.045H6.209c-2.782,0-5.045-2.264-5.045-5.045V6.182c0-2.782,2.263-5.045,5.045-5.045H55.536
				 M55.536,0.619H6.209c-3.059,0-5.563,2.504-5.563,5.563v49.327c0,3.061,2.504,5.563,5.563,5.563h49.327
				c3.061,0,5.563-2.502,5.563-5.563V6.182C61.099,3.123,58.597,0.619,55.536,0.619"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M14.171,7.155c0.898,0,1.463,0.553,1.463,1.373
				c0,0.84-0.583,1.393-1.504,1.393h-0.94v1.219h-0.981V7.155H14.171z M14.059,9.1c0.38,0,0.612-0.22,0.612-0.559
				c0-0.321-0.232-0.547-0.612-0.547H13.19V9.1H14.059z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M19.075,9.308v1.831h-0.946V9.51c0-0.351-0.207-0.589-0.517-0.589
				C17.243,8.921,17,9.177,17,9.754v1.385h-0.951V6.858H17v1.581c0.231-0.238,0.541-0.374,0.922-0.374
				C18.61,8.065,19.075,8.559,19.075,9.308"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M22.788,9.647c0,0.946-0.75,1.576-1.635,1.576
				c-0.904,0-1.654-0.63-1.654-1.576c0-0.939,0.75-1.57,1.654-1.57C22.038,8.077,22.788,8.708,22.788,9.647 M21.872,9.647
				c0-0.464-0.351-0.773-0.719-0.773c-0.387,0-0.738,0.309-0.738,0.773c0,0.47,0.351,0.773,0.738,0.773
				C21.521,10.42,21.872,10.117,21.872,9.647"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M25.468,11.008c-0.232,0.125-0.476,0.22-0.815,0.22
				c-0.743,0-1.189-0.404-1.189-1.189V8.91h-0.541V8.155h0.541v-0.88h0.951v0.88h0.88V8.91h-0.88v1.035
				c0,0.309,0.149,0.434,0.399,0.434c0.131,0,0.309-0.048,0.434-0.119L25.468,11.008z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M28.864,9.647c0,0.946-0.75,1.576-1.636,1.576
				c-0.903,0-1.653-0.63-1.653-1.576c0-0.939,0.75-1.57,1.653-1.57C28.114,8.077,28.864,8.708,28.864,9.647 M27.948,9.647
				c0-0.464-0.351-0.773-0.72-0.773c-0.386,0-0.737,0.309-0.737,0.773c0,0.47,0.351,0.773,0.737,0.773
				C27.597,10.42,27.948,10.117,27.948,9.647"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M32.405,10.842c0,0.921-0.703,1.427-1.672,1.427
				c-0.684,0-1.207-0.238-1.499-0.72l0.791-0.428c0.226,0.268,0.392,0.369,0.701,0.369c0.435,0,0.744-0.255,0.744-0.725v-0.084
				c-0.214,0.185-0.511,0.286-0.898,0.286c-0.779,0-1.439-0.618-1.439-1.463c0-0.826,0.66-1.439,1.439-1.439
				c0.387,0,0.684,0.101,0.898,0.285V8.154h0.935V10.842z M31.471,9.504c0-0.398-0.316-0.678-0.696-0.678
				c-0.416,0-0.713,0.28-0.713,0.678c0,0.399,0.297,0.684,0.713,0.684C31.155,10.188,31.471,9.903,31.471,9.504"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M35.239,8.137l-0.041,0.952h-0.174c-0.666,0-1.029,0.344-1.029,1.117v0.934
				h-0.951V8.155h0.951v0.571c0.215-0.357,0.541-0.613,1.029-0.613C35.108,8.113,35.167,8.119,35.239,8.137"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M38.748,11.14h-0.951v-0.238c-0.231,0.208-0.541,0.327-0.927,0.327
				c-0.791,0-1.457-0.636-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.386,0,0.695,0.119,0.927,0.327V8.154h0.951V11.14z
				 M37.797,9.647c0-0.458-0.345-0.773-0.731-0.773c-0.416,0-0.719,0.315-0.719,0.773c0,0.464,0.303,0.773,0.719,0.773
				C37.452,10.42,37.797,10.111,37.797,9.647"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M42.722,9.647c0,0.946-0.666,1.582-1.457,1.582
				c-0.387,0-0.695-0.119-0.928-0.327v1.249h-0.951V8.154h0.951v0.238c0.232-0.208,0.541-0.327,0.928-0.327
				C42.056,8.065,42.722,8.708,42.722,9.647 M41.788,9.647c0-0.458-0.303-0.773-0.72-0.773c-0.386,0-0.731,0.315-0.731,0.773
				c0,0.464,0.346,0.773,0.731,0.773C41.485,10.42,41.788,10.111,41.788,9.647"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M46.212,9.308v1.831h-0.947V9.51c0-0.351-0.207-0.589-0.516-0.589
				c-0.369,0-0.613,0.256-0.613,0.833v1.385h-0.951V6.858h0.951v1.581c0.232-0.238,0.541-0.374,0.922-0.374
				C45.747,8.065,46.212,8.559,46.212,9.308"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="48.142,12.15 47.114,12.15 47.595,11.039 46.358,8.147
				47.382,8.147 48.089,9.891 48.845,8.147 49.866,8.147 			"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#88155D"
          points="13.195,41.481 35.114,58.554 58.366,58.554 59.341,49.589
				47.456,40.995 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          d="M45.866,42.241l-30.993-0.043c-1.292-0.002-2.337-1.051-2.335-2.342l0.025-17.944
				c0.002-1.291,1.05-2.336,2.342-2.334l30.992,0.043c1.291,0.002,2.336,1.049,2.334,2.341l-0.023,17.943
				C48.206,41.196,47.157,42.243,45.866,42.241"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          d="M40.835,26.954L28.03,26.936l1.776-10.29c0.113-0.653,0.68-1.13,1.343-1.129l6.598,0.009
				c0.662,0.001,1.229,0.479,1.34,1.133L40.835,26.954z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#475354"
          d="M18.949,39.429h-0.557c-0.526-0.002-0.952-0.43-0.951-0.956l0.02-15.145
				c0.001-0.527,0.429-0.952,0.955-0.952l0.557,0.001c0.526,0.001,0.952,0.428,0.952,0.955l-0.022,15.144
				C19.902,39.003,19.475,39.429,18.949,39.429"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M24.826,23.237c-0.001,0.432-0.351,0.782-0.783,0.782
				c-0.433-0.001-0.783-0.352-0.782-0.784c0-0.432,0.352-0.782,0.784-0.781C24.477,22.455,24.827,22.806,24.826,23.237"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          d="M45.302,20.016l-3.473-0.005c-0.186,0-0.336-0.151-0.336-0.337l0.002-1.079
				c0-0.186,0.151-0.337,0.338-0.336l3.471,0.005c0.186,0,0.336,0.151,0.336,0.337l-0.002,1.079
				C45.638,19.866,45.485,20.016,45.302,20.016"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          d="M44.974,33.126c-0.008,5.824-4.736,10.539-10.561,10.531
				c-5.826-0.008-10.54-4.736-10.532-10.56c0.008-5.825,4.736-10.54,10.561-10.532C40.267,22.574,44.981,27.302,44.974,33.126"
        />

        <circle
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="none"
          stroke="#475354"
          strokeWidth="1.486"
          strokeMiterlimit="10"
          cx="34.427"
          cy="33.112"
          r="10.546"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#475354"
          d="M42.309,33.122c-0.007,4.353-3.539,7.877-7.892,7.871
				c-4.354-0.006-7.876-3.539-7.871-7.893c0.006-4.352,3.54-7.876,7.893-7.87C38.792,25.236,42.314,28.771,42.309,33.122"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#00B4AB"
          d="M30.368,31.074l4.06,2.038l-1.489-4.292
				C31.816,29.21,30.896,30.025,30.368,31.074"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="none"
          stroke="#00B4AB"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M30.368,31.074
				l4.06,2.038l-1.489-4.292C31.816,29.21,30.896,30.025,30.368,31.074z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          d="M36.571,33.112c0,1.183-0.961,2.143-2.145,2.143c-1.183,0-2.143-0.96-2.143-2.143
				c0-1.185,0.96-2.144,2.143-2.144C35.61,30.969,36.571,31.928,36.571,33.112"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#F37321"
          d="M38.979,34.675c-0.467,1.359-1.522,2.441-2.864,2.944l1.075,2.874
				c2.197-0.822,3.927-2.597,4.69-4.822L38.979,34.675z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M38.979,34.675
				c-0.467,1.359-1.522,2.441-2.864,2.944l1.075,2.874c2.197-0.822,3.927-2.597,4.69-4.822L38.979,34.675z"
        />

        <circle
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.623"
          strokeMiterlimit="10"
          cx="34.428"
          cy="33.112"
          r="4.817"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M34.015,33.183c-0.001,0.336-0.273,0.609-0.609,0.609
				c-0.338,0-0.609-0.273-0.609-0.611c0-0.336,0.273-0.607,0.611-0.607C33.743,32.573,34.016,32.847,34.015,33.183"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EF97A8"
          d="M56.038,4.408c0.689,0,1.271,0.582,1.271,1.271v50.334
				c0,0.688-0.582,1.27-1.271,1.27H5.706c-0.689,0-1.271-0.582-1.271-1.27V5.679c0-0.689,0.582-1.271,1.271-1.271H56.038
				 M56.038,0.001H5.706c-3.122,0-5.677,2.555-5.677,5.677v50.334c0,3.121,2.555,5.676,5.677,5.676h50.332
				c3.123,0,5.678-2.555,5.678-5.676V5.679C61.716,2.557,59.161,0.001,56.038,0.001"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#5087C7"
          d="M30.883,55.362c-2.638,0-4.773-2.137-4.773-4.771
				c0-2.637,2.135-4.773,4.773-4.773c2.638,0,4.774,2.137,4.774,4.773C35.657,53.226,33.521,55.362,30.883,55.362"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M30.883,45.817c2.638,0,4.774,2.137,4.774,4.773
				c0,2.635-2.136,4.771-4.774,4.771c-2.638,0-4.773-2.137-4.773-4.771C26.11,47.954,28.246,45.817,30.883,45.817 M30.883,45.405
				c-2.859,0-5.185,2.326-5.185,5.186c0,2.857,2.326,5.184,5.185,5.184c2.86,0,5.186-2.326,5.186-5.184
				C36.069,47.731,33.743,45.405,30.883,45.405"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M33.378,48.646c-0.239,0-0.465,0.107-0.62,0.25l-0.013-0.012
				c0.071-0.119,0.168-0.346,0.168-0.717c0-0.883-0.884-1.551-2.029-1.551c-1.145,0-2.041,0.668-2.041,1.551
				c0,0.371,0.12,0.598,0.179,0.717l-0.012,0.012c-0.155-0.143-0.382-0.25-0.619-0.25c-0.73,0-1.457,0.703-1.457,2.016
				c0,1.289,0.716,2.018,1.492,2.018c0.716,0,1.217-0.395,1.67-0.848c-0.071-0.109-0.179-0.217-0.298-0.217
				c-0.119,0-0.251,0.086-0.477,0.264c-0.299,0.229-0.561,0.359-0.908,0.359c-0.608,0-0.87-0.682-0.87-1.576
				c0-0.955,0.25-1.586,0.895-1.586c0.286,0,0.476,0.082,0.668,0.262c0.286,0.262,0.382,0.334,0.513,0.334
				c0.096,0,0.155-0.061,0.179-0.096c-0.214-0.215-0.525-0.668-0.525-1.252c0-0.668,0.561-1.133,1.611-1.133
				c1.05,0,1.611,0.465,1.611,1.133c0,0.584-0.311,1.037-0.525,1.252c0.024,0.035,0.084,0.096,0.179,0.096
				c0.132,0,0.228-0.072,0.513-0.334c0.18-0.18,0.383-0.262,0.668-0.262c0.645,0,0.896,0.631,0.896,1.586
				c0,0.895-0.263,1.576-0.871,1.576c-0.345,0-0.62-0.131-0.907-0.359c-0.227-0.178-0.358-0.264-0.477-0.264
				c-0.119,0-0.226,0.107-0.299,0.217c0.443,0.453,0.956,0.848,1.671,0.848c0.776,0,1.491-0.729,1.491-2.018
				C34.833,49.349,34.104,48.646,33.378,48.646"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M31.683,48.622h-0.537c-0.035,0-0.059-0.025-0.071-0.049l-0.168-0.514
				c-0.011-0.035-0.023-0.035-0.035,0l-0.167,0.514c-0.013,0.035-0.036,0.049-0.06,0.049h-0.537c-0.024,0-0.036,0.01-0.012,0.021
				l0.442,0.324c0.023,0.023,0.023,0.059,0.011,0.082l-0.167,0.502c-0.011,0.023-0.011,0.047,0.025,0.023l0.441-0.309
				c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.023,0.036,0.012,0.023-0.012L31.23,49.05c-0.012-0.035,0-0.072,0.024-0.082
				l0.44-0.324C31.719,48.632,31.707,48.622,31.683,48.622"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M31.277,52.151c-0.226-0.57-0.406-0.965-0.406-1.441
				c0-0.205,0.06-0.383,0.096-0.479c0.048-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.168-0.322,0.313
				c-0.072,0.117-0.155,0.346-0.155,0.523c0,0.336,0.108,0.693,0.179,0.906c0.132,0.371,0.251,0.801,0.251,1.207
				c0,0.645-0.287,1.084-0.799,1.479c0.047,0.037,0.166,0.096,0.31,0.096c0.5,0,1.097-0.561,1.097-1.324
				C31.54,52.819,31.409,52.511,31.277,52.151"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-2.679-25.929c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.679z M-2.77-25.26c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.45H-2.77z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="-1.676,-25.929 -1.465,-24.884 -1.46,-24.884 -1.247,-25.929
				-0.806,-25.929 -0.806,-24.449 -1.079,-24.449 -1.079,-25.631 -1.083,-25.631 -1.346,-24.449 -1.579,-24.449 -1.842,-25.631
				-1.846,-25.631 -1.846,-24.449 -2.12,-24.449 -2.12,-25.929 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-0.352-24.869c0,0.034,0.003,0.066,0.008,0.094
				c0.005,0.028,0.016,0.053,0.031,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.057,0.016,0.096,0.016
				c0.045,0,0.086-0.013,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086
				c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.021-0.124-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0c0-0.071-0.012-0.124-0.037-0.162c-0.025-0.038-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.014c-0.022,0.01-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.169,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-2.679-23.986c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.679z M-2.77-23.317c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.45H-2.77z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-1.86-23.622v0.149h0.004c0.029-0.054,0.064-0.097,0.106-0.129
				c0.041-0.033,0.092-0.05,0.151-0.05c0.008,0,0.017,0.001,0.026,0.001c0.009,0.002,0.018,0.003,0.026,0.004v0.274
				c-0.015-0.002-0.03-0.003-0.045-0.003c-0.014-0.001-0.029-0.001-0.044-0.001c-0.025,0-0.05,0.003-0.075,0.01
				s-0.047,0.018-0.068,0.034s-0.037,0.037-0.05,0.062c-0.012,0.026-0.019,0.057-0.019,0.094v0.67h-0.286v-1.115H-1.86z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-1.458-23.317c0.015-0.073,0.04-0.135,0.074-0.184
				c0.035-0.049,0.08-0.086,0.136-0.112c0.056-0.025,0.125-0.039,0.206-0.039c0.086,0,0.157,0.011,0.214,0.035
				c0.057,0.025,0.101,0.061,0.134,0.111c0.034,0.05,0.057,0.111,0.071,0.185c0.014,0.073,0.021,0.159,0.021,0.257
				c0,0.095-0.008,0.179-0.023,0.253c-0.015,0.072-0.04,0.134-0.075,0.183c-0.035,0.049-0.08,0.085-0.136,0.109
				c-0.056,0.023-0.124,0.034-0.206,0.034c-0.085,0.002-0.157-0.009-0.213-0.031c-0.057-0.023-0.102-0.059-0.135-0.108
				c-0.033-0.049-0.056-0.11-0.07-0.185c-0.014-0.073-0.021-0.159-0.021-0.255C-1.481-23.159-1.473-23.244-1.458-23.317
				 M-1.191-22.907c0.003,0.048,0.009,0.09,0.02,0.124c0.01,0.035,0.026,0.061,0.046,0.081c0.021,0.019,0.049,0.029,0.083,0.029
				c0.058,0,0.099-0.03,0.12-0.091c0.023-0.061,0.034-0.161,0.034-0.3c0-0.14-0.011-0.24-0.034-0.301
				c-0.021-0.061-0.062-0.091-0.12-0.091c-0.034,0-0.062,0.009-0.083,0.029c-0.02,0.019-0.036,0.046-0.046,0.08
				c-0.011,0.035-0.017,0.077-0.02,0.125s-0.004,0.101-0.004,0.158C-1.195-23.008-1.194-22.955-1.191-22.907"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M0.083-23.301c-0.004-0.026-0.01-0.05-0.019-0.071
				c-0.008-0.022-0.021-0.039-0.038-0.052s-0.039-0.019-0.066-0.019c-0.035,0-0.063,0.007-0.085,0.023
				c-0.023,0.016-0.039,0.04-0.05,0.07c-0.011,0.032-0.019,0.071-0.023,0.119c-0.004,0.048-0.006,0.104-0.006,0.169
				c0,0.069,0.002,0.128,0.006,0.177c0.004,0.049,0.012,0.09,0.023,0.121c0.011,0.032,0.026,0.055,0.047,0.07
				c0.02,0.014,0.046,0.022,0.077,0.022c0.048,0,0.084-0.021,0.106-0.063c0.022-0.041,0.033-0.107,0.033-0.199h0.274
				c0,0.146-0.033,0.258-0.099,0.335c-0.065,0.077-0.174,0.115-0.326,0.115c-0.072,0-0.135-0.008-0.188-0.026
				c-0.053-0.017-0.097-0.048-0.132-0.093c-0.036-0.045-0.063-0.105-0.08-0.181c-0.018-0.074-0.027-0.168-0.027-0.28
				c0-0.115,0.011-0.21,0.033-0.286c0.022-0.074,0.053-0.134,0.093-0.179c0.04-0.045,0.088-0.077,0.143-0.095
				c0.056-0.018,0.116-0.027,0.183-0.027c0.132,0,0.232,0.039,0.299,0.116c0.067,0.078,0.101,0.182,0.101,0.315H0.087
				C0.087-23.247,0.086-23.275,0.083-23.301"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M0.755-22.931c0,0.033,0.003,0.065,0.007,0.095
				c0.005,0.032,0.013,0.059,0.024,0.083c0.011,0.025,0.026,0.044,0.044,0.059c0.017,0.014,0.04,0.022,0.066,0.022
				c0.048,0,0.083-0.017,0.106-0.052c0.022-0.035,0.037-0.087,0.045-0.158h0.257c-0.005,0.13-0.04,0.229-0.103,0.297
				c-0.064,0.068-0.164,0.101-0.301,0.101c-0.103,0-0.184-0.017-0.242-0.052c-0.058-0.034-0.101-0.08-0.129-0.136
				c-0.028-0.057-0.044-0.121-0.051-0.191c-0.006-0.071-0.009-0.142-0.009-0.212c0-0.074,0.005-0.146,0.016-0.216
				c0.01-0.068,0.031-0.13,0.063-0.184c0.031-0.054,0.075-0.096,0.133-0.128c0.058-0.032,0.136-0.048,0.233-0.048
				c0.082,0,0.15,0.013,0.204,0.04c0.053,0.028,0.095,0.066,0.125,0.115c0.031,0.049,0.051,0.108,0.062,0.178
				c0.011,0.069,0.017,0.148,0.017,0.233v0.064H0.755V-22.931z M1.039-23.304c-0.004-0.032-0.011-0.06-0.022-0.083
				c-0.01-0.022-0.025-0.039-0.043-0.052c-0.019-0.011-0.043-0.017-0.072-0.017c-0.029,0-0.053,0.006-0.071,0.019
				c-0.019,0.014-0.034,0.031-0.045,0.053c-0.011,0.021-0.019,0.045-0.024,0.071c-0.004,0.026-0.007,0.053-0.007,0.079v0.044h0.292
				C1.046-23.234,1.043-23.271,1.039-23.304"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M1.679-22.834c0,0.049,0.013,0.088,0.038,0.118
				c0.026,0.029,0.063,0.044,0.111,0.044c0.045,0,0.08-0.011,0.106-0.034c0.026-0.022,0.039-0.054,0.039-0.098
				c0-0.037-0.01-0.064-0.031-0.083c-0.021-0.018-0.045-0.033-0.072-0.044l-0.201-0.073C1.59-23.031,1.53-23.071,1.49-23.122
				c-0.039-0.05-0.06-0.115-0.06-0.194c0-0.045,0.008-0.089,0.023-0.129c0.015-0.041,0.039-0.077,0.073-0.107
				c0.033-0.03,0.075-0.054,0.127-0.072c0.052-0.019,0.115-0.028,0.188-0.028c0.129,0,0.226,0.028,0.288,0.083
				c0.062,0.056,0.093,0.134,0.093,0.235v0.046H1.965c0-0.059-0.009-0.101-0.028-0.128c-0.019-0.027-0.051-0.04-0.098-0.04
				c-0.036,0-0.068,0.01-0.095,0.029c-0.027,0.02-0.04,0.051-0.04,0.09c0,0.028,0.009,0.053,0.026,0.075s0.05,0.041,0.098,0.056
				L2-23.147c0.09,0.031,0.154,0.07,0.191,0.12c0.037,0.048,0.056,0.115,0.056,0.199c0,0.06-0.01,0.112-0.031,0.155
				c-0.021,0.044-0.049,0.08-0.086,0.109C2.093-22.536,2.049-22.515,2-22.502c-0.05,0.012-0.105,0.018-0.167,0.018
				c-0.079,0-0.146-0.007-0.2-0.022c-0.052-0.015-0.095-0.038-0.126-0.069c-0.031-0.03-0.053-0.067-0.065-0.111
				c-0.013-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.834z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M2.6-22.834c0,0.049,0.013,0.088,0.038,0.118
				c0.026,0.029,0.063,0.044,0.111,0.044c0.045,0,0.08-0.011,0.106-0.034c0.026-0.022,0.039-0.054,0.039-0.098
				c0-0.037-0.01-0.064-0.031-0.083c-0.021-0.018-0.045-0.033-0.072-0.044l-0.201-0.073c-0.079-0.027-0.139-0.067-0.179-0.117
				c-0.039-0.05-0.06-0.115-0.06-0.194c0-0.045,0.008-0.089,0.023-0.129c0.015-0.041,0.039-0.077,0.073-0.107
				c0.033-0.03,0.075-0.054,0.127-0.072c0.052-0.019,0.115-0.028,0.188-0.028c0.129,0,0.226,0.028,0.288,0.083
				c0.062,0.056,0.093,0.134,0.093,0.235v0.046H2.886c0-0.059-0.009-0.101-0.028-0.128c-0.019-0.027-0.051-0.04-0.098-0.04
				c-0.036,0-0.068,0.01-0.095,0.029c-0.027,0.02-0.04,0.051-0.04,0.09c0,0.028,0.009,0.053,0.026,0.075
				c0.017,0.022,0.05,0.041,0.098,0.056l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.12c0.037,0.048,0.056,0.115,0.056,0.199
				c0,0.06-0.01,0.112-0.031,0.155c-0.021,0.044-0.049,0.08-0.086,0.109c-0.037,0.028-0.081,0.049-0.131,0.062
				c-0.05,0.012-0.105,0.018-0.167,0.018c-0.079,0-0.146-0.007-0.2-0.022c-0.052-0.015-0.095-0.038-0.126-0.069
				c-0.031-0.03-0.053-0.067-0.065-0.111c-0.013-0.043-0.019-0.092-0.019-0.146v-0.039H2.6V-22.834z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="-2.752,-22.043 -2.541,-20.999 -2.536,-20.999 -2.323,-22.043
				-1.882,-22.043 -1.882,-20.563 -2.155,-20.563 -2.155,-21.746 -2.159,-21.746 -2.422,-20.563 -2.655,-20.563 -2.918,-21.746
				-2.922,-21.746 -2.922,-20.563 -3.196,-20.563 -3.196,-22.043 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-1.678-21.364c0-0.065,0.01-0.119,0.031-0.163
				c0.021-0.043,0.049-0.078,0.084-0.106c0.035-0.026,0.077-0.046,0.124-0.057c0.048-0.012,0.099-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025c0.053,0.016,0.093,0.04,0.122,0.07c0.029,0.031,0.049,0.066,0.059,0.109
				c0.011,0.042,0.016,0.088,0.016,0.138v0.587c0,0.052,0.002,0.093,0.007,0.121c0.005,0.029,0.014,0.061,0.028,0.094h-0.274
				c-0.009-0.018-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059H-1.16c-0.033,0.058-0.071,0.096-0.115,0.113
				c-0.044,0.017-0.1,0.026-0.169,0.026c-0.05,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.072
				c-0.021-0.03-0.036-0.065-0.046-0.102c-0.01-0.038-0.014-0.076-0.014-0.113c0-0.053,0.005-0.098,0.016-0.136
				c0.012-0.039,0.028-0.071,0.051-0.097c0.023-0.028,0.052-0.05,0.087-0.068c0.035-0.018,0.078-0.033,0.128-0.048l0.161-0.043
				c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.077-0.03-0.101
				s-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.1,0.039c-0.022,0.027-0.033,0.062-0.033,0.106v0.031h-0.257V-21.364z
				 M-1.218-21.084c-0.022,0.009-0.042,0.015-0.06,0.019c-0.058,0.013-0.1,0.034-0.124,0.062c-0.025,0.029-0.038,0.069-0.038,0.119
				c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.045,0.045,0.083,0.045c0.019,0,0.039-0.003,0.06-0.009
				c0.021-0.007,0.04-0.016,0.057-0.03c0.018-0.014,0.032-0.032,0.043-0.054c0.01-0.022,0.016-0.048,0.016-0.079v-0.219
				C-1.175-21.105-1.196-21.094-1.218-21.084"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M0.156-20.619c0,0.144-0.036,0.251-0.107,0.323
				c-0.071,0.072-0.19,0.108-0.357,0.108c-0.122,0-0.215-0.026-0.281-0.078c-0.066-0.051-0.099-0.125-0.099-0.221h0.274
				c0,0.036,0.014,0.064,0.043,0.085c0.014,0.01,0.029,0.018,0.045,0.023c0.016,0.006,0.033,0.008,0.051,0.008
				c0.058,0,0.099-0.018,0.122-0.055c0.023-0.038,0.035-0.085,0.035-0.139v-0.148h-0.004c-0.029,0.042-0.065,0.075-0.107,0.1
				s-0.086,0.037-0.131,0.037c-0.114,0-0.199-0.043-0.256-0.129c-0.058-0.087-0.086-0.232-0.086-0.434
				c0-0.065,0.003-0.131,0.011-0.199s0.024-0.129,0.048-0.184c0.024-0.054,0.059-0.099,0.106-0.134
				c0.046-0.034,0.107-0.052,0.185-0.052c0.04,0,0.081,0.011,0.124,0.034c0.043,0.022,0.078,0.062,0.106,0.12h0.004v-0.124h0.274
				V-20.619z M-0.411-20.977c0.003,0.034,0.01,0.066,0.021,0.092c0.01,0.027,0.025,0.048,0.043,0.065
				c0.019,0.016,0.043,0.023,0.074,0.023c0.03,0,0.055-0.007,0.076-0.023c0.02-0.017,0.035-0.038,0.047-0.066
				c0.012-0.027,0.02-0.06,0.025-0.1s0.007-0.083,0.007-0.13c0-0.135-0.011-0.231-0.032-0.288c-0.022-0.057-0.06-0.085-0.117-0.085
				c-0.032,0-0.057,0.007-0.077,0.021c-0.019,0.014-0.034,0.037-0.045,0.068c-0.011,0.03-0.019,0.072-0.022,0.124
				c-0.004,0.051-0.005,0.115-0.005,0.19C-0.416-21.047-0.415-21.01-0.411-20.977"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M0.602-20.989c0,0.034,0.003,0.066,0.007,0.096
				c0.005,0.032,0.013,0.059,0.024,0.083c0.011,0.025,0.026,0.044,0.044,0.059c0.017,0.014,0.04,0.022,0.066,0.022
				c0.048,0,0.083-0.017,0.106-0.052c0.022-0.035,0.037-0.087,0.045-0.158H1.15c-0.005,0.13-0.04,0.229-0.103,0.297
				c-0.064,0.067-0.164,0.101-0.301,0.101c-0.103,0-0.184-0.017-0.242-0.052c-0.058-0.034-0.101-0.08-0.129-0.136
				c-0.028-0.057-0.044-0.121-0.051-0.191c-0.006-0.071-0.009-0.142-0.009-0.212c0-0.074,0.005-0.146,0.016-0.216
				c0.01-0.068,0.031-0.13,0.062-0.184c0.031-0.054,0.075-0.096,0.133-0.128c0.058-0.032,0.136-0.048,0.233-0.048
				c0.082,0,0.15,0.013,0.204,0.04c0.053,0.028,0.095,0.066,0.125,0.115c0.031,0.048,0.051,0.108,0.062,0.178
				c0.011,0.069,0.017,0.148,0.017,0.233v0.064H0.602V-20.989z M0.885-21.361c-0.004-0.032-0.011-0.06-0.022-0.083
				c-0.01-0.022-0.025-0.039-0.043-0.052c-0.019-0.011-0.043-0.017-0.072-0.017c-0.029,0-0.053,0.006-0.071,0.019
				c-0.019,0.014-0.034,0.031-0.045,0.053c-0.011,0.021-0.019,0.045-0.024,0.071c-0.004,0.026-0.007,0.053-0.007,0.079v0.043h0.292
				C0.893-21.291,0.89-21.328,0.885-21.361"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M1.59-21.679v0.131h0.004c0.031-0.056,0.07-0.096,0.118-0.122
				c0.049-0.025,0.104-0.039,0.166-0.039c0.091,0,0.161,0.026,0.21,0.076c0.05,0.049,0.074,0.13,0.074,0.242v0.827H1.876v-0.769
				c0-0.058-0.01-0.098-0.029-0.121c-0.02-0.023-0.05-0.035-0.093-0.035c-0.101,0-0.152,0.062-0.152,0.185v0.74H1.316v-1.115H1.59z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M2.701-21.998v0.319h0.166v0.195H2.701v0.603
				c0,0.044,0.007,0.076,0.02,0.094c0.013,0.019,0.04,0.028,0.082,0.028c0.01,0,0.022,0,0.033-0.001
				c0.011,0,0.021-0.001,0.031-0.003v0.2c-0.032,0-0.063,0.001-0.092,0.002c-0.03,0.002-0.062,0.003-0.095,0.003
				c-0.055,0-0.1-0.004-0.135-0.01c-0.036-0.009-0.063-0.024-0.081-0.047c-0.019-0.022-0.032-0.053-0.039-0.09
				c-0.006-0.037-0.01-0.084-0.01-0.141v-0.638H2.27v-0.195h0.145v-0.319H2.701z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M2.964-21.364c0-0.065,0.01-0.119,0.031-0.163
				c0.021-0.043,0.049-0.078,0.084-0.106c0.035-0.026,0.077-0.046,0.124-0.057c0.048-0.012,0.099-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025c0.053,0.016,0.093,0.04,0.122,0.07c0.029,0.031,0.049,0.066,0.059,0.109
				c0.011,0.042,0.016,0.088,0.016,0.138v0.587c0,0.052,0.002,0.093,0.007,0.121c0.005,0.029,0.014,0.061,0.028,0.094H3.521
				c-0.009-0.018-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059H3.482c-0.033,0.058-0.071,0.096-0.115,0.113
				c-0.043,0.017-0.1,0.026-0.169,0.026c-0.05,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.062-0.041-0.083-0.072
				c-0.021-0.03-0.036-0.065-0.046-0.102c-0.01-0.038-0.014-0.076-0.014-0.113c0-0.053,0.005-0.098,0.016-0.136
				c0.012-0.039,0.028-0.071,0.051-0.097c0.023-0.028,0.052-0.05,0.087-0.068c0.035-0.018,0.078-0.033,0.128-0.048l0.161-0.043
				c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.077-0.03-0.101
				s-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.1,0.039c-0.022,0.027-0.033,0.062-0.033,0.106v0.031H2.964V-21.364z
				 M3.424-21.084c-0.022,0.009-0.042,0.015-0.06,0.019c-0.058,0.013-0.1,0.034-0.124,0.063c-0.025,0.029-0.038,0.069-0.038,0.119
				c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.045,0.045,0.083,0.045c0.019,0,0.039-0.003,0.06-0.009
				c0.021-0.007,0.04-0.016,0.057-0.03c0.018-0.014,0.032-0.032,0.043-0.054c0.01-0.022,0.016-0.048,0.016-0.079v-0.219
				C3.467-21.106,3.446-21.094,3.424-21.084"
        />
        <rect
          x="-13.161"
          y="-26.429"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EF97A8"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-12.247-25.929c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.247z M-12.338-25.26c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H-12.338
				z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="-11.244,-25.93 -11.033,-24.884 -11.028,-24.884 -10.815,-25.93
				-10.374,-25.93 -10.374,-24.451 -10.647,-24.451 -10.647,-25.631 -10.651,-25.631 -10.914,-24.451 -11.147,-24.451
				-11.41,-25.631 -11.414,-25.631 -11.414,-24.451 -11.688,-24.451 -11.688,-25.93 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-9.919-24.869c0,0.034,0.003,0.066,0.008,0.094s0.016,0.052,0.031,0.072
				c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.057,0.016,0.096,0.016c0.045,0,0.086-0.014,0.123-0.044
				c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086c-0.009-0.025-0.024-0.046-0.045-0.066
				c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048c-0.066-0.022-0.124-0.046-0.172-0.073
				c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122c-0.015-0.046-0.023-0.1-0.023-0.161
				c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.071-0.012-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.022,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.289-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-8.313-25.486h-0.292v-0.195h0.027c0.099,0,0.177-0.018,0.235-0.055
				c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.298V-25.486z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-7.315-24.663c0.019,0.032,0.054,0.047,0.104,0.047
				c0.04,0,0.072-0.009,0.096-0.028c0.023-0.019,0.042-0.045,0.054-0.078c0.013-0.033,0.022-0.073,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.066-0.106,0.089c-0.046,0.023-0.103,0.035-0.169,0.035
				c-0.066,0-0.12-0.012-0.166-0.035c-0.046-0.023-0.083-0.054-0.111-0.095c-0.028-0.04-0.049-0.089-0.062-0.145
				c-0.013-0.056-0.02-0.117-0.02-0.183c0-0.17,0.038-0.3,0.113-0.39c0.075-0.09,0.196-0.135,0.362-0.135
				c0.102,0,0.184,0.019,0.244,0.058c0.061,0.037,0.108,0.088,0.14,0.151c0.033,0.063,0.054,0.135,0.064,0.219
				c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.003,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.056,0.258
				c-0.031,0.075-0.081,0.133-0.147,0.177c-0.067,0.043-0.161,0.065-0.281,0.065c-0.053,0-0.104-0.004-0.153-0.015
				c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.107-0.037-0.178h0.286
				C-7.346-24.737-7.336-24.694-7.315-24.663 M-7.331-25.219c0.025,0.042,0.066,0.064,0.124,0.064c0.059,0,0.102-0.022,0.127-0.064
				c0.025-0.043,0.037-0.115,0.037-0.213c0-0.042-0.002-0.081-0.006-0.117c-0.004-0.036-0.012-0.066-0.024-0.092
				c-0.012-0.025-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.064,0.008-0.086,0.022
				c-0.021,0.015-0.037,0.035-0.048,0.061c-0.012,0.026-0.019,0.057-0.022,0.092c-0.004,0.036-0.005,0.074-0.005,0.116
				C-7.368-25.333-7.356-25.262-7.331-25.219"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M-5.751-25.661c-0.061,0.086-0.115,0.178-0.163,0.276
				c-0.047,0.098-0.089,0.199-0.125,0.302c-0.036,0.104-0.066,0.21-0.089,0.317c-0.024,0.107-0.042,0.212-0.054,0.315h-0.313
				c0.011-0.088,0.027-0.181,0.05-0.278c0.022-0.098,0.051-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
				c0.053-0.103,0.115-0.203,0.187-0.3h-0.618v-0.257h0.904V-25.661z"
        />
        <rect
          x="6.275"
          y="-26.429"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#88155D"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M7.189-25.929c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				C7.26-25.05,7.195-25.04,7.125-25.04h-0.16v0.59H6.667v-1.479H7.189z M7.098-25.26c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17C7.217-25.69,7.164-25.71,7.09-25.71H6.965v0.45H7.098z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="8.192,-25.929 8.403,-24.884 8.408,-24.884 8.621,-25.929
				9.062,-25.929 9.062,-24.449 8.789,-24.449 8.789,-25.631 8.785,-25.631 8.522,-24.449 8.289,-24.449 8.026,-25.631
				8.022,-25.631 8.022,-24.449 7.748,-24.449 7.748,-25.929 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M9.517-24.869c0,0.034,0.003,0.066,0.008,0.094
				c0.005,0.028,0.016,0.053,0.031,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.057,0.016,0.096,0.016
				c0.045,0,0.086-0.013,0.123-0.044c0.037-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086
				c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.021-0.124-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041H9.869c0-0.071-0.012-0.124-0.037-0.162c-0.025-0.038-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.014c-0.022,0.01-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.169,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M10.783-24.45c0-0.064,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.052,0.06-0.101,0.101-0.151c0.041-0.049,0.088-0.098,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.055,0.079-0.08c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				s0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.022
				c-0.022,0.015-0.038,0.035-0.05,0.063c-0.012,0.026-0.019,0.056-0.023,0.091c-0.003,0.035-0.005,0.072-0.005,0.11h-0.286v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
				c0.072,0.069,0.107,0.168,0.107,0.296c0,0.047-0.004,0.091-0.012,0.132c-0.008,0.04-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.025,0.039-0.041,0.071-0.048,0.098h0.554v0.233H10.783z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M11.778-24.45c0-0.064,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.052,0.06-0.101,0.101-0.151c0.041-0.049,0.088-0.098,0.142-0.148l0.121-0.112
				c0.033-0.029,0.06-0.055,0.079-0.08c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				s0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.022
				c-0.022,0.015-0.038,0.035-0.05,0.063c-0.012,0.026-0.019,0.056-0.023,0.091c-0.003,0.035-0.005,0.072-0.005,0.11h-0.286v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
				c0.072,0.069,0.107,0.168,0.107,0.296c0,0.047-0.004,0.091-0.012,0.132c-0.008,0.04-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.025,0.039-0.041,0.071-0.048,0.098h0.554v0.233H11.778z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M12.826-25.102c0.042-0.067,0.104-0.109,0.188-0.125v-0.004
				c-0.04-0.01-0.074-0.025-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.07c-0.016-0.027-0.028-0.054-0.035-0.083
				c-0.007-0.03-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.115-0.31c0.076-0.063,0.182-0.094,0.316-0.094
				s0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.31c0,0.026-0.004,0.053-0.012,0.083
				c-0.007,0.029-0.019,0.056-0.036,0.083c-0.016,0.026-0.038,0.049-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004
				c0.083,0.016,0.145,0.058,0.187,0.125c0.041,0.066,0.062,0.145,0.062,0.238c0,0.043-0.005,0.09-0.015,0.14
				c-0.011,0.052-0.033,0.099-0.066,0.144c-0.033,0.044-0.08,0.08-0.142,0.109c-0.061,0.029-0.143,0.044-0.245,0.044
				s-0.184-0.015-0.246-0.044s-0.109-0.065-0.142-0.109c-0.033-0.045-0.055-0.092-0.065-0.144c-0.011-0.05-0.016-0.097-0.016-0.14
				C12.764-24.957,12.785-25.036,12.826-25.102 M13.07-24.771c0.005,0.032,0.014,0.059,0.027,0.082
				c0.013,0.023,0.031,0.041,0.053,0.054s0.05,0.019,0.083,0.019s0.06-0.006,0.083-0.019c0.022-0.013,0.04-0.031,0.052-0.054
				c0.013-0.023,0.023-0.05,0.027-0.082c0.005-0.03,0.008-0.063,0.008-0.098s-0.003-0.069-0.008-0.099
				c-0.004-0.03-0.014-0.057-0.027-0.08c-0.012-0.023-0.03-0.04-0.052-0.053c-0.023-0.014-0.05-0.02-0.083-0.02
				s-0.061,0.006-0.083,0.02c-0.022,0.013-0.04,0.03-0.053,0.053c-0.013,0.023-0.022,0.05-0.027,0.08s-0.007,0.064-0.007,0.099
				S13.065-24.801,13.07-24.771 M13.127-25.366c0.026,0.033,0.062,0.05,0.106,0.05s0.079-0.017,0.105-0.05
				c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.202-0.145-0.202s-0.145,0.067-0.145,0.202
				C13.088-25.451,13.101-25.399,13.127-25.366"
        />
        <rect
          x="16.144"
          y="-26.429"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#F37321"
          width="8.812"
          height="8.813"
        />

        <rect
          x="16.144"
          y="-26.428"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M17.058-25.929c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.058z M16.966-25.26c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.45H16.966z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="18.061,-25.929 18.271,-24.884 18.277,-24.884 18.49,-25.929
				18.931,-25.929 18.931,-24.449 18.658,-24.449 18.658,-25.631 18.654,-25.631 18.391,-24.449 18.158,-24.449 17.895,-25.631
				17.891,-25.631 17.891,-24.449 17.617,-24.449 17.617,-25.929 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M19.385-24.869c0,0.034,0.003,0.066,0.008,0.094s0.016,0.053,0.031,0.072
				c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.057,0.016,0.096,0.016c0.045,0,0.086-0.013,0.123-0.044
				c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086c-0.009-0.025-0.024-0.046-0.045-0.066
				c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048c-0.066-0.021-0.124-0.046-0.172-0.073
				c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122c-0.015-0.046-0.023-0.1-0.023-0.161
				c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.071-0.012-0.124-0.037-0.162c-0.025-0.038-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
				c-0.023,0.01-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.289-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M20.991-25.486h-0.292v-0.195h0.027c0.099,0,0.177-0.018,0.235-0.056
				c0.057-0.038,0.094-0.097,0.109-0.18h0.219v1.467h-0.298V-25.486z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M21.949-25.656l-0.031,0.334h0.004c0.027-0.04,0.064-0.067,0.108-0.085
				c0.044-0.017,0.098-0.025,0.161-0.025c0.071,0,0.129,0.013,0.176,0.04c0.046,0.027,0.082,0.063,0.109,0.107
				c0.027,0.044,0.047,0.094,0.057,0.15c0.011,0.056,0.017,0.113,0.017,0.171c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.066-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.083-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.057-0.025-0.101-0.058-0.132-0.099c-0.031-0.041-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.05,0.005,0.078c0.002,0.028,0.009,0.054,0.019,0.078
				c0.011,0.024,0.026,0.044,0.045,0.06c0.019,0.016,0.045,0.023,0.076,0.023c0.063,0,0.107-0.023,0.134-0.071
				c0.027-0.047,0.041-0.119,0.041-0.214c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.041-0.014-0.072-0.028-0.096
				c-0.013-0.024-0.03-0.04-0.053-0.05c-0.021-0.009-0.049-0.014-0.082-0.014c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.024-0.037,0.057-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H21.949z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M22.652-25.428c0.006-0.094,0.025-0.177,0.056-0.25
				c0.03-0.072,0.076-0.13,0.137-0.174c0.062-0.043,0.147-0.065,0.256-0.065c0.09,0,0.165,0.013,0.223,0.039
				c0.059,0.025,0.106,0.066,0.141,0.123c0.035,0.057,0.06,0.13,0.075,0.22c0.014,0.091,0.022,0.201,0.022,0.331
				c0,0.102-0.004,0.2-0.011,0.294s-0.025,0.177-0.056,0.25c-0.03,0.072-0.076,0.13-0.138,0.171
				c-0.061,0.042-0.147,0.062-0.256,0.062c-0.088,0-0.162-0.011-0.221-0.035c-0.06-0.024-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.06-0.13-0.075-0.221c-0.014-0.091-0.022-0.201-0.022-0.33C22.641-25.236,22.645-25.334,22.652-25.428
				 M22.947-24.842c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.033,0.029,0.056,0.05,0.069c0.02,0.013,0.046,0.018,0.078,0.018
				c0.034,0,0.061-0.007,0.081-0.023c0.021-0.016,0.037-0.043,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.153
				c0.004-0.063,0.007-0.142,0.007-0.236v-0.159c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.059-0.014-0.105-0.026-0.139
				c-0.013-0.034-0.029-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.008-0.08,0.024
				c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.037-0.02,0.088-0.025,0.152c-0.005,0.063-0.007,0.142-0.007,0.237v0.159
				C22.94-24.977,22.942-24.901,22.947-24.842"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M23.938-25.656l-0.031,0.334h0.004c0.028-0.04,0.064-0.067,0.108-0.085
				c0.044-0.017,0.098-0.025,0.161-0.025c0.071,0,0.129,0.013,0.176,0.04c0.046,0.027,0.083,0.063,0.109,0.107
				c0.027,0.044,0.047,0.094,0.057,0.15c0.011,0.056,0.017,0.113,0.017,0.171c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.066-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.083-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037s-0.101-0.058-0.132-0.099c-0.031-0.041-0.051-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.05,0.005,0.078c0.002,0.028,0.009,0.054,0.019,0.078
				c0.011,0.024,0.026,0.044,0.045,0.06c0.02,0.016,0.045,0.023,0.076,0.023c0.063,0,0.107-0.023,0.134-0.071
				c0.027-0.047,0.041-0.119,0.041-0.214c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.041-0.014-0.072-0.028-0.096
				c-0.013-0.024-0.03-0.04-0.053-0.05c-0.021-0.009-0.049-0.014-0.082-0.014c-0.043,0-0.076,0.012-0.099,0.036
				c-0.023,0.024-0.037,0.057-0.042,0.099H23.66l0.054-0.798h0.765v0.232H23.938z"
        />
        <rect
          x="25.78"
          y="-26.429"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#00B4AB"
          width="8.813"
          height="8.813"
        />

        <rect
          x="25.78"
          y="-26.428"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="none"
          stroke="#00B4AB"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M26.695-25.929c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H26.695z M26.604-25.26c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.45H26.604z
				"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="27.698,-25.929 27.909,-24.884 27.914,-24.884 28.127,-25.929
				28.568,-25.929 28.568,-24.449 28.295,-24.449 28.295,-25.631 28.291,-25.631 28.028,-24.449 27.795,-24.449 27.532,-25.631
				27.528,-25.631 27.528,-24.449 27.254,-24.449 27.254,-25.929 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M29.022-24.869c0,0.034,0.003,0.066,0.008,0.094
				c0.005,0.028,0.016,0.053,0.031,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.057,0.016,0.096,0.016
				c0.045,0,0.086-0.013,0.123-0.044c0.037-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086
				c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.021-0.124-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.071-0.012-0.124-0.037-0.162c-0.025-0.038-0.066-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.014c-0.022,0.01-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.169,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M30.613-24.683c0.021,0.046,0.065,0.068,0.13,0.068
				c0.035,0,0.064-0.006,0.086-0.019c0.021-0.013,0.037-0.029,0.049-0.051c0.012-0.021,0.019-0.047,0.023-0.075
				c0.003-0.029,0.005-0.06,0.005-0.091c0-0.034-0.002-0.065-0.007-0.095c-0.005-0.029-0.014-0.056-0.028-0.079
				c-0.014-0.024-0.033-0.042-0.058-0.057c-0.025-0.013-0.058-0.02-0.099-0.02h-0.1v-0.196h0.097c0.032,0,0.058-0.005,0.08-0.019
				c0.021-0.013,0.039-0.03,0.052-0.051c0.013-0.022,0.023-0.047,0.029-0.074c0.006-0.028,0.009-0.056,0.009-0.087
				c0-0.071-0.012-0.12-0.035-0.15c-0.024-0.029-0.059-0.043-0.108-0.043c-0.032,0-0.058,0.006-0.077,0.018
				c-0.021,0.011-0.036,0.028-0.047,0.05c-0.011,0.021-0.019,0.046-0.022,0.075c-0.003,0.029-0.005,0.061-0.005,0.096h-0.273
				c0-0.147,0.036-0.256,0.109-0.327c0.073-0.071,0.181-0.107,0.322-0.107c0.133,0,0.237,0.031,0.312,0.092
				c0.074,0.06,0.11,0.155,0.11,0.286c0,0.088-0.019,0.16-0.059,0.216c-0.039,0.056-0.094,0.091-0.164,0.107v0.004
				c0.095,0.015,0.162,0.053,0.202,0.115c0.039,0.061,0.059,0.139,0.059,0.231c0,0.049-0.007,0.101-0.019,0.154
				c-0.012,0.052-0.037,0.1-0.072,0.143c-0.037,0.042-0.086,0.077-0.149,0.103c-0.062,0.026-0.145,0.039-0.247,0.039
				c-0.143,0-0.249-0.038-0.32-0.116c-0.07-0.077-0.105-0.187-0.105-0.329v-0.006h0.286C30.581-24.793,30.591-24.728,30.613-24.683"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M31.284-24.45c0-0.064,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.052,0.06-0.101,0.101-0.151c0.041-0.049,0.088-0.098,0.142-0.148l0.121-0.112
				c0.033-0.029,0.059-0.055,0.079-0.08c0.02-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.018-0.052,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.038,0-0.067,0.008-0.088,0.022
				c-0.022,0.015-0.039,0.035-0.05,0.063c-0.012,0.026-0.019,0.056-0.023,0.091c-0.003,0.035-0.005,0.072-0.005,0.11h-0.286v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.333,0.103
				c0.071,0.069,0.106,0.168,0.106,0.296c0,0.047-0.004,0.091-0.012,0.132c-0.008,0.04-0.021,0.08-0.039,0.117
				c-0.02,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.045,0.04-0.079,0.079-0.104,0.117
				c-0.025,0.039-0.041,0.071-0.048,0.098h0.554v0.233H31.284z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M32.855-25.677c-0.019-0.03-0.056-0.045-0.104-0.045
				c-0.039,0-0.07,0.01-0.095,0.028s-0.043,0.045-0.056,0.078c-0.014,0.033-0.022,0.073-0.026,0.118
				c-0.004,0.046-0.006,0.096-0.006,0.149v0.073h0.004c0.024-0.039,0.062-0.069,0.106-0.092c0.047-0.023,0.104-0.034,0.17-0.034
				c0.064,0,0.119,0.011,0.166,0.034c0.045,0.023,0.082,0.054,0.111,0.095c0.027,0.041,0.049,0.089,0.061,0.145
				c0.014,0.056,0.02,0.117,0.02,0.183c0,0.17-0.037,0.299-0.111,0.387c-0.076,0.088-0.196,0.131-0.362,0.131
				c-0.103,0-0.185-0.017-0.245-0.053s-0.107-0.085-0.139-0.148c-0.033-0.064-0.055-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.204,0.009-0.302c0.006-0.098,0.024-0.184,0.056-0.259
				s0.08-0.135,0.146-0.182c0.066-0.045,0.16-0.067,0.281-0.067c0.053,0,0.104,0.006,0.152,0.018
				c0.051,0.013,0.094,0.033,0.131,0.062c0.036,0.029,0.066,0.067,0.09,0.115c0.022,0.048,0.035,0.107,0.035,0.178h-0.285
				C32.886-25.602,32.876-25.646,32.855-25.677 M32.589-24.79c0.004,0.036,0.012,0.067,0.024,0.092
				c0.011,0.026,0.028,0.046,0.05,0.06c0.021,0.015,0.049,0.021,0.084,0.021s0.064-0.007,0.086-0.021
				c0.021-0.014,0.037-0.034,0.049-0.062c0.01-0.026,0.019-0.057,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.116
				c0-0.099-0.013-0.17-0.037-0.213c-0.024-0.042-0.065-0.064-0.124-0.064c-0.061,0-0.104,0.022-0.127,0.064
				c-0.025,0.043-0.037,0.114-0.037,0.213C32.583-24.864,32.585-24.826,32.589-24.79"
        />
        <rect
          x="45.519"
          y="-26.429"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#5087C7"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M46.431-25.929c0.076,0,0.141,0.012,0.193,0.037
				c0.051,0.027,0.094,0.06,0.125,0.102c0.031,0.041,0.055,0.088,0.068,0.141c0.014,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.01,0.143-0.033,0.199c-0.023,0.057-0.055,0.103-0.096,0.139s-0.09,0.063-0.148,0.081
				c-0.057,0.017-0.123,0.027-0.191,0.027h-0.16v0.59h-0.299v-1.479H46.431z M46.341-25.26c0.059,0,0.107-0.018,0.145-0.054
				c0.035-0.036,0.055-0.092,0.055-0.168c0-0.074-0.018-0.131-0.049-0.17c-0.031-0.038-0.084-0.058-0.16-0.058h-0.123v0.45H46.341z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="47.435,-25.929 47.646,-24.884 47.649,-24.884 47.864,-25.929
				48.306,-25.929 48.306,-24.449 48.032,-24.449 48.032,-25.631 48.026,-25.631 47.765,-24.449 47.532,-24.449 47.269,-25.631
				47.265,-25.631 47.265,-24.449 46.991,-24.449 46.991,-25.929 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M48.759-24.869c0,0.034,0.004,0.066,0.008,0.094
				c0.006,0.028,0.016,0.053,0.031,0.072c0.016,0.019,0.037,0.035,0.061,0.045c0.027,0.012,0.059,0.016,0.098,0.016
				c0.045,0,0.086-0.013,0.123-0.044c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.062-0.014-0.086
				c-0.008-0.025-0.023-0.046-0.045-0.066c-0.02-0.019-0.047-0.036-0.082-0.053c-0.031-0.015-0.074-0.031-0.125-0.048
				c-0.064-0.021-0.123-0.046-0.172-0.073c-0.049-0.026-0.088-0.057-0.121-0.092c-0.031-0.035-0.057-0.076-0.07-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.121-0.327c0.082-0.072,0.195-0.108,0.336-0.108
				c0.068,0,0.129,0.007,0.184,0.021c0.057,0.016,0.105,0.039,0.145,0.071c0.043,0.033,0.074,0.074,0.096,0.124
				c0.023,0.051,0.035,0.111,0.035,0.182v0.041H49.11c0-0.071-0.012-0.124-0.037-0.162c-0.023-0.038-0.066-0.057-0.123-0.057
				c-0.033,0-0.061,0.005-0.084,0.014c-0.021,0.01-0.039,0.023-0.053,0.038c-0.014,0.016-0.021,0.035-0.027,0.055
				c-0.004,0.021-0.006,0.042-0.006,0.065c0,0.045,0.01,0.083,0.029,0.115c0.018,0.031,0.059,0.059,0.123,0.086l0.23,0.099
				c0.057,0.025,0.104,0.051,0.139,0.078c0.037,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.037,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.217,0.106-0.379,0.106
				c-0.168,0-0.289-0.036-0.361-0.109c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.297V-24.869z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M50.024-24.45c0-0.064,0.008-0.126,0.021-0.183
				c0.012-0.056,0.031-0.11,0.059-0.161c0.027-0.052,0.061-0.101,0.102-0.151c0.041-0.049,0.09-0.098,0.143-0.148l0.121-0.112
				c0.033-0.029,0.059-0.055,0.08-0.08c0.02-0.025,0.035-0.05,0.045-0.075c0.012-0.025,0.02-0.052,0.021-0.081
				c0.004-0.029,0.006-0.062,0.006-0.099c0-0.122-0.049-0.183-0.145-0.183c-0.037,0-0.068,0.008-0.088,0.022
				c-0.023,0.015-0.039,0.035-0.051,0.063c-0.012,0.026-0.02,0.056-0.023,0.091c-0.002,0.035-0.004,0.072-0.004,0.11h-0.287v-0.056
				c0-0.136,0.037-0.241,0.111-0.315c0.072-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103s0.107,0.168,0.107,0.296
				c0,0.047-0.004,0.091-0.014,0.132c-0.008,0.04-0.02,0.08-0.039,0.117c-0.018,0.038-0.041,0.074-0.07,0.109
				c-0.027,0.035-0.064,0.072-0.105,0.109l-0.166,0.153c-0.043,0.04-0.078,0.079-0.104,0.117c-0.023,0.039-0.041,0.071-0.047,0.098
				h0.553v0.233H50.024z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M51.933-25.66c-0.061,0.086-0.117,0.177-0.164,0.275
				c-0.047,0.099-0.088,0.2-0.125,0.303c-0.035,0.103-0.064,0.21-0.09,0.316c-0.023,0.107-0.041,0.212-0.053,0.316h-0.314
				c0.012-0.088,0.029-0.181,0.051-0.279c0.021-0.097,0.053-0.196,0.088-0.297c0.037-0.101,0.082-0.202,0.135-0.305
				c0.053-0.103,0.113-0.203,0.186-0.3h-0.617v-0.257h0.904V-25.66z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M52.354-25.486h-0.291v-0.195h0.025c0.102,0,0.178-0.018,0.236-0.056
				c0.057-0.038,0.094-0.097,0.109-0.18h0.219v1.467h-0.299V-25.486z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M53.063-25.102c0.041-0.067,0.104-0.109,0.188-0.125v-0.004
				c-0.041-0.01-0.074-0.025-0.102-0.046s-0.049-0.044-0.064-0.07c-0.018-0.027-0.029-0.054-0.035-0.083
				c-0.008-0.03-0.012-0.057-0.012-0.083c0-0.144,0.039-0.247,0.115-0.31s0.182-0.094,0.316-0.094s0.24,0.031,0.314,0.094
				c0.078,0.063,0.117,0.166,0.117,0.31c0,0.026-0.004,0.053-0.012,0.083c-0.008,0.029-0.02,0.056-0.037,0.083
				c-0.016,0.026-0.037,0.049-0.064,0.07s-0.061,0.036-0.1,0.046v0.004c0.084,0.016,0.146,0.058,0.188,0.125
				c0.041,0.066,0.063,0.145,0.063,0.238c0,0.043-0.006,0.09-0.016,0.14c-0.012,0.052-0.033,0.099-0.066,0.144
				c-0.033,0.044-0.08,0.08-0.141,0.109c-0.063,0.029-0.145,0.044-0.246,0.044s-0.184-0.015-0.246-0.044s-0.109-0.065-0.143-0.109
				c-0.031-0.045-0.055-0.092-0.064-0.144c-0.01-0.05-0.016-0.097-0.016-0.14C53.001-24.957,53.022-25.036,53.063-25.102
				 M53.308-24.771c0.004,0.032,0.014,0.059,0.027,0.082c0.012,0.023,0.029,0.041,0.053,0.054c0.021,0.013,0.049,0.019,0.082,0.019
				s0.061-0.006,0.084-0.019c0.02-0.013,0.039-0.031,0.051-0.054c0.014-0.023,0.023-0.05,0.027-0.082
				c0.006-0.03,0.008-0.063,0.008-0.098s-0.002-0.069-0.008-0.099c-0.004-0.03-0.014-0.057-0.027-0.08
				c-0.012-0.023-0.031-0.04-0.051-0.053c-0.023-0.014-0.051-0.02-0.084-0.02s-0.061,0.006-0.082,0.02
				c-0.023,0.013-0.041,0.03-0.053,0.053c-0.014,0.023-0.023,0.05-0.027,0.08c-0.006,0.03-0.008,0.064-0.008,0.099
				S53.302-24.801,53.308-24.771 M53.364-25.366c0.025,0.033,0.061,0.05,0.105,0.05s0.08-0.017,0.105-0.05
				c0.027-0.033,0.039-0.085,0.039-0.155c0-0.135-0.047-0.202-0.145-0.202s-0.145,0.067-0.145,0.202
				C53.325-25.451,53.339-25.399,53.364-25.366"
        />
        <rect
          x="35.649"
          y="-26.429"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          width="8.813"
          height="8.813"
        />

        <rect
          x="35.649"
          y="-26.428"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.813"
          height="8.813"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          points="36.239,-25.93 36.403,-24.835 36.407,-24.835 36.582,-25.93 36.868,-25.93
				37.042,-24.835 37.046,-24.835 37.21,-25.93 37.496,-25.93 37.226,-24.45 36.894,-24.45 36.728,-25.523 36.724,-25.523
				36.558,-24.45 36.224,-24.45 35.954,-25.93 			"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          points="37.915,-25.93 37.915,-25.363 38.263,-25.363 38.263,-25.93 38.562,-25.93
				38.562,-24.45 38.263,-24.45 38.263,-25.106 37.915,-25.106 37.915,-24.45 37.616,-24.45 37.616,-25.93 			"
        />
        <rect
          x="38.783"
          y="-25.93"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          width="0.298"
          height="1.48"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          points="40.169,-25.685 39.847,-25.685 39.847,-24.449 39.548,-24.449 39.548,-25.685
				39.225,-25.685 39.225,-25.93 40.169,-25.93 			"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          points="41.125,-25.685 40.596,-25.685 40.596,-25.337 41.093,-25.337 41.093,-25.092
				40.596,-25.092 40.596,-24.694 41.146,-24.694 41.146,-24.449 40.298,-24.449 40.298,-25.93 41.125,-25.93 			"
        />
        <rect
          x="55.386"
          y="-26.429"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M56.3-25.929c0.076,0,0.141,0.012,0.191,0.037
				c0.053,0.027,0.094,0.06,0.125,0.102c0.033,0.041,0.055,0.088,0.07,0.141c0.012,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.035,0.199c-0.021,0.057-0.055,0.103-0.094,0.139c-0.041,0.036-0.092,0.063-0.148,0.081
				c-0.059,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.297v-1.479H56.3z M56.208-25.26c0.061,0,0.107-0.018,0.145-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.033-0.038-0.086-0.058-0.16-0.058h-0.125v0.45H56.208z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="57.304,-25.929 57.515,-24.884 57.519,-24.884 57.731,-25.929
				58.173,-25.929 58.173,-24.449 57.899,-24.449 57.899,-25.631 57.896,-25.631 57.632,-24.449 57.399,-24.449 57.138,-25.631
				57.134,-25.631 57.134,-24.449 56.86,-24.449 56.86,-25.929 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M58.628-24.869c0,0.034,0.002,0.066,0.008,0.094
				c0.004,0.028,0.016,0.053,0.031,0.072c0.014,0.019,0.035,0.035,0.061,0.045c0.025,0.012,0.059,0.016,0.098,0.016
				c0.045,0,0.086-0.013,0.123-0.044c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.006-0.062-0.014-0.086
				c-0.01-0.025-0.025-0.046-0.045-0.066c-0.021-0.019-0.049-0.036-0.082-0.053c-0.033-0.015-0.074-0.031-0.125-0.048
				c-0.066-0.021-0.123-0.046-0.172-0.073c-0.049-0.026-0.09-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.014-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.082-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.184,0.021c0.057,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.096,0.124
				c0.023,0.051,0.033,0.111,0.033,0.182v0.041h-0.285c0-0.071-0.014-0.124-0.037-0.162c-0.025-0.038-0.066-0.057-0.123-0.057
				c-0.033,0-0.063,0.005-0.084,0.014c-0.023,0.01-0.039,0.023-0.055,0.038c-0.012,0.016-0.02,0.035-0.025,0.055
				c-0.004,0.021-0.006,0.042-0.006,0.065c0,0.045,0.01,0.083,0.027,0.115c0.02,0.031,0.061,0.059,0.125,0.086l0.23,0.099
				c0.057,0.025,0.102,0.051,0.139,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.035,0.065,0.045,0.102
				c0.006,0.038,0.01,0.079,0.01,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.215,0.106-0.377,0.106
				c-0.17,0-0.291-0.036-0.363-0.109c-0.07-0.073-0.107-0.179-0.107-0.315v-0.061h0.299V-24.869z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M56.276-23.987c0.063,0,0.117,0.007,0.17,0.02
				c0.053,0.012,0.096,0.033,0.133,0.061c0.037,0.029,0.066,0.068,0.088,0.116c0.02,0.049,0.031,0.108,0.031,0.179
				c0,0.079-0.02,0.148-0.057,0.206c-0.037,0.057-0.094,0.096-0.168,0.117v0.005c0.084,0.01,0.148,0.046,0.197,0.105
				c0.049,0.059,0.072,0.139,0.072,0.239c0,0.057-0.008,0.113-0.023,0.165c-0.016,0.053-0.041,0.099-0.076,0.138
				c-0.037,0.04-0.086,0.071-0.143,0.094c-0.061,0.023-0.133,0.036-0.221,0.036h-0.504v-1.481H56.276z M56.159-23.379
				c0.084,0,0.145-0.015,0.184-0.045c0.037-0.029,0.057-0.079,0.057-0.15c0-0.07-0.018-0.12-0.053-0.149
				c-0.033-0.029-0.088-0.044-0.164-0.044h-0.107v0.388H56.159z M56.192-22.726c0.072,0,0.129-0.016,0.174-0.048
				c0.043-0.032,0.066-0.091,0.066-0.176c0-0.043-0.006-0.079-0.018-0.107c-0.012-0.028-0.027-0.051-0.049-0.068
				c-0.02-0.018-0.043-0.029-0.07-0.037c-0.027-0.006-0.059-0.01-0.092-0.01h-0.129v0.446H56.192z"
        />
        <rect
          x="56.933"
          y="-23.986"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          width="0.285"
          height="1.48"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M57.417-23.307c0-0.065,0.01-0.119,0.031-0.163s0.049-0.078,0.084-0.106
				c0.035-0.026,0.076-0.046,0.123-0.057c0.049-0.012,0.1-0.018,0.154-0.018c0.084,0,0.154,0.008,0.207,0.025
				c0.051,0.016,0.092,0.04,0.121,0.07c0.029,0.031,0.049,0.066,0.059,0.109c0.012,0.042,0.016,0.088,0.016,0.138v0.587
				c0,0.052,0.004,0.093,0.008,0.121c0.006,0.029,0.014,0.061,0.029,0.094h-0.275c-0.008-0.018-0.016-0.037-0.021-0.057
				c-0.004-0.02-0.01-0.04-0.014-0.059h-0.004c-0.033,0.058-0.07,0.095-0.115,0.113c-0.043,0.017-0.1,0.026-0.168,0.026
				c-0.051,0-0.092-0.009-0.127-0.026c-0.035-0.018-0.063-0.041-0.082-0.072c-0.021-0.03-0.037-0.065-0.047-0.102
				c-0.01-0.038-0.014-0.076-0.014-0.113c0-0.053,0.006-0.098,0.016-0.136c0.012-0.039,0.029-0.071,0.051-0.097
				c0.023-0.028,0.053-0.05,0.088-0.068s0.078-0.033,0.129-0.048l0.16-0.043c0.043-0.011,0.072-0.026,0.09-0.045
				c0.016-0.02,0.023-0.048,0.023-0.085c0-0.043-0.01-0.077-0.029-0.101c-0.02-0.024-0.055-0.036-0.102-0.036
				c-0.045,0-0.078,0.013-0.1,0.039c-0.023,0.027-0.035,0.062-0.035,0.106v0.031h-0.256V-23.307z M57.878-23.028
				c-0.023,0.009-0.043,0.015-0.061,0.019c-0.059,0.013-0.1,0.034-0.125,0.062c-0.023,0.029-0.037,0.069-0.037,0.118
				c0,0.044,0.01,0.08,0.025,0.111c0.016,0.03,0.043,0.045,0.082,0.045c0.02,0,0.039-0.003,0.061-0.009
				c0.021-0.007,0.039-0.016,0.057-0.03c0.018-0.014,0.031-0.032,0.043-0.054c0.01-0.022,0.016-0.049,0.016-0.079v-0.219
				C57.919-23.049,57.899-23.037,57.878-23.028"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M58.948-23.301c-0.006-0.026-0.012-0.05-0.02-0.071
				c-0.008-0.022-0.021-0.039-0.037-0.052c-0.018-0.013-0.041-0.019-0.066-0.019c-0.035,0-0.064,0.007-0.086,0.023
				s-0.039,0.04-0.051,0.07c-0.01,0.032-0.018,0.071-0.021,0.119s-0.006,0.104-0.006,0.169c0,0.069,0.002,0.128,0.006,0.177
				c0.004,0.049,0.012,0.09,0.021,0.121c0.012,0.032,0.027,0.055,0.047,0.07c0.021,0.014,0.047,0.022,0.078,0.022
				c0.049,0,0.084-0.021,0.105-0.063c0.021-0.041,0.035-0.107,0.035-0.199h0.271c0,0.146-0.033,0.258-0.098,0.335
				c-0.066,0.077-0.174,0.115-0.326,0.115c-0.072,0-0.135-0.008-0.189-0.026c-0.053-0.017-0.096-0.048-0.131-0.093
				c-0.037-0.045-0.063-0.105-0.08-0.181c-0.018-0.074-0.027-0.168-0.027-0.28c0-0.115,0.012-0.21,0.033-0.286
				c0.021-0.074,0.053-0.134,0.094-0.179s0.088-0.077,0.143-0.095c0.057-0.018,0.117-0.027,0.184-0.027
				c0.131,0,0.23,0.039,0.299,0.116c0.066,0.078,0.1,0.182,0.1,0.315h-0.271C58.954-23.247,58.952-23.275,58.948-23.301"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="59.673,-23.205 59.677,-23.205 59.964,-23.622 60.278,-23.622
				59.958,-23.182 60.327,-22.506 60.013,-22.506 59.774,-22.965 59.673,-22.832 59.673,-22.506 59.386,-22.506 59.386,-23.986
				59.673,-23.986 			"
        />
        <rect
          x="65.255"
          y="-26.429"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#475354"
          width="8.811"
          height="8.813"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M66.169-25.929c0.076,0,0.139,0.012,0.191,0.037
				c0.053,0.027,0.094,0.06,0.125,0.102c0.031,0.041,0.055,0.088,0.068,0.141c0.014,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.01,0.143-0.033,0.199c-0.023,0.057-0.055,0.103-0.096,0.139s-0.09,0.063-0.146,0.081
				c-0.059,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.299v-1.479H66.169z M66.077-25.26c0.059,0,0.107-0.018,0.145-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.049-0.17c-0.031-0.038-0.084-0.058-0.158-0.058h-0.125v0.45H66.077z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          points="67.171,-25.929 67.382,-24.884 67.388,-24.884 67.601,-25.929
				68.042,-25.929 68.042,-24.449 67.769,-24.449 67.769,-25.631 67.765,-25.631 67.501,-24.449 67.269,-24.449 67.007,-25.631
				67.003,-25.631 67.003,-24.449 66.728,-24.449 66.728,-25.929 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M68.495-24.869c0,0.034,0.004,0.066,0.008,0.094
				c0.006,0.028,0.018,0.053,0.031,0.072c0.016,0.019,0.037,0.035,0.063,0.045c0.025,0.012,0.057,0.016,0.096,0.016
				c0.045,0,0.086-0.013,0.123-0.044c0.037-0.029,0.057-0.075,0.057-0.137c0-0.034-0.006-0.062-0.016-0.086
				c-0.008-0.025-0.023-0.046-0.043-0.066c-0.021-0.019-0.049-0.036-0.082-0.053c-0.033-0.015-0.076-0.031-0.125-0.048
				c-0.066-0.021-0.125-0.046-0.172-0.073c-0.049-0.026-0.09-0.057-0.121-0.092c-0.033-0.035-0.059-0.076-0.072-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.08-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.127,0.007,0.184,0.021c0.055,0.016,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.096,0.124
				c0.021,0.051,0.033,0.111,0.033,0.182v0.041h-0.285c0-0.071-0.014-0.124-0.037-0.162c-0.025-0.038-0.066-0.057-0.125-0.057
				c-0.033,0-0.061,0.005-0.082,0.014c-0.023,0.01-0.041,0.023-0.055,0.038c-0.014,0.016-0.021,0.035-0.025,0.055
				c-0.006,0.021-0.008,0.042-0.008,0.065c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.123,0.086l0.23,0.099
				c0.057,0.025,0.104,0.051,0.139,0.078c0.037,0.027,0.064,0.056,0.086,0.087c0.023,0.03,0.037,0.065,0.045,0.102
				c0.01,0.038,0.014,0.079,0.014,0.125c0,0.156-0.047,0.27-0.137,0.341c-0.09,0.071-0.217,0.106-0.379,0.106
				c-0.168,0-0.289-0.036-0.361-0.109c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.297V-24.869z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M70.241-25.917h0.309v0.93h0.141v0.233H70.55v0.304h-0.273v-0.304h-0.523
				v-0.245L70.241-25.917z M70.272-25.544l-0.287,0.557h0.291v-0.557H70.272z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M71.235-25.917h0.309v0.93h0.143v0.233h-0.143v0.304h-0.273v-0.304h-0.521
				v-0.245L71.235-25.917z M71.267-25.544l-0.285,0.557h0.289v-0.557H71.267z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#FFFFFF"
          d="M72.054-25.656l-0.031,0.334h0.006c0.027-0.04,0.063-0.067,0.107-0.085
				c0.043-0.017,0.098-0.025,0.162-0.025c0.068,0,0.127,0.013,0.174,0.04s0.084,0.063,0.109,0.107
				c0.027,0.044,0.047,0.094,0.059,0.15c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.008,0.156-0.02,0.222
				c-0.016,0.066-0.039,0.123-0.074,0.171c-0.037,0.048-0.084,0.083-0.145,0.108c-0.061,0.024-0.139,0.036-0.232,0.036
				c-0.092,0-0.166-0.012-0.225-0.037c-0.057-0.025-0.102-0.058-0.133-0.099c-0.029-0.041-0.049-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287c0,0.024,0,0.05,0.004,0.078c0.004,0.028,0.01,0.054,0.02,0.078
				s0.025,0.044,0.045,0.06c0.02,0.016,0.045,0.023,0.076,0.023c0.063,0,0.107-0.023,0.135-0.071
				c0.027-0.047,0.039-0.119,0.039-0.214c0-0.061-0.002-0.111-0.008-0.151c-0.004-0.041-0.016-0.072-0.027-0.096
				c-0.014-0.024-0.031-0.04-0.055-0.05c-0.021-0.009-0.049-0.014-0.082-0.014c-0.043,0-0.076,0.012-0.098,0.036
				c-0.023,0.024-0.037,0.057-0.043,0.099h-0.268l0.053-0.798h0.766v0.232H72.054z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M12.738,75.938c0.592,0,0.973,0.391,0.973,0.99
				c0,0.594-0.381,0.986-0.973,0.986c-0.588,0-0.969-0.393-0.969-0.986C11.769,76.329,12.15,75.938,12.738,75.938 M12.738,77.526
				c0.352,0,0.457-0.301,0.457-0.598c0-0.303-0.105-0.604-0.457-0.604c-0.348,0-0.453,0.301-0.453,0.604
				C12.285,77.226,12.39,77.526,12.738,77.526"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M14.696,75.987h0.378v0.346h-0.378v0.93c0,0.174,0.044,0.217,0.218,0.217
				c0.054,0,0.105-0.004,0.16-0.014v0.402c-0.087,0.014-0.2,0.018-0.302,0.018c-0.315,0-0.591-0.072-0.591-0.445v-1.107h-0.312
				v-0.346h0.312v-0.563h0.515V75.987z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M15.744,77.052c0.014,0.326,0.174,0.475,0.46,0.475
				c0.208,0,0.374-0.127,0.407-0.242h0.454c-0.146,0.443-0.454,0.631-0.878,0.631c-0.592,0-0.958-0.406-0.958-0.986
				c0-0.563,0.388-0.992,0.958-0.992c0.638,0,0.946,0.539,0.911,1.115H15.744z M16.582,76.726c-0.047-0.262-0.16-0.4-0.41-0.4
				c-0.327,0-0.421,0.254-0.428,0.4H16.582z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M17.8,77.255c0.004,0.225,0.193,0.316,0.396,0.316
				c0.149,0,0.337-0.059,0.337-0.24c0-0.156-0.214-0.211-0.584-0.291c-0.298-0.064-0.595-0.17-0.595-0.5
				c0-0.48,0.413-0.604,0.816-0.604c0.41,0,0.788,0.139,0.828,0.6h-0.49c-0.015-0.199-0.167-0.254-0.352-0.254
				c-0.117,0-0.287,0.021-0.287,0.174c0,0.186,0.29,0.211,0.584,0.279c0.301,0.068,0.596,0.178,0.596,0.525
				c0,0.494-0.429,0.654-0.857,0.654c-0.436,0-0.86-0.164-0.882-0.66H17.8z"
        />
        <rect
          x="8.943"
          y="80.466"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M12.047,79.028h0.89c0.798,0,1.244,0.4,1.244,1.234
				c0,0.867-0.381,1.359-1.244,1.359h-0.89V79.028z M12.393,81.331h0.574c0.235,0,0.871-0.066,0.871-1.021
				c0-0.615-0.229-0.99-0.864-0.99h-0.581V81.331z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M15.36,79.7c0.595,0,0.911,0.434,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.449,80.134,14.765,79.7,15.36,79.7 M15.36,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
				C14.776,81.138,15.037,81.392,15.36,81.392"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M17.641,79.745h0.291v0.297h0.006c0.132-0.232,0.341-0.342,0.61-0.342
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.27c0-0.229-0.146-0.379-0.381-0.379c-0.374,0-0.556,0.252-0.556,0.59v1.059
				h-0.308V79.745z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M20.466,79.7c0.595,0,0.911,0.434,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.556,80.134,19.872,79.7,20.466,79.7 M20.466,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
				C19.882,81.138,20.144,81.392,20.466,81.392"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M22.167,79.745h0.374v0.271h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193H21.54v-0.271h0.319v-0.564h0.309V79.745z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M25.552,81.614c-0.055,0.031-0.123,0.049-0.222,0.049
				c-0.159,0-0.261-0.086-0.261-0.289c-0.17,0.199-0.399,0.289-0.66,0.289c-0.341,0-0.621-0.15-0.621-0.525
				c0-0.424,0.316-0.516,0.636-0.576c0.34-0.066,0.631-0.043,0.631-0.275c0-0.27-0.222-0.313-0.417-0.313
				c-0.262,0-0.454,0.08-0.469,0.355h-0.308c0.018-0.465,0.377-0.629,0.795-0.629c0.337,0,0.703,0.078,0.703,0.516v0.967
				c0,0.145,0,0.209,0.099,0.209c0.025,0,0.054-0.002,0.094-0.018V81.614z M25.051,80.651c-0.12,0.088-0.352,0.092-0.559,0.127
				c-0.203,0.037-0.377,0.109-0.377,0.338c0,0.203,0.174,0.275,0.363,0.275c0.406,0,0.573-0.254,0.573-0.424V80.651z"
        />
        <rect
          x="25.855"
          y="79.03"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          width="0.308"
          height="2.59"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M27.071,79.745h0.374v0.271h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.271h0.319v-0.564h0.309V79.745z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M29.385,81.026c-0.084,0.412-0.381,0.637-0.802,0.637
				c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.621,0.848,1.074h-1.404
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.365H29.385z M29.087,80.503
				c-0.015-0.295-0.236-0.529-0.54-0.529c-0.324,0-0.516,0.242-0.538,0.529H29.087z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M29.722,79.745h0.29v0.395h0.007c0.149-0.301,0.356-0.449,0.69-0.439v0.328
				c-0.497,0-0.679,0.281-0.679,0.758v0.834h-0.308V79.745z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M33.61,81.614c-0.055,0.031-0.123,0.049-0.223,0.049
				c-0.158,0-0.26-0.086-0.26-0.289c-0.17,0.199-0.399,0.289-0.66,0.289c-0.342,0-0.622-0.15-0.622-0.525
				c0-0.424,0.317-0.516,0.636-0.576c0.34-0.066,0.631-0.043,0.631-0.275c0-0.27-0.222-0.313-0.417-0.313
				c-0.262,0-0.454,0.08-0.469,0.355h-0.308c0.018-0.465,0.378-0.629,0.794-0.629c0.338,0,0.705,0.078,0.705,0.516v0.967
				c0,0.145,0,0.209,0.096,0.209c0.027,0,0.056-0.002,0.096-0.018V81.614z M33.109,80.651c-0.119,0.088-0.353,0.092-0.56,0.127
				c-0.203,0.037-0.377,0.109-0.377,0.338c0,0.203,0.174,0.275,0.363,0.275c0.406,0,0.573-0.254,0.573-0.424V80.651z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M33.886,79.745h0.289v0.395h0.008c0.148-0.301,0.355-0.449,0.689-0.439
				v0.328c-0.497,0-0.68,0.281-0.68,0.758v0.834h-0.307V79.745z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M35.532,79.745h0.374v0.271h-0.374v1.164c0,0.143,0.039,0.168,0.232,0.168
				h0.142v0.271H35.67c-0.319,0-0.446-0.064-0.446-0.41v-1.193h-0.32v-0.271h0.32v-0.564h0.309V79.745z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          points="38.104,81.62 37.778,81.62 37.397,80.128 37.39,80.128
				37.013,81.62 36.679,81.62 36.075,79.745 36.416,79.745 36.841,81.28 36.849,81.28 37.226,79.745 37.563,79.745 37.955,81.28
				37.963,81.28 38.384,79.745 38.703,79.745 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M39.805,79.7c0.595,0,0.911,0.434,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.432-0.911-0.979C38.894,80.134,39.21,79.7,39.805,79.7 M39.805,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711s-0.584,0.254-0.584,0.711
				C39.221,81.138,39.481,81.392,39.805,81.392"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M41.067,79.745h0.289v0.395h0.008c0.148-0.301,0.355-0.449,0.689-0.439
				v0.328c-0.497,0-0.68,0.281-0.68,0.758v0.834h-0.307V79.745z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          points="42.304,79.03 42.612,79.03 42.612,80.565 43.468,79.745
				43.882,79.745 43.138,80.427 43.937,81.62 43.544,81.62 42.901,80.64 42.612,80.909 42.612,81.62 42.304,81.62 			"
        />
        <rect
          x="8.943"
          y="84.224"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M14.117,84.44c0,0.654-0.374,0.998-1.021,0.998
				c-0.668,0-1.063-0.309-1.063-0.998v-1.654h0.345v1.654c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.654
				h0.345V84.44z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M14.804,84.786c0.011,0.275,0.25,0.363,0.505,0.363
				c0.192,0,0.453-0.045,0.453-0.281c0-0.238-0.305-0.279-0.613-0.348c-0.305-0.068-0.614-0.17-0.614-0.529
				c0-0.379,0.374-0.533,0.701-0.533c0.414,0,0.744,0.129,0.769,0.588h-0.308c-0.022-0.24-0.233-0.316-0.436-0.316
				c-0.185,0-0.399,0.051-0.399,0.238c0,0.223,0.326,0.26,0.613,0.328c0.309,0.068,0.613,0.17,0.613,0.533
				c0,0.447-0.417,0.592-0.798,0.592c-0.421,0-0.777-0.172-0.794-0.635H14.804z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M18.03,84.782c-0.083,0.414-0.381,0.639-0.802,0.639
				c-0.599,0-0.882-0.414-0.9-0.988c0-0.561,0.37-0.975,0.882-0.975c0.663,0,0.867,0.619,0.848,1.074h-1.404
				c-0.011,0.324,0.174,0.615,0.585,0.615c0.254,0,0.431-0.123,0.486-0.365H18.03z M17.732,84.259
				c-0.014-0.293-0.236-0.529-0.54-0.529c-0.324,0-0.516,0.242-0.538,0.529H17.732z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M19.813,83.501h0.374v0.271h-0.374v1.166c0,0.141,0.04,0.166,0.232,0.166
				h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319v-0.563h0.309V83.501z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M20.854,83.163h-0.308v-0.377h0.308V83.163z M20.545,83.501h0.308v1.875
				h-0.308V83.501z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M22.951,85.218c0,0.609-0.28,0.922-0.871,0.922
				c-0.352,0-0.758-0.143-0.776-0.551h0.308c0.014,0.225,0.283,0.303,0.486,0.303c0.403,0,0.563-0.289,0.563-0.711v-0.123h-0.008
				c-0.101,0.229-0.348,0.342-0.584,0.342c-0.559,0-0.838-0.439-0.838-0.951c0-0.443,0.218-0.99,0.867-0.99
				c0.236,0,0.446,0.105,0.559,0.311h0.004v-0.268h0.29V85.218z M22.646,84.401c0-0.332-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.322-0.556,0.682c0,0.338,0.124,0.715,0.53,0.715C22.494,85.126,22.646,84.753,22.646,84.401"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M23.414,82.786h0.308v0.99h0.008c0.102-0.225,0.366-0.318,0.591-0.318
				c0.494,0,0.646,0.281,0.646,0.686v1.232h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.059
				h-0.308V82.786z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M25.859,83.501h0.374v0.271h-0.374v1.166c0,0.141,0.04,0.166,0.232,0.166
				h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319v-0.563h0.309V83.501z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M28.391,83.458c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.48,83.89,27.796,83.458,28.391,83.458 M28.391,85.149
				c0.323,0,0.584-0.256,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
				C27.807,84.894,28.068,85.149,28.391,85.149"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          points="30.509,85.378 30.179,85.378 29.482,83.501 29.827,83.501
				30.353,85.065 30.36,85.065 30.872,83.501 31.195,83.501 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M33.077,84.782c-0.082,0.414-0.381,0.639-0.802,0.639
				c-0.599,0-0.882-0.414-0.9-0.988c0-0.561,0.37-0.975,0.881-0.975c0.664,0,0.867,0.619,0.85,1.074h-1.405
				c-0.011,0.324,0.175,0.615,0.584,0.615c0.254,0,0.432-0.123,0.486-0.365H33.077z M32.779,84.259
				c-0.014-0.293-0.235-0.529-0.54-0.529c-0.323,0-0.516,0.242-0.538,0.529H32.779z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M33.413,83.501h0.291v0.396h0.006c0.149-0.301,0.356-0.451,0.69-0.441v0.328
				c-0.497,0-0.678,0.283-0.678,0.758v0.836h-0.31V83.501z"
        />
        <rect
          x="34.65"
          y="82.786"
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          width="0.308"
          height="2.59"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M36.247,83.458c0.594,0,0.91,0.432,0.91,0.984
				c0,0.547-0.316,0.979-0.91,0.979c-0.596,0-0.912-0.432-0.912-0.979C35.335,83.89,35.651,83.458,36.247,83.458 M36.247,85.149
				c0.322,0,0.584-0.256,0.584-0.707c0-0.459-0.262-0.713-0.584-0.713c-0.324,0-0.584,0.254-0.584,0.713
				C35.663,84.894,35.923,85.149,36.247,85.149"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M38.794,84.104c-0.049-0.236-0.211-0.375-0.465-0.375
				c-0.447,0-0.585,0.352-0.585,0.732c0,0.346,0.156,0.688,0.548,0.688c0.297,0,0.475-0.176,0.516-0.457h0.315
				c-0.069,0.457-0.356,0.729-0.827,0.729c-0.572,0-0.879-0.398-0.879-0.959c0-0.563,0.291-1.004,0.887-1.004
				c0.424,0,0.766,0.199,0.809,0.646H38.794z"
        />
        <polygon
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          points="39.485,82.786 39.794,82.786 39.794,84.321 40.649,83.501
				41.063,83.501 40.319,84.183 41.118,85.376 40.726,85.376 40.083,84.397 39.794,84.665 39.794,85.376 39.485,85.376 			"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M41.677,83.163h-0.31v-0.377h0.31V83.163z M41.367,83.501h0.31v1.875h-0.31
				V83.501z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M42.153,83.501h0.291v0.297h0.007c0.131-0.232,0.341-0.342,0.61-0.342
				c0.494,0,0.646,0.283,0.646,0.688v1.234h-0.308v-1.271c0-0.227-0.146-0.377-0.381-0.377c-0.375,0-0.556,0.25-0.556,0.588v1.061
				h-0.31V83.501z"
        />
        <path
          clipPath="url(#PHOTOGRAPHY_SVGID_92_)"
          fill="#EC008C"
          d="M45.79,85.218c0,0.609-0.281,0.922-0.871,0.922
				c-0.353,0-0.76-0.143-0.777-0.551h0.309c0.014,0.225,0.283,0.303,0.486,0.303c0.402,0,0.563-0.289,0.563-0.711v-0.123h-0.008
				c-0.102,0.229-0.348,0.342-0.584,0.342c-0.559,0-0.838-0.439-0.838-0.951c0-0.443,0.217-0.99,0.867-0.99
				c0.236,0,0.445,0.105,0.559,0.311h0.004v-0.268h0.291V85.218z M45.483,84.401c0-0.332-0.145-0.672-0.531-0.672
				c-0.396,0-0.557,0.322-0.557,0.682c0,0.338,0.124,0.715,0.529,0.715C45.333,85.126,45.483,84.753,45.483,84.401"
        />
      </g>
    </g>
  );
};

const MediaCritic = () => {
  return (
    <g id="Media_Critic">
      <g>
        <defs>
          <rect
            id="MEDIACRITIC_SVGID_93_"
            x="0.029"
            y="0.001"
            width="61.688"
            height="61.688"
          />
        </defs>
        <clipPath id="MEDIACRITIC_SVGID_94_">
          <use xlinkHref="#MEDIACRITIC_SVGID_93_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M6.209,60.812c-2.925,0-5.304-2.379-5.304-5.305V6.181
				c0-2.924,2.379-5.304,5.304-5.304h49.326c2.926,0,5.305,2.38,5.305,5.304v49.326c0,2.924-2.379,5.305-5.305,5.305H6.209z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M55.535,1.136c2.782,0,5.045,2.263,5.045,5.045v49.326
				c0,2.783-2.263,5.045-5.045,5.045H6.209c-2.782,0-5.046-2.262-5.046-5.045V6.181c0-2.782,2.264-5.045,5.046-5.045H55.535
				 M55.535,0.618H6.209c-3.06,0-5.564,2.504-5.564,5.563v49.326c0,3.061,2.504,5.564,5.564,5.564h49.326
				c3.061,0,5.563-2.504,5.563-5.564V6.181C61.099,3.122,58.596,0.618,55.535,0.618"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#88155D"
          points="27.497,36.546 4.658,59.384 1.82,58.036 1.82,28.955 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EBB7A8"
          d="M26.049,30.296c0,3.705-3.004,6.709-6.709,6.709
				c-3.706,0-6.709-3.004-6.709-6.709c0-3.706,3.003-6.709,6.709-6.709C23.044,23.587,26.049,26.59,26.049,30.296"
        />
        <rect
          x="19.339"
          y="23.586"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EBB7A8"
          width="5.893"
          height="13.418"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#D29B81"
          d="M27.301,26.941h-4.793c-0.926,0-1.677-0.751-1.677-1.677
				c0-0.927,0.751-1.678,1.677-1.678h4.793c0.926,0,1.677,0.751,1.677,1.678C28.978,26.19,28.227,26.941,27.301,26.941"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#D29B81"
          d="M27.063,30.296H22.27c-0.926,0-1.677-0.751-1.677-1.677
				c0-0.927,0.751-1.678,1.677-1.678h4.793c0.926,0,1.677,0.751,1.677,1.678C28.74,29.545,27.989,30.296,27.063,30.296"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#D29B81"
          d="M26.587,33.649h-4.793c-0.927,0-1.677-0.75-1.677-1.676
				c0-0.927,0.75-1.678,1.677-1.678h4.793c0.926,0,1.677,0.751,1.677,1.678C28.265,32.899,27.514,33.649,26.587,33.649"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#D29B81"
          d="M26.35,37.005h-4.793c-0.926,0-1.677-0.752-1.677-1.678
				s0.751-1.678,1.677-1.678h4.793c0.926,0,1.677,0.752,1.677,1.678S27.276,37.005,26.35,37.005"
        />
        <rect
          x="3.845"
          y="26.223"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EBB7A8"
          width="12.853"
          height="8.145"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EBB7A8"
          d="M18.858,26.941l-3.896-1.18l3.355-6.125l1.168-3.085
				c0.166-0.436,0.653-0.655,1.09-0.49l0.247,0.094c1.158,0.438,1.74,1.735,1.297,2.892l-0.803,2.099L18.858,26.941z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EBB7A8"
          d="M12.401,34.368c0.902,0,1.75,0.41,2.562,1.012
				c0.812,0.6,0.562-1.73,0.562-1.73l-2.474-1.045L12.401,34.368z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EBB7A8"
          d="M22.496,26.45h0.59c0.211,0,0.382-0.171,0.382-0.382v-1.608
				c0-0.211-0.171-0.382-0.382-0.382h-0.59c-0.655,0-1.185,0.531-1.185,1.186C21.311,25.918,21.841,26.45,22.496,26.45"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EBB7A8"
          d="M22.265,29.804h0.59c0.211,0,0.382-0.171,0.382-0.382v-1.608
				c0-0.211-0.171-0.382-0.382-0.382h-0.59c-0.655,0-1.186,0.531-1.186,1.187C21.079,29.273,21.609,29.804,22.265,29.804"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EBB7A8"
          d="M21.775,33.159h0.59c0.211,0,0.382-0.172,0.382-0.383v-1.608
				c0-0.211-0.171-0.382-0.382-0.382h-0.59c-0.655,0-1.186,0.531-1.186,1.186C20.589,32.628,21.12,33.159,21.775,33.159"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EBB7A8"
          d="M21.566,36.513h0.59c0.211,0,0.382-0.17,0.382-0.381v-1.608
				c0-0.212-0.171-0.382-0.382-0.382h-0.59c-0.655,0-1.186,0.531-1.186,1.186C20.38,35.982,20.911,36.513,21.566,36.513"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#005891"
          d="M11.668,35.479H3.992V25.112h7.676c0.612,0,1.109,0.496,1.109,1.108v8.15
				C12.777,34.982,12.28,35.479,11.668,35.479"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#88155D"
          points="35.566,24.078 56.079,3.566 60.288,3.566 60.677,30.295
				39.951,29.803 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#623B23"
          d="M36.749,30.22c0-3.705,3.004-6.709,6.709-6.709
				c3.706,0,6.71,3.004,6.71,6.709s-3.004,6.709-6.71,6.709C39.753,36.929,36.749,33.925,36.749,30.22"
        />
        <rect
          x="37.565"
          y="23.51"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#623B23"
          width="5.894"
          height="13.418"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#623B23"
          d="M36.6,33.573h4.793c0.926,0,1.677,0.752,1.677,1.678
				c0,0.927-0.751,1.678-1.677,1.678H36.6c-0.926,0-1.678-0.751-1.678-1.678C34.922,34.325,35.674,33.573,36.6,33.573"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#623B23"
          d="M35.734,30.22h4.793c0.928,0,1.678,0.751,1.678,1.677
				c0,0.927-0.75,1.677-1.678,1.677h-4.793c-0.926,0-1.676-0.75-1.676-1.677C34.059,30.971,34.809,30.22,35.734,30.22"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#623B23"
          d="M36.211,26.865h4.793c0.926,0,1.677,0.751,1.677,1.677
				c0,0.926-0.751,1.678-1.677,1.678h-4.793c-0.926,0-1.678-0.752-1.678-1.678C34.533,27.616,35.285,26.865,36.211,26.865"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#623B23"
          d="M36.815,23.51h4.793c0.927,0,1.677,0.751,1.677,1.677
				c0,0.927-0.75,1.678-1.677,1.678h-4.793c-0.927,0-1.677-0.751-1.677-1.678C35.139,24.261,35.889,23.51,36.815,23.51"
        />
        <rect
          x="46.101"
          y="26.147"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#623B23"
          width="12.853"
          height="8.145"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#623B23"
          d="M43.939,33.573l3.896,1.181l-3.355,6.124l-1.168,3.086
				c-0.165,0.436-0.653,0.654-1.09,0.49l-0.248-0.094c-1.157-0.439-1.739-1.736-1.297-2.893l0.805-2.1L43.939,33.573z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#623B23"
          d="M50.397,26.147c-0.902,0-1.75-0.411-2.562-1.012
				c-0.813-0.6-0.563,1.73-0.563,1.73l2.474,1.045L50.397,26.147z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#005891"
          d="M49.808,25.112h7.677v10.366h-7.677c-0.612,0-1.108-0.496-1.108-1.108
				v-8.149C48.699,25.608,49.195,25.112,49.808,25.112"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EF97A8"
          d="M56.039,4.407c0.689,0,1.27,0.582,1.27,1.271v50.333
				c0,0.689-0.58,1.271-1.27,1.271H5.706c-0.689,0-1.271-0.582-1.271-1.271V5.678c0-0.689,0.582-1.271,1.271-1.271H56.039
				 M56.039,0.001H5.706c-3.122,0-5.677,2.555-5.677,5.677v50.333c0,3.121,2.555,5.678,5.677,5.678h50.333
				c3.122,0,5.677-2.557,5.677-5.678V5.678C61.716,2.556,59.161,0.001,56.039,0.001"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#5087C7"
          d="M30.884,55.36c-2.638,0-4.773-2.135-4.773-4.771s2.135-4.773,4.773-4.773
				c2.638,0,4.774,2.137,4.774,4.773S33.521,55.36,30.884,55.36"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M30.884,45.817c2.638,0,4.774,2.135,4.774,4.771s-2.137,4.773-4.774,4.773
				c-2.638,0-4.773-2.137-4.773-4.773S28.246,45.817,30.884,45.817 M30.884,45.405c-2.859,0-5.185,2.324-5.185,5.184
				s2.326,5.184,5.185,5.184c2.86,0,5.186-2.324,5.186-5.184S33.744,45.405,30.884,45.405"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M33.377,48.644c-0.238,0-0.464,0.107-0.619,0.252l-0.014-0.014
				c0.072-0.117,0.169-0.346,0.169-0.715c0-0.883-0.884-1.551-2.029-1.551s-2.041,0.668-2.041,1.551
				c0,0.369,0.119,0.598,0.179,0.715l-0.013,0.014c-0.154-0.145-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.705-1.457,2.018
				c0,1.287,0.717,2.016,1.492,2.016c0.716,0,1.218-0.395,1.671-0.848c-0.072-0.107-0.18-0.215-0.298-0.215
				c-0.12,0-0.252,0.084-0.477,0.264c-0.299,0.227-0.562,0.357-0.908,0.357c-0.609,0-0.87-0.682-0.87-1.574
				c0-0.955,0.251-1.588,0.895-1.588c0.286,0,0.476,0.084,0.668,0.262c0.286,0.264,0.381,0.334,0.512,0.334
				c0.096,0,0.156-0.059,0.18-0.094c-0.215-0.215-0.525-0.668-0.525-1.254c0-0.668,0.56-1.133,1.611-1.133
				c1.049,0,1.611,0.465,1.611,1.133c0,0.586-0.312,1.039-0.525,1.254c0.023,0.035,0.083,0.094,0.178,0.094
				c0.132,0,0.227-0.07,0.513-0.334c0.179-0.178,0.382-0.262,0.668-0.262c0.646,0,0.896,0.633,0.896,1.588
				c0,0.893-0.263,1.574-0.871,1.574c-0.346,0-0.621-0.131-0.907-0.357c-0.226-0.18-0.357-0.264-0.476-0.264
				c-0.12,0-0.227,0.107-0.299,0.215c0.441,0.453,0.955,0.848,1.671,0.848c0.775,0,1.491-0.729,1.491-2.016
				C34.833,49.349,34.105,48.644,33.377,48.644"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M31.683,48.62h-0.537c-0.035,0-0.059-0.023-0.071-0.049l-0.168-0.512
				c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.512c-0.012,0.037-0.035,0.049-0.059,0.049h-0.537c-0.024,0-0.036,0.012-0.012,0.023
				l0.442,0.322c0.023,0.023,0.023,0.061,0.011,0.084l-0.167,0.5c-0.011,0.023-0.011,0.049,0.024,0.023l0.442-0.309
				c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.036,0.014,0.023-0.01L31.23,49.05c-0.012-0.035,0-0.072,0.024-0.084
				l0.44-0.322C31.719,48.632,31.707,48.62,31.683,48.62"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M31.277,52.151c-0.226-0.572-0.406-0.967-0.406-1.443
				c0-0.203,0.06-0.381,0.096-0.477c0.048-0.096,0.06-0.156,0.012-0.156c-0.072,0-0.227,0.168-0.322,0.311
				c-0.073,0.119-0.156,0.346-0.156,0.525c0,0.334,0.108,0.691,0.179,0.906c0.132,0.369,0.251,0.801,0.251,1.205
				c0,0.646-0.287,1.084-0.799,1.479c0.047,0.037,0.167,0.096,0.31,0.096c0.5,0,1.098-0.559,1.098-1.324
				C31.54,52.819,31.409,52.509,31.277,52.151"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          points="18.621,11.139 17.67,11.139 17.67,9.016 16.551,11.139 16.1,11.139
				14.977,9.004 14.977,11.139 14.025,11.139 14.025,7.155 14.987,7.155 16.325,9.682 17.663,7.155 18.621,7.155 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M22.275,9.89h-2.188c0.078,0.356,0.321,0.577,0.642,0.577
				c0.22,0,0.476-0.054,0.638-0.345l0.843,0.172c-0.255,0.63-0.796,0.934-1.481,0.934c-0.862,0-1.575-0.637-1.575-1.582
				c0-0.939,0.713-1.581,1.582-1.581c0.844,0,1.522,0.606,1.539,1.581V9.89z M20.105,9.307h1.232
				c-0.09-0.321-0.328-0.469-0.601-0.469C20.468,8.838,20.195,9.004,20.105,9.307"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M25.891,11.139H24.94v-0.238c-0.232,0.208-0.542,0.327-0.928,0.327
				c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.386,0,0.696,0.119,0.928,0.327V6.857h0.951V11.139z
				 M24.94,9.646c0-0.458-0.345-0.773-0.732-0.773c-0.416,0-0.719,0.315-0.719,0.773c0,0.464,0.303,0.773,0.719,0.773
				C24.595,10.419,24.94,10.11,24.94,9.646"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M27.588,7.273c0,0.303-0.232,0.53-0.553,0.53
				c-0.321,0-0.547-0.227-0.547-0.53c0-0.291,0.226-0.541,0.547-0.541C27.356,6.732,27.588,6.982,27.588,7.273 M26.56,8.153h0.951
				v2.985H26.56V8.153z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M31.309,11.139h-0.951v-0.238c-0.232,0.208-0.542,0.327-0.928,0.327
				c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.582,1.457-1.582c0.386,0,0.696,0.119,0.928,0.327V8.153h0.951V11.139z
				 M30.358,9.646c0-0.458-0.345-0.773-0.732-0.773c-0.416,0-0.719,0.315-0.719,0.773c0,0.464,0.303,0.773,0.719,0.773
				C30.013,10.419,30.358,10.11,30.358,9.646"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M35.342,9.872l0.874,0.19c-0.149,0.707-0.778,1.165-1.522,1.165
				c-0.892,0-1.629-0.636-1.629-1.581c0-0.939,0.737-1.582,1.629-1.582c0.726,0,1.355,0.446,1.522,1.148l-0.892,0.214
				c-0.084-0.363-0.327-0.529-0.631-0.529c-0.428,0-0.713,0.315-0.713,0.749c0,0.44,0.285,0.749,0.713,0.749
				C34.997,10.395,35.24,10.235,35.342,9.872"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M38.85,8.136l-0.041,0.952h-0.172c-0.666,0-1.029,0.344-1.029,1.117v0.934
				h-0.951V8.154h0.951v0.571c0.213-0.357,0.541-0.613,1.029-0.613C38.719,8.112,38.779,8.118,38.85,8.136"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M40.225,7.273c0,0.303-0.232,0.53-0.553,0.53
				c-0.321,0-0.547-0.227-0.547-0.53c0-0.291,0.226-0.541,0.547-0.541C39.992,6.732,40.225,6.982,40.225,7.273 M39.195,8.153h0.951
				v2.985h-0.951V8.153z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M43.016,11.008c-0.231,0.125-0.477,0.22-0.814,0.22
				c-0.744,0-1.189-0.404-1.189-1.189v-1.13h-0.541V8.154h0.541v-0.88h0.951v0.88h0.879v0.755h-0.879v1.035
				c0,0.309,0.148,0.434,0.398,0.434c0.131,0,0.309-0.048,0.434-0.119L43.016,11.008z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M44.418,7.273c0,0.303-0.233,0.53-0.555,0.53
				c-0.32,0-0.546-0.227-0.546-0.53c0-0.291,0.226-0.541,0.546-0.541C44.185,6.732,44.418,6.982,44.418,7.273 M43.389,8.153h0.951
				v2.985h-0.951V8.153z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M47.08,9.872l0.873,0.19c-0.148,0.707-0.779,1.165-1.521,1.165
				c-0.893,0-1.631-0.636-1.631-1.581c0-0.939,0.738-1.582,1.631-1.582c0.725,0,1.355,0.446,1.521,1.148l-0.892,0.214
				c-0.083-0.363-0.327-0.529-0.63-0.529c-0.428,0-0.713,0.315-0.713,0.749c0,0.44,0.285,0.749,0.713,0.749
				C46.734,10.395,46.979,10.235,47.08,9.872"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-2.406-25.93c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.069,0.141C-2.007-25.596-2-25.542-2-25.487
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				C-2.335-25.05-2.4-25.041-2.47-25.041h-0.16v0.59h-0.298v-1.479H-2.406z M-2.497-25.261c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058H-2.63v0.45H-2.497z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          points="-1.403,-25.93 -1.192,-24.885 -1.188,-24.885 -0.974,-25.93
				-0.532,-25.93 -0.532,-24.45 -0.806,-24.45 -0.806,-25.632 -0.811,-25.632 -1.073,-24.45 -1.306,-24.45 -1.568,-25.632
				-1.572,-25.632 -1.572,-24.45 -1.846,-24.45 -1.846,-25.93 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-0.079-24.869c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071s0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.014,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.024-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.022
				c0.056,0.015,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0.273
				c0-0.07-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.015
				c-0.023,0.009-0.04,0.022-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087C0.571-25.071,0.586-25.036,0.595-25c0.007,0.038,0.011,0.079,0.011,0.125
				c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.107-0.378,0.107c-0.169,0-0.29-0.037-0.362-0.11
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-2.406-23.987c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.069,0.141C-2.007-23.653-2-23.599-2-23.544
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.406z M-2.497-23.318c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058H-2.63v0.45H-2.497z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-1.587-23.623v0.149h0.004c0.029-0.053,0.064-0.097,0.106-0.129
				c0.041-0.032,0.092-0.049,0.151-0.049c0.008,0,0.017,0,0.026,0.001c0.009,0.001,0.018,0.002,0.026,0.003v0.274
				c-0.016-0.001-0.03-0.002-0.045-0.003c-0.014-0.001-0.029-0.001-0.044-0.001c-0.025,0-0.05,0.003-0.075,0.01
				c-0.025,0.007-0.047,0.018-0.068,0.034s-0.037,0.037-0.05,0.062c-0.012,0.026-0.019,0.057-0.019,0.095v0.669h-0.286v-1.115
				H-1.587z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-1.186-23.317c0.016-0.073,0.041-0.135,0.075-0.184
				c0.034-0.048,0.08-0.086,0.136-0.111c0.056-0.026,0.125-0.039,0.206-0.039c0.086-0.001,0.157,0.01,0.214,0.034
				c0.057,0.025,0.101,0.061,0.134,0.111c0.033,0.05,0.057,0.111,0.071,0.185c0.013,0.073,0.021,0.159,0.021,0.257
				c0,0.095-0.008,0.179-0.023,0.253c-0.016,0.072-0.04,0.134-0.075,0.183c-0.035,0.049-0.08,0.085-0.136,0.109
				c-0.056,0.023-0.124,0.035-0.206,0.035c-0.086,0.001-0.157-0.009-0.213-0.032c-0.057-0.023-0.102-0.059-0.135-0.108
				c-0.033-0.049-0.056-0.11-0.071-0.185c-0.013-0.073-0.021-0.159-0.021-0.255C-1.209-23.16-1.201-23.245-1.186-23.317
				 M-0.918-22.908c0.003,0.049,0.009,0.09,0.02,0.124c0.01,0.035,0.026,0.062,0.046,0.081c0.021,0.02,0.049,0.029,0.083,0.029
				c0.058,0,0.099-0.03,0.12-0.091c0.022-0.061,0.034-0.161,0.034-0.3c0-0.14-0.012-0.24-0.034-0.301
				c-0.021-0.061-0.062-0.091-0.12-0.091c-0.034,0-0.062,0.01-0.083,0.029c-0.02,0.019-0.036,0.046-0.046,0.081
				c-0.011,0.034-0.017,0.076-0.02,0.124c-0.003,0.048-0.004,0.101-0.004,0.158C-0.922-23.008-0.921-22.956-0.918-22.908"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M0.355-23.301c-0.004-0.026-0.01-0.05-0.019-0.071
				c-0.009-0.022-0.021-0.039-0.038-0.052c-0.017-0.014-0.039-0.02-0.066-0.02c-0.035,0-0.063,0.008-0.085,0.024
				c-0.023,0.016-0.039,0.039-0.05,0.07c-0.011,0.031-0.019,0.071-0.023,0.118c-0.004,0.048-0.007,0.104-0.007,0.169
				c0,0.07,0.003,0.128,0.007,0.178c0.004,0.048,0.012,0.089,0.023,0.121c0.011,0.032,0.026,0.055,0.047,0.069
				c0.02,0.015,0.046,0.022,0.077,0.022c0.048,0,0.084-0.021,0.105-0.062c0.023-0.042,0.034-0.108,0.034-0.199h0.274
				c0,0.146-0.033,0.258-0.099,0.335c-0.065,0.076-0.174,0.115-0.326,0.115c-0.072,0-0.135-0.009-0.188-0.026
				c-0.053-0.018-0.097-0.049-0.132-0.094c-0.036-0.044-0.063-0.104-0.081-0.18c-0.017-0.075-0.026-0.169-0.026-0.281
				c0-0.115,0.011-0.209,0.033-0.285c0.022-0.075,0.053-0.135,0.093-0.18s0.088-0.076,0.143-0.094
				c0.056-0.018,0.116-0.027,0.183-0.027c0.132,0,0.232,0.038,0.299,0.116c0.067,0.077,0.101,0.182,0.101,0.315H0.36
				C0.36-23.248,0.359-23.275,0.355-23.301"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M1.028-22.932c0,0.033,0.003,0.065,0.007,0.096
				c0.005,0.032,0.013,0.059,0.024,0.083c0.011,0.024,0.026,0.043,0.044,0.058c0.018,0.015,0.04,0.022,0.067,0.022
				c0.047,0,0.083-0.017,0.105-0.052c0.022-0.034,0.037-0.087,0.046-0.158h0.256c-0.005,0.13-0.04,0.229-0.103,0.297
				c-0.064,0.068-0.164,0.102-0.301,0.102c-0.103,0-0.184-0.017-0.242-0.053c-0.058-0.034-0.101-0.079-0.128-0.136
				c-0.028-0.057-0.045-0.12-0.052-0.191c-0.006-0.07-0.009-0.141-0.009-0.211c0-0.075,0.005-0.147,0.016-0.216
				c0.01-0.068,0.031-0.131,0.062-0.184c0.031-0.054,0.075-0.097,0.134-0.128c0.057-0.033,0.135-0.048,0.232-0.048
				c0.082,0,0.151,0.013,0.204,0.04c0.053,0.027,0.095,0.065,0.126,0.114c0.03,0.049,0.05,0.109,0.061,0.178
				c0.011,0.07,0.017,0.148,0.017,0.234v0.064H1.028V-22.932z M1.312-23.304c-0.004-0.032-0.011-0.06-0.022-0.083
				c-0.01-0.023-0.025-0.04-0.043-0.052c-0.019-0.012-0.043-0.017-0.072-0.017c-0.029,0-0.053,0.006-0.071,0.019
				c-0.018,0.014-0.034,0.031-0.045,0.052c-0.011,0.021-0.019,0.046-0.024,0.071c-0.004,0.027-0.007,0.053-0.007,0.079v0.044h0.293
				C1.319-23.234,1.316-23.272,1.312-23.304"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M1.953-22.834c0,0.048,0.012,0.087,0.038,0.117
				c0.026,0.03,0.063,0.045,0.111,0.045c0.044,0,0.079-0.012,0.106-0.034c0.026-0.022,0.039-0.055,0.039-0.099
				c0-0.036-0.01-0.063-0.031-0.082s-0.045-0.034-0.072-0.044l-0.202-0.073c-0.078-0.028-0.138-0.067-0.178-0.118
				c-0.039-0.049-0.06-0.114-0.06-0.193c0-0.046,0.008-0.089,0.023-0.13c0.015-0.04,0.039-0.076,0.073-0.107
				c0.033-0.03,0.075-0.054,0.127-0.072c0.052-0.018,0.115-0.027,0.188-0.027c0.129,0,0.226,0.028,0.288,0.083
				c0.062,0.055,0.093,0.133,0.093,0.234v0.046H2.238c0-0.058-0.009-0.1-0.028-0.127c-0.019-0.028-0.052-0.041-0.098-0.041
				c-0.037,0-0.068,0.01-0.095,0.03s-0.04,0.05-0.04,0.09c0,0.028,0.008,0.053,0.026,0.075c0.017,0.022,0.05,0.04,0.098,0.055
				l0.172,0.059c0.09,0.03,0.154,0.07,0.191,0.119c0.037,0.049,0.056,0.116,0.056,0.2c0,0.059-0.01,0.111-0.031,0.155
				c-0.021,0.043-0.05,0.079-0.086,0.108c-0.037,0.029-0.081,0.05-0.131,0.063c-0.05,0.012-0.106,0.018-0.167,0.018
				c-0.079,0-0.146-0.008-0.2-0.023c-0.052-0.015-0.095-0.037-0.126-0.068c-0.032-0.031-0.053-0.068-0.065-0.111
				c-0.013-0.044-0.019-0.093-0.019-0.146v-0.039h0.257V-22.834z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M2.873-22.834c0,0.048,0.013,0.087,0.038,0.117
				c0.026,0.03,0.063,0.045,0.111,0.045c0.045,0,0.08-0.012,0.106-0.034s0.039-0.055,0.039-0.099c0-0.036-0.01-0.063-0.03-0.082
				c-0.021-0.019-0.045-0.034-0.073-0.044l-0.201-0.073c-0.079-0.028-0.138-0.067-0.178-0.118c-0.04-0.049-0.06-0.114-0.06-0.193
				c0-0.046,0.007-0.089,0.022-0.13c0.016-0.04,0.04-0.076,0.073-0.107c0.033-0.03,0.076-0.054,0.127-0.072
				c0.052-0.018,0.115-0.027,0.188-0.027c0.129,0,0.226,0.028,0.288,0.083c0.062,0.055,0.094,0.133,0.094,0.234v0.046H3.159
				c0-0.058-0.01-0.1-0.029-0.127c-0.019-0.028-0.051-0.041-0.098-0.041c-0.036,0-0.067,0.01-0.095,0.03
				c-0.027,0.02-0.04,0.05-0.04,0.09c0,0.028,0.009,0.053,0.026,0.075s0.05,0.04,0.098,0.055l0.172,0.059
				c0.09,0.03,0.154,0.07,0.191,0.119c0.038,0.049,0.056,0.116,0.056,0.2c0,0.059-0.01,0.111-0.031,0.155
				c-0.021,0.043-0.049,0.079-0.086,0.108c-0.036,0.029-0.08,0.05-0.131,0.063c-0.05,0.012-0.105,0.018-0.167,0.018
				c-0.079,0-0.146-0.008-0.2-0.023c-0.052-0.015-0.094-0.037-0.126-0.068c-0.031-0.031-0.053-0.068-0.065-0.111
				c-0.012-0.044-0.019-0.093-0.019-0.146v-0.039h0.257V-22.834z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          points="-2.479,-22.044 -2.268,-21 -2.263,-21 -2.05,-22.044
				-1.608,-22.044 -1.608,-20.564 -1.882,-20.564 -1.882,-21.746 -1.886,-21.746 -2.149,-20.564 -2.382,-20.564 -2.644,-21.746
				-2.648,-21.746 -2.648,-20.564 -2.922,-20.564 -2.922,-22.044 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-1.405-21.365c0-0.065,0.011-0.119,0.032-0.163
				c0.02-0.043,0.048-0.078,0.083-0.106c0.035-0.026,0.077-0.046,0.124-0.057c0.048-0.012,0.099-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025c0.053,0.017,0.093,0.04,0.122,0.07c0.03,0.031,0.049,0.067,0.059,0.109
				c0.011,0.042,0.016,0.088,0.016,0.138v0.587c0,0.052,0.002,0.093,0.007,0.121c0.005,0.03,0.014,0.061,0.028,0.094h-0.274
				c-0.009-0.018-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059h-0.004c-0.033,0.058-0.071,0.096-0.114,0.113
				c-0.044,0.017-0.101,0.026-0.17,0.026c-0.049,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.072
				c-0.021-0.03-0.036-0.064-0.046-0.102c-0.009-0.038-0.014-0.076-0.014-0.113c0-0.052,0.005-0.098,0.016-0.136
				c0.012-0.038,0.029-0.07,0.052-0.097c0.022-0.028,0.051-0.05,0.087-0.068c0.034-0.018,0.077-0.033,0.127-0.048l0.161-0.043
				c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.077-0.03-0.101
				s-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.099,0.039c-0.023,0.027-0.034,0.062-0.034,0.106v0.031h-0.257V-21.365z
				 M-0.945-21.085c-0.022,0.009-0.042,0.015-0.06,0.02c-0.058,0.012-0.1,0.033-0.124,0.061c-0.025,0.03-0.038,0.069-0.038,0.119
				c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.045,0.045,0.083,0.045c0.019,0,0.039-0.003,0.06-0.009
				c0.021-0.007,0.04-0.016,0.058-0.03c0.017-0.014,0.031-0.032,0.042-0.054c0.01-0.021,0.016-0.048,0.016-0.079v-0.219
				C-0.902-21.106-0.923-21.094-0.945-21.085"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M0.429-20.621c0,0.144-0.036,0.252-0.107,0.324
				c-0.071,0.071-0.19,0.108-0.358,0.108c-0.121,0-0.215-0.026-0.281-0.078c-0.065-0.052-0.098-0.126-0.098-0.221h0.273
				c0,0.036,0.015,0.064,0.044,0.085c0.014,0.009,0.029,0.017,0.045,0.022c0.016,0.006,0.033,0.009,0.051,0.009
				c0.058,0,0.098-0.018,0.122-0.056c0.023-0.038,0.035-0.084,0.035-0.139v-0.147H0.151c-0.029,0.042-0.065,0.074-0.107,0.099
				c-0.042,0.026-0.086,0.037-0.131,0.037c-0.114,0-0.199-0.042-0.256-0.129c-0.058-0.086-0.086-0.231-0.086-0.434
				c0-0.065,0.003-0.131,0.011-0.199c0.008-0.068,0.024-0.129,0.048-0.184c0.024-0.054,0.059-0.099,0.106-0.133
				c0.046-0.035,0.107-0.052,0.185-0.052c0.04,0,0.081,0.011,0.124,0.033c0.043,0.022,0.078,0.062,0.106,0.121h0.004v-0.125h0.274
				V-20.621z M-0.138-20.977c0.003,0.034,0.01,0.065,0.021,0.092c0.01,0.027,0.025,0.048,0.043,0.064
				c0.018,0.016,0.043,0.024,0.074,0.024c0.03,0,0.055-0.008,0.075-0.024c0.02-0.016,0.036-0.037,0.048-0.065
				c0.012-0.028,0.02-0.061,0.025-0.101c0.005-0.039,0.007-0.082,0.007-0.129c0-0.135-0.011-0.231-0.032-0.288
				c-0.022-0.057-0.06-0.085-0.117-0.085c-0.032,0-0.057,0.007-0.077,0.021c-0.019,0.013-0.035,0.036-0.045,0.067
				c-0.011,0.031-0.019,0.072-0.022,0.125c-0.004,0.051-0.006,0.115-0.006,0.189C-0.144-21.048-0.142-21.012-0.138-20.977"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M0.875-20.989c0,0.033,0.002,0.065,0.007,0.096
				c0.005,0.032,0.013,0.059,0.024,0.083c0.011,0.024,0.026,0.043,0.044,0.058c0.017,0.015,0.04,0.022,0.066,0.022
				c0.048,0,0.083-0.017,0.106-0.052c0.022-0.034,0.037-0.087,0.045-0.158h0.257c-0.005,0.13-0.04,0.229-0.103,0.297
				c-0.064,0.068-0.164,0.102-0.301,0.102c-0.104,0-0.184-0.017-0.242-0.053c-0.059-0.034-0.101-0.079-0.129-0.136
				c-0.028-0.057-0.044-0.12-0.051-0.191c-0.006-0.07-0.009-0.141-0.009-0.211c0-0.075,0.005-0.147,0.016-0.216
				c0.009-0.068,0.03-0.131,0.062-0.184c0.03-0.054,0.075-0.097,0.133-0.128c0.058-0.033,0.136-0.048,0.233-0.048
				c0.082,0,0.15,0.013,0.203,0.04c0.054,0.027,0.096,0.065,0.126,0.114c0.03,0.05,0.051,0.109,0.062,0.179
				c0.011,0.069,0.017,0.147,0.017,0.233v0.064H0.875V-20.989z M1.159-21.361c-0.005-0.032-0.011-0.06-0.022-0.083
				c-0.01-0.023-0.025-0.04-0.043-0.052c-0.019-0.012-0.043-0.017-0.072-0.017c-0.029,0-0.053,0.006-0.071,0.019
				c-0.019,0.014-0.034,0.031-0.045,0.052c-0.011,0.021-0.019,0.046-0.024,0.071c-0.005,0.027-0.007,0.053-0.007,0.079v0.044h0.292
				C1.166-21.291,1.163-21.329,1.159-21.361"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M1.863-21.68v0.131h0.005c0.03-0.055,0.069-0.096,0.117-0.121
				c0.049-0.026,0.104-0.039,0.167-0.039c0.09,0,0.16,0.025,0.209,0.075c0.05,0.05,0.074,0.13,0.074,0.242v0.827H2.15v-0.769
				c0-0.058-0.011-0.098-0.03-0.121c-0.019-0.023-0.05-0.034-0.093-0.034c-0.101,0-0.152,0.061-0.152,0.184v0.74H1.59v-1.115H1.863z
				"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M2.974-21.999v0.319h0.165v0.195H2.974v0.603
				c0,0.044,0.007,0.076,0.02,0.094c0.013,0.019,0.04,0.029,0.081,0.029c0.011,0,0.022-0.001,0.034-0.001
				c0.011-0.001,0.021-0.002,0.03-0.003v0.199c-0.031,0-0.062,0.001-0.091,0.002c-0.03,0.002-0.062,0.003-0.095,0.003
				c-0.055,0-0.1-0.004-0.135-0.01c-0.036-0.008-0.063-0.024-0.081-0.047c-0.019-0.022-0.032-0.052-0.039-0.09
				c-0.007-0.037-0.01-0.084-0.01-0.14v-0.639H2.543v-0.195h0.145v-0.319H2.974z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M3.237-21.364c0-0.065,0.011-0.119,0.032-0.163
				c0.02-0.043,0.048-0.078,0.083-0.106c0.035-0.026,0.077-0.046,0.124-0.057c0.048-0.012,0.099-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025c0.053,0.017,0.093,0.04,0.122,0.07c0.03,0.031,0.049,0.067,0.059,0.109
				c0.011,0.042,0.016,0.088,0.016,0.138v0.587c0,0.052,0.002,0.093,0.007,0.121c0.005,0.03,0.014,0.062,0.029,0.094H3.794
				c-0.009-0.018-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059H3.755c-0.033,0.058-0.071,0.096-0.114,0.113
				c-0.044,0.017-0.101,0.026-0.17,0.026c-0.049,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.062-0.041-0.083-0.072
				c-0.021-0.03-0.036-0.064-0.046-0.102c-0.009-0.038-0.014-0.076-0.014-0.113c0-0.052,0.005-0.098,0.016-0.136
				c0.012-0.038,0.029-0.07,0.052-0.097c0.022-0.028,0.051-0.05,0.087-0.068c0.034-0.018,0.077-0.033,0.127-0.048l0.161-0.043
				c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.077-0.03-0.101
				s-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.099,0.039c-0.023,0.027-0.034,0.062-0.034,0.106v0.031H3.237V-21.364z
				 M3.697-21.085c-0.022,0.009-0.042,0.015-0.06,0.02c-0.058,0.012-0.1,0.033-0.124,0.061c-0.025,0.03-0.038,0.069-0.038,0.119
				c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.045,0.045,0.083,0.045c0.019,0,0.039-0.003,0.06-0.009
				c0.021-0.007,0.04-0.016,0.058-0.03c0.017-0.014,0.031-0.032,0.042-0.054c0.01-0.022,0.016-0.048,0.016-0.079v-0.219
				C3.74-21.106,3.719-21.094,3.697-21.085"
        />
        <rect
          x="-13.188"
          y="-26.43"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EF97A8"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-12.274-25.93c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.274z M-12.366-25.261c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H-12.366z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          points="-11.271,-25.93 -11.061,-24.885 -11.056,-24.885 -10.843,-25.93
				-10.401,-25.93 -10.401,-24.45 -10.675,-24.45 -10.675,-25.632 -10.679,-25.632 -10.942,-24.45 -11.175,-24.45 -11.437,-25.632
				-11.441,-25.632 -11.441,-24.45 -11.715,-24.45 -11.715,-25.93 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-9.947-24.869c0,0.034,0.003,0.066,0.008,0.094
				c0.005,0.028,0.016,0.053,0.031,0.072c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.013,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.025-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.184,0.021c0.056,0.016,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.071-0.013-0.124-0.038-0.162c-0.024-0.038-0.066-0.057-0.123-0.057
				c-0.033,0-0.061,0.005-0.083,0.014c-0.023,0.01-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
				c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.271-0.135,0.341s-0.216,0.106-0.378,0.106
				c-0.169,0-0.29-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-8.341-25.487h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.235-0.056
				c0.057-0.038,0.094-0.097,0.109-0.18h0.219v1.467h-0.298V-25.487z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-7.343-24.663c0.02,0.031,0.055,0.046,0.105,0.046
				c0.04,0,0.072-0.009,0.096-0.028c0.023-0.018,0.041-0.045,0.054-0.078c0.013-0.033,0.022-0.072,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.025,0.037-0.06,0.066-0.106,0.09c-0.047,0.022-0.103,0.034-0.169,0.034
				c-0.065,0-0.12-0.012-0.166-0.034c-0.046-0.024-0.083-0.055-0.111-0.096c-0.028-0.04-0.049-0.089-0.062-0.145
				c-0.014-0.056-0.02-0.117-0.02-0.183c0-0.17,0.038-0.3,0.113-0.39c0.075-0.09,0.196-0.134,0.362-0.134
				c0.102,0,0.183,0.018,0.244,0.057c0.061,0.038,0.108,0.088,0.14,0.151c0.032,0.063,0.054,0.136,0.064,0.219
				c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.004,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.057,0.259
				c-0.03,0.074-0.08,0.133-0.146,0.176c-0.068,0.044-0.162,0.065-0.281,0.065c-0.053,0-0.104-0.004-0.153-0.015
				c-0.049-0.01-0.092-0.03-0.129-0.058s-0.068-0.066-0.091-0.114c-0.023-0.047-0.034-0.107-0.036-0.177h0.287
				C-7.372-24.737-7.363-24.694-7.343-24.663 M-7.358-25.219c0.025,0.043,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.126-0.064
				c0.025-0.042,0.038-0.115,0.038-0.213c0-0.042-0.002-0.08-0.006-0.117c-0.004-0.035-0.012-0.066-0.024-0.092
				c-0.012-0.025-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.064,0.008-0.086,0.022
				c-0.021,0.015-0.037,0.035-0.049,0.061c-0.011,0.026-0.018,0.057-0.021,0.092c-0.004,0.036-0.005,0.074-0.005,0.116
				C-7.396-25.334-7.383-25.261-7.358-25.219"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M-5.779-25.661c-0.061,0.086-0.116,0.177-0.163,0.275
				c-0.047,0.099-0.089,0.2-0.126,0.303c-0.035,0.103-0.065,0.21-0.089,0.316c-0.023,0.107-0.041,0.212-0.053,0.316h-0.314
				c0.012-0.088,0.028-0.181,0.051-0.279c0.021-0.097,0.051-0.196,0.088-0.297c0.036-0.101,0.081-0.202,0.133-0.305
				c0.053-0.103,0.115-0.203,0.186-0.3h-0.617v-0.257h0.904V-25.661z"
        />
        <rect
          x="6.548"
          y="-26.43"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#88155D"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M7.462-25.93c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59H6.94v-1.479H7.462z M7.371-25.261c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058H7.238v0.45H7.371z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          points="8.465,-25.93 8.676,-24.885 8.681,-24.885 8.894,-25.93
				9.336,-25.93 9.336,-24.45 9.062,-24.45 9.062,-25.632 9.058,-25.632 8.795,-24.45 8.562,-24.45 8.3,-25.632 8.296,-25.632
				8.296,-24.45 8.022,-24.45 8.022,-25.93 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M9.79-24.869c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.014,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.024-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053C9.992-25.043,9.95-25.059,9.9-25.076
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.022
				c0.056,0.015,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.07-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.015
				c-0.023,0.009-0.04,0.022-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102c0.007,0.038,0.011,0.079,0.011,0.125
				c0,0.156-0.045,0.271-0.135,0.341s-0.216,0.107-0.378,0.107c-0.169,0-0.29-0.037-0.362-0.11
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061H9.79V-24.869z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M11.056-24.45c0-0.065,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.051,0.06-0.101,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.149l0.121-0.111
				c0.033-0.03,0.06-0.056,0.08-0.081s0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.011,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.109h-0.287v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.104
				c0.072,0.069,0.108,0.168,0.108,0.296c0,0.047-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H11.056z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M12.05-24.45c0-0.065,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.051,0.06-0.101,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.149l0.121-0.111
				c0.033-0.03,0.06-0.056,0.08-0.081c0.02-0.025,0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.011,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.109H12.05v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.104
				c0.072,0.069,0.108,0.168,0.108,0.296c0,0.047-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H12.05z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M13.099-25.103c0.042-0.067,0.104-0.108,0.187-0.125v-0.004
				c-0.04-0.01-0.074-0.025-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.07c-0.016-0.027-0.028-0.054-0.035-0.083
				s-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.115-0.31c0.076-0.063,0.181-0.094,0.316-0.094c0.134,0,0.239,0.031,0.315,0.094
				c0.077,0.063,0.116,0.166,0.116,0.31c0,0.026-0.004,0.054-0.012,0.083c-0.007,0.029-0.019,0.056-0.036,0.083
				c-0.016,0.026-0.038,0.049-0.065,0.07c-0.027,0.021-0.06,0.036-0.099,0.046v0.004c0.083,0.017,0.145,0.058,0.187,0.125
				c0.041,0.066,0.062,0.145,0.062,0.238c0,0.043-0.005,0.09-0.015,0.141c-0.011,0.051-0.033,0.098-0.066,0.143
				c-0.033,0.044-0.08,0.08-0.142,0.11c-0.061,0.028-0.143,0.043-0.245,0.043s-0.184-0.015-0.246-0.043
				c-0.062-0.03-0.109-0.066-0.142-0.11c-0.033-0.045-0.055-0.092-0.065-0.143c-0.011-0.051-0.016-0.098-0.016-0.141
				C13.037-24.958,13.058-25.037,13.099-25.103 M13.343-24.771c0.005,0.031,0.014,0.058,0.027,0.081
				c0.013,0.023,0.031,0.041,0.053,0.054c0.022,0.013,0.05,0.019,0.083,0.019c0.033,0,0.06-0.006,0.083-0.019
				c0.021-0.013,0.039-0.031,0.052-0.054c0.013-0.023,0.023-0.05,0.027-0.081c0.005-0.031,0.008-0.064,0.008-0.099
				s-0.003-0.068-0.008-0.099c-0.004-0.03-0.014-0.057-0.027-0.08c-0.013-0.023-0.031-0.04-0.052-0.053
				c-0.023-0.014-0.05-0.02-0.083-0.02c-0.033,0-0.061,0.006-0.083,0.02c-0.022,0.013-0.04,0.03-0.053,0.053
				c-0.013,0.022-0.022,0.05-0.027,0.08c-0.005,0.031-0.007,0.064-0.007,0.099S13.338-24.802,13.343-24.771 M13.4-25.367
				c0.026,0.033,0.061,0.05,0.106,0.05c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155
				c0-0.135-0.048-0.201-0.145-0.201c-0.097,0-0.145,0.066-0.145,0.201C13.361-25.452,13.374-25.399,13.4-25.367"
        />
        <rect
          x="16.416"
          y="-26.43"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#005891"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M17.331-25.93c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.331z M17.239-25.261c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H17.239z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          points="18.333,-25.93 18.544,-24.885 18.549,-24.885 18.763,-25.93
				19.205,-25.93 19.205,-24.45 18.931,-24.45 18.931,-25.632 18.926,-25.632 18.664,-24.45 18.431,-24.45 18.168,-25.632
				18.165,-25.632 18.165,-24.45 17.891,-24.45 17.891,-25.93 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M19.658-24.869c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.014,0.123-0.045c0.037-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.024-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.128,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041H20.01c0-0.07-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.022,0.009-0.04,0.022-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102
				c0.007,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.341c-0.09,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M20.924-24.45c0-0.065,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.051,0.06-0.101,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.149l0.121-0.111
				c0.033-0.03,0.06-0.056,0.08-0.081s0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.011,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.104
				c0.072,0.069,0.108,0.168,0.108,0.296c0,0.047-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.045,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.041,0.071-0.047,0.099h0.553v0.232H20.924z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M22.262-24.663c0.02,0.031,0.055,0.046,0.105,0.046
				c0.04,0,0.072-0.009,0.096-0.028c0.023-0.018,0.041-0.044,0.054-0.078c0.013-0.033,0.022-0.072,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.025,0.037-0.06,0.067-0.106,0.09c-0.047,0.023-0.103,0.034-0.169,0.034
				c-0.065,0-0.12-0.011-0.166-0.034c-0.046-0.023-0.083-0.055-0.111-0.096c-0.028-0.04-0.049-0.089-0.062-0.144
				c-0.014-0.057-0.02-0.118-0.02-0.184c0-0.17,0.038-0.3,0.113-0.39c0.075-0.089,0.196-0.134,0.362-0.134
				c0.102,0,0.183,0.018,0.244,0.057c0.061,0.038,0.108,0.088,0.14,0.151c0.032,0.063,0.054,0.136,0.064,0.219
				c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.004,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.057,0.259
				c-0.03,0.074-0.08,0.133-0.146,0.176c-0.068,0.044-0.162,0.066-0.281,0.066c-0.053,0-0.104-0.005-0.153-0.016
				c-0.049-0.01-0.092-0.03-0.129-0.058s-0.068-0.066-0.091-0.114c-0.023-0.047-0.034-0.107-0.036-0.177h0.286
				C22.232-24.737,22.242-24.694,22.262-24.663 M22.247-25.219c0.025,0.043,0.066,0.064,0.124,0.064
				c0.059,0,0.102-0.021,0.126-0.064c0.025-0.042,0.038-0.114,0.038-0.213c0-0.042-0.002-0.08-0.006-0.117
				c-0.004-0.035-0.012-0.066-0.024-0.091c-0.012-0.026-0.028-0.046-0.05-0.061c-0.021-0.014-0.049-0.022-0.084-0.022
				c-0.036,0-0.064,0.008-0.086,0.022c-0.021,0.015-0.037,0.035-0.049,0.061c-0.011,0.027-0.018,0.057-0.021,0.093
				c-0.004,0.035-0.005,0.073-0.005,0.115C22.21-25.333,22.222-25.261,22.247-25.219"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M23.393-25.918h0.309v0.931h0.141v0.232h-0.141v0.304h-0.274v-0.304h-0.522
				V-25L23.393-25.918z M23.424-25.545l-0.286,0.558h0.29v-0.558H23.424z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M24.211-25.657l-0.031,0.334h0.004c0.028-0.039,0.064-0.067,0.108-0.085
				c0.044-0.017,0.098-0.025,0.162-0.025c0.07,0,0.128,0.013,0.175,0.04c0.046,0.027,0.083,0.063,0.11,0.107
				c0.026,0.044,0.046,0.094,0.057,0.15c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.066-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.083-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.057-0.025-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287c0,0.024,0.001,0.05,0.004,0.078c0.002,0.028,0.009,0.054,0.02,0.078
				c0.01,0.024,0.025,0.044,0.044,0.06c0.02,0.016,0.045,0.023,0.076,0.023c0.063,0,0.107-0.023,0.135-0.071
				c0.027-0.047,0.04-0.119,0.04-0.214c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.041-0.014-0.072-0.027-0.096
				c-0.014-0.024-0.031-0.04-0.054-0.049c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
				c-0.022,0.024-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H24.211z"
        />
        <rect
          x="26.284"
          y="-26.43"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#623B23"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M27.199-25.93c0.076,0,0.14,0.012,0.192,0.038
				c0.052,0.026,0.094,0.059,0.125,0.101c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H27.199z M27.108-25.261c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H27.108z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          points="28.202,-25.93 28.413,-24.885 28.418,-24.885 28.631,-25.93
				29.073,-25.93 29.073,-24.45 28.799,-24.45 28.799,-25.632 28.795,-25.632 28.532,-24.45 28.299,-24.45 28.037,-25.632
				28.033,-25.632 28.033,-24.45 27.759,-24.45 27.759,-25.93 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M29.526-24.869c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.014,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.024-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.022
				c0.056,0.015,0.104,0.038,0.144,0.07c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.07-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.015
				c-0.023,0.009-0.04,0.022-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102c0.007,0.038,0.011,0.079,0.011,0.125
				c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.107-0.378,0.107c-0.169,0-0.29-0.037-0.362-0.11
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M31.271-25.918h0.309v0.931h0.141v0.232h-0.141v0.304h-0.274v-0.304h-0.522
				V-25L31.271-25.918z M31.303-25.545l-0.286,0.558h0.29v-0.558H31.303z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M32.364-25.677c-0.019-0.031-0.055-0.046-0.104-0.046
				c-0.038,0-0.069,0.01-0.094,0.028c-0.024,0.019-0.043,0.045-0.057,0.078c-0.013,0.033-0.021,0.073-0.025,0.118
				c-0.004,0.046-0.006,0.096-0.006,0.149v0.073h0.004c0.024-0.039,0.061-0.069,0.106-0.092c0.047-0.023,0.103-0.034,0.169-0.034
				c0.064,0,0.12,0.011,0.166,0.034c0.045,0.023,0.082,0.054,0.111,0.095c0.027,0.041,0.049,0.089,0.062,0.145
				c0.013,0.056,0.019,0.117,0.019,0.184c0,0.17-0.037,0.298-0.111,0.386c-0.076,0.088-0.196,0.132-0.362,0.132
				c-0.103,0-0.184-0.018-0.245-0.054c-0.06-0.036-0.107-0.085-0.139-0.148c-0.033-0.064-0.054-0.136-0.064-0.219
				c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.204,0.009-0.302c0.006-0.097,0.025-0.184,0.056-0.259
				c0.031-0.075,0.08-0.135,0.146-0.181c0.067-0.046,0.161-0.068,0.282-0.068c0.052,0,0.103,0.006,0.151,0.018
				c0.051,0.013,0.094,0.033,0.131,0.062c0.037,0.029,0.066,0.068,0.09,0.115c0.023,0.048,0.035,0.107,0.036,0.178h-0.286
				C32.395-25.602,32.385-25.646,32.364-25.677 M32.099-24.791c0.004,0.036,0.012,0.067,0.024,0.092
				c0.01,0.026,0.027,0.046,0.049,0.06c0.021,0.015,0.05,0.022,0.084,0.022c0.035,0,0.064-0.007,0.086-0.022
				c0.021-0.014,0.037-0.034,0.049-0.061c0.011-0.026,0.019-0.057,0.021-0.092c0.004-0.035,0.006-0.074,0.006-0.115
				c0-0.1-0.013-0.171-0.037-0.214c-0.025-0.043-0.066-0.064-0.125-0.064c-0.06,0-0.103,0.021-0.127,0.064s-0.037,0.114-0.037,0.214
				C32.092-24.865,32.094-24.826,32.099-24.791"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M33.125-24.663c0.02,0.031,0.055,0.046,0.105,0.046
				c0.039,0,0.071-0.009,0.096-0.028c0.023-0.018,0.041-0.044,0.054-0.078c0.013-0.033,0.022-0.072,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.025,0.037-0.061,0.067-0.106,0.09c-0.047,0.023-0.103,0.034-0.169,0.034
				c-0.064,0-0.12-0.011-0.166-0.034c-0.046-0.023-0.083-0.055-0.111-0.096c-0.027-0.04-0.049-0.089-0.062-0.144
				c-0.015-0.057-0.021-0.118-0.021-0.184c0-0.17,0.039-0.3,0.113-0.39c0.075-0.089,0.196-0.134,0.362-0.134
				c0.103,0,0.183,0.018,0.244,0.057c0.061,0.038,0.108,0.088,0.14,0.151c0.032,0.063,0.055,0.136,0.064,0.219
				c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.004,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.058,0.259
				c-0.029,0.074-0.08,0.133-0.146,0.176c-0.067,0.044-0.162,0.066-0.281,0.066c-0.053,0-0.104-0.005-0.153-0.016
				c-0.049-0.01-0.092-0.03-0.129-0.058s-0.067-0.066-0.091-0.114c-0.022-0.047-0.034-0.107-0.036-0.177h0.286
				C33.095-24.737,33.105-24.694,33.125-24.663 M33.109-25.219c0.025,0.043,0.066,0.064,0.125,0.064s0.102-0.021,0.125-0.064
				c0.025-0.042,0.039-0.114,0.039-0.213c0-0.042-0.002-0.08-0.006-0.117c-0.005-0.035-0.013-0.066-0.024-0.091
				c-0.013-0.026-0.028-0.046-0.05-0.061c-0.021-0.014-0.049-0.022-0.084-0.022c-0.037,0-0.064,0.008-0.086,0.022
				c-0.021,0.015-0.037,0.035-0.049,0.061c-0.012,0.027-0.019,0.057-0.021,0.093c-0.004,0.035-0.006,0.073-0.006,0.115
				C33.072-25.333,33.085-25.261,33.109-25.219"
        />
        <rect
          x="36.152"
          y="-26.43"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#D29B81"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M37.067-25.93c0.075,0,0.14,0.012,0.192,0.038
				c0.051,0.026,0.094,0.059,0.125,0.101c0.031,0.041,0.055,0.088,0.068,0.141c0.014,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.01,0.143-0.033,0.199c-0.023,0.057-0.055,0.103-0.096,0.139s-0.09,0.063-0.147,0.081s-0.123,0.027-0.192,0.027h-0.16
				v0.59h-0.299v-1.479H37.067z M36.977-25.261c0.059,0,0.106-0.018,0.144-0.054s0.056-0.092,0.056-0.168
				c0-0.074-0.017-0.131-0.049-0.17c-0.031-0.038-0.085-0.058-0.16-0.058h-0.123v0.45H36.977z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          points="38.07,-25.93 38.281,-24.885 38.286,-24.885 38.499,-25.93
				38.941,-25.93 38.941,-24.45 38.667,-24.45 38.667,-25.632 38.663,-25.632 38.4,-24.45 38.167,-24.45 37.905,-25.632
				37.901,-25.632 37.901,-24.45 37.627,-24.45 37.627,-25.93 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M39.395-24.869c0,0.035,0.004,0.066,0.008,0.095
				c0.006,0.028,0.017,0.052,0.031,0.071c0.016,0.019,0.036,0.035,0.062,0.045c0.026,0.012,0.058,0.017,0.097,0.017
				c0.045,0,0.086-0.014,0.123-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.004-0.062-0.014-0.086
				c-0.008-0.024-0.023-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.032-0.015-0.075-0.031-0.125-0.048
				c-0.065-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.058-0.076-0.071-0.122
				c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.121-0.327c0.082-0.072,0.195-0.108,0.337-0.108
				c0.067,0,0.128,0.007,0.184,0.022c0.056,0.015,0.104,0.038,0.144,0.07c0.043,0.033,0.074,0.074,0.097,0.124
				c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.124-0.037-0.162s-0.067-0.057-0.124-0.057
				c-0.033,0-0.061,0.005-0.083,0.015c-0.022,0.009-0.04,0.022-0.054,0.037c-0.014,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.123,0.086l0.23,0.099
				c0.057,0.025,0.104,0.051,0.139,0.078c0.037,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.037,0.066,0.045,0.102
				c0.008,0.038,0.013,0.079,0.013,0.125c0,0.156-0.046,0.271-0.136,0.341s-0.217,0.107-0.379,0.107
				c-0.168,0-0.289-0.037-0.361-0.11c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.297V-24.869z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M41.573-25.661c-0.062,0.086-0.116,0.178-0.163,0.275
				c-0.047,0.099-0.089,0.2-0.126,0.303c-0.035,0.103-0.065,0.21-0.089,0.316c-0.023,0.108-0.041,0.213-0.053,0.316h-0.314
				c0.012-0.088,0.028-0.181,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.298s0.082-0.202,0.133-0.304
				c0.054-0.103,0.115-0.203,0.187-0.301H40.67v-0.257h0.903V-25.661z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M41.959-25.657l-0.031,0.334h0.004c0.027-0.039,0.063-0.067,0.107-0.085
				c0.045-0.017,0.098-0.025,0.162-0.025c0.07,0,0.129,0.013,0.176,0.04c0.045,0.027,0.082,0.063,0.109,0.107
				c0.025,0.044,0.046,0.094,0.057,0.15c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.006,0.156-0.021,0.222
				c-0.013,0.066-0.036,0.123-0.073,0.171c-0.035,0.048-0.084,0.083-0.144,0.108c-0.062,0.024-0.138,0.036-0.231,0.036
				s-0.168-0.012-0.226-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.041-0.051-0.085-0.061-0.132
				c-0.011-0.048-0.017-0.093-0.017-0.134v-0.039h0.287c0,0.024,0.001,0.05,0.004,0.078c0.002,0.028,0.009,0.054,0.021,0.078
				c0.01,0.024,0.024,0.044,0.043,0.06c0.021,0.016,0.045,0.023,0.078,0.023c0.062,0,0.105-0.023,0.133-0.071
				c0.027-0.047,0.041-0.119,0.041-0.214c0-0.061-0.004-0.111-0.01-0.151c-0.005-0.041-0.014-0.072-0.027-0.096
				s-0.03-0.04-0.053-0.049c-0.021-0.01-0.05-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.1,0.036
				c-0.021,0.024-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H41.959z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M42.99-25.487h-0.292v-0.195h0.027c0.101,0,0.177-0.018,0.235-0.056
				c0.057-0.038,0.094-0.097,0.108-0.18h0.22v1.467H42.99V-25.487z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M44.125-25.918h0.309v0.931h0.141v0.232h-0.141v0.304h-0.274v-0.304h-0.522
				V-25L44.125-25.918z M44.155-25.545l-0.286,0.558h0.29v-0.558H44.155z"
        />
        <rect
          x="46.021"
          y="-26.43"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EBB7A8"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M46.936-25.93c0.076,0,0.141,0.012,0.191,0.038
				c0.053,0.026,0.095,0.059,0.125,0.101c0.033,0.041,0.056,0.088,0.07,0.141c0.012,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.022,0.057-0.054,0.103-0.095,0.139s-0.092,0.063-0.148,0.081
				c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.297v-1.479H46.936z M46.845-25.261c0.06,0,0.106-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.032-0.038-0.086-0.058-0.16-0.058h-0.125v0.45H46.845z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          points="47.938,-25.93 48.149,-24.885 48.154,-24.885 48.367,-25.93
				48.81,-25.93 48.81,-24.45 48.535,-24.45 48.535,-25.632 48.531,-25.632 48.269,-24.45 48.035,-24.45 47.773,-25.632
				47.77,-25.632 47.77,-24.45 47.496,-24.45 47.496,-25.93 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M49.264-24.869c0,0.035,0.003,0.066,0.008,0.095
				c0.005,0.028,0.016,0.052,0.031,0.071c0.015,0.019,0.035,0.035,0.061,0.045c0.025,0.012,0.059,0.017,0.098,0.017
				c0.045,0,0.086-0.014,0.123-0.045c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.006-0.062-0.014-0.086
				c-0.009-0.024-0.023-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.074-0.031-0.125-0.048
				c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.056-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.193-0.108,0.336-0.108
				c0.066,0,0.128,0.007,0.184,0.022c0.057,0.015,0.104,0.038,0.146,0.07c0.041,0.033,0.071,0.074,0.095,0.124
				c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.012-0.124-0.038-0.162c-0.022-0.038-0.065-0.057-0.123-0.057
				c-0.032,0-0.061,0.005-0.083,0.015c-0.023,0.009-0.039,0.022-0.054,0.037c-0.013,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.011,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078c0.035,0.027,0.063,0.056,0.086,0.087c0.021,0.031,0.035,0.066,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.091,0.071-0.216,0.107-0.378,0.107
				c-0.169,0-0.29-0.037-0.362-0.11c-0.071-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.869z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M51.441-25.661c-0.061,0.086-0.115,0.178-0.162,0.275
				c-0.048,0.099-0.09,0.2-0.125,0.303c-0.037,0.103-0.066,0.21-0.09,0.316c-0.023,0.108-0.042,0.213-0.054,0.316h-0.313
				c0.012-0.088,0.027-0.181,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.298c0.035-0.101,0.08-0.202,0.133-0.304
				c0.053-0.103,0.115-0.203,0.188-0.301h-0.619v-0.257h0.904V-25.661z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M51.826-25.657l-0.03,0.334h0.005c0.027-0.039,0.063-0.067,0.107-0.085
				c0.043-0.017,0.098-0.025,0.162-0.025c0.069,0,0.127,0.013,0.174,0.04s0.084,0.063,0.11,0.107s0.046,0.094,0.058,0.15
				c0.011,0.056,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.02,0.222c-0.015,0.066-0.039,0.123-0.074,0.171
				c-0.036,0.048-0.084,0.083-0.145,0.108c-0.06,0.024-0.138,0.036-0.232,0.036c-0.092,0-0.166-0.012-0.225-0.037
				c-0.057-0.025-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.062-0.132c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287
				c0,0.024,0.001,0.05,0.004,0.078c0.004,0.028,0.01,0.054,0.02,0.078c0.011,0.024,0.025,0.044,0.045,0.06
				c0.02,0.016,0.045,0.023,0.076,0.023c0.063,0,0.107-0.023,0.135-0.071c0.027-0.047,0.04-0.119,0.04-0.214
				c0-0.061-0.002-0.111-0.009-0.151c-0.004-0.041-0.015-0.072-0.027-0.096c-0.014-0.024-0.031-0.04-0.055-0.049
				c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.075,0.012-0.098,0.036s-0.037,0.057-0.043,0.099H51.55l0.053-0.798h0.765v0.232
				H51.826z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M52.52-24.45c0-0.065,0.006-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.051,0.06-0.101,0.103-0.15c0.041-0.05,0.088-0.099,0.142-0.149l0.12-0.111
				c0.033-0.03,0.061-0.056,0.08-0.081s0.035-0.05,0.046-0.075s0.019-0.052,0.022-0.081c0.002-0.029,0.004-0.062,0.004-0.099
				c0-0.122-0.047-0.183-0.145-0.183c-0.037,0-0.066,0.008-0.088,0.023s-0.038,0.036-0.05,0.062
				c-0.011,0.027-0.019,0.057-0.022,0.092c-0.003,0.034-0.005,0.071-0.005,0.109H52.52v-0.056c0-0.136,0.036-0.241,0.109-0.315
				c0.072-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103c0.072,0.069,0.108,0.168,0.108,0.296
				c0,0.047-0.004,0.091-0.013,0.132c-0.008,0.041-0.021,0.08-0.039,0.117c-0.019,0.038-0.041,0.074-0.071,0.109
				c-0.028,0.035-0.063,0.072-0.104,0.109l-0.166,0.153c-0.045,0.04-0.08,0.079-0.104,0.117c-0.024,0.039-0.041,0.071-0.047,0.099
				h0.553v0.232H52.52z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M53.525-25.429c0.006-0.094,0.024-0.177,0.055-0.249
				c0.031-0.073,0.076-0.131,0.138-0.174c0.062-0.044,0.147-0.066,0.256-0.066c0.091,0,0.164,0.013,0.224,0.039
				c0.059,0.025,0.105,0.066,0.141,0.123c0.035,0.056,0.061,0.13,0.074,0.22c0.015,0.091,0.022,0.201,0.022,0.331
				c0,0.102-0.004,0.2-0.011,0.294c-0.007,0.094-0.025,0.178-0.057,0.25c-0.029,0.072-0.075,0.13-0.137,0.171
				c-0.062,0.042-0.146,0.063-0.257,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.024-0.106-0.063-0.142-0.12
				c-0.037-0.056-0.062-0.13-0.076-0.221c-0.014-0.09-0.021-0.201-0.021-0.33C53.514-25.237,53.518-25.335,53.525-25.429
				 M53.82-24.843c0.004,0.059,0.013,0.105,0.025,0.139c0.012,0.034,0.029,0.057,0.051,0.069c0.021,0.013,0.045,0.018,0.077,0.018
				c0.034,0,0.062-0.007,0.081-0.023c0.02-0.016,0.037-0.043,0.049-0.081c0.012-0.038,0.021-0.089,0.025-0.153
				c0.004-0.063,0.007-0.142,0.007-0.236v-0.159c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.059-0.014-0.105-0.026-0.139
				c-0.013-0.034-0.03-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019s-0.06,0.008-0.08,0.024
				c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.038-0.021,0.088-0.024,0.152c-0.006,0.063-0.009,0.142-0.009,0.236v0.16
				C53.812-24.978,53.814-24.902,53.82-24.843"
        />
        <rect
          x="65.758"
          y="-26.43"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#5087C7"
          width="8.813"
          height="8.813"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M66.672-25.93c0.076,0,0.141,0.012,0.192,0.038
				c0.052,0.026,0.095,0.059,0.125,0.101c0.032,0.041,0.056,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.033,0.199c-0.023,0.057-0.056,0.103-0.096,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.057,0.018-0.122,0.027-0.192,0.027h-0.16v0.59H66.15v-1.479H66.672z M66.582-25.261c0.059,0,0.106-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.031-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H66.582z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          points="67.676,-25.93 67.887,-24.885 67.892,-24.885 68.104,-25.93
				68.547,-25.93 68.547,-24.45 68.272,-24.45 68.272,-25.632 68.269,-25.632 68.006,-24.45 67.772,-24.45 67.51,-25.632
				67.506,-25.632 67.506,-24.45 67.232,-24.45 67.232,-25.93 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M69-24.869c0,0.035,0.003,0.066,0.008,0.095
				c0.006,0.028,0.017,0.052,0.031,0.071c0.016,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.017,0.098,0.017
				c0.045,0,0.086-0.014,0.123-0.045c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.024-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.173-0.073c-0.049-0.026-0.088-0.056-0.12-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
				s-0.022-0.1-0.022-0.161c0-0.146,0.041-0.255,0.121-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.129,0.007,0.185,0.022
				c0.056,0.015,0.104,0.038,0.144,0.07c0.043,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.035,0.111,0.035,0.182v0.041h-0.287
				c0-0.07-0.012-0.124-0.037-0.162c-0.024-0.038-0.066-0.057-0.123-0.057c-0.034,0-0.061,0.005-0.083,0.015
				c-0.023,0.009-0.04,0.022-0.054,0.037c-0.014,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.123,0.086l0.23,0.099c0.057,0.025,0.104,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.066,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.045,0.27-0.135,0.341c-0.091,0.071-0.217,0.107-0.379,0.107c-0.169,0-0.289-0.037-0.361-0.11
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061H69V-24.869z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M70.266-24.45c0-0.065,0.008-0.126,0.021-0.183
				c0.013-0.056,0.032-0.11,0.06-0.161c0.027-0.051,0.061-0.101,0.103-0.15c0.04-0.05,0.088-0.099,0.141-0.149l0.121-0.111
				c0.033-0.03,0.06-0.056,0.08-0.081s0.035-0.05,0.046-0.075c0.012-0.025,0.02-0.052,0.021-0.081
				c0.004-0.029,0.006-0.062,0.006-0.099c0-0.122-0.049-0.183-0.145-0.183c-0.037,0-0.068,0.008-0.089,0.023
				c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.002,0.034-0.004,0.071-0.004,0.109h-0.287v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.347-0.109c0.149,0,0.26,0.034,0.332,0.103
				c0.071,0.069,0.107,0.168,0.107,0.296c0,0.047-0.004,0.091-0.014,0.132c-0.008,0.041-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.07,0.109c-0.027,0.035-0.064,0.072-0.105,0.109l-0.166,0.153c-0.043,0.04-0.078,0.079-0.104,0.117
				c-0.023,0.039-0.041,0.071-0.047,0.099h0.553v0.232H70.266z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M72.174-25.661c-0.062,0.086-0.117,0.178-0.164,0.275
				c-0.047,0.099-0.088,0.2-0.125,0.303c-0.035,0.103-0.065,0.21-0.09,0.316c-0.023,0.108-0.041,0.213-0.053,0.316h-0.314
				c0.013-0.088,0.028-0.181,0.051-0.278c0.021-0.098,0.052-0.197,0.089-0.298c0.036-0.101,0.081-0.202,0.133-0.304
				c0.053-0.103,0.115-0.203,0.187-0.301H71.27v-0.257h0.904V-25.661z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M72.596-25.487h-0.292v-0.195h0.026c0.101,0,0.178-0.018,0.235-0.056
				c0.058-0.038,0.095-0.097,0.109-0.18h0.219v1.467h-0.298V-25.487z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          d="M73.305-25.103c0.041-0.067,0.104-0.108,0.187-0.125v-0.004
				c-0.04-0.01-0.074-0.025-0.101-0.046s-0.049-0.044-0.065-0.07c-0.017-0.027-0.028-0.054-0.035-0.083s-0.01-0.057-0.01-0.083
				c0-0.144,0.038-0.247,0.114-0.31s0.182-0.094,0.316-0.094s0.239,0.031,0.314,0.094c0.078,0.063,0.117,0.166,0.117,0.31
				c0,0.026-0.004,0.054-0.013,0.083c-0.007,0.029-0.019,0.056-0.036,0.083c-0.016,0.026-0.037,0.049-0.064,0.07
				s-0.061,0.036-0.1,0.046v0.004c0.084,0.017,0.146,0.058,0.188,0.125c0.041,0.066,0.063,0.145,0.063,0.238
				c0,0.043-0.006,0.09-0.016,0.141c-0.012,0.051-0.033,0.098-0.066,0.143c-0.033,0.044-0.08,0.08-0.142,0.11
				c-0.062,0.028-0.144,0.043-0.245,0.043s-0.184-0.015-0.246-0.043c-0.063-0.03-0.109-0.066-0.142-0.11
				c-0.033-0.045-0.056-0.092-0.065-0.143c-0.011-0.051-0.016-0.098-0.016-0.141C73.242-24.958,73.264-25.037,73.305-25.103
				 M73.548-24.771c0.005,0.031,0.015,0.058,0.027,0.081c0.013,0.023,0.03,0.041,0.053,0.054s0.05,0.019,0.083,0.019
				s0.061-0.006,0.083-0.019c0.021-0.013,0.039-0.031,0.052-0.054c0.014-0.023,0.023-0.05,0.027-0.081
				c0.005-0.031,0.008-0.063,0.008-0.099s-0.003-0.068-0.008-0.099c-0.004-0.03-0.014-0.057-0.027-0.08
				c-0.013-0.023-0.031-0.04-0.052-0.053c-0.022-0.014-0.05-0.02-0.083-0.02s-0.061,0.006-0.083,0.02
				c-0.022,0.013-0.04,0.03-0.053,0.053s-0.022,0.05-0.027,0.08c-0.005,0.031-0.007,0.064-0.007,0.099S73.543-24.802,73.548-24.771
				 M73.605-25.367c0.025,0.033,0.061,0.05,0.105,0.05c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.039-0.085,0.039-0.155
				c0-0.135-0.047-0.201-0.145-0.201c-0.097,0-0.145,0.066-0.145,0.201C73.566-25.452,73.579-25.4,73.605-25.367"
        />
        <rect
          x="55.891"
          y="-26.43"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#FFFFFF"
          width="8.812"
          height="8.813"
        />

        <rect
          x="55.891"
          y="-26.43"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.812"
          height="8.813"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          points="56.48,-25.93 56.645,-24.836 56.648,-24.836 56.822,-25.93 57.109,-25.93
				57.283,-24.836 57.287,-24.836 57.451,-25.93 57.736,-25.93 57.468,-24.45 57.134,-24.45 56.968,-25.524 56.964,-25.524
				56.798,-24.45 56.464,-24.45 56.195,-25.93 			"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          points="58.156,-25.93 58.156,-25.364 58.504,-25.364 58.504,-25.93 58.803,-25.93
				58.803,-24.45 58.504,-24.45 58.504,-25.107 58.156,-25.107 58.156,-24.45 57.857,-24.45 57.857,-25.93 			"
        />
        <rect
          x="59.023"
          y="-25.931"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          width="0.298"
          height="1.48"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          points="60.41,-25.686 60.088,-25.686 60.088,-24.451 59.788,-24.451 59.788,-25.686
				59.465,-25.686 59.465,-25.931 60.41,-25.931 			"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          points="61.365,-25.686 60.836,-25.686 60.836,-25.337 61.334,-25.337 61.334,-25.093
				60.836,-25.093 60.836,-24.696 61.387,-24.696 61.387,-24.451 60.539,-24.451 60.539,-25.931 61.365,-25.931 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M12.738,75.937c0.592,0,0.973,0.391,0.973,0.992
				c0,0.594-0.381,0.984-0.973,0.984c-0.588,0-0.969-0.391-0.969-0.984C11.769,76.327,12.149,75.937,12.738,75.937 M12.738,77.526
				c0.352,0,0.457-0.301,0.457-0.598c0-0.303-0.105-0.604-0.457-0.604c-0.349,0-0.454,0.301-0.454,0.604
				C12.284,77.226,12.389,77.526,12.738,77.526"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M14.696,75.987h0.378v0.346h-0.378v0.928c0,0.174,0.044,0.219,0.218,0.219
				c0.054,0,0.105-0.004,0.16-0.016v0.402c-0.087,0.016-0.2,0.02-0.302,0.02c-0.315,0-0.591-0.074-0.591-0.447v-1.105h-0.312v-0.346
				h0.312v-0.563h0.515V75.987z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M15.744,77.052c0.014,0.324,0.174,0.475,0.46,0.475
				c0.208,0,0.374-0.127,0.407-0.244h0.454c-0.146,0.443-0.454,0.631-0.879,0.631c-0.591,0-0.957-0.404-0.957-0.984
				c0-0.564,0.388-0.992,0.957-0.992c0.639,0,0.947,0.537,0.911,1.115H15.744z M16.582,76.724c-0.047-0.262-0.16-0.398-0.41-0.398
				c-0.327,0-0.421,0.254-0.428,0.398H16.582z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M17.8,77.253c0.004,0.227,0.193,0.316,0.396,0.316
				c0.149,0,0.337-0.059,0.337-0.24c0-0.156-0.214-0.209-0.584-0.289c-0.298-0.066-0.595-0.17-0.595-0.502
				c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.139,0.828,0.598h-0.49c-0.015-0.199-0.167-0.254-0.352-0.254
				c-0.116,0-0.287,0.021-0.287,0.176c0,0.184,0.29,0.209,0.584,0.279c0.302,0.068,0.596,0.178,0.596,0.525
				c0,0.494-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.162-0.882-0.66H17.8z"
        />
        <rect
          x="8.943"
          y="80.466"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M12.047,79.028h0.89c0.798,0,1.244,0.398,1.244,1.234
				c0,0.867-0.381,1.357-1.244,1.357h-0.89V79.028z M12.393,81.329h0.573c0.236,0,0.871-0.064,0.871-1.02
				c0-0.617-0.228-0.99-0.864-0.99h-0.58V81.329z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M15.36,79.7c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.449,80.132,14.765,79.7,15.36,79.7 M15.36,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
				C14.776,81.138,15.037,81.392,15.36,81.392"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M17.64,79.743h0.291v0.297h0.006c0.131-0.232,0.341-0.34,0.61-0.34
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.271c0-0.227-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.59v1.059
				H17.64V79.743z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M20.466,79.7c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.555,80.132,19.871,79.7,20.466,79.7 M20.466,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
				C19.882,81.138,20.143,81.392,20.466,81.392"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M22.167,79.743h0.374v0.271h-0.374v1.166c0,0.143,0.039,0.166,0.232,0.166
				h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319v-0.563h0.309V79.743z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M25.552,81.612c-0.055,0.033-0.123,0.051-0.222,0.051
				c-0.16,0-0.261-0.088-0.261-0.291c-0.17,0.201-0.399,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.525
				c0-0.426,0.316-0.516,0.635-0.578c0.341-0.064,0.632-0.043,0.632-0.275c0-0.268-0.222-0.313-0.418-0.313
				c-0.261,0-0.453,0.082-0.468,0.355H23.86c0.018-0.465,0.378-0.627,0.795-0.627c0.338,0,0.704,0.076,0.704,0.514v0.967
				c0,0.145,0,0.211,0.098,0.211c0.026,0,0.055-0.004,0.095-0.02V81.612z M25.051,80.651c-0.12,0.086-0.352,0.09-0.559,0.127
				c-0.203,0.035-0.378,0.109-0.378,0.336c0,0.205,0.175,0.277,0.364,0.277c0.406,0,0.573-0.254,0.573-0.424V80.651z"
        />
        <rect
          x="25.855"
          y="79.028"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          width="0.308"
          height="2.592"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M27.07,79.743h0.374v0.273H27.07v1.164c0,0.143,0.04,0.166,0.232,0.166
				h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.193h-0.319v-0.273h0.319v-0.563h0.309V79.743z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M29.385,81.024c-0.083,0.414-0.381,0.639-0.802,0.639
				c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.619,0.849,1.074h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.367H29.385z M29.087,80.503
				c-0.014-0.295-0.236-0.531-0.54-0.531c-0.323,0-0.516,0.244-0.538,0.531H29.087z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M29.721,79.743h0.291v0.396h0.006c0.149-0.301,0.356-0.449,0.69-0.439v0.326
				c-0.497,0-0.678,0.283-0.678,0.758v0.836h-0.309V79.743z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M33.61,81.612c-0.056,0.033-0.123,0.051-0.222,0.051
				c-0.159,0-0.262-0.088-0.262-0.291c-0.17,0.201-0.398,0.291-0.66,0.291c-0.34,0-0.621-0.152-0.621-0.525
				c0-0.426,0.316-0.516,0.636-0.578c0.34-0.064,0.631-0.043,0.631-0.275c0-0.268-0.222-0.313-0.417-0.313
				c-0.262,0-0.454,0.082-0.469,0.355h-0.308c0.018-0.465,0.377-0.627,0.795-0.627c0.336,0,0.703,0.076,0.703,0.516v0.965
				c0,0.145,0,0.211,0.098,0.211c0.025,0,0.055-0.004,0.095-0.02V81.612z M33.109,80.651c-0.119,0.086-0.352,0.09-0.559,0.127
				c-0.203,0.035-0.377,0.109-0.377,0.336c0,0.205,0.174,0.277,0.362,0.277c0.406,0,0.573-0.254,0.573-0.424V80.651z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M33.885,79.743h0.29v0.396h0.007c0.149-0.301,0.356-0.449,0.69-0.439v0.326
				c-0.497,0-0.679,0.283-0.679,0.758v0.836h-0.309V79.743z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M35.532,79.743h0.374v0.273h-0.374v1.164c0,0.143,0.039,0.166,0.231,0.166
				h0.143v0.273H35.67c-0.32,0-0.447-0.066-0.447-0.41v-1.193h-0.318v-0.273h0.318v-0.563h0.31V79.743z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          points="38.104,81.62 37.777,81.62 37.396,80.128 37.389,80.128
				37.012,81.62 36.678,81.62 36.075,79.743 36.416,79.743 36.841,81.278 36.85,81.278 37.227,79.743 37.564,79.743 37.955,81.278
				37.963,81.278 38.383,79.743 38.703,79.743 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M39.805,79.7c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.596,0-0.911-0.432-0.911-0.979C38.894,80.132,39.209,79.7,39.805,79.7 M39.805,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713s-0.584,0.254-0.584,0.713
				C39.221,81.138,39.481,81.392,39.805,81.392"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M41.066,79.743h0.29v0.396h0.007c0.149-0.301,0.356-0.449,0.69-0.439v0.326
				c-0.497,0-0.679,0.283-0.679,0.758v0.836h-0.309V79.743z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          points="42.303,79.028 42.612,79.028 42.612,80.563 43.469,79.743
				43.883,79.743 43.139,80.425 43.937,81.62 43.545,81.62 42.901,80.64 42.612,80.909 42.612,81.62 42.303,81.62 			"
        />
        <rect
          x="8.943"
          y="84.222"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M14.116,84.44c0,0.652-0.374,0.998-1.02,0.998
				c-0.668,0-1.063-0.309-1.063-0.998v-1.654h0.345v1.654c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.654
				h0.345V84.44z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M14.804,84.784c0.011,0.277,0.25,0.363,0.505,0.363
				c0.192,0,0.454-0.043,0.454-0.279c0-0.24-0.306-0.279-0.614-0.348c-0.305-0.07-0.614-0.172-0.614-0.531
				c0-0.377,0.374-0.533,0.701-0.533c0.414,0,0.744,0.131,0.769,0.588h-0.308c-0.022-0.238-0.233-0.314-0.435-0.314
				c-0.186,0-0.4,0.051-0.4,0.238c0,0.223,0.326,0.258,0.614,0.326c0.308,0.07,0.612,0.172,0.612,0.535
				c0,0.445-0.417,0.59-0.798,0.59c-0.421,0-0.777-0.17-0.794-0.635H14.804z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M18.029,84.782c-0.083,0.412-0.38,0.637-0.802,0.637
				c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.882-0.977c0.664,0,0.868,0.621,0.849,1.074h-1.404
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.365H18.029z M17.732,84.259
				c-0.014-0.295-0.236-0.529-0.541-0.529c-0.323,0-0.515,0.242-0.537,0.529H17.732z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M19.813,83.501h0.374v0.271h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.271h0.319v-0.564h0.309V83.501z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M20.854,83.163h-0.308v-0.379h0.308V83.163z M20.546,83.499h0.308v1.877
				h-0.308V83.499z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M22.951,85.216c0,0.611-0.28,0.922-0.871,0.922
				c-0.352,0-0.759-0.141-0.777-0.551h0.309c0.014,0.225,0.283,0.305,0.486,0.305c0.403,0,0.562-0.291,0.562-0.711v-0.123h-0.007
				c-0.102,0.229-0.348,0.34-0.584,0.34c-0.559,0-0.838-0.438-0.838-0.951c0-0.441,0.217-0.99,0.867-0.99
				c0.236,0,0.446,0.105,0.559,0.313h0.003v-0.268h0.291V85.216z M22.646,84.399c0-0.33-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.324-0.556,0.684c0,0.338,0.124,0.715,0.53,0.715C22.494,85.126,22.646,84.753,22.646,84.399"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M23.414,82.784h0.309v0.992h0.007c0.102-0.225,0.366-0.318,0.591-0.318
				c0.494,0,0.646,0.281,0.646,0.686v1.232h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
				h-0.309V82.784z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M25.859,83.501h0.374v0.271h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
				h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.271h0.319v-0.564h0.309V83.501z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M28.391,83.456c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.48,83.888,27.796,83.456,28.391,83.456 M28.391,85.147
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
				C27.807,84.894,28.068,85.147,28.391,85.147"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          points="30.509,85.376 30.178,85.376 29.482,83.501 29.827,83.501
				30.353,85.063 30.36,85.063 30.872,83.501 31.194,83.501 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M33.078,84.782c-0.084,0.412-0.382,0.637-0.803,0.637
				c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.662,0,0.867,0.621,0.849,1.074h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.485-0.365H33.078z M32.779,84.259
				c-0.014-0.295-0.236-0.529-0.54-0.529c-0.323,0-0.516,0.242-0.538,0.529H32.779z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M33.414,83.501h0.291v0.395h0.006c0.148-0.301,0.355-0.449,0.689-0.439
				v0.326c-0.497,0-0.678,0.283-0.678,0.76v0.834h-0.309V83.501z"
        />
        <rect
          x="34.65"
          y="82.784"
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          width="0.309"
          height="2.592"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M36.246,83.456c0.596,0,0.912,0.432,0.912,0.984
				c0,0.547-0.316,0.979-0.912,0.979c-0.595,0-0.91-0.432-0.91-0.979C35.336,83.888,35.651,83.456,36.246,83.456 M36.246,85.147
				c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.322,0-0.584,0.254-0.584,0.711
				C35.662,84.894,35.924,85.147,36.246,85.147"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M38.793,84.103c-0.048-0.236-0.21-0.373-0.464-0.373
				c-0.446,0-0.585,0.352-0.585,0.732c0,0.346,0.156,0.686,0.548,0.686c0.298,0,0.476-0.174,0.515-0.457h0.316
				c-0.068,0.457-0.355,0.729-0.826,0.729c-0.574,0-0.88-0.398-0.88-0.957c0-0.563,0.29-1.006,0.886-1.006
				c0.424,0,0.767,0.201,0.81,0.646H38.793z"
        />
        <polygon
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          points="39.484,82.784 39.794,82.784 39.794,84.319 40.65,83.501
				41.064,83.501 40.32,84.183 41.117,85.376 40.727,85.376 40.083,84.396 39.794,84.665 39.794,85.376 39.484,85.376 			"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M41.676,83.163h-0.309v-0.379h0.309V83.163z M41.367,83.499h0.309v1.877
				h-0.309V83.499z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M42.154,83.501h0.291v0.297h0.006c0.131-0.232,0.341-0.342,0.609-0.342
				c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.309v-1.27c0-0.229-0.146-0.379-0.381-0.379c-0.373,0-0.555,0.252-0.555,0.59v1.059
				h-0.309V83.501z"
        />
        <path
          clipPath="url(#MEDIACRITIC_SVGID_94_)"
          fill="#EC008C"
          d="M45.789,85.216c0,0.611-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.141-0.776-0.551h0.31c0.014,0.225,0.282,0.305,0.485,0.305c0.403,0,0.562-0.291,0.562-0.711v-0.123h-0.006
				c-0.103,0.229-0.349,0.34-0.585,0.34c-0.559,0-0.838-0.438-0.838-0.951c0-0.441,0.218-0.99,0.867-0.99
				c0.235,0,0.446,0.105,0.56,0.313h0.002v-0.268h0.291V85.216z M45.484,84.399c0-0.33-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.324-0.556,0.684c0,0.338,0.124,0.715,0.53,0.715S45.484,84.753,45.484,84.399"
        />
      </g>
    </g>
  );
};

const Confectionery = () => {
  return (
    <g id="Confectionary">
      <g>
        <defs>
          <rect
            id="CONFECTIONARY_SVGID_95_"
            x="0.002"
            y="0"
            width="61.688"
            height="61.688"
          />
        </defs>
        <clipPath id="CONFECTIONARY_SVGID_96_">
          <use xlinkHref="#CONFECTIONARY_SVGID_95_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#EC008C"
          d="M6.182,60.813c-2.925,0-5.304-2.379-5.304-5.305V6.181
				c0-2.924,2.379-5.304,5.304-5.304H55.51c2.924,0,5.303,2.38,5.303,5.304v49.327c0,2.924-2.379,5.305-5.303,5.305H6.182z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#EC008C"
          d="M55.509,1.136c2.782,0,5.046,2.263,5.046,5.045v49.327
				c0,2.782-2.264,5.045-5.046,5.045H6.182c-2.782,0-5.045-2.263-5.045-5.045V6.181c0-2.782,2.263-5.045,5.045-5.045H55.509
				 M55.509,0.618H6.182c-3.059,0-5.563,2.504-5.563,5.563v49.327c0,3.061,2.504,5.563,5.563,5.563h49.327
				c3.06,0,5.563-2.503,5.563-5.563V6.181C61.072,3.122,58.568,0.618,55.509,0.618"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#88155D"
          points="51.652,22.423 37.16,32.453 19.479,31.092 10.093,37.494
				36.872,58.138 59.653,58.138 60.024,27.115 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#88155D"
          points="41.029,22.755 31.731,29.115 20.306,28.236 14.242,32.372
				31.544,45.711 46.264,45.711 46.419,25.767 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M14.298,9.543c-0.184,0.999-0.987,1.582-2.01,1.582
				c-1.183,0-2.117-0.91-2.117-2.082c0-1.165,0.934-2.08,2.117-2.08c0.981,0,1.814,0.582,2.004,1.527l-0.963,0.226
				c-0.125-0.577-0.53-0.856-1.041-0.856c-0.63,0-1.136,0.506-1.136,1.183c0,0.679,0.506,1.19,1.136,1.19
				c0.505,0,0.909-0.286,1.047-0.881L14.298,9.543z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M17.919,9.543c0,0.945-0.751,1.576-1.636,1.576
				c-0.904,0-1.654-0.631-1.654-1.576c0-0.939,0.75-1.57,1.654-1.57C17.168,7.973,17.919,8.604,17.919,9.543 M17.002,9.543
				c0-0.464-0.351-0.773-0.719-0.773c-0.387,0-0.737,0.309-0.737,0.773c0,0.469,0.35,0.773,0.737,0.773
				C16.651,10.316,17.002,10.012,17.002,9.543"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M21.398,9.203v1.832h-0.945V9.406c0-0.351-0.209-0.589-0.513-0.589
				c-0.374,0-0.618,0.255-0.618,0.833v1.385h-0.951V8.05h0.951v0.285c0.227-0.238,0.542-0.374,0.917-0.374
				C20.934,7.961,21.398,8.454,21.398,9.203"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M23.926,8.05v0.755h-0.821v2.23H22.16v-2.23h-0.428V8.05h0.428V7.818
				c0-0.702,0.482-1.147,1.201-1.147c0.202,0,0.399,0.041,0.589,0.125l-0.203,0.737c-0.089-0.03-0.184-0.048-0.255-0.048
				c-0.226,0-0.387,0.137-0.387,0.375V8.05H23.926z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M27.154,9.786h-2.188c0.078,0.357,0.321,0.577,0.642,0.577
				c0.221,0,0.477-0.053,0.637-0.345l0.844,0.173c-0.256,0.63-0.797,0.933-1.481,0.933c-0.862,0-1.575-0.637-1.575-1.582
				c0-0.939,0.713-1.581,1.582-1.581c0.844,0,1.522,0.606,1.539,1.581V9.786z M24.984,9.203h1.231
				c-0.089-0.321-0.327-0.469-0.6-0.469C25.347,8.734,25.073,8.9,24.984,9.203"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M29.712,9.769l0.874,0.19c-0.149,0.707-0.779,1.165-1.522,1.165
				c-0.892,0-1.63-0.636-1.63-1.581c0-0.94,0.738-1.582,1.63-1.582c0.725,0,1.356,0.446,1.522,1.147l-0.892,0.215
				c-0.083-0.363-0.327-0.53-0.63-0.53c-0.428,0-0.714,0.315-0.714,0.75c0,0.44,0.286,0.749,0.714,0.749
				C29.367,10.292,29.611,10.132,29.712,9.769"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M33.299,10.904c-0.232,0.125-0.477,0.22-0.814,0.22
				c-0.745,0-1.189-0.404-1.189-1.189v-1.13h-0.541V8.05h0.541V7.17h0.951v0.88h0.88v0.755h-0.88V9.84
				c0,0.309,0.148,0.434,0.398,0.434c0.131,0,0.309-0.048,0.434-0.119L33.299,10.904z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M34.7,7.169c0,0.304-0.231,0.53-0.554,0.53c-0.32,0-0.547-0.226-0.547-0.53
				c0-0.291,0.227-0.541,0.547-0.541C34.469,6.628,34.7,6.878,34.7,7.169 M33.672,8.05h0.95v2.986h-0.95V8.05z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M38.373,9.543c0,0.945-0.75,1.576-1.635,1.576
				c-0.904,0-1.654-0.631-1.654-1.576c0-0.939,0.75-1.57,1.654-1.57C37.623,7.973,38.373,8.604,38.373,9.543 M37.457,9.543
				c0-0.464-0.351-0.773-0.719-0.773C36.352,8.77,36,9.079,36,9.543c0,0.469,0.352,0.773,0.738,0.773
				C37.106,10.316,37.457,10.012,37.457,9.543"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M41.854,9.203v1.832h-0.945V9.406c0-0.351-0.208-0.589-0.512-0.589
				c-0.375,0-0.618,0.255-0.618,0.833v1.385h-0.952V8.05h0.952v0.285c0.226-0.238,0.541-0.374,0.916-0.374
				C41.389,7.961,41.854,8.454,41.854,9.203"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M45.396,9.786h-2.188c0.078,0.357,0.32,0.577,0.642,0.577
				c0.221,0,0.476-0.053,0.637-0.345l0.845,0.173c-0.256,0.63-0.797,0.933-1.481,0.933c-0.862,0-1.576-0.637-1.576-1.582
				c0-0.939,0.714-1.581,1.583-1.581c0.844,0,1.521,0.606,1.539,1.581V9.786z M43.227,9.203h1.23c-0.09-0.321-0.326-0.469-0.6-0.469
				C43.59,8.734,43.315,8.9,43.227,9.203"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          d="M48.051,8.032L48.01,8.983h-0.173c-0.666,0-1.028,0.345-1.028,1.118v0.934
				h-0.951V8.05h0.951v0.571c0.213-0.357,0.541-0.613,1.028-0.613C47.919,8.008,47.979,8.015,48.051,8.032"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFFFFF"
          points="50,12.046 48.972,12.046 49.453,10.934 48.218,8.044 49.24,8.044
				49.947,9.787 50.703,8.044 51.725,8.044 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFDD00"
          points="51.652,22.423 38.133,29.958 48.631,26.938 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#F37321"
          points="48.631,26.938 38.132,29.958 51.652,29.958 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          points="
				48.631,26.938 38.132,29.958 51.652,29.958 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#F37321"
          points="51.652,37.493 38.133,29.958 48.631,32.979 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          points="
				51.652,37.493 38.133,29.958 48.631,32.979 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFDD00"
          points="48.631,32.979 38.132,29.958 51.652,29.958 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFDD00"
          points="10.093,37.493 23.612,29.958 13.114,32.979 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#F37321"
          points="13.114,32.979 23.612,29.958 10.093,29.958 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          points="
				13.114,32.979 23.612,29.958 10.093,29.958 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#F37321"
          points="10.093,22.423 23.612,29.958 13.114,26.938 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          points="
				10.093,22.423 23.612,29.958 13.114,26.938 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#FFDD00"
          points="13.114,26.938 23.612,29.958 10.093,29.958 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="#F37321"
          d="M39.606,37.741H22.139c-1.475,0-2.669-1.194-2.669-2.668V24.961
				c0-1.475,1.194-2.669,2.669-2.669h17.468c1.476,0,2.669,1.194,2.669,2.669v10.112C42.275,36.547,41.082,37.741,39.606,37.741"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_96_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          d="M39.606,37.741
				H22.139c-1.475,0-2.669-1.194-2.669-2.668V24.961c0-1.475,1.194-2.669,2.669-2.669h17.468c1.476,0,2.669,1.194,2.669,2.669
				v10.112C42.275,36.547,41.082,37.741,39.606,37.741z"
        />
      </g>
      <g>
        <defs>
          <path
            id="CONFECTIONARY_SVGID_97_"
            d="M22.138,22.291c-1.474,0-2.669,1.196-2.669,2.67v10.111c0,1.476,1.195,2.67,2.669,2.67h17.467
					c1.475,0,2.671-1.194,2.671-2.67V24.96c0-1.474-1.196-2.67-2.671-2.67H22.138z"
          />
        </defs>
        <clipPath id="CONFECTIONARY_SVGID_98_">
          <use xlinkHref="#CONFECTIONARY_SVGID_97_" overflow="visible" />
        </clipPath>

        <rect
          x="17.554"
          y="29.307"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.1824 30.6212)"
          clipPath="url(#CONFECTIONARY_SVGID_98_)"
          fill="#FFDD00"
          width="26.638"
          height="1.418"
        />

        <rect
          x="22.587"
          y="29.307"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.7083 34.1802)"
          clipPath="url(#CONFECTIONARY_SVGID_98_)"
          fill="#FFDD00"
          width="26.638"
          height="1.418"
        />

        <rect
          x="27.621"
          y="29.307"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -9.2339 37.7397)"
          clipPath="url(#CONFECTIONARY_SVGID_98_)"
          fill="#FFDD00"
          width="26.638"
          height="1.419"
        />

        <rect
          x="32.654"
          y="29.308"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -7.76 41.2974)"
          clipPath="url(#CONFECTIONARY_SVGID_98_)"
          fill="#FFDD00"
          width="26.638"
          height="1.418"
        />

        <rect
          x="12.52"
          y="29.307"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.6568 27.0623)"
          clipPath="url(#CONFECTIONARY_SVGID_98_)"
          fill="#FFDD00"
          width="26.637"
          height="1.418"
        />

        <rect
          x="7.487"
          y="29.308"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -15.1307 23.5027)"
          clipPath="url(#CONFECTIONARY_SVGID_98_)"
          fill="#FFDD00"
          width="26.638"
          height="1.418"
        />

        <rect
          x="2.453"
          y="29.307"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -16.6051 19.9438)"
          clipPath="url(#CONFECTIONARY_SVGID_98_)"
          fill="#FFDD00"
          width="26.638"
          height="1.419"
        />
      </g>
      <g>
        <defs>
          <rect
            id="CONFECTIONARY_SVGID_99_"
            x="0.002"
            y="0"
            width="61.688"
            height="61.688"
          />
        </defs>
        <clipPath id="CONFECTIONARY_SVGID_100_">
          <use xlinkHref="#CONFECTIONARY_SVGID_99_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#663372"
          d="M38.748,30.017c0,2.664-3.525,4.824-7.875,4.824s-7.876-2.16-7.876-4.824
				c0-2.665,3.526-4.825,7.876-4.825S38.748,27.352,38.748,30.017"
        />

        <ellipse
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="1.098"
          strokeMiterlimit="10"
          cx="30.872"
          cy="30.017"
          rx="7.876"
          ry="4.824"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EF97A8"
          d="M56.012,4.407c0.689,0,1.271,0.582,1.271,1.271v50.333
				c0,0.688-0.581,1.271-1.271,1.271H5.678c-0.689,0-1.27-0.582-1.27-1.271V5.678c0-0.689,0.581-1.271,1.27-1.271H56.012
				 M56.012,0.001H5.678c-3.122,0-5.677,2.555-5.677,5.677v50.333c0,3.121,2.555,5.677,5.677,5.677h50.333
				c3.123,0,5.678-2.556,5.678-5.677V5.678C61.689,2.556,59.135,0.001,56.012,0.001"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#5087C7"
          d="M30.856,55.361c-2.638,0-4.774-2.135-4.774-4.771s2.136-4.773,4.774-4.773
				c2.638,0,4.774,2.137,4.774,4.773S33.494,55.361,30.856,55.361"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M30.856,45.816c2.638,0,4.774,2.137,4.774,4.773s-2.137,4.771-4.774,4.771
				c-2.638,0-4.774-2.135-4.774-4.771S28.218,45.816,30.856,45.816 M30.856,45.404c-2.86,0-5.186,2.326-5.186,5.186
				c0,2.858,2.326,5.184,5.186,5.184c2.858,0,5.185-2.325,5.185-5.184C36.041,47.73,33.715,45.404,30.856,45.404"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M33.35,48.645c-0.238,0-0.464,0.107-0.619,0.251l-0.014-0.013
				c0.073-0.118,0.169-0.346,0.169-0.715c0-0.883-0.885-1.551-2.029-1.551c-1.145,0-2.042,0.668-2.042,1.551
				c0,0.369,0.12,0.597,0.18,0.715l-0.013,0.013c-0.155-0.144-0.381-0.251-0.619-0.251c-0.729,0-1.457,0.704-1.457,2.018
				c0,1.287,0.717,2.016,1.492,2.016c0.716,0,1.218-0.396,1.67-0.848c-0.071-0.107-0.179-0.216-0.298-0.216
				s-0.251,0.085-0.477,0.265c-0.298,0.227-0.561,0.357-0.908,0.357c-0.608,0-0.869-0.682-0.869-1.574
				c0-0.955,0.25-1.588,0.895-1.588c0.286,0,0.476,0.083,0.668,0.262c0.286,0.264,0.381,0.334,0.512,0.334
				c0.096,0,0.156-0.06,0.179-0.094c-0.214-0.216-0.525-0.669-0.525-1.254c0-0.668,0.561-1.134,1.612-1.134
				c1.049,0,1.611,0.466,1.611,1.134c0,0.585-0.311,1.038-0.526,1.254c0.024,0.034,0.083,0.094,0.179,0.094
				c0.132,0,0.227-0.07,0.513-0.334c0.179-0.179,0.382-0.262,0.668-0.262c0.646,0,0.896,0.633,0.896,1.588
				c0,0.893-0.262,1.574-0.87,1.574c-0.347,0-0.621-0.131-0.908-0.357c-0.226-0.18-0.356-0.265-0.477-0.265
				c-0.119,0-0.226,0.108-0.298,0.216c0.441,0.452,0.956,0.848,1.67,0.848c0.775,0,1.492-0.729,1.492-2.016
				C34.807,49.349,34.078,48.645,33.35,48.645"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M31.656,48.621h-0.537c-0.036,0-0.059-0.024-0.071-0.049l-0.168-0.512
				c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.512c-0.012,0.037-0.035,0.049-0.06,0.049H30.08c-0.024,0-0.035,0.011-0.012,0.023
				l0.442,0.322c0.024,0.023,0.024,0.061,0.012,0.084l-0.167,0.5c-0.011,0.023-0.011,0.049,0.024,0.023l0.442-0.309
				c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.035,0.013,0.023-0.011l-0.166-0.513
				c-0.013-0.036,0-0.072,0.024-0.084l0.44-0.322C31.691,48.632,31.679,48.621,31.656,48.621"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M31.25,52.152c-0.226-0.573-0.406-0.967-0.406-1.443
				c0-0.204,0.06-0.382,0.096-0.477c0.047-0.097,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.167-0.321,0.311
				c-0.073,0.118-0.156,0.346-0.156,0.524c0,0.335,0.108,0.692,0.179,0.907c0.132,0.369,0.251,0.8,0.251,1.205
				c0,0.646-0.287,1.084-0.799,1.479c0.047,0.037,0.166,0.096,0.31,0.096c0.5,0,1.098-0.56,1.098-1.324
				C31.513,52.82,31.381,52.51,31.25,52.152"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-2.434-25.929c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.434z M-2.525-25.26c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H-2.525z"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          points="-1.431,-25.93 -1.22,-24.885 -1.215,-24.885 -1.002,-25.93
				-0.56,-25.93 -0.56,-24.45 -0.834,-24.45 -0.834,-25.632 -0.838,-25.632 -1.101,-24.45 -1.334,-24.45 -1.596,-25.632
				-1.6,-25.632 -1.6,-24.45 -1.874,-24.45 -1.874,-25.93 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-0.106-24.869c0,0.034,0.003,0.066,0.008,0.094
				c0.005,0.028,0.016,0.053,0.031,0.072s0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
				c0.045,0,0.086-0.013,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.025-0.024-0.046-0.044-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048
				c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
				s-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.016,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0.246
				c0-0.071-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
				c-0.023,0.01-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-2.434-23.986c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.434z M-2.525-23.317c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H-2.525z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-1.615-23.622v0.149h0.004c0.029-0.054,0.064-0.097,0.106-0.129
				c0.041-0.033,0.092-0.05,0.151-0.05c0.008,0,0.017,0.001,0.026,0.001c0.009,0.002,0.018,0.003,0.026,0.004v0.274
				c-0.016-0.002-0.03-0.003-0.045-0.003c-0.014-0.001-0.029-0.001-0.044-0.001c-0.025,0-0.05,0.003-0.075,0.01
				c-0.025,0.007-0.047,0.018-0.068,0.034s-0.037,0.037-0.05,0.062c-0.012,0.026-0.019,0.057-0.019,0.094v0.67h-0.286v-1.115H-1.615
				z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-1.214-23.317c0.016-0.073,0.041-0.135,0.075-0.184
				c0.034-0.049,0.08-0.086,0.136-0.112c0.056-0.025,0.125-0.039,0.206-0.039c0.086,0,0.157,0.011,0.214,0.035
				c0.057,0.025,0.101,0.061,0.134,0.111c0.033,0.05,0.057,0.111,0.071,0.185c0.013,0.073,0.021,0.159,0.021,0.257
				c0,0.095-0.008,0.179-0.023,0.253c-0.016,0.072-0.04,0.134-0.075,0.183c-0.035,0.049-0.08,0.085-0.136,0.109
				c-0.056,0.023-0.124,0.034-0.206,0.034c-0.086,0.002-0.157-0.008-0.213-0.031c-0.057-0.023-0.102-0.059-0.135-0.108
				c-0.033-0.049-0.056-0.11-0.071-0.185c-0.013-0.073-0.021-0.159-0.021-0.255C-1.237-23.159-1.229-23.244-1.214-23.317
				 M-0.946-22.907c0.003,0.048,0.009,0.09,0.02,0.124c0.01,0.035,0.026,0.061,0.046,0.081c0.021,0.019,0.049,0.029,0.083,0.029
				c0.058,0,0.099-0.03,0.12-0.091c0.022-0.061,0.034-0.161,0.034-0.3c0-0.14-0.012-0.24-0.034-0.301
				c-0.021-0.061-0.062-0.091-0.12-0.091c-0.034,0-0.062,0.009-0.083,0.029c-0.02,0.019-0.036,0.046-0.046,0.08
				c-0.011,0.035-0.017,0.077-0.02,0.125s-0.004,0.101-0.004,0.158C-0.95-23.008-0.949-22.955-0.946-22.907"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M0.328-23.301c-0.004-0.026-0.01-0.05-0.019-0.071
				c-0.009-0.022-0.021-0.039-0.038-0.052c-0.017-0.013-0.039-0.02-0.066-0.02c-0.035,0-0.063,0.008-0.085,0.024
				c-0.023,0.016-0.039,0.04-0.05,0.07c-0.011,0.032-0.019,0.071-0.023,0.119c-0.004,0.048-0.007,0.104-0.007,0.169
				c0,0.069,0.003,0.128,0.007,0.177s0.012,0.09,0.023,0.121c0.011,0.032,0.026,0.055,0.047,0.07
				c0.02,0.014,0.046,0.021,0.077,0.021c0.049,0,0.084-0.02,0.105-0.062c0.023-0.041,0.034-0.108,0.034-0.199h0.274
				c0,0.146-0.033,0.258-0.099,0.335c-0.065,0.077-0.174,0.115-0.326,0.115c-0.072,0-0.135-0.008-0.188-0.026
				c-0.053-0.017-0.097-0.048-0.132-0.093c-0.036-0.045-0.063-0.105-0.081-0.181c-0.017-0.075-0.026-0.168-0.026-0.28
				c0-0.115,0.011-0.21,0.033-0.286c0.022-0.074,0.053-0.134,0.093-0.18c0.04-0.044,0.088-0.076,0.143-0.094
				c0.056-0.018,0.116-0.027,0.183-0.027c0.132,0,0.232,0.039,0.299,0.116c0.067,0.078,0.101,0.182,0.101,0.315H0.333
				C0.333-23.247,0.332-23.275,0.328-23.301"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M1-22.931c0,0.033,0.003,0.065,0.007,0.095
				c0.005,0.032,0.013,0.059,0.024,0.083c0.011,0.025,0.026,0.044,0.044,0.059c0.018,0.014,0.04,0.022,0.067,0.022
				c0.047,0,0.083-0.017,0.105-0.052c0.022-0.035,0.037-0.087,0.046-0.158h0.256c-0.005,0.13-0.04,0.229-0.103,0.297
				c-0.064,0.068-0.164,0.101-0.301,0.101c-0.103,0-0.184-0.017-0.242-0.052c-0.058-0.034-0.101-0.08-0.128-0.136
				c-0.028-0.057-0.045-0.121-0.052-0.191c-0.006-0.071-0.009-0.142-0.009-0.212c0-0.074,0.005-0.146,0.016-0.216
				c0.01-0.068,0.031-0.13,0.062-0.184c0.031-0.054,0.075-0.096,0.134-0.128c0.057-0.032,0.135-0.048,0.232-0.048
				c0.082,0,0.151,0.013,0.204,0.04c0.053,0.028,0.095,0.066,0.126,0.115c0.03,0.049,0.05,0.108,0.061,0.178
				c0.011,0.069,0.017,0.148,0.017,0.233v0.064H1V-22.931z M1.284-23.304c-0.004-0.032-0.011-0.06-0.022-0.083
				c-0.01-0.022-0.025-0.039-0.043-0.052C1.2-23.45,1.176-23.457,1.147-23.457c-0.029,0-0.053,0.006-0.071,0.019
				c-0.018,0.014-0.034,0.031-0.045,0.053c-0.011,0.021-0.019,0.045-0.024,0.071C1.003-23.287,1-23.26,1-23.234v0.044h0.293
				C1.291-23.234,1.288-23.271,1.284-23.304"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M1.925-22.835c0,0.049,0.012,0.088,0.038,0.118
				c0.026,0.029,0.063,0.044,0.111,0.044c0.044,0,0.08-0.011,0.106-0.034c0.026-0.022,0.039-0.054,0.039-0.098
				c0-0.037-0.01-0.064-0.031-0.083c-0.021-0.018-0.045-0.033-0.072-0.044l-0.202-0.073c-0.078-0.027-0.138-0.067-0.178-0.117
				c-0.04-0.05-0.06-0.115-0.06-0.194c0-0.045,0.008-0.089,0.023-0.129c0.015-0.041,0.039-0.077,0.073-0.107
				c0.033-0.03,0.075-0.054,0.127-0.072c0.052-0.019,0.115-0.028,0.188-0.028c0.129,0,0.226,0.028,0.288,0.083
				c0.062,0.056,0.093,0.134,0.093,0.235v0.046H2.211c0-0.059-0.009-0.101-0.028-0.128c-0.019-0.027-0.052-0.04-0.098-0.04
				c-0.037,0-0.068,0.01-0.095,0.029c-0.027,0.02-0.04,0.051-0.04,0.09c0,0.028,0.008,0.053,0.026,0.075
				c0.017,0.022,0.05,0.041,0.098,0.056l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.12c0.037,0.048,0.056,0.115,0.056,0.199
				c0,0.06-0.01,0.112-0.031,0.155c-0.021,0.044-0.05,0.08-0.086,0.109c-0.037,0.028-0.081,0.049-0.131,0.062
				c-0.05,0.012-0.106,0.018-0.167,0.018c-0.079,0-0.146-0.007-0.2-0.022c-0.052-0.015-0.095-0.038-0.126-0.069
				c-0.032-0.03-0.053-0.067-0.065-0.111c-0.013-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.835z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M2.845-22.835c0,0.049,0.013,0.088,0.038,0.118
				c0.026,0.029,0.063,0.044,0.111,0.044c0.045,0,0.08-0.011,0.106-0.034c0.026-0.022,0.039-0.054,0.039-0.098
				c0-0.037-0.01-0.064-0.03-0.083c-0.021-0.018-0.045-0.033-0.073-0.044l-0.201-0.073c-0.079-0.027-0.138-0.067-0.178-0.117
				c-0.041-0.05-0.06-0.115-0.06-0.194c0-0.045,0.007-0.089,0.022-0.129c0.016-0.041,0.04-0.077,0.073-0.107
				c0.033-0.03,0.076-0.054,0.127-0.072c0.052-0.019,0.115-0.028,0.188-0.028c0.129,0,0.226,0.028,0.288,0.083
				c0.062,0.056,0.094,0.134,0.094,0.235v0.046H3.131c0-0.059-0.01-0.101-0.029-0.128c-0.019-0.027-0.051-0.04-0.098-0.04
				c-0.036,0-0.067,0.01-0.095,0.029c-0.027,0.02-0.04,0.051-0.04,0.09c0,0.028,0.009,0.053,0.026,0.075s0.05,0.041,0.098,0.056
				l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.12c0.038,0.048,0.056,0.115,0.056,0.199c0,0.06-0.01,0.112-0.031,0.155
				c-0.021,0.044-0.049,0.08-0.086,0.109c-0.036,0.028-0.08,0.049-0.131,0.062c-0.05,0.012-0.105,0.018-0.167,0.018
				c-0.079,0-0.146-0.007-0.2-0.022c-0.052-0.015-0.094-0.038-0.126-0.069c-0.031-0.03-0.053-0.067-0.065-0.111
				c-0.012-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.835z"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          points="-2.507,-22.044 -2.296,-20.999 -2.291,-20.999 -2.078,-22.044
				-1.636,-22.044 -1.636,-20.564 -1.91,-20.564 -1.91,-21.746 -1.914,-21.746 -2.177,-20.564 -2.41,-20.564 -2.672,-21.746
				-2.676,-21.746 -2.676,-20.564 -2.95,-20.564 -2.95,-22.044 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-1.433-21.364c0-0.065,0.011-0.119,0.032-0.163
				c0.02-0.043,0.048-0.078,0.083-0.106c0.035-0.026,0.077-0.046,0.124-0.057c0.048-0.012,0.099-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025c0.053,0.016,0.093,0.04,0.122,0.07c0.03,0.031,0.049,0.066,0.059,0.109
				c0.011,0.042,0.016,0.088,0.016,0.138v0.587c0,0.052,0.002,0.093,0.007,0.121c0.005,0.029,0.014,0.061,0.029,0.094h-0.275
				c-0.009-0.018-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059h-0.004c-0.033,0.058-0.071,0.096-0.114,0.113
				c-0.044,0.017-0.101,0.026-0.17,0.026c-0.049,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.072
				c-0.021-0.03-0.036-0.065-0.046-0.102c-0.009-0.038-0.014-0.076-0.014-0.113c0-0.053,0.005-0.098,0.016-0.136
				c0.012-0.038,0.029-0.071,0.052-0.097c0.022-0.028,0.051-0.05,0.087-0.068c0.034-0.018,0.077-0.033,0.127-0.048l0.161-0.043
				c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.077-0.03-0.101
				s-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.099,0.039c-0.023,0.027-0.034,0.062-0.034,0.106v0.031h-0.257V-21.364z
				 M-0.973-21.085c-0.022,0.009-0.042,0.015-0.06,0.019c-0.058,0.013-0.1,0.034-0.124,0.062c-0.025,0.029-0.038,0.069-0.038,0.119
				c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.045,0.045,0.083,0.045c0.019,0,0.039-0.003,0.06-0.009
				c0.021-0.007,0.04-0.016,0.058-0.03c0.017-0.014,0.031-0.032,0.042-0.054c0.01-0.022,0.016-0.048,0.016-0.079v-0.219
				C-0.93-21.106-0.951-21.094-0.973-21.085"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M0.401-20.62c0,0.144-0.036,0.251-0.107,0.323
				c-0.071,0.072-0.19,0.108-0.358,0.108c-0.121,0-0.215-0.026-0.281-0.078c-0.065-0.051-0.098-0.125-0.098-0.221h0.273
				c0,0.036,0.015,0.064,0.044,0.085c0.014,0.01,0.029,0.018,0.045,0.023c0.016,0.006,0.033,0.008,0.051,0.008
				c0.058,0,0.098-0.018,0.122-0.055c0.023-0.038,0.035-0.085,0.035-0.139v-0.148H0.124c-0.029,0.042-0.065,0.075-0.107,0.1
				s-0.086,0.037-0.131,0.037c-0.114,0-0.199-0.043-0.256-0.129c-0.058-0.087-0.086-0.232-0.086-0.434
				c0-0.065,0.003-0.131,0.011-0.199c0.008-0.068,0.024-0.129,0.048-0.184c0.024-0.054,0.059-0.099,0.106-0.134
				c0.046-0.034,0.107-0.052,0.185-0.052c0.04,0,0.081,0.011,0.124,0.034c0.043,0.022,0.078,0.062,0.106,0.12h0.004v-0.124h0.274
				V-20.62z M-0.166-20.977c0.003,0.034,0.01,0.066,0.021,0.092c0.01,0.027,0.025,0.048,0.043,0.065
				c0.018,0.016,0.043,0.023,0.074,0.023c0.03,0,0.055-0.007,0.075-0.023c0.02-0.017,0.036-0.038,0.048-0.066
				c0.012-0.027,0.02-0.06,0.025-0.1s0.007-0.083,0.007-0.13c0-0.135-0.011-0.231-0.032-0.288c-0.022-0.057-0.06-0.085-0.117-0.085
				c-0.032,0-0.057,0.007-0.077,0.021c-0.019,0.014-0.035,0.037-0.045,0.068c-0.011,0.03-0.019,0.072-0.022,0.124
				c-0.004,0.051-0.006,0.115-0.006,0.19C-0.171-21.048-0.169-21.011-0.166-20.977"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M0.847-20.988c0,0.033,0.002,0.065,0.007,0.095
				c0.005,0.032,0.013,0.059,0.024,0.083c0.011,0.025,0.026,0.044,0.044,0.059c0.017,0.014,0.04,0.022,0.066,0.022
				c0.048,0,0.083-0.017,0.106-0.052c0.022-0.035,0.037-0.087,0.045-0.158h0.257c-0.005,0.13-0.04,0.229-0.103,0.297
				c-0.064,0.067-0.164,0.101-0.301,0.101c-0.104,0-0.184-0.017-0.242-0.052c-0.059-0.034-0.101-0.08-0.129-0.136
				c-0.028-0.057-0.044-0.121-0.051-0.191c-0.006-0.071-0.009-0.142-0.009-0.212c0-0.074,0.005-0.146,0.016-0.216
				c0.009-0.068,0.03-0.13,0.062-0.184c0.03-0.054,0.075-0.096,0.133-0.128c0.058-0.032,0.136-0.048,0.233-0.048
				c0.082,0,0.15,0.013,0.203,0.04c0.054,0.028,0.096,0.066,0.126,0.115c0.03,0.048,0.051,0.108,0.062,0.178
				c0.011,0.069,0.017,0.148,0.017,0.233v0.064H0.847V-20.988z M1.131-21.361c-0.005-0.032-0.011-0.06-0.022-0.083
				c-0.01-0.022-0.025-0.039-0.043-0.052c-0.019-0.011-0.043-0.017-0.072-0.017c-0.029,0-0.053,0.006-0.071,0.019
				c-0.019,0.014-0.034,0.031-0.045,0.053c-0.011,0.021-0.019,0.045-0.024,0.071c-0.005,0.026-0.007,0.053-0.007,0.079v0.043h0.292
				C1.138-21.292,1.135-21.328,1.131-21.361"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M1.835-21.679v0.131H1.84c0.03-0.056,0.069-0.096,0.117-0.122
				c0.049-0.025,0.104-0.039,0.167-0.039c0.09,0,0.16,0.026,0.209,0.076c0.05,0.049,0.074,0.13,0.074,0.242v0.827H2.122v-0.769
				c0-0.058-0.011-0.098-0.03-0.121s-0.05-0.035-0.093-0.035c-0.101,0-0.152,0.062-0.152,0.185v0.74H1.562v-1.115H1.835z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M2.946-21.999v0.319h0.165v0.195H2.946v0.603
				c0,0.044,0.007,0.076,0.02,0.094c0.013,0.019,0.04,0.028,0.081,0.028c0.011,0,0.022,0,0.034-0.001
				c0.011,0,0.021-0.001,0.03-0.003v0.2c-0.031,0-0.062,0.001-0.091,0.002c-0.03,0.002-0.062,0.003-0.095,0.003
				c-0.055,0-0.1-0.004-0.135-0.01c-0.036-0.009-0.063-0.024-0.081-0.047c-0.019-0.022-0.032-0.053-0.039-0.09
				c-0.007-0.037-0.01-0.084-0.01-0.141v-0.638H2.516v-0.195H2.66v-0.319H2.946z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M3.209-21.364c0-0.065,0.011-0.119,0.032-0.163
				c0.02-0.043,0.048-0.078,0.083-0.106c0.035-0.026,0.077-0.046,0.124-0.057c0.048-0.012,0.099-0.018,0.153-0.018
				c0.085,0,0.155,0.008,0.207,0.025c0.053,0.016,0.093,0.04,0.122,0.07c0.03,0.031,0.049,0.066,0.059,0.109
				C4-21.462,4.005-21.416,4.005-21.366v0.587c0,0.052,0.002,0.093,0.007,0.121c0.005,0.029,0.014,0.061,0.029,0.094H3.767
				c-0.009-0.018-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059H3.728c-0.033,0.058-0.071,0.096-0.114,0.113
				c-0.044,0.017-0.101,0.026-0.17,0.026c-0.049,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.072
				c-0.021-0.03-0.036-0.065-0.046-0.102c-0.009-0.038-0.014-0.076-0.014-0.113c0-0.053,0.005-0.098,0.016-0.136
				c0.012-0.038,0.029-0.071,0.052-0.097c0.022-0.028,0.051-0.05,0.087-0.068c0.034-0.018,0.077-0.033,0.127-0.048l0.161-0.043
				c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.077-0.03-0.101
				c-0.02-0.024-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.099,0.039c-0.023,0.027-0.034,0.062-0.034,0.106v0.031H3.209
				V-21.364z M3.669-21.085c-0.022,0.009-0.042,0.015-0.06,0.019c-0.058,0.013-0.1,0.034-0.124,0.062
				c-0.025,0.029-0.038,0.069-0.038,0.119c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.045,0.045,0.083,0.045
				c0.019,0,0.039-0.003,0.06-0.009c0.021-0.007,0.04-0.016,0.058-0.03c0.017-0.014,0.031-0.032,0.042-0.054
				c0.01-0.021,0.016-0.048,0.016-0.079v-0.219C3.712-21.106,3.691-21.094,3.669-21.085"
        />
        <rect
          x="-13.217"
          y="-26.429"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EF97A8"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-12.302-25.929c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.302z M-12.393-25.26c0.059,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H-12.393z
				"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          points="-11.299,-25.93 -11.088,-24.885 -11.083,-24.885 -10.871,-25.93
				-10.428,-25.93 -10.428,-24.451 -10.702,-24.451 -10.702,-25.632 -10.707,-25.632 -10.969,-24.451 -11.202,-24.451
				-11.464,-25.632 -11.468,-25.632 -11.468,-24.451 -11.742,-24.451 -11.742,-25.93 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-9.975-24.869c0,0.034,0.003,0.066,0.008,0.094s0.016,0.052,0.031,0.072
				c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.014,0.123-0.044
				c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086c-0.008-0.025-0.024-0.046-0.044-0.066
				c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048c-0.066-0.022-0.124-0.046-0.172-0.073
				c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122c-0.015-0.046-0.023-0.1-0.023-0.161
				c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.015,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.071-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
				c-0.023,0.009-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-8.369-25.487h-0.292v-0.195h0.027c0.1,0,0.177-0.019,0.235-0.055
				c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.298V-25.487z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-7.371-24.663c0.02,0.032,0.055,0.047,0.105,0.047
				c0.04,0,0.072-0.009,0.096-0.028c0.023-0.019,0.041-0.045,0.054-0.078c0.013-0.033,0.022-0.073,0.026-0.118
				c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.025,0.037-0.06,0.066-0.106,0.089c-0.047,0.023-0.103,0.035-0.169,0.035
				c-0.065,0-0.12-0.012-0.166-0.035c-0.046-0.023-0.083-0.054-0.111-0.095c-0.028-0.04-0.049-0.089-0.062-0.145
				c-0.014-0.056-0.02-0.117-0.02-0.183c0-0.17,0.038-0.3,0.113-0.39c0.075-0.09,0.196-0.135,0.362-0.135
				c0.102,0,0.183,0.019,0.244,0.058c0.061,0.037,0.108,0.088,0.14,0.151c0.032,0.063,0.054,0.135,0.064,0.219
				c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.004,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.057,0.258
				c-0.03,0.075-0.08,0.133-0.146,0.177c-0.068,0.043-0.162,0.065-0.281,0.065c-0.053,0-0.104-0.004-0.153-0.015
				c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.068-0.066-0.091-0.114c-0.023-0.048-0.034-0.107-0.036-0.178h0.286
				C-7.401-24.737-7.391-24.694-7.371-24.663 M-7.386-25.219c0.025,0.043,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.126-0.064
				c0.025-0.043,0.038-0.115,0.038-0.213c0-0.042-0.002-0.081-0.006-0.117c-0.004-0.036-0.012-0.066-0.024-0.092
				c-0.012-0.025-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.064,0.008-0.086,0.022
				c-0.021,0.015-0.037,0.035-0.049,0.061c-0.011,0.026-0.018,0.057-0.021,0.092c-0.004,0.036-0.005,0.074-0.005,0.116
				C-7.423-25.334-7.411-25.262-7.386-25.219"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M-5.807-25.661c-0.061,0.086-0.116,0.178-0.163,0.276
				s-0.089,0.199-0.126,0.302c-0.035,0.104-0.065,0.21-0.089,0.317c-0.023,0.107-0.041,0.212-0.053,0.315h-0.314
				c0.012-0.088,0.028-0.181,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
				c0.053-0.103,0.115-0.203,0.186-0.3h-0.617v-0.257h0.904V-25.661z"
        />
        <rect
          x="6.52"
          y="-26.429"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#88155D"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M7.435-25.93c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027H7.21v0.59H6.913v-1.479H7.435z M7.343-25.261c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058H7.21v0.45H7.343z"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          points="8.438,-25.93 8.648,-24.885 8.653,-24.885 8.866,-25.93
				9.309,-25.93 9.309,-24.45 9.034,-24.45 9.034,-25.632 9.03,-25.632 8.768,-24.45 8.534,-24.45 8.272,-25.632 8.269,-25.632
				8.269,-24.45 7.995,-24.45 7.995,-25.93 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M9.762-24.869c0,0.034,0.003,0.066,0.008,0.094s0.016,0.053,0.031,0.072
				c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.013,0.123-0.044
				c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086c-0.008-0.025-0.024-0.046-0.044-0.066
				c-0.021-0.02-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048c-0.066-0.022-0.124-0.046-0.172-0.073
				c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122c-0.015-0.046-0.023-0.1-0.023-0.161
				c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.016,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.071-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
				c-0.023,0.01-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.011,0.079,0.011,0.125
				c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
				C9.5-24.61,9.464-24.716,9.464-24.852v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M11.028-24.45c0-0.065,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.052,0.06-0.101,0.102-0.151c0.04-0.049,0.088-0.098,0.141-0.148l0.121-0.112
				c0.033-0.029,0.06-0.055,0.08-0.08c0.02-0.025,0.035-0.05,0.046-0.075s0.019-0.052,0.022-0.081s0.005-0.062,0.005-0.099
				c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023c-0.022,0.015-0.038,0.035-0.05,0.062
				c-0.011,0.026-0.019,0.056-0.023,0.091c-0.003,0.035-0.004,0.072-0.004,0.11h-0.287v-0.056c0-0.136,0.037-0.241,0.11-0.315
				c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103c0.072,0.069,0.108,0.168,0.108,0.296
				c0,0.047-0.004,0.091-0.013,0.132c-0.008,0.04-0.021,0.08-0.039,0.117c-0.018,0.038-0.041,0.074-0.071,0.109
				c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117c-0.024,0.039-0.041,0.071-0.047,0.098
				h0.553v0.233H11.028z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M12.023-24.45c0-0.065,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.052,0.06-0.101,0.102-0.151c0.04-0.049,0.088-0.098,0.141-0.148l0.121-0.112
				c0.033-0.029,0.06-0.055,0.08-0.08c0.02-0.025,0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				s0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.035-0.05,0.062c-0.011,0.026-0.019,0.056-0.023,0.091c-0.003,0.035-0.004,0.072-0.004,0.11h-0.287v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
				c0.072,0.069,0.108,0.168,0.108,0.296c0,0.047-0.004,0.091-0.013,0.132c-0.008,0.04-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.041,0.071-0.047,0.098h0.553v0.233H12.023z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M13.071-25.102c0.042-0.067,0.104-0.109,0.187-0.125v-0.004
				c-0.04-0.01-0.074-0.025-0.101-0.046c-0.026-0.021-0.048-0.044-0.065-0.07c-0.016-0.027-0.028-0.054-0.035-0.083
				c-0.007-0.03-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.115-0.31c0.076-0.063,0.181-0.094,0.316-0.094
				c0.134,0,0.239,0.031,0.315,0.094c0.077,0.063,0.116,0.166,0.116,0.31c0,0.026-0.004,0.053-0.012,0.083
				c-0.007,0.029-0.019,0.056-0.036,0.083c-0.016,0.026-0.038,0.049-0.065,0.07s-0.06,0.036-0.099,0.046v0.004
				c0.083,0.016,0.145,0.058,0.187,0.125c0.041,0.066,0.062,0.145,0.062,0.238c0,0.043-0.005,0.09-0.015,0.141
				c-0.011,0.051-0.033,0.098-0.066,0.143c-0.033,0.044-0.08,0.08-0.142,0.109c-0.061,0.029-0.143,0.044-0.245,0.044
				s-0.184-0.015-0.246-0.044s-0.109-0.065-0.142-0.109c-0.033-0.045-0.055-0.092-0.065-0.143c-0.011-0.051-0.016-0.098-0.016-0.141
				C13.009-24.957,13.03-25.036,13.071-25.102 M13.315-24.771c0.005,0.032,0.014,0.059,0.027,0.082
				c0.013,0.023,0.031,0.041,0.053,0.054s0.05,0.019,0.083,0.019c0.033,0,0.06-0.006,0.083-0.019
				c0.021-0.013,0.039-0.031,0.052-0.054c0.013-0.023,0.023-0.05,0.027-0.082c0.005-0.03,0.008-0.063,0.008-0.098
				s-0.003-0.068-0.008-0.099c-0.004-0.03-0.014-0.057-0.027-0.08c-0.013-0.023-0.031-0.04-0.052-0.053
				c-0.023-0.014-0.05-0.02-0.083-0.02c-0.033,0-0.061,0.006-0.083,0.02c-0.022,0.013-0.04,0.03-0.053,0.053s-0.022,0.05-0.027,0.08
				c-0.005,0.031-0.007,0.064-0.007,0.099S13.311-24.801,13.315-24.771 M13.373-25.366c0.026,0.033,0.061,0.05,0.106,0.05
				c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.202-0.145-0.202
				c-0.097,0-0.145,0.067-0.145,0.202C13.333-25.451,13.346-25.399,13.373-25.366"
        />
        <rect
          x="16.389"
          y="-26.429"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#663372"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M17.303-25.93c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.303z M17.212-25.261c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H17.212z"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          points="18.306,-25.93 18.517,-24.885 18.522,-24.885 18.735,-25.93
				19.177,-25.93 19.177,-24.45 18.903,-24.45 18.903,-25.632 18.899,-25.632 18.636,-24.45 18.403,-24.45 18.141,-25.632
				18.137,-25.632 18.137,-24.45 17.863,-24.45 17.863,-25.93 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M19.63-24.869c0,0.034,0.003,0.066,0.008,0.094s0.016,0.053,0.031,0.072
				c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.013,0.123-0.044
				c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086c-0.008-0.025-0.024-0.046-0.044-0.066
				c-0.021-0.02-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048c-0.066-0.022-0.124-0.046-0.172-0.073
				c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122s-0.023-0.1-0.023-0.161
				c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.016,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.071-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
				c-0.023,0.01-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M21.199-25.656l-0.031,0.334h0.004c0.028-0.04,0.064-0.067,0.108-0.085
				c0.044-0.017,0.098-0.025,0.162-0.025c0.07,0,0.128,0.013,0.175,0.04c0.046,0.027,0.083,0.063,0.11,0.107
				c0.026,0.044,0.046,0.094,0.057,0.15c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.222
				c-0.013,0.066-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.083-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
				c-0.093,0-0.168-0.012-0.225-0.037c-0.057-0.025-0.101-0.058-0.132-0.099c-0.03-0.041-0.05-0.085-0.061-0.132
				c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287c0,0.024,0.001,0.05,0.004,0.078c0.002,0.028,0.009,0.054,0.02,0.078
				c0.01,0.024,0.025,0.044,0.044,0.06c0.02,0.016,0.045,0.023,0.076,0.023c0.063,0,0.107-0.023,0.135-0.071
				c0.027-0.047,0.04-0.119,0.04-0.214c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.041-0.014-0.072-0.027-0.096
				c-0.014-0.024-0.031-0.04-0.054-0.05c-0.021-0.009-0.049-0.014-0.082-0.014c-0.043,0-0.076,0.012-0.099,0.036
				c-0.022,0.024-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H21.199z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M21.891-24.45c0-0.065,0.007-0.126,0.02-0.183
				c0.013-0.056,0.033-0.11,0.06-0.161c0.027-0.052,0.06-0.101,0.102-0.151c0.04-0.049,0.088-0.098,0.141-0.148l0.121-0.112
				c0.033-0.029,0.06-0.055,0.08-0.08c0.02-0.025,0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.052,0.022-0.081
				s0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
				c-0.022,0.015-0.038,0.035-0.05,0.062c-0.011,0.026-0.019,0.056-0.023,0.091c-0.003,0.035-0.004,0.072-0.004,0.11h-0.287v-0.056
				c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
				c0.072,0.069,0.108,0.168,0.108,0.296c0,0.047-0.004,0.091-0.013,0.132c-0.008,0.04-0.021,0.08-0.039,0.117
				c-0.018,0.038-0.041,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.041,0.071-0.047,0.098h0.553v0.233H21.891z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M22.897-25.429c0.006-0.094,0.025-0.177,0.056-0.25
				c0.03-0.072,0.076-0.13,0.137-0.174c0.062-0.043,0.147-0.065,0.256-0.065c0.09,0,0.164,0.013,0.223,0.039
				c0.059,0.025,0.106,0.066,0.141,0.123c0.035,0.056,0.06,0.13,0.075,0.22c0.014,0.091,0.022,0.201,0.022,0.331
				c0,0.102-0.004,0.2-0.011,0.294c-0.007,0.094-0.026,0.177-0.056,0.25c-0.03,0.072-0.076,0.13-0.138,0.171
				c-0.061,0.042-0.147,0.062-0.256,0.062c-0.088,0-0.162-0.011-0.221-0.035c-0.06-0.024-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.061-0.13-0.075-0.221c-0.014-0.091-0.022-0.201-0.022-0.33C22.886-25.236,22.889-25.334,22.897-25.429
				 M23.192-24.843c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.033,0.028,0.056,0.05,0.069c0.02,0.013,0.046,0.018,0.078,0.018
				c0.033,0,0.061-0.007,0.081-0.023s0.037-0.043,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.153
				c0.004-0.063,0.007-0.142,0.007-0.236v-0.159c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.059-0.014-0.105-0.026-0.139
				c-0.013-0.034-0.03-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.007-0.08,0.024
				c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.037-0.02,0.088-0.025,0.152c-0.005,0.063-0.008,0.142-0.008,0.236v0.16
				C23.184-24.978,23.187-24.901,23.192-24.843"
        />
        <rect
          x="26.257"
          y="-26.429"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFDD00"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M27.171-25.93c0.076,0,0.14,0.012,0.192,0.037
				c0.052,0.027,0.094,0.06,0.125,0.102c0.032,0.041,0.055,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H27.171z M27.08-25.261c0.059,0,0.107-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H27.08z"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          points="28.174,-25.93 28.385,-24.885 28.39,-24.885 28.603,-25.93
				29.045,-25.93 29.045,-24.45 28.771,-24.45 28.771,-25.632 28.767,-25.632 28.504,-24.45 28.271,-24.45 28.009,-25.632
				28.005,-25.632 28.005,-24.45 27.731,-24.45 27.731,-25.93 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M29.499-24.869c0,0.034,0.003,0.066,0.008,0.094s0.016,0.053,0.031,0.072
				c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.013,0.123-0.044
				c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086c-0.008-0.025-0.024-0.046-0.044-0.066
				c-0.021-0.02-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.031-0.125-0.048c-0.066-0.022-0.124-0.046-0.172-0.073
				c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122c-0.015-0.046-0.023-0.1-0.023-0.161
				c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.021
				c0.056,0.016,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
				c0-0.071-0.013-0.124-0.037-0.162c-0.025-0.038-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
				c-0.023,0.01-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
				c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
				c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.012,0.079,0.012,0.125
				c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
				c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M31.105-25.486h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.235-0.056
				c0.057-0.038,0.094-0.097,0.109-0.18h0.219v1.467h-0.298V-25.486z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M31.771-25.429c0.006-0.094,0.025-0.177,0.056-0.25
				c0.03-0.072,0.076-0.13,0.137-0.174c0.062-0.043,0.147-0.065,0.256-0.065c0.091,0,0.164,0.013,0.224,0.039
				c0.059,0.025,0.105,0.066,0.141,0.123c0.035,0.056,0.061,0.13,0.074,0.22c0.015,0.091,0.022,0.201,0.022,0.331
				c0,0.102-0.004,0.2-0.011,0.294c-0.007,0.094-0.025,0.177-0.057,0.25c-0.029,0.072-0.076,0.13-0.137,0.171
				c-0.062,0.042-0.148,0.062-0.257,0.062c-0.088,0-0.162-0.011-0.221-0.035c-0.06-0.024-0.107-0.063-0.142-0.12
				c-0.036-0.056-0.061-0.13-0.075-0.221c-0.014-0.091-0.022-0.201-0.022-0.33C31.76-25.236,31.763-25.334,31.771-25.429
				 M32.066-24.843c0.004,0.059,0.012,0.105,0.025,0.139c0.012,0.033,0.027,0.056,0.05,0.069c0.021,0.013,0.046,0.018,0.078,0.018
				c0.033,0,0.062-0.007,0.081-0.023s0.037-0.043,0.049-0.081s0.02-0.089,0.025-0.153c0.004-0.063,0.007-0.142,0.007-0.236v-0.159
				c0-0.093-0.003-0.168-0.007-0.227c-0.006-0.059-0.014-0.105-0.026-0.139c-0.013-0.034-0.03-0.057-0.05-0.069
				c-0.021-0.013-0.047-0.019-0.079-0.019s-0.06,0.007-0.08,0.024c-0.021,0.016-0.038,0.043-0.049,0.081
				c-0.013,0.037-0.021,0.088-0.024,0.152c-0.006,0.063-0.009,0.142-0.009,0.236v0.16C32.058-24.978,32.061-24.901,32.066-24.843"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M33.098-24.662c0.02,0.031,0.055,0.046,0.104,0.046
				c0.04,0,0.072-0.009,0.097-0.028c0.022-0.018,0.041-0.045,0.054-0.078c0.013-0.033,0.022-0.072,0.026-0.118
				c0.004-0.045,0.006-0.095,0.006-0.149v-0.07h-0.004c-0.025,0.037-0.061,0.066-0.106,0.09c-0.047,0.022-0.103,0.034-0.169,0.034
				c-0.064,0-0.12-0.012-0.166-0.034c-0.046-0.024-0.083-0.055-0.111-0.096c-0.027-0.04-0.049-0.089-0.063-0.145
				c-0.014-0.056-0.02-0.117-0.02-0.183c0-0.17,0.038-0.3,0.113-0.39s0.195-0.134,0.361-0.134c0.103,0,0.184,0.018,0.244,0.057
				c0.062,0.038,0.108,0.088,0.141,0.151c0.031,0.063,0.054,0.136,0.064,0.219c0.008,0.082,0.014,0.169,0.014,0.259
				c0,0.105-0.004,0.206-0.01,0.303c-0.006,0.098-0.024,0.184-0.057,0.259c-0.029,0.074-0.08,0.133-0.146,0.176
				c-0.067,0.044-0.161,0.065-0.28,0.065c-0.054,0-0.104-0.004-0.153-0.015c-0.049-0.01-0.092-0.03-0.129-0.058
				c-0.037-0.028-0.068-0.066-0.091-0.114c-0.022-0.047-0.034-0.107-0.036-0.177h0.286C33.067-24.737,33.077-24.694,33.098-24.662
				 M33.082-25.219c0.025,0.043,0.066,0.064,0.125,0.064s0.102-0.021,0.125-0.064c0.025-0.042,0.038-0.115,0.038-0.213
				c0-0.042-0.002-0.08-0.006-0.117c-0.004-0.035-0.012-0.066-0.024-0.092c-0.012-0.025-0.027-0.045-0.049-0.06
				c-0.021-0.014-0.05-0.022-0.084-0.022c-0.037,0-0.064,0.008-0.087,0.022c-0.021,0.015-0.036,0.035-0.049,0.061
				c-0.011,0.026-0.019,0.057-0.021,0.092c-0.004,0.036-0.006,0.074-0.006,0.116C33.045-25.334,33.057-25.261,33.082-25.219"
        />
        <rect
          x="36.127"
          y="-26.43"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#F37321"
          width="8.812"
          height="8.813"
        />

        <rect
          x="36.126"
          y="-26.429"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="none"
          stroke="#F37321"
          strokeWidth="0.518"
          strokeMiterlimit="10"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M37.039-25.93c0.076,0,0.141,0.012,0.192,0.037
				c0.052,0.027,0.095,0.06,0.125,0.102c0.032,0.041,0.056,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.012,0.143-0.033,0.199c-0.023,0.057-0.056,0.103-0.096,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
				c-0.057,0.017-0.123,0.027-0.192,0.027h-0.16v0.59h-0.298v-1.479H37.039z M36.949-25.261c0.059,0,0.106-0.018,0.144-0.054
				s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.031-0.038-0.085-0.058-0.16-0.058h-0.124v0.45H36.949z"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          points="38.043,-25.93 38.254,-24.885 38.259,-24.885 38.472,-25.93
				38.914,-25.93 38.914,-24.45 38.64,-24.45 38.64,-25.632 38.636,-25.632 38.373,-24.45 38.14,-24.45 37.877,-25.632
				37.873,-25.632 37.873,-24.45 37.6,-24.45 37.6,-25.93 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M39.367-24.869c0,0.034,0.003,0.066,0.008,0.094
				c0.006,0.028,0.016,0.053,0.031,0.072c0.016,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.016,0.098,0.016
				c0.045,0,0.086-0.013,0.123-0.044c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
				c-0.008-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.047-0.036-0.082-0.053c-0.032-0.015-0.074-0.031-0.125-0.048
				c-0.065-0.021-0.123-0.046-0.172-0.073c-0.049-0.026-0.088-0.057-0.121-0.092c-0.032-0.035-0.057-0.076-0.071-0.122
				c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.147,0.041-0.256,0.121-0.327c0.082-0.072,0.194-0.108,0.336-0.108
				c0.067,0,0.129,0.007,0.185,0.021c0.056,0.016,0.104,0.039,0.144,0.071c0.043,0.033,0.073,0.074,0.096,0.124
				c0.023,0.051,0.035,0.111,0.035,0.182v0.041h-0.287c0-0.071-0.013-0.124-0.037-0.162c-0.024-0.038-0.066-0.057-0.123-0.057
				c-0.033,0-0.062,0.005-0.084,0.014c-0.022,0.01-0.039,0.023-0.053,0.038c-0.014,0.016-0.021,0.035-0.026,0.055
				c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.018,0.031,0.059,0.059,0.123,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.091,0.071-0.217,0.106-0.379,0.106
				c-0.169,0-0.29-0.036-0.361-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M40.973-25.486h-0.291v-0.195h0.027c0.1,0,0.176-0.018,0.234-0.056
				c0.057-0.038,0.094-0.097,0.109-0.18h0.219v1.467h-0.299V-25.486z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M41.931-25.656l-0.031,0.334h0.005c0.027-0.04,0.063-0.067,0.107-0.085
				c0.044-0.017,0.098-0.025,0.162-0.025c0.07,0,0.129,0.013,0.175,0.04s0.083,0.063,0.11,0.107c0.025,0.044,0.046,0.094,0.057,0.15
				c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.222c-0.013,0.066-0.038,0.123-0.073,0.171
				s-0.084,0.083-0.145,0.108c-0.061,0.024-0.137,0.036-0.231,0.036c-0.093,0-0.168-0.012-0.226-0.037
				c-0.057-0.025-0.1-0.058-0.131-0.099c-0.03-0.041-0.051-0.085-0.062-0.132c-0.01-0.048-0.017-0.093-0.017-0.134v-0.039h0.287
				c0,0.024,0.002,0.05,0.004,0.078c0.003,0.028,0.01,0.054,0.021,0.078s0.025,0.044,0.044,0.06c0.02,0.016,0.045,0.023,0.077,0.023
				c0.062,0,0.106-0.023,0.134-0.071c0.027-0.047,0.041-0.119,0.041-0.214c0-0.061-0.004-0.111-0.01-0.151
				c-0.005-0.041-0.014-0.072-0.027-0.096s-0.031-0.04-0.054-0.05c-0.021-0.009-0.049-0.014-0.082-0.014
				c-0.043,0-0.075,0.012-0.099,0.036c-0.022,0.024-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H41.931z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M42.634-25.429c0.007-0.094,0.025-0.177,0.056-0.25
				c0.03-0.072,0.076-0.13,0.138-0.174c0.062-0.043,0.147-0.065,0.256-0.065c0.09,0,0.164,0.013,0.224,0.039
				c0.059,0.025,0.105,0.066,0.141,0.123c0.035,0.056,0.06,0.13,0.074,0.22c0.015,0.091,0.022,0.201,0.022,0.331
				c0,0.102-0.004,0.2-0.011,0.294c-0.008,0.094-0.026,0.177-0.057,0.25c-0.029,0.072-0.076,0.13-0.138,0.171
				c-0.062,0.042-0.147,0.062-0.256,0.062c-0.088,0-0.162-0.011-0.222-0.035c-0.06-0.024-0.106-0.063-0.142-0.12
				c-0.036-0.056-0.061-0.13-0.075-0.221c-0.014-0.091-0.021-0.201-0.021-0.33C42.623-25.237,42.626-25.334,42.634-25.429
				 M42.93-24.843c0.004,0.059,0.012,0.105,0.025,0.139c0.012,0.033,0.027,0.056,0.05,0.069c0.021,0.013,0.046,0.018,0.078,0.018
				c0.033,0,0.062-0.007,0.081-0.023s0.037-0.043,0.049-0.081s0.02-0.089,0.025-0.153c0.004-0.063,0.007-0.142,0.007-0.236v-0.159
				c0-0.093-0.003-0.168-0.007-0.227c-0.006-0.059-0.014-0.105-0.026-0.139c-0.013-0.034-0.03-0.057-0.05-0.069
				c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.007-0.08,0.024c-0.021,0.016-0.038,0.043-0.049,0.081
				c-0.013,0.037-0.021,0.088-0.024,0.152c-0.006,0.063-0.009,0.142-0.009,0.236v0.16C42.921-24.978,42.924-24.901,42.93-24.843"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M43.92-25.656l-0.031,0.334h0.004c0.029-0.04,0.064-0.067,0.109-0.085
				c0.043-0.017,0.098-0.025,0.161-0.025c0.07,0,0.13,0.013,0.175,0.04c0.047,0.027,0.084,0.063,0.11,0.107s0.046,0.094,0.058,0.15
				c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.008,0.156-0.021,0.222c-0.013,0.066-0.037,0.123-0.072,0.171
				c-0.037,0.048-0.084,0.083-0.145,0.108c-0.061,0.024-0.138,0.036-0.232,0.036c-0.092,0-0.168-0.012-0.225-0.037
				s-0.102-0.058-0.132-0.099s-0.05-0.085-0.062-0.132c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287
				c0,0.024,0.001,0.05,0.004,0.078c0.002,0.028,0.01,0.054,0.02,0.078c0.011,0.024,0.025,0.044,0.045,0.06
				c0.019,0.016,0.045,0.023,0.076,0.023c0.063,0,0.106-0.023,0.135-0.071c0.027-0.047,0.039-0.119,0.039-0.214
				c0-0.061-0.002-0.111-0.008-0.151c-0.006-0.041-0.015-0.072-0.027-0.096c-0.014-0.024-0.031-0.04-0.055-0.05
				c-0.021-0.009-0.049-0.014-0.082-0.014c-0.043,0-0.076,0.012-0.099,0.036c-0.022,0.024-0.037,0.057-0.042,0.099h-0.268
				l0.054-0.798h0.765v0.232H43.92z"
        />
        <rect
          x="55.817"
          y="-26.43"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#5087C7"
          width="8.812"
          height="8.813"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M56.73-25.93c0.076,0,0.141,0.012,0.192,0.037
				c0.052,0.027,0.095,0.06,0.125,0.102c0.032,0.041,0.056,0.088,0.069,0.141c0.013,0.054,0.02,0.108,0.02,0.163
				c0,0.076-0.011,0.143-0.033,0.199c-0.023,0.057-0.055,0.103-0.096,0.139s-0.09,0.063-0.148,0.081
				c-0.058,0.017-0.123,0.027-0.192,0.027h-0.16v0.59h-0.298v-1.479H56.73z M56.64-25.261c0.06,0,0.107-0.018,0.144-0.054
				c0.037-0.036,0.056-0.092,0.056-0.168c0-0.074-0.017-0.131-0.048-0.17c-0.032-0.038-0.085-0.058-0.159-0.058h-0.125v0.45H56.64z"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          points="57.734,-25.93 57.945,-24.885 57.95,-24.885 58.164,-25.93
				58.604,-25.93 58.604,-24.45 58.332,-24.45 58.332,-25.632 58.327,-25.632 58.064,-24.45 57.832,-24.45 57.568,-25.632
				57.564,-25.632 57.564,-24.45 57.291,-24.45 57.291,-25.93 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M59.059-24.869c0,0.034,0.003,0.066,0.009,0.094
				c0.005,0.028,0.015,0.053,0.03,0.072c0.015,0.019,0.036,0.035,0.063,0.045c0.024,0.012,0.058,0.016,0.096,0.016
				c0.045,0,0.087-0.013,0.123-0.044c0.035-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.015-0.086
				c-0.008-0.025-0.023-0.046-0.045-0.066c-0.021-0.019-0.047-0.036-0.082-0.053c-0.032-0.015-0.074-0.031-0.125-0.048
				c-0.065-0.021-0.123-0.046-0.172-0.073c-0.047-0.026-0.088-0.057-0.121-0.092c-0.032-0.035-0.057-0.076-0.071-0.122
				c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.147,0.041-0.256,0.122-0.327c0.081-0.072,0.193-0.108,0.337-0.108
				c0.065,0,0.127,0.007,0.182,0.021c0.057,0.016,0.104,0.039,0.146,0.071c0.041,0.033,0.072,0.074,0.095,0.124
				c0.023,0.051,0.035,0.111,0.035,0.182v0.041h-0.285c0-0.071-0.014-0.124-0.039-0.162c-0.023-0.038-0.065-0.057-0.123-0.057
				c-0.033,0-0.062,0.005-0.084,0.014c-0.022,0.01-0.039,0.023-0.053,0.038c-0.013,0.016-0.021,0.035-0.027,0.055
				c-0.004,0.021-0.006,0.042-0.006,0.065c0,0.045,0.01,0.083,0.028,0.115c0.019,0.031,0.062,0.059,0.124,0.086l0.23,0.099
				c0.057,0.025,0.103,0.051,0.139,0.078s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
				c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
				c-0.168,0-0.289-0.036-0.361-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.869z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M60.325-24.45c0-0.064,0.007-0.126,0.021-0.183
				c0.012-0.056,0.032-0.11,0.06-0.161c0.026-0.052,0.06-0.101,0.101-0.151c0.041-0.049,0.088-0.098,0.143-0.148l0.121-0.112
				c0.033-0.029,0.06-0.055,0.078-0.08c0.021-0.025,0.037-0.05,0.047-0.075c0.012-0.025,0.02-0.052,0.022-0.081
				s0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.038,0-0.067,0.008-0.088,0.022
				c-0.022,0.015-0.039,0.035-0.051,0.063c-0.012,0.026-0.02,0.056-0.022,0.091s-0.005,0.072-0.005,0.11h-0.286v-0.056
				c0-0.136,0.036-0.241,0.11-0.315c0.072-0.073,0.188-0.109,0.346-0.109c0.148,0,0.26,0.034,0.332,0.103
				c0.07,0.069,0.107,0.168,0.107,0.296c0,0.047-0.005,0.091-0.013,0.132c-0.009,0.04-0.021,0.08-0.039,0.117
				c-0.019,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.063,0.072-0.105,0.109l-0.165,0.153c-0.044,0.04-0.079,0.079-0.104,0.117
				c-0.024,0.039-0.041,0.071-0.048,0.098h0.554v0.233H60.325z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M62.231-25.66c-0.061,0.086-0.114,0.177-0.161,0.275
				c-0.049,0.099-0.091,0.2-0.127,0.303c-0.035,0.103-0.066,0.21-0.089,0.316c-0.024,0.107-0.042,0.212-0.054,0.316h-0.313
				c0.01-0.088,0.026-0.181,0.049-0.279c0.022-0.097,0.053-0.196,0.088-0.297c0.037-0.101,0.082-0.202,0.134-0.305
				c0.053-0.103,0.114-0.203,0.187-0.3h-0.617v-0.257h0.903V-25.66z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M62.654-25.486h-0.291v-0.195h0.025c0.1,0,0.178-0.018,0.234-0.056
				c0.059-0.038,0.095-0.097,0.109-0.18h0.221v1.467h-0.299V-25.486z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          d="M63.362-25.103c0.042-0.067,0.104-0.109,0.187-0.125v-0.004
				c-0.039-0.01-0.072-0.025-0.1-0.046s-0.049-0.044-0.065-0.07c-0.017-0.027-0.028-0.054-0.035-0.083
				c-0.007-0.03-0.011-0.057-0.011-0.083c0-0.144,0.039-0.247,0.115-0.31s0.182-0.094,0.316-0.094c0.134,0,0.239,0.031,0.316,0.094
				c0.076,0.063,0.115,0.166,0.115,0.31c0,0.026-0.004,0.053-0.012,0.083c-0.008,0.029-0.02,0.056-0.037,0.083
				c-0.016,0.026-0.037,0.049-0.064,0.07s-0.061,0.036-0.1,0.046v0.004c0.084,0.016,0.146,0.058,0.188,0.125
				c0.041,0.066,0.062,0.145,0.062,0.238c0,0.043-0.005,0.09-0.015,0.141c-0.01,0.051-0.032,0.098-0.066,0.143
				c-0.031,0.044-0.08,0.08-0.141,0.109c-0.063,0.029-0.145,0.044-0.246,0.044s-0.184-0.015-0.246-0.044
				c-0.063-0.029-0.107-0.065-0.143-0.109c-0.032-0.045-0.053-0.092-0.064-0.143c-0.01-0.051-0.015-0.098-0.015-0.141
				C63.302-24.958,63.322-25.036,63.362-25.103 M63.607-24.771c0.004,0.032,0.014,0.059,0.026,0.082s0.03,0.041,0.053,0.054
				c0.022,0.013,0.05,0.019,0.083,0.019s0.061-0.006,0.083-0.019c0.022-0.013,0.04-0.031,0.054-0.054
				c0.012-0.023,0.021-0.05,0.026-0.082c0.004-0.03,0.007-0.063,0.007-0.098c0-0.035-0.003-0.068-0.007-0.099
				c-0.005-0.03-0.015-0.057-0.026-0.08c-0.014-0.023-0.031-0.04-0.054-0.053c-0.022-0.014-0.05-0.02-0.083-0.02
				s-0.061,0.006-0.083,0.02c-0.022,0.013-0.04,0.03-0.053,0.053s-0.022,0.05-0.026,0.08c-0.006,0.031-0.008,0.064-0.008,0.099
				C63.6-24.834,63.602-24.801,63.607-24.771 M63.664-25.366c0.025,0.033,0.062,0.05,0.105,0.05s0.079-0.017,0.105-0.05
				c0.026-0.033,0.039-0.085,0.039-0.155c0-0.135-0.047-0.202-0.145-0.202s-0.145,0.067-0.145,0.202
				C63.625-25.451,63.638-25.399,63.664-25.366"
        />
        <rect
          x="45.949"
          y="-26.43"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#FFFFFF"
          width="8.813"
          height="8.813"
        />

        <rect
          x="45.949"
          y="-26.429"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="none"
          stroke="#000000"
          strokeWidth="0.13"
          strokeMiterlimit="10"
          width="8.813"
          height="8.813"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          points="46.539,-25.93 46.703,-24.836 46.707,-24.836 46.881,-25.93 47.168,-25.93
				47.342,-24.836 47.346,-24.836 47.51,-25.93 47.795,-25.93 47.526,-24.45 47.192,-24.45 47.026,-25.524 47.022,-25.524
				46.856,-24.45 46.522,-24.45 46.254,-25.93 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          points="48.214,-25.93 48.214,-25.364 48.563,-25.364 48.563,-25.93 48.861,-25.93
				48.861,-24.45 48.563,-24.45 48.563,-25.107 48.214,-25.107 48.214,-24.45 47.916,-24.45 47.916,-25.93 			"
        />
        <rect
          x="49.082"
          y="-25.931"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          width="0.299"
          height="1.48"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          points="50.469,-25.686 50.146,-25.686 50.146,-24.45 49.847,-24.45 49.847,-25.686
				49.523,-25.686 49.523,-25.931 50.469,-25.931 			"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          points="51.424,-25.686 50.895,-25.686 50.895,-25.338 51.393,-25.338 51.393,-25.093
				50.895,-25.093 50.895,-24.695 51.445,-24.695 51.445,-24.45 50.598,-24.45 50.598,-25.931 51.424,-25.931 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M12.71,75.938c0.592,0,0.973,0.391,0.973,0.991
				c0,0.594-0.381,0.985-0.973,0.985c-0.588,0-0.968-0.392-0.968-0.985C11.742,76.328,12.122,75.938,12.71,75.938 M12.71,77.526
				c0.352,0,0.458-0.301,0.458-0.598c0-0.302-0.106-0.604-0.458-0.604c-0.348,0-0.453,0.302-0.453,0.604
				C12.257,77.226,12.362,77.526,12.71,77.526"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M14.668,75.988h0.378v0.345h-0.378v0.929c0,0.174,0.044,0.218,0.218,0.218
				c0.054,0,0.105-0.004,0.16-0.015v0.402c-0.087,0.015-0.2,0.019-0.301,0.019c-0.316,0-0.592-0.073-0.592-0.446v-1.106h-0.312
				v-0.345h0.312v-0.563h0.515V75.988z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M15.717,77.051c0.014,0.326,0.174,0.476,0.46,0.476
				c0.208,0,0.375-0.127,0.408-0.243h0.453c-0.145,0.443-0.453,0.631-0.878,0.631c-0.592,0-0.958-0.406-0.958-0.985
				c0-0.563,0.388-0.992,0.958-0.992c0.639,0,0.946,0.538,0.911,1.114H15.717z M16.555,76.725c-0.047-0.262-0.16-0.399-0.41-0.399
				c-0.327,0-0.421,0.255-0.428,0.399H16.555z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M17.773,77.254c0.004,0.225,0.192,0.316,0.396,0.316
				c0.149,0,0.337-0.059,0.337-0.24c0-0.156-0.214-0.21-0.584-0.29c-0.298-0.065-0.595-0.17-0.595-0.501
				c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.138,0.828,0.598H18.48c-0.014-0.199-0.166-0.254-0.351-0.254
				c-0.116,0-0.287,0.021-0.287,0.175c0,0.185,0.29,0.21,0.584,0.279c0.301,0.069,0.596,0.178,0.596,0.526
				c0,0.494-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.163-0.882-0.66H17.773z"
        />
        <rect
          x="8.917"
          y="80.467"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M12.021,79.029h0.889c0.798,0,1.244,0.398,1.244,1.234
				c0,0.867-0.38,1.357-1.244,1.357h-0.889V79.029z M12.365,81.33h0.574c0.235,0,0.871-0.064,0.871-1.02
				c0-0.617-0.228-0.99-0.864-0.99h-0.581V81.33z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M15.332,79.701c0.595,0,0.911,0.432,0.911,0.983
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.433-0.911-0.979C14.421,80.133,14.737,79.701,15.332,79.701 M15.332,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C14.748,81.138,15.009,81.392,15.332,81.392"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M17.613,79.744h0.291v0.297h0.006c0.131-0.232,0.341-0.341,0.61-0.341
				c0.494,0,0.646,0.283,0.646,0.687v1.233h-0.308V80.35c0-0.228-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.06
				h-0.309V79.744z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M20.439,79.701c0.595,0,0.911,0.432,0.911,0.983
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.433-0.911-0.979C19.528,80.133,19.844,79.701,20.439,79.701 M20.439,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
				C19.855,81.138,20.116,81.392,20.439,81.392"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M22.14,79.744h0.374v0.271H22.14v1.166c0,0.142,0.04,0.166,0.233,0.166
				h0.141v0.272h-0.236c-0.319,0-0.447-0.065-0.447-0.41v-1.194h-0.318v-0.271h0.318v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M25.525,81.613c-0.055,0.032-0.123,0.051-0.222,0.051
				c-0.16,0-0.261-0.088-0.261-0.291c-0.171,0.2-0.399,0.291-0.661,0.291c-0.34,0-0.62-0.152-0.62-0.526
				c0-0.425,0.316-0.515,0.635-0.577c0.341-0.064,0.631-0.043,0.631-0.275c0-0.269-0.221-0.313-0.417-0.313
				c-0.261,0-0.453,0.081-0.468,0.355h-0.309c0.018-0.465,0.378-0.628,0.795-0.628c0.338,0,0.704,0.077,0.704,0.515v0.967
				c0,0.145,0,0.21,0.098,0.21c0.026,0,0.055-0.003,0.095-0.019V81.613z M25.024,80.651c-0.12,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.036-0.378,0.109-0.378,0.337c0,0.204,0.175,0.276,0.363,0.276c0.407,0,0.574-0.254,0.574-0.425V80.651z"
        />
        <rect
          x="25.828"
          y="79.029"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          width="0.308"
          height="2.592"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M27.043,79.744h0.374v0.271h-0.374v1.166c0,0.142,0.04,0.166,0.232,0.166
				h0.142v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319v-0.271h0.319v-0.563h0.309V79.744z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M29.358,81.025c-0.084,0.413-0.381,0.639-0.803,0.639
				c-0.598,0-0.881-0.414-0.899-0.987c0-0.562,0.37-0.976,0.881-0.976c0.664,0,0.868,0.619,0.849,1.073h-1.404
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.366H29.358z M29.06,80.502
				c-0.015-0.293-0.236-0.529-0.541-0.529c-0.323,0-0.515,0.243-0.537,0.529H29.06z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M29.694,79.744h0.291v0.396h0.006c0.149-0.302,0.356-0.451,0.69-0.44v0.327
				c-0.497,0-0.678,0.283-0.678,0.758v0.835h-0.309V79.744z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M33.584,81.613c-0.056,0.032-0.123,0.051-0.223,0.051
				c-0.16,0-0.261-0.088-0.261-0.291c-0.171,0.2-0.399,0.291-0.661,0.291c-0.34,0-0.62-0.152-0.62-0.527
				c0-0.424,0.315-0.514,0.636-0.576c0.34-0.064,0.631-0.043,0.631-0.275c0-0.269-0.222-0.313-0.418-0.313
				c-0.261,0-0.453,0.08-0.468,0.355h-0.308c0.018-0.465,0.378-0.628,0.794-0.628c0.338,0,0.705,0.077,0.705,0.515v0.967
				c0,0.145,0,0.21,0.097,0.21c0.025,0,0.055-0.003,0.096-0.019V81.613z M33.082,80.651c-0.119,0.087-0.352,0.091-0.559,0.127
				c-0.203,0.036-0.377,0.108-0.377,0.337c0,0.204,0.174,0.276,0.363,0.276c0.406,0,0.572-0.255,0.572-0.425V80.651z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M33.857,79.744h0.291v0.396h0.006c0.149-0.302,0.357-0.451,0.69-0.44v0.327
				c-0.497,0-0.679,0.283-0.679,0.758v0.835h-0.309V79.744z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M35.505,79.744h0.374v0.271h-0.374v1.166c0,0.142,0.039,0.166,0.232,0.166
				h0.142v0.272h-0.236c-0.319,0-0.446-0.065-0.446-0.41v-1.194h-0.319v-0.271h0.319v-0.563h0.309V79.744z"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          points="38.077,81.62 37.751,81.62 37.369,80.129 37.361,80.129
				36.984,81.62 36.65,81.62 36.048,79.744 36.389,79.744 36.814,81.279 36.822,81.279 37.199,79.744 37.536,79.744 37.928,81.279
				37.936,81.279 38.355,79.744 38.675,79.744 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M39.777,79.701c0.596,0,0.911,0.432,0.911,0.983
				c0,0.547-0.315,0.979-0.911,0.979c-0.595,0-0.91-0.433-0.91-0.979C38.867,80.133,39.183,79.701,39.777,79.701 M39.777,81.392
				c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.322,0-0.584,0.254-0.584,0.712
				C39.193,81.138,39.455,81.392,39.777,81.392"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M41.039,79.744h0.291v0.396h0.006c0.149-0.302,0.356-0.451,0.69-0.44v0.327
				c-0.497,0-0.679,0.283-0.679,0.758v0.835h-0.309V79.744z"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          points="42.276,79.029 42.586,79.029 42.586,80.564 43.441,79.744
				43.855,79.744 43.111,80.426 43.909,81.62 43.518,81.62 42.875,80.641 42.586,80.909 42.586,81.62 42.276,81.62 			"
        />
        <rect
          x="8.917"
          y="84.223"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          width="1.815"
          height="0.291"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M14.089,84.441c0,0.652-0.375,0.998-1.02,0.998
				c-0.668,0-1.063-0.31-1.063-0.998v-1.656h0.345v1.656c0,0.457,0.26,0.707,0.718,0.707c0.435,0,0.675-0.25,0.675-0.707v-1.656
				h0.345V84.441z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M14.777,84.785c0.011,0.276,0.25,0.363,0.505,0.363
				c0.192,0,0.453-0.044,0.453-0.28c0-0.239-0.305-0.278-0.613-0.348c-0.305-0.069-0.614-0.171-0.614-0.53
				c0-0.378,0.374-0.533,0.701-0.533c0.414,0,0.744,0.131,0.769,0.588H15.67c-0.022-0.24-0.233-0.315-0.436-0.315
				c-0.185,0-0.399,0.051-0.399,0.239c0,0.222,0.326,0.258,0.614,0.326c0.308,0.069,0.612,0.172,0.612,0.534
				c0,0.446-0.417,0.591-0.798,0.591c-0.421,0-0.777-0.17-0.794-0.635H14.777z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M18.002,84.782c-0.083,0.413-0.381,0.638-0.802,0.638
				c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.62,0.849,1.074h-1.405
				c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.366H18.002z M17.704,84.26
				c-0.014-0.295-0.236-0.531-0.54-0.531c-0.323,0-0.515,0.244-0.538,0.531H17.704z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M19.786,83.5h0.374v0.272h-0.374v1.165c0,0.143,0.039,0.167,0.232,0.167
				h0.142v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319V83.5h0.319v-0.563h0.309V83.5z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M20.827,83.164H20.52v-0.379h0.308V83.164z M20.518,83.5h0.308v1.877
				h-0.308V83.5z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M22.923,85.217c0,0.609-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.143-0.776-0.551h0.308c0.015,0.225,0.283,0.304,0.486,0.304c0.403,0,0.563-0.29,0.563-0.711v-0.123h-0.007
				c-0.102,0.228-0.349,0.341-0.585,0.341c-0.558,0-0.838-0.438-0.838-0.951c0-0.442,0.218-0.99,0.867-0.99
				c0.236,0,0.447,0.105,0.559,0.312h0.004v-0.268h0.29V85.217z M22.619,84.4c0-0.33-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.323-0.556,0.684c0,0.337,0.124,0.714,0.53,0.714C22.466,85.126,22.619,84.753,22.619,84.4"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M23.387,82.785h0.308v0.991h0.008c0.102-0.225,0.366-0.319,0.591-0.319
				c0.494,0,0.646,0.283,0.646,0.687v1.233h-0.308v-1.271c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.06
				h-0.308V82.785z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M25.832,83.5h0.374v0.272h-0.374v1.165c0,0.143,0.04,0.167,0.232,0.167
				h0.142v0.272H25.97c-0.32,0-0.447-0.065-0.447-0.41v-1.194h-0.319V83.5h0.319v-0.563h0.309V83.5z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M28.363,83.457c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.453,83.889,27.769,83.457,28.363,83.457 M28.363,85.148
				c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
				C27.779,84.895,28.041,85.148,28.363,85.148"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          points="30.481,85.377 30.151,85.377 29.456,83.501 29.8,83.501
				30.326,85.064 30.333,85.064 30.845,83.501 31.167,83.501 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M33.049,84.782c-0.082,0.413-0.379,0.638-0.801,0.638
				c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.883-0.977c0.663,0,0.867,0.62,0.85,1.074h-1.406
				c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.366H33.049z M32.752,84.26
				c-0.014-0.295-0.235-0.531-0.539-0.531c-0.323,0-0.517,0.244-0.539,0.531H32.752z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M33.387,83.5h0.289v0.396h0.008c0.148-0.301,0.355-0.449,0.689-0.439v0.326
				c-0.496,0-0.679,0.283-0.679,0.758v0.836h-0.308V83.5z"
        />
        <rect
          x="34.625"
          y="82.785"
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          width="0.307"
          height="2.592"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M36.22,83.457c0.595,0,0.911,0.432,0.911,0.984
				c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.432-0.911-0.979C35.309,83.889,35.625,83.457,36.22,83.457 M36.22,85.148
				c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713s-0.584,0.254-0.584,0.713
				C35.636,84.895,35.896,85.148,36.22,85.148"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M38.766,84.104c-0.047-0.236-0.21-0.375-0.464-0.375
				c-0.447,0-0.584,0.353-0.584,0.733c0,0.345,0.155,0.687,0.547,0.687c0.297,0,0.476-0.174,0.515-0.457h0.317
				c-0.069,0.457-0.356,0.729-0.828,0.729c-0.573,0-0.878-0.398-0.878-0.958c0-0.563,0.29-1.005,0.885-1.005
				c0.426,0,0.766,0.2,0.811,0.646H38.766z"
        />
        <polygon
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          points="39.458,82.785 39.768,82.785 39.768,84.32 40.623,83.5
				41.037,83.5 40.293,84.183 41.091,85.377 40.699,85.377 40.056,84.396 39.768,84.666 39.768,85.377 39.458,85.377 			"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M41.648,83.164H41.34v-0.379h0.309V83.164z M41.34,83.5h0.31v1.877h-0.31
				V83.5z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M42.127,83.5h0.291v0.298h0.006c0.131-0.232,0.342-0.341,0.61-0.341
				c0.493,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.271c0-0.228-0.146-0.378-0.381-0.378c-0.374,0-0.556,0.251-0.556,0.59v1.059
				h-0.309V83.5z"
        />
        <path
          clipPath="url(#CONFECTIONARY_SVGID_100_)"
          fill="#EC008C"
          d="M45.762,85.217c0,0.609-0.279,0.922-0.871,0.922
				c-0.352,0-0.758-0.143-0.775-0.551h0.308c0.015,0.225,0.283,0.304,0.486,0.304c0.403,0,0.563-0.29,0.563-0.711v-0.123h-0.008
				c-0.102,0.228-0.349,0.341-0.585,0.341c-0.558,0-0.838-0.438-0.838-0.951c0-0.442,0.218-0.99,0.867-0.99
				c0.235,0,0.446,0.105,0.559,0.312h0.005v-0.268h0.289V85.217z M45.457,84.4c0-0.33-0.145-0.672-0.533-0.672
				c-0.396,0-0.556,0.323-0.556,0.684c0,0.337,0.124,0.714,0.53,0.714S45.457,84.753,45.457,84.4"
        />
      </g>
    </g>
  );
};

function ExpressMyself(props: any) {
  const { badge, fallback = null, ...otherProps } = props;

  switch (badge) {
    case 'Vlogging':
      return <Vlogging {...otherProps} />;
    case 'Photography':
      return <Photography {...otherProps} />;
    case 'Media critic':
      return <MediaCritic {...otherProps} />;
    case 'Confectionery':
      return <Confectionery {...otherProps} />;
    default:
      return fallback;
  }
}

export default ExpressMyself;
