import {
  createContext,
  useReducer,
  PropsWithChildren,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';

import {
  signInWithEmailAndPassword as SignInToFirebase,
  signOut as signOutOfFirebase,
  onAuthStateChanged,
  onIdTokenChanged,
  User
} from 'firebase/auth';

import {
  AuthState,
  DispatchAction,
  FirebaseUser,
  SignUpTokenData
} from 'types';
import { Roles } from '../constants';
import { auth } from '../firebase';
import notImplemented from 'utilities/notImplemented';
import { getCollection } from 'utilities/firebase';

enum Actions {
  SET_LOADING,
  SET_USER,
  SET_ROLE,
  SET_ERROR
}

const baseApiUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:5001/go-for-guides/us-central1/api'
    : 'https://us-central1-go-for-guides.cloudfunctions.net/api';

const initialState: AuthState = {
  status: null,
  isFirstLoad: true,
  loading: true,
  error: null,
  token: null,
  user: null,
  role: null,
  isValid: false,
  showSignInAsModal: false,

  setShowSignInAsModal: notImplemented('setShowSignInAsModal'),
  signUp: notImplemented('signUp'),
  getSignUpTokenData: notImplemented('getSignUpTokenData'),
  signInWithEmailAndPassword: notImplemented('signInWithEmailAndPassword'),
  signInWithUsernameAndPassword: notImplemented(
    'signInWithUsernameAndPassword'
  ),
  signOut: notImplemented('signOut')
};

export const Context = createContext(initialState);

function reducer(state: AuthState, action: DispatchAction) {
  switch (action.type) {
    case Actions.SET_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case Actions.SET_USER: {
      const user = action.payload;
      const token: string | null = user?.accessToken || null;
      const isValid = token !== null;

      return {
        ...state,
        loading: false,
        isFirstLoad: false,
        error: null,
        user: user as FirebaseUser,
        token,
        isValid
      };
    }
    case Actions.SET_ROLE:
      return {
        ...state,
        role: action.payload
      };
    case Actions.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export function Provider(props: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSignInAsModal, setShowSignInAsModal] = useState(false);
  const { children } = props;
  const location = useLocation();

  const getSignUpTokenData = async (token: string) => {
    const data = await getCollection<SignUpTokenData>(`signUpTokens/${token}`);

    if (
      !data ||
      parseInt(data.expiresAt, 10) < Date.now().valueOf() ||
      data.usesRemaining < 1
    )
      return null;

    return data;
  };

  const signInAs =
    state.role !== null && [Roles.Admin, Roles.Leader].includes(state.role) /*||
    JSON.parse(sessionStorage.getItem('signInAs.originLink') || 'null') !== null */
      ? async (uid: string, redirectTo: string) => {
          const { user, token, role } = state;
          const url = `${baseApiUrl}/v1/admin/signInAs`;

          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          };

          const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify({ uid, url: redirectTo })
          });

          if (response.status !== 200)
            return dispatch({
              type: Actions.SET_ERROR,
              payload: response.statusText
            });

          const data = await response.json();
          if (data.error)
            return dispatch({
              type: Actions.SET_ERROR,
              payload: data.error
            });

          if (user !== null && [Roles.Admin, Roles.Leader].includes(role)) {
            sessionStorage.setItem('signInAs.originLink', data.returnLink);
            sessionStorage.setItem(
              'signInAs.originDisplayName',
              user.displayName
            );
            sessionStorage.setItem('signInAs.originEmail', `${user.email}`);
            // sessionStorage.setItem(
            //   'signInAs.origin',
            //   JSON.stringify({
            //     uid: user.uid,
            //     displayName: user.displayName,
            //     email: user.email,
            //     redirectTo: window.location.href
            //   })
            // );
          }

          window.sessionStorage.setItem('signInAs.email', data.email);
          window.location.href = data.signInLink;
        }
      : undefined;

  const signOut = async () =>
    await signOutOfFirebase(auth).catch((error) =>
      dispatch({ type: Actions.SET_ERROR, payload: error })
    );

  const signInWithEmailAndPassword = async (
    emailAddress: string,
    password: string,
    callback?: () => void
  ) => {
    dispatch({ type: Actions.SET_LOADING });
    return await SignInToFirebase(auth, emailAddress, password)
      .then(callback)
      .catch((error) => dispatch({ type: Actions.SET_ERROR, payload: error }));
  };

  const signInWithUsernameAndPassword = async (
    username: string,
    password: string,
    callback?: () => void
  ) => {
    const emailAddress = `${username}@go4guides.co.uk`;
    return await signInWithEmailAndPassword(emailAddress, password, callback);
  };

  const signUp = async (
    username: string,
    password: string,
    signUpTokenId: string
  ) => {
    dispatch({ type: Actions.SET_LOADING });
    const url = `${baseApiUrl}/v1/auth/signUp`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.token}`
    };

    const response = await fetch(url, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ username, password, signUpTokenId })
    });

    if (response.status !== 200)
      return dispatch({
        type: Actions.SET_ERROR,
        payload: response.statusText
      });

    const data = await response.json();
    if (data.error)
      return dispatch({
        type: Actions.SET_ERROR,
        payload: data.error
      });

    signInWithUsernameAndPassword(username, password);
  };

  useEffect(() => {
    const onUserUpdated = async (user: User | null) => {
      const claims = user ? (await user.getIdTokenResult()).claims : null;
      const role: Roles | null = (claims?.role as Roles) || null;
      const payload = user
        ? {
            ...user,
            unitId: (claims?.unitId as string) || null,
            patrolId: (claims?.patrolId as string) || null
          }
        : null;

      dispatch({ type: Actions.SET_USER, payload });
      dispatch({ type: Actions.SET_ROLE, payload: role });

      if (payload === null) sessionStorage.clear();
    };

    onAuthStateChanged(auth, onUserUpdated);
    onIdTokenChanged(auth, onUserUpdated);

    if (state.error) dispatch({ type: Actions.SET_ERROR, payload: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Context.Provider
      value={{
        ...state,
        showSignInAsModal,
        setShowSignInAsModal,
        signInAs,
        signOut,
        signInWithEmailAndPassword,
        signInWithUsernameAndPassword,
        getSignUpTokenData,
        signUp
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
