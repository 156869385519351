import React from 'react';
import { toKebabCase } from 'utilities/string';

function EmptyBadge(props: any) {
  const { guidesTheme } = props;
  const color: string = `var(--${toKebabCase(guidesTheme)})`;
  const tint: string = `var(--${toKebabCase(guidesTheme)}-20)`;

  return (
    <>
      <path
        fill={tint}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.499,61.07H6.173c-3.06,0-5.564-2.504-5.564-5.563V6.181
	c0-3.06,2.504-5.564,5.564-5.564h49.326c3.06,0,5.563,2.505,5.563,5.564v49.327C61.063,58.566,58.559,61.07,55.499,61.07"
      />
      <path
        fill={color}
        d="M56.01,4.406c0.689,0,1.271,0.582,1.271,1.271v50.333c0,0.688-0.581,1.27-1.271,1.27H5.677
	c-0.689,0-1.271-0.582-1.271-1.27V5.678c0-0.689,0.582-1.271,1.271-1.271H56.01 M56.01,0H5.677C2.555,0,0,2.556,0,5.678v50.333
	c0,3.121,2.555,5.676,5.677,5.676H56.01c3.123,0,5.678-2.555,5.678-5.676V5.678C61.688,2.556,59.133,0,56.01,0"
      />
    </>
  );
}

export default EmptyBadge;
