import { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import { GuidesTheme as IGuidesTheme } from 'types';
import { GuidesThemesAreas as GTA, TextAlign } from '../../constants';
import DatabaseContext from 'context/Database';
import notImplemented from 'utilities/notImplemented';
import { randomString, toKebabCase, toTitleCase } from 'utilities/string';
import useMediaQuery from 'hooks/useMediaQuery';

import BackIcon from 'components/icons/ui/Back';
import Button, { IconButton } from 'components/button';
import { Appbar, MenuButton, PageContent } from 'components/layout';
import { GuidesThemesToolbar } from 'components/layout/Toolbar';
import { AccordionItem } from 'components/accordion';
import { FixedGrid } from 'components/grid';
import InterestBadge from 'components/interest-badge';
import SkillsBuilder from 'components/skills-builder';
import ActivitiesModal, {
  ActivitiesModalProps
} from 'components/modal/ActivitiesModal';
import { PageNotFound } from 'pages/error';
import ThemeActivity, { UMAThemeActivity } from './ThemeActivity';
import './guides-theme.scss';

// const PageNotFound = lazy(() => import('pages/error/PageNotFound'));
const emptyActivitiesModalProps: ActivitiesModalProps = {
  modalType: null,
  show: false,
  title: '',
  guidesTheme: null,
  activityIndex: 0,
  activityProgress: [],
  onClose: notImplemented('onClose'),
  onUpdateProgress: notImplemented('updateAwardProgress')
  // onAddProgress: notImplemented('onAddProgress'),
  // onRemoveProgress: notImplemented('onRemoveProgress')
};

function onSwipedHOC(
  guidesThemes: IGuidesTheme[],
  currentThemeName: string,
  callback: (slug: string) => void
) {
  const currentIndex = guidesThemes.findIndex(
    (theme) => theme.name === currentThemeName
  );

  return function onSwiped({ dir: direction, event }: any) {
    if (currentIndex === -1) return;

    event.stopPropagation();
    let newIndex = currentIndex;

    if (direction === 'Left')
      newIndex =
        currentIndex < guidesThemes.length - 1
          ? currentIndex + 1
          : currentIndex;
    else if (direction === 'Right')
      newIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;

    const slug = toKebabCase(guidesThemes[newIndex].name);

    callback(`/guides-themes/${slug}`);
  };
}

function GuidesTheme() {
  const {
    loading,
    guidesThemes,
    awardTargets,
    awardProgress,
    updateAwardProgress
  } = useContext(DatabaseContext);

  const [guidesTheme, setGuidesTheme] = useState<IGuidesTheme | null>(null);
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);
  const [activitiesModalProps, setActivitiesModalProps] =
    useState<ActivitiesModalProps>(emptyActivitiesModalProps);

  const navigate = useNavigate();
  const onSwipe = onSwipedHOC(guidesThemes, guidesTheme?.name || '', navigate);

  const swippableProps = useSwipeable({
    onSwipedLeft: onSwipe,
    onSwipedRight: onSwipe
    // NOTE: another approach via onSwiping
    // onSwiping: ({ event }) => event.stopPropagation(),
    // preventDefaultTouchmoveEvent: true
  });

  const completedInterestBadges = awardProgress.filter(
    (item) =>
      item.program === GTA.InterestBadges &&
      (item.theme === guidesTheme?.name || false)
  );

  const completedSkillsBuilders = awardProgress.filter(
    (item) =>
      item.program === GTA.SkillsBuilders &&
      (item.theme === guidesTheme?.name || false)
  );

  const completedUMA = awardProgress.filter(
    (item) =>
      item.program === GTA.UnitMeetingActivities &&
      (item.theme === guidesTheme?.name || false)
  );

  const isMobile = useMediaQuery('(max-width: 575px)');
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const params = useParams(); //: { theme: string }
  const contentClasses = [
    'content--with-appbar',
    'content--with-toolbar',
    'gt-theme',
    `gt-theme--${params.theme}`
  ];

  const layoutGridColumns = isMobile ? 1 : isDesktop ? 3 : 2;
  const isAccordionActive = (index: number) =>
    activeAccordionIndex === index || !isMobile;

  const accordionClasses = (index: number) => {
    let className = '';
    if (isAccordionActive(index))
      className += ` bg-${params.theme} text-light mb-2`;

    return className;
  };

  const updateActivitiesModalState = useCallback(
    (show: boolean, modalType?: GTA, activeIndex?: number) => {
      let newProps = {
        ...emptyActivitiesModalProps,
        show,
        guidesTheme,
        modalType: modalType || null,
        activityIndex: activeIndex || 0
      };

      switch (modalType) {
        case GTA.InterestBadges:
          newProps = {
            ...newProps,
            title: 'Interest Badges',
            activityProgress: completedInterestBadges
          };
          break;
        case GTA.SkillsBuilders:
          newProps = {
            ...newProps,
            title: 'Skills Builders',
            activityProgress: completedSkillsBuilders
          };
          break;
        case GTA.UnitMeetingActivities:
          newProps = {
            ...newProps,
            title: 'Unit Meeting Activities',
            activityProgress: completedUMA
          };
          break;
        default:
          break;
      }

      setActivitiesModalProps(newProps);
    },
    [
      completedInterestBadges,
      completedUMA,
      completedSkillsBuilders,
      guidesTheme
    ]
  );

  const onAccordionClick = (index: number) => () =>
    isMobile ? setActiveAccordionIndex(index) : null;

  useEffect(() => {
    if (!loading || guidesThemes.length > 0) {
      const themeName = toTitleCase(params.theme || '', '-');
      const newGuidesTheme =
        guidesThemes.find(
          (theme: { name: string }) => theme.name === themeName
        ) || null;

      setGuidesTheme(newGuidesTheme);
    }
  }, [guidesThemes, loading, params]);

  useEffect(() => {
    const { show, modalType, activityIndex } = activitiesModalProps;
    if (show && modalType)
      updateActivitiesModalState(show, modalType, activityIndex);
  }, [awardProgress]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [guidesTheme]);

  if (!guidesThemes && !loading) return <PageNotFound />;

  return (
    <>
      <Helmet>
        <title>{guidesTheme?.name || ''} | Guides Themes</title>
      </Helmet>

      <ActivitiesModal
        {...activitiesModalProps}
        onClose={() =>
          setActivitiesModalProps({
            ...activitiesModalProps,
            show: false
          })
        }
        onClosed={() => setActivitiesModalProps(emptyActivitiesModalProps)}
        onUpdateProgress={updateAwardProgress}
      />

      <Appbar
        title={guidesTheme?.name || ''}
        titleAlign={TextAlign.CENTER}
        className={`bg-${params.theme}`}
        style={{ borderBottom: `0.5rem solid var(--${params.theme}-20)` }}
        leftButton={() => (
          <IconButton
            onClick={() => {
              navigate(
                `/?t=${
                  guidesThemes.findIndex(
                    (theme: { name: any }) => theme.name === guidesTheme?.name
                  ) || ''
                }`
              );
            }}
          >
            <BackIcon className="text-light" />
          </IconButton>
        )}
        rightButtons={[() => <MenuButton className="text-light" />]}
      />

      <PageContent className={contentClasses.join(' ')} {...swippableProps}>
        {guidesTheme && (
          <FixedGrid
            columns={layoutGridColumns}
            style={{ gridTemplateColumns: isDesktop ? '1fr 40% 1fr' : '' }}
          >
            <div className="mt-1">
              <AccordionItem
                title="Interest Badges"
                open={isAccordionActive(0)}
                className={accordionClasses(0)}
                onClick={onAccordionClick(0)}
              >
                <FixedGrid columns={2}>
                  {guidesTheme.interestBadges.map(
                    (interestBadge: { name: any }, i: number | undefined) => {
                      return (
                        <ThemeActivity
                          key={randomString()}
                          targetCount={awardTargets?.interestBadges[1] || 0}
                          progressCount={
                            completedInterestBadges.filter(
                              (item) => item.category === interestBadge.name
                            ).length
                          }
                          onButtonClick={() =>
                            updateActivitiesModalState(
                              true,
                              GTA.InterestBadges,
                              i
                            )
                          }
                        >
                          <InterestBadge
                            badge={interestBadge.name}
                            guidesTheme={guidesTheme.name}
                            style={{ width: '100%' }}
                          />
                        </ThemeActivity>
                      );
                    }
                  )}
                </FixedGrid>
              </AccordionItem>
            </div>

            <div className="mt-1">
              <AccordionItem
                title="Skills Builders"
                open={isAccordionActive(1)}
                className={accordionClasses(1)}
                onClick={onAccordionClick(1)}
              >
                <FixedGrid columns={isDesktop ? 3 : 2}>
                  {guidesTheme.skillsBuilders
                    .sort((sbA, sbB) => {
                      if (!isDesktop) return sbA.stage < sbB.stage ? -1 : 1;
                      if (sbA.name < sbB.name) return -1;
                      if (sbA.name > sbB.name) return 1;

                      return 0;
                    })
                    .map(
                      (
                        skillsBuilder: { name: any; stage: any },
                        i: number | undefined
                      ) => (
                        <ThemeActivity
                          key={randomString()}
                          targetCount={awardTargets?.skillsBuilders[1] || 0}
                          progressCount={
                            completedSkillsBuilders.filter(
                              (item) =>
                                item.category === skillsBuilder.name &&
                                item.stage === skillsBuilder.stage
                            ).length
                          }
                          onButtonClick={() =>
                            updateActivitiesModalState(
                              true,
                              GTA.SkillsBuilders,
                              i
                            )
                          }
                        >
                          <SkillsBuilder
                            guidesTheme={guidesTheme.name}
                            skill={skillsBuilder.name}
                            stage={skillsBuilder.stage}
                            style={{ width: '100%' }}
                          />
                        </ThemeActivity>
                      )
                    )}
                </FixedGrid>
              </AccordionItem>
            </div>

            <div
              className="mt-1 mb-2"
              style={{ gridColumn: !isMobile && !isDesktop ? '1/3' : '' }}
            >
              <AccordionItem
                title="Unit Meeting Activities"
                open={isAccordionActive(2)}
                className={accordionClasses(2)}
                onClick={onAccordionClick(2)}
              >
                <div
                  className="bg-light--glass d-grid px-2 py-1 mb-2 br-rounded"
                  style={{
                    gridTemplateColumns: '1fr min(40%)',
                    alignItems: 'center'
                  }}
                >
                  <div className="flex-1 pr-1">
                    {completedUMA.length === 0 && (
                      <span className="d-block">{'No items completed'}</span>
                    )}

                    {completedUMA.length > 0 ? (
                      <>
                        <span className="d-block">{`${completedUMA.length} items completed`}</span>
                        <small>
                          {`${completedUMA.reduce(
                            (acc, uma) => acc + (uma.duration || 0),
                            0
                          )} / ${
                            awardTargets?.unitMeetingActivities || 0
                          } minutes`}
                        </small>
                      </>
                    ) : null}
                  </div>

                  <div>
                    <Button
                      color="dark"
                      block
                      onClick={() =>
                        updateActivitiesModalState(
                          true,
                          GTA.UnitMeetingActivities
                        )
                      }
                    >
                      {completedUMA.length === 0 ? 'Add' : 'Edit'}
                    </Button>
                  </div>
                </div>

                <FixedGrid columns={isMobile || isDesktop ? 1 : 3}>
                  {completedUMA.map((uma) => (
                    <UMAThemeActivity
                      key={randomString()}
                      color={params.theme}
                      // className={`bg-${params.theme}-70`}
                    >
                      <div className="flex-1">
                        <span className="d-block">{uma.task}</span>
                        <small>{`${uma.duration || 0} minutes`}</small>
                      </div>
                    </UMAThemeActivity>
                  ))}
                </FixedGrid>
              </AccordionItem>
            </div>
          </FixedGrid>
        )}
      </PageContent>

      <GuidesThemesToolbar />
    </>
  );
}

export default GuidesTheme;
