export const ASYNC_TIMEOUT = 6000;
export const SPACING_UNIT = 'rem';
export const FONT_UNIT = 'em';

export const BORDER_RADIUS = 0.625;
export const BASE_SPACING = 0.5;
export const HEADING_SCALING = 0.125;

export enum Roles {
  Admin = 'admin',
  Leader = 'leader',
  Member = 'member'
}

export enum CRUD {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify'
}

export enum TextCase {
  UPPER = 'uppercase',
  LOWER = 'lowercase',
  CAPITAL = 'capitalize'
}

export enum GuidesThemesAreas {
  InterestBadges = 'Interest Badges',
  SkillsBuilders = 'Skills Builders',
  UnitMeetingActivities = 'Unit Meeting Activities'
}

export enum AppbarButtons {
  NONE = 'none',
  MENU = 'menu',
  BACK = 'back',
  CLOSE = 'close',
  NOTIFICATIONS = 'notifications',
  OPTIONS = 'options'
}

export enum ToolbarButtons {
  HOME = 'home',
  PATROL = 'patrol',
  PROFILE = 'profile'
}

export const COLOURS_TO_SKIP = [
  'inherit',
  'initial',
  'unset',
  'currentColor',
  'transparent'
];

// BREAKPOINTS
export const BREAKPOINT_KEYS = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
export const BREAKPOINT_VALUES = [0, 36, 48, 62, 75, 87.5]; // [0, 576, 768, 992, 1400];
export enum Breakpoints {
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL
}
