import Base, { AwardProps } from './Base';

function HaveAdventures(props: AwardProps) {
  return (
    <Base {...props}>
      <g id="Have_Adventures_Award">
        <g>
          <defs>
            <rect id="SVGID_5_" x="0" width="53.513" height="66.893" />
          </defs>
          <clipPath id="SVGID_6_">
            <use xlinkHref="#SVGID_5_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M37.332,38.713l12.924,12.924c1.74-3.506,2.725-7.455,2.725-11.635V5.744
			c0-2.803-2.273-5.075-5.076-5.075H5.611c-2.803,0-5.075,2.272-5.075,5.075v34.258c0,14.482,11.74,26.221,26.222,26.221
			c1.673,0,3.305-0.162,4.891-0.463L15.585,49.698L37.332,38.713z"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#D1D1CE"
            d="M50.256,51.637L37.332,38.713L15.585,49.698l16.063,16.064
			C39.83,64.217,46.666,58.877,50.256,51.637"
          />
          <rect
            x="2.453"
            y="2.361"
            clipPath="url(#SVGID_6_)"
            fill="#72BE44"
            width="48.465"
            height="19.122"
          />

          <line
            clipPath="url(#SVGID_6_)"
            fill="none"
            stroke="#9BCD78"
            strokeWidth="3.822"
            strokeMiterlimit="10"
            x1="3.093"
            y1="22.01"
            x2="50.643"
            y2="22.01"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M21.733,8.692c0.017,0.124,0.021,0.227,0.021,0.314
			c0,1.048-0.748,1.801-1.79,1.801c-1.047,0-1.862-0.789-1.862-1.807c0-1.011,0.811-1.805,1.847-1.805
			c0.769,0,1.444,0.362,1.687,1.037l-0.82,0.237c-0.201-0.393-0.531-0.495-0.866-0.495c-0.563,0-0.99,0.443-0.99,1.025
			c0,0.589,0.438,1.032,1.017,1.032c0.454,0,0.784-0.237,0.912-0.619h-1.057V8.692H21.733z"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M24.825,10.73h-0.826v-0.248c-0.195,0.207-0.469,0.325-0.794,0.325
			c-0.604,0-1.007-0.429-1.007-1.078V8.142h0.82v1.412c0,0.305,0.182,0.512,0.444,0.512c0.325,0,0.536-0.223,0.536-0.724v-1.2h0.826
			V10.73z"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M26.356,7.377c0,0.263-0.2,0.459-0.479,0.459
			c-0.279,0-0.475-0.196-0.475-0.459c0-0.253,0.195-0.469,0.475-0.469C26.156,6.908,26.356,7.124,26.356,7.377 M25.464,8.141h0.825
			v2.59h-0.825V8.141z"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M29.654,10.73h-0.826v-0.206c-0.201,0.18-0.469,0.283-0.804,0.283
			c-0.686,0-1.264-0.553-1.264-1.372c0-0.815,0.578-1.372,1.264-1.372c0.335,0,0.604,0.103,0.804,0.284V7.017h0.826V10.73z
			 M28.828,9.436c0-0.397-0.299-0.671-0.634-0.671c-0.361,0-0.624,0.273-0.624,0.671c0,0.402,0.263,0.671,0.624,0.671
			C28.529,10.106,28.828,9.838,28.828,9.436"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M32.791,9.647h-1.898c0.066,0.309,0.277,0.5,0.557,0.5
			c0.191,0,0.412-0.047,0.553-0.299l0.732,0.148c-0.223,0.547-0.691,0.811-1.285,0.811c-0.748,0-1.367-0.552-1.367-1.372
			c0-0.815,0.619-1.372,1.373-1.372c0.732,0,1.32,0.526,1.336,1.372V9.647z M30.908,9.142h1.068
			c-0.078-0.279-0.285-0.408-0.521-0.408C31.223,8.733,30.984,8.878,30.908,9.142"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M33.848,9.889c0.037,0.223,0.238,0.305,0.459,0.305
			c0.217,0,0.336-0.092,0.336-0.206c0-0.088-0.066-0.155-0.258-0.191l-0.527-0.103c-0.479-0.088-0.752-0.347-0.752-0.753
			c0-0.531,0.459-0.877,1.113-0.877c0.646,0,1.074,0.294,1.178,0.742l-0.754,0.149c-0.025-0.16-0.18-0.304-0.434-0.304
			c-0.223,0-0.293,0.107-0.293,0.2c0,0.072,0.029,0.146,0.195,0.182l0.609,0.124c0.49,0.103,0.717,0.396,0.717,0.779
			c0,0.571-0.496,0.87-1.176,0.87c-0.609,0-1.131-0.221-1.219-0.753L33.848,9.889z"
          />
          <polygon
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            points="8.402,16.381 7.556,16.381 7.556,15.014 6.101,15.014 6.101,16.381
			5.25,16.381 5.25,12.925 6.101,12.925 6.101,14.271 7.556,14.271 7.556,12.925 8.402,12.925 		"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M11.729,16.381h-0.824v-0.207c-0.201,0.182-0.471,0.283-0.806,0.283
			c-0.686,0-1.264-0.551-1.264-1.371c0-0.815,0.578-1.373,1.264-1.373c0.335,0,0.604,0.104,0.806,0.284v-0.206h0.824V16.381z
			 M10.905,15.086c0-0.397-0.3-0.671-0.636-0.671c-0.36,0-0.624,0.274-0.624,0.671c0,0.402,0.264,0.67,0.624,0.67
			C10.605,15.756,10.905,15.489,10.905,15.086"
          />
          <polygon
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            points="13.979,16.381 13.138,16.381 12.065,13.786 12.937,13.786
			13.561,15.385 14.18,13.786 15.052,13.786 		"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M17.833,15.297h-1.898c0.066,0.311,0.278,0.5,0.557,0.5
			c0.19,0,0.413-0.047,0.553-0.299l0.732,0.15c-0.223,0.547-0.691,0.809-1.285,0.809c-0.747,0-1.366-0.551-1.366-1.371
			c0-0.815,0.619-1.373,1.372-1.373c0.733,0,1.319,0.526,1.336,1.373V15.297z M15.951,14.792h1.067
			c-0.076-0.278-0.283-0.408-0.521-0.408C16.265,14.384,16.028,14.529,15.951,14.792"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M22.012,16.381l-0.243-0.631h-1.454l-0.242,0.631h-0.914l1.444-3.458h0.872
			l1.445,3.458H22.012z M21.042,13.885l-0.439,1.135h0.882L21.042,13.885z"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M25.891,16.381h-0.825v-0.207c-0.201,0.182-0.47,0.283-0.805,0.283
			c-0.685,0-1.264-0.551-1.264-1.371c0-0.815,0.579-1.373,1.264-1.373c0.335,0,0.604,0.104,0.805,0.284v-1.331h0.825V16.381z
			 M25.066,15.086c0-0.397-0.299-0.671-0.635-0.671c-0.361,0-0.624,0.274-0.624,0.671c0,0.402,0.263,0.67,0.624,0.67
			C24.767,15.756,25.066,15.489,25.066,15.086"
          />
          <polygon
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            points="28.141,16.381 27.3,16.381 26.227,13.786 27.099,13.786
			27.723,15.385 28.342,13.786 29.215,13.786 		"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M31.994,15.297h-1.896c0.066,0.311,0.277,0.5,0.557,0.5
			c0.191,0,0.412-0.047,0.553-0.299l0.732,0.15c-0.223,0.547-0.693,0.809-1.285,0.809c-0.748,0-1.367-0.551-1.367-1.371
			c0-0.815,0.619-1.373,1.373-1.373c0.732,0,1.32,0.526,1.334,1.373V15.297z M30.113,14.792h1.068
			c-0.078-0.278-0.285-0.408-0.521-0.408C30.426,14.384,30.189,14.529,30.113,14.792"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M35.049,14.792v1.589H34.23v-1.414c0-0.304-0.182-0.51-0.445-0.51
			c-0.324,0-0.535,0.223-0.535,0.723v1.201h-0.826v-2.59h0.826v0.248c0.195-0.207,0.469-0.325,0.793-0.325
			C34.648,13.714,35.049,14.143,35.049,14.792"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M37.531,16.268c-0.201,0.107-0.412,0.191-0.707,0.191
			c-0.645,0-1.031-0.352-1.031-1.033v-0.98h-0.469v-0.654h0.469v-0.764h0.826v0.764h0.764v0.654h-0.764v0.898
			c0,0.268,0.129,0.377,0.346,0.377c0.113,0,0.268-0.041,0.377-0.104L37.531,16.268z"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M40.385,16.381h-0.824v-0.248c-0.197,0.207-0.471,0.324-0.797,0.324
			c-0.602,0-1.006-0.428-1.006-1.078v-1.587h0.82v1.414c0,0.303,0.182,0.51,0.445,0.51c0.324,0,0.537-0.221,0.537-0.723v-1.201
			h0.824V16.381z"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M42.887,13.776l-0.035,0.825h-0.15c-0.576,0-0.891,0.299-0.891,0.969v0.811
			h-0.826v-2.59h0.826v0.496c0.186-0.31,0.467-0.532,0.891-0.532C42.773,13.755,42.824,13.76,42.887,13.776"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M45.762,15.297h-1.898c0.066,0.311,0.277,0.5,0.557,0.5
			c0.191,0,0.412-0.047,0.553-0.299l0.732,0.15c-0.223,0.547-0.691,0.809-1.285,0.809c-0.748,0-1.367-0.551-1.367-1.371
			c0-0.815,0.619-1.373,1.373-1.373c0.732,0,1.32,0.526,1.336,1.373V15.297z M43.879,14.792h1.066
			c-0.076-0.278-0.283-0.408-0.52-0.408C44.193,14.384,43.955,14.529,43.879,14.792"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M46.82,15.54c0.035,0.223,0.236,0.305,0.459,0.305
			c0.217,0,0.336-0.092,0.336-0.207c0-0.088-0.068-0.154-0.26-0.189l-0.525-0.104c-0.48-0.088-0.754-0.346-0.754-0.753
			c0-0.531,0.459-0.877,1.113-0.877c0.646,0,1.074,0.294,1.178,0.743l-0.752,0.149c-0.027-0.16-0.182-0.305-0.436-0.305
			c-0.221,0-0.293,0.108-0.293,0.201c0,0.072,0.031,0.146,0.195,0.181l0.609,0.124c0.488,0.103,0.717,0.398,0.717,0.779
			c0,0.572-0.494,0.871-1.176,0.871c-0.609,0-1.131-0.221-1.219-0.752L46.82,15.54z"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#9BCD78"
            d="M48.336,3.822c0.748,0,1.357,0.608,1.357,1.356v34.957
			c0,12.646-10.289,22.936-22.936,22.936c-12.646,0-22.934-10.289-22.934-22.936V5.179c0-0.748,0.607-1.356,1.355-1.356H48.336
			 M48.336,0H5.179C2.32,0,0,2.317,0,5.179v34.957c0,14.777,11.98,26.758,26.757,26.758c14.778,0,26.756-11.98,26.756-26.758V5.179
			C53.514,2.317,51.195,0,48.336,0"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#492519"
            d="M38.412,42.409c0,2.26-0.916,4.09-2.045,4.09s-2.045-1.83-2.045-4.09
			c0-2.258,0.916-4.09,2.045-4.09S38.412,40.151,38.412,42.409"
          />
          <rect
            x="18.368"
            y="38.319"
            clipPath="url(#SVGID_6_)"
            fill="#492519"
            width="18.001"
            height="8.18"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#AB6E26"
            d="M18.367,46.098c-0.777,0-1.645-1.516-1.645-3.689s0.867-3.689,1.645-3.689
			s1.644,1.516,1.644,3.689S19.144,46.098,18.367,46.098"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#492519"
            d="M18.367,39.122c0.438,0,1.243,1.248,1.243,3.287
			c0,2.041-0.805,3.289-1.243,3.289s-1.243-1.248-1.243-3.289C17.124,40.37,17.928,39.122,18.367,39.122 M18.367,38.319
			c-1.129,0-2.045,1.83-2.045,4.09s0.916,4.09,2.045,4.09c1.13,0,2.045-1.83,2.045-4.09S19.497,38.319,18.367,38.319"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#A36C35"
            d="M18.223,45.831c0,2.102-0.851,3.805-1.9,3.805
			c-1.052,0-1.903-1.703-1.903-3.805c0-2.1,0.852-3.805,1.903-3.805C17.373,42.026,18.223,43.731,18.223,45.831"
          />
          <polygon
            clipPath="url(#SVGID_6_)"
            fill="#492519"
            points="33.738,39.11 35.791,39.11 36.361,36.942 35.229,36.495 		"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#AB6E26"
            d="M35.215,36.52c0.076-0.191,0.395-0.248,0.707-0.121
			c0.314,0.125,0.506,0.383,0.43,0.576c-0.078,0.193-0.395,0.248-0.709,0.123C35.33,36.973,35.139,36.713,35.215,36.52"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#F05C2A"
            d="M33.197,37.028c-0.01-0.217-0.029-0.432-0.059-0.641c0.002,0,0.004,0,0.006,0
			c-0.256-2.182-1.311-4.521-2.807-6.066c-0.002,0.512-0.072,1.006-0.209,1.479c-0.15,0.533-0.381,1.035-0.676,1.496
			c0.074-0.441,0.115-0.9,0.115-1.369c0-0.143-0.004-0.285-0.012-0.426c-0.039-0.789-0.195-1.523-0.445-2.189
			c-0.039-0.109-0.084-0.217-0.129-0.322c-0.301-0.693-0.708-1.309-1.203-1.828c-0.3-0.318-0.63-0.602-0.987-0.848
			c-0.011,0.012-0.021,0.023-0.03,0.035c0.082,0.354,0.132,0.727,0.145,1.113c0.004,0.076,0.005,0.154,0.005,0.232
			c0,0.301-0.02,0.596-0.062,0.883c-0.027,0.199-0.064,0.395-0.11,0.586c-0.147,0.631-0.393,1.225-0.715,1.768
			c-0.137,0.23-0.287,0.453-0.451,0.662c-0.047-0.191-0.085-0.387-0.114-0.586c-0.044-0.297-0.064-0.602-0.064-0.912
			c0-0.184,0.007-0.365,0.023-0.543c0.023-0.273,0.064-0.539,0.123-0.797c0.05-0.213,0.109-0.422,0.182-0.623
			c-0.401,0.291-0.77,0.629-1.096,1.014c-0.355,0.416-0.664,0.885-0.914,1.4c-0.195,0.4-0.355,0.83-0.478,1.285
			c-0.147,0.551-0.239,1.141-0.265,1.762c-0.008,0.127-0.01,0.254-0.01,0.381c0,0.783,0.101,1.535,0.286,2.244
			c0.105,0.4,0.237,0.785,0.394,1.154c-0.384-0.369-0.708-0.779-0.958-1.225c-0.39-0.682-0.605-1.441-0.605-2.246v-0.006
			c0.001-0.49,0.082-0.955,0.234-1.387c-0.5,0.443-0.928,0.971-1.265,1.568c-0.229,0.406-0.414,0.846-0.552,1.314
			c-0.151,0.512-0.245,1.061-0.271,1.637c-0.006,0.123-0.009,0.246-0.009,0.367c0,0.391,0.029,0.771,0.085,1.143
			c0.503,3.346,3.182,5.902,6.409,5.902c2.466,0,4.609-1.488,5.71-3.68c0.467-0.934,0.746-1.992,0.781-3.117
			c0.004-0.082,0.004-0.166,0.004-0.248C33.205,37.274,33.203,37.151,33.197,37.028"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#F89E2A"
            d="M28.011,37.557c-0.037,0.168-0.236,0.59-0.306,0.744
			c-0.301-1.047-1.113-1.959-1.716-2.125c-0.051,1.145-1.229,1.447-2.04,3.174c-0.182,0.389-0.28,0.824-0.28,1.281
			c0,0.168,0.013,0.332,0.039,0.494v0.004c0.102,0.607,0.381,1.156,0.788,1.586c0.551,0.588,1.34,0.957,2.213,0.957
			c1.682,0,3.042-1.361,3.042-3.041C29.752,38.872,28.571,37.788,28.011,37.557"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#623B23"
            d="M36.367,45.831c0,2.26-0.914,4.09-2.045,4.09c-1.129,0-2.045-1.83-2.045-4.09
			c0-2.258,0.916-4.09,2.045-4.09C35.453,41.741,36.367,43.573,36.367,45.831"
          />
          <rect
            x="16.322"
            y="41.741"
            clipPath="url(#SVGID_6_)"
            fill="#623B23"
            width="18.002"
            height="8.18"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#AB6E26"
            d="M16.322,49.52c-0.778,0-1.644-1.516-1.644-3.689s0.865-3.689,1.644-3.689
			c0.777,0,1.644,1.516,1.644,3.689S17.099,49.52,16.322,49.52"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#623B23"
            d="M16.322,42.542c0.438,0,1.243,1.25,1.243,3.289
			c0,2.041-0.805,3.289-1.243,3.289s-1.244-1.248-1.244-3.289C15.078,43.792,15.883,42.542,16.322,42.542 M16.322,41.741
			c-1.13,0-2.045,1.832-2.045,4.09c0,2.26,0.915,4.09,2.045,4.09s2.045-1.83,2.045-4.09C18.367,43.573,17.452,41.741,16.322,41.741"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#623B23"
            d="M16.861,45.831c0,1-0.253,1.809-0.564,1.809s-0.565-0.809-0.565-1.809
			c0-0.998,0.254-1.809,0.565-1.809S16.861,44.833,16.861,45.831"
          />

          <line
            clipPath="url(#SVGID_6_)"
            fill="none"
            stroke="#AB6E26"
            strokeWidth="0.562"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="19.566"
            y1="43.684"
            x2="25.219"
            y2="43.684"
          />

          <line
            clipPath="url(#SVGID_6_)"
            fill="none"
            stroke="#AB6E26"
            strokeWidth="0.562"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="23.696"
            y1="45.627"
            x2="29.248"
            y2="45.627"
          />

          <line
            clipPath="url(#SVGID_6_)"
            fill="none"
            stroke="#AB6E26"
            strokeWidth="0.562"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="19.075"
            y1="47.336"
            x2="22.625"
            y2="47.336"
          />

          <line
            clipPath="url(#SVGID_6_)"
            fill="none"
            stroke="#AB6E26"
            strokeWidth="0.562"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="23.908"
            y1="48.858"
            x2="28.028"
            y2="48.858"
          />

          <line
            clipPath="url(#SVGID_6_)"
            fill="none"
            stroke="#AB6E26"
            strokeWidth="0.562"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="30.611"
            y1="47.336"
            x2="33.27"
            y2="47.336"
          />

          <line
            clipPath="url(#SVGID_6_)"
            fill="none"
            stroke="#AB6E26"
            strokeWidth="0.562"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="30.611"
            y1="45.627"
            x2="32.135"
            y2="45.627"
          />
          <polyline
            clipPath="url(#SVGID_6_)"
            fill="#623B23"
            points="32.076,42.682 30.268,42.682 29.717,40.653 30.732,40.241 		"
          />
          <polygon
            clipPath="url(#SVGID_6_)"
            fill="#623B23"
            points="19.877,49.11 17.823,49.11 17.254,51.278 18.385,51.725 		"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#AB6E26"
            d="M30.736,40.249c0.064,0.15-0.111,0.367-0.393,0.484
			c-0.279,0.119-0.559,0.094-0.621-0.055c-0.063-0.15,0.113-0.367,0.393-0.486C30.395,40.075,30.674,40.098,30.736,40.249"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#AB6E26"
            d="M18.4,51.698c-0.077,0.193-0.394,0.25-0.708,0.123
			c-0.314-0.125-0.506-0.383-0.428-0.576c0.076-0.193,0.394-0.248,0.708-0.123C18.285,51.247,18.477,51.504,18.4,51.698"
          />

          <line
            clipPath="url(#SVGID_6_)"
            fill="none"
            stroke="#AB6E26"
            strokeWidth="0.562"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="28.388"
            y1="42.954"
            x2="31.047"
            y2="42.954"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#F05C2A"
            d="M21.292,31.797c0,0.244-0.198,0.443-0.441,0.443
			c-0.244,0-0.442-0.199-0.442-0.443s0.198-0.441,0.442-0.441C21.093,31.356,21.292,31.553,21.292,31.797"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#F05C2A"
            d="M24.11,28.069c0,0.244-0.197,0.441-0.44,0.441
			c-0.244,0-0.443-0.197-0.443-0.441s0.199-0.441,0.443-0.441C23.913,27.627,24.11,27.825,24.11,28.069"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#F05C2A"
            d="M34.434,34.36c0,0.244-0.197,0.441-0.441,0.441s-0.441-0.197-0.441-0.441
			c0-0.242,0.197-0.441,0.441-0.441S34.434,34.118,34.434,34.36"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#F05C2A"
            d="M29.906,26.186c0,0.244-0.195,0.441-0.439,0.441s-0.443-0.197-0.443-0.441
			s0.199-0.441,0.443-0.441S29.906,25.942,29.906,26.186"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#4F86C6"
            d="M26.757,61.278c-2.288,0-4.141-1.854-4.141-4.139
			c0-2.289,1.853-4.143,4.141-4.143c2.288,0,4.141,1.854,4.141,4.143C30.898,59.424,29.045,61.278,26.757,61.278"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M26.757,52.997c2.288,0,4.141,1.854,4.141,4.141s-1.854,4.141-4.141,4.141
			c-2.288,0-4.141-1.854-4.141-4.141S24.469,52.997,26.757,52.997 M26.757,52.639c-2.479,0-4.498,2.018-4.498,4.498
			s2.019,4.498,4.498,4.498c2.481,0,4.499-2.018,4.499-4.498S29.238,52.639,26.757,52.639"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M28.92,55.45c-0.207,0-0.402,0.094-0.538,0.219l-0.011-0.012
			c0.063-0.104,0.146-0.301,0.146-0.621c0-0.766-0.768-1.346-1.761-1.346s-1.77,0.58-1.77,1.346c0,0.32,0.103,0.518,0.155,0.621
			l-0.012,0.012c-0.134-0.125-0.331-0.219-0.537-0.219c-0.633,0-1.264,0.609-1.264,1.75c0,1.117,0.621,1.75,1.294,1.75
			c0.621,0,1.058-0.344,1.449-0.736c-0.063-0.092-0.155-0.188-0.258-0.188c-0.104,0-0.218,0.074-0.414,0.23
			c-0.259,0.197-0.487,0.311-0.788,0.311c-0.527,0-0.754-0.592-0.754-1.367c0-0.828,0.217-1.377,0.775-1.377
			c0.248,0,0.413,0.072,0.58,0.229c0.248,0.227,0.33,0.289,0.444,0.289c0.084,0,0.135-0.053,0.156-0.082
			c-0.188-0.188-0.456-0.58-0.456-1.088c0-0.58,0.486-0.984,1.397-0.984c0.91,0,1.398,0.404,1.398,0.984
			c0,0.508-0.271,0.9-0.456,1.088c0.02,0.029,0.072,0.082,0.155,0.082c0.113,0,0.196-0.063,0.444-0.289
			c0.155-0.156,0.332-0.229,0.58-0.229c0.561,0,0.777,0.549,0.777,1.377c0,0.775-0.229,1.367-0.756,1.367
			c-0.299,0-0.538-0.113-0.787-0.311c-0.197-0.156-0.311-0.23-0.414-0.23s-0.196,0.096-0.259,0.188
			c0.383,0.393,0.828,0.736,1.448,0.736c0.674,0,1.295-0.633,1.295-1.75C30.184,56.059,29.553,55.45,28.92,55.45"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M27.451,55.43h-0.466c-0.031,0-0.051-0.023-0.062-0.043l-0.146-0.443
			c-0.01-0.031-0.021-0.031-0.031,0l-0.145,0.443c-0.01,0.033-0.031,0.043-0.052,0.043h-0.466c-0.021,0-0.03,0.008-0.011,0.02
			l0.384,0.279c0.021,0.021,0.021,0.053,0.01,0.072l-0.146,0.434c-0.009,0.023-0.009,0.043,0.021,0.023l0.383-0.27
			c0.031-0.021,0.052-0.021,0.083,0l0.373,0.27c0.02,0.02,0.031,0.01,0.02-0.012l-0.144-0.445c-0.011-0.029,0-0.063,0.021-0.072
			l0.382-0.279C27.482,55.438,27.471,55.43,27.451,55.43"
          />
          <path
            clipPath="url(#SVGID_6_)"
            fill="#FFFFFF"
            d="M27.099,58.493c-0.196-0.496-0.353-0.838-0.353-1.252
			c0-0.176,0.053-0.332,0.084-0.414c0.041-0.082,0.052-0.135,0.01-0.135c-0.063,0-0.196,0.145-0.279,0.27
			c-0.063,0.104-0.135,0.301-0.135,0.455c0,0.289,0.093,0.602,0.154,0.787c0.115,0.32,0.219,0.693,0.219,1.047
			c0,0.559-0.249,0.941-0.693,1.281c0.04,0.033,0.145,0.084,0.27,0.084c0.434,0,0.951-0.486,0.951-1.148
			C27.327,59.073,27.213,58.803,27.099,58.493"
          />
        </g>
      </g>
    </Base>
  );
}

export default HaveAdventures;
