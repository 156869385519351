import React from 'react';

import './grid.scss';

function FixedGrid(props: any) {
  const { children, className, columns, flex, ...otherProps } = props;

  const classes = ['grid'];
  if (flex) classes.push('grid--flex');
  else classes.push(`grid-columns-${columns}`);
  if (className) classes.push(className);

  return (
    <div {...otherProps} className={classes.join(' ')}>
      {children}
    </div>
  );
}

export default FixedGrid;
