import React from 'react';
import { toKebabCase } from 'utilities/string';

function EmptySkill(props: any) {
  const { guidesTheme } = props;
  const color: string = `var(--${toKebabCase(guidesTheme)})`;
  const tint: string = `var(--${toKebabCase(guidesTheme)}-20)`;

  return (
    <>
      <polygon
        fill={tint}
        points="57.506,19.711 57.506,50.54 30.797,65.957 4.087,50.54 4.087,19.711 30.797,4.295 "
      />

      <path
        fill={color}
        d="M30.854,4.638l26.428,15.251v30.502L30.854,65.643L4.426,50.392V19.889L30.854,4.638 M30.854,0.067
	c-0.573,0-1.145,0.148-1.658,0.444L1.679,16.391c-1.026,0.593-1.658,1.686-1.658,2.87v31.76c0,1.185,0.631,2.276,1.658,2.87
	l27.517,15.88c0.513,0.294,1.084,0.442,1.658,0.442c0.573,0,1.144-0.148,1.658-0.442l27.518-15.88
	c1.027-0.594,1.657-1.686,1.657-2.87v-31.76c0-1.185-0.63-2.277-1.657-2.87L32.512,0.511C31.998,0.215,31.427,0.067,30.854,0.067"
      />
    </>
  );
}

export default EmptySkill;
