// import React from 'react';
import Base from './Base';

function KnowMyself(props: any) {
  const { invert, primaryColor, secondaryColor, ...otherProps } = props;
  const secondaryColour = secondaryColor || '#FFFFFF';
  const primaryColour = primaryColor || '#01AEEF';

  return (
    <Base {...otherProps}>
      <g>
        <defs>
          <path
            id="KNOWMYSELF_SVGID_11_"
            d="M2.031,34c0,17.592,14.26,31.852,31.853,31.852c17.591,0,31.851-14.26,31.851-31.852
 c0-17.591-14.26-31.852-31.851-31.852C16.292,2.148,2.031,16.409,2.031,34"
          />
        </defs>

        <clipPath id="KNOWMYSELF_SVGID_12_">
          <use xlinkHref="#KNOWMYSELF_SVGID_11_" overflow="visible" />
        </clipPath>
      </g>

      <g id="KnowMyself_Theme">
        <path
          clipPath="url(#KNOWMYSELF_SVGID_12_)"
          fill={invert ? primaryColour : secondaryColour}
          d="M64.461,34c0,16.888-13.689,30.578-30.577,30.578S3.305,50.889,3.305,34
     c0-16.889,13.691-30.579,30.579-30.579S64.461,17.112,64.461,34"
        />

        <path
          clipPath="url(#KNOWMYSELF_SVGID_12_)"
          fill={invert ? secondaryColour : primaryColour}
          d="M29.331,26.885c0-1.534-1.244-2.776-2.777-2.776
     c-1.534,0-2.778,1.242-2.778,2.776s1.244,2.777,2.778,2.777C28.087,29.663,29.331,28.419,29.331,26.885 M46.42,45.307
     c0.279-1.059-0.35-2.146-1.408-2.426c-1.061-0.281-2.146,0.348-2.428,1.406c-0.281,1.061,0.35,2.146,1.408,2.428
     S46.137,46.365,46.42,45.307 M53.602,43.75c0.078,0.674,0.082,1.361,0.004,2.055l1.398,0.256c0.094,0.408,0.078,0.947-0.07,1.508
     c-0.148,0.563-0.402,1.035-0.688,1.342l-1.34-0.471c-0.277,0.643-0.621,1.238-1.023,1.783l1.082,0.92
     c-0.121,0.402-0.404,0.859-0.814,1.271c-0.41,0.41-0.865,0.695-1.266,0.818l-0.928-1.078c-0.551,0.408-1.148,0.754-1.777,1.031
     l0.479,1.338c-0.307,0.287-0.779,0.543-1.34,0.695c-0.561,0.15-1.098,0.168-1.508,0.074l-0.262-1.395
     c-0.674,0.078-1.361,0.08-2.055,0.004l-0.256,1.396c-0.408,0.096-0.947,0.08-1.508-0.068s-1.037-0.402-1.342-0.688l0.471-1.342
     c-0.641-0.275-1.238-0.621-1.783-1.021l-0.92,1.08c-0.4-0.121-0.859-0.404-1.27-0.814c-0.412-0.408-0.697-0.865-0.82-1.266
     l1.078-0.926c-0.408-0.553-0.754-1.148-1.031-1.777l-1.338,0.477c-0.287-0.305-0.542-0.777-0.694-1.34
     c-0.15-0.559-0.169-1.098-0.074-1.506l1.395-0.262c-0.078-0.674-0.08-1.363-0.004-2.055L34,43.533
     c-0.096-0.406-0.079-0.945,0.069-1.508c0.148-0.561,0.402-1.035,0.688-1.342l1.341,0.471c0.275-0.641,0.621-1.236,1.021-1.783
     l-1.08-0.92c0.121-0.4,0.402-0.857,0.813-1.27s0.867-0.695,1.268-0.82l0.926,1.08c0.553-0.408,1.148-0.756,1.777-1.031
     l-0.477-1.34c0.305-0.287,0.779-0.541,1.34-0.692c0.561-0.152,1.098-0.17,1.506-0.076l0.262,1.393
     c0.674-0.076,1.363-0.08,2.057-0.002l0.256-1.397c0.408-0.097,0.945-0.08,1.508,0.069c0.559,0.148,1.035,0.402,1.342,0.687
     l-0.471,1.34c0.641,0.277,1.236,0.623,1.783,1.023l0.92-1.08c0.4,0.121,0.857,0.402,1.27,0.813s0.695,0.867,0.818,1.268
     l-1.078,0.926c0.41,0.551,0.756,1.148,1.031,1.775l1.34-0.477c0.287,0.307,0.541,0.779,0.693,1.34
     c0.15,0.563,0.17,1.098,0.076,1.508L53.602,43.75z M41.666,26.885c0,0.813-0.172,1.547-0.447,2.064l-1.984-0.155
     c-0.143,0.966-0.395,1.897-0.742,2.78l1.793,0.855c-0.02,0.586-0.236,1.307-0.645,2.012c-0.406,0.705-0.922,1.253-1.42,1.564
     l-1.641-1.127c-0.598,0.752-1.28,1.434-2.032,2.033l1.127,1.641c-0.311,0.498-0.862,1.014-1.565,1.42
     c-0.704,0.408-1.426,0.625-2.012,0.645l-0.856-1.793c-0.883,0.348-1.814,0.598-2.78,0.742l0.156,1.982
     c-0.518,0.277-1.252,0.449-2.064,0.449c-0.813,0-1.547-0.172-2.065-0.449l0.156-1.982c-0.966-0.145-1.897-0.395-2.78-0.742
     l-0.856,1.793c-0.586-0.02-1.308-0.236-2.012-0.645c-0.704-0.406-1.253-0.922-1.564-1.42l1.127-1.641
     c-0.751-0.6-1.434-1.281-2.034-2.033l-1.64,1.127c-0.498-0.311-1.014-0.859-1.421-1.564c-0.406-0.705-0.624-1.426-0.643-2.012
     l1.793-0.855c-0.347-0.883-0.599-1.814-0.743-2.78l-1.983,0.155c-0.276-0.518-0.448-1.251-0.448-2.064
     c0-0.813,0.172-1.546,0.448-2.063l1.983,0.154c0.144-0.966,0.396-1.896,0.743-2.778l-1.793-0.856
     c0.019-0.587,0.237-1.307,0.643-2.012c0.407-0.705,0.923-1.253,1.421-1.564l1.64,1.127c0.6-0.752,1.283-1.434,2.034-2.033
     l-1.127-1.642c0.311-0.497,0.86-1.013,1.564-1.419c0.704-0.407,1.426-0.624,2.012-0.645l0.856,1.794
     c0.883-0.349,1.814-0.6,2.78-0.743l-0.156-1.983c0.518-0.275,1.252-0.448,2.065-0.448c0.812,0,1.546,0.173,2.064,0.448
     l-0.156,1.983c0.966,0.144,1.897,0.395,2.78,0.743l0.856-1.794c0.586,0.021,1.308,0.237,2.012,0.645
     c0.703,0.406,1.254,0.922,1.565,1.419l-1.127,1.642c0.752,0.6,1.434,1.281,2.032,2.033l1.641-1.127
     c0.498,0.312,1.014,0.859,1.42,1.564c0.408,0.705,0.625,1.425,0.645,2.012l-1.793,0.856c0.348,0.883,0.6,1.813,0.742,2.778
     l1.984-0.154C41.494,25.339,41.666,26.073,41.666,26.885 M65.736,34c0-17.592-14.262-31.854-31.853-31.854
     C16.292,2.147,2.031,16.409,2.031,34c0,17.591,14.261,31.851,31.853,31.851C51.475,65.852,65.736,51.592,65.736,34"
        />
      </g>
    </Base>
  );
}

export default KnowMyself;
