// #startregion FirstAid Groups
const FirstAidStage5 = () => (
  <g id="Stage_5">
    <polygon
      fill="#DED3E8"
      points="57.55,19.706 57.55,50.547 30.84,65.969 4.13,50.547 4.13,19.706 30.84,4.285 			"
    />
    <polygon
      fill="#B293BF"
      points="57.549,50.547 30.841,65.969 30.841,35.128 			"
    />
    <polygon
      fill="#B293BF"
      points="57.549,19.708 57.549,50.547 30.841,35.127 			"
    />
    <polygon
      fill="#B293BF"
      points="30.841,4.281 57.549,19.701 30.841,35.121 			"
    />
    <polygon fill="#B293BF" points="30.838,65.969 4.13,50.549 30.838,35.129 			" />
    <polygon fill="#B293BF" points="30.838,35.131 4.13,50.551 4.13,19.71 			" />
    <g>
      <defs>
        <rect
          id="SVGID_11_"
          x="-0.004"
          y="0.022"
          width="61.688"
          height="70.205"
        />
      </defs>
      <clipPath id="SVGID_12_">
        <use xlinkHref="#SVGID_11_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_12_)"
        fill="#8A52A0"
        d="M30.84,4.598l26.437,15.263v30.528L30.84,65.652L4.402,50.389V19.861
					L30.84,4.598 M30.84,0.023c-0.573,0-1.145,0.148-1.658,0.444L1.654,16.36c-1.026,0.593-1.657,1.687-1.657,2.872v31.786
					c0,1.186,0.631,2.279,1.657,2.873l27.527,15.893c0.514,0.295,1.085,0.443,1.658,0.443s1.145-0.148,1.658-0.443l27.526-15.893
					c1.027-0.594,1.658-1.688,1.658-2.873V19.232c0-1.185-0.631-2.279-1.658-2.872L32.498,0.467
					C31.984,0.171,31.413,0.023,30.84,0.023"
      />
      <polygon
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        points="19.31,31.183 21.344,31.183 21.344,32.116 19.31,32.116
					19.31,33.67 18.241,33.67 18.241,29.331 21.596,29.331 21.596,30.27 19.31,30.27 				"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        d="M23.221,29.46c0,0.331-0.252,0.577-0.602,0.577
					c-0.349,0-0.595-0.246-0.595-0.577c0-0.316,0.246-0.589,0.595-0.589C22.969,28.871,23.221,29.144,23.221,29.46 M22.102,30.419
					h1.035v3.25h-1.035V30.419z"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        d="M26.266,30.4l-0.046,1.036h-0.188c-0.726,0-1.12,0.376-1.12,1.218v1.016
					h-1.037v-3.251h1.037v0.622c0.233-0.389,0.588-0.667,1.12-0.667C26.123,30.374,26.188,30.381,26.266,30.4"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        d="M27.426,32.614c0.045,0.279,0.297,0.382,0.575,0.382
					c0.272,0,0.421-0.116,0.421-0.259c0-0.11-0.084-0.194-0.322-0.24l-0.662-0.129c-0.603-0.11-0.945-0.434-0.945-0.945
					c0-0.667,0.577-1.101,1.399-1.101c0.81,0,1.347,0.369,1.477,0.933l-0.946,0.187c-0.032-0.2-0.227-0.381-0.544-0.381
					c-0.277,0-0.368,0.135-0.368,0.251c0,0.092,0.038,0.182,0.245,0.227l0.765,0.156c0.615,0.13,0.899,0.498,0.899,0.978
					c0,0.718-0.621,1.095-1.476,1.095c-0.766,0-1.418-0.279-1.528-0.947L27.426,32.614z"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        d="M32.314,33.527c-0.252,0.136-0.518,0.238-0.887,0.238
					c-0.809,0-1.295-0.439-1.295-1.293v-1.231h-0.59v-0.822h0.59V29.46h1.036v0.959h0.958v0.822h-0.958v1.127
					c0,0.337,0.162,0.472,0.434,0.472c0.142,0,0.336-0.051,0.472-0.129L32.314,33.527z"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        d="M37.502,33.669h-1.035V33.41c-0.253,0.227-0.591,0.355-1.011,0.355
					c-0.862,0-1.587-0.691-1.587-1.722c0-1.023,0.725-1.722,1.587-1.722c0.42,0,0.758,0.129,1.011,0.356v-0.259h1.035V33.669z
					 M36.467,32.044c0-0.498-0.377-0.841-0.797-0.841c-0.453,0-0.783,0.343-0.783,0.841c0,0.505,0.33,0.842,0.783,0.842
					C36.09,32.886,36.467,32.549,36.467,32.044"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        d="M39.362,29.46c0,0.331-0.253,0.577-0.604,0.577
					c-0.349,0-0.596-0.246-0.596-0.577c0-0.316,0.247-0.589,0.596-0.589C39.109,28.871,39.362,29.144,39.362,29.46 M38.241,30.419
					h1.036v3.25h-1.036V30.419z"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        d="M43.435,33.669h-1.036V33.41c-0.252,0.227-0.589,0.355-1.01,0.355
					c-0.861,0-1.586-0.691-1.586-1.722c0-1.023,0.725-1.722,1.586-1.722c0.421,0,0.758,0.129,1.01,0.356v-1.67h1.036V33.669z
					 M42.398,32.044c0-0.498-0.376-0.841-0.796-0.841c-0.454,0-0.784,0.343-0.784,0.841c0,0.505,0.33,0.842,0.784,0.842
					C42.022,32.886,42.398,32.549,42.398,32.044"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#8A52A0"
        d="M20.035,37.734l-1.469,0.299c-0.109-0.57-0.581-0.852-1.063-0.852
					c-0.498,0-0.815,0.271-0.815,0.615c0,0.246,0.108,0.427,0.489,0.509l1.262,0.28c1.051,0.236,1.669,0.808,1.669,1.77
					c0,1.297-1.152,1.967-2.504,1.967c-1.369,0-2.493-0.652-2.657-1.84l1.542-0.3c0.154,0.626,0.59,0.897,1.197,0.897
					c0.563,0,0.897-0.254,0.897-0.625c0-0.272-0.127-0.444-0.562-0.535l-1.271-0.272c-0.871-0.198-1.632-0.653-1.632-1.759
					c0-1.18,0.979-1.914,2.403-1.914C18.848,35.975,19.809,36.627,20.035,37.734"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#8A52A0"
        d="M24.106,41.986c-0.354,0.191-0.726,0.337-1.242,0.337
					c-1.134,0-1.813-0.617-1.813-1.813v-1.724h-0.825v-1.151h0.825v-1.342H22.5v1.342h1.344v1.151H22.5v1.578
					c0,0.471,0.229,0.661,0.608,0.661c0.2,0,0.472-0.071,0.663-0.182L24.106,41.986z"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#8A52A0"
        d="M29.176,42.188h-1.451v-0.363c-0.354,0.318-0.825,0.498-1.415,0.498
					c-1.206,0-2.223-0.97-2.223-2.412c0-1.433,1.017-2.41,2.223-2.41c0.59,0,1.061,0.18,1.415,0.498v-0.363h1.451V42.188z
					 M27.725,39.91c0-0.697-0.526-1.178-1.115-1.178c-0.635,0-1.098,0.48-1.098,1.178c0,0.707,0.463,1.18,1.098,1.18
					C27.198,41.09,27.725,40.617,27.725,39.91"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#8A52A0"
        d="M34.734,41.732c0,1.406-1.07,2.178-2.549,2.178
					c-1.043,0-1.841-0.363-2.285-1.098l1.205-0.652c0.346,0.408,0.6,0.562,1.07,0.562c0.663,0,1.135-0.39,1.135-1.106v-0.127
					c-0.326,0.281-0.78,0.437-1.369,0.437c-1.189,0-2.195-0.944-2.195-2.231c0-1.261,1.006-2.195,2.195-2.195
					c0.589,0,1.043,0.154,1.369,0.436v-0.3h1.424V41.732z M33.311,39.693c0-0.607-0.481-1.033-1.062-1.033
					c-0.634,0-1.088,0.426-1.088,1.033s0.454,1.043,1.088,1.043C32.829,40.736,33.311,40.301,33.311,39.693"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#8A52A0"
        d="M40.074,40.283h-3.337c0.118,0.543,0.49,0.879,0.979,0.879
					c0.336,0,0.725-0.082,0.97-0.526l1.288,0.263c-0.391,0.963-1.215,1.424-2.258,1.424c-1.315,0-2.403-0.97-2.403-2.412
					c0-1.433,1.088-2.412,2.412-2.412c1.288,0,2.321,0.926,2.349,2.412V40.283z M36.765,39.394h1.877
					c-0.136-0.489-0.499-0.717-0.916-0.717C37.317,38.677,36.9,38.931,36.765,39.394"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#8A52A0"
        d="M44.825,38.242c1.342,0,2.14,0.77,2.14,1.959
					c0,1.251-0.906,2.122-2.303,2.122c-1.251,0-2.313-0.718-2.349-2.087l1.415-0.28c0.008,0.789,0.462,1.097,0.961,1.097
					c0.516,0,0.87-0.334,0.87-0.834c0-0.453-0.345-0.752-0.87-0.752h-2.086l0.109-3.355h3.989v1.297h-2.684l-0.037,0.834H44.825z"
      />

      <rect
        x="28.668"
        y="17.629"
        transform="matrix(0.7071 0.7072 -0.7072 0.7071 23.0889 -16.0294)"
        clipPath="url(#SVGID_12_)"
        fill="#DED3E8"
        width="4.447"
        height="4.448"
      />

      <rect
        x="24.977"
        y="21.665"
        transform="matrix(0.7071 0.7072 -0.7072 0.7071 24.6175 -12.339)"
        clipPath="url(#SVGID_12_)"
        fill="#DED3E8"
        width="4.449"
        height="3.757"
      />

      <rect
        x="25.163"
        y="14.47"
        transform="matrix(0.7071 0.7072 -0.7072 0.7071 19.5837 -14.5777)"
        clipPath="url(#SVGID_12_)"
        fill="#DED3E8"
        width="4.447"
        height="3.757"
      />

      <rect
        x="32.012"
        y="20.973"
        transform="matrix(0.7071 0.7072 -0.7072 0.7071 26.4331 -17.4145)"
        clipPath="url(#SVGID_12_)"
        fill="#DED3E8"
        width="4.447"
        height="4.448"
      />

      <rect
        x="32.337"
        y="14.356"
        transform="matrix(0.7071 0.7072 -0.7072 0.7071 21.569 -19.6989)"
        clipPath="url(#SVGID_12_)"
        fill="#DED3E8"
        width="4.447"
        height="3.655"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        d="M34.861,19.402l2.328-2.328c0.554-0.555,0.554-1.454,0-2.008l-1.667-1.667
					c-0.555-0.555-1.454-0.555-2.009,0l-2.328,2.327L34.861,19.402z M34.623,18.197c-0.111,0.112-0.295,0.112-0.407,0
					c-0.112-0.113-0.112-0.296,0-0.408c0.112-0.113,0.296-0.113,0.407,0C34.736,17.901,34.736,18.083,34.623,18.197 M36.073,15.931
					c0.113-0.112,0.295-0.112,0.408,0c0.112,0.113,0.112,0.295,0,0.408c-0.113,0.112-0.295,0.112-0.408,0
					C35.962,16.226,35.962,16.044,36.073,15.931 M35.553,16.86c0.111,0.112,0.111,0.295,0,0.408c-0.113,0.112-0.295,0.112-0.408,0
					c-0.112-0.113-0.112-0.296,0-0.408C35.258,16.748,35.439,16.748,35.553,16.86 M35.104,15.483c0.112,0.113,0.112,0.296,0,0.407
					c-0.112,0.113-0.295,0.113-0.408,0c-0.111-0.111-0.111-0.294,0-0.407C34.81,15.371,34.992,15.371,35.104,15.483 M34.248,14.106
					c0.113-0.112,0.296-0.112,0.408,0c0.112,0.113,0.112,0.295,0,0.408c-0.112,0.113-0.295,0.113-0.408,0
					C34.137,14.401,34.137,14.219,34.248,14.106 M34.176,16.412c0.111,0.113,0.111,0.295,0,0.407c-0.112,0.113-0.295,0.113-0.407,0
					c-0.113-0.112-0.113-0.294,0-0.407C33.881,16.299,34.063,16.299,34.176,16.412 M33.319,15.036c0.113-0.113,0.295-0.113,0.408,0
					c0.113,0.112,0.113,0.294,0,0.407c-0.113,0.112-0.295,0.112-0.408,0C33.207,15.33,33.207,15.148,33.319,15.036 M32.392,15.964
					c0.112-0.112,0.295-0.112,0.407,0c0.112,0.113,0.112,0.295,0,0.407c-0.112,0.113-0.295,0.113-0.407,0
					C32.279,16.259,32.279,16.077,32.392,15.964"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        d="M26.822,20.089l-2.327,2.328c-0.554,0.554-0.554,1.453,0,2.008l1.667,1.667
					c0.555,0.555,1.454,0.555,2.009,0l2.327-2.327L26.822,20.089z M25.532,23.638c-0.113,0.112-0.295,0.112-0.407,0
					c-0.113-0.113-0.113-0.295,0-0.407c0.112-0.113,0.294-0.113,0.407,0C25.645,23.343,25.645,23.525,25.532,23.638 M26.054,22.709
					c-0.112-0.113-0.112-0.295,0-0.408c0.112-0.112,0.294-0.112,0.407,0c0.112,0.113,0.112,0.295,0,0.408
					C26.348,22.822,26.166,22.822,26.054,22.709 M26.501,24.085c-0.112-0.111-0.112-0.294,0-0.407c0.113-0.112,0.296-0.112,0.407,0
					c0.112,0.113,0.112,0.296,0,0.407C26.797,24.198,26.614,24.198,26.501,24.085 M27.357,25.463c-0.113,0.112-0.296,0.112-0.407,0
					c-0.113-0.113-0.113-0.296,0-0.408c0.111-0.112,0.294-0.112,0.407,0C27.469,25.167,27.469,25.35,27.357,25.463 M28.807,23.197
					c0.112-0.112,0.295-0.112,0.408,0c0.112,0.113,0.112,0.296,0,0.408c-0.113,0.113-0.296,0.113-0.408,0
					C28.694,23.493,28.694,23.31,28.807,23.197 M28.286,24.126c0.112,0.113,0.112,0.295,0,0.408c-0.113,0.112-0.296,0.112-0.408,0
					c-0.112-0.113-0.112-0.295,0-0.408C27.99,24.014,28.173,24.014,28.286,24.126 M27.837,22.75c0.113,0.112,0.113,0.295,0,0.407
					c-0.111,0.112-0.294,0.112-0.407,0s-0.113-0.295,0-0.407C27.543,22.637,27.726,22.637,27.837,22.75 M26.982,21.373
					c0.112-0.112,0.295-0.112,0.407,0c0.112,0.112,0.112,0.295,0,0.407c-0.112,0.113-0.295,0.113-0.407,0
					C26.87,21.668,26.87,21.485,26.982,21.373"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#663272"
        d="M37.189,22.417l-2.672-2.671l-3.676-3.677l-2.671-2.671
					c-0.277-0.277-0.642-0.416-1.005-0.416c-0.362,0-0.727,0.139-1.004,0.416l-1.668,1.667c-0.554,0.555-0.554,1.454,0,2.009
					l2.672,2.672l3.676,3.675l2.673,2.672c0.276,0.277,0.64,0.415,1.003,0.415c0.364,0,0.727-0.138,1.005-0.415l1.667-1.668
					C37.744,23.871,37.744,22.971,37.189,22.417 M28.975,16.132c0.112-0.113,0.295-0.113,0.407,0c0.112,0.112,0.112,0.294,0,0.407
					c-0.112,0.112-0.295,0.112-0.407,0C28.862,16.426,28.862,16.244,28.975,16.132 M28.047,15.203c0.111-0.113,0.294-0.113,0.406,0
					s0.112,0.294,0,0.407c-0.112,0.113-0.295,0.113-0.406,0C27.934,15.497,27.934,15.316,28.047,15.203 M28.006,16.579
					c0.112,0.113,0.112,0.296,0,0.408c-0.113,0.112-0.295,0.112-0.408,0c-0.112-0.112-0.112-0.295,0-0.408
					C27.711,16.467,27.893,16.467,28.006,16.579 M27.117,14.274c0.112-0.113,0.294-0.113,0.407,0c0.113,0.113,0.113,0.295,0,0.407
					c-0.113,0.113-0.295,0.113-0.407,0C27.005,14.569,27.005,14.387,27.117,14.274 M25.699,16.506c-0.111,0.113-0.295,0.113-0.406,0
					c-0.112-0.112-0.112-0.295,0-0.408c0.111-0.111,0.295-0.111,0.406,0C25.812,16.211,25.812,16.394,25.699,16.506 M26.629,17.435
					c-0.112,0.112-0.295,0.112-0.408,0c-0.112-0.113-0.112-0.295,0-0.408c0.113-0.112,0.296-0.112,0.408,0
					C26.74,17.14,26.74,17.322,26.629,17.435 M26.67,16.058c-0.113-0.112-0.113-0.295,0-0.407c0.111-0.113,0.295-0.113,0.406,0
					c0.113,0.112,0.113,0.295,0,0.407C26.965,16.17,26.781,16.17,26.67,16.058 M27.149,17.956c0.113-0.112,0.296-0.112,0.408,0
					s0.112,0.295,0,0.408c-0.112,0.112-0.295,0.112-0.408,0C27.037,18.251,27.037,18.068,27.149,17.956 M34.455,21.612
					c-0.112,0.113-0.294,0.113-0.406,0c-0.113-0.112-0.113-0.294,0-0.407c0.112-0.112,0.294-0.112,0.406,0
					C34.568,21.318,34.568,21.5,34.455,21.612 M28.683,20.282c-0.296-0.296-0.296-0.777,0-1.074l1.623-1.621
					c0.297-0.297,0.776-0.297,1.074,0L33,19.208c0.298,0.297,0.298,0.778,0,1.074l-1.62,1.622c-0.298,0.297-0.777,0.297-1.074,0
					L28.683,20.282z M32.632,23.438c-0.113,0.112-0.295,0.112-0.408,0c-0.112-0.113-0.112-0.295,0-0.408
					c0.113-0.112,0.295-0.112,0.408,0C32.743,23.143,32.743,23.325,32.632,23.438 M33.561,24.366c-0.113,0.113-0.296,0.113-0.408,0
					c-0.113-0.112-0.113-0.295,0-0.407c0.112-0.112,0.295-0.112,0.408,0C33.673,24.071,33.673,24.254,33.561,24.366 M33.601,22.989
					c-0.112-0.112-0.112-0.295,0-0.407c0.112-0.113,0.295-0.113,0.407,0c0.112,0.112,0.112,0.295,0,0.407
					C33.896,23.102,33.713,23.102,33.601,22.989 M34.489,25.295c-0.112,0.112-0.296,0.112-0.409,0c-0.111-0.113-0.111-0.295,0-0.407
					c0.113-0.113,0.297-0.113,0.409,0C34.602,25,34.602,25.182,34.489,25.295 M34.937,23.918c-0.112,0.113-0.295,0.113-0.407,0
					c-0.113-0.112-0.113-0.295,0-0.407c0.112-0.113,0.295-0.113,0.407,0C35.05,23.623,35.05,23.806,34.937,23.918 M35.385,22.542
					c-0.113,0.112-0.295,0.112-0.407,0c-0.113-0.113-0.113-0.295,0-0.408c0.112-0.113,0.294-0.113,0.407,0
					C35.498,22.247,35.498,22.429,35.385,22.542 M36.313,23.47c-0.113,0.113-0.295,0.113-0.407,0c-0.112-0.112-0.112-0.294,0-0.407
					c0.112-0.113,0.294-0.113,0.407,0C36.426,23.176,36.426,23.358,36.313,23.47"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#4F86C6"
        d="M30.842,59.539c-2.638,0-4.772-2.135-4.772-4.771s2.135-4.773,4.772-4.773
					c2.639,0,4.773,2.137,4.773,4.773S33.48,59.539,30.842,59.539"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#FFFFFF"
        d="M30.842,49.994c2.639,0,4.773,2.137,4.773,4.773s-2.135,4.771-4.773,4.771
					c-2.638,0-4.772-2.135-4.772-4.771S28.204,49.994,30.842,49.994 M30.842,49.582c-2.858,0-5.185,2.326-5.185,5.186
					c0,2.857,2.326,5.184,5.185,5.184c2.859,0,5.186-2.326,5.186-5.184C36.027,51.908,33.701,49.582,30.842,49.582"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#FFFFFF"
        d="M33.336,52.822c-0.239,0-0.465,0.107-0.62,0.252l-0.013-0.014
					c0.071-0.119,0.168-0.346,0.168-0.715c0-0.883-0.885-1.551-2.028-1.551c-1.146,0-2.042,0.668-2.042,1.551
					c0,0.369,0.12,0.596,0.18,0.715l-0.013,0.014c-0.154-0.145-0.381-0.252-0.619-0.252c-0.729,0-1.457,0.703-1.457,2.018
					c0,1.287,0.717,2.016,1.492,2.016c0.716,0,1.218-0.395,1.67-0.848c-0.071-0.107-0.179-0.215-0.298-0.215
					s-0.251,0.084-0.477,0.262c-0.299,0.229-0.562,0.359-0.907,0.359c-0.609,0-0.87-0.682-0.87-1.574
					c0-0.955,0.25-1.588,0.895-1.588c0.286,0,0.477,0.084,0.668,0.262c0.286,0.264,0.381,0.334,0.512,0.334
					c0.097,0,0.156-0.059,0.18-0.094c-0.214-0.215-0.525-0.668-0.525-1.254c0-0.668,0.562-1.135,1.612-1.135
					c1.049,0,1.61,0.467,1.61,1.135c0,0.586-0.311,1.039-0.525,1.254c0.023,0.035,0.084,0.094,0.18,0.094
					c0.131,0,0.227-0.07,0.513-0.334c0.179-0.178,0.382-0.262,0.668-0.262c0.644,0,0.895,0.633,0.895,1.588
					c0,0.893-0.263,1.574-0.871,1.574c-0.345,0-0.619-0.131-0.907-0.359c-0.226-0.178-0.356-0.262-0.477-0.262
					c-0.119,0-0.227,0.107-0.298,0.215c0.44,0.453,0.955,0.848,1.67,0.848c0.776,0,1.493-0.729,1.493-2.016
					C34.793,53.525,34.063,52.822,33.336,52.822"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#FFFFFF"
        d="M31.642,52.799h-0.537c-0.036,0-0.059-0.023-0.071-0.049l-0.168-0.512
					c-0.012-0.035-0.022-0.035-0.034,0l-0.168,0.512c-0.013,0.037-0.035,0.049-0.061,0.049h-0.537c-0.023,0-0.034,0.01-0.012,0.023
					l0.442,0.322c0.023,0.023,0.023,0.061,0.012,0.082l-0.167,0.502c-0.011,0.023-0.011,0.049,0.024,0.023l0.441-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.431,0.309c0.022,0.025,0.035,0.012,0.022-0.01l-0.166-0.516
					c-0.013-0.033,0-0.07,0.024-0.082l0.439-0.322C31.677,52.809,31.665,52.799,31.642,52.799"
      />
      <path
        clipPath="url(#SVGID_12_)"
        fill="#FFFFFF"
        d="M31.236,56.33c-0.227-0.572-0.406-0.967-0.406-1.443
					c0-0.203,0.06-0.383,0.096-0.477c0.047-0.098,0.061-0.156,0.011-0.156c-0.07,0-0.226,0.166-0.32,0.311
					c-0.073,0.119-0.156,0.346-0.156,0.523c0,0.336,0.108,0.693,0.179,0.908c0.132,0.369,0.251,0.799,0.251,1.205
					c0,0.645-0.287,1.086-0.799,1.479c0.047,0.037,0.166,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.324
					C31.498,56.998,31.367,56.688,31.236,56.33"
      />
    </g>
  </g>
);

const FirstAidStage4 = () => (
  <g id="Stage_4">
    <polygon
      fill="#DED3E8"
      points="57.551,19.702 57.551,50.543 30.841,65.964 4.131,50.543 4.131,19.702 30.841,4.281 			"
    />
    <polygon
      fill="#B293BF"
      points="57.551,50.543 30.843,65.963 30.843,35.123 			"
    />
    <polygon
      fill="#B293BF"
      points="57.551,19.703 57.551,50.543 30.843,35.123 			"
    />
    <polygon fill="#B293BF" points="30.842,4.277 57.55,19.697 30.842,35.117 			" />
    <polygon fill="#B293BF" points="30.84,65.964 4.132,50.544 30.84,35.124 			" />
    <g>
      <defs>
        <rect
          id="SVGID_13_"
          x="-0.002"
          y="0.018"
          width="61.687"
          height="70.204"
        />
      </defs>
      <clipPath id="SVGID_14_">
        <use xlinkHref="#SVGID_13_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_14_)"
        fill="#8A52A0"
        d="M30.841,4.593l26.438,15.263v30.526L30.841,65.647L4.404,50.383V19.856
					L30.841,4.593 M30.841,0.019c-0.572,0-1.145,0.148-1.657,0.444L1.656,16.355c-1.026,0.593-1.658,1.687-1.658,2.872v31.786
					c0,1.184,0.632,2.279,1.658,2.872l27.527,15.893c0.513,0.296,1.085,0.444,1.657,0.444c0.573,0,1.146-0.148,1.658-0.444
					l27.527-15.893c1.026-0.593,1.658-1.688,1.658-2.872V19.228c0-1.185-0.632-2.279-1.658-2.872L32.499,0.462
					C31.986,0.167,31.414,0.019,30.841,0.019"
      />
      <polygon
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        points="19.312,31.179 21.345,31.179 21.345,32.111 19.312,32.111
					19.312,33.666 18.243,33.666 18.243,29.327 21.597,29.327 21.597,30.266 19.312,30.266 				"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        d="M23.223,29.456c0,0.331-0.253,0.577-0.602,0.577
					c-0.351,0-0.596-0.246-0.596-0.577c0-0.317,0.245-0.589,0.596-0.589C22.97,28.867,23.223,29.139,23.223,29.456 M22.103,30.415
					h1.036v3.25h-1.036V30.415z"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        d="M26.268,30.395l-0.047,1.036h-0.187c-0.727,0-1.12,0.376-1.12,1.218v1.016
					h-1.037v-3.251h1.037v0.622c0.232-0.389,0.588-0.667,1.12-0.667C26.124,30.369,26.189,30.376,26.268,30.395"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        d="M27.427,32.609c0.045,0.279,0.297,0.382,0.576,0.382
					c0.271,0,0.421-0.116,0.421-0.259c0-0.11-0.084-0.194-0.323-0.24l-0.662-0.129c-0.602-0.11-0.944-0.434-0.944-0.945
					c0-0.667,0.576-1.101,1.398-1.101c0.811,0,1.348,0.369,1.477,0.933l-0.945,0.187c-0.033-0.2-0.227-0.381-0.544-0.381
					c-0.278,0-0.369,0.135-0.369,0.251c0,0.092,0.039,0.182,0.246,0.227l0.764,0.156c0.615,0.13,0.9,0.498,0.9,0.978
					c0,0.718-0.622,1.093-1.477,1.093c-0.765,0-1.418-0.278-1.527-0.945L27.427,32.609z"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        d="M32.316,33.522c-0.252,0.136-0.519,0.239-0.887,0.239
					c-0.81,0-1.296-0.439-1.296-1.294v-1.23h-0.589v-0.823h0.589v-0.959h1.036v0.959h0.958v0.823H31.17v1.126
					c0,0.337,0.162,0.472,0.435,0.472c0.142,0,0.336-0.051,0.472-0.129L32.316,33.522z"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        d="M37.504,33.664h-1.036v-0.258c-0.252,0.227-0.59,0.355-1.01,0.355
					c-0.861,0-1.587-0.691-1.587-1.722c0-1.023,0.726-1.722,1.587-1.722c0.42,0,0.758,0.129,1.01,0.356v-0.259h1.036V33.664z
					 M36.468,32.04c0-0.498-0.376-0.841-0.796-0.841c-0.454,0-0.784,0.343-0.784,0.841c0,0.505,0.33,0.842,0.784,0.842
					C36.092,32.882,36.468,32.544,36.468,32.04"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        d="M39.363,29.456c0,0.331-0.252,0.577-0.603,0.577
					c-0.35,0-0.596-0.246-0.596-0.577c0-0.317,0.246-0.589,0.596-0.589C39.111,28.867,39.363,29.139,39.363,29.456 M38.243,30.415
					h1.036v3.25h-1.036V30.415z"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        d="M43.436,33.664H42.4v-0.258c-0.252,0.227-0.59,0.355-1.011,0.355
					c-0.86,0-1.586-0.691-1.586-1.722c0-1.023,0.726-1.722,1.586-1.722c0.421,0,0.759,0.129,1.011,0.356v-1.67h1.035V33.664z
					 M42.4,32.04c0-0.498-0.376-0.841-0.797-0.841c-0.453,0-0.783,0.343-0.783,0.841c0,0.505,0.33,0.842,0.783,0.842
					C42.024,32.882,42.4,32.544,42.4,32.04"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#8A52A0"
        d="M19.81,37.73l-1.469,0.299c-0.109-0.571-0.58-0.853-1.062-0.853
					c-0.499,0-0.815,0.272-0.815,0.616c0,0.245,0.108,0.426,0.489,0.508l1.261,0.281c1.052,0.236,1.669,0.807,1.669,1.77
					c0,1.297-1.152,1.967-2.503,1.967c-1.369,0-2.494-0.652-2.657-1.84l1.541-0.301c0.154,0.626,0.59,0.898,1.197,0.898
					c0.563,0,0.897-0.254,0.897-0.626c0-0.272-0.127-0.444-0.562-0.535l-1.271-0.272c-0.87-0.199-1.632-0.652-1.632-1.759
					c0-1.179,0.979-1.914,2.403-1.914C18.621,35.97,19.584,36.623,19.81,37.73"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#8A52A0"
        d="M23.881,41.982c-0.354,0.191-0.725,0.336-1.242,0.336
					c-1.134,0-1.814-0.617-1.814-1.814v-1.723H20v-1.15h0.824v-1.342h1.45v1.342h1.344v1.15h-1.344v1.578
					c0,0.471,0.229,0.662,0.608,0.662c0.2,0,0.472-0.072,0.663-0.182L23.881,41.982z"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#8A52A0"
        d="M28.949,42.183h-1.451v-0.364c-0.354,0.319-0.824,0.5-1.413,0.5
					c-1.207,0-2.224-0.971-2.224-2.412c0-1.434,1.017-2.412,2.224-2.412c0.589,0,1.06,0.181,1.413,0.498V37.63h1.451V42.183z
					 M27.498,39.906c0-0.698-0.525-1.179-1.114-1.179c-0.636,0-1.099,0.48-1.099,1.179c0,0.707,0.463,1.179,1.099,1.179
					C26.973,41.085,27.498,40.613,27.498,39.906"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#8A52A0"
        d="M34.508,41.729c0,1.406-1.069,2.177-2.548,2.177
					c-1.043,0-1.841-0.363-2.286-1.097l1.206-0.653c0.346,0.407,0.6,0.562,1.07,0.562c0.663,0,1.134-0.39,1.134-1.105v-0.127
					c-0.326,0.281-0.779,0.436-1.369,0.436c-1.188,0-2.194-0.943-2.194-2.232c0-1.26,1.006-2.193,2.194-2.193
					c0.59,0,1.043,0.154,1.369,0.436v-0.301h1.424V41.729z M33.084,39.688c0-0.606-0.48-1.032-1.061-1.032
					c-0.634,0-1.088,0.426-1.088,1.032c0,0.608,0.454,1.045,1.088,1.045C32.604,40.732,33.084,40.296,33.084,39.688"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#8A52A0"
        d="M39.849,40.278h-3.337c0.118,0.544,0.49,0.879,0.979,0.879
					c0.336,0,0.726-0.081,0.971-0.526l1.288,0.264c-0.391,0.961-1.216,1.424-2.259,1.424c-1.314,0-2.402-0.971-2.402-2.412
					c0-1.434,1.088-2.412,2.412-2.412c1.287,0,2.321,0.925,2.349,2.412V40.278z M36.539,39.389h1.877
					c-0.137-0.488-0.499-0.717-0.916-0.717C37.092,38.672,36.675,38.926,36.539,39.389"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#8A52A0"
        d="M46.54,41.066v1.116h-1.405v-1.116h-3.156v-1.342l2.712-3.617h1.85v3.762
					h0.734v1.197H46.54z M45.135,37.549l-1.642,2.32h1.642V37.549z"
      />

      <rect
        x="28.668"
        y="17.625"
        transform="matrix(0.7071 0.7072 -0.7072 0.7071 23.0858 -16.0307)"
        clipPath="url(#SVGID_14_)"
        fill="#DED3E8"
        width="4.447"
        height="4.448"
      />

      <rect
        x="24.978"
        y="21.661"
        transform="matrix(0.7069 0.7073 -0.7073 0.7069 24.62 -12.3407)"
        clipPath="url(#SVGID_14_)"
        fill="#DED3E8"
        width="4.448"
        height="3.756"
      />

      <rect
        x="25.163"
        y="14.465"
        transform="matrix(0.7072 0.7071 -0.7071 0.7072 19.5759 -14.5781)"
        clipPath="url(#SVGID_14_)"
        fill="#DED3E8"
        width="4.448"
        height="3.757"
      />

      <rect
        x="32.011"
        y="20.968"
        transform="matrix(0.7072 0.7071 -0.7071 0.7072 26.4233 -17.4147)"
        clipPath="url(#SVGID_14_)"
        fill="#DED3E8"
        width="4.448"
        height="4.449"
      />

      <rect
        x="32.337"
        y="14.352"
        transform="matrix(0.7072 0.7071 -0.7071 0.7072 21.5605 -19.6985)"
        clipPath="url(#SVGID_14_)"
        fill="#DED3E8"
        width="4.448"
        height="3.655"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        d="M34.861,19.397l2.328-2.328c0.555-0.555,0.555-1.454,0-2.008l-1.667-1.667
					c-0.555-0.555-1.454-0.555-2.009,0l-2.327,2.327L34.861,19.397z M34.625,18.192c-0.113,0.112-0.297,0.112-0.408,0
					c-0.113-0.113-0.113-0.296,0-0.408c0.111-0.113,0.295-0.113,0.408,0C34.736,17.896,34.736,18.079,34.625,18.192 M36.073,15.926
					c0.113-0.112,0.296-0.112,0.408,0c0.112,0.113,0.112,0.295,0,0.408c-0.112,0.112-0.295,0.112-0.408,0
					C35.962,16.221,35.962,16.039,36.073,15.926 M35.553,16.855c0.113,0.112,0.113,0.295,0,0.408c-0.112,0.112-0.295,0.112-0.408,0
					c-0.112-0.113-0.112-0.296,0-0.408C35.258,16.743,35.44,16.743,35.553,16.855 M35.104,15.479c0.112,0.113,0.112,0.296,0,0.407
					c-0.112,0.113-0.295,0.113-0.407,0c-0.112-0.111-0.112-0.294,0-0.407C34.81,15.366,34.992,15.366,35.104,15.479 M34.25,14.102
					c0.111-0.112,0.294-0.112,0.406,0c0.113,0.113,0.113,0.295,0,0.408c-0.112,0.113-0.295,0.113-0.406,0
					C34.137,14.396,34.137,14.214,34.25,14.102 M34.176,16.407c0.113,0.113,0.113,0.295,0,0.407c-0.112,0.113-0.294,0.113-0.407,0
					c-0.113-0.112-0.113-0.294,0-0.407C33.882,16.294,34.063,16.294,34.176,16.407 M33.32,15.031c0.112-0.113,0.295-0.113,0.407,0
					c0.113,0.112,0.113,0.294,0,0.407c-0.112,0.112-0.295,0.112-0.407,0C33.209,15.325,33.209,15.144,33.32,15.031 M32.392,15.959
					c0.113-0.112,0.295-0.112,0.408,0c0.111,0.113,0.111,0.295,0,0.407c-0.113,0.113-0.295,0.113-0.408,0
					C32.279,16.254,32.279,16.072,32.392,15.959"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        d="M26.822,20.085l-2.327,2.328c-0.555,0.554-0.555,1.453,0,2.008l1.667,1.667
					c0.556,0.555,1.454,0.555,2.01,0l2.326-2.327L26.822,20.085z M25.532,23.634c-0.112,0.112-0.295,0.112-0.407,0
					c-0.112-0.113-0.112-0.295,0-0.407c0.112-0.113,0.295-0.113,0.407,0C25.645,23.339,25.645,23.521,25.532,23.634 M26.054,22.705
					c-0.112-0.113-0.112-0.295,0-0.408c0.112-0.112,0.295-0.112,0.407,0c0.112,0.113,0.112,0.295,0,0.408
					C26.349,22.818,26.166,22.818,26.054,22.705 M26.502,24.081c-0.112-0.111-0.112-0.294,0-0.407c0.112-0.112,0.295-0.112,0.406,0
					c0.113,0.113,0.113,0.296,0,0.407C26.797,24.194,26.614,24.194,26.502,24.081 M27.357,25.459c-0.113,0.112-0.296,0.112-0.408,0
					c-0.111-0.113-0.111-0.296,0-0.408c0.112-0.112,0.295-0.112,0.408,0C27.47,25.163,27.47,25.346,27.357,25.459 M28.807,23.193
					c0.113-0.112,0.296-0.112,0.408,0c0.112,0.113,0.112,0.296,0,0.408c-0.112,0.113-0.295,0.113-0.408,0
					C28.694,23.489,28.694,23.306,28.807,23.193 M28.286,24.122c0.112,0.113,0.112,0.295,0,0.408c-0.112,0.112-0.296,0.112-0.407,0
					c-0.113-0.113-0.113-0.295,0-0.408C27.99,24.01,28.174,24.01,28.286,24.122 M27.838,22.746c0.112,0.112,0.112,0.295,0,0.407
					s-0.295,0.112-0.407,0c-0.113-0.112-0.113-0.295,0-0.407C27.543,22.633,27.726,22.633,27.838,22.746 M26.982,21.369
					c0.112-0.112,0.295-0.112,0.407,0c0.112,0.112,0.112,0.295,0,0.407c-0.112,0.113-0.295,0.113-0.407,0
					C26.87,21.664,26.87,21.481,26.982,21.369"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#663272"
        d="M37.189,22.413l-2.671-2.671l-3.676-3.677l-2.671-2.671
					c-0.278-0.277-0.642-0.416-1.006-0.416c-0.362,0-0.727,0.139-1.004,0.416l-1.667,1.667c-0.555,0.555-0.555,1.454,0,2.009
					l2.671,2.672l3.677,3.675l2.672,2.672c0.276,0.277,0.64,0.415,1.004,0.415c0.363,0,0.726-0.138,1.004-0.415l1.667-1.668
					C37.744,23.867,37.744,22.967,37.189,22.413 M28.976,16.128c0.112-0.113,0.294-0.113,0.407,0c0.111,0.112,0.111,0.294,0,0.407
					c-0.113,0.112-0.295,0.112-0.407,0C28.862,16.422,28.862,16.24,28.976,16.128 M28.047,15.199c0.112-0.113,0.294-0.113,0.406,0
					c0.113,0.113,0.113,0.294,0,0.407c-0.112,0.113-0.294,0.113-0.406,0C27.934,15.493,27.934,15.312,28.047,15.199 M28.006,16.575
					c0.112,0.113,0.112,0.296,0,0.408c-0.112,0.113-0.295,0.113-0.407,0c-0.112-0.112-0.112-0.295,0-0.408
					C27.711,16.463,27.894,16.463,28.006,16.575 M27.117,14.27c0.113-0.113,0.295-0.113,0.407,0c0.113,0.113,0.113,0.295,0,0.407
					c-0.112,0.113-0.294,0.113-0.407,0C27.004,14.564,27.004,14.383,27.117,14.27 M25.7,16.501c-0.112,0.113-0.295,0.113-0.408,0
					c-0.111-0.112-0.111-0.295,0-0.408c0.113-0.111,0.296-0.111,0.408,0C25.813,16.207,25.813,16.39,25.7,16.501 M26.629,17.431
					c-0.112,0.112-0.296,0.112-0.407,0c-0.112-0.113-0.112-0.295,0-0.408c0.111-0.112,0.295-0.112,0.407,0
					C26.741,17.136,26.741,17.318,26.629,17.431 M26.67,16.054c-0.112-0.112-0.112-0.295,0-0.407c0.111-0.113,0.295-0.113,0.407,0
					c0.112,0.112,0.112,0.295,0,0.407C26.965,16.167,26.781,16.167,26.67,16.054 M27.15,17.952c0.112-0.112,0.295-0.112,0.408,0
					c0.111,0.112,0.111,0.295,0,0.408c-0.113,0.112-0.296,0.112-0.408,0C27.037,18.247,27.037,18.063,27.15,17.952 M34.456,21.607
					c-0.112,0.113-0.295,0.113-0.407,0c-0.112-0.112-0.112-0.294,0-0.407c0.112-0.112,0.295-0.112,0.407,0
					C34.568,21.313,34.568,21.496,34.456,21.607 M28.684,20.278c-0.296-0.296-0.296-0.777,0-1.074l1.622-1.621
					c0.297-0.297,0.776-0.297,1.073,0l1.623,1.621c0.295,0.297,0.295,0.778,0,1.074l-1.623,1.622c-0.297,0.297-0.776,0.297-1.073,0
					L28.684,20.278z M32.632,23.434c-0.112,0.112-0.296,0.112-0.408,0c-0.112-0.113-0.112-0.295,0-0.408
					c0.112-0.112,0.296-0.112,0.408,0C32.744,23.139,32.744,23.321,32.632,23.434 M33.561,24.362c-0.113,0.113-0.295,0.113-0.408,0
					c-0.112-0.112-0.112-0.295,0-0.407c0.113-0.112,0.295-0.112,0.408,0C33.672,24.066,33.672,24.25,33.561,24.362 M33.602,22.985
					c-0.113-0.112-0.113-0.295,0-0.407c0.111-0.113,0.294-0.113,0.407,0c0.111,0.112,0.111,0.295,0,0.407
					C33.896,23.098,33.713,23.098,33.602,22.985 M34.489,25.291c-0.112,0.112-0.296,0.112-0.408,0c-0.111-0.113-0.111-0.295,0-0.407
					c0.112-0.113,0.296-0.113,0.408,0C34.602,24.996,34.602,25.178,34.489,25.291 M34.938,23.914c-0.113,0.113-0.296,0.113-0.408,0
					c-0.112-0.112-0.112-0.295,0-0.407c0.112-0.113,0.295-0.113,0.408,0C35.05,23.619,35.05,23.802,34.938,23.914 M35.386,22.537
					c-0.113,0.113-0.295,0.113-0.408,0c-0.113-0.112-0.113-0.294,0-0.407c0.113-0.113,0.295-0.113,0.408,0
					C35.497,22.243,35.497,22.425,35.386,22.537 M36.313,23.466c-0.112,0.113-0.294,0.113-0.407,0c-0.113-0.112-0.113-0.294,0-0.407
					c0.113-0.113,0.295-0.113,0.407,0C36.427,23.172,36.427,23.354,36.313,23.466"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#4F86C6"
        d="M30.842,59.534c-2.638,0-4.772-2.135-4.772-4.771
					c0-2.637,2.135-4.773,4.772-4.773c2.639,0,4.774,2.137,4.774,4.773C35.616,57.399,33.48,59.534,30.842,59.534"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#FFFFFF"
        d="M30.842,49.99c2.639,0,4.774,2.137,4.774,4.772
					c0,2.637-2.136,4.771-4.774,4.771c-2.638,0-4.772-2.135-4.772-4.771C26.069,52.127,28.204,49.99,30.842,49.99 M30.842,49.578
					c-2.858,0-5.184,2.326-5.184,5.185c0,2.857,2.325,5.184,5.184,5.184c2.86,0,5.187-2.326,5.187-5.184
					C36.028,51.904,33.702,49.578,30.842,49.578"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#FFFFFF"
        d="M33.336,52.817c-0.239,0-0.464,0.107-0.62,0.251l-0.013-0.012
					c0.072-0.12,0.169-0.346,0.169-0.716c0-0.884-0.885-1.552-2.029-1.552c-1.146,0-2.041,0.668-2.041,1.552
					c0,0.37,0.12,0.596,0.179,0.716l-0.012,0.012c-0.155-0.144-0.382-0.251-0.62-0.251c-0.729,0-1.456,0.703-1.456,2.018
					c0,1.288,0.716,2.016,1.492,2.016c0.716,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.18-0.215-0.298-0.215
					c-0.119,0-0.252,0.085-0.478,0.263c-0.299,0.228-0.562,0.359-0.907,0.359c-0.609,0-0.87-0.683-0.87-1.575
					c0-0.955,0.251-1.587,0.895-1.587c0.286,0,0.477,0.083,0.668,0.262c0.286,0.263,0.381,0.333,0.512,0.333
					c0.097,0,0.156-0.06,0.181-0.094c-0.215-0.216-0.525-0.668-0.525-1.253c0-0.668,0.561-1.135,1.611-1.135
					c1.05,0,1.611,0.467,1.611,1.135c0,0.585-0.312,1.037-0.525,1.253c0.022,0.034,0.083,0.094,0.179,0.094
					c0.131,0,0.227-0.07,0.513-0.333c0.179-0.179,0.382-0.262,0.668-0.262c0.645,0,0.896,0.632,0.896,1.587
					c0,0.893-0.263,1.575-0.871,1.575c-0.346,0-0.62-0.132-0.907-0.359c-0.227-0.178-0.357-0.263-0.477-0.263
					c-0.12,0-0.228,0.107-0.299,0.215c0.44,0.453,0.955,0.848,1.671,0.848c0.775,0,1.491-0.728,1.491-2.016
					C34.792,53.521,34.063,52.817,33.336,52.817"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#FFFFFF"
        d="M31.642,52.794h-0.537c-0.035,0-0.059-0.024-0.071-0.048l-0.168-0.512
					c-0.012-0.037-0.022-0.037-0.035,0l-0.166,0.512c-0.013,0.036-0.036,0.048-0.061,0.048h-0.537c-0.023,0-0.035,0.011-0.012,0.024
					l0.441,0.321c0.023,0.023,0.023,0.061,0.012,0.083l-0.166,0.501c-0.012,0.024-0.012,0.049,0.023,0.024l0.441-0.31
					c0.036-0.024,0.059-0.024,0.095,0l0.431,0.31c0.023,0.024,0.035,0.012,0.023-0.011l-0.167-0.515
					c-0.012-0.035,0-0.07,0.024-0.083l0.439-0.321C31.678,52.805,31.666,52.794,31.642,52.794"
      />
      <path
        clipPath="url(#SVGID_14_)"
        fill="#FFFFFF"
        d="M31.236,56.324c-0.227-0.571-0.406-0.966-0.406-1.442
					c0-0.203,0.061-0.382,0.096-0.478c0.048-0.096,0.061-0.155,0.012-0.155c-0.071,0-0.227,0.167-0.322,0.311
					c-0.072,0.119-0.155,0.346-0.155,0.524c0,0.335,0.108,0.692,0.18,0.906c0.132,0.37,0.251,0.801,0.251,1.206
					c0,0.646-0.287,1.084-0.801,1.479c0.049,0.037,0.168,0.096,0.312,0.096c0.5,0,1.097-0.561,1.097-1.324
					C31.498,56.992,31.367,56.683,31.236,56.324"
      />
    </g>
  </g>
);

const FirstAidStage3 = () => (
  <g id="Stage_3">
    <polygon
      fill="#DED3E8"
      points="57.564,19.698 57.564,50.539 30.855,65.96 4.145,50.539 4.145,19.698 30.855,4.277 			"
    />
    <polygon
      fill="#B293BF"
      points="57.564,50.539 30.855,65.959 30.855,35.119 			"
    />
    <polygon
      fill="#B293BF"
      points="57.564,19.699 57.564,50.539 30.856,35.119 			"
    />
    <polygon
      fill="#B293BF"
      points="30.855,4.273 57.563,19.693 30.855,35.113 			"
    />
    <g>
      <defs>
        <rect
          id="SVGID_15_"
          x="0.011"
          y="0.014"
          width="61.686"
          height="70.204"
        />
      </defs>
      <clipPath id="SVGID_16_">
        <use xlinkHref="#SVGID_15_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_16_)"
        fill="#8A52A0"
        d="M30.854,4.589l26.437,15.263v30.528L30.854,65.644L4.417,50.381V19.853
					L30.854,4.589 M30.854,0.015c-0.573,0-1.145,0.148-1.658,0.444L1.669,16.352c-1.027,0.593-1.658,1.687-1.658,2.872V51.01
					c0,1.185,0.631,2.279,1.658,2.872l27.526,15.893c0.514,0.296,1.085,0.444,1.658,0.444S32,70.07,32.513,69.774l27.526-15.893
					c1.027-0.593,1.66-1.688,1.66-2.872V19.224c0-1.185-0.633-2.279-1.66-2.872L32.513,0.458C32,0.163,31.427,0.015,30.854,0.015"
      />
      <polygon
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        points="19.324,31.175 21.357,31.175 21.357,32.107 19.324,32.107
					19.324,33.662 18.256,33.662 18.256,29.323 21.61,29.323 21.61,30.262 19.324,30.262 				"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        d="M23.236,29.452c0,0.331-0.252,0.577-0.603,0.577
					c-0.35,0-0.596-0.246-0.596-0.577c0-0.316,0.246-0.589,0.596-0.589C22.984,28.863,23.236,29.136,23.236,29.452 M22.116,30.411
					h1.036v3.25h-1.036V30.411z"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        d="M26.28,30.392l-0.046,1.036h-0.188c-0.726,0-1.119,0.376-1.119,1.218v1.016
					H23.89V30.41h1.037v0.622c0.232-0.389,0.589-0.667,1.119-0.667C26.138,30.365,26.202,30.373,26.28,30.392"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        d="M27.439,32.605c0.046,0.279,0.299,0.382,0.576,0.382
					c0.272,0,0.421-0.116,0.421-0.259c0-0.109-0.084-0.193-0.322-0.24l-0.661-0.129c-0.603-0.109-0.946-0.434-0.946-0.945
					c0-0.667,0.577-1.101,1.399-1.101c0.81,0,1.347,0.369,1.477,0.933l-0.946,0.187c-0.031-0.2-0.226-0.381-0.544-0.381
					c-0.277,0-0.369,0.135-0.369,0.251c0,0.092,0.039,0.182,0.246,0.227l0.765,0.156c0.614,0.13,0.899,0.498,0.899,0.978
					c0,0.719-0.621,1.095-1.476,1.095c-0.764,0-1.418-0.279-1.528-0.946L27.439,32.605z"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        d="M32.329,33.52c-0.252,0.135-0.518,0.238-0.887,0.238
					c-0.809,0-1.295-0.439-1.295-1.294v-1.23h-0.589v-0.823h0.589v-0.959h1.036v0.959h0.958v0.823h-0.958v1.125
					c0,0.338,0.162,0.473,0.434,0.473c0.143,0,0.336-0.051,0.474-0.129L32.329,33.52z"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        d="M37.518,33.66H36.48v-0.258c-0.252,0.227-0.588,0.355-1.01,0.355
					c-0.861,0-1.586-0.691-1.586-1.723c0-1.022,0.725-1.721,1.586-1.721c0.422,0,0.758,0.129,1.01,0.356v-0.259h1.037V33.66z
					 M36.48,32.035c0-0.497-0.375-0.84-0.795-0.84c-0.455,0-0.785,0.343-0.785,0.84c0,0.506,0.33,0.843,0.785,0.843
					C36.105,32.878,36.48,32.541,36.48,32.035"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        d="M39.375,29.452c0,0.331-0.252,0.577-0.602,0.577s-0.596-0.246-0.596-0.577
					c0-0.316,0.246-0.589,0.596-0.589S39.375,29.136,39.375,29.452 M38.256,30.411h1.035v3.25h-1.035V30.411z"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        d="M43.449,33.66h-1.035v-0.258c-0.252,0.227-0.59,0.355-1.012,0.355
					c-0.861,0-1.586-0.691-1.586-1.723c0-1.022,0.725-1.721,1.586-1.721c0.422,0,0.76,0.129,1.012,0.356V29h1.035V33.66z
					 M42.414,32.035c0-0.497-0.377-0.84-0.797-0.84c-0.453,0-0.783,0.343-0.783,0.84c0,0.506,0.33,0.843,0.783,0.843
					C42.037,32.878,42.414,32.541,42.414,32.035"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#8A52A0"
        d="M20.044,37.727l-1.469,0.299c-0.109-0.571-0.581-0.852-1.061-0.852
					c-0.5,0-0.816,0.271-0.816,0.615c0,0.245,0.107,0.426,0.489,0.508l1.261,0.281c1.052,0.236,1.669,0.807,1.669,1.77
					c0,1.297-1.152,1.967-2.504,1.967c-1.368,0-2.493-0.652-2.657-1.84l1.542-0.301c0.154,0.627,0.59,0.898,1.197,0.898
					c0.562,0,0.897-0.254,0.897-0.626c0-0.272-0.127-0.444-0.562-0.535l-1.271-0.272c-0.869-0.198-1.632-0.652-1.632-1.758
					c0-1.18,0.979-1.914,2.403-1.914C18.857,35.967,19.818,36.619,20.044,37.727"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#8A52A0"
        d="M24.116,41.979c-0.354,0.191-0.726,0.336-1.242,0.336
					c-1.134,0-1.813-0.617-1.813-1.814v-1.723h-0.825v-1.15h0.825v-1.342h1.45v1.342h1.343v1.15H22.51v1.578
					c0,0.471,0.228,0.662,0.608,0.662c0.199,0,0.471-0.072,0.662-0.181L24.116,41.979z"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#8A52A0"
        d="M29.184,42.18h-1.45v-0.365c-0.354,0.32-0.825,0.5-1.414,0.5
					c-1.207,0-2.223-0.971-2.223-2.412c0-1.434,1.016-2.411,2.223-2.411c0.589,0,1.061,0.18,1.414,0.498v-0.363h1.45V42.18z
					 M27.734,39.902c0-0.698-0.526-1.178-1.115-1.178c-0.635,0-1.098,0.479-1.098,1.178c0,0.707,0.463,1.179,1.098,1.179
					C27.208,41.081,27.734,40.609,27.734,39.902"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#8A52A0"
        d="M34.744,41.725c0,1.406-1.07,2.177-2.548,2.177
					c-1.043,0-1.841-0.362-2.286-1.097l1.206-0.653c0.345,0.408,0.599,0.562,1.069,0.562c0.663,0,1.135-0.39,1.135-1.105V41.48
					c-0.327,0.281-0.781,0.436-1.37,0.436c-1.189,0-2.195-0.943-2.195-2.231c0-1.261,1.006-2.194,2.195-2.194
					c0.589,0,1.043,0.154,1.37,0.436v-0.301h1.424V41.725z M33.32,39.685c0-0.606-0.482-1.033-1.062-1.033
					c-0.635,0-1.088,0.427-1.088,1.033c0,0.608,0.453,1.044,1.088,1.044C32.838,40.729,33.32,40.293,33.32,39.685"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#8A52A0"
        d="M40.084,40.274h-3.338c0.119,0.544,0.49,0.879,0.98,0.879
					c0.336,0,0.725-0.081,0.969-0.526l1.289,0.264c-0.391,0.962-1.215,1.424-2.258,1.424c-1.316,0-2.404-0.971-2.404-2.412
					c0-1.434,1.088-2.412,2.412-2.412c1.289,0,2.322,0.926,2.35,2.412V40.274z M36.773,39.385h1.877
					c-0.137-0.488-0.498-0.717-0.916-0.717C37.326,38.668,36.91,38.922,36.773,39.385"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#8A52A0"
        d="M46.957,40.355c0,1.152-0.934,1.959-2.268,1.959
					c-1.279,0-2.213-0.743-2.35-1.923l1.416-0.263c0.08,0.6,0.408,0.943,0.943,0.943c0.508,0,0.869-0.291,0.869-0.734
					c0-0.399-0.299-0.635-0.824-0.635h-0.816v-1.188h0.752c0.518,0,0.816-0.226,0.816-0.616c0-0.417-0.316-0.698-0.807-0.698
					s-0.854,0.3-0.906,0.979l-1.369-0.264c0.135-1.205,1.033-1.949,2.266-1.949c1.299,0,2.178,0.798,2.178,1.914
					c0,0.553-0.236,0.979-0.654,1.224C46.684,39.35,46.957,39.785,46.957,40.355"
      />

      <rect
        x="28.68"
        y="17.621"
        transform="matrix(0.7071 0.7072 -0.7072 0.7071 23.0866 -16.0405)"
        clipPath="url(#SVGID_16_)"
        fill="#DED3E8"
        width="4.447"
        height="4.448"
      />

      <rect
        x="24.989"
        y="21.657"
        transform="matrix(0.7071 0.7072 -0.7072 0.7071 24.6154 -12.35)"
        clipPath="url(#SVGID_16_)"
        fill="#DED3E8"
        width="4.449"
        height="3.756"
      />

      <rect
        x="25.175"
        y="14.461"
        transform="matrix(0.7071 0.7072 -0.7072 0.7071 19.5814 -14.5887)"
        clipPath="url(#SVGID_16_)"
        fill="#DED3E8"
        width="4.447"
        height="3.757"
      />

      <rect
        x="32.024"
        y="20.964"
        transform="matrix(0.7072 0.7071 -0.7071 0.7072 26.4246 -17.4247)"
        clipPath="url(#SVGID_16_)"
        fill="#DED3E8"
        width="4.449"
        height="4.449"
      />

      <rect
        x="32.349"
        y="14.348"
        transform="matrix(0.7071 0.7071 -0.7071 0.7071 21.5641 -19.7093)"
        clipPath="url(#SVGID_16_)"
        fill="#DED3E8"
        width="4.448"
        height="3.655"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        d="M34.873,19.394l2.328-2.328c0.555-0.555,0.555-1.454,0-2.008l-1.666-1.667
					c-0.555-0.555-1.455-0.555-2.01,0l-2.328,2.327L34.873,19.394z M34.635,18.188c-0.111,0.112-0.295,0.112-0.406,0
					c-0.113-0.113-0.113-0.296,0-0.408c0.111-0.113,0.295-0.113,0.406,0C34.748,17.893,34.748,18.075,34.635,18.188 M36.086,15.922
					c0.113-0.112,0.295-0.112,0.408,0c0.111,0.113,0.111,0.295,0,0.408c-0.113,0.112-0.295,0.112-0.408,0
					C35.973,16.217,35.973,16.035,36.086,15.922 M35.564,16.852c0.113,0.112,0.113,0.295,0,0.408c-0.113,0.112-0.295,0.112-0.408,0
					c-0.111-0.113-0.111-0.296,0-0.408C35.27,16.739,35.451,16.739,35.564,16.852 M35.117,15.474c0.111,0.113,0.111,0.296,0,0.407
					c-0.113,0.113-0.295,0.113-0.408,0c-0.113-0.111-0.113-0.294,0-0.407C34.822,15.362,35.004,15.362,35.117,15.474 M34.26,14.097
					c0.113-0.112,0.295-0.112,0.408,0c0.113,0.113,0.113,0.295,0,0.408s-0.295,0.113-0.408,0
					C34.148,14.393,34.148,14.21,34.26,14.097 M34.188,16.403c0.113,0.113,0.113,0.295,0,0.407c-0.111,0.113-0.293,0.113-0.406,0
					c-0.113-0.112-0.113-0.294,0-0.407S34.076,16.291,34.188,16.403 M33.332,15.027c0.113-0.113,0.295-0.113,0.408,0
					c0.113,0.112,0.113,0.294,0,0.407c-0.113,0.112-0.295,0.112-0.408,0C33.219,15.321,33.219,15.139,33.332,15.027 M32.404,15.955
					c0.111-0.112,0.295-0.112,0.406,0c0.113,0.113,0.113,0.295,0,0.407c-0.111,0.113-0.295,0.113-0.406,0
					C32.292,16.25,32.292,16.068,32.404,15.955"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        d="M26.834,20.081l-2.327,2.328c-0.555,0.554-0.555,1.453,0,2.008l1.667,1.667
					c0.555,0.555,1.454,0.555,2.009,0l2.327-2.327L26.834,20.081z M25.544,23.63c-0.113,0.112-0.295,0.112-0.408,0
					c-0.112-0.113-0.112-0.295,0-0.407c0.113-0.113,0.295-0.113,0.408,0C25.656,23.335,25.656,23.517,25.544,23.63 M26.065,22.701
					c-0.112-0.113-0.112-0.295,0-0.407c0.113-0.113,0.295-0.113,0.408,0c0.112,0.112,0.112,0.294,0,0.407
					C26.36,22.814,26.178,22.814,26.065,22.701 M26.513,24.077c-0.112-0.111-0.112-0.294,0-0.407c0.113-0.112,0.295-0.112,0.407,0
					c0.112,0.113,0.112,0.296,0,0.407C26.808,24.19,26.626,24.19,26.513,24.077 M27.37,25.455c-0.113,0.112-0.296,0.112-0.408,0
					c-0.112-0.113-0.112-0.296,0-0.408c0.112-0.112,0.295-0.112,0.408,0C27.481,25.159,27.481,25.342,27.37,25.455 M28.819,23.189
					c0.112-0.112,0.296-0.112,0.407,0c0.113,0.113,0.113,0.296,0,0.408c-0.111,0.113-0.295,0.113-0.407,0
					C28.707,23.485,28.707,23.302,28.819,23.189 M28.298,24.118c0.111,0.113,0.111,0.295,0,0.408c-0.113,0.112-0.296,0.112-0.408,0
					c-0.113-0.113-0.113-0.295,0-0.408C28.002,24.006,28.185,24.006,28.298,24.118 M27.849,22.742c0.113,0.112,0.113,0.295,0,0.407
					c-0.112,0.112-0.294,0.112-0.407,0c-0.112-0.112-0.112-0.295,0-0.407C27.555,22.629,27.737,22.629,27.849,22.742 M26.995,21.365
					c0.111-0.112,0.295-0.112,0.406,0c0.113,0.112,0.113,0.295,0,0.407c-0.111,0.113-0.295,0.113-0.406,0
					C26.882,21.66,26.882,21.477,26.995,21.365"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#663272"
        d="M37.201,22.409l-2.67-2.671l-3.678-3.677l-2.671-2.671
					c-0.276-0.277-0.641-0.416-1.004-0.416c-0.362,0-0.727,0.139-1.004,0.416l-1.668,1.667c-0.554,0.555-0.554,1.454,0,2.009
					l2.672,2.672l3.675,3.675l2.672,2.672c0.277,0.277,0.641,0.415,1.006,0.415c0.363,0,0.725-0.138,1.004-0.415l1.666-1.668
					C37.756,23.863,37.756,22.963,37.201,22.409 M28.987,16.124c0.111-0.113,0.295-0.113,0.407,0c0.112,0.112,0.112,0.294,0,0.407
					c-0.112,0.112-0.296,0.112-0.407,0C28.875,16.418,28.875,16.236,28.987,16.124 M28.058,15.195c0.112-0.113,0.295-0.113,0.407,0
					c0.112,0.113,0.112,0.294,0,0.407c-0.112,0.113-0.295,0.113-0.407,0C27.946,15.489,27.946,15.308,28.058,15.195 M28.017,16.571
					c0.113,0.113,0.113,0.296,0,0.408c-0.111,0.113-0.295,0.113-0.407,0c-0.112-0.112-0.112-0.295,0-0.408
					C27.722,16.459,27.906,16.459,28.017,16.571 M27.129,14.266c0.112-0.113,0.294-0.113,0.407,0c0.113,0.113,0.113,0.295,0,0.407
					c-0.113,0.113-0.295,0.113-0.407,0C27.016,14.561,27.016,14.379,27.129,14.266 M25.711,16.498c-0.112,0.113-0.294,0.113-0.407,0
					c-0.112-0.112-0.112-0.295,0-0.408c0.113-0.111,0.295-0.111,0.407,0C25.825,16.203,25.825,16.386,25.711,16.498 M26.64,17.427
					c-0.111,0.112-0.295,0.112-0.406,0c-0.113-0.113-0.113-0.295,0-0.408c0.111-0.112,0.295-0.112,0.406,0
					C26.752,17.132,26.752,17.313,26.64,17.427 M26.681,16.05c-0.112-0.112-0.112-0.295,0-0.407c0.112-0.113,0.295-0.113,0.407,0
					c0.113,0.112,0.113,0.295,0,0.407C26.976,16.163,26.793,16.163,26.681,16.05 M27.162,17.948c0.113-0.112,0.296-0.112,0.408,0
					c0.111,0.112,0.111,0.295,0,0.408c-0.112,0.112-0.295,0.112-0.408,0C27.049,18.243,27.049,18.06,27.162,17.948 M34.467,21.604
					c-0.111,0.113-0.293,0.113-0.406,0c-0.111-0.112-0.111-0.294,0-0.407c0.113-0.112,0.295-0.112,0.406,0
					C34.58,21.31,34.58,21.492,34.467,21.604 M28.696,20.274c-0.297-0.296-0.297-0.777,0-1.074l1.622-1.621
					c0.297-0.297,0.776-0.297,1.072,0l1.622,1.621c0.297,0.297,0.297,0.778,0,1.074l-1.622,1.622c-0.296,0.297-0.775,0.297-1.072,0
					L28.696,20.274z M32.644,23.43c-0.113,0.112-0.296,0.112-0.408,0c-0.112-0.113-0.112-0.295,0-0.408
					c0.112-0.112,0.295-0.112,0.408,0C32.755,23.135,32.755,23.317,32.644,23.43 M33.572,24.358c-0.113,0.113-0.295,0.113-0.408,0
					c-0.113-0.112-0.113-0.295,0-0.407s0.295-0.112,0.408,0C33.684,24.063,33.684,24.246,33.572,24.358 M33.613,22.98
					c-0.113-0.112-0.113-0.295,0-0.407c0.111-0.113,0.295-0.113,0.406,0c0.113,0.112,0.113,0.295,0,0.407
					C33.908,23.094,33.725,23.094,33.613,22.98 M34.502,25.287c-0.113,0.112-0.297,0.112-0.408,0c-0.113-0.113-0.113-0.295,0-0.407
					c0.111-0.113,0.295-0.113,0.408,0C34.613,24.992,34.613,25.174,34.502,25.287 M34.949,23.91c-0.113,0.113-0.295,0.113-0.408,0
					c-0.113-0.112-0.113-0.295,0-0.407c0.113-0.113,0.295-0.113,0.408,0C35.063,23.615,35.063,23.798,34.949,23.91 M35.396,22.534
					c-0.113,0.112-0.295,0.112-0.406,0c-0.113-0.113-0.113-0.295,0-0.408c0.111-0.113,0.293-0.113,0.406,0
					C35.508,22.239,35.508,22.421,35.396,22.534 M36.326,23.462c-0.113,0.113-0.295,0.113-0.408,0c-0.113-0.112-0.113-0.294,0-0.407
					c0.113-0.113,0.295-0.113,0.408,0C36.438,23.168,36.438,23.35,36.326,23.462"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#4F86C6"
        d="M30.854,59.53c-2.638,0-4.772-2.135-4.772-4.771
					c0-2.636,2.135-4.772,4.772-4.772c2.638,0,4.773,2.137,4.773,4.772C35.627,57.396,33.492,59.53,30.854,59.53"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#FFFFFF"
        d="M30.854,49.986c2.638,0,4.773,2.137,4.773,4.772
					c0,2.637-2.135,4.771-4.773,4.771c-2.638,0-4.772-2.135-4.772-4.771C26.082,52.123,28.216,49.986,30.854,49.986 M30.854,49.574
					c-2.858,0-5.185,2.326-5.185,5.185c0,2.857,2.326,5.184,5.185,5.184c2.859,0,5.185-2.326,5.185-5.184
					C36.039,51.9,33.713,49.574,30.854,49.574"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#FFFFFF"
        d="M33.348,52.813c-0.238,0-0.465,0.107-0.62,0.251l-0.014-0.012
					c0.072-0.12,0.168-0.346,0.168-0.716c0-0.883-0.883-1.551-2.027-1.551c-1.146,0-2.041,0.668-2.041,1.551
					c0,0.37,0.12,0.596,0.179,0.716l-0.012,0.012c-0.155-0.144-0.382-0.251-0.62-0.251c-0.729,0-1.456,0.703-1.456,2.018
					c0,1.288,0.716,2.016,1.492,2.016c0.716,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.181-0.215-0.299-0.215
					s-0.251,0.085-0.477,0.263c-0.299,0.229-0.562,0.359-0.909,0.359c-0.607,0-0.869-0.683-0.869-1.575
					c0-0.955,0.25-1.587,0.896-1.587c0.286,0,0.476,0.083,0.668,0.262c0.286,0.263,0.381,0.333,0.512,0.333
					c0.097,0,0.156-0.059,0.18-0.094c-0.214-0.215-0.524-0.668-0.524-1.253c0-0.668,0.561-1.135,1.611-1.135
					c1.049,0,1.609,0.467,1.609,1.135c0,0.585-0.31,1.038-0.523,1.253c0.022,0.035,0.083,0.094,0.178,0.094
					c0.132,0,0.227-0.07,0.513-0.333c0.18-0.179,0.382-0.262,0.667-0.262c0.646,0,0.896,0.632,0.896,1.587
					c0,0.893-0.264,1.575-0.871,1.575c-0.345,0-0.621-0.131-0.908-0.359c-0.226-0.178-0.356-0.263-0.476-0.263
					c-0.12,0-0.228,0.107-0.299,0.215c0.44,0.453,0.955,0.848,1.67,0.848c0.775,0,1.492-0.728,1.492-2.016
					C34.805,53.517,34.076,52.813,33.348,52.813"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#FFFFFF"
        d="M31.654,52.79h-0.537c-0.036,0-0.059-0.024-0.071-0.048l-0.168-0.512
					c-0.012-0.036-0.022-0.036-0.035,0l-0.168,0.512c-0.012,0.036-0.034,0.048-0.059,0.048h-0.538c-0.023,0-0.035,0.011-0.012,0.024
					l0.442,0.321c0.023,0.023,0.023,0.061,0.012,0.083l-0.167,0.501c-0.012,0.024-0.012,0.05,0.024,0.024l0.441-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.024,0.025,0.035,0.012,0.024-0.011l-0.167-0.515
					c-0.013-0.034,0-0.07,0.023-0.083l0.44-0.321C31.69,52.801,31.676,52.79,31.654,52.79"
      />
      <path
        clipPath="url(#SVGID_16_)"
        fill="#FFFFFF"
        d="M31.248,56.321c-0.226-0.572-0.406-0.967-0.406-1.443
					c0-0.203,0.061-0.382,0.097-0.477c0.048-0.097,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.167-0.321,0.311
					c-0.072,0.119-0.155,0.347-0.155,0.524c0,0.335,0.107,0.693,0.179,0.907c0.132,0.37,0.251,0.8,0.251,1.206
					c0,0.645-0.287,1.085-0.799,1.478c0.046,0.037,0.167,0.096,0.31,0.096c0.5,0,1.097-0.56,1.097-1.323
					C31.509,56.989,31.378,56.679,31.248,56.321"
      />
    </g>
  </g>
);
// #endregion

// #startregion FirstAid Groups
const FeelGoodStage5 = () => (
  <g id="Stage_5_3_">
    <polygon
      fill="#DED3E8"
      points="57.548,19.712 57.548,50.554 30.838,65.975 4.128,50.554 4.128,19.712 30.838,4.291 			"
    />
    <polygon fill="#B293BF" points="57.548,50.554 30.84,65.975 30.84,35.135 			" />
    <polygon
      fill="#B293BF"
      points="57.548,19.714 57.548,50.554 30.84,35.134 			"
    />
    <polygon
      fill="#B293BF"
      points="30.839,4.288 57.547,19.708 30.839,35.128 			"
    />
    <polygon
      fill="#B293BF"
      points="30.837,65.975 4.128,50.555 30.837,35.135 			"
    />
    <polygon fill="#B293BF" points="30.837,35.137 4.128,50.557 4.128,19.717 			" />
    <g>
      <defs>
        <rect
          id="SVGID_17_"
          x="-0.005"
          y="0.028"
          width="61.687"
          height="70.204"
        />
      </defs>
      <clipPath id="SVGID_18_">
        <use xlinkHref="#SVGID_17_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_18_)"
        fill="#8A52A0"
        d="M30.838,4.604l26.438,15.263v30.528L30.838,65.658L4.401,50.396V19.867
					L30.838,4.604 M30.838,0.029c-0.572,0-1.145,0.148-1.658,0.445L1.653,16.366c-1.027,0.593-1.658,1.687-1.658,2.872v31.786
					c0,1.186,0.631,2.279,1.658,2.872L29.18,69.788c0.514,0.297,1.086,0.445,1.658,0.445c0.573,0,1.146-0.148,1.658-0.445
					l27.527-15.892c1.026-0.593,1.658-1.687,1.658-2.872V19.238c0-1.185-0.632-2.279-1.658-2.872L32.496,0.474
					C31.983,0.177,31.411,0.029,30.838,0.029"
      />
      <polygon
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        points="17.239,31.189 19.271,31.189 19.271,32.122 17.239,32.122
					17.239,33.677 16.17,33.677 16.17,29.338 19.525,29.338 19.525,30.277 17.239,30.277 				"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        d="M23.068,32.317h-2.385c0.086,0.388,0.351,0.628,0.7,0.628
					c0.239,0,0.519-0.058,0.693-0.376l0.919,0.188c-0.278,0.687-0.868,1.017-1.612,1.017c-0.938,0-1.716-0.693-1.716-1.723
					c0-1.023,0.777-1.722,1.722-1.722c0.921,0,1.658,0.66,1.679,1.722V32.317z M20.704,31.682h1.34
					c-0.097-0.35-0.356-0.511-0.654-0.511C21.099,31.171,20.801,31.352,20.704,31.682"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        d="M26.728,32.317h-2.384c0.085,0.388,0.35,0.628,0.699,0.628
					c0.239,0,0.519-0.058,0.693-0.376l0.919,0.188c-0.278,0.687-0.868,1.017-1.612,1.017c-0.938,0-1.716-0.693-1.716-1.723
					c0-1.023,0.777-1.722,1.723-1.722c0.92,0,1.657,0.66,1.678,1.722V32.317z M24.363,31.682h1.34
					c-0.097-0.35-0.355-0.511-0.653-0.511C24.759,31.171,24.461,31.352,24.363,31.682"
      />
      <rect
        x="27.2"
        y="29.014"
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        width="1.036"
        height="4.662"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        d="M33.709,33.353c0,1.004-0.766,1.554-1.82,1.554
					c-0.744,0-1.314-0.259-1.631-0.783l0.86-0.467c0.246,0.291,0.428,0.402,0.765,0.402c0.473,0,0.809-0.279,0.809-0.79v-0.091
					c-0.232,0.201-0.556,0.311-0.978,0.311c-0.848,0-1.567-0.674-1.567-1.593c0-0.9,0.72-1.567,1.567-1.567
					c0.422,0,0.745,0.11,0.978,0.311v-0.214h1.018V33.353z M32.691,31.896c0-0.434-0.343-0.738-0.758-0.738
					c-0.453,0-0.777,0.304-0.777,0.738s0.324,0.745,0.777,0.745C32.349,32.641,32.691,32.33,32.691,31.896"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        d="M37.769,32.051c0,1.03-0.815,1.717-1.779,1.717
					c-0.984,0-1.801-0.687-1.801-1.717c0-1.023,0.816-1.709,1.801-1.709C36.953,30.342,37.769,31.028,37.769,32.051 M36.771,32.051
					c0-0.505-0.382-0.842-0.782-0.842c-0.421,0-0.804,0.337-0.804,0.842c0,0.511,0.383,0.842,0.804,0.842
					C36.39,32.893,36.771,32.562,36.771,32.051"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        d="M41.61,32.051c0,1.03-0.816,1.717-1.781,1.717
					c-0.984,0-1.799-0.687-1.799-1.717c0-1.023,0.814-1.709,1.799-1.709C40.794,30.342,41.61,31.028,41.61,32.051 M40.613,32.051
					c0-0.505-0.383-0.842-0.784-0.842c-0.421,0-0.803,0.337-0.803,0.842c0,0.511,0.382,0.842,0.803,0.842
					C40.23,32.893,40.613,32.562,40.613,32.051"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        d="M45.503,33.676h-1.036v-0.259c-0.252,0.228-0.59,0.356-1.01,0.356
					c-0.862,0-1.588-0.692-1.588-1.723c0-1.023,0.726-1.722,1.588-1.722c0.42,0,0.758,0.129,1.01,0.356v-1.671h1.036V33.676z
					 M44.467,32.051c0-0.498-0.376-0.842-0.796-0.842c-0.454,0-0.784,0.344-0.784,0.842c0,0.505,0.33,0.842,0.784,0.842
					C44.091,32.893,44.467,32.556,44.467,32.051"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#8A52A0"
        d="M20.033,37.74l-1.469,0.3c-0.109-0.571-0.581-0.853-1.062-0.853
					c-0.498,0-0.816,0.272-0.816,0.617c0,0.244,0.109,0.426,0.49,0.508l1.261,0.28c1.051,0.235,1.669,0.808,1.669,1.769
					c0,1.297-1.151,1.969-2.504,1.969c-1.369,0-2.493-0.653-2.657-1.842l1.542-0.299c0.154,0.625,0.59,0.897,1.197,0.897
					c0.563,0,0.897-0.254,0.897-0.626c0-0.271-0.127-0.444-0.562-0.534l-1.27-0.272c-0.872-0.199-1.632-0.652-1.632-1.76
					c0-1.179,0.979-1.914,2.403-1.914C18.846,35.98,19.807,36.635,20.033,37.74"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#8A52A0"
        d="M24.104,41.994c-0.354,0.19-0.725,0.336-1.241,0.336
					c-1.135,0-1.814-0.617-1.814-1.814v-1.723h-0.825v-1.152h0.825v-1.342h1.45v1.342h1.343v1.152h-1.343v1.578
					c0,0.471,0.228,0.662,0.607,0.662c0.2,0,0.473-0.073,0.663-0.182L24.104,41.994z"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#8A52A0"
        d="M29.174,42.193h-1.451V41.83c-0.354,0.318-0.825,0.499-1.415,0.499
					c-1.206,0-2.222-0.97-2.222-2.412c0-1.433,1.016-2.412,2.222-2.412c0.59,0,1.062,0.181,1.415,0.499v-0.363h1.451V42.193z
					 M27.723,39.917c0-0.698-0.525-1.179-1.115-1.179c-0.635,0-1.098,0.48-1.098,1.179c0,0.707,0.463,1.179,1.098,1.179
					C27.197,41.096,27.723,40.624,27.723,39.917"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#8A52A0"
        d="M34.732,41.739c0,1.406-1.07,2.177-2.548,2.177
					c-1.043,0-1.841-0.362-2.286-1.097l1.206-0.653c0.345,0.408,0.599,0.563,1.069,0.563c0.664,0,1.135-0.391,1.135-1.107v-0.127
					c-0.326,0.282-0.78,0.437-1.369,0.437c-1.189,0-2.195-0.943-2.195-2.231c0-1.261,1.006-2.193,2.195-2.193
					c0.589,0,1.043,0.154,1.369,0.434v-0.299h1.424V41.739z M33.309,39.699c0-0.607-0.481-1.033-1.062-1.033
					c-0.634,0-1.088,0.426-1.088,1.033s0.454,1.043,1.088,1.043C32.827,40.742,33.309,40.307,33.309,39.699"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#8A52A0"
        d="M40.072,40.289h-3.337c0.118,0.544,0.49,0.879,0.979,0.879
					c0.336,0,0.725-0.08,0.97-0.525l1.288,0.263c-0.39,0.962-1.215,1.424-2.258,1.424c-1.315,0-2.403-0.97-2.403-2.412
					c0-1.433,1.088-2.411,2.412-2.411c1.288,0,2.322,0.924,2.349,2.411V40.289z M36.763,39.4h1.877
					c-0.136-0.49-0.499-0.717-0.916-0.717C37.315,38.684,36.898,38.938,36.763,39.4"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#8A52A0"
        d="M44.824,38.248c1.342,0,2.14,0.771,2.14,1.959
					c0,1.252-0.907,2.123-2.303,2.123c-1.251,0-2.313-0.718-2.35-2.086l1.415-0.281c0.008,0.789,0.462,1.097,0.961,1.097
					c0.517,0,0.87-0.335,0.87-0.834c0-0.454-0.344-0.753-0.87-0.753h-2.086l0.109-3.355h3.99v1.297h-2.685l-0.037,0.834H44.824z"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        d="M31.52,21.445c0-1.647,1.34-2.987,2.987-2.987
					c1.027,0,1.935,0.521,2.474,1.313c0.948-1.521,0.766-3.547-0.557-4.869c-1.54-1.539-4.034-1.539-5.574,0
					c-1.539-1.539-4.033-1.539-5.574,0c-1.537,1.539-1.537,4.034,0,5.574l5.055,5.122c0.286,0.289,0.754,0.289,1.04,0l1.584-1.605
					C32.095,23.467,31.52,22.524,31.52,21.445 M27.961,14.99c-1.412,0-2.561,1.149-2.561,2.561c0,0.156-0.127,0.282-0.282,0.282
					c-0.156,0-0.282-0.126-0.282-0.282c0-1.723,1.402-3.125,3.125-3.125c0.156,0,0.282,0.126,0.282,0.282
					C28.243,14.864,28.117,14.99,27.961,14.99"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#DED3E8"
        d="M32.453,21.445c0,1.133,0.921,2.053,2.055,2.053
					c1.132,0,2.053-0.92,2.053-2.053s-0.921-2.053-2.053-2.053C33.374,19.392,32.453,20.313,32.453,21.445 M35.806,20.516
					c0.127,0.127,0.127,0.333,0,0.459l-1.414,1.415c-0.064,0.063-0.147,0.095-0.23,0.095s-0.166-0.032-0.229-0.095l-0.707-0.708
					c-0.127-0.126-0.127-0.332,0-0.459c0.127-0.126,0.332-0.126,0.459,0l0.478,0.478l1.186-1.185
					C35.474,20.389,35.68,20.389,35.806,20.516"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        d="M33.684,21.224c-0.126-0.127-0.333-0.127-0.459,0
					c-0.127,0.127-0.127,0.333,0,0.459l0.707,0.707c0.063,0.064,0.146,0.096,0.229,0.096s0.166-0.032,0.229-0.096l1.414-1.414
					c0.128-0.127,0.128-0.333,0-0.459c-0.125-0.127-0.331-0.127-0.459,0l-1.185,1.185L33.684,21.224z"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#663272"
        d="M38.629,24.77l-1.193-1.193c-0.157-0.158-0.392-0.194-0.585-0.108
					l-0.081-0.08l-0.262-0.262c0.384-0.455,0.616-1.042,0.616-1.682c0-1.443-1.174-2.617-2.617-2.617
					c-1.442,0-2.617,1.174-2.617,2.617c0,1.443,1.175,2.617,2.617,2.617c0.567,0,1.091-0.184,1.521-0.491l0.265,0.264l0.106,0.108
					c-0.064,0.185-0.025,0.4,0.122,0.548l1.193,1.193c0.206,0.206,0.539,0.206,0.744,0l0.171-0.171
					C38.835,25.308,38.835,24.975,38.629,24.77 M36.561,21.445c0,1.133-0.921,2.053-2.054,2.053c-1.132,0-2.054-0.92-2.054-2.053
					c0-1.133,0.922-2.053,2.054-2.053C35.64,19.392,36.561,20.312,36.561,21.445"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#4F86C6"
        d="M30.841,59.545c-2.639,0-4.773-2.136-4.773-4.771s2.135-4.772,4.773-4.772
					c2.638,0,4.772,2.137,4.772,4.772S33.479,59.545,30.841,59.545"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#FFFFFF"
        d="M30.841,50.001c2.638,0,4.772,2.136,4.772,4.772s-2.135,4.772-4.772,4.772
					c-2.639,0-4.773-2.136-4.773-4.772S28.202,50.001,30.841,50.001 M30.841,49.59c-2.859,0-5.186,2.325-5.186,5.184
					s2.326,5.184,5.186,5.184c2.858,0,5.185-2.325,5.185-5.184S33.699,49.59,30.841,49.59"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#FFFFFF"
        d="M33.334,52.828c-0.239,0-0.465,0.108-0.62,0.251l-0.013-0.012
					c0.072-0.119,0.168-0.346,0.168-0.716c0-0.883-0.884-1.551-2.028-1.551s-2.042,0.668-2.042,1.551c0,0.37,0.12,0.597,0.181,0.716
					l-0.014,0.012c-0.153-0.143-0.381-0.251-0.619-0.251c-0.729,0-1.456,0.704-1.456,2.018c0,1.288,0.717,2.016,1.491,2.016
					c0.716,0,1.218-0.395,1.67-0.847c-0.07-0.108-0.179-0.216-0.298-0.216s-0.251,0.085-0.476,0.264
					c-0.299,0.228-0.563,0.358-0.908,0.358c-0.609,0-0.87-0.683-0.87-1.575c0-0.955,0.25-1.587,0.896-1.587
					c0.286,0,0.476,0.083,0.668,0.262c0.286,0.263,0.381,0.334,0.512,0.334c0.096,0,0.156-0.06,0.179-0.095
					c-0.214-0.215-0.524-0.668-0.524-1.253c0-0.668,0.561-1.133,1.611-1.133c1.05,0,1.61,0.465,1.61,1.133
					c0,0.585-0.311,1.038-0.525,1.253c0.024,0.035,0.084,0.095,0.181,0.095c0.131,0,0.226-0.071,0.512-0.334
					c0.179-0.179,0.382-0.262,0.668-0.262c0.644,0,0.896,0.632,0.896,1.587c0,0.893-0.264,1.575-0.871,1.575
					c-0.346,0-0.619-0.131-0.907-0.358c-0.227-0.179-0.357-0.264-0.478-0.264c-0.119,0-0.226,0.107-0.298,0.216
					c0.441,0.452,0.955,0.847,1.67,0.847c0.776,0,1.493-0.728,1.493-2.016C34.791,53.532,34.063,52.828,33.334,52.828"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#FFFFFF"
        d="M31.64,52.805h-0.536c-0.036,0-0.06-0.023-0.071-0.048l-0.168-0.513
					c-0.013-0.035-0.023-0.035-0.035,0l-0.168,0.513c-0.012,0.036-0.035,0.048-0.06,0.048h-0.537c-0.024,0-0.035,0.012-0.013,0.023
					l0.442,0.323c0.023,0.022,0.023,0.06,0.012,0.083l-0.167,0.501c-0.011,0.023-0.011,0.048,0.024,0.023l0.441-0.309
					c0.036-0.025,0.06-0.025,0.096,0l0.43,0.309c0.023,0.024,0.035,0.012,0.023-0.011l-0.166-0.514
					c-0.014-0.035,0-0.071,0.023-0.083l0.44-0.323C31.675,52.816,31.663,52.805,31.64,52.805"
      />
      <path
        clipPath="url(#SVGID_18_)"
        fill="#FFFFFF"
        d="M31.234,56.336c-0.227-0.573-0.406-0.966-0.406-1.443
					c0-0.203,0.061-0.382,0.096-0.477c0.047-0.097,0.061-0.156,0.012-0.156c-0.071,0-0.227,0.167-0.321,0.312
					c-0.073,0.118-0.156,0.346-0.156,0.523c0,0.335,0.108,0.693,0.179,0.907c0.133,0.371,0.252,0.8,0.252,1.206
					c0,0.644-0.287,1.084-0.8,1.478c0.048,0.037,0.166,0.097,0.311,0.097c0.5,0,1.097-0.562,1.097-1.324
					C31.496,57.004,31.365,56.693,31.234,56.336"
      />
    </g>
  </g>
);

const FeelGoodStage4 = () => (
  <g id="Stage_4_3_">
    <polygon
      fill="#DED3E8"
      points="57.565,19.701 57.565,50.543 30.855,65.963 4.145,50.543 4.145,19.701 30.855,4.28 			"
    />
    <polygon
      fill="#B293BF"
      points="57.564,50.543 30.856,65.963 30.856,35.123 			"
    />
    <polygon
      fill="#B293BF"
      points="57.564,19.703 57.564,50.543 30.856,35.123 			"
    />
    <polygon
      fill="#B293BF"
      points="30.856,4.277 57.564,19.697 30.856,35.117 			"
    />
    <polygon
      fill="#B293BF"
      points="30.854,65.964 4.146,50.543 30.854,35.123 			"
    />
    <g>
      <defs>
        <rect
          id="SVGID_19_"
          x="0.012"
          y="0.018"
          width="61.687"
          height="70.204"
        />
      </defs>
      <clipPath id="SVGID_20_">
        <use xlinkHref="#SVGID_19_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_20_)"
        fill="#8A52A0"
        d="M30.855,4.594l26.437,15.263v30.527L30.855,65.647L4.418,50.384V19.856
					L30.855,4.594 M30.855,0.019c-0.573,0-1.145,0.148-1.658,0.445L1.67,16.355c-1.026,0.593-1.658,1.687-1.658,2.872v31.785
					c0,1.185,0.632,2.28,1.658,2.872l27.527,15.893c0.513,0.297,1.085,0.445,1.658,0.445c0.573,0,1.145-0.148,1.658-0.445
					L60.04,53.885c1.026-0.592,1.658-1.688,1.658-2.872V19.228c0-1.185-0.632-2.279-1.658-2.872L32.513,0.463
					C32,0.167,31.428,0.019,30.855,0.019"
      />
      <polygon
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        points="17.256,31.179 19.289,31.179 19.289,32.111 17.256,32.111
					17.256,33.666 16.188,33.666 16.188,29.327 19.542,29.327 19.542,30.266 17.256,30.266 				"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        d="M23.085,32.306h-2.384c0.085,0.388,0.35,0.628,0.7,0.628
					c0.239,0,0.518-0.058,0.693-0.376l0.919,0.188c-0.278,0.686-0.868,1.017-1.612,1.017c-0.939,0-1.716-0.694-1.716-1.723
					c0-1.023,0.777-1.722,1.722-1.722c0.92,0,1.658,0.66,1.678,1.722V32.306z M20.722,31.671h1.34
					c-0.097-0.35-0.356-0.511-0.654-0.511C21.116,31.16,20.818,31.341,20.722,31.671"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        d="M26.745,32.306h-2.384c0.085,0.388,0.35,0.628,0.7,0.628
					c0.239,0,0.518-0.058,0.693-0.376l0.919,0.188c-0.278,0.686-0.868,1.017-1.612,1.017c-0.939,0-1.716-0.694-1.716-1.723
					c0-1.023,0.777-1.722,1.722-1.722c0.92,0,1.658,0.66,1.678,1.722V32.306z M24.381,31.671h1.34
					c-0.097-0.35-0.356-0.511-0.654-0.511C24.775,31.16,24.478,31.341,24.381,31.671"
      />
      <rect
        x="27.217"
        y="29.003"
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        width="1.036"
        height="4.662"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        d="M33.727,33.342c0,1.004-0.766,1.554-1.821,1.554
					c-0.744,0-1.314-0.259-1.631-0.784l0.861-0.466c0.246,0.291,0.427,0.402,0.764,0.402c0.473,0,0.809-0.279,0.809-0.79v-0.092
					c-0.233,0.201-0.557,0.312-0.978,0.312c-0.847,0-1.567-0.674-1.567-1.593c0-0.9,0.72-1.567,1.567-1.567
					c0.421,0,0.745,0.11,0.978,0.311v-0.214h1.018V33.342z M32.709,31.885c0-0.434-0.343-0.738-0.758-0.738
					c-0.453,0-0.777,0.304-0.777,0.738c0,0.434,0.324,0.745,0.777,0.745C32.366,32.63,32.709,32.318,32.709,31.885"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        d="M37.786,32.04c0,1.03-0.815,1.716-1.78,1.716c-0.983,0-1.8-0.686-1.8-1.716
					c0-1.023,0.816-1.709,1.8-1.709C36.971,30.331,37.786,31.017,37.786,32.04 M36.789,32.04c0-0.505-0.382-0.842-0.783-0.842
					c-0.421,0-0.804,0.337-0.804,0.842c0,0.511,0.383,0.842,0.804,0.842C36.407,32.882,36.789,32.551,36.789,32.04"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        d="M41.627,32.04c0,1.03-0.816,1.716-1.781,1.716
					c-0.983,0-1.799-0.686-1.799-1.716c0-1.023,0.815-1.709,1.799-1.709C40.811,30.331,41.627,31.017,41.627,32.04 M40.63,32.04
					c0-0.505-0.382-0.842-0.784-0.842c-0.42,0-0.803,0.337-0.803,0.842c0,0.511,0.383,0.842,0.803,0.842
					C40.248,32.882,40.63,32.551,40.63,32.04"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        d="M45.52,33.665h-1.035v-0.259c-0.252,0.227-0.591,0.355-1.011,0.355
					c-0.862,0-1.587-0.691-1.587-1.722c0-1.023,0.725-1.722,1.587-1.722c0.42,0,0.759,0.129,1.011,0.356v-1.671h1.035V33.665z
					 M44.484,32.04c0-0.498-0.377-0.842-0.797-0.842c-0.453,0-0.783,0.344-0.783,0.842c0,0.505,0.33,0.842,0.783,0.842
					C44.107,32.882,44.484,32.545,44.484,32.04"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#8A52A0"
        d="M19.824,37.73l-1.469,0.299c-0.109-0.571-0.58-0.853-1.061-0.853
					c-0.499,0-0.816,0.272-0.816,0.617c0,0.244,0.109,0.426,0.49,0.508l1.26,0.28c1.052,0.236,1.669,0.808,1.669,1.769
					c0,1.298-1.152,1.968-2.503,1.968c-1.369,0-2.494-0.652-2.657-1.84l1.541-0.3c0.154,0.625,0.59,0.897,1.197,0.897
					c0.563,0,0.898-0.254,0.898-0.627c0-0.271-0.127-0.443-0.562-0.533l-1.27-0.273c-0.87-0.199-1.632-0.652-1.632-1.759
					c0-1.179,0.979-1.914,2.403-1.914C18.636,35.97,19.598,36.624,19.824,37.73"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#8A52A0"
        d="M23.895,41.982c-0.354,0.191-0.725,0.336-1.242,0.336
					c-1.134,0-1.814-0.617-1.814-1.813v-1.724h-0.825V37.63h0.825v-1.343h1.45v1.343h1.343v1.151h-1.343v1.578
					c0,0.471,0.228,0.662,0.608,0.662c0.2,0,0.472-0.072,0.663-0.181L23.895,41.982z"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#8A52A0"
        d="M28.964,42.182h-1.451v-0.363c-0.354,0.318-0.825,0.5-1.414,0.5
					c-1.207,0-2.223-0.971-2.223-2.412c0-1.434,1.016-2.412,2.223-2.412c0.589,0,1.06,0.181,1.414,0.499V37.63h1.451V42.182z
					 M27.513,39.906c0-0.698-0.526-1.18-1.115-1.18c-0.635,0-1.098,0.481-1.098,1.18c0,0.707,0.463,1.179,1.098,1.179
					C26.987,41.085,27.513,40.613,27.513,39.906"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#8A52A0"
        d="M34.522,41.729c0,1.406-1.069,2.177-2.548,2.177
					c-1.043,0-1.841-0.362-2.286-1.097l1.206-0.654c0.345,0.409,0.599,0.563,1.07,0.563c0.663,0,1.134-0.39,1.134-1.107v-0.127
					c-0.326,0.282-0.78,0.437-1.369,0.437c-1.189,0-2.195-0.943-2.195-2.231c0-1.261,1.006-2.194,2.195-2.194
					c0.589,0,1.043,0.154,1.369,0.435v-0.3h1.424V41.729z M33.099,39.688c0-0.608-0.481-1.034-1.061-1.034
					c-0.634,0-1.088,0.426-1.088,1.034c0,0.606,0.454,1.043,1.088,1.043C32.618,40.731,33.099,40.295,33.099,39.688"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#8A52A0"
        d="M39.863,40.278h-3.338c0.119,0.544,0.49,0.879,0.979,0.879
					c0.336,0,0.726-0.081,0.97-0.526l1.288,0.264c-0.39,0.962-1.215,1.424-2.258,1.424c-1.315,0-2.403-0.971-2.403-2.412
					c0-1.434,1.088-2.412,2.412-2.412c1.288,0,2.322,0.926,2.35,2.412V40.278z M36.553,39.389h1.877
					c-0.136-0.489-0.499-0.717-0.916-0.717C37.105,38.672,36.689,38.926,36.553,39.389"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#8A52A0"
        d="M46.555,41.067v1.115h-1.406v-1.115h-3.156v-1.343l2.713-3.618h1.85v3.763
					h0.734v1.198H46.555z M45.148,37.549l-1.641,2.32h1.641V37.549z"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        d="M31.535,21.434c0-1.647,1.34-2.987,2.988-2.987
					c1.027,0,1.934,0.521,2.472,1.313c0.949-1.521,0.767-3.547-0.555-4.869c-1.54-1.539-4.035-1.539-5.574,0
					c-1.539-1.539-4.035-1.539-5.574,0c-1.538,1.539-1.538,4.034,0,5.574l5.054,5.122c0.286,0.289,0.753,0.289,1.039,0l1.584-1.605
					C32.111,23.456,31.535,22.513,31.535,21.434 M27.978,14.979c-1.412,0-2.562,1.149-2.562,2.561c0,0.156-0.127,0.282-0.282,0.282
					c-0.156,0-0.282-0.126-0.282-0.282c0-1.723,1.402-3.125,3.125-3.125c0.155,0,0.282,0.126,0.282,0.282
					C28.259,14.853,28.132,14.979,27.978,14.979"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#DED3E8"
        d="M32.47,21.435c0,1.133,0.921,2.053,2.054,2.053s2.053-0.92,2.053-2.053
					c0-1.133-0.92-2.053-2.053-2.053S32.47,20.301,32.47,21.435 M35.822,20.505c0.125,0.127,0.125,0.333,0,0.459l-1.416,1.415
					c-0.063,0.063-0.146,0.095-0.229,0.095c-0.082,0-0.165-0.032-0.229-0.095l-0.707-0.708c-0.127-0.126-0.127-0.332,0-0.459
					c0.127-0.126,0.332-0.126,0.459,0l0.478,0.478l1.185-1.185C35.488,20.378,35.695,20.378,35.822,20.505"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        d="M33.699,21.213c-0.127-0.127-0.332-0.127-0.459,0s-0.127,0.333,0,0.459
					l0.707,0.707c0.064,0.064,0.147,0.096,0.23,0.096s0.166-0.032,0.229-0.096l1.416-1.414c0.127-0.127,0.127-0.333,0-0.459
					c-0.127-0.127-0.332-0.127-0.459,0l-1.186,1.185L33.699,21.213z"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#663272"
        d="M38.645,24.759l-1.193-1.193c-0.157-0.158-0.392-0.194-0.584-0.108
					l-0.08-0.08l-0.263-0.262c0.384-0.455,0.615-1.042,0.615-1.682c0-1.443-1.174-2.617-2.616-2.617
					c-1.443,0-2.617,1.174-2.617,2.617c0,1.443,1.173,2.617,2.617,2.617c0.566,0,1.09-0.184,1.52-0.491l0.265,0.264l0.106,0.108
					c-0.064,0.185-0.025,0.4,0.123,0.548l1.193,1.193c0.205,0.206,0.538,0.206,0.743,0l0.171-0.171
					C38.851,25.297,38.851,24.964,38.645,24.759 M36.576,21.435c0,1.133-0.92,2.053-2.053,2.053c-1.132,0-2.054-0.92-2.054-2.053
					c0-1.133,0.922-2.053,2.054-2.053C35.656,19.381,36.576,20.301,36.576,21.435"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#4F86C6"
        d="M30.856,59.534c-2.638,0-4.773-2.136-4.773-4.771
					c0-2.637,2.135-4.772,4.773-4.772s4.774,2.136,4.774,4.772C35.631,57.398,33.494,59.534,30.856,59.534"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#FFFFFF"
        d="M30.856,49.99c2.638,0,4.774,2.135,4.774,4.772
					c0,2.636-2.137,4.771-4.774,4.771s-4.773-2.135-4.773-4.771C26.083,52.125,28.219,49.99,30.856,49.99 M30.856,49.578
					c-2.859,0-5.184,2.326-5.184,5.185s2.325,5.183,5.184,5.183c2.86,0,5.187-2.324,5.187-5.183S33.717,49.578,30.856,49.578"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#FFFFFF"
        d="M33.35,52.817c-0.238,0-0.464,0.108-0.62,0.251l-0.013-0.012
					c0.073-0.119,0.169-0.346,0.169-0.717c0-0.883-0.884-1.551-2.029-1.551s-2.041,0.668-2.041,1.551
					c0,0.371,0.12,0.598,0.179,0.717l-0.012,0.012c-0.155-0.143-0.382-0.251-0.62-0.251c-0.729,0-1.456,0.704-1.456,2.018
					c0,1.288,0.716,2.016,1.492,2.016c0.716,0,1.217-0.396,1.67-0.847c-0.072-0.108-0.18-0.217-0.298-0.217
					c-0.119,0-0.252,0.086-0.477,0.265c-0.299,0.227-0.562,0.357-0.908,0.357c-0.609,0-0.87-0.682-0.87-1.574
					c0-0.955,0.251-1.587,0.895-1.587c0.286,0,0.476,0.082,0.668,0.262c0.286,0.263,0.381,0.334,0.512,0.334
					c0.096,0,0.156-0.061,0.18-0.096c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.668,0.56-1.134,1.611-1.134
					c1.05,0,1.611,0.466,1.611,1.134c0,0.584-0.311,1.037-0.525,1.252c0.023,0.035,0.083,0.096,0.179,0.096
					c0.131,0,0.226-0.071,0.512-0.334c0.179-0.18,0.382-0.262,0.668-0.262c0.646,0,0.896,0.632,0.896,1.587
					c0,0.893-0.263,1.574-0.871,1.574c-0.346,0-0.62-0.131-0.907-0.357c-0.227-0.179-0.358-0.265-0.477-0.265
					c-0.12,0-0.227,0.108-0.299,0.217c0.441,0.451,0.955,0.847,1.67,0.847c0.775,0,1.492-0.728,1.492-2.016
					C34.807,53.521,34.078,52.817,33.35,52.817"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#FFFFFF"
        d="M31.656,52.794h-0.537c-0.035,0-0.059-0.024-0.071-0.048l-0.168-0.514
					c-0.012-0.034-0.023-0.034-0.035,0l-0.167,0.514c-0.012,0.036-0.036,0.048-0.06,0.048h-0.537c-0.024,0-0.035,0.011-0.012,0.023
					l0.442,0.322c0.023,0.023,0.023,0.061,0.011,0.083l-0.166,0.501c-0.012,0.024-0.012,0.049,0.024,0.024l0.441-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.024,0.024,0.036,0.012,0.024-0.012l-0.167-0.514
					c-0.012-0.034,0-0.07,0.024-0.083l0.44-0.322C31.692,52.805,31.68,52.794,31.656,52.794"
      />
      <path
        clipPath="url(#SVGID_20_)"
        fill="#FFFFFF"
        d="M31.251,56.324c-0.228-0.572-0.406-0.966-0.406-1.442
					c0-0.203,0.06-0.382,0.096-0.478c0.047-0.096,0.061-0.155,0.011-0.155c-0.071,0-0.226,0.167-0.322,0.311
					c-0.072,0.119-0.155,0.347-0.155,0.524c0,0.335,0.108,0.692,0.179,0.906c0.132,0.371,0.251,0.801,0.251,1.205
					c0,0.646-0.287,1.086-0.8,1.479c0.048,0.037,0.167,0.096,0.311,0.096c0.5,0,1.097-0.561,1.097-1.324
					C31.513,56.993,31.382,56.683,31.251,56.324"
      />
    </g>
  </g>
);

const FeelGoodStage3 = () => (
  <g id="Stage_3_3_">
    <polygon
      fill="#DED3E8"
      points="57.564,19.686 57.564,50.527 30.856,65.948 4.146,50.527 4.146,19.686 30.856,4.265 			"
    />
    <polygon
      fill="#B293BF"
      points="57.564,50.527 30.857,65.948 30.857,35.107 			"
    />
    <polygon
      fill="#B293BF"
      points="57.564,19.688 57.564,50.527 30.857,35.107 			"
    />
    <polygon
      fill="#B293BF"
      points="30.856,4.261 57.564,19.682 30.856,35.102 			"
    />
    <g>
      <defs>
        <rect
          id="SVGID_21_"
          x="0.013"
          y="0.018"
          width="61.687"
          height="70.204"
        />
      </defs>
      <clipPath id="SVGID_22_">
        <use xlinkHref="#SVGID_21_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_22_)"
        fill="#8A52A0"
        d="M30.857,4.594l26.437,15.263v30.528L30.857,65.647L4.419,50.385V19.856
					L30.857,4.594 M30.857,0.019c-0.573,0-1.146,0.148-1.658,0.445L1.671,16.355c-1.027,0.593-1.658,1.687-1.658,2.872v31.786
					c0,1.186,0.631,2.279,1.658,2.872l27.527,15.892c0.513,0.297,1.085,0.445,1.658,0.445c0.573,0,1.145-0.148,1.659-0.445
					l27.526-15.892c1.027-0.593,1.658-1.687,1.658-2.872V19.228c0-1.185-0.631-2.279-1.658-2.872L32.516,0.463
					C32.001,0.167,31.43,0.019,30.857,0.019"
      />
      <polygon
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        points="17.257,31.179 19.291,31.179 19.291,32.111 17.257,32.111
					17.257,33.666 16.189,33.666 16.189,29.327 19.543,29.327 19.543,30.266 17.257,30.266 				"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        d="M23.086,32.307h-2.384c0.085,0.387,0.35,0.627,0.7,0.627
					c0.239,0,0.518-0.058,0.693-0.375l0.919,0.188c-0.278,0.686-0.868,1.017-1.612,1.017c-0.939,0-1.716-0.694-1.716-1.723
					c0-1.023,0.777-1.722,1.723-1.722c0.919,0,1.657,0.66,1.677,1.722V32.307z M20.722,31.671h1.34
					c-0.097-0.35-0.356-0.511-0.653-0.511C21.118,31.16,20.819,31.341,20.722,31.671"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        d="M26.746,32.307h-2.384c0.085,0.387,0.35,0.627,0.7,0.627
					c0.239,0,0.518-0.058,0.692-0.375l0.92,0.188c-0.278,0.686-0.868,1.017-1.612,1.017c-0.939,0-1.716-0.694-1.716-1.723
					c0-1.023,0.777-1.722,1.723-1.722c0.919,0,1.657,0.66,1.677,1.722V32.307z M24.382,31.671h1.34
					c-0.097-0.35-0.356-0.511-0.653-0.511C24.777,31.16,24.479,31.341,24.382,31.671"
      />
      <rect
        x="27.218"
        y="29.003"
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        width="1.036"
        height="4.662"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        d="M33.728,33.342c0,1.004-0.765,1.554-1.82,1.554
					c-0.745,0-1.314-0.259-1.632-0.784l0.861-0.466c0.247,0.291,0.428,0.402,0.765,0.402c0.472,0,0.81-0.279,0.81-0.79v-0.091
					c-0.233,0.2-0.557,0.311-0.979,0.311c-0.848,0-1.567-0.674-1.567-1.593c0-0.9,0.719-1.567,1.567-1.567
					c0.421,0,0.745,0.11,0.979,0.311v-0.214h1.017V33.342z M32.71,31.885c0-0.434-0.343-0.738-0.758-0.738
					c-0.453,0-0.777,0.304-0.777,0.738c0,0.434,0.324,0.745,0.777,0.745C32.367,32.63,32.71,32.318,32.71,31.885"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        d="M37.788,32.04c0,1.03-0.816,1.716-1.781,1.716
					c-0.984,0-1.799-0.686-1.799-1.716c0-1.023,0.814-1.709,1.799-1.709C36.972,30.331,37.788,31.017,37.788,32.04 M36.79,32.04
					c0-0.505-0.381-0.842-0.783-0.842c-0.422,0-0.803,0.337-0.803,0.842c0,0.511,0.381,0.842,0.803,0.842
					C36.409,32.882,36.79,32.551,36.79,32.04"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        d="M41.628,32.04c0,1.03-0.816,1.716-1.779,1.716
					c-0.985,0-1.801-0.686-1.801-1.716c0-1.023,0.815-1.709,1.801-1.709C40.812,30.331,41.628,31.017,41.628,32.04 M40.632,32.04
					c0-0.505-0.383-0.842-0.783-0.842c-0.422,0-0.805,0.337-0.805,0.842c0,0.511,0.383,0.842,0.805,0.842
					C40.249,32.882,40.632,32.551,40.632,32.04"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        d="M45.521,33.665h-1.035v-0.259c-0.252,0.227-0.59,0.355-1.01,0.355
					c-0.863,0-1.586-0.691-1.586-1.722c0-1.023,0.723-1.722,1.586-1.722c0.42,0,0.758,0.129,1.01,0.356v-1.671h1.035V33.665z
					 M44.485,32.04c0-0.498-0.377-0.842-0.797-0.842c-0.453,0-0.783,0.344-0.783,0.842c0,0.505,0.33,0.842,0.783,0.842
					C44.108,32.882,44.485,32.545,44.485,32.04"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#8A52A0"
        d="M20.047,37.73l-1.469,0.299c-0.109-0.571-0.581-0.853-1.061-0.853
					c-0.499,0-0.816,0.272-0.816,0.617c0,0.244,0.108,0.426,0.489,0.508l1.261,0.28c1.052,0.236,1.669,0.809,1.669,1.77
					c0,1.297-1.152,1.967-2.504,1.967c-1.368,0-2.493-0.652-2.657-1.84l1.542-0.3c0.154,0.625,0.59,0.897,1.197,0.897
					c0.562,0,0.898-0.254,0.898-0.626c0-0.271-0.127-0.444-0.562-0.535l-1.27-0.272c-0.87-0.199-1.632-0.651-1.632-1.759
					c0-1.179,0.979-1.914,2.403-1.914C18.859,35.97,19.82,36.624,20.047,37.73"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#8A52A0"
        d="M24.118,41.982c-0.354,0.191-0.725,0.336-1.242,0.336
					c-1.134,0-1.814-0.616-1.814-1.813v-1.724h-0.825V37.63h0.825v-1.342h1.451v1.342h1.342v1.151h-1.342v1.578
					c0,0.472,0.227,0.662,0.608,0.662c0.199,0,0.471-0.072,0.662-0.182L24.118,41.982z"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#8A52A0"
        d="M29.187,42.182h-1.451v-0.362c-0.353,0.317-0.825,0.499-1.414,0.499
					c-1.207,0-2.222-0.971-2.222-2.412c0-1.434,1.015-2.412,2.222-2.412c0.589,0,1.061,0.182,1.414,0.499v-0.362h1.451V42.182z
					 M27.736,39.906c0-0.698-0.526-1.179-1.115-1.179c-0.635,0-1.097,0.48-1.097,1.179c0,0.707,0.462,1.18,1.097,1.18
					C27.21,41.086,27.736,40.613,27.736,39.906"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#8A52A0"
        d="M34.746,41.729c0,1.406-1.07,2.178-2.548,2.178
					c-1.043,0-1.841-0.363-2.286-1.098l1.206-0.654c0.345,0.409,0.599,0.563,1.07,0.563c0.663,0,1.133-0.39,1.133-1.106v-0.127
					c-0.325,0.281-0.779,0.436-1.368,0.436c-1.189,0-2.195-0.943-2.195-2.231c0-1.261,1.006-2.194,2.195-2.194
					c0.589,0,1.043,0.154,1.368,0.435V37.63h1.425V41.729z M33.321,39.688c0-0.608-0.48-1.034-1.06-1.034
					c-0.635,0-1.088,0.426-1.088,1.034c0,0.607,0.453,1.043,1.088,1.043C32.841,40.731,33.321,40.296,33.321,39.688"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#8A52A0"
        d="M40.086,40.278h-3.337c0.118,0.544,0.49,0.879,0.979,0.879
					c0.336,0,0.727-0.081,0.971-0.526l1.287,0.264c-0.389,0.962-1.215,1.424-2.258,1.424c-1.314,0-2.402-0.97-2.402-2.412
					c0-1.433,1.088-2.412,2.412-2.412c1.287,0,2.322,0.926,2.349,2.412V40.278z M36.776,39.39h1.877
					c-0.137-0.49-0.5-0.718-0.916-0.718C37.329,38.672,36.912,38.926,36.776,39.39"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#8A52A0"
        d="M46.959,40.359c0,1.152-0.935,1.959-2.267,1.959
					c-1.279,0-2.213-0.744-2.35-1.923l1.415-0.262c0.081,0.598,0.408,0.942,0.942,0.942c0.509,0,0.871-0.291,0.871-0.734
					c0-0.398-0.299-0.635-0.824-0.635h-0.816v-1.188h0.752c0.518,0,0.816-0.226,0.816-0.616c0-0.418-0.316-0.697-0.807-0.697
					s-0.854,0.298-0.907,0.979l-1.369-0.263c0.136-1.207,1.034-1.95,2.267-1.95c1.297,0,2.178,0.798,2.178,1.913
					c0,0.554-0.236,0.979-0.654,1.226C46.687,39.354,46.959,39.787,46.959,40.359"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        d="M31.537,21.434c0-1.647,1.339-2.987,2.987-2.987
					c1.026,0,1.935,0.521,2.474,1.313c0.947-1.521,0.766-3.547-0.557-4.869c-1.54-1.539-4.035-1.539-5.574,0
					c-1.539-1.539-4.034-1.539-5.573,0s-1.539,4.034,0,5.574l5.053,5.122c0.286,0.289,0.754,0.289,1.04,0l1.583-1.605
					C32.111,23.456,31.537,22.513,31.537,21.434 M27.978,14.979c-1.412,0-2.561,1.149-2.561,2.561c0,0.156-0.127,0.282-0.283,0.282
					c-0.155,0-0.281-0.126-0.281-0.282c0-1.723,1.402-3.125,3.125-3.125c0.155,0,0.282,0.126,0.282,0.282
					C28.259,14.853,28.132,14.979,27.978,14.979"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#DED3E8"
        d="M32.47,21.435c0,1.133,0.922,2.053,2.055,2.053
					c1.131,0,2.053-0.92,2.053-2.053s-0.922-2.053-2.053-2.053C33.392,19.381,32.47,20.302,32.47,21.435 M35.821,20.505
					c0.127,0.127,0.127,0.333,0,0.459l-1.414,1.415c-0.063,0.063-0.146,0.095-0.229,0.095s-0.166-0.032-0.229-0.095l-0.707-0.708
					c-0.127-0.126-0.127-0.332,0-0.459c0.127-0.126,0.332-0.126,0.459,0l0.478,0.478l1.185-1.185
					C35.489,20.378,35.694,20.378,35.821,20.505"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        d="M33.7,21.213c-0.127-0.127-0.334-0.127-0.459,0
					c-0.127,0.127-0.127,0.333,0,0.459l0.707,0.707c0.063,0.064,0.146,0.096,0.229,0.096s0.166-0.032,0.229-0.096l1.414-1.414
					c0.127-0.127,0.127-0.333,0-0.459c-0.127-0.127-0.332-0.127-0.459,0l-1.185,1.185L33.7,21.213z"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#663272"
        d="M38.645,24.759l-1.192-1.193c-0.158-0.158-0.393-0.194-0.586-0.108
					l-0.08-0.08l-0.262-0.262c0.383-0.455,0.615-1.042,0.615-1.682c0-1.443-1.174-2.617-2.615-2.617
					c-1.443,0-2.618,1.174-2.618,2.617c0,1.443,1.174,2.617,2.618,2.617c0.564,0,1.09-0.184,1.518-0.491l0.266,0.264l0.107,0.108
					c-0.065,0.185-0.026,0.4,0.123,0.548l1.193,1.193c0.204,0.206,0.537,0.206,0.742,0l0.171-0.171
					C38.851,25.297,38.851,24.964,38.645,24.759 M36.577,21.435c0,1.133-0.922,2.053-2.053,2.053c-1.133,0-2.055-0.92-2.055-2.053
					c0-1.133,0.922-2.053,2.055-2.053C35.655,19.381,36.577,20.301,36.577,21.435"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#4F86C6"
        d="M30.857,59.534c-2.638,0-4.773-2.136-4.773-4.771s2.135-4.772,4.773-4.772
					s4.773,2.137,4.773,4.772S33.495,59.534,30.857,59.534"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#FFFFFF"
        d="M30.857,49.99c2.638,0,4.773,2.136,4.773,4.772
					c0,2.636-2.135,4.772-4.773,4.772s-4.773-2.137-4.773-4.772C26.083,52.126,28.219,49.99,30.857,49.99 M30.857,49.579
					c-2.859,0-5.185,2.325-5.185,5.184s2.326,5.184,5.185,5.184c2.859,0,5.185-2.325,5.185-5.184S33.716,49.579,30.857,49.579"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#FFFFFF"
        d="M33.351,52.817c-0.239,0-0.465,0.108-0.621,0.251l-0.013-0.012
					c0.072-0.119,0.168-0.346,0.168-0.716c0-0.883-0.883-1.551-2.028-1.551s-2.041,0.668-2.041,1.551c0,0.37,0.12,0.597,0.179,0.716
					l-0.012,0.012c-0.155-0.143-0.382-0.251-0.62-0.251c-0.729,0-1.456,0.704-1.456,2.018c0,1.288,0.716,2.016,1.492,2.016
					c0.716,0,1.217-0.395,1.67-0.847c-0.072-0.108-0.18-0.216-0.299-0.216c-0.118,0-0.251,0.085-0.476,0.264
					c-0.299,0.228-0.562,0.358-0.909,0.358c-0.608,0-0.869-0.683-0.869-1.575c0-0.955,0.25-1.587,0.895-1.587
					c0.286,0,0.476,0.083,0.668,0.262c0.286,0.263,0.381,0.334,0.512,0.334c0.096,0,0.156-0.06,0.179-0.095
					c-0.214-0.215-0.523-0.668-0.523-1.253c0-0.668,0.56-1.133,1.611-1.133c1.049,0,1.61,0.465,1.61,1.133
					c0,0.585-0.31,1.038-0.524,1.253c0.023,0.035,0.083,0.095,0.178,0.095c0.132,0,0.227-0.071,0.513-0.334
					c0.179-0.179,0.382-0.262,0.667-0.262c0.646,0,0.896,0.632,0.896,1.587c0,0.893-0.263,1.575-0.87,1.575
					c-0.346,0-0.621-0.131-0.908-0.358c-0.226-0.179-0.357-0.264-0.476-0.264c-0.12,0-0.227,0.107-0.299,0.216
					c0.441,0.452,0.955,0.847,1.67,0.847c0.776,0,1.491-0.728,1.491-2.016C34.806,53.521,34.078,52.817,33.351,52.817"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#FFFFFF"
        d="M31.656,52.794h-0.537c-0.036,0-0.059-0.023-0.071-0.048l-0.168-0.513
					c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.513c-0.012,0.036-0.035,0.048-0.059,0.048H30.08c-0.023,0-0.035,0.011-0.012,0.023
					l0.443,0.323c0.023,0.022,0.023,0.06,0.011,0.083l-0.167,0.501c-0.011,0.023-0.011,0.048,0.025,0.023l0.441-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.024,0.024,0.035,0.012,0.024-0.012l-0.167-0.513
					c-0.013-0.035,0-0.071,0.023-0.083l0.441-0.323C31.692,52.805,31.679,52.794,31.656,52.794"
      />
      <path
        clipPath="url(#SVGID_22_)"
        fill="#FFFFFF"
        d="M31.25,56.325c-0.226-0.573-0.406-0.966-0.406-1.443
					c0-0.203,0.06-0.382,0.096-0.477c0.048-0.097,0.06-0.156,0.011-0.156c-0.071,0-0.227,0.167-0.321,0.311
					c-0.073,0.119-0.156,0.347-0.156,0.524c0,0.335,0.108,0.693,0.179,0.907c0.132,0.371,0.251,0.8,0.251,1.205
					c0,0.645-0.287,1.085-0.799,1.479c0.046,0.037,0.167,0.097,0.31,0.097c0.5,0,1.097-0.562,1.097-1.324
					C31.512,56.993,31.381,56.683,31.25,56.325"
      />
    </g>
  </g>
);
// #endregion

const FirstAid = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <FirstAidStage3 {...otherProps} />;
    case 4:
      return <FirstAidStage4 {...otherProps} />;
    case 5:
      return <FirstAidStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

const FeelGood = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <FeelGoodStage3 {...otherProps} />;
    case 4:
      return <FeelGoodStage4 {...otherProps} />;
    case 5:
      return <FeelGoodStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

function BeWell(props: any) {
  const { skill, stage, fallback = null, ...otherProps } = props;

  switch (skill) {
    case 'First aid':
      return <FirstAid stage={stage} {...otherProps} fallback={fallback} />;
    case 'Feel Good':
      return <FeelGood stage={stage} {...otherProps} fallback={fallback} />;
    default:
      return fallback;
  }
}

export default BeWell;
