import { TextAlign } from '../../constants';
import React, { HTMLProps } from 'react';
import { randomString } from 'utilities/string';
import './dots.scss';

type DotsProps = HTMLProps<HTMLDivElement> & {
  count: number;
  activeColor?: string;
  inactiveColor?: string;
  selectedIndexes?: number[];
  align?: TextAlign;
  onDotClick?: (index: number) => void;
};

function Dots(props: DotsProps) {
  const {
    className,
    count,
    activeColor,
    inactiveColor,
    selectedIndexes = [],
    align = TextAlign.CENTER,
    onDotClick,
    ...otherProps
  } = props;

  const dotsArray = [...new Array(count)];
  const groupClasses = ['dot-group', `dot-group--${align}`];

  if (typeof onDotClick === 'function')
    groupClasses.push('dot-group--clickable');
  if (className) groupClasses.push(className);

  return (
    <div {...otherProps} className={groupClasses.join(' ')}>
      {dotsArray.map((_, index) => {
        let dotClassName = 'dot';
        if (selectedIndexes.includes(index)) {
          dotClassName += ' dot--active';
          dotClassName += activeColor ? ` bg-${activeColor}` : ' bg-light';
        } else {
          dotClassName += inactiveColor ? ` bg-${inactiveColor}` : ' bg-light';
        }

        return (
          <div
            key={randomString()}
            tabIndex={-1}
            role="button"
            className={dotClassName}
            onClick={onDotClick ? () => onDotClick(index) : undefined}
          />
        );
      })}
    </div>
  );
}

export default Dots;
