import React, { HTMLProps } from 'react';

import Typography, {
  BaseTypographyProps as TypographyProps
} from './Typography';

type HeadingProps = TypographyProps &
  HTMLProps<HeadingProps> & {
    size: 1 | 2 | 3 | 4 | 5 | 6;
  };

function Heading(props: HeadingProps) {
  const { children, size, ...otherProps } = props;
  return (
    <Typography {...otherProps} as={`h${size}`}>
      {children}
    </Typography>
  );
}

export default Heading;
