// #startregion Network Groups
const NetworkStage5 = () => (
  <g id="Stage_5_6_">
    <polygon
      fill="#ABD1E2"
      points="57.574,19.729 57.574,50.57 30.864,65.99 4.154,50.57 4.154,19.729 30.864,4.307 			"
    />
    <polygon fill="#60C1E5" points="57.574,50.57 30.866,65.99 30.866,35.15 			" />
    <polygon fill="#60C1E5" points="57.574,19.73 57.574,50.57 30.866,35.15 			" />
    <polygon
      fill="#60C1E5"
      points="30.865,4.304 57.573,19.724 30.865,35.144 			"
    />
    <polygon fill="#60C1E5" points="30.863,65.992 4.155,50.572 30.863,35.15 			" />
    <polygon fill="#60C1E5" points="30.865,35.15 4.158,50.57 4.158,19.73 			" />
    <g>
      <path
        fill="#00ADEE"
        d="M30.864,4.62l26.438,15.263V50.41L30.864,65.676L4.428,50.41V19.883L30.864,4.62 M30.864,0.045
    c-0.573,0-1.145,0.148-1.658,0.444L1.68,16.382c-1.027,0.593-1.658,1.687-1.658,2.872v31.787c0,1.186,0.631,2.279,1.658,2.871
    l27.526,15.895c0.514,0.295,1.085,0.443,1.658,0.443s1.146-0.148,1.658-0.443L60.05,53.912c1.026-0.592,1.657-1.686,1.657-2.871
    V19.254c0-1.185-0.631-2.279-1.657-2.872L32.522,0.489C32.01,0.193,31.438,0.045,30.864,0.045"
      />
      <polygon
        fill="#005890"
        points="21.973,33.691 21.093,33.691 18.896,31.18 18.896,33.691 17.829,33.691 17.829,29.354
    18.716,29.354 20.911,31.943 20.911,29.354 21.973,29.354 				"
      />
      <path
        fill="#005890"
        d="M25.854,32.333H23.47c0.084,0.388,0.351,0.628,0.699,0.628c0.239,0,0.519-0.059,0.693-0.376l0.919,0.188
    c-0.278,0.687-0.868,1.017-1.612,1.017c-0.938,0-1.716-0.693-1.716-1.723c0-1.023,0.777-1.722,1.723-1.722
    c0.919,0,1.657,0.66,1.678,1.722V32.333z M23.489,31.697h1.34c-0.097-0.35-0.355-0.511-0.653-0.511
    C23.884,31.187,23.586,31.367,23.489,31.697"
      />
      <path
        fill="#005890"
        d="M28.734,33.549c-0.252,0.137-0.518,0.24-0.887,0.24c-0.809,0-1.295-0.44-1.295-1.294v-1.231h-0.589
    v-0.822h0.589v-0.959h1.036v0.959h0.958v0.822h-0.958v1.127c0,0.337,0.162,0.472,0.434,0.472c0.143,0,0.336-0.051,0.474-0.129
    L28.734,33.549z"
      />
      <polygon
        fill="#005890"
        points="32.75,33.698 31.851,33.698 31.268,31.937 30.679,33.698 29.778,33.698 28.735,30.441
    29.752,30.441 30.283,32.189 30.898,30.441 31.63,30.441 32.245,32.196 32.776,30.441 33.792,30.441 				"
      />
      <path
        fill="#005890"
        d="M37.446,32.066c0,1.03-0.816,1.716-1.781,1.716c-0.984,0-1.799-0.686-1.799-1.716
    c0-1.023,0.814-1.709,1.799-1.709C36.63,30.357,37.446,31.043,37.446,32.066 M36.449,32.066c0-0.505-0.382-0.841-0.784-0.841
    c-0.421,0-0.803,0.336-0.803,0.841c0,0.511,0.382,0.842,0.803,0.842C36.067,32.908,36.449,32.578,36.449,32.066"
      />
      <path
        fill="#005890"
        d="M40.31,30.422l-0.046,1.036h-0.188c-0.726,0-1.12,0.376-1.12,1.217v1.017h-1.037v-3.25h1.037v0.622
    c0.233-0.389,0.588-0.667,1.12-0.667C40.167,30.396,40.231,30.402,40.31,30.422"
      />
      <polygon
        fill="#005890"
        points="42.964,33.691 41.909,32.52 41.722,32.52 41.722,33.691 40.686,33.691 40.686,29.03
    41.722,29.03 41.722,31.549 41.863,31.549 42.894,30.441 44.143,30.441 42.719,31.989 44.292,33.691 				"
      />
      <path
        fill="#007DC4"
        d="M20.06,37.757l-1.469,0.299c-0.109-0.571-0.58-0.853-1.062-0.853c-0.499,0-0.815,0.272-0.815,0.617
    c0,0.244,0.108,0.426,0.489,0.508l1.261,0.281c1.052,0.235,1.669,0.808,1.669,1.769c0,1.297-1.152,1.968-2.503,1.968
    c-1.369,0-2.494-0.653-2.657-1.841l1.541-0.3c0.153,0.626,0.59,0.897,1.196,0.897c0.563,0,0.898-0.254,0.898-0.625
    c0-0.272-0.127-0.444-0.562-0.536l-1.271-0.271c-0.87-0.201-1.632-0.653-1.632-1.761c0-1.179,0.979-1.914,2.403-1.914
    C18.871,35.996,19.833,36.65,20.06,37.757"
      />
      <path
        fill="#007DC4"
        d="M24.131,42.01c-0.354,0.191-0.726,0.336-1.242,0.336c-1.134,0-1.813-0.617-1.813-1.814v-1.724H20.25
    v-1.15h0.825v-1.342h1.45v1.342h1.343v1.15h-1.343v1.578c0,0.472,0.228,0.662,0.608,0.662c0.199,0,0.471-0.072,0.662-0.181
    L24.131,42.01z"
      />
      <path
        fill="#007DC4"
        d="M29.2,42.211h-1.451v-0.363c-0.354,0.316-0.825,0.498-1.414,0.498c-1.207,0-2.223-0.972-2.223-2.413
    c0-1.434,1.016-2.412,2.223-2.412c0.589,0,1.061,0.182,1.414,0.499v-0.362H29.2V42.211z M27.749,39.933
    c0-0.698-0.526-1.178-1.115-1.178c-0.635,0-1.098,0.479-1.098,1.178c0,0.707,0.463,1.18,1.098,1.18
    C27.223,41.112,27.749,40.64,27.749,39.933"
      />
      <path
        fill="#007DC4"
        d="M34.758,41.755c0,1.407-1.069,2.179-2.548,2.179c-1.043,0-1.841-0.363-2.285-1.098l1.206-0.652
    c0.345,0.406,0.598,0.561,1.07,0.561c0.663,0,1.134-0.389,1.134-1.106v-0.127c-0.327,0.282-0.78,0.437-1.37,0.437
    c-1.188,0-2.194-0.944-2.194-2.232c0-1.261,1.006-2.194,2.194-2.194c0.59,0,1.043,0.154,1.37,0.435v-0.299h1.423V41.755z
     M33.335,39.715c0-0.608-0.481-1.034-1.062-1.034c-0.635,0-1.088,0.426-1.088,1.034s0.453,1.043,1.088,1.043
    C32.854,40.758,33.335,40.323,33.335,39.715"
      />
      <path
        fill="#007DC4"
        d="M40.099,40.306h-3.337c0.118,0.543,0.49,0.879,0.979,0.879c0.336,0,0.726-0.082,0.971-0.526l1.287,0.263
    c-0.391,0.962-1.215,1.425-2.258,1.425c-1.315,0-2.403-0.971-2.403-2.413c0-1.433,1.088-2.412,2.412-2.412
    c1.288,0,2.321,0.926,2.349,2.412V40.306z M36.789,39.416h1.877c-0.137-0.49-0.499-0.717-0.916-0.717
    C37.342,38.699,36.925,38.953,36.789,39.416"
      />
      <path
        fill="#007DC4"
        d="M44.85,38.265c1.342,0,2.14,0.77,2.14,1.959c0,1.251-0.905,2.122-2.303,2.122
    c-1.252,0-2.312-0.718-2.349-2.087l1.415-0.28c0.009,0.789,0.462,1.097,0.961,1.097c0.517,0,0.871-0.336,0.871-0.834
    c0-0.454-0.346-0.753-0.871-0.753h-2.086l0.109-3.355h3.989v1.298h-2.684l-0.036,0.834H44.85z"
      />
      <path
        fill="#005890"
        d="M28.097,19.584v-0.408c0-0.985-0.5-1.847-1.254-2.333c-0.412,0.254-0.896,0.404-1.414,0.404
    c-0.523,0-1.011-0.152-1.424-0.41c-0.147,0.094-0.287,0.202-0.418,0.326c-0.532,0.509-0.826,1.195-0.826,1.931v0.49
    c0,0.031,0.024,0.055,0.056,0.055h5.225C28.071,19.639,28.097,19.615,28.097,19.584"
      />
      <path
        fill="#005890"
        d="M25.429,16.792c1.236,0,2.243-1.006,2.243-2.244c0-1.237-1.007-2.244-2.243-2.244
    c-1.237,0-2.244,1.007-2.244,2.244C23.185,15.786,24.191,16.792,25.429,16.792"
      />
      <path
        fill="#005890"
        d="M37.909,16.843c-0.411,0.254-0.896,0.404-1.414,0.404c-0.522,0-1.009-0.152-1.423-0.41
    c-0.148,0.094-0.288,0.202-0.418,0.326c-0.533,0.509-0.826,1.195-0.826,1.931v0.49c0,0.031,0.024,0.055,0.055,0.055h5.226
    c0.03,0,0.055-0.024,0.055-0.055v-0.408C39.163,18.192,38.664,17.329,37.909,16.843"
      />
      <path
        fill="#005890"
        d="M36.495,16.792c1.237,0,2.244-1.006,2.244-2.244c0-1.237-1.007-2.244-2.244-2.244
    s-2.244,1.007-2.244,2.244C34.251,15.786,35.258,16.792,36.495,16.792"
      />
      <path
        fill="#005890"
        d="M32.375,24.392c-0.411,0.254-0.895,0.404-1.413,0.404c-0.522,0-1.01-0.152-1.424-0.41
    c-0.147,0.094-0.288,0.203-0.418,0.326c-0.533,0.509-0.826,1.195-0.826,1.931v0.49c0,0.031,0.025,0.055,0.056,0.055h5.225
    c0.031,0,0.056-0.024,0.056-0.055v-0.408C33.63,25.741,33.13,24.878,32.375,24.392"
      />
      <path
        fill="#005890"
        d="M30.962,24.341c1.236,0,2.244-1.006,2.244-2.244c0-1.237-1.008-2.244-2.244-2.244
    c-1.237,0-2.244,1.007-2.244,2.244C28.718,23.335,29.725,24.341,30.962,24.341"
      />
      <path
        fill="#005890"
        d="M28.73,16.792h4.462c0.126,0,0.229-0.102,0.229-0.227c0-0.127-0.103-0.228-0.229-0.228H28.73
    c-0.125,0-0.227,0.101-0.227,0.228C28.504,16.69,28.605,16.792,28.73,16.792"
      />
      <path
        fill="#005890"
        d="M36.793,20.794l-2.89,2.889c-0.089,0.089-0.089,0.233,0,0.322c0.045,0.044,0.103,0.067,0.16,0.067
    c0.06,0,0.117-0.023,0.162-0.067l2.889-2.889c0.09-0.089,0.09-0.233,0-0.322C37.025,20.706,36.882,20.706,36.793,20.794"
      />
      <path
        fill="#005890"
        d="M28.021,24.005c0.089-0.089,0.089-0.233,0-0.322l-2.891-2.889c-0.088-0.089-0.231-0.089-0.32,0
    c-0.09,0.089-0.09,0.233,0,0.322l2.889,2.889c0.045,0.045,0.104,0.067,0.161,0.067S27.976,24.051,28.021,24.005"
      />
      <path
        fill="#4F86C6"
        d="M30.864,59.563c-2.638,0-4.772-2.135-4.772-4.771s2.135-4.773,4.772-4.773
    c2.639,0,4.773,2.137,4.773,4.773S33.503,59.563,30.864,59.563"
      />
      <path
        fill="#FFFFFF"
        d="M30.864,50.018c2.639,0,4.773,2.137,4.773,4.773s-2.135,4.771-4.773,4.771
    c-2.638,0-4.772-2.135-4.772-4.771S28.227,50.018,30.864,50.018 M30.864,49.605c-2.858,0-5.185,2.326-5.185,5.186
    c0,2.857,2.326,5.184,5.185,5.184c2.859,0,5.186-2.326,5.186-5.184C36.05,51.932,33.724,49.605,30.864,49.605"
      />
      <path
        fill="#FFFFFF"
        d="M33.358,52.846c-0.239,0-0.465,0.107-0.62,0.25l-0.014-0.012c0.072-0.119,0.169-0.346,0.169-0.715
    c0-0.885-0.885-1.553-2.028-1.553c-1.146,0-2.042,0.668-2.042,1.553c0,0.369,0.12,0.596,0.18,0.715l-0.013,0.012
    c-0.154-0.143-0.381-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.016c0,1.289,0.717,2.018,1.492,2.018
    c0.716,0,1.218-0.396,1.67-0.848c-0.071-0.107-0.179-0.217-0.298-0.217s-0.251,0.086-0.477,0.264
    c-0.299,0.229-0.562,0.359-0.908,0.359c-0.608,0-0.869-0.682-0.869-1.576c0-0.953,0.25-1.586,0.895-1.586
    c0.286,0,0.477,0.082,0.668,0.262c0.286,0.264,0.381,0.334,0.512,0.334c0.097,0,0.156-0.061,0.18-0.096
    c-0.214-0.215-0.525-0.668-0.525-1.252c0-0.668,0.562-1.135,1.612-1.135c1.049,0,1.609,0.467,1.609,1.135
    c0,0.584-0.31,1.037-0.524,1.252c0.023,0.035,0.084,0.096,0.179,0.096c0.132,0,0.228-0.07,0.513-0.334
    c0.18-0.18,0.383-0.262,0.668-0.262c0.645,0,0.896,0.633,0.896,1.586c0,0.895-0.263,1.576-0.871,1.576
    c-0.345,0-0.619-0.131-0.907-0.359c-0.226-0.178-0.356-0.264-0.477-0.264c-0.119,0-0.227,0.109-0.298,0.217
    c0.44,0.451,0.955,0.848,1.67,0.848c0.775,0,1.492-0.729,1.492-2.018C34.814,53.549,34.086,52.846,33.358,52.846"
      />
      <path
        fill="#FFFFFF"
        d="M31.664,52.822h-0.537c-0.036,0-0.059-0.025-0.071-0.049l-0.168-0.512c-0.012-0.035-0.022-0.035-0.035,0
    l-0.168,0.512c-0.012,0.037-0.035,0.049-0.06,0.049h-0.537c-0.024,0-0.035,0.01-0.012,0.023l0.441,0.322
    c0.024,0.023,0.024,0.061,0.013,0.082l-0.167,0.502c-0.012,0.023-0.012,0.049,0.023,0.023l0.442-0.309
    c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.035,0.012,0.023-0.012l-0.166-0.514
    c-0.013-0.035,0-0.07,0.024-0.082l0.439-0.322C31.699,52.832,31.687,52.822,31.664,52.822"
      />
      <path
        fill="#FFFFFF"
        d="M31.258,56.352c-0.226-0.57-0.405-0.965-0.405-1.441c0-0.203,0.06-0.383,0.096-0.479
    c0.047-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.168-0.321,0.313c-0.072,0.119-0.155,0.346-0.155,0.523
    c0,0.336,0.107,0.693,0.179,0.906c0.132,0.371,0.251,0.801,0.251,1.207c0,0.645-0.287,1.084-0.799,1.479
    c0.047,0.037,0.166,0.096,0.31,0.096c0.5,0,1.098-0.563,1.098-1.324C31.521,57.02,31.39,56.711,31.258,56.352"
      />
    </g>
  </g>
);

const NetworkStage4 = () => (
  <g id="Stage_4_6_">
    <polygon
      fill="#ABD1E2"
      points="57.573,19.725 57.573,50.566 30.864,65.986 4.154,50.566 4.154,19.725 30.864,4.304 			"
    />
    <polygon
      fill="#60C1E5"
      points="57.572,50.566 30.864,65.986 30.864,35.146 			"
    />
    <polygon
      fill="#60C1E5"
      points="57.572,19.727 57.572,50.566 30.864,35.146 			"
    />
    <polygon fill="#60C1E5" points="30.864,4.3 57.572,19.72 30.864,35.141 			" />
    <polygon
      fill="#60C1E5"
      points="30.862,65.988 4.154,50.568 30.862,35.147 			"
    />
    <g>
      <path
        fill="#00ADEE"
        d="M30.863,4.617L57.301,19.88v30.526L30.863,65.672L4.427,50.406V19.88L30.863,4.617 M30.863,0.042
					c-0.573,0-1.146,0.148-1.659,0.444L1.679,16.379c-1.026,0.593-1.659,1.687-1.659,2.872v31.786c0,1.186,0.633,2.279,1.659,2.871
					l27.526,15.895c0.513,0.295,1.086,0.443,1.659,0.443c0.572,0,1.146-0.148,1.658-0.443l27.527-15.895
					c1.026-0.592,1.658-1.686,1.658-2.871V19.251c0-1.185-0.632-2.279-1.658-2.872L32.521,0.486
					C32.009,0.19,31.436,0.042,30.863,0.042"
      />
      <polygon
        fill="#005890"
        points="21.972,33.688 21.092,33.688 18.896,31.176 18.896,33.688 17.828,33.688 17.828,29.35
					18.716,29.35 20.91,31.94 20.91,29.35 21.972,29.35 				"
      />
      <path
        fill="#005890"
        d="M25.852,32.329h-2.383c0.084,0.388,0.35,0.628,0.699,0.628c0.239,0,0.519-0.059,0.693-0.376l0.919,0.188
					c-0.278,0.686-0.868,1.016-1.612,1.016c-0.938,0-1.716-0.693-1.716-1.722c0-1.023,0.777-1.722,1.723-1.722
					c0.919,0,1.657,0.66,1.677,1.722V32.329z M23.488,31.694h1.34c-0.097-0.35-0.356-0.511-0.653-0.511
					C23.883,31.183,23.585,31.363,23.488,31.694"
      />
      <path
        fill="#005890"
        d="M28.734,33.547c-0.252,0.135-0.518,0.238-0.887,0.238c-0.809,0-1.295-0.439-1.295-1.294V31.26h-0.589
					v-0.822h0.589v-0.959h1.036v0.959h0.958v0.822h-0.958v1.127c0,0.337,0.162,0.472,0.434,0.472c0.142,0,0.336-0.051,0.473-0.129
					L28.734,33.547z"
      />
      <polygon
        fill="#005890"
        points="32.75,33.695 31.85,33.695 31.267,31.934 30.677,33.695 29.777,33.695 28.734,30.438
					29.751,30.438 30.282,32.186 30.897,30.438 31.629,30.438 32.244,32.193 32.775,30.438 33.793,30.438 				"
      />
      <path
        fill="#005890"
        d="M37.445,32.063c0,1.031-0.816,1.716-1.781,1.716c-0.984,0-1.799-0.686-1.799-1.716
					c0-1.023,0.814-1.709,1.799-1.709C36.629,30.354,37.445,31.04,37.445,32.063 M36.448,32.063c0-0.505-0.382-0.841-0.784-0.841
					c-0.421,0-0.803,0.336-0.803,0.841c0,0.511,0.382,0.842,0.803,0.842C36.066,32.905,36.448,32.574,36.448,32.063"
      />
      <path
        fill="#005890"
        d="M40.309,30.418l-0.046,1.036h-0.188c-0.726,0-1.12,0.376-1.12,1.217v1.017h-1.037v-3.251h1.037v0.622
					c0.233-0.389,0.588-0.667,1.12-0.667C40.166,30.393,40.23,30.399,40.309,30.418"
      />
      <polygon
        fill="#005890"
        points="42.963,33.688 41.908,32.516 41.721,32.516 41.721,33.688 40.685,33.688 40.685,29.026
					41.721,29.026 41.721,31.545 41.863,31.545 42.893,30.438 44.142,30.438 42.717,31.985 44.291,33.688 				"
      />
      <path
        fill="#007DC4"
        d="M19.832,37.754l-1.469,0.299c-0.109-0.572-0.581-0.854-1.062-0.854c-0.498,0-0.816,0.273-0.816,0.617
					c0,0.244,0.109,0.428,0.49,0.508l1.261,0.281c1.051,0.236,1.669,0.809,1.669,1.77c0,1.297-1.152,1.967-2.504,1.967
					c-1.369,0-2.493-0.652-2.657-1.84l1.542-0.299c0.154,0.625,0.59,0.896,1.197,0.896c0.562,0,0.898-0.254,0.898-0.625
					c0-0.271-0.127-0.445-0.562-0.537l-1.27-0.27c-0.871-0.201-1.632-0.654-1.632-1.762c0-1.178,0.979-1.913,2.403-1.913
					C18.644,35.993,19.605,36.646,19.832,37.754"
      />
      <path
        fill="#007DC4"
        d="M23.903,42.006c-0.354,0.191-0.725,0.336-1.242,0.336c-1.134,0-1.814-0.617-1.814-1.814v-1.723h-0.825
					v-1.15h0.825v-1.342h1.451v1.342h1.342v1.15h-1.342v1.578c0,0.471,0.227,0.662,0.608,0.662c0.199,0,0.471-0.072,0.662-0.182
					L23.903,42.006z"
      />
      <path
        fill="#007DC4"
        d="M28.972,42.207h-1.451v-0.363c-0.353,0.316-0.825,0.498-1.414,0.498c-1.207,0-2.222-0.971-2.222-2.412
					c0-1.434,1.015-2.412,2.222-2.412c0.589,0,1.061,0.182,1.414,0.498v-0.361h1.451V42.207z M27.521,39.93
					c0-0.697-0.526-1.178-1.115-1.178c-0.635,0-1.097,0.48-1.097,1.178c0,0.707,0.462,1.18,1.097,1.18
					C26.995,41.109,27.521,40.637,27.521,39.93"
      />
      <path
        fill="#007DC4"
        d="M34.529,41.752c0,1.406-1.068,2.178-2.548,2.178c-1.043,0-1.841-0.363-2.285-1.098l1.206-0.652
					c0.345,0.406,0.598,0.561,1.07,0.561c0.663,0,1.134-0.389,1.134-1.105v-0.127c-0.327,0.281-0.78,0.436-1.37,0.436
					c-1.188,0-2.194-0.943-2.194-2.232c0-1.26,1.006-2.193,2.194-2.193c0.59,0,1.043,0.154,1.37,0.434v-0.299h1.423V41.752z
					 M33.106,39.711c0-0.607-0.481-1.033-1.061-1.033c-0.635,0-1.088,0.426-1.088,1.033c0,0.609,0.453,1.045,1.088,1.045
					C32.625,40.756,33.106,40.32,33.106,39.711"
      />
      <path
        fill="#007DC4"
        d="M39.871,40.303h-3.338c0.118,0.543,0.49,0.879,0.979,0.879c0.336,0,0.726-0.082,0.971-0.527l1.287,0.264
					c-0.39,0.963-1.215,1.424-2.258,1.424c-1.315,0-2.403-0.971-2.403-2.412c0-1.434,1.088-2.412,2.412-2.412
					c1.288,0,2.322,0.926,2.35,2.412V40.303z M36.561,39.412h1.877c-0.136-0.488-0.499-0.717-0.916-0.717
					C37.113,38.695,36.696,38.949,36.561,39.412"
      />
      <path
        fill="#007DC4"
        d="M46.563,41.09v1.117h-1.405V41.09h-3.156v-1.342l2.711-3.618h1.851v3.763h0.734v1.197H46.563z
					 M45.157,37.572l-1.642,2.32h1.642V37.572z"
      />
      <path
        fill="#005890"
        d="M28.097,19.581v-0.408c0-0.985-0.499-1.847-1.254-2.333c-0.412,0.254-0.895,0.404-1.414,0.404
					c-0.523,0-1.009-0.152-1.423-0.41c-0.148,0.094-0.288,0.202-0.418,0.326c-0.533,0.509-0.827,1.195-0.827,1.931v0.49
					c0,0.031,0.025,0.055,0.056,0.055h5.225C28.072,19.636,28.097,19.612,28.097,19.581"
      />
      <path
        fill="#005890"
        d="M25.429,16.789c1.237,0,2.244-1.006,2.244-2.244c0-1.237-1.007-2.244-2.244-2.244
					s-2.244,1.007-2.244,2.244C23.185,15.783,24.192,16.789,25.429,16.789"
      />
      <path
        fill="#005890"
        d="M37.91,16.84c-0.412,0.254-0.896,0.404-1.414,0.404c-0.522,0-1.01-0.152-1.423-0.41
					c-0.147,0.094-0.288,0.202-0.418,0.326c-0.534,0.509-0.827,1.195-0.827,1.931v0.49c0,0.031,0.025,0.055,0.057,0.055h5.224
					c0.031,0,0.056-0.024,0.056-0.055v-0.408C39.164,18.189,38.664,17.326,37.91,16.84"
      />
      <path
        fill="#005890"
        d="M36.496,16.789c1.237,0,2.244-1.006,2.244-2.244c0-1.237-1.007-2.244-2.244-2.244
					c-1.236,0-2.244,1.007-2.244,2.244C34.252,15.783,35.26,16.789,36.496,16.789"
      />
      <path
        fill="#005890"
        d="M32.377,24.389c-0.412,0.254-0.895,0.404-1.414,0.404c-0.523,0-1.01-0.152-1.423-0.41
					c-0.148,0.094-0.288,0.203-0.418,0.326c-0.534,0.509-0.827,1.195-0.827,1.931v0.49c0,0.031,0.025,0.055,0.056,0.055h5.224
					c0.03,0,0.056-0.024,0.056-0.055v-0.408C33.631,25.738,33.131,24.875,32.377,24.389"
      />
      <path
        fill="#005890"
        d="M30.963,24.337c1.237,0,2.244-1.006,2.244-2.244c0-1.237-1.007-2.244-2.244-2.244
					c-1.237,0-2.244,1.007-2.244,2.244C28.719,23.332,29.726,24.337,30.963,24.337"
      />
      <path
        fill="#005890"
        d="M28.731,16.789h4.462c0.127,0,0.229-0.102,0.229-0.227c0-0.127-0.102-0.228-0.229-0.228h-4.462
					c-0.125,0-0.227,0.101-0.227,0.228C28.505,16.687,28.606,16.789,28.731,16.789"
      />
      <path
        fill="#005890"
        d="M36.794,20.791l-2.89,2.889c-0.088,0.089-0.088,0.233,0,0.322c0.045,0.044,0.103,0.067,0.16,0.067
					c0.06,0,0.117-0.023,0.162-0.067l2.889-2.889c0.09-0.089,0.09-0.233,0-0.322C37.027,20.702,36.883,20.702,36.794,20.791"
      />
      <path
        fill="#005890"
        d="M28.021,24.002c0.089-0.089,0.089-0.233,0-0.322l-2.889-2.889c-0.089-0.089-0.233-0.089-0.322,0
					c-0.089,0.089-0.089,0.233,0,0.322l2.889,2.889c0.045,0.045,0.103,0.067,0.161,0.067S27.977,24.047,28.021,24.002"
      />
      <path
        fill="#4F86C6"
        d="M30.863,59.559c-2.638,0-4.773-2.135-4.773-4.771s2.135-4.773,4.773-4.773
					c2.638,0,4.773,2.137,4.773,4.773S33.501,59.559,30.863,59.559"
      />
      <path
        fill="#FFFFFF"
        d="M30.863,50.014c2.638,0,4.773,2.137,4.773,4.773s-2.136,4.771-4.773,4.771
					c-2.638,0-4.773-2.135-4.773-4.771S28.225,50.014,30.863,50.014 M30.863,49.602c-2.859,0-5.186,2.326-5.186,5.186
					c0,2.857,2.326,5.184,5.186,5.184s5.185-2.326,5.185-5.184C36.048,51.928,33.723,49.602,30.863,49.602"
      />
      <path
        fill="#FFFFFF"
        d="M33.356,52.842c-0.239,0-0.464,0.107-0.62,0.25l-0.013-0.012c0.073-0.119,0.169-0.346,0.169-0.715
					c0-0.885-0.884-1.553-2.029-1.553c-1.145,0-2.041,0.668-2.041,1.553c0,0.369,0.119,0.596,0.179,0.715l-0.012,0.012
					c-0.155-0.143-0.382-0.25-0.62-0.25c-0.729,0-1.456,0.703-1.456,2.016c0,1.289,0.716,2.018,1.492,2.018
					c0.716,0,1.217-0.396,1.67-0.848c-0.072-0.107-0.18-0.217-0.298-0.217c-0.119,0-0.252,0.086-0.478,0.264
					c-0.298,0.229-0.562,0.359-0.907,0.359c-0.609,0-0.87-0.682-0.87-1.576c0-0.953,0.251-1.586,0.895-1.586
					c0.286,0,0.476,0.082,0.668,0.262c0.286,0.264,0.381,0.334,0.512,0.334c0.096,0,0.156-0.061,0.18-0.096
					c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.668,0.56-1.135,1.611-1.135c1.049,0,1.611,0.467,1.611,1.135
					c0,0.584-0.311,1.037-0.525,1.252c0.023,0.035,0.083,0.096,0.179,0.096c0.131,0,0.226-0.07,0.512-0.334
					c0.179-0.18,0.382-0.262,0.668-0.262c0.645,0,0.896,0.633,0.896,1.586c0,0.895-0.264,1.576-0.871,1.576
					c-0.347,0-0.622-0.131-0.907-0.359c-0.227-0.178-0.358-0.264-0.477-0.264c-0.12,0-0.227,0.109-0.299,0.217
					c0.441,0.451,0.955,0.848,1.671,0.848c0.775,0,1.491-0.729,1.491-2.018C34.813,53.545,34.084,52.842,33.356,52.842"
      />
      <path
        fill="#FFFFFF"
        d="M31.663,52.818h-0.537c-0.035,0-0.059-0.025-0.071-0.049l-0.168-0.512c-0.012-0.035-0.023-0.035-0.035,0
					l-0.167,0.512c-0.012,0.037-0.036,0.049-0.06,0.049h-0.537c-0.024,0-0.035,0.01-0.012,0.023l0.442,0.322
					c0.023,0.023,0.023,0.061,0.011,0.082l-0.167,0.502c-0.011,0.023-0.011,0.049,0.025,0.023l0.441-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.036,0.012,0.023-0.012l-0.166-0.514
					c-0.012-0.035,0-0.07,0.024-0.082l0.44-0.322C31.698,52.828,31.687,52.818,31.663,52.818"
      />
      <path
        fill="#FFFFFF"
        d="M31.257,56.348c-0.227-0.57-0.406-0.965-0.406-1.441c0-0.203,0.06-0.383,0.096-0.479
					c0.047-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.168-0.322,0.313c-0.072,0.119-0.155,0.346-0.155,0.523
					c0,0.336,0.108,0.693,0.179,0.906c0.132,0.371,0.251,0.801,0.251,1.207c0,0.645-0.287,1.084-0.8,1.479
					c0.048,0.037,0.167,0.096,0.311,0.096c0.5,0,1.097-0.563,1.097-1.324C31.519,57.016,31.388,56.707,31.257,56.348"
      />
    </g>
  </g>
);

const NetworkStage3 = () => (
  <g id="Stage_3_6_">
    <polygon
      fill="#ABD1E2"
      points="57.506,19.692 57.506,50.533 30.797,65.955 4.087,50.533 4.087,19.692 30.797,4.271 			"
    />
    <polygon
      fill="#60C1E5"
      points="57.505,50.533 30.797,65.955 30.797,35.114 			"
    />
    <polygon
      fill="#60C1E5"
      points="57.505,19.694 57.505,50.533 30.797,35.114 			"
    />
    <polygon
      fill="#60C1E5"
      points="30.797,4.268 57.505,19.688 30.797,35.107 			"
    />
    <g>
      <path
        fill="#00ADEE"
        d="M30.796,4.584l26.437,15.263v30.527L30.796,65.639L4.359,50.375V19.848L30.796,4.584 M30.796,0.009
					c-0.573,0-1.145,0.148-1.658,0.444L1.611,16.347c-1.027,0.593-1.658,1.687-1.658,2.872v31.785c0,1.186,0.631,2.279,1.658,2.873
					L29.138,69.77c0.513,0.295,1.085,0.443,1.658,0.443c0.573,0,1.144-0.148,1.659-0.443l27.526-15.893
					c1.027-0.594,1.658-1.688,1.658-2.873V19.219c0-1.186-0.631-2.279-1.658-2.872L32.455,0.454
					C31.94,0.158,31.369,0.009,30.796,0.009"
      />
      <polygon
        fill="#005890"
        points="21.905,33.656 21.025,33.656 18.829,31.145 18.829,33.656 17.761,33.656 17.761,29.318
					18.647,29.318 20.843,31.908 20.843,29.318 21.905,29.318 				"
      />
      <path
        fill="#005890"
        d="M25.785,32.297h-2.384c0.085,0.389,0.35,0.628,0.7,0.628c0.239,0,0.518-0.06,0.693-0.376l0.919,0.188
					c-0.278,0.687-0.868,1.017-1.612,1.017c-0.939,0-1.716-0.692-1.716-1.722c0-1.023,0.777-1.723,1.723-1.723
					c0.919,0,1.657,0.66,1.677,1.723V32.297z M23.421,31.662h1.34c-0.097-0.35-0.356-0.512-0.653-0.512
					C23.816,31.15,23.518,31.331,23.421,31.662"
      />
      <path
        fill="#005890"
        d="M28.667,33.514c-0.252,0.137-0.518,0.239-0.887,0.239c-0.809,0-1.295-0.44-1.295-1.294v-1.23h-0.59
					v-0.822h0.59v-0.959h1.036v0.959h0.958v0.822h-0.958v1.127c0,0.336,0.162,0.472,0.434,0.472c0.142,0,0.336-0.052,0.473-0.13
					L28.667,33.514z"
      />
      <polygon
        fill="#005890"
        points="32.683,33.662 31.782,33.662 31.199,31.902 30.61,33.662 29.71,33.662 28.667,30.406
					29.684,30.406 30.215,32.154 30.83,30.406 31.562,30.406 32.177,32.16 32.708,30.406 33.726,30.406 				"
      />
      <path
        fill="#005890"
        d="M37.378,32.031c0,1.029-0.816,1.715-1.779,1.715c-0.986,0-1.801-0.686-1.801-1.715
					c0-1.023,0.814-1.709,1.801-1.709C36.562,30.322,37.378,31.008,37.378,32.031 M36.381,32.031c0-0.506-0.382-0.842-0.782-0.842
					c-0.423,0-0.805,0.336-0.805,0.842c0,0.511,0.382,0.842,0.805,0.842C35.999,32.873,36.381,32.542,36.381,32.031"
      />
      <path
        fill="#005890"
        d="M40.241,30.387l-0.046,1.035h-0.187c-0.727,0-1.121,0.377-1.121,1.218v1.017h-1.037v-3.251h1.037v0.622
					c0.233-0.389,0.59-0.667,1.121-0.667C40.099,30.36,40.163,30.367,40.241,30.387"
      />
      <polygon
        fill="#005890"
        points="42.896,33.656 41.841,32.484 41.653,32.484 41.653,33.656 40.617,33.656 40.617,28.994
					41.653,28.994 41.653,31.514 41.796,31.514 42.825,30.406 44.074,30.406 42.65,31.953 44.224,33.656 				"
      />
      <path
        fill="#007DC4"
        d="M19.987,37.721l-1.469,0.299c-0.109-0.57-0.581-0.852-1.061-0.852c-0.499,0-0.816,0.271-0.816,0.617
					c0,0.244,0.108,0.426,0.489,0.508l1.261,0.281c1.052,0.234,1.669,0.807,1.669,1.768c0,1.297-1.152,1.967-2.504,1.967
					c-1.368,0-2.493-0.652-2.657-1.84l1.542-0.299c0.154,0.625,0.59,0.896,1.197,0.896c0.562,0,0.898-0.254,0.898-0.625
					c0-0.271-0.127-0.443-0.562-0.535l-1.27-0.271c-0.87-0.201-1.632-0.652-1.632-1.76c0-1.18,0.979-1.914,2.403-1.914
					C18.799,35.961,19.76,36.615,19.987,37.721"
      />
      <path
        fill="#007DC4"
        d="M24.058,41.973c-0.354,0.191-0.725,0.336-1.242,0.336c-1.134,0-1.814-0.615-1.814-1.813v-1.725h-0.825
					v-1.15h0.825v-1.342h1.451v1.342h1.342v1.15h-1.342v1.578c0,0.473,0.227,0.662,0.608,0.662c0.199,0,0.471-0.072,0.662-0.18
					L24.058,41.973z"
      />
      <path
        fill="#007DC4"
        d="M29.126,42.174h-1.451v-0.363c-0.353,0.316-0.825,0.498-1.414,0.498c-1.207,0-2.222-0.971-2.222-2.412
					c0-1.434,1.015-2.412,2.222-2.412c0.589,0,1.062,0.182,1.414,0.5v-0.363h1.451V42.174z M27.675,39.896
					c0-0.697-0.526-1.178-1.115-1.178c-0.635,0-1.097,0.48-1.097,1.178c0,0.707,0.462,1.18,1.097,1.18
					C27.149,41.076,27.675,40.604,27.675,39.896"
      />
      <path
        fill="#007DC4"
        d="M34.686,41.719c0,1.406-1.069,2.178-2.548,2.178c-1.043,0-1.841-0.363-2.286-1.098l1.206-0.652
					c0.345,0.408,0.6,0.563,1.07,0.563c0.662,0,1.133-0.391,1.133-1.107v-0.127c-0.325,0.281-0.779,0.436-1.368,0.436
					c-1.189,0-2.195-0.943-2.195-2.23c0-1.262,1.006-2.195,2.195-2.195c0.589,0,1.043,0.154,1.368,0.436v-0.299h1.425V41.719z
					 M33.261,39.68c0-0.609-0.48-1.035-1.061-1.035c-0.635,0-1.088,0.426-1.088,1.035c0,0.607,0.453,1.043,1.088,1.043
					C32.78,40.723,33.261,40.287,33.261,39.68"
      />
      <path
        fill="#007DC4"
        d="M40.026,40.27h-3.338c0.118,0.543,0.49,0.879,0.979,0.879c0.336,0,0.727-0.082,0.971-0.525l1.287,0.262
					c-0.389,0.963-1.215,1.424-2.258,1.424c-1.314,0-2.402-0.969-2.402-2.412c0-1.432,1.088-2.412,2.412-2.412
					c1.287,0,2.322,0.926,2.35,2.412V40.27z M36.716,39.381h1.877c-0.137-0.49-0.499-0.717-0.916-0.717
					C37.269,38.664,36.852,38.918,36.716,39.381"
      />
      <path
        fill="#007DC4"
        d="M46.898,40.35c0,1.152-0.935,1.959-2.267,1.959c-1.279,0-2.213-0.742-2.35-1.922l1.416-0.262
					c0.08,0.598,0.407,0.941,0.942,0.941c0.508,0,0.87-0.291,0.87-0.734c0-0.398-0.299-0.635-0.824-0.635H43.87V38.51h0.752
					c0.518,0,0.816-0.227,0.816-0.617c0-0.418-0.316-0.697-0.807-0.697s-0.854,0.299-0.907,0.979l-1.369-0.262
					c0.136-1.207,1.034-1.951,2.267-1.951c1.297,0,2.178,0.799,2.178,1.914c0,0.553-0.236,0.979-0.654,1.225
					C46.626,39.344,46.898,39.779,46.898,40.35"
      />
      <path
        fill="#005890"
        d="M28.03,19.549v-0.408c0-0.984-0.499-1.848-1.254-2.333c-0.411,0.254-0.895,0.403-1.413,0.403
					c-0.522,0-1.01-0.151-1.424-0.409c-0.148,0.094-0.288,0.202-0.418,0.325c-0.533,0.51-0.826,1.195-0.826,1.932v0.49
					c0,0.031,0.024,0.055,0.055,0.055h5.225C28.006,19.604,28.03,19.58,28.03,19.549"
      />
      <path
        fill="#005890"
        d="M25.362,16.756c1.237,0,2.244-1.006,2.244-2.244c0-1.236-1.007-2.243-2.244-2.243
					c-1.237,0-2.244,1.007-2.244,2.243C23.119,15.75,24.125,16.756,25.362,16.756"
      />
      <path
        fill="#005890"
        d="M37.843,16.808c-0.412,0.254-0.895,0.403-1.414,0.403c-0.521,0-1.008-0.151-1.422-0.41
					c-0.148,0.095-0.289,0.203-0.418,0.326c-0.533,0.51-0.826,1.195-0.826,1.932v0.49c0,0.031,0.023,0.055,0.055,0.055h5.225
					c0.031,0,0.055-0.023,0.055-0.055v-0.408C39.097,18.156,38.599,17.293,37.843,16.808"
      />
      <path
        fill="#005890"
        d="M36.429,16.756c1.238,0,2.244-1.006,2.244-2.244c0-1.236-1.006-2.243-2.244-2.243
					c-1.236,0-2.243,1.007-2.243,2.243C34.186,15.75,35.192,16.756,36.429,16.756"
      />
      <path
        fill="#005890"
        d="M32.31,24.356c-0.41,0.254-0.895,0.403-1.414,0.403c-0.522,0-1.009-0.151-1.423-0.409
					c-0.148,0.094-0.288,0.202-0.418,0.325c-0.533,0.51-0.826,1.195-0.826,1.932v0.49c0,0.031,0.024,0.055,0.055,0.055h5.225
					c0.031,0,0.055-0.023,0.055-0.055v-0.408C33.563,25.705,33.065,24.842,32.31,24.356"
      />
      <path
        fill="#005890"
        d="M30.896,24.305c1.237,0,2.244-1.006,2.244-2.243s-1.007-2.244-2.244-2.244
					c-1.237,0-2.244,1.007-2.244,2.244S29.659,24.305,30.896,24.305"
      />
      <path
        fill="#005890"
        d="M28.665,16.756h4.462c0.126,0,0.228-0.102,0.228-0.227c0-0.127-0.102-0.229-0.228-0.229h-4.462
					c-0.126,0-0.227,0.102-0.227,0.229C28.438,16.654,28.539,16.756,28.665,16.756"
      />
      <path
        fill="#005890"
        d="M36.727,20.759l-2.89,2.89c-0.088,0.088-0.088,0.232,0,0.321c0.045,0.044,0.104,0.067,0.162,0.067
					c0.058,0,0.115-0.023,0.16-0.067l2.89-2.89c0.089-0.088,0.089-0.232,0-0.321S36.815,20.67,36.727,20.759"
      />
      <path
        fill="#005890"
        d="M27.955,23.97c0.089-0.089,0.089-0.233,0-0.321l-2.89-2.89c-0.089-0.089-0.233-0.089-0.321,0
					c-0.09,0.089-0.09,0.233,0,0.322l2.889,2.889c0.044,0.046,0.104,0.067,0.161,0.067C27.852,24.037,27.91,24.016,27.955,23.97"
      />
      <path
        fill="#4F86C6"
        d="M30.796,59.525c-2.638,0-4.773-2.135-4.773-4.771s2.135-4.773,4.773-4.773s4.773,2.137,4.773,4.773
					S33.435,59.525,30.796,59.525"
      />
      <path
        fill="#FFFFFF"
        d="M30.796,49.98c2.638,0,4.773,2.137,4.773,4.773s-2.135,4.771-4.773,4.771s-4.773-2.135-4.773-4.771
					S28.158,49.98,30.796,49.98 M30.796,49.568c-2.859,0-5.185,2.326-5.185,5.186c0,2.857,2.326,5.184,5.185,5.184
					c2.859,0,5.185-2.326,5.185-5.184C35.981,51.895,33.655,49.568,30.796,49.568"
      />
      <path
        fill="#FFFFFF"
        d="M33.29,52.809c-0.239,0-0.465,0.107-0.62,0.252l-0.013-0.014c0.072-0.119,0.168-0.346,0.168-0.715
					c0-0.883-0.883-1.551-2.028-1.551c-1.145,0-2.041,0.668-2.041,1.551c0,0.369,0.12,0.596,0.179,0.715l-0.012,0.014
					c-0.155-0.145-0.382-0.252-0.62-0.252c-0.729,0-1.456,0.703-1.456,2.018c0,1.287,0.716,2.016,1.492,2.016
					c0.716,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.18-0.215-0.299-0.215c-0.118,0-0.251,0.084-0.476,0.262
					c-0.299,0.229-0.562,0.359-0.909,0.359c-0.608,0-0.869-0.682-0.869-1.574c0-0.955,0.25-1.588,0.896-1.588
					c0.286,0,0.476,0.084,0.668,0.262c0.286,0.264,0.381,0.334,0.512,0.334c0.096,0,0.156-0.059,0.179-0.094
					c-0.214-0.215-0.524-0.668-0.524-1.254c0-0.668,0.56-1.133,1.611-1.133c1.049,0,1.61,0.465,1.61,1.133
					c0,0.586-0.311,1.039-0.524,1.254c0.022,0.035,0.083,0.094,0.179,0.094c0.131,0,0.227-0.07,0.512-0.334
					c0.18-0.178,0.383-0.262,0.668-0.262c0.646,0,0.896,0.633,0.896,1.588c0,0.893-0.263,1.574-0.87,1.574
					c-0.346,0-0.621-0.131-0.908-0.359c-0.226-0.178-0.356-0.262-0.476-0.262c-0.12,0-0.228,0.107-0.3,0.215
					c0.441,0.453,0.955,0.848,1.671,0.848c0.776,0,1.491-0.729,1.491-2.016C34.745,53.512,34.019,52.809,33.29,52.809"
      />
      <path
        fill="#FFFFFF"
        d="M31.596,52.785h-0.537c-0.036,0-0.059-0.023-0.071-0.049l-0.168-0.512c-0.012-0.035-0.023-0.035-0.035,0
					l-0.168,0.512c-0.012,0.037-0.035,0.049-0.059,0.049H30.02c-0.023,0-0.035,0.012-0.012,0.023l0.443,0.322
					c0.023,0.023,0.023,0.061,0.011,0.084l-0.167,0.5c-0.011,0.023-0.011,0.049,0.025,0.023l0.441-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.024,0.025,0.035,0.014,0.024-0.012l-0.167-0.512
					c-0.013-0.035,0-0.072,0.023-0.084l0.441-0.322C31.632,52.797,31.618,52.785,31.596,52.785"
      />
      <path
        fill="#FFFFFF"
        d="M31.19,56.316c-0.226-0.572-0.406-0.967-0.406-1.443c0-0.203,0.06-0.383,0.096-0.477
					c0.048-0.098,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.166-0.321,0.311c-0.073,0.119-0.156,0.346-0.156,0.523
					c0,0.336,0.108,0.693,0.179,0.908c0.132,0.369,0.251,0.799,0.251,1.205c0,0.645-0.287,1.084-0.799,1.479
					c0.046,0.037,0.167,0.096,0.31,0.096c0.5,0,1.097-0.561,1.097-1.324C31.452,56.984,31.321,56.674,31.19,56.316"
      />
    </g>
  </g>
);
// #endregion

// #startregion Reflect Groups
const ReflectStage5 = () => (
  <g id="Stage_5_7_">
    <polygon
      fill="#ABD1E2"
      points="4.16,19.706 4.16,50.548 30.869,65.97 57.58,50.548 57.58,19.706 30.869,4.284 			"
    />
    <polygon
      fill="#60C1E5"
      points="57.58,50.548 30.871,65.968 30.871,35.128 			"
    />
    <polygon fill="#60C1E5" points="57.58,19.708 57.58,50.548 30.871,35.128 			" />
    <polygon
      fill="#60C1E5"
      points="30.871,4.282 57.578,19.702 30.871,35.122 			"
    />
    <polygon fill="#60C1E5" points="4.16,50.55 30.867,65.97 30.867,35.128 			" />
    <polygon fill="#60C1E5" points="4.162,50.548 30.869,35.128 4.162,19.708 			" />
    <g>
      <path
        fill="#00ADEE"
        d="M30.867,4.602l26.438,15.263v30.528L30.867,65.656L4.43,50.393V19.864L30.867,4.602 M30.867,0.026
					c-0.572,0-1.145,0.147-1.658,0.444L1.682,16.363c-1.027,0.593-1.658,1.688-1.658,2.872v31.786c0,1.186,0.631,2.279,1.658,2.871
					l27.527,15.893c0.514,0.297,1.086,0.445,1.658,0.445s1.145-0.148,1.658-0.445l27.527-15.893
					c1.025-0.592,1.658-1.686,1.658-2.871V19.235c0-1.185-0.633-2.279-1.658-2.872L32.525,0.471
					C32.012,0.174,31.439,0.026,30.867,0.026"
      />
      <path
        fill="#005890"
        d="M22.873,33.671l-1.309-1.49h-0.311v1.49h-1.068v-4.339h2.195c0.938,0,1.561,0.57,1.561,1.418
					c0,0.712-0.441,1.224-1.141,1.38l1.359,1.541H22.873z M22.264,31.287c0.383,0,0.629-0.207,0.629-0.524
					c0-0.311-0.246-0.518-0.629-0.518h-1.01v1.042H22.264z"
      />
      <path
        fill="#005890"
        d="M27.555,32.312h-2.383c0.084,0.387,0.35,0.627,0.699,0.627c0.238,0,0.518-0.058,0.693-0.375l0.918,0.188
					c-0.277,0.686-0.867,1.018-1.611,1.018c-0.939,0-1.715-0.695-1.715-1.724c0-1.022,0.775-1.722,1.721-1.722
					c0.92,0,1.658,0.66,1.678,1.722V32.312z M25.191,31.676h1.34c-0.098-0.351-0.355-0.511-0.654-0.511
					C25.586,31.165,25.289,31.346,25.191,31.676"
      />
      <path
        fill="#005890"
        d="M29.199,30.212v0.207h0.895v0.822h-0.895v2.43H28.17v-2.43h-0.465v-0.822h0.465v-0.252
					c0-0.764,0.525-1.25,1.309-1.25c0.221,0,0.434,0.045,0.641,0.137l-0.221,0.803c-0.096-0.031-0.199-0.053-0.277-0.053
					C29.375,29.804,29.199,29.954,29.199,30.212 M31.461,33.671h-1.037v-4.662h1.037V33.671z"
      />
      <path
        fill="#005890"
        d="M35.334,32.312h-2.385c0.086,0.387,0.352,0.627,0.701,0.627c0.238,0,0.518-0.058,0.693-0.375
					l0.918,0.188c-0.279,0.686-0.867,1.018-1.611,1.018c-0.939,0-1.717-0.695-1.717-1.724c0-1.022,0.777-1.722,1.723-1.722
					c0.92,0,1.658,0.66,1.678,1.722V32.312z M32.971,31.676h1.34c-0.098-0.351-0.357-0.511-0.654-0.511
					C33.365,31.165,33.066,31.346,32.971,31.676"
      />
      <path
        fill="#005890"
        d="M38.072,32.291l0.953,0.207c-0.162,0.771-0.848,1.269-1.658,1.269c-0.971,0-1.773-0.692-1.773-1.722
					c0-1.022,0.803-1.722,1.773-1.722c0.789,0,1.477,0.484,1.658,1.249l-0.971,0.233c-0.092-0.396-0.357-0.576-0.688-0.576
					c-0.467,0-0.775,0.343-0.775,0.815c0,0.479,0.309,0.815,0.775,0.815C37.697,32.86,37.963,32.687,38.072,32.291"
      />
      <path
        fill="#005890"
        d="M41.908,33.528c-0.252,0.135-0.52,0.238-0.889,0.238c-0.809,0-1.295-0.439-1.295-1.294v-1.231h-0.59
					v-0.822h0.59v-0.957h1.037v0.957h0.957v0.822h-0.957v1.127c0,0.338,0.162,0.473,0.434,0.473c0.143,0,0.336-0.051,0.473-0.129
					L41.908,33.528z"
      />
      <path
        fill="#007DC4"
        d="M20.064,37.735l-1.469,0.299c-0.109-0.57-0.58-0.852-1.061-0.852c-0.5,0-0.816,0.271-0.816,0.617
					c0,0.244,0.109,0.426,0.49,0.508l1.26,0.279c1.053,0.236,1.668,0.809,1.668,1.77c0,1.297-1.15,1.967-2.502,1.967
					c-1.369,0-2.494-0.652-2.656-1.84l1.541-0.299c0.154,0.625,0.59,0.896,1.195,0.896c0.564,0,0.898-0.254,0.898-0.627
					c0-0.27-0.127-0.443-0.563-0.533l-1.27-0.271c-0.869-0.201-1.631-0.654-1.631-1.76c0-1.18,0.979-1.915,2.402-1.915
					C18.877,35.975,19.838,36.629,20.064,37.735"
      />
      <path
        fill="#007DC4"
        d="M24.137,41.987c-0.354,0.191-0.727,0.336-1.242,0.336c-1.135,0-1.814-0.617-1.814-1.813v-1.725h-0.826
					v-1.15h0.826v-1.344h1.451v1.344h1.342v1.15h-1.342v1.578c0,0.471,0.227,0.662,0.607,0.662c0.199,0,0.471-0.072,0.662-0.18
					L24.137,41.987z"
      />
      <path
        fill="#007DC4"
        d="M29.205,42.187h-1.451v-0.361c-0.354,0.316-0.824,0.498-1.414,0.498c-1.207,0-2.223-0.971-2.223-2.412
					c0-1.434,1.016-2.412,2.223-2.412c0.59,0,1.061,0.182,1.414,0.5v-0.363h1.451V42.187z M27.754,39.911
					c0-0.697-0.525-1.18-1.115-1.18c-0.635,0-1.098,0.482-1.098,1.18c0,0.707,0.463,1.18,1.098,1.18
					C27.229,41.091,27.754,40.618,27.754,39.911"
      />
      <path
        fill="#007DC4"
        d="M34.764,41.733c0,1.406-1.07,2.178-2.547,2.178c-1.045,0-1.842-0.363-2.287-1.098l1.207-0.654
					c0.344,0.41,0.598,0.564,1.068,0.564c0.664,0,1.135-0.391,1.135-1.107v-0.127c-0.326,0.281-0.779,0.436-1.369,0.436
					c-1.189,0-2.195-0.943-2.195-2.23c0-1.262,1.006-2.195,2.195-2.195c0.59,0,1.043,0.154,1.369,0.436v-0.301h1.424V41.733z
					 M33.34,39.694c0-0.609-0.48-1.035-1.061-1.035c-0.635,0-1.088,0.426-1.088,1.035c0,0.605,0.453,1.043,1.088,1.043
					C32.859,40.737,33.34,40.3,33.34,39.694"
      />
      <path
        fill="#007DC4"
        d="M40.104,40.284h-3.336c0.117,0.543,0.488,0.879,0.979,0.879c0.336,0,0.727-0.082,0.971-0.527
					l1.287,0.264c-0.391,0.963-1.215,1.424-2.258,1.424c-1.314,0-2.402-0.971-2.402-2.412c0-1.434,1.088-2.412,2.41-2.412
					c1.289,0,2.322,0.926,2.35,2.412V40.284z M36.793,39.394h1.877c-0.135-0.488-0.498-0.717-0.916-0.717
					C37.348,38.677,36.932,38.931,36.793,39.394"
      />
      <path
        fill="#007DC4"
        d="M44.855,38.243c1.342,0,2.141,0.771,2.141,1.959c0,1.25-0.908,2.121-2.305,2.121
					c-1.25,0-2.313-0.717-2.348-2.086l1.414-0.281c0.008,0.789,0.463,1.098,0.961,1.098c0.516,0,0.871-0.336,0.871-0.834
					c0-0.453-0.344-0.754-0.871-0.754h-2.086l0.109-3.354h3.99v1.298h-2.686l-0.035,0.834H44.855z"
      />
      <rect x="26.172" y="17.472" fill="#ABD1E2" width="7.938" height="4.121" />
      <path
        fill="#005890"
        d="M33.039,13.081c-1.801,0-3.371,0.991-4.193,2.457c-0.416-0.163-0.869-0.252-1.344-0.252
					c-2.045,0-3.705,1.659-3.705,3.705c0,2.047,1.66,3.706,3.705,3.706c0.344,0,0.676-0.048,0.99-0.136
					c0.139,0.932,0.939,1.646,1.91,1.646c0.943,0,1.727-0.675,1.896-1.568c0.24,0.037,0.488,0.058,0.74,0.058
					c2.654,0,4.809-2.153,4.809-4.808C37.848,15.233,35.693,13.081,33.039,13.081 M31.055,18.938c0,0.361-0.293,0.654-0.652,0.654
					c-0.361,0-0.654-0.293-0.654-0.654c0-0.359,0.293-0.652,0.654-0.652C30.762,18.286,31.055,18.579,31.055,18.938 M27.766,18.938
					c0-0.359,0.291-0.652,0.652-0.652s0.652,0.293,0.652,0.652c0,0.361-0.291,0.654-0.652,0.654S27.766,19.3,27.766,18.938
					 M32.385,19.593c-0.361,0-0.652-0.293-0.652-0.654c0-0.359,0.291-0.652,0.652-0.652s0.652,0.293,0.652,0.652
					C33.037,19.3,32.746,19.593,32.385,19.593"
      />
      <path
        fill="#005890"
        d="M27.383,23.324c-0.646,0-1.168,0.522-1.168,1.167s0.521,1.167,1.168,1.167
					c0.645,0,1.166-0.522,1.166-1.167S28.027,23.324,27.383,23.324"
      />
      <path
        fill="#005890"
        d="M25.342,25.067c-0.359,0-0.652,0.291-0.652,0.652s0.293,0.653,0.652,0.653
					c0.361,0,0.654-0.292,0.654-0.653S25.703,25.067,25.342,25.067"
      />
      <path
        fill="#4F86C6"
        d="M30.869,59.54c-2.637,0-4.773-2.137-4.773-4.771c0-2.637,2.137-4.773,4.773-4.773
					c2.639,0,4.773,2.137,4.773,4.773C35.643,57.403,33.508,59.54,30.869,59.54"
      />
      <path
        fill="#FFFFFF"
        d="M30.869,49.995c2.639,0,4.773,2.135,4.773,4.773c0,2.635-2.135,4.771-4.773,4.771
					c-2.637,0-4.773-2.137-4.773-4.771C26.096,52.13,28.232,49.995,30.869,49.995 M30.869,49.583c-2.859,0-5.184,2.326-5.184,5.186
					c0,2.857,2.324,5.182,5.184,5.182s5.186-2.324,5.186-5.182C36.055,51.909,33.729,49.583,30.869,49.583"
      />
      <path
        fill="#FFFFFF"
        d="M33.363,52.823c-0.238,0-0.465,0.107-0.619,0.25l-0.014-0.012c0.072-0.119,0.168-0.346,0.168-0.717
					c0-0.883-0.885-1.551-2.027-1.551c-1.146,0-2.043,0.668-2.043,1.551c0,0.371,0.119,0.598,0.18,0.717l-0.012,0.012
					c-0.154-0.143-0.383-0.25-0.619-0.25c-0.73,0-1.457,0.703-1.457,2.016c0,1.289,0.717,2.018,1.492,2.018
					c0.715,0,1.217-0.396,1.67-0.848c-0.072-0.107-0.18-0.217-0.299-0.217s-0.252,0.086-0.477,0.264
					c-0.299,0.229-0.561,0.359-0.908,0.359c-0.607,0-0.869-0.682-0.869-1.576c0-0.955,0.25-1.586,0.895-1.586
					c0.287,0,0.477,0.082,0.668,0.262c0.287,0.264,0.381,0.334,0.512,0.334c0.098,0,0.156-0.061,0.18-0.096
					c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.668,0.561-1.133,1.613-1.133c1.049,0,1.609,0.465,1.609,1.133
					c0,0.584-0.311,1.037-0.525,1.252c0.023,0.035,0.084,0.096,0.18,0.096c0.131,0,0.227-0.07,0.512-0.334
					c0.18-0.18,0.383-0.262,0.668-0.262c0.645,0,0.896,0.631,0.896,1.586c0,0.895-0.264,1.576-0.871,1.576
					c-0.346,0-0.619-0.131-0.908-0.359c-0.225-0.178-0.355-0.264-0.477-0.264c-0.119,0-0.227,0.109-0.299,0.217
					c0.441,0.451,0.955,0.848,1.672,0.848c0.775,0,1.49-0.729,1.49-2.018C34.818,53.526,34.092,52.823,33.363,52.823"
      />
      <path
        fill="#FFFFFF"
        d="M31.67,52.8h-0.537c-0.037,0-0.061-0.025-0.072-0.049l-0.168-0.514c-0.012-0.033-0.021-0.033-0.035,0
					l-0.168,0.514c-0.012,0.037-0.035,0.049-0.059,0.049h-0.537c-0.025,0-0.035,0.01-0.012,0.021l0.441,0.324
					c0.023,0.023,0.023,0.061,0.012,0.082l-0.168,0.502c-0.01,0.023-0.01,0.047,0.025,0.023l0.441-0.309
					c0.037-0.025,0.059-0.025,0.096,0l0.43,0.309c0.023,0.023,0.035,0.012,0.023-0.012l-0.166-0.514
					c-0.014-0.035,0-0.07,0.023-0.082l0.439-0.324C31.705,52.81,31.691,52.8,31.67,52.8"
      />
      <path
        fill="#FFFFFF"
        d="M31.264,56.329c-0.227-0.572-0.406-0.965-0.406-1.441c0-0.203,0.061-0.383,0.096-0.479
					c0.047-0.096,0.061-0.154,0.012-0.154c-0.072,0-0.227,0.166-0.322,0.311c-0.072,0.119-0.154,0.346-0.154,0.523
					c0,0.336,0.107,0.693,0.178,0.906c0.133,0.371,0.252,0.801,0.252,1.207c0,0.645-0.287,1.084-0.799,1.479
					c0.047,0.037,0.166,0.096,0.309,0.096c0.5,0,1.098-0.561,1.098-1.324C31.525,56.997,31.395,56.688,31.264,56.329"
      />
    </g>
  </g>
);

const ReflectStage4 = () => (
  <g id="Stage_4_7_">
    <polygon
      fill="#ABD1E2"
      points="57.563,19.7 57.563,50.541 30.854,65.961 4.145,50.541 4.145,19.7 30.854,4.278 			"
    />
    <polygon
      fill="#60C1E5"
      points="57.563,50.541 30.856,65.961 30.856,35.121 			"
    />
    <polygon
      fill="#60C1E5"
      points="57.563,19.701 57.563,50.541 30.856,35.121 			"
    />
    <polygon
      fill="#60C1E5"
      points="30.856,4.275 57.563,19.695 30.856,35.115 			"
    />
    <polygon
      fill="#60C1E5"
      points="30.854,65.963 4.145,50.543 30.854,35.122 			"
    />
    <g>
      <path
        fill="#00ADEE"
        d="M30.854,4.591l26.437,15.263v30.528L30.854,65.646L4.417,50.383V19.854L30.854,4.591 M30.854,0.017
					c-0.572,0-1.146,0.148-1.658,0.445L1.668,16.354c-1.025,0.593-1.658,1.687-1.658,2.872v31.786c0,1.186,0.633,2.279,1.658,2.871
					l27.527,15.893c0.512,0.297,1.086,0.445,1.658,0.445s1.145-0.148,1.658-0.445l27.527-15.893
					c1.027-0.592,1.658-1.686,1.658-2.871V19.226c0-1.185-0.631-2.279-1.658-2.872L32.512,0.461
					C31.999,0.165,31.426,0.017,30.854,0.017"
      />
      <path
        fill="#005890"
        d="M22.858,33.664l-1.309-1.49h-0.311v1.49H20.17v-4.339h2.195c0.938,0,1.561,0.569,1.561,1.418
					c0,0.712-0.441,1.223-1.141,1.379l1.359,1.542H22.858z M22.249,31.28c0.383,0,0.629-0.207,0.629-0.525
					c0-0.31-0.246-0.517-0.629-0.517h-1.01v1.042H22.249z"
      />
      <path
        fill="#005890"
        d="M27.54,32.304h-2.383c0.084,0.388,0.35,0.628,0.699,0.628c0.24,0,0.518-0.059,0.691-0.376l0.922,0.188
					C27.19,33.43,26.6,33.76,25.856,33.76c-0.939,0-1.715-0.693-1.715-1.722c0-1.023,0.775-1.722,1.721-1.722
					c0.92,0,1.658,0.66,1.678,1.722V32.304z M25.176,31.669h1.34c-0.098-0.35-0.355-0.511-0.654-0.511
					C25.571,31.158,25.274,31.338,25.176,31.669"
      />
      <path
        fill="#005890"
        d="M29.186,30.206v0.207h0.893v0.822h-0.893v2.427h-1.031v-2.427H27.69v-0.822h0.465v-0.252
					c0-0.765,0.525-1.25,1.309-1.25c0.221,0,0.434,0.045,0.641,0.136l-0.219,0.803c-0.098-0.032-0.201-0.052-0.279-0.052
					C29.36,29.797,29.186,29.947,29.186,30.206 M31.444,33.662h-1.035V29h1.035V33.662z"
      />
      <path
        fill="#005890"
        d="M35.318,32.304h-2.384c0.085,0.388,0.351,0.628,0.701,0.628c0.238,0,0.518-0.059,0.693-0.376
					l0.918,0.188c-0.279,0.686-0.867,1.016-1.611,1.016c-0.939,0-1.716-0.693-1.716-1.722c0-1.023,0.777-1.722,1.722-1.722
					c0.92,0,1.658,0.66,1.678,1.722V32.304z M32.956,31.669h1.339c-0.098-0.35-0.357-0.511-0.654-0.511
					C33.35,31.158,33.051,31.338,32.956,31.669"
      />
      <path
        fill="#005890"
        d="M38.057,32.284l0.953,0.207c-0.162,0.771-0.848,1.269-1.658,1.269c-0.971,0-1.773-0.692-1.773-1.723
					c0-1.022,0.803-1.722,1.773-1.722c0.789,0,1.477,0.486,1.658,1.25l-0.971,0.233c-0.092-0.395-0.357-0.576-0.688-0.576
					c-0.467,0-0.775,0.343-0.775,0.815c0,0.48,0.309,0.817,0.775,0.817C37.682,32.854,37.947,32.679,38.057,32.284"
      />
      <path
        fill="#005890"
        d="M41.893,33.521c-0.252,0.135-0.52,0.238-0.889,0.238c-0.809,0-1.295-0.439-1.295-1.294v-1.231h-0.588
					v-0.822h0.588v-0.959h1.037v0.959h0.957v0.822h-0.957v1.127c0,0.337,0.162,0.472,0.434,0.472c0.143,0,0.336-0.051,0.473-0.129
					L41.893,33.521z"
      />
      <path
        fill="#007DC4"
        d="M19.823,37.729l-1.469,0.299c-0.109-0.572-0.582-0.852-1.063-0.852c-0.498,0-0.816,0.271-0.816,0.615
					c0,0.244,0.109,0.428,0.49,0.51l1.262,0.279c1.051,0.236,1.668,0.809,1.668,1.77c0,1.297-1.15,1.967-2.504,1.967
					c-1.369,0-2.492-0.652-2.656-1.84l1.543-0.299c0.152,0.625,0.59,0.896,1.195,0.896c0.563,0,0.898-0.254,0.898-0.625
					c0-0.271-0.127-0.445-0.563-0.537l-1.27-0.271c-0.871-0.199-1.631-0.652-1.631-1.758c0-1.18,0.979-1.915,2.402-1.915
					C18.635,35.968,19.596,36.621,19.823,37.729"
      />
      <path
        fill="#007DC4"
        d="M23.893,41.98c-0.354,0.191-0.725,0.336-1.242,0.336c-1.133,0-1.813-0.617-1.813-1.814v-1.723h-0.826
					v-1.15h0.826v-1.342h1.451v1.342h1.342v1.15H22.29v1.578c0,0.471,0.227,0.662,0.607,0.662c0.199,0,0.471-0.072,0.662-0.182
					L23.893,41.98z"
      />
      <path
        fill="#007DC4"
        d="M28.963,42.182h-1.451v-0.363c-0.354,0.316-0.826,0.498-1.414,0.498c-1.207,0-2.223-0.971-2.223-2.412
					c0-1.434,1.016-2.412,2.223-2.412c0.588,0,1.061,0.182,1.414,0.498v-0.361h1.451V42.182z M27.512,39.904
					c0-0.697-0.527-1.178-1.115-1.178c-0.635,0-1.098,0.48-1.098,1.178c0,0.707,0.463,1.18,1.098,1.18
					C26.985,41.084,27.512,40.611,27.512,39.904"
      />
      <path
        fill="#007DC4"
        d="M34.52,41.727c0,1.406-1.068,2.178-2.546,2.178c-1.043,0-1.842-0.363-2.285-1.098l1.205-0.652
					c0.346,0.408,0.598,0.561,1.07,0.561c0.664,0,1.134-0.389,1.134-1.105v-0.127c-0.328,0.281-0.781,0.436-1.371,0.436
					c-1.188,0-2.193-0.943-2.193-2.23c0-1.262,1.006-2.195,2.193-2.195c0.59,0,1.043,0.154,1.371,0.434v-0.299h1.422V41.727z
					 M33.098,39.688c0-0.609-0.482-1.035-1.062-1.035c-0.635,0-1.088,0.426-1.088,1.035c0,0.607,0.453,1.043,1.088,1.043
					C32.616,40.73,33.098,40.295,33.098,39.688"
      />
      <path
        fill="#007DC4"
        d="M39.861,40.277h-3.338c0.119,0.543,0.49,0.879,0.98,0.879c0.336,0,0.725-0.082,0.971-0.527l1.287,0.264
					c-0.391,0.963-1.215,1.424-2.258,1.424c-1.316,0-2.404-0.971-2.404-2.412c0-1.434,1.088-2.412,2.412-2.412
					c1.289,0,2.322,0.926,2.35,2.412V40.277z M36.551,39.387h1.877c-0.135-0.488-0.498-0.717-0.916-0.717
					C37.104,38.67,36.688,38.924,36.551,39.387"
      />
      <path
        fill="#007DC4"
        d="M46.553,41.064v1.117h-1.406v-1.117h-3.154v-1.342l2.711-3.618h1.85v3.763h0.734v1.197H46.553z
					 M45.146,37.547l-1.641,2.32h1.641V37.547z"
      />
      <rect x="26.155" y="17.466" fill="#ABD1E2" width="7.939" height="4.121" />
      <path
        fill="#005890"
        d="M33.023,13.074c-1.8,0-3.371,0.991-4.193,2.457c-0.416-0.163-0.869-0.252-1.344-0.252
					c-2.045,0-3.705,1.659-3.705,3.705c0,2.047,1.66,3.706,3.705,3.706c0.344,0,0.676-0.048,0.99-0.135
					c0.139,0.931,0.939,1.646,1.91,1.646c0.941,0,1.727-0.675,1.896-1.568c0.24,0.037,0.488,0.057,0.74,0.057
					c2.654,0,4.807-2.153,4.807-4.808C37.83,15.227,35.678,13.074,33.023,13.074 M31.04,18.932c0,0.361-0.293,0.653-0.652,0.653
					c-0.361,0-0.654-0.292-0.654-0.653c0-0.36,0.293-0.653,0.654-0.653C30.747,18.279,31.04,18.572,31.04,18.932 M27.75,18.932
					c0-0.36,0.291-0.653,0.652-0.653s0.652,0.293,0.652,0.653c0,0.361-0.291,0.653-0.652,0.653S27.75,19.293,27.75,18.932
					 M32.37,19.585c-0.361,0-0.652-0.292-0.652-0.653c0-0.36,0.291-0.653,0.652-0.653c0.359,0,0.652,0.293,0.652,0.653
					C33.021,19.293,32.729,19.585,32.37,19.585"
      />
      <path
        fill="#005890"
        d="M27.366,23.317c-0.643,0-1.166,0.522-1.166,1.167s0.523,1.167,1.166,1.167
					c0.646,0,1.168-0.522,1.168-1.167S28.012,23.317,27.366,23.317"
      />
      <path
        fill="#005890"
        d="M25.327,25.06c-0.361,0-0.652,0.292-0.652,0.653c0,0.361,0.291,0.653,0.652,0.653
					s0.654-0.292,0.654-0.653C25.981,25.352,25.688,25.06,25.327,25.06"
      />
      <path
        fill="#4F86C6"
        d="M30.854,59.533c-2.639,0-4.773-2.137-4.773-4.771c0-2.637,2.135-4.773,4.773-4.773
					c2.638,0,4.773,2.137,4.773,4.773C35.627,57.396,33.492,59.533,30.854,59.533"
      />
      <path
        fill="#FFFFFF"
        d="M30.854,49.988c2.638,0,4.773,2.137,4.773,4.773c0,2.635-2.135,4.771-4.773,4.771
					c-2.639,0-4.773-2.137-4.773-4.771C26.081,52.125,28.215,49.988,30.854,49.988 M30.854,49.576c-2.859,0-5.186,2.326-5.186,5.186
					c0,2.857,2.326,5.182,5.186,5.182c2.859,0,5.185-2.324,5.185-5.182C36.039,51.902,33.713,49.576,30.854,49.576"
      />
      <path
        fill="#FFFFFF"
        d="M33.348,52.816c-0.24,0-0.464,0.107-0.621,0.25l-0.012-0.012c0.072-0.119,0.168-0.346,0.168-0.717
					c0-0.883-0.883-1.551-2.029-1.551c-1.145,0-2.041,0.668-2.041,1.551c0,0.371,0.119,0.598,0.18,0.717l-0.012,0.012
					c-0.156-0.143-0.383-0.25-0.621-0.25c-0.729,0-1.455,0.703-1.455,2.016c0,1.289,0.717,2.018,1.492,2.018
					c0.715,0,1.217-0.396,1.67-0.848c-0.072-0.107-0.18-0.217-0.299-0.217s-0.252,0.086-0.477,0.264
					c-0.299,0.229-0.563,0.359-0.908,0.359c-0.609,0-0.869-0.682-0.869-1.576c0-0.955,0.25-1.586,0.895-1.586
					c0.285,0,0.477,0.082,0.668,0.262c0.285,0.264,0.381,0.334,0.512,0.334c0.096,0,0.156-0.061,0.18-0.096
					c-0.215-0.215-0.523-0.668-0.523-1.252c0-0.668,0.559-1.133,1.609-1.133c1.049,0,1.611,0.465,1.611,1.133
					c0,0.584-0.311,1.037-0.525,1.252c0.023,0.035,0.084,0.096,0.18,0.096c0.131,0,0.227-0.07,0.512-0.334
					c0.18-0.18,0.382-0.262,0.667-0.262c0.645,0,0.896,0.631,0.896,1.586c0,0.895-0.264,1.576-0.871,1.576
					c-0.345,0-0.621-0.131-0.906-0.359c-0.229-0.178-0.359-0.264-0.479-0.264s-0.227,0.109-0.299,0.217
					c0.441,0.451,0.955,0.848,1.671,0.848c0.775,0,1.49-0.729,1.49-2.018C34.803,53.52,34.074,52.816,33.348,52.816"
      />
      <path
        fill="#FFFFFF"
        d="M31.653,52.793h-0.537c-0.033,0-0.059-0.025-0.07-0.049l-0.168-0.514c-0.012-0.033-0.023-0.033-0.035,0
					l-0.166,0.514c-0.014,0.037-0.037,0.049-0.061,0.049h-0.537c-0.023,0-0.035,0.01-0.012,0.021l0.441,0.324
					c0.023,0.023,0.023,0.061,0.012,0.082l-0.168,0.502c-0.01,0.023-0.01,0.047,0.025,0.023l0.441-0.309
					c0.035-0.025,0.059-0.025,0.094,0l0.432,0.309c0.021,0.023,0.035,0.012,0.021-0.012L31.2,53.221
					c-0.012-0.035,0-0.07,0.025-0.082l0.439-0.324C31.69,52.803,31.678,52.793,31.653,52.793"
      />
      <path
        fill="#FFFFFF"
        d="M31.249,56.322c-0.227-0.572-0.406-0.965-0.406-1.441c0-0.203,0.061-0.383,0.096-0.479
					c0.047-0.096,0.061-0.154,0.012-0.154c-0.072,0-0.227,0.166-0.322,0.311c-0.072,0.119-0.154,0.346-0.154,0.523
					c0,0.336,0.107,0.693,0.178,0.906c0.133,0.371,0.252,0.801,0.252,1.207c0,0.645-0.287,1.084-0.801,1.479
					c0.049,0.037,0.168,0.096,0.311,0.096c0.5,0,1.098-0.561,1.098-1.324C31.51,56.99,31.379,56.682,31.249,56.322"
      />
    </g>
  </g>
);

const ReflectStage3 = () => (
  <g id="Stage_3_7_">
    <polygon
      fill="#ABD1E2"
      points="57.576,19.703 57.576,50.545 30.867,65.965 4.157,50.545 4.157,19.703 30.867,4.282 			"
    />
    <polygon
      fill="#60C1E5"
      points="57.576,50.545 30.869,65.965 30.869,35.125 			"
    />
    <polygon
      fill="#60C1E5"
      points="57.576,19.705 57.576,50.545 30.869,35.125 			"
    />
    <polygon
      fill="#60C1E5"
      points="30.867,4.279 57.576,19.699 30.867,35.119 			"
    />
    <g>
      <path
        fill="#00ADEE"
        d="M30.867,4.595l26.438,15.263v30.528L30.867,65.65L4.429,50.387V19.858L30.867,4.595 M30.867,0.021
					c-0.572,0-1.145,0.148-1.658,0.445L1.681,16.357c-1.027,0.593-1.658,1.687-1.658,2.872v31.786c0,1.186,0.631,2.279,1.658,2.871
					l27.527,15.893c0.514,0.297,1.086,0.445,1.658,0.445s1.145-0.148,1.658-0.445l27.528-15.893
					c1.025-0.592,1.658-1.686,1.658-2.871V19.229c0-1.185-0.633-2.279-1.658-2.872L32.525,0.465
					C32.011,0.168,31.439,0.021,30.867,0.021"
      />
      <path
        fill="#005890"
        d="M22.871,33.668l-1.309-1.49h-0.311v1.49h-1.07v-4.339h2.197c0.938,0,1.559,0.57,1.559,1.418
					c0,0.712-0.439,1.224-1.139,1.379l1.359,1.542H22.871z M22.261,31.284c0.381,0,0.627-0.207,0.627-0.524
					c0-0.311-0.246-0.518-0.627-0.518h-1.01v1.042H22.261z"
      />
      <path
        fill="#005890"
        d="M27.552,32.308h-2.385c0.086,0.388,0.352,0.628,0.701,0.628c0.238,0,0.518-0.058,0.691-0.376l0.92,0.188
					c-0.279,0.686-0.867,1.018-1.611,1.018c-0.939,0-1.717-0.695-1.717-1.724c0-1.023,0.777-1.722,1.723-1.722
					c0.92,0,1.658,0.66,1.678,1.722V32.308z M25.189,31.673h1.34c-0.098-0.35-0.357-0.511-0.654-0.511
					C25.583,31.162,25.285,31.343,25.189,31.673"
      />
      <path
        fill="#005890"
        d="M29.199,30.209v0.207h0.893v0.822h-0.893v2.429h-1.031v-2.429h-0.465v-0.822h0.465v-0.252
					c0-0.764,0.525-1.25,1.309-1.25c0.221,0,0.434,0.045,0.641,0.136l-0.221,0.803c-0.096-0.032-0.199-0.052-0.277-0.052
					C29.373,29.801,29.199,29.951,29.199,30.209 M31.457,33.668h-1.035v-4.663h1.035V33.668z"
      />
      <path
        fill="#005890"
        d="M35.332,32.308h-2.385c0.086,0.388,0.35,0.628,0.7,0.628c0.24,0,0.52-0.058,0.693-0.376l0.92,0.188
					c-0.279,0.686-0.869,1.018-1.613,1.018c-0.938,0-1.715-0.695-1.715-1.724c0-1.023,0.777-1.722,1.723-1.722
					c0.92,0,1.656,0.66,1.678,1.722V32.308z M32.966,31.673h1.34c-0.096-0.35-0.355-0.511-0.652-0.511
					C33.363,31.162,33.064,31.343,32.966,31.673"
      />
      <path
        fill="#005890"
        d="M38.07,32.288l0.953,0.207c-0.162,0.771-0.85,1.269-1.658,1.269c-0.971,0-1.775-0.692-1.775-1.722
					c0-1.023,0.805-1.722,1.775-1.722c0.789,0,1.477,0.485,1.658,1.249l-0.973,0.233c-0.09-0.395-0.355-0.576-0.686-0.576
					c-0.467,0-0.777,0.343-0.777,0.816c0,0.479,0.311,0.816,0.777,0.816C37.695,32.858,37.961,32.683,38.07,32.288"
      />
      <path
        fill="#005890"
        d="M41.904,33.525c-0.252,0.135-0.518,0.238-0.887,0.238c-0.809,0-1.295-0.439-1.295-1.294v-1.231h-0.588
					v-0.822h0.588v-0.958h1.037v0.958h0.957v0.822H40.76v1.127c0,0.337,0.16,0.472,0.434,0.472c0.141,0,0.336-0.051,0.473-0.129
					L41.904,33.525z"
      />
      <path
        fill="#007DC4"
        d="M20.056,37.732l-1.469,0.299c-0.107-0.57-0.58-0.852-1.061-0.852c-0.498,0-0.816,0.271-0.816,0.617
					c0,0.244,0.109,0.426,0.49,0.508l1.26,0.279c1.053,0.236,1.67,0.809,1.67,1.77c0,1.297-1.152,1.967-2.504,1.967
					c-1.369,0-2.494-0.652-2.658-1.84l1.543-0.299c0.154,0.625,0.59,0.896,1.197,0.896c0.563,0,0.898-0.254,0.898-0.627
					c0-0.27-0.127-0.443-0.563-0.533l-1.27-0.271c-0.871-0.201-1.633-0.654-1.633-1.76c0-1.18,0.979-1.915,2.402-1.915
					C18.869,35.972,19.83,36.626,20.056,37.732"
      />
      <path
        fill="#007DC4"
        d="M24.128,41.984c-0.354,0.191-0.725,0.336-1.242,0.336c-1.135,0-1.814-0.617-1.814-1.813v-1.725h-0.824
					v-1.15h0.824v-1.344h1.451v1.344h1.342v1.15h-1.342v1.578c0,0.471,0.227,0.662,0.607,0.662c0.199,0,0.473-0.072,0.662-0.18
					L24.128,41.984z"
      />
      <path
        fill="#007DC4"
        d="M29.197,42.184h-1.451v-0.361c-0.354,0.316-0.824,0.498-1.414,0.498c-1.207,0-2.223-0.971-2.223-2.412
					c0-1.434,1.016-2.412,2.223-2.412c0.59,0,1.061,0.182,1.414,0.5v-0.363h1.451V42.184z M27.746,39.908
					c0-0.697-0.525-1.18-1.115-1.18c-0.635,0-1.096,0.482-1.096,1.18c0,0.707,0.461,1.18,1.096,1.18
					C27.22,41.088,27.746,40.615,27.746,39.908"
      />
      <path
        fill="#007DC4"
        d="M34.756,41.73c0,1.406-1.07,2.178-2.547,2.178c-1.043,0-1.842-0.363-2.287-1.098l1.207-0.654
					c0.344,0.41,0.598,0.564,1.07,0.564c0.662,0,1.133-0.391,1.133-1.107v-0.127c-0.326,0.281-0.78,0.436-1.37,0.436
					c-1.189,0-2.195-0.943-2.195-2.23c0-1.262,1.006-2.195,2.195-2.195c0.59,0,1.043,0.154,1.37,0.436v-0.301h1.424V41.73z
					 M33.332,39.691c0-0.609-0.481-1.035-1.061-1.035c-0.635,0-1.088,0.426-1.088,1.035c0,0.605,0.453,1.043,1.088,1.043
					C32.851,40.734,33.332,40.297,33.332,39.691"
      />
      <path
        fill="#007DC4"
        d="M40.096,40.281H36.76c0.117,0.543,0.49,0.879,0.979,0.879c0.336,0,0.727-0.082,0.971-0.527l1.287,0.264
					c-0.391,0.963-1.215,1.424-2.258,1.424c-1.314,0-2.402-0.971-2.402-2.412c0-1.434,1.088-2.412,2.412-2.412
					c1.287,0,2.322,0.926,2.348,2.412V40.281z M36.787,39.391h1.877c-0.137-0.488-0.5-0.717-0.916-0.717
					C37.34,38.674,36.922,38.928,36.787,39.391"
      />
      <path
        fill="#007DC4"
        d="M46.969,40.361c0,1.152-0.934,1.959-2.266,1.959c-1.279,0-2.213-0.744-2.35-1.922l1.414-0.264
					c0.082,0.6,0.408,0.943,0.943,0.943c0.508,0,0.871-0.291,0.871-0.734c0-0.398-0.299-0.635-0.824-0.635h-0.818v-1.188h0.754
					c0.518,0,0.816-0.227,0.816-0.617c0-0.418-0.318-0.697-0.807-0.697c-0.49,0-0.854,0.299-0.908,0.979l-1.369-0.264
					c0.137-1.207,1.035-1.949,2.268-1.949c1.297,0,2.178,0.798,2.178,1.912c0,0.555-0.236,0.98-0.654,1.227
					C46.697,39.355,46.969,39.791,46.969,40.361"
      />
      <rect x="26.169" y="17.469" fill="#ABD1E2" width="7.938" height="4.121" />
      <path
        fill="#005890"
        d="M33.035,13.078c-1.801,0-3.37,0.991-4.192,2.457c-0.418-0.163-0.869-0.252-1.344-0.252
					c-2.047,0-3.705,1.659-3.705,3.705c0,2.047,1.658,3.706,3.705,3.706c0.344,0,0.674-0.048,0.99-0.135
					c0.139,0.931,0.939,1.646,1.908,1.646c0.943,0,1.729-0.675,1.898-1.568c0.24,0.037,0.488,0.057,0.739,0.057
					c2.656,0,4.809-2.153,4.809-4.808C37.844,15.23,35.691,13.078,33.035,13.078 M31.05,18.936c0,0.361-0.291,0.653-0.652,0.653
					c-0.359,0-0.652-0.292-0.652-0.653c0-0.36,0.293-0.653,0.652-0.653C30.759,18.283,31.05,18.576,31.05,18.936 M27.761,18.936
					c0-0.36,0.293-0.653,0.654-0.653c0.359,0,0.652,0.293,0.652,0.653c0,0.361-0.293,0.653-0.652,0.653
					C28.054,19.589,27.761,19.297,27.761,18.936 M32.382,19.589c-0.361,0-0.654-0.292-0.654-0.653c0-0.36,0.293-0.653,0.654-0.653
					s0.653,0.293,0.653,0.653C33.035,19.297,32.744,19.589,32.382,19.589"
      />
      <path
        fill="#005890"
        d="M27.378,23.321c-0.645,0-1.166,0.522-1.166,1.167s0.521,1.167,1.166,1.167s1.168-0.522,1.168-1.167
					S28.023,23.321,27.378,23.321"
      />
      <path
        fill="#005890"
        d="M25.339,25.064c-0.361,0-0.652,0.292-0.652,0.653c0,0.361,0.291,0.653,0.652,0.653
					s0.652-0.292,0.652-0.653C25.992,25.356,25.701,25.064,25.339,25.064"
      />
      <path
        fill="#4F86C6"
        d="M30.867,59.537c-2.639,0-4.773-2.137-4.773-4.771c0-2.637,2.135-4.773,4.773-4.773
					c2.639,0,4.774,2.137,4.774,4.773C35.641,57.4,33.506,59.537,30.867,59.537"
      />
      <path
        fill="#FFFFFF"
        d="M30.867,49.992c2.639,0,4.774,2.135,4.774,4.773c0,2.635-2.135,4.771-4.774,4.771
					c-2.639,0-4.773-2.137-4.773-4.771C26.093,52.127,28.228,49.992,30.867,49.992 M30.867,49.58c-2.859,0-5.186,2.326-5.186,5.186
					c0,2.857,2.326,5.182,5.186,5.182c2.86,0,5.186-2.324,5.186-5.182C36.053,51.906,33.727,49.58,30.867,49.58"
      />
      <path
        fill="#FFFFFF"
        d="M33.361,52.82c-0.24,0-0.465,0.107-0.622,0.25l-0.012-0.012c0.07-0.119,0.168-0.346,0.168-0.717
					c0-0.883-0.885-1.551-2.029-1.551s-2.041,0.668-2.041,1.551c0,0.371,0.121,0.598,0.18,0.717l-0.012,0.012
					c-0.156-0.143-0.383-0.25-0.621-0.25c-0.729,0-1.455,0.703-1.455,2.016c0,1.289,0.715,2.018,1.492,2.018
					c0.715,0,1.217-0.396,1.67-0.848c-0.072-0.107-0.18-0.217-0.299-0.217s-0.252,0.086-0.477,0.264
					c-0.299,0.229-0.563,0.359-0.908,0.359c-0.609,0-0.869-0.682-0.869-1.576c0-0.955,0.25-1.586,0.895-1.586
					c0.285,0,0.477,0.082,0.668,0.262c0.285,0.264,0.381,0.334,0.512,0.334c0.096,0,0.156-0.061,0.18-0.096
					c-0.215-0.215-0.525-0.668-0.525-1.252c0-0.668,0.561-1.133,1.611-1.133c1.049,0,1.611,0.465,1.611,1.133
					c0,0.584-0.311,1.037-0.525,1.252c0.023,0.035,0.084,0.096,0.178,0.096c0.133,0,0.229-0.07,0.514-0.334
					c0.18-0.18,0.383-0.262,0.668-0.262c0.645,0,0.895,0.631,0.895,1.586c0,0.895-0.262,1.576-0.869,1.576
					c-0.346,0-0.622-0.131-0.909-0.359c-0.227-0.178-0.357-0.264-0.477-0.264s-0.227,0.109-0.299,0.217
					c0.441,0.451,0.955,0.848,1.67,0.848c0.777,0,1.492-0.729,1.492-2.018C34.816,53.523,34.088,52.82,33.361,52.82"
      />
      <path
        fill="#FFFFFF"
        d="M31.666,52.797h-0.537c-0.035,0-0.059-0.025-0.07-0.049l-0.168-0.514c-0.012-0.033-0.023-0.033-0.035,0
					l-0.168,0.514c-0.012,0.037-0.035,0.049-0.059,0.049h-0.539c-0.021,0-0.035,0.01-0.012,0.021l0.443,0.324
					c0.023,0.023,0.023,0.061,0.012,0.082l-0.168,0.502c-0.01,0.023-0.01,0.047,0.025,0.023l0.441-0.309
					c0.035-0.025,0.059-0.025,0.094,0l0.432,0.309c0.023,0.023,0.033,0.012,0.023-0.012l-0.168-0.514
					c-0.012-0.035,0-0.07,0.023-0.082l0.441-0.324C31.703,52.807,31.689,52.797,31.666,52.797"
      />
      <path
        fill="#FFFFFF"
        d="M31.261,56.326c-0.227-0.572-0.406-0.965-0.406-1.441c0-0.203,0.059-0.383,0.096-0.479
					c0.047-0.096,0.061-0.154,0.01-0.154c-0.07,0-0.225,0.166-0.32,0.311c-0.072,0.119-0.156,0.346-0.156,0.523
					c0,0.336,0.107,0.693,0.18,0.906c0.131,0.371,0.25,0.801,0.25,1.207c0,0.645-0.287,1.084-0.799,1.479
					c0.047,0.037,0.168,0.096,0.311,0.096c0.5,0,1.098-0.561,1.098-1.324C31.523,56.994,31.392,56.686,31.261,56.326"
      />
    </g>
  </g>
);
// #endregion

const Network = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <NetworkStage3 {...otherProps} />;
    case 4:
      return <NetworkStage4 {...otherProps} />;
    case 5:
      return <NetworkStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

const Reflect = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <ReflectStage3 {...otherProps} />;
    case 4:
      return <ReflectStage4 {...otherProps} />;
    case 5:
      return <ReflectStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

function KnowMyself(props: any) {
  const { skill, stage, fallback = null, ...otherProps } = props;

  switch (skill) {
    case 'Network':
      return <Network stage={stage} {...otherProps} fallback={fallback} />;
    case 'Reflect':
      return <Reflect stage={stage} {...otherProps} fallback={fallback} />;
    default:
      return fallback;
  }
}

export default KnowMyself;
