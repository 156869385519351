import Base, { AwardProps } from './Base';

function SkillsForMyFuture(props: AwardProps) {
  return (
    <Base {...props}>
      <g id="Skills_For_My_Future_Award">
        <g>
          <defs>
            <rect id="SVGID_1_" x="0" y="0" width="53.513" height="66.894" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_2_)"
            fill="#FFFFFF"
            d="M26.757,66.223L26.757,66.223c-14.481,0-26.222-11.74-26.222-26.222V5.744
			c0-2.804,2.271-5.075,5.075-5.075h42.293c2.803,0,5.076,2.271,5.076,5.075v34.258C52.98,54.483,41.24,66.223,26.757,66.223"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#D1D1CE"
            d="M16.624,51.559l11.883,11.883c11.832-0.896,21.312-10.804,21.312-22.86
			l0.039-4.971l-8.643-8.644L16.624,51.559z"
          />
          <rect
            x="2.453"
            y="2.361"
            clipPath="url(#SVGID_2_)"
            fill="#F5ACCC"
            width="48.465"
            height="19.121"
          />

          <line
            clipPath="url(#SVGID_2_)"
            fill="none"
            stroke="#E9CAD8"
            strokeWidth="3.822"
            strokeMiterlimit="10"
            x1="3.093"
            y1="22.01"
            x2="50.643"
            y2="22.01"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M13.778,8.659c0.015,0.123,0.021,0.227,0.021,0.314
			c0,1.047-0.749,1.8-1.791,1.8c-1.046,0-1.861-0.789-1.861-1.806c0-1.011,0.811-1.805,1.847-1.805c0.768,0,1.445,0.36,1.688,1.036
			l-0.82,0.237c-0.201-0.392-0.532-0.495-0.867-0.495c-0.563,0-0.99,0.444-0.99,1.026c0,0.589,0.438,1.032,1.017,1.032
			c0.454,0,0.783-0.237,0.913-0.619h-1.058V8.659H13.778z"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M16.869,10.696h-0.825v-0.248c-0.197,0.207-0.47,0.325-0.795,0.325
			c-0.604,0-1.006-0.428-1.006-1.078V8.107h0.819V9.52c0,0.304,0.182,0.51,0.444,0.51c0.325,0,0.537-0.221,0.537-0.722V8.107h0.825
			V10.696z"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M18.402,7.342c0,0.264-0.201,0.459-0.48,0.459
			c-0.278,0-0.475-0.195-0.475-0.459c0-0.252,0.196-0.469,0.475-0.469C18.201,6.874,18.402,7.09,18.402,7.342 M17.508,8.106h0.826
			v2.589h-0.826V8.106z"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M21.698,10.696h-0.825v-0.207c-0.202,0.182-0.47,0.284-0.805,0.284
			c-0.686,0-1.264-0.552-1.264-1.372c0-0.814,0.578-1.372,1.264-1.372c0.335,0,0.603,0.104,0.805,0.283V6.981h0.825V10.696z
			 M20.873,9.401c0-0.396-0.299-0.671-0.635-0.671c-0.36,0-0.624,0.274-0.624,0.671c0,0.402,0.264,0.67,0.624,0.67
			C20.574,10.071,20.873,9.803,20.873,9.401"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M24.833,9.613h-1.897c0.066,0.309,0.279,0.5,0.558,0.5
			c0.191,0,0.413-0.046,0.552-0.299l0.732,0.148c-0.222,0.548-0.691,0.811-1.284,0.811c-0.748,0-1.367-0.552-1.367-1.372
			c0-0.815,0.619-1.372,1.372-1.372c0.733,0,1.32,0.526,1.335,1.372V9.613z M22.952,9.107h1.068C23.943,8.828,23.736,8.7,23.499,8.7
			C23.267,8.7,23.029,8.844,22.952,9.107"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M25.893,9.855c0.035,0.222,0.236,0.304,0.459,0.304
			c0.217,0,0.335-0.093,0.335-0.206c0-0.088-0.067-0.154-0.259-0.19l-0.525-0.104c-0.48-0.088-0.753-0.346-0.753-0.753
			c0-0.531,0.459-0.877,1.114-0.877c0.645,0,1.072,0.294,1.176,0.742l-0.753,0.15c-0.025-0.16-0.181-0.305-0.434-0.305
			c-0.222,0-0.294,0.108-0.294,0.201c0,0.072,0.031,0.145,0.196,0.18l0.609,0.124c0.488,0.104,0.716,0.398,0.716,0.779
			c0,0.573-0.494,0.872-1.175,0.872c-0.609,0-1.13-0.222-1.219-0.753L25.893,9.855z"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M31.902,8.164l-0.836,0.17c-0.061-0.325-0.33-0.485-0.604-0.485
			c-0.283,0-0.465,0.155-0.465,0.351c0,0.14,0.063,0.242,0.279,0.289l0.717,0.16c0.6,0.134,0.949,0.459,0.949,1.005
			c0,0.739-0.654,1.12-1.424,1.12c-0.779,0-1.418-0.372-1.51-1.047l0.877-0.17c0.086,0.356,0.334,0.51,0.68,0.51
			c0.32,0,0.512-0.144,0.512-0.354c0-0.155-0.072-0.253-0.32-0.306l-0.723-0.154c-0.496-0.113-0.928-0.371-0.928-1
			c0-0.671,0.557-1.089,1.367-1.089C31.227,7.163,31.773,7.533,31.902,8.164"
          />
          <polygon
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            points="34.266,10.696 33.426,9.762 33.275,9.762 33.275,10.696
			32.449,10.696 32.449,6.982 33.275,6.982 33.275,8.988 33.389,8.988 34.209,8.107 35.205,8.107 34.07,9.338 35.324,10.696 		"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M36.541,7.342c0,0.264-0.201,0.459-0.479,0.459
			c-0.279,0-0.475-0.195-0.475-0.459c0-0.252,0.195-0.469,0.475-0.469C36.34,6.874,36.541,7.09,36.541,7.342 M35.648,8.106h0.826
			v2.589h-0.826V8.106z"
          />
          <rect
            x="37.115"
            y="6.982"
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            width="0.824"
            height="3.714"
          />
          <rect
            x="38.539"
            y="6.982"
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            width="0.824"
            height="3.714"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M40.592,9.855c0.035,0.222,0.236,0.304,0.459,0.304
			c0.217,0,0.336-0.093,0.336-0.206c0-0.088-0.068-0.154-0.258-0.19l-0.527-0.104c-0.48-0.088-0.754-0.346-0.754-0.753
			c0-0.531,0.459-0.877,1.115-0.877c0.645,0,1.072,0.294,1.176,0.742l-0.752,0.15c-0.027-0.16-0.182-0.305-0.436-0.305
			c-0.221,0-0.293,0.108-0.293,0.201c0,0.072,0.031,0.145,0.195,0.18l0.609,0.124c0.49,0.104,0.717,0.398,0.717,0.779
			c0,0.573-0.494,0.872-1.176,0.872c-0.609,0-1.131-0.222-1.219-0.753L40.592,9.855z"
          />
          <polygon
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            points="10.254,14.366 11.874,14.366 11.874,15.109 10.254,15.109
			10.254,16.347 9.403,16.347 9.403,12.891 12.075,12.891 12.075,13.639 10.254,13.639 		"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M15.093,15.052c0,0.819-0.65,1.366-1.418,1.366
			c-0.785,0-1.435-0.547-1.435-1.366c0-0.815,0.649-1.362,1.435-1.362C14.443,13.69,15.093,14.237,15.093,15.052 M14.299,15.052
			c0-0.403-0.304-0.671-0.624-0.671c-0.335,0-0.64,0.268-0.64,0.671c0,0.406,0.305,0.67,0.64,0.67
			C13.996,15.722,14.299,15.458,14.299,15.052"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M17.425,13.742l-0.036,0.825H17.24c-0.577,0-0.892,0.3-0.892,0.97v0.81h-0.826
			v-2.589h0.826v0.495c0.186-0.31,0.469-0.532,0.892-0.532C17.312,13.72,17.364,13.726,17.425,13.742"
          />
          <polygon
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            points="22.961,16.346 22.136,16.346 22.136,14.504 21.167,16.346
			20.774,16.346 19.799,14.495 19.799,16.346 18.974,16.346 18.974,12.891 19.809,12.891 20.97,15.083 22.131,12.891 22.961,12.891
					"
          />
          <polygon
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            points="24.845,17.223 23.953,17.223 24.37,16.258 23.297,13.752
			24.185,13.752 24.799,15.263 25.454,13.752 26.341,13.752 		"
          />
          <polygon
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            points="28.725,14.366 30.346,14.366 30.346,15.109 28.725,15.109
			28.725,16.347 27.874,16.347 27.874,12.891 30.545,12.891 30.545,13.639 28.725,13.639 		"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M33.502,16.346h-0.826v-0.247c-0.195,0.206-0.469,0.324-0.793,0.324
			c-0.604,0-1.006-0.428-1.006-1.078v-1.588h0.82v1.413c0,0.305,0.18,0.51,0.443,0.51c0.324,0,0.535-0.221,0.535-0.722v-1.201h0.826
			V16.346z"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M36.02,16.233c-0.201,0.108-0.412,0.191-0.705,0.191
			c-0.646,0-1.033-0.352-1.033-1.032v-0.98h-0.469v-0.654h0.469v-0.764h0.826v0.764h0.764v0.654h-0.764v0.898
			c0,0.268,0.129,0.376,0.346,0.376c0.113,0,0.268-0.041,0.377-0.103L36.02,16.233z"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M38.873,16.346h-0.824v-0.247c-0.197,0.206-0.471,0.324-0.795,0.324
			c-0.604,0-1.006-0.428-1.006-1.078v-1.588h0.82v1.413c0,0.305,0.18,0.51,0.443,0.51c0.324,0,0.537-0.221,0.537-0.722v-1.201h0.824
			V16.346z"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M41.377,13.742l-0.037,0.825h-0.15c-0.576,0-0.891,0.3-0.891,0.97v0.81h-0.826
			v-2.589h0.826v0.495c0.186-0.31,0.469-0.532,0.891-0.532C41.264,13.72,41.314,13.726,41.377,13.742"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M44.248,15.264h-1.896c0.066,0.309,0.279,0.5,0.557,0.5
			c0.191,0,0.414-0.047,0.553-0.3l0.732,0.149c-0.223,0.547-0.691,0.81-1.285,0.81c-0.748,0-1.365-0.552-1.365-1.372
			c0-0.814,0.617-1.371,1.371-1.371c0.732,0,1.32,0.525,1.334,1.371V15.264z M42.367,14.757h1.068
			c-0.078-0.278-0.285-0.406-0.521-0.406C42.682,14.351,42.443,14.495,42.367,14.757"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#E9CAD8"
            d="M48.336,3.821c0.748,0,1.357,0.609,1.357,1.357v34.957
			c0,12.646-10.289,22.935-22.936,22.935c-12.646,0-22.934-10.289-22.934-22.935V5.178c0-0.748,0.607-1.357,1.355-1.357H48.336
			 M48.336,0H5.179C2.32,0,0,2.317,0,5.178v34.957c0,14.777,11.98,26.758,26.757,26.758c14.778,0,26.756-11.98,26.756-26.758V5.178
			C53.514,2.317,51.195,0,48.336,0"
          />
          <polygon
            clipPath="url(#SVGID_2_)"
            fill="#D1D1CE"
            points="37.092,42.15 31.752,47.488 18.797,34.534 22.977,28.037 		"
          />
          <polygon
            clipPath="url(#SVGID_2_)"
            fill="#D1D1CE"
            points="32.158,44.551 26.637,50.073 13.681,37.117 22.291,34.684 		"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#738086"
            d="M37.316,51.601L37.316,51.601c-1.193,1.193-3.131,1.193-4.324,0L19.866,38.475
			c-1.193-1.194-1.193-3.131,0-4.325c1.194-1.194,3.131-1.194,4.325,0l13.125,13.126C38.512,48.47,38.512,50.407,37.316,51.601"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#979FA3"
            d="M36.264,48.333c0.611,0.612,0.611,1.603,0,2.215
			c-0.611,0.611-1.604,0.611-2.215,0c-0.611-0.612-0.611-1.603,0-2.215C34.66,47.721,35.652,47.721,36.264,48.333"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#FFFFFF"
            d="M35.6,48.997c0.244,0.245,0.244,0.642,0,0.887
			c-0.246,0.245-0.641,0.245-0.887,0c-0.244-0.245-0.244-0.642,0-0.887C34.959,48.752,35.354,48.752,35.6,48.997"
          />

          <rect
            x="25.24"
            y="39.928"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 36.9684 -7.1061)"
            clipPath="url(#SVGID_2_)"
            fill="#5D2453"
            width="3.643"
            height="2.288"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M16.632,51.489L16.632,51.489c-1.361-1.361-1.361-3.565-0.001-4.926
			l6.504-6.504c0.428-0.428,1.121-0.428,1.549,0l3.377,3.377c0.428,0.427,0.428,1.121,0,1.548l-6.504,6.505
			C20.197,52.849,17.993,52.849,16.632,51.489"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#792859"
            d="M29.26,41.998l-3.25-3.249c-0.386-0.386-0.386-1.012,0-1.396
			c0.385-0.386,1.012-0.386,1.396,0l3.25,3.25c0.385,0.386,0.385,1.011,0,1.396C30.27,42.384,29.645,42.384,29.26,41.998"
          />
          <polygon
            clipPath="url(#SVGID_2_)"
            fill="#979FA3"
            points="38.012,31.338 29.703,39.649 28.416,38.361 36.725,30.052
			37.447,28.236 40.51,26.267 41.742,27.498 39.771,30.56 		"
          />

          <line
            clipPath="url(#SVGID_2_)"
            fill="none"
            stroke="#F5ACCC"
            strokeWidth="0.637"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="23.687"
            y1="42.036"
            x2="17.736"
            y2="47.987"
          />

          <line
            clipPath="url(#SVGID_2_)"
            fill="none"
            stroke="#F5ACCC"
            strokeWidth="0.637"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="24.849"
            y1="43.199"
            x2="18.898"
            y2="49.15"
          />

          <line
            clipPath="url(#SVGID_2_)"
            fill="none"
            stroke="#F5ACCC"
            strokeWidth="0.637"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="26.011"
            y1="44.36"
            x2="20.06"
            y2="50.311"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#979FA3"
            d="M16.418,26.836l3.359,3.358c0.099,0.1,0.135,0.246,0.092,0.379l-0.826,2.574
			c-0.037,0.116-0.127,0.206-0.243,0.242l-2.574,0.827c-0.133,0.043-0.279,0.007-0.379-0.092l-3.325-3.326
			c-0.075-0.074-0.201-0.043-0.232,0.056c-0.72,2.317-0.119,4.955,1.803,6.732c2.545,2.353,6.528,2.285,8.996-0.148
			c2.579-2.543,2.59-6.695,0.034-9.251c-1.799-1.799-4.387-2.326-6.649-1.583C16.375,26.637,16.345,26.762,16.418,26.836"
          />
          <polygon
            clipPath="url(#SVGID_2_)"
            fill="#738086"
            points="38.012,31.338 39.771,30.56 41.742,27.498 41.189,26.945
			29.094,39.04 29.703,39.649 		"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#4F86C6"
            d="M26.757,61.278c-2.288,0-4.141-1.854-4.141-4.141
			c0-2.289,1.853-4.142,4.141-4.142c2.288,0,4.141,1.853,4.141,4.142C30.898,59.424,29.045,61.278,26.757,61.278"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#FFFFFF"
            d="M26.757,52.996c2.288,0,4.141,1.854,4.141,4.141
			c0,2.288-1.854,4.143-4.141,4.143c-2.288,0-4.141-1.854-4.141-4.143C22.617,54.849,24.469,52.996,26.757,52.996 M26.757,52.638
			c-2.48,0-4.498,2.019-4.498,4.498c0,2.481,2.018,4.498,4.498,4.498c2.481,0,4.499-2.017,4.499-4.498
			C31.256,54.657,29.238,52.638,26.757,52.638"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#FFFFFF"
            d="M28.92,55.449c-0.207,0-0.402,0.094-0.538,0.219l-0.011-0.012
			c0.063-0.103,0.146-0.3,0.146-0.62c0-0.767-0.768-1.347-1.761-1.347s-1.771,0.58-1.771,1.347c0,0.32,0.104,0.518,0.156,0.62
			l-0.012,0.012c-0.134-0.125-0.331-0.219-0.537-0.219c-0.633,0-1.264,0.61-1.264,1.75c0,1.118,0.621,1.75,1.294,1.75
			c0.621,0,1.058-0.343,1.449-0.735c-0.063-0.093-0.155-0.188-0.258-0.188c-0.104,0-0.219,0.074-0.414,0.229
			c-0.259,0.197-0.487,0.311-0.788,0.311c-0.527,0-0.754-0.591-0.754-1.367c0-0.828,0.217-1.377,0.775-1.377
			c0.248,0,0.413,0.072,0.58,0.229c0.248,0.228,0.33,0.289,0.444,0.289c0.084,0,0.135-0.052,0.156-0.082
			c-0.188-0.188-0.456-0.58-0.456-1.087c0-0.58,0.486-0.984,1.397-0.984c0.91,0,1.398,0.404,1.398,0.984
			c0,0.507-0.271,0.899-0.456,1.087c0.02,0.03,0.072,0.082,0.155,0.082c0.113,0,0.196-0.062,0.444-0.289
			c0.155-0.156,0.332-0.229,0.58-0.229c0.561,0,0.777,0.549,0.777,1.377c0,0.776-0.229,1.367-0.756,1.367
			c-0.3,0-0.538-0.113-0.787-0.311c-0.197-0.155-0.311-0.229-0.414-0.229c-0.104,0-0.196,0.095-0.259,0.188
			c0.383,0.393,0.827,0.735,1.448,0.735c0.674,0,1.295-0.632,1.295-1.75C30.184,56.059,29.553,55.449,28.92,55.449"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#FFFFFF"
            d="M27.451,55.429h-0.466c-0.031,0-0.051-0.022-0.062-0.042l-0.146-0.444
			c-0.01-0.031-0.021-0.031-0.031,0l-0.145,0.444c-0.01,0.032-0.031,0.042-0.052,0.042h-0.466c-0.021,0-0.03,0.009-0.011,0.02
			l0.384,0.28c0.021,0.021,0.021,0.052,0.01,0.072l-0.146,0.434c-0.009,0.022-0.009,0.043,0.021,0.022l0.383-0.27
			c0.031-0.021,0.052-0.021,0.083,0l0.373,0.27c0.02,0.021,0.031,0.01,0.02-0.011l-0.144-0.445c-0.011-0.03,0-0.063,0.021-0.072
			l0.381-0.28C27.482,55.438,27.471,55.429,27.451,55.429"
          />
          <path
            clipPath="url(#SVGID_2_)"
            fill="#FFFFFF"
            d="M27.099,58.493c-0.196-0.497-0.353-0.838-0.353-1.252
			c0-0.177,0.053-0.332,0.083-0.414c0.042-0.083,0.053-0.136,0.011-0.136c-0.063,0-0.196,0.145-0.279,0.27
			c-0.063,0.104-0.135,0.302-0.135,0.455c0,0.29,0.093,0.602,0.154,0.787c0.115,0.321,0.219,0.694,0.219,1.046
			c0,0.561-0.249,0.942-0.693,1.283c0.04,0.032,0.145,0.083,0.27,0.083c0.434,0,0.951-0.485,0.951-1.148
			C27.327,59.073,27.213,58.803,27.099,58.493"
          />
        </g>
      </g>
    </Base>
  );
}

export default SkillsForMyFuture;
