import './select.scss';

function Select(props: any) {
  const { children, className, placeholder, ...otherProps } = props;
  let newClassName = 'select';

  if (className) newClassName += ` ${className}`;
  return (
    <select {...otherProps} className={newClassName}>
      <option value="" disabled>
        {placeholder || 'Select one'}
      </option>
      {children}
    </select>
  );
}

export default Select;
