// import React from 'react';
import Base from './Base';

function SkillsForMyFuture(props: any) {
  const { invert, primaryColor, secondaryColor, ...otherProps } = props;
  const secondaryColour = secondaryColor || '#FFFFFF';
  const primaryColour = primaryColor || '#F6ADCD';

  return (
    <Base {...otherProps}>
      <g>
        <defs>
          <path
            id="SKILLSFORMYFUTURE_SVGID_1_"
            d="M2.03,34c0,17.592,14.262,31.852,31.853,31.852c17.592,0,31.852-14.26,31.852-31.852
    c0-17.591-14.26-31.852-31.852-31.852C16.292,2.148,2.03,16.409,2.03,34"
          />
        </defs>

        <clipPath id="SKILLSFORMYFUTURE_SVGID_2_">
          <use xlinkHref="#SKILLSFORMYFUTURE_SVGID_1_" overflow="visible" />
        </clipPath>
      </g>

      <g id="SkillsForMyFuture_Theme">
        <path
          clipPath="url(#SKILLSFORMYFUTURE_SVGID_2_)"
          fill={invert ? primaryColour : secondaryColour}
          d="M64.462,34c0,16.888-13.689,30.578-30.579,30.578
			C16.995,64.578,3.305,50.889,3.305,34c0-16.889,13.69-30.579,30.579-30.579C50.772,3.421,64.462,17.112,64.462,34"
        />

        <path
          clipPath="url(#SKILLSFORMYFUTURE_SVGID_2_)"
          fill={invert ? secondaryColour : primaryColour}
          d="M20.292,49.252c0.93-0.93,0.93-2.439,0-3.371c-0.933-0.93-2.441-0.93-3.372,0
			c-0.932,0.932-0.932,2.441,0,3.371C17.851,50.184,19.359,50.184,20.292,49.252 M51.454,30.595
			c-3.219,3.218-7.902,4.073-11.902,2.567L22.845,51.195c-0.093,0.109-0.191,0.215-0.294,0.318c-2.18,2.178-5.712,2.178-7.892,0
			c-2.179-2.18-2.179-5.713,0-7.893c0.104-0.102,0.209-0.201,0.317-0.293L33.01,26.622c-1.506-3.999-0.65-8.685,2.567-11.902
			c2.844-2.842,6.83-3.841,10.479-2.998l-6.465,6.466l1.773,6.622l6.621,1.775l6.467-6.467
			C55.296,23.766,54.296,27.753,51.454,30.595 M65.735,34c0-17.593-14.26-31.853-31.852-31.853C16.292,2.148,2.03,16.408,2.03,34
			c0,17.589,14.262,31.851,31.853,31.851C51.476,65.852,65.735,51.59,65.735,34"
        />
      </g>
    </Base>
  );
}

export default SkillsForMyFuture;
