import React, { HTMLProps } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

import './typography.scss';

type LinkProps = HTMLProps<HTMLAnchorElement>;

export function ExternalLink(props: LinkProps) {
  const { children, ...otherProps } = props;
  return (
    <a {...otherProps} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

function Link(props: RouterLinkProps) {
  const { children, to, ...otherProps } = props;
  return (
    <RouterLink {...otherProps} to={to}>
      {children}
    </RouterLink>
  );
}

export default Link;
