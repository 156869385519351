import React, { HTMLProps } from 'react';
import './alert.scss';

type AlertProps = HTMLProps<HTMLDivElement> & {
  type: 'success' | 'info' | 'warning' | 'danger' | 'offline';
  message: string;
  solid?: boolean;
};

function Alert(props: AlertProps) {
  const { className, children, type, solid, ...otherProps } = props;
  const classes = [`alert alert-${type}${solid ? '--solid' : ''}`];
  if (className) classes.push(className);

  return (
    <div {...otherProps} className={classes.join(' ')}>
      {children}
    </div>
  );
}

export default Alert;
