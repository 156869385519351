import React from 'react';

const PersonalBrand = () => {
  return (
    <g id="Personal_Brand">
      <defs>
        <rect
          id="PERSONALBRAND_SVGID_101_"
          x="0.03"
          width="61.686"
          height="61.687"
        />
      </defs>
      <clipPath id="PERSONALBRAND_SVGID_102_">
        <use xlinkHref="#PERSONALBRAND_SVGID_101_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#00AEEF"
        d="M55.536,61.069H6.21c-3.06,0-5.563-2.504-5.563-5.563V6.181
			c0-3.06,2.504-5.563,5.563-5.563h49.326c3.061,0,5.564,2.503,5.564,5.563v49.326C61.101,58.565,58.597,61.069,55.536,61.069"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M11.046,7.155c0.897,0,1.463,0.553,1.463,1.373
			c0,0.839-0.584,1.392-1.504,1.392h-0.94v1.219H9.083V7.155H11.046z M10.933,9.099c0.379,0,0.613-0.22,0.613-0.559
			c0-0.321-0.234-0.547-0.613-0.547h-0.868v1.106H10.933z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M15.871,9.89h-2.188c0.078,0.356,0.32,0.577,0.642,0.577
			c0.222,0,0.478-0.054,0.638-0.345l0.844,0.172c-0.256,0.631-0.797,0.934-1.481,0.934c-0.862,0-1.575-0.637-1.575-1.582
			c0-0.94,0.713-1.581,1.582-1.581c0.844,0,1.521,0.606,1.539,1.581V9.89z M13.701,9.307h1.23c-0.088-0.321-0.326-0.47-0.6-0.47
			C14.064,8.837,13.79,9.004,13.701,9.307"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M18.525,8.136l-0.041,0.951h-0.172c-0.666,0-1.029,0.345-1.029,1.118v0.934
			h-0.951V8.154h0.951v0.571c0.214-0.357,0.541-0.613,1.029-0.613C18.394,8.112,18.454,8.118,18.525,8.136"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M19.619,10.169c0.041,0.255,0.273,0.351,0.528,0.351
			c0.25,0,0.388-0.107,0.388-0.238c0-0.101-0.078-0.178-0.299-0.22L19.63,9.943c-0.553-0.101-0.868-0.398-0.868-0.868
			c0-0.612,0.528-1.011,1.284-1.011c0.744,0,1.236,0.339,1.355,0.857l-0.867,0.171c-0.03-0.184-0.209-0.35-0.5-0.35
			c-0.256,0-0.34,0.125-0.34,0.232c0,0.083,0.036,0.166,0.227,0.208l0.701,0.143c0.565,0.119,0.828,0.457,0.828,0.898
			c0,0.66-0.571,1.005-1.356,1.005c-0.702,0-1.304-0.256-1.404-0.869L19.619,10.169z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M24.997,9.646c0,0.945-0.75,1.576-1.636,1.576
			c-0.903,0-1.653-0.631-1.653-1.576c0-0.939,0.75-1.57,1.653-1.57C24.247,8.076,24.997,8.707,24.997,9.646 M24.081,9.646
			c0-0.464-0.351-0.773-0.72-0.773c-0.387,0-0.737,0.309-0.737,0.773c0,0.469,0.351,0.773,0.737,0.773
			C23.73,10.419,24.081,10.115,24.081,9.646"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M28.476,9.307v1.832h-0.945V9.51c0-0.351-0.209-0.589-0.512-0.589
			c-0.375,0-0.618,0.255-0.618,0.832v1.386h-0.952V8.154h0.952v0.285c0.226-0.238,0.541-0.374,0.915-0.374
			C28.012,8.065,28.476,8.558,28.476,9.307"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M32.243,11.138h-0.951V10.9c-0.232,0.209-0.541,0.328-0.928,0.328
			c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.581,1.457-1.581c0.387,0,0.695,0.118,0.928,0.327V8.154h0.951V11.138z
			 M31.292,9.646c0-0.458-0.346-0.773-0.731-0.773c-0.417,0-0.72,0.315-0.72,0.773c0,0.464,0.303,0.773,0.72,0.773
			C30.947,10.419,31.292,10.11,31.292,9.646"
      />
      <rect
        x="32.88"
        y="6.857"
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        width="0.951"
        height="4.281"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M39.118,9.646c0,0.945-0.666,1.582-1.457,1.582
			c-0.385,0-0.695-0.119-0.928-0.328v0.238h-0.951V6.857h0.951v1.535c0.232-0.209,0.543-0.327,0.928-0.327
			C38.452,8.065,39.118,8.707,39.118,9.646 M38.185,9.646c0-0.458-0.303-0.773-0.719-0.773c-0.387,0-0.732,0.315-0.732,0.773
			c0,0.464,0.346,0.773,0.732,0.773C37.882,10.419,38.185,10.11,38.185,9.646"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M41.774,8.136l-0.041,0.951h-0.172c-0.666,0-1.027,0.345-1.027,1.118v0.934
			h-0.953V8.154h0.953v0.571c0.213-0.357,0.539-0.613,1.027-0.613C41.644,8.112,41.704,8.118,41.774,8.136"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M45.286,11.138h-0.953V10.9c-0.23,0.209-0.541,0.328-0.926,0.328
			c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.581,1.457-1.581c0.385,0,0.695,0.118,0.926,0.327V8.154h0.953V11.138z
			 M44.333,9.646c0-0.458-0.344-0.773-0.73-0.773c-0.416,0-0.721,0.315-0.721,0.773c0,0.464,0.305,0.773,0.721,0.773
			C43.989,10.419,44.333,10.11,44.333,9.646"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M48.95,9.307v1.832h-0.947V9.51c0-0.351-0.207-0.589-0.51-0.589
			c-0.375,0-0.619,0.255-0.619,0.832v1.386h-0.951V8.154h0.951v0.285c0.227-0.238,0.541-0.374,0.916-0.374
			C48.485,8.065,48.95,8.558,48.95,9.307"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M52.718,11.138h-0.951V10.9c-0.232,0.209-0.541,0.328-0.928,0.328
			c-0.791,0-1.457-0.637-1.457-1.582c0-0.939,0.666-1.581,1.457-1.581c0.387,0,0.695,0.118,0.928,0.327V6.857h0.951V11.138z
			 M51.767,9.646c0-0.458-0.346-0.773-0.732-0.773c-0.416,0-0.719,0.315-0.719,0.773c0,0.464,0.303,0.773,0.719,0.773
			C51.421,10.419,51.767,10.11,51.767,9.646"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#005891"
        points="41.161,17.362 59.351,35.552 58.833,58.317 39.353,58.317
			19.873,38.521 		"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFDD00"
        d="M30.857,42.843c-8.01,0-14.527-6.516-14.527-14.526
			c0-8.009,6.518-14.527,14.527-14.527c8.009,0,14.527,6.518,14.527,14.527C45.384,36.327,38.866,42.843,30.857,42.843"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M30.857,14.308c7.724,0,14.007,6.285,14.007,14.009
			c0,7.726-6.283,14.009-14.007,14.009c-7.725,0-14.01-6.283-14.01-14.009C16.847,20.593,23.132,14.308,30.857,14.308
			 M30.857,13.271c-8.31,0-15.045,6.736-15.045,15.045c0,8.31,6.735,15.046,15.045,15.046c8.308,0,15.044-6.736,15.044-15.046
			C45.901,20.008,39.165,13.271,30.857,13.271"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#60C2E6"
        d="M56.04,4.407c0.689,0,1.27,0.582,1.27,1.271v50.333
			c0,0.688-0.58,1.27-1.27,1.27H5.708c-0.688,0-1.271-0.582-1.271-1.27V5.678c0-0.689,0.582-1.271,1.271-1.271H56.04 M56.04,0H5.708
			C2.585,0,0.031,2.556,0.031,5.678v50.333c0,3.121,2.555,5.676,5.677,5.676H56.04c3.123,0,5.678-2.555,5.678-5.676V5.678
			C61.718,2.556,59.163,0,56.04,0"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#005891"
        d="M41.716,22.139c0,0.34-0.275,0.615-0.615,0.615s-0.615-0.275-0.615-0.615
			s0.275-0.615,0.615-0.615S41.716,21.799,41.716,22.139"
      />

      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="none"
        stroke="#F37321"
        strokeWidth="1.096"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="
			M35.841,38.972c-1.512,0.707-3.201,1.102-4.979,1.102"
      />

      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="none"
        stroke="#F37321"
        strokeWidth="1.096"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="
			M41.513,33.343c-0.832,1.75-2.084,3.262-3.623,4.406"
      />

      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="1.096"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="
			M28.225,16.632c1.627-0.376,3.36-0.41,5.1-0.039"
      />

      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="1.096"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="
			M24.701,18.078c0.407-0.251,0.83-0.477,1.267-0.677"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#005891"
        points="30.568,32.294 28.665,32.706 27.745,28.46 26.427,33.19
			25.523,33.388 22.349,29.605 23.275,33.876 21.371,34.288 19.642,26.316 21.571,25.898 25.343,30.375 26.924,24.738 28.84,24.322
					"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#005891"
        d="M37.259,28.228l-4.377,0.95c0.309,0.68,0.891,1.016,1.533,0.875
			c0.441-0.095,0.93-0.313,1.125-0.966l1.764-0.021c-0.238,1.371-1.189,2.213-2.559,2.51c-1.725,0.373-3.429-0.59-3.839-2.482
			c-0.406-1.88,0.742-3.475,2.48-3.852c1.689-0.365,3.309,0.554,3.768,2.498L37.259,28.228z M32.663,28.004l2.463-0.534
			c-0.316-0.604-0.857-0.798-1.404-0.681C33.187,26.906,32.71,27.357,32.663,28.004"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#005891"
        d="M39.282,22.445l0.938,4.904l-1.881,0.408l-1.176-4.854l-0.08-0.369
			l2.117-0.459L39.282,22.445z M40.763,29.051c0.129,0.595-0.225,1.145-0.855,1.281c-0.643,0.14-1.193-0.215-1.322-0.81
			c-0.123-0.571,0.223-1.155,0.865-1.296C40.081,28.09,40.64,28.479,40.763,29.051"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#5087C7"
        d="M30.884,55.36c-2.638,0-4.772-2.137-4.772-4.771
			c0-2.637,2.135-4.773,4.772-4.773c2.638,0,4.775,2.137,4.775,4.773C35.659,53.224,33.522,55.36,30.884,55.36"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M30.884,45.815c2.638,0,4.775,2.135,4.775,4.773
			c0,2.637-2.137,4.771-4.775,4.771c-2.638,0-4.772-2.135-4.772-4.771C26.112,47.95,28.247,45.815,30.884,45.815 M30.884,45.403
			c-2.859,0-5.185,2.326-5.185,5.186c0,2.857,2.325,5.184,5.185,5.184c2.861,0,5.187-2.326,5.187-5.184
			C36.071,47.729,33.745,45.403,30.884,45.403"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M33.378,48.644c-0.238,0-0.465,0.107-0.619,0.25l-0.014-0.012
			c0.072-0.119,0.168-0.346,0.168-0.717c0-0.883-0.884-1.551-2.029-1.551c-1.145,0-2.041,0.668-2.041,1.551
			c0,0.371,0.121,0.598,0.18,0.717l-0.012,0.012c-0.156-0.143-0.383-0.25-0.619-0.25c-0.73,0-1.457,0.703-1.457,2.016
			c0,1.289,0.716,2.018,1.492,2.018c0.715,0,1.217-0.395,1.67-0.848c-0.071-0.107-0.18-0.215-0.299-0.215s-0.25,0.084-0.477,0.262
			c-0.299,0.229-0.561,0.359-0.908,0.359c-0.607,0-0.87-0.682-0.87-1.576c0-0.955,0.25-1.586,0.896-1.586
			c0.285,0,0.476,0.082,0.668,0.262c0.285,0.264,0.382,0.334,0.513,0.334c0.097,0,0.155-0.061,0.179-0.096
			c-0.214-0.215-0.524-0.666-0.524-1.252c0-0.668,0.561-1.133,1.61-1.133c1.051,0,1.611,0.465,1.611,1.133
			c0,0.586-0.311,1.037-0.525,1.252c0.024,0.035,0.084,0.096,0.18,0.096c0.132,0,0.226-0.07,0.513-0.334
			c0.178-0.18,0.381-0.262,0.668-0.262c0.643,0,0.895,0.631,0.895,1.586c0,0.895-0.264,1.576-0.871,1.576
			c-0.346,0-0.619-0.131-0.906-0.359c-0.229-0.178-0.358-0.262-0.478-0.262c-0.119,0-0.226,0.107-0.299,0.215
			c0.442,0.453,0.955,0.848,1.671,0.848c0.775,0,1.492-0.729,1.492-2.018C34.835,49.347,34.104,48.644,33.378,48.644"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M31.684,48.62h-0.537c-0.035,0-0.06-0.025-0.071-0.049l-0.168-0.514
			c-0.011-0.033-0.022-0.033-0.035,0l-0.166,0.514c-0.014,0.037-0.037,0.049-0.061,0.049h-0.537c-0.023,0-0.035,0.01-0.012,0.021
			l0.441,0.324c0.023,0.023,0.023,0.061,0.012,0.082l-0.167,0.502c-0.011,0.023-0.011,0.049,0.024,0.023l0.441-0.309
			c0.036-0.025,0.059-0.025,0.095,0l0.431,0.309c0.022,0.025,0.035,0.012,0.022-0.012l-0.166-0.514c-0.012-0.035,0-0.07,0.024-0.082
			l0.439-0.324C31.72,48.63,31.708,48.62,31.684,48.62"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M31.279,52.151c-0.227-0.574-0.406-0.967-0.406-1.443
			c0-0.205,0.061-0.383,0.096-0.477c0.049-0.098,0.061-0.156,0.012-0.156c-0.071,0-0.227,0.166-0.322,0.311
			c-0.072,0.117-0.155,0.346-0.155,0.523c0,0.336,0.108,0.693,0.179,0.908c0.133,0.369,0.252,0.799,0.252,1.205
			c0,0.645-0.287,1.084-0.8,1.479c0.048,0.037,0.166,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.324
			C31.541,52.819,31.41,52.509,31.279,52.151"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-12.274-25.954c0.076,0,0.14,0.012,0.191,0.037
			c0.053,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.021,0.108,0.021,0.163
			c0,0.076-0.012,0.143-0.035,0.199c-0.022,0.057-0.053,0.103-0.094,0.139s-0.091,0.063-0.148,0.081
			c-0.059,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.274z M-12.366-25.285c0.06,0,0.107-0.018,0.145-0.054
			s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.159-0.058h-0.125v0.45H-12.366z"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        points="-11.271,-25.954 -11.06,-24.909 -11.055,-24.909 -10.842,-25.954
			-10.401,-25.954 -10.401,-24.475 -10.674,-24.475 -10.674,-25.656 -10.678,-25.656 -10.941,-24.475 -11.174,-24.475
			-11.437,-25.656 -11.441,-25.656 -11.441,-24.475 -11.715,-24.475 -11.715,-25.954 		"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-9.947-24.893c0,0.034,0.003,0.066,0.009,0.094
			c0.004,0.028,0.016,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.097,0.016
			c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.013-0.086
			c-0.01-0.025-0.023-0.047-0.045-0.066s-0.049-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.09-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.082-0.072,0.193-0.108,0.336-0.108
			c0.066,0,0.127,0.007,0.184,0.021c0.057,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.096,0.124
			c0.023,0.051,0.033,0.111,0.033,0.182v0.041h-0.285c0-0.071-0.012-0.125-0.037-0.162c-0.025-0.039-0.066-0.057-0.124-0.057
			c-0.033,0-0.062,0.004-0.083,0.014c-0.023,0.009-0.04,0.023-0.053,0.038c-0.014,0.016-0.022,0.035-0.027,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.028,0.115c0.02,0.031,0.061,0.059,0.125,0.086l0.229,0.099
			c0.058,0.025,0.103,0.051,0.14,0.078c0.035,0.027,0.063,0.056,0.086,0.087c0.021,0.03,0.035,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.047,0.27-0.137,0.341s-0.216,0.106-0.377,0.106c-0.17,0-0.289-0.036-0.363-0.109
			c-0.071-0.073-0.107-0.179-0.107-0.315v-0.061h0.298V-24.893z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-8.68-24.475c0-0.065,0.007-0.125,0.02-0.182
			c0.014-0.057,0.033-0.111,0.061-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.089-0.099,0.142-0.149l0.121-0.111
			c0.033-0.029,0.061-0.056,0.08-0.081c0.021-0.025,0.035-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.021-0.081
			c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.146-0.183c-0.036,0-0.066,0.008-0.088,0.023
			s-0.037,0.036-0.049,0.062c-0.013,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.006,0.071-0.006,0.11H-8.68v-0.057
			c0-0.136,0.037-0.241,0.109-0.315c0.073-0.073,0.188-0.109,0.347-0.109c0.149,0,0.26,0.034,0.332,0.103
			c0.071,0.07,0.106,0.168,0.106,0.297c0,0.047-0.004,0.09-0.012,0.131s-0.021,0.081-0.039,0.117
			c-0.018,0.038-0.042,0.074-0.07,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
			c-0.025,0.039-0.041,0.071-0.049,0.099h0.555v0.232H-8.68z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-7.342-24.688c0.02,0.032,0.055,0.047,0.104,0.047
			c0.04,0,0.071-0.009,0.096-0.028c0.022-0.019,0.042-0.045,0.054-0.078c0.014-0.033,0.022-0.073,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.149v-0.07H-7.06c-0.024,0.037-0.06,0.066-0.106,0.089c-0.045,0.023-0.103,0.035-0.168,0.035
			s-0.121-0.012-0.166-0.035c-0.047-0.023-0.084-0.054-0.111-0.095c-0.028-0.041-0.049-0.089-0.063-0.145
			c-0.013-0.056-0.02-0.117-0.02-0.183c0-0.17,0.038-0.3,0.113-0.39c0.074-0.09,0.195-0.135,0.361-0.135
			c0.103,0,0.184,0.019,0.244,0.058c0.061,0.037,0.108,0.088,0.141,0.151c0.033,0.063,0.054,0.135,0.063,0.219
			c0.009,0.082,0.015,0.169,0.015,0.259c0,0.105-0.004,0.206-0.01,0.303c-0.006,0.098-0.025,0.184-0.056,0.258
			c-0.031,0.075-0.081,0.133-0.147,0.177c-0.066,0.043-0.16,0.065-0.281,0.065c-0.053,0-0.104-0.005-0.152-0.015
			c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.107-0.037-0.178h0.287
			C-7.372-24.761-7.363-24.718-7.342-24.688 M-7.358-25.243c0.025,0.042,0.066,0.064,0.124,0.064c0.06,0,0.103-0.022,0.127-0.064
			c0.024-0.043,0.037-0.115,0.037-0.214c0-0.041-0.002-0.08-0.007-0.116c-0.004-0.036-0.012-0.066-0.023-0.092
			c-0.012-0.025-0.027-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.063,0.008-0.086,0.022
			c-0.021,0.015-0.037,0.035-0.048,0.061c-0.012,0.026-0.02,0.057-0.022,0.092c-0.004,0.036-0.005,0.074-0.005,0.115
			C-7.395-25.358-7.383-25.286-7.358-25.243"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-5.779-25.685c-0.062,0.086-0.114,0.178-0.163,0.276
			c-0.047,0.098-0.089,0.199-0.125,0.302c-0.035,0.104-0.066,0.21-0.089,0.317c-0.024,0.107-0.042,0.212-0.054,0.315h-0.313
			c0.01-0.088,0.026-0.181,0.049-0.278c0.022-0.098,0.052-0.197,0.088-0.297c0.037-0.102,0.082-0.203,0.134-0.305
			c0.053-0.103,0.114-0.203,0.187-0.3h-0.617v-0.257h0.903V-25.685z"
      />
      <rect
        x="-3.356"
        y="-26.453"
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#00AEEF"
        width="8.813"
        height="8.813"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-2.442-25.954c0.076,0,0.141,0.012,0.192,0.038
			c0.052,0.026,0.094,0.059,0.125,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.012,0.143-0.034,0.199c-0.022,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
			c-0.058,0.018-0.123,0.027-0.193,0.027h-0.159v0.59h-0.298v-1.479H-2.442z M-2.533-25.285c0.06,0,0.106-0.018,0.144-0.054
			s0.056-0.092,0.056-0.168c0-0.074-0.017-0.131-0.048-0.17c-0.032-0.039-0.086-0.058-0.159-0.058h-0.125v0.45H-2.533z"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        points="-1.438,-25.954 -1.227,-24.909 -1.223,-24.909 -1.009,-25.954
			-0.569,-25.954 -0.569,-24.474 -0.841,-24.474 -0.841,-25.656 -0.846,-25.656 -1.108,-24.474 -1.341,-24.474 -1.604,-25.656
			-1.608,-25.656 -1.608,-24.474 -1.882,-24.474 -1.882,-25.954 		"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-0.114-24.893c0,0.035,0.003,0.066,0.008,0.094
			c0.005,0.029,0.016,0.053,0.031,0.072c0.015,0.019,0.035,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.097,0.017
			c0.045,0,0.086-0.015,0.123-0.045c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.006-0.062-0.014-0.086
			c-0.009-0.025-0.023-0.047-0.045-0.066c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.015-0.074-0.032-0.125-0.048
			c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.192-0.108,0.335-0.108
			c0.066,0,0.128,0.007,0.184,0.022c0.057,0.014,0.104,0.038,0.146,0.07c0.041,0.033,0.071,0.074,0.095,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0.238c0-0.07-0.012-0.125-0.037-0.162c-0.025-0.038-0.066-0.057-0.123-0.057
			c-0.033,0-0.062,0.005-0.084,0.014c-0.022,0.01-0.039,0.023-0.053,0.038c-0.013,0.016-0.021,0.035-0.027,0.055
			c-0.004,0.021-0.007,0.042-0.007,0.065c0,0.045,0.011,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.139,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.035,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341s-0.216,0.106-0.378,0.106c-0.169,0-0.289-0.036-0.362-0.109
			c-0.071-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.893z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-2.442-24.011c0.076,0,0.141,0.012,0.192,0.038s0.094,0.059,0.125,0.1
			c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163c0,0.076-0.012,0.143-0.034,0.199
			c-0.022,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081c-0.058,0.018-0.123,0.027-0.193,0.027h-0.159v0.59
			h-0.298v-1.479H-2.442z M-2.533-23.342c0.06,0,0.106-0.018,0.144-0.054c0.037-0.036,0.056-0.092,0.056-0.168
			c0-0.074-0.017-0.131-0.048-0.17c-0.032-0.039-0.086-0.058-0.159-0.058h-0.125v0.45H-2.533z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-1.624-23.646v0.149h0.004c0.029-0.054,0.064-0.097,0.106-0.129
			c0.041-0.033,0.093-0.049,0.151-0.049c0.008,0,0.018,0,0.025,0.001c0.01,0.001,0.019,0.002,0.026,0.003v0.274
			C-1.325-23.399-1.34-23.4-1.355-23.4c-0.015-0.001-0.028-0.001-0.044-0.001c-0.025,0-0.05,0.003-0.075,0.01
			c-0.024,0.007-0.047,0.018-0.067,0.034c-0.021,0.016-0.037,0.036-0.051,0.062c-0.012,0.026-0.019,0.057-0.019,0.095v0.669h-0.286
			v-1.115H-1.624z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-1.221-23.341c0.016-0.073,0.041-0.135,0.074-0.184
			c0.035-0.049,0.08-0.086,0.137-0.112c0.056-0.025,0.125-0.038,0.205-0.038c0.086-0.001,0.158,0.01,0.215,0.034
			s0.101,0.061,0.134,0.111c0.034,0.05,0.058,0.111,0.071,0.184c0.014,0.074,0.021,0.16,0.021,0.257c0,0.096-0.008,0.18-0.022,0.253
			c-0.016,0.073-0.041,0.134-0.075,0.184c-0.035,0.049-0.08,0.085-0.136,0.109c-0.057,0.023-0.125,0.035-0.207,0.035
			c-0.084,0.001-0.156-0.01-0.213-0.032c-0.057-0.023-0.102-0.059-0.135-0.108c-0.033-0.049-0.056-0.11-0.07-0.185
			c-0.014-0.074-0.021-0.159-0.021-0.256C-1.244-23.185-1.235-23.269-1.221-23.341 M-0.954-22.931c0.003,0.048,0.01,0.09,0.02,0.124
			c0.011,0.035,0.026,0.062,0.047,0.081c0.021,0.02,0.049,0.029,0.082,0.029c0.059,0,0.1-0.03,0.121-0.091
			c0.022-0.061,0.033-0.161,0.033-0.301c0-0.139-0.011-0.239-0.033-0.3c-0.021-0.061-0.063-0.091-0.121-0.091
			c-0.033,0-0.062,0.009-0.082,0.029c-0.021,0.019-0.036,0.046-0.047,0.081c-0.01,0.034-0.017,0.076-0.02,0.124
			c-0.003,0.048-0.004,0.1-0.004,0.157S-0.957-22.979-0.954-22.931"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M0.32-23.325c-0.004-0.026-0.01-0.05-0.019-0.071s-0.021-0.039-0.038-0.052
			s-0.039-0.02-0.066-0.02c-0.035,0-0.063,0.008-0.085,0.024c-0.022,0.016-0.038,0.039-0.05,0.07
			c-0.011,0.031-0.02,0.071-0.023,0.118c-0.004,0.048-0.006,0.105-0.006,0.169c0,0.07,0.002,0.129,0.006,0.178
			s0.013,0.089,0.023,0.121c0.012,0.032,0.025,0.055,0.047,0.069c0.02,0.015,0.047,0.022,0.077,0.022
			c0.048,0,0.084-0.021,0.106-0.062c0.021-0.042,0.033-0.108,0.033-0.199h0.273c0,0.146-0.033,0.258-0.1,0.335
			c-0.064,0.076-0.174,0.115-0.326,0.115c-0.071,0-0.135-0.009-0.188-0.026s-0.097-0.049-0.132-0.094
			c-0.036-0.044-0.063-0.104-0.08-0.18c-0.019-0.075-0.026-0.169-0.026-0.281c0-0.114,0.011-0.209,0.033-0.285
			c0.021-0.075,0.053-0.135,0.092-0.18c0.041-0.045,0.088-0.076,0.144-0.094c0.056-0.018,0.116-0.027,0.183-0.027
			c0.133,0,0.232,0.039,0.299,0.116c0.067,0.078,0.102,0.182,0.102,0.315H0.326C0.326-23.272,0.324-23.299,0.32-23.325"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M0.993-22.957c0,0.033,0.003,0.065,0.007,0.096
			c0.006,0.032,0.014,0.059,0.023,0.083c0.012,0.025,0.027,0.044,0.045,0.058c0.017,0.015,0.04,0.022,0.066,0.022
			c0.047,0,0.082-0.017,0.105-0.052c0.021-0.034,0.037-0.087,0.045-0.157h0.257c-0.005,0.13-0.04,0.228-0.103,0.296
			c-0.064,0.068-0.164,0.102-0.301,0.102c-0.104,0-0.185-0.017-0.242-0.052c-0.059-0.034-0.102-0.08-0.129-0.137
			c-0.028-0.056-0.045-0.12-0.051-0.191c-0.007-0.07-0.01-0.141-0.01-0.211c0-0.075,0.005-0.146,0.016-0.216
			c0.011-0.068,0.031-0.13,0.063-0.184c0.031-0.054,0.075-0.097,0.133-0.128c0.059-0.032,0.137-0.048,0.233-0.048
			c0.082,0,0.149,0.013,0.204,0.04c0.053,0.027,0.095,0.066,0.125,0.114c0.031,0.05,0.051,0.109,0.062,0.178
			c0.011,0.07,0.017,0.148,0.017,0.234v0.064H0.993V-22.957z M1.277-23.328c-0.004-0.032-0.012-0.06-0.021-0.083
			c-0.011-0.023-0.025-0.04-0.044-0.052S1.168-23.48,1.14-23.48c-0.029,0-0.054,0.006-0.071,0.019
			c-0.019,0.014-0.034,0.031-0.046,0.052C1.013-23.388,1.005-23.363,1-23.337c-0.004,0.026-0.007,0.052-0.007,0.078v0.044h0.292
			C1.284-23.258,1.281-23.295,1.277-23.328"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M1.917-22.859c0,0.048,0.013,0.087,0.037,0.117
			c0.026,0.03,0.063,0.045,0.111,0.045c0.045,0,0.079-0.011,0.105-0.034S2.21-22.786,2.21-22.83c0-0.036-0.01-0.063-0.03-0.082
			c-0.021-0.018-0.046-0.034-0.072-0.044l-0.201-0.073c-0.079-0.027-0.139-0.067-0.179-0.117c-0.039-0.05-0.061-0.115-0.061-0.194
			c0-0.045,0.008-0.089,0.023-0.129c0.016-0.041,0.039-0.077,0.073-0.107c0.032-0.031,0.075-0.055,0.127-0.073
			s0.114-0.027,0.188-0.027c0.129,0,0.226,0.028,0.287,0.083c0.063,0.055,0.094,0.133,0.094,0.235v0.045H2.203
			c0-0.058-0.009-0.1-0.027-0.127c-0.02-0.027-0.051-0.041-0.098-0.041c-0.037,0-0.068,0.01-0.096,0.03s-0.04,0.051-0.04,0.09
			c0,0.028,0.009,0.053,0.026,0.075c0.017,0.022,0.05,0.04,0.098,0.056l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.119
			s0.056,0.116,0.056,0.2c0,0.059-0.01,0.111-0.03,0.155c-0.021,0.043-0.05,0.079-0.086,0.108c-0.037,0.029-0.082,0.05-0.132,0.063
			c-0.05,0.012-0.104,0.018-0.167,0.018c-0.079,0-0.146-0.008-0.199-0.023S1.775-22.569,1.744-22.6
			c-0.031-0.03-0.053-0.067-0.064-0.111c-0.014-0.043-0.02-0.092-0.02-0.146v-0.039h0.258V-22.859z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M2.837-22.859c0,0.048,0.013,0.087,0.037,0.117
			c0.027,0.03,0.063,0.045,0.111,0.045c0.045,0,0.079-0.011,0.106-0.034c0.026-0.022,0.039-0.055,0.039-0.099
			c0-0.036-0.011-0.063-0.031-0.082c-0.021-0.018-0.045-0.034-0.071-0.044l-0.201-0.073c-0.08-0.027-0.14-0.067-0.18-0.117
			c-0.04-0.05-0.06-0.115-0.06-0.194c0-0.045,0.008-0.089,0.022-0.129c0.016-0.041,0.039-0.077,0.073-0.107
			c0.033-0.031,0.075-0.055,0.127-0.073S2.926-23.676,3-23.676c0.129,0,0.226,0.028,0.287,0.083
			c0.063,0.055,0.094,0.133,0.094,0.235v0.045H3.123c0-0.058-0.008-0.1-0.027-0.127c-0.02-0.027-0.051-0.041-0.098-0.041
			c-0.037,0-0.068,0.01-0.096,0.03s-0.039,0.051-0.039,0.09c0,0.028,0.008,0.053,0.025,0.075s0.05,0.04,0.098,0.056l0.172,0.058
			c0.09,0.031,0.154,0.07,0.191,0.119s0.057,0.116,0.057,0.2c0,0.059-0.012,0.111-0.031,0.155c-0.021,0.043-0.049,0.079-0.086,0.108
			c-0.037,0.029-0.082,0.05-0.131,0.063c-0.051,0.012-0.105,0.018-0.168,0.018c-0.078,0-0.146-0.008-0.199-0.023
			c-0.053-0.015-0.096-0.037-0.127-0.068c-0.03-0.03-0.053-0.067-0.064-0.111c-0.014-0.043-0.02-0.092-0.02-0.146v-0.039h0.258
			V-22.859z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-2.37-21.804c-0.026-0.049-0.073-0.074-0.141-0.074
			c-0.037,0-0.068,0.01-0.092,0.03c-0.023,0.021-0.044,0.053-0.057,0.098c-0.014,0.045-0.024,0.104-0.029,0.176
			c-0.006,0.073-0.008,0.161-0.008,0.266c0,0.112,0.003,0.203,0.011,0.272c0.007,0.069,0.019,0.123,0.035,0.161
			c0.016,0.038,0.036,0.063,0.06,0.076c0.025,0.013,0.053,0.02,0.084,0.02c0.027,0,0.051-0.004,0.074-0.014
			c0.023-0.008,0.042-0.027,0.059-0.053c0.017-0.027,0.029-0.065,0.039-0.114s0.016-0.114,0.016-0.194h0.298
			c0,0.08-0.006,0.156-0.019,0.229c-0.013,0.072-0.035,0.134-0.069,0.188c-0.034,0.054-0.082,0.097-0.144,0.127
			c-0.062,0.03-0.141,0.045-0.238,0.045c-0.112,0-0.201-0.018-0.269-0.053c-0.065-0.036-0.116-0.088-0.151-0.156
			s-0.059-0.148-0.068-0.242c-0.012-0.094-0.016-0.198-0.016-0.311c0-0.112,0.004-0.215,0.016-0.31
			c0.01-0.094,0.033-0.176,0.068-0.244c0.035-0.067,0.086-0.12,0.151-0.158c0.067-0.038,0.156-0.057,0.269-0.057
			c0.105,0,0.189,0.017,0.252,0.052c0.061,0.034,0.106,0.078,0.137,0.131c0.031,0.054,0.05,0.111,0.057,0.172
			c0.008,0.062,0.012,0.119,0.012,0.173h-0.299C-2.333-21.676-2.345-21.755-2.37-21.804"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-1.785-20.437c0.052,0,0.09-0.014,0.112-0.041
			c0.023-0.028,0.037-0.065,0.041-0.11l-0.313-1.115h0.3l0.166,0.771h0.004l0.145-0.771h0.286l-0.271,1.04
			c-0.023,0.093-0.048,0.167-0.07,0.225c-0.023,0.057-0.051,0.102-0.082,0.133c-0.03,0.031-0.067,0.051-0.111,0.061
			c-0.043,0.01-0.098,0.014-0.165,0.014H-1.86v-0.213C-1.834-20.439-1.809-20.437-1.785-20.437"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M-0.92-21.389c0-0.065,0.01-0.119,0.031-0.162
			c0.021-0.044,0.049-0.079,0.084-0.106c0.035-0.027,0.076-0.047,0.123-0.058c0.049-0.012,0.1-0.018,0.154-0.018
			c0.084,0,0.154,0.008,0.207,0.025s0.092,0.04,0.121,0.071c0.029,0.03,0.049,0.066,0.059,0.108
			c0.012,0.042,0.017,0.088,0.017,0.138v0.587c0,0.052,0.002,0.093,0.007,0.122c0.006,0.029,0.014,0.06,0.028,0.093h-0.274
			c-0.009-0.018-0.017-0.037-0.021-0.057c-0.005-0.02-0.011-0.04-0.015-0.059h-0.004c-0.033,0.058-0.07,0.096-0.115,0.113
			c-0.043,0.017-0.1,0.026-0.168,0.026c-0.051,0-0.092-0.009-0.127-0.026c-0.035-0.017-0.063-0.041-0.082-0.071
			c-0.021-0.031-0.037-0.065-0.047-0.103s-0.014-0.076-0.014-0.113c0-0.052,0.005-0.098,0.016-0.136
			c0.013-0.038,0.028-0.07,0.051-0.097c0.023-0.027,0.053-0.05,0.088-0.067c0.035-0.019,0.078-0.034,0.128-0.048l0.161-0.044
			c0.043-0.011,0.072-0.026,0.089-0.045c0.017-0.02,0.024-0.048,0.024-0.085c0-0.043-0.01-0.076-0.029-0.101
			c-0.021-0.024-0.055-0.036-0.102-0.036c-0.046,0-0.078,0.013-0.101,0.039c-0.022,0.027-0.033,0.062-0.033,0.106v0.031H-0.92
			V-21.389z M-0.46-21.109c-0.022,0.009-0.042,0.016-0.06,0.02c-0.059,0.012-0.101,0.033-0.125,0.062
			c-0.024,0.029-0.037,0.068-0.037,0.118c0,0.043,0.009,0.079,0.024,0.11c0.017,0.03,0.046,0.045,0.083,0.045
			c0.02,0,0.039-0.003,0.061-0.009c0.021-0.006,0.039-0.016,0.057-0.03s0.032-0.032,0.043-0.054c0.01-0.022,0.016-0.048,0.016-0.079
			v-0.219C-0.417-21.13-0.438-21.119-0.46-21.109"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M0.352-21.703v0.131h0.004c0.03-0.056,0.069-0.096,0.118-0.122
			c0.049-0.025,0.104-0.038,0.166-0.038c0.091,0,0.16,0.025,0.21,0.075c0.05,0.049,0.073,0.13,0.073,0.242v0.827H0.638v-0.769
			c0-0.058-0.01-0.098-0.029-0.121c-0.02-0.023-0.05-0.034-0.093-0.034c-0.101,0-0.152,0.061-0.152,0.184v0.74H0.078v-1.115H0.352z"
      />
      <rect
        x="6.513"
        y="-26.453"
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#005891"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M7.427-25.954c0.075,0,0.139,0.012,0.191,0.038
			c0.052,0.026,0.094,0.059,0.125,0.1c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.021,0.108,0.021,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.147,0.081
			c-0.059,0.018-0.123,0.027-0.193,0.027h-0.16v0.59H6.905v-1.479H7.427z M7.335-25.285c0.059,0,0.107-0.018,0.145-0.054
			s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.159-0.058H7.203v0.45H7.335z"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        points="8.429,-25.954 8.64,-24.909 8.646,-24.909 8.859,-25.954
			9.3,-25.954 9.3,-24.474 9.027,-24.474 9.027,-25.656 9.023,-25.656 8.759,-24.474 8.527,-24.474 8.263,-25.656 8.259,-25.656
			8.259,-24.474 7.986,-24.474 7.986,-25.954 		"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M9.754-24.893c0,0.035,0.003,0.066,0.008,0.094
			c0.005,0.029,0.017,0.053,0.031,0.072s0.036,0.035,0.062,0.045c0.025,0.012,0.057,0.017,0.096,0.017
			c0.045,0,0.086-0.015,0.123-0.045c0.037-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.013-0.086
			c-0.01-0.025-0.024-0.047-0.045-0.066c-0.021-0.019-0.049-0.037-0.082-0.053c-0.033-0.015-0.076-0.032-0.125-0.048
			c-0.066-0.022-0.125-0.046-0.172-0.073c-0.049-0.026-0.09-0.057-0.121-0.092C9.539-25.3,9.514-25.341,9.5-25.387
			c-0.016-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.335-0.108
			c0.067,0,0.128,0.007,0.185,0.022c0.056,0.014,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.096,0.124
			c0.022,0.051,0.033,0.111,0.033,0.182v0.041h-0.285c0-0.07-0.013-0.125-0.037-0.162c-0.025-0.038-0.066-0.057-0.125-0.057
			c-0.033,0-0.061,0.005-0.082,0.014c-0.023,0.01-0.041,0.023-0.054,0.038c-0.013,0.016-0.022,0.035-0.026,0.055
			c-0.006,0.021-0.008,0.042-0.008,0.065c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099
			c0.058,0.025,0.104,0.051,0.14,0.078c0.036,0.027,0.063,0.056,0.086,0.087c0.021,0.031,0.036,0.065,0.045,0.102
			c0.008,0.038,0.013,0.079,0.013,0.125c0,0.156-0.047,0.27-0.137,0.341s-0.216,0.106-0.378,0.106c-0.169,0-0.289-0.036-0.362-0.109
			c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.298V-24.893z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M11.021-24.475c0-0.064,0.006-0.126,0.02-0.182
			c0.014-0.057,0.033-0.111,0.061-0.162c0.026-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.149l0.121-0.111
			c0.033-0.029,0.061-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075s0.019-0.052,0.022-0.081
			c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.146-0.183c-0.037,0-0.066,0.008-0.088,0.022
			c-0.021,0.015-0.037,0.036-0.05,0.063c-0.012,0.027-0.019,0.057-0.022,0.092c-0.004,0.034-0.006,0.071-0.006,0.109h-0.285v-0.055
			c0-0.137,0.037-0.242,0.109-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103s0.107,0.168,0.107,0.297
			c0,0.047-0.004,0.09-0.012,0.131s-0.021,0.08-0.039,0.117c-0.018,0.038-0.042,0.074-0.071,0.109
			c-0.028,0.035-0.063,0.072-0.106,0.109l-0.164,0.153c-0.045,0.04-0.08,0.08-0.104,0.117c-0.024,0.039-0.041,0.071-0.048,0.099
			h0.554v0.232H11.021z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M12.359-24.688c0.02,0.032,0.055,0.047,0.104,0.047
			c0.041,0,0.072-0.009,0.097-0.028c0.022-0.019,0.042-0.044,0.054-0.078c0.014-0.033,0.021-0.072,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.067-0.106,0.09c-0.046,0.023-0.103,0.034-0.169,0.034
			c-0.065,0-0.12-0.011-0.166-0.034s-0.083-0.055-0.11-0.096c-0.028-0.041-0.049-0.089-0.063-0.145
			c-0.013-0.056-0.02-0.117-0.02-0.183c0-0.17,0.037-0.3,0.113-0.39c0.074-0.089,0.195-0.134,0.361-0.134
			c0.102,0,0.184,0.018,0.244,0.057c0.061,0.037,0.107,0.088,0.14,0.151c0.033,0.063,0.054,0.136,0.063,0.219
			c0.01,0.082,0.015,0.169,0.015,0.259c0,0.105-0.003,0.206-0.009,0.303c-0.006,0.098-0.025,0.184-0.057,0.258
			c-0.03,0.075-0.08,0.134-0.146,0.177c-0.066,0.044-0.161,0.066-0.281,0.066c-0.053,0-0.104-0.006-0.152-0.016
			c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.107-0.037-0.177h0.287
			C12.33-24.761,12.337-24.718,12.359-24.688 M12.343-25.243c0.024,0.043,0.065,0.064,0.123,0.064c0.06,0,0.103-0.021,0.127-0.064
			c0.025-0.043,0.037-0.114,0.037-0.214c0-0.041-0.002-0.08-0.006-0.116s-0.012-0.066-0.023-0.092
			c-0.012-0.025-0.028-0.045-0.051-0.06c-0.021-0.014-0.049-0.022-0.084-0.022s-0.063,0.008-0.086,0.022
			c-0.021,0.015-0.037,0.035-0.047,0.061c-0.013,0.027-0.02,0.057-0.022,0.093c-0.004,0.035-0.005,0.073-0.005,0.114
			C12.306-25.357,12.318-25.286,12.343-25.243"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M13.49-25.941h0.309v0.93h0.141v0.233h-0.141v0.304h-0.274v-0.304h-0.522
			v-0.245L13.49-25.941z M13.52-25.568l-0.286,0.557h0.29v-0.557H13.52z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M14.308-25.681l-0.031,0.334h0.004c0.027-0.039,0.063-0.067,0.107-0.084
			c0.045-0.017,0.098-0.026,0.161-0.026c0.071,0,0.129,0.013,0.177,0.04c0.045,0.027,0.082,0.063,0.108,0.107
			s0.047,0.094,0.058,0.15c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.006,0.156-0.021,0.222
			c-0.013,0.066-0.038,0.123-0.073,0.171s-0.084,0.083-0.144,0.108c-0.062,0.024-0.138,0.036-0.231,0.036s-0.168-0.012-0.226-0.037
			s-0.101-0.058-0.132-0.099c-0.03-0.04-0.051-0.085-0.061-0.132c-0.011-0.048-0.017-0.093-0.017-0.134v-0.039h0.286
			c0,0.024,0.002,0.051,0.005,0.078c0.002,0.028,0.009,0.054,0.019,0.078c0.012,0.024,0.026,0.045,0.045,0.06
			c0.021,0.016,0.045,0.024,0.076,0.024c0.063,0,0.107-0.024,0.135-0.072c0.026-0.047,0.041-0.119,0.041-0.214
			c0-0.061-0.004-0.111-0.01-0.151c-0.005-0.04-0.014-0.072-0.027-0.096c-0.014-0.023-0.03-0.04-0.053-0.049
			c-0.021-0.01-0.05-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.1,0.036c-0.023,0.025-0.037,0.057-0.042,0.099h-0.269l0.055-0.798
			h0.765v0.232H14.308z"
      />
      <rect
        x="16.149"
        y="-26.453"
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFDD00"
        width="8.813"
        height="8.813"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M17.064-25.954c0.076,0,0.141,0.012,0.191,0.038
			c0.053,0.026,0.095,0.059,0.125,0.1c0.033,0.042,0.056,0.089,0.07,0.142c0.012,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.012,0.143-0.034,0.199c-0.022,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
			c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.297v-1.479H17.064z M16.973-25.285c0.06,0,0.106-0.018,0.144-0.054
			s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.033-0.039-0.086-0.058-0.16-0.058h-0.125v0.45H16.973z"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        points="18.068,-25.954 18.279,-24.909 18.283,-24.909 18.496,-25.954
			18.937,-25.954 18.937,-24.474 18.664,-24.474 18.664,-25.656 18.66,-25.656 18.397,-24.474 18.164,-24.474 17.902,-25.656
			17.897,-25.656 17.897,-24.474 17.623,-24.474 17.623,-25.954 		"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M19.392-24.893c0,0.035,0.002,0.066,0.008,0.094
			c0.005,0.029,0.016,0.053,0.031,0.072c0.015,0.019,0.035,0.035,0.061,0.045c0.025,0.012,0.059,0.017,0.098,0.017
			c0.045,0,0.086-0.015,0.123-0.045c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.006-0.062-0.014-0.086
			c-0.009-0.025-0.023-0.047-0.045-0.066s-0.048-0.037-0.082-0.053c-0.033-0.015-0.074-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.192-0.108,0.335-0.108
			c0.066,0,0.128,0.007,0.184,0.022c0.057,0.014,0.104,0.038,0.146,0.07c0.041,0.033,0.071,0.074,0.095,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.012-0.125-0.037-0.162c-0.025-0.038-0.066-0.057-0.124-0.057
			c-0.032,0-0.062,0.005-0.083,0.014c-0.023,0.01-0.04,0.023-0.053,0.038c-0.014,0.016-0.021,0.035-0.027,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.011,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.139,0.078c0.035,0.027,0.063,0.056,0.086,0.087c0.021,0.031,0.035,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.091,0.071-0.216,0.106-0.378,0.106
			c-0.17,0-0.289-0.036-0.362-0.109c-0.071-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.893z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M20.998-25.511h-0.291v-0.195h0.026c0.099,0,0.177-0.019,0.235-0.056
			c0.057-0.037,0.094-0.097,0.108-0.18h0.22v1.467h-0.299V-25.511z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M21.664-25.453c0.006-0.094,0.025-0.177,0.057-0.249
			c0.029-0.073,0.075-0.131,0.137-0.174c0.062-0.044,0.146-0.066,0.256-0.066c0.09,0,0.164,0.013,0.223,0.039
			c0.059,0.025,0.105,0.066,0.141,0.123c0.035,0.056,0.061,0.13,0.075,0.221c0.015,0.09,0.022,0.2,0.022,0.33
			c0,0.102-0.004,0.2-0.012,0.295c-0.006,0.093-0.024,0.177-0.056,0.249c-0.03,0.073-0.075,0.13-0.138,0.171
			c-0.061,0.042-0.146,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.061-0.024-0.107-0.063-0.143-0.12
			c-0.036-0.056-0.061-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C21.652-25.26,21.657-25.359,21.664-25.453
			 M21.958-24.867c0.005,0.059,0.014,0.105,0.025,0.139c0.013,0.034,0.029,0.057,0.051,0.069c0.02,0.013,0.046,0.019,0.078,0.019
			c0.033,0,0.061-0.008,0.08-0.024c0.021-0.016,0.037-0.043,0.05-0.081c0.011-0.038,0.021-0.089,0.024-0.152
			c0.004-0.064,0.008-0.143,0.008-0.236v-0.16c0-0.093-0.004-0.168-0.008-0.227s-0.014-0.105-0.025-0.139
			c-0.014-0.033-0.029-0.057-0.051-0.069c-0.021-0.013-0.047-0.019-0.078-0.019c-0.033,0-0.061,0.008-0.08,0.024
			c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.038-0.021,0.088-0.025,0.152c-0.005,0.063-0.007,0.142-0.007,0.236v0.16
			C21.952-25.001,21.954-24.926,21.958-24.867"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M22.992-24.688c0.019,0.032,0.053,0.047,0.104,0.047
			c0.04,0,0.072-0.009,0.096-0.028s0.042-0.044,0.055-0.078c0.013-0.033,0.021-0.072,0.025-0.118
			c0.005-0.045,0.008-0.095,0.008-0.149v-0.07h-0.006c-0.023,0.037-0.061,0.067-0.105,0.09c-0.047,0.023-0.104,0.034-0.17,0.034
			c-0.064,0-0.119-0.011-0.166-0.034c-0.045-0.023-0.082-0.055-0.11-0.096s-0.049-0.089-0.062-0.145
			c-0.014-0.056-0.021-0.117-0.021-0.183c0-0.17,0.038-0.3,0.112-0.39c0.076-0.089,0.197-0.134,0.363-0.134
			c0.102,0,0.184,0.018,0.243,0.057c0.062,0.037,0.108,0.088,0.14,0.151c0.033,0.063,0.055,0.136,0.064,0.219
			c0.01,0.082,0.014,0.169,0.014,0.259c0,0.105-0.002,0.206-0.009,0.303c-0.007,0.098-0.024,0.184-0.056,0.258
			c-0.031,0.075-0.081,0.134-0.146,0.177c-0.067,0.044-0.162,0.066-0.281,0.066c-0.053,0-0.104-0.006-0.153-0.016
			c-0.049-0.01-0.093-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.091-0.114c-0.022-0.048-0.034-0.107-0.036-0.177h0.286
			C22.96-24.761,22.97-24.718,22.992-24.688 M22.975-25.243c0.024,0.043,0.065,0.064,0.124,0.064s0.102-0.021,0.127-0.064
			s0.037-0.114,0.037-0.214c0-0.041-0.002-0.08-0.006-0.116s-0.012-0.066-0.024-0.092c-0.012-0.025-0.028-0.045-0.05-0.06
			c-0.021-0.014-0.049-0.021-0.084-0.021c-0.036,0-0.064,0.008-0.086,0.021c-0.021,0.015-0.037,0.035-0.048,0.062
			c-0.013,0.027-0.019,0.057-0.022,0.093c-0.004,0.035-0.005,0.073-0.005,0.114C22.938-25.357,22.951-25.286,22.975-25.243"
      />
      <rect
        x="45.755"
        y="-26.453"
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#5087C7"
        width="8.813"
        height="8.813"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M46.669-25.954c0.076,0,0.141,0.012,0.191,0.038
			c0.053,0.026,0.094,0.059,0.125,0.1c0.033,0.042,0.055,0.089,0.07,0.142c0.012,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.012,0.143-0.035,0.199c-0.021,0.057-0.053,0.103-0.094,0.139s-0.09,0.063-0.148,0.081s-0.123,0.027-0.193,0.027h-0.16
			v0.59h-0.297v-1.479H46.669z M46.577-25.285c0.059,0,0.107-0.018,0.145-0.054s0.055-0.092,0.055-0.168
			c0-0.074-0.016-0.131-0.047-0.17c-0.033-0.039-0.086-0.058-0.16-0.058h-0.125v0.45H46.577z"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        points="47.673,-25.954 47.884,-24.909 47.888,-24.909 48.101,-25.954
			48.542,-25.954 48.542,-24.474 48.269,-24.474 48.269,-25.656 48.265,-25.656 48.003,-24.474 47.769,-24.474 47.507,-25.656
			47.503,-25.656 47.503,-24.474 47.228,-24.474 47.228,-25.954 		"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M48.997-24.893c0,0.035,0.002,0.066,0.008,0.094
			c0.004,0.029,0.016,0.053,0.031,0.072c0.014,0.019,0.035,0.035,0.061,0.045c0.025,0.012,0.059,0.017,0.096,0.017
			c0.047,0,0.088-0.015,0.123-0.045c0.037-0.029,0.057-0.075,0.057-0.137c0-0.034-0.004-0.062-0.014-0.086
			c-0.01-0.025-0.023-0.047-0.045-0.066s-0.049-0.037-0.082-0.053c-0.033-0.015-0.074-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.049-0.026-0.09-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.082-0.072,0.193-0.108,0.336-0.108c0.066,0,0.127,0.007,0.184,0.022
			c0.057,0.014,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.096,0.124c0.023,0.051,0.033,0.111,0.033,0.182v0.041h-0.285
			c0-0.07-0.012-0.125-0.037-0.162c-0.023-0.038-0.066-0.057-0.125-0.057c-0.031,0-0.061,0.005-0.082,0.014
			c-0.021,0.01-0.041,0.023-0.053,0.038c-0.014,0.016-0.021,0.035-0.027,0.055c-0.006,0.021-0.008,0.042-0.008,0.065
			c0,0.045,0.01,0.083,0.029,0.115c0.02,0.031,0.061,0.059,0.125,0.086l0.229,0.099c0.059,0.025,0.104,0.051,0.141,0.078
			c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.035,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
			c0,0.156-0.047,0.27-0.137,0.341s-0.215,0.106-0.377,0.106c-0.168,0-0.289-0.036-0.363-0.109
			c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.299V-24.893z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M50.263-24.475c0-0.065,0.008-0.126,0.02-0.182
			c0.014-0.057,0.033-0.111,0.061-0.162s0.061-0.101,0.102-0.15c0.041-0.05,0.088-0.099,0.141-0.149l0.121-0.111
			c0.033-0.029,0.061-0.056,0.08-0.081c0.021-0.025,0.035-0.05,0.047-0.075s0.02-0.052,0.021-0.081
			c0.004-0.029,0.006-0.062,0.006-0.099c0-0.122-0.049-0.183-0.146-0.183c-0.035,0-0.066,0.008-0.088,0.023
			c-0.021,0.015-0.037,0.036-0.049,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.002,0.034-0.006,0.071-0.006,0.109h-0.285v-0.055
			c0-0.137,0.035-0.242,0.109-0.316c0.074-0.073,0.189-0.109,0.348-0.109c0.148,0,0.26,0.034,0.332,0.103
			c0.07,0.069,0.105,0.168,0.105,0.297c0,0.047-0.004,0.09-0.012,0.131s-0.021,0.08-0.039,0.117
			c-0.018,0.038-0.041,0.074-0.07,0.109c-0.029,0.035-0.064,0.072-0.105,0.109l-0.166,0.153c-0.043,0.04-0.078,0.08-0.104,0.117
			c-0.025,0.039-0.041,0.071-0.049,0.099h0.555v0.232H50.263z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M52.169-25.685c-0.061,0.086-0.115,0.178-0.162,0.276
			s-0.09,0.199-0.125,0.302c-0.037,0.104-0.066,0.21-0.09,0.316c-0.023,0.108-0.041,0.213-0.053,0.316h-0.314
			c0.012-0.088,0.027-0.181,0.051-0.278c0.021-0.098,0.053-0.197,0.088-0.298s0.08-0.202,0.133-0.304
			c0.053-0.103,0.115-0.203,0.188-0.301h-0.619v-0.257h0.904V-25.685z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M52.593-25.511H52.3v-0.195h0.027c0.1,0,0.178-0.019,0.234-0.056
			c0.059-0.037,0.094-0.097,0.109-0.18h0.219v1.467h-0.297V-25.511z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M53.3-25.127c0.043-0.066,0.105-0.108,0.188-0.124v-0.004
			c-0.039-0.01-0.072-0.025-0.102-0.046c-0.025-0.021-0.047-0.044-0.064-0.07c-0.016-0.027-0.027-0.054-0.035-0.083
			c-0.006-0.029-0.01-0.057-0.01-0.083c0-0.144,0.037-0.247,0.115-0.31c0.076-0.063,0.182-0.094,0.316-0.094
			c0.133,0,0.238,0.031,0.314,0.094s0.115,0.166,0.115,0.31c0,0.026-0.004,0.054-0.01,0.083c-0.008,0.029-0.021,0.056-0.037,0.083
			c-0.016,0.026-0.039,0.049-0.064,0.07c-0.027,0.021-0.061,0.036-0.1,0.046v0.004c0.082,0.016,0.145,0.058,0.188,0.124
			c0.041,0.067,0.061,0.146,0.061,0.239c0,0.043-0.004,0.09-0.014,0.141c-0.01,0.051-0.033,0.098-0.066,0.143
			c-0.033,0.044-0.08,0.08-0.141,0.109c-0.063,0.029-0.145,0.044-0.246,0.044c-0.104,0-0.186-0.015-0.246-0.044
			c-0.063-0.029-0.109-0.065-0.143-0.109c-0.033-0.045-0.055-0.092-0.064-0.143c-0.01-0.051-0.016-0.098-0.016-0.141
			C53.239-24.981,53.261-25.061,53.3-25.127 M53.546-24.794c0.004,0.031,0.012,0.058,0.025,0.081
			c0.014,0.023,0.031,0.041,0.053,0.054s0.051,0.019,0.084,0.019s0.059-0.006,0.082-0.019c0.021-0.013,0.039-0.031,0.053-0.054
			c0.012-0.022,0.023-0.05,0.027-0.081c0.004-0.031,0.008-0.064,0.008-0.099s-0.004-0.068-0.008-0.099
			c-0.004-0.031-0.016-0.057-0.027-0.08c-0.014-0.023-0.031-0.04-0.053-0.054c-0.023-0.013-0.049-0.019-0.082-0.019
			s-0.063,0.006-0.084,0.019c-0.021,0.014-0.039,0.031-0.053,0.054s-0.021,0.049-0.025,0.08c-0.006,0.031-0.008,0.064-0.008,0.099
			S53.54-24.826,53.546-24.794 M53.601-25.391c0.027,0.033,0.063,0.05,0.107,0.05c0.043,0,0.078-0.017,0.104-0.05
			c0.027-0.033,0.041-0.085,0.041-0.155c0-0.135-0.049-0.201-0.145-0.201c-0.098,0-0.146,0.066-0.146,0.201
			C53.562-25.476,53.575-25.424,53.601-25.391"
      />
      <rect
        x="36.216"
        y="-26.453"
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        width="8.813"
        height="8.813"
      />

      <rect
        x="36.216"
        y="-26.454"
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="none"
        stroke="#000000"
        strokeWidth="0.13"
        strokeMiterlimit="10"
        width="8.813"
        height="8.813"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        points="36.808,-25.954 36.97,-24.86 36.976,-24.86 37.149,-25.954 37.435,-25.954
			37.608,-24.86 37.612,-24.86 37.776,-25.954 38.063,-25.954 37.794,-24.474 37.46,-24.474 37.294,-25.548 37.29,-25.548
			37.124,-24.474 36.79,-24.474 36.521,-25.954 		"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        points="38.481,-25.954 38.481,-25.388 38.831,-25.388 38.831,-25.954 39.128,-25.954
			39.128,-24.474 38.831,-24.474 38.831,-25.131 38.481,-25.131 38.481,-24.474 38.183,-24.474 38.183,-25.954 		"
      />
      <rect
        x="39.351"
        y="-25.954"
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        width="0.297"
        height="1.48"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        points="40.737,-25.709 40.413,-25.709 40.413,-24.475 40.114,-24.475 40.114,-25.709
			39.792,-25.709 39.792,-25.955 40.737,-25.955 		"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        points="41.692,-25.709 41.163,-25.709 41.163,-25.362 41.659,-25.362 41.659,-25.117
			41.163,-25.117 41.163,-24.719 41.712,-24.719 41.712,-24.475 40.864,-24.475 40.864,-25.955 41.692,-25.955 		"
      />
      <rect
        x="26.134"
        y="-26.453"
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#F37321"
        width="8.812"
        height="8.813"
      />

      <rect
        x="26.134"
        y="-26.454"
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="none"
        stroke="#F37321"
        strokeWidth="0.518"
        strokeMiterlimit="10"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M26.917-25.954c0.076,0,0.141,0.012,0.191,0.038
			c0.053,0.026,0.095,0.059,0.125,0.1c0.033,0.042,0.056,0.089,0.069,0.142c0.013,0.054,0.021,0.108,0.021,0.163
			c0,0.076-0.012,0.143-0.034,0.199c-0.022,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081s-0.123,0.027-0.193,0.027h-0.16
			v0.59h-0.297v-1.479H26.917z M26.827-25.285c0.059,0,0.106-0.018,0.144-0.054s0.055-0.092,0.055-0.168
			c0-0.074-0.016-0.131-0.047-0.17c-0.033-0.039-0.086-0.058-0.16-0.058h-0.125v0.45H26.827z"
      />
      <polygon
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        points="27.92,-25.954 28.132,-24.909 28.136,-24.909 28.349,-25.954
			28.791,-25.954 28.791,-24.474 28.517,-24.474 28.517,-25.656 28.513,-25.656 28.25,-24.474 28.017,-24.474 27.754,-25.656
			27.75,-25.656 27.75,-24.474 27.476,-24.474 27.476,-25.954 		"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M29.246-24.893c0,0.035,0.002,0.066,0.008,0.094
			c0.005,0.029,0.016,0.053,0.031,0.072c0.015,0.019,0.035,0.035,0.062,0.045c0.024,0.012,0.058,0.017,0.096,0.017
			c0.046,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.006-0.062-0.014-0.086
			c-0.009-0.025-0.023-0.047-0.045-0.066s-0.048-0.037-0.082-0.053c-0.033-0.015-0.074-0.032-0.125-0.048
			c-0.066-0.022-0.124-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.192-0.108,0.335-0.108
			c0.066,0,0.128,0.007,0.184,0.022c0.057,0.014,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.096,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.012-0.125-0.037-0.162c-0.025-0.038-0.066-0.057-0.124-0.057
			c-0.032,0-0.062,0.005-0.083,0.014c-0.023,0.01-0.04,0.023-0.053,0.038c-0.014,0.016-0.021,0.035-0.027,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.028,0.115c0.02,0.031,0.061,0.059,0.125,0.086l0.229,0.099
			c0.058,0.025,0.103,0.051,0.14,0.078c0.035,0.027,0.063,0.056,0.086,0.087c0.021,0.031,0.035,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.137,0.341c-0.09,0.071-0.215,0.106-0.377,0.106
			c-0.17,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.893z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M30.851-25.511H30.56v-0.195h0.026c0.099,0,0.177-0.019,0.235-0.056
			c0.057-0.037,0.094-0.097,0.108-0.18h0.22v1.467h-0.299V-25.511z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M31.809-25.681l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.084
			c0.044-0.017,0.098-0.026,0.161-0.026c0.072,0,0.13,0.013,0.177,0.04c0.045,0.027,0.082,0.063,0.107,0.107
			c0.027,0.044,0.047,0.094,0.059,0.15c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.006,0.156-0.02,0.222
			s-0.039,0.123-0.074,0.171s-0.084,0.083-0.145,0.108c-0.06,0.024-0.138,0.036-0.231,0.036c-0.093,0-0.168-0.012-0.226-0.037
			s-0.101-0.058-0.131-0.099c-0.031-0.04-0.052-0.085-0.062-0.132c-0.011-0.048-0.017-0.093-0.017-0.134v-0.039h0.287
			c0,0.024,0.002,0.051,0.004,0.078c0.002,0.028,0.01,0.054,0.02,0.078c0.011,0.024,0.025,0.045,0.045,0.06
			c0.02,0.016,0.045,0.024,0.077,0.024c0.062,0,0.107-0.024,0.132-0.072c0.027-0.047,0.041-0.119,0.041-0.214
			c0-0.061-0.002-0.111-0.008-0.151s-0.016-0.072-0.029-0.096c-0.012-0.023-0.029-0.04-0.053-0.049
			C32-25.245,31.973-25.25,31.94-25.25c-0.043,0-0.076,0.012-0.099,0.036c-0.023,0.025-0.037,0.057-0.043,0.099h-0.268l0.055-0.798
			h0.765v0.232H31.809z"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M32.511-25.453c0.008-0.094,0.025-0.177,0.057-0.249
			c0.029-0.073,0.076-0.131,0.137-0.174c0.063-0.044,0.148-0.066,0.256-0.066c0.092,0,0.166,0.013,0.225,0.039
			c0.059,0.025,0.105,0.066,0.141,0.123c0.035,0.056,0.059,0.13,0.074,0.221c0.014,0.09,0.021,0.2,0.021,0.33
			c0,0.102-0.004,0.2-0.01,0.295c-0.008,0.093-0.025,0.177-0.055,0.249c-0.031,0.073-0.078,0.13-0.141,0.171
			c-0.061,0.042-0.145,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.059-0.024-0.107-0.063-0.143-0.12
			c-0.035-0.056-0.059-0.13-0.074-0.221c-0.014-0.09-0.021-0.201-0.021-0.33C32.501-25.26,32.505-25.359,32.511-25.453
			 M32.806-24.867c0.006,0.059,0.014,0.105,0.027,0.139c0.012,0.034,0.029,0.057,0.049,0.069c0.021,0.013,0.047,0.019,0.078,0.019
			c0.035,0,0.063-0.008,0.082-0.024c0.021-0.016,0.037-0.043,0.049-0.081s0.02-0.089,0.025-0.152
			c0.004-0.064,0.006-0.143,0.006-0.236v-0.16c0-0.093-0.002-0.168-0.006-0.227c-0.006-0.059-0.014-0.105-0.027-0.139
			c-0.012-0.033-0.027-0.057-0.049-0.069c-0.021-0.013-0.047-0.019-0.08-0.019s-0.059,0.008-0.08,0.024
			c-0.02,0.016-0.037,0.043-0.049,0.081s-0.02,0.088-0.025,0.152c-0.004,0.063-0.006,0.142-0.006,0.236v0.16
			C32.8-25.001,32.802-24.926,32.806-24.867"
      />
      <path
        clipPath="url(#PERSONALBRAND_SVGID_102_)"
        fill="#FFFFFF"
        d="M33.798-25.681l-0.031,0.334h0.004c0.029-0.039,0.064-0.067,0.109-0.084
			c0.043-0.017,0.098-0.026,0.16-0.026c0.07,0,0.131,0.013,0.176,0.04c0.047,0.027,0.084,0.063,0.109,0.107
			c0.027,0.044,0.047,0.094,0.057,0.15c0.012,0.056,0.018,0.113,0.018,0.171c0,0.082-0.008,0.156-0.021,0.222
			c-0.012,0.066-0.037,0.123-0.072,0.171c-0.037,0.048-0.084,0.083-0.145,0.108c-0.061,0.024-0.139,0.036-0.232,0.036
			c-0.092,0-0.168-0.012-0.225-0.037c-0.059-0.025-0.102-0.058-0.131-0.099c-0.031-0.04-0.053-0.085-0.063-0.132
			c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287c0,0.024,0.002,0.051,0.004,0.078c0.002,0.028,0.01,0.054,0.02,0.078
			s0.025,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.076,0.024c0.063,0,0.107-0.024,0.133-0.072
			c0.027-0.047,0.041-0.119,0.041-0.214c0-0.061-0.002-0.111-0.008-0.151s-0.016-0.072-0.029-0.096
			c-0.012-0.023-0.029-0.04-0.053-0.049c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.098,0.036
			c-0.023,0.025-0.037,0.057-0.043,0.099h-0.268l0.055-0.798h0.764v0.232H33.798z"
      />
    </g>
  );
};

const GuidingHistory = () => {
  return (
    <g id="Guiding_History">
      <defs>
        <rect
          id="GUIDINGHISTORY_SVGID_103_"
          x="0.029"
          width="61.688"
          height="61.687"
        />
      </defs>
      <clipPath id="GUIDINGHISTORY_SVGID_104_">
        <use xlinkHref="#GUIDINGHISTORY_SVGID_103_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#00AEEF"
        d="M55.535,61.071H6.208c-3.059,0-5.563-2.506-5.563-5.564V6.181
			c0-3.06,2.504-5.563,5.563-5.563h49.327c3.061,0,5.563,2.503,5.563,5.563v49.326C61.099,58.565,58.596,61.071,55.535,61.071"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#005891"
        points="44.912,18.537 60.073,33.618 60.106,58.024 30.325,60.192
			16.827,46.622 		"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M12.977,8.79C12.995,8.933,13,9.052,13,9.153
			c0,1.207-0.862,2.075-2.064,2.075c-1.207,0-2.146-0.909-2.146-2.081c0-1.166,0.933-2.081,2.128-2.081
			c0.886,0,1.665,0.416,1.945,1.195l-0.946,0.274c-0.231-0.452-0.612-0.571-0.999-0.571c-0.648,0-1.142,0.511-1.142,1.183
			c0,0.678,0.506,1.189,1.173,1.189c0.523,0,0.903-0.273,1.052-0.713h-1.219V8.79H12.977z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M16.539,11.139h-0.952v-0.285c-0.225,0.237-0.541,0.374-0.915,0.374
			c-0.696,0-1.159-0.493-1.159-1.243V8.154h0.945v1.629c0,0.351,0.208,0.589,0.511,0.589c0.375,0,0.618-0.256,0.618-0.832V8.154
			h0.952V11.139z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M18.233,7.273c0,0.304-0.232,0.53-0.553,0.53s-0.547-0.226-0.547-0.53
			c0-0.291,0.226-0.54,0.547-0.54S18.233,6.982,18.233,7.273 M17.204,8.154h0.951v2.985h-0.951V8.154z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M21.953,11.139h-0.951v-0.238c-0.232,0.208-0.541,0.327-0.928,0.327
			c-0.79,0-1.456-0.636-1.456-1.582c0-0.939,0.666-1.581,1.456-1.581c0.387,0,0.696,0.118,0.928,0.327V6.857h0.951V11.139z
			 M21.002,9.646c0-0.458-0.345-0.773-0.732-0.773c-0.416,0-0.719,0.315-0.719,0.773c0,0.464,0.303,0.773,0.719,0.773
			C20.657,10.419,21.002,10.11,21.002,9.646"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M23.65,7.273c0,0.304-0.231,0.53-0.553,0.53c-0.32,0-0.547-0.226-0.547-0.53
			c0-0.291,0.227-0.54,0.547-0.54C23.419,6.733,23.65,6.982,23.65,7.273 M22.621,8.154h0.952v2.985h-0.952V8.154z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M27.255,9.307v1.832H26.31v-1.63c0-0.351-0.208-0.589-0.512-0.589
			c-0.374,0-0.619,0.256-0.619,0.833v1.386h-0.951V8.153h0.951v0.286c0.227-0.238,0.542-0.374,0.917-0.374
			C26.791,8.065,27.255,8.559,27.255,9.307"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M30.937,10.841c0,0.922-0.702,1.427-1.671,1.427
			c-0.684,0-1.208-0.238-1.499-0.719l0.791-0.428c0.226,0.267,0.393,0.368,0.702,0.368c0.434,0,0.743-0.255,0.743-0.725v-0.083
			c-0.214,0.184-0.511,0.285-0.897,0.285c-0.779,0-1.44-0.618-1.44-1.463c0-0.826,0.661-1.439,1.44-1.439
			c0.386,0,0.683,0.101,0.897,0.286V8.153h0.934V10.841z M30.003,9.503c0-0.398-0.314-0.678-0.695-0.678
			c-0.417,0-0.714,0.28-0.714,0.678s0.297,0.684,0.714,0.684C29.689,10.188,30.003,9.901,30.003,9.503"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M35.921,9.307v1.832h-0.945v-1.63c0-0.351-0.208-0.589-0.518-0.589
			c-0.369,0-0.613,0.256-0.613,0.833v1.386h-0.951V6.857h0.951v1.582c0.232-0.238,0.541-0.374,0.923-0.374
			C35.456,8.065,35.921,8.559,35.921,9.307"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M37.578,7.273c0,0.304-0.231,0.53-0.552,0.53
			c-0.322,0-0.549-0.226-0.549-0.53c0-0.291,0.227-0.54,0.549-0.54C37.347,6.733,37.578,6.982,37.578,7.273 M36.55,8.154h0.951
			v2.985H36.55V8.154z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M38.888,10.169c0.043,0.256,0.274,0.351,0.529,0.351
			c0.25,0,0.388-0.107,0.388-0.238c0-0.101-0.078-0.178-0.299-0.22l-0.606-0.119c-0.553-0.101-0.867-0.398-0.867-0.868
			c0-0.612,0.529-1.011,1.283-1.011c0.745,0,1.238,0.339,1.356,0.856l-0.867,0.173c-0.031-0.185-0.208-0.351-0.499-0.351
			c-0.257,0-0.341,0.125-0.341,0.232c0,0.083,0.036,0.166,0.228,0.208l0.7,0.143c0.565,0.119,0.827,0.457,0.827,0.898
			c0,0.66-0.571,1.005-1.355,1.005c-0.702,0-1.303-0.256-1.403-0.869L38.888,10.169z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M43.444,11.008c-0.232,0.125-0.477,0.22-0.815,0.22
			c-0.744,0-1.188-0.404-1.188-1.189v-1.13h-0.541V8.154h0.541V7.273h0.951v0.881h0.879v0.755h-0.879v1.035
			c0,0.309,0.148,0.433,0.397,0.433c0.131,0,0.31-0.047,0.435-0.118L43.444,11.008z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M46.84,9.646c0,0.946-0.75,1.576-1.635,1.576
			c-0.904,0-1.653-0.63-1.653-1.576c0-0.939,0.749-1.57,1.653-1.57C46.09,8.076,46.84,8.707,46.84,9.646 M45.924,9.646
			c0-0.464-0.352-0.773-0.719-0.773c-0.388,0-0.738,0.309-0.738,0.773c0,0.47,0.351,0.773,0.738,0.773
			C45.572,10.419,45.924,10.116,45.924,9.646"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M49.487,8.136l-0.041,0.951h-0.173c-0.666,0-1.029,0.345-1.029,1.118v0.934
			h-0.951V8.154h0.951v0.571c0.214-0.357,0.541-0.613,1.029-0.613C49.355,8.112,49.415,8.118,49.487,8.136"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        points="51.437,12.149 50.408,12.149 50.89,11.038 49.654,8.147
			50.676,8.147 51.383,9.89 52.139,8.147 53.16,8.147 		"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFDD00"
        points="30.872,16.692 33.979,12.965 35.782,17.469 39.889,14.885
			40.21,19.727 44.915,18.537 43.726,23.241 48.566,23.564 45.982,27.67 50.487,29.473 46.76,32.579 50.487,35.687 45.982,37.489
			48.566,41.595 43.726,41.917 44.915,46.622 40.21,45.433 39.889,50.274 35.782,47.688 33.979,52.194 30.872,48.466 27.766,52.194
			25.963,47.688 21.856,50.274 21.534,45.433 16.829,46.622 18.019,41.917 13.178,41.595 15.763,37.489 11.257,35.687
			14.985,32.579 11.257,29.473 15.763,27.67 13.178,23.564 18.019,23.241 16.829,18.537 21.534,19.727 21.856,14.885 25.963,17.469
			27.766,12.965 		"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#005891"
        points="13.178,41.595 17.107,45.524 18.019,41.917 		"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#005891"
        points="11.257,35.687 14.719,39.147 15.762,37.489 		"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#005891"
        points="33.979,12.965 37.44,16.426 35.782,17.47 		"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#005891"
        points="39.889,14.885 43.818,18.815 40.211,19.726 		"
      />
      <rect
        x="25.835"
        y="45.265"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        width="10.118"
        height="14.775"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        d="M37.657,39.19c1.063,0,1.924-0.863,1.924-1.924V20.269
			c0-1.063-0.86-1.924-1.924-1.924c-1.063,0-1.924,0.861-1.924,1.924v16.998C35.733,38.327,36.595,39.19,37.657,39.19"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        d="M33.136,37.622c1.063,0,1.924-0.861,1.924-1.924V18.7
			c0-1.063-0.861-1.924-1.924-1.924s-1.924,0.861-1.924,1.924v16.998C31.212,36.761,32.073,37.622,33.136,37.622"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        d="M28.625,40.165c1.063,0,1.924-0.863,1.924-1.926V19.998
			c0-1.063-0.861-1.925-1.924-1.925s-1.924,0.862-1.924,1.925v18.241C26.702,39.302,27.563,40.165,28.625,40.165"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        d="M22.208,38.669c0,4.797,3.889,8.688,8.687,8.688
			c4.797,0,8.687-3.891,8.687-8.688c0-4.799-3.89-8.687-8.687-8.687C26.097,29.982,22.208,33.87,22.208,38.669"
      />
      <rect
        x="34.407"
        y="30.459"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        width="1.754"
        height="1.753"
      />
      <rect
        x="29.913"
        y="29.742"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        width="1.754"
        height="1.754"
      />
      <rect
        x="37.827"
        y="35.632"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        width="1.754"
        height="3.344"
      />
      <rect
        x="22.208"
        y="33.567"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        width="1.754"
        height="5.102"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        d="M24.132,37.706c-1.238,0-2.245-1.006-2.245-2.244v-7.366
			c0-1.238,1.007-2.246,2.245-2.246s2.245,1.008,2.245,2.246v7.366C26.377,36.7,25.37,37.706,24.132,37.706"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFDD00"
        d="M24.132,26.17c1.063,0,1.924,0.862,1.924,1.926v7.366
			c0,1.063-0.861,1.924-1.924,1.924s-1.924-0.861-1.924-1.924v-7.366C22.208,27.032,23.069,26.17,24.132,26.17 M24.132,25.529
			c-1.415,0-2.566,1.151-2.566,2.566v7.366c0,1.414,1.151,2.566,2.566,2.566s2.565-1.152,2.565-2.566v-7.366
			C26.697,26.681,25.547,25.529,24.132,25.529"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        d="M29.626,43.017c0-2.846,2.303-5.15,5.15-5.15 M37.929,33.661l-11.154-3.343
			c-1.164-0.443-2.467,0.141-2.911,1.305c-0.444,1.163,0.14,2.466,1.303,2.909l9.552,3.32"
      />

      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="none"
        stroke="#FFDD00"
        strokeWidth="0.641"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
			M29.626,43.017c0-2.846,2.303-5.15,5.15-5.15 M37.929,33.661l-11.154-3.343c-1.164-0.443-2.467,0.141-2.911,1.305
			c-0.444,1.163,0.14,2.466,1.303,2.909l9.552,3.32"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#60C2E6"
        d="M56.039,4.407c0.689,0,1.27,0.582,1.27,1.271v50.333
			c0,0.688-0.58,1.27-1.271,1.27H5.705c-0.689,0-1.27-0.582-1.27-1.27V5.678c0-0.689,0.581-1.271,1.27-1.271H56.039 M56.039,0.001
			H5.705c-3.122,0-5.677,2.555-5.677,5.677v50.333c0,3.121,2.555,5.676,5.677,5.676h50.333c3.123,0,5.679-2.555,5.679-5.676V5.678
			C61.717,2.556,59.161,0.001,56.039,0.001"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#5087C7"
        d="M30.883,55.36c-2.638,0-4.774-2.135-4.774-4.771s2.136-4.773,4.774-4.773
			c2.637,0,4.774,2.137,4.774,4.773S33.521,55.36,30.883,55.36"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M30.883,45.815c2.637,0,4.774,2.137,4.774,4.773s-2.137,4.771-4.774,4.771
			c-2.638,0-4.774-2.135-4.774-4.771S28.245,45.815,30.883,45.815 M30.883,45.403c-2.86,0-5.185,2.326-5.185,5.186
			c0,2.857,2.325,5.184,5.185,5.184c2.859,0,5.185-2.326,5.185-5.184C36.068,47.729,33.742,45.403,30.883,45.403"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M33.377,48.644c-0.239,0-0.464,0.107-0.62,0.25l-0.014-0.012
			c0.074-0.119,0.17-0.346,0.17-0.715c0-0.885-0.885-1.553-2.029-1.553c-1.145,0-2.042,0.668-2.042,1.553
			c0,0.369,0.12,0.596,0.18,0.715l-0.013,0.012c-0.155-0.143-0.381-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.016
			c0,1.289,0.716,2.018,1.492,2.018c0.716,0,1.218-0.395,1.67-0.848c-0.071-0.107-0.179-0.215-0.298-0.215s-0.251,0.084-0.477,0.264
			c-0.298,0.227-0.561,0.357-0.908,0.357c-0.608,0-0.869-0.682-0.869-1.576c0-0.955,0.25-1.586,0.895-1.586
			c0.286,0,0.476,0.082,0.668,0.264c0.286,0.262,0.381,0.332,0.512,0.332c0.096,0,0.156-0.061,0.179-0.096
			c-0.214-0.215-0.525-0.666-0.525-1.252c0-0.668,0.561-1.133,1.612-1.133c1.049,0,1.611,0.465,1.611,1.133
			c0,0.586-0.312,1.037-0.526,1.252c0.024,0.035,0.084,0.096,0.179,0.096c0.133,0,0.228-0.07,0.514-0.332
			c0.179-0.182,0.382-0.264,0.668-0.264c0.645,0,0.895,0.631,0.895,1.586c0,0.895-0.262,1.576-0.869,1.576
			c-0.347,0-0.621-0.131-0.908-0.357c-0.227-0.18-0.357-0.264-0.478-0.264c-0.119,0-0.226,0.107-0.298,0.215
			c0.441,0.453,0.955,0.848,1.671,0.848c0.774,0,1.491-0.729,1.491-2.018C34.833,49.347,34.104,48.644,33.377,48.644"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M31.683,48.62h-0.537c-0.036,0-0.059-0.023-0.071-0.049l-0.168-0.512
			c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.512c-0.012,0.037-0.035,0.049-0.06,0.049h-0.537c-0.024,0-0.035,0.012-0.012,0.023
			l0.442,0.322c0.024,0.023,0.024,0.061,0.012,0.084l-0.167,0.5c-0.011,0.023-0.011,0.049,0.024,0.023l0.442-0.309
			c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.035,0.012,0.023-0.01l-0.166-0.514c-0.013-0.035,0-0.072,0.024-0.084
			l0.44-0.322C31.718,48.632,31.706,48.62,31.683,48.62"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M31.277,52.151c-0.226-0.572-0.406-0.967-0.406-1.443
			c0-0.203,0.06-0.381,0.096-0.477c0.047-0.096,0.06-0.156,0.011-0.156c-0.071,0-0.226,0.166-0.321,0.311
			c-0.073,0.119-0.156,0.346-0.156,0.525c0,0.334,0.108,0.691,0.179,0.906c0.132,0.369,0.251,0.799,0.251,1.205
			c0,0.645-0.287,1.084-0.799,1.479c0.047,0.037,0.166,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.324
			C31.54,52.819,31.408,52.509,31.277,52.151"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-12.538-25.927c0.076,0,0.14,0.012,0.192,0.038
			c0.052,0.026,0.094,0.059,0.125,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
			s-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.538z M-12.629-25.258c0.059,0,0.107-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H-12.629z
			"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        points="-11.535,-25.927 -11.324,-24.882 -11.319,-24.882 -11.105,-25.927
			-10.665,-25.927 -10.665,-24.447 -10.938,-24.447 -10.938,-25.629 -10.942,-25.629 -11.205,-24.447 -11.438,-24.447
			-11.701,-25.629 -11.705,-25.629 -11.705,-24.447 -11.979,-24.447 -11.979,-25.927 		"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-10.21-24.866c0,0.035,0.003,0.066,0.009,0.094
			c0.004,0.029,0.015,0.053,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
			c0.045,0,0.087-0.014,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
			c0.066,0,0.127,0.007,0.183,0.022c0.056,0.014,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057
			c-0.033,0-0.061,0.005-0.083,0.014c-0.023,0.01-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
			c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.866z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-8.944-24.447c0-0.065,0.007-0.126,0.02-0.182
			c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.149l0.121-0.111
			c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.052,0.022-0.081
			c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.038,0-0.067,0.008-0.088,0.023
			c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.056
			c0-0.136,0.036-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
			c0.071,0.069,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.009,0.041-0.021,0.08-0.039,0.117
			c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.045,0.04-0.079,0.08-0.104,0.117
			c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H-8.944z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-7.606-24.659c0.019,0.031,0.054,0.046,0.104,0.046
			c0.041,0,0.072-0.009,0.096-0.028c0.023-0.018,0.042-0.044,0.054-0.078c0.014-0.033,0.022-0.072,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.067-0.106,0.09c-0.046,0.023-0.103,0.034-0.169,0.034
			c-0.065,0-0.12-0.011-0.165-0.034c-0.047-0.023-0.084-0.055-0.112-0.096c-0.028-0.04-0.049-0.089-0.062-0.145
			c-0.013-0.056-0.019-0.117-0.019-0.183c0-0.17,0.037-0.3,0.112-0.39c0.076-0.089,0.196-0.134,0.362-0.134
			c0.102,0,0.184,0.018,0.244,0.057c0.061,0.037,0.108,0.088,0.14,0.151c0.033,0.063,0.054,0.136,0.064,0.219
			c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.003,0.206-0.009,0.303c-0.006,0.098-0.025,0.184-0.056,0.258
			c-0.031,0.075-0.08,0.134-0.147,0.177c-0.067,0.044-0.161,0.066-0.281,0.066c-0.053,0-0.103-0.005-0.153-0.016
			c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.047-0.035-0.107-0.037-0.177h0.287
			C-7.636-24.734-7.627-24.691-7.606-24.659 M-7.622-25.216c0.025,0.043,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.127-0.064
			c0.025-0.043,0.037-0.114,0.037-0.213c0-0.042-0.002-0.081-0.006-0.117s-0.012-0.066-0.024-0.092
			c-0.011-0.025-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022c-0.035,0-0.064,0.008-0.086,0.022
			c-0.021,0.015-0.037,0.035-0.048,0.061c-0.011,0.027-0.019,0.057-0.022,0.093c-0.003,0.035-0.005,0.073-0.005,0.115
			C-7.659-25.33-7.647-25.259-7.622-25.216"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-6.042-25.658c-0.061,0.086-0.115,0.178-0.162,0.276
			c-0.048,0.098-0.09,0.199-0.126,0.302c-0.036,0.104-0.066,0.21-0.089,0.316c-0.024,0.108-0.042,0.213-0.054,0.316h-0.313
			c0.011-0.088,0.027-0.181,0.05-0.278c0.022-0.098,0.052-0.197,0.088-0.298c0.036-0.101,0.081-0.202,0.133-0.304
			c0.053-0.103,0.115-0.203,0.187-0.301h-0.618v-0.257h0.904V-25.658z"
      />
      <rect
        x="-3.62"
        y="-26.427"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#00AEEF"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-2.706-25.927c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.706z M-2.797-25.258c0.059,0,0.107-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058H-2.93v0.45H-2.797z"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        points="-1.702,-25.927 -1.491,-24.882 -1.486,-24.882 -1.273,-25.927
			-0.833,-25.927 -0.833,-24.448 -1.105,-24.448 -1.105,-25.629 -1.109,-25.629 -1.372,-24.448 -1.605,-24.448 -1.868,-25.629
			-1.872,-25.629 -1.872,-24.448 -2.146,-24.448 -2.146,-25.927 		"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-0.378-24.866c0,0.034,0.003,0.066,0.009,0.094
			c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.02,0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.016,0.097,0.016
			c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.047-0.045-0.066c-0.021-0.02-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
			c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
			c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.139,0.078s0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
			c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.866z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-2.706-23.983c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.104-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.706z M-2.797-23.314c0.059,0,0.107-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058H-2.93v0.45H-2.797z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-1.887-23.619v0.149h0.005c0.028-0.054,0.064-0.097,0.105-0.129
			c0.041-0.033,0.092-0.05,0.151-0.05c0.009,0,0.017,0.001,0.027,0.001l0.025,0.004v0.273c-0.015-0.001-0.03-0.002-0.044-0.002
			c-0.015-0.001-0.03-0.001-0.045-0.001c-0.025,0-0.05,0.003-0.075,0.009c-0.025,0.008-0.047,0.019-0.068,0.035
			c-0.021,0.016-0.037,0.036-0.049,0.062c-0.013,0.026-0.02,0.057-0.02,0.094v0.67H-2.16v-1.115H-1.887z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-1.484-23.314c0.015-0.074,0.04-0.135,0.074-0.184
			c0.035-0.049,0.08-0.086,0.137-0.112c0.055-0.025,0.124-0.039,0.205-0.039c0.086,0,0.157,0.011,0.214,0.035
			c0.057,0.025,0.101,0.061,0.134,0.111c0.034,0.049,0.057,0.111,0.071,0.185c0.014,0.073,0.021,0.159,0.021,0.256
			c0,0.096-0.008,0.18-0.023,0.253c-0.015,0.073-0.04,0.135-0.075,0.184c-0.034,0.049-0.08,0.085-0.135,0.109
			c-0.057,0.023-0.125,0.034-0.207,0.034c-0.085,0.002-0.157-0.008-0.213-0.031c-0.057-0.023-0.101-0.059-0.135-0.108
			c-0.033-0.049-0.056-0.111-0.07-0.185c-0.014-0.074-0.021-0.159-0.021-0.256C-1.507-23.157-1.5-23.241-1.484-23.314
			 M-1.217-22.904c0.003,0.048,0.01,0.09,0.02,0.124c0.011,0.035,0.026,0.061,0.046,0.081c0.021,0.019,0.049,0.029,0.083,0.029
			c0.058,0,0.099-0.03,0.121-0.091c0.022-0.061,0.033-0.161,0.033-0.301c0-0.139-0.011-0.239-0.033-0.3
			c-0.022-0.061-0.063-0.092-0.121-0.092c-0.034,0-0.062,0.01-0.083,0.03c-0.02,0.019-0.035,0.046-0.046,0.08
			c-0.01,0.035-0.017,0.077-0.02,0.125c-0.003,0.048-0.004,0.101-0.004,0.157C-1.222-23.005-1.221-22.953-1.217-22.904"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M0.056-23.298c-0.004-0.026-0.01-0.05-0.019-0.071
			C0.029-23.392,0.016-23.408,0-23.421c-0.018-0.013-0.04-0.02-0.067-0.02c-0.035,0-0.063,0.008-0.085,0.024s-0.039,0.04-0.05,0.07
			c-0.011,0.032-0.019,0.071-0.023,0.119c-0.004,0.048-0.006,0.104-0.006,0.169c0,0.069,0.002,0.128,0.006,0.177
			c0.004,0.049,0.012,0.09,0.023,0.121c0.011,0.032,0.027,0.055,0.047,0.07c0.02,0.014,0.046,0.021,0.077,0.021
			c0.049,0,0.084-0.02,0.106-0.062c0.022-0.041,0.034-0.107,0.034-0.199h0.273c0,0.146-0.033,0.258-0.098,0.335
			c-0.066,0.077-0.175,0.115-0.327,0.115c-0.072,0-0.134-0.009-0.188-0.026c-0.053-0.017-0.097-0.048-0.132-0.093
			c-0.036-0.045-0.063-0.105-0.08-0.181c-0.018-0.074-0.027-0.168-0.027-0.281c0-0.114,0.011-0.209,0.033-0.285
			c0.022-0.075,0.053-0.134,0.094-0.18c0.04-0.045,0.087-0.076,0.142-0.094c0.056-0.018,0.117-0.027,0.183-0.027
			c0.132,0,0.232,0.039,0.3,0.116c0.067,0.078,0.1,0.182,0.1,0.315H0.062C0.062-23.244,0.06-23.272,0.056-23.298"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M0.729-22.929c0,0.033,0.002,0.066,0.007,0.096
			c0.005,0.031,0.013,0.059,0.024,0.083c0.011,0.025,0.026,0.044,0.044,0.059c0.017,0.014,0.04,0.022,0.066,0.022
			c0.048,0,0.083-0.018,0.106-0.053c0.022-0.034,0.037-0.087,0.045-0.157h0.257c-0.005,0.13-0.04,0.229-0.103,0.297
			c-0.064,0.067-0.164,0.101-0.301,0.101c-0.104,0-0.184-0.017-0.242-0.052c-0.059-0.034-0.101-0.08-0.129-0.136
			c-0.028-0.057-0.044-0.121-0.051-0.191c-0.006-0.071-0.009-0.142-0.009-0.212c0-0.075,0.005-0.146,0.016-0.216
			c0.009-0.068,0.03-0.13,0.062-0.184c0.03-0.054,0.075-0.097,0.133-0.128c0.058-0.032,0.136-0.048,0.233-0.048
			c0.082,0,0.15,0.013,0.203,0.04c0.054,0.027,0.096,0.066,0.126,0.114c0.03,0.049,0.051,0.109,0.062,0.179
			c0.011,0.069,0.017,0.147,0.017,0.233v0.064H0.729V-22.929z M1.013-23.301c-0.005-0.032-0.011-0.06-0.022-0.083
			c-0.01-0.023-0.025-0.04-0.043-0.052c-0.019-0.011-0.043-0.017-0.072-0.017c-0.029,0-0.053,0.006-0.071,0.019
			c-0.019,0.014-0.034,0.031-0.045,0.053c-0.011,0.021-0.019,0.045-0.024,0.071c-0.005,0.026-0.007,0.053-0.007,0.079v0.043h0.292
			C1.02-23.231,1.017-23.269,1.013-23.301"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M1.653-22.832c0,0.049,0.013,0.088,0.038,0.118
			c0.026,0.029,0.063,0.044,0.111,0.044c0.045,0,0.079-0.011,0.106-0.033c0.026-0.023,0.039-0.055,0.039-0.1
			c0-0.036-0.01-0.063-0.03-0.082c-0.021-0.018-0.045-0.033-0.073-0.044l-0.201-0.073c-0.079-0.027-0.138-0.067-0.178-0.117
			c-0.041-0.05-0.06-0.115-0.06-0.194c0-0.045,0.007-0.089,0.022-0.129c0.016-0.041,0.04-0.077,0.073-0.107
			c0.033-0.03,0.076-0.054,0.127-0.072c0.052-0.019,0.115-0.028,0.188-0.028c0.129,0,0.226,0.028,0.288,0.083
			s0.094,0.134,0.094,0.235v0.045H1.94c0-0.058-0.01-0.1-0.029-0.127c-0.019-0.027-0.051-0.041-0.098-0.041
			c-0.036,0-0.067,0.01-0.095,0.03c-0.027,0.02-0.04,0.051-0.04,0.09c0,0.028,0.009,0.053,0.026,0.075s0.05,0.041,0.098,0.056
			l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.119c0.038,0.049,0.056,0.116,0.056,0.2c0,0.06-0.01,0.112-0.031,0.155
			c-0.021,0.044-0.049,0.079-0.086,0.109c-0.036,0.028-0.08,0.049-0.131,0.062c-0.05,0.012-0.105,0.018-0.167,0.018
			c-0.079,0-0.146-0.007-0.2-0.022c-0.052-0.016-0.094-0.038-0.126-0.069c-0.031-0.03-0.053-0.067-0.065-0.111
			c-0.012-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.832z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M2.573-22.832c0,0.049,0.012,0.088,0.038,0.118
			c0.026,0.029,0.063,0.044,0.111,0.044c0.044,0,0.08-0.011,0.106-0.033c0.026-0.023,0.039-0.055,0.039-0.1
			c0-0.036-0.01-0.063-0.031-0.082c-0.021-0.018-0.045-0.033-0.072-0.044l-0.202-0.073c-0.078-0.027-0.138-0.067-0.178-0.117
			c-0.039-0.05-0.06-0.115-0.06-0.194c0-0.045,0.008-0.089,0.023-0.129c0.015-0.041,0.039-0.077,0.073-0.107
			c0.033-0.03,0.075-0.054,0.127-0.072c0.052-0.019,0.115-0.028,0.188-0.028c0.129,0,0.226,0.028,0.288,0.083
			s0.093,0.134,0.093,0.235v0.045H2.859c0-0.058-0.009-0.1-0.028-0.127c-0.019-0.027-0.052-0.041-0.098-0.041
			c-0.037,0-0.068,0.01-0.095,0.03c-0.027,0.02-0.04,0.051-0.04,0.09c0,0.028,0.008,0.053,0.026,0.075
			c0.017,0.022,0.05,0.041,0.098,0.056l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.119s0.056,0.116,0.056,0.2
			c0,0.06-0.01,0.112-0.031,0.155c-0.021,0.044-0.05,0.079-0.086,0.109c-0.037,0.028-0.081,0.049-0.131,0.062
			c-0.05,0.012-0.106,0.018-0.167,0.018c-0.079,0-0.146-0.007-0.2-0.022C2.475-22.52,2.431-22.542,2.4-22.573
			c-0.032-0.03-0.053-0.067-0.065-0.111c-0.013-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.832z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-2.634-21.777c-0.026-0.049-0.073-0.074-0.14-0.074
			c-0.038,0-0.068,0.01-0.092,0.03c-0.025,0.021-0.044,0.053-0.058,0.098c-0.013,0.044-0.023,0.104-0.028,0.176
			c-0.006,0.073-0.009,0.161-0.009,0.266c0,0.112,0.004,0.203,0.012,0.272c0.007,0.069,0.019,0.123,0.035,0.161
			c0.016,0.037,0.036,0.063,0.06,0.076c0.024,0.013,0.052,0.02,0.084,0.02c0.026,0,0.051-0.004,0.074-0.014
			c0.023-0.008,0.042-0.027,0.058-0.053c0.017-0.027,0.03-0.065,0.04-0.114c0.01-0.05,0.015-0.114,0.015-0.194h0.298
			c0,0.08-0.007,0.156-0.018,0.228c-0.013,0.072-0.036,0.134-0.07,0.188c-0.034,0.054-0.082,0.096-0.143,0.127
			c-0.062,0.03-0.141,0.045-0.239,0.045c-0.112,0-0.201-0.018-0.268-0.053c-0.066-0.036-0.117-0.089-0.152-0.156
			c-0.035-0.068-0.058-0.149-0.069-0.242c-0.01-0.094-0.015-0.198-0.015-0.311c0-0.112,0.005-0.215,0.015-0.31
			c0.011-0.094,0.034-0.176,0.069-0.244c0.035-0.067,0.086-0.12,0.152-0.158c0.067-0.038,0.156-0.057,0.268-0.057
			c0.106,0,0.19,0.017,0.252,0.052c0.061,0.034,0.107,0.078,0.137,0.131c0.03,0.054,0.05,0.111,0.057,0.172
			c0.008,0.061,0.011,0.119,0.011,0.173h-0.298C-2.596-21.649-2.609-21.728-2.634-21.777"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-2.048-20.41c0.052,0,0.09-0.013,0.113-0.041s0.036-0.065,0.04-0.11
			l-0.313-1.115h0.299l0.166,0.771h0.004l0.145-0.771h0.286l-0.27,1.04c-0.025,0.093-0.049,0.168-0.071,0.225
			c-0.023,0.057-0.05,0.102-0.081,0.133c-0.031,0.031-0.069,0.051-0.112,0.061c-0.044,0.01-0.098,0.014-0.165,0.014h-0.116v-0.213
			C-2.099-20.412-2.073-20.41-2.048-20.41"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M-1.184-21.362c0-0.065,0.01-0.119,0.031-0.162
			c0.021-0.044,0.049-0.079,0.084-0.106c0.035-0.027,0.076-0.047,0.124-0.058c0.048-0.012,0.099-0.018,0.153-0.018
			c0.085,0,0.155,0.008,0.207,0.025c0.052,0.017,0.093,0.041,0.122,0.071c0.029,0.03,0.049,0.066,0.059,0.108
			c0.011,0.042,0.015,0.088,0.015,0.138v0.587c0,0.052,0.003,0.093,0.008,0.122c0.005,0.029,0.014,0.06,0.028,0.093h-0.274
			c-0.009-0.017-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059h-0.004c-0.033,0.059-0.071,0.096-0.115,0.113
			c-0.044,0.017-0.1,0.026-0.169,0.026c-0.05,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.071
			c-0.021-0.03-0.036-0.065-0.046-0.103c-0.01-0.038-0.014-0.075-0.014-0.113c0-0.052,0.005-0.097,0.016-0.135
			c0.012-0.039,0.028-0.071,0.051-0.098c0.023-0.027,0.051-0.05,0.087-0.067c0.035-0.019,0.078-0.034,0.128-0.048l0.161-0.044
			c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.076-0.03-0.101
			c-0.02-0.024-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.1,0.039c-0.022,0.027-0.034,0.062-0.034,0.106v0.031h-0.256V-21.362
			z M-0.724-21.083c-0.022,0.009-0.042,0.016-0.06,0.02c-0.059,0.012-0.1,0.033-0.124,0.062c-0.025,0.029-0.038,0.068-0.038,0.118
			c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.044,0.046,0.083,0.046c0.019,0,0.039-0.004,0.06-0.01
			c0.021-0.006,0.04-0.016,0.057-0.03s0.031-0.032,0.043-0.054c0.01-0.022,0.016-0.048,0.016-0.079v-0.219
			C-0.682-21.104-0.702-21.092-0.724-21.083"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M0.088-21.676v0.131h0.005c0.03-0.056,0.069-0.096,0.117-0.122
			c0.049-0.025,0.104-0.039,0.167-0.039c0.09,0,0.16,0.025,0.209,0.076c0.05,0.049,0.074,0.13,0.074,0.242v0.827H0.375v-0.769
			c0-0.058-0.011-0.098-0.03-0.121c-0.019-0.023-0.05-0.035-0.093-0.035c-0.101,0-0.152,0.062-0.152,0.185v0.74h-0.285v-1.115H0.088
			z"
      />
      <rect
        x="6.249"
        y="-26.427"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#005891"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M7.163-25.927c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59H6.641v-1.479H7.163z M7.072-25.258c0.059,0,0.107-0.018,0.144-0.054
			s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.159-0.058H6.938v0.45H7.072z"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        points="8.166,-25.927 8.377,-24.882 8.382,-24.882 8.595,-25.927
			9.036,-25.927 9.036,-24.448 8.763,-24.448 8.763,-25.629 8.759,-25.629 8.496,-24.448 8.263,-24.448 8,-25.629 7.996,-25.629
			7.996,-24.448 7.722,-24.448 7.722,-25.927 		"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M9.49-24.866c0,0.034,0.003,0.066,0.009,0.094
			c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
			c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.047-0.045-0.066s-0.048-0.037-0.082-0.053C9.692-25.041,9.65-25.057,9.6-25.073
			c-0.066-0.021-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
			c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041H9.843c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
			c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.139,0.078s0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
			c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061H9.49V-24.866z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M10.757-24.447c0-0.065,0.007-0.125,0.02-0.182
			c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.149l0.121-0.111
			c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
			c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
			c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.057
			c0-0.136,0.036-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
			c0.071,0.07,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.009,0.041-0.021,0.081-0.039,0.117
			c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
			c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H10.757z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M12.095-24.66c0.019,0.032,0.054,0.047,0.104,0.047
			c0.041,0,0.072-0.009,0.096-0.028c0.023-0.019,0.042-0.045,0.054-0.078c0.014-0.033,0.022-0.073,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.066-0.106,0.089s-0.103,0.035-0.169,0.035
			c-0.065,0-0.12-0.012-0.165-0.035c-0.047-0.023-0.084-0.054-0.112-0.095c-0.028-0.041-0.049-0.089-0.062-0.145
			c-0.013-0.056-0.019-0.117-0.019-0.183c0-0.17,0.037-0.3,0.112-0.39c0.076-0.09,0.196-0.135,0.362-0.135
			c0.102,0,0.184,0.019,0.244,0.058c0.061,0.037,0.108,0.088,0.14,0.151c0.033,0.063,0.054,0.135,0.064,0.218
			c0.009,0.083,0.014,0.17,0.014,0.26c0,0.105-0.003,0.206-0.009,0.303c-0.006,0.098-0.025,0.184-0.056,0.258
			c-0.031,0.075-0.08,0.133-0.147,0.177c-0.067,0.043-0.161,0.065-0.281,0.065c-0.053,0-0.103-0.005-0.153-0.015
			c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.107-0.037-0.178h0.287
			C12.065-24.734,12.074-24.691,12.095-24.66 M12.079-25.216c0.025,0.042,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.127-0.064
			c0.025-0.043,0.037-0.115,0.037-0.214c0-0.041-0.002-0.08-0.006-0.116c-0.004-0.036-0.012-0.066-0.024-0.092
			c-0.011-0.025-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022c-0.035,0-0.064,0.008-0.086,0.022
			c-0.021,0.015-0.037,0.035-0.048,0.061c-0.011,0.026-0.019,0.057-0.022,0.092c-0.003,0.036-0.005,0.074-0.005,0.115
			C12.042-25.331,12.054-25.259,12.079-25.216"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M13.225-25.916h0.309v0.931h0.142v0.232h-0.142v0.305H13.26v-0.305h-0.522
			v-0.244L13.225-25.916z M13.256-25.542l-0.287,0.557h0.291v-0.557H13.256z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M14.044-25.654l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.084
			c0.044-0.017,0.098-0.026,0.161-0.026c0.071,0,0.13,0.013,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.107
			c0.027,0.044,0.046,0.093,0.057,0.15c0.011,0.055,0.017,0.113,0.017,0.17c0,0.082-0.007,0.156-0.021,0.223
			c-0.013,0.066-0.038,0.122-0.073,0.171c-0.036,0.047-0.084,0.083-0.144,0.107c-0.061,0.024-0.138,0.036-0.232,0.036
			c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.04-0.051-0.085-0.061-0.132
			c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.051,0.005,0.079c0.002,0.027,0.009,0.053,0.019,0.077
			c0.011,0.024,0.025,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.077,0.024c0.062,0,0.106-0.024,0.133-0.071
			c0.027-0.048,0.041-0.12,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
			c-0.013-0.023-0.03-0.04-0.053-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
			c-0.023,0.025-0.037,0.058-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H14.044z"
      />
      <rect
        x="15.886"
        y="-26.427"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFDD00"
        width="8.813"
        height="8.813"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M16.8-25.927c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H16.8z M16.709-25.258c0.059,0,0.107-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H16.709z"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        points="17.803,-25.927 18.014,-24.882 18.019,-24.882 18.232,-25.927
			18.674,-25.927 18.674,-24.448 18.4,-24.448 18.4,-25.629 18.396,-25.629 18.133,-24.448 17.9,-24.448 17.638,-25.629
			17.634,-25.629 17.634,-24.448 17.36,-24.448 17.36,-25.927 		"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M19.128-24.866c0,0.034,0.003,0.066,0.008,0.094
			c0.005,0.028,0.016,0.052,0.031,0.072s0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
			c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.008-0.025-0.024-0.047-0.044-0.066c-0.021-0.02-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.124-0.046-0.172-0.073c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			s-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.021
			c0.056,0.015,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041H19.48
			c0-0.071-0.013-0.125-0.037-0.162c-0.025-0.039-0.067-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
			c-0.023,0.009-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
			c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
			s0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.011,0.079,0.011,0.125
			c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
			c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.866z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M20.734-25.484h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.235-0.055
			c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.298V-25.484z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M21.4-25.425c0.006-0.095,0.025-0.178,0.056-0.25
			c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.147-0.066,0.256-0.066c0.09,0,0.164,0.013,0.223,0.039
			c0.059,0.026,0.106,0.067,0.141,0.123c0.035,0.057,0.06,0.13,0.075,0.221c0.014,0.09,0.022,0.201,0.022,0.33
			c0,0.102-0.004,0.2-0.011,0.295c-0.007,0.094-0.026,0.177-0.056,0.25c-0.03,0.072-0.077,0.129-0.138,0.17
			c-0.061,0.042-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.023-0.107-0.063-0.142-0.12
			c-0.036-0.056-0.061-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C21.389-25.233,21.392-25.331,21.4-25.425 M21.695-24.84
			c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.028,0.057,0.05,0.069c0.02,0.013,0.046,0.019,0.078,0.019
			c0.033,0,0.061-0.008,0.081-0.024s0.037-0.042,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.152
			c0.004-0.064,0.007-0.142,0.007-0.236v-0.16c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.058-0.014-0.105-0.026-0.139
			c-0.013-0.033-0.03-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.008-0.08,0.024
			c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.089-0.025,0.152c-0.005,0.064-0.008,0.142-0.008,0.237v0.16
			C21.687-24.974,21.69-24.899,21.695-24.84"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M22.727-24.66c0.02,0.032,0.055,0.047,0.105,0.047
			c0.04,0,0.072-0.009,0.096-0.028c0.023-0.019,0.041-0.045,0.054-0.078c0.013-0.033,0.022-0.073,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.025,0.037-0.06,0.066-0.106,0.089c-0.047,0.023-0.103,0.035-0.169,0.035
			c-0.065,0-0.12-0.012-0.166-0.035c-0.046-0.023-0.083-0.054-0.111-0.095c-0.028-0.041-0.049-0.089-0.062-0.145
			c-0.014-0.056-0.02-0.117-0.02-0.183c0-0.17,0.038-0.3,0.113-0.39c0.075-0.09,0.196-0.135,0.362-0.135
			c0.102,0,0.183,0.019,0.244,0.058c0.061,0.037,0.108,0.088,0.14,0.151s0.054,0.135,0.064,0.218c0.009,0.083,0.014,0.17,0.014,0.26
			c0,0.105-0.004,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.057,0.258c-0.03,0.075-0.08,0.133-0.146,0.177
			c-0.068,0.043-0.162,0.065-0.281,0.065c-0.053,0-0.104-0.005-0.153-0.015c-0.049-0.01-0.092-0.03-0.129-0.058
			c-0.037-0.028-0.068-0.066-0.091-0.114c-0.023-0.048-0.034-0.107-0.036-0.178h0.287C22.697-24.734,22.707-24.691,22.727-24.66
			 M22.711-25.216c0.025,0.042,0.066,0.064,0.124,0.064c0.059,0,0.102-0.022,0.126-0.064C22.986-25.259,23-25.331,23-25.43
			c0-0.041-0.002-0.08-0.006-0.116c-0.004-0.036-0.012-0.066-0.024-0.092c-0.012-0.025-0.028-0.045-0.05-0.06
			c-0.021-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.064,0.008-0.086,0.022c-0.021,0.015-0.037,0.035-0.049,0.061
			c-0.011,0.026-0.018,0.057-0.021,0.092c-0.004,0.036-0.005,0.074-0.005,0.115C22.674-25.331,22.687-25.259,22.711-25.216"
      />
      <rect
        x="45.491"
        y="-26.427"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#5087C7"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M46.405-25.927c0.076,0,0.14,0.012,0.191,0.037
			c0.053,0.026,0.095,0.06,0.126,0.101c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.021,0.108,0.021,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.103-0.096,0.139c-0.04,0.036-0.09,0.063-0.146,0.081
			c-0.059,0.017-0.123,0.027-0.195,0.027h-0.158v0.59h-0.299v-1.479H46.405z M46.314-25.258c0.059,0,0.107-0.018,0.145-0.054
			c0.035-0.036,0.054-0.092,0.054-0.168c0-0.074-0.015-0.131-0.048-0.17c-0.031-0.039-0.084-0.058-0.159-0.058h-0.124v0.45H46.314z"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        points="47.408,-25.927 47.619,-24.882 47.625,-24.882 47.838,-25.927
			48.278,-25.927 48.278,-24.448 48.006,-24.448 48.006,-25.629 48.002,-25.629 47.738,-24.448 47.506,-24.448 47.242,-25.629
			47.238,-25.629 47.238,-24.448 46.965,-24.448 46.965,-25.927 		"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M48.733-24.866c0,0.034,0.003,0.066,0.008,0.094s0.017,0.052,0.03,0.072
			c0.016,0.02,0.037,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.014,0.123-0.044
			c0.037-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.015-0.086c-0.009-0.025-0.023-0.047-0.043-0.066
			c-0.021-0.02-0.049-0.037-0.082-0.053s-0.076-0.032-0.127-0.048c-0.064-0.022-0.123-0.046-0.172-0.073
			c-0.048-0.026-0.088-0.057-0.119-0.092c-0.035-0.035-0.058-0.076-0.072-0.122c-0.016-0.046-0.023-0.1-0.023-0.161
			c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.192-0.108,0.336-0.108c0.067,0,0.128,0.007,0.183,0.021
			c0.058,0.015,0.104,0.039,0.146,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
			c0-0.071-0.013-0.125-0.036-0.162c-0.025-0.039-0.068-0.057-0.125-0.057c-0.033,0-0.061,0.004-0.084,0.014
			c-0.021,0.009-0.039,0.023-0.053,0.038c-0.013,0.016-0.021,0.035-0.027,0.055c-0.004,0.021-0.007,0.042-0.007,0.065
			c0,0.045,0.011,0.083,0.03,0.115c0.02,0.031,0.061,0.059,0.124,0.086l0.229,0.099c0.058,0.025,0.104,0.051,0.139,0.078
			c0.037,0.027,0.064,0.056,0.087,0.087c0.021,0.03,0.036,0.065,0.044,0.102c0.008,0.038,0.012,0.079,0.012,0.125
			c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
			c-0.072-0.073-0.107-0.179-0.107-0.315v-0.061h0.298V-24.866z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M49.999-24.447c0-0.065,0.007-0.125,0.021-0.182
			c0.013-0.057,0.033-0.111,0.06-0.162c0.026-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.143-0.149l0.121-0.111
			c0.033-0.029,0.06-0.056,0.078-0.081c0.021-0.025,0.037-0.05,0.047-0.075c0.012-0.025,0.02-0.051,0.022-0.081
			c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.066,0.008-0.088,0.023
			c-0.022,0.015-0.038,0.036-0.051,0.062c-0.01,0.027-0.02,0.057-0.022,0.092c-0.003,0.034-0.004,0.071-0.004,0.11h-0.287v-0.057
			c0-0.136,0.036-0.241,0.11-0.315c0.072-0.073,0.188-0.109,0.346-0.109c0.148,0,0.26,0.034,0.332,0.103
			c0.07,0.07,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.013,0.131s-0.021,0.081-0.038,0.117
			c-0.019,0.038-0.041,0.074-0.072,0.109c-0.028,0.035-0.063,0.072-0.105,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
			c-0.023,0.039-0.041,0.071-0.047,0.099h0.554v0.232H49.999z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M51.906-25.658c-0.062,0.086-0.115,0.178-0.162,0.276
			c-0.049,0.098-0.09,0.199-0.127,0.302c-0.035,0.104-0.064,0.21-0.088,0.317c-0.024,0.107-0.043,0.212-0.055,0.315h-0.313
			c0.011-0.088,0.026-0.181,0.05-0.278c0.021-0.098,0.051-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
			c0.054-0.103,0.115-0.203,0.187-0.3h-0.617v-0.257h0.904V-25.658z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M52.328-25.484h-0.291v-0.195h0.026c0.099,0,0.177-0.018,0.234-0.055
			c0.058-0.038,0.095-0.098,0.11-0.181h0.219v1.467h-0.299V-25.484z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M53.037-25.101c0.041-0.066,0.104-0.108,0.188-0.124v-0.004
			c-0.041-0.01-0.074-0.025-0.102-0.046c-0.026-0.021-0.049-0.044-0.064-0.07c-0.018-0.027-0.029-0.054-0.036-0.083
			c-0.007-0.03-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.114-0.31s0.182-0.094,0.316-0.094s0.239,0.031,0.316,0.094
			s0.115,0.166,0.115,0.31c0,0.026-0.004,0.053-0.012,0.083c-0.008,0.029-0.02,0.056-0.035,0.083
			c-0.018,0.026-0.039,0.049-0.066,0.07s-0.061,0.036-0.098,0.046v0.004c0.082,0.016,0.144,0.058,0.186,0.124
			c0.041,0.067,0.063,0.146,0.063,0.239c0,0.043-0.006,0.09-0.016,0.141c-0.01,0.051-0.031,0.098-0.064,0.142
			c-0.033,0.045-0.082,0.081-0.143,0.11s-0.144,0.044-0.246,0.044c-0.102,0-0.184-0.015-0.246-0.044
			c-0.062-0.029-0.107-0.065-0.141-0.11c-0.034-0.044-0.055-0.091-0.066-0.142c-0.01-0.051-0.015-0.098-0.015-0.141
			C52.976-24.954,52.996-25.033,53.037-25.101 M53.281-24.769c0.004,0.032,0.015,0.059,0.026,0.082
			c0.013,0.022,0.031,0.041,0.054,0.054s0.049,0.019,0.082,0.019s0.062-0.006,0.084-0.019c0.021-0.013,0.039-0.032,0.053-0.054
			c0.013-0.023,0.021-0.05,0.026-0.082c0.005-0.03,0.007-0.063,0.007-0.098s-0.002-0.069-0.007-0.099
			c-0.005-0.031-0.014-0.057-0.026-0.08c-0.014-0.023-0.031-0.041-0.053-0.054c-0.022-0.013-0.051-0.019-0.084-0.019
			s-0.06,0.006-0.082,0.019c-0.022,0.013-0.041,0.031-0.054,0.054c-0.012,0.023-0.022,0.049-0.026,0.08
			c-0.006,0.03-0.008,0.064-0.008,0.099S53.275-24.798,53.281-24.769 M53.338-25.363c0.025,0.033,0.062,0.05,0.105,0.05
			c0.045,0,0.079-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.202-0.146-0.202
			c-0.097,0-0.145,0.067-0.145,0.202C53.299-25.448,53.312-25.396,53.338-25.363"
      />
      <rect
        x="35.623"
        y="-26.427"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        width="8.813"
        height="8.813"
      />

      <rect
        x="35.623"
        y="-26.427"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="none"
        stroke="#000000"
        strokeWidth="0.13"
        strokeMiterlimit="10"
        width="8.813"
        height="8.813"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        points="36.214,-25.927 36.377,-24.833 36.382,-24.833 36.556,-25.927 36.842,-25.927
			37.016,-24.833 37.021,-24.833 37.185,-25.927 37.47,-25.927 37.2,-24.448 36.866,-24.448 36.7,-25.521 36.696,-25.521
			36.53,-24.448 36.196,-24.448 35.928,-25.927 		"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        points="37.888,-25.927 37.888,-25.361 38.237,-25.361 38.237,-25.927 38.535,-25.927
			38.535,-24.448 38.237,-24.448 38.237,-25.104 37.888,-25.104 37.888,-24.448 37.591,-24.448 37.591,-25.927 		"
      />
      <rect
        x="38.757"
        y="-25.927"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        width="0.299"
        height="1.479"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        points="40.144,-25.683 39.82,-25.683 39.82,-24.447 39.521,-24.447 39.521,-25.683
			39.198,-25.683 39.198,-25.928 40.144,-25.928 		"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        points="41.099,-25.683 40.569,-25.683 40.569,-25.334 41.067,-25.334 41.067,-25.089
			40.569,-25.089 40.569,-24.691 41.12,-24.691 41.12,-24.447 40.271,-24.447 40.271,-25.928 41.099,-25.928 		"
      />
      <rect
        x="25.753"
        y="-26.427"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#905D39"
        width="8.813"
        height="8.813"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M26.668-25.926c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H26.668z M26.577-25.257c0.059,0,0.107-0.018,0.144-0.054
			s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H26.577z"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        points="27.672,-25.927 27.883,-24.882 27.888,-24.882 28.101,-25.927
			28.542,-25.927 28.542,-24.448 28.269,-24.448 28.269,-25.629 28.265,-25.629 28.001,-24.448 27.769,-24.448 27.506,-25.629
			27.501,-25.629 27.501,-24.448 27.228,-24.448 27.228,-25.927 		"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M28.996-24.866c0,0.034,0.003,0.066,0.009,0.094
			c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
			c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.047-0.045-0.066s-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
			c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
			c-0.033,0-0.061,0.004-0.083,0.014c-0.022,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.341s-0.216,0.106-0.378,0.106
			c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.866z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M30.74-25.915h0.309v0.931h0.142v0.232h-0.142v0.305h-0.273v-0.305h-0.522
			v-0.244L30.74-25.915z M30.772-25.542l-0.287,0.557h0.291v-0.557H30.772z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M31.835-25.675c-0.02-0.03-0.056-0.045-0.105-0.045
			c-0.039,0-0.07,0.01-0.095,0.028c-0.023,0.018-0.042,0.045-0.056,0.077c-0.013,0.034-0.021,0.074-0.025,0.119
			c-0.004,0.046-0.007,0.095-0.007,0.149v0.073h0.005c0.025-0.039,0.06-0.07,0.107-0.092c0.046-0.023,0.102-0.035,0.168-0.035
			c0.065,0,0.12,0.012,0.167,0.035c0.045,0.022,0.082,0.054,0.111,0.095c0.027,0.041,0.049,0.089,0.062,0.145
			c0.013,0.056,0.021,0.117,0.021,0.183c0,0.17-0.039,0.299-0.113,0.387c-0.075,0.088-0.196,0.131-0.362,0.131
			c-0.102,0-0.183-0.017-0.244-0.053c-0.061-0.036-0.108-0.086-0.14-0.148c-0.033-0.064-0.054-0.136-0.064-0.219
			c-0.009-0.083-0.014-0.169-0.014-0.259c0-0.104,0.003-0.205,0.009-0.302c0.006-0.098,0.025-0.184,0.057-0.259
			c0.03-0.075,0.079-0.135,0.145-0.182c0.067-0.045,0.161-0.068,0.282-0.068c0.052,0,0.104,0.007,0.152,0.02
			c0.051,0.013,0.094,0.033,0.131,0.062c0.037,0.029,0.067,0.067,0.09,0.115s0.035,0.107,0.035,0.178h-0.286
			C31.863-25.599,31.855-25.644,31.835-25.675 M31.568-24.787c0.005,0.036,0.012,0.066,0.024,0.092
			c0.012,0.026,0.028,0.046,0.05,0.06c0.021,0.014,0.049,0.022,0.084,0.022c0.036,0,0.064-0.008,0.086-0.022
			c0.021-0.014,0.037-0.034,0.047-0.061c0.013-0.026,0.02-0.057,0.022-0.093c0.004-0.034,0.005-0.073,0.005-0.115
			c0-0.099-0.012-0.17-0.037-0.213c-0.025-0.043-0.066-0.064-0.124-0.064c-0.06,0-0.102,0.021-0.126,0.064
			c-0.025,0.043-0.038,0.114-0.038,0.213C31.562-24.862,31.564-24.823,31.568-24.787"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M32.576-24.681c0.021,0.045,0.065,0.068,0.13,0.068
			c0.035,0,0.064-0.007,0.086-0.02c0.021-0.013,0.037-0.029,0.049-0.051c0.012-0.021,0.02-0.047,0.023-0.075
			c0.003-0.029,0.005-0.06,0.005-0.091c0-0.034-0.002-0.065-0.007-0.095c-0.006-0.029-0.014-0.056-0.028-0.079
			c-0.013-0.024-0.032-0.043-0.058-0.057c-0.025-0.013-0.059-0.021-0.1-0.021h-0.1v-0.195h0.098c0.031,0,0.059-0.006,0.079-0.019
			c0.022-0.013,0.039-0.03,0.052-0.051c0.015-0.022,0.023-0.047,0.029-0.075c0.006-0.027,0.01-0.056,0.01-0.087
			c0-0.07-0.013-0.119-0.035-0.149c-0.024-0.029-0.06-0.043-0.108-0.043c-0.031,0-0.058,0.006-0.077,0.017
			c-0.02,0.012-0.035,0.029-0.047,0.05c-0.011,0.022-0.018,0.047-0.021,0.076c-0.004,0.029-0.006,0.061-0.006,0.095h-0.272
			c0-0.146,0.036-0.255,0.108-0.326c0.073-0.071,0.182-0.107,0.322-0.107c0.135,0,0.237,0.03,0.313,0.092
			c0.074,0.06,0.109,0.155,0.109,0.286c0,0.088-0.019,0.16-0.059,0.216c-0.039,0.056-0.094,0.091-0.164,0.107v0.004
			c0.095,0.015,0.162,0.053,0.202,0.115c0.038,0.061,0.059,0.138,0.059,0.231c0,0.049-0.006,0.101-0.019,0.153
			c-0.012,0.053-0.037,0.1-0.072,0.144c-0.037,0.042-0.085,0.077-0.148,0.103c-0.063,0.026-0.146,0.039-0.248,0.039
			c-0.142,0-0.248-0.038-0.319-0.116c-0.07-0.077-0.104-0.187-0.104-0.329v-0.007h0.285C32.543-24.791,32.556-24.726,32.576-24.681"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#FFFFFF"
        d="M33.55-25.654l-0.031,0.334h0.004c0.027-0.039,0.063-0.067,0.107-0.084
			c0.045-0.017,0.099-0.026,0.161-0.026c0.071,0,0.13,0.013,0.177,0.041c0.045,0.026,0.082,0.062,0.108,0.107
			c0.027,0.044,0.046,0.093,0.058,0.15c0.01,0.055,0.017,0.113,0.017,0.17c0,0.082-0.007,0.156-0.021,0.223
			c-0.014,0.066-0.039,0.122-0.074,0.171c-0.034,0.047-0.084,0.083-0.144,0.107c-0.062,0.024-0.138,0.036-0.231,0.036
			s-0.168-0.012-0.226-0.037s-0.101-0.058-0.132-0.099c-0.029-0.04-0.051-0.085-0.061-0.132c-0.01-0.048-0.017-0.092-0.017-0.134
			v-0.039h0.286c0,0.024,0.002,0.051,0.005,0.079c0.002,0.027,0.009,0.053,0.019,0.077c0.012,0.024,0.025,0.045,0.045,0.06
			c0.021,0.016,0.046,0.024,0.078,0.024c0.062,0,0.105-0.024,0.133-0.071c0.027-0.048,0.041-0.12,0.041-0.215
			c0-0.061-0.004-0.111-0.01-0.151c-0.004-0.04-0.015-0.072-0.027-0.096c-0.014-0.023-0.03-0.04-0.053-0.049
			c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.1,0.036c-0.023,0.025-0.037,0.058-0.042,0.099h-0.268l0.054-0.798
			h0.766v0.232H33.55z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M12.737,75.937c0.592,0,0.973,0.391,0.973,0.992
			c0,0.594-0.381,0.984-0.973,0.984c-0.588,0-0.968-0.391-0.968-0.984C11.769,76.327,12.149,75.937,12.737,75.937 M12.737,77.526
			c0.352,0,0.458-0.301,0.458-0.598c0-0.303-0.106-0.604-0.458-0.604c-0.348,0-0.453,0.301-0.453,0.604
			C12.284,77.226,12.389,77.526,12.737,77.526"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M14.695,75.987h0.378v0.346h-0.378v0.928c0,0.174,0.044,0.219,0.218,0.219
			c0.054,0,0.105-0.004,0.16-0.016v0.402c-0.087,0.016-0.2,0.02-0.301,0.02c-0.316,0-0.592-0.074-0.592-0.447v-1.105h-0.312v-0.346
			h0.312v-0.563h0.515V75.987z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M15.743,77.052c0.014,0.324,0.174,0.475,0.46,0.475
			c0.208,0,0.375-0.127,0.408-0.244h0.453c-0.145,0.443-0.453,0.631-0.878,0.631c-0.592,0-0.958-0.404-0.958-0.984
			c0-0.564,0.388-0.992,0.958-0.992c0.639,0,0.946,0.537,0.911,1.115H15.743z M16.581,76.724c-0.047-0.26-0.16-0.398-0.41-0.398
			c-0.327,0-0.421,0.254-0.428,0.398H16.581z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M17.8,77.253c0.004,0.227,0.192,0.316,0.396,0.316
			c0.149,0,0.337-0.059,0.337-0.24c0-0.156-0.214-0.209-0.584-0.289c-0.298-0.064-0.595-0.17-0.595-0.502
			c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.139,0.828,0.598h-0.491c-0.014-0.199-0.166-0.254-0.351-0.254
			c-0.116,0-0.287,0.021-0.287,0.176c0,0.186,0.29,0.209,0.584,0.279c0.301,0.068,0.596,0.178,0.596,0.525
			c0,0.494-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.162-0.882-0.66H17.8z"
      />
      <rect
        x="8.942"
        y="80.466"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        width="1.815"
        height="0.291"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M12.048,79.028h0.889c0.798,0,1.244,0.398,1.244,1.234
			c0,0.867-0.38,1.357-1.244,1.357h-0.889V79.028z M12.392,81.329h0.574c0.235,0,0.871-0.064,0.871-1.02
			c0-0.615-0.228-0.99-0.864-0.99h-0.581V81.329z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M15.359,79.7c0.595,0,0.911,0.432,0.911,0.984
			c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.448,80.132,14.764,79.7,15.359,79.7 M15.359,81.392
			c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
			C14.775,81.138,15.036,81.392,15.359,81.392"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M17.64,79.743h0.291v0.297h0.006c0.131-0.23,0.341-0.34,0.61-0.34
			c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.27c0-0.229-0.146-0.379-0.381-0.379c-0.374,0-0.555,0.25-0.555,0.59v1.059
			H17.64V79.743z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M20.466,79.7c0.595,0,0.911,0.432,0.911,0.984
			c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.555,80.132,19.871,79.7,20.466,79.7 M20.466,81.392
			c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
			C19.882,81.138,20.143,81.392,20.466,81.392"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M22.167,79.743h0.374v0.273h-0.374v1.164c0,0.143,0.04,0.166,0.233,0.166
			h0.141v0.273h-0.236c-0.319,0-0.447-0.066-0.447-0.41v-1.193H21.54v-0.273h0.318v-0.563h0.309V79.743z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M25.552,81.612c-0.055,0.033-0.123,0.051-0.222,0.051
			c-0.16,0-0.261-0.088-0.261-0.291c-0.171,0.201-0.399,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.525
			c0-0.424,0.316-0.516,0.635-0.578c0.341-0.064,0.631-0.043,0.631-0.275c0-0.268-0.221-0.313-0.417-0.313
			c-0.261,0-0.453,0.082-0.468,0.355h-0.309c0.018-0.465,0.378-0.627,0.795-0.627c0.338,0,0.704,0.076,0.704,0.516v0.965
			c0,0.145,0,0.211,0.098,0.211c0.026,0,0.055-0.004,0.095-0.02V81.612z M25.051,80.651c-0.12,0.086-0.352,0.09-0.559,0.127
			c-0.203,0.035-0.378,0.109-0.378,0.336c0,0.205,0.175,0.277,0.363,0.277c0.407,0,0.574-0.254,0.574-0.424V80.651z"
      />
      <rect
        x="25.855"
        y="79.028"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        width="0.308"
        height="2.592"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M27.07,79.743h0.374v0.271H27.07v1.166c0,0.143,0.04,0.166,0.232,0.166
			h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319v-0.563h0.309V79.743z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M29.385,81.024c-0.084,0.414-0.381,0.639-0.803,0.639
			c-0.598,0-0.881-0.414-0.899-0.986c0-0.563,0.37-0.977,0.881-0.977c0.664,0,0.868,0.619,0.849,1.074h-1.404
			c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.367H29.385z M29.087,80.503
			c-0.014-0.295-0.236-0.531-0.541-0.531c-0.323,0-0.515,0.244-0.537,0.531H29.087z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M29.721,79.743h0.291v0.396h0.006c0.149-0.301,0.356-0.449,0.69-0.439v0.326
			c-0.497,0-0.678,0.283-0.678,0.76v0.834h-0.309V79.743z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M33.61,81.612c-0.055,0.033-0.123,0.051-0.223,0.051
			c-0.16,0-0.261-0.088-0.261-0.291c-0.17,0.201-0.399,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.525
			c0-0.426,0.316-0.516,0.635-0.578c0.341-0.064,0.632-0.043,0.632-0.275c0-0.268-0.222-0.313-0.419-0.313
			c-0.26,0-0.452,0.082-0.467,0.355h-0.309c0.018-0.465,0.378-0.627,0.794-0.627c0.338,0,0.705,0.076,0.705,0.516v0.965
			c0,0.145,0,0.211,0.097,0.211c0.026,0,0.055-0.004,0.096-0.02V81.612z M33.108,80.651c-0.118,0.086-0.352,0.09-0.559,0.127
			c-0.203,0.035-0.377,0.109-0.377,0.336c0,0.205,0.174,0.277,0.363,0.277c0.406,0,0.572-0.254,0.572-0.424V80.651z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M33.884,79.743h0.291v0.396h0.006c0.149-0.301,0.357-0.449,0.69-0.439v0.326
			c-0.497,0-0.678,0.283-0.678,0.76v0.834h-0.31V79.743z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M35.532,79.743h0.373v0.271h-0.373v1.166c0,0.143,0.039,0.166,0.231,0.166
			h0.142v0.273h-0.236c-0.319,0-0.446-0.066-0.446-0.41v-1.195h-0.319v-0.271h0.319v-0.563h0.31V79.743z"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        points="38.104,81.62 37.778,81.62 37.396,80.128 37.389,80.128
			37.012,81.62 36.678,81.62 36.075,79.743 36.415,79.743 36.841,81.278 36.849,81.278 37.226,79.743 37.563,79.743 37.954,81.278
			37.962,81.278 38.383,79.743 38.702,79.743 		"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M39.805,79.7c0.595,0,0.911,0.432,0.911,0.984
			c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.432-0.911-0.979C38.894,80.132,39.21,79.7,39.805,79.7 M39.805,81.392
			c0.322,0,0.584-0.254,0.584-0.707c0-0.459-0.262-0.713-0.584-0.713c-0.323,0-0.585,0.254-0.585,0.713
			C39.22,81.138,39.481,81.392,39.805,81.392"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M41.066,79.743h0.29v0.396h0.007c0.149-0.301,0.356-0.449,0.69-0.439v0.326
			c-0.498,0-0.68,0.283-0.68,0.76v0.834h-0.308V79.743z"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        points="42.303,79.028 42.612,79.028 42.612,80.563 43.468,79.743
			43.882,79.743 43.138,80.425 43.937,81.62 43.544,81.62 42.901,80.64 42.612,80.909 42.612,81.62 42.303,81.62 		"
      />
      <rect
        x="8.942"
        y="84.222"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        width="1.815"
        height="0.291"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M14.116,84.44c0,0.652-0.375,0.998-1.02,0.998
			c-0.668,0-1.063-0.309-1.063-0.998v-1.656h0.345v1.656c0,0.457,0.26,0.707,0.718,0.707c0.435,0,0.675-0.25,0.675-0.707v-1.656
			h0.345V84.44z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M14.804,84.784c0.011,0.277,0.25,0.363,0.505,0.363
			c0.192,0,0.453-0.043,0.453-0.279c0-0.24-0.305-0.279-0.613-0.348c-0.305-0.07-0.614-0.172-0.614-0.531
			c0-0.377,0.374-0.533,0.701-0.533c0.414,0,0.744,0.131,0.769,0.588h-0.308c-0.022-0.238-0.233-0.314-0.436-0.314
			c-0.185,0-0.399,0.051-0.399,0.238c0,0.223,0.326,0.258,0.614,0.326c0.308,0.07,0.612,0.172,0.612,0.535
			c0,0.445-0.417,0.59-0.798,0.59c-0.421,0-0.777-0.17-0.794-0.635H14.804z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M18.029,84.782c-0.083,0.412-0.381,0.637-0.802,0.637
			c-0.599,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.621,0.849,1.074h-1.405
			c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.365H18.029z M17.731,84.259
			c-0.014-0.295-0.236-0.529-0.54-0.529c-0.323,0-0.515,0.242-0.538,0.529H17.731z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M19.813,83.501h0.374v0.271h-0.374v1.164c0,0.143,0.039,0.168,0.232,0.168
			h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.271h0.319v-0.564h0.309V83.501z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M20.853,83.163h-0.308v-0.379h0.308V83.163z M20.545,83.501h0.308v1.875
			h-0.308V83.501z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M22.95,85.216c0,0.609-0.279,0.922-0.871,0.922
			c-0.352,0-0.758-0.141-0.776-0.551h0.308c0.015,0.225,0.283,0.305,0.486,0.305c0.403,0,0.563-0.291,0.563-0.711v-0.123h-0.007
			c-0.102,0.227-0.349,0.34-0.585,0.34c-0.558,0-0.838-0.438-0.838-0.951c0-0.441,0.218-0.99,0.867-0.99
			c0.236,0,0.447,0.105,0.559,0.313h0.004v-0.268h0.29V85.216z M22.645,84.399c0-0.33-0.145-0.672-0.533-0.672
			c-0.396,0-0.556,0.324-0.556,0.684c0,0.338,0.124,0.715,0.53,0.715C22.493,85.126,22.645,84.753,22.645,84.399"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M23.414,82.784h0.308v0.992h0.008c0.102-0.225,0.366-0.318,0.591-0.318
			c0.494,0,0.646,0.281,0.646,0.684v1.234h-0.308v-1.27c0-0.229-0.146-0.379-0.381-0.379c-0.374,0-0.556,0.252-0.556,0.59v1.059
			h-0.308V82.784z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M25.858,83.501h0.374v0.271h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
			h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193H25.23v-0.271h0.319v-0.564h0.309V83.501z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M28.39,83.456c0.595,0,0.911,0.432,0.911,0.984
			c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.432-0.911-0.979C27.479,83.888,27.795,83.456,28.39,83.456 M28.39,85.147
			c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
			C27.806,84.894,28.067,85.147,28.39,85.147"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        points="30.508,85.376 30.178,85.376 29.482,83.501 29.826,83.501
			30.352,85.063 30.359,85.063 30.871,83.501 31.194,83.501 		"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M33.076,84.782c-0.082,0.412-0.38,0.637-0.802,0.637
			c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.883-0.977c0.663,0,0.867,0.621,0.85,1.074h-1.405
			c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.365H33.076z M32.779,84.259
			c-0.015-0.295-0.236-0.529-0.54-0.529c-0.323,0-0.516,0.242-0.538,0.529H32.779z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M33.413,83.501h0.29v0.395h0.007c0.149-0.301,0.355-0.449,0.69-0.439v0.326
			c-0.497,0-0.679,0.283-0.679,0.76v0.834h-0.309V83.501z"
      />
      <rect
        x="34.65"
        y="82.786"
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        width="0.308"
        height="2.59"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M36.246,83.456c0.595,0,0.911,0.432,0.911,0.984
			c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.432-0.911-0.979C35.335,83.888,35.651,83.456,36.246,83.456 M36.246,85.147
			c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711s-0.584,0.254-0.584,0.711
			C35.662,84.894,35.923,85.147,36.246,85.147"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M38.792,84.103c-0.047-0.236-0.209-0.373-0.464-0.373
			c-0.446,0-0.584,0.352-0.584,0.732c0,0.346,0.155,0.686,0.548,0.686c0.297,0,0.476-0.174,0.514-0.457h0.318
			c-0.07,0.457-0.356,0.729-0.828,0.729c-0.574,0-0.879-0.398-0.879-0.957c0-0.563,0.291-1.006,0.885-1.006
			c0.426,0,0.767,0.201,0.811,0.646H38.792z"
      />
      <polygon
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        points="39.484,82.784 39.793,82.784 39.793,84.319 40.649,83.501
			41.063,83.501 40.319,84.183 41.118,85.376 40.726,85.376 40.082,84.396 39.793,84.665 39.793,85.376 39.484,85.376 		"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M41.675,83.163h-0.309v-0.379h0.309V83.163z M41.366,83.501h0.309v1.875
			h-0.309V83.501z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M42.153,83.501h0.291v0.297h0.006c0.131-0.232,0.342-0.342,0.61-0.342
			c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.059
			h-0.309V83.501z"
      />
      <path
        clipPath="url(#GUIDINGHISTORY_SVGID_104_)"
        fill="#EC008C"
        d="M45.789,85.216c0,0.611-0.279,0.922-0.871,0.922
			c-0.353,0-0.758-0.141-0.776-0.551h0.308c0.016,0.225,0.283,0.305,0.486,0.305c0.403,0,0.563-0.291,0.563-0.711v-0.123h-0.006
			c-0.103,0.229-0.35,0.34-0.586,0.34c-0.558,0-0.837-0.438-0.837-0.949c0-0.443,0.218-0.992,0.866-0.992
			c0.236,0,0.447,0.105,0.559,0.313h0.004v-0.268h0.291V85.216z M45.483,84.399c0-0.33-0.145-0.672-0.532-0.672
			c-0.396,0-0.557,0.324-0.557,0.684c0,0.338,0.124,0.715,0.53,0.715C45.332,85.126,45.483,84.753,45.483,84.399"
      />
    </g>
  );
};

const HumanRights = () => {
  return (
    <g id="Human_Rights">
      <defs>
        <rect
          id="HUMANRIGHTS_SVGID_105_"
          x="0.03"
          width="61.687"
          height="61.688"
        />
      </defs>
      <clipPath id="HUMANRIGHTS_SVGID_106_">
        <use xlinkHref="#HUMANRIGHTS_SVGID_105_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#00AEEF"
        d="M55.724,61.658H6.398c-3.06,0-5.564-2.503-5.564-5.563V6.769
			c0-3.06,2.504-5.563,5.564-5.563h49.326c3.061,0,5.563,2.503,5.563,5.563v49.327C61.286,59.155,58.784,61.658,55.724,61.658"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#005891"
        points="36.356,28.805 59.309,52.456 58.694,59.834 43.331,59.561
			25.522,41.678 		"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        points="14.507,11.139 13.531,11.139 13.531,9.563 11.854,9.563
			11.854,11.139 10.874,11.139 10.874,7.155 11.854,7.155 11.854,8.707 13.531,8.707 13.531,7.155 14.507,7.155 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M18.212,11.139h-0.951v-0.285c-0.226,0.237-0.541,0.375-0.916,0.375
			c-0.696,0-1.16-0.494-1.16-1.243V8.154h0.945v1.629c0,0.351,0.209,0.589,0.513,0.589c0.374,0,0.618-0.256,0.618-0.832V8.154h0.951
			V11.139z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M23.853,9.272v1.867h-0.945V9.474c0-0.339-0.22-0.553-0.5-0.553
			c-0.35,0-0.582,0.256-0.582,0.755v1.463h-0.951V9.474c0-0.339-0.22-0.553-0.506-0.553c-0.346,0-0.571,0.256-0.571,0.755v1.463
			h-0.951V8.154h0.951v0.273c0.202-0.225,0.493-0.362,0.88-0.362c0.416,0,0.773,0.178,0.976,0.505
			c0.237-0.309,0.594-0.505,1.058-0.505C23.348,8.065,23.853,8.546,23.853,9.272"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M27.627,11.138h-0.951V10.9c-0.232,0.209-0.541,0.328-0.927,0.328
			c-0.791,0-1.458-0.637-1.458-1.582c0-0.939,0.667-1.581,1.458-1.581c0.386,0,0.695,0.118,0.927,0.327V8.154h0.951V11.138z
			 M26.676,9.646c0-0.458-0.345-0.773-0.731-0.773c-0.417,0-0.72,0.315-0.72,0.773c0,0.464,0.303,0.773,0.72,0.773
			C26.331,10.419,26.676,10.11,26.676,9.646"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M31.292,9.307v1.832h-0.946V9.51c0-0.351-0.208-0.589-0.512-0.589
			c-0.374,0-0.618,0.255-0.618,0.832v1.386h-0.951V8.154h0.951v0.285c0.226-0.238,0.541-0.374,0.916-0.374
			C30.828,8.065,31.292,8.559,31.292,9.307"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M35.396,8.136l-0.042,0.951h-0.172c-0.666,0-1.028,0.345-1.028,1.118v0.934
			h-0.951V8.154h0.951v0.571c0.213-0.357,0.54-0.613,1.028-0.613C35.265,8.112,35.323,8.118,35.396,8.136"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M36.771,7.274c0,0.303-0.232,0.53-0.554,0.53s-0.548-0.227-0.548-0.53
			c0-0.292,0.227-0.541,0.548-0.541S36.771,6.982,36.771,7.274 M35.741,8.154h0.951v2.985h-0.951V8.154z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M40.413,10.842c0,0.922-0.703,1.427-1.672,1.427
			c-0.684,0-1.207-0.238-1.499-0.719l0.791-0.428c0.226,0.267,0.394,0.368,0.702,0.368c0.434,0,0.743-0.256,0.743-0.725v-0.083
			c-0.214,0.184-0.511,0.285-0.897,0.285c-0.779,0-1.438-0.618-1.438-1.463c0-0.826,0.659-1.439,1.438-1.439
			c0.387,0,0.684,0.101,0.897,0.286V8.154h0.935V10.842z M39.479,9.503c0-0.398-0.315-0.678-0.695-0.678
			c-0.417,0-0.714,0.28-0.714,0.678s0.297,0.683,0.714,0.683C39.163,10.187,39.479,9.901,39.479,9.503"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M44.079,9.307v1.832h-0.945V9.51c0-0.351-0.209-0.589-0.518-0.589
			c-0.367,0-0.611,0.255-0.611,0.832v1.386h-0.953V6.857h0.953v1.582c0.23-0.238,0.541-0.374,0.92-0.374
			C43.615,8.065,44.079,8.559,44.079,9.307"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M46.89,11.008c-0.231,0.125-0.476,0.22-0.813,0.22
			c-0.744,0-1.19-0.404-1.19-1.189v-1.13h-0.541V8.154h0.541V7.273h0.953v0.881h0.879v0.755h-0.879v1.035
			c0,0.309,0.147,0.433,0.397,0.433c0.131,0,0.31-0.047,0.435-0.119L46.89,11.008z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M47.97,10.169c0.041,0.255,0.273,0.351,0.529,0.351
			c0.25,0,0.387-0.107,0.387-0.238c0-0.101-0.078-0.178-0.299-0.22l-0.605-0.119c-0.553-0.101-0.868-0.398-0.868-0.868
			c0-0.612,0.529-1.011,1.284-1.011c0.744,0,1.236,0.339,1.355,0.857l-0.867,0.171c-0.03-0.184-0.209-0.35-0.5-0.35
			c-0.256,0-0.34,0.125-0.34,0.232c0,0.083,0.037,0.166,0.228,0.208l0.701,0.143c0.563,0.119,0.825,0.457,0.825,0.898
			c0,0.66-0.569,1.005-1.354,1.005c-0.702,0-1.302-0.256-1.403-0.869L47.97,10.169z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#60C2E6"
        d="M56.04,4.407c0.689,0,1.27,0.582,1.27,1.271v50.333
			c0,0.688-0.58,1.271-1.27,1.271H5.707c-0.689,0-1.271-0.582-1.271-1.271V5.678c0-0.689,0.582-1.271,1.271-1.271H56.04
			 M56.04,0.001H5.707c-3.123,0-5.677,2.555-5.677,5.677v50.333c0,3.122,2.554,5.677,5.677,5.677H56.04
			c3.122,0,5.677-2.555,5.677-5.677V5.678C61.717,2.556,59.162,0.001,56.04,0.001"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M25.142,29.482c0-1.135,2.572-2.055,5.744-2.055
			c3.171,0,5.743,0.92,5.743,2.055v11.449c0,1.135-2.571,2.055-5.743,2.055c-3.172,0-5.744-0.92-5.744-2.055V29.482z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#F37321"
        d="M35.432,22.4c0,2.475-2.007,4.48-4.48,4.48c-2.474,0-4.48-2.006-4.48-4.48
			c0-2.474,4.48-8.634,4.48-8.634S35.432,19.927,35.432,22.4"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="none"
        stroke="#F37321"
        strokeWidth="0.518"
        strokeMiterlimit="10"
        d="M35.432,22.4
			c0,2.475-2.007,4.48-4.48,4.48c-2.474,0-4.48-2.006-4.48-4.48c0-2.474,4.48-8.634,4.48-8.634S35.432,19.927,35.432,22.4"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFDD00"
        d="M33.493,23.291c0,1.436-1.164,2.599-2.599,2.599
			c-1.436,0-2.599-1.163-2.599-2.599c0-1.435,2.599-4.903,2.599-4.903S33.493,21.856,33.493,23.291"
      />

      <line
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="0.921"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="25.728"
        y1="30.197"
        x2="24.777"
        y2="35.429"
      />

      <line
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="none"
        stroke="#005891"
        strokeWidth="1.061"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="30.919"
        y1="28.723"
        x2="30.919"
        y2="23.193"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#ACD1E3"
        d="M36.629,29.482c0,0.82-1.345,1.233-3.288,1.563
			c-1.408,0.239-2.456,1.427-2.456,2.854v9.086c3.172,0,5.744-0.92,5.744-2.055V29.482"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#5087C7"
        d="M30.884,55.361c-2.638,0-4.773-2.136-4.773-4.771
			c0-2.638,2.135-4.773,4.773-4.773s4.774,2.136,4.774,4.773C35.658,53.226,33.522,55.361,30.884,55.361"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M30.884,45.816c2.638,0,4.774,2.136,4.774,4.773
			c0,2.637-2.136,4.771-4.774,4.771s-4.773-2.135-4.773-4.771C26.111,47.952,28.246,45.816,30.884,45.816 M30.884,45.404
			c-2.859,0-5.185,2.326-5.185,5.186c0,2.857,2.326,5.184,5.185,5.184c2.86,0,5.186-2.326,5.186-5.184
			C36.07,47.73,33.744,45.404,30.884,45.404"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M33.378,48.644c-0.239,0-0.465,0.108-0.62,0.251l-0.013-0.012
			c0.072-0.119,0.168-0.346,0.168-0.716c0-0.884-0.883-1.552-2.028-1.552c-1.145,0-2.041,0.668-2.041,1.552
			c0,0.37,0.119,0.597,0.179,0.716l-0.013,0.012c-0.154-0.143-0.381-0.251-0.619-0.251c-0.729,0-1.457,0.704-1.457,2.017
			c0,1.289,0.717,2.018,1.493,2.018c0.715,0,1.217-0.395,1.67-0.848c-0.072-0.107-0.18-0.215-0.298-0.215
			c-0.119,0-0.252,0.084-0.477,0.263c-0.299,0.228-0.562,0.358-0.908,0.358c-0.609,0-0.87-0.682-0.87-1.576
			c0-0.955,0.251-1.586,0.895-1.586c0.286,0,0.476,0.082,0.668,0.263c0.286,0.262,0.381,0.333,0.512,0.333
			c0.096,0,0.156-0.061,0.18-0.096c-0.215-0.215-0.525-0.666-0.525-1.252c0-0.668,0.56-1.133,1.611-1.133
			c1.049,0,1.61,0.465,1.61,1.133c0,0.586-0.311,1.037-0.524,1.252c0.022,0.035,0.083,0.096,0.179,0.096
			c0.131,0,0.227-0.071,0.512-0.333c0.18-0.181,0.383-0.263,0.668-0.263c0.646,0,0.896,0.631,0.896,1.586
			c0,0.895-0.263,1.576-0.871,1.576c-0.346,0-0.621-0.131-0.906-0.358c-0.228-0.179-0.358-0.263-0.478-0.263
			c-0.12,0-0.227,0.107-0.299,0.215c0.44,0.453,0.954,0.848,1.671,0.848c0.775,0,1.49-0.729,1.49-2.018
			C34.833,49.348,34.105,48.644,33.378,48.644"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M31.684,48.621h-0.537c-0.035,0-0.059-0.024-0.071-0.049l-0.168-0.513
			c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.513c-0.012,0.036-0.035,0.049-0.059,0.049h-0.537c-0.025,0-0.036,0.011-0.012,0.022
			l0.442,0.323c0.023,0.023,0.023,0.06,0.011,0.083l-0.167,0.501c-0.011,0.023-0.011,0.049,0.024,0.023l0.442-0.309
			c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.036,0.012,0.023-0.011L31.23,49.05c-0.012-0.035,0-0.071,0.024-0.083
			l0.44-0.323C31.72,48.632,31.708,48.621,31.684,48.621"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M31.278,52.152c-0.226-0.573-0.406-0.967-0.406-1.443
			c0-0.204,0.06-0.382,0.096-0.477c0.048-0.097,0.06-0.156,0.012-0.156c-0.072,0-0.227,0.166-0.322,0.311
			c-0.073,0.118-0.156,0.346-0.156,0.524c0,0.335,0.108,0.692,0.179,0.907c0.132,0.369,0.251,0.799,0.251,1.205
			c0,0.645-0.287,1.084-0.799,1.479c0.047,0.037,0.167,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.324
			C31.541,52.82,31.41,52.51,31.278,52.152"
      />
      <rect
        x="-0.938"
        y="76.799"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        width="5.986"
        height="5.987"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M0.936,78.836v1.273c0,0.167-0.011,0.43-0.221,0.572
			c-0.082,0.053-0.195,0.084-0.308,0.084c-0.136,0-0.297-0.045-0.433-0.176l0.224-0.269c0.036,0.054,0.096,0.103,0.184,0.103
			c0.079,0,0.124-0.034,0.144-0.065c0.045-0.065,0.042-0.19,0.042-0.305v-1.218H0.936z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        points="2.316,80.723 2.039,80.723 1.382,78.836 1.772,78.836 2.194,80.12
			2.655,78.819 2.859,78.819 3.295,80.12 3.747,78.836 4.138,78.836 3.439,80.723 3.162,80.723 2.749,79.526 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-12.442-25.953c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.014,0.054,0.021,0.108,0.021,0.163
			c0,0.076-0.012,0.143-0.034,0.199c-0.024,0.057-0.055,0.103-0.096,0.139s-0.09,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.442z M-12.533-25.284c0.06,0,0.107-0.018,0.144-0.054
			s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.033-0.039-0.085-0.058-0.16-0.058h-0.125v0.45H-12.533z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        points="-11.438,-25.954 -11.228,-24.909 -11.223,-24.909 -11.01,-25.954
			-10.568,-25.954 -10.568,-24.475 -10.842,-24.475 -10.842,-25.656 -10.846,-25.656 -11.109,-24.475 -11.342,-24.475
			-11.604,-25.656 -11.608,-25.656 -11.608,-24.475 -11.883,-24.475 -11.883,-25.954 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-10.114-24.893c0,0.034,0.003,0.066,0.009,0.094
			c0.005,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
			c0.046,0,0.087-0.014,0.124-0.044c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.047-0.045-0.066s-0.048-0.037-0.081-0.053c-0.034-0.016-0.076-0.032-0.126-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.147,0.04-0.256,0.122-0.327c0.081-0.072,0.193-0.108,0.336-0.108
			c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.073,0.074,0.095,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
			c-0.033,0-0.061,0.004-0.083,0.014c-0.022,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.14,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.044,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
			c-0.168,0-0.289-0.036-0.361-0.109c-0.073-0.073-0.109-0.179-0.109-0.315v-0.061h0.298V-24.893z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-8.848-24.474c0-0.065,0.007-0.125,0.02-0.182
			c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.102-0.15c0.04-0.05,0.088-0.099,0.141-0.149l0.121-0.111
			c0.033-0.029,0.06-0.056,0.08-0.081c0.02-0.025,0.035-0.05,0.046-0.075c0.011-0.025,0.019-0.051,0.022-0.081
			c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
			c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.019,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.057
			c0-0.136,0.037-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.15,0,0.26,0.034,0.332,0.103
			c0.072,0.07,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.008,0.041-0.021,0.081-0.039,0.117
			c-0.018,0.038-0.042,0.074-0.071,0.109c-0.028,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
			c-0.025,0.039-0.041,0.071-0.047,0.099h0.553v0.232H-8.848z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-7.51-24.687c0.019,0.032,0.055,0.047,0.104,0.047
			c0.041,0,0.072-0.009,0.096-0.028c0.023-0.019,0.042-0.045,0.054-0.078c0.014-0.033,0.022-0.073,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.066-0.106,0.089s-0.103,0.035-0.169,0.035
			c-0.065,0-0.12-0.012-0.165-0.035c-0.046-0.023-0.084-0.054-0.112-0.095c-0.028-0.041-0.049-0.089-0.062-0.145
			c-0.013-0.056-0.019-0.117-0.019-0.183c0-0.17,0.037-0.3,0.112-0.39c0.076-0.09,0.196-0.135,0.362-0.135
			c0.102,0,0.184,0.019,0.244,0.058c0.061,0.037,0.108,0.088,0.14,0.151c0.033,0.063,0.054,0.135,0.064,0.219
			c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.003,0.206-0.009,0.303c-0.006,0.098-0.025,0.184-0.056,0.258
			c-0.031,0.075-0.08,0.133-0.147,0.177c-0.067,0.043-0.161,0.065-0.281,0.065c-0.053,0-0.103-0.005-0.153-0.015
			c-0.048-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.107-0.037-0.178h0.287
			C-7.54-24.761-7.53-24.718-7.51-24.687 M-7.526-25.243c0.025,0.042,0.067,0.064,0.124,0.064c0.06,0,0.102-0.022,0.127-0.064
			c0.025-0.043,0.038-0.115,0.038-0.214c0-0.041-0.002-0.08-0.007-0.116c-0.004-0.036-0.012-0.066-0.024-0.092
			c-0.011-0.025-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022s-0.064,0.008-0.086,0.022
			c-0.021,0.015-0.037,0.035-0.048,0.061c-0.011,0.026-0.018,0.057-0.022,0.092c-0.003,0.036-0.005,0.074-0.005,0.115
			C-7.563-25.358-7.551-25.286-7.526-25.243"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-5.946-25.685c-0.061,0.086-0.116,0.178-0.163,0.276
			c-0.047,0.098-0.089,0.199-0.126,0.302c-0.035,0.104-0.065,0.21-0.089,0.317c-0.023,0.107-0.041,0.212-0.053,0.315h-0.314
			c0.012-0.088,0.028-0.181,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
			c0.053-0.103,0.115-0.203,0.186-0.3H-6.85v-0.257h0.904V-25.685z"
      />
      <rect
        x="-3.523"
        y="-26.453"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#00AEEF"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-2.61-25.953c0.076,0,0.14,0.012,0.192,0.038
			c0.052,0.026,0.094,0.059,0.125,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.014,0.054,0.021,0.108,0.021,0.163
			c0,0.076-0.012,0.143-0.034,0.199c-0.024,0.057-0.055,0.103-0.096,0.139s-0.09,0.063-0.148,0.081
			c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.61z M-2.701-25.284c0.06,0,0.107-0.018,0.144-0.054
			s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.033-0.039-0.085-0.058-0.16-0.058h-0.125v0.45H-2.701z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        points="-1.606,-25.954 -1.395,-24.909 -1.39,-24.909 -1.177,-25.954
			-0.735,-25.954 -0.735,-24.474 -1.009,-24.474 -1.009,-25.656 -1.013,-25.656 -1.276,-24.474 -1.509,-24.474 -1.771,-25.656
			-1.775,-25.656 -1.775,-24.474 -2.05,-24.474 -2.05,-25.954 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-0.282-24.893c0,0.035,0.003,0.066,0.009,0.094
			c0.005,0.029,0.015,0.053,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
			c0.046,0,0.087-0.015,0.124-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.047-0.045-0.066s-0.048-0.037-0.081-0.053c-0.034-0.015-0.076-0.032-0.126-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			s-0.022-0.1-0.022-0.161c0-0.146,0.04-0.255,0.122-0.327c0.081-0.072,0.193-0.108,0.336-0.108c0.066,0,0.127,0.007,0.183,0.022
			c0.056,0.014,0.104,0.038,0.145,0.07c0.041,0.033,0.073,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0.071
			c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
			c-0.022,0.01-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.026,0.055c-0.006,0.021-0.008,0.042-0.008,0.065
			c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.14,0.078
			c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.065,0.044,0.102c0.008,0.038,0.012,0.079,0.012,0.125
			c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.036-0.361-0.109
			c-0.073-0.073-0.109-0.179-0.109-0.315v-0.061h0.298V-24.893z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-2.61-24.01c0.076,0,0.14,0.012,0.192,0.038
			c0.052,0.026,0.094,0.059,0.125,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.014,0.054,0.021,0.108,0.021,0.163
			c0,0.076-0.012,0.143-0.034,0.199c-0.024,0.057-0.055,0.103-0.096,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
			c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.61z M-2.701-23.341c0.06,0,0.107-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.033-0.039-0.085-0.058-0.16-0.058h-0.125v0.45H-2.701z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-1.791-23.646v0.149h0.005c0.028-0.054,0.064-0.097,0.105-0.129
			c0.042-0.033,0.092-0.049,0.151-0.049c0.009,0,0.017,0,0.027,0.001c0.008,0.001,0.017,0.002,0.025,0.003v0.274
			c-0.015-0.002-0.03-0.003-0.044-0.003c-0.015-0.001-0.03-0.001-0.045-0.001c-0.025,0-0.05,0.003-0.074,0.01
			c-0.026,0.007-0.048,0.018-0.069,0.034c-0.02,0.016-0.037,0.036-0.049,0.062c-0.013,0.026-0.02,0.057-0.02,0.095v0.669h-0.285
			v-1.115H-1.791z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-1.389-23.341c0.016-0.073,0.041-0.135,0.075-0.184s0.08-0.086,0.136-0.112
			c0.056-0.025,0.125-0.038,0.206-0.038c0.086-0.001,0.157,0.01,0.213,0.034c0.057,0.024,0.102,0.061,0.135,0.111
			c0.033,0.05,0.056,0.111,0.071,0.184c0.013,0.074,0.021,0.16,0.021,0.257c0,0.096-0.008,0.18-0.023,0.253
			c-0.016,0.073-0.041,0.134-0.075,0.184c-0.035,0.049-0.08,0.085-0.136,0.109c-0.056,0.023-0.124,0.035-0.206,0.035
			c-0.086,0.001-0.157-0.01-0.214-0.032c-0.056-0.023-0.101-0.059-0.134-0.108c-0.033-0.049-0.057-0.11-0.071-0.185
			c-0.013-0.074-0.021-0.159-0.021-0.256C-1.412-23.184-1.404-23.268-1.389-23.341 M-1.121-22.931
			c0.003,0.048,0.009,0.09,0.019,0.124c0.011,0.035,0.027,0.062,0.047,0.081c0.021,0.019,0.049,0.029,0.083,0.029
			c0.058,0,0.098-0.03,0.12-0.091s0.034-0.161,0.034-0.301c0-0.139-0.012-0.239-0.034-0.3c-0.022-0.062-0.062-0.091-0.12-0.091
			c-0.034,0-0.062,0.009-0.083,0.029c-0.02,0.019-0.036,0.046-0.047,0.081c-0.01,0.034-0.016,0.076-0.019,0.124
			c-0.003,0.048-0.004,0.1-0.004,0.157S-1.124-22.979-1.121-22.931"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M0.152-23.325c-0.003-0.026-0.009-0.05-0.018-0.071
			c-0.009-0.022-0.022-0.039-0.038-0.052c-0.018-0.013-0.039-0.02-0.067-0.02c-0.035,0-0.063,0.008-0.085,0.024
			s-0.039,0.039-0.05,0.07c-0.011,0.031-0.018,0.071-0.022,0.118c-0.004,0.048-0.007,0.105-0.007,0.169
			c0,0.07,0.003,0.129,0.007,0.178c0.004,0.049,0.011,0.089,0.022,0.121c0.011,0.032,0.027,0.055,0.047,0.069
			c0.02,0.015,0.046,0.022,0.077,0.022c0.049,0,0.084-0.021,0.106-0.062c0.022-0.042,0.034-0.108,0.034-0.199h0.273
			c0,0.146-0.033,0.258-0.098,0.335c-0.066,0.076-0.175,0.115-0.327,0.115c-0.072,0-0.134-0.009-0.188-0.026
			c-0.053-0.017-0.097-0.049-0.132-0.094c-0.035-0.044-0.062-0.104-0.08-0.18c-0.018-0.075-0.027-0.169-0.027-0.281
			c0-0.114,0.011-0.209,0.034-0.285c0.021-0.075,0.052-0.135,0.093-0.18c0.04-0.045,0.087-0.076,0.142-0.094
			c0.056-0.018,0.117-0.027,0.183-0.027c0.132,0,0.232,0.039,0.3,0.116c0.067,0.078,0.1,0.182,0.1,0.315H0.158
			C0.158-23.272,0.156-23.299,0.152-23.325"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M0.825-22.956c0,0.033,0.002,0.065,0.007,0.096
			c0.006,0.032,0.013,0.059,0.024,0.083c0.011,0.025,0.026,0.044,0.044,0.058c0.017,0.015,0.04,0.022,0.066,0.022
			c0.048,0,0.084-0.017,0.106-0.052c0.022-0.034,0.037-0.087,0.046-0.157h0.256c-0.005,0.13-0.04,0.228-0.103,0.296
			c-0.064,0.068-0.164,0.102-0.301,0.102c-0.103,0-0.184-0.017-0.242-0.052c-0.059-0.034-0.101-0.08-0.128-0.137
			c-0.029-0.056-0.045-0.12-0.052-0.191c-0.006-0.07-0.009-0.141-0.009-0.211c0-0.075,0.005-0.146,0.016-0.216
			c0.01-0.068,0.03-0.13,0.062-0.184c0.031-0.054,0.075-0.097,0.133-0.128c0.058-0.032,0.136-0.048,0.233-0.048
			c0.082,0,0.151,0.013,0.203,0.04c0.054,0.027,0.096,0.066,0.126,0.114c0.03,0.05,0.051,0.109,0.062,0.178
			c0.011,0.07,0.017,0.148,0.017,0.234v0.064H0.825V-22.956z M1.109-23.328c-0.005-0.032-0.011-0.06-0.022-0.083
			c-0.01-0.023-0.025-0.04-0.043-0.052c-0.019-0.012-0.043-0.017-0.072-0.017c-0.029,0-0.053,0.006-0.071,0.019
			c-0.019,0.014-0.034,0.031-0.045,0.052c-0.011,0.021-0.018,0.046-0.024,0.072c-0.005,0.026-0.007,0.052-0.007,0.078v0.044h0.293
			C1.116-23.258,1.113-23.295,1.109-23.328"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M1.749-22.858c0,0.048,0.013,0.087,0.038,0.117
			c0.026,0.03,0.063,0.045,0.112,0.045c0.044,0,0.079-0.011,0.105-0.034c0.026-0.022,0.039-0.055,0.039-0.099
			c0-0.036-0.01-0.063-0.03-0.082c-0.021-0.018-0.045-0.034-0.073-0.044l-0.201-0.073c-0.079-0.027-0.138-0.067-0.178-0.117
			s-0.06-0.115-0.06-0.194c0-0.045,0.007-0.089,0.022-0.129c0.016-0.041,0.04-0.077,0.073-0.107
			c0.033-0.031,0.076-0.055,0.128-0.073c0.051-0.018,0.114-0.027,0.187-0.027c0.129,0,0.226,0.028,0.288,0.083
			c0.062,0.055,0.094,0.133,0.094,0.235v0.045H2.036c0-0.058-0.01-0.1-0.029-0.127c-0.018-0.027-0.051-0.041-0.098-0.041
			c-0.036,0-0.067,0.01-0.094,0.03c-0.028,0.02-0.041,0.051-0.041,0.09c0,0.028,0.009,0.053,0.026,0.075
			c0.017,0.021,0.05,0.04,0.099,0.056l0.172,0.058c0.089,0.031,0.153,0.07,0.19,0.119c0.038,0.049,0.056,0.116,0.056,0.2
			c0,0.059-0.01,0.111-0.031,0.155c-0.021,0.043-0.049,0.079-0.086,0.108c-0.036,0.029-0.08,0.05-0.13,0.063
			c-0.051,0.012-0.106,0.018-0.167,0.018c-0.08,0-0.147-0.008-0.201-0.023C1.65-22.546,1.608-22.568,1.576-22.6
			c-0.031-0.03-0.053-0.067-0.065-0.111c-0.012-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.858z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M2.669-22.858c0,0.048,0.012,0.087,0.038,0.117
			c0.026,0.03,0.063,0.045,0.111,0.045c0.045,0,0.079-0.011,0.106-0.034c0.026-0.022,0.039-0.055,0.039-0.099
			c0-0.036-0.01-0.063-0.03-0.082c-0.022-0.018-0.046-0.034-0.073-0.044l-0.202-0.073c-0.078-0.027-0.137-0.067-0.178-0.117
			c-0.04-0.05-0.059-0.115-0.059-0.194c0-0.045,0.007-0.089,0.022-0.129c0.015-0.041,0.039-0.077,0.073-0.107
			c0.033-0.031,0.076-0.055,0.127-0.073c0.052-0.018,0.115-0.027,0.188-0.027c0.129,0,0.226,0.028,0.288,0.083
			c0.062,0.055,0.093,0.133,0.093,0.235v0.045H2.956c0-0.058-0.009-0.1-0.028-0.127C2.909-23.466,2.876-23.48,2.83-23.48
			c-0.036,0-0.067,0.01-0.095,0.03c-0.027,0.02-0.04,0.051-0.04,0.09c0,0.028,0.009,0.053,0.026,0.075
			c0.017,0.021,0.05,0.04,0.098,0.056l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.119c0.037,0.049,0.056,0.116,0.056,0.2
			c0,0.059-0.01,0.111-0.031,0.155c-0.021,0.043-0.05,0.079-0.086,0.108c-0.036,0.029-0.08,0.05-0.131,0.063
			c-0.05,0.012-0.106,0.018-0.167,0.018c-0.079,0-0.146-0.008-0.2-0.023C2.57-22.546,2.527-22.568,2.497-22.6
			c-0.031-0.03-0.053-0.067-0.065-0.111c-0.012-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.858z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-2.538-21.804c-0.026-0.049-0.073-0.074-0.14-0.074
			c-0.037,0-0.068,0.01-0.092,0.03c-0.024,0.021-0.043,0.053-0.058,0.098c-0.013,0.045-0.023,0.104-0.028,0.176
			c-0.006,0.073-0.009,0.161-0.009,0.266c0,0.112,0.004,0.203,0.012,0.272c0.007,0.069,0.02,0.123,0.035,0.161
			c0.016,0.038,0.036,0.063,0.06,0.076c0.025,0.013,0.052,0.02,0.084,0.02c0.027,0,0.051-0.004,0.074-0.014
			c0.023-0.008,0.042-0.027,0.059-0.053c0.016-0.027,0.029-0.065,0.039-0.114c0.01-0.049,0.015-0.114,0.015-0.194h0.298
			c0,0.08-0.006,0.156-0.018,0.228c-0.013,0.072-0.036,0.134-0.07,0.188c-0.034,0.054-0.082,0.097-0.142,0.127
			c-0.063,0.03-0.142,0.045-0.24,0.045c-0.112,0-0.201-0.018-0.268-0.053c-0.066-0.036-0.117-0.088-0.152-0.156
			c-0.035-0.068-0.058-0.148-0.068-0.242c-0.011-0.094-0.016-0.198-0.016-0.311c0-0.112,0.005-0.215,0.016-0.31
			c0.01-0.094,0.033-0.176,0.068-0.244c0.035-0.067,0.086-0.12,0.152-0.158c0.067-0.038,0.156-0.057,0.268-0.057
			c0.106,0,0.19,0.017,0.252,0.052c0.061,0.034,0.107,0.078,0.137,0.131c0.03,0.054,0.05,0.111,0.057,0.172
			c0.008,0.062,0.012,0.119,0.012,0.173H-2.5C-2.5-21.676-2.513-21.755-2.538-21.804"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-1.952-20.437c0.052,0,0.09-0.014,0.113-0.041
			c0.023-0.028,0.036-0.065,0.04-0.11l-0.313-1.115h0.299l0.166,0.771h0.004l0.145-0.771h0.286l-0.27,1.04
			c-0.024,0.093-0.049,0.167-0.071,0.225c-0.023,0.057-0.05,0.102-0.081,0.133c-0.031,0.031-0.069,0.051-0.112,0.061
			c-0.044,0.01-0.098,0.014-0.165,0.014h-0.116v-0.213C-2.002-20.438-1.978-20.437-1.952-20.437"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M-1.088-21.388c0-0.065,0.011-0.119,0.031-0.162
			c0.021-0.044,0.049-0.079,0.084-0.106s0.077-0.047,0.124-0.058c0.048-0.012,0.099-0.018,0.153-0.018
			c0.085,0,0.155,0.008,0.207,0.025c0.052,0.017,0.093,0.04,0.122,0.071c0.03,0.03,0.049,0.066,0.059,0.108
			c0.011,0.042,0.016,0.088,0.016,0.138v0.587c0,0.052,0.002,0.093,0.007,0.122s0.014,0.06,0.028,0.093h-0.274
			c-0.009-0.018-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059H-0.57c-0.033,0.058-0.071,0.096-0.114,0.113
			c-0.044,0.017-0.101,0.026-0.17,0.026c-0.049,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.071
			c-0.021-0.031-0.036-0.065-0.046-0.103c-0.01-0.038-0.014-0.076-0.014-0.113c0-0.052,0.005-0.098,0.016-0.136
			c0.012-0.038,0.029-0.07,0.051-0.097c0.023-0.027,0.051-0.05,0.087-0.067c0.035-0.019,0.078-0.034,0.127-0.048l0.162-0.044
			c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.076-0.03-0.101
			c-0.02-0.024-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.1,0.039c-0.022,0.027-0.034,0.062-0.034,0.106v0.031h-0.256V-21.388
			z M-0.628-21.109c-0.022,0.009-0.042,0.016-0.06,0.02c-0.059,0.012-0.1,0.033-0.124,0.062c-0.025,0.029-0.038,0.068-0.038,0.118
			c0,0.043,0.008,0.079,0.025,0.11c0.017,0.03,0.044,0.045,0.083,0.045c0.019,0,0.039-0.003,0.06-0.009s0.04-0.016,0.057-0.03
			c0.017-0.014,0.031-0.032,0.043-0.054c0.01-0.022,0.016-0.048,0.016-0.079v-0.219C-0.586-21.13-0.606-21.118-0.628-21.109"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M0.185-21.703v0.131h0.004c0.03-0.056,0.07-0.096,0.118-0.122
			c0.048-0.025,0.104-0.038,0.166-0.038c0.091,0,0.161,0.025,0.21,0.075c0.05,0.049,0.074,0.13,0.074,0.242v0.827H0.47v-0.769
			c0-0.058-0.01-0.098-0.029-0.121c-0.02-0.023-0.051-0.034-0.094-0.034c-0.1,0-0.151,0.061-0.151,0.184v0.74h-0.286v-1.115H0.185z"
      />
      <rect
        x="6.345"
        y="-26.453"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#005891"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M7.259-25.953c0.076,0,0.14,0.012,0.191,0.038
			c0.053,0.026,0.095,0.059,0.126,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.055,0.104-0.096,0.139c-0.04,0.036-0.09,0.063-0.147,0.081
			c-0.058,0.018-0.123,0.027-0.194,0.027H7.035v0.59H6.737v-1.479H7.259z M7.168-25.284c0.059,0,0.107-0.018,0.144-0.054
			c0.036-0.036,0.054-0.092,0.054-0.168c0-0.074-0.015-0.131-0.047-0.17c-0.032-0.039-0.085-0.058-0.16-0.058H7.035v0.45H7.168z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        points="8.262,-25.954 8.473,-24.909 8.478,-24.909 8.691,-25.954
			9.132,-25.954 9.132,-24.474 8.859,-24.474 8.859,-25.656 8.855,-25.656 8.592,-24.474 8.359,-24.474 8.096,-25.656
			8.092,-25.656 8.092,-24.474 7.819,-24.474 7.819,-25.954 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M9.587-24.893c0,0.035,0.003,0.066,0.008,0.094
			c0.005,0.029,0.016,0.053,0.031,0.072c0.015,0.019,0.036,0.035,0.061,0.045c0.025,0.012,0.058,0.017,0.096,0.017
			c0.046,0,0.087-0.015,0.124-0.045c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.047-0.044-0.066c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.015-0.075-0.032-0.126-0.048
			c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.088-0.057-0.12-0.092C9.37-25.3,9.347-25.341,9.332-25.387
			c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.193-0.108,0.336-0.108
			c0.067,0,0.128,0.007,0.183,0.021c0.057,0.014,0.104,0.038,0.145,0.07c0.042,0.033,0.073,0.074,0.096,0.124
			c0.022,0.051,0.034,0.111,0.034,0.182v0.041H9.939c0-0.07-0.013-0.125-0.037-0.162c-0.025-0.038-0.067-0.057-0.125-0.057
			c-0.033,0-0.06,0.005-0.083,0.014c-0.022,0.01-0.039,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
			c-0.004,0.021-0.007,0.042-0.007,0.065c0,0.045,0.011,0.083,0.03,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.138,0.078c0.037,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.037,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
			c-0.169,0-0.29-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.893z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M10.853-24.474c0-0.065,0.007-0.126,0.02-0.182
			c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.149l0.121-0.111
			c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.052,0.022-0.081
			c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
			c-0.022,0.015-0.038,0.036-0.05,0.062c-0.011,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.004,0.071-0.004,0.109h-0.287v-0.055
			c0-0.137,0.036-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
			c0.071,0.069,0.108,0.168,0.108,0.297c0,0.047-0.004,0.09-0.013,0.131s-0.021,0.08-0.039,0.117
			c-0.018,0.038-0.041,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.045,0.04-0.079,0.08-0.104,0.117
			c-0.024,0.039-0.041,0.071-0.048,0.099h0.554v0.232H10.853z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M12.19-24.687c0.02,0.032,0.055,0.047,0.105,0.047
			c0.04,0,0.072-0.009,0.096-0.028c0.023-0.019,0.041-0.044,0.054-0.078c0.013-0.033,0.022-0.072,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.025,0.037-0.06,0.067-0.107,0.09c-0.046,0.023-0.102,0.034-0.169,0.034
			c-0.064,0-0.119-0.011-0.165-0.034c-0.046-0.023-0.083-0.055-0.111-0.096c-0.028-0.041-0.05-0.089-0.062-0.145
			c-0.014-0.056-0.02-0.117-0.02-0.183c0-0.17,0.038-0.3,0.113-0.39c0.075-0.089,0.196-0.134,0.362-0.134
			c0.102,0,0.183,0.018,0.244,0.057c0.061,0.037,0.108,0.088,0.14,0.151c0.032,0.063,0.054,0.136,0.063,0.219
			c0.01,0.082,0.015,0.169,0.015,0.259c0,0.105-0.004,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.057,0.258
			c-0.03,0.075-0.08,0.134-0.146,0.177c-0.068,0.044-0.162,0.066-0.282,0.066c-0.053,0-0.103-0.006-0.152-0.016
			c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.068-0.066-0.091-0.114s-0.034-0.107-0.036-0.177h0.286
			C12.161-24.761,12.17-24.718,12.19-24.687 M12.176-25.243c0.024,0.043,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.126-0.064
			c0.025-0.043,0.038-0.114,0.038-0.214c0-0.041-0.003-0.08-0.007-0.116s-0.012-0.066-0.023-0.092
			c-0.012-0.025-0.028-0.045-0.05-0.06c-0.022-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.064,0.008-0.086,0.022
			c-0.021,0.015-0.038,0.035-0.049,0.061c-0.011,0.027-0.019,0.057-0.021,0.093c-0.004,0.035-0.005,0.073-0.005,0.114
			C12.139-25.357,12.15-25.286,12.176-25.243"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M13.32-25.941h0.309v0.93h0.142v0.233h-0.142v0.304h-0.273v-0.304h-0.522
			v-0.245L13.32-25.941z M13.353-25.568l-0.287,0.557h0.291v-0.557H13.353z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M14.14-25.68l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.084
			c0.044-0.017,0.098-0.026,0.162-0.026c0.07,0,0.128,0.013,0.175,0.04c0.046,0.027,0.083,0.063,0.109,0.107
			c0.027,0.044,0.047,0.094,0.058,0.15c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.222
			c-0.013,0.066-0.038,0.123-0.073,0.171c-0.036,0.048-0.084,0.083-0.144,0.108c-0.061,0.024-0.138,0.036-0.232,0.036
			c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.04-0.05-0.085-0.061-0.132
			c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287c0,0.024,0.001,0.051,0.004,0.078c0.002,0.028,0.009,0.054,0.019,0.078
			c0.011,0.024,0.025,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.077,0.024c0.062,0,0.106-0.024,0.133-0.072
			c0.028-0.047,0.041-0.119,0.041-0.214c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
			c-0.013-0.023-0.03-0.04-0.053-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
			c-0.023,0.025-0.037,0.057-0.042,0.099h-0.267l0.053-0.798h0.765v0.232H14.14z"
      />
      <rect
        x="16.214"
        y="-26.453"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#ACD1E3"
        width="8.812"
        height="8.813"
      />
      <rect
        x="25.966"
        y="-26.453"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFDD00"
        width="8.813"
        height="8.813"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M26.88-25.953c0.076,0,0.14,0.012,0.192,0.038
			c0.052,0.026,0.094,0.059,0.125,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
			s-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H26.88z M26.789-25.284c0.059,0,0.107-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H26.789z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        points="27.884,-25.954 28.095,-24.909 28.1,-24.909 28.313,-25.954
			28.753,-25.954 28.753,-24.474 28.48,-24.474 28.48,-25.656 28.477,-25.656 28.213,-24.474 27.98,-24.474 27.718,-25.656
			27.713,-25.656 27.713,-24.474 27.439,-24.474 27.439,-25.954 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M29.208-24.893c0,0.035,0.003,0.066,0.009,0.094
			c0.004,0.029,0.015,0.053,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
			c0.045,0,0.087-0.015,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.047-0.045-0.066s-0.048-0.037-0.082-0.053c-0.033-0.015-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			s-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108c0.066,0,0.127,0.007,0.183,0.022
			c0.056,0.014,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285
			c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.005-0.083,0.014
			c-0.023,0.01-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
			c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
			c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
			c0,0.156-0.046,0.27-0.136,0.341s-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.036-0.362-0.109
			c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.893z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M30.814-25.51h-0.292v-0.195h0.026c0.1,0,0.178-0.019,0.235-0.056
			c0.058-0.037,0.094-0.097,0.109-0.18h0.22v1.467h-0.298V-25.51z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M31.479-25.453c0.006-0.094,0.025-0.177,0.056-0.249
			c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.148-0.066,0.256-0.066c0.091,0,0.165,0.013,0.225,0.039
			c0.057,0.025,0.104,0.066,0.139,0.123c0.035,0.056,0.061,0.13,0.076,0.221c0.014,0.09,0.021,0.2,0.021,0.33
			c0,0.102-0.004,0.2-0.012,0.295c-0.006,0.093-0.024,0.177-0.055,0.249c-0.031,0.073-0.076,0.13-0.139,0.171
			c-0.061,0.042-0.146,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.059-0.024-0.107-0.063-0.142-0.12
			c-0.036-0.056-0.06-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C31.469-25.259,31.473-25.358,31.479-25.453
			 M31.774-24.867c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.029,0.057,0.05,0.069c0.021,0.013,0.046,0.019,0.078,0.019
			c0.033,0,0.061-0.008,0.081-0.024s0.036-0.043,0.049-0.081c0.011-0.038,0.021-0.089,0.024-0.152
			c0.004-0.064,0.008-0.143,0.008-0.236v-0.16c0-0.093-0.004-0.168-0.008-0.227s-0.014-0.105-0.025-0.139
			c-0.014-0.033-0.029-0.057-0.05-0.069c-0.021-0.013-0.048-0.019-0.079-0.019c-0.033,0-0.059,0.008-0.08,0.024
			c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.088-0.025,0.152c-0.005,0.063-0.007,0.142-0.007,0.236v0.16
			C31.768-25,31.77-24.925,31.774-24.867"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M32.808-24.687c0.019,0.032,0.054,0.047,0.104,0.047
			c0.041,0,0.072-0.009,0.096-0.028c0.023-0.019,0.043-0.044,0.055-0.078c0.014-0.033,0.021-0.072,0.025-0.118
			c0.006-0.045,0.008-0.095,0.008-0.149v-0.07h-0.006c-0.023,0.037-0.06,0.067-0.105,0.09c-0.047,0.023-0.104,0.034-0.169,0.034
			s-0.12-0.011-0.165-0.034c-0.047-0.023-0.084-0.055-0.112-0.096s-0.049-0.089-0.062-0.145c-0.014-0.056-0.02-0.117-0.02-0.183
			c0-0.17,0.037-0.3,0.112-0.39c0.075-0.089,0.196-0.134,0.362-0.134c0.102,0,0.184,0.018,0.244,0.057
			c0.061,0.037,0.107,0.088,0.14,0.151s0.054,0.136,0.063,0.219c0.01,0.082,0.014,0.169,0.014,0.259
			c0,0.105-0.002,0.206-0.008,0.303c-0.006,0.098-0.025,0.184-0.057,0.258c-0.031,0.075-0.08,0.134-0.146,0.177
			c-0.067,0.044-0.162,0.066-0.281,0.066c-0.053,0-0.104-0.006-0.153-0.016c-0.049-0.01-0.092-0.03-0.129-0.058
			c-0.037-0.028-0.067-0.066-0.09-0.114s-0.035-0.107-0.037-0.177h0.287C32.777-24.761,32.786-24.718,32.808-24.687 M32.791-25.243
			c0.025,0.043,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.127-0.064s0.037-0.114,0.037-0.214c0-0.041-0.002-0.08-0.006-0.116
			s-0.012-0.066-0.023-0.092c-0.012-0.025-0.029-0.045-0.051-0.06c-0.021-0.014-0.049-0.022-0.084-0.022s-0.064,0.008-0.086,0.022
			c-0.021,0.015-0.037,0.035-0.048,0.061c-0.011,0.027-0.019,0.057-0.022,0.093c-0.002,0.035-0.004,0.073-0.004,0.114
			C32.755-25.356,32.767-25.286,32.791-25.243"
      />
      <rect
        x="55.687"
        y="-26.453"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#5087C7"
        width="8.813"
        height="8.813"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M56.601-25.953c0.076,0,0.141,0.012,0.192,0.038
			c0.052,0.026,0.095,0.059,0.125,0.1c0.032,0.042,0.056,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.033,0.199c-0.023,0.057-0.056,0.103-0.096,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
			s-0.123,0.027-0.192,0.027h-0.16v0.59h-0.298v-1.479H56.601z M56.511-25.284c0.059,0,0.106-0.018,0.144-0.054
			c0.036-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.16-0.058h-0.124v0.45H56.511z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        points="57.604,-25.954 57.815,-24.909 57.82,-24.909 58.033,-25.954
			58.476,-25.954 58.476,-24.474 58.201,-24.474 58.201,-25.656 58.197,-25.656 57.935,-24.474 57.701,-24.474 57.438,-25.656
			57.435,-25.656 57.435,-24.474 57.161,-24.474 57.161,-25.954 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M58.929-24.893c0,0.035,0.003,0.066,0.008,0.094
			c0.006,0.029,0.016,0.053,0.031,0.072s0.036,0.035,0.061,0.045c0.026,0.012,0.059,0.017,0.098,0.017
			c0.045,0,0.086-0.015,0.123-0.045c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.008-0.025-0.024-0.047-0.045-0.066s-0.047-0.037-0.081-0.053c-0.033-0.015-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.124-0.046-0.173-0.073c-0.049-0.026-0.088-0.057-0.121-0.092c-0.032-0.035-0.057-0.076-0.071-0.122
			s-0.022-0.1-0.022-0.161c0-0.146,0.041-0.255,0.121-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.129,0.007,0.185,0.022
			c0.056,0.014,0.104,0.038,0.144,0.07c0.043,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.035,0.111,0.035,0.182v0.041H59.28
			c0-0.07-0.013-0.125-0.037-0.162c-0.024-0.038-0.066-0.057-0.123-0.057c-0.033,0-0.062,0.005-0.084,0.014
			c-0.022,0.01-0.039,0.023-0.053,0.038c-0.014,0.016-0.021,0.035-0.026,0.055s-0.007,0.042-0.007,0.064
			c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.123,0.086l0.23,0.099c0.057,0.025,0.104,0.051,0.139,0.078
			c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.031,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
			c0,0.156-0.045,0.27-0.135,0.341c-0.091,0.071-0.217,0.106-0.379,0.106c-0.169,0-0.289-0.036-0.361-0.109
			s-0.108-0.179-0.108-0.315v-0.061h0.298V-24.893z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M60.194-24.474c0-0.065,0.008-0.126,0.021-0.182
			c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.061-0.101,0.103-0.15c0.04-0.05,0.088-0.099,0.141-0.149l0.121-0.111
			c0.033-0.029,0.06-0.056,0.08-0.081c0.021-0.025,0.035-0.05,0.046-0.075c0.012-0.025,0.02-0.052,0.021-0.081
			c0.004-0.029,0.006-0.062,0.006-0.099c0-0.122-0.049-0.183-0.145-0.183c-0.037,0-0.068,0.008-0.089,0.023
			c-0.022,0.015-0.038,0.036-0.05,0.062c-0.011,0.027-0.02,0.057-0.023,0.092c-0.002,0.034-0.004,0.071-0.004,0.109h-0.287v-0.055
			c0-0.137,0.037-0.242,0.11-0.316c0.073-0.073,0.188-0.109,0.347-0.109c0.149,0,0.26,0.034,0.332,0.103
			c0.071,0.069,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.014,0.131c-0.008,0.041-0.021,0.08-0.038,0.117
			c-0.019,0.038-0.041,0.074-0.071,0.109c-0.027,0.035-0.064,0.072-0.105,0.109l-0.166,0.153c-0.043,0.04-0.078,0.08-0.104,0.117
			c-0.023,0.039-0.041,0.071-0.047,0.099h0.553v0.232H60.194z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M62.103-25.684c-0.062,0.086-0.117,0.178-0.164,0.276
			s-0.089,0.199-0.125,0.302c-0.035,0.104-0.065,0.21-0.09,0.316c-0.023,0.108-0.041,0.213-0.053,0.316h-0.314
			c0.012-0.088,0.028-0.181,0.051-0.278c0.021-0.098,0.052-0.197,0.088-0.298c0.037-0.101,0.082-0.202,0.134-0.304
			c0.053-0.103,0.114-0.203,0.187-0.301h-0.617v-0.257h0.904V-25.684z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M62.524-25.51h-0.292v-0.195h0.026c0.101,0,0.178-0.019,0.236-0.056
			c0.057-0.037,0.094-0.097,0.108-0.18h0.219v1.467h-0.298V-25.51z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M63.233-25.127c0.041-0.066,0.104-0.108,0.187-0.124v-0.004
			c-0.04-0.01-0.074-0.025-0.101-0.046s-0.049-0.044-0.065-0.07c-0.017-0.027-0.028-0.054-0.035-0.083
			c-0.007-0.029-0.01-0.057-0.01-0.083c0-0.144,0.038-0.247,0.114-0.31c0.076-0.063,0.182-0.094,0.316-0.094
			s0.239,0.031,0.315,0.094c0.077,0.063,0.116,0.166,0.116,0.31c0,0.026-0.004,0.054-0.013,0.083
			c-0.007,0.029-0.019,0.056-0.036,0.083c-0.016,0.026-0.037,0.049-0.064,0.07s-0.061,0.036-0.1,0.046v0.004
			c0.084,0.016,0.146,0.058,0.188,0.124c0.041,0.067,0.063,0.146,0.063,0.239c0,0.043-0.006,0.09-0.016,0.141
			c-0.011,0.051-0.033,0.098-0.066,0.143c-0.033,0.044-0.08,0.08-0.142,0.109c-0.062,0.029-0.144,0.044-0.245,0.044
			s-0.184-0.015-0.246-0.044c-0.062-0.029-0.109-0.065-0.142-0.109c-0.033-0.045-0.056-0.092-0.065-0.143
			c-0.011-0.051-0.016-0.098-0.016-0.141C63.171-24.981,63.192-25.06,63.233-25.127 M63.478-24.794
			c0.004,0.031,0.014,0.058,0.026,0.081s0.03,0.041,0.053,0.054c0.022,0.013,0.05,0.019,0.083,0.019s0.061-0.006,0.083-0.019
			c0.021-0.013,0.039-0.031,0.052-0.054c0.014-0.023,0.023-0.05,0.027-0.081c0.005-0.031,0.008-0.064,0.008-0.099
			c0-0.035-0.003-0.068-0.008-0.099c-0.004-0.031-0.014-0.057-0.027-0.08c-0.013-0.023-0.031-0.04-0.052-0.054
			c-0.022-0.013-0.05-0.019-0.083-0.019s-0.061,0.006-0.083,0.019c-0.022,0.014-0.04,0.031-0.053,0.054s-0.022,0.049-0.026,0.08
			c-0.006,0.031-0.008,0.064-0.008,0.099C63.47-24.858,63.472-24.825,63.478-24.794 M63.534-25.39
			c0.025,0.033,0.061,0.05,0.105,0.05c0.044,0,0.079-0.017,0.105-0.05c0.027-0.033,0.039-0.085,0.039-0.155
			c0-0.135-0.047-0.201-0.145-0.201c-0.097,0-0.145,0.066-0.145,0.201C63.495-25.475,63.508-25.423,63.534-25.39"
      />
      <rect
        x="45.819"
        y="-26.453"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        width="8.812"
        height="8.813"
      />

      <rect
        x="45.819"
        y="-26.453"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="none"
        stroke="#000000"
        strokeWidth="0.13"
        strokeMiterlimit="10"
        width="8.812"
        height="8.813"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        points="46.409,-25.954 46.573,-24.859 46.577,-24.859 46.751,-25.954 47.038,-25.954
			47.212,-24.859 47.216,-24.859 47.38,-25.954 47.665,-25.954 47.396,-24.474 47.063,-24.474 46.896,-25.548 46.893,-25.548
			46.727,-24.474 46.393,-24.474 46.124,-25.954 		"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        points="48.084,-25.954 48.084,-25.388 48.433,-25.388 48.433,-25.954 48.731,-25.954
			48.731,-24.474 48.433,-24.474 48.433,-25.131 48.084,-25.131 48.084,-24.474 47.786,-24.474 47.786,-25.954 		"
      />
      <rect
        x="48.953"
        y="-25.954"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        width="0.298"
        height="1.48"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        points="50.339,-25.709 50.017,-25.709 50.017,-24.474 49.717,-24.474 49.717,-25.709
			49.394,-25.709 49.394,-25.954 50.339,-25.954 		"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        points="51.294,-25.709 50.765,-25.709 50.765,-25.361 51.263,-25.361 51.263,-25.116
			50.765,-25.116 50.765,-24.718 51.315,-24.718 51.315,-24.474 50.468,-24.474 50.468,-25.954 51.294,-25.954 		"
      />
      <rect
        x="35.951"
        y="-26.453"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#F37321"
        width="8.812"
        height="8.813"
      />

      <rect
        x="35.95"
        y="-26.453"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="none"
        stroke="#F37321"
        strokeWidth="0.518"
        strokeMiterlimit="10"
        width="8.813"
        height="8.813"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M36.864-25.953c0.076,0,0.141,0.012,0.191,0.038
			c0.053,0.026,0.095,0.059,0.125,0.1c0.033,0.042,0.056,0.089,0.07,0.142c0.012,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.012,0.143-0.034,0.199c-0.022,0.057-0.056,0.103-0.095,0.139c-0.041,0.036-0.092,0.063-0.148,0.081
			c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.297v-1.479H36.864z M36.773-25.284c0.06,0,0.106-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.047-0.17c-0.032-0.039-0.086-0.058-0.16-0.058H36.64v0.45H36.773z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        points="37.867,-25.954 38.078,-24.909 38.083,-24.909 38.296,-25.954
			38.738,-25.954 38.738,-24.474 38.464,-24.474 38.464,-25.656 38.46,-25.656 38.197,-24.474 37.964,-24.474 37.702,-25.656
			37.698,-25.656 37.698,-24.474 37.425,-24.474 37.425,-25.954 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M39.192-24.893c0,0.035,0.002,0.066,0.008,0.094
			c0.005,0.029,0.016,0.053,0.031,0.072c0.015,0.019,0.035,0.035,0.061,0.045c0.025,0.012,0.059,0.017,0.098,0.017
			c0.045,0,0.086-0.015,0.123-0.045c0.035-0.029,0.055-0.075,0.055-0.137c0-0.034-0.006-0.062-0.014-0.086
			c-0.009-0.025-0.025-0.047-0.045-0.066c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.015-0.074-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.022-0.1-0.022-0.161c0-0.146,0.041-0.255,0.122-0.327c0.082-0.072,0.193-0.108,0.336-0.108
			c0.066,0,0.128,0.007,0.184,0.022c0.057,0.014,0.104,0.038,0.145,0.07c0.042,0.033,0.072,0.074,0.096,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.07-0.013-0.125-0.037-0.162c-0.025-0.038-0.066-0.057-0.124-0.057
			c-0.032,0-0.061,0.005-0.083,0.014c-0.023,0.01-0.039,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.064c0,0.045,0.011,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.139,0.078c0.035,0.027,0.063,0.056,0.086,0.087c0.021,0.031,0.035,0.065,0.045,0.102
			c0.007,0.038,0.011,0.079,0.011,0.125c0,0.156-0.045,0.27-0.135,0.341c-0.091,0.071-0.216,0.106-0.378,0.106
			c-0.17,0-0.29-0.036-0.362-0.109c-0.071-0.073-0.108-0.179-0.108-0.315v-0.061h0.299V-24.893z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M40.798-25.51h-0.292v-0.195h0.027c0.1,0,0.177-0.019,0.235-0.056
			c0.057-0.037,0.094-0.097,0.108-0.18h0.22v1.467h-0.299V-25.51z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M41.755-25.68l-0.03,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.084
			c0.043-0.017,0.098-0.026,0.162-0.026c0.069,0,0.127,0.013,0.174,0.04c0.047,0.027,0.084,0.063,0.11,0.107s0.046,0.094,0.058,0.15
			c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.222c-0.013,0.066-0.037,0.123-0.072,0.171
			c-0.036,0.048-0.084,0.083-0.145,0.108c-0.061,0.024-0.138,0.036-0.232,0.036c-0.092,0-0.168-0.012-0.225-0.037
			c-0.057-0.025-0.101-0.058-0.132-0.099c-0.03-0.04-0.05-0.085-0.062-0.132c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287
			c0,0.024,0.001,0.051,0.004,0.078c0.002,0.028,0.01,0.054,0.021,0.078c0.01,0.024,0.024,0.045,0.044,0.06
			c0.02,0.016,0.045,0.024,0.076,0.024c0.063,0,0.106-0.024,0.135-0.072c0.027-0.047,0.04-0.119,0.04-0.214
			c0-0.061-0.003-0.111-0.009-0.151c-0.006-0.04-0.014-0.072-0.027-0.096c-0.014-0.023-0.031-0.04-0.055-0.049
			c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.075,0.012-0.098,0.036c-0.022,0.025-0.037,0.057-0.043,0.099h-0.267l0.053-0.798
			h0.765v0.232H41.755z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M42.458-25.453c0.007-0.094,0.025-0.177,0.057-0.249
			c0.03-0.073,0.076-0.131,0.137-0.174c0.063-0.044,0.147-0.066,0.256-0.066c0.09,0,0.164,0.013,0.224,0.039
			c0.059,0.025,0.106,0.066,0.141,0.123c0.035,0.056,0.06,0.13,0.075,0.221c0.014,0.09,0.021,0.2,0.021,0.33
			c0,0.102-0.004,0.2-0.01,0.295c-0.008,0.093-0.027,0.177-0.057,0.249c-0.03,0.073-0.077,0.13-0.139,0.171
			c-0.061,0.042-0.146,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.024-0.107-0.063-0.142-0.12
			c-0.036-0.056-0.062-0.13-0.075-0.221c-0.014-0.09-0.021-0.201-0.021-0.33C42.448-25.259,42.45-25.358,42.458-25.453
			 M42.754-24.866c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.027,0.057,0.049,0.069c0.021,0.013,0.047,0.019,0.078,0.019
			c0.033,0,0.062-0.008,0.082-0.024c0.02-0.016,0.037-0.043,0.049-0.081c0.011-0.038,0.02-0.089,0.024-0.152
			c0.005-0.064,0.007-0.143,0.007-0.236v-0.16c0-0.093-0.002-0.168-0.007-0.227s-0.014-0.105-0.026-0.139
			c-0.012-0.033-0.029-0.057-0.049-0.069c-0.021-0.013-0.048-0.019-0.08-0.019c-0.033,0-0.06,0.008-0.08,0.024
			c-0.021,0.016-0.037,0.043-0.049,0.081s-0.02,0.088-0.024,0.152c-0.005,0.063-0.009,0.142-0.009,0.236v0.16
			C42.745-25,42.749-24.925,42.754-24.866"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M43.745-25.68l-0.031,0.334h0.004c0.028-0.039,0.064-0.067,0.108-0.084
			s0.098-0.026,0.161-0.026c0.07,0,0.129,0.013,0.176,0.04c0.046,0.027,0.083,0.063,0.109,0.107s0.047,0.094,0.058,0.15
			c0.01,0.056,0.016,0.113,0.016,0.171c0,0.082-0.007,0.156-0.021,0.222s-0.038,0.123-0.073,0.171
			c-0.036,0.048-0.084,0.083-0.144,0.108c-0.062,0.024-0.139,0.036-0.232,0.036s-0.168-0.012-0.225-0.037
			c-0.058-0.025-0.102-0.058-0.133-0.099c-0.029-0.04-0.05-0.085-0.061-0.132c-0.01-0.048-0.016-0.093-0.016-0.134v-0.039h0.287
			c0,0.024,0,0.051,0.004,0.078c0.002,0.028,0.008,0.054,0.02,0.078c0.01,0.024,0.025,0.045,0.044,0.06
			c0.021,0.016,0.045,0.024,0.077,0.024c0.063,0,0.105-0.024,0.134-0.072c0.027-0.047,0.04-0.119,0.04-0.214
			c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.027-0.096c-0.014-0.023-0.03-0.04-0.054-0.049
			c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.1,0.036c-0.021,0.025-0.037,0.057-0.041,0.099h-0.268l0.053-0.798
			h0.766v0.232H43.745z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M17.53-25.953c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.53z M17.439-25.284c0.059,0,0.107-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H17.439z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        points="18.534,-25.954 18.745,-24.909 18.75,-24.909 18.963,-25.954
			19.404,-25.954 19.404,-24.475 19.131,-24.475 19.131,-25.656 19.127,-25.656 18.864,-24.475 18.631,-24.475 18.368,-25.656
			18.364,-25.656 18.364,-24.475 18.09,-24.475 18.09,-25.954 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M19.858-24.893c0,0.034,0.003,0.066,0.009,0.094
			c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
			c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.047-0.045-0.066s-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			s-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108c0.066,0,0.127,0.007,0.183,0.021
			c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285
			c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
			c-0.022,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.064
			c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
			c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
			c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.036-0.362-0.109
			c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.893z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M21.125-24.474c0-0.065,0.007-0.125,0.02-0.182
			c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.05,0.088-0.099,0.142-0.149l0.121-0.111
			c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
			c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
			c-0.022,0.015-0.038,0.036-0.05,0.062c-0.012,0.027-0.02,0.057-0.023,0.092c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.057
			c0-0.136,0.036-0.241,0.11-0.315c0.073-0.073,0.188-0.109,0.346-0.109c0.149,0,0.26,0.034,0.332,0.103
			c0.071,0.07,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.009,0.041-0.021,0.081-0.039,0.117
			c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
			c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H21.125z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M22.463-24.687c0.019,0.032,0.054,0.047,0.104,0.047
			c0.041,0,0.072-0.009,0.096-0.028c0.023-0.019,0.042-0.045,0.054-0.078c0.014-0.033,0.022-0.073,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.066-0.106,0.089s-0.103,0.035-0.169,0.035
			c-0.065,0-0.12-0.012-0.165-0.035c-0.047-0.023-0.084-0.054-0.112-0.095c-0.028-0.041-0.049-0.089-0.062-0.145
			c-0.013-0.056-0.019-0.117-0.019-0.183c0-0.17,0.037-0.3,0.112-0.39c0.076-0.09,0.196-0.135,0.362-0.135
			c0.102,0,0.184,0.019,0.244,0.058c0.061,0.037,0.108,0.088,0.14,0.151c0.033,0.063,0.054,0.135,0.064,0.219
			c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.003,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.056,0.258
			c-0.031,0.075-0.08,0.133-0.147,0.177c-0.067,0.043-0.161,0.065-0.281,0.065c-0.053,0-0.103-0.005-0.153-0.015
			c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114s-0.035-0.107-0.037-0.178h0.286
			C22.432-24.761,22.441-24.718,22.463-24.687 M22.447-25.243c0.025,0.042,0.066,0.064,0.124,0.064c0.059,0,0.102-0.022,0.127-0.064
			c0.025-0.043,0.037-0.115,0.037-0.214c0-0.041-0.002-0.08-0.006-0.116c-0.004-0.036-0.012-0.066-0.024-0.092
			c-0.011-0.025-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022c-0.035,0-0.064,0.008-0.086,0.022
			c-0.021,0.015-0.037,0.035-0.048,0.061c-0.011,0.026-0.019,0.057-0.022,0.092c-0.003,0.036-0.005,0.074-0.005,0.115
			C22.41-25.357,22.422-25.286,22.447-25.243"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#FFFFFF"
        d="M23.125-25.453c0.006-0.094,0.025-0.177,0.056-0.249
			c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.148-0.066,0.256-0.066c0.091,0,0.165,0.013,0.224,0.039
			c0.058,0.026,0.105,0.067,0.14,0.123c0.035,0.057,0.06,0.13,0.075,0.221c0.014,0.09,0.022,0.201,0.022,0.33
			c0,0.102-0.004,0.2-0.011,0.295c-0.007,0.094-0.025,0.177-0.055,0.25c-0.031,0.072-0.077,0.129-0.139,0.17
			c-0.061,0.042-0.146,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.059-0.024-0.107-0.063-0.142-0.12
			c-0.036-0.056-0.06-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C23.114-25.26,23.118-25.358,23.125-25.453 M23.42-24.867
			c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.034,0.029,0.057,0.05,0.069c0.021,0.013,0.046,0.019,0.078,0.019
			c0.034,0,0.061-0.008,0.081-0.024c0.021-0.016,0.037-0.042,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.152
			c0.004-0.064,0.007-0.143,0.007-0.236v-0.16c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.058-0.014-0.105-0.026-0.139
			c-0.013-0.033-0.029-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.059,0.008-0.08,0.024
			s-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.089-0.025,0.152c-0.005,0.064-0.007,0.142-0.007,0.236v0.161
			C23.413-25.001,23.415-24.926,23.42-24.867"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M12.738,75.938c0.592,0,0.973,0.391,0.973,0.991
			c0,0.594-0.381,0.985-0.973,0.985c-0.588,0-0.969-0.392-0.969-0.985C11.769,76.328,12.15,75.938,12.738,75.938 M12.738,77.526
			c0.352,0,0.457-0.301,0.457-0.598c0-0.302-0.105-0.603-0.457-0.603c-0.349,0-0.454,0.301-0.454,0.603
			C12.284,77.226,12.389,77.526,12.738,77.526"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M14.696,75.988h0.378v0.345h-0.378v0.929c0,0.174,0.044,0.219,0.218,0.219
			c0.054,0,0.105-0.004,0.16-0.016v0.402c-0.087,0.015-0.2,0.019-0.302,0.019c-0.315,0-0.591-0.073-0.591-0.446v-1.106h-0.312
			v-0.345h0.312v-0.563h0.515V75.988z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M15.745,77.052c0.014,0.326,0.174,0.476,0.46,0.476
			c0.208,0,0.374-0.127,0.407-0.244h0.454c-0.146,0.443-0.454,0.631-0.879,0.631c-0.591,0-0.957-0.405-0.957-0.985
			c0-0.563,0.388-0.992,0.957-0.992c0.639,0,0.947,0.538,0.911,1.115H15.745z M16.583,76.725c-0.047-0.261-0.16-0.398-0.41-0.398
			c-0.327,0-0.421,0.254-0.428,0.398H16.583z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M17.801,77.254c0.004,0.226,0.193,0.316,0.396,0.316
			c0.149,0,0.337-0.058,0.337-0.24c0-0.156-0.214-0.209-0.584-0.289c-0.298-0.065-0.595-0.17-0.595-0.502
			c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.138,0.828,0.598h-0.49c-0.015-0.199-0.167-0.254-0.352-0.254
			c-0.116,0-0.287,0.021-0.287,0.176c0,0.185,0.29,0.209,0.584,0.278c0.302,0.069,0.596,0.179,0.596,0.526
			c0,0.494-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.162-0.882-0.66H17.801z"
      />
      <rect
        x="8.944"
        y="80.467"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        width="1.815"
        height="0.291"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M12.048,79.029h0.89c0.798,0,1.244,0.398,1.244,1.234
			c0,0.867-0.381,1.357-1.244,1.357h-0.89V79.029z M12.393,81.33h0.573c0.236,0,0.871-0.064,0.871-1.02
			c0-0.616-0.228-0.99-0.864-0.99h-0.58V81.33z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M15.36,79.701c0.595,0,0.911,0.432,0.911,0.984
			c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C14.449,80.133,14.765,79.701,15.36,79.701 M15.36,81.393
			c0.323,0,0.584-0.255,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
			C14.776,81.138,15.037,81.393,15.36,81.393"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M17.641,79.744h0.291v0.297h0.006c0.131-0.231,0.341-0.341,0.61-0.341
			c0.494,0,0.646,0.283,0.646,0.687v1.234h-0.308v-1.271c0-0.228-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.06
			h-0.309V79.744z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M20.467,79.701c0.595,0,0.911,0.432,0.911,0.984
			c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C19.556,80.133,19.872,79.701,20.467,79.701 M20.467,81.393
			c0.323,0,0.584-0.255,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
			C19.883,81.138,20.144,81.393,20.467,81.393"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M22.168,79.744h0.374v0.272h-0.374v1.165c0,0.142,0.039,0.167,0.232,0.167
			h0.142v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.41v-1.194H21.54v-0.272h0.319v-0.563h0.309V79.744z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M25.553,81.613c-0.055,0.032-0.123,0.051-0.222,0.051
			c-0.16,0-0.261-0.088-0.261-0.291c-0.17,0.201-0.399,0.291-0.661,0.291c-0.34,0-0.62-0.152-0.62-0.526
			c0-0.424,0.316-0.515,0.635-0.577c0.341-0.064,0.632-0.043,0.632-0.275c0-0.269-0.222-0.313-0.418-0.313
			c-0.261,0-0.453,0.081-0.468,0.355H23.86c0.018-0.465,0.378-0.627,0.795-0.627c0.338,0,0.704,0.076,0.704,0.515v0.966
			c0,0.145,0,0.21,0.098,0.21c0.026,0,0.055-0.003,0.095-0.019V81.613z M25.052,80.652c-0.12,0.086-0.352,0.09-0.559,0.127
			c-0.203,0.035-0.378,0.108-0.378,0.336c0,0.205,0.175,0.276,0.364,0.276c0.406,0,0.573-0.254,0.573-0.424V80.652z"
      />
      <rect
        x="25.856"
        y="79.029"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        width="0.308"
        height="2.591"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M27.071,79.744h0.374v0.272h-0.374v1.165c0,0.142,0.04,0.166,0.232,0.166
			h0.142v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.409v-1.194h-0.319v-0.272h0.319v-0.563h0.309V79.744z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M29.386,81.025c-0.083,0.414-0.381,0.639-0.802,0.639
			c-0.599,0-0.882-0.414-0.9-0.987c0-0.562,0.37-0.976,0.882-0.976c0.663,0,0.867,0.619,0.849,1.074H28.01
			c-0.011,0.325,0.175,0.616,0.585,0.616c0.254,0,0.432-0.122,0.486-0.366H29.386z M29.088,80.503
			c-0.014-0.294-0.236-0.53-0.54-0.53c-0.323,0-0.516,0.243-0.538,0.53H29.088z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M29.722,79.744h0.291v0.396h0.006c0.149-0.301,0.356-0.45,0.69-0.44v0.327
			c-0.497,0-0.678,0.283-0.678,0.759v0.834h-0.309V79.744z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M33.61,81.613c-0.055,0.032-0.123,0.051-0.221,0.051
			c-0.16,0-0.262-0.088-0.262-0.291c-0.17,0.201-0.398,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.526
			c0-0.424,0.316-0.515,0.636-0.577c0.34-0.064,0.631-0.043,0.631-0.275c0-0.269-0.223-0.313-0.418-0.313
			c-0.262,0-0.453,0.081-0.469,0.355H31.92c0.019-0.465,0.378-0.627,0.795-0.627c0.337,0,0.704,0.076,0.704,0.515v0.966
			c0,0.145,0,0.21,0.098,0.21c0.025,0,0.055-0.003,0.094-0.019V81.613z M33.11,80.651c-0.119,0.087-0.353,0.091-0.56,0.128
			c-0.202,0.035-0.377,0.108-0.377,0.336c0,0.205,0.175,0.276,0.363,0.276c0.405,0,0.573-0.254,0.573-0.424V80.651z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M33.886,79.744h0.29v0.396h0.007c0.148-0.301,0.355-0.45,0.689-0.44v0.327
			c-0.496,0-0.678,0.283-0.678,0.759v0.834h-0.309V79.744z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M35.532,79.744h0.375v0.272h-0.375v1.165c0,0.142,0.039,0.166,0.232,0.166
			h0.143v0.272h-0.236c-0.32,0-0.447-0.065-0.447-0.409v-1.194h-0.318v-0.272h0.318v-0.563h0.309V79.744z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        points="38.104,81.621 37.778,81.621 37.397,80.129 37.39,80.129
			37.013,81.621 36.679,81.621 36.075,79.744 36.417,79.744 36.842,81.279 36.85,81.279 37.227,79.744 37.564,79.744 37.956,81.279
			37.964,81.279 38.384,79.744 38.704,79.744 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M39.806,79.701c0.595,0,0.91,0.432,0.91,0.983
			c0,0.548-0.315,0.979-0.91,0.979c-0.596,0-0.912-0.432-0.912-0.979C38.894,80.133,39.21,79.701,39.806,79.701 M39.806,81.392
			c0.322,0,0.584-0.254,0.584-0.707c0-0.458-0.262-0.712-0.584-0.712c-0.323,0-0.584,0.254-0.584,0.712
			C39.222,81.138,39.482,81.392,39.806,81.392"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M41.067,79.744h0.29v0.396h0.007c0.148-0.301,0.355-0.45,0.689-0.44v0.327
			c-0.496,0-0.679,0.283-0.679,0.759v0.834h-0.308V79.744z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        points="42.304,79.029 42.612,79.029 42.612,80.564 43.469,79.744
			43.883,79.744 43.139,80.426 43.937,81.62 43.545,81.62 42.901,80.641 42.612,80.909 42.612,81.62 42.304,81.62 		"
      />
      <rect
        x="8.944"
        y="84.223"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        width="1.815"
        height="0.291"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M14.117,84.441c0,0.652-0.374,0.998-1.02,0.998
			c-0.668,0-1.063-0.31-1.063-0.998v-1.655h0.345v1.655c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.655
			h0.345V84.441z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M14.805,84.785c0.011,0.276,0.25,0.363,0.505,0.363
			c0.192,0,0.454-0.044,0.454-0.279c0-0.24-0.306-0.279-0.614-0.349c-0.305-0.069-0.614-0.171-0.614-0.53
			c0-0.378,0.374-0.533,0.701-0.533c0.414,0,0.744,0.131,0.769,0.588h-0.308c-0.022-0.239-0.233-0.315-0.436-0.315
			c-0.185,0-0.399,0.051-0.399,0.239c0,0.222,0.326,0.258,0.613,0.326c0.309,0.069,0.613,0.172,0.613,0.535
			c0,0.445-0.417,0.59-0.798,0.59c-0.421,0-0.777-0.17-0.794-0.635H14.805z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M18.029,84.782c-0.083,0.413-0.38,0.638-0.802,0.638
			c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.882-0.977c0.664,0,0.868,0.62,0.849,1.074h-1.404
			c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.366H18.029z M17.732,84.26
			c-0.015-0.295-0.236-0.53-0.541-0.53c-0.323,0-0.515,0.243-0.537,0.53H17.732z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M19.813,83.501h0.374v0.272h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
			h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V83.501z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M20.854,83.163h-0.308v-0.378h0.308V83.163z M20.546,83.501h0.308v1.876
			h-0.308V83.501z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M22.952,85.217c0,0.61-0.28,0.922-0.871,0.922
			c-0.352,0-0.759-0.142-0.777-0.551h0.309c0.014,0.225,0.283,0.305,0.486,0.305c0.403,0,0.562-0.291,0.562-0.712v-0.122h-0.007
			c-0.102,0.228-0.348,0.34-0.584,0.34c-0.559,0-0.838-0.438-0.838-0.951c0-0.442,0.217-0.99,0.867-0.99
			c0.236,0,0.446,0.105,0.559,0.313h0.003v-0.269h0.291V85.217z M22.646,84.4c0-0.33-0.145-0.672-0.533-0.672
			c-0.396,0-0.556,0.323-0.556,0.684c0,0.337,0.124,0.714,0.53,0.714C22.495,85.126,22.646,84.753,22.646,84.4"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M23.415,82.785h0.309v0.992h0.007c0.102-0.226,0.366-0.319,0.591-0.319
			c0.494,0,0.646,0.282,0.646,0.685v1.234H24.66v-1.27c0-0.229-0.146-0.379-0.381-0.379c-0.374,0-0.555,0.252-0.555,0.59v1.059
			h-0.309V82.785z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M25.859,83.501h0.374v0.272h-0.374v1.164c0,0.143,0.04,0.168,0.232,0.168
			h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.272h0.319v-0.563h0.309V83.501z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M28.392,83.457c0.595,0,0.911,0.432,0.911,0.984
			c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.48,83.889,27.797,83.457,28.392,83.457 M28.392,85.148
			c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712s-0.584,0.254-0.584,0.712
			C27.808,84.895,28.068,85.148,28.392,85.148"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        points="30.51,85.377 30.179,85.377 29.482,83.501 29.828,83.501
			30.354,85.064 30.361,85.064 30.873,83.501 31.196,83.501 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M33.078,84.782c-0.083,0.413-0.381,0.638-0.802,0.638
			c-0.6,0-0.882-0.414-0.9-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.62,0.849,1.074h-1.404
			c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.433-0.123,0.485-0.366H33.078z M32.78,84.26c-0.014-0.295-0.236-0.53-0.54-0.53
			c-0.323,0-0.516,0.243-0.538,0.53H32.78z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M33.414,83.501h0.291v0.396h0.006c0.149-0.301,0.356-0.449,0.69-0.439v0.326
			c-0.498,0-0.678,0.283-0.678,0.759v0.835h-0.31V83.501z"
      />
      <rect
        x="34.651"
        y="82.786"
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        width="0.308"
        height="2.591"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M36.247,83.457c0.596,0,0.911,0.433,0.911,0.984
			c0,0.547-0.315,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C35.336,83.89,35.652,83.457,36.247,83.457 M36.247,85.148
			c0.323,0,0.584-0.254,0.584-0.707c0-0.458-0.261-0.712-0.584-0.712c-0.322,0-0.584,0.254-0.584,0.712
			C35.663,84.895,35.925,85.148,36.247,85.148"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M38.794,84.104c-0.049-0.236-0.211-0.374-0.465-0.374
			c-0.446,0-0.584,0.353-0.584,0.733c0,0.345,0.156,0.686,0.548,0.686c0.297,0,0.475-0.174,0.515-0.457h0.316
			c-0.069,0.457-0.356,0.729-0.827,0.729c-0.573,0-0.879-0.398-0.879-0.957c0-0.563,0.29-1.006,0.886-1.006
			c0.424,0,0.766,0.201,0.809,0.646H38.794z"
      />
      <polygon
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        points="39.485,82.786 39.794,82.786 39.794,84.32 40.65,83.501
			41.064,83.501 40.32,84.184 41.118,85.377 40.727,85.377 40.083,84.396 39.794,84.666 39.794,85.377 39.485,85.377 		"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M41.677,83.163h-0.309v-0.378h0.309V83.163z M41.368,83.501h0.309v1.876
			h-0.309V83.501z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M42.155,83.501h0.291v0.297h0.006c0.131-0.231,0.34-0.341,0.609-0.341
			c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.309v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
			h-0.309V83.501z"
      />
      <path
        clipPath="url(#HUMANRIGHTS_SVGID_106_)"
        fill="#EC008C"
        d="M45.79,85.217c0,0.61-0.279,0.922-0.871,0.922
			c-0.352,0-0.759-0.142-0.777-0.551h0.31c0.014,0.225,0.282,0.305,0.485,0.305c0.403,0,0.563-0.291,0.563-0.711v-0.123h-0.007
			c-0.103,0.228-0.349,0.34-0.584,0.34c-0.56,0-0.839-0.438-0.839-0.95c0-0.443,0.218-0.991,0.867-0.991
			c0.236,0,0.446,0.105,0.56,0.313h0.003v-0.269h0.291V85.217z M45.485,84.4c0-0.33-0.146-0.672-0.533-0.672
			c-0.396,0-0.557,0.323-0.557,0.684c0,0.337,0.125,0.714,0.53,0.714C45.333,85.126,45.485,84.753,45.485,84.4"
      />
    </g>
  );
};

const Asparations = () => {
  return (
    <g id="Asparations">
      <defs>
        <rect
          id="ASPARATIONS_SVGID_107_"
          x="0.029"
          y="0"
          width="61.688"
          height="61.688"
        />
      </defs>
      <clipPath id="ASPARATIONS_SVGID_108_">
        <use xlinkHref="#ASPARATIONS_SVGID_107_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#00AEEF"
        d="M55.536,61.071H6.208c-3.059,0-5.563-2.504-5.563-5.563V6.181
			c0-3.06,2.504-5.563,5.563-5.563h49.328c3.06,0,5.563,2.503,5.563,5.563v49.328C61.099,58.567,58.596,61.071,55.536,61.071"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M17.414,11.114l-0.28-0.726h-1.677l-0.279,0.726h-1.052L15.79,7.13h1.005
			l1.665,3.984H17.414z M16.296,8.236L15.79,9.544h1.017L16.296,8.236z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M19.487,10.145c0.042,0.256,0.274,0.351,0.529,0.351
			c0.25,0,0.387-0.107,0.387-0.238c0-0.101-0.078-0.178-0.298-0.22L19.5,9.919c-0.553-0.101-0.868-0.398-0.868-0.868
			c0-0.612,0.529-1.011,1.284-1.011c0.744,0,1.237,0.339,1.355,0.856l-0.867,0.173c-0.03-0.185-0.208-0.351-0.499-0.351
			c-0.256,0-0.34,0.125-0.34,0.232c0,0.083,0.036,0.166,0.227,0.208l0.701,0.143c0.564,0.119,0.826,0.457,0.826,0.898
			c0,0.66-0.57,1.005-1.355,1.005c-0.702,0-1.303-0.256-1.404-0.869L19.487,10.145z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M25.13,9.622c0,0.946-0.666,1.582-1.457,1.582
			c-0.386,0-0.696-0.119-0.928-0.328v1.25h-0.952V8.13h0.952v0.238c0.232-0.209,0.542-0.327,0.928-0.327
			C24.464,8.041,25.13,8.683,25.13,9.622 M24.196,9.622c0-0.458-0.303-0.773-0.719-0.773c-0.387,0-0.732,0.315-0.732,0.773
			c0,0.464,0.345,0.773,0.732,0.773C23.893,10.395,24.196,10.085,24.196,9.622"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M26.641,7.25c0,0.303-0.232,0.529-0.553,0.529
			c-0.321,0-0.547-0.226-0.547-0.529c0-0.292,0.226-0.541,0.547-0.541C26.409,6.708,26.641,6.958,26.641,7.25 M25.612,8.129h0.952
			v2.985h-0.952V8.129z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M29.413,8.111l-0.041,0.951h-0.173c-0.666,0-1.028,0.345-1.028,1.118v0.934
			h-0.952V8.129h0.952v0.571c0.213-0.357,0.54-0.613,1.028-0.613C29.282,8.087,29.341,8.093,29.413,8.111"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M32.923,11.114h-0.951v-0.238c-0.232,0.209-0.541,0.328-0.927,0.328
			c-0.791,0-1.458-0.636-1.458-1.582c0-0.939,0.667-1.581,1.458-1.581c0.386,0,0.694,0.118,0.927,0.327V8.129h0.951V11.114z
			 M31.972,9.622c0-0.458-0.345-0.773-0.73-0.773c-0.417,0-0.72,0.315-0.72,0.773c0,0.464,0.303,0.773,0.72,0.773
			C31.627,10.395,31.972,10.085,31.972,9.622"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M35.795,10.983c-0.232,0.125-0.476,0.22-0.815,0.22
			c-0.742,0-1.189-0.404-1.189-1.189v-1.13h-0.541V8.129h0.541V7.249h0.953v0.881h0.879v0.755h-0.879v1.035
			c0,0.309,0.147,0.433,0.397,0.433c0.131,0,0.31-0.047,0.435-0.118L35.795,10.983z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M37.196,7.25c0,0.303-0.232,0.529-0.554,0.529s-0.547-0.226-0.547-0.529
			c0-0.292,0.226-0.541,0.547-0.541S37.196,6.958,37.196,7.25 M36.167,8.129h0.951v2.985h-0.951V8.129z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M40.869,9.622c0,0.946-0.75,1.576-1.635,1.576
			c-0.903,0-1.653-0.63-1.653-1.576c0-0.939,0.75-1.57,1.653-1.57C40.119,8.052,40.869,8.683,40.869,9.622 M39.954,9.622
			c0-0.464-0.352-0.773-0.72-0.773c-0.388,0-0.737,0.309-0.737,0.773c0,0.47,0.35,0.773,0.737,0.773
			C39.603,10.395,39.954,10.092,39.954,9.622"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M44.35,9.283v1.832h-0.946V9.485c0-0.352-0.208-0.59-0.512-0.59
			c-0.374,0-0.618,0.256-0.618,0.833v1.386h-0.951V8.129h0.951v0.285c0.228-0.238,0.542-0.374,0.916-0.374
			C43.886,8.041,44.35,8.534,44.35,9.283"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M45.695,10.145c0.041,0.256,0.272,0.351,0.53,0.351
			c0.249,0,0.386-0.107,0.386-0.238c0-0.101-0.077-0.178-0.297-0.22l-0.608-0.119c-0.552-0.101-0.867-0.398-0.867-0.868
			c0-0.612,0.529-1.011,1.285-1.011c0.743,0,1.236,0.339,1.355,0.856l-0.868,0.173c-0.028-0.185-0.208-0.351-0.5-0.351
			c-0.255,0-0.339,0.125-0.339,0.232c0,0.083,0.036,0.166,0.227,0.208L46.7,9.301c0.565,0.119,0.827,0.457,0.827,0.898
			c0,0.66-0.571,1.005-1.356,1.005c-0.701,0-1.301-0.256-1.402-0.869L45.695,10.145z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#F1B67B"
        d="M14.457,37.003c-0.477,2.488-0.174,4.221,1.821,6.217l2.474,2.475
			c3.398,0.967,4.916-0.078,6.919-2.08l7.652-7.605c0.246-0.248,0.369-0.549,0.369-0.904s-0.123-0.658-0.369-0.904
			c-0.26-0.26-0.561-0.383-0.903-0.371c-0.343,0.016-0.638,0.145-0.884,0.393l-4.358,4.357l-0.39-0.391l5.874-5.875
			c0.246-0.246,0.37-0.54,0.37-0.883c0-0.342-0.124-0.635-0.37-0.883c-0.246-0.246-0.548-0.369-0.903-0.369
			c-0.357,0-0.651,0.117-0.884,0.349l-5.874,5.875l-0.37-0.369l6.408-6.409c0.247-0.246,0.37-0.548,0.37-0.904
			c0-0.355-0.116-0.649-0.349-0.883c-0.247-0.246-0.548-0.369-0.903-0.369c-0.357,0-0.658,0.123-0.905,0.369l-6.408,6.409
			l-0.37-0.369l5.505-5.505c0.246-0.247,0.37-0.548,0.37-0.903c0-0.356-0.124-0.657-0.37-0.904c-0.26-0.26-0.562-0.383-0.904-0.369
			c-0.342,0.014-0.637,0.143-0.883,0.391l-9.818,9.817L14.457,37.003"
      />

      <rect
        x="6.943"
        y="38.438"
        transform="matrix(0.7071 0.7071 -0.7071 0.7071 38.1085 6.5917)"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#F1B67B"
        width="8.309"
        height="21.718"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#F1B67B"
        d="M14.457,37.003l1.473-7.515c0.072-0.371,0.263-0.66,0.571-0.868
			c0.308-0.207,0.647-0.273,1.019-0.201c0.371,0.072,0.666,0.26,0.885,0.56c0.22,0.3,0.291,0.646,0.215,1.036l-1.473,7.514
			L14.457,37.003z"
      />

      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="none"
        stroke="#EBA262"
        strokeWidth="0.518"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
			M19.019,34.761c0,0,3.71,3.711-0.098,7.518"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#005891"
        points="44.284,41.267 57.97,54.952 57.918,41.837 51.851,35.769 		"
      />
      <polyline
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#005891"
        points="32.778,15.86 54.45,37.532 48.983,42.847 26.533,20.396 		"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFDD00"
        points="47.174,39.255 44.284,41.267 45.304,37.896 42.498,35.769
			46.019,35.696 47.174,32.372 48.33,35.696 51.851,35.769 49.044,37.896 50.063,41.267 		"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFDD00"
        points="28.919,18.736 26.534,20.396 27.375,17.616 25.06,15.859
			27.965,15.8 28.919,13.057 29.873,15.8 32.778,15.859 30.463,17.616 31.304,20.396 		"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#005891"
        points="28.919,13.056 31.7,15.838 29.873,15.801 		"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#005891"
        points="51.367,18.75 58.826,26.209 58.826,42.849 35.871,19.919
			35.659,18.75 		"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFDD00"
        points="43.513,24.606 38.659,27.984 40.372,22.324 35.659,18.751
			41.571,18.63 43.513,13.044 45.454,18.63 51.367,18.751 46.654,22.324 48.367,27.984 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#60C2E6"
        d="M56.039,4.407c0.688,0,1.271,0.582,1.271,1.271v50.333
			c0,0.689-0.582,1.271-1.271,1.271H5.706c-0.689,0-1.271-0.582-1.271-1.271V5.678c0-0.689,0.582-1.271,1.271-1.271H56.039
			 M56.039,0.001H5.706c-3.123,0-5.677,2.554-5.677,5.677v50.333c0,3.123,2.554,5.678,5.677,5.678h50.333
			c3.122,0,5.677-2.555,5.677-5.678V5.678C61.716,2.555,59.161,0.001,56.039,0.001"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#5087C7"
        d="M30.883,55.362c-2.638,0-4.773-2.137-4.773-4.771
			c0-2.639,2.135-4.773,4.773-4.773s4.774,2.135,4.774,4.773C35.657,53.226,33.521,55.362,30.883,55.362"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M30.883,45.817c2.638,0,4.774,2.135,4.774,4.773
			c0,2.637-2.137,4.771-4.774,4.771s-4.773-2.135-4.773-4.771C26.11,47.952,28.245,45.817,30.883,45.817 M30.883,45.405
			c-2.859,0-5.185,2.326-5.185,5.186c0,2.857,2.326,5.184,5.185,5.184c2.86,0,5.187-2.326,5.187-5.184
			C36.069,47.731,33.743,45.405,30.883,45.405"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M33.376,48.646c-0.238,0-0.463,0.107-0.619,0.25l-0.014-0.012
			c0.073-0.119,0.17-0.346,0.17-0.717c0-0.883-0.885-1.551-2.03-1.551s-2.041,0.668-2.041,1.551c0,0.371,0.119,0.598,0.179,0.717
			l-0.013,0.012c-0.154-0.143-0.381-0.25-0.619-0.25c-0.729,0-1.457,0.703-1.457,2.016c0,1.289,0.717,2.018,1.492,2.018
			c0.716,0,1.218-0.395,1.671-0.848c-0.072-0.107-0.18-0.215-0.298-0.215c-0.12,0-0.252,0.084-0.478,0.262
			c-0.298,0.229-0.561,0.359-0.907,0.359c-0.609,0-0.87-0.682-0.87-1.576c0-0.955,0.251-1.586,0.895-1.586
			c0.286,0,0.476,0.082,0.668,0.262c0.286,0.262,0.381,0.334,0.512,0.334c0.096,0,0.156-0.061,0.18-0.096
			c-0.215-0.215-0.525-0.666-0.525-1.252c0-0.668,0.56-1.133,1.611-1.133c1.049,0,1.612,0.465,1.612,1.133
			c0,0.586-0.312,1.037-0.525,1.252c0.023,0.035,0.082,0.096,0.178,0.096c0.132,0,0.227-0.072,0.514-0.334
			c0.178-0.18,0.381-0.262,0.668-0.262c0.645,0,0.896,0.631,0.896,1.586c0,0.895-0.263,1.576-0.871,1.576
			c-0.347,0-0.621-0.131-0.908-0.359c-0.226-0.178-0.356-0.262-0.476-0.262c-0.12,0-0.227,0.107-0.299,0.215
			c0.44,0.453,0.955,0.848,1.67,0.848c0.775,0,1.492-0.729,1.492-2.018C34.833,49.349,34.104,48.646,33.376,48.646"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M31.683,48.622h-0.537c-0.035,0-0.059-0.025-0.071-0.049l-0.168-0.514
			c-0.012-0.035-0.023-0.035-0.035,0l-0.168,0.514c-0.012,0.035-0.035,0.049-0.059,0.049h-0.537c-0.025,0-0.036,0.01-0.012,0.021
			l0.442,0.324c0.023,0.023,0.023,0.059,0.011,0.082l-0.167,0.502c-0.011,0.023-0.011,0.049,0.024,0.023l0.442-0.309
			c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.025,0.037,0.012,0.023-0.012l-0.166-0.514c-0.012-0.035,0-0.07,0.023-0.082
			l0.44-0.324C31.718,48.632,31.706,48.622,31.683,48.622"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M31.276,52.153c-0.226-0.574-0.406-0.967-0.406-1.443
			c0-0.205,0.06-0.383,0.096-0.477c0.048-0.098,0.06-0.156,0.012-0.156c-0.072,0-0.227,0.166-0.322,0.311
			c-0.073,0.117-0.156,0.346-0.156,0.523c0,0.336,0.108,0.693,0.179,0.908c0.132,0.369,0.251,0.799,0.251,1.205
			c0,0.645-0.287,1.084-0.799,1.479c0.047,0.037,0.167,0.096,0.31,0.096c0.5,0,1.098-0.561,1.098-1.324
			C31.54,52.821,31.409,52.511,31.276,52.153"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-12.276-25.926c0.076,0,0.14,0.012,0.192,0.038
			c0.052,0.026,0.094,0.059,0.125,0.1c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
			c-0.058,0.018-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-12.276z M-12.367-25.257c0.059,0,0.107-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058H-12.5v0.45H-12.367z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        points="-11.272,-25.927 -11.062,-24.882 -11.056,-24.882 -10.843,-25.927
			-10.402,-25.927 -10.402,-24.447 -10.675,-24.447 -10.675,-25.629 -10.679,-25.629 -10.942,-24.447 -11.175,-24.447
			-11.438,-25.629 -11.442,-25.629 -11.442,-24.447 -11.716,-24.447 -11.716,-25.927 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-9.948-24.866c0,0.035,0.003,0.066,0.009,0.094
			c0.004,0.029,0.015,0.053,0.03,0.072s0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.017,0.096,0.017
			c0.045,0,0.087-0.014,0.123-0.045c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.046-0.045-0.066c-0.021-0.019-0.048-0.036-0.082-0.053c-0.033-0.015-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.146,0.041-0.255,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
			c0.066,0,0.127,0.007,0.183,0.022c0.056,0.014,0.104,0.038,0.145,0.07c0.041,0.033,0.072,0.074,0.095,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.07-0.013-0.125-0.038-0.162c-0.024-0.038-0.066-0.057-0.124-0.057
			c-0.033,0-0.061,0.005-0.083,0.014c-0.023,0.01-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.031,0.036,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
			c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.866z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-8.682-24.447c0-0.065,0.007-0.126,0.02-0.182
			c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15c0.041-0.049,0.088-0.099,0.142-0.148l0.121-0.112
			c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.052,0.022-0.081
			c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
			c-0.022,0.015-0.038,0.036-0.05,0.063c-0.012,0.026-0.02,0.056-0.023,0.091c-0.003,0.034-0.005,0.071-0.005,0.109h-0.286v-0.055
			c0-0.137,0.036-0.242,0.11-0.315c0.073-0.074,0.188-0.11,0.346-0.11c0.149,0,0.26,0.034,0.332,0.103
			c0.071,0.069,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.009,0.041-0.021,0.08-0.039,0.117
			c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.153c-0.044,0.04-0.079,0.08-0.104,0.117
			c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H-8.682z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-7.344-24.659c0.019,0.031,0.054,0.046,0.104,0.046
			c0.041,0,0.072-0.009,0.096-0.028c0.023-0.018,0.042-0.044,0.054-0.078c0.014-0.033,0.022-0.072,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.149v-0.07h-0.005c-0.024,0.037-0.06,0.067-0.106,0.09s-0.103,0.034-0.169,0.034
			c-0.065,0-0.12-0.011-0.166-0.034s-0.083-0.055-0.111-0.096c-0.028-0.04-0.049-0.089-0.062-0.145
			c-0.013-0.056-0.019-0.117-0.019-0.183c0-0.17,0.037-0.3,0.112-0.39c0.076-0.089,0.196-0.134,0.362-0.134
			c0.102,0,0.184,0.018,0.244,0.057c0.061,0.037,0.108,0.088,0.14,0.151c0.033,0.063,0.054,0.136,0.064,0.219
			c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.003,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.056,0.258
			c-0.031,0.075-0.08,0.134-0.147,0.177c-0.067,0.044-0.161,0.066-0.281,0.066c-0.053,0-0.103-0.005-0.153-0.016
			c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.047-0.035-0.107-0.037-0.177h0.286
			C-7.375-24.734-7.365-24.691-7.344-24.659 M-7.359-25.216c0.025,0.043,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.127-0.064
			s0.037-0.114,0.037-0.213c0-0.042-0.002-0.081-0.006-0.117c-0.004-0.036-0.012-0.066-0.024-0.092
			c-0.011-0.025-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022c-0.035,0-0.064,0.008-0.086,0.022
			c-0.021,0.015-0.037,0.035-0.048,0.061c-0.011,0.027-0.019,0.057-0.022,0.093c-0.003,0.035-0.005,0.073-0.005,0.115
			C-7.396-25.33-7.385-25.259-7.359-25.216"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-5.78-25.657c-0.061,0.086-0.115,0.178-0.162,0.276
			c-0.048,0.098-0.09,0.199-0.126,0.302c-0.036,0.104-0.066,0.21-0.089,0.316c-0.024,0.108-0.042,0.213-0.054,0.316h-0.313
			c0.011-0.088,0.027-0.181,0.05-0.278c0.022-0.098,0.051-0.197,0.088-0.298c0.036-0.101,0.081-0.202,0.133-0.304
			c0.053-0.103,0.115-0.203,0.187-0.301h-0.618v-0.257h0.904V-25.657z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        d="M-13.124-16.536c0.008-0.071,0.025-0.131,0.052-0.182c0.026-0.051,0.064-0.091,0.114-0.119
			c0.049-0.029,0.116-0.043,0.2-0.043s0.151,0.014,0.201,0.043c0.05,0.028,0.088,0.068,0.114,0.119
			c0.027,0.051,0.044,0.111,0.052,0.182c0.007,0.071,0.011,0.149,0.011,0.233s-0.004,0.163-0.011,0.233
			c-0.008,0.071-0.025,0.131-0.052,0.182c-0.026,0.051-0.064,0.089-0.114,0.116c-0.05,0.027-0.117,0.041-0.201,0.041
			s-0.151-0.014-0.2-0.041c-0.05-0.027-0.088-0.065-0.114-0.116c-0.027-0.051-0.044-0.111-0.052-0.182
			c-0.008-0.07-0.011-0.149-0.011-0.233S-13.132-16.465-13.124-16.536 M-12.906-16.113c0.004,0.052,0.011,0.095,0.022,0.128
			c0.01,0.032,0.026,0.056,0.046,0.071c0.021,0.015,0.048,0.023,0.08,0.023c0.034,0,0.06-0.008,0.08-0.023s0.036-0.039,0.047-0.071
			c0.011-0.033,0.018-0.076,0.022-0.128c0.004-0.052,0.005-0.115,0.005-0.19c0-0.075-0.001-0.138-0.005-0.19
			c-0.004-0.052-0.011-0.095-0.022-0.127c-0.011-0.033-0.027-0.058-0.047-0.073c-0.02-0.015-0.046-0.023-0.08-0.023
			c-0.032,0-0.059,0.008-0.08,0.023c-0.02,0.015-0.036,0.04-0.046,0.073c-0.011,0.032-0.018,0.075-0.022,0.127
			c-0.004,0.052-0.006,0.115-0.006,0.19C-12.912-16.228-12.91-16.165-12.906-16.113"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        points="-11.925,-16.038 -11.919,-16.038 -11.756,-16.858 -11.523,-16.858 -11.779,-15.749
			-12.065,-15.749 -12.322,-16.858 -12.082,-16.858 		"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        points="-10.821,-16.675 -11.217,-16.675 -11.217,-16.414 -10.844,-16.414 -10.844,-16.23
			-11.217,-16.23 -11.217,-15.932 -10.805,-15.932 -10.805,-15.749 -11.441,-15.749 -11.441,-16.858 -10.821,-16.858 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        d="M-10.28-16.858c0.093,0,0.166,0.023,0.217,0.071c0.052,0.047,0.079,0.119,0.079,0.215
			c0,0.075-0.015,0.135-0.045,0.183c-0.031,0.047-0.077,0.077-0.141,0.091v0.003c0.057,0.008,0.098,0.026,0.124,0.054
			c0.025,0.028,0.042,0.073,0.048,0.135c0.001,0.021,0.003,0.044,0.004,0.068c0.001,0.024,0.002,0.052,0.003,0.082
			c0.002,0.059,0.006,0.104,0.01,0.135c0.006,0.031,0.02,0.052,0.04,0.064v0.009h-0.243c-0.011-0.016-0.018-0.033-0.022-0.053
			c-0.003-0.021-0.005-0.042-0.006-0.064l-0.005-0.213c-0.003-0.043-0.014-0.077-0.034-0.102c-0.019-0.025-0.052-0.038-0.099-0.038
			h-0.12v0.47h-0.223v-1.11H-10.28z M-10.376-16.374c0.054,0,0.095-0.012,0.124-0.038c0.029-0.026,0.043-0.068,0.043-0.128
			c0-0.103-0.051-0.154-0.155-0.154h-0.106v0.32H-10.376z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        points="-9.829,-16.858 -9.605,-16.858 -9.605,-15.932 -9.209,-15.932 -9.209,-15.749
			-9.829,-15.749 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        d="M-9.122-16.536c0.009-0.071,0.025-0.131,0.052-0.182c0.026-0.051,0.064-0.091,0.114-0.119
			c0.05-0.029,0.117-0.043,0.201-0.043s0.15,0.014,0.2,0.043c0.05,0.028,0.088,0.068,0.115,0.119
			c0.026,0.051,0.043,0.111,0.051,0.182c0.008,0.071,0.011,0.149,0.011,0.233s-0.003,0.163-0.011,0.233
			c-0.008,0.071-0.025,0.131-0.051,0.182c-0.027,0.051-0.065,0.089-0.115,0.116c-0.05,0.027-0.116,0.041-0.2,0.041
			s-0.151-0.014-0.201-0.041c-0.05-0.027-0.088-0.065-0.114-0.116c-0.027-0.051-0.043-0.111-0.052-0.182
			c-0.007-0.07-0.011-0.149-0.011-0.233S-9.129-16.465-9.122-16.536 M-8.904-16.113c0.004,0.052,0.011,0.095,0.022,0.128
			c0.011,0.032,0.027,0.056,0.047,0.071s0.047,0.023,0.08,0.023c0.033,0,0.059-0.008,0.08-0.023c0.02-0.015,0.036-0.039,0.046-0.071
			c0.012-0.033,0.018-0.076,0.022-0.128c0.004-0.052,0.006-0.115,0.006-0.19c0-0.075-0.002-0.138-0.006-0.19
			c-0.004-0.052-0.01-0.095-0.022-0.127c-0.01-0.033-0.026-0.058-0.046-0.073c-0.021-0.015-0.047-0.023-0.08-0.023
			c-0.033,0-0.06,0.008-0.08,0.023c-0.02,0.015-0.036,0.04-0.047,0.073c-0.011,0.032-0.018,0.075-0.022,0.127
			c-0.004,0.052-0.005,0.115-0.005,0.19C-8.909-16.228-8.908-16.165-8.904-16.113"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        d="M-7.8-16.66c-0.019-0.037-0.054-0.055-0.105-0.055c-0.028,0-0.051,0.007-0.069,0.022
			s-0.032,0.04-0.043,0.073c-0.01,0.034-0.017,0.078-0.021,0.133c-0.004,0.054-0.007,0.12-0.007,0.199
			c0,0.084,0.003,0.152,0.009,0.204c0.006,0.052,0.014,0.092,0.027,0.12c0.011,0.029,0.026,0.048,0.045,0.058
			c0.018,0.01,0.039,0.014,0.062,0.014c0.02,0,0.039-0.003,0.055-0.009c0.017-0.007,0.032-0.021,0.045-0.041
			c0.012-0.02,0.022-0.049,0.029-0.085c0.008-0.037,0.011-0.086,0.011-0.146h0.224c0,0.06-0.004,0.118-0.014,0.172
			c-0.009,0.053-0.026,0.1-0.052,0.14c-0.026,0.041-0.061,0.073-0.107,0.095c-0.047,0.023-0.106,0.035-0.18,0.035
			c-0.084,0-0.15-0.014-0.2-0.041c-0.05-0.027-0.088-0.066-0.114-0.116C-8.232-15.939-8.249-16-8.257-16.07
			c-0.007-0.071-0.011-0.149-0.011-0.233c0-0.084,0.004-0.162,0.011-0.233c0.008-0.071,0.025-0.132,0.052-0.183
			c0.026-0.05,0.064-0.09,0.114-0.118c0.05-0.029,0.116-0.043,0.2-0.043c0.08,0,0.143,0.013,0.189,0.039s0.081,0.059,0.104,0.099
			c0.022,0.04,0.037,0.083,0.043,0.129c0.005,0.046,0.008,0.089,0.008,0.129h-0.224C-7.771-16.565-7.781-16.623-7.8-16.66"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        points="-7.187,-16.414 -7.184,-16.414 -6.901,-16.859 -6.658,-16.859 -6.96,-16.383
			-6.624,-15.749 -6.876,-15.749 -7.105,-16.204 -7.187,-16.078 -7.187,-15.749 -7.411,-15.749 -7.411,-16.859 -7.187,-16.859 		"
      />
      <rect
        x="-6.565"
        y="-16.858"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        width="0.224"
        height="1.11"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        points="-5.646,-16.098 -5.644,-16.098 -5.644,-16.858 -5.438,-16.858 -5.438,-15.748
			-5.694,-15.748 -5.967,-16.525 -5.97,-16.525 -5.97,-15.748 -6.176,-15.748 -6.176,-16.858 -5.917,-16.858 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        d="M-4.797-16.592c-0.005-0.022-0.012-0.042-0.022-0.06c-0.011-0.018-0.024-0.033-0.04-0.045
			c-0.016-0.012-0.035-0.018-0.058-0.018c-0.054,0-0.093,0.03-0.117,0.09s-0.036,0.16-0.036,0.299c0,0.066,0.003,0.126,0.007,0.18
			s0.012,0.1,0.022,0.137c0.011,0.038,0.026,0.067,0.047,0.087c0.02,0.021,0.046,0.031,0.078,0.031c0.014,0,0.028-0.004,0.044-0.011
			c0.017-0.007,0.031-0.018,0.045-0.032c0.015-0.015,0.026-0.033,0.036-0.055c0.009-0.023,0.014-0.049,0.014-0.079v-0.114h-0.148
			v-0.164h0.362v0.598h-0.165v-0.102h-0.003c-0.027,0.043-0.059,0.074-0.097,0.092s-0.083,0.027-0.136,0.027
			c-0.068,0-0.124-0.012-0.167-0.036c-0.043-0.023-0.077-0.06-0.101-0.11c-0.025-0.05-0.041-0.111-0.049-0.184
			c-0.008-0.073-0.012-0.158-0.012-0.254c0-0.094,0.006-0.175,0.017-0.246c0.013-0.07,0.033-0.129,0.062-0.177
			c0.029-0.047,0.067-0.082,0.115-0.106c0.048-0.024,0.107-0.036,0.179-0.036c0.122,0,0.21,0.03,0.263,0.092
			c0.055,0.06,0.082,0.147,0.082,0.26H-4.79C-4.79-16.549-4.792-16.57-4.797-16.592"
      />
      <rect
        x="-3.357"
        y="-26.426"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#00AEEF"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-2.443-25.926c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.056-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.443z M-2.534-25.257c0.059,0,0.107-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H-2.534z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        points="-1.44,-25.926 -1.229,-24.881 -1.224,-24.881 -1.011,-25.926
			-0.57,-25.926 -0.57,-24.447 -0.843,-24.447 -0.843,-25.628 -0.847,-25.628 -1.11,-24.447 -1.343,-24.447 -1.606,-25.628
			-1.61,-25.628 -1.61,-24.447 -1.884,-24.447 -1.884,-25.926 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-0.116-24.865c0,0.034,0.003,0.066,0.009,0.094
			c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
			c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.047-0.045-0.066s-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			s-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108c0.066,0,0.127,0.007,0.183,0.021
			c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041H0.237
			c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
			c-0.023,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
			c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
			c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
			c0,0.156-0.046,0.271-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.168,0-0.289-0.036-0.362-0.109
			c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.865z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-2.443-23.983c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.057-0.054,0.103-0.095,0.139c-0.041,0.036-0.09,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H-2.443z M-2.534-23.314c0.059,0,0.107-0.018,0.144-0.054
			c0.037-0.036,0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17s-0.085-0.058-0.159-0.058h-0.125v0.45H-2.534z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-1.625-23.619v0.149h0.005c0.028-0.054,0.064-0.097,0.105-0.129
			c0.041-0.033,0.092-0.05,0.151-0.05c0.009,0,0.017,0.001,0.027,0.001l0.025,0.004v0.273c-0.015-0.001-0.03-0.002-0.044-0.002
			c-0.015-0.001-0.03-0.001-0.045-0.001c-0.025,0-0.05,0.003-0.075,0.009c-0.025,0.008-0.047,0.019-0.068,0.035
			s-0.037,0.036-0.049,0.062c-0.013,0.026-0.02,0.057-0.02,0.094v0.67h-0.285v-1.115H-1.625z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-1.222-23.313c0.015-0.074,0.04-0.135,0.074-0.184
			c0.035-0.049,0.08-0.086,0.137-0.112c0.055-0.025,0.124-0.039,0.205-0.039c0.086,0,0.157,0.011,0.214,0.035
			c0.057,0.025,0.101,0.061,0.134,0.111c0.034,0.049,0.057,0.111,0.071,0.185c0.014,0.073,0.021,0.159,0.021,0.256
			c0,0.096-0.008,0.18-0.023,0.253s-0.04,0.135-0.075,0.184c-0.034,0.049-0.08,0.085-0.135,0.109
			c-0.057,0.023-0.125,0.034-0.207,0.034c-0.085,0.002-0.157-0.008-0.213-0.031c-0.057-0.023-0.101-0.059-0.135-0.108
			c-0.033-0.049-0.056-0.111-0.07-0.185c-0.014-0.074-0.021-0.159-0.021-0.256C-1.245-23.157-1.237-23.241-1.222-23.313
			 M-0.955-22.904c0.003,0.048,0.01,0.09,0.02,0.124c0.011,0.035,0.026,0.061,0.046,0.081c0.021,0.019,0.049,0.029,0.083,0.029
			c0.058,0,0.099-0.03,0.121-0.091s0.033-0.161,0.033-0.301c0-0.139-0.011-0.239-0.033-0.3c-0.022-0.061-0.063-0.092-0.121-0.092
			c-0.034,0-0.062,0.01-0.083,0.03c-0.02,0.019-0.035,0.046-0.046,0.08c-0.01,0.035-0.017,0.077-0.02,0.125
			c-0.003,0.048-0.004,0.101-0.004,0.157C-0.959-23.004-0.958-22.953-0.955-22.904"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M0.318-23.298c-0.004-0.026-0.01-0.05-0.019-0.072
			c-0.008-0.021-0.021-0.038-0.037-0.051c-0.018-0.013-0.04-0.02-0.067-0.02c-0.035,0-0.063,0.008-0.085,0.024
			c-0.022,0.016-0.039,0.04-0.05,0.07c-0.011,0.032-0.019,0.071-0.023,0.119c-0.004,0.048-0.006,0.104-0.006,0.169
			c0,0.069,0.002,0.128,0.006,0.177c0.004,0.049,0.012,0.09,0.023,0.121c0.011,0.032,0.027,0.055,0.047,0.07
			c0.02,0.014,0.046,0.021,0.077,0.021c0.049,0,0.084-0.02,0.106-0.062c0.022-0.041,0.034-0.107,0.034-0.199h0.273
			c0,0.146-0.033,0.258-0.098,0.335c-0.066,0.077-0.175,0.115-0.327,0.115c-0.072,0-0.134-0.009-0.188-0.026
			c-0.053-0.017-0.097-0.048-0.132-0.093c-0.036-0.045-0.063-0.105-0.08-0.181c-0.018-0.074-0.027-0.168-0.027-0.281
			c0-0.114,0.011-0.209,0.033-0.285c0.022-0.075,0.053-0.134,0.094-0.18c0.04-0.045,0.087-0.076,0.142-0.094
			c0.056-0.018,0.117-0.027,0.183-0.027c0.132,0,0.232,0.039,0.3,0.116c0.067,0.078,0.1,0.182,0.1,0.315H0.324
			C0.324-23.244,0.322-23.272,0.318-23.298"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M0.991-22.929c0,0.033,0.002,0.066,0.007,0.096
			c0.005,0.031,0.013,0.059,0.024,0.083c0.011,0.025,0.026,0.044,0.044,0.059c0.017,0.014,0.04,0.022,0.066,0.022
			c0.048,0,0.083-0.018,0.106-0.053c0.022-0.034,0.037-0.087,0.045-0.157H1.54c-0.005,0.13-0.04,0.229-0.103,0.297
			c-0.064,0.067-0.164,0.101-0.301,0.101c-0.104,0-0.184-0.017-0.242-0.052c-0.059-0.034-0.101-0.08-0.129-0.136
			c-0.028-0.057-0.044-0.121-0.051-0.191c-0.006-0.071-0.009-0.142-0.009-0.212c0-0.075,0.005-0.146,0.016-0.216
			c0.009-0.068,0.03-0.13,0.062-0.184c0.03-0.054,0.075-0.097,0.133-0.128c0.058-0.032,0.136-0.048,0.233-0.048
			c0.082,0,0.15,0.013,0.203,0.04c0.054,0.027,0.096,0.066,0.126,0.114c0.03,0.049,0.051,0.109,0.062,0.179
			c0.011,0.069,0.017,0.147,0.017,0.233v0.063H0.991V-22.929z M1.275-23.301c-0.005-0.032-0.011-0.06-0.022-0.083
			c-0.01-0.023-0.025-0.04-0.043-0.052c-0.019-0.011-0.043-0.017-0.072-0.017c-0.029,0-0.053,0.006-0.071,0.019
			c-0.019,0.014-0.034,0.031-0.045,0.053c-0.011,0.021-0.019,0.045-0.024,0.071c-0.005,0.026-0.007,0.053-0.007,0.079v0.043h0.292
			C1.282-23.231,1.279-23.268,1.275-23.301"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M1.916-22.832c0,0.049,0.013,0.088,0.038,0.118
			c0.026,0.029,0.063,0.044,0.111,0.044c0.045,0,0.08-0.011,0.106-0.033c0.026-0.023,0.039-0.055,0.039-0.1
			c0-0.036-0.01-0.063-0.03-0.082c-0.021-0.018-0.045-0.033-0.073-0.044l-0.201-0.073c-0.079-0.027-0.138-0.067-0.178-0.117
			c-0.041-0.05-0.06-0.115-0.06-0.194c0-0.045,0.007-0.089,0.022-0.129c0.016-0.041,0.04-0.077,0.073-0.107
			c0.033-0.03,0.076-0.054,0.127-0.072c0.052-0.019,0.115-0.028,0.188-0.028c0.129,0,0.226,0.028,0.288,0.083
			c0.062,0.055,0.094,0.134,0.094,0.235v0.045H2.202c0-0.058-0.01-0.1-0.029-0.127c-0.019-0.027-0.051-0.041-0.098-0.041
			c-0.036,0-0.067,0.01-0.095,0.03c-0.027,0.02-0.04,0.051-0.04,0.09c0,0.028,0.009,0.053,0.026,0.075s0.05,0.041,0.098,0.056
			l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.119c0.038,0.049,0.056,0.116,0.056,0.2c0,0.06-0.01,0.112-0.031,0.155
			c-0.021,0.044-0.049,0.079-0.086,0.109c-0.036,0.028-0.08,0.049-0.131,0.062c-0.05,0.012-0.105,0.018-0.167,0.018
			c-0.079,0-0.146-0.007-0.2-0.022c-0.052-0.016-0.094-0.038-0.126-0.069c-0.031-0.03-0.053-0.067-0.065-0.111
			c-0.012-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.832z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M2.836-22.832c0,0.049,0.012,0.088,0.038,0.118
			c0.026,0.029,0.063,0.044,0.111,0.044c0.044,0,0.08-0.011,0.106-0.033c0.026-0.023,0.039-0.055,0.039-0.1
			c0-0.036-0.01-0.063-0.031-0.082c-0.021-0.018-0.045-0.033-0.072-0.044l-0.202-0.073c-0.078-0.027-0.138-0.067-0.178-0.117
			c-0.04-0.05-0.06-0.115-0.06-0.194c0-0.045,0.008-0.089,0.023-0.129c0.015-0.041,0.039-0.077,0.073-0.107
			c0.033-0.03,0.075-0.054,0.127-0.072c0.052-0.019,0.115-0.028,0.188-0.028c0.129,0,0.226,0.028,0.288,0.083
			c0.062,0.055,0.093,0.134,0.093,0.235v0.045H3.122c0-0.058-0.009-0.1-0.028-0.127c-0.019-0.027-0.052-0.041-0.098-0.041
			c-0.037,0-0.068,0.01-0.095,0.03s-0.04,0.051-0.04,0.09c0,0.028,0.008,0.053,0.026,0.075c0.017,0.022,0.05,0.041,0.098,0.056
			l0.172,0.058c0.09,0.031,0.154,0.07,0.191,0.119c0.037,0.049,0.056,0.116,0.056,0.2c0,0.06-0.01,0.112-0.031,0.155
			c-0.021,0.044-0.05,0.079-0.086,0.109C3.25-22.533,3.206-22.512,3.156-22.5c-0.05,0.012-0.106,0.018-0.167,0.018
			c-0.079,0-0.146-0.007-0.2-0.022c-0.052-0.016-0.095-0.038-0.126-0.069c-0.032-0.03-0.053-0.067-0.065-0.111
			c-0.013-0.043-0.019-0.092-0.019-0.146v-0.039h0.257V-22.832z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-2.372-21.776c-0.026-0.049-0.073-0.074-0.14-0.074
			c-0.038,0-0.068,0.01-0.092,0.03c-0.024,0.021-0.044,0.053-0.058,0.098c-0.013,0.044-0.023,0.104-0.028,0.176
			c-0.006,0.073-0.009,0.161-0.009,0.266c0,0.112,0.004,0.203,0.012,0.272c0.007,0.069,0.019,0.123,0.035,0.161
			c0.016,0.037,0.036,0.063,0.06,0.076c0.024,0.013,0.052,0.02,0.084,0.02c0.026,0,0.051-0.004,0.074-0.014
			c0.023-0.008,0.042-0.027,0.058-0.053c0.017-0.027,0.03-0.065,0.04-0.114c0.01-0.05,0.015-0.114,0.015-0.194h0.298
			c0,0.08-0.007,0.156-0.018,0.228c-0.013,0.072-0.036,0.134-0.07,0.188c-0.034,0.054-0.082,0.096-0.143,0.127
			c-0.062,0.03-0.141,0.045-0.239,0.045c-0.112,0-0.201-0.018-0.268-0.054c-0.066-0.035-0.117-0.088-0.152-0.155
			c-0.035-0.068-0.058-0.149-0.069-0.242c-0.01-0.094-0.015-0.198-0.015-0.311c0-0.112,0.005-0.215,0.015-0.31
			c0.011-0.094,0.034-0.176,0.069-0.244c0.035-0.067,0.086-0.12,0.152-0.158c0.067-0.038,0.156-0.057,0.268-0.057
			c0.106,0,0.19,0.017,0.252,0.052c0.061,0.034,0.107,0.078,0.137,0.131c0.03,0.054,0.05,0.111,0.057,0.172
			c0.008,0.061,0.011,0.119,0.011,0.173h-0.298C-2.333-21.649-2.346-21.728-2.372-21.776"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-1.786-20.41c0.052,0,0.09-0.013,0.113-0.041
			c0.023-0.028,0.036-0.065,0.04-0.11l-0.313-1.115h0.299l0.166,0.771h0.004l0.145-0.771h0.286l-0.27,1.04
			c-0.025,0.093-0.049,0.168-0.071,0.225c-0.023,0.057-0.05,0.102-0.081,0.133c-0.031,0.031-0.069,0.051-0.112,0.061
			c-0.044,0.01-0.098,0.014-0.165,0.014h-0.116v-0.213C-1.836-20.412-1.811-20.41-1.786-20.41"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M-0.922-21.361c0-0.065,0.01-0.119,0.031-0.162
			c0.021-0.044,0.049-0.079,0.084-0.106c0.035-0.027,0.076-0.047,0.124-0.058c0.048-0.012,0.099-0.018,0.153-0.018
			c0.085,0,0.155,0.008,0.207,0.025c0.052,0.017,0.093,0.041,0.122,0.071c0.029,0.03,0.049,0.066,0.059,0.108
			c0.011,0.042,0.015,0.088,0.015,0.138v0.587c0,0.052,0.003,0.093,0.008,0.122c0.005,0.029,0.014,0.06,0.028,0.093h-0.274
			c-0.009-0.017-0.017-0.037-0.021-0.057c-0.005-0.02-0.01-0.04-0.014-0.059h-0.004c-0.033,0.059-0.071,0.096-0.115,0.113
			c-0.044,0.017-0.1,0.026-0.169,0.026c-0.05,0-0.092-0.009-0.126-0.026c-0.035-0.017-0.063-0.041-0.083-0.071
			c-0.021-0.03-0.036-0.065-0.046-0.103c-0.01-0.038-0.014-0.075-0.014-0.113c0-0.052,0.005-0.097,0.016-0.135
			c0.012-0.039,0.028-0.071,0.051-0.098s0.051-0.05,0.087-0.067c0.035-0.019,0.078-0.034,0.128-0.048l0.161-0.044
			c0.043-0.011,0.073-0.026,0.089-0.045c0.017-0.02,0.025-0.048,0.025-0.085c0-0.043-0.01-0.076-0.03-0.101
			c-0.02-0.024-0.054-0.036-0.102-0.036c-0.045,0-0.078,0.013-0.1,0.039c-0.022,0.027-0.034,0.062-0.034,0.106v0.031h-0.256V-21.361
			z M-0.462-21.082c-0.022,0.009-0.042,0.016-0.06,0.02c-0.059,0.012-0.1,0.033-0.124,0.062c-0.025,0.029-0.038,0.068-0.038,0.118
			c0,0.043,0.009,0.079,0.025,0.11c0.017,0.03,0.044,0.046,0.083,0.046c0.019,0,0.039-0.004,0.06-0.01
			c0.021-0.006,0.04-0.016,0.057-0.03c0.017-0.014,0.031-0.032,0.043-0.054c0.01-0.022,0.016-0.048,0.016-0.079v-0.219
			C-0.42-21.103-0.44-21.091-0.462-21.082"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M0.351-21.676v0.131h0.005c0.03-0.056,0.069-0.096,0.117-0.122
			c0.049-0.025,0.104-0.038,0.167-0.038c0.09,0,0.16,0.024,0.209,0.075c0.05,0.049,0.074,0.13,0.074,0.242v0.827H0.637v-0.769
			c0-0.058-0.011-0.098-0.03-0.121s-0.05-0.035-0.093-0.035c-0.101,0-0.152,0.062-0.152,0.185v0.74H0.077v-1.115H0.351z"
      />
      <rect
        x="55.544"
        y="-26.426"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#5087C7"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M56.456-25.926c0.076,0,0.141,0.012,0.193,0.037
			c0.051,0.026,0.094,0.06,0.125,0.101c0.031,0.042,0.055,0.089,0.068,0.142c0.014,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.033,0.199c-0.023,0.056-0.055,0.103-0.096,0.139s-0.09,0.063-0.147,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.159v0.59h-0.299v-1.479H56.456z M56.366-25.257c0.059,0,0.106-0.018,0.144-0.054
			s0.056-0.092,0.056-0.168c0-0.074-0.017-0.131-0.049-0.17c-0.031-0.039-0.085-0.058-0.158-0.058h-0.125v0.45H56.366z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        points="57.46,-25.926 57.671,-24.881 57.677,-24.881 57.89,-25.926
			58.33,-25.926 58.33,-24.448 58.058,-24.448 58.058,-25.628 58.054,-25.628 57.79,-24.448 57.558,-24.448 57.294,-25.628
			57.29,-25.628 57.29,-24.448 57.017,-24.448 57.017,-25.926 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M58.784-24.865c0,0.034,0.004,0.066,0.01,0.094
			c0.004,0.028,0.015,0.052,0.029,0.072c0.016,0.019,0.036,0.035,0.063,0.045c0.025,0.012,0.058,0.016,0.096,0.016
			c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.057-0.075,0.057-0.137c0-0.034-0.006-0.062-0.015-0.086
			c-0.009-0.025-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
			c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.058-0.076-0.072-0.122
			s-0.022-0.1-0.022-0.161c0-0.147,0.041-0.256,0.123-0.327c0.08-0.072,0.192-0.108,0.336-0.108c0.065,0,0.127,0.007,0.183,0.021
			c0.056,0.015,0.104,0.039,0.146,0.071c0.041,0.033,0.071,0.074,0.095,0.124c0.022,0.051,0.034,0.111,0.034,0.182v0.041h-0.285
			c0-0.071-0.014-0.125-0.038-0.162c-0.024-0.039-0.065-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
			c-0.022,0.009-0.04,0.023-0.054,0.038c-0.012,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
			c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.123,0.086l0.23,0.099c0.057,0.025,0.104,0.051,0.139,0.078
			c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.037,0.065,0.045,0.102c0.008,0.038,0.013,0.079,0.013,0.125
			c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.217,0.106-0.379,0.106c-0.168,0-0.289-0.036-0.361-0.109
			c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.865z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M60.051-24.447c0-0.065,0.007-0.125,0.021-0.182
			c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15s0.088-0.099,0.143-0.148l0.121-0.112
			c0.033-0.029,0.061-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075s0.019-0.051,0.022-0.081
			c0.002-0.029,0.004-0.062,0.004-0.099c0-0.122-0.047-0.183-0.145-0.183c-0.038,0-0.066,0.008-0.088,0.023
			c-0.021,0.015-0.038,0.036-0.051,0.063c-0.012,0.026-0.02,0.056-0.022,0.091c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.056
			c0-0.137,0.036-0.242,0.11-0.315c0.072-0.074,0.188-0.11,0.346-0.11c0.148,0,0.26,0.034,0.332,0.103
			c0.071,0.07,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.01,0.041-0.021,0.081-0.039,0.117
			c-0.019,0.038-0.043,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
			c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H60.051z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M61.958-25.657c-0.062,0.086-0.115,0.178-0.162,0.276
			c-0.049,0.098-0.09,0.199-0.127,0.302c-0.035,0.104-0.065,0.21-0.088,0.317c-0.024,0.107-0.043,0.212-0.055,0.315h-0.313
			c0.011-0.088,0.027-0.181,0.05-0.278c0.022-0.098,0.051-0.197,0.088-0.297c0.036-0.102,0.081-0.203,0.133-0.305
			c0.054-0.103,0.115-0.203,0.187-0.3h-0.617v-0.257h0.904V-25.657z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M62.38-25.483h-0.291v-0.195h0.025c0.1,0,0.178-0.018,0.235-0.055
			c0.058-0.038,0.094-0.098,0.108-0.181h0.221v1.467H62.38V-25.483z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M63.089-25.1c0.041-0.066,0.104-0.108,0.187-0.124v-0.004
			c-0.04-0.01-0.073-0.025-0.101-0.046c-0.026-0.021-0.049-0.044-0.065-0.07c-0.017-0.027-0.028-0.054-0.034-0.083
			c-0.008-0.03-0.011-0.057-0.011-0.083c0-0.144,0.038-0.247,0.115-0.31c0.075-0.063,0.181-0.094,0.315-0.094
			s0.239,0.031,0.316,0.094c0.076,0.063,0.115,0.166,0.115,0.31c0,0.026-0.004,0.053-0.012,0.083
			c-0.008,0.029-0.02,0.056-0.037,0.083c-0.016,0.026-0.037,0.049-0.064,0.07s-0.061,0.036-0.1,0.046v0.004
			c0.084,0.016,0.146,0.058,0.188,0.124c0.041,0.067,0.063,0.146,0.063,0.239c0,0.043-0.006,0.09-0.016,0.14
			c-0.01,0.052-0.031,0.099-0.066,0.144c-0.031,0.044-0.08,0.08-0.141,0.109c-0.062,0.029-0.144,0.044-0.246,0.044
			c-0.102,0-0.184-0.015-0.246-0.044c-0.062-0.029-0.107-0.065-0.142-0.109c-0.032-0.045-0.054-0.092-0.065-0.144
			c-0.01-0.05-0.015-0.097-0.015-0.14C63.027-24.954,63.048-25.033,63.089-25.1 M63.333-24.768c0.004,0.032,0.014,0.059,0.026,0.082
			c0.013,0.022,0.031,0.041,0.054,0.054c0.021,0.013,0.049,0.019,0.082,0.019s0.061-0.006,0.084-0.019
			c0.021-0.013,0.039-0.032,0.053-0.054c0.012-0.023,0.021-0.05,0.027-0.082c0.004-0.03,0.006-0.063,0.006-0.098
			s-0.002-0.069-0.006-0.099c-0.006-0.031-0.016-0.057-0.027-0.08c-0.014-0.023-0.031-0.041-0.053-0.054
			c-0.023-0.013-0.051-0.019-0.084-0.019s-0.061,0.006-0.082,0.019c-0.022,0.013-0.041,0.031-0.054,0.054s-0.022,0.049-0.026,0.08
			c-0.006,0.03-0.008,0.064-0.008,0.099S63.327-24.798,63.333-24.768 M63.39-25.363c0.025,0.033,0.063,0.05,0.105,0.05
			c0.045,0,0.08-0.017,0.105-0.05c0.027-0.033,0.04-0.085,0.04-0.155c0-0.135-0.048-0.201-0.146-0.201
			c-0.097,0-0.145,0.066-0.145,0.201C63.351-25.448,63.363-25.396,63.39-25.363"
      />
      <rect
        x="45.675"
        y="-26.426"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        width="8.813"
        height="8.813"
      />

      <rect
        x="45.675"
        y="-26.426"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="none"
        stroke="#000000"
        strokeWidth="0.13"
        strokeMiterlimit="10"
        width="8.813"
        height="8.813"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        points="46.265,-25.926 46.429,-24.833 46.433,-24.833 46.607,-25.926 46.894,-25.926
			47.067,-24.833 47.072,-24.833 47.235,-25.926 47.521,-25.926 47.252,-24.448 46.918,-24.448 46.752,-25.521 46.748,-25.521
			46.582,-24.448 46.248,-24.448 45.979,-25.926 		"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        points="47.94,-25.926 47.94,-25.36 48.288,-25.36 48.288,-25.926 48.587,-25.926
			48.587,-24.448 48.288,-24.448 48.288,-25.104 47.94,-25.104 47.94,-24.448 47.642,-24.448 47.642,-25.926 		"
      />
      <rect
        x="48.809"
        y="-25.926"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        width="0.298"
        height="1.479"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        points="50.194,-25.682 49.872,-25.682 49.872,-24.447 49.573,-24.447 49.573,-25.682
			49.249,-25.682 49.249,-25.927 50.194,-25.927 		"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        points="51.149,-25.682 50.621,-25.682 50.621,-25.334 51.119,-25.334 51.119,-25.089
			50.621,-25.089 50.621,-24.691 51.171,-24.691 51.171,-24.447 50.323,-24.447 50.323,-25.927 51.149,-25.927 		"
      />
      <rect
        x="6.512"
        y="-26.426"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#005891"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M7.425-25.926c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.056-0.054,0.103-0.095,0.139s-0.09,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59H6.903v-1.479H7.425z M7.334-25.257c0.059,0,0.107-0.018,0.144-0.054
			s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058H7.201v0.45H7.334z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        points="8.429,-25.926 8.64,-24.881 8.645,-24.881 8.857,-25.926
			9.298,-25.926 9.298,-24.448 9.025,-24.448 9.025,-25.628 9.021,-25.628 8.758,-24.448 8.525,-24.448 8.263,-25.628
			8.258,-25.628 8.258,-24.448 7.984,-24.448 7.984,-25.926 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M9.753-24.865c0,0.034,0.003,0.066,0.009,0.094
			c0.004,0.028,0.015,0.052,0.03,0.072c0.015,0.019,0.036,0.035,0.062,0.045c0.025,0.012,0.058,0.016,0.096,0.016
			c0.045,0,0.087-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.005-0.062-0.014-0.086
			c-0.009-0.025-0.024-0.047-0.045-0.066c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.015-0.046-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.123-0.327c0.081-0.072,0.193-0.108,0.336-0.108
			c0.066,0,0.127,0.007,0.183,0.021c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.285c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.124-0.057
			c-0.033,0-0.061,0.004-0.083,0.014c-0.023,0.009-0.04,0.023-0.053,0.038c-0.013,0.016-0.022,0.035-0.027,0.055
			c-0.005,0.021-0.007,0.042-0.007,0.065c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.061,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.139,0.078c0.036,0.027,0.065,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106
			c-0.168,0-0.289-0.036-0.362-0.109c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.865z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M11.019-24.447c0-0.065,0.007-0.125,0.02-0.182
			c0.013-0.057,0.033-0.111,0.06-0.162c0.027-0.051,0.06-0.101,0.101-0.15s0.088-0.099,0.142-0.148l0.121-0.112
			c0.033-0.029,0.06-0.056,0.079-0.081c0.021-0.025,0.036-0.05,0.047-0.075c0.011-0.025,0.019-0.051,0.022-0.081
			c0.003-0.029,0.005-0.062,0.005-0.099c0-0.122-0.048-0.183-0.145-0.183c-0.037,0-0.067,0.008-0.088,0.023
			c-0.022,0.015-0.038,0.036-0.05,0.063c-0.012,0.026-0.02,0.056-0.023,0.091c-0.003,0.034-0.005,0.071-0.005,0.11h-0.286v-0.056
			c0-0.137,0.036-0.242,0.11-0.315c0.073-0.074,0.188-0.11,0.346-0.11c0.149,0,0.26,0.034,0.332,0.103
			c0.071,0.07,0.107,0.168,0.107,0.297c0,0.047-0.004,0.09-0.012,0.131c-0.009,0.041-0.021,0.081-0.039,0.117
			c-0.018,0.038-0.042,0.074-0.071,0.109c-0.029,0.035-0.064,0.072-0.106,0.109l-0.165,0.154c-0.044,0.04-0.079,0.079-0.104,0.116
			c-0.025,0.039-0.041,0.071-0.048,0.099h0.554v0.232H11.019z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M12.357-24.66c0.019,0.032,0.054,0.047,0.104,0.047
			c0.041,0,0.072-0.009,0.096-0.028c0.023-0.019,0.042-0.045,0.054-0.078c0.014-0.033,0.022-0.073,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.15v-0.069h-0.005c-0.024,0.037-0.06,0.066-0.106,0.089s-0.103,0.035-0.169,0.035
			c-0.065,0-0.12-0.012-0.165-0.035c-0.047-0.023-0.084-0.054-0.112-0.095c-0.028-0.041-0.049-0.089-0.062-0.145
			c-0.013-0.056-0.019-0.117-0.019-0.183c0-0.17,0.037-0.3,0.112-0.39c0.076-0.09,0.196-0.135,0.362-0.135
			c0.102,0,0.184,0.019,0.244,0.058c0.061,0.037,0.108,0.088,0.14,0.151c0.033,0.063,0.054,0.135,0.064,0.219
			c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.003,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.056,0.258
			c-0.031,0.075-0.08,0.133-0.147,0.177c-0.067,0.043-0.161,0.065-0.281,0.065c-0.053,0-0.103-0.005-0.153-0.015
			c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.067-0.066-0.09-0.114c-0.023-0.048-0.035-0.107-0.037-0.178h0.287
			C12.327-24.733,12.336-24.69,12.357-24.66 M12.341-25.216c0.025,0.042,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.127-0.064
			c0.025-0.043,0.037-0.115,0.037-0.214c0-0.041-0.002-0.08-0.006-0.116c-0.004-0.036-0.012-0.066-0.024-0.092
			c-0.011-0.025-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022s-0.064,0.008-0.086,0.022
			c-0.021,0.015-0.037,0.035-0.048,0.061c-0.011,0.026-0.019,0.057-0.022,0.092c-0.003,0.036-0.005,0.074-0.005,0.115
			C12.304-25.331,12.316-25.259,12.341-25.216"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M13.487-25.915h0.309v0.931h0.142v0.232h-0.142v0.305h-0.273v-0.305h-0.522
			v-0.244L13.487-25.915z M13.519-25.541l-0.287,0.557h0.291v-0.557H13.519z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M14.306-25.653l-0.031,0.334h0.004c0.028-0.039,0.063-0.067,0.108-0.084
			c0.044-0.017,0.098-0.026,0.161-0.026c0.071,0,0.13,0.013,0.176,0.041c0.046,0.026,0.083,0.062,0.109,0.107
			c0.027,0.044,0.046,0.093,0.057,0.15c0.011,0.055,0.017,0.113,0.017,0.17c0,0.082-0.007,0.156-0.021,0.222
			c-0.013,0.067-0.038,0.123-0.073,0.172c-0.035,0.047-0.084,0.083-0.144,0.107c-0.061,0.024-0.138,0.036-0.232,0.036
			c-0.093,0-0.168-0.012-0.225-0.037c-0.058-0.025-0.101-0.058-0.132-0.099c-0.03-0.04-0.051-0.085-0.061-0.132
			c-0.01-0.048-0.016-0.092-0.016-0.134v-0.039h0.286c0,0.024,0.002,0.051,0.005,0.079c0.002,0.027,0.009,0.053,0.019,0.077
			c0.011,0.024,0.025,0.045,0.045,0.06c0.02,0.016,0.045,0.024,0.076,0.024c0.063,0,0.107-0.024,0.134-0.071
			c0.027-0.048,0.041-0.12,0.041-0.215c0-0.061-0.003-0.111-0.009-0.151c-0.005-0.04-0.015-0.072-0.028-0.096
			c-0.013-0.023-0.03-0.04-0.053-0.049c-0.022-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.076,0.012-0.099,0.036
			c-0.023,0.025-0.037,0.058-0.042,0.099h-0.268l0.054-0.798h0.765v0.232H14.306z"
      />
      <rect
        x="16.148"
        y="-26.426"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFDD00"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M17.063-25.926c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.011,0.143-0.034,0.199c-0.023,0.056-0.055,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H17.063z M16.972-25.257c0.059,0,0.107-0.018,0.144-0.054
			s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.16-0.058h-0.124v0.45H16.972z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        points="18.066,-25.926 18.277,-24.881 18.282,-24.881 18.495,-25.926
			18.937,-25.926 18.937,-24.448 18.663,-24.448 18.663,-25.628 18.659,-25.628 18.396,-24.448 18.163,-24.448 17.901,-25.628
			17.896,-25.628 17.896,-24.448 17.623,-24.448 17.623,-25.926 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M19.391-24.865c0,0.034,0.003,0.066,0.008,0.094s0.016,0.052,0.031,0.072
			c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016c0.045,0,0.086-0.014,0.123-0.044
			c0.036-0.029,0.055-0.075,0.055-0.137c0-0.034-0.005-0.062-0.014-0.086c-0.008-0.025-0.024-0.047-0.044-0.066
			c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048c-0.066-0.022-0.124-0.046-0.172-0.073
			c-0.049-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122c-0.015-0.046-0.023-0.1-0.023-0.161
			c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.021
			c0.056,0.015,0.104,0.039,0.144,0.071c0.042,0.033,0.073,0.074,0.096,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
			c0-0.071-0.013-0.125-0.038-0.162c-0.024-0.039-0.066-0.057-0.123-0.057c-0.033,0-0.061,0.004-0.083,0.014
			c-0.023,0.009-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
			c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
			c0.036,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102c0.007,0.038,0.011,0.079,0.011,0.125
			c0,0.156-0.045,0.27-0.135,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
			c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.865z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M20.997-25.483h-0.292v-0.195h0.027c0.1,0,0.177-0.018,0.235-0.055
			c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.298V-25.483z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M21.662-25.426c0.006-0.094,0.025-0.177,0.056-0.249
			c0.03-0.073,0.076-0.131,0.137-0.174c0.062-0.044,0.147-0.066,0.256-0.066c0.09,0,0.164,0.013,0.223,0.039
			c0.059,0.026,0.106,0.067,0.141,0.123c0.035,0.057,0.06,0.13,0.075,0.221c0.014,0.09,0.022,0.201,0.022,0.33
			c0,0.102-0.004,0.2-0.011,0.295c-0.007,0.094-0.026,0.177-0.056,0.25c-0.03,0.072-0.077,0.129-0.138,0.17
			c-0.061,0.042-0.147,0.063-0.256,0.063c-0.088,0-0.162-0.012-0.221-0.036c-0.06-0.024-0.107-0.063-0.142-0.12
			c-0.036-0.056-0.061-0.13-0.075-0.221c-0.014-0.09-0.022-0.201-0.022-0.33C21.651-25.232,21.654-25.331,21.662-25.426
			 M21.957-24.84c0.005,0.059,0.013,0.105,0.026,0.139c0.012,0.033,0.028,0.057,0.05,0.069c0.02,0.013,0.046,0.019,0.078,0.019
			c0.033,0,0.061-0.008,0.081-0.024s0.037-0.042,0.049-0.081c0.011-0.038,0.02-0.089,0.025-0.152
			c0.004-0.064,0.007-0.143,0.007-0.236v-0.16c0-0.093-0.003-0.168-0.007-0.227c-0.005-0.058-0.014-0.105-0.026-0.139
			c-0.013-0.033-0.03-0.057-0.05-0.069c-0.021-0.013-0.047-0.019-0.079-0.019c-0.033,0-0.06,0.008-0.08,0.024
			c-0.021,0.016-0.038,0.043-0.049,0.081c-0.012,0.038-0.02,0.089-0.025,0.152c-0.005,0.064-0.008,0.142-0.008,0.236v0.161
			C21.949-24.974,21.952-24.898,21.957-24.84"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M22.989-24.66c0.02,0.032,0.055,0.047,0.105,0.047
			c0.04,0,0.072-0.009,0.096-0.028c0.023-0.019,0.041-0.045,0.054-0.078c0.013-0.033,0.022-0.073,0.026-0.118
			c0.005-0.045,0.007-0.095,0.007-0.15v-0.069h-0.005c-0.025,0.037-0.06,0.066-0.106,0.089c-0.047,0.023-0.103,0.035-0.169,0.035
			c-0.065,0-0.12-0.012-0.166-0.035c-0.046-0.023-0.083-0.054-0.111-0.095s-0.049-0.089-0.062-0.145
			c-0.014-0.056-0.02-0.117-0.02-0.183c0-0.17,0.038-0.3,0.113-0.39c0.075-0.09,0.196-0.135,0.362-0.135
			c0.102,0,0.183,0.019,0.244,0.058c0.061,0.037,0.108,0.088,0.14,0.151c0.032,0.063,0.054,0.135,0.064,0.219
			c0.009,0.082,0.014,0.169,0.014,0.259c0,0.105-0.004,0.206-0.009,0.303c-0.007,0.098-0.025,0.184-0.057,0.258
			c-0.03,0.075-0.08,0.133-0.146,0.177c-0.068,0.043-0.162,0.065-0.281,0.065c-0.053,0-0.104-0.005-0.153-0.015
			c-0.049-0.01-0.092-0.03-0.129-0.058c-0.037-0.028-0.068-0.066-0.091-0.114c-0.023-0.048-0.034-0.107-0.036-0.178h0.286
			C22.959-24.733,22.969-24.69,22.989-24.66 M22.974-25.216c0.025,0.042,0.066,0.064,0.124,0.064c0.059,0,0.102-0.021,0.126-0.064
			c0.025-0.043,0.038-0.115,0.038-0.214c0-0.041-0.002-0.08-0.006-0.116c-0.004-0.036-0.012-0.066-0.024-0.092
			c-0.012-0.025-0.028-0.045-0.05-0.06c-0.021-0.014-0.049-0.022-0.084-0.022c-0.036,0-0.064,0.008-0.086,0.022
			c-0.021,0.015-0.037,0.035-0.049,0.061c-0.011,0.026-0.018,0.057-0.021,0.092c-0.004,0.036-0.005,0.074-0.005,0.115
			C22.937-25.331,22.949-25.259,22.974-25.216"
      />
      <rect
        x="26.133"
        y="-26.426"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#F1B67B"
        width="8.812"
        height="8.813"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M27.046-25.926c0.076,0,0.14,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.012,0.143-0.034,0.199c-0.023,0.056-0.054,0.103-0.095,0.139c-0.041,0.036-0.091,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.16v0.59h-0.298v-1.479H27.046z M26.956-25.257c0.059,0,0.107-0.018,0.144-0.054
			s0.055-0.092,0.055-0.168c0-0.074-0.016-0.131-0.048-0.17c-0.032-0.039-0.085-0.058-0.159-0.058h-0.125v0.45H26.956z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        points="28.049,-25.926 28.26,-24.881 28.266,-24.881 28.479,-25.926
			28.92,-25.926 28.92,-24.448 28.646,-24.448 28.646,-25.628 28.643,-25.628 28.379,-24.448 28.146,-24.448 27.884,-25.628
			27.88,-25.628 27.88,-24.448 27.606,-24.448 27.606,-25.926 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M29.374-24.865c0,0.034,0.003,0.066,0.008,0.094
			c0.005,0.028,0.016,0.052,0.031,0.072c0.015,0.019,0.036,0.035,0.061,0.045c0.026,0.012,0.058,0.016,0.097,0.016
			c0.045,0,0.086-0.014,0.123-0.044c0.036-0.029,0.056-0.075,0.056-0.137c0-0.034-0.006-0.062-0.015-0.086
			c-0.008-0.025-0.023-0.047-0.044-0.066c-0.021-0.019-0.048-0.037-0.082-0.053c-0.033-0.016-0.075-0.032-0.125-0.048
			c-0.066-0.022-0.124-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			s-0.023-0.1-0.023-0.161c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108c0.067,0,0.128,0.007,0.184,0.021
			c0.056,0.015,0.104,0.039,0.145,0.071c0.041,0.033,0.072,0.074,0.095,0.124c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286
			c0-0.071-0.012-0.125-0.037-0.162c-0.024-0.039-0.066-0.057-0.124-0.057c-0.033,0-0.061,0.004-0.083,0.014
			c-0.023,0.009-0.04,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055c-0.005,0.021-0.007,0.042-0.007,0.065
			c0,0.045,0.01,0.083,0.029,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099c0.057,0.025,0.103,0.051,0.139,0.078
			c0.036,0.027,0.064,0.056,0.086,0.087c0.022,0.03,0.036,0.065,0.045,0.102c0.008,0.038,0.012,0.079,0.012,0.125
			c0,0.156-0.046,0.27-0.136,0.341c-0.09,0.071-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.362-0.109
			c-0.072-0.073-0.108-0.179-0.108-0.315v-0.061h0.298V-24.865z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M30.98-25.483h-0.292v-0.195h0.027c0.1,0,0.178-0.018,0.235-0.055
			c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467H30.98V-25.483z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M31.938-25.653l-0.031,0.334h0.005c0.027-0.039,0.063-0.067,0.107-0.084
			s0.098-0.026,0.162-0.026c0.07,0,0.129,0.013,0.175,0.041c0.046,0.026,0.083,0.062,0.11,0.107c0.025,0.044,0.046,0.093,0.057,0.15
			c0.012,0.055,0.016,0.113,0.016,0.17c0,0.082-0.007,0.156-0.02,0.222c-0.014,0.067-0.039,0.123-0.074,0.172
			c-0.035,0.047-0.083,0.083-0.145,0.107c-0.059,0.024-0.137,0.036-0.231,0.036c-0.093,0-0.167-0.012-0.226-0.037
			c-0.057-0.025-0.1-0.058-0.131-0.099c-0.03-0.04-0.051-0.085-0.062-0.132c-0.01-0.048-0.017-0.092-0.017-0.134v-0.039h0.287
			c0,0.024,0.002,0.051,0.004,0.079c0.004,0.027,0.01,0.053,0.021,0.077c0.011,0.024,0.025,0.045,0.044,0.06
			c0.02,0.016,0.045,0.024,0.076,0.024c0.063,0,0.107-0.024,0.135-0.071c0.027-0.048,0.041-0.12,0.041-0.215
			c0-0.061-0.003-0.111-0.01-0.151c-0.004-0.04-0.014-0.072-0.027-0.096c-0.014-0.023-0.031-0.04-0.054-0.049
			c-0.021-0.01-0.049-0.015-0.082-0.015c-0.043,0-0.075,0.012-0.099,0.036c-0.022,0.025-0.037,0.058-0.042,0.099h-0.267l0.053-0.798
			h0.765v0.232H31.938z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M33.208-25.674c-0.02-0.03-0.057-0.045-0.105-0.045
			c-0.039,0-0.07,0.01-0.095,0.028c-0.024,0.019-0.042,0.045-0.056,0.078c-0.014,0.033-0.022,0.073-0.026,0.118
			c-0.004,0.046-0.006,0.095-0.006,0.149v0.073h0.004c0.026-0.039,0.062-0.069,0.108-0.092c0.046-0.023,0.102-0.035,0.168-0.035
			c0.064,0,0.119,0.012,0.166,0.035c0.045,0.023,0.082,0.054,0.111,0.095c0.027,0.041,0.049,0.089,0.062,0.145
			s0.021,0.117,0.021,0.183c0,0.17-0.039,0.299-0.113,0.387c-0.075,0.088-0.196,0.131-0.362,0.131c-0.103,0-0.185-0.017-0.245-0.053
			s-0.107-0.086-0.139-0.148c-0.033-0.064-0.055-0.136-0.064-0.219c-0.009-0.083-0.014-0.169-0.014-0.259
			c0-0.104,0.003-0.205,0.009-0.302c0.006-0.098,0.024-0.184,0.058-0.259c0.029-0.075,0.078-0.135,0.145-0.182
			c0.066-0.045,0.161-0.068,0.281-0.068c0.053,0,0.104,0.007,0.152,0.019c0.051,0.013,0.094,0.033,0.131,0.062
			c0.037,0.029,0.067,0.067,0.09,0.115c0.022,0.048,0.035,0.107,0.035,0.178h-0.285C33.237-25.599,33.228-25.643,33.208-25.674
			 M32.94-24.787c0.006,0.036,0.013,0.066,0.024,0.092c0.013,0.026,0.028,0.046,0.05,0.06c0.021,0.014,0.049,0.022,0.084,0.022
			c0.036,0,0.064-0.008,0.086-0.022c0.021-0.014,0.037-0.034,0.049-0.061c0.011-0.026,0.019-0.057,0.021-0.093
			c0.003-0.034,0.005-0.073,0.005-0.115c0-0.099-0.012-0.17-0.037-0.213c-0.024-0.042-0.065-0.064-0.124-0.064
			c-0.06,0-0.102,0.022-0.127,0.064c-0.023,0.043-0.037,0.114-0.037,0.213C32.935-24.861,32.937-24.822,32.94-24.787"
      />
      <rect
        x="35.806"
        y="-26.426"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EBA262"
        width="8.813"
        height="8.813"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M36.72-25.926c0.076,0,0.141,0.012,0.192,0.037
			c0.052,0.026,0.094,0.06,0.125,0.101c0.032,0.042,0.055,0.089,0.069,0.142c0.013,0.054,0.02,0.108,0.02,0.163
			c0,0.076-0.012,0.143-0.034,0.199c-0.022,0.056-0.054,0.103-0.095,0.139s-0.092,0.063-0.148,0.081
			c-0.058,0.017-0.123,0.027-0.193,0.027h-0.159v0.59h-0.298v-1.479H36.72z M36.629-25.257c0.06,0,0.106-0.018,0.144-0.054
			s0.056-0.092,0.056-0.168c0-0.074-0.017-0.131-0.048-0.17c-0.032-0.039-0.086-0.058-0.159-0.058h-0.125v0.45H36.629z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        points="37.724,-25.926 37.935,-24.881 37.938,-24.881 38.151,-25.926
			38.594,-25.926 38.594,-24.447 38.319,-24.447 38.319,-25.628 38.315,-25.628 38.053,-24.447 37.819,-24.447 37.558,-25.628
			37.554,-25.628 37.554,-24.447 37.28,-24.447 37.28,-25.926 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M39.048-24.865c0,0.034,0.003,0.066,0.008,0.094
			c0.005,0.028,0.016,0.052,0.031,0.072c0.015,0.019,0.035,0.035,0.061,0.045c0.026,0.012,0.059,0.016,0.098,0.016
			c0.045,0,0.086-0.014,0.123-0.044c0.035-0.029,0.056-0.075,0.056-0.137c0-0.034-0.007-0.062-0.015-0.086
			c-0.008-0.025-0.023-0.047-0.045-0.066c-0.021-0.019-0.047-0.037-0.082-0.053c-0.033-0.016-0.074-0.032-0.125-0.048
			c-0.065-0.022-0.123-0.046-0.172-0.073c-0.048-0.026-0.089-0.057-0.121-0.092c-0.033-0.035-0.057-0.076-0.072-0.122
			c-0.014-0.046-0.022-0.1-0.022-0.161c0-0.147,0.041-0.256,0.122-0.327c0.082-0.072,0.194-0.108,0.336-0.108
			c0.067,0,0.128,0.007,0.184,0.021c0.057,0.015,0.104,0.039,0.146,0.071c0.041,0.033,0.072,0.074,0.095,0.124
			c0.023,0.051,0.034,0.111,0.034,0.182v0.041h-0.286c0-0.071-0.012-0.125-0.037-0.162c-0.023-0.039-0.065-0.057-0.123-0.057
			c-0.033,0-0.062,0.004-0.084,0.014c-0.022,0.009-0.039,0.023-0.054,0.038c-0.013,0.016-0.021,0.035-0.026,0.055
			c-0.004,0.021-0.006,0.042-0.006,0.065c0,0.045,0.01,0.083,0.028,0.115c0.019,0.031,0.06,0.059,0.124,0.086l0.23,0.099
			c0.057,0.025,0.103,0.051,0.139,0.078c0.035,0.027,0.064,0.056,0.086,0.087c0.021,0.03,0.036,0.065,0.045,0.102
			c0.008,0.038,0.012,0.079,0.012,0.125c0,0.156-0.046,0.271-0.136,0.341s-0.216,0.106-0.378,0.106c-0.169,0-0.29-0.036-0.361-0.109
			c-0.072-0.073-0.109-0.179-0.109-0.315v-0.061h0.299V-24.865z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M41.226-25.657c-0.061,0.086-0.115,0.178-0.162,0.276
			c-0.047,0.098-0.09,0.199-0.125,0.302c-0.037,0.104-0.066,0.21-0.09,0.317s-0.041,0.212-0.054,0.315h-0.313
			c0.012-0.088,0.027-0.181,0.051-0.278c0.021-0.098,0.051-0.197,0.088-0.297c0.035-0.102,0.081-0.203,0.133-0.305
			c0.053-0.103,0.115-0.203,0.188-0.3h-0.619v-0.257h0.904V-25.657z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M41.788-25.915h0.309v0.931h0.141v0.232h-0.141v0.305h-0.274v-0.305H41.3
			v-0.244L41.788-25.915z M41.818-25.541l-0.286,0.557h0.29v-0.557H41.818z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M42.644-25.483h-0.293v-0.195h0.027c0.101,0,0.178-0.018,0.235-0.055
			c0.058-0.038,0.095-0.098,0.108-0.181h0.22v1.467h-0.298V-25.483z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#FFFFFF"
        d="M43.638-25.483h-0.291v-0.195h0.026c0.101,0,0.179-0.018,0.235-0.055
			c0.057-0.038,0.094-0.098,0.109-0.181h0.219v1.467h-0.299V-25.483z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M12.737,75.938c0.592,0,0.973,0.391,0.973,0.99
			c0,0.594-0.381,0.986-0.973,0.986c-0.588,0-0.969-0.393-0.969-0.986C11.768,76.329,12.149,75.938,12.737,75.938 M12.737,77.526
			c0.352,0,0.457-0.301,0.457-0.598c0-0.301-0.105-0.604-0.457-0.604c-0.349,0-0.454,0.303-0.454,0.604
			C12.283,77.226,12.388,77.526,12.737,77.526"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M14.695,75.989h0.378v0.344h-0.378v0.93c0,0.174,0.044,0.217,0.218,0.217
			c0.054,0,0.105-0.004,0.16-0.014v0.402c-0.087,0.014-0.2,0.018-0.302,0.018c-0.315,0-0.591-0.072-0.591-0.445v-1.107h-0.312
			v-0.344h0.312v-0.564h0.515V75.989z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M15.744,77.052c0.014,0.326,0.174,0.475,0.46,0.475
			c0.208,0,0.374-0.127,0.407-0.242h0.454c-0.146,0.443-0.454,0.631-0.879,0.631c-0.591,0-0.957-0.406-0.957-0.986
			c0-0.563,0.388-0.992,0.957-0.992c0.639,0,0.947,0.539,0.911,1.115H15.744z M16.582,76.726c-0.047-0.262-0.16-0.4-0.41-0.4
			c-0.327,0-0.421,0.256-0.428,0.4H16.582z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M17.799,77.255c0.004,0.225,0.193,0.316,0.396,0.316
			c0.149,0,0.337-0.059,0.337-0.24c0-0.156-0.214-0.211-0.584-0.291c-0.298-0.064-0.595-0.17-0.595-0.5
			c0-0.479,0.414-0.602,0.816-0.602c0.41,0,0.788,0.137,0.828,0.598h-0.49c-0.015-0.199-0.167-0.254-0.352-0.254
			c-0.116,0-0.287,0.021-0.287,0.174c0,0.186,0.29,0.211,0.584,0.279c0.302,0.07,0.596,0.178,0.596,0.527
			c0,0.494-0.429,0.652-0.857,0.652c-0.436,0-0.86-0.164-0.882-0.66H17.799z"
      />
      <rect
        x="8.942"
        y="80.466"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        width="1.815"
        height="0.291"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M12.047,79.03h0.89c0.798,0,1.244,0.398,1.244,1.234
			c0,0.867-0.381,1.357-1.244,1.357h-0.89V79.03z M12.392,81.331h0.573c0.236,0,0.871-0.064,0.871-1.02
			c0-0.617-0.228-0.99-0.864-0.99h-0.58V81.331z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M15.359,79.702c0.595,0,0.911,0.432,0.911,0.982
			c0,0.547-0.316,0.98-0.911,0.98c-0.595,0-0.911-0.434-0.911-0.98C14.448,80.134,14.764,79.702,15.359,79.702 M15.359,81.392
			c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
			C14.775,81.138,15.036,81.392,15.359,81.392"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M17.64,79.745h0.291v0.297h0.006c0.132-0.232,0.341-0.342,0.61-0.342
			c0.494,0,0.646,0.283,0.646,0.688v1.232h-0.308v-1.27c0-0.229-0.146-0.377-0.381-0.377c-0.374,0-0.555,0.25-0.555,0.588v1.059
			H17.64V79.745z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M20.466,79.702c0.595,0,0.911,0.432,0.911,0.982
			c0,0.547-0.316,0.98-0.911,0.98c-0.595,0-0.911-0.434-0.911-0.98C19.555,80.134,19.871,79.702,20.466,79.702 M20.466,81.392
			c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.323,0-0.584,0.254-0.584,0.711
			C19.882,81.138,20.143,81.392,20.466,81.392"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M22.167,79.745h0.374v0.271h-0.374v1.166c0,0.141,0.039,0.166,0.232,0.166
			h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.271h0.319v-0.563h0.309V79.745z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M25.551,81.614c-0.055,0.031-0.123,0.051-0.222,0.051
			c-0.16,0-0.261-0.088-0.261-0.291c-0.17,0.199-0.399,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.527
			c0-0.424,0.316-0.514,0.635-0.576c0.341-0.064,0.632-0.043,0.632-0.275c0-0.27-0.222-0.313-0.418-0.313
			c-0.261,0-0.453,0.08-0.468,0.355h-0.309c0.018-0.465,0.378-0.629,0.795-0.629c0.338,0,0.704,0.078,0.704,0.516v0.967
			c0,0.145,0,0.209,0.098,0.209c0.026,0,0.055-0.002,0.095-0.018V81.614z M25.05,80.651c-0.12,0.088-0.352,0.092-0.559,0.127
			c-0.203,0.037-0.378,0.109-0.378,0.338c0,0.203,0.175,0.275,0.364,0.275c0.406,0,0.573-0.254,0.573-0.424V80.651z"
      />
      <rect
        x="25.855"
        y="79.03"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        width="0.308"
        height="2.59"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M27.07,79.745h0.374v0.271H27.07v1.166c0,0.141,0.04,0.166,0.232,0.166
			h0.142v0.271h-0.236c-0.32,0-0.447-0.064-0.447-0.41v-1.193h-0.319v-0.271h0.319v-0.563h0.309V79.745z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M29.385,81.026c-0.083,0.414-0.381,0.639-0.802,0.639
			c-0.599,0-0.882-0.414-0.9-0.988c0-0.561,0.37-0.975,0.882-0.975c0.663,0,0.867,0.619,0.849,1.074h-1.405
			c-0.011,0.324,0.175,0.615,0.585,0.615c0.254,0,0.432-0.123,0.486-0.365H29.385z M29.087,80.503
			c-0.014-0.293-0.236-0.529-0.54-0.529c-0.323,0-0.516,0.242-0.538,0.529H29.087z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M29.721,79.745h0.291v0.396h0.006c0.149-0.301,0.356-0.451,0.69-0.441v0.328
			c-0.497,0-0.678,0.283-0.678,0.758v0.834h-0.309V79.745z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M33.61,81.614c-0.056,0.031-0.123,0.051-0.223,0.051
			c-0.158,0-0.261-0.088-0.261-0.291c-0.17,0.199-0.399,0.291-0.66,0.291c-0.341,0-0.621-0.152-0.621-0.527
			c0-0.424,0.316-0.514,0.636-0.576c0.34-0.064,0.631-0.043,0.631-0.275c0-0.27-0.221-0.313-0.416-0.313
			c-0.262,0-0.455,0.08-0.469,0.355h-0.309c0.018-0.465,0.378-0.629,0.795-0.629c0.337,0,0.704,0.078,0.704,0.516v0.967
			c0,0.145,0,0.209,0.097,0.209c0.026,0,0.055-0.002,0.096-0.018V81.614z M33.108,80.651c-0.119,0.088-0.352,0.092-0.559,0.127
			c-0.203,0.037-0.377,0.109-0.377,0.338c0,0.203,0.174,0.275,0.363,0.275c0.406,0,0.572-0.254,0.572-0.424V80.651z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M33.885,79.745h0.29v0.396h0.007c0.149-0.301,0.356-0.451,0.69-0.441v0.328
			c-0.498,0-0.68,0.283-0.68,0.758v0.834h-0.308V79.745z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M35.532,79.745h0.373v0.271h-0.373v1.166c0,0.141,0.039,0.166,0.231,0.166
			h0.142v0.271h-0.236c-0.319,0-0.446-0.064-0.446-0.41v-1.193h-0.319v-0.271h0.319v-0.563h0.31V79.745z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        points="38.104,81.622 37.777,81.622 37.396,80.13 37.389,80.13
			37.012,81.622 36.678,81.622 36.075,79.745 36.415,79.745 36.841,81.28 36.849,81.28 37.226,79.745 37.563,79.745 37.954,81.28
			37.962,81.28 38.382,79.745 38.702,79.745 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M39.804,79.702c0.596,0,0.912,0.432,0.912,0.982
			c0,0.547-0.316,0.98-0.912,0.98c-0.595,0-0.91-0.434-0.91-0.98C38.894,80.134,39.209,79.702,39.804,79.702 M39.804,81.392
			c0.323,0,0.584-0.254,0.584-0.707c0-0.457-0.261-0.711-0.584-0.711c-0.322,0-0.584,0.254-0.584,0.711
			C39.22,81.138,39.481,81.392,39.804,81.392"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M41.065,79.745h0.291v0.396h0.007c0.149-0.303,0.356-0.451,0.69-0.441v0.328
			c-0.498,0-0.68,0.283-0.68,0.758v0.834h-0.309V79.745z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        points="42.303,79.03 42.612,79.03 42.612,80.565 43.468,79.745
			43.882,79.745 43.138,80.427 43.936,81.62 43.544,81.62 42.901,80.642 42.612,80.909 42.612,81.62 42.303,81.62 		"
      />
      <rect
        x="8.942"
        y="84.224"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        width="1.815"
        height="0.291"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M14.116,84.442c0,0.652-0.374,0.998-1.02,0.998
			c-0.668,0-1.063-0.311-1.063-0.998v-1.656h0.345v1.656c0,0.457,0.261,0.707,0.718,0.707c0.436,0,0.675-0.25,0.675-0.707v-1.656
			h0.345V84.442z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M14.804,84.786c0.011,0.275,0.25,0.363,0.505,0.363
			c0.192,0,0.454-0.045,0.454-0.281c0-0.238-0.306-0.277-0.614-0.348c-0.305-0.068-0.614-0.17-0.614-0.529
			c0-0.379,0.374-0.533,0.701-0.533c0.414,0,0.744,0.131,0.769,0.588h-0.308c-0.022-0.24-0.233-0.316-0.435-0.316
			c-0.186,0-0.4,0.051-0.4,0.24c0,0.221,0.326,0.258,0.614,0.326c0.308,0.068,0.612,0.172,0.612,0.535
			c0,0.445-0.417,0.59-0.798,0.59c-0.421,0-0.777-0.17-0.794-0.635H14.804z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M18.028,84.782c-0.083,0.414-0.38,0.639-0.802,0.639
			c-0.598,0-0.882-0.414-0.9-0.986c0-0.563,0.371-0.977,0.882-0.977c0.664,0,0.868,0.619,0.849,1.074h-1.404
			c-0.011,0.326,0.174,0.617,0.585,0.617c0.254,0,0.431-0.123,0.486-0.367H18.028z M17.731,84.261
			c-0.015-0.295-0.236-0.531-0.541-0.531c-0.323,0-0.515,0.244-0.537,0.531H17.731z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M19.813,83.501h0.374v0.271h-0.374v1.166c0,0.143,0.04,0.166,0.232,0.166
			h0.142v0.273H19.95c-0.32,0-0.447-0.066-0.447-0.41v-1.195h-0.319v-0.271h0.319v-0.563h0.309V83.501z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M20.853,83.163h-0.308v-0.377h0.308V83.163z M20.545,83.501h0.308v1.877
			h-0.308V83.501z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M22.95,85.218c0,0.609-0.28,0.922-0.871,0.922
			c-0.352,0-0.759-0.143-0.777-0.551h0.309c0.014,0.225,0.283,0.303,0.486,0.303c0.403,0,0.563-0.289,0.563-0.711v-0.123h-0.008
			c-0.102,0.229-0.348,0.342-0.584,0.342c-0.559,0-0.838-0.439-0.838-0.951c0-0.443,0.217-0.99,0.867-0.99
			c0.236,0,0.446,0.105,0.559,0.313h0.004v-0.27h0.29V85.218z M22.646,84.401c0-0.33-0.145-0.672-0.533-0.672
			c-0.396,0-0.556,0.322-0.556,0.684c0,0.336,0.124,0.713,0.53,0.713C22.494,85.126,22.646,84.753,22.646,84.401"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M23.414,82.786h0.309v0.992h0.007c0.102-0.227,0.366-0.32,0.591-0.32
			c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.27c0-0.23-0.146-0.379-0.381-0.379c-0.374,0-0.555,0.25-0.555,0.59v1.059
			h-0.309V82.786z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M25.858,83.501h0.374v0.271h-0.374v1.166c0,0.143,0.04,0.166,0.232,0.166
			h0.142v0.273h-0.236c-0.32,0-0.447-0.066-0.447-0.41v-1.195H25.23v-0.271h0.319v-0.563h0.309V83.501z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M28.391,83.458c0.595,0,0.911,0.432,0.911,0.984
			c0,0.547-0.316,0.979-0.911,0.979c-0.595,0-0.911-0.432-0.911-0.979C27.479,83.89,27.795,83.458,28.391,83.458 M28.391,85.149
			c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713c-0.323,0-0.584,0.254-0.584,0.713
			C27.807,84.896,28.067,85.149,28.391,85.149"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        points="30.508,85.378 30.178,85.378 29.481,83.501 29.827,83.501
			30.353,85.065 30.359,85.065 30.872,83.501 31.194,83.501 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M33.077,84.782c-0.083,0.414-0.381,0.639-0.803,0.639
			c-0.599,0-0.881-0.414-0.899-0.986c0-0.563,0.37-0.977,0.882-0.977c0.663,0,0.867,0.619,0.85,1.074h-1.405
			c-0.011,0.326,0.175,0.617,0.585,0.617c0.254,0,0.432-0.123,0.486-0.367H33.077z M32.778,84.261
			c-0.014-0.295-0.235-0.531-0.539-0.531c-0.323,0-0.516,0.244-0.538,0.531H32.778z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M33.413,83.501h0.291v0.396h0.006c0.148-0.301,0.355-0.449,0.689-0.439
			v0.326c-0.496,0-0.678,0.283-0.678,0.758v0.836h-0.309V83.501z"
      />
      <rect
        x="34.649"
        y="82.786"
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        width="0.309"
        height="2.592"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M36.246,83.458c0.595,0,0.911,0.432,0.911,0.984
			c0,0.547-0.316,0.979-0.911,0.979s-0.911-0.432-0.911-0.979C35.335,83.89,35.651,83.458,36.246,83.458 M36.246,85.149
			c0.323,0,0.584-0.254,0.584-0.707c0-0.459-0.261-0.713-0.584-0.713s-0.584,0.254-0.584,0.713
			C35.662,84.896,35.923,85.149,36.246,85.149"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M38.793,84.104c-0.048-0.236-0.21-0.375-0.464-0.375
			c-0.447,0-0.586,0.354-0.586,0.734c0,0.344,0.156,0.686,0.549,0.686c0.297,0,0.475-0.174,0.515-0.457h0.315
			c-0.068,0.457-0.355,0.729-0.826,0.729c-0.573,0-0.879-0.398-0.879-0.957c0-0.564,0.291-1.006,0.886-1.006
			c0.424,0,0.767,0.199,0.81,0.646H38.793z"
      />
      <polygon
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        points="39.484,82.786 39.794,82.786 39.794,84.321 40.649,83.501
			41.063,83.501 40.319,84.183 41.117,85.378 40.726,85.378 40.083,84.397 39.794,84.667 39.794,85.378 39.484,85.378 		"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M41.676,83.163h-0.31v-0.377h0.31V83.163z M41.366,83.501h0.31v1.877h-0.31
			V83.501z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M42.153,83.501h0.291v0.297h0.006c0.131-0.232,0.342-0.34,0.61-0.34
			c0.494,0,0.646,0.283,0.646,0.686v1.234h-0.308v-1.271c0-0.227-0.146-0.377-0.381-0.377c-0.374,0-0.556,0.25-0.556,0.588v1.061
			h-0.309V83.501z"
      />
      <path
        clipPath="url(#ASPARATIONS_SVGID_108_)"
        fill="#EC008C"
        d="M45.789,85.218c0,0.609-0.28,0.922-0.871,0.922
			c-0.353,0-0.759-0.143-0.776-0.551h0.309c0.014,0.225,0.283,0.303,0.486,0.303c0.402,0,0.562-0.289,0.562-0.711v-0.123h-0.007
			c-0.102,0.229-0.348,0.342-0.584,0.342c-0.559,0-0.838-0.439-0.838-0.951c0-0.443,0.217-0.99,0.867-0.99
			c0.235,0,0.445,0.105,0.559,0.313h0.003v-0.27h0.291V85.218z M45.483,84.401c0-0.33-0.145-0.672-0.532-0.672
			c-0.396,0-0.556,0.322-0.556,0.684c0,0.336,0.123,0.713,0.529,0.713C45.332,85.126,45.483,84.753,45.483,84.401"
      />
    </g>
  );
};

function KnowMyself(props: any) {
  const { badge, fallback = null, ...otherProps } = props;

  switch (badge) {
    case 'Personal Brand':
      return <PersonalBrand {...otherProps} />;
    case 'Guiding History':
      return <GuidingHistory {...otherProps} />;
    case 'Human Rights':
      return <HumanRights {...otherProps} />;
    case 'Asparations':
      return <Asparations {...otherProps} />;
    default:
      return fallback;
  }
}

export default KnowMyself;
