// import React from 'react';
import Base from './Base';

function ExpressMyself(props: any) {
  const { invert, primaryColor, secondaryColor, ...otherProps } = props;
  const secondaryColour = secondaryColor || '#FFFFFF';
  const primaryColour = primaryColor || '#EC008C';

  return (
    <Base {...otherProps}>
      <g>
        <defs>
          <path
            id="EXPRESSMYSELF_SVGID_9_"
            d="M2.031,34c0,17.589,14.26,31.851,31.853,31.851c17.591,0,31.851-14.262,31.851-31.851
    c0-17.592-14.26-31.853-31.851-31.853C16.292,2.148,2.031,16.409,2.031,34"
          />
        </defs>

        <clipPath id="EXPRESSMYSELF_SVGID_10_">
          <use xlinkHref="#EXPRESSMYSELF_SVGID_9_" overflow="visible" />
        </clipPath>
      </g>

      <g id="ExpressMyself_Theme">
        <path
          clipPath="url(#EXPRESSMYSELF_SVGID_10_)"
          fill={invert ? primaryColour : secondaryColour}
          d="M64.461,34c0,16.886-13.689,30.578-30.577,30.578S3.305,50.887,3.305,34
			c0-16.888,13.691-30.579,30.579-30.579S64.461,17.112,64.461,34"
        />

        <path
          clipPath="url(#EXPRESSMYSELF_SVGID_10_)"
          fill={invert ? secondaryColour : primaryColour}
          d="M54.355,32.288c0,5.007-3.297,9.435-8.355,12.194l3.361,8.096l-10.998-5.512
			c-1.443,0.238-2.938,0.375-4.479,0.375c-11.308,0-20.474-6.785-20.474-15.153c0-8.371,9.166-15.155,20.474-15.155
			C45.189,17.133,54.355,23.917,54.355,32.288 M65.734,34c0-17.591-14.26-31.852-31.851-31.852C16.292,2.148,2.031,16.41,2.031,34
			c0,17.589,14.261,31.851,31.853,31.851C51.475,65.852,65.734,51.59,65.734,34"
        />
      </g>
    </Base>
  );
}

export default ExpressMyself;
