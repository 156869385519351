import { FormEvent, useContext, useEffect, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';

import AuthContext from 'context/Auth';
import DatabaseContext from 'context/Database';
import { friendlyMessage } from 'utilities/firebase';

import PersonIcon from 'components/icons/ui/Person';
import LockIcon from 'components/icons/ui/Lock';
import VisibilityIcon from 'components/icons/ui/Visibility';
import VisibilityOffIcon from 'components/icons/ui/VisibilityOff';

import { PageContent } from 'components/layout';
import Logo from 'components/logo';
import { Input, InputGroup, PasswordBox } from 'components/form';
import { Paragraph, Span } from 'components/typography';

import './auth.scss';
import Button, { IconButton } from 'components/button';
import { SignUpTokenData } from 'types';

function SignUp() {
  const {
    loading,
    isValid: isAuthorised,
    error,
    role,
    getSignUpTokenData,
    signUp
  } = useContext(AuthContext);
  const { units } = useContext(DatabaseContext);

  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [usernameSuffix, setUsernameSuffix] = useState('');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  // Verify Token
  const [verifyingToken, setVerifyingToken] = useState(true);
  const [tokenData, setTokenData] = useState<SignUpTokenData | null>(null);

  const location = useLocation();

  const getSignUpToken = () => {
    const queryString = new URLSearchParams(location.search);
    const token = queryString.get('token');
    return token;
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const signUpToken = getSignUpToken();

    if (
      verifyingToken ||
      !signUpToken ||
      !tokenData ||
      (!tokenData && !verifyingToken)
    ) {
      toast.error(
        'Invalid sign up token. Your sign up link may have no more uses or has expired.'
      );
      return;
    }

    if (password !== passwordConfirm) {
      toast.error('Passwords do not match');
      return;
    }

    signUp(
      `${username}.${usernameSuffix}`.toLowerCase(),
      password,
      signUpToken
    );
  };

  useEffect(() => {
    if (isAuthorised && role) {
      setSignUpSuccess(true);
      toast.success('You have successfully signed up');
    }
  }, [role, isAuthorised]);

  useEffect(() => {
    let isMounted = true;
    const queryString = new URLSearchParams(location.search);
    const token = queryString.get('token');

    if (!token) {
      setVerifyingToken(false);
      return;
    }

    getSignUpTokenData(token)
      .then((data) => {
        if (!data) {
          setVerifyingToken(false);
          return;
        }

        let newUsernameSuffix = data.role;

        if (newUsernameSuffix === 'member') {
          const unit = units && units[data.unitId];
          newUsernameSuffix = unit && unit.patrols[data.patrolId];
        }

        if (!isMounted) return;

        setTokenData(data);
        setUsernameSuffix(newUsernameSuffix);
        setVerifyingToken(false);
      })
      .catch((error) => console.log(error));

    return () => {
      isMounted = false;
    };
  }, [location, units, getSignUpTokenData]);

  useEffect(() => {
    if (!error) return;
    toast.error(friendlyMessage(error));
  }, [error]);

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>

      {signUpSuccess && <Navigate to="/" />}

      <PageContent className="content--sign-up">
        <Logo />

        <form onSubmit={onSubmit}>
          {tokenData && units ? (
            <Paragraph>{units[tokenData.unitId].displayName}</Paragraph>
          ) : null}

          <InputGroup>
            <Span color="light" className="input-group--addon bg-dark">
              <PersonIcon size={32} />
            </Span>

            <Input
              placeholder="Username"
              className={`bg-dark--glass${usernameSuffix ? ' pr-1' : ''}`}
              value={username}
              onChange={(e: any) => setUsername(e.target.value)}
              required
              disabled={loading}
              pattern="^(\w){3,}$"
            />

            {usernameSuffix && (
              <Span
                color="dark"
                className="input-group--addon bg-dark--glass text-uppercase pl-1 pr-2"
              >
                .{usernameSuffix}
              </Span>
            )}
          </InputGroup>

          <InputGroup>
            <Span color="light" className="input-group--addon bg-dark">
              <LockIcon size={32} />
            </Span>

            <PasswordBox
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              className="bg-dark--glass"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              required
              disabled={loading}
              pattern="^(\w){6,}$"
              // onInvalid={(e: any) => console.log('onInvalid', e)}
              // onInput={() => ''}
            />

            <IconButton
              className="input-group--addon bg-dark--glass"
              onClick={() => setShowPassword(!showPassword)}
              disabled={loading}
            >
              {!showPassword ? <VisibilityIcon size={32} /> : null}
              {showPassword ? <VisibilityOffIcon size={32} /> : null}
            </IconButton>
          </InputGroup>

          <InputGroup>
            <Span color="light" className="input-group--addon bg-dark">
              <LockIcon size={32} />
            </Span>

            <PasswordBox
              type={showPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              className="bg-dark--glass"
              value={passwordConfirm}
              onChange={(e: any) => setPasswordConfirm(e.target.value)}
              required
              disabled={loading}
              pattern="^(\w){6,}$"
            />

            <IconButton
              className="input-group--addon bg-dark--glass"
              onClick={() => setShowPassword(!showPassword)}
              disabled={loading}
            >
              {!showPassword ? <VisibilityIcon size={32} /> : null}
              {showPassword ? <VisibilityOffIcon size={32} /> : null}
            </IconButton>
          </InputGroup>

          <Button type="submit" color="primary" block disabled={loading}>
            {loading ? 'Loading...' : 'Sign Up'}
          </Button>

          <div className="my-2">
            {'Already have an account? '}
            <Link to="/sign-in" className="text-dark">
              Sign In
            </Link>
          </div>
        </form>
      </PageContent>
    </>
  );
}

export default SignUp;
