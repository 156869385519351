import React, { HTMLProps, PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { TextAlign } from '../../constants';
import { IconButton } from 'components/button';
import { Appbar } from 'components/layout';
import CloseIcon from 'components/icons/ui/Close';

import './modal.scss';

export type ModalProps = HTMLProps<HTMLDivElement> &
  PropsWithChildren<{
    show: boolean;
    color?: string;
    onClose?: () => void;
    onClosed?: () => void;
  }>;

export function ModalHeader(props: PropsWithChildren<any>) {
  const { title, color = 'light', onCloseClicked } = props;

  return (
    <Appbar
      className={`bg-${color}`}
      style={{ borderBottom: `0.5rem solid var(--${color}-20)` }}
      title={title}
      titleAlign={TextAlign.CENTER}
      rightButtons={[
        () => (
          <IconButton className="text-light" onClick={onCloseClicked}>
            <CloseIcon />
          </IconButton>
        )
      ]}
    />
  );
}

export function ModalBody(props: PropsWithChildren<any>) {
  const { children, className, ...otherProps } = props;

  let newClassName = 'modal__body px-2 mb-1';
  if (className) newClassName += ` ${className}`;

  return (
    <div {...otherProps} className={newClassName}>
      {children}
    </div>
  );
}

export function ModalFooter(props: any) {
  const { children, className, ...otherProps } = props;

  const classes = ['modal__footer', 'p-2'];
  if (className) classes.push(className);

  return (
    <div {...otherProps} className={classes.join(' ')}>
      {children}
    </div>
  );
}

function Modal(props: ModalProps) {
  const [isMounted, setIsMounted] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);

  const { children, show, color, onClose, onClosed } = props;
  const modalClassName = `modal${
    isVisible ? ' modal--show in' : ''
  } bg-${color}`;

  useEffect(() => {
    if (show && !isMounted) setIsMounted(true);
    else if (show && !isVisible) setTimeout(setIsVisible, 300, true);
    else if (!show && isVisible) setIsVisible(false);
    else if (!show && isMounted) {
      if (onClosed) setTimeout(onClosed, 300);
      setTimeout(setIsMounted, 300, false);
    }
  }, [show, isVisible, isMounted, onClosed]);

  return isMounted
    ? ReactDOM.createPortal(
        <div
          className={`modal-wrapper${isVisible ? ' fade-in' : ''}`}
          onClick={onClose}
        >
          <div className={modalClassName}>{children}</div>
        </div>,
        document.getElementById('portals') || document.body
      )
    : null;
}

export default Modal;
