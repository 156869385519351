import { HTMLProps, useContext, useEffect, useState } from 'react';

import { TextAlign } from '../../constants';
import AuthContext from 'context/Auth';
import { randomString } from 'utilities/string';
import { IconButton } from 'components/button';
import { Span } from 'components/typography';
import MoreVertIcon from 'components/icons/ui/MoreVert';

import './appbar.scss';

export type AppbarProps = HTMLProps<HTMLElement> & {
  title: string;
  titleAlign?: TextAlign;
  color?: string;
  leftButton?: () => JSX.Element;
  rightButtons?: (() => JSX.Element)[];
};

export function MenuButton(props: any) {
  const { signOut, user, signInAs, setShowSignInAsModal } =
    useContext(AuthContext);
  const [openMenu, setOpenMenu] = useState(false);
  const { ...otherProps } = props;

  const originLink: string | null = sessionStorage.getItem(
    'signInAs.originLink'
  );
  const originDisplayName: string | null = sessionStorage.getItem(
    'signInAs.originDisplayName'
  );

  const onSignOutClick = () => {
    setOpenMenu(false);
    const confirmed = window.confirm('Are you sure you want to sign out?');
    if (confirmed) signOut();
  };

  const hideMenu = (event: MouseEvent) => {
    if (!(event.target instanceof HTMLElement)) return;
    // Check tosee if the target is a child of the menu
    if (event.target.closest('#appbar-menu-dropdown')) return;

    setOpenMenu(false);
    document.removeEventListener('click', hideMenu);
  };

  const onReturnToClick = () => {
    setOpenMenu(false);
    if (originLink !== null) window.location.href = originLink;
    else alert('No original sign in link found');
  };

  useEffect(() => {
    if (openMenu) document.addEventListener('click', hideMenu);
    return () => document.removeEventListener('click', hideMenu);
  }, [openMenu]);

  return (
    <div style={{ position: 'relative' }}>
      <IconButton
        id="appbar-menu"
        {...otherProps}
        onClick={() => setOpenMenu(true)}
      >
        <MoreVertIcon />
      </IconButton>

      {openMenu ? (
        <div
          id="appbar-menu-dropdown"
          style={{
            position: 'absolute',
            top: '100%',
            right: 0,
            backgroundColor: '#ffffff',
            padding: '0.25rem 0',
            minWidth: 100,
            width: 200,
            borderRadius: '0.25rem',
            textAlign: 'right'
          }}
        >
          {user && (
            <div style={{ padding: '0.5rem 1rem' }}>
              <span>{user.displayName}</span>
            </div>
          )}

          {signInAs !== undefined && (
            <a
              className="d-block text-dark"
              style={{ padding: '0.5rem 1rem', cursor: 'pointer' }}
              onClick={() => setShowSignInAsModal(true)}
            >
              Switch Account
            </a>
          )}

          {originLink !== null && (
            <a
              className="d-block text-dark"
              style={{ padding: '0.5rem 1rem', cursor: 'pointer' }}
              onClick={onReturnToClick}
            >
              Sign in as {originDisplayName}
            </a>
          )}

          {/* <a
            className="d-block text-dark"
            style={{ padding: '0.5rem 1rem', cursor: 'pointer' }}
          >
            About
          </a> */}

          {/* <hr /> */}

          <a
            className="d-block bg-danger-70 text-light"
            style={{ padding: '0.5rem 1rem', cursor: 'pointer' }}
            onClick={onSignOutClick}
          >
            Sign Out
          </a>
        </div>
      ) : null}
    </div>
  );
}

function Appbar(props: AppbarProps) {
  const {
    className,
    title,
    titleAlign,
    color = 'light',
    leftButton: LeftButton,
    rightButtons = [],
    ...otherProps
  } = props;

  let newClassName = 'appbar';
  if (!LeftButton) newClassName += ' appbar--no-left-button';
  if (!rightButtons.length) newClassName += ' appbar--no-right-buttons';
  if (className) newClassName += ` ${className}`;

  return (
    <header id="appbar" {...otherProps} className={newClassName}>
      {LeftButton ? <LeftButton /> : null}

      <Span
        color={title ? color : null}
        align={titleAlign}
        className={!title ? 'appbar__spacer' : null}
      >
        {title}
      </Span>

      {rightButtons.map((Button) => (
        <Button key={randomString()} />
      ))}
    </header>
  );
}

export default Appbar;
