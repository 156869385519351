import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD-FiEbVw-QoEi3qwFTt4fQhwK14rSiggc',
  authDomain: 'go-for-guides.firebaseapp.com',
  databaseURL:
    'https://go-for-guides-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'go-for-guides',
  storageBucket: 'go-for-guides.appspot.com',
  messagingSenderId: '540803659562',
  appId: '1:540803659562:web:4c51c7725d052c4809d053',
  measurementId: 'G-5HFKNRZWGF'
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeDGrocAAAAAJhpJfdvZCkDGxoFfhSF0KV7zzUe'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
