// import React from 'react';
import './checkbox.scss';

function Checkbox(props: any) {
  const { className, label, checked, onChange, ...otherProps } = props;

  const classes = ['checkbox'];
  if (className) classes.push(className);

  return (
    <div {...otherProps} className={classes.join(' ')}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <label htmlFor="">{label}</label>
    </div>
  );
}

export default Checkbox;
