import React, { HTMLProps } from 'react';

import Typography, {
  BaseTypographyProps as TypographyProps
} from './Typography';

type ParagraphProps = HTMLProps<HTMLParagraphElement> & TypographyProps & {};

function Paragraph(props: ParagraphProps) {
  const { children, ...otherProps } = props;
  return (
    <Typography {...otherProps} as="p">
      {children}
    </Typography>
  );
}

export default Paragraph;
