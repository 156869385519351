import './input.scss';

export function InputGroup(props: any) {
  const { children, className, ...otherProps } = props;
  let newClassName = 'input-group';

  if (className) newClassName += ` ${className}`;
  return (
    <div {...otherProps} className={newClassName}>
      {children}
    </div>
  );
}

function Input(props: any) {
  const { className, type = 'text', ...otherProps } = props;
  let newClassName = 'input';

  if (className) newClassName += ` ${className}`;
  return <input type={type} {...otherProps} className={newClassName} />;
}

export function PasswordBox(props: any) {
  const { className, ...otherProps } = props;
  let newClassName = 'input--password';

  if (className) newClassName += ` ${className}`;
  return <Input type="password" {...otherProps} className={newClassName} />;
}

export default Input;
