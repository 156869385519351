// #startregion MakeChange Groups
const MakeChangeStage5 = () => (
  <g id="Stage_5_8_">
    <polygon
      fill="#FFE4C9"
      points="57.58,19.713 57.58,50.563 30.866,65.989 4.155,50.563 4.155,19.713 30.866,4.288 			"
    />
    <polygon
      fill="#FAB582"
      points="57.577,50.563 30.868,65.989 30.868,35.14 			"
    />
    <polygon
      fill="#FAB582"
      points="57.577,19.715 57.577,50.563 30.868,35.14 			"
    />
    <polygon
      fill="#FAB582"
      points="30.868,4.283 57.577,19.708 30.868,35.133 			"
    />
    <polygon fill="#FAB582" points="30.865,65.99 4.155,50.565 30.865,35.141 			" />
    <polygon fill="#FAB582" points="30.868,35.14 4.157,50.563 4.157,19.715 			" />
    <g>
      <path
        fill="#F68E1E"
        d="M30.868,4.599l26.438,15.269v30.539L30.868,65.674L4.428,50.406V19.868L30.868,4.599 M30.868,0.023
					c-0.574,0-1.146,0.149-1.659,0.444L1.68,16.366c-1.027,0.593-1.659,1.687-1.659,2.873v31.797c0,1.184,0.631,2.28,1.659,2.873
					l27.529,15.897c0.513,0.296,1.085,0.444,1.659,0.444c0.572,0,1.145-0.148,1.658-0.444l27.528-15.897
					c1.027-0.593,1.659-1.689,1.659-2.873V19.238c0-1.186-0.632-2.279-1.659-2.873L32.525,0.468
					C32.012,0.172,31.44,0.023,30.868,0.023"
      />
      <polygon
        fill="#BA5026"
        points="15.232,33.681 14.195,33.681 14.195,31.37 12.979,33.681 12.486,33.681 11.262,31.356
					11.262,33.681 10.226,33.681 10.226,29.341 11.274,29.341 12.732,32.095 14.189,29.341 15.232,29.341 				"
      />
      <path
        fill="#BA5026"
        d="M19.339,33.681h-1.037v-0.26c-0.252,0.228-0.59,0.356-1.01,0.356c-0.861,0-1.586-0.692-1.586-1.723
					c0-1.023,0.725-1.723,1.586-1.723c0.419,0,0.758,0.129,1.01,0.356V30.43h1.037V33.681z M18.302,32.055
					c0-0.498-0.376-0.84-0.795-0.84c-0.454,0-0.784,0.342-0.784,0.84c0,0.505,0.33,0.842,0.784,0.842
					C17.926,32.896,18.302,32.56,18.302,32.055"
      />
      <polygon
        fill="#BA5026"
        points="22.305,33.681 21.25,32.509 21.063,32.509 21.063,33.681 20.025,33.681 20.025,29.017
					21.063,29.017 21.063,31.536 21.205,31.536 22.233,30.43 23.484,30.43 22.059,31.978 23.632,33.681 				"
      />
      <path
        fill="#BA5026"
        d="M26.898,32.322h-2.384c0.085,0.388,0.351,0.627,0.701,0.627c0.239,0,0.517-0.058,0.693-0.376
					l0.919,0.189c-0.279,0.685-0.868,1.015-1.612,1.015c-0.939,0-1.717-0.693-1.717-1.723c0-1.023,0.778-1.723,1.724-1.723
					c0.919,0,1.658,0.661,1.677,1.723V32.322z M24.533,31.686h1.341c-0.098-0.351-0.356-0.512-0.654-0.512
					C24.929,31.174,24.631,31.354,24.533,31.686"
      />
      <path
        fill="#BA5026"
        d="M31.075,32.301l0.952,0.208c-0.162,0.771-0.847,1.269-1.658,1.269c-0.971,0-1.773-0.692-1.773-1.723
					c0-1.022,0.802-1.723,1.773-1.723c0.79,0,1.477,0.486,1.658,1.25l-0.971,0.233c-0.091-0.395-0.356-0.577-0.687-0.577
					c-0.466,0-0.776,0.344-0.776,0.816c0,0.48,0.31,0.817,0.776,0.817C30.7,32.872,30.965,32.696,31.075,32.301"
      />
      <path
        fill="#BA5026"
        d="M35.798,31.686v1.995h-1.03v-1.774c0-0.382-0.228-0.641-0.563-0.641c-0.402,0-0.668,0.279-0.668,0.907
					v1.509h-1.036v-4.663h1.036v1.721c0.252-0.257,0.59-0.407,1.003-0.407C35.292,30.332,35.798,30.871,35.798,31.686"
      />
      <path
        fill="#BA5026"
        d="M39.865,33.681h-1.036v-0.26c-0.252,0.228-0.589,0.356-1.01,0.356c-0.86,0-1.587-0.692-1.587-1.723
					c0-1.023,0.727-1.723,1.587-1.723c0.421,0,0.758,0.129,1.01,0.356V30.43h1.036V33.681z M38.829,32.055
					c0-0.498-0.377-0.84-0.797-0.84c-0.453,0-0.783,0.342-0.783,0.84c0,0.505,0.33,0.842,0.783,0.842
					C38.452,32.896,38.829,32.56,38.829,32.055"
      />
      <path
        fill="#BA5026"
        d="M43.849,31.686v1.995h-1.03v-1.774c0-0.382-0.227-0.641-0.557-0.641c-0.407,0-0.673,0.279-0.673,0.907
					v1.509h-1.036V30.43h1.036v0.31c0.246-0.257,0.588-0.407,0.997-0.407C43.342,30.332,43.849,30.871,43.849,31.686"
      />
      <path
        fill="#BA5026"
        d="M47.84,33.356c0,1.004-0.766,1.556-1.821,1.556c-0.745,0-1.314-0.26-1.633-0.783l0.862-0.467
					c0.246,0.291,0.428,0.401,0.764,0.401c0.475,0,0.81-0.279,0.81-0.791v-0.09c-0.232,0.2-0.558,0.312-0.978,0.312
					c-0.848,0-1.567-0.674-1.567-1.593c0-0.901,0.72-1.569,1.567-1.569c0.42,0,0.745,0.11,0.978,0.311V30.43h1.019V33.356z
					 M46.821,31.901c0-0.435-0.343-0.74-0.757-0.74c-0.453,0-0.776,0.305-0.776,0.74c0,0.434,0.323,0.744,0.776,0.744
					C46.479,32.645,46.821,32.334,46.821,31.901"
      />
      <path
        fill="#BA5026"
        d="M51.718,32.322h-2.382c0.083,0.388,0.35,0.627,0.698,0.627c0.239,0,0.52-0.058,0.692-0.376l0.92,0.189
					c-0.277,0.685-0.868,1.015-1.612,1.015c-0.939,0-1.717-0.693-1.717-1.723c0-1.023,0.777-1.723,1.723-1.723
					c0.921,0,1.659,0.661,1.678,1.723V32.322z M49.355,31.686h1.339c-0.098-0.351-0.355-0.512-0.654-0.512
					C49.749,31.174,49.451,31.354,49.355,31.686"
      />
      <path
        fill="#F17E21"
        d="M20.062,37.747l-1.469,0.3c-0.11-0.571-0.581-0.854-1.062-0.854c-0.498,0-0.815,0.273-0.815,0.617
					c0,0.244,0.108,0.426,0.49,0.509l1.26,0.28c1.052,0.236,1.67,0.808,1.67,1.77c0,1.297-1.153,1.968-2.503,1.968
					c-1.369,0-2.494-0.652-2.658-1.841l1.542-0.3c0.154,0.625,0.59,0.897,1.197,0.897c0.563,0,0.898-0.254,0.898-0.626
					c0-0.271-0.128-0.443-0.563-0.535l-1.27-0.272c-0.871-0.199-1.633-0.653-1.633-1.76c0-1.179,0.979-1.913,2.403-1.913
					C18.873,35.987,19.834,36.641,20.062,37.747"
      />
      <path
        fill="#F17E21"
        d="M24.133,42.001c-0.353,0.191-0.725,0.336-1.242,0.336c-1.135,0-1.815-0.617-1.815-1.815v-1.723h-0.825
					v-1.151h0.825v-1.343h1.451v1.343h1.343v1.151h-1.343v1.579c0,0.47,0.228,0.661,0.608,0.661c0.2,0,0.471-0.072,0.662-0.18
					L24.133,42.001z"
      />
      <path
        fill="#F17E21"
        d="M29.202,42.201h-1.451v-0.363c-0.353,0.319-0.826,0.499-1.414,0.499c-1.207,0-2.222-0.971-2.222-2.413
					c0-1.434,1.016-2.412,2.222-2.412c0.588,0,1.062,0.18,1.414,0.498v-0.362h1.451V42.201z M27.751,39.924
					c0-0.698-0.526-1.179-1.114-1.179c-0.636,0-1.098,0.48-1.098,1.179c0,0.707,0.462,1.179,1.098,1.179
					C27.225,41.103,27.751,40.631,27.751,39.924"
      />
      <path
        fill="#F17E21"
        d="M34.762,41.746c0,1.406-1.07,2.179-2.549,2.179c-1.043,0-1.84-0.364-2.286-1.099l1.207-0.652
					c0.345,0.407,0.599,0.562,1.07,0.562c0.663,0,1.134-0.389,1.134-1.105v-0.127c-0.327,0.281-0.781,0.437-1.37,0.437
					c-1.188,0-2.195-0.946-2.195-2.233c0-1.261,1.007-2.194,2.195-2.194c0.589,0,1.043,0.153,1.37,0.435v-0.3h1.424V41.746z
					 M33.338,39.706c0-0.607-0.481-1.033-1.062-1.033c-0.636,0-1.088,0.426-1.088,1.033s0.452,1.045,1.088,1.045
					C32.856,40.751,33.338,40.313,33.338,39.706"
      />
      <path
        fill="#F17E21"
        d="M40.102,40.297h-3.337c0.118,0.544,0.489,0.878,0.979,0.878c0.337,0,0.728-0.081,0.973-0.524
					l1.285,0.262c-0.389,0.962-1.213,1.425-2.258,1.425c-1.313,0-2.402-0.971-2.402-2.412c0-1.434,1.09-2.413,2.412-2.413
					c1.288,0,2.323,0.924,2.349,2.413V40.297z M36.792,39.407h1.876c-0.136-0.489-0.498-0.718-0.915-0.718
					C37.346,38.689,36.928,38.944,36.792,39.407"
      />
      <path
        fill="#F17E21"
        d="M44.854,38.255c1.344,0,2.141,0.77,2.141,1.96c0,1.251-0.907,2.122-2.304,2.122
					c-1.251,0-2.312-0.717-2.348-2.086l1.414-0.281c0.008,0.789,0.462,1.097,0.959,1.097c0.518,0,0.871-0.334,0.871-0.834
					c0-0.454-0.343-0.754-0.871-0.754h-2.084l0.109-3.355h3.989v1.298h-2.685l-0.036,0.834H44.854z"
      />
      <path
        fill="#BA5026"
        d="M28.187,22.919c1.395-0.537,2.582-1.493,3.407-2.716h2.589h0.023v-1.428
					c0-0.081,0.093-0.125,0.157-0.074l3.387,2.787c0.048,0.038,0.048,0.11,0,0.148l-3.387,2.787
					c-0.064,0.051-0.157,0.007-0.157-0.075v-1.428h-0.023H28.187z"
      />
      <polygon
        fill="#BA5026"
        points="29.147,13.565 29.178,13.565 29.147,13.602 				"
      />
      <path
        fill="#BA5026"
        d="M30.949,11.592l1.622,1.973l0.254,0.31l0.91,1.104c0.051,0.063,0.007,0.158-0.076,0.158h-0.833h-0.479
					v1.035c0,3.727-3.022,6.747-6.749,6.747h-2.027h-0.413c-0.055,0-0.098-0.042-0.098-0.096V22.41v-1.698V20.3
					c0-0.054,0.043-0.097,0.098-0.097h0.413h2.027c2.229,0,4.032-1.805,4.032-4.031v-1.035h-0.481h-1.06
					c-0.08,0-0.124-0.095-0.073-0.158l1.132-1.377l0.031-0.037l1.621-1.973C30.839,11.545,30.91,11.545,30.949,11.592"
      />
      <path
        fill="#4F86C6"
        d="M30.868,59.559c-2.638,0-4.775-2.136-4.775-4.773c0-2.637,2.137-4.774,4.775-4.774
					c2.639,0,4.773,2.138,4.773,4.774C35.641,57.423,33.507,59.559,30.868,59.559"
      />
      <path
        fill="#FFFFFF"
        d="M30.868,50.011c2.639,0,4.773,2.138,4.773,4.774c0,2.638-2.134,4.773-4.773,4.773
					c-2.638,0-4.775-2.136-4.775-4.773C26.093,52.148,28.229,50.011,30.868,50.011 M30.868,49.601c-2.86,0-5.186,2.325-5.186,5.185
					s2.326,5.187,5.186,5.187c2.859,0,5.184-2.327,5.184-5.187S33.727,49.601,30.868,49.601"
      />
      <path
        fill="#FFFFFF"
        d="M33.36,52.839c-0.238,0-0.464,0.108-0.619,0.251l-0.013-0.011c0.072-0.121,0.168-0.347,0.168-0.717
					c0-0.884-0.884-1.552-2.029-1.552s-2.041,0.668-2.041,1.552c0,0.37,0.119,0.596,0.178,0.717l-0.012,0.011
					c-0.154-0.143-0.381-0.251-0.619-0.251c-0.729,0-1.458,0.704-1.458,2.019c0,1.288,0.718,2.016,1.494,2.016
					c0.715,0,1.217-0.395,1.67-0.847c-0.072-0.107-0.18-0.216-0.299-0.216c-0.12,0-0.251,0.086-0.476,0.263
					c-0.3,0.229-0.563,0.358-0.909,0.358c-0.607,0-0.869-0.681-0.869-1.574c0-0.955,0.25-1.588,0.895-1.588
					c0.286,0,0.476,0.083,0.667,0.262c0.286,0.264,0.381,0.333,0.513,0.333c0.095,0,0.156-0.059,0.179-0.093
					c-0.214-0.216-0.524-0.669-0.524-1.254c0-0.668,0.56-1.135,1.611-1.135c1.05,0,1.61,0.467,1.61,1.135
					c0,0.585-0.311,1.038-0.523,1.254c0.021,0.034,0.082,0.093,0.177,0.093c0.133,0,0.228-0.069,0.513-0.333
					c0.179-0.179,0.383-0.262,0.669-0.262c0.644,0,0.895,0.633,0.895,1.588c0,0.894-0.262,1.574-0.87,1.574
					c-0.347,0-0.62-0.13-0.907-0.358c-0.227-0.177-0.358-0.263-0.476-0.263c-0.122,0-0.229,0.108-0.3,0.216
					c0.442,0.452,0.954,0.847,1.671,0.847c0.774,0,1.491-0.728,1.491-2.016C34.816,53.543,34.09,52.839,33.36,52.839"
      />
      <path
        fill="#FFFFFF"
        d="M31.667,52.816h-0.537c-0.036,0-0.058-0.025-0.071-0.048l-0.167-0.513c-0.012-0.037-0.023-0.037-0.036,0
					l-0.167,0.513c-0.012,0.035-0.035,0.048-0.059,0.048h-0.537c-0.025,0-0.036,0.012-0.012,0.022l0.441,0.323
					c0.023,0.022,0.023,0.061,0.011,0.082l-0.167,0.502c-0.011,0.024-0.011,0.049,0.023,0.024l0.443-0.309
					c0.036-0.026,0.059-0.026,0.095,0l0.429,0.309c0.023,0.024,0.036,0.012,0.023-0.013l-0.165-0.514
					c-0.012-0.034,0-0.069,0.024-0.082l0.44-0.323C31.702,52.828,31.69,52.816,31.667,52.816"
      />
      <path
        fill="#FFFFFF"
        d="M31.261,56.349c-0.225-0.572-0.406-0.967-0.406-1.445c0-0.201,0.059-0.381,0.095-0.476
					c0.048-0.097,0.061-0.157,0.011-0.157c-0.07,0-0.225,0.168-0.32,0.312c-0.073,0.12-0.155,0.347-0.155,0.523
					c0,0.337,0.107,0.693,0.179,0.908c0.132,0.37,0.25,0.8,0.25,1.207c0,0.644-0.286,1.084-0.799,1.478
					c0.048,0.037,0.167,0.096,0.311,0.096c0.5,0,1.097-0.561,1.097-1.324C31.523,57.016,31.392,56.706,31.261,56.349"
      />
    </g>
  </g>
);

const MakeChangeStage4 = () => (
  <g id="Stage_4_9_">
    <polygon
      fill="#FFE4C9"
      points="57.578,19.705 57.578,50.543 30.862,65.961 4.145,50.543 4.145,19.705 30.862,4.286 			"
    />
    <polygon
      fill="#FAB582"
      points="57.577,50.543 30.862,65.961 30.862,35.125 			"
    />
    <polygon
      fill="#FAB582"
      points="57.577,19.707 57.577,50.543 30.862,35.125 			"
    />
    <polygon fill="#FAB582" points="30.862,4.281 57.577,19.7 30.862,35.118 			" />
    <polygon
      fill="#FAB582"
      points="30.861,65.963 4.146,50.544 30.861,35.126 			"
    />
    <g>
      <path
        fill="#F68E1E"
        d="M30.862,4.598l26.442,15.262v30.526L30.862,65.646L4.418,50.386V19.859L30.862,4.598 M30.862,0.023
					c-0.574,0-1.147,0.149-1.661,0.444L1.669,16.359C0.643,16.952,0.01,18.045,0.01,19.23v31.783c0,1.186,0.633,2.279,1.659,2.872
					l27.532,15.891c0.513,0.296,1.087,0.444,1.661,0.444c0.572,0,1.145-0.148,1.658-0.444l27.534-15.891
					c1.028-0.593,1.659-1.687,1.659-2.872V19.23c0-1.185-0.631-2.278-1.659-2.871L32.52,0.467
					C32.007,0.172,31.435,0.023,30.862,0.023"
      />
      <polygon
        fill="#BA5026"
        points="15.224,33.667 14.188,33.667 14.188,31.356 12.97,33.667 12.478,33.667 11.253,31.343
					11.253,33.667 10.217,33.667 10.217,29.329 11.267,29.329 12.724,32.082 14.181,29.329 15.224,29.329 				"
      />
      <path
        fill="#BA5026"
        d="M19.332,33.667h-1.036v-0.26c-0.253,0.227-0.59,0.355-1.012,0.355c-0.861,0-1.587-0.691-1.587-1.721
					c0-1.023,0.727-1.722,1.587-1.722c0.421,0,0.758,0.129,1.012,0.356v-0.259h1.036V33.667z M18.296,32.042
					c0-0.498-0.377-0.84-0.797-0.84c-0.454,0-0.784,0.342-0.784,0.84c0,0.504,0.331,0.841,0.784,0.841
					C17.918,32.883,18.296,32.546,18.296,32.042"
      />
      <polygon
        fill="#BA5026"
        points="22.299,33.667 21.243,32.495 21.056,32.495 21.056,33.667 20.02,33.667 20.02,29.005
					21.056,29.005 21.056,31.523 21.197,31.523 22.228,30.417 23.477,30.417 22.053,31.965 23.626,33.667 				"
      />
      <path
        fill="#BA5026"
        d="M26.892,32.308h-2.384c0.085,0.388,0.351,0.627,0.701,0.627c0.239,0,0.519-0.058,0.693-0.376
					l0.919,0.189c-0.279,0.684-0.868,1.014-1.612,1.014c-0.94,0-1.717-0.692-1.717-1.721c0-1.023,0.777-1.722,1.723-1.722
					c0.92,0,1.659,0.66,1.678,1.722V32.308z M24.528,31.673h1.34c-0.098-0.35-0.356-0.511-0.655-0.511
					C24.923,31.162,24.625,31.341,24.528,31.673"
      />
      <path
        fill="#BA5026"
        d="M31.07,32.288l0.951,0.208c-0.16,0.771-0.847,1.268-1.656,1.268c-0.973,0-1.776-0.691-1.776-1.721
					c0-1.022,0.804-1.722,1.776-1.722c0.788,0,1.476,0.486,1.656,1.25l-0.971,0.233c-0.091-0.395-0.356-0.576-0.685-0.576
					c-0.468,0-0.777,0.343-0.777,0.815c0,0.48,0.31,0.817,0.777,0.817C30.694,32.858,30.96,32.684,31.07,32.288"
      />
      <path
        fill="#BA5026"
        d="M35.793,31.673v1.994h-1.031v-1.774c0-0.382-0.225-0.641-0.562-0.641c-0.402,0-0.668,0.279-0.668,0.906
					v1.509h-1.036v-4.661h1.036v1.721c0.253-0.257,0.59-0.407,1.005-0.407C35.288,30.319,35.793,30.857,35.793,31.673"
      />
      <path
        fill="#BA5026"
        d="M39.861,33.667h-1.036v-0.26c-0.251,0.227-0.59,0.355-1.01,0.355c-0.862,0-1.588-0.691-1.588-1.721
					c0-1.023,0.726-1.722,1.588-1.722c0.42,0,0.759,0.129,1.01,0.356v-0.259h1.036V33.667z M38.825,32.042
					c0-0.498-0.376-0.84-0.796-0.84c-0.455,0-0.785,0.342-0.785,0.84c0,0.504,0.33,0.841,0.785,0.841
					C38.449,32.883,38.825,32.546,38.825,32.042"
      />
      <path
        fill="#BA5026"
        d="M43.845,31.673v1.994h-1.029v-1.774c0-0.382-0.226-0.641-0.558-0.641c-0.407,0-0.672,0.279-0.672,0.906
					v1.509H40.55v-3.25h1.036v0.31c0.245-0.257,0.588-0.407,0.997-0.407C43.341,30.319,43.845,30.857,43.845,31.673"
      />
      <path
        fill="#BA5026"
        d="M47.837,33.343c0,1.004-0.767,1.556-1.822,1.556c-0.744,0-1.313-0.261-1.63-0.784l0.86-0.467
					c0.246,0.292,0.428,0.401,0.765,0.401c0.473,0,0.81-0.279,0.81-0.79v-0.09c-0.232,0.199-0.557,0.311-0.979,0.311
					c-0.847,0-1.566-0.673-1.566-1.592c0-0.901,0.72-1.568,1.566-1.568c0.423,0,0.747,0.11,0.979,0.311v-0.213h1.018V33.343z
					 M46.819,31.888c0-0.435-0.344-0.74-0.759-0.74c-0.452,0-0.777,0.305-0.777,0.74c0,0.433,0.325,0.744,0.777,0.744
					C46.476,32.631,46.819,32.321,46.819,31.888"
      />
      <path
        fill="#BA5026"
        d="M51.718,32.308h-2.386c0.086,0.388,0.351,0.627,0.7,0.627c0.239,0,0.52-0.058,0.694-0.376l0.919,0.189
					c-0.28,0.684-0.869,1.014-1.613,1.014c-0.938,0-1.716-0.692-1.716-1.721c0-1.023,0.777-1.722,1.723-1.722
					c0.921,0,1.658,0.66,1.679,1.722V32.308z M49.353,31.673h1.341c-0.097-0.35-0.356-0.511-0.654-0.511
					C49.748,31.162,49.449,31.341,49.353,31.673"
      />
      <path
        fill="#F17E21"
        d="M19.827,37.731l-1.47,0.3c-0.108-0.571-0.58-0.854-1.062-0.854c-0.498,0-0.816,0.273-0.816,0.616
					c0,0.245,0.109,0.427,0.49,0.509l1.262,0.28c1.051,0.236,1.669,0.808,1.669,1.77c0,1.297-1.152,1.967-2.504,1.967
					c-1.37,0-2.494-0.652-2.658-1.84l1.542-0.3c0.154,0.625,0.59,0.897,1.197,0.897c0.563,0,0.898-0.255,0.898-0.626
					c0-0.271-0.126-0.443-0.561-0.535l-1.271-0.272c-0.872-0.199-1.632-0.653-1.632-1.759c0-1.179,0.978-1.913,2.403-1.913
					C18.638,35.972,19.6,36.625,19.827,37.731"
      />
      <path
        fill="#F17E21"
        d="M23.899,41.983c-0.355,0.191-0.725,0.336-1.242,0.336c-1.135,0-1.815-0.616-1.815-1.814v-1.722h-0.826
					v-1.151h0.826v-1.343h1.451v1.343h1.343v1.151h-1.343v1.577c0,0.47,0.228,0.662,0.609,0.662c0.199,0,0.47-0.072,0.661-0.181
					L23.899,41.983z"
      />
      <path
        fill="#F17E21"
        d="M28.969,42.184h-1.452V41.82c-0.354,0.319-0.825,0.499-1.415,0.499c-1.206,0-2.222-0.97-2.222-2.412
					c0-1.434,1.016-2.411,2.222-2.411c0.589,0,1.061,0.18,1.415,0.498v-0.362h1.452V42.184z M27.518,39.907
					c0-0.698-0.525-1.178-1.116-1.178c-0.634,0-1.096,0.479-1.096,1.178c0,0.707,0.462,1.179,1.096,1.179
					C26.992,41.086,27.518,40.614,27.518,39.907"
      />
      <path
        fill="#F17E21"
        d="M34.528,41.729c0,1.406-1.068,2.178-2.548,2.178c-1.044,0-1.842-0.363-2.286-1.098l1.206-0.652
					c0.346,0.407,0.599,0.562,1.071,0.562c0.663,0,1.134-0.39,1.134-1.105v-0.126c-0.326,0.28-0.781,0.436-1.371,0.436
					c-1.188,0-2.195-0.945-2.195-2.232c0-1.26,1.007-2.193,2.195-2.193c0.59,0,1.044,0.153,1.371,0.435v-0.3h1.423V41.729z
					 M33.105,39.689c0-0.606-0.48-1.033-1.061-1.033c-0.636,0-1.089,0.427-1.089,1.033c0,0.607,0.453,1.044,1.089,1.044
					C32.625,40.733,33.105,40.297,33.105,39.689"
      />
      <path
        fill="#F17E21"
        d="M39.871,40.28h-3.338c0.118,0.543,0.489,0.877,0.979,0.877c0.335,0,0.727-0.08,0.972-0.524l1.286,0.263
					c-0.39,0.961-1.215,1.424-2.258,1.424c-1.315,0-2.403-0.97-2.403-2.411c0-1.434,1.088-2.412,2.412-2.412
					c1.289,0,2.323,0.924,2.35,2.412V40.28z M36.561,39.391h1.877c-0.136-0.489-0.498-0.717-0.916-0.717
					C37.114,38.674,36.696,38.928,36.561,39.391"
      />
      <path
        fill="#F17E21"
        d="M46.565,41.067v1.115H45.16v-1.115h-3.158v-1.343l2.712-3.615h1.852v3.761h0.733v1.197H46.565z
					 M45.16,37.549l-1.643,2.321h1.643V37.549z"
      />
      <path
        fill="#BA5026"
        d="M28.181,22.91c1.396-0.537,2.584-1.493,3.408-2.715h2.59h0.022v-1.428c0-0.081,0.096-0.125,0.157-0.074
					l3.387,2.785c0.048,0.038,0.048,0.11,0,0.148l-3.387,2.785c-0.062,0.051-0.157,0.007-0.157-0.075V22.91h-0.022H28.181z"
      />
      <polygon
        fill="#BA5026"
        points="29.141,13.559 29.173,13.559 29.141,13.596 				"
      />
      <path
        fill="#BA5026"
        d="M30.942,11.587l1.623,1.972l0.255,0.31l0.907,1.104c0.053,0.063,0.008,0.158-0.072,0.158H32.82h-0.482
					v1.035c0,3.725-3.02,6.744-6.747,6.744h-2.03h-0.414c-0.054,0-0.097-0.043-0.097-0.097V22.4v-1.697v-0.412
					c0-0.054,0.042-0.097,0.097-0.097h0.414h2.03c2.226,0,4.031-1.805,4.031-4.029v-1.035h-0.482h-1.059
					c-0.08,0-0.125-0.095-0.075-0.158l1.134-1.376l0.032-0.037l1.62-1.972C30.832,11.54,30.905,11.54,30.942,11.587"
      />
      <path
        fill="#4F86C6"
        d="M30.861,59.533c-2.638,0-4.774-2.134-4.774-4.771s2.136-4.772,4.774-4.772
					c2.639,0,4.775,2.136,4.775,4.772S33.5,59.533,30.861,59.533"
      />
      <path
        fill="#FFFFFF"
        d="M30.861,49.99c2.639,0,4.775,2.136,4.775,4.772s-2.136,4.771-4.775,4.771
					c-2.638,0-4.774-2.134-4.774-4.771S28.223,49.99,30.861,49.99 M30.861,49.579c-2.859,0-5.185,2.325-5.185,5.184
					c0,2.857,2.326,5.184,5.185,5.184c2.862,0,5.187-2.326,5.187-5.184C36.048,51.904,33.723,49.579,30.861,49.579"
      />
      <path
        fill="#FFFFFF"
        d="M33.356,52.817c-0.239,0-0.466,0.108-0.621,0.251l-0.013-0.012c0.073-0.121,0.167-0.347,0.167-0.716
					c0-0.884-0.883-1.552-2.029-1.552c-1.145,0-2.042,0.668-2.042,1.552c0,0.369,0.122,0.595,0.181,0.716l-0.012,0.012
					c-0.156-0.143-0.381-0.251-0.62-0.251c-0.729,0-1.458,0.703-1.458,2.017c0,1.288,0.717,2.016,1.493,2.016
					c0.717,0,1.219-0.395,1.67-0.847c-0.07-0.107-0.179-0.216-0.297-0.216c-0.12,0-0.251,0.086-0.478,0.263
					c-0.298,0.229-0.561,0.358-0.908,0.358c-0.608,0-0.87-0.681-0.87-1.574c0-0.954,0.251-1.587,0.896-1.587
					c0.286,0,0.476,0.083,0.667,0.263c0.287,0.263,0.381,0.332,0.514,0.332c0.095,0,0.155-0.059,0.179-0.093
					c-0.215-0.216-0.526-0.668-0.526-1.253c0-0.668,0.562-1.135,1.611-1.135c1.051,0,1.612,0.467,1.612,1.135
					c0,0.585-0.31,1.037-0.525,1.253c0.023,0.034,0.084,0.093,0.179,0.093c0.133,0,0.227-0.069,0.513-0.332
					c0.179-0.18,0.382-0.263,0.669-0.263c0.644,0,0.895,0.633,0.895,1.587c0,0.894-0.264,1.574-0.871,1.574
					c-0.345,0-0.621-0.13-0.906-0.358c-0.228-0.177-0.36-0.263-0.478-0.263c-0.12,0-0.225,0.108-0.3,0.216
					c0.443,0.452,0.957,0.847,1.673,0.847c0.776,0,1.492-0.728,1.492-2.016C34.813,53.521,34.083,52.817,33.356,52.817"
      />
      <path
        fill="#FFFFFF"
        d="M31.662,52.795h-0.538c-0.036,0-0.058-0.025-0.07-0.048l-0.167-0.514c-0.012-0.036-0.025-0.036-0.037,0
					l-0.167,0.514c-0.012,0.035-0.034,0.048-0.061,0.048h-0.537c-0.023,0-0.035,0.011-0.012,0.022l0.443,0.322
					c0.023,0.023,0.023,0.061,0.011,0.082l-0.167,0.502c-0.011,0.024-0.011,0.049,0.024,0.024l0.443-0.309
					c0.034-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.024,0.035,0.012,0.023-0.013l-0.167-0.514
					c-0.012-0.034,0-0.069,0.023-0.082l0.441-0.322C31.696,52.806,31.686,52.795,31.662,52.795"
      />
      <path
        fill="#FFFFFF"
        d="M31.256,56.325c-0.226-0.572-0.406-0.967-0.406-1.444c0-0.202,0.059-0.381,0.095-0.476
					c0.048-0.097,0.061-0.157,0.012-0.157c-0.071,0-0.227,0.168-0.323,0.312c-0.071,0.119-0.154,0.347-0.154,0.523
					c0,0.336,0.107,0.692,0.179,0.907c0.132,0.37,0.25,0.799,0.25,1.206c0,0.646-0.286,1.084-0.799,1.478
					c0.048,0.036,0.167,0.096,0.311,0.096c0.5,0,1.097-0.561,1.097-1.323C31.517,56.992,31.386,56.683,31.256,56.325"
      />
    </g>
  </g>
);

const MakeChangeStage3 = () => (
  <g id="Stage_3_9_">
    <polygon
      fill="#FFE4C9"
      points="57.578,19.702 57.578,50.547 30.861,65.968 4.145,50.547 4.145,19.702 30.861,4.279 			"
    />
    <polygon
      fill="#FAB582"
      points="57.578,50.547 30.864,65.968 30.864,35.125 			"
    />
    <polygon
      fill="#FAB582"
      points="57.578,19.704 57.578,50.547 30.864,35.125 			"
    />
    <polygon
      fill="#FAB582"
      points="30.862,4.275 57.577,19.696 30.862,35.118 			"
    />
    <g>
      <path
        fill="#F68E1E"
        d="M30.862,4.592l26.444,15.264v30.532L30.862,65.652L4.418,50.388V19.856L30.862,4.592 M30.862,0.017
        c-0.574,0-1.146,0.149-1.659,0.444L1.669,16.355c-1.027,0.593-1.659,1.687-1.659,2.872v31.789c0,1.186,0.631,2.279,1.659,2.872
        l27.534,15.895c0.513,0.296,1.085,0.444,1.659,0.444c0.572,0,1.145-0.148,1.658-0.444l27.535-15.895
        c1.027-0.593,1.658-1.687,1.658-2.872V19.228c0-1.186-0.631-2.279-1.658-2.872L32.52,0.461
        C32.007,0.166,31.435,0.017,30.862,0.017"
      />
      <polygon
        fill="#BA5026"
        points="15.224,33.667 14.188,33.667 14.188,31.355 12.97,33.667 12.478,33.667 11.253,31.342
        11.253,33.667 10.217,33.667 10.217,29.328 11.267,29.328 12.724,32.081 14.182,29.328 15.224,29.328 				"
      />
      <path
        fill="#BA5026"
        d="M19.332,33.667h-1.036v-0.26c-0.253,0.227-0.59,0.355-1.012,0.355c-0.861,0-1.586-0.691-1.586-1.722
        c0-1.023,0.725-1.722,1.586-1.722c0.421,0,0.759,0.129,1.012,0.356v-0.259h1.036V33.667z M18.296,32.041
        c0-0.498-0.377-0.84-0.797-0.84c-0.454,0-0.784,0.342-0.784,0.84c0,0.505,0.33,0.842,0.784,0.842
        C17.919,32.882,18.296,32.546,18.296,32.041"
      />
      <polygon
        fill="#BA5026"
        points="22.3,33.667 21.243,32.495 21.056,32.495 21.056,33.667 20.02,33.667 20.02,29.004
        21.056,29.004 21.056,31.522 21.197,31.522 22.228,30.417 23.479,30.417 22.053,31.964 23.627,33.667 				"
      />
      <path
        fill="#BA5026"
        d="M26.892,32.308h-2.384c0.085,0.388,0.351,0.627,0.7,0.627c0.239,0,0.519-0.058,0.692-0.375l0.92,0.188
        c-0.279,0.686-0.868,1.015-1.613,1.015c-0.939,0-1.715-0.693-1.715-1.722c0-1.021,0.776-1.722,1.723-1.722
        c0.918,0,1.658,0.66,1.677,1.722V32.308z M24.528,31.672h1.34c-0.098-0.35-0.356-0.511-0.653-0.511
        C24.923,31.162,24.624,31.34,24.528,31.672"
      />
      <path
        fill="#BA5026"
        d="M31.071,32.287l0.952,0.208c-0.162,0.771-0.849,1.27-1.659,1.27c-0.972,0-1.774-0.693-1.774-1.724
        c0-1.021,0.803-1.722,1.774-1.722c0.791,0,1.476,0.485,1.659,1.25l-0.973,0.234c-0.089-0.396-0.356-0.578-0.687-0.578
        c-0.466,0-0.777,0.344-0.777,0.815c0,0.48,0.311,0.817,0.777,0.817C30.694,32.858,30.961,32.683,31.071,32.287"
      />
      <path
        fill="#BA5026"
        d="M35.793,31.672v1.995h-1.03v-1.774c0-0.383-0.226-0.642-0.563-0.642c-0.402,0-0.668,0.28-0.668,0.907
        v1.509h-1.036v-4.662h1.036v1.721c0.253-0.257,0.589-0.407,1.005-0.407C35.288,30.319,35.793,30.856,35.793,31.672"
      />
      <path
        fill="#BA5026"
        d="M39.861,33.667h-1.036v-0.26c-0.251,0.227-0.589,0.355-1.01,0.355c-0.862,0-1.586-0.691-1.586-1.722
        c0-1.023,0.724-1.722,1.586-1.722c0.421,0,0.759,0.129,1.01,0.356v-0.259h1.036V33.667z M38.825,32.041
        c0-0.498-0.376-0.84-0.796-0.84c-0.454,0-0.785,0.342-0.785,0.84c0,0.505,0.331,0.842,0.785,0.842
        C38.449,32.882,38.825,32.546,38.825,32.041"
      />
      <path
        fill="#BA5026"
        d="M43.846,31.672v1.995h-1.03v-1.774c0-0.383-0.226-0.642-0.558-0.642c-0.407,0-0.672,0.28-0.672,0.907
        v1.509h-1.037v-3.25h1.037v0.309c0.246-0.257,0.588-0.407,0.997-0.407C43.341,30.319,43.846,30.856,43.846,31.672"
      />
      <path
        fill="#BA5026"
        d="M47.836,33.342c0,1.005-0.765,1.556-1.819,1.556c-0.746,0-1.315-0.26-1.633-0.784l0.86-0.466
        c0.249,0.291,0.429,0.402,0.766,0.402c0.474,0,0.811-0.28,0.811-0.792v-0.089c-0.234,0.199-0.558,0.311-0.979,0.311
        c-0.849,0-1.567-0.674-1.567-1.593c0-0.901,0.719-1.568,1.567-1.568c0.422,0,0.745,0.11,0.979,0.311v-0.213h1.016V33.342z
         M46.82,31.887c0-0.435-0.344-0.74-0.76-0.74c-0.452,0-0.777,0.305-0.777,0.74c0,0.434,0.325,0.744,0.777,0.744
        C46.477,32.631,46.82,32.32,46.82,31.887"
      />
      <path
        fill="#BA5026"
        d="M51.718,32.308h-2.386c0.085,0.388,0.351,0.627,0.7,0.627c0.24,0,0.519-0.058,0.693-0.375l0.92,0.188
        c-0.28,0.686-0.868,1.015-1.613,1.015c-0.938,0-1.715-0.693-1.715-1.722c0-1.021,0.776-1.722,1.722-1.722
        c0.921,0,1.658,0.66,1.679,1.722V32.308z M49.353,31.672h1.341c-0.098-0.35-0.356-0.511-0.654-0.511
        C49.748,31.162,49.449,31.34,49.353,31.672"
      />
      <path
        fill="#F17E21"
        d="M20.05,37.731l-1.469,0.3c-0.11-0.57-0.583-0.853-1.063-0.853c-0.5,0-0.816,0.272-0.816,0.616
        c0,0.245,0.107,0.427,0.49,0.509l1.26,0.28c1.053,0.235,1.67,0.808,1.67,1.77c0,1.297-1.153,1.966-2.505,1.966
        c-1.368,0-2.493-0.651-2.658-1.84l1.543-0.3c0.154,0.626,0.591,0.898,1.197,0.898c0.561,0,0.898-0.255,0.898-0.626
        c0-0.272-0.127-0.444-0.562-0.535l-1.27-0.271c-0.871-0.2-1.633-0.652-1.633-1.76c0-1.179,0.979-1.913,2.403-1.913
        C18.861,35.973,19.823,36.626,20.05,37.731"
      />
      <path
        fill="#F17E21"
        d="M24.121,41.985c-0.354,0.19-0.725,0.334-1.241,0.334c-1.135,0-1.814-0.615-1.814-1.813v-1.724H20.24
        v-1.151h0.826V36.29h1.452v1.342h1.341v1.151h-1.341v1.578c0,0.472,0.227,0.661,0.608,0.661c0.198,0,0.47-0.07,0.662-0.18
        L24.121,41.985z"
      />
      <path
        fill="#F17E21"
        d="M29.192,42.186h-1.452V41.82c-0.354,0.319-0.826,0.499-1.415,0.499c-1.208,0-2.223-0.969-2.223-2.412
        c0-1.434,1.015-2.411,2.223-2.411c0.589,0,1.061,0.181,1.415,0.5v-0.364h1.452V42.186z M27.741,39.907
        c0-0.697-0.527-1.177-1.116-1.177c-0.636,0-1.097,0.479-1.097,1.177c0,0.707,0.46,1.181,1.097,1.181
        C27.214,41.088,27.741,40.614,27.741,39.907"
      />
      <path
        fill="#F17E21"
        d="M34.752,41.73c0,1.406-1.071,2.177-2.548,2.177c-1.044,0-1.842-0.362-2.287-1.096l1.207-0.653
        c0.344,0.407,0.598,0.562,1.07,0.562c0.664,0,1.134-0.391,1.134-1.106v-0.126c-0.326,0.28-0.78,0.436-1.369,0.436
        c-1.189,0-2.196-0.943-2.196-2.232c0-1.26,1.007-2.194,2.196-2.194c0.589,0,1.043,0.154,1.369,0.435v-0.3h1.424V41.73z
         M33.328,39.69c0-0.606-0.482-1.033-1.062-1.033c-0.635,0-1.088,0.427-1.088,1.033c0,0.608,0.453,1.044,1.088,1.044
        C32.846,40.734,33.328,40.299,33.328,39.69"
      />
      <path
        fill="#F17E21"
        d="M40.094,40.28h-3.338c0.118,0.544,0.49,0.879,0.979,0.879c0.336,0,0.726-0.081,0.971-0.525l1.287,0.263
        c-0.39,0.961-1.215,1.423-2.258,1.423c-1.315,0-2.404-0.969-2.404-2.41c0-1.434,1.089-2.413,2.413-2.413
        c1.289,0,2.322,0.925,2.35,2.413V40.28z M36.782,39.392h1.879c-0.137-0.49-0.501-0.718-0.917-0.718
        C37.336,38.674,36.918,38.928,36.782,39.392"
      />
      <path
        fill="#F17E21"
        d="M46.969,40.361c0,1.152-0.934,1.958-2.268,1.958c-1.279,0-2.213-0.742-2.35-1.922l1.416-0.262
        c0.079,0.599,0.406,0.942,0.941,0.942c0.509,0,0.871-0.291,0.871-0.734c0-0.399-0.3-0.636-0.823-0.636h-0.817V38.52h0.752
        c0.518,0,0.816-0.226,0.816-0.615c0-0.418-0.316-0.698-0.807-0.698s-0.854,0.299-0.908,0.979l-1.368-0.263
        c0.136-1.207,1.034-1.95,2.267-1.95c1.297,0,2.178,0.798,2.178,1.913c0,0.554-0.236,0.979-0.654,1.225
        C46.696,39.354,46.969,39.791,46.969,40.361"
      />
      <path
        fill="#BA5026"
        d="M28.181,22.907c1.395-0.536,2.583-1.492,3.407-2.715h2.589h0.023v-1.428
        c0-0.082,0.094-0.125,0.157-0.074l3.387,2.786c0.047,0.038,0.047,0.109,0,0.148l-3.387,2.786
        c-0.063,0.051-0.157,0.007-0.157-0.074v-1.428h-0.023H28.181z"
      />
      <polygon
        fill="#BA5026"
        points="29.142,13.555 29.173,13.555 29.142,13.592 				"
      />
      <path
        fill="#BA5026"
        d="M30.943,11.582l1.622,1.973l0.254,0.31l0.91,1.104c0.051,0.063,0.007,0.158-0.076,0.158H32.82H32.34
        v1.035c0,3.726-3.021,6.745-6.75,6.745h-2.028h-0.413c-0.055,0-0.098-0.042-0.098-0.096v-0.413v-1.697v-0.413
        c0-0.053,0.043-0.096,0.098-0.096h0.413h2.028c2.228,0,4.032-1.805,4.032-4.03v-1.035h-0.481h-1.06
        c-0.081,0-0.125-0.095-0.073-0.158l1.133-1.377l0.03-0.037l1.622-1.973C30.833,11.535,30.904,11.535,30.943,11.582"
      />
      <path
        fill="#4F86C6"
        d="M30.862,59.539c-2.639,0-4.776-2.137-4.776-4.772c0-2.638,2.137-4.773,4.776-4.773
        c2.638,0,4.774,2.136,4.774,4.773C35.637,57.402,33.5,59.539,30.862,59.539"
      />
      <path
        fill="#FFFFFF"
        d="M30.862,49.993c2.638,0,4.774,2.136,4.774,4.773c0,2.636-2.137,4.772-4.774,4.772
        c-2.639,0-4.776-2.137-4.776-4.772C26.086,52.129,28.223,49.993,30.862,49.993 M30.862,49.582c-2.86,0-5.187,2.326-5.187,5.185
        s2.326,5.184,5.187,5.184c2.859,0,5.186-2.325,5.186-5.184S33.722,49.582,30.862,49.582"
      />
      <path
        fill="#FFFFFF"
        d="M33.356,52.821c-0.239,0-0.466,0.107-0.621,0.25l-0.014-0.011c0.073-0.121,0.168-0.347,0.168-0.717
        c0-0.883-0.883-1.552-2.029-1.552c-1.145,0-2.04,0.669-2.04,1.552c0,0.37,0.12,0.596,0.179,0.717l-0.012,0.011
        c-0.154-0.143-0.381-0.25-0.62-0.25c-0.729,0-1.458,0.703-1.458,2.016c0,1.29,0.718,2.018,1.493,2.018
        c0.717,0,1.219-0.395,1.67-0.848c-0.07-0.107-0.179-0.216-0.297-0.216c-0.12,0-0.252,0.086-0.478,0.263
        c-0.298,0.229-0.562,0.359-0.908,0.359c-0.608,0-0.869-0.681-0.869-1.576c0-0.953,0.25-1.585,0.896-1.585
        c0.286,0,0.476,0.082,0.667,0.261c0.287,0.263,0.381,0.334,0.514,0.334c0.095,0,0.155-0.06,0.179-0.094
        c-0.214-0.216-0.524-0.669-0.524-1.253c0-0.67,0.56-1.135,1.609-1.135c1.051,0,1.612,0.465,1.612,1.135
        c0,0.584-0.311,1.037-0.525,1.253c0.023,0.034,0.084,0.094,0.179,0.094c0.132,0,0.227-0.071,0.514-0.334
        c0.179-0.179,0.382-0.261,0.668-0.261c0.646,0,0.895,0.632,0.895,1.585c0,0.896-0.264,1.576-0.87,1.576
        c-0.346,0-0.622-0.131-0.908-0.359c-0.227-0.177-0.359-0.263-0.478-0.263c-0.12,0-0.226,0.108-0.299,0.216
        c0.442,0.453,0.955,0.848,1.67,0.848c0.777,0,1.493-0.728,1.493-2.018C34.813,53.524,34.084,52.821,33.356,52.821"
      />
      <path
        fill="#FFFFFF"
        d="M31.662,52.798h-0.538c-0.036,0-0.06-0.024-0.071-0.049l-0.169-0.512c-0.011-0.036-0.022-0.036-0.034,0
        l-0.167,0.512c-0.014,0.036-0.038,0.049-0.061,0.049h-0.537c-0.025,0-0.037,0.012-0.014,0.023l0.443,0.322
        c0.023,0.023,0.023,0.061,0.011,0.082l-0.165,0.502c-0.013,0.023-0.013,0.048,0.023,0.023l0.441-0.308
        c0.036-0.026,0.058-0.026,0.096,0l0.429,0.308c0.024,0.024,0.035,0.013,0.024-0.013l-0.168-0.513
        c-0.011-0.034,0-0.07,0.025-0.082l0.44-0.322C31.698,52.81,31.684,52.798,31.662,52.798"
      />
      <path
        fill="#FFFFFF"
        d="M31.256,56.329c-0.227-0.572-0.406-0.968-0.406-1.442c0-0.204,0.059-0.383,0.095-0.478
        c0.048-0.096,0.061-0.157,0.011-0.157c-0.07,0-0.225,0.168-0.322,0.311c-0.071,0.12-0.154,0.347-0.154,0.524
        c0,0.336,0.107,0.693,0.179,0.908c0.132,0.37,0.25,0.8,0.25,1.206c0,0.646-0.286,1.084-0.799,1.478
        c0.048,0.037,0.167,0.096,0.311,0.096c0.499,0,1.097-0.56,1.097-1.325C31.518,56.996,31.386,56.687,31.256,56.329"
      />
    </g>
  </g>
);
// #endregion

// #startregion Influence Groups
const InfluenceStage5 = () => (
  <g id="Stage_5_9_">
    <polygon
      fill="#FFE4C9"
      points="57.578,19.705 57.578,50.543 30.857,65.961 4.135,50.543 4.135,19.705 30.857,4.286 			"
    />
    <polygon
      fill="#FAB582"
      points="57.578,50.543 30.859,65.961 30.859,35.125 			"
    />
    <polygon
      fill="#FAB582"
      points="57.578,19.707 57.578,50.543 30.859,35.125 			"
    />
    <polygon fill="#FAB582" points="30.857,4.281 57.576,19.7 30.857,35.118 			" />
    <polygon
      fill="#FAB582"
      points="30.854,65.963 4.135,50.544 30.854,35.126 			"
    />
    <polygon fill="#FAB582" points="30.857,35.125 4.138,50.543 4.138,19.707 			" />
    <g>
      <path
        fill="#F68E1E"
        d="M30.857,4.598l26.447,15.262v30.526L30.857,65.646L4.409,50.386V19.859L30.857,4.598 M30.857,0.023
					c-0.574,0-1.146,0.149-1.66,0.446L1.659,16.359C0.631,16.952,0,18.046,0,19.23v31.783c0,1.186,0.631,2.279,1.659,2.872
					l27.539,15.89c0.513,0.297,1.085,0.445,1.66,0.445c0.572,0,1.145-0.148,1.658-0.445l27.539-15.89
					c1.027-0.593,1.659-1.687,1.659-2.872V19.23c0-1.184-0.632-2.278-1.659-2.871L32.515,0.469
					C32.002,0.172,31.429,0.023,30.857,0.023"
      />
      <rect x="16.822" y="29.328" fill="#BA5026" width="1.068" height="4.338" />
      <path
        fill="#BA5026"
        d="M21.874,31.673v1.994h-1.03v-1.774c0-0.382-0.227-0.641-0.557-0.641c-0.409,0-0.673,0.279-0.673,0.906
					v1.509h-1.037v-3.25h1.037v0.31c0.247-0.257,0.589-0.407,0.998-0.407C21.368,30.319,21.874,30.857,21.874,31.673"
      />
      <path
        fill="#BA5026"
        d="M23.735,30.211v0.205h0.893v0.824h-0.893v2.427h-1.031V31.24h-0.466v-0.824h0.466v-0.251
					c0-0.763,0.524-1.25,1.309-1.25c0.22,0,0.433,0.045,0.641,0.135l-0.22,0.803c-0.098-0.031-0.201-0.052-0.278-0.052
					C23.91,29.801,23.735,29.951,23.735,30.211 M25.996,33.667h-1.038v-4.662h1.038V33.667z"
      />
      <path
        fill="#BA5026"
        d="M29.934,33.667h-1.035v-0.311c-0.248,0.259-0.59,0.406-0.999,0.406c-0.757,0-1.262-0.536-1.262-1.351
					v-1.995h1.029v1.774c0,0.381,0.227,0.641,0.558,0.641c0.407,0,0.674-0.277,0.674-0.906v-1.509h1.035V33.667z"
      />
      <path
        fill="#BA5026"
        d="M33.81,32.308h-2.384c0.084,0.388,0.352,0.627,0.699,0.627c0.242,0,0.52-0.06,0.694-0.376l0.918,0.189
					c-0.277,0.684-0.866,1.014-1.612,1.014c-0.938,0-1.716-0.692-1.716-1.721c0-1.022,0.778-1.722,1.723-1.722
					c0.92,0,1.659,0.66,1.678,1.722V32.308z M31.445,31.673h1.341c-0.098-0.35-0.356-0.51-0.654-0.51
					C31.839,31.163,31.543,31.341,31.445,31.673"
      />
      <path
        fill="#BA5026"
        d="M37.58,31.673v1.994h-1.029v-1.774c0-0.382-0.226-0.641-0.558-0.641c-0.408,0-0.673,0.279-0.673,0.906
					v1.509h-1.037v-3.25h1.037v0.31c0.246-0.257,0.588-0.407,0.997-0.407C37.073,30.319,37.58,30.857,37.58,31.673"
      />
      <path
        fill="#BA5026"
        d="M40.489,32.288l0.952,0.208c-0.161,0.771-0.848,1.27-1.657,1.27c-0.973,0-1.774-0.693-1.774-1.723
					c0-1.022,0.802-1.722,1.774-1.722c0.789,0,1.478,0.486,1.657,1.25l-0.973,0.234c-0.09-0.396-0.355-0.577-0.685-0.577
					c-0.468,0-0.776,0.343-0.776,0.815c0,0.48,0.309,0.817,0.776,0.817C40.113,32.858,40.379,32.684,40.489,32.288"
      />
      <path
        fill="#BA5026"
        d="M45.104,32.308h-2.386c0.086,0.388,0.351,0.627,0.7,0.627c0.239,0,0.519-0.06,0.693-0.376l0.919,0.189
					c-0.279,0.684-0.868,1.014-1.612,1.014c-0.94,0-1.716-0.692-1.716-1.721c0-1.022,0.775-1.722,1.723-1.722
					c0.919,0,1.658,0.66,1.679,1.722V32.308z M42.739,31.673h1.341c-0.097-0.35-0.357-0.51-0.654-0.51
					C43.134,31.163,42.836,31.341,42.739,31.673"
      />
      <path
        fill="#F17E21"
        d="M20.047,37.731l-1.47,0.298c-0.108-0.57-0.581-0.852-1.062-0.852c-0.498,0-0.815,0.273-0.815,0.618
					c0,0.243,0.108,0.425,0.49,0.507l1.26,0.28c1.053,0.237,1.671,0.808,1.671,1.77c0,1.297-1.153,1.967-2.504,1.967
					c-1.369,0-2.495-0.652-2.658-1.84l1.542-0.299c0.154,0.626,0.59,0.896,1.197,0.896c0.562,0,0.898-0.255,0.898-0.626
					c0-0.271-0.126-0.442-0.563-0.535l-1.27-0.272c-0.872-0.199-1.633-0.651-1.633-1.759c0-1.179,0.979-1.914,2.403-1.914
					C18.858,35.971,19.822,36.625,20.047,37.731"
      />
      <path
        fill="#F17E21"
        d="M24.12,41.983c-0.354,0.191-0.725,0.336-1.243,0.336c-1.135,0-1.814-0.616-1.814-1.814v-1.722h-0.826
					v-1.151h0.826v-1.343h1.452v1.343h1.343v1.151h-1.343v1.577c0,0.47,0.227,0.662,0.608,0.662c0.2,0,0.471-0.073,0.662-0.181
					L24.12,41.983z"
      />
      <path
        fill="#F17E21"
        d="M29.191,42.184h-1.452V41.82c-0.353,0.316-0.826,0.499-1.415,0.499c-1.206,0-2.222-0.97-2.222-2.412
					c0-1.434,1.016-2.411,2.222-2.411c0.589,0,1.062,0.18,1.415,0.498v-0.362h1.452V42.184z M27.739,39.907
					c0-0.698-0.524-1.178-1.115-1.178c-0.636,0-1.098,0.479-1.098,1.178c0,0.707,0.462,1.179,1.098,1.179
					C27.215,41.086,27.739,40.614,27.739,39.907"
      />
      <path
        fill="#F17E21"
        d="M34.752,41.729c0,1.407-1.07,2.178-2.549,2.178c-1.044,0-1.842-0.363-2.287-1.097l1.207-0.653
					c0.345,0.407,0.6,0.562,1.07,0.562c0.664,0,1.135-0.39,1.135-1.105v-0.126c-0.327,0.28-0.782,0.434-1.37,0.434
					c-1.189,0-2.196-0.942-2.196-2.23c0-1.261,1.007-2.193,2.196-2.193c0.588,0,1.043,0.153,1.37,0.435v-0.3h1.424V41.729z
					 M33.328,39.689c0-0.607-0.481-1.034-1.063-1.034c-0.636,0-1.088,0.427-1.088,1.034s0.452,1.043,1.088,1.043
					C32.847,40.732,33.328,40.297,33.328,39.689"
      />
      <path
        fill="#F17E21"
        d="M40.094,40.28h-3.337c0.118,0.543,0.489,0.877,0.979,0.877c0.335,0,0.727-0.08,0.971-0.524l1.288,0.263
					c-0.391,0.961-1.216,1.424-2.259,1.424c-1.313,0-2.404-0.97-2.404-2.411c0-1.434,1.091-2.412,2.413-2.412
					c1.288,0,2.322,0.926,2.349,2.412V40.28z M36.783,39.391h1.877c-0.134-0.49-0.497-0.717-0.915-0.717
					C37.337,38.674,36.92,38.928,36.783,39.391"
      />
      <path
        fill="#F17E21"
        d="M44.849,38.238c1.343,0,2.141,0.771,2.141,1.959c0,1.251-0.908,2.122-2.305,2.122
					c-1.251,0-2.313-0.717-2.349-2.085l1.414-0.28c0.009,0.787,0.463,1.096,0.961,1.096c0.518,0,0.872-0.336,0.872-0.834
					c0-0.453-0.346-0.754-0.872-0.754h-2.086l0.109-3.354h3.991v1.297h-2.687l-0.035,0.833H44.849z"
      />
      <path
        fill="#BA5026"
        d="M27.059,25.352c0.246,0.755,1.06,1.167,1.815,0.923l0.589-0.192c0.188-0.063,0.292-0.266,0.23-0.454
					l-0.795-2.438h-2.544L27.059,25.352z"
      />
      <path
        fill="#BA5026"
        d="M26.224,16.82c-0.971,0-1.764,0.762-1.816,1.72h-0.284c-0.301,0-0.546,0.245-0.546,0.546v1.375
					c0,0.301,0.245,0.545,0.546,0.545h0.284c0.052,0.952,0.833,1.708,1.794,1.721h0.002h4.867V16.82H26.224z M26.784,18.227
					c0.125,0,0.229,0.102,0.229,0.227v2.64c0,0.125-0.103,0.228-0.229,0.228c-0.127,0-0.229-0.102-0.229-0.228v-2.64
					C26.556,18.329,26.657,18.227,26.784,18.227 M25.554,21.094v-2.64c0-0.125,0.102-0.227,0.228-0.227
					c0.127,0,0.228,0.102,0.228,0.227v2.64c0,0.125-0.101,0.228-0.228,0.228C25.656,21.321,25.554,21.219,25.554,21.094
					 M28.975,22.189c-0.2,0-0.361-0.163-0.361-0.362s0.161-0.36,0.361-0.36c0.199,0,0.36,0.161,0.36,0.36
					S29.174,22.189,28.975,22.189 M30.179,22.189c-0.199,0-0.362-0.163-0.362-0.362s0.163-0.36,0.362-0.36
					c0.198,0,0.36,0.161,0.36,0.36S30.376,22.189,30.179,22.189"
      />
      <path
        fill="#BA5026"
        d="M36.186,14.44l-4.658,2.188v6.29l4.658,2.188c0.076,0.036,0.161-0.019,0.161-0.103v-10.46
					C36.347,14.461,36.262,14.405,36.186,14.44"
      />
      <path
        fill="#BA5026"
        d="M37.261,13.856c-0.249,0-0.453,0.204-0.453,0.454v10.926c0,0.252,0.204,0.456,0.453,0.456
					c0.252,0,0.456-0.204,0.456-0.456V14.31C37.717,14.06,37.513,13.856,37.261,13.856"
      />
      <path
        fill="#4F86C6"
        d="M30.857,59.533c-2.639,0-4.776-2.136-4.776-4.771c0-2.637,2.137-4.772,4.776-4.772
					c2.639,0,4.774,2.136,4.774,4.772C35.632,57.397,33.496,59.533,30.857,59.533"
      />
      <path
        fill="#FFFFFF"
        d="M30.857,49.99c2.639,0,4.774,2.136,4.774,4.772c0,2.635-2.136,4.771-4.774,4.771
					c-2.639,0-4.776-2.136-4.776-4.771C26.081,52.126,28.218,49.99,30.857,49.99 M30.857,49.579c-2.861,0-5.188,2.324-5.188,5.184
					c0,2.857,2.327,5.183,5.188,5.183c2.858,0,5.187-2.325,5.187-5.183C36.044,51.903,33.716,49.579,30.857,49.579"
      />
      <path
        fill="#FFFFFF"
        d="M33.352,52.817c-0.239,0-0.465,0.108-0.621,0.251l-0.013-0.012c0.073-0.119,0.169-0.347,0.169-0.716
					c0-0.884-0.884-1.552-2.029-1.552s-2.042,0.668-2.042,1.552c0,0.369,0.12,0.597,0.179,0.716l-0.013,0.012
					c-0.154-0.143-0.381-0.251-0.619-0.251c-0.73,0-1.459,0.704-1.459,2.017c0,1.288,0.718,2.016,1.495,2.016
					c0.715,0,1.217-0.395,1.67-0.847c-0.072-0.107-0.181-0.216-0.299-0.216c-0.12,0-0.251,0.086-0.476,0.264
					c-0.3,0.228-0.563,0.357-0.909,0.357c-0.609,0-0.87-0.682-0.87-1.574c0-0.954,0.25-1.587,0.896-1.587
					c0.287,0,0.477,0.083,0.667,0.263c0.286,0.263,0.381,0.334,0.513,0.334c0.095,0,0.155-0.061,0.179-0.095
					c-0.215-0.216-0.524-0.668-0.524-1.253c0-0.668,0.56-1.134,1.611-1.134c1.049,0,1.611,0.466,1.611,1.134
					c0,0.585-0.311,1.037-0.524,1.253c0.022,0.034,0.082,0.095,0.178,0.095c0.132,0,0.227-0.071,0.513-0.334
					c0.179-0.18,0.383-0.263,0.669-0.263c0.646,0,0.894,0.633,0.894,1.587c0,0.893-0.261,1.574-0.869,1.574
					c-0.347,0-0.62-0.13-0.907-0.357c-0.228-0.178-0.358-0.264-0.477-0.264c-0.121,0-0.227,0.108-0.3,0.216
					c0.441,0.452,0.956,0.847,1.672,0.847c0.775,0,1.49-0.728,1.49-2.016C34.807,53.521,34.08,52.817,33.352,52.817"
      />
      <path
        fill="#FFFFFF"
        d="M31.657,52.795h-0.539c-0.036,0-0.058-0.025-0.07-0.048l-0.168-0.514c-0.012-0.036-0.023-0.036-0.036,0
					l-0.168,0.514c-0.013,0.035-0.034,0.048-0.059,0.048h-0.537c-0.024,0-0.035,0.011-0.012,0.021l0.441,0.323
					c0.023,0.023,0.023,0.061,0.011,0.084l-0.166,0.5c-0.011,0.024-0.011,0.049,0.023,0.024l0.443-0.309
					c0.036-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.024,0.035,0.012,0.023-0.012l-0.166-0.513
					c-0.011-0.036,0-0.071,0.024-0.084l0.44-0.323C31.691,52.806,31.68,52.795,31.657,52.795"
      />
      <path
        fill="#FFFFFF"
        d="M31.25,56.325c-0.225-0.573-0.406-0.967-0.406-1.443c0-0.203,0.059-0.382,0.095-0.477
					c0.048-0.097,0.06-0.157,0.011-0.157c-0.07,0-0.226,0.168-0.321,0.312c-0.073,0.119-0.155,0.347-0.155,0.523
					c0,0.336,0.107,0.692,0.179,0.907c0.132,0.372,0.25,0.8,0.25,1.206c0,0.645-0.286,1.084-0.8,1.478
					c0.048,0.036,0.167,0.096,0.311,0.096c0.5,0,1.099-0.561,1.099-1.323C31.514,56.992,31.381,56.683,31.25,56.325"
      />
    </g>
  </g>
);

const InfluenceStage4 = () => (
  <g id="Stage_4_8_">
    <polygon
      fill="#FFE4C9"
      points="57.578,19.705 57.578,50.543 30.862,65.961 4.145,50.543 4.145,19.705 30.862,4.286 			"
    />
    <polygon
      fill="#FAB582"
      points="57.577,50.543 30.863,65.961 30.863,35.125 			"
    />
    <polygon
      fill="#FAB582"
      points="57.577,19.707 57.577,50.543 30.863,35.125 			"
    />
    <polygon fill="#FAB582" points="30.862,4.281 57.577,19.7 30.862,35.119 			" />
    <polygon
      fill="#FAB582"
      points="30.861,65.963 4.146,50.544 30.861,35.126 			"
    />
    <g>
      <path
        fill="#F68E1E"
        d="M30.862,4.598l26.443,15.262v30.526L30.862,65.646L4.418,50.386V19.859L30.862,4.598 M30.862,0.023
					c-0.574,0-1.147,0.149-1.66,0.446L1.669,16.359C0.644,16.952,0.01,18.046,0.01,19.23v31.783c0,1.186,0.633,2.279,1.659,2.872
					l27.533,15.89c0.513,0.297,1.086,0.445,1.66,0.445c0.572,0,1.146-0.148,1.659-0.445l27.533-15.89
					c1.027-0.593,1.658-1.687,1.658-2.872V19.23c0-1.184-0.631-2.278-1.658-2.871L32.521,0.469
					C32.008,0.172,31.434,0.023,30.862,0.023"
      />
      <rect x="16.829" y="29.329" fill="#BA5026" width="1.068" height="4.338" />
      <path
        fill="#BA5026"
        d="M21.881,31.673v1.994h-1.03v-1.774c0-0.382-0.227-0.641-0.558-0.641c-0.407,0-0.673,0.279-0.673,0.906
					v1.509h-1.036v-3.25h1.036v0.31c0.246-0.257,0.589-0.407,0.998-0.407C21.375,30.319,21.881,30.857,21.881,31.673"
      />
      <path
        fill="#BA5026"
        d="M23.74,30.211v0.205h0.895v0.824H23.74v2.427h-1.03V31.24h-0.465v-0.824h0.465v-0.251
					c0-0.763,0.525-1.25,1.309-1.25c0.22,0,0.434,0.045,0.642,0.137l-0.221,0.801c-0.096-0.031-0.2-0.052-0.278-0.052
					C23.916,29.801,23.74,29.951,23.74,30.211 M26.001,33.667h-1.036v-4.662h1.036V33.667z"
      />
      <path
        fill="#BA5026"
        d="M29.94,33.667h-1.036v-0.311c-0.246,0.259-0.59,0.408-0.997,0.408c-0.759,0-1.263-0.538-1.263-1.353
					v-1.995h1.029v1.774c0,0.382,0.226,0.641,0.557,0.641c0.407,0,0.673-0.277,0.673-0.906v-1.509h1.036V33.667z"
      />
      <path
        fill="#BA5026"
        d="M33.814,32.308h-2.384c0.084,0.388,0.351,0.627,0.7,0.627c0.239,0,0.519-0.058,0.693-0.376l0.919,0.189
					c-0.279,0.684-0.869,1.016-1.613,1.016c-0.94,0-1.717-0.694-1.717-1.723c0-1.022,0.777-1.722,1.724-1.722
					c0.919,0,1.657,0.66,1.677,1.722V32.308z M31.451,31.673h1.34c-0.097-0.35-0.356-0.51-0.653-0.51
					C31.846,31.163,31.547,31.343,31.451,31.673"
      />
      <path
        fill="#BA5026"
        d="M37.585,31.673v1.994h-1.03v-1.774c0-0.382-0.227-0.641-0.557-0.641c-0.409,0-0.673,0.279-0.673,0.906
					v1.509h-1.038v-3.25h1.038v0.31c0.245-0.257,0.588-0.407,0.996-0.407C37.079,30.319,37.585,30.857,37.585,31.673"
      />
      <path
        fill="#BA5026"
        d="M40.494,32.288l0.951,0.208c-0.161,0.771-0.848,1.27-1.657,1.27c-0.972,0-1.774-0.693-1.774-1.722
					c0-1.023,0.803-1.721,1.774-1.721c0.789,0,1.475,0.484,1.657,1.248l-0.97,0.234c-0.092-0.396-0.358-0.577-0.688-0.577
					c-0.467,0-0.776,0.343-0.776,0.816c0,0.479,0.31,0.816,0.776,0.816C40.117,32.858,40.384,32.684,40.494,32.288"
      />
      <path
        fill="#BA5026"
        d="M45.107,32.308h-2.386c0.085,0.388,0.351,0.627,0.702,0.627c0.238,0,0.517-0.058,0.691-0.376
					l0.921,0.189c-0.28,0.684-0.87,1.016-1.612,1.016c-0.94,0-1.718-0.694-1.718-1.723c0-1.022,0.777-1.722,1.723-1.722
					c0.92,0,1.658,0.66,1.679,1.722V32.308z M42.742,31.673h1.341c-0.097-0.35-0.357-0.51-0.654-0.51
					C43.138,31.163,42.84,31.343,42.742,31.673"
      />
      <path
        fill="#F17E21"
        d="M19.828,37.731l-1.47,0.3c-0.109-0.572-0.581-0.854-1.063-0.854c-0.498,0-0.815,0.273-0.815,0.618
					c0,0.243,0.108,0.425,0.49,0.507l1.261,0.28c1.051,0.236,1.67,0.808,1.67,1.77c0,1.297-1.152,1.968-2.504,1.968
					c-1.37,0-2.494-0.653-2.658-1.841l1.542-0.299c0.154,0.624,0.59,0.896,1.198,0.896c0.562,0,0.898-0.255,0.898-0.626
					c0-0.271-0.126-0.443-0.562-0.534l-1.271-0.273c-0.872-0.199-1.633-0.651-1.633-1.759c0-1.179,0.979-1.913,2.404-1.913
					C18.639,35.972,19.6,36.625,19.828,37.731"
      />
      <path
        fill="#F17E21"
        d="M23.899,41.983c-0.354,0.191-0.725,0.337-1.242,0.337c-1.134,0-1.814-0.617-1.814-1.813v-1.724h-0.825
					v-1.152h0.825v-1.342h1.451v1.342h1.342v1.152h-1.342v1.577c0,0.473,0.227,0.663,0.608,0.663c0.2,0,0.472-0.073,0.662-0.182
					L23.899,41.983z"
      />
      <path
        fill="#F17E21"
        d="M28.969,42.183h-1.451V41.82c-0.353,0.319-0.825,0.499-1.414,0.499c-1.208,0-2.223-0.97-2.223-2.412
					c0-1.433,1.015-2.411,2.223-2.411c0.589,0,1.061,0.18,1.414,0.499v-0.363h1.451V42.183z M27.518,39.907
					c0-0.698-0.526-1.179-1.115-1.179c-0.635,0-1.097,0.48-1.097,1.179c0,0.708,0.462,1.18,1.097,1.18
					C26.992,41.087,27.518,40.615,27.518,39.907"
      />
      <path
        fill="#F17E21"
        d="M34.529,41.729c0,1.407-1.069,2.178-2.549,2.178c-1.043,0-1.841-0.363-2.285-1.097l1.206-0.654
					c0.344,0.409,0.598,0.564,1.07,0.564c0.664,0,1.134-0.392,1.134-1.107v-0.129c-0.326,0.283-0.78,0.437-1.37,0.437
					c-1.188,0-2.194-0.942-2.194-2.23c0-1.261,1.006-2.193,2.194-2.193c0.59,0,1.044,0.153,1.37,0.435v-0.3h1.424V41.729z
					 M33.105,39.689c0-0.607-0.481-1.033-1.061-1.033c-0.636,0-1.088,0.426-1.088,1.033s0.452,1.044,1.088,1.044
					C32.624,40.733,33.105,40.297,33.105,39.689"
      />
      <path
        fill="#F17E21"
        d="M39.871,40.28h-3.338c0.118,0.543,0.49,0.877,0.979,0.877c0.337,0,0.727-0.08,0.972-0.524l1.287,0.263
					c-0.39,0.961-1.216,1.424-2.259,1.424c-1.315,0-2.404-0.97-2.404-2.411c0-1.434,1.089-2.412,2.413-2.412
					c1.288,0,2.323,0.926,2.35,2.412V40.28z M36.562,39.391h1.876c-0.136-0.49-0.498-0.717-0.916-0.717
					C37.114,38.674,36.697,38.928,36.562,39.391"
      />
      <path
        fill="#F17E21"
        d="M46.565,41.068v1.114h-1.406v-1.114h-3.157v-1.344l2.712-3.616h1.852v3.762H47.3v1.198H46.565z
					 M45.159,37.549l-1.643,2.321h1.643V37.549z"
      />
      <path
        fill="#BA5026"
        d="M27.065,25.352c0.247,0.755,1.059,1.167,1.816,0.923l0.588-0.192c0.188-0.063,0.292-0.266,0.231-0.454
					l-0.797-2.438h-2.544L27.065,25.352z"
      />
      <path
        fill="#BA5026"
        d="M26.231,16.82c-0.972,0-1.764,0.762-1.817,1.72h-0.283c-0.302,0-0.546,0.245-0.546,0.547v1.375
					c0,0.301,0.244,0.545,0.546,0.545h0.283c0.052,0.952,0.832,1.708,1.793,1.721h0.003h4.866V16.82H26.231z M26.79,18.227
					c0.126,0,0.228,0.102,0.228,0.227v2.64c0,0.125-0.102,0.228-0.228,0.228c-0.127,0-0.228-0.102-0.228-0.228v-2.64
					C26.563,18.329,26.663,18.227,26.79,18.227 M25.56,21.094v-2.64c0-0.125,0.103-0.227,0.229-0.227
					c0.125,0,0.228,0.102,0.228,0.227v2.64c0,0.125-0.102,0.228-0.228,0.228C25.663,21.321,25.56,21.219,25.56,21.094 M28.98,22.189
					c-0.2,0-0.361-0.163-0.361-0.362s0.162-0.36,0.361-0.36c0.199,0,0.362,0.161,0.362,0.36S29.179,22.189,28.98,22.189
					 M30.183,22.189c-0.198,0-0.361-0.163-0.361-0.362s0.163-0.36,0.361-0.36c0.2,0,0.361,0.161,0.361,0.36
					S30.383,22.189,30.183,22.189"
      />
      <path
        fill="#BA5026"
        d="M36.188,14.44l-4.656,2.188v6.29l4.656,2.188c0.076,0.036,0.163-0.019,0.163-0.103v-10.46
					C36.352,14.461,36.265,14.405,36.188,14.44"
      />
      <path
        fill="#BA5026"
        d="M37.266,13.856c-0.251,0-0.455,0.204-0.455,0.454v10.926c0,0.252,0.204,0.456,0.455,0.456
					s0.455-0.204,0.455-0.456V14.31C37.721,14.06,37.517,13.856,37.266,13.856"
      />
      <path
        fill="#4F86C6"
        d="M30.861,59.533c-2.637,0-4.773-2.136-4.773-4.771c0-2.637,2.136-4.772,4.773-4.772
					c2.64,0,4.775,2.136,4.775,4.772C35.637,57.397,33.501,59.533,30.861,59.533"
      />
      <path
        fill="#FFFFFF"
        d="M30.861,49.99c2.64,0,4.775,2.136,4.775,4.772c0,2.635-2.136,4.771-4.775,4.771
					c-2.637,0-4.773-2.136-4.773-4.771C26.088,52.126,28.224,49.99,30.861,49.99 M30.861,49.579c-2.859,0-5.186,2.324-5.186,5.184
					c0,2.857,2.326,5.183,5.186,5.183c2.861,0,5.188-2.325,5.188-5.183C36.049,51.903,33.723,49.579,30.861,49.579"
      />
      <path
        fill="#FFFFFF"
        d="M33.356,52.817c-0.239,0-0.465,0.108-0.62,0.251l-0.013-0.012c0.073-0.119,0.168-0.347,0.168-0.716
					c0-0.884-0.885-1.552-2.03-1.552c-1.145,0-2.042,0.668-2.042,1.552c0,0.369,0.121,0.597,0.18,0.716l-0.013,0.012
					c-0.155-0.143-0.381-0.251-0.619-0.251c-0.729,0-1.458,0.704-1.458,2.017c0,1.288,0.716,2.016,1.493,2.016
					c0.716,0,1.219-0.395,1.67-0.847c-0.071-0.107-0.179-0.216-0.298-0.216c-0.119,0-0.251,0.086-0.478,0.264
					c-0.298,0.228-0.561,0.357-0.908,0.357c-0.608,0-0.87-0.682-0.87-1.574c0-0.954,0.25-1.587,0.896-1.587
					c0.286,0,0.476,0.083,0.668,0.263c0.286,0.263,0.381,0.334,0.512,0.334c0.096,0,0.156-0.061,0.179-0.095
					c-0.214-0.216-0.526-0.668-0.526-1.253c0-0.668,0.562-1.134,1.612-1.134c1.051,0,1.612,0.466,1.612,1.134
					c0,0.585-0.311,1.037-0.526,1.253c0.024,0.034,0.084,0.095,0.179,0.095c0.132,0,0.228-0.071,0.513-0.334
					c0.18-0.18,0.383-0.263,0.669-0.263c0.645,0,0.895,0.633,0.895,1.587c0,0.893-0.263,1.574-0.871,1.574
					c-0.345,0-0.621-0.13-0.906-0.357c-0.227-0.178-0.359-0.264-0.479-0.264s-0.226,0.108-0.299,0.216
					c0.443,0.452,0.957,0.847,1.671,0.847c0.777,0,1.493-0.728,1.493-2.016C34.813,53.521,34.084,52.817,33.356,52.817"
      />
      <path
        fill="#FFFFFF"
        d="M31.662,52.795h-0.537c-0.036,0-0.059-0.025-0.072-0.048l-0.168-0.514c-0.011-0.036-0.024-0.036-0.035,0
					l-0.167,0.514c-0.012,0.035-0.035,0.048-0.06,0.048h-0.538c-0.024,0-0.035,0.011-0.012,0.021l0.442,0.323
					c0.024,0.023,0.024,0.061,0.012,0.084l-0.167,0.5c-0.011,0.024-0.011,0.049,0.024,0.024l0.442-0.309
					c0.035-0.025,0.059-0.025,0.095,0l0.43,0.309c0.023,0.024,0.035,0.012,0.023-0.012l-0.167-0.513
					c-0.011-0.036,0-0.071,0.024-0.084l0.442-0.323C31.697,52.806,31.686,52.795,31.662,52.795"
      />
      <path
        fill="#FFFFFF"
        d="M31.256,56.325c-0.226-0.573-0.406-0.967-0.406-1.443c0-0.203,0.06-0.382,0.095-0.477
					c0.048-0.097,0.061-0.157,0.012-0.157c-0.072,0-0.226,0.168-0.323,0.312c-0.071,0.119-0.154,0.347-0.154,0.523
					c0,0.336,0.107,0.692,0.179,0.907c0.132,0.372,0.251,0.8,0.251,1.206c0,0.645-0.287,1.084-0.8,1.478
					c0.048,0.036,0.167,0.096,0.311,0.096c0.5,0,1.097-0.561,1.097-1.322C31.518,56.992,31.387,56.683,31.256,56.325"
      />
    </g>
  </g>
);

const InfluenceStage3 = () => (
  <g id="Stage_3_8_">
    <polygon
      fill="#FFE4C9"
      points="57.579,19.67 57.579,50.453 30.861,65.844 4.144,50.453 4.144,19.67 30.861,4.278 			"
    />
    <polygon
      fill="#FAB582"
      points="57.578,50.453 30.863,65.844 30.863,35.063 			"
    />
    <polygon
      fill="#FAB582"
      points="57.578,19.671 57.578,50.453 30.863,35.063 			"
    />
    <polygon
      fill="#FAB582"
      points="30.863,4.273 57.578,19.665 30.863,35.057 			"
    />
    <g>
      <path
        fill="#F68E1E"
        d="M30.862,4.673l26.443,15.215V50.32L30.862,65.535L4.418,50.32V19.889L30.862,4.673 M30.862,0.114
					c-0.574,0-1.146,0.148-1.66,0.442L1.67,16.399c-1.027,0.591-1.66,1.682-1.66,2.863v31.685c0,1.183,0.632,2.272,1.66,2.864
					l27.533,15.842c0.514,0.295,1.086,0.443,1.66,0.443c0.573,0,1.146-0.148,1.658-0.443l27.533-15.842
					c1.026-0.592,1.659-1.682,1.659-2.864V19.262c0-1.181-0.633-2.272-1.659-2.863L32.521,0.556
					C32.008,0.262,31.436,0.114,30.862,0.114"
      />
      <rect x="16.83" y="29.329" fill="#BA5026" width="1.067" height="4.325" />
      <path
        fill="#BA5026"
        d="M21.881,31.666v1.989h-1.031v-1.769c0-0.381-0.225-0.64-0.557-0.64c-0.407,0-0.673,0.279-0.673,0.904
					v1.504h-1.037v-3.24h1.037v0.308c0.246-0.257,0.589-0.406,0.997-0.406C21.376,30.317,21.881,30.852,21.881,31.666"
      />
      <path
        fill="#BA5026"
        d="M23.74,30.208v0.205h0.895v0.821H23.74v2.42h-1.029v-2.42h-0.466v-0.821h0.466v-0.251
					c0-0.761,0.524-1.246,1.308-1.246c0.22,0,0.434,0.045,0.641,0.137l-0.221,0.799c-0.096-0.031-0.2-0.052-0.279-0.052
					C23.916,29.799,23.74,29.949,23.74,30.208 M26.002,33.654h-1.037v-4.648h1.037V33.654z"
      />
      <path
        fill="#BA5026"
        d="M29.941,33.654h-1.037v-0.311c-0.247,0.259-0.588,0.407-0.998,0.407c-0.758,0-1.263-0.536-1.263-1.35
					v-1.987h1.031v1.768c0,0.381,0.225,0.639,0.556,0.639c0.408,0,0.674-0.276,0.674-0.903v-1.503h1.037V33.654z"
      />
      <path
        fill="#BA5026"
        d="M33.814,32.299h-2.384c0.085,0.387,0.35,0.625,0.7,0.625c0.239,0,0.518-0.057,0.692-0.374l0.92,0.188
					c-0.278,0.682-0.868,1.014-1.613,1.014c-0.939,0-1.715-0.691-1.715-1.718c0-1.018,0.776-1.716,1.722-1.716
					c0.919,0,1.659,0.658,1.677,1.716V32.299z M31.451,31.666h1.341c-0.097-0.348-0.356-0.508-0.654-0.508
					C31.845,31.157,31.548,31.336,31.451,31.666"
      />
      <path
        fill="#BA5026"
        d="M37.584,31.666v1.989h-1.029v-1.769c0-0.381-0.227-0.64-0.558-0.64c-0.407,0-0.672,0.279-0.672,0.904
					v1.504h-1.037v-3.24h1.037v0.308c0.245-0.257,0.589-0.406,0.996-0.406C37.081,30.317,37.584,30.852,37.584,31.666"
      />
      <path
        fill="#BA5026"
        d="M40.494,32.279l0.952,0.207c-0.163,0.771-0.85,1.266-1.657,1.266c-0.972,0-1.775-0.691-1.775-1.716
					c0-1.02,0.804-1.717,1.775-1.717c0.789,0,1.475,0.483,1.657,1.246l-0.974,0.233c-0.088-0.395-0.354-0.576-0.684-0.576
					c-0.469,0-0.779,0.343-0.779,0.814c0,0.477,0.311,0.813,0.779,0.813C40.118,32.847,40.385,32.673,40.494,32.279"
      />
      <path
        fill="#BA5026"
        d="M45.106,32.299h-2.386c0.086,0.387,0.352,0.625,0.702,0.625c0.238,0,0.518-0.057,0.693-0.374l0.92,0.188
					c-0.28,0.682-0.87,1.014-1.613,1.014c-0.938,0-1.717-0.691-1.717-1.718c0-1.018,0.778-1.716,1.723-1.716
					c0.919,0,1.659,0.658,1.678,1.716V32.299z M42.742,31.666h1.342c-0.099-0.348-0.358-0.508-0.655-0.508
					C43.137,31.157,42.84,31.336,42.742,31.666"
      />
      <path
        fill="#F17E21"
        d="M20.05,37.706l-1.47,0.297c-0.109-0.568-0.581-0.85-1.062-0.85c-0.5,0-0.816,0.271-0.816,0.617
					c0,0.241,0.108,0.424,0.489,0.505l1.261,0.281c1.053,0.232,1.669,0.805,1.669,1.762c0,1.294-1.152,1.963-2.504,1.963
					c-1.368,0-2.494-0.651-2.658-1.837l1.542-0.297c0.154,0.622,0.59,0.893,1.198,0.893c0.562,0,0.898-0.253,0.898-0.623
					c0-0.27-0.127-0.441-0.563-0.532l-1.271-0.272c-0.87-0.199-1.632-0.649-1.632-1.753c0-1.176,0.979-1.907,2.403-1.907
					C18.861,35.952,19.823,36.603,20.05,37.706"
      />
      <path
        fill="#F17E21"
        d="M24.122,41.945c-0.354,0.189-0.725,0.336-1.243,0.336c-1.134,0-1.814-0.616-1.814-1.81v-1.718H20.24
					v-1.147h0.825v-1.338h1.452v1.338h1.343v1.147h-1.343v1.573c0,0.471,0.227,0.66,0.609,0.66c0.198,0,0.471-0.073,0.662-0.181
					L24.122,41.945z"
      />
      <path
        fill="#F17E21"
        d="M29.192,42.144h-1.452v-0.361c-0.354,0.317-0.826,0.497-1.414,0.497c-1.208,0-2.223-0.968-2.223-2.403
					c0-1.428,1.016-2.405,2.223-2.405c0.588,0,1.06,0.181,1.414,0.497v-0.361h1.452V42.144z M27.741,39.876
					c0-0.697-0.526-1.177-1.116-1.177c-0.635,0-1.097,0.479-1.097,1.177c0,0.705,0.462,1.174,1.097,1.174
					C27.214,41.05,27.741,40.581,27.741,39.876"
      />
      <path
        fill="#F17E21"
        d="M34.753,41.691c0,1.402-1.07,2.171-2.549,2.171c-1.044,0-1.842-0.362-2.287-1.094l1.206-0.652
					c0.345,0.408,0.598,0.562,1.07,0.562c0.664,0,1.134-0.389,1.134-1.103v-0.128c-0.325,0.279-0.78,0.434-1.369,0.434
					c-1.188,0-2.195-0.938-2.195-2.223c0-1.256,1.007-2.188,2.195-2.188c0.589,0,1.043,0.154,1.369,0.434v-0.299h1.426V41.691z
					 M33.327,39.658c0-0.607-0.481-1.031-1.06-1.031c-0.635,0-1.088,0.424-1.088,1.031c0,0.605,0.453,1.041,1.088,1.041
					C32.846,40.699,33.327,40.264,33.327,39.658"
      />
      <path
        fill="#F17E21"
        d="M40.094,40.245h-3.338c0.118,0.544,0.489,0.877,0.979,0.877c0.336,0,0.727-0.082,0.97-0.523l1.289,0.263
					c-0.39,0.957-1.216,1.418-2.259,1.418c-1.314,0-2.404-0.968-2.404-2.403c0-1.428,1.09-2.405,2.413-2.405
					c1.288,0,2.322,0.924,2.35,2.405V40.245z M36.784,39.361h1.877c-0.136-0.491-0.499-0.718-0.917-0.718
					C37.336,38.644,36.918,38.897,36.784,39.361"
      />
      <path
        fill="#F17E21"
        d="M46.969,40.327c0,1.149-0.935,1.952-2.27,1.952c-1.277,0-2.212-0.74-2.349-1.918l1.417-0.259
					c0.079,0.597,0.406,0.94,0.942,0.94c0.507,0,0.87-0.292,0.87-0.734c0-0.396-0.299-0.633-0.824-0.633h-0.818v-1.184h0.754
					c0.517,0,0.817-0.226,0.817-0.616c0-0.415-0.317-0.695-0.81-0.695c-0.488,0-0.853,0.3-0.905,0.977l-1.369-0.262
					c0.135-1.203,1.034-1.943,2.267-1.943c1.297,0,2.178,0.795,2.178,1.907c0,0.553-0.234,0.977-0.653,1.221
					C46.696,39.325,46.969,39.756,46.969,40.327"
      />
      <path
        fill="#BA5026"
        d="M27.065,25.365c0.247,0.752,1.059,1.165,1.815,0.92l0.588-0.192c0.189-0.062,0.291-0.265,0.229-0.453
					l-0.795-2.429h-2.543L27.065,25.365z"
      />
      <path
        fill="#BA5026"
        d="M26.231,16.858c-0.972,0-1.765,0.759-1.817,1.715h-0.283c-0.302,0-0.546,0.245-0.546,0.544v1.373
					c0,0.299,0.244,0.543,0.546,0.543h0.283c0.052,0.948,0.832,1.703,1.793,1.715h0.003h4.866v-5.89H26.231z M26.79,18.259
					c0.125,0,0.228,0.104,0.228,0.228v2.632c0,0.125-0.102,0.226-0.228,0.226c-0.126,0-0.229-0.101-0.229-0.226v-2.632
					C26.562,18.363,26.664,18.259,26.79,18.259 M25.561,21.12v-2.632c0-0.125,0.102-0.228,0.228-0.228
					c0.126,0,0.227,0.104,0.227,0.228v2.632c0,0.125-0.101,0.226-0.227,0.226C25.663,21.346,25.561,21.245,25.561,21.12
					 M28.979,22.211c-0.198,0-0.361-0.162-0.361-0.361s0.163-0.358,0.361-0.358c0.2,0,0.362,0.16,0.362,0.358
					S29.179,22.211,28.979,22.211 M30.184,22.211c-0.2,0-0.362-0.162-0.362-0.361s0.162-0.358,0.362-0.358s0.361,0.16,0.361,0.358
					S30.383,22.211,30.184,22.211"
      />
      <path
        fill="#BA5026"
        d="M36.189,14.487l-4.658,2.181v6.271l4.658,2.182c0.075,0.035,0.162-0.019,0.162-0.103V14.589
					C36.352,14.507,36.265,14.451,36.189,14.487"
      />
      <path
        fill="#BA5026"
        d="M37.266,13.903c-0.25,0-0.455,0.203-0.455,0.453v10.892c0,0.252,0.205,0.455,0.455,0.455
					c0.252,0,0.455-0.203,0.455-0.455V14.356C37.721,14.106,37.518,13.903,37.266,13.903"
      />
      <path
        fill="#4F86C6"
        d="M30.861,59.44c-2.637,0-4.773-2.129-4.773-4.756c0-2.628,2.137-4.757,4.773-4.757
					c2.64,0,4.775,2.129,4.775,4.757C35.636,57.312,33.501,59.44,30.861,59.44"
      />
      <path
        fill="#FFFFFF"
        d="M30.861,49.928c2.64,0,4.775,2.129,4.775,4.757c0,2.627-2.135,4.756-4.775,4.756
					c-2.637,0-4.773-2.129-4.773-4.756C26.087,52.057,28.224,49.928,30.861,49.928 M30.861,49.518c-2.858,0-5.185,2.316-5.185,5.167
					c0,2.85,2.327,5.166,5.185,5.166c2.86,0,5.189-2.316,5.189-5.166C36.05,51.834,33.721,49.518,30.861,49.518"
      />
      <path
        fill="#FFFFFF"
        d="M33.356,52.746c-0.239,0-0.466,0.107-0.62,0.25l-0.014-0.012c0.072-0.119,0.168-0.346,0.168-0.715
					c0-0.88-0.884-1.546-2.03-1.546c-1.144,0-2.04,0.666-2.04,1.546c0,0.369,0.119,0.596,0.179,0.715l-0.013,0.012
					c-0.154-0.143-0.381-0.25-0.619-0.25c-0.729,0-1.458,0.702-1.458,2.01c0,1.284,0.717,2.011,1.493,2.011
					c0.716,0,1.218-0.396,1.67-0.845c-0.07-0.106-0.179-0.216-0.298-0.216s-0.251,0.085-0.477,0.263
					c-0.299,0.227-0.562,0.357-0.908,0.357c-0.608,0-0.87-0.68-0.87-1.57c0-0.951,0.251-1.583,0.896-1.583
					c0.286,0,0.477,0.084,0.668,0.262c0.285,0.263,0.382,0.335,0.513,0.335c0.096,0,0.155-0.061,0.179-0.095
					c-0.214-0.216-0.524-0.667-0.524-1.251c0-0.665,0.561-1.129,1.61-1.129c1.051,0,1.61,0.464,1.61,1.129
					c0,0.584-0.308,1.035-0.523,1.251c0.023,0.034,0.083,0.095,0.179,0.095c0.132,0,0.227-0.072,0.513-0.335
					c0.179-0.178,0.383-0.262,0.669-0.262c0.645,0,0.894,0.632,0.894,1.583c0,0.891-0.261,1.57-0.869,1.57
					c-0.347,0-0.622-0.131-0.908-0.357c-0.227-0.178-0.359-0.263-0.477-0.263c-0.119,0-0.227,0.109-0.298,0.216
					c0.44,0.449,0.954,0.845,1.669,0.845c0.777,0,1.495-0.727,1.495-2.011C34.814,53.448,34.084,52.746,33.356,52.746"
      />
      <path
        fill="#FFFFFF"
        d="M31.662,52.723h-0.539c-0.036,0-0.058-0.023-0.07-0.049l-0.169-0.51c-0.012-0.037-0.022-0.037-0.035,0
					l-0.166,0.51c-0.013,0.037-0.036,0.049-0.061,0.049h-0.537c-0.024,0-0.036,0.012-0.013,0.022l0.444,0.321
					c0.022,0.023,0.022,0.061,0.01,0.084l-0.166,0.5c-0.012,0.022-0.012,0.047,0.023,0.022l0.441-0.308
					c0.036-0.025,0.058-0.025,0.096,0l0.43,0.308c0.023,0.024,0.035,0.013,0.023-0.011l-0.167-0.512
					c-0.012-0.035,0-0.072,0.024-0.084l0.44-0.321C31.697,52.734,31.684,52.723,31.662,52.723"
      />
      <path
        fill="#FFFFFF"
        d="M31.256,56.242c-0.227-0.571-0.407-0.963-0.407-1.438c0-0.203,0.061-0.381,0.096-0.475
					c0.049-0.098,0.061-0.157,0.011-0.157c-0.071,0-0.225,0.168-0.322,0.312c-0.072,0.117-0.154,0.344-0.154,0.521
					c0,0.334,0.107,0.691,0.179,0.905c0.132,0.368,0.251,0.797,0.251,1.201c0,0.641-0.288,1.082-0.8,1.473
					c0.047,0.037,0.166,0.095,0.311,0.095c0.5,0,1.096-0.559,1.096-1.318C31.517,56.905,31.387,56.6,31.256,56.242"
      />
    </g>
  </g>
);
// #endregion

const MakeChange = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <MakeChangeStage3 {...otherProps} />;
    case 4:
      return <MakeChangeStage4 {...otherProps} />;
    case 5:
      return <MakeChangeStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

const Influence = (props: any) => {
  const { stage, fallback = null, ...otherProps } = props;

  switch (stage) {
    case 3:
      return <InfluenceStage3 {...otherProps} />;
    case 4:
      return <InfluenceStage4 {...otherProps} />;
    case 5:
      return <InfluenceStage5 {...otherProps} />;
    default:
      return fallback;
  }
};

function TakeAction(props: any) {
  const { skill, stage, fallback = null, ...otherProps } = props;

  switch (skill) {
    case 'Make change':
      return <MakeChange stage={stage} {...otherProps} fallback={fallback} />;
    case 'Influence':
      return <Influence stage={stage} {...otherProps} fallback={fallback} />;
    default:
      return fallback;
  }
}

export default TakeAction;
