// import { lazy } from 'react';
import { RouteProps as RouterRouteProps } from 'react-router-dom';

import routeData from 'data/routes.json';

import { SignIn, SignUp } from 'pages/auth';
import GuidesThemes from 'pages/guides-themes';
import GuidesTheme from 'pages/guides-theme';
// import { toKebabCase } from 'utilities/string';

// type RouteOptions = {
//   title: string;
// };

// const guidesThemes = [
//   'Know Myself',
//   'Express Myself',
//   'Be Well',
//   'Have Adventures',
//   'Take Action',
//   'Skills For My Future'
// ];

// const themeRoutes = guidesThemes.map((theme) => ({
//   name: `GuidesTheme_${theme.replace(/\s/g, '')}`,
//   path: `/guides-themes/${toKebabCase(theme)}`,
//   exact: true,
//   component: GuidesTheme
// }));

export type RouteProps = RouterRouteProps & {
  name: string;
  component?: React.ComponentType<any>;
};

// ROUTES
export const publicRoutes: RouteProps[] = [
  { ...routeData.signin, component: SignIn },
  { ...routeData.signup, component: SignUp }
];

export const privateRoutes: RouteProps[] = [
  { ...routeData.guidesThemes, component: GuidesThemes },
  { ...routeData.guidesTheme, component: GuidesTheme }
]; //.concat(themeRoutes);

// console.log(privateRoutes);
