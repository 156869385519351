import { Suspense, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth } from '../firebase';

import { publicRoutes, privateRoutes, RouteProps } from 'routes';
import AuthContext from 'context/Auth';
import withRender from 'components/hoc/withRender';
import Alert from 'components/alert';
import useOnlineStatus from 'hooks/useOnlineStatus';
import { PageNotFound } from 'pages/error';
import AboutModal from 'components/modal/AboutModal';
import SignInAsModal from 'components/modal/SignInAsModal';
const OfflineAlert = withRender(Alert);

function App() {
  const {
    loading,
    isValid: authorised,
    isFirstLoad,
    role,
    showSignInAsModal,
    setShowSignInAsModal
  } = useContext(AuthContext);
  const isOnline = useOnlineStatus();
  const location = useLocation();
  const navigate = useNavigate();

  const renderRoute = (routeProps: RouteProps, isPrivate: boolean = false) => {
    const { path, component: Component, name, ...otherProps } = routeProps;
    const isUnauthorised = !loading && !authorised && isPrivate;
    let element =
      !loading && !isFirstLoad && (role || !isPrivate)
        ? (Component && <Component />) || null
        : null;

    if (isUnauthorised)
      element = (
        <Navigate
          key={name}
          to={`/sign-in?r=${encodeURI(location.pathname)}`}
          replace
        />
      );

    return <Route {...otherProps} key={name} path={path} element={element} />;
  };

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const originEmail = sessionStorage.getItem('signInAs.originEmail');
      const email = sessionStorage.getItem('signInAs.email') || originEmail;

      if (email === null) return;
      console.log('Signing in with email:', email);
      signInWithEmailLink(auth, email)
        .then(() => navigate('/'))
        .catch((error) => console.error(error))
        .finally(() => {
          sessionStorage.removeItem('signInAs.email');
          if (email === originEmail) {
            sessionStorage.removeItem('signInAs.originEmail');
            sessionStorage.removeItem('signInAs.originLink');
            sessionStorage.removeItem('signInAs.originDisplayName');
          }
        });
    }
  }, []);

  useEffect(() => {
    document.body.classList.toggle('offline', !isOnline);
  }, [isOnline]);

  return (
    <>
      <Helmet titleTemplate="%s | Go4Guides">
        <title>Loading...</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <ToastContainer newestOnTop />
      <AboutModal show={false} />
      <SignInAsModal
        show={showSignInAsModal}
        onClose={() => setShowSignInAsModal(false)}
      />

      <OfflineAlert id="offlineAlert" type="offline" render={!isOnline}>
        <span>The application is working offline!</span>
      </OfflineAlert>

      <Suspense fallback={null}>
        <Routes>
          {publicRoutes.map((routeProps: RouteProps) =>
            renderRoute(routeProps)
          )}

          {privateRoutes.map((routeProps: RouteProps) =>
            renderRoute(routeProps, true)
          )}

          <Route element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
