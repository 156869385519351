import React from 'react';

export type NullableElement = JSX.Element | null;
type Props = Record<string, unknown> & {
  render: boolean;
};

function withRender(WrappedComponent: any) {
  return function Component(props: Props): NullableElement {
    const { render, ...otherProps } = props;
    return render ? <WrappedComponent {...otherProps} /> : null;
  };
}

export default withRender;
