import { HTMLAttributes, PropsWithChildren } from 'react';

export type AwardProps = PropsWithChildren<HTMLAttributes<HTMLOrSVGElement>>;

function Base(props: AwardProps) {
  const { children, ...otherProps } = props;

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      // width="53.515px"
      // height="66.893px"
      viewBox="0 0 53.515 66.893"
      enableBackground="new 0 0 53.515 66.893"
      xmlSpace="preserve"
      {...otherProps}
    >
      {children}
    </svg>
  );
}

export default Base;
