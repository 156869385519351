import React, { PropsWithChildren } from 'react';

export type BaseThemeProps = PropsWithChildren<any> & {
  fillColor?: string;
  width?: number;
  height?: number;
};

function Base(props: BaseThemeProps) {
  const { children, fillColor, width, height, ...otherProps } = props;
  return (
    <svg
      {...otherProps}
      viewBox="0 0 68 68"
      enableBackground="new 0 0 68 68"
      width={width}
      height={height}
    >
      {fillColor ? (
        <g id="Square_Background">
          <rect
            x="-0.18"
            display="inline"
            width="68.126"
            height="68"
            fill={fillColor}
          />
        </g>
      ) : null}

      {children}
    </svg>
  );
}

export default Base;
