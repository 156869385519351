import { forwardRef, PropsWithChildren } from 'react';
import './content.scss';

type MainProps = PropsWithChildren<any>;

const Content = forwardRef((props: MainProps, ref) => {
  const { children, className, ...otherProps } = props;

  const classes = ['content'];
  if (className) classes.push(className);

  return (
    <main className={classes.join(' ')} {...otherProps} ref={ref}>
      {children}
    </main>
  );
});

export default Content;
